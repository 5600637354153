// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-dialogs-camera-AdorCalibrationV2-StepElevate-module__container--Akk9I ._-_-packages-core-src-web-app-components-dialogs-camera-AdorCalibrationV2-StepElevate-module__tab--CcQuO{padding:10px 0}._-_-packages-core-src-web-app-components-dialogs-camera-AdorCalibrationV2-StepElevate-module__container--Akk9I ._-_-packages-core-src-web-app-components-dialogs-camera-AdorCalibrationV2-StepElevate-module__steps--d2BQd{margin:0}._-_-packages-core-src-web-app-components-dialogs-camera-AdorCalibrationV2-StepElevate-module__container--Akk9I ._-_-packages-core-src-web-app-components-dialogs-camera-AdorCalibrationV2-StepElevate-module__video--KOc1e{width:100%;min-height:225px}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/dialogs/camera/AdorCalibrationV2/StepElevate.module.scss"],"names":[],"mappings":"AACE,0NACE,cAAA,CAGF,4NACE,QAAA,CAGF,4NACE,UAAA,CACA,gBAAA","sourcesContent":[".container {\n  .tab {\n    padding: 10px 0;\n  }\n\n  .steps {\n    margin: 0;\n  }\n\n  .video {\n    width: 100%;\n    min-height: 225px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_-_-packages-core-src-web-app-components-dialogs-camera-AdorCalibrationV2-StepElevate-module__container--Akk9I",
	"tab": "_-_-packages-core-src-web-app-components-dialogs-camera-AdorCalibrationV2-StepElevate-module__tab--CcQuO",
	"steps": "_-_-packages-core-src-web-app-components-dialogs-camera-AdorCalibrationV2-StepElevate-module__steps--d2BQd",
	"video": "_-_-packages-core-src-web-app-components-dialogs-camera-AdorCalibrationV2-StepElevate-module__video--KOc1e"
};
export default ___CSS_LOADER_EXPORT___;
