// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-actions-camera-preview-helper-BasePreviewManager-module__mt-24--HO4Wu{margin-top:24px}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/actions/camera/preview-helper/BasePreviewManager.module.scss"],"names":[],"mappings":"AAAA,qGACE,eAAA","sourcesContent":[".mt-24 {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mt-24": "_-_-packages-core-src-web-app-actions-camera-preview-helper-BasePreviewManager-module__mt-24--HO4Wu"
};
export default ___CSS_LOADER_EXPORT___;
