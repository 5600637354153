// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-AdvancedBlock-module__container--HwZ0-{padding:10px 0 !important}._-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-AdvancedBlock-module__container--HwZ0- .ant-collapse-header-text{font-weight:bold;font-size:10pt}._-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-AdvancedBlock-module__container--HwZ0- ._-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-AdvancedBlock-module__panel--26qqT{padding:0;margin-top:20px;display:flex;flex-direction:column;gap:20px;font-size:10pt}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/views/beambox/Right-Panels/ConfigPanel/AdvancedBlock.module.scss"],"names":[],"mappings":"AAAA,6GACE,yBAAA,CAEA,uIACE,gBAAA,CACA,cAAA,CAGF,sNACE,SAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,cAAA","sourcesContent":[".container {\n  padding: 10px 0 !important;\n\n  :global(.ant-collapse-header-text) {\n    font-weight: bold;\n    font-size: 10pt;\n  }\n\n  .panel {\n    padding: 0;\n    margin-top: 20px;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    font-size: 10pt;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-AdvancedBlock-module__container--HwZ0-",
	"panel": "_-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-AdvancedBlock-module__panel--26qqT"
};
export default ___CSS_LOADER_EXPORT___;
