// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-dialogs-autoFit-AutoFitPanel-index-module__sider--ZZ\\+ra{max-width:260px}._-_-packages-core-src-web-app-components-dialogs-autoFit-AutoFitPanel-index-module__icon--64W3W{font-size:32px}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/dialogs/autoFit/AutoFitPanel/index.module.scss"],"names":[],"mappings":"AAAA,mGACE,eAAA,CAGF,iGACE,cAAA","sourcesContent":[".sider {\n  max-width: 260px;\n}\n\n.icon {\n  font-size: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sider": "_-_-packages-core-src-web-app-components-dialogs-autoFit-AutoFitPanel-index-module__sider--ZZ+ra",
	"icon": "_-_-packages-core-src-web-app-components-dialogs-autoFit-AutoFitPanel-index-module__icon--64W3W"
};
export default ___CSS_LOADER_EXPORT___;
