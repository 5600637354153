// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-dialogs-MaterialTestGeneratorPanel-WorkAreaInfo-module__workarea--BJFE6{color:#7c7c7c;font-size:14px;margin:0 10px 0 0;display:flex;align-items:center;column-gap:8px;line-height:30px}@media screen and (max-width: 600px){._-_-packages-core-src-web-app-components-dialogs-MaterialTestGeneratorPanel-WorkAreaInfo-module__workarea--BJFE6{margin-left:0}}._-_-packages-core-src-web-app-components-dialogs-MaterialTestGeneratorPanel-WorkAreaInfo-module__workarea--BJFE6 ._-_-packages-core-src-web-app-components-dialogs-MaterialTestGeneratorPanel-WorkAreaInfo-module__icon--diKaV{font-size:16px}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/dialogs/MaterialTestGeneratorPanel/WorkAreaInfo.module.scss"],"names":[],"mappings":"AAAA,kHACE,aAAA,CACA,cAAA,CACA,iBAAA,CACA,YAAA,CACA,kBAAA,CACA,cAAA,CACA,gBAAA,CAEA,qCATF,kHAUI,aAAA,CAAA,CAGF,gOACE,cAAA","sourcesContent":[".workarea {\n  color: #7c7c7c;\n  font-size: 14px;\n  margin: 0 10px 0 0;\n  display: flex;\n  align-items: center;\n  column-gap: 8px;\n  line-height: 30px;\n\n  @media screen and (max-width: 600px) {\n    margin-left: 0;\n  }\n\n  .icon {\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"workarea": "_-_-packages-core-src-web-app-components-dialogs-MaterialTestGeneratorPanel-WorkAreaInfo-module__workarea--BJFE6",
	"icon": "_-_-packages-core-src-web-app-components-dialogs-MaterialTestGeneratorPanel-WorkAreaInfo-module__icon--diKaV"
};
export default ___CSS_LOADER_EXPORT___;
