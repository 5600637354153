// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-views-monitor-MonitorFilelist-module__container--T-R5a{display:flex;flex-wrap:wrap;width:100%;height:400px;overflow:scroll}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/views/monitor/MonitorFilelist.module.scss"],"names":[],"mappings":"AAAA,sFACE,YAAA,CACA,cAAA,CACA,UAAA,CACA,YAAA,CACA,eAAA","sourcesContent":[".container {\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  height: 400px;\n  overflow: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_-_-packages-core-src-web-app-views-monitor-MonitorFilelist-module__container--T-R5a"
};
export default ___CSS_LOADER_EXPORT___;
