// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-views-dialogs-Progress-module__progress--nbGu- .ant-progress-text{min-width:46px}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/views/dialogs/Progress.module.scss"],"names":[],"mappings":"AACE,iGACE,cAAA","sourcesContent":[".progress {\n  :global(.ant-progress-text) {\n    min-width: 46px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progress": "_-_-packages-core-src-web-app-views-dialogs-Progress-module__progress--nbGu-"
};
export default ___CSS_LOADER_EXPORT___;
