// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-beambox-svg-editor-Banner-module__container--9xO87{flex:0 0 auto;width:100%;background-color:#f0f0f0;font-weight:700;color:#6a5959;display:flex;justify-content:center;align-items:center;border-bottom:1px solid #e0e0e0}._-_-packages-core-src-web-app-components-beambox-svg-editor-Banner-module__flex--DIP-h :first-child{margin-top:8px}._-_-packages-core-src-web-app-components-beambox-svg-editor-Banner-module__flex--DIP-h div{margin-bottom:8px}._-_-packages-core-src-web-app-components-beambox-svg-editor-Banner-module__text--RwXCB{height:16px;display:flex;justify-content:center;align-items:center}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/beambox/svg-editor/Banner.module.scss"],"names":[],"mappings":"AAAA,6FACE,aAAA,CACA,UAAA,CACA,wBAAA,CACA,eAAA,CACA,aAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,+BAAA,CAIA,qGACE,cAAA,CAGF,4FACE,iBAAA,CAIJ,wFACE,WAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":[".container {\n  flex: 0 0 auto;\n  width: 100%;\n  background-color: #f0f0f0;\n  font-weight: 700;\n  color: #6a5959;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border-bottom: 1px solid #e0e0e0;\n}\n\n.flex {\n  :first-child {\n    margin-top: 8px;\n  }\n\n  div {\n    margin-bottom: 8px;\n  }\n}\n\n.text {\n  height: 16px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_-_-packages-core-src-web-app-components-beambox-svg-editor-Banner-module__container--9xO87",
	"flex": "_-_-packages-core-src-web-app-components-beambox-svg-editor-Banner-module__flex--DIP-h",
	"text": "_-_-packages-core-src-web-app-components-beambox-svg-editor-Banner-module__text--RwXCB"
};
export default ___CSS_LOADER_EXPORT___;
