import type { ILang } from '@core/interfaces/ILang';

const lang: ILang = {
  alert: {
    abort: 'Abandonner',
    cancel: 'Annuler',
    caption: 'Erreur',
    close: 'Fermer',
    confirm: 'Confirmer',
    dont_save: 'Ne pas enregistrer',
    dont_show_again: 'Ne plus afficher',
    duplicated_preset_name: 'Nom de préréglage dupliqué',
    error: 'ERREUR',
    info: 'INFO',
    instruction: 'Instruction',
    learn_more: 'En savoir plus',
    no: 'Non',
    ok: 'OK',
    ok2: 'OK',
    oops: 'Oups...',
    retry: 'Réessayer',
    save: 'Enregistrer',
    stop: 'Arrêter',
    warning: 'AVERTISSEMENT',
    yes: 'Oui',
  },
  auto_fit: {
    artwork_size: "Taille de l'œuvre",
    error_tip1: "1. L'œuvre est-elle correctement placée sur le matériau ?",
    error_tip2: '2. Les contours du matériau sont-ils suffisamment clairs pour être reconnus ?',
    failed_to_find_contour: "Échec de la détection des contours pour l'ajustement automatique",
    learn_more: 'Voir le tutoriel.',
    learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    offset_x: 'Décalage X',
    offset_y: 'Décalage Y',
    pattern_size: 'Taille du motif',
    position_artwork: "Positionner l'œuvre d'art",
    position_step1: 'Ajustez la taille et la position de votre œuvre sur le motif.',
    position_step2: "Cliquez sur « Appliquer » pour appliquer l'ajustement automatique à ce motif.",
    preview_first: "Veuillez d'abord effectuer l'aperçu de la caméra.",
    reset_position: 'Réinitialiser la position',
    rotation: 'Pivoter',
    select_a_pattern: 'Sélectionner un motif',
    selected_artwork: "Œuvre d'art sélectionnée",
    step1: "Choisissez un motif où vous souhaitez placer l'œuvre d'art.",
    step2: "Si aucun motif n'est trouvé, prévisualisez à nouveau la caméra et appliquez Ajustement Automatique.",
    step3: 'Si cela ne parvient toujours pas à trouver le motif correct, assurez-vous que votre pièce est clairement reconnaissable et que la zone de travail est exempte de débris.',
    title: 'Ajustement Automatique',
  },
  barcode_generator: {
    bar_height: 'Hauteur de barre',
    bar_width: 'Largeur de barre',
    barcode: {
      invalid_value: 'La valeur est invalide pour le format sélectionné.',
    },
    font: 'Police',
    font_size: 'Taille de police',
    hide_text: 'Masquer le texte',
    invert_color: 'Inverser couleur',
    text_margin: 'Marge de texte',
  },
  beambox: {
    announcement_panel: {
      title: 'Annonce',
    },
    banner: {
      auto_feeder: 'Mode Alimentation Automatique',
      camera_preview: 'Aperçu',
      camera_preview_borderless_mode: '(Fond Ouvert)',
      curve_engraving: 'Mode Gravure Courbe',
      pass_through: 'Mode Traversant',
      rotary: 'Mode Rotatif',
    },
    bottom_right_panel: {
      convert_text_to_path_before_export: 'Convertir le texte en tracé...',
      export_file_error_ask_for_upload: "Échec de l'exportation de la tâche. Êtes-vous prêt à fournir la scène de travail à l'équipe de développement pour le rapport de bug ?",
      retreive_image_data: "Récupérer les données d'image...",
    },
    context_menu: {
      copy: 'Copier',
      cut: 'Couper',
      delete: 'Supprimer',
      duplicate: 'Dupliquer',
      group: 'Grouper',
      move_back: 'Mettre en arrière-plan',
      move_down: "Descendre d'un cran",
      move_front: 'Amener au premier plan',
      move_up: "Monter d'un cran",
      paste: 'Coller',
      paste_in_place: 'Coller en place',
      ungroup: 'Dégrouper',
    },
    document_panel: {
      add_on: 'Modules complémentaires',
      auto_feeder: 'Chargeur automatique',
      auto_feeder_scale: 'Échelle du chargeur automatique',
      auto_feeder_url: 'https://support.flux3dp.com/hc/en-us/articles/11688230498575',
      borderless_mode: 'Mode sans bordure',
      current_position: 'Position Actuelle',
      disable: 'Désactiver',
      document_settings: 'Paramètres du document',
      enable: 'Activer',
      enable_autofocus: 'Mise au point automatique',
      enable_diode: 'Laser à diode',
      engrave_dpi: 'Résolution',
      frame_before_start: "Cadre avant d'exécuter",
      frame_before_start_url: 'https://support.flux3dp.com/hc/en-us/articles/11494925637135',
      high: 'Élevé',
      job_origin: 'Origine du Travail',
      laser_source: 'Source laser',
      low: 'Faible',
      machine: 'Machine',
      medium: 'Moyen',
      notification: {
        changeFromPrintingWorkareaTitle: "Voulez-vous convertir les calques d'impression en calques laser?",
      },
      origin: 'Origine',
      pass_through: 'Passage direct',
      pass_through_height_desc: "Entrez la longueur de l'objet pour étendre la zone de travail.",
      rotary_mode: 'Mode rotatif',
      scale: 'Échelle',
      start_from: 'Commencer À Partir de',
      start_position: 'Position de Départ',
      start_work_button: 'Bouton Exécuter',
      ultra: 'Ultra élevé',
      workarea: 'Zone de travail',
    },
    image_trace_panel: {
      back: 'Retour',
      brightness: 'Luminosité',
      cancel: 'Annuler',
      contrast: 'Contraste',
      next: 'Suivant',
      okay: 'OK',
      threshold: 'Seuil',
      tuning: 'Paramètres',
    },
    layer_color_config_panel: {
      add: 'Ajouter',
      add_config: 'Ajouter une couleur',
      color: 'Couleur',
      default: 'Réinitialiser aux valeurs par défaut',
      in_use: 'Cette couleur est utilisée.',
      layer_color_config: 'Configurations de couleur de couche',
      no_input: 'Veuillez saisir un code couleur hexadécimal valide.',
      power: 'Puissance',
      repeat: 'Exécuter',
      speed: 'Vitesse',
      sure_to_delete: 'Êtes-vous sûr de supprimer ce paramètre de couleur ?',
      sure_to_reset: 'Vous perdrez tous les paramètres personnalisés, êtes-vous sûr de réinitialiser aux paramètres par défaut ?',
    },
    left_panel: {
      borderless_blind_area: 'Zone non gravable',
      borderless_preview: 'Aperçu caméra en mode fond ouvert',
      diode_blind_area: 'Zone non visible du module laser hybride',
      ellipse: 'Ellipse',
      image: 'Image',
      label: {
        adjust_height: 'Ajuster la hauteur',
        array: 'Tableau',
        boxgen: 'Boxgen',
        choose_camera: 'Caméra',
        clear_preview: "Effacer l'aperçu",
        cursor: 'Sélectionner',
        curve_engraving: {
          clear_area: 'Effacer la zone sélectionnée',
          exit: 'Quitter',
          preview_3d_curve: 'Aperçu de la gravure de courbe',
          select_area: 'Sélectionner une zone',
          title: 'Gravure de courbe',
        },
        end_preview: 'Arrêter aperçu',
        line: 'Ligne',
        live_feed: 'Diffusion en direct',
        my_cloud: 'Mon cloud',
        oval: 'Ovale',
        pass_through: 'Passage direct',
        pen: 'Stylo',
        photo: 'Image',
        polygon: 'Polygone',
        preview: 'Aperçu caméra',
        qr_code: 'Code QR',
        rect: 'Rectangle',
        shapes: 'Formes',
        text: 'Texte',
        trace: 'Tracer image',
      },
      line: 'Ligne',
      rectangle: 'Rectangle',
      text: 'Texte',
      unpreviewable_area: 'Zone non visible',
    },
    network_testing_panel: {
      average_response: 'Temps de réponse moyen',
      cannot_connect_1: "#840 Échec de connexion à l'adresse IP cible",
      cannot_connect_2: "#840 Échec de connexion à l'adresse IP cible. Assurez-vous que la cible se trouve dans le même réseau.",
      cannot_get_local: "Accès à l'adresse IP locale échoué.",
      connection_quality: 'Qualité de connexion',
      device_not_on_list: '#842 La machine ne figure pas dans la liste, mais la qualité de la connexion est >70 et le temps de réponse moyen est <100ms',
      empty_ip: "#818 Veuillez d'abord entrer l'adresse IP du périphérique cible.",
      end: 'Terminer',
      fail_to_start_network_test: '#817 Échec du démarrage des tests réseau.',
      hint_connect_camera_timeout: "Délai d'attente lors du démarrage de l'aperçu de la caméra ?",
      hint_connect_failed_when_sending_job: "Échec de la connexion lors de l'envoi d'une tâche ?",
      hint_device_often_on_list: 'La machine ne se trouve souvent pas dans la liste ?',
      insert_ip: 'Adresse IP du périphérique cible :',
      invalid_ip: '#818 Adresse IP non valide',
      ip_startswith_169: "#843 L'adresse IP de la machine commence par 169.254",
      link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
      link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
      link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
      linux_permission_hint: 'Cette erreur se produit généralement en raison de permissions insuffisantes.\nVeuillez exécuter "sudo beam-studio --no-sandbox" dans le terminal pour obtenir les permissions et effectuer des tests réseau.',
      local_ip: 'Adresse IP locale :',
      network_testing: 'Test de réseau',
      network_unhealthy: '#841 Qualité de connexion <70 ou temps de réponse moyen >100ms',
      start: 'Démarrer',
      test_completed: 'Test terminé',
      test_fail: 'Échec du test',
      testing: 'Test du réseau...',
    },
    object_panels: {
      lock_desc: 'Conserver le rapport largeur/hauteur (MAJ)',
      text_to_path: {
        check_thumbnail_warning: "Certains textes ont été modifiés avec d'autres polices lors de la conversion des textes en tracés et certains caractères peuvent ne pas être convertis normalement. <br/> Veuillez vérifier à nouveau l'image d'aperçu avant d'envoyer la tâche.",
        error_when_parsing_text: 'Erreur lors de la conversion du texte en tracé',
        font_substitute_pop: 'Votre texte contient des caractères qui ne sont pas pris en charge par la police actuelle. <br/>Souhaitez-vous utiliser <strong>%s</strong> comme substitution ?',
        retry: 'Veuillez réessayer plus tard ou choisir une autre police',
        use_current_font: 'Utiliser la police actuelle',
      },
      wait_for_parsing_font: 'Analyse de la police en cours... Veuillez patienter une seconde',
    },
    path_preview: {
      current_position: 'Position actuelle',
      cut_distance: 'Distance de découpe',
      cut_time: 'Temps de découpe',
      end_preview: "Fin de l'aperçu",
      estimated_time: 'Temps total estimé',
      invert: 'Inverser',
      pause: 'Pause',
      play: 'Lire',
      play_speed: 'Vitesse de lecture',
      preview_info: "Informations d'aperçu",
      rapid_distance: 'Distance de déplacement',
      rapid_time: 'Temps de déplacement',
      remark: '* Toutes les informations sont des valeurs estimées à titre indicatif.',
      size: 'Taille',
      start_here: 'Commencer ici',
      stop: 'Arrêter',
      travel_path: 'Trajet',
    },
    photo_edit_panel: {
      aspect_ratio: "Format d'image",
      brightness: 'Luminosité',
      brightness_and_contrast: 'Luminosité / Contraste',
      cancel: 'Annuler',
      circumference: 'Circonférence',
      compare: 'Comparer',
      contrast: 'Contraste',
      crop: 'Recadrer',
      curve: 'Courbe',
      diameter: 'Diamètre',
      free: 'Libre',
      invert: 'Inverser les couleurs',
      okay: 'OK',
      original: 'Original',
      phote_edit: 'Édition de photo',
      processing: 'Traitement',
      radius: 'Rayon',
      rotary_warped: 'Déformé Rotatif',
      rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
      sharpen: 'Netteté',
      sharpness: 'Netteté',
      start: 'Démarrer',
      warp: 'Déformation',
    },
    popup: {
      ai_credit: {
        buy_link: 'https://member.flux3dp.com/en-us/credit',
        go: 'Aller',
        insufficient_credit: "Vous n'avez plus de crédit",
        insufficient_credit_msg: 'Vous ne pouvez pas utiliser le %s. Allez au centre des membres et achetez un crédit IA.',
        relogin_to_use: 'Veuillez vous reconnecter pour utiliser cette fonction.',
      },
      auto_feeder_origin: 'Utilisez l’aperçu du cadrage pour vérifier la trajectoire et la zone de la tête laser, puis ajustez le placement du matériau ou la position de départ de la gravure en fonction des résultats afin d’éviter les collisions lors du processus de gravure.',
      auto_switch_tab: {
        message: 'Une nouvelle option permettant de basculer automatiquement entre le panneau des calques et le panneau des objets a été ajoutée aux Préférences. Cette option est désactivée par défaut. Souhaitez-vous activer la commutation automatique maintenant ? <br/>Vous pouvez modifier ce paramètre à tout moment dans les Préférences.',
        title: 'Commutation Automatique entre le Panneau des Calques et des Objets',
      },
      backend_connect_failed_ask_to_upload: '#802 Des erreurs se sont produites en essayant de se connecter au backend. Voulez-vous télécharger votre journal de rapport de bug ?',
      backend_error_hint: "Les fonctionnalités pourraient ne pas fonctionner correctement en raison d'une erreur de backend.",
      both_power_and_speed_too_high: "Utiliser une puissance laser inférieure prolongera la durée de vie du tube laser.\\nDe plus, une vitesse trop élevée à cette résolution peut entraîner une qualité d'ombrage de gravure inférieure.",
      bug_report: 'Rapport de bug',
      change_workarea_before_preview: 'La zone de travail de %s ne correspond pas à la zone de travail actuellement définie. Voulez-vous basculer la zone de travail actuelle ?',
      convert_to_path_fail: 'Échec de la conversion en chemin.',
      dxf_bounding_box_size_over: "La taille du dessin dépasse la zone de travail. Veuillez déplacer votre dessin plus près de l'origine dans votre logiciel CAO, ou assurez-vous que l'unité est définie correctement.",
      dxf_version_waring: "La version de ce fichier DXF n'est pas 2013, il pourrait y avoir des problèmes de compatibilité potentiels.",
      facebook_group_invitation: {
        already_joined: 'Déjà rejoint',
        join_now: 'Comptez sur moi',
        later: 'Peut-être plus tard',
        message: "Rejoignez notre groupe Facebook officiel pour vous connecter avec d'autres utilisateurs de découpe laser FLUX, discuter des lasers FLUX, partager des œuvres laser et rester à jour avec les dernières mises à jour sur nos produits. Nous avons hâte de vous y voir !",
        title: 'Rejoignez le groupe officiel des utilisateurs FLUX',
      },
      import_file_contain_invalid_path: "#808 Le fichier SVG importé contient un chemin d'image non valide. Assurez-vous que tous les fichiers image existent ou intégrez l'image dans le fichier",
      import_file_error_ask_for_upload: "Échec de l'importation du fichier SVG. Êtes-vous prêt à fournir le fichier à l'équipe de développement pour le rapport de bug ?",
      layer_by_color: 'Couleur',
      layer_by_layer: 'Couche',
      loading_image: "Chargement de l'image, veuillez patienter...",
      more_than_two_object: "Trop d'objets. Seuls 2 objets sont pris en charge",
      mouse: 'Souris',
      no_support_text: "Beam Studio ne prend pas en charge les balises de texte pour le moment. Veuillez convertir le texte en chemin avant l'importation.",
      nolayer: 'Couche unique',
      not_support_object_type: "Type d'objet non pris en charge",
      or_turn_off_borderless_mode: 'Ou désactivez le mode Open Bottom.',
      pdf2svg: {
        error_pdf2svg_not_found: '#825 Erreur : commande pdf2svg introuvable. Veuillez installer pdf2svg avec votre gestionnaire de paquets (par exemple, "yum install pdf2svg" ou "apt-get install pdf2svg").',
        error_when_converting_pdf: '#824 Erreur lors de la conversion PDF en SVG :',
      },
      progress: {
        calculating: 'Calcul',
        uploading: 'Téléversement',
      },
      questionnaire: {
        caption: 'Aidez-nous à remplir le questionnaire',
        message: 'Aidez-nous à remplir le questionnaire pour améliorer Beam Studio.',
        no_questionnaire_available: "Il n'y a pas de questionnaire disponible pour le moment.",
        unable_to_get_url: "Échec de l'obtention du lien vers le questionnaire via Internet. Veuillez confirmer votre connexion Internet.",
      },
      recommend_downgrade_software: 'Nous avons détecté une ancienne version du micrologiciel. Nous traitons activement les problèmes de compatibilité, mais pour l\'instant, nous vous recommandons de revenir à <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>.',
      recommend_upgrade_firmware: "Nous avons détecté une ancienne version du micrologiciel. Nous traitons activement les problèmes de compatibilité, mais pour l'instant, nous vous recommandons de mettre à jour vers le dernier micrologiciel.",
      save_unsave_changed: 'Voulez-vous enregistrer les modifications non enregistrées ?',
      select_at_least_two: 'Sélectionnez deux objets pour continuer',
      select_first: "Sélectionnez d'abord un objet.",
      select_import_method: 'Sélectionner le style de superposition :',
      select_import_module: 'Sélectionner le module :',
      sentry: {
        message: "Acceptez-vous de télécharger automatiquement des informations pertinentes vers l'équipe de développement lorsque des erreurs se produisent ?",
        title: 'Améliorons Beam Studio ensemble',
      },
      should_update_firmware_to_continue: '#814 Votre micrologiciel ne prend pas en charge cette version de Beam Studio. Veuillez mettre à jour le micrologiciel pour continuer. (Menu > Machine > [Votre machine] > Mettre à jour le micrologiciel)',
      speed_too_high_lower_the_quality: "Utiliser une vitesse trop élevée à cette résolution peut entraîner une qualité d'ombrage de gravure inférieure.",
      still_continue: 'Continuer',
      successfully_uploaded: 'Téléversement du fichier réussi.',
      svg_1_1_waring: 'La version de ce fichier SVG est v 1.1, il pourrait y avoir des problèmes de compatibilité potentiels.',
      svg_image_path_waring: "Ce fichier SVG contient <image> chargé à partir du chemin du fichier. Cela pourrait causer des échecs lors du chargement. \\nPour éviter ce risque, veuillez utiliser une image intégrée lors de l'exportation SVG.",
      text_to_path: {
        caption: 'Convertisseur de texte en tracé 2.0',
        message: "Beam Studio introduit maintenant un nouveau convertisseur de texte en tracé (Convertisseur 2.0), qui produit des résultats plus fiables ! Voulez-vous passer à celui-ci maintenant ?\nVous pouvez également ajuster ce paramètre plus tard dans la section 'Convertisseur de texte en tracé' dans les préférences.",
      },
      too_fast_for_auto_feeder: "L'utilisation de vitesses trop élevées dans les couches contenant des objets de chemin peut réduire la précision de la découpe.\nNous ne recommandons pas d'utiliser une vitesse supérieure à %(limit)s lors de la découpe avec l'Auto Feeder.",
      too_fast_for_auto_feeder_and_constrain: "Les couches suivantes : %(layers)s \ncontiennent des chemins vectoriels et ont une vitesse supérieure à %(limit)s.\nLa vitesse de découpe des chemins vectoriels avec l'Auto Feeder sera limitée à %(limit)s.\nVous pouvez supprimer cette limite dans les Paramètres de Préférences.",
      too_fast_for_curve: 'L’utilisation de vitesses trop élevées pour les calques contenant des objets de gravure en courbe peut réduire la précision de la gravure.\nNous recommandons de maintenir la vitesse en dessous de %(limit)s pour des résultats optimaux.',
      too_fast_for_curve_and_constrain: 'Les calques suivants : %(layers)s dépassent la limite de vitesse de %(limit)s.\nLa vitesse de gravure des objets en courbe sera limitée à %(limit)s. Vous pouvez supprimer cette limite dans les paramètres de préférences.',
      too_fast_for_path: "Utiliser une vitesse trop élevée dans les couches contenant des objets de chemin peut entraîner une précision inférieure lors de la découpe.\\nNous ne recommandons pas d'utiliser une vitesse supérieure à %(limit)s lors de la découpe.",
      too_fast_for_path_and_constrain: 'Les couches suivantes : %(layers)s\ncontiennent des objets de chemin vectoriel, et ont une vitesse supérieure à %(limit)s.\\nLa vitesse de découpe des objets de chemin vectoriel sera limitée à %(limit)s.\\nVous pouvez supprimer cette limite dans les paramètres de préférences.',
      touchpad: 'Pavé tactile',
      ungroup_use: "Cela dégroupera le DXF ou le SVG importé. Comme le fichier pourrait contenir un grand nombre d'éléments, le dégroupage pourrait prendre du temps. Êtes-vous sûr de procéder ?",
      upload_failed: '#819 Échec du téléversement du fichier.',
      upload_file_too_large: '#819 Le fichier est trop volumineux pour être téléversé.',
      vectorize_shading_image: "Les images dégradées prennent plus de temps à tracer et sont sujettes au bruit. Veuillez désactiver le dégradé de l'image avant d'exécuter.",
    },
    rating_panel: {
      description: 'Si vous aimez Beam Studio, nous vous serions très reconnaissants si vous pouviez nous évaluer.',
      thank_you: 'Merci !',
      title: 'Aimez-vous Beam Studio ?',
    },
    right_panel: {
      laser_panel: {
        advanced: 'Avancé',
        backlash: 'Jeu',
        bi_directional: 'Bidirectionnel',
        by: 'de',
        ce_z_high_speed: 'Axe Z haute vitesse',
        ce_z_high_speed_link: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
        color_adjustment: 'Réglage couleur',
        color_adjustment_short: 'Couleur',
        color_strength: 'Intensité',
        cross_hatch: 'Hachure Croisée',
        custom_preset: 'Personnalisé',
        cut: 'Couper',
        diode: 'Laser à diode',
        do_not_adjust_default_para: 'Les paramètres par défaut ne peuvent pas être modifiés.',
        dottingTime: 'Temps de pointage',
        dropdown: {
          inches: {
            abs_engraving: 'ABS - Gravure',
            acrylic_3mm_cutting: 'Acrylique - 1/8″ Découpe',
            acrylic_5mm_cutting: 'Acrylique - 3/16″ Découpe',
            acrylic_8mm_cutting: 'Acrylique - 1/4″ Découpe',
            acrylic_10mm_cutting: 'Acrylique - 3/8″ Découpe',
            acrylic_engraving: 'Acrylique - Gravure',
            acrylic_printing: 'Acrylique - Impression',
            aluminum_engraving: 'Aluminium - Gravure',
            aluminum_light: 'Aluminium (clair)',
            bamboo_printing: 'Bambou - Impression',
            black_abs: 'ABS noir',
            black_acrylic_3mm_cutting: 'Acrylique noir - 1/8″ Découpe',
            black_acrylic_5mm_cutting: 'Acrylique noir - 3/16″ Découpe',
            black_acrylic_engraving: 'Acrylique noir - Gravure',
            brass_dark: 'Laiton (foncé)',
            brass_engraving: 'Laiton - Gravure',
            brass_light: 'Laiton (clair)',
            canvas_printing: 'Toile - Impression',
            cardstock_printing: 'Carton - Impression',
            copper: 'Cuivre',
            cork_printing: 'Liège - Impression',
            denim_1mm_cutting: 'Jean - 1/32″ Découpe',
            fabric_3mm_cutting: 'Tissu - 1/8″ Découpe',
            fabric_5mm_cutting: 'Tissu - 3/16″ Découpe',
            fabric_engraving: 'Tissu - Gravure',
            fabric_printing: 'Tissu - Impression',
            flat_stone_printing: 'Pierre plate - Impression',
            glass_bw_engraving: 'Verre - Gravure',
            glass_printing: 'Verre - Impression',
            gloss_leather_printing: 'Cuir verni - Impression',
            gold_engraving: 'Or - Gravure',
            iron_engraving: 'Fer - Gravure',
            leather_3mm_cutting: 'Cuir - 1/8″ Découpe',
            leather_5mm_cutting: 'Cuir - 3/16″ Découpe',
            leather_engraving: 'Cuir - Gravure',
            mdf_3mm_cutting: 'MDF - 1/8″ Découpe',
            mdf_5mm_cutting: 'MDF - 3/16″ Découpe',
            mdf_engraving: 'MDF - Gravure',
            metal_bw_engraving: 'Métal - Gravure',
            opaque_acrylic: 'Acrylique opaque',
            pc_printing: 'PC - Impression',
            rubber_bw_engraving: 'Caoutchouc - Gravure',
            silver_engraving: 'Argent - Gravure',
            stainless_steel_bw_engraving_diode: 'Métal - Gravure (Laser à diode)',
            stainless_steel_dark: 'Acier inoxydable (foncé)',
            stainless_steel_engraving: 'Acier inoxydable - Gravure',
            stainless_steel_light: 'Acier inoxydable (clair)',
            stainless_steel_printing: 'Acier inoxydable - Impression',
            steel_engraving_spray_engraving: 'Métal - Gravure',
            stone: 'Pierre',
            ti_engraving: 'Titane - Gravure',
            titanium_dark: 'Titane (foncé)',
            titanium_light: 'Titane (clair)',
            white_abs: 'ABS blanc',
            wood_3mm_cutting: 'Bois - 1/8″ Découpe',
            wood_5mm_cutting: 'Bois - 3/16″ Découpe',
            wood_7mm_cutting: 'Bois - 1/4″ Découpe',
            wood_8mm_cutting: 'Bois - 1/4″ Découpe',
            wood_10mm_cutting: 'Bois - 3/8″ Découpe',
            wood_engraving: 'Bois - Gravure',
            wood_printing: 'Bois - Impression',
          },
          mm: {
            abs_engraving: 'ABS - Gravure',
            acrylic_3mm_cutting: 'Acrylique - 3mm Découpe',
            acrylic_5mm_cutting: 'Acrylique - 5mm Découpe',
            acrylic_8mm_cutting: 'Acrylique - 8mm Découpe',
            acrylic_10mm_cutting: 'Acrylique - 10mm Découpe',
            acrylic_engraving: 'Acrylique - Gravure',
            acrylic_printing: 'Acrylique - Impression',
            aluminum_engraving: 'Aluminium - Gravure',
            aluminum_light: 'Aluminium (clair)',
            bamboo_printing: 'Bambou - Impression',
            black_abs: 'ABS noir',
            black_acrylic_3mm_cutting: 'Acrylique noire - 3mm Découpe',
            black_acrylic_5mm_cutting: 'Acrylique noire - 5mm Découpe',
            black_acrylic_engraving: 'Acrylique noire - Gravure',
            brass_dark: 'Laiton (foncé)',
            brass_engraving: 'Laiton - Gravure',
            brass_light: 'Laiton (clair)',
            canvas_printing: 'Toile - Impression',
            cardstock_printing: 'Carton - Impression',
            copper: 'Cuivre',
            cork_printing: 'Liège - Impression',
            denim_1mm_cutting: 'Jean - 1mm Découpe',
            fabric_3mm_cutting: 'Tissu - 3mm Découpe',
            fabric_5mm_cutting: 'Tissu - 5mm Découpe',
            fabric_engraving: 'Tissu - Gravure',
            fabric_printing: 'Tissu - Impression',
            flat_stone_printing: 'Pierre plate - Impression',
            glass_bw_engraving: 'Verre - Gravure',
            glass_printing: 'Verre - Impression',
            gloss_leather_printing: 'Cuir verni - Impression',
            gold_engraving: 'Or - Gravure',
            iron_engraving: 'Fer - Gravure',
            leather_3mm_cutting: 'Cuir - 3mm Découpe',
            leather_5mm_cutting: 'Cuir - 5mm Découpe',
            leather_engraving: 'Cuir - Gravure',
            mdf_3mm_cutting: 'MDF - 3mm Découpe',
            mdf_5mm_cutting: 'MDF - 5mm Découpe',
            mdf_engraving: 'MDF - Gravure',
            metal_bw_engraving: 'Métal - Gravure',
            opaque_acrylic: 'Acrylique opaque',
            pc_printing: 'PC - Impression',
            rubber_bw_engraving: 'Caoutchouc - Gravure',
            silver_engraving: 'Argent - Gravure',
            stainless_steel_bw_engraving_diode: 'Acier inoxydable - Gravure (Laser diode)',
            stainless_steel_dark: 'Acier inoxydable (foncé)',
            stainless_steel_engraving: 'Acier inoxydable - Gravure',
            stainless_steel_light: 'Acier inoxydable (clair)',
            stainless_steel_printing: 'Acier inoxydable - Impression',
            steel_engraving_spray_engraving: 'Métal - Gravure',
            stone: 'Pierre',
            ti_engraving: 'Titane - Gravure',
            titanium_dark: 'Titane (foncé)',
            titanium_light: 'Titane (clair)',
            white_abs: 'ABS blanc',
            wood_3mm_cutting: 'Bois - 3mm Découpe',
            wood_5mm_cutting: 'Bois - 5mm Découpe',
            wood_7mm_cutting: 'Bois - 7mm Découpe',
            wood_8mm_cutting: 'Bois - 8mm Découpe',
            wood_10mm_cutting: 'Bois - 10mm Découpe',
            wood_engraving: 'Bois - Gravure',
            wood_printing: 'Bois - Impression',
          },
          parameters: 'Préréglages',
          save: 'Ajouter les paramètres actuels',
        },
        engrave: 'Graver',
        existing_name: 'Ce nom existe déjà.',
        fill_angle: 'Angle de Remplissage',
        fill_interval: 'Intervalle de Remplissage',
        fill_setting: 'Paramètres de Remplissage',
        filled_path_only: 'Uniquement pour les chemins de remplissage',
        focus_adjustment: 'Réglage de la mise au point',
        frequency: 'Fréquence',
        gradient_only: 'Uniquement pour les images dégradées',
        halftone: 'Demi-teinte',
        halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
        height: "Hauteur de l'objet",
        ink_saturation: 'Saturation',
        ink_type: {
          normal: 'Encre',
          text: "Type d'encre",
          UV: 'Encre UV',
        },
        laser_speed: {
          fast: 'Rapide',
          max: 300,
          min: 3,
          slow: 'Lent',
          step: 0.1,
          text: 'Vitesse',
          unit: 'mm/s',
        },
        low_power_warning: 'Une puissance laser inférieure (moins de 10 %) pourrait ne pas émettre la lumière laser.',
        low_speed_warning: 'Une vitesse lente pourrait brûler le matériau.',
        lower_focus: 'Abaisser la Mise au Point',
        lower_focus_desc: "Abaisser la hauteur du focus d'une distance spécifique après la mise au point pour améliorer la performance de coupe.",
        module: 'Module',
        more: 'Plus',
        multi_layer: 'Couches multiples',
        para_in_use: 'Ce paramètre est utilisé.',
        parameters: 'Paramètres',
        power: {
          high: 'Haute',
          low: 'Basse',
          max: 100,
          min: 1,
          step: 0.1,
          text: 'Puissance',
        },
        preset_management: {
          add_new: 'Ajouter Nouveau',
          delete: 'Supprimer',
          export: 'Exporter',
          export_preset_title: 'Exporter les paramètres prédéfinis',
          import: 'Importer',
          laser: 'Laser',
          lower_focus_by: 'Abaisser la Mise au Point de',
          new_preset_name: 'Nouveau nom de préréglage',
          preset: 'Préréglage',
          print: 'Imprimer',
          reset: 'Réinitialiser',
          save_and_exit: 'Enregistrer et quitter',
          show_all: 'Tout Afficher',
          sure_to_import_presets: "Cela chargera l'agencement des préréglages et écrasera les paramètres personnalisés, êtes-vous sûr de vouloir continuer?",
          sure_to_reset: 'Cela supprimera vos paramètres personnalisés et réinitialisera tous les préréglages, êtes-vous sûr de vouloir continuer?',
          title: 'Gérer les Paramètres',
          wobble_diameter: 'Diamètre de wobble',
          wobble_step: 'Taille de pas de wobble',
        },
        preset_setting: 'Paramètres prédéfinis (%s)',
        presets: 'Préréglages',
        print_multipass: 'Multi-passes',
        promark_speed_desc: "Les paramètres de vitesse ne s'appliquent pas aux images avec dégradés.",
        pulse_width: "Largeur d'impulsion",
        pwm_advanced_desc: 'Réglez une puissance minimale pour le mode profondeur.',
        pwm_advanced_hint: "Ce réglage s'applique spécifiquement aux images en dégradé lors de l'utilisation du mode profondeur.",
        pwm_advanced_setting: 'Paramètres de Puissance du Mode Profondeur',
        repeat: 'Nombre de Passes',
        single_color: 'Couleur Unique',
        single_color_desc: 'Uniquement applicable à la couche couleur complète et ne peut pas être utilisé avec la couche couleur unique étendue.',
        slider: {
          fast: 'Rapide',
          high: 'Élevé',
          low: 'Bas',
          regular: 'Régulier',
          slow: 'Lent',
          very_fast: 'Très rapide',
          very_high: 'Max',
          very_low: 'Min',
          very_slow: 'Très lent',
        },
        speed: 'Vitesse',
        speed_constrain_warning: 'La vitesse de découpe des objets de trajet vectoriel sera limitée à %(limit)s. Vous pouvez supprimer cette limite dans les paramètres de préférences.',
        speed_constrain_warning_auto_feeder: 'La vitesse de coupe des objets Alimentateur Automatique sera limitée à %(limit)s. Vous pouvez supprimer cette limite dans les paramètres de préférence.',
        speed_constrain_warning_curve_engraving: 'La vitesse de gravure des objets courbes sera limitée à %(limit)s. Vous pouvez supprimer cette limite dans les paramètres de préférences.',
        stepwise_focusing: 'Mise au Point Progressive',
        stepwise_focusing_desc: "Abaissement progressif de la distance de mise au point en fonction de la hauteur de l'objet à chaque nombre de passes.",
        strength: 'Puissance',
        times: 'fois',
        various_preset: 'Divers préréglages',
        white_ink: 'Encre blanche',
        white_ink_settings: 'Paramètres encre blanche',
        wobble: 'Oscillation',
        wobble_desc: 'Crée de petits cercles le long du chemin, ce qui est bénéfique pour les tâches de découpe. (Pour les objets de chemin uniquement.)',
        wobble_diameter: 'Diamètre',
        wobble_step: 'Taille de pas',
        z_step: 'Pas en Z',
      },
      layer_panel: {
        current_layer: 'Calque actuel',
        layer1: 'Calque 1',
        layer_bitmap: 'Bitmap',
        layer_cutting: 'Découpe',
        layer_engraving: 'Gravure',
        layers: {
          del: 'Supprimer le calque',
          dupe: 'Dupliquer le calque',
          fullColor: 'Couleur complète',
          layer: 'Calque',
          layers: 'Calques',
          lock: 'Verrouiller la couche',
          merge_all: 'Fusionner tout',
          merge_down: 'Fusionner vers le bas',
          merge_selected: 'Fusionner les couches sélectionnées',
          move_down: 'Déplacer le calque vers le bas',
          move_elems_to: 'Déplacer les éléments vers :',
          move_selected: 'Déplacer les éléments sélectionnés vers une autre couche',
          move_up: 'Déplacer le calque vers le haut',
          new: 'Nouveau calque',
          rename: 'Renommer le calque',
          splitFullColor: 'Décomposer la couche',
          switchToFullColor: 'Passer en couche couleur complète',
          switchToSingleColor: 'Passer en couche couleur unie',
          unlock: 'Déverrouiller',
        },
        move_elems_to: 'Déplacer les éléments vers :',
        notification: {
          dupeLayerName: 'Il existe déjà un calque avec ce nom !',
          enterNewLayerName: 'Veuillez saisir le nouveau nom de calque',
          enterUniqueLayerName: 'Veuillez saisir un nom de calque unique',
          layerHasThatName: 'Le calque a déjà ce nom',
          mergeLaserLayerToPrintingLayerMsg: 'Veuillez noter que si vous terminez cette opération, les paramètres du calque laser seront supprimés et définis en fonction du calque actuel.',
          mergeLaserLayerToPrintingLayerTitle: "Voulez-vous fusionner ces calques en un seul calque d'impression ?",
          mergePrintingLayerToLaserLayerMsg: "Veuillez noter que si vous terminez cette opération, les paramètres de couleur du calque d'impression seront supprimés et définis en fonction du calque actuel.",
          mergePrintingLayerToLaserLayerTitle: 'Voulez-vous fusionner ces calques en un seul calque laser ?',
          moveElemFromPrintingLayerMsg: "Veuillez noter que si vous terminez cette opération, les paramètres de couleur de l'élément sélectionné seront supprimés et définis en fonction de %s.",
          moveElemFromPrintingLayerTitle: "Déplacer l'élément sélectionné vers %s et le convertir en élément laser ?",
          moveElemToPrintingLayerMsg: "Veuillez noter que si vous terminez cette opération, les paramètres de l'élément sélectionné seront supprimés et définis en fonction de %s.",
          moveElemToPrintingLayerTitle: "Déplacer l'élément sélectionné vers %s et le convertir en élément d'impression ?",
          newName: 'NOUVEAU NOM',
          QmoveElemsToLayer: 'Déplacer les éléments sélectionnés vers le calque « %s » ?',
          splitColorMsg: "Veuillez noter que si vous poursuivez cette opération, vous ne pourrez pas revenir aux calques de couleur d'origine.",
          splitColorTitle: 'Voulez-vous décomposer le calque sélectionné en calques CMJN ?',
        },
      },
      object_panel: {
        actions_panel: {
          ai_bg_removal: "Suppression de l'arrière-plan",
          ai_bg_removal_reminder: "L'appui sur le bouton utilisera immédiatement 0,2 crédit, voulez-vous continuer ?",
          ai_bg_removal_short: 'Suppr. arrière-plan',
          array: 'Tableau',
          auto_fit: 'Ajustement Automatique',
          bevel: 'Chanfrein',
          brightness: 'Luminosité',
          convert_to_path: 'Convertir en tracé',
          create_textpath: 'Créer un texte sur un tracé',
          create_textpath_short: 'Texte sur tracé',
          crop: 'Rogner',
          decompose_path: 'Décomposer',
          detach_path: 'Décomposer le texte sur tracé',
          detach_path_short: 'Décomposer',
          disassemble_use: 'Désassembler',
          disassembling: 'Désassemblage en cours...',
          edit_path: 'Modifier le tracé',
          fetching_web_font: 'Récupération de la police en ligne...',
          grading: 'Dégradé',
          invert: 'Inverser',
          offset: 'Décalage',
          outline: 'Contour',
          replace_with: 'Remplacer par...',
          replace_with_short: 'Remplacer',
          sharpen: 'Affûter',
          simplify: 'Simplifier',
          smart_nest: 'Nidification Intelligente',
          trace: 'Tracer',
          ungrouping: 'Dégroupement en cours...',
          uploading_font_to_machine: 'Téléchargement de la police vers la machine...',
          wait_for_parsing_font: 'Analyse de la police... Veuillez patienter une seconde',
          weld_text: 'Souder le texte',
        },
        align: 'Aligner',
        boolean: 'Opérations booléennes',
        bottom_align: 'Aligner en bas',
        center_align: 'Centrer',
        difference: 'Différence',
        distribute: 'Distribuer',
        flip: 'Retourner',
        group: 'Grouper',
        hdist: 'Distribuer horizontalement',
        hflip: 'Retourner horizontalement',
        intersect: 'Intersection',
        left_align: 'Aligner à gauche',
        lock_aspect: 'Verrouiller les proportions',
        middle_align: 'Aligner au milieu',
        option_panel: {
          color: 'Couleur',
          fill: 'Remplissage',
          font_family: 'Police',
          font_size: 'Taille',
          font_style: 'Style',
          letter_spacing: 'Espacement des lettres',
          line_spacing: 'Espacement des lignes',
          path_infill: 'Remplissage du tracé',
          pwm_engraving: 'Mode Profondeur',
          pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
          rounded_corner: 'Coin arrondi',
          shading: 'Ombrage',
          sides: 'Côtés',
          start_offset: 'Décalage du texte',
          stroke: 'Contour',
          stroke_color: 'Couleur de contour',
          stroke_width: 'Largeur de contour',
          text_infill: 'Remplissage du texte',
          threshold: 'Seuil de luminosité',
          threshold_short: 'Seuil',
          vertical_align: 'Alignement',
          vertical_text: 'Texte vertical',
        },
        path_edit_panel: {
          connect: 'Relier',
          delete: 'Supprimer',
          disconnect: 'Déconnecter',
          node_type: 'TYPE DE NOEUD',
          round: 'Arrondi',
          sharp: 'Angle vif',
        },
        right_align: 'Aligner à droite',
        subtract: 'Soustraire',
        top_align: 'Aligner en haut',
        ungroup: 'Dégrouper',
        union: 'Union',
        unlock_aspect: 'Déverrouiller les proportions',
        vdist: 'Distribuer verticalement',
        vflip: 'Retournement vertical',
        zoom: 'Zoom',
      },
      tabs: {
        layers: 'Calques',
        objects: 'Objets',
        path_edit: 'Édition de chemin',
      },
    },
    shapes_panel: {
      animals: 'Animal',
      arrow: 'Flèche',
      basic: 'De base',
      birds: 'Oiseau',
      celebration: 'Célébration',
      circular: 'Circulaire',
      CNY: 'Nouvel an chinois',
      corner: 'Coin',
      decor: 'Décor',
      easter: 'Pâques',
      elements: 'Éléments',
      environment: 'Environnement',
      graphics: 'Graphiques',
      halloween: 'Halloween',
      holidays: 'Vacances',
      label: 'Étiquette',
      land: 'Terre',
      line: 'Ligne',
      nature: 'Nature',
      photo: 'Photo',
      plants: 'Plantes',
      ribbon: 'Ruban',
      sea: 'Mer',
      shape: 'Forme',
      speech: 'Discours',
      text: 'Zone de texte',
      title: 'Formes',
      valentines: 'Saint-Valentin',
      weather: 'Météo',
      Xmas: 'Noël',
    },
    svg_editor: {
      unable_to_fetch_clipboard_img: "Échec de la récupération de l'image depuis votre presse-papiers",
      unnsupported_file_type: "Le type de fichier n'est pas directement pris en charge. Veuillez convertir le fichier en SVG ou bitmap",
    },
    tag: {
      g: 'Groupe',
      image: 'Image',
      text: 'Texte',
      use: 'Importer SVG',
    },
    time_est_button: {
      calculate: 'Estimer le temps',
      estimate_time: 'Temps estimé :',
    },
    tool_panels: {
      _nest: {
        end: 'Fermer',
        no_element: "Il n'y a pas d'élément à arranger.",
        rotations: 'Rotation possible',
        spacing: 'Espacement',
        start_nest: 'Arranger',
        stop_nest: 'Arrêter',
      },
      _offset: {
        corner_type: 'Coin',
        direction: 'Direction de décalage',
        dist: 'Distance de décalage',
        fail_message: 'Échec du décalage des objets.',
        inward: "Vers l'intérieur",
        not_support_message: 'Éléments sélectionnés contenant des balises SVG non prises en charge :\nImage, Groupe, Texte et Objet importé.',
        outward: "Vers l'extérieur",
        round: 'Arrondi',
        sharp: 'Pointu',
      },
      array_dimension: 'Dimension du tableau',
      array_interval: 'Intervalle de tableau',
      cancel: 'Annuler',
      columns: 'Cols.',
      confirm: 'Confirmer',
      dx: 'X',
      dy: 'Y',
      grid_array: 'Créer une grille',
      nest: "Optimisation de l'arrangement",
      offset: 'Décalage',
      rows: 'Lignes',
    },
    units: {
      mm: 'mm',
      walt: 'W',
    },
    zoom_block: {
      fit_to_window: 'Ajuster à la fenêtre',
    },
  },
  boxgen: {
    add_option: 'Ajouter option',
    basic_box: 'Boîte basique',
    beam_radius: 'Compensation du faisceau',
    beam_radius_warning: "Supprimez la compensation de la rainure lorsque les bords ou les joints de la boîte sont courts pour assurer l'assemblage de la boîte",
    cancel: 'Annuler',
    coming_soon: 'Bientôt disponible',
    continue_import: 'Continuer à importer',
    control_tooltip: 'Clic gauche pour pivoter\nMolette pour zoomer\nClic droit pour déplacer',
    control_tooltip_touch: 'Glisser pour pivoter\nPincer pour zoomer\nDeux doigts pour déplacer',
    cover: 'Couvercle',
    customize: 'Personnaliser',
    depth: 'Profondeur',
    edge: 'Bord',
    finger: 'Doigt',
    finger_warning: "La longueur intérieure du côté de la boîte doit être d'au moins 6 mm (0,24 pouces) pour être compatible avec l'assemblage par doigt.",
    height: 'Hauteur',
    import: 'Importer',
    inner: 'Intérieur',
    joints: 'Joint',
    max_dimension_tooltip: 'La largeur/hauteur/profondeur maximale est de %s.',
    merge: 'Fusionner',
    outer: 'Extérieur',
    reset: 'Réinitialiser',
    tCount: 'Nombre de T',
    tCount_tooltip: "Le nombre de rainures en T s'applique au côté court ; la quantité sur le côté long est calculée en fonction de sa longueur.",
    tDiameter: 'Diamètre de T',
    text_label: 'Étiquette',
    thickness: 'Épaisseur',
    title: 'BOXGEN',
    tLength: 'Longueur de T',
    tSlot: 'Rainure en T',
    tSlot_warning: "La longueur du côté de la boîte doit être d'au moins 30 mm (1,18 pouce) pour être compatible avec l'assemblage par rainure en T.",
    volume: 'Volume',
    width: 'Largeur',
    workarea: 'Zone de travail',
    zoom: 'Zoom',
  },
  buttons: {
    back: 'Retour',
    back_to_beam_studio: 'Retourner à Beam Studio',
    done: 'Terminé',
    next: 'Suivant',
  },
  calibration: {
    ador_autofocus_focusing_block: "Appuyez sur l'icône « AF » sur la page principale de la machine pendant 3 secondes et laissez la sonde toucher le bloc de mise au point.",
    ador_autofocus_material: "Appuyez sur l'icône « AF » sur la page principale de la machine pendant 3 secondes et laissez la sonde toucher doucement le matériau.",
    align_ilb: 'Position: Alignez avec le point gravé en bas à gauche intérieur.',
    align_ilt: 'Position: Alignez avec le point gravé en haut à gauche intérieur.',
    align_irb: 'Position: Alignez avec le point gravé en bas à droite intérieur.',
    align_irt: 'Position: Alignez avec le point gravé en haut à droite intérieur.',
    align_olb: 'Position: Alignez avec le point gravé en bas à gauche extérieur.',
    align_olt: 'Position: Alignez avec le point gravé en haut à gauche extérieur.',
    align_orb: 'Position: Alignez avec le point gravé en bas à droite extérieur.',
    align_ort: 'Position: Alignez avec le point gravé en haut à droite extérieur.',
    align_red_cross_cut: 'Veuillez aligner le milieu de la croix rouge avec la croix de coupe.',
    align_red_cross_print: 'Veuillez aligner le milieu de la croix rouge avec la croix imprimée.',
    analyze_result_fail: "Échec de l'analyse de l'image capturée. <br/> Veuillez vous assurer : <br/> 1. L'image capturée couvre entièrement la feuille blanche. <br/> 2. La plateforme est correctement mise au point.",
    ask_for_readjust: "Voulez-vous sauter l'étape de gravure et ensuite prendre une photo et calibrer directement ?",
    back: 'Retour',
    calculating_camera_matrix: 'Calcul de la matrice de caméra...',
    calculating_regression_parameters: 'Calcul des paramètres de régression...',
    calibrate_camera_before_calibrate_modules: "Veuillez effectuer l'étalonnage de la caméra avant d'étalonner les modules.",
    calibrate_chessboard_success_msg: 'La photo de l’échiquier a été capturée avec succès.<br/>Le score pour cette photo est %s (%.2f).',
    calibrate_done: "Étalonnage terminé. Une meilleure précision de l'appareil photo est obtenue lorsque la mise au point est précise.",
    calibrate_done_diode: 'Étalonnage terminé. Le décalage du module laser à diode a été enregistré.',
    calibrating: 'Calibrage...',
    calibrating_with_device_pictures: "Calibration avec les images de l'appareil en cours...",
    camera_calibration: 'Étalonnage de la caméra',
    camera_parameter_saved_successfully: "Paramètres de l'appareil photo enregistrés avec succès.",
    cancel: 'Annuler',
    check_checkpoint_data: 'Données de point de contrôle',
    check_device_pictures: "Vérifier les images de l'appareil",
    checking_checkpoint: 'Vérification des données de point de contrôle...',
    checking_pictures: "Vérification des images de l'appareil en cours...",
    diode_calibration: 'Étalonnage du module laser à diode',
    do_engraving: 'Gravure',
    download_chessboard_file: 'Télécharger le fichier échiquier',
    downloading_checkpoint: 'Téléchargement des données du point de contrôle...',
    downloading_pictures: 'Téléchargement des images...',
    drawing_calibration_image: "Dessin de l'image d'étalonnage...",
    dx: 'X',
    dy: 'Y',
    elevate_and_cut: 'Élever et Couper',
    elevate_and_cut_step_1: "Placez un morceau de bois de couleur claire de format A4 au centre de l'espace de travail, surélevé à 20 mm.",
    elevate_and_cut_step_1_prism_lift: "Utilisez Ador Prism Lift avec une longueur maximale de 14 mm avec un morceau de bois d'au moins 6 mm d'épaisseur.",
    failed_to_calibrate_camera: "#848 Échec de l'étalonnage de la caméra, veuillez contacter le support FLUX.",
    failed_to_calibrate_chessboard: "Échec de la calibration avec l'image de l'échiquier.",
    failed_to_calibrate_with_pictures: "#848 Échec de la calibration avec les images de l'appareil.",
    failed_to_download_pictures: '#848 Échec du téléchargement des images, veuillez contacter le support FLUX.',
    failed_to_move_laser_head: 'Échec du déplacement de la tête laser.',
    failed_to_parse_checkpoint: "Impossible d'analyser les données du point de contrôle.",
    failed_to_save_calibration_results: "#849 Échec de l'enregistrement des résultats d'étalonnage, veuillez réessayer. Si cela se reproduit, veuillez contacter le support FLUX.",
    failed_to_save_camera_parameter: "Échec de l'enregistrement des paramètres de l'appareil photo.",
    failed_to_solve_pnp: 'Échec de la résolution de la position de la caméra.',
    finish: 'Terminer',
    found_checkpoint: 'Les données du point de contrôle ont été trouvées sur votre appareil. Souhaitez-vous les récupérer à partir du point de contrôle?',
    getting_plane_height: 'Obtenir la Hauteur du Plan...',
    hint_adjust_parameters: 'Utilisez ces paramètres pour ajuster le carré rouge',
    hint_red_square: "Veuillez aligner l'extérieur du carré rouge avec le carré découpé.",
    module_calibration_2w_ir: 'Étalonnage du module infrarouge',
    module_calibration_printer: "Étalonnage du module d'impression",
    moving_laser_head: 'Déplacement de la tête laser...',
    next: 'Suivant',
    no_picutre_found: "#846 Votre appareil n'a pas de photos brutes disponibles pour l'étalonnage. Veuillez contacter le support FLUX.",
    perform_autofocus_bb2: 'Veuillez vous rendre au panneau de commande de la machine et appuyez sur AF pour effectuer la mise au point automatique.',
    please_do_camera_calibration_and_focus: {
      beambox: "Lors de l'étalonnage du module laser à diode, une caméra est nécessaire.\nVeuillez vous assurer que la caméra de cette machine a été étalonnée.\nEt veuillez régler la plateforme au point focal (la hauteur de l'acrylique retournée)",
      beamo: "Lors de l'étalonnage du module laser à diode, une caméra est nécessaire.\nVeuillez vous assurer que la caméra de cette machine a été étalonnée.\nEt veuillez régler la tête laser pour se concentrer sur l'objet de gravure (la hauteur de l'acrylique retournée)",
    },
    please_goto_beambox_first: "Veuillez passer en mode gravure (Beambox) afin d'utiliser cette fonctionnalité.",
    please_place_dark_colored_paper: 'Veuillez placer une feuille de papier de couleur foncée de format A4 ou Letter au centre de la zone de travail.',
    please_place_paper: 'Veuillez placer une feuille de papier blanc de format A4 ou Letter dans le coin supérieur gauche de la zone de travail.',
    please_place_paper_center: 'Veuillez placer une feuille de papier blanc de format A4 ou Letter au centre de la zone de travail.',
    please_refocus: {
      beambox: "Veuillez ajuster la plateforme au point focal (la hauteur de l'acrylique retournée)",
      beamo: "Veuillez ajuster la tête laser pour se concentrer sur l'objet de gravure (la hauteur de l'acrylique retournée)",
      beamo_af: "Veuillez double-cliquer sur le bouton latéral de l'accessoire de mise au point automatique et laisser la sonde toucher doucement le matériau.",
      hexa: "Double-cliquez sur le bouton de réglage de la hauteur pour soulever la table en nid d'abeille et faire toucher la sonde au matériau de gravure.",
    },
    preparing_to_take_picture: 'Préparation à la prise de photo...',
    promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    put_chessboard: 'Placer le Damier',
    put_chessboard_1: 'Veuillez vous rendre au panneau de commande de la machine et appuyez sur AF pour effectuer la mise au point automatique.',
    put_chessboard_2: "Veuillez déplacer le papier à damier ou la tête laser jusqu'à ce que l'ensemble du damier soit confirmé dans le cadre rouge de la fenêtre de prévisualisation en direct.",
    put_chessboard_3: "Cliquez avec le bouton droit pour télécharger l'image de l’échiquier à l'emplacement actuel et vérifier sa netteté.",
    put_chessboard_bb2_desc_1: "Téléchargez le fichier d'échiquier suivant et imprimez-le sur une feuille A4 (l'échiquier imprimé doit comporter des cases de 1x1 cm).",
    put_chessboard_bb2_desc_2: "Fixez l'échiquier imprimé sur une planche indéformable, comme de l'acrylique ou du verre, en vous assurant que l'échiquier est lisse et sans plis ni soulèvements.",
    put_chessboard_bb2_desc_3: "Placez la planche avec l'échiquier bien à plat au centre de la zone de travail.",
    put_chessboard_promark_1: 'Ajustez la distance focale à la mise au point appropriée pour la lentille de champ.',
    put_chessboard_promark_2: 'Assurez-vous que le papier quadrillé est bien visible sans éblouissement. Cliquez sur "Suivant" pour prendre une photo.',
    put_chessboard_promark_desc_1: "Veuillez utiliser le papier quadrillé fourni dans la boîte d'accessoires ou imprimez le motif de quadrillage suivant sur du papier A4 pour l'étalonnage de la caméra.",
    put_chessboard_promark_desc_2: 'Veuillez placer le damier à plat et au centre de la plateforme de travail.',
    put_paper: 'Mettre du papier',
    put_paper_promark_1: "Placez le carton noir de la boîte d'accessoires sur la plateforme de travail.",
    put_paper_promark_2: 'Ajustez la mise au point correctement, puis cliquez sur "Commencer Gravure" pour continuer la gravure.',
    put_paper_skip: 'Si le dessin de calibration ne passe pas automatiquement à la prise de photo, appuyez sur « Ignorer » pour prendre une photo.',
    put_paper_step1: 'Veuillez placer une feuille de papier blanc de format A4 ou Lettre au centre de la zone de travail.',
    put_paper_step2: "Fixez les quatre coins du papier pour qu'il soit bien plat.",
    put_paper_step3: 'Cliquez sur "Commencer Gravure".',
    res_average: 'Moyen',
    res_excellent: 'Excellent',
    res_poor: 'Médiocre',
    retake: 'Reprendre la photo',
    rotation_angle: 'Rotation',
    show_last_config: 'Afficher le dernier résultat',
    skip: 'Sauter',
    solve_pnp_step1: 'Veuillez aligner les points de gravure selon le numéro et la position attendue de chaque point marqueur rouge.',
    solve_pnp_step2: 'Vous pouvez appuyer sur "Reprendre la photo" pour réaligner ou ajuster manuellement les positions des marqueurs.',
    solve_pnp_step3: 'Si l\'image capturée ne correspond pas à la scène réelle, veuillez zoomer ou déplacer l\'écran pour ajuster le point de calibration sur la carte de calibration noire au centre, puis essayez de "Reprendre la photo".',
    solve_pnp_title: 'Aligner les points de repère',
    start_engrave: 'Commencer Gravure',
    start_printing: 'Commencer Impression',
    taking_picture: 'Prise de photo...',
    unable_to_load_camera_parameters: "#851 Aucun paramètre de calibration de la caméra n'est disponible sur votre appareil. Allez dans 'Calibration' > 'Calibrer la caméra (Avancé)' pour terminer la calibration et obtenir les paramètres.",
    update_firmware_msg1: 'Votre micrologiciel ne prend pas en charge cette fonctionnalité. Veuillez mettre à jour le micrologiciel en v',
    update_firmware_msg2: 'ou supérieur pour continuer。 (Menu > Machine > [Votre machine] > Mettre à jour le micrologiciel)',
    uploading_images: 'Téléversement des images...',
    use_last_config: "Utiliser la dernière valeur d'étalonnage",
    use_old_camera_parameter: "Voulez-vous utiliser les paramètres actuels de l'objectif de l'appareil photo?",
    with_af: 'Avec mise au point automatique',
    with_prism_lift: 'Avec Ador Prism Lift',
    without_af: 'Sans mise au point automatique',
    without_prism_lift: 'Sans Ador Prism Lift',
    x_ratio: 'Ratio X',
    y_ratio: 'Ratio Y',
    zendesk_link: 'https://support.flux3dp.com/hc/en-us-fr/articles/360001811416',
  },
  camera_data_backup: {
    download_success: 'Données de la caméra téléchargées avec succès.',
    downloading_data: 'Téléchargement des données...',
    estimated_time_left: 'Temps estimé restant :',
    folder_not_exists: "Le dossier sélectionné n'existe pas.",
    incorrect_folder: 'Échec du téléversement des données de calibration. Veuillez vérifier si le dossier que vous avez sélectionné est correct.',
    no_picture_found: 'Aucune image trouvée dans la machine.',
    title: 'Sauvegarde des données de la caméra',
    upload_success: 'Données de la caméra téléchargées avec succès.',
    uploading_data: 'Chargement des données...',
  },
  caption: {
    connectionTimeout: 'Délai de connexion',
  },
  change_logs: {
    added: 'Ajouté :',
    change_log: 'Journaux de modifications :',
    changed: 'Modifié :',
    fixed: 'Corrigé :',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    see_older_version: 'Voir les versions précédentes',
  },
  code_generator: {
    barcode: 'Code-barres',
    qr_code: 'Code QR',
  },
  curve_engraving: {
    '921': 'Échec de la mise au point automatique.',
    '922': 'Échec de la mesure avec lumière rouge.',
    amount: 'Quantité',
    apply_arkwork: 'Appliquer l’œuvre sur la courbe',
    apply_camera: 'Appliquer l’image de la caméra sur la courbe',
    click_to_select_point: 'Cliquez pour sélectionner ou désélectionner les points à mesurer à nouveau.',
    coloumns: 'Colonnes',
    column_gap: 'Écart entre les colonnes',
    err_object_over_range: "L'objet dépasse la plage de mesure.",
    failed_to_take_reference: 'Échec de la prise de référence',
    gap: 'Écart',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    measure_audofocus_area: 'Mesurer la zone de mise au point automatique',
    preview_3d_curve: 'Aperçu de la gravure de courbe',
    remeasure: 'Re-mesurer',
    remeasuring_points: 'Remesure des points...',
    reselect_area: 'Re-sélectionner la zone',
    row_gap: 'Écart entre les rangées',
    rows: 'Rangées',
    set_object_height: "Définir la hauteur de l'objet",
    set_object_height_desc: "Mesurez l'épaisseur maximale de l'objet.",
    start_autofocus: 'Démarrer la mise au point automatique',
    starting_measurement: 'Démarrage de la mesure...',
    sure_to_delete: 'Voulez-vous supprimer les données de mise au point de la gravure sur courbe ?',
    take_reference: 'Prendre référence',
    take_reference_desc: 'Veuillez déplacer la tête laser au point le plus haut de l’objet, abaisser la sonde de mise au point et cliquer sur « Confirmer » pour faire la mise au point.',
  },
  device: {
    abort: 'Abandonner',
    aborted: 'Abandonné',
    aborting: 'Abandon',
    busy: 'Occupé',
    cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
    cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
    cartridge_serial_number: 'Ink cartridge serial number',
    close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
    completed: 'Terminé',
    completing: 'Finalisation',
    deviceList: 'Liste appareils',
    disable: 'Désactiver',
    disconnectedError: {
      caption: 'Machine déconnectée',
      message: "Veuillez confirmer si l'accès réseau de %s est disponible",
    },
    enable: 'Activer',
    firmware_version: 'Version micrologiciel',
    ink_color: 'Ink color',
    ink_level: 'Ink level',
    ink_type: 'Ink type',
    IP: 'IP',
    model_name: 'Nom du modèle',
    occupied: 'Maintenance',
    pause: 'Mettre en pause',
    paused: 'En pause',
    pausedFromError: 'Interrompu suite à une erreur',
    pausing: 'Mise en pause',
    please_wait: 'Veuillez patienter...',
    preparing: 'Préparation',
    processing: 'Traitement',
    quit: 'Quitter',
    ready: 'Prêt',
    reset: 'Réinitialiser',
    resuming: 'Reprise',
    retry: 'Réessayer',
    running: 'Fonctionnement',
    scanning: 'Analyse',
    select: 'Sélectionner',
    select_printer: "Sélectionner l'imprimante",
    serial_number: 'Numéro de série',
    start: 'Démarrer',
    starting: 'Démarrage',
    status: 'Statut',
    submodule_type: 'Module',
    toolhead_change: "Changer la tête de l'outil",
    unknown: 'Inconnu',
    uploading: 'Téléversement',
    UUID: 'UUID',
  },
  device_selection: {
    no_beambox: '#801 Nous ne pouvons pas trouver votre machine sur le réseau.\nVeuillez suivre le <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">guide</a> pour résoudre le problème de connexion.',
    no_device_web: "#801 Vérifiez l'état de votre machine, ou cliquez sur 'Configuration de la machine' ci-dessous pour configurer la machine.",
    select_usb_device: 'Sélectionner un périphérique USB',
  },
  editor: {
    exposure: "Aperçu de l'exposition",
    opacity: "Opacité de l'aperçu",
    prespray_area: 'Zone de pré-pulvérisation',
  },
  error_pages: {
    screen_size: "Veuillez noter que Beam Studio peut ne pas fonctionner de manière optimale sur votre appareil. Pour une expérience optimale, il devrait s'agir d'un appareil avec une largeur d'écran d'au moins 1024 pixels.",
  },
  flux_id_login: {
    connection_fail: '#847 Échec de la connexion au service de membres FLUX.',
    email: 'E-mail',
    flux_plus: {
      access_monotype_feature: "Vous n'avez pas le module complémentaire Polices Monotype.",
      access_monotype_feature_note: 'Vous devez être membre de FLUX+ Pro ou avoir le module complémentaire Polices Monotype pour accéder à cette fonctionnalité.',
      access_plus_feature_1: 'Vous accédez à une',
      access_plus_feature_2: 'fonctionnalité.',
      access_plus_feature_note: 'Vous devez être membre de FLUX+ pour accéder à cette fonctionnalité.',
      ai_credit_tooltip: "Pour la suppression de l'arrière-plan par IA",
      explore_plans: 'Explorez les forfaits FLUX+',
      features: {
        ai_bg_removal: "Suppression de l'arrière-plan par IA",
        boxgen: 'Générateur de boîtes 3D',
        dmkt: '1000+ fichiers de conception',
        monotype: '250+ polices premium',
        my_cloud: 'Stockage cloud illimité',
      },
      flux_credit_tooltip: "Pour les fichiers Design Market et la suppression de l'arrière-plan par IA",
      get_addon: 'Obtenir le module',
      goto_member_center: 'Accédez à votre espace membre',
      learn_more: 'En savoir plus',
      member_center_url: 'https://member.flux3dp.com/en-US/subscription',
      subscribe_now: 'Abonnez-vous maintenant',
      thank_you: "Merci d'être un membre précieux !",
      website_url: 'https://flux3dp.com/subscription',
    },
    forget_password: 'Mot de passe oublié ?',
    incorrect: "L'adresse e-mail ou le mot de passe est incorrect.",
    login: 'Connexion',
    login_success: 'Connexion réussie.',
    lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
    new_to_flux: 'Nouveau chez FLUX ? Créez un compte.',
    not_verified: "L'adresse e-mail n'a pas encore été vérifiée.",
    offline: 'Travailler hors ligne',
    password: 'Mot de passe',
    register: 'Créer votre compte FLUX',
    remember_me: 'Se souvenir de moi',
    signup_url: 'https://id.flux3dp.com/user/login#up',
    unlock_shape_library: 'Connectez-vous pour déverrouiller la bibliothèque de formes.',
    work_offline: 'Travailler hors ligne',
  },
  framing: {
    area_check: 'Vérification de la zone',
    areacheck_desc: "Assure la sécurité de la zone de travail en prévisualisant la boîte englobante de l'objet et la zone d'accélération de la tête laser.",
    calculating_task: 'Calcul de la tâche...',
    framing: 'Cadrage',
    framing_desc: "Aperçu de la boîte englobante de l'objet.",
    hull: 'Coque',
    hull_desc: "Affiche un aperçu d'une forme qui suit étroitement le design, comme un élastique enroulé autour de l'objet.",
    low_laser: 'Laser faible',
    low_laser_desc: 'Définissez une valeur de laser à faible puissance pour la tâche de cadrage.',
    rotate_axis: 'Axe de rotation',
    rotateaxis_desc: "Aperçu du centre de l'axe de rotation, l'axe lui-même ne bouge pas.",
    rotation_frame_warning: "L'aperçu du cadre effectuera une rotation complète. Évitez de l'interrompre en cours de route afin de ne pas provoquer un décalage de la position du marquage.",
    rotation_framing_desc: "Aperçu de la gamme complète de rotation, l'axe tourne selon l'aperçu.",
    start_task: 'Démarrer la Tâche',
    start_task_description: 'Après avoir terminé l’aperçu du cadre, cliquez sur "Démarrer la Tâche" pour soumettre le travail.',
  },
  general: {
    choose_folder: 'Choisir un dossier',
    processing: 'Traitement...',
  },
  generic_error: {
    OPERATION_ERROR: "[OE] Un conflit d'état s'est produit, veuillez réessayer l'action.",
    SUBSYSTEM_ERROR: "[SE] Erreur lors de l'exécution de la tâche par le micrologiciel de la machine. Veuillez redémarrer la machine.",
    UNKNOWN_COMMAND: "[UC] Veuillez mettre à jour le micrologiciel de l'appareil",
    UNKNOWN_ERROR: "[UE] Une erreur inconnue s'est produite. Veuillez redémarrer Beam Studio et la machine.",
  },
  global: {
    apply: 'Appliquer',
    back: 'Retour',
    cancel: 'Annuler',
    editing: {
      redo: 'Rétablir',
      reset: 'Réinitialiser',
      undo: 'Annuler',
      zoom_in: 'Agrandir',
      zoom_out: 'Réduire',
    },
    mode_conflict: "Cette option n'est pas disponible dans le mode actuel.",
    ok: 'OK',
    save: 'Enregistrer',
    stop: 'Arrêter',
  },
  image_edit_panel: {
    eraser: {
      brush_size: 'Taille du pinceau',
      description: 'Cliquez ou faites glisser pour effacer manuellement les zones indésirables.',
      title: 'Gomme',
    },
    magic_wand: {
      description: 'Sélectionnez et supprimez les zones adjacentes en fonction de la similarité des couleurs.',
      title: 'Baguette magique',
      tolerance: 'Tolérance',
    },
    title: "Modifier l'image",
  },
  initialize: {
    back: 'Retour',
    cancel: 'Annuler',
    confirm: 'Confirmer',
    connect: 'Se connecter',
    connect_ethernet: {
      title: 'Connexion directe',
      tutorial1: '1. Connectez la machine à votre ordinateur avec un câble Ethernet.',
      tutorial2_1: '2. Suivez ',
      tutorial2_2: 'pour configurer votre ordinateur comme routeur.',
      tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
      tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
      tutorial2_a_text: 'ce guide',
      tutorial3: '3. Cliquez sur Suivant.',
    },
    connect_machine_ip: {
      alert: {
        swiftray_connection_error: 'Impossible de se connecter au serveur. Veuillez redémarrer Beam Studio et réessayer.',
      },
      check_camera: 'Vérification de la disponibilité de la caméra',
      check_connection: 'Vérification de la connexion à la machine',
      check_firmware: 'Vérification de la version du firmware',
      check_ip: "Vérification de la disponibilité de l'IP",
      check_swiftray_connection: 'Vérification de la connexion au serveur',
      check_swiftray_connection_unreachable: 'serveur inaccessible',
      check_usb: 'Vérifier la connexion USB',
      enter_ip: "Entrez l'adresse IP de votre machine",
      finish_setting: 'Commencez à créer !',
      invalid_format: 'Format non valide',
      invalid_ip: 'IP non valide : ',
      promark_hint: 'Si vous ne parvenez pas à vous connecter via USB de manière répétée, consultez l\'<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">article du Centre d\'aide</a>.',
      retry: 'Réessayer',
      starts_with_169254: 'Commence par 169.254',
      succeeded_message: 'Connexion réussie 🎉',
      unreachable: 'IP injoignable',
    },
    connect_usb: {
      connect_camera: 'Connectez la caméra de la machine à votre ordinateur avec un câble USB.',
      title: 'Connexion USB',
      title_sub: ' (HEXA & Ador uniquement)',
      turn_off_machine: 'Éteignez la machine.',
      turn_on_machine: 'Allumez la machine.',
      tutorial1: 'Connectez la machine à votre ordinateur avec un câble USB.',
      tutorial2: 'Cliquez sur "Suivant".',
      wait_for_turning_on: 'Cliquez sur "Suivant" une fois le processus de démarrage terminé et que vous êtes arrivé sur l\'écran principal.',
    },
    connect_wifi: {
      title: 'Connexion au Wi-Fi',
      tutorial1: '1. Accédez au panneau tactile > Cliquez sur "RÉSEAU" > "Se connecter au Wi-Fi".',
      tutorial1_ador: '1. Accédez au panneau tactile > Cliquez sur "MACHINE" > Cliquez sur "RÉSEAU" > "Se connecter au Wi-Fi".',
      tutorial2: '2. Sélectionnez et connectez-vous au Wi-Fi de votre choix.',
      what_if_1: 'Que faire si je ne vois pas mon Wi-Fi ?',
      what_if_1_content: "1. Le chiffrement Wi-Fi doit être WPA2 ou sans mot de passe.\n2. Le chiffrement peut être défini dans l'interface d'administration du routeur Wi-Fi. Si le routeur ne prend pas en charge WPA2 et que vous avez besoin d'aide pour choisir le bon routeur, veuillez contacter le support FLUX.",
      what_if_2: 'Que faire si je ne vois aucun Wi-Fi ?',
      what_if_2_content: "1. Assurez-vous que la clé Wi-Fi est entièrement branchée.\n2. S'il n'y a pas d'adresse MAC du réseau sans fil sur l'écran tactile, veuillez contacter le support FLUX.\n3. Le canal Wi-Fi doit être 2.4Ghz (5Ghz n'est pas pris en charge).",
    },
    connect_wired: {
      title: 'Connexion au réseau filaire',
      tutorial1: '1. Connectez la machine à votre routeur.',
      tutorial2: '2. Appuyez sur "RÉSEAU" pour obtenir l\'IP du réseau filaire.',
      tutorial2_ador: '2. Appuyez sur "MACHINE" > "RÉSEAU" pour obtenir l\'IP du réseau filaire.',
      what_if_1: "Que faire si l'IP est vide ?",
      what_if_1_content: "1. Assurez-vous que le câble Ethernet est entièrement branché.\n2. S'il n'y a pas d'adresse MAC du réseau filaire sur l'écran tactile, veuillez contacter le support FLUX.",
      what_if_2: "Que faire si l'IP commence par 169 ?",
      what_if_2_content: "1. Si l'adresse IP commence par 169.254, il devrait s'agir d'un problème de configuration DHCP, veuillez contacter votre fournisseur d'accès à Internet pour plus d'assistance.\n2. Si votre ordinateur se connecte à Internet directement en utilisant PPPoE, veuillez passer à l'utilisation du routeur pour se connecter en utilisant PPPoE, et activer la fonction DHCP dans le routeur.",
    },
    connecting: 'Connexion en cours...',
    connection_types: {
      ether2ether: 'Connexion directe',
      usb: 'Connexion USB',
      wifi: 'Wi-Fi',
      wired: 'Réseau filaire',
    },
    next: 'Suivant',
    no_machine: "Je n'ai pas de machine pour le moment.",
    promark: {
      configuration_confirmation: 'Cela garantit que votre Promark est correctement configuré pour une efficacité et une précision optimales.',
      or_complete_later: `Ou, ignorez cette étape et complétez les paramètres Promark plus tard à :
    Machines > « Nom Promark » > Paramètres Promark`,
      qc_instructions: 'Remplissez les paramètres au dos de la carte « QC Pass »',
      select_laser_source: 'Sélectionnez votre Promark',
      select_workarea: 'Sélectionnez la zone de travail',
      settings: 'Paramètres Promark',
    },
    retry: 'Réessayer',
    select_beambox: 'Sélectionnez votre Beambox',
    select_connection_type: 'Comment souhaitez-vous vous connecter ?',
    select_language: 'Sélectionnez la langue',
    select_machine_type: 'Sélectionnez votre type de machine',
    setting_completed: {
      back: 'Retour',
      great: 'Bienvenue sur Beam Studio',
      ok: 'COMMENCER À CRÉER',
      setup_later: 'Vous pouvez toujours configurer votre machine depuis la barre de titre > "Machines" > "Configuration de la machine"',
      start: 'Démarrer',
    },
    skip: 'Passer',
    start: 'Démarrer',
  },
  input_machine_password: {
    connect: 'CONNECTER',
    password: 'Mot de passe',
    require_password: '"%s" nécessite un mot de passe',
  },
  insecure_websocket: {
    extension_detected: 'Extension Beam Studio Connect détectée',
    extension_detected_description: "Nous avons détecté que vous avez installé l'extension Beam Studio Connect. Veuillez cliquer sur 'Confirmer' pour rediriger vers HTTPS, ou cliquez sur 'Annuler' pour continuer à utiliser HTTP.",
    extension_not_deteced: "Impossible de détecter l'extension Beam Studio Connect",
    extension_not_deteced_description: "Pour utiliser HTTPS, cliquez sur 'Confirmer' pour installer l'extension Beam Studio Connect. Après avoir installé l'extension, veuillez actualiser la page pour l'activer.<br/>Sinon, cliquez sur le lien ci-dessous pour voir comment utiliser HTTP sur Chrome.",
    unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Lien</a>',
  },
  layer_module: {
    general_laser: 'Laser',
    laser_2w_infrared: 'Laser infrarouge 2W',
    laser_10w_diode: 'Laser diode 10W',
    laser_20w_diode: 'Laser diode 20W',
    non_working_area: 'Zone non-travail',
    none: 'None',
    notification: {
      convertFromLaserModuleMsg: 'Veuillez noter que si vous terminez cette opération, les paramètres de la couche laser seront supprimés et définis en fonction de la couche actuelle.',
      convertFromLaserModuleTitle: 'Voulez-vous convertir le module Laser en module Impression ?',
      convertFromPrintingModuleMsg: "Veuillez noter que si vous terminez cette opération, les paramètres de couleur de la couche d'impression seront supprimés et définis en fonction de la couche actuelle.",
      convertFromPrintingModuleTitle: 'Voulez-vous convertir le module Impression en module Laser ?',
      importedDocumentContainsPrinting: "Le document contient une couche d'impression, souhaitez-vous changer l'espace de travail en Ador ?",
      performIRCaliMsg: "Cliquez sur « Confirmer » pour exécuter l'étalonnage ou accédez à l'étalonnage via le menu supérieur. <br /> (Machine > [Nom de votre machine] > Étalonner le module infrarouge)",
      performIRCaliTitle: "Effectuez l'étalonnage du module infrarouge",
      performPrintingCaliMsg: "Cliquez sur « Confirmer » pour exécuter l'étalonnage ou accédez à l'étalonnage via le menu supérieur. <br /> (Machine> [Nom de votre machine]> Étalonner le module d'impression)",
      performPrintingCaliTitle: "Effectuez l'étalonnage du module d'impression",
      printingLayersCoverted: "Les couches d'impression ont été converties en couches laser.",
    },
    printing: 'Impression',
    unknown: 'Unknown Module',
  },
  machine_status: {
    '-17': 'Mode E/S de cartouche',
    '-10': 'Mode maintenance',
    '-2': 'Numérisation',
    '-1': 'Maintenance',
    '0': 'Inactif',
    '1': 'Initialisation',
    '2': 'ST_TRANSFORM',
    '4': 'Démarrage',
    '6': 'Reprise',
    '16': 'Travail',
    '18': 'Reprise',
    '32': 'En pause',
    '36': 'En pause',
    '38': 'En pause',
    '48': 'En pause',
    '50': 'En pause',
    '64': 'Terminé',
    '66': 'Finalisation en cours',
    '68': 'Préparation',
    '128': 'Abandonné',
    '256': 'Alarme',
    '512': 'Fatal',
    UNKNOWN: 'Inconnu',
  },
  material_test_generator: {
    block_settings: 'Paramètres du Bloc',
    columns: 'Colonnes',
    count: 'Quantité',
    cut: 'Couper',
    engrave: 'Graver',
    export: 'Exporter',
    max: 'Max',
    min: 'Min',
    parameter: 'Paramètre',
    preview: 'Aperçu',
    rows: 'Lignes',
    size: 'Taille (HxL)',
    spacing: 'Espacement',
    table_settings: 'Paramètres du Tableau',
    text_settings: 'Paramètres texte',
    title: 'Générateur de Test de Matériau',
  },
  menu: {
    inches: 'Pouces',
    mm: 'mm',
  },
  message: {
    auth_error: "#820 Erreur d'authentification : veuillez mettre à jour Beam Studio et le firmware de la machine vers la dernière version.",
    authenticating: 'Authentification en cours...',
    camera: {
      abort_preview: 'Abandonner',
      camera_cable_unstable: "Il est détecté que la caméra transfère des photos de manière instable. L'aperçu peut encore être effectué normalement, mais il peut y avoir un problème d'aperçu lent ou de dépassement de délai.",
      continue_preview: 'Continuer',
      fail_to_transmit_image: '#845 Quelque chose s\'est mal passé avec la transmission de l\'image. Veuillez redémarrer votre machine ou Beam Studio. Si cette erreur persiste, veuillez suivre <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">ce guide</a>.',
      ws_closed_unexpectly: '#844 La connexion à la caméra de la machine a été fermée de manière inattendue. Si cette erreur persiste, veuillez suivre <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">ce guide</a>.',
    },
    cancelling: 'Annulation...',
    cant_connect_to_device: '#827 Impossible de se connecter à la machine, veuillez vérifier votre connexion',
    connected: 'Connecté',
    connecting: 'Connexion en cours...',
    connectingCamera: 'Connexion de la caméra...',
    connectingMachine: 'Connexion de %s en cours...',
    connectionTimeout: "#805 Délai de connexion du périphérique. Veuillez vérifier l'état de votre réseau et le voyant Wi-Fi de votre machine.",
    device_blocked: {
      caption: 'Numéro de série non autorisé',
      offline: "Le numéro de série de votre appareil est limité à une utilisation hors ligne car il semble être désactivé. Veuillez fournir le numéro de série de la machine (situé à l'arrière de l'appareil) à votre revendeur et demandez-lui de contacter support@flux3dp.com pour activer l'autorisation en ligne. Si vous souhaitez utiliser l'appareil hors ligne, veuillez contacter directement support@flux3dp.com.",
      online: "Le numéro de série de votre appareil semble être désactivé. Veuillez fournir le numéro de série de la machine (situé à l'arrière de l'appareil) à votre revendeur et demandez-lui de contacter support@flux3dp.com pour activer l'autorisation de l'appareil.",
    },
    device_busy: {
      caption: 'Machine occupée',
      message: 'La machine exécute une autre tâche, réessayez plus tard. Si elle ne fonctionne plus, veuillez redémarrer la machine.',
    },
    device_is_used: 'La machine est utilisée, voulez-vous annuler la tâche en cours ?',
    device_not_found: {
      caption: 'Machine par défaut introuvable',
      message: '#812 Veuillez vérifier le voyant Wi-Fi de votre machine',
    },
    disconnected: "Connexion instable, veuillez vérifier la connexion de l'appareil et réessayer plus tard",
    endingLineCheckMode: 'Sortie du mode de vérification de ligne...',
    endingRawMode: 'Sortie du mode brut...',
    enteringLineCheckMode: 'Accès au mode de vérification de ligne...',
    enteringRawMode: 'Accès au mode brut...',
    enteringRedLaserMeasureMode: 'Entrée en mode de mesure au laser rouge...',
    exitingRotaryMode: 'Sortie du mode rotatif...',
    getProbePosition: 'Obtention de la position de la sonde...',
    gettingLaserSpeed: 'Lecture de la vitesse de la tête laser...',
    homing: 'Homing...',
    need_password: 'Mot de passe requis pour se connecter à la machine',
    please_enter_dpi: "Veuillez entrer l'unité de votre fichier (en mm)",
    preview: {
      adjust: 'Ajuster',
      adjust_height_tooltip: "Cliquez sur la case à cocher pour activer l'édition.",
      already_performed_auto_focus: 'Vous avez déjà effectué la mise au point automatique, utiliser les valeurs existantes ?',
      auto_focus: 'Mise au point automatique',
      auto_focus_instruction: "Veuillez déplacer la tête du module laser au-dessus de l'objet et suivre les instructions d'animation pour appuyer sur AF pour effectuer la mise au point.",
      camera_preview: 'Aperçu de la caméra',
      enter_manually: 'Entrer manuellement',
      please_enter_height: "Veuillez entrer la hauteur de l'objet. Afin de capturer un cliché de caméra précis.",
    },
    promark_disconnected: "#850 Connexion de l'appareil interrompue, veuillez vérifier l'état de connexion de l'appareil.",
    redLaserTakingReference: 'Prise de référence...',
    retrievingCameraOffset: 'Lecture du décalage de la caméra...',
    settingLaserSpeed: 'Réglage de la vitesse de la tête laser...',
    swiftray_disconnected: 'Impossible de se connecter au backend, tentative de reconnexion en cours.',
    swiftray_reconnected: "Backend reconnecté, veuillez réessayer d'envoyer la tâche.",
    time_remaining: 'Temps restant:',
    tryingToConenctMachine: 'Tentative de connexion à la machine...',
    turningOffAirPump: 'Arrêt de la pompe à air...',
    turningOffFan: 'Arrêt du ventilateur...',
    unable_to_find_machine: 'Impossible de trouver la machine',
    unable_to_start: '#830 Impossible de démarrer la tâche. Veuillez réessayer. Si cela se reproduit, veuillez nous contacter avec un rapport de bug :',
    unavailableWorkarea: '#804 La zone de travail actuelle dépasse la zone de travail de cette machine. Veuillez vérifier la zone de travail de la machine sélectionnée ou définir la zone de travail à partir de Edition > Paramètres du document.',
    unknown_device: "#826 Impossible de se connecter à la machine, assurez-vous que l'USB est connecté à la machine",
    unknown_error: "#821 L'application a rencontré une erreur inconnue, veuillez utiliser Aide > Menu > Rapport de bug.",
    unsupport_osx_version: 'La version actuelle de macOS %s peut ne pas prendre en charge certaines fonctions. Veuillez mettre à jour vers macOS 11+.',
    unsupport_win_version: 'La version actuelle de %s peut ne pas prendre en charge certaines fonctions. Veuillez mettre à jour vers la dernière version.',
    unsupported_example_file: "Le fichier d'exemple sélectionné n'est pas pris en charge par la zone de travail actuelle.",
    uploading_fcode: 'Téléversement du code F',
    usb_unplugged: 'La connexion USB est perdue. Veuillez vérifier votre connexion USB',
    wrong_swiftray_version_message: "Version backend incorrecte (version: {version}). Veuillez vérifier si plusieurs instances de Beam Studio sont en cours d'exécution et fermer les instances supplémentaires avant de réessayer.",
    wrong_swiftray_version_title: 'Version backend incorrecte',
  },
  monitor: {
    ask_reconnect: 'La connexion avec la machine a été interrompue. Voulez-vous vous reconnecter ?',
    bug_report: 'Rapport de bug',
    camera: 'Caméra',
    cancel: 'Annuler',
    confirmFileDelete: 'Êtes-vous sûr de vouloir supprimer ce fichier ?',
    connecting: 'Connexion en cours, veuillez patienter...',
    DEVICE_ERROR: "Quelque chose s'est mal passé\\nVeuillez redémarrer la machine",
    download: 'Télécharger',
    extensionNotSupported: "Ce format de fichier n'est pas pris en charge",
    fileExistContinue: 'Le fichier existe déjà, voulez-vous le remplacer ?',
    forceStop: 'Voulez-vous annuler la tâche en cours ?',
    go: 'Démarrer',
    HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: "#913 Débit d'air anormal",
    HARDWARE_ERROR_BOTTOM_OPENED: '#903 Fond ouvert. Fermez le fond pour continuer.',
    HARDWARE_ERROR_DOOR_OPENED: '#901 Fermez la porte pour continuer.',
    HARDWARE_ERROR_DRAWER_OPENED: '#911 Tiroir ouvert',
    HARDWARE_ERROR_FIRE_DETECTED: '#912 Détection de flamme anormale',
    HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Module incorrect détecté. Veuillez installer le bon module pour continuer.',
    HARDWARE_ERROR_HEADTYPE_NONE: '#917 Aucun module détecté. Assurez-vous que le module est correctement installé pour continuer.',
    HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Module inconnu détecté. Veuillez installer le bon module pour continuer.',
    HARDWARE_ERROR_HOMING_PULLOFF_FAILED: "#920 Erreur de retrait lors de l'homing",
    HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Erreur critique : Carte mère hors ligne. Veuillez contacter le support FLUX.',
    HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Surchauffe. Veuillez attendre quelques minutes.',
    HARDWARE_ERROR_PLATFORM_HOMING_FAILED: "#910 Échec de la réinitialisation de l'axe Z",
    HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Aucune réponse du module imprimante.',
    HARDWARE_ERROR_PROBE_SHOWED: 'Veuillez rétracter la sonde.',
    HARDWARE_ERROR_PUMP_ERROR: "#900 Veuillez vérifier votre réservoir d'eau.",
    HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Aucun module rotatif détecté',
    HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Erreur critique : Sous-système sans réponse. Veuillez contacter le support FLUX.',
    HARDWARE_FAILURE: "Quelque chose s'est mal passé\\nVeuillez redémarrer la machine",
    hour: 'h',
    left: 'gauche',
    MAINBOARD_OFFLINE: '#905 Erreur de connexion à la carte mère.\\nVeuillez redémarrer la machine.',
    minute: 'min',
    monitor: 'SURVEILLER',
    NO_RESPONSE: '#905 Erreur de connexion à la carte mère.\\nVeuillez redémarrer la machine.',
    pause: 'Mettre en pause',
    prepareRelocate: 'Préparation pour repositionnement',
    processing: 'Traitement en cours',
    record: 'Enregistrer',
    relocate: 'Repositionner',
    RESOURCE_BUSY: 'La machine est occupée. \nSi elle ne fonctionne pas, veuillez redémarrer la machine',
    resume: 'Reprendre',
    savingPreview: 'Génération de miniatures',
    second: 's',
    start: 'Démarrer',
    stop: 'Arrêter',
    SUBSYSTEM_ERROR: "#402 Erreur critique : aucune réponse du sous-système. Veuillez contacter l'assistance FLUX.",
    task: {
      BEAMBOX: 'Gravure laser',
      'N/A': 'Mode libre',
    },
    taskTab: 'Tâche',
    temperature: 'Température',
    upload: 'Téléverser',
    USER_OPERATION: "Veuillez suivre les instructions sur le panneau de l'appareil pour continuer.",
    USER_OPERATION_CHANGE_CARTRIDGE: 'Veuillez insérer la cartouche correcte pour continuer.',
    USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Veuillez insérer la cartouche pour continuer.',
    USER_OPERATION_CHANGE_TOOLHEAD: 'Veuillez installer le bon module pour continuer.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Module incorrect détecté. Veuillez installer le bon module pour continuer.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Aucun module détecté. Assurez-vous que le module est correctement installé pour continuer.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Module inconnu détecté. Veuillez installer le bon module pour continuer.',
    USER_OPERATION_REMOVE_CARTRIDGE: 'Veuillez retirer la cartouche pour continuer.',
    USER_OPERATION_ROTARY_PAUSE: 'Veuillez passer au moteur rotatif.',
  },
  my_cloud: {
    action: {
      confirmFileDelete: 'Êtes-vous sûr de vouloir supprimer ce fichier ? Cette action est irréversible.',
      delete: 'Supprimer',
      download: 'Télécharger',
      duplicate: 'Dupliquer',
      open: 'Ouvrir',
      rename: 'Renommer',
    },
    file_limit: 'Fichier gratuit',
    loading_file: 'Chargement...',
    no_file_subtitle: 'Accédez au menu > "Fichier" > "Enregistrer dans le cloud"',
    no_file_title: 'Enregistrez des fichiers dans Mon cloud pour commencer.',
    save_file: {
      choose_action: 'Enregistrer le fichier :',
      input_file_name: 'Entrer le nom du fichier :',
      invalid_char: 'Caractères non valides :',
      save: 'Enregistrer',
      save_new: 'Enregistrer sous',
      storage_limit_exceeded: "Votre stockage cloud a atteint la limite supérieure. Veuillez supprimer les fichiers inutiles avant d'en sauvegarder de nouveaux.",
    },
    sort: {
      a_to_z: 'Nom : A - Z',
      most_recent: 'Plus récent',
      oldest: 'Plus ancien',
      z_to_a: 'Nom : Z - A',
    },
    title: 'Mon cloud',
    upgrade: 'Mettre à niveau',
  },
  noun_project_panel: {
    clear: 'Effacer',
    elements: 'Éléments',
    enjoy_shape_library: 'Profitez de la base de données de formes.',
    export_svg_title: "Impossible d'exporter le SVG",
    export_svg_warning: "Ce projet contient des objets protégés par les droits d'auteur. Par conséquent, Beam Studio exclura automatiquement ces objets lors de l'exportation. Vous pouvez toujours enregistrer votre projet au format Beam Studio Scene (.beam) pour conserver toutes vos données. Voulez-vous toujours exporter ?",
    learn_more: 'En savoir plus',
    login_first: 'Connectez-vous pour déverrouiller la base de données de formes.',
    recent: 'Récent',
    search: 'Rechercher',
    shapes: 'Formes',
  },
  pass_through: {
    export: 'Exporter vers la Zone de Travail',
    exporting: 'Exportation...',
    guide_mark: 'Marque de Guidage',
    guide_mark_desc: "Les marques de guidage seront gravées comme point de référence pour aligner l'œuvre d'art.",
    guide_mark_length: 'Longueur:',
    guide_mark_x: 'Coordonnée X:',
    height_desc: 'Réglez la hauteur de chaque section de la zone de travail.',
    help_links: {
      ado1: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
      fbb2: 'https://support.flux3dp.com/hc/en-us/articles/11570021253263',
    },
    help_text: 'Comment configurer le passage direct pour %(model)s ?',
    object_length: "Longueur de l'Objet",
    ref_layer: 'Couche de Référence',
    ref_layer_desc: "Veuillez noter que l'exécution de la couche de référence est par défaut définie sur 0. Elle ne sera pas exécutée mais est uniquement destinée à la référence d'alignement.",
    ref_layer_name: 'Référence',
    title: 'Mode passage direct',
    workarea_height: 'Zone de Travail (Hauteur):',
  },
  promark_connection_test: {
    description: 'Le système effectuera une opération de lumière rouge pendant environ deux minutes pour vérifier si la connexion est stable.',
    health: 'Stabilité:',
    healthy_description: 'La stabilité est bonne, mais évitez de déplacer l’ordinateur de manière significative pendant la gravure.',
    res_0: 'Excellent',
    res_1: 'Bon',
    res_2: 'Faible',
    res_3: 'Trop faible',
    res_4: 'Très faible',
    restart: 'Redémarrer le test',
    start: 'Démarrer le test',
    stop: 'Arrêter le test',
    title: 'Test de stabilité de connexion',
    unhealthy_description: 'La stabilité est trop faible. Il est recommandé de remplacer l’adaptateur réseau ou le câble d’alimentation.',
  },
  promark_settings: {
    angle: 'Angle',
    bulge: 'Renflement',
    field: 'Champ',
    galvo_configuration: 'Configuration Galvo',
    mark: 'Marquer',
    mark_parameters: 'Paramètres de marquage',
    offsetX: 'Décalage X',
    offsetY: 'Décalage Y',
    preview: 'Aperçu',
    red_dot: 'Point rouge',
    scale: 'Échelle',
    scaleX: 'Échelle X',
    scaleY: 'Échelle Y',
    skew: 'Inclinaison',
    switchXY: 'Changer X/Y',
    title: 'Paramètres Promark',
    trapezoid: 'Trapèze',
    workarea_hint: 'Vous pouvez changer la zone de travail dans les "Paramètres du document".',
    z_axis_adjustment: {
      section1: 'Ajustez la hauteur de l’axe Z pour affiner la mise au point.',
      title: 'Réglage de l’Axe Z',
      tooltip1: 'Essayez de marquer un carré de 1x1 cm pour confirmer si la distance focale actuelle est appropriée.',
    },
  },
  qr_code_generator: {
    error_tolerance: 'Tolérance aux erreurs',
    error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
    invert: 'Inverser la couleur de fond',
    placeholder: 'Saisissez un lien ou du texte',
    preview: 'Aperçu',
    title: 'Générateur de code QR',
  },
  rotary_settings: {
    circumference: 'Circonférence',
    extend_workarea: 'Étendre la zone de travail',
    mirror: 'Miroir',
    object_diameter: "Diamètre de l'Objet",
    overlap_size: 'Taille du chevauchement',
    rotary_scale: 'Échelle de rotation',
    split_setting: 'Paramètre de division',
    split_setting_url: 'https://support.flux3dp.com/hc/en-us/articles/12276094010767',
    split_size: 'Taille de la division',
    type: 'Type',
  },
  select_device: {
    auth_failure: "#811 Échec de l'authentification",
    select_device: "Sélectionner l'appareil",
    unable_to_connect: "#810 Impossible d'établir une connexion stable avec la machine",
  },
  settings: {
    anti_aliasing: 'Anticrénelage',
    auto_connect: 'Sélectionner automatiquement la seule machine',
    auto_switch_tab: "Changement automatique entre le panneau de calques et d'objets",
    autofocus_offset: 'Décalage de la mise au point automatique',
    autosave_enabled: 'Sauvegarde automatique',
    autosave_interval: 'Enregistrer tous les',
    autosave_number: 'Nombre de sauvegardes automatiques',
    autosave_path: 'Emplacement de la sauvegarde automatique',
    autosave_path_not_correct: 'Le chemin spécifié est introuvable.',
    blade_precut_position: 'Position de précoupe',
    blade_precut_switch: 'Précoupe de lame',
    blade_radius: 'Rayon de lame',
    bottom_up: 'De bas en haut',
    calculation_optimization: 'Accélération du calcul de trajectoire',
    cancel: 'Annuler',
    caption: 'Paramètres',
    check_updates: 'Vérification automatique',
    close: 'Fermer',
    confirm_remove_default: 'La machine par défaut va être supprimée.',
    confirm_reset: 'Confirmer la réinitialisation de Beam Studio?',
    continuous_drawing: 'Dessin continu',
    curve_engraving_speed_limit: 'Limite de vitesse de gravure sur courbe',
    custom_preview_height: "Hauteur d'aperçu personnalisée",
    default_beambox_model: 'Modèle par défaut Beambox',
    default_borderless_mode: 'Ouverture inférieure par défaut',
    default_enable_autofocus_module: 'Autofocus par défaut',
    default_enable_diode_module: 'Laser à diode par défaut',
    default_font_family: 'Police par défaut',
    default_font_style: 'Style de police par défaut',
    default_laser_module: 'Module laser par défaut',
    default_machine: 'Machine par défaut',
    default_machine_button: 'Vide',
    default_units: 'Unités par défaut',
    diode_offset: 'Décalage du laser à diode',
    diode_one_way_engraving: 'Gravure à sens unique au laser à diode',
    diode_two_way_warning: "L'émission bidirectionnelle de lumière est plus rapide et risque de générer une certaine imprécision sur la position de gravure au laser. Il est recommandé de tester d'abord.",
    disabled: 'Désactivé',
    done: 'Appliquer',
    enable_custom_backlash: 'Activer la compensation de jeu personnalisée',
    enable_low_speed: 'Activer le mouvement à vitesse lente',
    enabled: 'Activé',
    engraving_direction: 'Direction',
    fast_gradient: 'Optimisation de la vitesse',
    font_convert: 'Convertisseur de texte en tracé',
    font_substitute: 'Remplacer les caractères non pris en charge',
    grouped_objects: 'Objets groupés',
    groups: {
      ador_modules: 'Modules Ador',
      autosave: 'Sauvegarde automatique',
      camera: 'Caméra',
      connection: 'Connexion',
      editor: 'Éditeur',
      engraving: 'Gravure',
      general: 'Général',
      mask: 'Découpage de la zone de travail',
      modules: 'Complément',
      path: 'Contour',
      privacy: 'Confidentialité',
      text_to_path: 'Texte',
      update: 'Mises à jour logicielles',
    },
    guess_poke: "Rechercher l'adresse IP de la machine",
    guides: 'Repères',
    guides_origin: 'Origine des repères',
    help_center_urls: {
      anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
      calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
      connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
      continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
      default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
      default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
      default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
      fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
      font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
      font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
      image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
      loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
      mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
      reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
      segmented_engraving: 'https://support.flux3dp.com/hc/en-us/articles/12306366019215',
      simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
      vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
    },
    high: 'Élevé',
    image_downsampling: "Qualité d'aperçu bitmap",
    ip: 'Adresse IP de la machine',
    keep_preview_result: 'Conserver le Résultat de l’Aperçu',
    language: 'Langue',
    loop_compensation: 'Compensation de boucle',
    low: 'Bas',
    low_laser_for_preview: 'Laser pour Exécution du cadre',
    mask: 'Masque de la zone de travail',
    medium: 'Moyen',
    module_offset_2w_ir: 'Décalage du laser infrarouge 2W',
    module_offset_10w: 'Décalage du laser à diode 10W',
    module_offset_20w: 'Décalage du laser à diode 20W',
    module_offset_printer: "Décalage de l'imprimante",
    none: 'Aucun',
    normal: 'Normal',
    notification_off: 'Désactivé',
    notification_on: 'Activé',
    notifications: 'Notifications de bureau',
    off: 'Arrêt',
    on: 'Marche',
    preview_movement_speed: "Vitesse d'aperçu du mouvement",
    printer_advanced_mode: "Mode avancé de l'imprimante",
    remove_default_machine_button: 'Supprimer',
    reset: 'Réinitialiser Beam Studio',
    reset_now: 'Réinitialiser Beam Studio',
    segmented_engraving: 'Gravure segmentée',
    share_with_flux: 'Partager les analyses de Beam Studio',
    simplify_clipper_path: 'Optimiser le chemin calculé',
    single_object: 'Objet unique',
    tabs: {
      device: 'Machine',
      general: 'Général',
    },
    text_path_calc_optimization: 'Optimisation du calcul de chemin de texte',
    top_down: 'De haut en bas',
    trace_output: "Sortie de tracé d'image",
    update_beta: 'Bêta',
    update_latest: 'Dernier',
    vector_speed_constraint: 'Limite de vitesse',
    wrong_ip_format: 'Formats IP incorrects',
  },
  social_media: {
    facebook: 'Discutez avec les FLUXers, posez des questions et apprenez des astuces !',
    instagram: 'Obtenez les dernières inspirations, offres et cadeaux gratuits !',
    youtube: 'Consultez les tutoriels de Beam Studio et des idées créatives.',
  },
  support: {
    no_vcredist: 'Veuillez installer Visual C++ Redistributable 2015<br/>Cela peut être téléchargé sur flux3dp.com',
    no_webgl: "WebGL n'est pas pris en charge. Veuillez utiliser d'autres appareils.",
  },
  topbar: {
    alerts: {
      add_content_first: "Veuillez d'abord ajouter des objets",
      door_opened: 'Veuillez fermer le couvercle de protection pour activer le laser de découpe.',
      fail_to_connect_with_camera: '#803 Échec de la connexion avec la caméra de la machine. Veuillez redémarrer votre machine ou Beam Studio. Si cette erreur persiste, veuillez suivre <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">ce guide</a>.',
      fail_to_get_door_status: 'Veuillez vous assurer que le couvercle de protection est fermé pour activer le laser de découpe.',
      fail_to_start_preview: '#803 Échec du démarrage du mode aperçu. Veuillez redémarrer votre machine ou Beam Studio. Si cette erreur persiste, veuillez suivre <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">ce guide</a>.',
      headtype_mismatch: 'Module incorrect détecté. ',
      headtype_none: 'Aucun module détecté. ',
      headtype_unknown: 'Module inconnu détecté. ',
      install_correct_headtype: 'Veuillez installer correctement les modules laser diode 10W/20W pour activer le laser de découpe.',
      job_origin_unavailable: "La définition de l'origine de la tâche nécessite la version du firmware 4.3.5 / 5.3.5 ou supérieure. Voulez-vous mettre à jour le firmware maintenant?",
      job_origin_warning: 'Vous utilisez actuellement la “position actuelle” comme point de départ. Veuillez vous assurer que la tête laser est déplacée à la position correcte pour éviter les collisions.',
      power_too_high: 'PUISSANCE TROP ÉLEVÉE',
      power_too_high_confirm: 'PRIS EN COMPTE',
      power_too_high_msg: 'Utiliser une puissance laser inférieure (moins de 70 %) prolongera la durée de vie du tube laser.\nEntrez "PRIS EN COMPTE" pour continuer.',
      pwm_unavailable: 'Le Mode Profondeur nécessite une version de firmware 4.3.4 / 5.3.4 ou supérieure. Voulez-vous mettre à jour le firmware maintenant ?',
      QcleanScene: 'Voulez-vous effacer le dessin?<br/>Cela effacera également votre historique des annulations !',
      start_preview_timeout: '#803 Délai d’attente dépassé lors du démarrage du mode aperçu. Veuillez redémarrer votre machine ou Beam Studio. Si cette erreur persiste, veuillez suivre <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">ce guide</a>.',
    },
    frame_task: 'Exécution du cadre',
    hint: {
      polygon: 'Appuyez sur les touches + / - pour augmenter / diminuer les côtés.',
    },
    menu: {
      about: 'À propos de Beam Studio',
      about_beam_studio: 'À propos de Beam Studio',
      account: 'Compte',
      add_new_machine: 'Configuration de la machine',
      align_center: 'Centrer',
      anti_aliasing: 'Anticrénelage',
      auto_align: 'Alignement Automatique',
      borderless_mode: 'Mode plein écran',
      bug_report: 'Rapport de bug',
      calibrate_beambox_camera: 'Calibrer la caméra Beambox',
      calibrate_beambox_camera_borderless: 'Calibrer la caméra Beambox (ouverture inférieure)',
      calibrate_camera_advanced: 'Calibrer la caméra (Avancé)',
      calibrate_diode_module: 'Calibrer le module laser à diode',
      calibrate_ir_module: 'Calibrer le module infrarouge',
      calibrate_printer_module: "Calibrer le module d'impression",
      calibration: 'Calibrages',
      camera_calibration_data: 'Données de calibration de la caméra',
      change_logs: 'Journal des modifications',
      clear_scene: 'Nouveaux fichiers',
      close: 'Fermer la fenêtre',
      commands: 'Commandes',
      contact: 'Nous contacter',
      copy: 'Copier',
      cut: 'Couper',
      dashboard: 'Tableau de bord',
      decompose_path: 'Décomposer',
      delete: 'Supprimer',
      design_market: 'Marché de conception',
      dev_tool: 'Outil de débogage',
      disassemble_use: 'Désassembler',
      document_setting: 'Paramètres du document',
      document_setting_short: 'Document',
      download_data: 'Télécharger des données',
      download_log: 'Télécharger les journaux',
      download_log_canceled: 'Téléchargement des journaux annulé',
      download_log_error: "Une erreur inconnue s'est produite, veuillez réessayer plus tard",
      duplicate: 'Dupliquer',
      edit: 'Modifier',
      example_files: 'Fichiers d’Exemple',
      export_BVG: 'BVG',
      export_flux_task: 'Tâche FLUX',
      export_JPG: 'JPG',
      export_PNG: 'PNG',
      export_SVG: 'SVG',
      export_to: 'Exporter vers...',
      file: 'Fichier',
      fit_to_window: 'Ajuster à la taille de la fenêtre',
      follow_us: 'Suivez-nous',
      forum: 'Forum communautaire',
      group: 'Grouper',
      help: 'Aide',
      help_center: "Centre d'aide",
      hide: 'Masquer Beam Studio',
      hideothers: 'Masquer les autres',
      image_crop: 'Rogner',
      image_curve: 'Courbe',
      image_invert: 'Inverser',
      image_sharpen: 'Netteté',
      image_stamp: 'Biseautage',
      image_vectorize: 'Vectoriser',
      import_acrylic_focus_probe: 'Sonde de mise au point acrylique - 3mm',
      import_ador_laser_example: 'Exemple de Ador Laser',
      import_ador_printing_example_full: "Exemple d'impression Ador - Couleurs complètes",
      import_ador_printing_example_single: "Exemple d'impression Ador - Couleur unique",
      import_beambox_2_example: 'Exemple de Beambox II',
      import_beambox_2_focus_probe: 'Sonde de Mise au Point Beambox II - 3 mm',
      import_hello_beambox: 'Exemple de Beambox',
      import_hello_beamo: 'Exemple de beamo',
      import_hexa_example: 'Exemple de HEXA',
      import_material_printing_test: "Test d'impression de matériau",
      import_material_testing_cut: 'Test de découpe de matériau',
      import_material_testing_engrave: 'Test de gravure de matériau',
      import_material_testing_line: 'Test de ligne de matériau',
      import_material_testing_old: 'Test de gravure de matériau - Classique',
      import_material_testing_simple_cut: 'Test de découpe de matériau - Simple',
      import_promark_example: 'Exemple de Promark',
      import_promark_mopa_20w_color: 'Test de Couleur MOPA 20W',
      import_promark_mopa_60w_color: 'Test de Couleur MOPA 60W',
      import_promark_mopa_100w_color: 'Test de Couleur MOPA 100W',
      keyboard_shortcuts: 'Raccourcis clavier',
      layer_color_config: 'Paramètres de couleur',
      layer_setting: 'Calque',
      link: {
        beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
        contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
        design_market: 'https://dmkt.io',
        downloads: 'https://flux3dp.com/downloads/',
        forum: 'https://www.facebook.com/groups/flux.laser/',
        help_center: 'https://helpcenter.flux3dp.com/',
        shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
      },
      log: {
        camera: 'Caméra',
        cloud: 'Cloud',
        discover: 'Découverte',
        hardware: 'Matériel',
        network: 'Réseau',
        player: 'Lecteur',
        robot: 'Robot',
        usb: 'USB',
        usblist: 'Liste USB',
      },
      machine_info: 'Informations sur la machine',
      machines: 'Machines',
      manage_account: 'Gérer mon compte',
      material_test: 'Test de Matériau',
      minimize: 'Minimiser',
      my_account: 'Mon compte',
      network_testing: 'Tester les paramètres réseau',
      object: 'Objet',
      offset: 'Décaler',
      open: 'Ouvrir',
      paste: 'Coller',
      paste_in_place: 'Coller en place',
      path: 'Trajet',
      photo_edit: 'Image',
      preferences: 'Préférences',
      promark_color_test: 'Test de Couleur Promark',
      questionnaire: 'Questionnaire de commentaires',
      quit: 'Quitter',
      recent: 'Ouverte récente',
      redo: 'Rétablir',
      reload_app: "Recharger l'application",
      reset: 'Réinitialiser',
      rotary_setup: 'Configuration du Rotary',
      rotate: 'Pivoter',
      samples: 'Exemples',
      save_as: 'Enregistrer sous...',
      save_scene: 'Enregistrer',
      save_to_cloud: 'Enregistrer dans le cloud',
      scale: "Mettre à l'échelle",
      service: 'Services',
      set_as_default: 'Définir par défaut',
      show_gesture_tutorial: 'Introduction aux gestes',
      show_grids: 'Afficher la grille',
      show_layer_color: 'Utiliser la couleur de calque',
      show_rulers: 'Afficher les règles',
      show_start_tutorial: 'Afficher le didacticiel de démarrage',
      show_ui_intro: "Afficher l'introduction à l'interface",
      sign_in: 'Se connecter',
      sign_out: 'Se déconnecter',
      software_update: 'Mise à jour du logiciel',
      svg_edit: 'SVG',
      switch_to_beta: 'Passer à la version bêta',
      switch_to_latest: 'Passer à la version stable',
      tools: {
        box_generator: 'Générateur de Boîtes',
        code_generator: 'Générateur de code',
        material_test_generator: 'Générateur de Test de Matériau',
        title: 'Outils',
      },
      tutorial: "Didacticiel d'impression Delta",
      undo: 'Annuler',
      ungroup: 'Dégrouper',
      update: 'Vérifier la mise à jour',
      update_firmware: 'Mettre à jour le micrologiciel',
      upload_data: 'Télécharger des données',
      using_beam_studio_api: "Utiliser l'API Beam Studio",
      view: 'Affichage',
      window: 'Fenêtre',
      zoom_in: 'Zoom avant',
      zoom_out: 'Zoom arrière',
      zoom_with_window: 'Ajuster automatiquement à la taille de la fenêtre',
    },
    preview: 'PRÉVISUALISATION',
    preview_press_esc_to_stop: 'Appuyez sur ESC pour arrêter l’aperçu de la caméra.',
    rename_tab: "Renommer l'onglet",
    select_machine: 'Sélectionner une machine',
    tag_names: {
      dxf: 'Objet DXF',
      ellipse: 'Ellipse',
      g: 'Groupe',
      image: 'Image',
      line: 'Ligne',
      multi_select: 'Objets multiples',
      no_selection: 'Aucune sélection',
      pass_through_object: 'Objet de Passage',
      path: 'Trajet',
      polygon: 'Polygone',
      rect: 'Rectangle',
      svg: 'Objet SVG',
      text: 'Texte',
      text_path: 'Texte sur trajet',
      use: 'Objet importé',
    },
    task_preview: 'Aperçu de la tâche',
    titles: {
      settings: 'Préférences',
    },
    untitled: 'Sans titre',
  },
  topmenu: {
    credit: 'Beam Studio est rendu possible par le <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> projet open source et d\'autres <a target="_blank" href="https://flux3dp.com/credits/">logiciels open source</a>.',
    device: {
      download_log_canceled: 'Téléchargement du journal annulé',
      download_log_error: "Une erreur inconnue s'est produite, veuillez réessayer plus tard",
      log: {
        usblist: 'Liste USB',
      },
      network_test: 'Tester le réseau',
    },
    file: {
      all_files: 'Tous les fichiers',
      clear_recent: 'Effacer les fichiers récemment ouverts',
      converting: 'Conversion en image...',
      fcode_files: 'Code FLUX',
      import: 'Importer',
      jpg_files: 'JPG',
      label: 'Fichier',
      path_not_exit: 'Ce chemin semble ne plus exister sur le disque.',
      png_files: 'PNG',
      save_fcode: 'Exporter la tâche FLUX',
      save_jpg: 'Exporter le JPG',
      save_png: 'Exporter le PNG',
      save_scene: 'Enregistrer la scène',
      save_svg: 'Exporter le SVG',
      scene_files: 'Scènes Beam Studio',
      svg_files: 'SVG',
    },
    ok: 'OK',
    version: 'Version',
  },
  tutorial: {
    ask_retry_calibration: 'Voulez-vous réétalonner la caméra ?',
    camera_calibration_failed: "Échec d'étalonnage de la caméra",
    gesture: {
      click: "Appuyez pour sélectionner l'objet.",
      drag: 'Faites glisser pour sélectionner plusieurs objets.',
      hold: 'Appuyez et maintenez enfoncé pour ouvrir le menu contextuel.',
      pan: 'Faites défiler le canevas avec deux doigts.',
      zoom: 'Pincer avec deux doigts pour zoomer / dézoomer le canevas.',
    },
    links: {
      adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
    },
    look_for_machine: 'Recherche de la machine pour le didacticiel...',
    needNewInterfaceTutorial: 'Souhaitez-vous commencer un tutoriel pour la nouvelle interface de Beam Studio ?<br/>(Vous pouvez le sauter maintenant et le commencer plus tard en cliquant sur "Aide" > "Afficher l\'Introduction à l\'Interface".)',
    needNewUserTutorial: 'Souhaitez-vous commencer un tutoriel ?<br/>(Vous pouvez le sauter maintenant et le commencer plus tard en cliquant sur "Aide" > "Afficher le Tutoriel de Démarrage".)',
    newInterface: {
      add_new_layer: 'Ajouter un nouveau calque',
      align_controls: 'Aligner les commandes',
      basic_shapes: 'Formes de base',
      camera_preview: 'Aperçu de la caméra',
      drag_to_sort: 'Glisser pour trier',
      end_alert: "Êtes-vous sûr de vouloir terminer l'introduction à la nouvelle interface utilisateur ?",
      flip: 'Retourner',
      group_controls: 'Grouper les commandes',
      layer_controls: 'Clic droit pour sélectionner les contrôles de calque :\nDupliquer / Fusionner / Verrouiller / Supprimer des calques',
      object_actions: "Actions sur l'objet",
      pen_tool: 'Outil Plume',
      rename_by_double_click: 'Renommer en double-cliquant',
      select_image_text: 'Sélectionner / Image / Texte',
      select_machine: 'Sélectionner une machine',
      shape_operation: 'Opération de forme',
      start_work: 'Commencer le travail',
      switch_between_layer_panel_and_object_panel: 'Basculer entre le panneau des calques et le panneau des objets',
    },
    newUser: {
      add_new_layer: 'Ajouter un nouveau calque',
      adjust_focus: '2. Régler la mise au point',
      close_cover: '3. Fermer le couvercle',
      drag_to_draw: 'Faites glisser pour dessiner',
      draw_a_circle: 'Dessiner un cercle',
      draw_a_rect: 'Dessiner un rectangle',
      end_alert: 'Êtes-vous sûr de vouloir terminer le tutoriel ?',
      end_preview_mode: 'Quitter le mode Aperçu',
      infill: 'Activer le remplissage',
      please_select_wood_cutting: 'Veuillez sélectionner le préréglage "Bois - Découpe".',
      please_select_wood_engraving: 'Veuillez sélectionner le préréglage "Bois - Gravure".',
      preview_the_platform: 'Aperçu de la plateforme',
      put_wood: "1. Mettre l'échantillon de bois",
      send_the_file: 'Envoyer le fichier',
      set_preset_wood_cut: 'Définir le préréglage : Bois - Découpe',
      set_preset_wood_engraving: 'Définir le préréglage : Bois - Gravure',
      switch_to_layer_panel: 'Passer au panneau des calques',
      switch_to_object_panel: 'Passer au panneau des objets',
      switch_to_preview_mode: 'Passer en mode Aperçu',
    },
    next: 'SUIVANT',
    retry: 'Réessayer',
    set_connection: 'Définir la connexion',
    skip: 'Passer',
    skip_tutorial: 'Vous avez sauté le tutoriel. Vous pouvez toujours démarrer le tutoriel en cliquant sur "Aide" > "Afficher le tutoriel de démarrage"',
    suggest_calibrate_camera_first: 'Nous conseillons aux utilisateurs de calibrer la caméra initialement et de refocaliser avant chaque prévisualisation pour des résultats optimaux.<br/>Voulez-vous confirmer pour effectuer la calibration maintenant ?<br/>(Vous pouvez le sauter maintenant et le faire plus tard en cliquant sur "Menu" > "Machine" > [Votre Machine] > "Calibrer la Caméra".)',
    tutorial_complete: "C'est tout pour le didacticiel. Maintenant, c'est le moment de créer !",
    unable_to_find_machine: 'Impossible de trouver la machine pour le didacticiel. Voulez-vous accéder à la page des paramètres de connexion, réessayer ou ignorer le didacticiel ?',
    welcome: 'BIENVENUE',
  },
  update: {
    cannot_reach_internet: '#823 Le serveur est inaccessible<br/>Veuillez vérifier la connexion Internet',
    download: 'MISE À JOUR EN LIGNE',
    firmware: {
      caption: 'Une mise à jour du micrologiciel de la machine est disponible',
      confirm: 'TÉLÉCHARGER',
      firmware_too_old_update_by_sdcard: "La version du micrologiciel est trop ancienne. Veuillez mettre à jour le micrologiciel à l'aide d'une carte SD.",
      force_update_message: '#814 Veuillez mettre à jour votre machine avec la dernière version du micrologiciel.',
      latest_firmware: {
        cant_get_latest: "Impossible d'obtenir des informations sur le dernier micrologiciel.",
        caption: 'Mise à jour du micrologiciel de la machine',
        message: 'Vous avez le dernier micrologiciel de la machine',
        still_update: 'METTRE À JOUR',
      },
      message_pattern_1: '"%s" est maintenant prêt pour la mise à jour du micrologiciel.',
      message_pattern_2: 'Micrologiciel %s v%s est maintenant disponible - Vous avez v%s.',
      too_old_for_web: 'La version actuelle du micrologiciel de votre machine est la v%s.\nSi vous souhaitez utiliser la version en ligne de Beam Studio, veuillez mettre à jour le micrologiciel de la machine vers la dernière version.',
      update_fail: '#822 Échec de la mise à jour',
      update_success: 'Micrologiciel mis à jour avec succès',
      upload_file: 'Téléchargement du micrologiciel (*.bin / *.fxfw)',
    },
    install: 'INSTALLER',
    later: 'PLUS TARD',
    preparing: 'Préparation en cours...',
    release_note: 'Note de version :',
    skip: 'Ignorer cette version',
    software: {
      available_switch: 'Beam Studio v%s est disponible maintenant. Vous avez la v%s. Voulez-vous passer à cette version ?',
      available_update: 'Beam Studio v%s est disponible maintenant. Vous avez la v%s. Voulez-vous télécharger la mise à jour ?',
      caption: 'Une mise à jour logicielle de Beam Studio est disponible',
      check_update: 'Rechercher les mises à jour',
      checking: 'Vérification des mises à jour',
      downloading: 'Téléchargement des mises à jour en arrière-plan, vous pouvez cliquer sur "OK" pour continuer votre travail.',
      install_or_not: 'est prêt pour la mise à jour. Voulez-vous redémarrer et mettre à jour maintenant ?',
      no: 'Non',
      no_response: 'Échec de la connexion au serveur, veuillez vérifier vos paramètres réseau.',
      not_found: 'Vous utilisez la dernière version de Beam Studio.',
      switch_or_not: 'est prêt pour le changement. Voulez-vous redémarrer et changer maintenant ?',
      switch_version: 'Changer de version',
      switch_version_not_found: 'Version commutable introuvable.',
      update_for_ador: "La version logicielle actuelle %s n'est pas compatible, veuillez télécharger la dernière version de Beam Studio pour Ador.",
      yes: 'Oui',
    },
    update: 'Mettre à jour',
    updating: 'Mise à jour en cours...',
    upload: 'TÉLÉCHARGER',
  },
  web_cam: {
    no_device: "Impossible de détecter l'appareil photo. Veuillez reconnecter la caméra et réessayer.",
    no_permission: "Beam Studio n'a pas l'autorisation d'accéder à la caméra. Assurez-vous que l'autorisation est accordée à Beam Studio dans les paramètres du navigateur ou du système.",
  },
  z_speed_limit_test: {
    alert_before: 'Avant de commencer la gravure de courbes, le système effectuera un test de charge pour éviter toute perte de pas due à un poids excessif.',
    alert_failed: "Le poids de l'objet actuel dépasse la limite de charge pour la vitesse actuelle de l'axe Z. Veuillez réduire la vitesse de l'axe Z sur le panneau de la machine ou remplacer l'objet de gravure avant de refaire le test.",
    ignore: 'Ignorer',
    retest: 'Retester',
    testing: 'Exécution du test de charge...',
  },
};

export default lang;
