import type { ILang } from '@core/interfaces/ILang';

const lang: ILang = {
  alert: {
    abort: 'Avbryt',
    cancel: 'Avbryt',
    caption: 'Fel',
    close: 'Stäng',
    confirm: 'Bekräfta',
    dont_save: 'Spara inte',
    dont_show_again: 'Visa inte igen',
    duplicated_preset_name: 'Duplicerat förinställningsnamn',
    error: 'OJ',
    info: 'INFO',
    instruction: 'Instruktion',
    learn_more: 'Läs mer',
    no: 'Nej',
    ok: 'OK',
    ok2: 'OK',
    oops: 'Hoppsan...',
    retry: 'Försök igen',
    save: 'Spara',
    stop: 'Stopp',
    warning: 'VARNING',
    yes: 'Ja',
  },
  auto_fit: {
    artwork_size: 'Storlek på konstverk',
    error_tip1: '1. Är konstverket placerat korrekt på materialet?',
    error_tip2: '2. Är materialkonturerna tillräckligt klara för igenkänning?',
    failed_to_find_contour: 'Misslyckades med att hitta konturer för automatisk anpassning',
    learn_more: 'Visa handledning.',
    learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    offset_x: 'Förskjutning X',
    offset_y: 'Förskjutning Y',
    pattern_size: 'Mönsterstorlek',
    position_artwork: 'Placera konstverk',
    position_step1: 'Justera storleken och positionen för ditt konstverk på mönstret.',
    position_step2: 'Klicka på "Tillämpa" för att använda Auto Fit på detta mönster.',
    preview_first: 'Utför kameraförhandsgranskningen först.',
    reset_position: 'Återställ position',
    rotation: 'Rotera',
    select_a_pattern: 'Välj ett mönster',
    selected_artwork: 'Vald konstverk',
    step1: 'Välj ett mönster där du vill placera konstverket.',
    step2: 'Om inget mönster hittas, förhandsgranska kameran igen och använd Auto Fit.',
    step3: 'Om det fortfarande inte går att hitta rätt mönster, se till att ditt arbetsstycke är tydligt igenkännbart och att arbetsområdet är rent från skräp.',
    title: 'Auto Fit',
  },
  barcode_generator: {
    bar_height: 'Streckhöjd',
    bar_width: 'Streckbredd',
    barcode: {
      invalid_value: 'Värdet är ogiltigt för det valda formatet.',
    },
    font: 'Typsnitt',
    font_size: 'Teckensnittsstorlek',
    hide_text: 'Dölj text',
    invert_color: 'Invertera färg',
    text_margin: 'Textmarginal',
  },
  beambox: {
    announcement_panel: {
      title: 'Meddelande',
    },
    banner: {
      auto_feeder: 'Auto Matningsläge',
      camera_preview: 'Förhandsgranskning',
      camera_preview_borderless_mode: '(Öppen Botten)',
      curve_engraving: 'Kurvgravyrläge',
      pass_through: 'Genomgångsläge',
      rotary: 'Rotationsläge',
    },
    bottom_right_panel: {
      convert_text_to_path_before_export: 'Konvertera text till bana...',
      export_file_error_ask_for_upload: 'Misslyckades att exportera uppgift. Vill du skicka arbetsytan till utvecklingsteamet för felrapportering?',
      retreive_image_data: 'Hämtar bilddata...',
    },
    context_menu: {
      copy: 'Kopiera',
      cut: 'Klipp ut',
      delete: 'Ta bort',
      duplicate: 'Duplicera',
      group: 'Gruppera',
      move_back: 'Flytta längst bak',
      move_down: 'Flytta bakåt',
      move_front: 'Flytta längst fram',
      move_up: 'Flytta framåt',
      paste: 'Klistra in',
      paste_in_place: 'Klistra in på plats',
      ungroup: 'Dela grupp',
    },
    document_panel: {
      add_on: 'Tillägg',
      auto_feeder: 'Automatisk matning',
      auto_feeder_scale: 'Skala för automatisk matning',
      auto_feeder_url: 'https://support.flux3dp.com/hc/en-us/articles/11688230498575',
      borderless_mode: 'Öppen botten',
      current_position: 'Nuvarande Position',
      disable: 'Inaktivera',
      document_settings: 'Dokumentinställningar',
      enable: 'Aktivera',
      enable_autofocus: 'Autofokus',
      enable_diode: 'Diodlaser',
      engrave_dpi: 'Upplösning',
      frame_before_start: 'Ram innan utförande',
      frame_before_start_url: 'https://support.flux3dp.com/hc/en-us/articles/11494925637135',
      high: 'Hög',
      job_origin: 'Jobb Ursprung',
      laser_source: 'Laserkälla',
      low: 'Låg',
      machine: 'Maskin',
      medium: 'Medium',
      notification: {
        changeFromPrintingWorkareaTitle: 'Vill du konvertera utskriftslagren till laserskikt?',
      },
      origin: 'Ursprung',
      pass_through: 'Genomgång',
      pass_through_height_desc: 'Ange längden på objektet för att utöka arbetsområdet.',
      rotary_mode: 'Roterande',
      scale: 'Skala',
      start_from: 'Starta Från',
      start_position: 'Startposition',
      start_work_button: 'Kör Knapp',
      ultra: 'Ultrahög',
      workarea: 'Arbetsområde',
    },
    image_trace_panel: {
      back: 'Tillbaka',
      brightness: 'Ljusstyrka',
      cancel: 'Avbryt',
      contrast: 'Kontrast',
      next: 'Nästa',
      okay: 'OK',
      threshold: 'Tröskelvärde',
      tuning: 'Inställningar',
    },
    layer_color_config_panel: {
      add: 'Lägg till',
      add_config: 'Lägg till färg',
      color: 'Färg',
      default: 'Återställ till standard',
      in_use: 'Den här färgen används.',
      layer_color_config: 'Lagerfärgsinställningar',
      no_input: 'Ange giltig hex-färgkod.',
      power: 'Effekt',
      repeat: 'Upprepa',
      speed: 'Hastighet',
      sure_to_delete: 'Är du säker på att du vill ta bort den här färginställningen?',
      sure_to_reset: 'Du kommer att förlora alla anpassade inställningar, är du säker på att du vill återställa till standard?',
    },
    left_panel: {
      borderless_blind_area: 'Icke-graveringsområde',
      borderless_preview: 'Öppna bottenläge kameraförhandsgranskning',
      diode_blind_area: 'Hybridlaser tilläggs blint område',
      ellipse: 'Ellips',
      image: 'Bild',
      label: {
        adjust_height: 'Justera höjd',
        array: 'Matris',
        boxgen: 'Boxgen',
        choose_camera: 'Kamera',
        clear_preview: 'Rensa förhandsgranskning',
        cursor: 'Välj',
        curve_engraving: {
          clear_area: 'Rensa valt område',
          exit: 'Avsluta',
          preview_3d_curve: 'Förhandsvisning av kurvgravering',
          select_area: 'Välj område',
          title: 'Kurvgravering',
        },
        end_preview: 'Avsluta förhandsgranskning',
        line: 'Linje',
        live_feed: 'Livebild',
        my_cloud: 'Mitt moln',
        oval: 'Oval',
        pass_through: 'Genomgång',
        pen: 'Penn',
        photo: 'Bild',
        polygon: 'Polygon',
        preview: 'Kameraförhandsgranskning',
        qr_code: 'QR-kod',
        rect: 'Rektangel',
        shapes: 'Objekt',
        text: 'Text',
        trace: 'Spåra bild',
      },
      line: 'Linje',
      rectangle: 'Rektangel',
      text: 'Text',
      unpreviewable_area: 'Blint område',
    },
    network_testing_panel: {
      average_response: 'Genomsnittlig svarstid',
      cannot_connect_1: '#840 Misslyckades att ansluta till mål-IP.',
      cannot_connect_2: '#840 Misslyckades att ansluta till mål-IP. Kontrollera att målet är i samma nätverk.',
      cannot_get_local: 'Kunde inte hämta lokal IP-adress',
      connection_quality: 'Anslutningskvalitet',
      device_not_on_list: '#842 Maskinen hittades inte i listan men anslutningen verkar fungera',
      empty_ip: '#818 Ange målenhetens IP-adress först.',
      end: 'Sluta',
      fail_to_start_network_test: '#817 Misslyckades starta nätverktest',
      hint_connect_camera_timeout: 'Tidsgränser vid kameraförhandsgranskning?',
      hint_connect_failed_when_sending_job: 'Problem att ansluta vid jobbsändning?',
      hint_device_often_on_list: 'Hittas maskinen sällan i listan?',
      insert_ip: 'Målenhetens IP-adress:',
      invalid_ip: '#818 Ogiltig IP-adress',
      ip_startswith_169: '#843 Maskinens IP-adress börjar med 169.254',
      link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
      link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
      link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
      linux_permission_hint: 'Otillräckliga behörigheter. Kör "sudo beam-studio --no-sandbox" för nätverktest.',
      local_ip: 'Lokal IP-adress:',
      network_testing: 'Nätverkstest',
      network_unhealthy: '#841 Dålig anslutningskvalitet eller hög svarstid',
      start: 'Starta',
      test_completed: 'Test slutförd',
      test_fail: 'Test misslyckades',
      testing: 'Testar nätverk...',
    },
    object_panels: {
      lock_desc: 'Behåll bredd- och höjdförhållandet (SHIFT)',
      text_to_path: {
        check_thumbnail_warning: 'Vissa texter ändrades till andra typsnitt när text tolkades till banor och vissa tecken kanske inte konverterades korrekt. <br/> Kontrollera förhandsgranskningsbilden igen innan du skickar uppgiften.',
        error_when_parsing_text: 'Fel vid omvandling av text till bana',
        font_substitute_pop: 'Din text innehåller tecken som inte stöds av aktuellt typsnitt. <br/>Vill du använda <strong>%s</strong> som ersättning?',
        retry: 'Försök igen senare eller välj ett annat typsnitt',
        use_current_font: 'Använd aktuellt typsnitt',
      },
      wait_for_parsing_font: 'Tolkar teckensnitt ... Vänta en sekund',
    },
    path_preview: {
      current_position: 'Aktuell position',
      cut_distance: 'Skärdistans',
      cut_time: 'Skärtid',
      end_preview: 'Sluta förhandsgranska',
      estimated_time: 'Total uppskattad tid',
      invert: 'Invertera',
      pause: 'Pausa',
      play: 'Spela',
      play_speed: 'Uppspelningshastighet',
      preview_info: 'Förhandsgranskningsinformation',
      rapid_distance: 'Förflyttningsdistans',
      rapid_time: 'Förflyttningstid',
      remark: '* All information är uppskattade värden som referens.',
      size: 'Storlek',
      start_here: 'Börja här',
      stop: 'Stoppa',
      travel_path: 'Förflyttningsväg',
    },
    photo_edit_panel: {
      aspect_ratio: 'Bildförhållande',
      brightness: 'Ljusstyrka',
      brightness_and_contrast: 'Ljusstyrka / Kontrast',
      cancel: 'Avbryt',
      circumference: 'Omkrets',
      compare: 'Jämför',
      contrast: 'Kontrast',
      crop: 'Beskär',
      curve: 'Kurva',
      diameter: 'Diameter',
      free: 'Fritt',
      invert: 'Invertera färg',
      okay: 'OK',
      original: 'Original',
      phote_edit: 'Foto redigering',
      processing: 'Bearbetar',
      radius: 'Radie',
      rotary_warped: 'Roterande Förvrängd',
      rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
      sharpen: 'Skärpa',
      sharpness: 'Skärpa',
      start: 'Start',
      warp: 'Förvrängning',
    },
    popup: {
      ai_credit: {
        buy_link: 'https://member.flux3dp.com/en-US/credit',
        go: 'Gå',
        insufficient_credit: 'Du har slut på kredit',
        insufficient_credit_msg: 'Du kan inte använda %s. Gå till medlemscentret och köp AI-kredit',
        relogin_to_use: 'Logga in igen för att använda den här funktionen',
      },
      auto_feeder_origin: 'Använd inramningsförhandsgranskningen för att kontrollera laserhuvudets bana och område, och justera sedan materialets placering eller gravyrens startposition baserat på resultaten för att undvika kollisioner under graveringsprocessen.',
      auto_switch_tab: {
        message: 'Ett nytt alternativ för att automatiskt växla mellan lagpanelen och objektpanelen har lagts till i Inställningar. Detta alternativ är inaktiverat som standard. Vill du aktivera automatisk växling nu? <br/>Du kan ändra denna inställning när som helst i Inställningar.',
        title: 'Automatisk Växling mellan Lager- och Objektpanel',
      },
      backend_connect_failed_ask_to_upload: '#802 Fel uppstår hela tiden när anslutning till servern försöks. Vill du ladda upp din felrapportlogg?',
      backend_error_hint: 'Funktioner kanske inte fungerar korrekt på grund av serverfel.',
      both_power_and_speed_too_high: 'Att använda lägre laserstyrka förlänger laserns livslängd.\nOckså, för hög hastighet vid denna upplösning kan ge sämre kvalitet på skugggravering.',
      bug_report: 'Felrapport',
      change_workarea_before_preview: 'Arbetsområdet för %s matchar inte det för närvarande inställda arbetsområdet. Vill du byta nuvarande arbetsområde?',
      convert_to_path_fail: 'Misslyckades med att konvertera till bana.',
      dxf_bounding_box_size_over: 'Ritningens storlek är utanför arbetsområdet. Flytta din ritning närmare origo i ditt CAD-program, eller se till att enheten är korrekt inställd.',
      dxf_version_waring: 'Versionen av denna DXF-fil är inte 2013, det kan finnas potentiella kompatibilitetsproblem.',
      facebook_group_invitation: {
        already_joined: 'Redan ansluten',
        join_now: 'Räkna in mig',
        later: 'Kanske senare',
        message: 'Gå med i vår officiella Facebook-grupp för att kontakta andra FLUX-laseranvändare, diskutera FLUX-lasrar, dela laserkonst och hålla dig uppdaterad om de senaste uppdateringarna av våra produkter. Vi ser fram emot att se dig där!',
        title: 'Gå med i FLUX officiella användargrupp',
      },
      import_file_contain_invalid_path: '#808 Importerad SVG-fil innehåller ogiltig bildsökväg. Se till att alla bildfiler finns eller bädda in bild i filen',
      import_file_error_ask_for_upload: 'Misslyckades med att importera SVG-filen. Vill du skicka filen till utvecklingsteamet för felrapportering?',
      layer_by_color: 'Färg',
      layer_by_layer: 'Skikt',
      loading_image: 'Läser in bild, vänta...',
      more_than_two_object: 'För många objekt. Stöder endast 2 objekt',
      mouse: 'Mus',
      no_support_text: 'Beam Studio stöder inte text just nu. Vänligen omvandla text till bana innan import.',
      nolayer: 'Ett skikt',
      not_support_object_type: 'Stöder inte objekttyp',
      or_turn_off_borderless_mode: 'Eller stäng av öppen bottenläge.',
      pdf2svg: {
        error_pdf2svg_not_found: '#825 Fel: Kommandot pdf2svg hittades inte. Installera pdf2svg med ditt pakethanterare (t.ex. "yum install pdf2svg" eller "apt-get install pdf2svg").',
        error_when_converting_pdf: '#824 Fel vid konvertering av PDF till SVG:',
      },
      progress: {
        calculating: 'Beräknar',
        uploading: 'Laddar upp',
      },
      questionnaire: {
        caption: 'Hjälp oss fylla i frågeformuläret',
        message: 'Hjälp oss fylla i frågeformuläret för att göra Beam Studio bättre.',
        no_questionnaire_available: 'Det finns inget frågeformulär tillgängligt just nu.',
        unable_to_get_url: 'Det gick inte att hämta länken till frågeformuläret via internet. Bekräfta din internetanslutning.',
      },
      recommend_downgrade_software: 'Vi upptäckte en äldre firmwareversion. Vi arbetar aktivt med kompatibilitetsproblem, men för närvarande rekommenderar vi att återgå till Beam Studio 1.9.5.',
      recommend_upgrade_firmware: 'Vi upptäckte en äldre firmware-version. Vi arbetar aktivt med kompatibilitetsproblem, men tills vidare rekommenderar vi att du uppdaterar till den senaste firmware-versionen.',
      save_unsave_changed: 'Vill du spara osparade ändringar?',
      select_at_least_two: 'Välj två objekt för att fortsätta',
      select_first: 'Välj ett objekt först.',
      select_import_method: 'Välj skiktstil:',
      select_import_module: 'Välj modul:',
      sentry: {
        message: 'Godkänner du att relevant information automatiskt laddas upp till utvecklingsteamet när fel uppstår?',
        title: 'Låt oss göra Beam Studio bättre tillsammans',
      },
      should_update_firmware_to_continue: '#814 Din firmware stöder inte denna version av Beam Studio. Uppdatera firmware för att fortsätta. (Meny > Maskin > [Din maskin] > Uppdatera firmware)',
      speed_too_high_lower_the_quality: 'För hög hastighet vid denna upplösning kan ge sämre kvalitet på skugggravering.',
      still_continue: 'Fortsätt',
      successfully_uploaded: 'Filuppladdningen lyckades.',
      svg_1_1_waring: 'Versionen av denna SVG-fil är v 1.1, det kan finnas potentiella kompatibilitetsproblem.',
      svg_image_path_waring: 'Denna SVG-fil innehåller <image> som läser in från filsökväg. Detta kan orsaka fel vid inläsning.\nFör att undvika denna risk, använd inbäddade bilder vid export av SVG.',
      text_to_path: {
        caption: 'Text till bana konverterare 2.0',
        message: 'Beam Studio introducerar nu en ny text till bana konverterare (Konverterare 2.0), som producerar mer tillförlitliga resultat! Vill du byta till den nu?\\nDu kan också justera den här inställningen senare i avsnittet "Text till bana konverterare" under inställningarna.',
      },
      too_fast_for_auto_feeder: 'Att använda för höga hastigheter i lager som innehåller banobjekt kan minska skärprecisions.\nVi rekommenderar inte att använda hastigheter högre än %(limit)s vid skärning med Auto Feeder.',
      too_fast_for_auto_feeder_and_constrain: 'Följande lager: %(layers)s \ninnehar vektorbanobjekt och har en hastighet som överskrider %(limit)s.\nSkärhastigheten för vektorbanobjekt med Auto Feeder kommer att begränsas till %(limit)s.\nDu kan ta bort denna begränsning i Preferensinställningarna.',
      too_fast_for_curve: 'Att använda för höga hastigheter för lager som innehåller kurvgravyrer kan minska graveringsprecisionen.\nVi rekommenderar att hålla hastigheten under %(limit)s för bästa resultat.',
      too_fast_for_curve_and_constrain: 'Följande lager: %(layers)s överskrider hastighetsgränsen på %(limit)s.\nGraveringshastigheten för kurvobjekt kommer att begränsas till %(limit)s. Du kan ta bort denna begränsning i Inställningar.',
      too_fast_for_path: 'För hög hastighet i lager med banobjekt kan ge lägre precision vid skärning.\nVi rekommenderar inte hastigheter över %(limit)s vid skärning.',
      too_fast_for_path_and_constrain: 'Följande lager: %(layers)s \ninnehåller vektorbanaobjekt och har hastighet över %(limit)s.\nSkärhastigheten för vektorbanaobjekt kommer begränsas till %(limit)s.\nDu kan ta bort denna begränsning under inställningar.',
      touchpad: 'Pekplatta',
      ungroup_use: 'Detta kommer att separera importerad DXF eller SVG. Eftersom filen kan innehålla ett stort antal element kan det ta tid att separera. Är du säker på att du vill fortsätta?',
      upload_failed: '#819 Filuppladdningen misslyckades.',
      upload_file_too_large: '#819 Filen är för stor för uppladdning.',
      vectorize_shading_image: 'Gradientbilder tar längre tid att spåra och är benägna till brus. Vänligen stäng av bildgradienten innan du kör.',
    },
    rating_panel: {
      description: 'Om du gillar Beam Studio skulle vi uppskatta det mycket om du kunde betygsätta oss.',
      thank_you: 'Tack!',
      title: 'Gillar du Beam Studio?',
    },
    right_panel: {
      laser_panel: {
        advanced: 'Avancerat',
        backlash: 'Backlash',
        bi_directional: 'Dubbelriktad',
        by: 'Med',
        ce_z_high_speed: 'Höghastighets Z-axel',
        ce_z_high_speed_link: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
        color_adjustment: 'Kanaljustering',
        color_adjustment_short: 'Kanal',
        color_strength: 'Styrka',
        cross_hatch: 'Korsskraffering',
        custom_preset: 'Anpassad',
        cut: 'Skär',
        diode: 'Diodlaser',
        do_not_adjust_default_para: 'Standardinställningarna kan inte justeras.',
        dottingTime: 'Punktningstid',
        dropdown: {
          inches: {
            abs_engraving: 'ABS - gravering',
            acrylic_3mm_cutting: 'Akryl - 1/8″ skärning',
            acrylic_5mm_cutting: 'Akryl - 3/16″ skärning',
            acrylic_8mm_cutting: 'Akryl - 1/4″ skärning',
            acrylic_10mm_cutting: 'Akryl - 3/8″ skärning',
            acrylic_engraving: 'Akryl - gravering',
            acrylic_printing: 'Akryl - utskrift',
            aluminum_engraving: 'Aluminium - gravering',
            aluminum_light: 'Aluminium (ljus)',
            bamboo_printing: 'Bambuträ - utskrift',
            black_abs: 'Svart ABS',
            black_acrylic_3mm_cutting: 'Svart akryl - 1/8″ skärning',
            black_acrylic_5mm_cutting: 'Svart akryl - 3/16″ skärning',
            black_acrylic_engraving: 'Svart akryl - gravering',
            brass_dark: 'Mässing (mörk)',
            brass_engraving: 'Mässing - gravering',
            brass_light: 'Mässing (ljus)',
            canvas_printing: 'Duk - utskrift',
            cardstock_printing: 'Kartong - utskrift',
            copper: 'Koppar',
            cork_printing: 'Kork - utskrift',
            denim_1mm_cutting: 'Denim - 1/32″ skärning',
            fabric_3mm_cutting: 'Tyg - 1/8″ skärning',
            fabric_5mm_cutting: 'Tyg - 3/16″ skärning',
            fabric_engraving: 'Tyg - gravering',
            fabric_printing: 'Tyg - utskrift',
            flat_stone_printing: 'Platt sten - utskrift',
            glass_bw_engraving: 'Glas - gravering',
            glass_printing: 'Glas - utskrift',
            gloss_leather_printing: 'Blankt läder - utskrift',
            gold_engraving: 'Guld - gravering',
            iron_engraving: 'Järn - gravering',
            leather_3mm_cutting: 'Läder - 1/8″ skärning',
            leather_5mm_cutting: 'Läder - 3/16″ skärning',
            leather_engraving: 'Läder - gravering',
            mdf_3mm_cutting: 'MDF - 1/8″ skärning',
            mdf_5mm_cutting: 'MDF - 3/16″ skärning',
            mdf_engraving: 'MDF - gravering',
            metal_bw_engraving: 'Metall - gravering',
            opaque_acrylic: 'Ogenomskinlig akryl',
            pc_printing: 'PC - utskrift',
            rubber_bw_engraving: 'Gummi - gravering',
            silver_engraving: 'Silver - gravering',
            stainless_steel_bw_engraving_diode: 'Metall - gravering (diodlaser)',
            stainless_steel_dark: 'Rostfritt stål (mörk)',
            stainless_steel_engraving: 'Rostfritt stål - gravering',
            stainless_steel_light: 'Rostfritt stål (ljus)',
            stainless_steel_printing: 'Rostfritt stål - utskrift',
            steel_engraving_spray_engraving: 'Metall - gravering',
            stone: 'Sten',
            ti_engraving: 'Titan - gravering',
            titanium_dark: 'Titan (mörk)',
            titanium_light: 'Titan (ljus)',
            white_abs: 'Vit ABS',
            wood_3mm_cutting: 'Trä - 1/8″ skärning',
            wood_5mm_cutting: 'Trä - 3/16″ skärning',
            wood_7mm_cutting: 'Trä - 1/4″ skärning',
            wood_8mm_cutting: 'Trä - 1/4″ skärning',
            wood_10mm_cutting: 'Trä - 3/8″ skärning',
            wood_engraving: 'Trä - gravering',
            wood_printing: 'Trä - utskrift',
          },
          mm: {
            abs_engraving: 'ABS - gravering',
            acrylic_3mm_cutting: 'Akryl - 3 mm skärning',
            acrylic_5mm_cutting: 'Akryl - 5 mm skärning',
            acrylic_8mm_cutting: 'Akryl - 8 mm skärning',
            acrylic_10mm_cutting: 'Akryl - 10 mm skärning',
            acrylic_engraving: 'Akryl - gravering',
            acrylic_printing: 'Akryl - utskrift',
            aluminum_engraving: 'Aluminium - gravering',
            aluminum_light: 'Aluminium (ljus)',
            bamboo_printing: 'Bambus - utskrift',
            black_abs: 'Svart ABS',
            black_acrylic_3mm_cutting: 'Svart akryl - 3 mm skärning',
            black_acrylic_5mm_cutting: 'Svart akryl - 5 mm skärning',
            black_acrylic_engraving: 'Svart akryl - gravering',
            brass_dark: 'Mässing (mörk)',
            brass_engraving: 'Mässing - gravering',
            brass_light: 'Mässing (ljus)',
            canvas_printing: 'Canvas - utskrift',
            cardstock_printing: 'Kartong - utskrift',
            copper: 'Koppar',
            cork_printing: 'Kork - utskrift',
            denim_1mm_cutting: 'Denim - 1 mm skärning',
            fabric_3mm_cutting: 'Tyg - 3 mm skärning',
            fabric_5mm_cutting: 'Tyg - 5 mm skärning',
            fabric_engraving: 'Tyg - gravering',
            fabric_printing: 'Tyg - utskrift',
            flat_stone_printing: 'Platt sten - utskrift',
            glass_bw_engraving: 'Glas - gravering',
            glass_printing: 'Glas - utskrift',
            gloss_leather_printing: 'Blankt läder - utskrift',
            gold_engraving: 'Guld - gravering',
            iron_engraving: 'Järn - gravering',
            leather_3mm_cutting: 'Läder - 3 mm skärning',
            leather_5mm_cutting: 'Läder - 5 mm skärning',
            leather_engraving: 'Läder - gravering',
            mdf_3mm_cutting: 'MDF - 3 mm skärning',
            mdf_5mm_cutting: 'MDF - 5 mm skärning',
            mdf_engraving: 'MDF - gravering',
            metal_bw_engraving: 'Metall - gravering',
            opaque_acrylic: 'Ogenomskinlig akryl',
            pc_printing: 'PC - utskrift',
            rubber_bw_engraving: 'Gummi - gravering',
            silver_engraving: 'Silver - gravering',
            stainless_steel_bw_engraving_diode: 'Rostfritt stål - gravering (diodlaser)',
            stainless_steel_dark: 'Rostfritt stål (mörk)',
            stainless_steel_engraving: 'Rostfritt stål - gravering',
            stainless_steel_light: 'Rostfritt stål (ljus)',
            stainless_steel_printing: 'Rostfritt stål - utskrift',
            steel_engraving_spray_engraving: 'Metall - gravering',
            stone: 'Sten',
            ti_engraving: 'Titan - gravering',
            titanium_dark: 'Titan (mörk)',
            titanium_light: 'Titan (ljus)',
            white_abs: 'Vit ABS',
            wood_3mm_cutting: 'Trä - 3 mm skärning',
            wood_5mm_cutting: 'Trä - 5 mm skärning',
            wood_7mm_cutting: 'Trä - 7 mm skärning',
            wood_8mm_cutting: 'Trä - 8 mm skärning',
            wood_10mm_cutting: 'Trä - 10 mm skärning',
            wood_engraving: 'Trä - gravering',
            wood_printing: 'Trä - utskrift',
          },
          parameters: 'Förinställningar',
          save: 'Lägg till aktuella inställningar',
        },
        engrave: 'Gravera',
        existing_name: 'Det här namnet har redan använts.',
        fill_angle: 'Fyll Vinkel',
        fill_interval: 'Fyll intervall',
        fill_setting: 'Fyllningsinställningar',
        filled_path_only: 'Endast för fyllnadsbanor',
        focus_adjustment: 'Fokusjustering',
        frequency: 'Frekvens',
        gradient_only: 'Endast för gradientbilder',
        halftone: 'Halvton',
        halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
        height: 'Objekthöjd',
        ink_saturation: 'Mättnad',
        ink_type: {
          normal: 'Bläck',
          text: 'Bläcktyp',
          UV: 'UV-bläck',
        },
        laser_speed: {
          fast: 'Snabb',
          max: 300,
          min: 3,
          slow: 'Långsam',
          step: 0.1,
          text: 'Hastighet',
          unit: 'mm/s',
        },
        low_power_warning: 'Lägre laserstyrka (under 10%) kanske inte avger laserljuset.',
        low_speed_warning: 'Låg hastighet kan orsaka brännskador på materialet.',
        lower_focus: 'Sänk Fokus',
        lower_focus_desc: 'Sänka fokus höjden med ett specifikt avstånd efter fokusering för att förbättra skärprestanda.',
        module: 'Modul',
        more: 'Hantera',
        multi_layer: 'Flera lager',
        para_in_use: 'Den här parametern används.',
        parameters: 'Parametrar',
        power: {
          high: 'Hög',
          low: 'Låg',
          max: 100,
          min: 1,
          step: 0.1,
          text: 'Effekt',
        },
        preset_management: {
          add_new: 'Lägg till Ny',
          delete: 'Ta bort',
          export: 'Exportera',
          export_preset_title: 'Exportera förinställningar',
          import: 'Importera',
          laser: 'Laser',
          lower_focus_by: 'Sänk Fokus med',
          new_preset_name: 'Nytt förinställningsnamn',
          preset: 'Förinställning',
          print: 'Skriv Ut',
          reset: 'Återställ',
          save_and_exit: 'Spara och avsluta',
          show_all: 'Visa Alla',
          sure_to_import_presets: 'Detta kommer att ladda arrangemanget av förinställningar och skriva över anpassade parametrar, är du säker på att du vill fortsätta?',
          sure_to_reset: 'Detta kommer att ta bort dina anpassade parametrar och återställa alla förinställningar, är du säker på att du vill fortsätta?',
          title: 'Hantera Parametrar',
          wobble_diameter: 'Wobble-diameter',
          wobble_step: 'Wobble-stegstorlek',
        },
        preset_setting: 'Inställningar (%s)',
        presets: 'Standardinställning',
        print_multipass: 'Fler-pass',
        promark_speed_desc: 'Hastighetsparametrar gäller inte för gradientbilder.',
        pulse_width: 'Pulsbredd',
        pwm_advanced_desc: 'Ställ in en minimieffekt för djupinställningar.',
        pwm_advanced_hint: 'Denna inställning gäller specifikt för gradientbilder när du använder djupinställningar.',
        pwm_advanced_setting: 'Djupinställningar för Strömläge',
        repeat: 'Passantal',
        single_color: 'Enkelfärg',
        single_color_desc: 'Endast tillämpligt på fullfärgs lager och kan inte användas med expanderad enfärgs lager.',
        slider: {
          fast: 'Snabb',
          high: 'Hög',
          low: 'Låg',
          regular: 'Vanlig',
          slow: 'Långsam',
          very_fast: 'Snabbast',
          very_high: 'Högsta',
          very_low: 'Lägsta',
          very_slow: 'Långsammast',
        },
        speed: 'Hastighet',
        speed_constrain_warning: 'Skärhastigheten för vektorbanor kommer att begränsas till %(limit)s. Du kan ta bort denna gräns i inställningarna.',
        speed_constrain_warning_auto_feeder: 'Skärhastigheten för Auto Feeder-objekt kommer att begränsas till %(limit)s. Du kan ta bort denna gräns i Inställningar för preferenser.',
        speed_constrain_warning_curve_engraving: 'Hastigheten för kurvgravering kommer att begränsas till %(limit)s. Du kan ta bort denna gräns i inställningarna för preferenser.',
        stepwise_focusing: 'Stegvis Fokusering',
        stepwise_focusing_desc: 'Sänka successivt fokusavståndet baserat på objektets höjd under varje pass antal.',
        strength: 'Effekt',
        times: 'gånger',
        various_preset: 'Olika förinställningar',
        white_ink: 'Vitt bläck',
        white_ink_settings: 'Inställningar för vitt bläck',
        wobble: 'Vibration',
        wobble_desc: 'Skapar små cirklar längs vägen, vilket är fördelaktigt för skäruppgifter. (Endast för banaobjekt.)',
        wobble_diameter: 'Diameter',
        wobble_step: 'Stegstorlek',
        z_step: 'Z-steg',
      },
      layer_panel: {
        current_layer: 'Aktuellt lager',
        layer1: 'Lager 1',
        layer_bitmap: 'Bitmap',
        layer_cutting: 'Skärning',
        layer_engraving: 'Gravering',
        layers: {
          del: 'Ta bort lager',
          dupe: 'Duplicera lager',
          fullColor: 'Fullfärg',
          layer: 'Lager',
          layers: 'Lager',
          lock: 'Lås lager',
          merge_all: 'Slå samman alla',
          merge_down: 'Slå samman nedåt',
          merge_selected: 'Slå samman valda lager',
          move_down: 'Flytta lager nedåt',
          move_elems_to: 'Flytta element till:',
          move_selected: 'Flytta valda element till ett annat lager',
          move_up: 'Flytta lager uppåt',
          new: 'Nytt lager',
          rename: 'Byt namn på lager',
          splitFullColor: 'Expandera lager',
          switchToFullColor: 'Växla till fullfärgs-lager',
          switchToSingleColor: 'Växla till en-färgs-lager',
          unlock: 'Lås upp',
        },
        move_elems_to: 'Flytta objekt till:',
        notification: {
          dupeLayerName: 'Det finns redan ett lager med det namnet!',
          enterNewLayerName: 'Ange det nya lagernamnet',
          enterUniqueLayerName: 'Ange ett unikt lagernamn',
          layerHasThatName: 'Lagret har redan det namnet',
          mergeLaserLayerToPrintingLayerMsg: 'Observera att om du slutför den här åtgärden kommer laser-lagrets inställningar att tas bort och ställas in enligt aktuellt lager.',
          mergeLaserLayerToPrintingLayerTitle: 'Vill du slå samman dessa lager till ett utskriftslager?',
          mergePrintingLayerToLaserLayerMsg: 'Observera att om du slutför den här åtgärden kommer färginställningarna för utskrifts-lagret att tas bort och ställas in enligt aktuellt lager.',
          mergePrintingLayerToLaserLayerTitle: 'Vill du slå samman dessa lager till ett laser-lager?',
          moveElemFromPrintingLayerMsg: 'Observera att om du slutför den här åtgärden kommer färginställningarna för det valda elementet att tas bort och ställas in enligt %s.',
          moveElemFromPrintingLayerTitle: 'Flytta valt element till %s och konvertera det till laser-element?',
          moveElemToPrintingLayerMsg: 'Observera att om du slutför den här åtgärden kommer inställningarna för det valda elementet att tas bort och ställas in enligt %s.',
          moveElemToPrintingLayerTitle: 'Flytta valt element till %s och konvertera det till utskrifts-element?',
          newName: 'NYTT NAMN',
          QmoveElemsToLayer: "Flytta markerade objekt till lager '%s'?",
          splitColorMsg: 'Observera att om du fortsätter med den här åtgärden kan du inte återgå till de ursprungliga färgskikten.',
          splitColorTitle: 'Vill du expandera det valda lagret till CMYK-lager?',
        },
      },
      object_panel: {
        actions_panel: {
          ai_bg_removal: 'Ta bort bakgrunden',
          ai_bg_removal_reminder: 'Tryck på knappen kommer omedelbart att använda 0,2 kredit, vill du fortsätta?',
          ai_bg_removal_short: 'BG bort',
          array: 'Fält',
          auto_fit: 'Auto Fit',
          bevel: 'Fasa',
          brightness: 'Ljusstyrka',
          convert_to_path: 'Konvertera till bana',
          create_textpath: 'Skapa text på bana',
          create_textpath_short: 'Text på bana',
          crop: 'Beskär',
          decompose_path: 'Dela upp',
          detach_path: 'Dela upp text på bana',
          detach_path_short: 'Dela upp',
          disassemble_use: 'Demontera',
          disassembling: 'Demonterar...',
          edit_path: 'Redigera bana',
          fetching_web_font: 'Hämtar webbfont...',
          grading: 'Gradering',
          invert: 'Invertera',
          offset: 'Förskjutning',
          outline: 'Kontur',
          replace_with: 'Ersätt med...',
          replace_with_short: 'Ersätt',
          sharpen: 'Skärpa',
          simplify: 'Förenkla',
          smart_nest: 'Smart Nest',
          trace: 'Spåra',
          ungrouping: 'Avgrupperar...',
          uploading_font_to_machine: 'Laddar upp font till maskin...',
          wait_for_parsing_font: 'Tolkar font... Vänta en sekund',
          weld_text: 'Svetsa text',
        },
        align: 'Justera',
        boolean: 'Boolesk',
        bottom_align: 'Bottenjustera',
        center_align: 'Centrera',
        difference: 'Skillnad',
        distribute: 'Distribuera',
        flip: 'Vänd',
        group: 'Gruppera',
        hdist: 'Distribuera horisontellt',
        hflip: 'Horisontell vändning',
        intersect: 'Skärning',
        left_align: 'Vänsterjustera',
        lock_aspect: 'Lås proportioner',
        middle_align: 'Mittenjustera',
        option_panel: {
          color: 'Färg',
          fill: 'Fyllning',
          font_family: 'Typsnitt',
          font_size: 'Storlek',
          font_style: 'Stil',
          letter_spacing: 'Bokstavsavstånd',
          line_spacing: 'Radavstånd',
          path_infill: 'Banaifyllnad',
          pwm_engraving: 'Djup-läge',
          pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
          rounded_corner: 'Rundade hörn',
          shading: 'Gradient',
          sides: 'Sidor',
          start_offset: 'Textförskjutning',
          stroke: 'Kontur',
          stroke_color: 'Konturfärg',
          stroke_width: 'Linjebredd',
          text_infill: 'Textifyllnad',
          threshold: 'Ljusstyrketröskel',
          threshold_short: 'Tröskel',
          vertical_align: 'Justering',
          vertical_text: 'Vertikal text',
        },
        path_edit_panel: {
          connect: 'Anslut',
          delete: 'Ta bort',
          disconnect: 'Koppla från',
          node_type: 'NODTYP',
          round: 'Rund',
          sharp: 'Skarp',
        },
        right_align: 'Högerjustera',
        subtract: 'Subtrahera',
        top_align: 'Toppjustera',
        ungroup: 'Dela upp',
        union: 'Förena',
        unlock_aspect: 'Lås upp proportioner',
        vdist: 'Distribuera vertikalt',
        vflip: 'Vertikal vändning',
        zoom: 'Zooma',
      },
      tabs: {
        layers: 'Lager',
        objects: 'Objekt',
        path_edit: 'Banas redigering',
      },
    },
    shapes_panel: {
      animals: 'Djur',
      arrow: 'Pil',
      basic: 'Grundläggande',
      birds: 'Fågel',
      celebration: 'Firande',
      circular: 'Cirkulär',
      CNY: 'Kinesiskt nyår',
      corner: 'Hörn',
      decor: 'Dekor',
      easter: 'Påsk',
      elements: 'Element',
      environment: 'Miljö',
      graphics: 'Grafik',
      halloween: 'Halloween',
      holidays: 'Helgdag',
      label: 'Etikett',
      land: 'Land',
      line: 'Linje',
      nature: 'Natur',
      photo: 'Foto',
      plants: 'Växter',
      ribbon: 'Band',
      sea: 'Hav',
      shape: 'Form',
      speech: 'Tal',
      text: 'Textruta',
      title: 'Objekt',
      valentines: 'Alla hjärtans dag',
      weather: 'Väder',
      Xmas: 'Jul',
    },
    svg_editor: {
      unable_to_fetch_clipboard_img: 'Misslyckades att hämta bild från urklipp',
      unnsupported_file_type: 'Filtypen stöds inte direkt. Vänligen konvertera filen till SVG eller bitmap',
    },
    tag: {
      g: 'Grupp',
      image: 'Bild',
      text: 'Text',
      use: 'Importera SVG',
    },
    time_est_button: {
      calculate: 'Uppskatta tid',
      estimate_time: 'Uppskattad tid:',
    },
    tool_panels: {
      _nest: {
        end: 'Stäng',
        no_element: 'Det finns inga element att optimera.',
        rotations: 'Möjliga rotationer',
        spacing: 'Mellanrum',
        start_nest: 'Optimera',
        stop_nest: 'Stoppa',
      },
      _offset: {
        corner_type: 'Hörn',
        direction: 'Förskjutningsriktning',
        dist: 'Förskjutningsavstånd',
        fail_message: 'Misslyckades att förskjuta objekt.',
        inward: 'Inåt',
        not_support_message: 'Valda element som innehåller ej supportade SVG-taggar:\nBild, Grupp, Text och Importerat Objekt.',
        outward: 'Utåt',
        round: 'Rund',
        sharp: 'Skarp',
      },
      array_dimension: 'Arraydimension',
      array_interval: 'Arrayintervall',
      cancel: 'Avbryt',
      columns: 'Kolumner',
      confirm: 'Bekräfta',
      dx: 'X',
      dy: 'Y',
      grid_array: 'Skapa rutnätsarray',
      nest: 'Optimering',
      offset: 'Förskjutning',
      rows: 'Rader',
    },
    units: {
      mm: 'mm',
      walt: 'W',
    },
    zoom_block: {
      fit_to_window: 'Anpassa till fönster',
    },
  },
  boxgen: {
    add_option: 'Lägg till alternativ',
    basic_box: 'Grundläggande låda',
    beam_radius: 'Kompensation för skärstråle',
    beam_radius_warning: 'Ta bort kompensation för sågkärv när kanterna eller fogarna på lådan är korta för att säkerställa montering',
    cancel: 'Avbryt',
    coming_soon: 'Kommer snart',
    continue_import: 'Fortsätt importera',
    control_tooltip: 'Vänster mus för att rotera\nScrolla för att zooma\nHöger mus för att panorera',
    control_tooltip_touch: 'Dra för att rotera\nNypa för att zooma\nTvå fingrar för att panorera',
    cover: 'Lock',
    customize: 'Anpassa',
    depth: 'Djup',
    edge: 'Kant',
    finger: 'Finger',
    finger_warning: 'Lådans inre sidlängd måste vara minst 6 mm (0,24 tum) för att vara kompatibel med fingerskarvning.',
    height: 'Höjd',
    import: 'Importera',
    inner: 'Inre',
    joints: 'Fog',
    max_dimension_tooltip: 'Max bredd/höjd/djup inställning är %s.',
    merge: 'Sammanfoga',
    outer: 'Yttre',
    reset: 'Återställ',
    tCount: 'T Antal',
    tCount_tooltip: 'Antalet T-spår gäller den korta sidan; kvantiteten på den långa sidan beräknas baserat på dess längd.',
    tDiameter: 'T Diameter',
    text_label: 'Etikett',
    thickness: 'Tjocklek',
    title: 'BOXGEN',
    tLength: 'T Längd',
    tSlot: 'T-spår',
    tSlot_warning: 'Lådans sidlängd måste vara minst 30 mm (1,18 tum) för att vara kompatibel med T-spårskarvning.',
    volume: 'Volym',
    width: 'Bredd',
    workarea: 'Arbetsområde',
    zoom: 'Zooma',
  },
  buttons: {
    back: 'TILLBAKA',
    back_to_beam_studio: 'Tillbaka till Beam Studio',
    done: 'KLAR',
    next: 'NÄSTA',
  },
  calibration: {
    ador_autofocus_focusing_block: 'Tryck på "AF"-ikonen på maskinens huvudsida i 3 sekunder och låt sonden vidröra fokuseringsblocket.',
    ador_autofocus_material: 'Tryck på "AF"-ikonen på maskinens huvudsida i 3 sekunder och låt sonden vidröra materialet mjukt.',
    align_ilb: 'Position: Justera med den graverade punkten på insidan nere till vänster.',
    align_ilt: 'Position: Justera med den graverade punkten på insidan uppe till vänster.',
    align_irb: 'Position: Justera med den graverade punkten på insidan nere till höger.',
    align_irt: 'Position: Justera med den graverade punkten på insidan uppe till höger.',
    align_olb: 'Position: Justera med den graverade punkten på utsidan nere till vänster.',
    align_olt: 'Position: Justera med den graverade punkten på utsidan uppe till vänster.',
    align_orb: 'Position: Justera med den graverade punkten på utsidan nere till höger.',
    align_ort: 'Position: Justera med den graverade punkten på utsidan uppe till höger.',
    align_red_cross_cut: 'Justera mitten av det röda korset med skärkorset.',
    align_red_cross_print: 'Justera mitten av det röda korset med det utskrivna korset.',
    analyze_result_fail: 'Misslyckades med att analysera den fångade bilden. <br/> Kontrollera: <br/> 1. Bilden täcks helt av vitt papper. <br/> 2. Plattformen är korrekt fokuserad.',
    ask_for_readjust: 'Vill du hoppa över gravyret och sedan ta en bild och kalibrera direkt?',
    back: 'Bakåt',
    calculating_camera_matrix: 'Beräknar kameramatris...',
    calculating_regression_parameters: 'Beräknar regressionsparametrar...',
    calibrate_camera_before_calibrate_modules: 'Utför kamerakalibrering innan modulkalibrering.',
    calibrate_chessboard_success_msg: 'Schackbrädesfotot har fångats framgångsrikt.<br/>Poängen för detta foto är %s (%.2f).',
    calibrate_done: 'Kalibrering klar. Bättre kameranoggrannhet vid korrekt fokus.',
    calibrate_done_diode: 'Kalibrering klar. Förskjutning för diodlasermodul sparad.',
    calibrating: 'Kalibrerar...',
    calibrating_with_device_pictures: 'Kalibrerar med enhetsbilder...',
    camera_calibration: 'Kamerakalibrering',
    camera_parameter_saved_successfully: 'Kameraparametrar sparade framgångsrikt.',
    cancel: 'Avbryt',
    check_checkpoint_data: 'Kontrollpunktsdata',
    check_device_pictures: 'Kontrollera enhetsbilder',
    checking_checkpoint: 'Kontrollerar kontrollpunktsdata...',
    checking_pictures: 'Kontrollerar enhetsbilder...',
    diode_calibration: 'Diodlasermodulkalibrering',
    do_engraving: 'Utför Gravyr',
    download_chessboard_file: 'Ladda ner schackbrädefil',
    downloading_checkpoint: 'Laddar ner kontrollpunktsdata...',
    downloading_pictures: 'Laddar ner bilder...',
    drawing_calibration_image: 'Ritar kalibreringsbild...',
    dx: 'X',
    dy: 'Y',
    elevate_and_cut: 'Lyft och Skär',
    elevate_and_cut_step_1: 'Placera en ljusfärgad träbit i A4-storlek i mitten av arbetsytan och lyft den till 20 mm höjd.',
    elevate_and_cut_step_1_prism_lift: 'Använd Ador Prism Lift med sin maximala längd på 14 mm tillsammans med ett trästycke med minst 6 mm tjocklek för att lyfta träet till en höjd av 20 mm.',
    failed_to_calibrate_camera: '#848 Det gick inte att kalibrera kameran, kontakta FLUX-supporten.',
    failed_to_calibrate_chessboard: 'Misslyckades med att kalibrera med schackbrädsbilden.',
    failed_to_calibrate_with_pictures: '#848 Misslyckades med att kalibrera med enhetsbilder.',
    failed_to_download_pictures: '#848 Det gick inte att ladda ner bilder, kontakta FLUX-supporten.',
    failed_to_move_laser_head: 'Misslyckades med att flytta laserhuvudet.',
    failed_to_parse_checkpoint: 'Misslyckades med att analysera kontrollpunktsdata.',
    failed_to_save_calibration_results: '#849 Det gick inte att spara kalibreringsresultat, försök igen. Om detta fortsätter hända, kontakta FLUX-supporten.',
    failed_to_save_camera_parameter: 'Misslyckades med att spara kameraparametrarna.',
    failed_to_solve_pnp: 'Kunde inte lösa kamerapositionen.',
    finish: 'Klar',
    found_checkpoint: 'Kontrollpunktsdata hittades på din enhet. Vill du återställa från kontrollpunkten?',
    getting_plane_height: 'Hämtar Planhöjd...',
    hint_adjust_parameters: 'Använd dessa parametrar för att justera den röda rutan',
    hint_red_square: 'Justera den röda rutans kant med den skurna rutan.',
    module_calibration_2w_ir: 'Infraröd modulkalibrering',
    module_calibration_printer: 'Skrivarmodulkalibrering',
    moving_laser_head: 'Flyttar laserhuvudet...',
    next: 'Nästa',
    no_picutre_found: '#846 Din enhet har inga tillgängliga råbilder för kalibrering. Vänligen kontakta FLUX-supporten.',
    perform_autofocus_bb2: 'Gå till maskinens kontrollpanel och tryck på AF för att utföra autofokus.',
    please_do_camera_calibration_and_focus: {
      beambox: 'När kalibrering av diodlasermodul utförs behövs kamera.\nSe till att kameran på den här maskinen är kalibrerad.\nOch justera plattformen till fokalpunkten (höjden på den vända akrylen)',
      beamo: 'När kalibrering av diodlasermodul utförs behövs kamera.\nSe till att kameran på den här maskinen är kalibrerad.\nOch justera laserhuvudet för att fokusera på graveringsobjektet (höjden på den vända akrylen)',
    },
    please_goto_beambox_first: 'Växla till graveringsläge (Beambox) för att kunna använda den här funktionen.',
    please_place_dark_colored_paper: 'Placera ett A4- eller brevpappersark i mörkt färgat papper i mitten av arbetsområdet.',
    please_place_paper: 'Placera ett A4- eller brevpappersark i vitt papper i det övre vänstra hörnet av arbetsområdet.',
    please_place_paper_center: 'Placera ett A4- eller brevpappersark i vitt papper i mitten av arbetsområdet.',
    please_refocus: {
      beambox: 'Justera plattformen till fokalpunkten (höjden på den vända akrylen).',
      beamo: 'Justera laserhuvudet för att fokusera på graveringsobjektet (höjden på den vända akrylen).',
      beamo_af: 'Dubbelklicka på sidoknappen på autofokustillägget och låt sonden mjukt vidröra materialet.',
      hexa: 'Dubbelklicka på höjdjusteringsknappen för att höja honungskakan uppåt och låta sonden vidröra graveringsmaterialet.',
    },
    preparing_to_take_picture: 'Förbereder för att ta bild...',
    promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    put_chessboard: 'Placera Schackbrädet',
    put_chessboard_1: 'Gå till maskinens kontrollpanel och tryck på AF för att utföra autofokus.',
    put_chessboard_2: 'Flytta schackbrädepappret eller laserhuvudet tills hela schackbrädet är bekräftat att vara inom den röda ramen i fönstret för liveförhandsgranskning.',
    put_chessboard_3: 'Högerklicka för att ladda ner schackbrädesbilden på den aktuella platsen och kontrollera dess skärpa.',
    put_chessboard_bb2_desc_1: 'Ladda ner följande schackbrädefil och skriv ut den på A4-papper (det utskrivna schackbrädet ska ha rutor som är 1x1 cm).',
    put_chessboard_bb2_desc_2: 'Fäst det utskrivna schackbrädet på en icke-deformerbar skiva, som akryl eller glas, och se till att schackbrädet är slätt och utan veck eller upphöjda kanter.',
    put_chessboard_bb2_desc_3: 'Placera skivan med schackbrädet plant i mitten av arbetsområdet.',
    put_chessboard_promark_1: 'Justera brännvidden till rätt fokus för fältlinsen.',
    put_chessboard_promark_2: 'Se till att rutnätspappret är tydligt synligt utan bländning. Klicka på "Nästa" för att ta en bild.',
    put_chessboard_promark_desc_1: 'Använd det rutnätspapper som medföljer i tillbehörslådan, eller skriv ut följande rutnätmönster på A4-papper för kamerakalibrering.',
    put_chessboard_promark_desc_2: 'Placera schackbrädet plant och centrerat på arbetsplattformen.',
    put_paper: 'Lägg Papper',
    put_paper_promark_1: 'Placera det svarta kartongarket från tillbehörslådan på arbetsplattformen.',
    put_paper_promark_2: 'Justera fokuset korrekt och klicka sedan på "Starta Gravyr" för att fortsätta med graveringen.',
    put_paper_skip: 'Om kalibreringsritningen inte automatiskt går vidare till att ta bild, tryck på "Hoppa över" för att ta bild.',
    put_paper_step1: 'Vänligen placera ett vitt A4- eller brevpappersark i mitten av arbetsytan.',
    put_paper_step2: 'Säkra de fyra hörnen på papperet för att säkerställa att det ligger plant.',
    put_paper_step3: 'Klicka på "Starta Gravyr".',
    res_average: 'Genomsnittlig',
    res_excellent: 'Utmärkt',
    res_poor: 'Dålig',
    retake: 'Ta om bild',
    rotation_angle: 'Rotation',
    show_last_config: 'Visa senaste resultat',
    skip: 'Hoppa Över',
    solve_pnp_step1: 'Justera gravyrpunkterna enligt numret och den förväntade positionen för varje röd markörpunkt.',
    solve_pnp_step2: 'Du kan trycka på "Ta om bild" för att justera om eller justera markörpositionerna manuellt.',
    solve_pnp_step3: 'Om den tagna bilden inte matchar den verkliga scenen, zooma eller dra skärmen för att justera kalibreringspunkten på den svarta kalibreringskortet till mitten och sedan försöka "Ta om bild".',
    solve_pnp_title: 'Justera markörpunkterna',
    start_engrave: 'Starta Gravyr',
    start_printing: 'Starta Utskrift',
    taking_picture: 'Tar bild...',
    unable_to_load_camera_parameters: "#851 Det finns inga kamerakalibreringsparametrar tillgängliga på din enhet. Gå till 'Kalibrering' > 'Kalibrera kamera (Avancerad)' för att slutföra kalibreringen och få parametrarna.",
    update_firmware_msg1: 'Din firmware stöder inte den här funktionen. Uppdatera firmware till v',
    update_firmware_msg2: 'eller senare för att fortsätta。 (Meny > Maskin > [Din maskin] > Uppdatera firmware)',
    uploading_images: 'Laddar upp bilder...',
    use_last_config: 'Använd senaste kalibreringsvärden',
    use_old_camera_parameter: 'Vill du använda aktuella kamerans objektivparametrar?',
    with_af: 'Med autofokus',
    with_prism_lift: 'Med Ador Prism Lift',
    without_af: 'Utan autofokus',
    without_prism_lift: 'Utan Ador Prism Lift',
    x_ratio: 'X-förhållande',
    y_ratio: 'Y-förhållande',
    zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
  },
  camera_data_backup: {
    download_success: 'Kameradata har laddats ner framgångsrikt.',
    downloading_data: 'Laddar ner data...',
    estimated_time_left: 'Beräknad återstående tid:',
    folder_not_exists: 'Den valda mappen finns inte.',
    incorrect_folder: 'Misslyckades att ladda upp kalibreringsdata. Kontrollera om den valda mappen är korrekt.',
    no_picture_found: 'Inget bild hittades i maskinen.',
    title: 'Kamera Data Backup',
    upload_success: 'Kameradata har laddats upp framgångsrikt.',
    uploading_data: 'Laddar upp data...',
  },
  caption: {
    connectionTimeout: 'Anslutningstidsgräns',
  },
  change_logs: {
    added: 'Tillagt:',
    change_log: 'Ändringsloggar:',
    changed: 'Ändrat:',
    fixed: 'Fixat:',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    see_older_version: 'Se äldre versioner',
  },
  code_generator: {
    barcode: 'Streckkod',
    qr_code: 'QR-kod',
  },
  curve_engraving: {
    '921': 'Misslyckades med autofokus.',
    '922': 'Misslyckades med att utföra mätning med rött ljus.',
    amount: 'Mängd',
    apply_arkwork: 'Applicera konstverk på kurva',
    apply_camera: 'Applicera kamerabild på kurva',
    click_to_select_point: 'Klicka för att välja eller avmarkera punkter för att mäta igen.',
    coloumns: 'Kolumner',
    column_gap: 'Kolumnavstånd',
    err_object_over_range: 'Objektet överskrider mätområdet.',
    failed_to_take_reference: 'Misslyckades med att ta referens',
    gap: 'Avstånd',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    measure_audofocus_area: 'Mät autofokusområde',
    preview_3d_curve: 'Förhandsvisning av kurvgravering',
    remeasure: 'Mät om',
    remeasuring_points: 'Mäter om punkter...',
    reselect_area: 'Välj område igen',
    row_gap: 'Radavstånd',
    rows: 'Rader',
    set_object_height: 'Ställ in objektets höjd',
    set_object_height_desc: 'Mät objektets maximala tjocklek.',
    start_autofocus: 'Starta autofokus',
    starting_measurement: 'Startar mätning...',
    sure_to_delete: 'Vill du ta bort fokusdata för kurvgravering?',
    take_reference: 'Ta referens',
    take_reference_desc: 'Flytta laserkoppen till objektets högsta punkt, sänk fokussonden och klicka på "Bekräfta" för att fokusera.',
  },
  device: {
    abort: 'Avbryt',
    aborted: 'Avbruten',
    aborting: 'Avbryter',
    busy: 'Upptagen',
    cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
    cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
    cartridge_serial_number: 'Ink cartridge serial number',
    close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
    completed: 'Slutförd',
    completing: 'Slutför',
    deviceList: 'Maskinlista',
    disable: 'Inaktivera',
    disconnectedError: {
      caption: 'Maskinen frånkopplad',
      message: 'Vänligen kontrollera om nätverksåtkomsten för %s är tillgänglig',
    },
    enable: 'Aktivera',
    firmware_version: 'Firmware-version',
    ink_color: 'Ink color',
    ink_level: 'Ink level',
    ink_type: 'Ink type',
    IP: 'IP',
    model_name: 'Modellnamn',
    occupied: 'Underhåller',
    pause: 'Pausa',
    paused: 'Pausad',
    pausedFromError: 'Pausad på grund av fel',
    pausing: 'Pausar',
    please_wait: 'Vänta...',
    preparing: 'Förbereder',
    processing: 'Bearbetar',
    quit: 'Avsluta',
    ready: 'Redo',
    reset: 'Återställ',
    resuming: 'Återupptar',
    retry: 'Försök igen',
    running: 'Arbetar',
    scanning: 'Skannar',
    select: 'Välj',
    select_printer: 'Välj skrivare',
    serial_number: 'Serienummer',
    start: 'Starta',
    starting: 'Startar',
    status: 'Status',
    submodule_type: 'Module',
    toolhead_change: 'Byt verktygshuvud',
    unknown: 'Okänd',
    uploading: 'Laddar upp',
    UUID: 'UUID',
  },
  device_selection: {
    no_beambox: '#801 Vi hittar inte din maskin i nätverket.\nFölj <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">guiden</a> för att felsöka anslutningsproblemet.',
    no_device_web: "#801 Kontrollera statusen för din maskin, eller klicka på 'Maskininställning' nedan för att ställa in maskinen.",
    select_usb_device: 'Välj USB-enhet',
  },
  editor: {
    exposure: 'Förhandsvisningsexponering',
    opacity: 'Förhandsvisningsgenomskinlighet',
    prespray_area: 'Primeringsyta',
  },
  error_pages: {
    screen_size: 'Observera att Beam Studio kanske inte fungerar optimalt på din enhet. För bästa upplevelse bör enheten ha en skärmstorlek på minst 1024 bildpunkter.',
  },
  flux_id_login: {
    connection_fail: '#847 Misslyckades att ansluta till FLUX medlemstjänst.',
    email: 'E-post',
    flux_plus: {
      access_monotype_feature: 'Du har inte Monotype Fonts-tillägget.',
      access_monotype_feature_note: 'Du måste ha FLUX+ Pro-medlemskap eller Monotype Fonts-tillägget för att använda den här funktionen.',
      access_plus_feature_1: 'Du använder en',
      access_plus_feature_2: 'funktion.',
      access_plus_feature_note: 'Du måste ha FLUX+ medlemskap för att använda den här funktionen.',
      ai_credit_tooltip: 'För AI-bakgrundsborttagning',
      explore_plans: 'Utforska FLUX+ planer',
      features: {
        ai_bg_removal: 'AI bakgrundsborttagning',
        boxgen: '3D lådgenerator',
        dmkt: '1000+ designfiler',
        monotype: '250+ premiumtypsnitt',
        my_cloud: 'Obegränsad molnlagring',
      },
      flux_credit_tooltip: 'För designmarknadsfiler och AI-bakgrundsborttagning',
      get_addon: 'Skaffa tillägg',
      goto_member_center: 'Gå till medlemscenter',
      learn_more: 'Läs mer',
      member_center_url: 'https://member.flux3dp.com/en-US/subscription',
      subscribe_now: 'Prenumerera nu',
      thank_you: 'Tack för att du är en värdefull medlem!',
      website_url: 'https://flux3dp.com/subscription',
    },
    forget_password: 'Glömt lösenord?',
    incorrect: 'Fel e-postadress eller lösenord.',
    login: 'Logga in',
    login_success: 'Inloggningen lyckades.',
    lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
    new_to_flux: 'Ny på FLUX? Skapa ett konto.',
    not_verified: 'E-postadressen har inte verifierats ännu.',
    offline: 'Arbeta offline',
    password: 'Lösenord',
    register: 'Skapa ditt FLUX-konto',
    remember_me: 'Kom ihåg mig',
    signup_url: 'https://id.flux3dp.com/user/login#up',
    unlock_shape_library: 'Logga in för att låsa upp formdatabasen.',
    work_offline: 'Arbeta offline',
  },
  framing: {
    area_check: 'Områdeskontroll',
    areacheck_desc: 'Säkerställer att arbetsområdet är säkert genom att förhandsgranska objektets begränsningsram och laserns accelerationszon.',
    calculating_task: 'Beräknar uppgift...',
    framing: 'Inramning',
    framing_desc: 'Förhandsgranskar objektets avgränsningsruta.',
    hull: 'Skrov',
    hull_desc: 'Förhandsvisar en form som följer designen noggrant, som ett gummiband lindat runt objektet.',
    low_laser: 'Låg laser',
    low_laser_desc: 'Ställ in ett lågeffektslaservärde för inramningsuppgiften.',
    rotate_axis: 'Rotationsaxel',
    rotateaxis_desc: 'Förhandsgranskar rotationens axelcenter, själva axeln rör sig inte.',
    rotation_frame_warning: 'Ramförhandsgranskningen kommer att rotera en gång. Undvik att stoppa halvvägs för att förhindra att markeringspositionen förskjuts.',
    rotation_framing_desc: 'Förhandsgranskar hela rotationsområdet, axeln roterar enligt förhandsgranskningen.',
    start_task: 'เริ่มงาน',
    start_task_description: 'หลังจากแสดงตัวอย่างเฟรมเสร็จสิ้น ให้คลิก "เริ่มงาน" เพื่อส่งงาน.',
  },
  general: {
    choose_folder: 'Välj mapp',
    processing: 'Bearbetar...',
  },
  generic_error: {
    OPERATION_ERROR: '[OE] En statuskonflikt inträffade, försök igen.',
    SUBSYSTEM_ERROR: '[SE] Fel vid maskinvarans programvara när uppgiften utfördes. Starta om maskinen.',
    UNKNOWN_COMMAND: '[UC] Uppdatera maskinvarans programvara',
    UNKNOWN_ERROR: '[UE] Ett okänt fel inträffade. Starta om Beam Studio och maskinen.',
  },
  global: {
    apply: 'Tillämpa',
    back: 'Tillbaka',
    cancel: 'Avbryt',
    editing: {
      redo: 'Gör om',
      reset: 'Återställ',
      undo: 'Ångra',
      zoom_in: 'Zooma in',
      zoom_out: 'Zooma ut',
    },
    mode_conflict: 'Detta alternativ är inte tillgängligt i aktuellt läge.',
    ok: 'OK',
    save: 'Spara',
    stop: 'Stoppa',
  },
  image_edit_panel: {
    eraser: {
      brush_size: 'Penselstorlek',
      description: 'Klicka eller dra för att manuellt radera oönskade områden.',
      title: 'Radergummi',
    },
    magic_wand: {
      description: 'Välj och ta bort angränsande områden baserat på färglikhet.',
      title: 'Magisk Stav',
      tolerance: 'Tolerans',
    },
    title: 'Redigera Bild',
  },
  initialize: {
    back: 'Tillbaka',
    cancel: 'Avbryt',
    confirm: 'Bekräfta',
    connect: 'Anslut',
    connect_ethernet: {
      title: 'Direktanslutning',
      tutorial1: '1. Anslut maskinen till din dator med en Ethernet-kabel.',
      tutorial2_1: '2. Följ ',
      tutorial2_2: ' för att konfigurera din dator som en router.',
      tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
      tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
      tutorial2_a_text: 'denna guide',
      tutorial3: '3. Klicka på Nästa.',
    },
    connect_machine_ip: {
      alert: {
        swiftray_connection_error: 'Det gick inte att ansluta till servern. Starta om Beam Studio och försök igen.',
      },
      check_camera: 'Kontrollerar kameratillgänglighet',
      check_connection: 'Kontrollerar maskinanslutning',
      check_firmware: 'Kontrollerar firmware-version',
      check_ip: 'Kontrollerar IP-tillgänglighet',
      check_swiftray_connection: 'Kontrollerar serveranslutning',
      check_swiftray_connection_unreachable: 'servern är inte tillgänglig',
      check_usb: 'Kontrollera USB-anslutning',
      enter_ip: 'Ange maskinens IP',
      finish_setting: 'Börja skapa!',
      invalid_format: 'Ogiltigt format',
      invalid_ip: 'Ogiltig IP: ',
      promark_hint: 'Om du upprepade gånger inte kan ansluta via USB, se <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">Hjälpcentrets artikel</a>.',
      retry: 'Försök igen',
      starts_with_169254: 'Börjar med 169.254',
      succeeded_message: 'Ansluten 🎉',
      unreachable: 'IP oåtkomlig',
    },
    connect_usb: {
      connect_camera: 'Anslut maskinens kamera till din dator med en USB-kabel.',
      title: 'USB-anslutning',
      title_sub: ' (endast HEXA)',
      turn_off_machine: 'Stäng av maskinen.',
      turn_on_machine: 'Slå på maskinen.',
      tutorial1: '1. Anslut maskinen till din dator med en USB-kabel.',
      tutorial2: '2. Klicka på Nästa.',
      wait_for_turning_on: 'Klicka på "Nästa" när startprocessen är klar och du har kommit till huvudskärmen.',
    },
    connect_wifi: {
      title: 'Ansluter till Wi-Fi',
      tutorial1: '1. Gå till pekskärmen > Klicka på "Nätverk" > "Anslut till WiFi".',
      tutorial1_ador: '1. Gå till pekskärmen > Klicka på "MASKIN" > Klicka på "Nätverk" > "Anslut till WiFi".',
      tutorial2: '2. Välj och anslut till ditt föredragna Wi-Fi.',
      what_if_1: 'Vad gör jag om jag inte ser mitt Wi-Fi?',
      what_if_1_content: '1. Wi-Fi-krypteringen bör vara WPA2 eller inget lösenord.\n2. Krypteringsläget kan ställas in i Wi-Fi-routerns administrationsgränssnitt. Om routern inte stöder WPA2 och du behöver hjälp med att välja rätt router, kontakta FLUX-supporten.',
      what_if_2: 'Vad händer om jag inte ser något Wi-Fi?',
      what_if_2_content: '1. Se till att Wi-Fi-dongeln är ordentligt ansluten.\n2. Om det inte finns någon MAC-adress för det trådlösa nätverket på pekskärmen, kontakta FLUX-supporten.\n3. Wi-Fi-kanalen bör vara 2,4 GHz (5 GHz stöds inte).',
    },
    connect_wired: {
      title: 'Ansluter till kabelanslutet nätverk',
      tutorial1: '1. Anslut maskinen med din router.',
      tutorial2: '2. Tryck på "Nätverk" för att få den kabelanslutna nätverks-IP:n.',
      tutorial2_ador: '2. Tryck på "MASKIN" > "Nätverk" för att få den trådbundna IP-adressen.',
      what_if_1: 'Vad händer om IP:n är tom?',
      what_if_1_content: '1. Se till att Ethernet-kabeln är ordentligt ansluten.\n2 Om det inte finns någon MAC-adress för det kabelanslutna nätverket på pekskärmen, kontakta FLUX-supporten.',
      what_if_2: 'Vad händer om IP:n börjar med 169?',
      what_if_2_content: '1. Om IP-adressen börjar med 169.254 bör det vara ett DHCP-inställningsproblem, kontakta din internetleverantör för ytterligare hjälp.\n2. Om din dator ansluter direkt till internet med PPPoE, ändra till att ansluta via router med PPPoE och aktivera DHCP-funktionen i routern.',
    },
    connecting: 'Ansluter...',
    connection_types: {
      ether2ether: 'Direktanslutning',
      usb: 'USB-anslutning',
      wifi: 'Wi-Fi',
      wired: 'Trådbundet nätverk',
    },
    next: 'Nästa',
    no_machine: 'Jag har ingen maskin nu.',
    promark: {
      configuration_confirmation: 'Detta säkerställer att din Promark är korrekt konfigurerad för optimal effektivitet och noggrannhet.',
      or_complete_later: `Eller hoppa över detta steg och slutför Promark-inställningarna senare på:
    Maskiner > "Promark Namn" > Promark-inställningar`,
      qc_instructions: 'Fyll i parametrarna som finns på baksidan av "QC Pass"-kortet',
      select_laser_source: 'Välj din Promark',
      select_workarea: 'Välj Arbetsområde',
      settings: 'Promark-inställningar',
    },
    retry: 'Försök igen',
    select_beambox: 'Välj din Beambox',
    select_connection_type: 'Hur vill du ansluta?',
    select_language: 'Välj språk',
    select_machine_type: 'Välj din modell',
    setting_completed: {
      back: 'Tillbaka',
      great: 'Välkommen till Beam Studio',
      ok: 'BÖRJA SKAPA',
      setup_later: 'Du kan alltid konfigurera din maskin från titelraden > "Maskiner" > "Maskinkonfiguration"',
      start: 'Starta',
    },
    skip: 'Hoppa över',
    start: 'Starta',
  },
  input_machine_password: {
    connect: 'ANSLUT',
    password: 'Lösenord',
    require_password: '"%s" kräver ett lösenord',
  },
  insecure_websocket: {
    extension_detected: 'Beam Studio Connect-tillägg upptäckt',
    extension_detected_description: "Vi har upptäckt att du har installerat Beam Studio Connect-tillägget. Klicka på 'Bekräfta' för att omdirigera till HTTPS, eller klicka på 'Avbryt' för att fortsätta använda HTTP.",
    extension_not_deteced: 'Kunde inte upptäcka Beam Studio Connect-tillägg',
    extension_not_deteced_description: "För att använda HTTPS, klicka på 'Bekräfta' för att installera Beam Studio Connect-tillägget. Efter att ha installerat tillägget, uppdatera sidan för att aktivera det.<br/>Annars klickar du på länken nedan för att se hur du använder HTTP i Chrome.",
    unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Länk</a>',
  },
  layer_module: {
    general_laser: 'Laser',
    laser_2w_infrared: '2W infraröd laser',
    laser_10w_diode: '10W diodlaser',
    laser_20w_diode: '20W diodlaser',
    non_working_area: 'Icke-Arbetsområde',
    none: 'None',
    notification: {
      convertFromLaserModuleMsg: 'Observera att om du slutför den här åtgärden kommer inställningarna för laserskiktet att tas bort och ställas in enligt aktuellt skikt.',
      convertFromLaserModuleTitle: 'Vill du konvertera lasermodulen till utskriftsmodul?',
      convertFromPrintingModuleMsg: 'Observera att om du slutför den här åtgärden kommer färginställningarna för utskriftslagret att tas bort och ställas in enligt aktuellt lager.',
      convertFromPrintingModuleTitle: 'Vill du konvertera utskriftsmodulen till lasermodul?',
      importedDocumentContainsPrinting: 'Dokumentet innehåller utskriftslager, vill du ändra arbetsområde till Ador?',
      performIRCaliMsg: 'Klicka på "Bekräfta" för att utföra kalibreringen eller gå till kalibrering via huvudmenyn.<br />(Maskin > [Ditt maskinnamn] > Kalibrera infraröd modul)',
      performIRCaliTitle: 'Utför infraröd modulkalibrering',
      performPrintingCaliMsg: 'Klicka på "Bekräfta" för att utföra kalibreringen eller gå till kalibrering via huvudmenyn.<br />(Maskin > [Ditt maskinnamn] > Kalibrera utskriftsmodul)',
      performPrintingCaliTitle: 'Utför utskriftsmodulkalibreringen',
      printingLayersCoverted: 'Skrivarlagren har konverterats till laserskikt.',
    },
    printing: 'Utskrift',
    unknown: 'Unknown Module',
  },
  machine_status: {
    '-17': 'Patron I/O-läge',
    '-10': 'Underhållsläge',
    '-2': 'Skannar',
    '-1': 'Underhåller',
    '0': 'Inaktiv',
    '1': 'Initierar',
    '2': 'ST_TRANSFORM',
    '4': 'Startar',
    '6': 'Återupptar',
    '16': 'Arbetar',
    '18': 'Återupptar',
    '32': 'Pausad',
    '36': 'Pausad',
    '38': 'Pausar',
    '48': 'Pausad',
    '50': 'Pausar',
    '64': 'Slutförd',
    '66': 'Slutför',
    '68': 'Förbereder',
    '128': 'Avbruten',
    '256': 'Larm',
    '512': 'Fatal',
    UNKNOWN: 'Okänd',
  },
  material_test_generator: {
    block_settings: 'Blockinställningar',
    columns: 'Kolumner',
    count: 'Antal',
    cut: 'Skär',
    engrave: 'Gravera',
    export: 'Exportera',
    max: 'Max',
    min: 'Min',
    parameter: 'Parameter',
    preview: 'Förhandsgranska',
    rows: 'Rader',
    size: 'Storlek (HxB)',
    spacing: 'Mellanrum',
    table_settings: 'Tabellinställningar',
    text_settings: 'Textinställningar',
    title: 'Materialtestgenerator',
  },
  menu: {
    inches: 'tum',
    mm: 'mm',
  },
  message: {
    auth_error: '#820 Autentiseringsfel: Uppdatera Beam Studio och maskinens firmware till senaste versionen.',
    authenticating: 'Autentiserar...',
    camera: {
      abort_preview: 'Avbryt',
      camera_cable_unstable: 'Det upptäcktes att kameran överför bilder ostabilt. Förhandsgranskning kan fortfarande utföras normalt, men det kan finnas problem med långsam förhandsgranskning eller timeout.',
      continue_preview: 'Fortsätt',
      fail_to_transmit_image: '#845 Något gick fel med bildöverföringen. Starta om din maskin eller Beam Studio. Om felet kvarstår, följ <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">denna guide</a>.',
      ws_closed_unexpectly: '#844 Anslutningen till maskinkameran har stängts oväntat. Om felet kvarstår, följ <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">denna guide</a>.',
    },
    cancelling: 'Avbryter...',
    cant_connect_to_device: '#827 Kan inte ansluta till maskinen, kontrollera din anslutning',
    connected: 'Ansluten',
    connecting: 'Ansluter...',
    connectingCamera: 'Ansluter kamera...',
    connectingMachine: 'Ansluter %s...',
    connectionTimeout: '#805 Enhetens anslutningstid gick ut. Kontrollera ditt nätverkstillstånd och maskinens WiFi-indikator.',
    device_blocked: {
      caption: 'Otillåtet serienummer',
      offline: 'Enhetens serienummer är begränsat till offline-användning eftersom det verkar vara inaktiverat. Vänligen uppge enhetens serienummer (placerat på baksidan av enheten) till din återförsäljare och be dem kontakta support@flux3dp.com för att aktivera online-autentisering. Om du vill använda enheten offline, kontakta support@flux3dp.com direkt.',
      online: 'Enhetens serienummer verkar vara inaktiverat. Vänligen uppge enhetens serienummer (placerat på baksidan av enheten) till din återförsäljare och be dem kontakta support@flux3dp.com för att aktivera enhetsautentisering.',
    },
    device_busy: {
      caption: 'Maskinen är upptagen',
      message: 'Maskinen utför en annan uppgift, försök igen senare. Om den slutar fungera, starta om maskinen.',
    },
    device_is_used: 'Maskinen används, vill du avbryta aktuell uppgift?',
    device_not_found: {
      caption: 'Standardmaskin hittades inte',
      message: '#812 Kontrollera maskinens WiFi-indikator',
    },
    disconnected: 'Ostabil anslutning, kontrollera enhetsanslutning och försök igen senare',
    endingLineCheckMode: 'Avslutar linjekontrollläge...',
    endingRawMode: 'Lämnar råläge...',
    enteringLineCheckMode: 'Går in i linjekontrolläge...',
    enteringRawMode: 'Går in i råläge...',
    enteringRedLaserMeasureMode: 'Går in i rött laser-mätningsläge...',
    exitingRotaryMode: 'Avslutar roterande läge...',
    getProbePosition: 'Hämtar sondposition...',
    gettingLaserSpeed: 'Läser laserhuvudhastighet...',
    homing: 'Hemkörning...',
    need_password: 'Lösenord krävs för att ansluta till maskinen',
    please_enter_dpi: 'Ange enheten för din fil (i mm)',
    preview: {
      adjust: 'Justera',
      adjust_height_tooltip: 'Markera för att aktivera redigering.',
      already_performed_auto_focus: 'Du har redan utfört autofokus, använd befintliga värden?',
      auto_focus: 'Autofokus',
      auto_focus_instruction: 'Flytta laserhuvudet över objektet och följ anvisningarna för att trycka på AF för att fokusera.',
      camera_preview: 'Kameraförhandsgranskning',
      enter_manually: 'Ange manuellt',
      please_enter_height: 'Ange objektets höjd. För att ta en exakt kamerabild.',
    },
    promark_disconnected: '#850 Enhetens anslutning har avbrutits, kontrollera enhetens anslutningsstatus.',
    redLaserTakingReference: 'Tar referens...',
    retrievingCameraOffset: 'Läser kameraoffset...',
    settingLaserSpeed: 'Ställer in laserhuvudhastighet...',
    swiftray_disconnected: 'Kunde inte ansluta till backend, försöker ansluta igen.',
    swiftray_reconnected: 'Backend har återanslutits, försök att skicka jobbet igen.',
    time_remaining: 'Återstående tid:',
    tryingToConenctMachine: 'Försöker ansluta till maskin...',
    turningOffAirPump: 'Stänger av luftpump...',
    turningOffFan: 'Stänger av fläkt...',
    unable_to_find_machine: 'Kan inte hitta maskin ',
    unable_to_start: '#830 Det gick inte att starta uppgiften. Försök igen. Om detta händer igen, vänligen kontakta oss med en felrapport:',
    unavailableWorkarea: '#804 Aktuellt arbetsområde överskrider maskinens arbetsområde. Kontrollera arbetsområdet för vald maskin eller ställ in arbetsområde från Redigera > Dokumentinställningar.',
    unknown_device: '#826 Kan inte ansluta till maskinen, kontrollera att USB är ansluten till maskinen',
    unknown_error: '#821 Applikationen har stött på ett okänt fel, använd Hjälp > Meny > Rapportera bugg.',
    unsupport_osx_version: 'Aktuell macOS-version %s kanske inte stöder alla funktioner. Uppdatera gärna till macOS 11+.',
    unsupport_win_version: 'Aktuell OS-version %s kanske inte stöder alla funktioner. Uppdatera gärna till den senaste versionen.',
    unsupported_example_file: 'Det valda exempelfilen stöds inte av aktuellt arbetsområde.',
    uploading_fcode: 'Laddar upp FCode',
    usb_unplugged: 'USB-anslutningen förlorades. Kontrollera din USB-anslutning',
    wrong_swiftray_version_message: 'Felaktig backend-version (version: {version}). Kontrollera om flera instanser av Beam Studio körs och stäng eventuella extra instanser innan du försöker igen.',
    wrong_swiftray_version_title: 'Felaktig backend-version',
  },
  monitor: {
    ask_reconnect: 'Anslutningen till maskinen har brutits. Vill du återansluta?',
    bug_report: 'Felrapport',
    camera: 'Kamera',
    cancel: 'Avbryt',
    confirmFileDelete: 'Är du säker på att du vill radera den här filen?',
    connecting: 'Ansluter, vänta...',
    DEVICE_ERROR: 'Något gick fel. Starta om maskinen',
    download: 'Ladda ner',
    extensionNotSupported: 'Det här filformatet stöds inte',
    fileExistContinue: 'Filen finns redan, vill du ersätta den?',
    forceStop: 'Vill du avbryta aktuell uppgift?',
    go: 'Starta',
    HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Luftflödessensor avvikande',
    HARDWARE_ERROR_BOTTOM_OPENED: '#903 Botten öppen. Stäng botten för att fortsätta.',
    HARDWARE_ERROR_DOOR_OPENED: '#901 Stäng dörren för att fortsätta.',
    HARDWARE_ERROR_DRAWER_OPENED: '#911 Lådan öppnades',
    HARDWARE_ERROR_FIRE_DETECTED: '#912 Flamsäkerhetssensor avvikande',
    HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Fel modul upptäckt. Installera rätt modul för att fortsätta.',
    HARDWARE_ERROR_HEADTYPE_NONE: '#917 Modul ej upptäckt. Kontrollera att modulen är korrekt installerad för att fortsätta.',
    HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Okänd modul upptäckt. Installera rätt modul för att fortsätta.',
    HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Homing Pull-Off-fel',
    HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Kritiskt fel: Huvudkort offline. Kontakta FLUX support.',
    HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Överhettad. Vänta några minuter.',
    HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Z-axel återställning misslyckades',
    HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Inget svar från skrivarmodul.',
    HARDWARE_ERROR_PROBE_SHOWED: 'Vänligen dra tillbaka sonden.',
    HARDWARE_ERROR_PUMP_ERROR: '#900 Kontrollera din vattentank.',
    HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Ingen roterande modul detekterad',
    HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Kritiskt fel: Inget svar från delsystem. Kontakta FLUX support.',
    HARDWARE_FAILURE: 'Något gick fel. Starta om maskinen',
    hour: 'h',
    left: 'vänster',
    MAINBOARD_OFFLINE: '#905 Fel vid anslutning till huvudkortet. Starta om maskinen.',
    minute: 'm',
    monitor: 'ÖVERVAKA',
    NO_RESPONSE: '#905 Fel vid anslutning till huvudkortet. Starta om maskinen.',
    pause: 'Pausa',
    prepareRelocate: 'Förbereder flytt',
    processing: 'Bearbetar',
    record: 'Spela in',
    relocate: 'Flytta',
    RESOURCE_BUSY: 'Maskinen är upptagen. Om den inte körs, starta om maskinen',
    resume: 'Återuppta',
    savingPreview: 'Genererar miniatyrbilder',
    second: 's',
    start: 'Starta',
    stop: 'Stoppa',
    SUBSYSTEM_ERROR: '#402 Kritiskt fel: Delsystem svarar inte. Kontakta FLUX-supporten.',
    task: {
      BEAMBOX: 'Lasergravering',
      'N/A': 'Fritt läge',
    },
    taskTab: 'Uppgift',
    temperature: 'Temperatur',
    upload: 'Ladda upp',
    USER_OPERATION: 'Följ instruktionerna på enhetspanelen för att fortsätta.',
    USER_OPERATION_CHANGE_CARTRIDGE: 'Sätt i rätt patron för att fortsätta.',
    USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Sätt i patronen för att fortsätta.',
    USER_OPERATION_CHANGE_TOOLHEAD: 'Installera rätt modul för att fortsätta.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Fel modul upptäckt. Installera rätt modul för att fortsätta.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Modul ej upptäckt. Kontrollera att modulen är korrekt installerad för att fortsätta.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Okänd modul upptäckt. Installera rätt modul för att fortsätta.',
    USER_OPERATION_REMOVE_CARTRIDGE: 'Ta bort patronen för att fortsätta.',
    USER_OPERATION_ROTARY_PAUSE: 'Växla till den roterande motorn',
  },
  my_cloud: {
    action: {
      confirmFileDelete: 'Är du säker på att du vill ta bort den här filen? Den här åtgärden kan inte ångras.',
      delete: 'Ta bort',
      download: 'Ladda ner',
      duplicate: 'Duplicera',
      open: 'Öppna',
      rename: 'Byt namn',
    },
    file_limit: 'Gratis fil',
    loading_file: 'Laddar...',
    no_file_subtitle: 'Gå till Meny > "Arkiv" > "Spara till molnet"',
    no_file_title: 'Spara filer till Mitt moln för att komma igång.',
    save_file: {
      choose_action: 'Spara fil:',
      input_file_name: 'Ange filnamn:',
      invalid_char: 'Ogiltiga tecken:',
      save: 'Spara',
      save_new: 'Spara som ny fil',
      storage_limit_exceeded: 'Din molnlagring har nått gränsen. Ta bort eventuella onödiga filer innan du sparar nya.',
    },
    sort: {
      a_to_z: 'Namn: A - Ö',
      most_recent: 'Senast',
      oldest: 'Äldst',
      z_to_a: 'Namn: Ö - A',
    },
    title: 'Mitt moln',
    upgrade: 'Uppgradera',
  },
  noun_project_panel: {
    clear: 'Rensa',
    elements: 'Element',
    enjoy_shape_library: 'Njut av formdatabasen.',
    export_svg_title: 'Kan inte exportera SVG',
    export_svg_warning: 'Det här projektet innehåller objekt som skyddas av upphovsrätten. Därför kommer Beam Studio automatiskt utesluta dessa objekt vid exporten. Du kan fortfarande spara ditt projekt i Beam Studio Scene (.beam-format) för att behålla alla dina data. Vill du fortfarande exportera?',
    learn_more: 'Läs mer',
    login_first: 'Logga in för att låsa upp formdatabasen.',
    recent: 'Senaste',
    search: 'Sök',
    shapes: 'Former',
  },
  pass_through: {
    export: 'Exportera till Arbetsområde',
    exporting: 'Exporterar...',
    guide_mark: 'Guidemärke',
    guide_mark_desc: 'Guidemärken kommer att graveras som en referenspunkt för att justera konstverket.',
    guide_mark_length: 'Längd:',
    guide_mark_x: 'X-koordinat:',
    height_desc: 'Ställ in höjden på varje arbetsområdessektion.',
    help_links: {
      ado1: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
      fbb2: 'https://support.flux3dp.com/hc/en-us/articles/11570021253263',
    },
    help_text: 'Hur ställer man in genomgång för %(model)s?',
    object_length: 'Objektlängd',
    ref_layer: 'Referenslager',
    ref_layer_desc: 'Observera att utförandet av referenslagret är inställt på 0 som standard. Det kommer inte att utföras utan är endast för justeringsreferens.',
    ref_layer_name: 'Referens',
    title: 'Genomgångsläge',
    workarea_height: 'Arbetsområde (Höjd):',
  },
  promark_connection_test: {
    description: 'Systemet kör en rödljusoperation i cirka två minuter för att kontrollera om anslutningen är stabil.',
    health: 'Stabilitet:',
    healthy_description: 'Stabiliteten är bra, men undvik att flytta datorn avsevärt under gravering.',
    res_0: 'Utmärkt',
    res_1: 'Bra',
    res_2: 'Låg',
    res_3: 'För Låg',
    res_4: 'Mycket Låg',
    restart: 'Starta Test Igen',
    start: 'Starta Test',
    stop: 'Stoppa Test',
    title: 'Anslutningsstabilitetstest',
    unhealthy_description: 'Stabiliteten är för låg. Det rekommenderas att byta ut nätverksadaptern eller strömkabeln.',
  },
  promark_settings: {
    angle: 'Vinkel',
    bulge: 'Utbuktning',
    field: 'Fält',
    galvo_configuration: 'Galvo-konfiguration',
    mark: 'Märk',
    mark_parameters: 'Märkningsparametrar',
    offsetX: 'Förskjutning X',
    offsetY: 'Förskjutning Y',
    preview: 'Förhandsvisning',
    red_dot: 'Röd punkt',
    scale: 'Skala',
    scaleX: 'Skala X',
    scaleY: 'Skala Y',
    skew: 'Skevhet',
    switchXY: 'Byt X/Y',
    title: 'Promark-inställningar',
    trapezoid: 'Trapezoid',
    workarea_hint: 'Du kan ändra arbetsområdet i "Dokumentinställningar".',
    z_axis_adjustment: {
      section1: 'Justera Z-axelns höjd för att finjustera fokus.',
      title: 'Justering av Z-Axeln',
      tooltip1: 'Försök att märka en 1x1 cm kvadrat för att bekräfta om det aktuella fokusavståndet är lämpligt.',
    },
  },
  qr_code_generator: {
    error_tolerance: 'Felmarginal',
    error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
    invert: 'Invertera bakgrundsfärg',
    placeholder: 'Ange en länk eller text',
    preview: 'Förhandsgranska',
    title: 'QR-kodsgenerator',
  },
  rotary_settings: {
    circumference: 'Omkrets',
    extend_workarea: 'Utvidga arbetsområdet',
    mirror: 'Spegel',
    object_diameter: 'Objekt Diameter',
    overlap_size: 'Överlappningsstorlek',
    rotary_scale: 'Rotationsskala',
    split_setting: 'Delningsinställning',
    split_setting_url: 'https://support.flux3dp.com/hc/en-us/articles/12276094010767',
    split_size: 'Delningsstorlek',
    type: 'Typ',
  },
  select_device: {
    auth_failure: '#811 Autentisering misslyckades',
    select_device: 'Välj enhet',
    unable_to_connect: '#810 Kan inte bygga en stabil anslutning med maskinen',
  },
  settings: {
    anti_aliasing: 'Kantutjämning',
    auto_connect: 'Välj automatiskt den enda maskinen',
    auto_switch_tab: 'Automatisk växling mellan lager- och objektpanelen',
    autofocus_offset: 'Autofokusoffset',
    autosave_enabled: 'Autospara',
    autosave_interval: 'Spara varje',
    autosave_number: 'Antal autosparningar',
    autosave_path: 'Autospara plats',
    autosave_path_not_correct: 'Angiven sökväg hittades inte.',
    blade_precut_position: 'Förskärningsposition',
    blade_precut_switch: 'Blad förskärning',
    blade_radius: 'Bladradie',
    bottom_up: 'Nerifrån och upp',
    calculation_optimization: 'Banuppskattningsacceleration',
    cancel: 'Avbryt',
    caption: 'Inställningar',
    check_updates: 'Automatisk kontroll',
    close: 'Stäng',
    confirm_remove_default: 'Standardmaskinen kommer att tas bort.',
    confirm_reset: 'Bekräfta återställning av Beam Studio?',
    continuous_drawing: 'Kontinuerlig ritning',
    curve_engraving_speed_limit: 'Hastighetsgräns för kurvgravering',
    custom_preview_height: 'Anpassad förhandsgranskningshöjd',
    default_beambox_model: 'Standarddokumentinställning',
    default_borderless_mode: 'Öppna botten som standard',
    default_enable_autofocus_module: 'Autofokus som standard',
    default_enable_diode_module: 'Diodlaser som standard',
    default_font_family: 'Standardtypsnitt',
    default_font_style: 'Standard typsnittsstil',
    default_laser_module: 'Standard Lasermodul',
    default_machine: 'Standardmaskin',
    default_machine_button: 'Tom',
    default_units: 'Standardenheter',
    diode_offset: 'Diodlaseroffset',
    diode_one_way_engraving: 'Diodlaser enkelriktad gravering',
    diode_two_way_warning: 'Tvåvägs ljusemittering är snabbare och genererar förmodligen viss felmarginal på lasergraveringspositionen. Rekommenderar att testa först.',
    disabled: 'Inaktiverad',
    done: 'Verkställ',
    enable_custom_backlash: 'Aktivera anpassad backlashkompensation',
    enable_low_speed: 'Aktivera långsam hastighetsrörelse',
    enabled: 'Aktiverad',
    engraving_direction: 'Riktning',
    fast_gradient: 'Hastighetsoptimering',
    font_convert: 'Text till bana konverterare',
    font_substitute: 'Ersätt osupportade tecken',
    grouped_objects: 'Grupperade objekt',
    groups: {
      ador_modules: 'Ador Moduler',
      autosave: 'Autospara',
      camera: 'Kamera',
      connection: 'Anslutning',
      editor: 'Redigerare',
      engraving: 'Rasterisering (skanning)',
      general: 'Allmänt',
      mask: 'Arbetsområdesklippning',
      modules: 'Tillägg',
      path: 'Vektor (konturer)',
      privacy: 'Sekretess',
      text_to_path: 'Text',
      update: 'Programuppdateringar',
    },
    guess_poke: 'Sök efter maskinens IP-adress',
    guides: 'Riktlinjer',
    guides_origin: 'Riktlinjeursprung',
    help_center_urls: {
      anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
      calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
      connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
      continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
      default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
      default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
      default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
      fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
      font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
      font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
      image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
      loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
      mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
      reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
      segmented_engraving: 'https://support.flux3dp.com/hc/en-us/articles/12306366019215',
      simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
      vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
    },
    high: 'Hög',
    image_downsampling: 'Bildnedsamplingskvalitet',
    ip: 'Maskinens IP-adress',
    keep_preview_result: 'Behåll Förhandsgranskningsresultat',
    language: 'Språk',
    loop_compensation: 'Kompensering för slingor',
    low: 'Låg',
    low_laser_for_preview: 'Laser för Kör ram',
    mask: 'Arbetsområdesklippning',
    medium: 'Medel',
    module_offset_2w_ir: '2W Infraröd Laser Offset',
    module_offset_10w: '10W Diodlaser Offset',
    module_offset_20w: '20W Diodlaser Offset',
    module_offset_printer: 'Skrivaroffset',
    none: 'Ingen',
    normal: 'Normal',
    notification_off: 'Av',
    notification_on: 'På',
    notifications: 'Skrivbordsmeddelanden',
    off: 'Av',
    on: 'På',
    preview_movement_speed: 'Förhandsgranskning hastighet',
    printer_advanced_mode: 'Avancerat Skrivarläge',
    remove_default_machine_button: 'Ta bort',
    reset: 'Återställ Beam Studio',
    reset_now: 'Återställ Beam Studio',
    segmented_engraving: 'Segmenterad gravyr',
    share_with_flux: 'Dela Beam Studio-analys',
    simplify_clipper_path: 'Optimera den beräknade banan',
    single_object: 'Enskilt objekt',
    tabs: {
      device: 'Maskin',
      general: 'Allmänt',
    },
    text_path_calc_optimization: 'Bana beräkningsoptimering',
    top_down: 'Uppifrån och ner',
    trace_output: 'Bildspårningsutdata',
    update_beta: 'Beta',
    update_latest: 'Senaste',
    vector_speed_constraint: 'Hastighetsbegränsning',
    wrong_ip_format: 'Felaktigt IP-format',
  },
  social_media: {
    facebook: 'Chatta med FLUXers, ställ frågor och lär dig tips!',
    instagram: 'Få de senaste inspirationerna, erbjudandena och gratisprodukterna!',
    youtube: 'Se Beam Studio-handledningar och hantverksidéer.',
  },
  support: {
    no_vcredist: 'Installera Visual C++ Redistributable 2015<br/>Det kan laddas ner på flux3dp.com',
    no_webgl: 'WebGL stöds inte. Använd en annan enhet.',
  },
  topbar: {
    alerts: {
      add_content_first: 'Lägg till objekt först',
      door_opened: 'Stäng luckan för att aktivera lågeffektslaser för skärning.',
      fail_to_connect_with_camera: '#803 Det gick inte att ansluta till maskinkameran. Starta om din maskin eller Beam Studio. Om felet kvarstår, följ <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">denna guide</a>.',
      fail_to_get_door_status: 'Kontrollera att luckan är stängd för att aktivera lågeffektslaser för skärning.',
      fail_to_start_preview: '#803 Det gick inte att starta förhandsgranskningsläget. Starta om din dator eller Beam Studio. Om felet kvarstår, följ <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">denna guide</a>.',
      headtype_mismatch: 'Fel modultyp upptäckt. ',
      headtype_none: 'Ingen modul upptäckt. ',
      headtype_unknown: 'Okänd modul upptäckt. ',
      install_correct_headtype: 'Installera 10W/20W diodlasermoduler korrekt för att aktivera lågeffektslaser för skärning.',
      job_origin_unavailable: 'Att ställa in jobbets ursprung kräver firmwareversion 4.3.5 / 5.3.5 eller senare. Vill du uppdatera firmware nu?',
      job_origin_warning: 'Du använder för närvarande "aktuell position" som startpunkt. Se till att laserhuvudet flyttas till rätt position för att undvika kollisioner.',
      power_too_high: 'FÖR HÖG EFFEKT',
      power_too_high_confirm: 'NOTERAT',
      power_too_high_msg: 'Att använda lägre laserstyrka (under 70%) förlänger laserns livslängd.\nSkriv in "NOTERAT" för att fortsätta.',
      pwm_unavailable: 'Djup-läge kräver firmware version 4.3.4 / 5.3.4 eller högre. Vill du uppdatera firmware nu?',
      QcleanScene: 'Vill du rensa ritningen?<br/>Detta kommer också att radera din ånghistorik!',
      start_preview_timeout: '#803 Timeout uppstod när förhandsgranskningsläget startades. Starta om din dator eller Beam Studio. Om felet kvarstår, följ <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">denna guide</a>.',
    },
    frame_task: 'Kör ram',
    hint: {
      polygon: 'Tryck på + / - tangenten för att öka / minska sidor.',
    },
    menu: {
      about: 'Om Beam Studio',
      about_beam_studio: 'Om Beam Studio',
      account: 'Konto',
      add_new_machine: 'Maskininställning',
      align_center: 'Centrera',
      anti_aliasing: 'Kantutjämning',
      auto_align: 'Automatisk Justering',
      borderless_mode: 'Helskärm',
      bug_report: 'Felrapport',
      calibrate_beambox_camera: 'Kalibrera kamera',
      calibrate_beambox_camera_borderless: 'Kalibrera kamera (öppen botten)',
      calibrate_camera_advanced: 'Kalibrera kamera (Avancerad)',
      calibrate_diode_module: 'Kalibrera diodlasermodul',
      calibrate_ir_module: 'Kalibrera Infraröd Modul',
      calibrate_printer_module: 'Kalibrera Utskriftsmodul',
      calibration: 'Kalibreringar',
      camera_calibration_data: 'Kamerakalibreringsdata',
      change_logs: 'Ändringsloggar',
      clear_scene: 'Nya filer',
      close: 'Stäng fönster',
      commands: 'Kommandon',
      contact: 'Kontakta oss',
      copy: 'Kopiera',
      cut: 'Klipp ut',
      dashboard: 'Instrumentpanel',
      decompose_path: 'Dela upp',
      delete: 'Ta bort',
      design_market: 'Designmarknad',
      dev_tool: 'Debug-verktyg',
      disassemble_use: 'Demontera',
      document_setting: 'Dokumentinställningar',
      document_setting_short: 'Dokument',
      download_data: 'Ladda ner Data',
      download_log: 'Ladda ner loggar',
      download_log_canceled: 'Loggnedladdning avbruten',
      download_log_error: 'Ett okänt fel uppstod, försök igen senare',
      duplicate: 'Duplicera',
      edit: 'Redigera',
      example_files: 'Exempelfiler',
      export_BVG: 'BVG',
      export_flux_task: 'FLUX-uppgift',
      export_JPG: 'JPG',
      export_PNG: 'PNG',
      export_SVG: 'SVG',
      export_to: 'Exportera till...',
      file: 'Arkiv',
      fit_to_window: 'Anpassa till fönster',
      follow_us: 'Följ oss',
      forum: 'Communityforum',
      group: 'Gruppera',
      help: 'Hjälp',
      help_center: 'Hjälpcenter',
      hide: 'Dölj Beam Studio',
      hideothers: 'Dölj andra',
      image_crop: 'Beskär',
      image_curve: 'Kurva',
      image_invert: 'Invertera',
      image_sharpen: 'Skärpa',
      image_stamp: 'Fas',
      image_vectorize: 'Spåra',
      import_acrylic_focus_probe: 'Akrylfokusprov - 3mm',
      import_ador_laser_example: 'Exempel på Ador Laser',
      import_ador_printing_example_full: 'Exempel på Ador Utskrift - Flerfärg',
      import_ador_printing_example_single: 'Exempel på Ador Utskrift - Enfärg',
      import_beambox_2_example: 'Exempel på Beambox II',
      import_beambox_2_focus_probe: 'Beambox II Fokussond - 3 mm',
      import_hello_beambox: 'Exempel på Beambox',
      import_hello_beamo: 'Exempel på beamo',
      import_hexa_example: 'Exempel på HEXA',
      import_material_printing_test: 'Materialutskriftstest',
      import_material_testing_cut: 'Materialskärningstest',
      import_material_testing_engrave: 'Materialgraveringstest',
      import_material_testing_line: 'Materiallinjetest',
      import_material_testing_old: 'Materialgraveringstest - Klassiskt',
      import_material_testing_simple_cut: 'Materialskärningstest - Enkelt',
      import_promark_example: 'Exempel på Promark',
      import_promark_mopa_20w_color: 'MOPA 20W Färgtest',
      import_promark_mopa_60w_color: 'MOPA 60W Färgtest',
      import_promark_mopa_100w_color: 'MOPA 100W Färgtest',
      keyboard_shortcuts: 'Tangentbordsgenvägar',
      layer_color_config: 'Färginställningar',
      layer_setting: 'Lager',
      link: {
        beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
        contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
        design_market: 'https://dmkt.io',
        downloads: 'https://flux3dp.com/downloads/',
        forum: 'https://www.facebook.com/groups/flux.laser/',
        help_center: 'https://helpcenter.flux3dp.com/',
        shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
      },
      log: {
        camera: 'Kamera',
        cloud: 'Moln',
        discover: 'Upptäck',
        hardware: 'Hårdvara',
        network: 'Nätverk',
        player: 'Spelare',
        robot: 'Robot',
        usb: 'USB',
        usblist: 'USB-lista',
      },
      machine_info: 'Maskininfo',
      machines: 'Maskiner',
      manage_account: 'Hantera mitt konto',
      material_test: 'Materialtest',
      minimize: 'Minimera',
      my_account: 'Mitt konto',
      network_testing: 'Testa nätverksinställningar',
      object: 'Objekt',
      offset: 'Förskjut',
      open: 'Öppna',
      paste: 'Klistra in',
      paste_in_place: 'Klistra in på plats',
      path: 'Bana',
      photo_edit: 'Bild',
      preferences: 'Inställningar',
      promark_color_test: 'Promark Färgtest',
      questionnaire: 'Feedbackenkät',
      quit: 'Avsluta',
      recent: 'Öppna Senaste',
      redo: 'Gör om',
      reload_app: 'Ladda om App',
      reset: 'Återställ',
      rotary_setup: 'Roterande Inställningar',
      rotate: 'Rotera',
      samples: 'Exempel',
      save_as: 'Spara som...',
      save_scene: 'Spara',
      save_to_cloud: 'Spara till molnet',
      scale: 'Skala',
      service: 'Tjänster',
      set_as_default: 'Ange som standard',
      show_gesture_tutorial: 'Handgester Introduktion',
      show_grids: 'Visa rutnät',
      show_layer_color: 'Använd lagrets färg',
      show_rulers: 'Visa linjaler',
      show_start_tutorial: 'Visa startguide',
      show_ui_intro: 'Visa gränssnittsintroduktion',
      sign_in: 'Logga in',
      sign_out: 'Logga ut',
      software_update: 'Programuppdatering',
      svg_edit: 'SVG',
      switch_to_beta: 'Växla till Beta-version',
      switch_to_latest: 'Växla till Stabil version',
      tools: {
        box_generator: 'Lådgenerator',
        code_generator: 'Kodgenerator',
        material_test_generator: 'Materialtestgenerator',
        title: 'Verktyg',
      },
      tutorial: 'Starta Deltas familjeskrivartutorial',
      undo: 'Ångra',
      ungroup: 'Dela upp grupp',
      update: 'Kontrollera Uppdatering',
      update_firmware: 'Uppdatera inbyggd programvara',
      upload_data: 'Ladda upp Data',
      using_beam_studio_api: 'Använda Beam Studio API',
      view: 'Visa',
      window: 'Fönster',
      zoom_in: 'Zooma in',
      zoom_out: 'Zooma ut',
      zoom_with_window: 'Autoanpassa till fönster',
    },
    preview: 'FÖRHANDSVISA',
    preview_press_esc_to_stop: 'Tryck på ESC för att stoppa kameraförhandsvisningen.',
    rename_tab: 'Byt namn på flik',
    select_machine: 'Välj en maskin',
    tag_names: {
      dxf: 'DXF-objekt',
      ellipse: 'Oval',
      g: 'Grupp',
      image: 'Bild',
      line: 'Linje',
      multi_select: 'Flera objekt',
      no_selection: 'Ingen val',
      pass_through_object: 'Passera Objekt',
      path: 'Bana',
      polygon: 'Polygon',
      rect: 'Rektangel',
      svg: 'SVG-objekt',
      text: 'Text',
      text_path: 'Text på bana',
      use: 'Importerat objekt',
    },
    task_preview: 'Banor',
    titles: {
      settings: 'Inställningar',
    },
    untitled: 'Namnlös',
  },
  topmenu: {
    credit: 'Beam Studio är möjlig tack vare det öppen källkodsprojektet <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> och annan <a target="_blank" href="https://flux3dp.com/credits/">öppen källkod</a>.',
    device: {
      download_log_canceled: 'Logg nedladdning avbruten',
      download_log_error: 'Okänt fel uppstod, försök igen senare',
      log: {
        usblist: 'USB-lista',
      },
      network_test: 'Testa nätverk',
    },
    file: {
      all_files: 'Alla filer',
      clear_recent: 'Rensa nyligen öppnade',
      converting: 'Konverterar till bild...',
      fcode_files: 'FLUX Code',
      import: 'Importera',
      jpg_files: 'JPG',
      label: 'Fil',
      path_not_exit: 'Denna sökväg verkar inte längre finnas på disken.',
      png_files: 'PNG',
      save_fcode: 'Exportera FLUX-uppgift',
      save_jpg: 'Exportera JPG',
      save_png: 'Exportera PNG',
      save_scene: 'Spara scen',
      save_svg: 'Exportera SVG',
      scene_files: 'Beam Studio Scene',
      svg_files: 'SVG',
    },
    ok: 'OK',
    version: 'Version',
  },
  tutorial: {
    ask_retry_calibration: 'Skulle du vilja kalibrera kameran igen?',
    camera_calibration_failed: 'Kamerakalibrering misslyckades',
    gesture: {
      click: 'Tryck för att välja objektet.',
      drag: 'Dra för att markera flera objekt.',
      hold: 'Tryck och håll för att öppna snabbmenyn.',
      pan: 'Panorera på arbetsytan med två fingrar.',
      zoom: 'Nypa med två fingrar för att zooma in/ut på arbetsytan.',
    },
    links: {
      adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
    },
    look_for_machine: 'Söker maskin för handledning...',
    needNewInterfaceTutorial: 'Vill du starta en handledning för Beam Studios nya gränssnitt?<br/>(Du kan hoppa över den nu och starta senare genom att klicka på "Hjälp" > "Visa Gränssnittsintroduktion".)',
    needNewUserTutorial: 'Vill du starta en handledning?<br/>(Du kan hoppa över den nu och starta senare genom att klicka på "Hjälp" > "Visa Starthandledning".)',
    newInterface: {
      add_new_layer: 'Lägg till nytt lager',
      align_controls: 'Justeringskontroller',
      basic_shapes: 'Grundläggande former',
      camera_preview: 'Kameraförhandsgranskning',
      drag_to_sort: 'Dra för att sortera',
      end_alert: 'Är du säker på att du vill avsluta introduktionen till det nya användargränssnittet?',
      flip: 'Vänd',
      group_controls: 'Gruppkontroller',
      layer_controls: 'Högerklicka för att välja lagerkontroller:\nDuplicera / Slå samman / Lås / Ta bort lager',
      object_actions: 'Objektåtgärder',
      pen_tool: 'Pennverktyg',
      rename_by_double_click: 'Byt namn genom dubbelklick',
      select_image_text: 'Välj / Bild / Text',
      select_machine: 'Välj maskin',
      shape_operation: 'Formåtgärd',
      start_work: 'Börja arbeta',
      switch_between_layer_panel_and_object_panel: 'Växla mellan lagerpanel och objektpanel',
    },
    newUser: {
      add_new_layer: 'Lägg till ett nytt lager',
      adjust_focus: '2. Justera fokus',
      close_cover: '3. Stäng locket',
      drag_to_draw: 'Dra för att rita',
      draw_a_circle: 'Rita en cirkel',
      draw_a_rect: 'Rita en rektangel',
      end_alert: 'Är du säker på att du vill avsluta handledningen?',
      end_preview_mode: 'Avsluta förhandsgranskningsläge',
      infill: 'Aktivera utfyllnad',
      please_select_wood_cutting: 'Vänligen välj förinställning "Trä - skärning".',
      please_select_wood_engraving: 'Vänligen välj förinställning "Trä - gravering".',
      preview_the_platform: 'Förhandsgranska plattformen',
      put_wood: '1. Lägg träprovet',
      send_the_file: 'Skicka filen',
      set_preset_wood_cut: 'Ställ in förval: Trä - skärning',
      set_preset_wood_engraving: 'Ställ in förval: Trä - gravering',
      switch_to_layer_panel: 'Byt till lagerpanelen',
      switch_to_object_panel: 'Byt till objektpanel',
      switch_to_preview_mode: 'Byt till förhandsgranskningsläge',
    },
    next: 'NÄSTA',
    retry: 'Försök igen',
    set_connection: 'Ställ in anslutning',
    skip: 'Hoppa över',
    skip_tutorial: 'Du har hoppat över handledningen. Du kan alltid starta handledningen genom att klicka på "Hjälp" > "Visa starthandledning"',
    suggest_calibrate_camera_first: 'Vi rekommenderar användare att kalibrera kameran initialt och fokusera om innan varje förhandsvisning för optimala resultat.<br/>Vill du bekräfta för att genomföra kalibreringen nu?<br/>(Du kan hoppa över det nu och göra det senare genom att klicka på "Meny" > "Maskin" > [Din Maskin] > "Kalibrera Kamera".)',
    tutorial_complete: 'Det var allt för handledningen. Dags att börja skapa!',
    unable_to_find_machine: 'Det gick inte att hitta maskinen för handledningen. Vill du gå till anslutningsinställningssidan, försöka igen eller hoppa över handledningen?',
    welcome: 'VÄLKOMMEN',
  },
  update: {
    cannot_reach_internet: '#823 Servern är inte nåbar<br/>Kontrollera internetanslutningen',
    download: 'ONLINEUPPDATERING',
    firmware: {
      caption: 'En uppdatering av maskinvarans programvara är tillgänglig',
      confirm: 'LADDA UPP',
      firmware_too_old_update_by_sdcard: 'Versionen av maskinvarans programvara är för gammal. Uppdatera med ett SD-kort.',
      force_update_message: '#814 Uppdatera maskinen till den senaste versionen av maskinvarans programvara.',
      latest_firmware: {
        cant_get_latest: 'Kan inte hämta information om den senaste versionen.',
        caption: 'Uppdatering av maskinvarans programvara',
        message: 'Du har den senaste versionen av maskinvarans programvara',
        still_update: 'UPPDATERA',
      },
      message_pattern_1: '"%s" är nu redo för uppdatering av maskinvarans programvara.',
      message_pattern_2: '%s maskinvara v%s finns nu tillgänglig - Du har v%s.',
      too_old_for_web: 'Din nuvarande version av maskinvarans programvara är v%s.\nOm du vill använda Beam Studios onlineversion, uppdatera maskinvarans programvara till den senaste versionen.',
      update_fail: '#822 Uppdateringen misslyckades',
      update_success: 'Maskinvarans programvara har uppdaterats',
      upload_file: 'Ladda upp maskinvara (*.bin / *.fxfw)',
    },
    install: 'INSTALLERA',
    later: 'SENARE',
    preparing: 'Förbereder...',
    release_note: 'Versionsinformation:',
    skip: 'Hoppa över den här versionen',
    software: {
      available_switch: 'Beam Studio v%s är tillgänglig nu. Du har v%s. Vill du byta till den här versionen?',
      available_update: 'Beam Studio v%s är tillgänglig nu. Du har v%s. Vill du ladda ner uppdateringen?',
      caption: 'En uppdatering för Beam Studio är tillgänglig',
      check_update: 'Sök efter uppdateringar',
      checking: 'Söker efter uppdateringar',
      downloading: 'Laddar ner uppdateringar i bakgrunden, du kan klicka "OK" för att fortsätta arbeta.',
      install_or_not: 'är redo för uppdatering. Vill du starta om och uppdatera nu?',
      no: 'Nej',
      no_response: 'Det gick inte att ansluta till servern, kontrollera dina nätverksinställningar.',
      not_found: 'Du använder den senaste versionen av Beam Studio.',
      switch_or_not: 'är redo för byte. Vill du starta om och byta nu?',
      switch_version: 'Byt version',
      switch_version_not_found: 'Bytebar version hittades inte.',
      update_for_ador: 'Den aktuella programvaruversionen %s är inkompatibel, vänligen ladda ner den senaste versionen av Beam Studio för Ador.',
      yes: 'Ja',
    },
    update: 'Uppdatera',
    updating: 'Uppdaterar...',
    upload: 'LADDA UPP',
  },
  web_cam: {
    no_device: 'Kan inte hitta kameranheten. Anslut kameran igen och försök igen.',
    no_permission: 'Beam Studio har inte behörighet att komma åt kameran. Se till att behörigheten är beviljad till Beam Studio i webbläsarinställningarna eller systeminställningarna.',
  },
  z_speed_limit_test: {
    alert_before: 'Innan kurvgraveringen startar, kommer systemet att utföra ett belastningstest för att förhindra stegförlust på grund av överdriven vikt.',
    alert_failed: 'Den nuvarande objektets vikt överskrider belastningsgränsen för den nuvarande Z-axelhastigheten. Minska Z-axelhastigheten på maskinpanelen eller byt ut graveringsobjektet innan du testar igen.',
    ignore: 'Ignorera',
    retest: 'Testa igen',
    testing: 'Utför belastningstest...',
  },
};

export default lang;
