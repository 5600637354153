import type { ILang } from '@core/interfaces/ILang';

const lang: ILang = {
  alert: {
    abort: 'Batalkan',
    cancel: 'Batal',
    caption: 'Kesalahan',
    close: 'Tutup',
    confirm: 'Konfirmasi',
    dont_save: 'Jangan Simpan',
    dont_show_again: 'Jangan tampilkan lagi',
    duplicated_preset_name: 'Nama preset duplikat',
    error: 'KESALAHAN',
    info: 'INFO',
    instruction: 'Petunjuk',
    learn_more: 'Pelajari Lebih Lanjut',
    no: 'Tidak',
    ok: 'OK',
    ok2: 'OK',
    oops: 'Ups...',
    retry: 'Coba lagi',
    save: 'Simpan',
    stop: 'Berhenti',
    warning: 'PERINGATAN',
    yes: 'Ya',
  },
  auto_fit: {
    artwork_size: 'Ukuran Karya Seni',
    error_tip1: '1. Apakah karya seni ditempatkan dengan benar pada bahan?',
    error_tip2: '2. Apakah kontur material cukup jelas untuk dikenali?',
    failed_to_find_contour: 'Gagal menemukan kontur untuk penyesuaian otomatis',
    learn_more: 'Lihat tutorial.',
    learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    offset_x: 'Pergeseran X',
    offset_y: 'Pergeseran Y',
    pattern_size: 'Ukuran Pola',
    position_artwork: 'Posisikan Karya Seni',
    position_step1: 'Sesuaikan ukuran dan posisi karya seni Anda pada pola.',
    position_step2: 'Klik "Terapkan" untuk menerapkan Auto Fit pada pola ini.',
    preview_first: 'Silakan lakukan pratinjau kamera terlebih dahulu.',
    reset_position: 'Atur Ulang Posisi',
    rotation: 'Putar',
    select_a_pattern: 'Pilih Pola',
    selected_artwork: 'Karya Seni yang Dipilih',
    step1: 'Pilih pola tempat Anda ingin meletakkan karya seni.',
    step2: 'Jika tidak ditemukan pola, pratinjau kamera lagi dan terapkan Pas Otomatis.',
    step3: 'Jika masih gagal menemukan pola yang benar, pastikan benda kerja Anda dapat dikenali dengan jelas dan area kerja bersih dari kotoran.',
    title: 'Pas Otomatis',
  },
  barcode_generator: {
    bar_height: 'Tinggi Batang',
    bar_width: 'Lebar Batang',
    barcode: {
      invalid_value: 'Nilai tidak valid untuk format yang dipilih.',
    },
    font: 'Font',
    font_size: 'Ukuran Font',
    hide_text: 'Sembunyikan Teks',
    invert_color: 'Balik Warna',
    text_margin: 'Margin Teks',
  },
  beambox: {
    announcement_panel: {
      title: 'Pengumuman',
    },
    banner: {
      auto_feeder: 'Mode Pengumpan Otomatis',
      camera_preview: 'Pratinjau',
      camera_preview_borderless_mode: '(Bawah Terbuka)',
      curve_engraving: 'Mode Ukiran Lengkung',
      pass_through: 'Mode Lintasan',
      rotary: 'Mode Putar',
    },
    bottom_right_panel: {
      convert_text_to_path_before_export: 'Ubah Teks Menjadi Lintasan...',
      export_file_error_ask_for_upload: 'Gagal mengekspor pekerjaan. Apakah Anda bersedia memberikan adegan kerja kepada tim pengembang untuk laporan bug?',
      retreive_image_data: 'Ambil Data Gambar...',
    },
    context_menu: {
      copy: 'Salin',
      cut: 'Potong',
      delete: 'Hapus',
      duplicate: 'Duplikat',
      group: 'Grup',
      move_back: 'Kirim ke Belakang',
      move_down: 'Kirim ke Bawah',
      move_front: 'Bawa ke Depan',
      move_up: 'Bawa ke Atas',
      paste: 'Tempel',
      paste_in_place: 'Tempel di Tempat',
      ungroup: 'Batalkan Grup',
    },
    document_panel: {
      add_on: 'Add-on',
      auto_feeder: 'Pengumpan otomatis',
      auto_feeder_scale: 'Skala Pengumpan Otomatis',
      auto_feeder_url: 'https://support.flux3dp.com/hc/en-us/articles/11688230498575',
      borderless_mode: 'Buka Bawah',
      current_position: 'Posisi Saat Ini',
      disable: 'Nonaktifkan',
      document_settings: 'Pengaturan Dokumen',
      enable: 'Aktifkan',
      enable_autofocus: 'Autofokus',
      enable_diode: 'Laser Diode',
      engrave_dpi: 'Resolusi',
      frame_before_start: 'Frame sebelum menjalankan',
      frame_before_start_url: 'https://support.flux3dp.com/hc/en-us/articles/11494925637135',
      high: 'Tinggi',
      job_origin: 'Asal Tugas',
      laser_source: 'Sumber Laser',
      low: 'Rendah',
      machine: 'Mesin',
      medium: 'Sedang',
      notification: {
        changeFromPrintingWorkareaTitle: 'Apakah Anda ingin mengubah Lapisan Cetak menjadi Lapisan Laser?',
      },
      origin: 'Asal',
      pass_through: 'Lewat langsung',
      pass_through_height_desc: 'Masukkan panjang objek untuk memperluas area kerja.',
      rotary_mode: 'Putar',
      scale: 'Skala',
      start_from: 'Mulai Dari',
      start_position: 'Posisi Awal',
      start_work_button: 'Tombol Eksekusi',
      ultra: 'Sangat Tinggi',
      workarea: 'Area Kerja',
    },
    image_trace_panel: {
      back: 'Kembali',
      brightness: 'Kecerahan',
      cancel: 'Batal',
      contrast: 'Kontras',
      next: 'Berikutnya',
      okay: 'Oke',
      threshold: 'Ambang',
      tuning: 'Parameter',
    },
    layer_color_config_panel: {
      add: 'Tambah',
      add_config: 'Tambah Warna',
      color: 'Warna',
      default: 'Setel Ulang Ke Default',
      in_use: 'Warna ini sedang digunakan.',
      layer_color_config: 'Konfigurasi Warna Lapisan',
      no_input: 'Harap masukkan kode warna heksadesimal yang valid.',
      power: 'Daya',
      repeat: 'Eksekusi',
      speed: 'Kecepatan',
      sure_to_delete: 'Apakah Anda yakin untuk menghapus pengaturan warna ini?',
      sure_to_reset: 'Anda akan kehilangan semua parameter yang disesuaikan, apakah Anda yakin untuk menyetel ulang ke default?',
    },
    left_panel: {
      borderless_blind_area: 'Area Non-ukir',
      borderless_preview: 'Pratinjau Kamera Mode Bawah Terbuka',
      diode_blind_area: 'Area Buta Tambahan Laser Hibrid',
      ellipse: 'Elips',
      image: 'Gambar',
      label: {
        adjust_height: 'Sesuaikan Tinggi',
        array: 'Larik',
        boxgen: 'Boxgen',
        choose_camera: 'Kamera',
        clear_preview: 'Hapus Pratinjau',
        cursor: 'Pilih',
        curve_engraving: {
          clear_area: 'Hapus Area yang Dipilih',
          exit: 'Keluar',
          preview_3d_curve: 'Pratinjau Ukiran Kurva',
          select_area: 'Pilih area',
          title: 'Ukiran Kurva',
        },
        end_preview: 'Akhiri Pratinjau',
        line: 'Garis',
        live_feed: 'Siaran Langsung',
        my_cloud: 'Awan Saya',
        oval: 'Lingkaran',
        pass_through: 'Lewat langsung',
        pen: 'Pen',
        photo: 'Gambar',
        polygon: 'Poligon',
        preview: 'Pratinjau Kamera',
        qr_code: 'Kode QR',
        rect: 'Persegi Panjang',
        shapes: 'Bentuk',
        text: 'Teks',
        trace: 'Jejak Gambar',
      },
      line: 'Garis',
      rectangle: 'Persegi Panjang',
      text: 'Teks',
      unpreviewable_area: 'Area Buta',
    },
    network_testing_panel: {
      average_response: 'Rata-rata Waktu Respon',
      cannot_connect_1: '#840 Gagal terhubung ke IP target.',
      cannot_connect_2: '#840 Gagal terhubung ke IP target. Pastikan target berada di jaringan yang sama.',
      cannot_get_local: 'Akses ke alamat IP lokal gagal.',
      connection_quality: 'Kualitas Koneksi',
      device_not_on_list: '#842 Mesin tidak ada di daftar, tetapi Kualitas koneksi >70 dan rata-rata waktu respon <100ms',
      empty_ip: '#818 Mohon masukkan alamat IP perangkat target terlebih dahulu.',
      end: 'Selesai',
      fail_to_start_network_test: '#817 Gagal memulai pengujian jaringan.',
      hint_connect_camera_timeout: 'Timeout terjadi saat memulai pratinjau kamera?',
      hint_connect_failed_when_sending_job: 'Gagal terhubung saat mengirim pekerjaan?',
      hint_device_often_on_list: 'Mesin sering tidak ditemukan di daftar?',
      insert_ip: 'Alamat IP perangkat target:',
      invalid_ip: '#818 Alamat IP tidak valid',
      ip_startswith_169: '#843 Alamat IP mesin dimulai dengan 169.254',
      link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
      link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
      link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
      linux_permission_hint: 'Kesalahan ini biasanya terjadi karena izin yang tidak memadai.\nHarap jalankan "sudo beam-studio --no-sandbox" di terminal untuk mendapatkan izin dan melakukan pengujian jaringan.',
      local_ip: 'Alamat IP lokal:',
      network_testing: 'Pengujian Jaringan',
      network_unhealthy: '#841 Kualitas koneksi <70 atau rata-rata waktu respon >100ms',
      start: 'Mulai',
      test_completed: 'Pengujian Selesai',
      test_fail: 'Pengujian Gagal',
      testing: 'Menguji Jaringan...',
    },
    object_panels: {
      lock_desc: 'Jaga rasio lebar dan tinggi (SHIFT)',
      text_to_path: {
        check_thumbnail_warning: 'Beberapa teks diubah ke font lain saat mengubah teks ke path dan beberapa karakter mungkin tidak dikonversi dengan normal.\nPeriksa gambar pratinjau lagi sebelum mengirim tugas.',
        error_when_parsing_text: 'Kesalahan saat mengubah teks ke path',
        font_substitute_pop: 'Teks Anda berisi karakter yang tidak didukung oleh font saat ini. <br/>Apakah Anda ingin menggunakan <strong>%s</strong> sebagai pengganti?',
        retry: 'Coba lagi nanti atau pilih font lain',
        use_current_font: 'Gunakan Font Saat Ini',
      },
      wait_for_parsing_font: 'Mengurai font... Mohon tunggu sebentar',
    },
    path_preview: {
      current_position: 'Posisi Saat Ini',
      cut_distance: 'Jarak Pemotongan',
      cut_time: 'Waktu Pemotongan',
      end_preview: 'Selesai Pratinjau',
      estimated_time: 'Total Waktu Perkiraan',
      invert: 'Balik',
      pause: 'Jeda',
      play: 'Mainkan',
      play_speed: 'Kecepatan Pemutaran',
      preview_info: 'Pratinjau Informasi',
      rapid_distance: 'Jarak Perjalanan',
      rapid_time: 'Waktu Perjalanan',
      remark: '* Semua informasi adalah nilai perkiraan untuk referensi.',
      size: 'Ukuran',
      start_here: 'Mulai Di Sini',
      stop: 'Berhenti',
      travel_path: 'Lintasan Perjalanan',
    },
    photo_edit_panel: {
      aspect_ratio: 'Rasio Aspek',
      brightness: 'Kecerahan',
      brightness_and_contrast: 'Kecerahan / Kontras',
      cancel: 'Batal',
      circumference: 'Keliling',
      compare: 'Bandingkan',
      contrast: 'Kontras',
      crop: 'Potong',
      curve: 'Lengkungan',
      diameter: 'Diameter',
      free: 'Bebas',
      invert: 'Balik Warna',
      okay: 'Oke',
      original: 'Asli',
      phote_edit: 'Edit Foto',
      processing: 'Memproses',
      radius: 'Jari-jari',
      rotary_warped: 'Berputar Melengkung',
      rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
      sharpen: 'Tajamkan',
      sharpness: 'Ketajaman',
      start: 'Mulai',
      warp: 'Melengkung',
    },
    popup: {
      ai_credit: {
        buy_link: 'https://member.flux3dp.com/en-US/credit',
        go: 'Pergi',
        insufficient_credit: 'Anda kehabisan Kredit',
        insufficient_credit_msg: 'Anda tidak dapat menggunakan %s. Pergi ke pusat anggota dan beli Kredit AI.',
        relogin_to_use: 'Silakan login ulang untuk menggunakan fitur ini.',
      },
      auto_feeder_origin: 'Gunakan pratinjau pembingkaian untuk memeriksa jalur dan area kepala laser, lalu sesuaikan posisi material atau posisi mulai ukiran berdasarkan hasil untuk menghindari tabrakan selama proses pengukiran.',
      auto_switch_tab: {
        message: 'Opsi baru untuk beralih secara otomatis antara panel Lapisan dan panel Objek telah ditambahkan ke Preferensi. Opsi ini dinonaktifkan secara default. Apakah Anda ingin mengaktifkan pengalihan otomatis sekarang? <br/>Anda dapat mengubah pengaturan ini kapan saja di Preferensi.',
        title: 'Pengalihan Otomatis Panel Lapisan dan Objek',
      },
      backend_connect_failed_ask_to_upload: '#802 Kesalahan terus terjadi saat mencoba terhubung ke backend. Apakah Anda ingin mengunggah log laporan bug Anda?',
      backend_error_hint: 'Fitur mungkin tidak berfungsi dengan benar karena kesalahan backend.',
      both_power_and_speed_too_high: 'Menggunakan daya laser lebih rendah akan memperpanjang umur tabung laser. Juga, kecepatan terlalu tinggi pada resolusi ini dapat mengurangi kualitas ukiran bayangan.',
      bug_report: 'Laporan Bug',
      change_workarea_before_preview: 'Area kerja %s tidak cocok dengan area kerja yang saat ini diatur. Apakah Anda ingin beralih area kerja saat ini?',
      convert_to_path_fail: 'Gagal mengubah ke jalur.',
      dxf_bounding_box_size_over: 'Ukuran gambar melebihi area kerja. Harap pindahkan gambar Anda lebih dekat ke asal dalam perangkat lunak CAD Anda, atau pastikan bahwa unit diatur dengan benar.',
      dxf_version_waring: 'Versi file DXF ini bukan 2013, mungkin ada masalah kompatibilitas potensial.',
      facebook_group_invitation: {
        already_joined: 'Sudah Bergabung',
        join_now: 'Gabung Sekarang',
        later: 'Mungkin Nanti',
        message: 'Bergabunglah dengan grup Facebook resmi kami untuk terhubung dengan pengguna laser FLUX lainnya, membahas laser FLUX, berbagi karya seni laser, dan tetap update dengan pembaruan terbaru pada produk kami. Kami tidak sabar melihat Anda di sana!',
        title: 'Bergabung dengan Grup Pengguna Resmi FLUX',
      },
      import_file_contain_invalid_path: '#808 File SVG yang diimpor berisi path gambar yang tidak valid. Pastikan semua file gambar ada atau sisipkan gambar dalam file',
      import_file_error_ask_for_upload: 'Gagal mengimpor file SVG. Apakah Anda bersedia memberikan file ke tim pengembang untuk laporan bug ?',
      layer_by_color: 'Warna',
      layer_by_layer: 'Lapisan',
      loading_image: 'Memuat gambar, harap tunggu...',
      more_than_two_object: 'Terlalu banyak objek. Hanya mendukung 2 objek',
      mouse: 'Mouse',
      no_support_text: 'Beam Studio saat ini tidak mendukung teks. Harap ubah teks ke path sebelum mengimpor.',
      nolayer: 'Lapisan Tunggal',
      not_support_object_type: 'Tidak mendukung jenis objek',
      or_turn_off_borderless_mode: 'Atau matikan mode Open Bottom.',
      pdf2svg: {
        error_pdf2svg_not_found: '#825 Kesalahan: Perintah pdf2svg tidak ditemukan. Harap pasang pdf2svg dengan manajer paket Anda (mis. "yum install pdf2svg" atau "apt-get install pdf2svg").',
        error_when_converting_pdf: '#824 Kesalahan saat mengonversi PDF ke SVG:',
      },
      progress: {
        calculating: 'Menghitung',
        uploading: 'Mengunggah',
      },
      questionnaire: {
        caption: 'Bantu Kami Mengisi Kuesioner',
        message: 'Bantu kami mengisi kuesioner untuk membuat Beam Studio lebih baik.',
        no_questionnaire_available: 'Saat ini tidak ada kuesioner yang tersedia.',
        unable_to_get_url: 'Gagal mendapatkan tautan ke kuesioner melalui internet. Harap konfirmasi koneksi internet Anda.',
      },
      recommend_downgrade_software: 'Kami mendeteksi versi firmware yang lebih lama. Kami sedang menangani masalah kompatibilitas, tetapi untuk saat ini, kami menyarankan kembali ke <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>.',
      recommend_upgrade_firmware: 'Kami mendeteksi versi firmware yang lebih lama. Kami sedang menangani masalah kompatibilitas, tetapi untuk saat ini, kami menyarankan memperbarui ke firmware terbaru.',
      save_unsave_changed: 'Apakah Anda ingin menyimpan perubahan yang belum disimpan?',
      select_at_least_two: 'Pilih dua objek untuk melanjutkan',
      select_first: 'Pilih objek terlebih dahulu.',
      select_import_method: 'Pilih gaya pelapisan:',
      select_import_module: 'Pilih Modul:',
      sentry: {
        message: 'Apakah Anda setuju untuk secara otomatis mengunggah informasi yang relevan ke tim pengembangan ketika terjadi kesalahan?',
        title: 'Mari kita membuat Beam Studio lebih baik bersama',
      },
      should_update_firmware_to_continue: '#814 Firmware Anda tidak mendukung versi Beam Studio ini. Harap perbarui firmware untuk melanjutkan. (Menu > Mesin > [Mesin Anda] > Perbarui Firmware)',
      speed_too_high_lower_the_quality: 'Menggunakan kecepatan terlalu tinggi pada resolusi ini dapat mengurangi kualitas ukiran bayangan.',
      still_continue: 'Lanjutkan',
      successfully_uploaded: 'Unggah file berhasil.',
      svg_1_1_waring: 'Versi file SVG ini adalah v 1.1, mungkin ada masalah kompatibilitas potensial.',
      svg_image_path_waring: 'File SVG ini berisi <image> memuat dari jalur file. Ini mungkin menyebabkan kegagalan saat memuat.\nUntuk menghindari risiko ini, mohon gunakan gambar yang disematkan saat mengekspor SVG.',
      text_to_path: {
        caption: 'Konverter Teks ke Jalur 2.0',
        message: "Beam Studio sekarang memperkenalkan konverter teks ke jalur yang baru (Konverter 2.0), yang menghasilkan hasil yang lebih andal! Apakah Anda ingin beralih ke sana sekarang? \nAnda juga dapat menyesuaikan pengaturan ini nanti di bagian 'Konverter Teks ke Jalur' dalam preferensi.",
      },
      too_fast_for_auto_feeder: 'Menggunakan kecepatan yang terlalu tinggi pada lapisan yang berisi objek jalur dapat mengurangi ketelitian saat pemotongan.\nKami tidak menyarankan menggunakan kecepatan lebih dari %(limit)s saat memotong dengan Auto Feeder.',
      too_fast_for_auto_feeder_and_constrain: 'Lapisan berikut: %(layers)s \nmemuat objek jalur vektor dan memiliki kecepatan yang melebihi %(limit)s.\nKecepatan pemotongan untuk objek jalur vektor dengan Auto Feeder akan dibatasi pada %(limit)s.\nAnda dapat menghapus batas ini di Pengaturan Preferensi.',
      too_fast_for_curve: 'Menggunakan kecepatan terlalu tinggi untuk lapisan yang berisi objek ukiran lengkung dapat mengurangi presisi ukiran.\nKami menyarankan untuk menjaga kecepatan di bawah %(limit)s untuk hasil optimal.',
      too_fast_for_curve_and_constrain: 'Lapisan berikut: %(layers)s melebihi batas kecepatan %(limit)s.\nKecepatan ukiran untuk objek lengkung akan dibatasi hingga %(limit)s. Anda dapat menghapus batas ini di Pengaturan Preferensi.',
      too_fast_for_path: 'Menggunakan kecepatan terlalu tinggi pada lapisan yang berisi objek path dapat mengurangi presisi saat pemotongan. Kami tidak menyarankan menggunakan kecepatan lebih dari %(limit)s saat pemotongan.',
      too_fast_for_path_and_constrain: 'Lapisan berikut: %(layers)s \nberisi objek path vektor, dan memiliki kecepatan melebihi %(limit)s. Kecepatan pemotongan objek path vektor akan dibatasi hingga %(limit)s. Anda dapat menghapus batasan ini di Pengaturan Preferensi.',
      touchpad: 'TouchPad',
      ungroup_use: 'Ini akan membatalkan pengelompokan DXF atau SVG yang diimpor. Karena file mungkin berisi sejumlah besar elemen, mungkin memerlukan waktu untuk membatalkan pengelompokan. Apakah Anda yakin untuk melanjutkan?',
      upload_failed: '#819 Unggah file gagal.',
      upload_file_too_large: '#819 File terlalu besar untuk diunggah.',
      vectorize_shading_image: 'Gambar gradien memerlukan waktu lebih lama untuk melacak dan rentan terhadap noise. Harap matikan gradien gambar sebelum mengeksekusi.',
    },
    rating_panel: {
      description: 'Jika Anda menyukai Beam Studio, kami akan sangat menghargai jika Anda dapat memberi penilaian.',
      thank_you: 'Terima kasih!',
      title: 'Menikmati Beam Studio?',
    },
    right_panel: {
      laser_panel: {
        advanced: 'Lanjutan',
        backlash: 'Backlash',
        bi_directional: 'Dua Arah',
        by: 'Sebesar',
        ce_z_high_speed: 'Sumbu Z kecepatan tinggi',
        ce_z_high_speed_link: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
        color_adjustment: 'Penyesuaian Saluran',
        color_adjustment_short: 'Saluran',
        color_strength: 'Kekuatan',
        cross_hatch: 'Arsir Silang',
        custom_preset: 'Kustom',
        cut: 'Potong',
        diode: 'Laser Diode',
        do_not_adjust_default_para: 'Preset tidak dapat disesuaikan.',
        dottingTime: 'Waktu Penandaan',
        dropdown: {
          inches: {
            abs_engraving: 'ABS - Pengukiran',
            acrylic_3mm_cutting: 'Akrilik - 1/8″ Potong',
            acrylic_5mm_cutting: 'Akrilik - 3/16″ Potong',
            acrylic_8mm_cutting: 'Akrilik - 1/4″ Potong',
            acrylic_10mm_cutting: 'Akrilik - 3/8″ Potong',
            acrylic_engraving: 'Akrilik - Pengukiran',
            acrylic_printing: 'Akrilik - Cetak',
            aluminum_engraving: 'Aluminium - Pengukiran',
            aluminum_light: 'Aluminium (terang)',
            bamboo_printing: 'Bambu - Cetak',
            black_abs: 'ABS Hitam',
            black_acrylic_3mm_cutting: 'Akrilik Hitam - 1/8″ Potong',
            black_acrylic_5mm_cutting: 'Akrilik Hitam - 3/16″ Potong',
            black_acrylic_engraving: 'Akrilik Hitam - Pengukiran',
            brass_dark: 'Kuningan (gelap)',
            brass_engraving: 'Kuningan - Pengukiran',
            brass_light: 'Kuningan (terang)',
            canvas_printing: 'Kanvas - Cetak',
            cardstock_printing: 'Karton Tebal - Cetak',
            copper: 'Tembaga',
            cork_printing: 'Gabus - Cetak',
            denim_1mm_cutting: 'Denim - 1/32″ Potong',
            fabric_3mm_cutting: 'Kain - 1/8″ Potong',
            fabric_5mm_cutting: 'Kain - 3/16″ Potong',
            fabric_engraving: 'Kain - Pengukiran',
            fabric_printing: 'Kain - Cetak',
            flat_stone_printing: 'Batu Datar - Cetak',
            glass_bw_engraving: 'Kaca - Pengukiraniran',
            glass_printing: 'Kaca - Cetak',
            gloss_leather_printing: 'Kulit Glossy - Cetak',
            gold_engraving: 'Emas - Pengukiran',
            iron_engraving: 'Besi - Pengukiran',
            leather_3mm_cutting: 'Kulit - 1/8″ Potong',
            leather_5mm_cutting: 'Kulit - 3/16″ Potong',
            leather_engraving: 'Kulit - Pengukiran',
            mdf_3mm_cutting: 'MDF - 1/8″ Potong',
            mdf_5mm_cutting: 'MDF - 3/16″ Potong',
            mdf_engraving: 'MDF - Pengukiran',
            metal_bw_engraving: 'Logam - Pengukiran',
            opaque_acrylic: 'Akrilik buram',
            pc_printing: 'PC - Cetak',
            rubber_bw_engraving: 'Karet - Pengukiran',
            silver_engraving: 'Perak - Pengukiran',
            stainless_steel_bw_engraving_diode: 'Logam - Pengukiran (Laser Diode)',
            stainless_steel_dark: 'Baja tahan karat (gelap)',
            stainless_steel_engraving: 'Baja Tahan Karat - Pengukiran',
            stainless_steel_light: 'Baja tahan karat (terang)',
            stainless_steel_printing: 'Baja Tahan Karat - Cetak',
            steel_engraving_spray_engraving: 'Logam - Pengukiran',
            stone: 'Batu',
            ti_engraving: 'Titanium - Pengukiran',
            titanium_dark: 'Titanium (gelap)',
            titanium_light: 'Titanium (terang)',
            white_abs: 'ABS Putih',
            wood_3mm_cutting: 'Kayu - 1/8″ Potong',
            wood_5mm_cutting: 'Kayu - 3/16″ Potong',
            wood_7mm_cutting: 'Kayu - 1/4″ Potong',
            wood_8mm_cutting: 'Kayu - 1/4″ Potong',
            wood_10mm_cutting: 'Kayu - 3/8″ Potong',
            wood_engraving: 'Kayu - Pengukiran',
            wood_printing: 'Kayu - Cetak',
          },
          mm: {
            abs_engraving: 'ABS - Pengukiran',
            acrylic_3mm_cutting: 'Akrilik - 3mm Potong',
            acrylic_5mm_cutting: 'Akrilik - 5mm Potong',
            acrylic_8mm_cutting: 'Akrilik - 8mm Potong',
            acrylic_10mm_cutting: 'Akrilik - 10mm Potong',
            acrylic_engraving: 'Akrilik - Pengukiran',
            acrylic_printing: 'Akrilik - Cetak',
            aluminum_engraving: 'Aluminium - Pengukiran',
            aluminum_light: 'Aluminium (terang)',
            bamboo_printing: 'Bambu - Cetak',
            black_abs: 'ABS Hitam',
            black_acrylic_3mm_cutting: 'Akrilik Hitam - 3mm Potong',
            black_acrylic_5mm_cutting: 'Akrilik Hitam - 5mm Potong',
            black_acrylic_engraving: 'Akrilik Hitam - Pengukiran',
            brass_dark: 'Kuningan (gelap)',
            brass_engraving: 'Perunggu - Pengukiran',
            brass_light: 'Kuningan (terang)',
            canvas_printing: 'Kanvas - Cetak',
            cardstock_printing: 'Karton Tebal - Cetak',
            copper: 'Tembaga',
            cork_printing: 'Gabus - Cetak',
            denim_1mm_cutting: 'Denim - 1mm Potong',
            fabric_3mm_cutting: 'Kain - 3mm Potong',
            fabric_5mm_cutting: 'Kain - 5mm Potong',
            fabric_engraving: 'Kain - Pengukiran',
            fabric_printing: 'Kain - Cetak',
            flat_stone_printing: 'Batu Datar - Cetak',
            glass_bw_engraving: 'Kaca - Pengukiraniran',
            glass_printing: 'Kaca - Cetak',
            gloss_leather_printing: 'Kulit Glossy - Cetak',
            gold_engraving: 'Emas - Pengukiran',
            iron_engraving: 'Besi - Pengukiran',
            leather_3mm_cutting: 'Kulit - 3mm Potong',
            leather_5mm_cutting: 'Kulit - 5mm Potong',
            leather_engraving: 'Kulit - Pengukiran',
            mdf_3mm_cutting: 'MDF - 3mm Potong',
            mdf_5mm_cutting: 'MDF - 5mm Potong',
            mdf_engraving: 'MDF Selandia Baru - Pengukiran',
            metal_bw_engraving: 'Logam - Pengukiran',
            opaque_acrylic: 'Akrilik buram',
            pc_printing: 'PC - Cetak',
            rubber_bw_engraving: 'Karet - Pengukiran',
            silver_engraving: 'Perak - Pengukiran',
            stainless_steel_bw_engraving_diode: 'Baja Tahan Karat - Pengukiran (Laser Diode)',
            stainless_steel_dark: 'Baja tahan karat (gelap)',
            stainless_steel_engraving: 'Baja Tahan Karat - Pengukiran',
            stainless_steel_light: 'Baja tahan karat (terang)',
            stainless_steel_printing: 'Baja Tahan Karat - Cetak',
            steel_engraving_spray_engraving: 'Logam - Pengukiran',
            stone: 'Batu',
            ti_engraving: 'Titanium - Pengukiran',
            titanium_dark: 'Titanium (gelap)',
            titanium_light: 'Titanium (terang)',
            white_abs: 'ABS Putih',
            wood_3mm_cutting: 'Kayu - 3mm Potong',
            wood_5mm_cutting: 'Kayu - 5mm Potong',
            wood_7mm_cutting: 'Kayu - 7mm Potong',
            wood_8mm_cutting: 'Kayu - 8mm Potong',
            wood_10mm_cutting: 'Kayu - 10mm Potong',
            wood_engraving: 'Kayu - Pengukiran',
            wood_printing: 'Kayu - Cetak',
          },
          parameters: 'Preset',
          save: 'Tambahkan parameter saat ini',
        },
        engrave: 'Ukir',
        existing_name: 'Nama ini sudah digunakan.',
        fill_angle: 'Sudut Isian',
        fill_interval: 'Jarak Isian',
        fill_setting: 'Pengaturan Isi',
        filled_path_only: 'Hanya untuk jalur isian',
        focus_adjustment: 'Penyesuaian Fokus',
        frequency: 'Frekuensi',
        gradient_only: 'Hanya untuk gambar gradasi',
        halftone: 'Setengah tone',
        halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
        height: 'Tinggi Objek',
        ink_saturation: 'Saturasi',
        ink_type: {
          normal: 'Tinta',
          text: 'Jenis Tinta',
          UV: 'Tinta UV',
        },
        laser_speed: {
          fast: 'Cepat',
          max: 300,
          min: 3,
          slow: 'Lambat',
          step: 0.1,
          text: 'Kecepatan',
          unit: 'mm/s',
        },
        low_power_warning: 'Daya laser yang lebih rendah (di bawah 10%) mungkin tidak memancarkan cahaya laser.',
        low_speed_warning: 'Kecepatan rendah dapat menyebabkan pembakaran bahan.',
        lower_focus: 'Turunkan Fokus',
        lower_focus_desc: 'Menurunkan ketinggian fokus dengan jarak tertentu setelah fokus untuk meningkatkan kinerja pemotongan.',
        module: 'Modul',
        more: 'Kelola',
        multi_layer: 'Beberapa Lapisan',
        para_in_use: 'Parameter ini sedang digunakan.',
        parameters: 'Parameter',
        power: {
          high: 'Tinggi',
          low: 'Rendah',
          max: 100,
          min: 1,
          step: 0.1,
          text: 'Daya',
        },
        preset_management: {
          add_new: 'Tambahkan Baru',
          delete: 'Hapus',
          export: 'Ekspor',
          export_preset_title: 'Ekspor Preset',
          import: 'Impor',
          laser: 'Laser',
          lower_focus_by: 'Turunkan Fokus dengan',
          new_preset_name: 'Nama Preset Baru',
          preset: 'Prasetel',
          print: 'Cetak',
          reset: 'Atur Ulang',
          save_and_exit: 'Simpan dan Keluar',
          show_all: 'Tampilkan Semua',
          sure_to_import_presets: 'Ini akan memuat susunan prasetel dan menimpa parameter yang Anda sesuaikan, apakah Anda yakin ingin melanjutkan?',
          sure_to_reset: 'Ini akan menghapus parameter yang Anda sesuaikan dan mengatur ulang semua prasetel, apakah Anda yakin ingin melanjutkan?',
          title: 'Kelola Parameter',
          wobble_diameter: 'Diameter goyangan',
          wobble_step: 'Ukuran langkah goyangan',
        },
        preset_setting: 'Pengaturan Parameter (%s)',
        presets: 'Preset',
        print_multipass: 'Multi-pass',
        promark_speed_desc: 'Parameter kecepatan tidak berlaku untuk gambar gradasi.',
        pulse_width: 'Lebar Denyut',
        pwm_advanced_desc: 'Tetapkan daya minimum untuk mode kedalaman.',
        pwm_advanced_hint: 'Pengaturan ini berlaku khusus untuk gambar gradasi saat menggunakan mode kedalaman.',
        pwm_advanced_setting: 'Pengaturan Daya Mode Kedalaman',
        repeat: 'Jumlah Lintasan',
        single_color: 'Warna Tunggal',
        single_color_desc: 'Hanya berlaku untuk lapisan warna penuh dan tidak dapat digunakan dengan lapisan warna tunggal yang diperluas.',
        slider: {
          fast: 'Cepat',
          high: 'Tinggi',
          low: 'Rendah',
          regular: 'Reguler',
          slow: 'Lambat',
          very_fast: 'Paling Cepat',
          very_high: 'Maksimal',
          very_low: 'Minimal',
          very_slow: 'Paling Lambat',
        },
        speed: 'Kecepatan',
        speed_constrain_warning: 'Kecepatan pemotongan path vector akan dibatasi hingga %(limit)s. Anda dapat menghapus batasan ini di Pengaturan Preferensi.',
        speed_constrain_warning_auto_feeder: 'Kecepatan pemotongan objek Pengumpan Otomatis akan dibatasi hingga %(limit)s. Anda dapat menghapus batas ini di Pengaturan Preferensi.',
        speed_constrain_warning_curve_engraving: 'Kecepatan ukiran objek lengkung akan dibatasi hingga %(limit)s. Anda dapat menghapus batas ini di Pengaturan Preferensi.',
        stepwise_focusing: 'Pemfokusan Bertahap',
        stepwise_focusing_desc: 'Menurunkan jarak fokus secara bertahap berdasarkan tinggi objek selama setiap hitungan lintasan.',
        strength: 'Daya',
        times: 'kali',
        various_preset: 'Berbagai Preset',
        white_ink: 'Tinta Putih',
        white_ink_settings: 'Pengaturan Tinta Putih',
        wobble: 'Goyangan',
        wobble_desc: 'Membuat lingkaran kecil di sepanjang jalur, yang bermanfaat untuk tugas pemotongan. (Hanya untuk objek jalur.)',
        wobble_diameter: 'Diameter',
        wobble_step: 'Ukuran langkah',
        z_step: 'Langkah Z',
      },
      layer_panel: {
        current_layer: 'Lapisan Saat Ini',
        layer1: 'Lapisan 1',
        layer_bitmap: 'Bitmap',
        layer_cutting: 'Pemotongan',
        layer_engraving: 'Pengukiran',
        layers: {
          del: 'Hapus Lapisan',
          dupe: 'Duplikat Lapisan',
          fullColor: 'Warna penuh',
          layer: 'Lapisan',
          layers: 'Lapisan',
          lock: 'Kunci Lapisan',
          merge_all: 'Gabung Semua',
          merge_down: 'Gabung Ke Bawah',
          merge_selected: 'Gabung Lapisan Terpilih',
          move_down: 'Pindahkan Lapisan Ke Bawah',
          move_elems_to: 'Pindahkan elemen ke:',
          move_selected: 'Pindahkan elemen terpilih ke lapisan yang berbeda',
          move_up: 'Pindahkan Lapisan Ke Atas',
          new: 'Lapisan Baru',
          rename: 'Ubah Nama Lapisan',
          splitFullColor: 'Perluas Lapisan',
          switchToFullColor: 'Alihkan ke lapisan warna penuh',
          switchToSingleColor: 'Alihkan ke lapisan warna tunggal',
          unlock: 'Buka Kunci',
        },
        move_elems_to: 'Pindahkan elemen ke:',
        notification: {
          dupeLayerName: 'Sudah ada lapisan dengan nama itu!',
          enterNewLayerName: 'Silakan masukkan nama lapisan baru',
          enterUniqueLayerName: 'Silakan masukkan nama lapisan yang unik',
          layerHasThatName: 'Lapisan sudah memiliki nama itu',
          mergeLaserLayerToPrintingLayerMsg: 'Harap perhatikan bahwa jika Anda menyelesaikan operasi ini, pengaturan lapisan laser akan dihapus dan diatur sesuai dengan lapisan saat ini.',
          mergeLaserLayerToPrintingLayerTitle: 'Apakah Anda ingin menggabungkan lapisan ini menjadi satu lapisan Pencetakan?',
          mergePrintingLayerToLaserLayerMsg: 'Harap perhatikan bahwa jika Anda menyelesaikan operasi ini, pengaturan warna lapisan pencetakan akan dihapus dan diatur sesuai dengan lapisan saat ini.',
          mergePrintingLayerToLaserLayerTitle: 'Apakah Anda ingin menggabungkan lapisan ini menjadi satu lapisan Laser?',
          moveElemFromPrintingLayerMsg: 'Harap perhatikan bahwa jika Anda menyelesaikan operasi ini, pengaturan warna dari elemen terpilih akan dihapus dan diatur sesuai dengan %s.',
          moveElemFromPrintingLayerTitle: 'Pindahkan elemen terpilih ke %s dan ubah menjadi elemen laser?',
          moveElemToPrintingLayerMsg: 'Harap perhatikan bahwa jika Anda menyelesaikan operasi ini, pengaturan dari elemen terpilih akan dihapus dan diatur sesuai dengan %s.',
          moveElemToPrintingLayerTitle: 'Pindahkan elemen terpilih ke %s dan ubah menjadi elemen pencetakan?',
          newName: 'NAMA BARU',
          QmoveElemsToLayer: "Pindahkan elemen terpilih ke lapisan '%s'?",
          splitColorMsg: 'Harap perhatikan bahwa jika Anda melanjutkan operasi ini, Anda tidak akan dapat kembali ke lapisan warna asli.',
          splitColorTitle: 'Apakah Anda ingin memperluas lapisan terpilih menjadi lapisan CMYK?',
        },
      },
      object_panel: {
        actions_panel: {
          ai_bg_removal: 'Hapus Latar Belakang',
          ai_bg_removal_reminder: 'Menekan tombol akan segera menggunakan 0,2 Kredit, apakah Anda ingin melanjutkan?',
          ai_bg_removal_short: 'Hps Latar Blkg',
          array: 'Array',
          auto_fit: 'Pas Otomatis',
          bevel: 'Bevel',
          brightness: 'Kecerahan',
          convert_to_path: 'Convert to Path',
          create_textpath: 'Buat Teks pada Jalur',
          create_textpath_short: 'Teks Jalur',
          crop: 'Potong',
          decompose_path: 'Dekomposisi',
          detach_path: 'Lepaskan Teks pada Jalur',
          detach_path_short: 'Lepaskan',
          disassemble_use: 'Lepaskan',
          disassembling: 'Membongkar...',
          edit_path: 'Edit Lintasan',
          fetching_web_font: 'Mendapatkan font online...',
          grading: 'Pemeringkatan',
          invert: 'Invert',
          offset: 'Offset',
          outline: 'Garis Besar',
          replace_with: 'Ganti Dengan...',
          replace_with_short: 'Ganti',
          sharpen: 'Tajamkan',
          simplify: 'Peredaan',
          smart_nest: 'Sarang Cerdas',
          trace: 'Telusuri',
          ungrouping: 'Batalkan pengelompokan...',
          uploading_font_to_machine: 'Mengunggah font ke mesin...',
          wait_for_parsing_font: 'Mengurai font... Mohon tunggu sebentar',
          weld_text: 'Weld Text',
        },
        align: 'Rata',
        boolean: 'Boolean',
        bottom_align: 'Rata Bawah',
        center_align: 'Rata Tengah',
        difference: 'Selisih',
        distribute: 'Sebar',
        flip: 'Balik',
        group: 'Kelompok',
        hdist: 'Sebar Horizontal',
        hflip: 'Balik Horizontal',
        intersect: 'Iris',
        left_align: 'Rata Kiri',
        lock_aspect: 'Kunci Aspek',
        middle_align: 'Rata Tengah',
        option_panel: {
          color: 'Warna',
          fill: 'Isi',
          font_family: 'Font',
          font_size: 'Ukuran',
          font_style: 'Gaya',
          letter_spacing: 'Spasi Huruf',
          line_spacing: 'Spasi Baris',
          path_infill: 'Isi Lintasan',
          pwm_engraving: 'Mode Kedalaman',
          pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
          rounded_corner: 'Sudut Bulat',
          shading: 'Gradasi',
          sides: 'Sisi',
          start_offset: 'Offset Teks',
          stroke: 'Garis',
          stroke_color: 'Warna Garis',
          stroke_width: 'Lebar Garis',
          text_infill: 'Isi Teks',
          threshold: 'Ambang Kecerahan',
          threshold_short: 'Ambang',
          vertical_align: 'Perataan',
          vertical_text: 'Teks Vertikal',
        },
        path_edit_panel: {
          connect: 'Sambungkan',
          delete: 'Hapus',
          disconnect: 'Putuskan',
          node_type: 'JENIS NODE',
          round: 'Bulat',
          sharp: 'Tajam',
        },
        right_align: 'Rata Kanan',
        subtract: 'Kurang',
        top_align: 'Rata Atas',
        ungroup: 'Pisah Kelompok',
        union: 'Gabung',
        unlock_aspect: 'Buka Kunci Aspek',
        vdist: 'Sebar Vertikal',
        vflip: 'Balik Vertikal',
        zoom: 'Perbesar',
      },
      tabs: {
        layers: 'Lapisan',
        objects: 'Objek',
        path_edit: 'Edit Jalur',
      },
    },
    shapes_panel: {
      animals: 'Hewan',
      arrow: 'Panah',
      basic: 'Dasar',
      birds: 'Burung',
      celebration: 'Perayaan',
      circular: 'Lingkaran',
      CNY: 'Tahun Baru Imlek',
      corner: 'Sudut',
      decor: 'Dekorasi',
      easter: 'Paskah',
      elements: 'Elemen',
      environment: 'Lingkungan',
      graphics: 'Grafis',
      halloween: 'Halloween',
      holidays: 'Liburan',
      label: 'Label',
      land: 'Daratan',
      line: 'Garis',
      nature: 'Alam',
      photo: 'Foto',
      plants: 'Tanaman',
      ribbon: 'Pita',
      sea: 'Laut',
      shape: 'Bentuk',
      speech: 'Pidato',
      text: 'Teks',
      title: 'Elemen',
      valentines: 'Valentine',
      weather: 'Cuaca',
      Xmas: 'Natal',
    },
    svg_editor: {
      unable_to_fetch_clipboard_img: 'Gagal mengambil gambar dari clipboard Anda',
      unnsupported_file_type: 'Jenis file tidak didukung secara langsung. Silakan konversi file menjadi SVG atau bitmap',
    },
    tag: {
      g: 'Grup',
      image: 'Gambar',
      text: 'Teks',
      use: 'Impor SVG',
    },
    time_est_button: {
      calculate: 'Perkirakan waktu',
      estimate_time: 'Perkiraan Waktu:',
    },
    tool_panels: {
      _nest: {
        end: 'Tutup',
        no_element: 'Tidak ada elemen untuk diatur.',
        rotations: 'Kemungkinan Rotasi',
        spacing: 'Spasi',
        start_nest: 'Atur',
        stop_nest: 'Berhenti',
      },
      _offset: {
        corner_type: 'Sudut',
        direction: 'Arah Offset',
        dist: 'Jarak Offset',
        fail_message: 'Gagal Meng-offset Objek',
        inward: 'Ke Dalam',
        not_support_message: 'Elemen terpilih mengandung tag SVG yang tidak didukung:\nGambar, Grup, Teks dan Objek Terimpor.',
        outward: 'Ke Luar',
        round: 'Bulat',
        sharp: 'Tajam',
      },
      array_dimension: 'Dimensi Pola',
      array_interval: 'Interval Array',
      cancel: 'Batal',
      columns: 'Kolom',
      confirm: 'Konfirmasi',
      dx: 'X',
      dy: 'Y',
      grid_array: 'Buat Pola Grid',
      nest: 'Pengaturan Susunan',
      offset: 'Offset',
      rows: 'Baris',
    },
    units: {
      mm: 'mm',
      walt: 'W',
    },
    zoom_block: {
      fit_to_window: 'Sesuaikan dengan Jendela',
    },
  },
  boxgen: {
    add_option: 'Tambah Opsi',
    basic_box: 'Kotak Dasar',
    beam_radius: 'Kompensasi kerf',
    beam_radius_warning: 'Hapus kompensasi kerf saat tepi atau sambungan kotak pendek untuk memastikan perakitan kotak yang tepat',
    cancel: 'Batal',
    coming_soon: 'Segera Hadir',
    continue_import: 'Lanjutkan untuk Impor',
    control_tooltip: 'Klik kiri untuk memutar\nGulir untuk memperbesar\nKlik kanan untuk menggeser',
    control_tooltip_touch: 'Seret untuk memutar\nCubit untuk memperbesar\nDua jari untuk menggeser',
    cover: 'Tutup',
    customize: 'Sesuaikan',
    depth: 'Kedalaman',
    edge: 'Tepi',
    finger: 'Jari',
    finger_warning: 'Panjang sisi dalam kotak minimal 6mm (0,24 inci) agar kompatibel dengan sambungan jari.',
    height: 'Tinggi',
    import: 'Impor',
    inner: 'Dalam',
    joints: 'Sambungan',
    max_dimension_tooltip: 'Maks lebar/tinggi/kedalaman pengaturan adalah %s.',
    merge: 'Gabung',
    outer: 'Luar',
    reset: 'Setel Ulang',
    tCount: 'Hitungan T',
    tCount_tooltip: 'Jumlah slot T berlaku untuk sisi pendek; jumlah pada sisi panjang dihitung berdasarkan panjangnya.',
    tDiameter: 'Diameter T',
    text_label: 'Label',
    thickness: 'Ketebalan',
    title: 'BOXGEN',
    tLength: 'Panjang T',
    tSlot: 'Slot-T',
    tSlot_warning: 'Panjang sisi kotak minimal 30mm (1,18 inci) agar kompatibel dengan sambungan T-slot.',
    volume: 'Volume',
    width: 'Lebar',
    workarea: 'Area Kerja',
    zoom: 'Perbesar',
  },
  buttons: {
    back: 'Kembali',
    back_to_beam_studio: 'Kembali ke Beam Studio',
    done: 'Selesai',
    next: 'Berikutnya',
  },
  calibration: {
    ador_autofocus_focusing_block: 'Tekan ikon "AF" di halaman utama mesin selama 3 detik dan biarkan probe menyentuh Blok Fokus.',
    ador_autofocus_material: 'Tekan ikon “AF” di halaman utama mesin selama 3 detik dan biarkan probe menyentuh bahan dengan lembut.',
    align_ilb: 'Posisi: Sejajarkan dengan titik terukir di kiri bawah dalam.',
    align_ilt: 'Posisi: Sejajarkan dengan titik terukir di kiri atas dalam.',
    align_irb: 'Posisi: Sejajarkan dengan titik terukir di kanan bawah dalam.',
    align_irt: 'Posisi: Sejajarkan dengan titik terukir di kanan atas dalam.',
    align_olb: 'Posisi: Sejajarkan dengan titik terukir di kiri bawah luar.',
    align_olt: 'Posisi: Sejajarkan dengan titik terukir di kiri atas luar.',
    align_orb: 'Posisi: Sejajarkan dengan titik terukir di kanan bawah luar.',
    align_ort: 'Posisi: Sejajarkan dengan titik terukir di kanan atas luar.',
    align_red_cross_cut: 'Harap sejajarkan tengah salib merah dengan salib pemotongan.',
    align_red_cross_print: 'Harap sejajarkan tengah salib merah dengan salib cetak.',
    analyze_result_fail: 'Gagal menganalisis gambar yang ditangkap.<br/>Pastikan:<br/>1. Gambar yang ditangkap sepenuhnya tertutup kertas putih.<br/>2. Platform difokuskan dengan benar.',
    ask_for_readjust: 'Apakah Anda ingin melewati langkah pengukiran dan kemudian mengambil foto dan mengkalibrasi secara langsung?',
    back: 'Kembali',
    calculating_camera_matrix: 'Menghitung Matriks Kamera...',
    calculating_regression_parameters: 'Menghitung Parameter Regresi...',
    calibrate_camera_before_calibrate_modules: 'Harap lakukan kalibrasi kamera sebelum mengkalibrasi modul.',
    calibrate_chessboard_success_msg: 'Foto papan catur berhasil diambil.<br/>Skor untuk foto ini adalah %s (%.2f).',
    calibrate_done: 'Kalibrasi selesai. Akurasi kamera yang lebih baik diberikan saat fokus tepat.',
    calibrate_done_diode: 'Kalibrasi selesai. Offset modul laser diode telah disimpan.',
    calibrating: 'Mengkalibrasi...',
    calibrating_with_device_pictures: 'Melakukan kalibrasi dengan gambar perangkat...',
    camera_calibration: 'Kalibrasi Kamera',
    camera_parameter_saved_successfully: 'Parameter kamera berhasil disimpan.',
    cancel: 'Batalkan',
    check_checkpoint_data: 'Data titik kontrol',
    check_device_pictures: 'Periksa Gambar Perangkat',
    checking_checkpoint: 'Memeriksa data titik kontrol...',
    checking_pictures: 'Memeriksa gambar perangkat...',
    diode_calibration: 'Kalibrasi Modul Laser Diode',
    do_engraving: 'Lakukan Pengukiran',
    download_chessboard_file: 'Unduh file papan catur',
    downloading_checkpoint: 'Mengunduh data titik kontrol...',
    downloading_pictures: 'Mengunduh Gambar...',
    drawing_calibration_image: 'Menggambar gambar kalibrasi...',
    dx: 'X',
    dy: 'Y',
    elevate_and_cut: 'Menaikkan dan Memotong',
    elevate_and_cut_step_1: 'Letakkan sepotong kayu berukuran A4 berwarna terang di tengah area kerja, diangkat ke 20mm.',
    elevate_and_cut_step_1_prism_lift: 'Gunakan Ador Prism Lift dengan panjang maksimum 14 mm bersama dengan kayu setidaknya 6 mm tebal.',
    failed_to_calibrate_camera: '#848 Gagal mengkalibrasi kamera, silakan hubungi Layanan FLUX.',
    failed_to_calibrate_chessboard: 'Gagal mengkalibrasi dengan gambar papan catur.',
    failed_to_calibrate_with_pictures: '#848 Gagal melakukan kalibrasi dengan gambar perangkat.',
    failed_to_download_pictures: '#848 Gagal mengunduh gambar, silakan hubungi Dukungan FLUX.',
    failed_to_move_laser_head: 'Gagal memindahkan kepala laser.',
    failed_to_parse_checkpoint: 'Gagal mengurai data titik kontrol.',
    failed_to_save_calibration_results: '#849 Gagal menyimpan hasil kalibrasi, silakan coba lagi. Jika ini terus terjadi, silakan hubungi Layanan FLUX.',
    failed_to_save_camera_parameter: 'Gagal menyimpan parameter kamera.',
    failed_to_solve_pnp: 'Gagal memecahkan posisi kamera.',
    finish: 'Selesai',
    found_checkpoint: 'Data titik kontrol ditemukan di perangkat Anda. Apakah Anda ingin memulihkannya dari titik kontrol?',
    getting_plane_height: 'Mendapatkan Tinggi Bidang...',
    hint_adjust_parameters: 'Gunakan parameter ini untuk menyesuaikan kotak merah',
    hint_red_square: 'Harap sejajarkan bagian luar kotak merah dengan kotak pemotongan.',
    module_calibration_2w_ir: 'Kalibrasi Modul Inframerah',
    module_calibration_printer: 'Kalibrasi Modul Printer',
    moving_laser_head: 'Memindahkan kepala laser...',
    next: 'Berikutnya',
    no_picutre_found: '#846 Perangkat Anda tidak memiliki foto mentah yang tersedia untuk kalibrasi. Silakan hubungi dukungan FLUX.',
    perform_autofocus_bb2: 'Silakan pergi ke panel kontrol mesin dan tekan AF untuk melakukan fokus otomatis.',
    please_do_camera_calibration_and_focus: {
      beambox: 'Saat mengkalibrasi modul laser diode, kamera diperlukan.\nPastikan kamera mesin ini telah dikalibrasi.\nDan sesuaikan platform ke titik fokus (tinggi akrilik yang dibalik)',
      beamo: 'Saat mengkalibrasi modul laser diode, kamera diperlukan.\nPastikan kamera mesin ini telah dikalibrasi.\nDan sesuaikan kepala laser untuk fokus pada objek ukiran (tinggi akrilik yang dibalik)',
    },
    please_goto_beambox_first: 'Silakan beralih ke Mode Pengukiran ( Beambox ) untuk menggunakan fitur ini.',
    please_place_dark_colored_paper: 'Silakan letakkan kertas berwarna gelap ukuran A4 atau Letter di tengah area kerja.',
    please_place_paper: 'Silakan letakkan kertas putih ukuran A4 atau Letter di pojok kiri atas area kerja.',
    please_place_paper_center: 'Silakan letakkan kertas putih ukuran A4 atau Letter di tengah area kerja.',
    please_refocus: {
      beambox: 'Silakan sesuaikan platform ke titik fokus (tinggi akrilik yang dibalik)',
      beamo: 'Silakan sesuaikan kepala laser untuk memfokuskan pada benda ukiran (tinggi akrilik yang dibalik)',
      beamo_af: 'Silakan ketuk dua kali tombol di samping penambahan fokus otomatis dan biarkan probe menyentuh material dengan lembut.',
      hexa: 'Klik dua kali tombol penyesuaian tinggi untuk menaikkan meja sarang lebah dan membuat probe menyentuh bahan benda ukiran.',
    },
    preparing_to_take_picture: 'Persiapan untuk mengambil foto...',
    promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    put_chessboard: 'Tempatkan Papan Catur',
    put_chessboard_1: 'Silakan pergi ke panel kontrol mesin dan tekan AF untuk melakukan fokus otomatis.',
    put_chessboard_2: 'Silakan pindahkan kertas papan catur atau kepala laser hingga seluruh papan catur dikonfirmasi berada dalam bingkai merah di jendela pratinjau langsung.',
    put_chessboard_3: 'Klik kanan untuk mengunduh gambar papan catur di lokasi saat ini dan periksa kejernihannya.',
    put_chessboard_bb2_desc_1: 'Unduh file papan catur berikut dan cetak di kertas A4 (papan catur yang dicetak harus memiliki kotak berukuran 1x1 cm).',
    put_chessboard_bb2_desc_2: 'Pasang kertas papan catur yang sudah dicetak pada papan yang tidak dapat berubah bentuk, seperti akrilik atau kaca, dan pastikan papan catur rata tanpa kerutan atau terangkat.',
    put_chessboard_bb2_desc_3: 'Letakkan papan dengan papan catur rata di tengah area kerja.',
    put_chessboard_promark_1: 'Sesuaikan jarak fokus ke fokus yang sesuai untuk lensa bidang.',
    put_chessboard_promark_2: 'Pastikan kertas papan catur terlihat jelas tanpa silau. Klik "Berikutnya" untuk mengambil foto.',
    put_chessboard_promark_desc_1: 'Silakan gunakan kertas papan catur yang disediakan di kotak aksesori, atau cetak pola papan catur berikut di kertas A4 untuk kalibrasi kamera.',
    put_chessboard_promark_desc_2: 'Harap letakkan kertas papan catur secara datar dan terpusat di platform kerja.',
    put_paper: 'Letakkan kertas',
    put_paper_promark_1: 'Letakkan karton hitam dari kotak aksesori di platform kerja.',
    put_paper_promark_2: 'Sesuaikan fokus dengan benar, lalu klik "Mulai Ukir" untuk melanjutkan proses ukiran.',
    put_paper_skip: 'Jika gambar kalibrasi tidak otomatis melanjutkan ke pengambilan gambar, tekan "Lewati" untuk mengambil gambar.',
    put_paper_step1: 'Harap letakkan selembar kertas putih berukuran A4 atau Surat di tengah area kerja.',
    put_paper_step2: 'Pastikan empat sudut kertas terpasang dengan baik untuk memastikan kertas tersebut datar.',
    put_paper_step3: 'Klik "Mulai Ukir".',
    res_average: 'Rata-rata',
    res_excellent: 'Luar biasa',
    res_poor: 'Buruk',
    retake: 'Ambil Ulang Gambar',
    rotation_angle: 'Rotasi',
    show_last_config: 'Tampilkan Hasil Terakhir',
    skip: 'Lewati',
    solve_pnp_step1: 'Silakan sejajarkan titik ukiran sesuai dengan nomor dan posisi yang diharapkan dari setiap titik penanda merah.',
    solve_pnp_step2: 'Anda dapat menekan "Ambil Ulang Gambar" untuk meluruskan kembali atau menyesuaikan posisi penanda secara manual.',
    solve_pnp_step3: 'Jika gambar yang diambil tidak sesuai dengan keadaan sebenarnya, harap perbesar atau geser layar untuk menyesuaikan titik kalibrasi pada kartu kalibrasi hitam ke tengah, kemudian coba "Ambil Ulang Gambar".',
    solve_pnp_title: 'Luruskan Titik Penanda',
    start_engrave: 'Mulai Ukir',
    start_printing: 'Mulai Cetak',
    taking_picture: 'Mengambil Gambar...',
    unable_to_load_camera_parameters: "#851 Tidak ada parameter kalibrasi kamera yang tersedia di perangkat Anda. Pergi ke 'Kalibrasi' > 'Kalibrasi Kamera (Lanjutan)' untuk menyelesaikan kalibrasi dan mendapatkan parameter.",
    update_firmware_msg1: 'Firmware Anda tidak mendukung fungsi ini. Harap perbarui firmware ke v',
    update_firmware_msg2: 'atau di atas untuk melanjutkan。 (Menu > Mesin > [Mesin Anda] > Perbarui Firmware)',
    uploading_images: 'Mengunggah Gambar...',
    use_last_config: 'Gunakan Nilai Kalibrasi Terakhir',
    use_old_camera_parameter: 'Apakah Anda ingin menggunakan parameter lensa kamera saat ini?',
    with_af: 'Dengan Fokus Otomatis',
    with_prism_lift: 'Dengan Ador Prism Lift',
    without_af: 'Tanpa Fokus Otomatis',
    without_prism_lift: 'Tanpa Ador Prism Lift',
    x_ratio: 'Rasio X',
    y_ratio: 'Rasio Y',
    zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
  },
  camera_data_backup: {
    download_success: 'Data kamera berhasil diunduh.',
    downloading_data: 'Mengunduh data...',
    estimated_time_left: 'Estimasi waktu tersisa:',
    folder_not_exists: 'Folder yang dipilih tidak ada.',
    incorrect_folder: 'Gagal mengunggah data kalibrasi. Harap periksa apakah folder yang Anda pilih sudah benar.',
    no_picture_found: 'Tidak ada gambar ditemukan di mesin.',
    title: 'Cadangkan Data Kamera',
    upload_success: 'Data kamera berhasil diunggah.',
    uploading_data: 'Mengunggah data...',
  },
  caption: {
    connectionTimeout: 'Koneksi timeout',
  },
  change_logs: {
    added: 'Ditambahkan:',
    change_log: 'Log Perubahan:',
    changed: 'Diubah:',
    fixed: 'Diperbaiki:',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    see_older_version: 'Lihat Versi Lama',
  },
  code_generator: {
    barcode: 'Kode Batang',
    qr_code: 'Kode QR',
  },
  curve_engraving: {
    '921': 'Gagal melakukan fokus otomatis.',
    '922': 'Gagal melakukan pengukuran dengan cahaya merah.',
    amount: 'Jumlah',
    apply_arkwork: 'Terapkan karya seni pada kurva',
    apply_camera: 'Terapkan gambar kamera pada kurva',
    click_to_select_point: 'Klik untuk memilih atau membatalkan pilihan titik untuk mengukur ulang.',
    coloumns: 'Kolom',
    column_gap: 'Celah Kolom',
    err_object_over_range: 'Objek melebihi jangkauan pengukuran.',
    failed_to_take_reference: 'Gagal mengambil referensi',
    gap: 'Celah',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    measure_audofocus_area: 'Ukur Area Autofokus',
    preview_3d_curve: 'Pratinjau Ukiran Kurva',
    remeasure: 'Ukur ulang',
    remeasuring_points: 'Mengukur ulang titik...',
    reselect_area: 'Pilih Area Kembali',
    row_gap: 'Celah Baris',
    rows: 'Baris',
    set_object_height: 'Atur Tinggi Objek',
    set_object_height_desc: 'Ukur ketebalan maksimum objek.',
    start_autofocus: 'Mulai Autofokus',
    starting_measurement: 'Memulai pengukuran...',
    sure_to_delete: 'Apakah Anda ingin menghapus data fokus ukiran kurva?',
    take_reference: 'Ambil Referensi',
    take_reference_desc: 'Silakan pindahkan kepala laser ke titik tertinggi objek, turunkan probe fokus, dan klik "Konfirmasi" untuk memfokuskan.',
  },
  device: {
    abort: 'Batalkan',
    aborted: 'Dibatalkan',
    aborting: 'Membatalkan',
    busy: 'Sibuk',
    cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
    cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
    cartridge_serial_number: 'Ink cartridge serial number',
    close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
    completed: 'Selesai',
    completing: 'Menyelesaikan',
    deviceList: 'Daftar Mesin',
    disable: 'Nonaktifkan',
    disconnectedError: {
      caption: 'Mesin terputus',
      message: 'Harap konfirmasi apakah akses jaringan %s tersedia',
    },
    enable: 'Aktifkan',
    firmware_version: 'Versi Firmware',
    ink_color: 'Ink color',
    ink_level: 'Ink level',
    ink_type: 'Ink type',
    IP: 'IP',
    model_name: 'Nama Model',
    occupied: 'Menduduki',
    pause: 'Jeda',
    paused: 'Dijeda',
    pausedFromError: 'Dihentikan karena kesalahan',
    pausing: 'Menjeda',
    please_wait: 'Mohon Tunggu...',
    preparing: 'Mempersiapkan',
    processing: 'Memproses',
    quit: 'Keluar',
    ready: 'Siap',
    reset: 'Reset',
    resuming: 'Melanjutkan',
    retry: 'Coba lagi',
    running: 'Bekerja',
    scanning: 'Memindai',
    select: 'Pilih',
    select_printer: 'Pilih Printer',
    serial_number: 'Nomor Seri',
    start: 'Mulai',
    starting: 'Memulai',
    status: 'Status',
    submodule_type: 'Module',
    toolhead_change: 'Ganti kepala alat',
    unknown: 'Tidak diketahui',
    uploading: 'Mengunggah',
    UUID: 'UUID',
  },
  device_selection: {
    no_beambox: '#801 Kami tidak dapat menemukan mesin Anda di jaringan.\nHarap ikuti <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">panduan</a> untuk mengatasi masalah koneksi.',
    no_device_web: "#801 Periksa status mesin Anda, atau klik 'Setup Mesin' di bawah untuk mengatur mesin.",
    select_usb_device: 'Pilih Perangkat USB',
  },
  editor: {
    exposure: 'Pemaparan Pratinjau',
    opacity: 'Opasitas Pratinjau',
    prespray_area: 'Area Priming',
  },
  error_pages: {
    screen_size: 'Harap dicatat bahwa Beam Studio mungkin tidak berfungsi secara optimal pada perangkat Anda. Untuk pengalaman terbaik, disarankan perangkat dengan lebar layar minimal 1024 piksel.',
  },
  flux_id_login: {
    connection_fail: '#847 Gagal terhubung ke layanan keanggotaan FLUX.',
    email: 'Email',
    flux_plus: {
      access_monotype_feature: 'Anda tidak berlangganan Add-on Font Monotype.',
      access_monotype_feature_note: 'Anda perlu berlangganan FLUX+ Pro atau Add-on Font Monotype untuk mengakses fitur ini.',
      access_plus_feature_1: 'Anda mengakses fitur',
      access_plus_feature_2: 'fitur.',
      access_plus_feature_note: 'Anda perlu berlangganan FLUX+ untuk mengakses fitur ini.',
      ai_credit_tooltip: 'Untuk penghapusan latar belakang AI',
      explore_plans: 'Jelajahi Rencana FLUX+',
      features: {
        ai_bg_removal: 'Pembuangan Latar Belakang AI',
        boxgen: 'Generator Kotak 3D',
        dmkt: '1000+ File Desain',
        monotype: '250+ Font Premium',
        my_cloud: 'Penyimpanan Cloud Tak Terbatas',
      },
      flux_credit_tooltip: 'Untuk file Pasar Desain dan penghapusan latar belakang AI',
      get_addon: 'Dapatkan Add-on',
      goto_member_center: 'Pergi ke Pusat Anggota',
      learn_more: 'Pelajari Lebih Lanjut',
      member_center_url: 'https://member.flux3dp.com/en-US/subscription',
      subscribe_now: 'Berlangganan sekarang',
      thank_you: 'Terima kasih telah menjadi anggota berharga!',
      website_url: 'https://flux3dp.com/subscription',
    },
    forget_password: 'Lupa Kata Sandi?',
    incorrect: 'Alamat email atau kata sandi salah.',
    login: 'Masuk',
    login_success: 'Berhasil login.',
    lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
    new_to_flux: 'Baru di FLUX? Buat akun.',
    not_verified: 'Alamat email belum diverifikasi.',
    offline: 'Bekerja Offline',
    password: 'Kata Sandi',
    register: 'Buat Akun FLUX Anda',
    remember_me: 'Ingat saya',
    signup_url: 'https://id.flux3dp.com/user/login#up',
    unlock_shape_library: 'Masuk untuk membuka kunci database bentuk.',
    work_offline: 'Bekerja Offline',
  },
  framing: {
    area_check: 'Pemeriksaan Area',
    areacheck_desc: 'Memastikan area kerja aman dengan menampilkan kotak pembatas objek dan zona akselerasi kepala laser.',
    calculating_task: 'Menghitung tugas...',
    framing: 'Pembingkaian',
    framing_desc: 'Pratinjau kotak pembatas objek.',
    hull: 'Kerangka',
    hull_desc: 'Memperlihatkan bentuk yang mengikuti desain, seperti karet yang melilit objek.',
    low_laser: 'Laser rendah',
    low_laser_desc: 'Tetapkan nilai laser daya rendah untuk tugas pembingkaian.',
    rotate_axis: 'Poros rotasi',
    rotateaxis_desc: 'Pratinjau pusat poros rotasi, poros itu sendiri tidak bergerak.',
    rotation_frame_warning: 'Pratinjau bingkai akan berputar sekali. Hindari berhenti di tengah jalan untuk mencegah ketidaksesuaian posisi penandaan.',
    rotation_framing_desc: 'Pratinjau jangkauan rotasi penuh, poros berputar sesuai dengan pratinjau.',
    start_task: 'Mulai Tugas',
    start_task_description: 'Setelah menyelesaikan pratinjau bingkai, klik "Mulai Tugas" untuk mengirimkan pekerjaan.',
  },
  general: {
    choose_folder: 'Pilih Folder',
    processing: 'Memproses...',
  },
  generic_error: {
    OPERATION_ERROR: '[OE] Terjadi konflik status, harap coba ulangi tindakan.',
    SUBSYSTEM_ERROR: '[SE] Terjadi kesalahan saat firmware mesin mengeksekusi tugas. Harap mulai ulang mesin.',
    UNKNOWN_COMMAND: '[UC] Harap perbarui Firmware Perangkat',
    UNKNOWN_ERROR: '[UE] Terjadi kesalahan yang tidak diketahui. Harap mulai ulang Beam Studio dan mesin.',
  },
  global: {
    apply: 'Terapkan',
    back: 'Kembali',
    cancel: 'Batal',
    editing: {
      redo: 'Ulangi',
      reset: 'Atur Ulang',
      undo: 'Urungkan',
      zoom_in: 'Perbesar',
      zoom_out: 'Perkecil',
    },
    mode_conflict: 'Opsi ini tidak tersedia dalam mode saat ini.',
    ok: 'OK',
    save: 'Simpan',
    stop: 'Berhenti',
  },
  image_edit_panel: {
    eraser: {
      brush_size: 'Ukuran Kuas',
      description: 'Klik atau seret untuk menghapus area yang tidak diinginkan secara manual.',
      title: 'Penghapus',
    },
    magic_wand: {
      description: 'Pilih dan hapus area yang berdekatan berdasarkan kesamaan warna.',
      title: 'Tongkat Ajaib',
      tolerance: 'Toleransi',
    },
    title: 'Edit Gambar',
  },
  initialize: {
    back: 'Kembali',
    cancel: 'Batal',
    confirm: 'Konfirmasi',
    connect: 'Sambungkan',
    connect_ethernet: {
      title: 'Sambungan Langsung',
      tutorial1: '1. Sambungkan mesin dengan komputer Anda dengan kabel ethernet.',
      tutorial2_1: '2. Ikuti',
      tutorial2_2: '2. untuk menjadikan komputer Anda sebagai router.',
      tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
      tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
      tutorial2_a_text: 'panduan ini',
      tutorial3: '3. Klik Lanjutkan.',
    },
    connect_machine_ip: {
      alert: {
        swiftray_connection_error: 'Tidak dapat terhubung ke server. Silakan mulai ulang Beam Studio dan coba lagi.',
      },
      check_camera: 'Memeriksa ketersediaan kamera',
      check_connection: 'Memeriksa Koneksi Mesin',
      check_firmware: 'Memeriksa versi firmware',
      check_ip: 'Memeriksa ketersediaan IP',
      check_swiftray_connection: 'Memeriksa koneksi server',
      check_swiftray_connection_unreachable: 'server tidak dapat dijangkau',
      check_usb: 'Periksa Koneksi USB',
      enter_ip: 'Masukkan IP Mesin Anda',
      finish_setting: 'Mulai Membuat!',
      invalid_format: 'Format tidak valid',
      invalid_ip: 'IP Tidak Valid: ',
      promark_hint: 'Jika Anda berulang kali gagal terhubung melalui USB, silakan lihat <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">artikel di Pusat Bantuan</a>.',
      retry: 'Coba lagi',
      starts_with_169254: 'Dimulai dengan 169.254',
      succeeded_message: 'Berhasil terhubung 🎉',
      unreachable: 'IP tidak dapat dijangkau',
    },
    connect_usb: {
      connect_camera: 'Hubungkan kamera mesin ke komputer Anda dengan kabel USB.',
      title: 'Koneksi USB',
      title_sub: '(HEXA & Ador Saja)',
      turn_off_machine: 'Matikan mesin.',
      turn_on_machine: 'Nyalakan mesin.',
      tutorial1: 'Sambungkan mesin dengan komputer Anda dengan kabel USB.',
      tutorial2: "Klik 'Lanjutkan'.",
      wait_for_turning_on: 'Klik "Lanjutkan" setelah proses startup selesai dan Anda telah masuk ke layar utama.',
    },
    connect_wifi: {
      title: 'Menyambung ke Wi-Fi',
      tutorial1: '1. Pergi ke Panel Sentuh > Klik "MESIN" > Klik "Jaringan" > "Sambung ke WiFi".',
      tutorial1_ador: '1. Pergi ke Panel Sentuh > Klik "MESIN" > Klik "Jaringan" > "Sambung ke WiFi".',
      tutorial2: '2. Pilih dan sambung Wi-Fi pilihan Anda.',
      what_if_1: 'Bagaimana jika saya tidak melihat Wi-Fi saya?',
      what_if_1_content: '1. Mode enkripsi Wi-Fi harus WPA2 atau tanpa kata sandi.\n2. Mode enkripsi dapat diatur di antarmuka administrasi router Wi-Fi. Jika router tidak mendukung WPA2 dan Anda membutuhkan bantuan memilih router yang tepat, silakan hubungi Dukungan FLUX.',
      what_if_2: 'Bagaimana jika saya tidak melihat Wi-Fi sama sekali?',
      what_if_2_content: '1. Pastikan stik Wi-Fi terpasang dengan benar.\n2. Jika tidak ada Alamat MAC jaringan nirkabel di layar sentuh, silakan hubungi Dukungan FLUX.\n3. Saluran Wi-Fi harus 2,4Ghz (5Ghz tidak didukung).',
    },
    connect_wired: {
      title: 'Menyambung ke Jaringan Kabel',
      tutorial1: '1. Sambungkan mesin dengan router Anda.',
      tutorial2: '2. Tekan "MESIN" > "Jaringan" untuk mendapatkan IP jaringan kabel.',
      tutorial2_ador: '2. Tekan "MESIN" > "Jaringan" untuk mendapatkan IP jaringan kabel.',
      what_if_1: 'Bagaimana jika IP kosong?',
      what_if_1_content: '1. Pastikan kabel Ethernet terpasang dengan benar.\n2. Jika tidak ada Alamat MAC jaringan kabel di layar sentuh, silakan hubungi Dukungan FLUX.',
      what_if_2: 'Bagaimana jika IP dimulai dengan 169?',
      what_if_2_content: '1. Jika alamat IP dimulai dengan 169.254, itu harus masalah pengaturan DHCP, silakan hubungi ISP (penyedia layanan internet) Anda untuk bantuan lebih lanjut.\n2. Jika komputer Anda terhubung ke internet langsung menggunakan PPPoE, silakan ganti menggunakan router untuk menyambung menggunakan PPPoE, dan aktifkan fitur DHCP di router.',
    },
    connecting: 'Menghubungkan...',
    connection_types: {
      ether2ether: 'Sambungan Langsung',
      usb: 'Sambungan USB',
      wifi: 'Wi-Fi',
      wired: 'Jaringan Kabel',
    },
    next: 'Berikutnya',
    no_machine: 'Saya tidak memiliki mesin sekarang.',
    promark: {
      configuration_confirmation: 'Ini memastikan Promark Anda dikonfigurasi dengan benar untuk efisiensi dan akurasi optimal.',
      or_complete_later: `Atau, lewati langkah ini dan lengkapi Pengaturan Promark nanti di:
    Mesin > "Nama Promark" > Pengaturan Promark`,
      qc_instructions: 'Isi parameter yang terletak di bagian belakang kartu "QC Pass"',
      select_laser_source: 'Pilih Promark Anda',
      select_workarea: 'Pilih Area Kerja',
      settings: 'Pengaturan Promark',
    },
    retry: 'Coba lagi',
    select_beambox: 'Pilih Beambox Anda',
    select_connection_type: 'Bagaimana Anda ingin menyambung?',
    select_language: 'Pilih Bahasa',
    select_machine_type: 'Pilih Jenis Mesin Anda',
    setting_completed: {
      back: 'Kembali',
      great: 'Selamat datang di Beam Studio',
      ok: 'MULAI MEMBUAT',
      setup_later: 'Anda selalu dapat mengatur mesin Anda dari Bilah Judul > "Mesin" > "Pengaturan Mesin"',
      start: 'Mulai',
    },
    skip: 'Lewati',
    start: 'Mulai',
  },
  input_machine_password: {
    connect: 'HUBUNGKAN',
    password: 'Kata Sandi',
    require_password: '"%s" memerlukan kata sandi',
  },
  insecure_websocket: {
    extension_detected: 'Perpanjangan Beam Studio Connect Terdeteksi',
    extension_detected_description: "Kami telah mendeteksi bahwa Anda telah menginstal perpanjangan Beam Studio Connect. Klik 'Konfirmasi' untuk mengalihkan ke HTTPS, atau klik 'Batal' untuk melanjutkan menggunakan HTTP.",
    extension_not_deteced: 'Tidak dapat Mendeteksi Perpanjangan Beam Studio Connect',
    extension_not_deteced_description: "Untuk menggunakan HTTPS, klik 'Konfirmasi' untuk menginstal ekstensi Beam Studio Connect. Setelah menginstal ekstensi, silakan segarkan halaman untuk mengaktifkannya.<br/>Jika tidak, klik tautan di bawah ini untuk melihat cara menggunakan HTTP di Chrome.",
    unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Tautan</a>',
  },
  layer_module: {
    general_laser: 'Laser',
    laser_2w_infrared: 'Laser Inframerah 2W',
    laser_10w_diode: 'Laser Diode 10W',
    laser_20w_diode: 'Laser Diode 20W',
    non_working_area: 'Area Non-kerja',
    none: 'None',
    notification: {
      convertFromLaserModuleMsg: 'Harap perhatikan bahwa jika Anda menyelesaikan operasi ini, pengaturan lapisan laser akan dihapus dan disesuaikan dengan lapisan saat ini.',
      convertFromLaserModuleTitle: 'Apakah Anda ingin mengubah modul Laser menjadi modul Cetak?',
      convertFromPrintingModuleMsg: 'Harap perhatikan bahwa jika Anda menyelesaikan operasi ini, pengaturan warna lapisan cetak akan dihapus dan disesuaikan dengan lapisan saat ini.',
      convertFromPrintingModuleTitle: 'Apakah Anda ingin mengubah modul Pencetakan menjadi modul Laser?',
      importedDocumentContainsPrinting: 'Dokumen berisi lapisan cetak, apakah Anda ingin mengubah area kerja ke Ador?',
      performIRCaliMsg: 'Klik "Konfirmasi" untuk mengeksekusi kalibrasi, atau akses kalibrasi melalui menu atas. <br /> (Mesin> [Nama Mesin Anda]> Kalibrasi Modul Inframerah)',
      performIRCaliTitle: 'Lakukan kalibrasi modul Inframerah',
      performPrintingCaliMsg: 'Klik "Konfirmasi" untuk mengeksekusi kalibrasi, atau akses kalibrasi melalui menu atas. <br /> (Mesin> [Nama Mesin Anda]> Kalibrasi Modul Cetak)',
      performPrintingCaliTitle: 'Lakukan kalibrasi modul cetak',
      printingLayersCoverted: 'Lapisan cetak telah dikonversi menjadi lapisan laser.',
    },
    printing: 'Pencetakan',
    unknown: 'Unknown Module',
  },
  machine_status: {
    '-17': 'Mode Kartrid IO',
    '-10': 'Mode perawatan',
    '-2': 'Pemindaian',
    '-1': 'Pemeliharaan',
    '0': 'Menganggur',
    '1': 'Memulai',
    '2': 'ST_TRANSFORM',
    '4': 'Mulai',
    '6': 'Melanjutkan',
    '16': 'Bekerja',
    '18': 'Melanjutkan',
    '32': 'Dijeda',
    '36': 'Dijeda',
    '38': 'Penjedaan',
    '48': 'Dijeda',
    '50': 'Penjedaan',
    '64': 'Selesai',
    '66': 'Menyelesaikan',
    '68': 'Mempersiapkan',
    '128': 'Dibatalkan',
    '256': 'Alarm',
    '512': 'Fatal',
    UNKNOWN: 'Tidak diketahui',
  },
  material_test_generator: {
    block_settings: 'Pengaturan Blok',
    columns: 'Kolom',
    count: 'Jumlah',
    cut: 'Potong',
    engrave: 'Ukir',
    export: 'Ekspor',
    max: 'Maks',
    min: 'Min',
    parameter: 'Parameter',
    preview: 'Pratinjau',
    rows: 'Baris',
    size: 'Ukuran (PxL)',
    spacing: 'Jarak',
    table_settings: 'Pengaturan Tabel',
    text_settings: 'Pengaturan Teks',
    title: 'Generator Uji Material',
  },
  menu: {
    inches: 'Inci',
    mm: 'mm',
  },
  message: {
    auth_error: '#820 Kesalahan Otentikasi: Harap perbarui Beam Studio dan firmware mesin ke versi terbaru.',
    authenticating: 'Mengotentikasi...',
    camera: {
      abort_preview: 'Batalkan',
      camera_cable_unstable: 'Terdeteksi kamera mentransfer foto secara tidak stabil. Pratinjau masih dapat dilakukan, tetapi mungkin ada masalah lambat atau waktu habis.',
      continue_preview: 'Lanjutkan',
      fail_to_transmit_image: '#845 Terjadi kesalahan transmisi gambar. Harap mulai ulang mesin atau Beam Studio. Jika error berlanjut, ikuti <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">panduan ini</a>.',
      ws_closed_unexpectly: '#844 Koneksi ke kamera mesin ditutup secara tidak terduga. Jika error berlanjut, ikuti <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">panduan ini</a>.',
    },
    cancelling: 'Membatalkan...',
    cant_connect_to_device: '#827 Tidak dapat terhubung ke mesin, harap periksa koneksi Anda',
    connected: 'Terhubung',
    connecting: 'Menghubungkan...',
    connectingCamera: 'Menghubungkan kamera...',
    connectingMachine: 'Menghubungkan %s...',
    connectionTimeout: '#805 Waktu Koneksi Perangkat Habis. Silakan periksa koneksi jaringan Anda dan indikator Wi-Fi mesin.',
    device_blocked: {
      caption: 'Nomor Seri Tidak Sah',
      offline: 'Nomor seri perangkat Anda dibatasi untuk penggunaan offline karena tampaknya dinonaktifkan. Silakan berikan nomor seri perangkat (terletak di bagian belakang perangkat) kepada penjual Anda dan minta mereka menghubungi support@flux3dp.com untuk mengaktifkan otorisasi online. Jika Anda ingin menggunakan perangkat secara offline, silakan hubungi support@flux3dp.com langsung.',
      online: 'Nomor seri perangkat Anda tampaknya dinonaktifkan. Silakan berikan nomor seri perangkat (terletak di bagian belakang perangkat) kepada penjual Anda dan minta mereka menghubungi support@flux3dp.com untuk mengaktifkan otorisasi perangkat.',
    },
    device_busy: {
      caption: 'Mesin Sibuk',
      message: 'Mesin sedang mengerjakan tugas lain, coba lagi nanti. Jika berhenti bekerja, harap mulai ulang mesin.',
    },
    device_is_used: 'Mesin sedang digunakan, apakah Anda ingin membatalkan tugas saat ini?',
    device_not_found: {
      caption: 'Mesin Default tidak ditemukan',
      message: '#812 Silakan periksa indikator Wi-Fi mesin',
    },
    disconnected: 'Koneksi tidak stabil, harap periksa koneksi perangkat dan coba lagi nanti',
    endingLineCheckMode: 'Keluar dari mode pemeriksaan garis...',
    endingRawMode: 'Keluar dari mode mentah...',
    enteringLineCheckMode: 'Memasuki mode pemeriksaan garis...',
    enteringRawMode: 'Memasuki mode mentah...',
    enteringRedLaserMeasureMode: 'Memasuki mode pengukuran laser merah...',
    exitingRotaryMode: 'Keluar dari mode putar...',
    getProbePosition: 'Mendapatkan Posisi Probe...',
    gettingLaserSpeed: 'Membaca kecepatan kepala laser...',
    homing: 'Melakukan pengaturan awal...',
    need_password: 'Memerlukan Kata Sandi untuk Menghubungkan ke Mesin',
    please_enter_dpi: 'Harap masukkan Satuan file Anda (dalam mm)',
    preview: {
      adjust: 'Sesuaikan',
      adjust_height_tooltip: 'Centang kotak untuk mengaktifkan penyuntingan.',
      already_performed_auto_focus: 'Anda sudah melakukan fokus otomatis, gunakan nilai yang ada?',
      auto_focus: 'Fokus Otomatis',
      auto_focus_instruction: 'Harap pindahkan kepala modul laser di atas objek, dan ikuti petunjuk animasi untuk menekan AF untuk memfokuskan.',
      camera_preview: 'Pratinjau Kamera',
      enter_manually: 'Masukkan Secara Manual',
      please_enter_height: 'Silakan masukkan tinggi objek agar dapat mengambil gambar kamera yang akurat.',
    },
    promark_disconnected: '#850 Koneksi perangkat terputus, periksa status koneksi perangkat.',
    redLaserTakingReference: 'Mengambil referensi...',
    retrievingCameraOffset: 'Membaca offset kamera...',
    settingLaserSpeed: 'Mengatur kecepatan kepala laser...',
    swiftray_disconnected: 'Tidak dapat terhubung ke backend, mencoba menghubungkan ulang.',
    swiftray_reconnected: 'Backend telah terhubung kembali, silakan coba kirim ulang pekerjaan.',
    time_remaining: 'Waktu tersisa:',
    tryingToConenctMachine: 'Mencoba menyambungkan ke mesin...',
    turningOffAirPump: 'Mematikan pompa udara...',
    turningOffFan: 'Mematikan kipas...',
    unable_to_find_machine: 'Tidak dapat menemukan mesin',
    unable_to_start: '#830 Tidak dapat memulai tugas. Harap coba lagi. Jika ini terjadi lagi, hubungi kami dengan laporan bug:',
    unavailableWorkarea: '#804 Area kerja saat ini melebihi area kerja mesin ini. Harap periksa area kerja mesin yang dipilih atau atur area kerja dari Edit > Pengaturan Dokumen.',
    unknown_device: '#826 Tidak dapat terhubung ke mesin, pastikan USB terpasang ke mesin',
    unknown_error: '#821 Aplikasi mengalami kesalahan yang tidak diketahui, harap gunakan Bantuan > Menu > Laporan Bug.',
    unsupport_osx_version: 'Versi macOS %s saat ini mungkin tidak mendukung beberapa fungsi. Harap perbarui ke macOS 11+.',
    unsupport_win_version: 'Versi OS %s saat ini mungkin tidak mendukung beberapa fungsi. Harap perbarui ke versi terbaru.',
    unsupported_example_file: 'File contoh yang dipilih tidak didukung oleh area kerja saat ini.',
    uploading_fcode: 'Mengunggah FCode',
    usb_unplugged: 'Koneksi USB terputus. Harap periksa koneksi USB Anda',
    wrong_swiftray_version_message: 'Versi backend salah (versi: {version}). Harap periksa apakah beberapa instance Beam Studio sedang berjalan dan tutup instance tambahan sebelum mencoba lagi.',
    wrong_swiftray_version_title: 'Versi backend salah',
  },
  monitor: {
    ask_reconnect: 'Koneksi dengan mesin telah terputus. Apakah Anda ingin menyambungkan kembali?',
    bug_report: 'Laporan Bug',
    camera: 'Kamera',
    cancel: 'Batalkan',
    confirmFileDelete: 'Apakah Anda yakin ingin menghapus file ini?',
    connecting: 'Menghubungkan, mohon tunggu...',
    DEVICE_ERROR: 'Ada kesalahan\nHarap mulai ulang mesin',
    download: 'Unduh',
    extensionNotSupported: 'Format file ini tidak didukung',
    fileExistContinue: 'File sudah ada, apakah Anda ingin menggantinya?',
    forceStop: 'Apakah Anda ingin membatalkan tugas saat ini?',
    go: 'Mulai',
    HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Sensor aliran udara abnormal',
    HARDWARE_ERROR_BOTTOM_OPENED: '#903 Bawah Terbuka. Tutup bagian bawah untuk melanjutkan.',
    HARDWARE_ERROR_DOOR_OPENED: '#901 Tutup pintu untuk melanjutkan.',
    HARDWARE_ERROR_DRAWER_OPENED: '#911 Laci terbuka',
    HARDWARE_ERROR_FIRE_DETECTED: '#912 Sensor api anomali',
    HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Terdeteksi modul yang salah. Mohon pasang modul yang benar untuk melanjutkan.',
    HARDWARE_ERROR_HEADTYPE_NONE: '#917 Tidak terdeteksi modul. Pastikan modul terpasang dengan benar untuk melanjutkan.',
    HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Terdeteksi modul yang tidak diketahui. Pasang modul yang benar untuk melanjutkan.',
    HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Kesalahan Tarik Lepas Penghomatan',
    HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Kesalahan Kritis: Papan utama offline. Silakan hubungi Dukungan FLUX.',
    HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Terlalu panas. Mohon tunggu beberapa menit.',
    HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Reset sumbu Z gagal',
    HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Tidak ada respon dari modul printer.',
    HARDWARE_ERROR_PROBE_SHOWED: 'Harap tarik kembali probe.',
    HARDWARE_ERROR_PUMP_ERROR: '#900 Silakan periksa dengan tangki air Anda.',
    HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Tidak terdeteksi modul putar',
    HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Kesalahan Kritis: Subsistem tidak merespons. Silakan hubungi Dukungan FLUX.',
    HARDWARE_FAILURE: 'Ada kesalahan\nHarap mulai ulang mesin',
    hour: 'j',
    left: 'kiri',
    MAINBOARD_OFFLINE: '#905 Kesalahan saat menyambung ke papan utama.\nHarap mulai ulang mesin.',
    minute: 'm',
    monitor: 'MONITOR',
    NO_RESPONSE: '#905 Kesalahan saat menyambung ke papan utama.\nHarap mulai ulang mesin.',
    pause: 'Jeda',
    prepareRelocate: 'Persiapan untuk Pindah',
    processing: 'Memproses',
    record: 'Rekam',
    relocate: 'Pindahkan',
    RESOURCE_BUSY: 'Mesin sibuk\nJika tidak berjalan, harap mulai ulang mesin',
    resume: 'Lanjutkan',
    savingPreview: 'Membuat gambar mini',
    second: 'd',
    start: 'Mulai',
    stop: 'Berhenti',
    SUBSYSTEM_ERROR: '#402 Kesalahan Kritis: Subsistem tidak merespons. Harap hubungi Dukungan FLUX.',
    task: {
      BEAMBOX: 'Pemotongan Laser',
      'N/A': 'Mode Bebas',
    },
    taskTab: 'Tugas',
    temperature: 'Suhu',
    upload: 'Unggah',
    USER_OPERATION: 'Silakan ikuti petunjuk di panel perangkat untuk melanjutkan.',
    USER_OPERATION_CHANGE_CARTRIDGE: 'Silakan masukkan kartrid yang benar untuk melanjutkan.',
    USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Silakan masukkan kartrid untuk melanjutkan.',
    USER_OPERATION_CHANGE_TOOLHEAD: 'Pasang modul yang benar untuk melanjutkan.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Terdeteksi modul yang salah. Mohon pasang modul yang benar untuk melanjutkan.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Tidak terdeteksi modul. Pastikan modul terpasang dengan benar untuk melanjutkan.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Terdeteksi modul yang tidak diketahui. Pasang modul yang benar untuk melanjutkan.',
    USER_OPERATION_REMOVE_CARTRIDGE: 'Harap lepas kartrid untuk melanjutkan.',
    USER_OPERATION_ROTARY_PAUSE: 'Harap beralih ke motor putar.',
  },
  my_cloud: {
    action: {
      confirmFileDelete: 'Apakah Anda yakin ingin menghapus berkas ini? Tindakan ini tidak dapat dibatalkan.',
      delete: 'Hapus',
      download: 'Unduh',
      duplicate: 'Duplikat',
      open: 'Buka',
      rename: 'Ubah Nama',
    },
    file_limit: 'File gratis',
    loading_file: 'Memuat...',
    no_file_subtitle: 'Buka Menu > "Berkas" > "Simpan ke Awan"',
    no_file_title: 'Simpan berkas ke Awan Saya untuk memulai.',
    save_file: {
      choose_action: 'Simpan Berkas:',
      input_file_name: 'Masukkan nama berkas:',
      invalid_char: 'Karakter tidak valid:',
      save: 'Simpan',
      save_new: 'Simpan sebagai berkas baru',
      storage_limit_exceeded: 'Penyimpanan cloud Anda telah mencapai batas atas. Harap hapus file yang tidak diperlukan sebelum menyimpan file baru.',
    },
    sort: {
      a_to_z: 'Nama: A - Z',
      most_recent: 'Terbaru',
      oldest: 'Terlama',
      z_to_a: 'Nama: Z - A',
    },
    title: 'Awan Saya',
    upgrade: 'Tingkatkan',
  },
  noun_project_panel: {
    clear: 'Hapus',
    elements: 'Elemen',
    enjoy_shape_library: 'Nikmati basis data bentuk.',
    export_svg_title: 'Tidak dapat Mengekspor SVG',
    export_svg_warning: 'Proyek ini berisi objek yang dilindungi oleh hukum kekayaan intelektual. Oleh karena itu Beam Studio akan secara otomatis mengecualikan objek-objek ini selama ekspor. Anda masih dapat menyimpan proyek Anda dalam format Beam Studio Scene (.beam) untuk menyimpan seluruh data Anda. Apakah Anda masih ingin mengekspor?',
    learn_more: 'Pelajari Lebih Lanjut',
    login_first: 'Masuk untuk membuka basis data bentuk.',
    recent: 'Terbaru',
    search: 'Cari',
    shapes: 'Bentuk',
  },
  pass_through: {
    export: 'Ekspor ke Area Kerja',
    exporting: 'Mengekspor...',
    guide_mark: 'Tanda Panduan',
    guide_mark_desc: 'Tanda panduan akan diukir sebagai titik referensi untuk menyelaraskan karya seni.',
    guide_mark_length: 'Panjang:',
    guide_mark_x: 'Koordinat X:',
    height_desc: 'Atur ketinggian setiap bagian area kerja.',
    help_links: {
      ado1: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
      fbb2: 'https://support.flux3dp.com/hc/en-us/articles/11570021253263',
    },
    help_text: 'Bagaimana cara mengatur Lewat Langsung untuk %(model)s?',
    object_length: 'Panjang Objek',
    ref_layer: 'Lapisan Referensi',
    ref_layer_desc: 'Harap dicatat bahwa eksekusi lapisan referensi secara default diatur ke 0. Itu tidak akan dieksekusi tetapi hanya untuk referensi penyelarasan.',
    ref_layer_name: 'Referensi',
    title: 'Mode Lewat Langsung',
    workarea_height: 'Area Kerja (Tinggi):',
  },
  promark_connection_test: {
    description: 'Sistem akan menjalankan operasi lampu merah selama sekitar dua menit untuk memeriksa apakah koneksi stabil.',
    health: 'Stabilitas:',
    healthy_description: 'Stabilitas baik, tetapi hindari menggerakkan komputer secara signifikan selama proses gravir.',
    res_0: 'Sangat Baik',
    res_1: 'Baik',
    res_2: 'Rendah',
    res_3: 'Terlalu Rendah',
    res_4: 'Sangat Rendah',
    restart: 'Ulangi Tes',
    start: 'Mulai Tes',
    stop: 'Hentikan Tes',
    title: 'Tes Stabilitas Koneksi',
    unhealthy_description: 'Stabilitas terlalu rendah. Disarankan untuk mengganti adaptor jaringan atau kabel daya.',
  },
  promark_settings: {
    angle: 'Sudut',
    bulge: 'Tonjolan',
    field: 'Bidang',
    galvo_configuration: 'Konfigurasi Galvo',
    mark: 'Tandai',
    mark_parameters: 'Parameter Penandaan',
    offsetX: 'Penggeseran X',
    offsetY: 'Penggeseran Y',
    preview: 'Pratinjau',
    red_dot: 'Titik Merah',
    scale: 'Skala',
    scaleX: 'Skala X',
    scaleY: 'Skala Y',
    skew: 'Kemiringan',
    switchXY: 'Tukar X/Y',
    title: 'Pengaturan Promark',
    trapezoid: 'Trapesium',
    workarea_hint: 'Anda dapat mengubah area kerja di "Pengaturan Dokumen".',
    z_axis_adjustment: {
      section1: 'Sesuaikan ketinggian sumbu Z untuk menyempurnakan fokus.',
      title: 'Penyesuaian Sumbu Z',
      tooltip1: 'Cobalah menandai kotak 1x1 cm untuk memastikan jarak fokus saat ini sesuai.',
    },
  },
  qr_code_generator: {
    error_tolerance: 'Toleransi Kesalahan',
    error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
    invert: 'Balik warna latar belakang',
    placeholder: 'Masukkan tautan atau teks',
    preview: 'Pratinjau',
    title: 'Pembuat Kode QR',
  },
  rotary_settings: {
    circumference: 'Keliling',
    extend_workarea: 'Perluas area kerja',
    mirror: 'Cermin',
    object_diameter: 'Diameter Objek',
    overlap_size: 'Ukuran tumpang tindih',
    rotary_scale: 'Skala Rotasi',
    split_setting: 'Pengaturan pemisahan',
    split_setting_url: 'https://support.flux3dp.com/hc/en-us/articles/12276094010767',
    split_size: 'Ukuran pemisahan',
    type: 'Jenis',
  },
  select_device: {
    auth_failure: '#811 Autentikasi gagal',
    select_device: 'Pilih Perangkat',
    unable_to_connect: '#810 Tidak dapat membangun koneksi stabil dengan mesin',
  },
  settings: {
    anti_aliasing: 'Anti-Aliasing',
    auto_connect: 'Pilih otomatis satu-satunya mesin',
    auto_switch_tab: 'Sakelar Otomatis Panel Lapisan dan Objek',
    autofocus_offset: 'Offset Fokus Otomatis',
    autosave_enabled: 'Simpan Otomatis',
    autosave_interval: 'Simpan Setiap',
    autosave_number: 'Jumlah Simpan Otomatis',
    autosave_path: 'Lokasi Simpan Otomatis',
    autosave_path_not_correct: 'Lokasi yang ditentukan tidak ditemukan.',
    blade_precut_position: 'Posisi Pra-pemotongan',
    blade_precut_switch: 'Pra-pemotongan Bilah',
    blade_radius: 'Jari-jari Bilah',
    bottom_up: 'Bawah-atas',
    calculation_optimization: 'Percepatan Perhitungan Jalur',
    cancel: 'Batal',
    caption: 'Pengaturan',
    check_updates: 'Periksa Pembaruan Otomatis',
    close: 'Tutup',
    confirm_remove_default: 'Mesin standar akan dihapus.',
    confirm_reset: 'Konfirmasi atur ulang Beam Studio?',
    continuous_drawing: 'Gambar Kontinu',
    curve_engraving_speed_limit: 'Batas kecepatan ukiran kurva',
    custom_preview_height: 'Tinggi Pratinjau Kustom',
    default_beambox_model: 'Pengaturan Dokumen Default',
    default_borderless_mode: 'Buka Bawah Default',
    default_enable_autofocus_module: 'Autofokus Default',
    default_enable_diode_module: 'Laser Diode Default',
    default_font_family: 'Font Default',
    default_font_style: 'Gaya Font Default',
    default_laser_module: 'Modul Laser Default',
    default_machine: 'Mesin Standar',
    default_machine_button: 'Kosong',
    default_units: 'Satuan Standar',
    diode_offset: 'Offset Laser Diode',
    diode_one_way_engraving: 'Grafir Laser Diode Satu Arah',
    diode_two_way_warning: 'Pancaran cahaya dua arah lebih cepat dan mungkin menghasilkan ketidaktepatan posisi ukiran laser. Disarankan untuk menguji terlebih dahulu.',
    disabled: 'Nonaktif',
    done: 'Terapkan',
    enable_custom_backlash: 'Aktifkan Kompensasi Backlash Kustom',
    enable_low_speed: 'Aktifkan gerakan kecepatan lambat',
    enabled: 'Diaktifkan',
    engraving_direction: 'Arah',
    fast_gradient: 'Optimasi Kecepatan',
    font_convert: 'Konverter Teks ke Jalur',
    font_substitute: 'Ganti Karakter yang Tidak Didukung',
    grouped_objects: 'Objek Terkelompok',
    groups: {
      ador_modules: 'Modul Ador',
      autosave: 'Simpan Otomatis',
      camera: 'Kamera',
      connection: 'Koneksi',
      editor: 'Editor',
      engraving: 'Raster (Pemindaian)',
      general: 'Umum',
      mask: 'Pemotongan Area Kerja',
      modules: 'Add-on',
      path: 'Vektor (Garis Luar)',
      privacy: 'Privasi',
      text_to_path: 'Teks',
      update: 'Pembaruan Perangkat Lunak',
    },
    guess_poke: 'Cari alamat IP mesin',
    guides: 'Panduan',
    guides_origin: 'Asal Panduan',
    help_center_urls: {
      anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
      calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
      connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
      continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
      default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
      default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
      default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
      fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
      font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
      font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
      image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
      loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
      mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
      reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
      segmented_engraving: 'https://support.flux3dp.com/hc/en-us/articles/12306366019215',
      simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
      vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
    },
    high: 'Tinggi',
    image_downsampling: 'Kualitas Pratinjau Bitmap',
    ip: 'Alamat IP Mesin',
    keep_preview_result: 'Pertahankan Hasil Pratinjau',
    language: 'Bahasa',
    loop_compensation: 'Kompensasi Loop',
    low: 'Rendah',
    low_laser_for_preview: 'Laser untuk Jalankan Bingkai',
    mask: 'Pemotongan Area Kerja',
    medium: 'Sedang',
    module_offset_2w_ir: 'Offset Laser Inframerah 2W',
    module_offset_10w: 'Offset Laser Diode 10W',
    module_offset_20w: 'Offset Laser Diode 20W',
    module_offset_printer: 'Offset Printer',
    none: 'Tidak Ada',
    normal: 'Tinggi',
    notification_off: 'Mati',
    notification_on: 'Menyala',
    notifications: 'Notifikasi Desktop',
    off: 'Mati',
    on: 'Hidup',
    preview_movement_speed: 'Kecepatan Pratinjau Gerakan',
    printer_advanced_mode: 'Mode Printer Lanjutan',
    remove_default_machine_button: 'Hapus',
    reset: 'Atur ulang Beam Studio',
    reset_now: 'Atur ulang Beam Studio',
    segmented_engraving: 'Pengukiran tersegmentasi',
    share_with_flux: 'Bagikan Analitik Beam Studio',
    simplify_clipper_path: 'Optimalkan Jalur yang Dihitung',
    single_object: 'Objek Tunggal',
    tabs: {
      device: 'Mesin',
      general: 'Umum',
    },
    text_path_calc_optimization: 'Optimasi Perhitungan Jalur',
    top_down: 'Atas-bawah',
    trace_output: 'Keluaran Lacak Gambar',
    update_beta: 'Beta',
    update_latest: 'Terbaru',
    vector_speed_constraint: 'Batas Kecepatan',
    wrong_ip_format: 'Format IP salah',
  },
  social_media: {
    facebook: 'Berkolaborasi dengan FLUXers, ajukan pertanyaan, dan pelajari tips!',
    instagram: 'Dapatkan inspirasi terbaru, penawaran, dan hadiah gratis!',
    youtube: 'Lihat tutorial Beam Studio dan ide kerajinan.',
  },
  support: {
    no_vcredist: 'Mohon instal Visual C++ Redistributable 2015<br/>Dapat diunduh di flux3dp.com',
    no_webgl: 'WebGL tidak didukung. Harap gunakan perangkat lain.',
  },
  topbar: {
    alerts: {
      add_content_first: 'Silakan tambahkan objek terlebih dahulu',
      door_opened: 'Harap tutup pintu penutup untuk mengaktifkan laser rendah agar bingkai dapat berjalan.',
      fail_to_connect_with_camera: '#803 Gagal membuat koneksi dengan kamera mesin. Harap mulai ulang mesin Anda atau Beam Studio. Jika kesalahan ini berlanjut, ikuti <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">panduan ini</a>.',
      fail_to_get_door_status: 'Harap pastikan pintu penutup tertutup untuk mengaktifkan laser rendah agar bingkai dapat berjalan.',
      fail_to_start_preview: '#803 Gagal memulai mode pratinjau. Harap mulai ulang mesin Anda atau Beam Studio. Jika kesalahan ini berlanjut, ikuti <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">panduan ini</a>.',
      headtype_mismatch: 'Modul yang tidak cocok terdeteksi. ',
      headtype_none: 'Modul tidak terdeteksi. ',
      headtype_unknown: 'Modul tidak dikenali terdeteksi. ',
      install_correct_headtype: 'Harap pasang modul laser diode 10W/20W dengan benar untuk mengaktifkan laser rendah agar bingkai dapat berjalan.',
      job_origin_unavailable: 'Pengaturan asal pekerjaan memerlukan versi firmware 4.3.5 / 5.3.5 atau yang lebih baru. Apakah Anda ingin memperbarui firmware sekarang?',
      job_origin_warning: 'Anda saat ini menggunakan "posisi saat ini" sebagai titik awal. Pastikan kepala laser dipindahkan ke posisi yang benar untuk menghindari tabrakan.',
      power_too_high: 'DAYA TERLALU TINGGI',
      power_too_high_confirm: 'SAYA MENGERTI',
      power_too_high_msg: 'Menggunakan daya laser yang lebih rendah (di bawah 70%) akan memperpanjang masa pakai tabung laser.\nMasukkan "SAYA MENGERTI" untuk melanjutkan.',
      pwm_unavailable: 'Mode Kedalaman memerlukan versi firmware 4.3.4 / 5.3.4 atau lebih tinggi. Apakah Anda ingin memperbarui firmware sekarang?',
      QcleanScene: 'Apakah Anda ingin menghapus gambar?<br/>Ini juga akan menghapus riwayat undo Anda!',
      start_preview_timeout: '#803 Timeout telah terjadi saat memulai mode pratinjau. Harap mulai ulang mesin Anda atau Beam Studio. Jika kesalahan ini berlanjut, ikuti <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">panduan ini</a>.',
    },
    frame_task: 'Jalankan Bingkai',
    hint: {
      polygon: 'Tekan tombol + / - untuk menambah / mengurangi sisi.',
    },
    menu: {
      about: 'Tentang Beam Studio',
      about_beam_studio: 'Tentang Beam Studio',
      account: 'Akun',
      add_new_machine: 'Setup Mesin',
      align_center: 'Rata Tengah',
      anti_aliasing: 'Anti-Aliasing',
      auto_align: 'Penjajaran Otomatis',
      borderless_mode: 'Mode Tanpa Batas',
      bug_report: 'Laporan Bug',
      calibrate_beambox_camera: 'Kalibrasi Kamera',
      calibrate_beambox_camera_borderless: 'Kalibrasi Kamera (Tanpa Bingkai Bawah)',
      calibrate_camera_advanced: 'Kalibrasi Kamera (Lanjutan)',
      calibrate_diode_module: 'Kalibrasi Modul Laser Diode',
      calibrate_ir_module: 'Kalibrasi Modul Inframerah',
      calibrate_printer_module: 'Kalibrasi Modul Pencetakan',
      calibration: 'Kalibrasi',
      camera_calibration_data: 'Data Kalibrasi Kamera',
      change_logs: 'Log Perubahan',
      clear_scene: 'File baru',
      close: 'Tutup Jendela',
      commands: 'Perintah',
      contact: 'Hubungi Kami',
      copy: 'Salin',
      cut: 'Potong',
      dashboard: 'Dasbor',
      decompose_path: 'Dekomposisi',
      delete: 'Hapus',
      design_market: 'Pasar Desain',
      dev_tool: 'Alat Debug',
      disassemble_use: 'Pisahkan',
      document_setting: 'Pengaturan Dokumen',
      document_setting_short: 'Dokumen',
      download_data: 'Unduh Data',
      download_log: 'Unduh Log',
      download_log_canceled: 'Unduhan log dibatalkan',
      download_log_error: 'Terjadi kesalahan yang tidak diketahui, silakan coba lagi nanti',
      duplicate: 'Duplikat',
      edit: 'Sunting',
      example_files: 'Contoh Berkas',
      export_BVG: 'BVG',
      export_flux_task: 'Tugas FLUX',
      export_JPG: 'JPG',
      export_PNG: 'PNG',
      export_SVG: 'SVG',
      export_to: 'Ekspor Ke...',
      file: 'Berkas',
      fit_to_window: 'Sesuaikan Ukuran Jendela',
      follow_us: 'Ikuti kami',
      forum: 'Forum Komunitas',
      group: 'Kelompokkan',
      help: 'Bantuan',
      help_center: 'Pusat Bantuan',
      hide: 'Sembunyikan Beam Studio',
      hideothers: 'Sembunyikan Lainnya',
      image_crop: 'Potong',
      image_curve: 'Kurva',
      image_invert: 'Balik',
      image_sharpen: 'Tajamkan',
      image_stamp: 'Cap',
      image_vectorize: 'Vektorisasi',
      import_acrylic_focus_probe: 'Probe Fokus Akrilik - 3mm',
      import_ador_laser_example: 'Contoh Laser Ador',
      import_ador_printing_example_full: 'Contoh Cetak Ador - Warna Penuh',
      import_ador_printing_example_single: 'Contoh Cetak Ador - Satu Warna',
      import_beambox_2_example: 'Contoh Beambox II',
      import_beambox_2_focus_probe: 'Sonde Fokus Beambox II - 3 mm',
      import_hello_beambox: 'Contoh Beambox',
      import_hello_beamo: 'Contoh beamo',
      import_hexa_example: 'Contoh HEXA',
      import_material_printing_test: 'Uji Cetak Bahan',
      import_material_testing_cut: 'Uji Pemotongan Bahan',
      import_material_testing_engrave: 'Uji Ukiran Bahan',
      import_material_testing_line: 'Uji Garis Bahan',
      import_material_testing_old: 'Uji Pahatan Bahan - Klasik',
      import_material_testing_simple_cut: 'Uji Pemotongan Bahan - Sederhana',
      import_promark_example: 'Contoh Promark',
      import_promark_mopa_20w_color: 'Tes Warna MOPA 20W',
      import_promark_mopa_60w_color: 'Tes Warna MOPA 60W',
      import_promark_mopa_100w_color: 'Tes Warna MOPA 100W',
      keyboard_shortcuts: 'Pintasan keyboard',
      layer_color_config: 'Pengaturan Warna',
      layer_setting: 'Lapisan',
      link: {
        beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
        contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
        design_market: 'https://dmkt.io',
        downloads: 'https://flux3dp.com/downloads/',
        forum: 'https://www.facebook.com/groups/flux.laser/',
        help_center: 'https://helpcenter.flux3dp.com/',
        shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
      },
      log: {
        camera: 'Kamera',
        cloud: 'Cloud',
        discover: 'Temukan',
        hardware: 'Perangkat Keras',
        network: 'Jaringan',
        player: 'Pemutar',
        robot: 'Robot',
        usb: 'USB',
        usblist: 'Daftar USB',
      },
      machine_info: 'Info Mesin',
      machines: 'Mesin',
      manage_account: 'Kelola Akun Saya',
      material_test: 'Tes Material',
      minimize: 'Minimalkan',
      my_account: 'Akun Saya',
      network_testing: 'Tes Pengaturan Jaringan',
      object: 'Objek',
      offset: 'Ofset',
      open: 'Buka',
      paste: 'Tempel',
      paste_in_place: 'Tempel di Tempat',
      path: 'Jalur',
      photo_edit: 'Gambar',
      preferences: 'Preferensi',
      promark_color_test: 'Tes Warna Promark',
      questionnaire: 'Kuesioner Umpan Balik',
      quit: 'Keluar',
      recent: 'Buka Terbaru',
      redo: 'Ulangi',
      reload_app: 'Muat Ulang Aplikasi',
      reset: 'Atur Ulang',
      rotary_setup: 'Pengaturan Rotary',
      rotate: 'Putar',
      samples: 'Contoh',
      save_as: 'Simpan Sebagai...',
      save_scene: 'Simpan',
      save_to_cloud: 'Simpan ke Awan',
      scale: 'Skala',
      service: 'Layanan',
      set_as_default: 'Atur sebagai Default',
      show_gesture_tutorial: 'Pengenalan Gerakan Tangan',
      show_grids: 'Tampilkan Grid',
      show_layer_color: 'Gunakan Warna Lapisan',
      show_rulers: 'Tampilkan Penggaris',
      show_start_tutorial: 'Tampilkan Tutorial Mulai',
      show_ui_intro: 'Tampilkan Pengenalan Antarmuka',
      sign_in: 'Masuk',
      sign_out: 'Keluar',
      software_update: 'Pembaruan Perangkat Lunak',
      svg_edit: 'SVG',
      switch_to_beta: 'Beralih ke Rilis Beta',
      switch_to_latest: 'Beralih ke Rilis Stabil',
      tools: {
        box_generator: 'Generator Kotak',
        code_generator: 'Pembuat Kode',
        material_test_generator: 'Generator Uji Material',
        title: 'Alat',
      },
      tutorial: 'Mulai Tutorial Pencetakan Delta Family',
      undo: 'Batalkan',
      ungroup: 'Lepas Kelompok',
      update: 'Periksa Pembaruan',
      update_firmware: 'Perbarui Firmware',
      upload_data: 'Unggah Data',
      using_beam_studio_api: 'Menggunakan Beam Studio API',
      view: 'Tampilan',
      window: 'Jendela',
      zoom_in: 'Perbesar',
      zoom_out: 'Perkecil',
      zoom_with_window: 'Otomatis Sesuaikan Ukuran Jendela',
    },
    preview: 'PRATINJAU',
    preview_press_esc_to_stop: 'Tekan ESC untuk menghentikan pratinjau kamera.',
    rename_tab: 'Ganti nama tab',
    select_machine: 'Pilih mesin',
    tag_names: {
      dxf: 'Objek DXF',
      ellipse: 'Oval',
      g: 'Grup',
      image: 'Gambar',
      line: 'Garis',
      multi_select: 'Beberapa Objek',
      no_selection: 'Tidak Ada Pilihan',
      pass_through_object: 'Objek Melewati',
      path: 'Jalur',
      polygon: 'Poligon',
      rect: 'Persegi Panjang',
      svg: 'Objek SVG',
      text: 'Teks',
      text_path: 'Teks pada Jalur',
      use: 'Objek Impor',
    },
    task_preview: 'Pratinjau Jalur',
    titles: {
      settings: 'Pengaturan',
    },
    untitled: 'Tanpa Judul',
  },
  topmenu: {
    credit: 'Beam Studio dimungkinkan oleh proyek sumber terbuka <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> dan perangkat lunak sumber terbuka lainnya <a target="_blank" href="https://flux3dp.com/credits/">sumber terbuka</a>.',
    device: {
      download_log_canceled: 'Log unduhan dibatalkan',
      download_log_error: 'Terjadi kesalahan yang tidak diketahui, silakan coba lagi nanti',
      log: {
        usblist: 'Daftar USB',
      },
      network_test: 'Tes Jaringan',
    },
    file: {
      all_files: 'Semua File',
      clear_recent: 'Hapus Baru Dibuka',
      converting: 'Mengkonversi ke Gambar...',
      fcode_files: 'Kode FLUX',
      import: 'Impor',
      jpg_files: 'JPG',
      label: 'File',
      path_not_exit: 'Jalur ini sepertinya tidak ada lagi di disk.',
      png_files: 'PNG',
      save_fcode: 'Ekspor Tugas FLUX',
      save_jpg: 'Ekspor JPG',
      save_png: 'Ekspor PNG',
      save_scene: 'Simpan Adegan',
      save_svg: 'Ekspor SVG',
      scene_files: 'Adegan Beam Studio',
      svg_files: 'SVG',
    },
    ok: 'OK',
    version: 'Versi',
  },
  tutorial: {
    ask_retry_calibration: 'Apakah Anda ingin mengkalibrasi kamera lagi?',
    camera_calibration_failed: 'Kalibrasi Kamera Gagal',
    gesture: {
      click: 'Ketuk untuk memilih objek.',
      drag: 'Seret untuk memilih beberapa objek.',
      hold: 'Tekan dan tahan untuk membuka menu konteks.',
      pan: 'Geser kanvas dengan dua jari.',
      zoom: 'Cubit dengan dua jari untuk memperbesar/memperkecil kanvas.',
    },
    links: {
      adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
    },
    look_for_machine: 'Mencari mesin untuk tutorial...',
    needNewInterfaceTutorial: 'Apakah Anda ingin memulai tutorial untuk antarmuka baru Beam Studio?<br/>(Anda dapat melewatkan ini sekarang dan memulainya nanti dengan mengklik "Bantuan" > "Tampilkan Pengantar Antarmuka".)',
    needNewUserTutorial: 'Apakah Anda ingin memulai tutorial?<br/>(Anda dapat melewatkan ini sekarang dan memulainya nanti dengan mengklik "Bantuan" > "Tampilkan Tutorial Awal".)',
    newInterface: {
      add_new_layer: 'Tambahkan Lapisan Baru',
      align_controls: 'Kontrol Perataan',
      basic_shapes: 'Bentuk Dasar',
      camera_preview: 'Pratinjau Kamera',
      drag_to_sort: 'Seret untuk mengurutkan',
      end_alert: 'Apakah Anda yakin untuk mengakhiri pengenalan UI baru?',
      flip: 'Balik',
      group_controls: 'Kontrol Pengelompokan',
      layer_controls: 'Klik kanan untuk memilih Kontrol Lapisan:\nDuplikat / Gabung / Kunci / Hapus Lapisan',
      object_actions: 'Tindakan Objek',
      pen_tool: 'Alat Pena',
      rename_by_double_click: 'Ganti nama dengan klik dua kali',
      select_image_text: 'Pilih / Gambar / Teks',
      select_machine: 'Pilih mesin',
      shape_operation: 'Operasi Bentuk',
      start_work: 'Mulai Bekerja',
      switch_between_layer_panel_and_object_panel: 'Beralih antara Panel Lapisan dan Panel Objek',
    },
    newUser: {
      add_new_layer: 'Tambahkan Lapisan Baru',
      adjust_focus: '2. Sesuaikan fokus',
      close_cover: '3. Tutup penutup',
      drag_to_draw: 'Seret untuk Menggambar',
      draw_a_circle: 'Gambar Lingkaran',
      draw_a_rect: 'Gambar Persegi Panjang',
      end_alert: 'Apakah Anda yakin untuk mengakhiri tutorial?',
      end_preview_mode: 'Akhiri Mode Pratinjau',
      infill: 'Nyalakan Isi',
      please_select_wood_cutting: 'Silakan pilih preset "Kayu - Potong"',
      please_select_wood_engraving: 'Mohon pilih Preset "Kayu - Pengukiran".',
      preview_the_platform: 'Pratinjau Platform',
      put_wood: '1. Letakkan contoh kayu',
      send_the_file: 'Kirim Berkas',
      set_preset_wood_cut: 'Atur Preset: Kayu - Potong',
      set_preset_wood_engraving: 'Atur Preset: Kayu - Pengukiran',
      switch_to_layer_panel: 'Beralih ke Panel Lapisan',
      switch_to_object_panel: 'Beralih ke Panel Objek',
      switch_to_preview_mode: 'Beralih ke Mode Pratinjau',
    },
    next: 'BERIKUTNYA',
    retry: 'Coba lagi',
    set_connection: 'Atur Koneksi',
    skip: 'Lewati',
    skip_tutorial: 'Anda telah melewati tutorial. Anda selalu dapat memulai tutorial dengan mengklik "Bantuan" > "Tampilkan Tutorial Awal"',
    suggest_calibrate_camera_first: 'Kami menyarankan pengguna untuk mengkalibrasi kamera pada awalnya dan mengatur ulang fokus sebelum setiap pratinjau untuk hasil yang optimal.<br/>Apakah Anda ingin mengonfirmasi untuk melakukan kalibrasi sekarang?<br/>(Anda dapat melewatkan ini sekarang dan melakukannya nanti dengan mengklik "Menu" > "Mesin" > [Mesin Anda] > "Kalibrasi Kamera".)',
    tutorial_complete: 'Itu semua untuk tutorialnya. Sekarang saatnya untuk membuat!',
    unable_to_find_machine: 'Tidak dapat menemukan mesin untuk Tutorial. Apakah Anda ingin pergi ke halaman pengaturan koneksi, mencoba lagi atau melewati tutorial?',
    welcome: 'SELAMAT DATANG',
  },
  update: {
    cannot_reach_internet: '#823 Server tidak dapat dijangkau<br/>Harap periksa koneksi internet',
    download: 'PEMBARUAN ONLINE',
    firmware: {
      caption: 'Pembaruan Firmware untuk mesin tersedia',
      confirm: 'UNGGAH',
      firmware_too_old_update_by_sdcard: 'Versi firmware terlalu lama. Harap perbarui firmware menggunakan kartu SD.',
      force_update_message: '#814 Harap perbarui mesin Anda ke versi firmware terbaru.',
      latest_firmware: {
        cant_get_latest: 'Tidak dapat mendapatkan informasi firmware terbaru.',
        caption: 'Pembaruan Firmware Mesin',
        message: 'Anda memiliki Firmware Mesin terbaru',
        still_update: 'PERBARUI',
      },
      message_pattern_1: '"%s" siap untuk pembaruan firmware.',
      message_pattern_2: '%s Firmware v%s sekarang tersedia - Anda memiliki v%s.',
      too_old_for_web: 'Versi firmware mesin Anda saat ini adalah v%s.\nJika Anda ingin menggunakan versi online dari Beam Studio, harap perbarui firmware mesin ke versi terbaru.',
      update_fail: '#822 Pembaruan Gagal',
      update_success: 'Pembaruan firmware berhasil diunggah',
      upload_file: 'Firmware upload (*.bin / *.fxfw)',
    },
    install: 'PASANG',
    later: 'NANTI',
    preparing: 'Mempersiapkan...',
    release_note: 'Catatan Rilis:',
    skip: 'Lewati Versi Ini',
    software: {
      available_switch: 'Beam Studio v%s tersedia sekarang. Anda memiliki v%s. Apakah Anda ingin beralih ke versi ini?',
      available_update: 'Beam Studio v%s tersedia sekarang. Anda memiliki v%s. Apakah Anda ingin mengunduh pembaruan?',
      caption: 'Pembaruan Perangkat Lunak Beam Studio tersedia',
      check_update: 'Periksa Pembaruan',
      checking: 'Memeriksa Pembaruan',
      downloading: 'Mengunduh pembaruan di latar belakang, Anda dapat mengeklik "OK" untuk melanjutkan pekerjaan Anda.',
      install_or_not: '%s siap untuk diperbarui. Apakah Anda ingin memulai ulang dan memperbarui sekarang?',
      no: 'Tidak',
      no_response: 'Gagal terhubung ke server, harap periksa pengaturan jaringan Anda.',
      not_found: 'Anda menggunakan versi Beam Studio terbaru.',
      switch_or_not: '%s siap untuk beralih. Apakah Anda ingin memulai ulang dan beralih sekarang?',
      switch_version: 'Ganti Versi',
      switch_version_not_found: 'Versi yang dapat diubah tidak ditemukan.',
      update_for_ador: 'Versi perangkat lunak saat ini %s tidak kompatibel, harap unduh versi terbaru dari Beam Studio untuk Ador.',
      yes: 'Ya',
    },
    update: 'Perbarui',
    updating: 'Memperbarui...',
    upload: 'UNGGAH',
  },
  web_cam: {
    no_device: 'Tidak dapat mendeteksi perangkat kamera. Silakan sambungkan kembali kamera dan coba lagi.',
    no_permission: 'Beam Studio tidak memiliki izin untuk mengakses kamera. Pastikan izin diberikan ke Beam Studio di pengaturan browser atau pengaturan sistem.',
  },
  z_speed_limit_test: {
    alert_before: 'Sebelum memulai ukiran kurva, sistem akan melakukan uji beban untuk mencegah kehilangan langkah akibat berat yang berlebihan.',
    alert_failed: 'Berat objek saat ini melebihi batas beban untuk kecepatan sumbu Z saat ini. Kurangi kecepatan sumbu Z pada panel mesin atau ganti objek ukiran sebelum melakukan pengujian ulang.',
    ignore: 'Abaikan',
    retest: 'Uji ulang',
    testing: 'Melakukan uji beban...',
  },
};

export default lang;
