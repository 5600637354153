// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-widgets-FullWindowPanel-Sider-module__sider--wqhhD{flex:0 0 auto;width:500px;height:100%;display:flex;border-right:1px solid #ddd;flex-direction:column}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/widgets/FullWindowPanel/Sider.module.scss"],"names":[],"mappings":"AAAA,kFACE,aAAA,CACA,WAAA,CACA,WAAA,CACA,YAAA,CACA,2BAAA,CACA,qBAAA","sourcesContent":[".sider {\n  flex: 0 0 auto;\n  width: 500px;\n  height: 100%;\n  display: flex;\n  border-right: 1px solid #ddd;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sider": "_-_-packages-core-src-web-app-widgets-FullWindowPanel-Sider-module__sider--wqhhD"
};
export default ___CSS_LOADER_EXPORT___;
