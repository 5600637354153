// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-beambox-right-panel-AddLayerButton-module__btn--V2OQI{position:absolute;cursor:pointer;padding:0;bottom:4px;right:4px;font-size:24px;z-index:1;background:none;border:none}@media screen and (max-width: 600px){._-_-packages-core-src-web-app-components-beambox-right-panel-AddLayerButton-module__btn--V2OQI{top:8px;left:8px;z-index:999;width:30px;height:30px;font-size:28px}}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/beambox/right-panel/AddLayerButton.module.scss"],"names":[],"mappings":"AAAA,gGACE,iBAAA,CACA,cAAA,CACA,SAAA,CACA,UAAA,CACA,SAAA,CACA,cAAA,CACA,SAAA,CACA,eAAA,CACA,WAAA,CAGF,qCACE,gGACE,OAAA,CACA,QAAA,CACA,WAAA,CACA,UAAA,CACA,WAAA,CACA,cAAA,CAAA","sourcesContent":[".btn {\n  position: absolute;\n  cursor: pointer;\n  padding: 0;\n  bottom: 4px;\n  right: 4px;\n  font-size: 24px;\n  z-index: 1;\n  background: none;\n  border: none;\n}\n\n@media screen and (max-width: 600px) {\n  .btn {\n    top: 8px;\n    left: 8px;\n    z-index: 999;\n    width: 30px;\n    height: 30px;\n    font-size: 28px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "_-_-packages-core-src-web-app-components-beambox-right-panel-AddLayerButton-module__btn--V2OQI"
};
export default ___CSS_LOADER_EXPORT___;
