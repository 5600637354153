import type { ILang } from '@core/interfaces/ILang';

const lang: ILang = {
  alert: {
    abort: 'Zrušit',
    cancel: 'Storno',
    caption: 'Chyba',
    close: 'Zavřít',
    confirm: 'Potvrdit',
    dont_save: 'Neukládat',
    dont_show_again: 'Znovu již nezobrazovat',
    duplicated_preset_name: 'Duplikované jméno předvolby',
    error: 'JEJDA',
    info: 'INFO',
    instruction: 'Instrukce',
    learn_more: 'Zjistit více',
    no: 'Ne',
    ok: 'OK',
    ok2: 'OK',
    oops: 'Jejky...',
    retry: 'Opakovat',
    save: 'Uložit',
    stop: 'Zastavit',
    warning: 'VAROVÁNÍ',
    yes: 'Ano',
  },
  auto_fit: {
    artwork_size: 'Velikost kresby',
    error_tip1: '1. Je kresba umístěna na materiálu správně?',
    error_tip2: '2. Jsou obrysy materiálu dostatečně jasné pro rozpoznání?',
    failed_to_find_contour: 'Nepodařilo se najít žádné obrysy pro automatické přizpůsobení',
    learn_more: 'Zobrazit návod.',
    learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    offset_x: 'Posun X',
    offset_y: 'Posun Y',
    pattern_size: 'Velikost vzoru',
    position_artwork: 'Umístěte dílo',
    position_step1: 'Upravte velikost a polohu svého díla na vzoru.',
    position_step2: 'Klikněte na „Použít“ pro použití automatického přizpůsobení na tento vzor.',
    preview_first: 'Nejprve proveďte náhled fotoaparátu.',
    reset_position: 'Obnovit pozici',
    rotation: 'Otočit',
    select_a_pattern: 'Vyberte vzor',
    selected_artwork: 'Vybrané dílo',
    step1: 'Vyberte vzor, na který chcete umístit dílo.',
    step2: 'Pokud nebyl nalezen žádný vzor, znovu náhled kamery a použijte funkci Automatické přizpůsobení.',
    step3: 'Pokud se stále nedaří najít správný vzor, ujistěte se, že váš předmět je jasně rozpoznatelný a pracovní plocha je čistá od nečistot.',
    title: 'Automatické přizpůsobení',
  },
  barcode_generator: {
    bar_height: 'Výška čáry',
    bar_width: 'Šířka čáry',
    barcode: {
      invalid_value: 'Hodnota je neplatná pro vybraný formát.',
    },
    font: 'Písmo',
    font_size: 'Velikost písma',
    hide_text: 'Skrýt text',
    invert_color: 'Invertovat barvu',
    text_margin: 'Okraj textu',
  },
  beambox: {
    announcement_panel: {
      title: 'Ohlášení',
    },
    banner: {
      auto_feeder: 'Režim Automatického Podavače',
      camera_preview: 'Náhled',
      camera_preview_borderless_mode: '(Otevřené Dno)',
      curve_engraving: 'Režim Gravírování na Křivkách',
      pass_through: 'Režim Průchodu',
      rotary: 'Režim Otočný',
    },
    bottom_right_panel: {
      convert_text_to_path_before_export: 'Převést text na cestu...',
      export_file_error_ask_for_upload: 'Nepodařilo se exportovat úlohu. Jste ochotni při hlášení chyby poskytnout vývojářům pracovní oblast?',
      retreive_image_data: 'Získat data z obrázku...',
    },
    context_menu: {
      copy: 'Kopírovat',
      cut: 'Vyjmout',
      delete: 'Smazat',
      duplicate: 'Duplikovat',
      group: 'Sloučit do skupiny',
      move_back: 'Přenést dál',
      move_down: 'Přenést do pozadí',
      move_front: 'Přenést do popředí',
      move_up: 'Přenést blíž',
      paste: 'Vložit',
      paste_in_place: 'Vložit na místo',
      ungroup: 'Rozdělit skupinu',
    },
    document_panel: {
      add_on: 'Doplňky',
      auto_feeder: 'Automatický podavač',
      auto_feeder_scale: 'Měřítko automatického podavače',
      auto_feeder_url: 'https://support.flux3dp.com/hc/en-us/articles/11688230498575',
      borderless_mode: 'Otevřít spodní část',
      current_position: 'Aktuální pozice',
      disable: 'Zakázat',
      document_settings: 'Nastavení dokumentu',
      enable: 'Povolit',
      enable_autofocus: 'Autofokus',
      enable_diode: 'Diodový laser',
      engrave_dpi: 'Rozlišení',
      frame_before_start: 'Nejprve rámec, pak provést',
      frame_before_start_url: 'https://support.flux3dp.com/hc/en-us/articles/11494925637135',
      high: 'Vysoké',
      job_origin: 'Nastavování vodítek',
      laser_source: 'Laserový zdroj',
      low: 'Nízké',
      machine: 'Stroj',
      medium: 'Střední',
      notification: {
        changeFromPrintingWorkareaTitle: 'Chcete převést Tiskové vrstvy na Laserové vrstvy?',
      },
      origin: 'Vodítka',
      pass_through: 'Průchod',
      pass_through_height_desc: 'Zadat délku objektu k rozšíření pracovní oblasti.',
      rotary_mode: 'Rotační',
      scale: 'Měřítko',
      start_from: 'Začít od',
      start_position: 'Počáteční pozice',
      start_work_button: 'Tlačítko Spustit',
      ultra: 'Ultravysoké',
      workarea: 'Pracovní oblast',
    },
    image_trace_panel: {
      back: 'Zpět',
      brightness: 'Jas',
      cancel: 'Storno',
      contrast: 'Kontrast',
      next: 'Další',
      okay: 'OK',
      threshold: 'Hranice',
      tuning: 'Parametry',
    },
    layer_color_config_panel: {
      add: 'Přidat',
      add_config: 'Přidat barvu',
      color: 'Barva',
      default: 'Obnovení výchozího nastavení',
      in_use: 'Tato barva se již využívá.',
      layer_color_config: 'Nastavení barev vrstev',
      no_input: 'Zadejte prosím platný hex kód barvy',
      power: 'Intenzita',
      repeat: 'Vykonate',
      speed: 'Rychlost',
      sure_to_delete: 'Určitě chcete smazat toto nastavení barev?',
      sure_to_reset: 'Přijdete o všechny přizpůsobené parametry. Opravdu chcete restartovat do původního nastavení?',
    },
    left_panel: {
      borderless_blind_area: 'Ne-gravírovací oblast',
      borderless_preview: 'Náhled kamery s otevřenou spodní částí',
      diode_blind_area: 'Doplněk hybridního laseru - slepá oblast',
      ellipse: 'Elipsa',
      image: 'Obrázek',
      label: {
        adjust_height: 'Přizpůsobit výšku',
        array: 'Pole',
        boxgen: 'Boxgen',
        choose_camera: 'Kamera',
        clear_preview: 'Vyčistit náhled',
        cursor: 'Vybrat',
        curve_engraving: {
          clear_area: 'Vyčistit vybranou oblast',
          exit: 'Konec',
          preview_3d_curve: 'Náhled gravírování křivky',
          select_area: 'Vybrat oblast',
          title: 'Gravírování křivky',
        },
        end_preview: 'Konec náhledu',
        line: 'Čára',
        live_feed: 'Živé vysílání',
        my_cloud: 'Můj cloud',
        oval: 'Ovál',
        pass_through: 'Průchod',
        pen: 'Propiska',
        photo: 'Obrázek',
        polygon: 'Polygon',
        preview: 'Náhled kamery',
        qr_code: 'QR kód',
        rect: 'Obdélník',
        shapes: 'Prvky',
        text: 'Text',
        trace: 'Obkreslit obrázek',
      },
      line: 'Čára',
      rectangle: 'Obdélník',
      text: 'Text',
      unpreviewable_area: 'Slepá oblast',
    },
    network_testing_panel: {
      average_response: 'Průměrná reakční doba',
      cannot_connect_1: '#840 Nepodařilo se připojit k cílové IP.',
      cannot_connect_2: '#840 Nepodařilo se připojit k cílové IP. Ujistěte se prosím, že je cíl ve stejné síti.',
      cannot_get_local: 'Nepodařilo se připojit k místní IP adrese.',
      connection_quality: 'Kvalita připojení',
      device_not_on_list: '#842 Přístroj není na seznamu, ale kvalita připojení je >70 a průměrná reakční doba je <100 ms.',
      empty_ip: '#818 Zadejte prosím nejdříve IP adresu cílového zařízení.',
      end: 'Ukončit',
      fail_to_start_network_test: '#817 Nepodařilo se zahájit testování sítě.',
      hint_connect_camera_timeout: 'Při zahajování náhledu kamery vyprší časový limit?',
      hint_connect_failed_when_sending_job: 'Nedaří se vám při odeslání úlohy připojit?',
      hint_device_often_on_list: 'Přístroj se často nenalézá na seznamu?',
      insert_ip: 'IP adresa cílového zařízení:',
      invalid_ip: '#818 Neplatná IP adresa',
      ip_startswith_169: '#843 IP adresa přístroje začíná 169.254',
      link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
      link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
      link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
      linux_permission_hint: 'Tato chyba se obvykle objevuje při nedostatku uživatelských práv.\nSpusťte prosím na terminálu "sudo beam-studio --no-sandbox", abyste získali práva a zahájili testování sítě.',
      local_ip: 'Místní IP adresa:',
      network_testing: 'Testování sítě',
      network_unhealthy: '#841 Kvalita připojení <70 nebo průměrná reakční doba >100 ms',
      start: 'Začít',
      test_completed: 'Test dokončen',
      test_fail: 'Test selhal',
      testing: 'Testuji síť...',
    },
    object_panels: {
      lock_desc: 'Zachovat poměr šířky a výšky (SHIFT)',
      text_to_path: {
        check_thumbnail_warning: 'Některé texty byly změněny na jiné fonty při rozebírání textů na cesty a některé znaky se nemusely správně převést.\nPřed odesláním úlohy prosím znovu zkontrolujte náhledový obrázek.',
        error_when_parsing_text: 'Chyba při převádění textu na cestu.',
        font_substitute_pop: 'Váš text obsahuje znaky, které nepodporuje současný font. <br/>Přejete si použít jako náhradu <strong>%s</strong>?',
        retry: 'Znovu prosím opakujte nebo vyberte jiný font',
        use_current_font: 'Použít současný font',
      },
      wait_for_parsing_font: 'Analyzuji font... Vyčkejte chvíli, prosím.',
    },
    path_preview: {
      current_position: 'Současná pozice',
      cut_distance: 'Vzdálenost řezu',
      cut_time: 'Snížit délku',
      end_preview: 'Ukončit náhled',
      estimated_time: 'Odhadovaná celková délka',
      invert: 'Převrátit',
      pause: 'Pauza',
      play: 'Přehrát',
      play_speed: 'Rychlost přehrávání',
      preview_info: 'Informace o náhledu',
      rapid_distance: 'Vzdálenost cesty',
      rapid_time: 'Putovní délka',
      remark: '* * Všechny poskytované hodnoty jsou pouze odhadem.',
      size: 'Velikost',
      start_here: 'Začít zde',
      stop: 'Zastavit',
      travel_path: 'Putovní cesta',
    },
    photo_edit_panel: {
      aspect_ratio: 'Poměr stran',
      brightness: 'Jas',
      brightness_and_contrast: 'Jas / Kontrast',
      cancel: 'Storno',
      circumference: 'Obvod',
      compare: 'Porovnat',
      contrast: 'Kontrast',
      crop: 'Oříznout',
      curve: 'Křivka',
      diameter: 'Průměr',
      free: 'Volné',
      invert: 'Převrátit barvy',
      okay: 'OK',
      original: 'Originál',
      phote_edit: 'Úprava fotky',
      processing: 'Zpracovávám',
      radius: 'Poloměr',
      rotary_warped: 'Otočně Zkřivené',
      rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
      sharpen: 'Zostřit',
      sharpness: 'Zaostření',
      start: 'Začít',
      warp: 'Zkřivit',
    },
    popup: {
      ai_credit: {
        buy_link: 'https://member.flux3dp.com/en-US/credit',
        go: 'Spustit',
        insufficient_credit: 'Došel vám kredit.',
        insufficient_credit_msg: 'Nemůžete použít %s. Přihlaste se do klientské zóny a zakupte AI kredit.',
        relogin_to_use: 'K použití této funkce se prosím znovu přihlaste.',
      },
      auto_feeder_origin: 'Použijte náhled rámování k ověření dráhy a oblasti laserové hlavy, poté upravte umístění materiálu nebo počáteční pozici gravírování podle výsledků, abyste předešli kolizím během procesu gravírování.',
      auto_switch_tab: {
        message: 'Do Předvoleb byla přidána nová možnost pro automatické přepínání mezi panelem Vrstvy a panelem Objekty. Tato možnost je ve výchozím nastavení vypnutá. Chcete nyní povolit automatické přepínání?<br/>Toto nastavení můžete kdykoli změnit v Předvolbách.',
        title: 'Automatické přepínání panelu vrstev a objektů',
      },
      backend_connect_failed_ask_to_upload: '#802 Objevují se chyby při snaze připojit se k backendu. Přejete si nahrát záznamy z hlášení o chybě?',
      backend_error_hint: 'Funkce nemusí správně pracovat kvůli chybě v backendu.',
      both_power_and_speed_too_high: 'Využívání laseru nízké intenzity prodlouží životnost laserové trubice.\nA příliš vysoká rychlost může u tohoto rozlišení vyústit v nízkou kvalitu gravírovaného stínování.',
      bug_report: 'Nahlášení chyby',
      change_workarea_before_preview: 'Pracovní oblast %s nesedí k momentálně nastavené pracovní oblasti. Chcete přepnout na momentální pracovní oblast?',
      convert_to_path_fail: 'Nepodařilo se převést na cestu.',
      dxf_bounding_box_size_over: 'Velikost kresby je mimo pracovní oblast. Přesuňte prosím svou kresbu blíž k vodítku ve vašem CAD softwaru, nebo se ujistěte, že je jednotka správně nastavena.',
      dxf_version_waring: 'Verze tohoto DFX souboru není 2013, může dojít k problémům s kompatibilitou.',
      facebook_group_invitation: {
        already_joined: 'Už jsem členem',
        join_now: 'Jdu do toho',
        later: 'Možná později',
        message: 'Přidejte se k naší oficiální facebookové skupině, kde se propojíte s dalšími uživateli FLUX a kde můžete diskutovat o FLUX laserech, sdílet své laserové výtvory a být v obraze ohledně našich produktů. Těšíme se na vás!',
        title: 'Přidejte se k oficiální uživatelské skupině FLUX',
      },
      import_file_contain_invalid_path: '#808 Importovaný SVG soubor obsahuje nesprávnou cestu k obrázku. Ujistěte se prosím, že všechny soubory obrázků existují nebo do souboru vložte obrázek',
      import_file_error_ask_for_upload: 'Nepodařilo se importovat SVG soubor. Jste ochotní soubor poskytnout vývojářskému týmu pro nahlášení chyby?',
      layer_by_color: 'Barva',
      layer_by_layer: 'Vrstva',
      loading_image: 'Nahrávám obrázek, prosím vyčkejte...',
      more_than_two_object: 'Příliš mnoho objektů. Podpora pouze pro 2 objekty.',
      mouse: 'Myš',
      no_support_text: 'Beam Studio v současnosti nepodporuje textové štítky. Převeďte prosím před importem text na cestu.',
      nolayer: 'Jedna vrstva',
      not_support_object_type: 'Nepodporovaný typ objektu.',
      or_turn_off_borderless_mode: ' Nebo vypněte mód otevřené spodní části.',
      pdf2svg: {
        error_pdf2svg_not_found: '#825 Chyba: Příkaz pdf2svg nenalezen. Nainstalujte prosím pdf2svg se svým správcem balíčků (např.: "yum install pdf2svg" či "apt_get install pdf2svg").',
        error_when_converting_pdf: '#824 Chyba při převádění PDF na SVG:',
      },
      progress: {
        calculating: 'Vypočítávám',
        uploading: 'Nahrávám',
      },
      questionnaire: {
        caption: 'Pomozte nám vyplnit dotazník',
        message: 'Pomozte nám vyplnit dotazník, abychom mohli vylepšit Beam Studio',
        no_questionnaire_available: 'V současnosti není k dispozici žádný dotazník.',
        unable_to_get_url: 'Nepodařilo se získat odkaz k internetovému dotazníku. Potvrďte prosím své internetové připojení.',
      },
      recommend_downgrade_software: 'Rozpoznali jsme starší verzi firmwaru. Aktivně adresujeme problémy s kompatibilitou, ale prozatím doporučujeme vrátit se k <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>.',
      recommend_upgrade_firmware: 'Rozpoznali jsme starší verzi firmwaru. Aktivně adresujeme problémy s kompatibilitou, ale prozatím doporučujeme nainstalovat nejaktuálnější firmware.',
      save_unsave_changed: 'Přejete si uložit neuložené změny?',
      select_at_least_two: 'Pro pokračování vyberte dva objekty',
      select_first: 'Nejprve vyberte objekt.',
      select_import_method: 'Vyberte styl vrstvy:',
      select_import_module: 'Vyberte modul:',
      sentry: {
        message: 'Souhlasíte s tím, že pokud nastane chyba, budou se vývojářům automaticky nahrávat relevantní informace?',
        title: 'Pojďme společně vylepšit Beam Studio',
      },
      should_update_firmware_to_continue: '#814 Váš firmware nevyužívá tuto verzi Beam Studia. Pro pokračování prosím aktualizujte firmware. (Menu > Přístroj > [Váš přístroj] > Aktualizovat firmware)',
      speed_too_high_lower_the_quality: 'Využívání příliš vysoké rychlosti může u tohoto rozlišení vyústit v nízkou kvalitu gravírovaného stínování.',
      still_continue: 'Pokračovat',
      successfully_uploaded: 'Soubor byl úspěšně nahrán.',
      svg_1_1_waring: 'Verze tohoto SVG souboru je 1.1. Může dojít k problémům s kompatibilitou.',
      svg_image_path_waring: 'Tento SVG soubor obsahuje <image> načtený z cesty souboru. Můžou nastat chyby při načítání.\nAbyste se tomuto riziku vyhnuli, používejte prosím při exportování SVG vložený obrázek.',
      text_to_path: {
        caption: 'Konvertor textu na cestu 2.0',
        message: 'Beam Studio vám představuje nový konvertor textu na cestu (Converter 2.0), který přináší ještě spolehlivější výsledky!Přejete si na něho přepnout?\nSvoji volbu můžete později změnit ve vlastnostech v sekci Konvertor textu na cestu.',
      },
      too_fast_for_auto_feeder: 'Použití příliš vysoké rychlosti ve vrstvách obsahujících objekty cesty může vést ke snížení přesnosti při řezání.\nNedoporučujeme používat rychlost vyšší než %(limit)s při řezání s automatickým podavačem.',
      too_fast_for_auto_feeder_and_constrain: 'Následující vrstvy: %(layers)s \nobsahují vektorové cesty a jejich rychlost přesahuje %(limit)s.\nRychlost řezání vektorových cest s automatickým podavačem bude omezena na %(limit)s.\nTento limit můžete odstranit v nastavení předvoleb.',
      too_fast_for_curve: 'Použití příliš vysoké rychlosti pro vrstvy obsahující objekty křivkového gravírování může snížit přesnost gravírování.\nDoporučujeme udržovat rychlost pod %(limit)s pro optimální výsledky s objekty křivkového gravírování.',
      too_fast_for_curve_and_constrain: 'Následující vrstvy: %(layers)s překračují rychlostní limit %(limit)s.\nRychlost gravírování objektů křivky bude omezena na %(limit)s. Tento limit můžete odstranit v nastavení předvoleb.',
      too_fast_for_path: 'Využívání příliš vysoké rychlosti u vrstev obsahující objekty cest může vyústit v nízkou přesnost při řezání.\nNedoporučujeme při řezání využívat rychlost vyšší než %(limit)s.',
      too_fast_for_path_and_constrain: 'Následující vrstvy: %(layers)s \nobsahují vektorové objekty cest a jejich rychlost převyšuje %(limit)s.\nRychlost řezání vektorových objektů cest bude omezena na %(limit)s.\nTento limit můžete odstranit v Nastavení.',
      touchpad: 'TouchPad',
      ungroup_use: 'Toto odstraní ze skupiny importované DXF nebo SVG. Protože může soubor obsahovat velký počet prvků, může rozdělování skupiny trvat. Opravdu si přejete pokračovat?',
      upload_failed: '#819 Nepodařilo se nahrát soubor.',
      upload_file_too_large: '#819 Soubor je pro nahrání příliš velký.',
      vectorize_shading_image: 'Obrázky s přechodem trvají delší dobu obkreslit a může dojít k zvýšenému hluku. Vypněte prosím obrázky s přechodem před zahájením.',
    },
    rating_panel: {
      description: 'Pokud se vám Beam Studio líbí, velice bychom ocenili, kdybyste nás ohodnotili.',
      thank_you: 'Děkujeme!',
      title: 'Užíváte si práci s Beam Studio?',
    },
    right_panel: {
      laser_panel: {
        advanced: 'Pokročilé',
        backlash: 'Odpor',
        bi_directional: 'Obousměrný',
        by: 'Podle',
        ce_z_high_speed: 'Vysokorychlostní osa Z',
        ce_z_high_speed_link: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
        color_adjustment: 'Přizpůsobení kanálu',
        color_adjustment_short: 'Kanál',
        color_strength: 'Síla',
        cross_hatch: 'Křížové Stínování',
        custom_preset: 'Přizpůsobit',
        cut: 'Řezat',
        diode: 'Diodový laser',
        do_not_adjust_default_para: 'Předvolby nemůžou být upravovány.',
        dottingTime: 'Čas bodování',
        dropdown: {
          inches: {
            abs_engraving: 'ABS - gravírování',
            acrylic_3mm_cutting: 'Akryl - 1/8″ řez',
            acrylic_5mm_cutting: 'Akryl - 3/16″ řez',
            acrylic_8mm_cutting: 'Akryl - 1/4″ řez',
            acrylic_10mm_cutting: 'Akryl - 3/8″ řez',
            acrylic_engraving: 'Akryl - gravírování',
            acrylic_printing: 'Akryl - tisk',
            aluminum_engraving: 'Hliník - gravírování',
            aluminum_light: 'Hliník (světlý)',
            bamboo_printing: 'Bambus - tisk',
            black_abs: 'Černý ABS',
            black_acrylic_3mm_cutting: 'Černý akryl - 1/8″ řez',
            black_acrylic_5mm_cutting: 'Černý akryl - 3/16″ řez',
            black_acrylic_engraving: 'Černý akryl - gravírování',
            brass_dark: 'Mosaz (tmavá)',
            brass_engraving: 'Mosaz - gravírování',
            brass_light: 'Mosaz (světlá)',
            canvas_printing: 'Plátno - tisk',
            cardstock_printing: 'Karton - tisk',
            copper: 'Měď',
            cork_printing: 'Korek - tisk',
            denim_1mm_cutting: 'Džínovina - 1/32″ řez',
            fabric_3mm_cutting: 'Textilie - 1/8″ řez',
            fabric_5mm_cutting: 'Textilie - 3/16″ řez',
            fabric_engraving: 'Textilie - gravírování',
            fabric_printing: 'Textilie - tisk',
            flat_stone_printing: 'Plochý kámen - tisk',
            glass_bw_engraving: 'Sklo - gravírování',
            glass_printing: 'Sklo - tisk',
            gloss_leather_printing: 'Lesklá kůže - tisk',
            gold_engraving: 'Zlato - gravírování',
            iron_engraving: 'Železo - gravírování',
            leather_3mm_cutting: 'Kůže - 1/8″ řez',
            leather_5mm_cutting: 'Kůže - 3/16″ řez',
            leather_engraving: 'Kůže - gravírování',
            mdf_3mm_cutting: 'Novozélandský MDF - 1/8″ řez',
            mdf_5mm_cutting: 'Novozélandský MDF - 3/16″ řez',
            mdf_engraving: 'Novozélandský MDF - gravírování',
            metal_bw_engraving: 'Kov - gravírování',
            opaque_acrylic: 'Neprůhledný akryl',
            pc_printing: 'Polykarbonát - tisk',
            rubber_bw_engraving: 'Guma - gravírování',
            silver_engraving: 'Stříbro - gravírování',
            stainless_steel_bw_engraving_diode: 'Nerezová ocel - gravírování (diodový laser)',
            stainless_steel_dark: 'Nerezová ocel (tmavá)',
            stainless_steel_engraving: 'Nerezová ocel - gravírování',
            stainless_steel_light: 'Nerezová ocel (světlá)',
            stainless_steel_printing: 'Nerezová ocel - tisk',
            steel_engraving_spray_engraving: 'Ocel - gravírování',
            stone: 'Kámen',
            ti_engraving: 'Titan - gravírování',
            titanium_dark: 'Titan (tmavý)',
            titanium_light: 'Titan (světlý)',
            white_abs: 'Bílý ABS',
            wood_3mm_cutting: 'Dřevo - 1/8″ řez',
            wood_5mm_cutting: 'Dřevo - 3/16″ řez',
            wood_7mm_cutting: 'Dřevo - 1/4″ řez',
            wood_8mm_cutting: 'Dřevo - 1/4″ řez',
            wood_10mm_cutting: 'Dřevo - 3/8″ řez',
            wood_engraving: 'Dřevo - gravírování',
            wood_printing: 'Dřevo - tisk',
          },
          mm: {
            abs_engraving: 'ABS - gravírování',
            acrylic_3mm_cutting: 'Akryl - 3mm řez',
            acrylic_5mm_cutting: 'Akryl - 5mm řez',
            acrylic_8mm_cutting: 'Akryl - 8mm řez',
            acrylic_10mm_cutting: 'Akryl - 10mm řez',
            acrylic_engraving: 'Akryl - gravírování',
            acrylic_printing: 'Akryl - tisk',
            aluminum_engraving: 'Hliník - gravírování',
            aluminum_light: 'Hliník (světlý)',
            bamboo_printing: 'Bambus - tisk',
            black_abs: 'Černý ABS',
            black_acrylic_3mm_cutting: 'Černý akryl - 3mm řez',
            black_acrylic_5mm_cutting: 'Černý akryl - 5mm řez',
            black_acrylic_engraving: 'Černý akryl - gravírování',
            brass_dark: 'Mosaz (tmavá)',
            brass_engraving: 'Mosaz - gravírování',
            brass_light: 'Mosaz (světlá)',
            canvas_printing: 'Plátno - tisk',
            cardstock_printing: 'Karton - tisk',
            copper: 'Měď',
            cork_printing: 'Korek - tisk',
            denim_1mm_cutting: 'Džínovina - 1mm řez',
            fabric_3mm_cutting: 'Textilie - 3mm řez',
            fabric_5mm_cutting: 'Textilie - 5mm řez',
            fabric_engraving: 'Textilie - gravírování',
            fabric_printing: 'Textilie - tisk',
            flat_stone_printing: 'Plochý kámen - tisk',
            glass_bw_engraving: 'Sklo - gravírování',
            glass_printing: 'Sklo - tisk',
            gloss_leather_printing: 'Lesklá kůže - tisk',
            gold_engraving: 'Zlato - gravírování',
            iron_engraving: 'Železo - gravírování',
            leather_3mm_cutting: 'Kůže - 3mm řez',
            leather_5mm_cutting: 'Kůže - 5mm řez',
            leather_engraving: 'Kůže - gravírování',
            mdf_3mm_cutting: 'Novozélandská MDF - 3mm řez',
            mdf_5mm_cutting: 'Novozélandská MDF - 5mm řez',
            mdf_engraving: 'Novozélandská MDF - gravírování',
            metal_bw_engraving: 'Kov - gravírování',
            opaque_acrylic: 'Neprůhledný akryl',
            pc_printing: 'Polykarbonát - tisk',
            rubber_bw_engraving: 'Guma - gravírování',
            silver_engraving: 'Stříbro - gravírování',
            stainless_steel_bw_engraving_diode: 'Nerezová ocel - gravírování (diodový laser)',
            stainless_steel_dark: 'Nerezová ocel (tmavá)',
            stainless_steel_engraving: 'Nerezová ocel - gravírování',
            stainless_steel_light: 'Nerezová ocel (světlá)',
            stainless_steel_printing: 'Nerezová ocel - tisk',
            steel_engraving_spray_engraving: 'Ocel - gravírování',
            stone: 'Kámen',
            ti_engraving: 'Titan - gravírování',
            titanium_dark: 'Titan (tmavý)',
            titanium_light: 'Titan (světlý)',
            white_abs: 'Bílý ABS',
            wood_3mm_cutting: 'Dřevo - 3mm řez',
            wood_5mm_cutting: 'Dřevo - 5mm řez',
            wood_7mm_cutting: 'Dřevo - 7mm řez',
            wood_8mm_cutting: 'Dřevo - 8mm řez',
            wood_10mm_cutting: 'Dřevo - 10mm řez',
            wood_engraving: 'Dřevo - gravírování',
            wood_printing: 'Dřevo - tisk',
          },
          parameters: 'Předvolby',
          save: 'Přidat současné parametry',
        },
        engrave: 'Gravírovat',
        existing_name: 'Toto jméno je již používáno.',
        fill_angle: 'Úhel Vyplnění',
        fill_interval: 'Interval vyplnění',
        fill_setting: 'Nastavení Výplně',
        filled_path_only: 'Pouze pro vyplněné cesty',
        focus_adjustment: 'Přizpůsobení fokusu',
        frequency: 'Frekvence',
        gradient_only: 'Pouze pro gradientní obrázky',
        halftone: 'Půltón',
        halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
        height: 'Výška objektu',
        ink_saturation: 'Saturace',
        ink_type: {
          normal: 'Inkoust',
          text: 'Typ inkoustu',
          UV: 'UV inkoust',
        },
        laser_speed: {
          fast: 'Rychlá',
          max: 300,
          min: 3,
          slow: 'Pomalá',
          step: 0.1,
          text: 'Rychlost',
          unit: 'mm/s',
        },
        low_power_warning: 'Laser nízké intenzity (pod 10 %) nemusí vyslat laserové světlo.',
        low_speed_warning: 'Nízká rychlost může způsobit pálení materiálu.',
        lower_focus: 'Nižší zaostření',
        lower_focus_desc: 'Snížení výšky zaostření o určitou vzdálenost po zaostření pro zlepšení řezného výkonu.',
        module: 'Modul',
        more: 'Spravovat',
        multi_layer: 'Několik vrstev',
        para_in_use: 'Tento parametr je již používán.',
        parameters: 'Parametry',
        power: {
          high: 'Vysoká',
          low: 'Nízká',
          max: 100,
          min: 1,
          step: 0.1,
          text: 'Intenzita',
        },
        preset_management: {
          add_new: 'Přidat nový',
          delete: 'Smazat',
          export: 'Exportovat',
          export_preset_title: 'Exportovat předvolby',
          import: 'Importovat',
          laser: 'Laser',
          lower_focus_by: 'Snižte zaostření o',
          new_preset_name: 'Název nové převolby',
          preset: 'Předvolba',
          print: 'Vytisknout',
          reset: 'Restartovat',
          save_and_exit: 'Uložit a ukončit',
          show_all: 'Zobrazit vše',
          sure_to_import_presets: 'Přepíše vaše osobní nastavení a resetují se všechna tovární nastavení. Opravdu chcete pokračovat?',
          sure_to_reset: 'Odstraní se vaše osobní nastavení a resetují se všechny předvolby parametrů. Opravdu chcete pokračovat?',
          title: 'Spravovat parametry',
          wobble_diameter: 'Průměr vlnění',
          wobble_step: 'Velikost kroku vlnění',
        },
        preset_setting: 'Nastavení parametrů (%s)',
        presets: 'Předvolba',
        print_multipass: 'Multi-pass',
        promark_speed_desc: 'Parametr rychlosti není použitelný pro přechodové obrázky.',
        pulse_width: 'Šířka Pulzu',
        pwm_advanced_desc: 'Nastavte minimální výkon pro režim hloubky.',
        pwm_advanced_hint: 'Toto nastavení platí konkrétně pro obrázek s barevným přechodem při používání Depth Mode (režimu hloubky).',
        pwm_advanced_setting: 'Nastavení Depth Mode Power (výkonu režimu hloubky)',
        repeat: 'Přeskočit počítání',
        single_color: 'Jednobarevné',
        single_color_desc: 'Použitelné pouze pro plně barevnou vrstvu a nelze je použít s rozšířenou jednobarevnou vrstvou.',
        slider: {
          fast: 'Rychlé',
          high: 'Vysoké',
          low: 'Nízké',
          regular: 'Běžné',
          slow: 'Pomalé',
          very_fast: 'Nejrychlejší',
          very_high: 'Max',
          very_low: 'Min',
          very_slow: 'Nejpomalejší',
        },
        speed: 'Rychlost',
        speed_constrain_warning: 'Rychlost řezání objektů vektorových cest bude omezeno na %(limit)s. Toto omezení můžete odstranit v Nastavení.',
        speed_constrain_warning_auto_feeder: 'Rychlost řezání objektů Automatické podavače bude omezena na %(limit)s. Tento limit můžete odstranit v Nastavení preferencí.',
        speed_constrain_warning_curve_engraving: 'Rychlost gravírování křivkových objektů bude omezena na %(limit)s. Tento limit můžete odstranit v nastavení předvoleb.',
        stepwise_focusing: 'Postupné ostření',
        stepwise_focusing_desc: 'Postupné snižování zaostřovací vzdálenosti na základě výšky objektu během každého počítání průchodů.',
        strength: 'Intenzita',
        times: 'krát',
        various_preset: 'Různé předvolby',
        white_ink: 'Bílý inkoust',
        white_ink_settings: 'Nastavení bílého inkoustu',
        wobble: 'Vlnění',
        wobble_desc: 'Vytvoří malé kruhy podél cesty, což je užitečné pro úlohy řezání. (Pouze pro objekty cesty.)',
        wobble_diameter: 'Průměr',
        wobble_step: 'Velikost kroku',
        z_step: 'Krok Z',
      },
      layer_panel: {
        current_layer: 'Současná vrstva',
        layer1: 'Vrstva 1',
        layer_bitmap: 'Bitmapa',
        layer_cutting: 'Řezání',
        layer_engraving: 'Gravírování',
        layers: {
          del: 'Smazat vrstvu',
          dupe: 'Duplikovat vrstvu',
          fullColor: 'Barevné',
          layer: 'Vrstva',
          layers: 'Vrstvy',
          lock: 'Uzamknout vrstvu',
          merge_all: 'Sloučit vše',
          merge_down: 'Sloučit dolů',
          merge_selected: 'Sloučit vybrané vrstvy',
          move_down: 'Přesunout vrstvu dolů',
          move_elems_to: 'Přesunout prvky do:',
          move_selected: 'Přesunout vybrané prvky do jiné vrstvy',
          move_up: 'Přesunout vrstvu nahoru',
          new: 'Nová vrstva',
          rename: 'Přejmenovat vrstvu',
          splitFullColor: 'Rozšířit vrstvu',
          switchToFullColor: 'Přepnout na barevnou vrstvu',
          switchToSingleColor: 'Přepnout na jednobarevnou vrstvu',
          unlock: 'Odemknout',
        },
        move_elems_to: 'Přesunout prvky do:',
        notification: {
          dupeLayerName: 'Tak už se nějaká vrstva jmenuje!',
          enterNewLayerName: 'Zadejte prosím nové jméno pro vrstvu',
          enterUniqueLayerName: 'Zadejte prosím unikátní jméno pro vrstvu',
          layerHasThatName: 'Tak už se vrstva jmenuje',
          mergeLaserLayerToPrintingLayerMsg: 'Pozor: Pokud tuto operaci dokončíte, nastavení laserové vrstvy bude odstraněno a přiřazeno podle současné vrstvy.',
          mergeLaserLayerToPrintingLayerTitle: 'Chcete spojit tyto vrstvy do jedné Tiskové vrstvy?',
          mergePrintingLayerToLaserLayerMsg: 'Pozor: Pokud tuto operaci dokončíte, nastavení barev tiskové vrstvy bude odstraněno a přiřazeno podle současné vrstvy.',
          mergePrintingLayerToLaserLayerTitle: 'Chcete tyto vrstvy spojit do jedné Laserové vrstvy?',
          moveElemFromPrintingLayerMsg: 'Pozor: Pokud tuto operaci dokončíte, nastavení barev vybraného prvku bude odstraněno a přiřazeno podle %s.',
          moveElemFromPrintingLayerTitle: 'Přesunout vybraný prvek do %s a převést ho na laserový prvek?',
          moveElemToPrintingLayerMsg: 'Pozor: Pokud tuto operaci dokončíte, nastavení vybraného prvku bude odstraněno a přiřazeno podle %s.',
          moveElemToPrintingLayerTitle: 'Přesunout vybraný prvek do %s a převést ho na tiskový prvek?',
          newName: 'NOVÉ JMÉNO',
          QmoveElemsToLayer: 'Přesunout vybrané prvky do vrstvy %s?',
          splitColorMsg: 'Pozor: Pokud budete s touto operací pokračovat, nebudete se moci vrátit k původním barevným vrstvám.',
          splitColorTitle: 'Přejete si rozšířit vybranou vrstvu do vrstev CMYK?',
        },
      },
      object_panel: {
        actions_panel: {
          ai_bg_removal: 'Odstranění pozadí',
          ai_bg_removal_reminder: 'Stisknutím tlačítka okamžitě zaplatíte 0,2 kreditu. Přejete si pokračovat?',
          ai_bg_removal_short: 'Odstranění pozadí',
          array: 'Pole',
          auto_fit: 'Automaticky přizpůsobit',
          bevel: 'Zkosit',
          brightness: 'Jas',
          convert_to_path: 'Převést na cestu',
          create_textpath: 'Vytvořit text na cestě',
          create_textpath_short: 'Text na cestě',
          crop: 'Oříznout',
          decompose_path: 'Rozložit',
          detach_path: 'Rozložit text na cestě',
          detach_path_short: 'Rozložit',
          disassemble_use: 'Rozebrat',
          disassembling: 'Rozebírám...',
          edit_path: 'Upravit cestu',
          fetching_web_font: 'Získávám online font...',
          grading: 'Přechod',
          invert: 'Převrátit',
          offset: 'Ofset',
          outline: 'Obrys',
          replace_with: 'Nahradit s...',
          replace_with_short: 'Nahradit',
          sharpen: 'Zaostřit',
          simplify: 'Zjednodušit',
          smart_nest: 'Smart Nest',
          trace: 'Stopa',
          ungrouping: 'Rozděluji skupinu...',
          uploading_font_to_machine: 'Uploaduji font do přístroje...',
          wait_for_parsing_font: 'Analyzuji font... Počkejte prosím chvíli.',
          weld_text: 'Přivařit text',
        },
        align: 'Zarovnání',
        boolean: 'Boolean',
        bottom_align: 'Zarovnání dolů',
        center_align: 'Zarovnání na střed',
        difference: 'Rozdíl',
        distribute: 'Rozšířit',
        flip: 'Převrátit',
        group: 'Sloučit do skupiny',
        hdist: 'Horizontálně rozšířit',
        hflip: 'Převrátit horizontálně',
        intersect: 'Protnout',
        left_align: 'Zarovnání vlevo',
        lock_aspect: 'Uzamknout aspekt',
        middle_align: 'Zarovnání do středu',
        option_panel: {
          color: 'Barva',
          fill: 'Výplň',
          font_family: 'Font',
          font_size: 'Velikost',
          font_style: 'Styl',
          letter_spacing: 'Mezery mezi písmeny',
          line_spacing: 'Řádkování',
          path_infill: 'Výplň cesty',
          pwm_engraving: 'Depth Mode (režim hloubky)',
          pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
          rounded_corner: 'Zaoblený roh',
          shading: 'Barevný přechod',
          sides: 'Strany',
          start_offset: 'Ofset textu',
          stroke: 'Štětec',
          stroke_color: 'Barva štětce',
          stroke_width: 'Šířka štětce',
          text_infill: 'Výplň textu',
          threshold: 'Hranice jasu',
          threshold_short: 'Hranice',
          vertical_align: 'Zarovnání',
          vertical_text: 'Vertikální text',
        },
        path_edit_panel: {
          connect: 'Připojit',
          delete: 'Smazat',
          disconnect: 'Odpojit',
          node_type: 'TYP UZLU',
          round: 'Zaoblený',
          sharp: 'Ostrý',
        },
        right_align: 'Zarovnání vpravo',
        subtract: 'Ubrat',
        top_align: 'Zarovnání nahoru',
        ungroup: 'Rozdělit skupinu',
        union: 'Spojení',
        unlock_aspect: 'Odemknout aspekt',
        vdist: 'Vertikálně rozšířit',
        vflip: 'Převrátit vertikálně',
        zoom: 'Zoom',
      },
      tabs: {
        layers: 'Vrstvy',
        objects: 'Objekty',
        path_edit: 'Upravit cestu',
      },
    },
    shapes_panel: {
      animals: 'Zvíře',
      arrow: 'Šíp',
      basic: 'Základní',
      birds: 'Pták',
      celebration: 'Oslava',
      circular: 'Kruhový',
      CNY: 'Čínský Nový rok',
      corner: 'Roh',
      decor: 'Výzdoba',
      easter: 'Velikonoce',
      elements: 'Prvky',
      environment: 'Prostředí',
      graphics: 'Grafika',
      halloween: 'Halloween',
      holidays: 'Prázdniny',
      label: 'Štítek',
      land: 'Země',
      line: 'Linka',
      nature: 'Příroda',
      photo: 'Fotka',
      plants: 'Rostlina',
      ribbon: 'Stuha',
      sea: 'Moře',
      shape: 'Tvar',
      speech: 'Řeč',
      text: 'Ohraničení textu',
      title: 'Prvky',
      valentines: 'Valentýn',
      weather: 'Počasí',
      Xmas: 'Vánoce',
    },
    svg_editor: {
      unable_to_fetch_clipboard_img: 'Nepodařilo se získat obrázek ze schránky',
      unnsupported_file_type: 'Tento typ souboru není přímo podporován. Převeďte prosím soubor na SVG či bitmapu.',
    },
    tag: {
      g: 'Skupina',
      image: 'Obrázek',
      text: 'Text',
      use: 'Import SVG',
    },
    time_est_button: {
      calculate: 'Odhadnout délku',
      estimate_time: 'Odhadovaná délka:',
    },
    tool_panels: {
      _nest: {
        end: 'Zavřít',
        no_element: 'Žádné prvky k uspořádání.',
        rotations: 'Možná rotace',
        spacing: 'Rozestupy',
        start_nest: 'Uspořádat',
        stop_nest: 'Zastavit',
      },
      _offset: {
        corner_type: 'Roh',
        direction: 'Směr ofsetu',
        dist: 'Vzdálenost ofsetu',
        fail_message: 'Nepodařil se ofset objektů.',
        inward: 'Dovnitř',
        not_support_message: 'Vybrané prvky obsahují nepodporované  SVG tagy\nObrázek, skupina, text a importovaný objekt.',
        outward: 'Ven',
        round: 'Zaoblený',
        sharp: 'Ostrý',
      },
      array_dimension: 'Dimenze pole',
      array_interval: 'Interval pole',
      cancel: 'Storno',
      columns: 'Sloupce.',
      confirm: 'Potvrdit',
      dx: 'X',
      dy: 'Y',
      grid_array: 'Vytvořit mřížkové pole',
      nest: 'Optimalizace uspořádání',
      offset: 'Ofset',
      rows: 'Řádky',
    },
    units: {
      mm: 'mm',
      walt: 'W',
    },
    zoom_block: {
      fit_to_window: 'Přizpůsobit oknu',
    },
  },
  boxgen: {
    add_option: 'Přidat možnost',
    basic_box: 'Základní box',
    beam_radius: 'Kompenzace řezné spáry',
    beam_radius_warning: 'Odstranit kompenzaci řezné spáry, když jsou okraje a spoje boxu krátké, aby se zajistilo správné sestavení boxu',
    cancel: 'Storno',
    coming_soon: 'Již brzy',
    continue_import: 'Pokračovat k Importu',
    control_tooltip: 'Levé tlačítko myši pro rotaci\nPosouvání pro zoom\nPravé tlačítko pro panorama',
    control_tooltip_touch: 'Táhněte pro rotaci\nŠpetka pro zoom\nDva prsty pro panorama',
    cover: 'Obal',
    customize: 'Přizpůsobit',
    depth: 'Hloubka',
    edge: 'Hrana',
    finger: 'Zakousnutí',
    finger_warning: 'Vnitřní strana výšky boxu musí být minimálně 6 mm (0,24 palce), aby byl kompatibilní se spojem Zakousnutí.',
    height: 'Výška',
    import: 'Importovat',
    inner: 'Vnitřní',
    joints: 'Spoj',
    max_dimension_tooltip: 'Maximální šířka/výška/hloubka je nastavena na %s.',
    merge: 'Sloučit',
    outer: 'Vnější',
    reset: 'Restartovat',
    tCount: 'Počet Téček',
    tCount_tooltip: 'Počet Téček se vztahuje ke kratší straně. Jejich počet na delší straně je vypočítán na základě její délky.',
    tDiameter: 'Průměr Téčka',
    text_label: 'Popis',
    thickness: 'Tloušťka',
    title: 'BOXGEN',
    tLength: 'Výška Téček',
    tSlot: 'Téčka',
    tSlot_warning: 'Výška strany boxu musí být minimálně 30 mm (1,18 palce), aby byl kompatibilní se spojování Téčky.',
    volume: 'Objem',
    width: 'Šířka',
    workarea: 'Pracovní oblast',
    zoom: 'Zoom',
  },
  buttons: {
    back: 'Předchozí',
    back_to_beam_studio: 'Zpátky do Beam Studio',
    done: 'Hotovo',
    next: 'Další',
  },
  calibration: {
    ador_autofocus_focusing_block: 'Tři sekundy držte stisknutou ikonu AF na hlavní stránce přístroje a nechte sondu, ať se dotýká fokusního bloku.',
    ador_autofocus_material: 'Tři sekundy držte stisknutou ikonu AF na hlavní stránce přístroje a nechte sondu, ať se jemně dotýká materiálu.',
    align_ilb: 'Pozice: Zarovnejte s vyrytým bodem dole vlevo uvnitř.',
    align_ilt: 'Pozice: Zarovnejte s vyrytým bodem nahoře vlevo uvnitř.',
    align_irb: 'Pozice: Zarovnejte s vyrytým bodem dole vpravo uvnitř.',
    align_irt: 'Pozice: Zarovnejte s vyrytým bodem nahoře vpravo uvnitř.',
    align_olb: 'Pozice: Zarovnejte s vyrytým bodem dole vlevo venku.',
    align_olt: 'Pozice: Zarovnejte s vyrytým bodem nahoře vlevo venku.',
    align_orb: 'Pozice: Zarovnejte s vyrytým bodem dole vpravo venku.',
    align_ort: 'Pozice: Zarovnejte s vyrytým bodem nahoře vpravo venku.',
    align_red_cross_cut: 'Zarovnejte prosím prostředek červeného kříže s vyříznutým křížem.',
    align_red_cross_print: 'Zarovnejte prosím prostředek červeného kříže s vytisknutým křížem.',
    analyze_result_fail: 'Nepodařilo se analyzovat zachycený obrázek. <br/>Ujistěte se prosím, že:<br/>1. Zachycený obrázek je celý zakryt bílým papírem.<br/>2. Platforma se řádně fokusuje.',
    ask_for_readjust: 'Přejete si přeskočit krok gravírování a fotit a kalibrovat přímo?',
    back: 'Zpět',
    calculating_camera_matrix: 'Vypočítávám matici kamery...',
    calculating_regression_parameters: 'Vypočítávám regresní parametry...',
    calibrate_camera_before_calibrate_modules: 'Nechte prosím znovu zkalibrovat kameru před kalibrací modulů.',
    calibrate_chessboard_success_msg: 'Úspěšně zachycena fotografie šachovnice.<br/>Skóre pro tuto fotografii je %s (%.2f).',
    calibrate_done: 'Kalibrace dokončena. Při přesném fokusu je lepší přesnost kamery.',
    calibrate_done_diode: 'Kalibrace dokončena. Ofset modulu laserové diody byl uložen.',
    calibrating: 'Kalibruji...',
    calibrating_with_device_pictures: 'Kalibruji s obrázky zařízení...',
    camera_calibration: 'Kalibrace kamery ',
    camera_parameter_saved_successfully: 'Parametr kamery úspěšně uložen.',
    cancel: 'Storno',
    check_checkpoint_data: 'Data kontrolního bodu',
    check_device_pictures: 'Kontrola obrázků zařízení',
    checking_checkpoint: 'Kontroluji data kontrolního bodu...',
    checking_pictures: 'Kontroluji obrázky zařízení...',
    diode_calibration: 'Kalibrace modulu diodového laseru',
    do_engraving: 'Gravírovat',
    download_chessboard_file: 'Stáhnout soubor šachovnice',
    downloading_checkpoint: 'Stahuji data kontrolního bodu...',
    downloading_pictures: 'Stahuji obrázky...',
    drawing_calibration_image: 'Vykresluji kalibrační obrázek...',
    dx: 'X',
    dy: 'Y',
    elevate_and_cut: 'Zvednout a řezat',
    elevate_and_cut_step_1: 'Umístěte světlou dřevěnou desku velikosti A4 nebo obálky do pracovní oblasti pozvednuté na 20 mm.',
    elevate_and_cut_step_1_prism_lift: 'Použijte Ador Prism Lift o maximální délce 14 mm s  minimálně 6 mm širokou dřevěnou deskou.',
    failed_to_calibrate_camera: '#848 Nepodařilo se kalibrovat kameru. Kontaktujte prosím FLUX podporu.',
    failed_to_calibrate_chessboard: 'Kalibrace s obrázkem šachovnice se nezdařila.',
    failed_to_calibrate_with_pictures: '#848 Nepodařilo se kalibrovat s obrázky zařízení.',
    failed_to_download_pictures: '#848 Nepodařilo se stáhnout obrázky. Kontaktujte prosím FLUX podporu.',
    failed_to_move_laser_head: 'Nepodařilo se pohnout laserovou hlavou.',
    failed_to_parse_checkpoint: 'Nepodařilo se rozebrat data kontrolního bodu.',
    failed_to_save_calibration_results: '#849 Nepodařilo se uložit výsledky kalibrace, zkuste to prosím znovu. Pokud bude problém přetrvávat, kontaktujte prosím FLUX podporu.',
    failed_to_save_camera_parameter: 'Nepodařilo se uložit parametr kamery.',
    failed_to_solve_pnp: 'Nepodařilo se vyřešit pozici kamery.',
    finish: 'Hotovo',
    found_checkpoint: 'Ve vašem zařízení byla nalezena data kontrolního bodu. Přejete si je z kontrolního bodu obnovit?',
    getting_plane_height: 'Získávám výšku plochy...',
    hint_adjust_parameters: 'Použijte tyto parametry k přizpůsobení červeného čtverce',
    hint_red_square: 'Prosím zarovnejte vnější část červeného čtverce s ořízlým čtvercem.',
    module_calibration_2w_ir: 'Kalibrace infračerveného modulu',
    module_calibration_printer: 'Kalibrace tiskového modulu',
    moving_laser_head: 'Pohybuji laserovou hlavou...',
    next: 'Další',
    no_picutre_found: '#846 Vaše zařízení nemá dostupné žádné nezpracované fotky ke kalibraci. Kontaktujte prosím podporu FLUX.',
    perform_autofocus_bb2: 'Přejděte na ovládací panel zařízení a stiskněte tlačítko AF pro automatické zaostření.',
    please_do_camera_calibration_and_focus: {
      beambox: 'Při kalibrování modulu laserové diody je potřeba kamera.\nUjistěte se prosím, že byla kamera tohoto stroje zkalibrována.\nPoté prosím upravte platformu na ústřední bod (výška vypnutého akrylu)',
      beamo: 'Při kalibrování modulu laserové diody je potřeba kamera.\nUjistěte se prosím, že byla kamera tohoto stroje zkalibrována.\nPoté prosím upravte laserovou hlavici, aby se fokusovala na gravírovaný objekt (výška vypnutého akrylu)',
    },
    please_goto_beambox_first: 'Abyste tuto funkci mohli použít, přepněte prosím na mód gravírování ( Beambox ).',
    please_place_dark_colored_paper: 'Umístěte prosím A4 nebo tmavý dopisový papír doprostřed pracovní oblasti.',
    please_place_paper: 'Umístěte prosím A4 nebo bílý dopisový papír do levého horního rohu pracovní oblasti.',
    please_place_paper_center: 'Umístěte prosím A4 nebo bílý dopisový papír doprostřed pracovní oblasti.',
    please_refocus: {
      beambox: 'Přizpůsobte prosím platformu ke kontaktnímu místu (výška vypnutého akrylu)',
      beamo: 'Přizpůsobte prosím laserovou hadici, aby se nastavila na gravírovaný objekt (výška vypnutého akrylu)',
      beamo_af: 'Dvakrát prosím poklepejte na postranní tlačítko doplňku autofokus a nechte sondu, aby se jemně dotýkala materiálu.',
      hexa: 'Dvakrát klikněte na tlačítko přizpůsobení výšky, abyste zvedli nahoru voštinový stůl a sonda se dotýkala gravírovaného materiálu.',
    },
    preparing_to_take_picture: 'Připravuji se k vyfocení...',
    promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    put_chessboard: 'Umístěte šachovnici',
    put_chessboard_1: 'Přejděte na ovládací panel zařízení a stiskněte tlačítko AF pro automatické zaostření.',
    put_chessboard_2: 'Pohybujte šachovnicovým papírem nebo laserovou hlavou, dokud nebude potvrzeno, že celá šachovnice je v okně červeného rámečku živého náhledu.',
    put_chessboard_3: 'Klikněte pravým tlačítkem myši pro stažení obrázku šachovnice na aktuální pozici a zkontrolujte jeho jasnost.',
    put_chessboard_bb2_desc_1: 'Stáhněte si následující soubor šachovnice a vytiskněte jej na papír velikosti A4 (vytištěná šachovnice by měla mít čtverce o rozměrech 1x1 cm).',
    put_chessboard_bb2_desc_2: 'Připevněte vytištěnou šachovnici na nedeformovatelnou desku, jako je akryl nebo sklo, a ujistěte se, že šachovnice je hladká a bez záhybů nebo zvedání.',
    put_chessboard_bb2_desc_3: 'Položte desku se šachovnicí rovně do středu pracovní plochy.',
    put_chessboard_promark_1: 'Nastavte ohniskovou vzdálenost na vhodné zaostření pro objektiv s polem.',
    put_chessboard_promark_2: 'Ujistěte se, že je šachovnicový papír jasně viditelný bez oslnění. Klikněte na "Další" pro pořízení fotografie.',
    put_chessboard_promark_desc_1: 'Použijte šachovnicový papír dodaný v krabici s příslušenstvím, nebo vytiskněte následující šachovnicový vzor na papír velikosti A4 pro kalibraci kamery.',
    put_chessboard_promark_desc_2: 'Správně nastavte zaostření a poté klikněte na "Další" pro pokračování v gravírování.',
    put_paper: 'Vložte papír',
    put_paper_promark_1: 'Položte černou lepenku z příslušenství na pracovní platformu.',
    put_paper_promark_2: 'Správně nastavte zaostření a poté klikněte na "Začít Gravírovat" pro pokračování v gravírování.',
    put_paper_skip: 'Pokud kalibrační kresba automaticky nepokračuje k pořízení snímku, stiskněte „Přeskočit“ pro pořízení snímku.',
    put_paper_step1: 'Vložte prosím bílý papír formátu A4 nebo dopisu doprostřed pracovní oblasti.',
    put_paper_step2: 'Zajistěte čtyři rohy papíru, aby zůstal rovně položený.',
    put_paper_step3: 'Klikněte na "Začít Gravírovat"',
    res_average: 'Průměrné',
    res_excellent: 'Výborné',
    res_poor: 'Špatné',
    retake: 'Znovu vyfotit',
    rotation_angle: 'Rotace',
    show_last_config: 'Ukázat poslední výsledek',
    skip: 'Přeskočit',
    solve_pnp_step1: 'Zarovnejte prosím gravírovací body podle počtu a očekávané pozice všech červených značkovacích bodů.',
    solve_pnp_step2: 'Pro opětovné zarovnání můžete stisknout "Znovu vyfotit" nebo manuálně přizpůsobit pozice značky.',
    solve_pnp_step3: "Pokud se snímek neodpovídá skutečnosti, prosím přiblížit nebo posunout obraz, aby bylo nastaveno kalibrační bod na černé kartě do středu, a poté zkuste 'Znovu vyfotit'.",
    solve_pnp_title: 'Zarovnat značkovací body.',
    start_engrave: 'Začít Gravírovat',
    start_printing: 'Začít Tisk',
    taking_picture: 'Fotím...',
    unable_to_load_camera_parameters: "#851 Na vašem zařízení nejsou dostupné žádné parametry kalibrace kamery. Přejděte do 'Kalibrace' > 'Kalibrace kamery (Pokročilé)' a dokončete kalibraci pro získání parametrů.",
    update_firmware_msg1: 'Váš firmware nepodporuje tuto funkci. Pro pokračování prosím aktualizujte firmware na novější verzi',
    update_firmware_msg2: 'nebo vyšší (Menu > Přístroj > [Váš přístroj] > Aktualizovat firmware)',
    uploading_images: 'Nahrávám obrázky...',
    use_last_config: 'Použít hodnotu poslední kalibrace',
    use_old_camera_parameter: 'Přejete si použít současný parametr kamerových čoček?',
    with_af: 'S autofokusem.',
    with_prism_lift: 'S Ador Prism Lift',
    without_af: 'Bez autofokusu.',
    without_prism_lift: 'Bez Ador Prism Lift',
    x_ratio: 'Poměr X',
    y_ratio: 'Poměr Y',
    zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
  },
  camera_data_backup: {
    download_success: 'Data z kamery úspěšně stažena.',
    downloading_data: 'Stahuji data...',
    estimated_time_left: 'Zbývající délka:',
    folder_not_exists: 'Vybraná složka neexistuje.',
    incorrect_folder: 'Nepodařilo se nahrát kalibrační data. Zkontrolujte prosím, jestli byla vybrána správná složka.',
    no_picture_found: 'V přístroji nebyly nalezeny žádné obrázky.',
    title: 'Záloha dat z kamery',
    upload_success: 'Data z kamery úspěšně nahrána.',
    uploading_data: 'Nahrávám data...',
  },
  caption: {
    connectionTimeout: 'Vypršel časový limit připojení',
  },
  change_logs: {
    added: 'Přidáno:',
    change_log: 'Záznamy o změnách:',
    changed: 'Změněno:',
    fixed: 'Opraveno:',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    see_older_version: 'Prohlédnout starší verze',
  },
  code_generator: {
    barcode: 'Čárový kód',
    qr_code: 'QR kód',
  },
  curve_engraving: {
    '921': 'Nepodařilo se automaticky zaostřit.',
    '922': 'Nepodařilo se provést měření červeným světlem.',
    amount: 'Množství',
    apply_arkwork: 'Použít umělecké dílo na křivku',
    apply_camera: 'Použít obraz z kamery na křivku',
    click_to_select_point: 'Klikněte pro vybrání nebo odznačení bodů pro opětovné měření.',
    coloumns: 'Sloupce',
    column_gap: 'Mezera mezi sloupci',
    err_object_over_range: 'Objekt překračuje rozsah měření.',
    failed_to_take_reference: 'Nepodařilo se získat referenci',
    gap: 'Mezera',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    measure_audofocus_area: 'Změřit oblast autofokusu',
    preview_3d_curve: 'Náhled gravírování křivky',
    remeasure: 'Znovu změřit',
    remeasuring_points: 'Přeměřuji body...',
    reselect_area: 'Znovu vybrat oblast',
    row_gap: 'Mezera mezi řádky',
    rows: 'Řádky',
    set_object_height: 'Nastavit výšku objektu',
    set_object_height_desc: 'Změřit maximální tloušťku objektu.',
    start_autofocus: 'Zahájit autofokus',
    starting_measurement: 'Zahájit měření...',
    sure_to_delete: 'Chcete smazat zaostřovací data pro gravírování křivky?',
    take_reference: 'Vzít referenci',
    take_reference_desc: 'Prosím, přesuňte laserovou hlavu na nejvyšší bod objektu, spusťte zaostřovací sondu a klikněte na „Potvrdit“ pro zaostření.',
  },
  device: {
    abort: 'Zrušit',
    aborted: 'Zrušeno',
    aborting: 'Zrušeno',
    busy: 'Zaneprázdněn',
    cartridge_info_read_failed: 'Ujistěte se prosím, že je inkoust zcela vložen. Pokuste se inkoust vyjmout a znovu vložit.',
    cartridge_info_verification_failed: 'Ověřte prosím, že je váš inkoust FLUX autentický.',
    cartridge_serial_number: 'Sériové číslo inkoustové kazety',
    close_door_to_read_cartridge_info: 'K získání informací o inkoustu prosím zavřete dvířka přístroje.',
    completed: 'Dokončeno',
    completing: 'Dokončuji',
    deviceList: 'Seznam přístrojů',
    disable: 'Zakázat',
    disconnectedError: {
      caption: 'Přístroj odpojen',
      message: 'Potvrďte prosím, jestli je dostupný síťový přístup k %s',
    },
    enable: 'Povolit',
    firmware_version: 'Verze firmwaru',
    ink_color: 'Barva inkoustu',
    ink_level: 'Úroveň inkoustu',
    ink_type: 'Typ inkoustu',
    IP: 'IP',
    model_name: 'Jméno modelu',
    occupied: 'Zachovávám',
    pause: 'Pauza',
    paused: 'Pozastaveno',
    pausedFromError: 'Pozastaveno před chybou',
    pausing: 'Pozastavuji',
    please_wait: 'Prosím vyčkejte...',
    preparing: 'Připravuji',
    processing: 'Zpracovávám',
    quit: 'Konec',
    ready: 'Připraven',
    reset: 'Restart (Kop)',
    resuming: 'Obnovuji',
    retry: 'Opakovat',
    running: 'Pracuji',
    scanning: 'Skenuji',
    select: 'Vybrat',
    select_printer: 'Vyberte tiskárnu',
    serial_number: 'Sériové číslo',
    start: 'Začít',
    starting: 'Zahajuji',
    status: 'Stav',
    submodule_type: 'Modul',
    toolhead_change: 'Změnit hlavu nástroje',
    unknown: 'Neznámý',
    uploading: 'Nahrávám',
    UUID: 'UUID',
  },
  device_selection: {
    no_beambox: '#801 Nemůžeme najít v síti váš přístroj.\n Podívejte se prosím do tohoto <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">návodu</a> pro řešení problému s připojením.',
    no_device_web: '#801  Zkontrolujte prosím stav vašeho přístroje nebo klikněte v dolní části na Nastavení přístroje k řádnému nastavení přístroje.',
    select_usb_device: 'Vyberte zařízení USB',
  },
  editor: {
    exposure: 'Náhled expozice',
    opacity: 'Náhled průhlednosti',
    prespray_area: 'Primární oblast',
  },
  error_pages: {
    screen_size: 'Je možné, že Beam Studio nebude na vašem zařízení správně fungovat. Pro optimální zážitek by měla mít obrazovka minimální šířku 1024 pixelů.',
  },
  flux_id_login: {
    connection_fail: '#847 Nepodařilo se připojit k členské službě FLUX.',
    email: 'E-mail',
    flux_plus: {
      access_monotype_feature: 'Nemáte doplněk Monotype Fonts.',
      access_monotype_feature_note: 'Musíte být členem FLUX+ nebo mít doplněk Monotype Fonts, abyste měli přístup k této funkci.',
      access_plus_feature_1: 'Přihlašujete se k',
      access_plus_feature_2: 'funkci.',
      access_plus_feature_note: 'Musíte být členem FLUX+, abyste měli přístup k této funkci.',
      ai_credit_tooltip: 'Pro AI odstraňování pozadí',
      explore_plans: 'Prozkoumat nabídky FLUX+',
      features: {
        ai_bg_removal: 'Odstraňování pozadí',
        boxgen: '3D Box generátor',
        dmkt: '1000+ designových souborů',
        monotype: '250+ premium fontů',
        my_cloud: 'Neomezený úložný prostor v cloudu',
      },
      flux_credit_tooltip: 'Pro soubory z Design Market a AI odstraňování pozadí',
      get_addon: 'Získat doplněk',
      goto_member_center: 'Jít do Členského centra',
      learn_more: 'Zjistit víc',
      member_center_url: 'https://member.flux3dp.com/en-US/subscription',
      subscribe_now: 'Přihlásit se k odběru',
      thank_you: 'Děkujeme, že jste naším cenným členem!',
      website_url: 'https://flux3dp.com/subscription',
    },
    forget_password: 'Zapomenuté heslo?',
    incorrect: 'E-mailová adresa nebo heslo není správné.',
    login: 'Přihlásit',
    login_success: 'Jste přihlášen.',
    lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
    new_to_flux: 'Začínáte s FLUX? Vytvořte si účet.',
    not_verified: 'E-mailová adresa zatím nebyla ověřena.',
    offline: 'Práce off-line',
    password: 'Heslo',
    register: 'Vytvořte si svůj FLUX účet',
    remember_me: 'Pamatuj si mě',
    signup_url: 'https://id.flux3dp.com/user/login#up',
    unlock_shape_library: 'Přihlaste se k odemčení databáze tvarů.',
    work_offline: 'Práce off-line',
  },
  framing: {
    area_check: 'Kontrola oblasti',
    areacheck_desc: 'Zajišťuje bezpečnost pracovní oblasti náhledem na ohraničující rámeček objektu a zónu akcelerace laserové hlavy.',
    calculating_task: 'Výpočet úlohy...',
    framing: 'Rámování',
    framing_desc: 'Náhled ohraničujícího rámečku objektu.',
    hull: 'Obrys',
    hull_desc: 'Zobrazuje tvar, který těsně kopíruje návrh, jako gumička obepínající objekt.',
    low_laser: 'Nízký laser',
    low_laser_desc: 'Nastavte hodnotu nízkého výkonu laseru pro úlohu rámování.',
    rotate_axis: 'Otočit osu',
    rotateaxis_desc: 'Náhled osy rotace, osa se nepohybuje.',
    rotation_frame_warning: 'Náhled rámečku se otočí o jednu otáčku. Nepřerušujte jej, aby nedošlo k posunutí polohy bodu.',
    rotation_framing_desc: 'Náhled celého rozsahu rotace, osa se pohybuje podle náhledu.',
    start_task: 'Spustit Úkol',
    start_task_description: 'Po dokončení náhledu rámce klikněte na „Spustit Úkol“ pro odeslání úlohy.',
  },
  general: {
    choose_folder: 'Vyberte složku',
    processing: 'Zpracovávám',
  },
  generic_error: {
    OPERATION_ERROR: '[OE] Nastal stavový konflikt, opakujte prosím akci.',
    SUBSYSTEM_ERROR: '[SE] Chyba při vykonávání úlohy strojového firmwaru. Restartujte prosím stroj.',
    UNKNOWN_COMMAND: '[UC] Aktualizujte prosím firmware zařízení.',
    UNKNOWN_ERROR: '[UE] Nastala neznámá chyba. Restartujte prosím Beam Studio a přístroj.',
  },
  global: {
    apply: 'Použít',
    back: 'Zpět',
    cancel: 'Zrušit',
    editing: {
      redo: 'Znovu',
      reset: 'Resetovat',
      undo: 'Zpět',
      zoom_in: 'Přiblížit',
      zoom_out: 'Oddálit',
    },
    mode_conflict: 'Tato možnost není dostupná v aktuálním režimu.',
    ok: 'OK',
    save: 'Uložit',
    stop: 'Zastavit',
  },
  image_edit_panel: {
    eraser: {
      brush_size: 'Velikost štětce',
      description: 'Klikněte nebo přetáhněte pro ruční vymazání nežádoucích oblastí.',
      title: 'Guma',
    },
    magic_wand: {
      description: 'Vyberte a odstraňte přilehlé oblasti na základě podobnosti barev.',
      title: 'Kouzelná hůlka',
      tolerance: 'Tolerance',
    },
    title: 'Upravit obrázek',
  },
  initialize: {
    back: 'Zpět',
    cancel: 'Zrušit',
    confirm: 'Potvrdit',
    connect: 'Připojit',
    connect_ethernet: {
      title: 'Přímé připojení',
      tutorial1: '1. Propojte přístroj a váš počítač síťovým kabelem.',
      tutorial2_1: '2. Postupujte ',
      tutorial2_2: ' abyste ze svého počítače udělali router.',
      tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
      tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
      tutorial2_a_text: 'podle tohoto návodu',
      tutorial3: '3. Klikněte na Další.',
    },
    connect_machine_ip: {
      alert: {
        swiftray_connection_error: 'Nelze se připojit k serveru. Restartujte Beam Studio a zkuste to znovu.',
      },
      check_camera: 'Kontroluji dostupnost kamery',
      check_connection: 'Kontroluji připojení k přístroji',
      check_firmware: 'Kontroluji verzi firmwaru',
      check_ip: 'Kontroluji dostupnost IP.',
      check_swiftray_connection: 'Kontrola připojení k serveru',
      check_swiftray_connection_unreachable: 'server není dosažitelný',
      check_usb: 'Zkontrolujte USB připojení.',
      enter_ip: 'Zadejte IP adresu svého přístroje.',
      finish_setting: 'Pojďme tvořit!',
      invalid_format: 'Špatný formát',
      invalid_ip: 'Špatné IP: ',
      promark_hint: 'Pokud se vám opakovaně nedaří připojit přes USB, podívejte se na <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">článek v Centru nápovědy</a>.',
      retry: 'Opakovat',
      starts_with_169254: 'Začíná 169.254',
      succeeded_message: 'Úspěšně připojeno 🎉',
      unreachable: 'Nedosažitelné IP',
    },
    connect_usb: {
      connect_camera: 'Připojte kameru stroje k počítači pomocí USB kabelu.',
      title: 'USB připojení',
      title_sub: ' (Pouze HEXA & Ador)',
      turn_off_machine: 'Vypněte přístroj.',
      turn_on_machine: 'Zapněte přístroj.',
      tutorial1: 'Propojte USB kabelem svůj přístroj a počítač.',
      tutorial2: 'Klikněte na “Další”.',
      wait_for_turning_on: 'Klikněte na “Další”, až bude zahajovací proces hotový a uvidíte hlavní obrazovku.',
    },
    connect_wifi: {
      title: 'Připojuji se k Wi-Fi',
      tutorial1: '1.Na dotykovém panelu klikněte na "Síť" > "Připojit k Wi-Fi".',
      tutorial1_ador: '1. Na dotykovém panelu klikněte na "PŘÍSTROJ" > "Síť" > "Připojit k Wi-Fi".',
      tutorial2: '2. Zvolte svoji Wi-Fi a připojte se k ní.',
      what_if_1: 'Co když nevidím svou Wi-Fi?',
      what_if_1_content: '1. Šifrovací mód Wi-Fi by měl být WPA2 či bez hesla.\n2. Šifrovací mód může být nastaven v administrační části Wi-Fi routeru. Pokud router nepodporuje WPA2 a potřebujete pomoc se zvolením správného routeru, kontaktujte prosím podporu FLUX.',
      what_if_2: 'Co když nevidím žádnou Wi-Fi?',
      what_if_2_content: '1. Ujistěte se, že je Wi-Fi adaptér správně zapojený.\n2. Pokud se na dotykové obrazovce nenachází žádná MAC adresa bezdrátové sítě, kontaktujte prosím podporu FLUX.\n3. Kanál Wi-Fi by měl být 2,4 GHz (5Ghz není podporován).',
    },
    connect_wired: {
      title: 'Připojení pomocí síťového kabelu',
      tutorial1: '1. Připojte se svým routerem k přístroji.',
      tutorial2: '2. Stiskněte "Síť", abyste získali IP k drátové síti.',
      tutorial2_ador: '2. Stiskněte "PŘÍSTROJ" > "Síť", abyste získali IP drátové sítě.',
      what_if_1: 'Co když je IP prázdná?',
      what_if_1_content: '1. Ujistěte se, že je síťový kabel správně zapojený.\n2. Pokud není na dotykové obrazovce žádná MAC adresa drátové sítě, kontaktujte prosím podporu FLUX.',
      what_if_2: 'Co když IP začíná 169?',
      what_if_2_content: '1. Pokud IP adresa začíná 169.254, mělo by se jednat o problém s nastavením DHCP. Kontaktujte prosím svého poskytovatele internetu pro další pomoc.\n2. Pokud se váš počítač připojuje k internetu přímo s pomocí PPPoE, změňte prosím router, aby se připojoval pomocí PPPoE a aktivujte u routeru funkci DHCP.',
    },
    connecting: 'Připojuji...',
    connection_types: {
      ether2ether: 'Přímé připojení',
      usb: 'USB připojení',
      wifi: 'Wi-Fi',
      wired: 'Drátové připojení',
    },
    next: 'Další',
    no_machine: 'Nemám nyní přístroj.',
    promark: {
      configuration_confirmation: 'Tím zajistíte, že váš Promark bude správně nakonfigurován pro optimální efektivitu a přesnost.',
      or_complete_later: `Nebo přeskočte tento krok a dokončete nastavení Promark později na:
    Zařízení > „Název Promark“ > Nastavení Promark`,
      qc_instructions: 'Vyplňte parametry uvedené na zadní straně karty „QC Pass“',
      select_laser_source: 'Vyberte svůj Promark',
      select_workarea: 'Vyberte pracovní oblast',
      settings: 'Nastavení Promark',
    },
    retry: 'Opakovat',
    select_beambox: 'Vyberte svůj Beambox',
    select_connection_type: 'Jak se přejete připojit?',
    select_language: 'Vyberte jazyk',
    select_machine_type: 'Vyberte typ vašeho přístroje',
    setting_completed: {
      back: 'Zpět',
      great: 'Vítejte v Beam Studio',
      ok: 'ZAČÍT TVOŘIT',
      setup_later: 'Vždy můžete svůj přístroj nastavit v záhlaví > "Přístroje" > "Nastavení přístroje"',
      start: 'Začít',
    },
    skip: 'Přeskočit',
    start: 'Spustit',
  },
  input_machine_password: {
    connect: 'PŘIPOJIT',
    password: 'Heslo',
    require_password: '"%s" vyžaduje heslo',
  },
  insecure_websocket: {
    extension_detected: 'Zjištěno rozšíření Beam Studio Connect.',
    extension_detected_description: 'Zjistili jsme, že bylo nainstalováno rozšíření Beam Studio Connect. Klikněte prosím na Potvrdit, abyste se přesměrovali na HTTPS, nebo klikněte na Storno, abyste nadále používali HTTP.',
    extension_not_deteced: 'Nepodařilo se najít rozšíření Beam Studio Connect.',
    extension_not_deteced_description: 'Pro použití HTTPS prosím klikněte na Potvrdit, čímž se nainstaluje rozšíření Beam Studio Connect. Po instalaci prosím obnovte stránku, aby se rozšíření aktivovalo.<br/>Nebo klikněte na odkaz dole, abyste zjistili, jak na Chrome používat HTTP.',
    unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Odkaz</a>',
  },
  layer_module: {
    general_laser: 'Laser',
    laser_2w_infrared: '2W infračervený laser',
    laser_10w_diode: '10W diodový laser',
    laser_20w_diode: '20W diodový laser',
    non_working_area: 'Nepracovní oblast',
    none: 'Žádný',
    notification: {
      convertFromLaserModuleMsg: 'Pozor: Pokud tuto operaci dokončíte, nastavení laserové vrstvy bude odstraněno a nastaveno podle současné vrstvy.',
      convertFromLaserModuleTitle: 'Přejte si převést Laserový modul na Tiskový modul?',
      convertFromPrintingModuleMsg: 'Pozor: Pokud tuto operaci dokončíte, nastavení barev tiskové vrstvy bude odstraněno a nastaveno podle současné vrstvy.',
      convertFromPrintingModuleTitle: 'Přejte si převést Tiskový modul na Laserový modul?',
      importedDocumentContainsPrinting: 'Dokument obsahuje tiskovou vrstvu, přejete si změnit pracovní oblast na Ador?',
      performIRCaliMsg: 'Klikněte na Potvrdit k provedení kalibrace, nebo vyberte kalibraci přes horní menu.<br />(Přístroj > [Jméno vašeho přístroje] > Kalibrovat infračervený modul)',
      performIRCaliTitle: 'Proveďte kalibraci infračerveného modulu',
      performPrintingCaliMsg: 'Klikněte na Potvrdit k provedení kalibrace, nebo vyberte kalibraci přes horní menu.<br />(Přístroj > [Jméno vašeho přístroje] > Kalibrovat tiskový modul)',
      performPrintingCaliTitle: 'Proveďte kalibraci tiskového modulu',
      printingLayersCoverted: 'Tiskové vrstvy byly převedeny na laserové vrstvy.',
    },
    printing: 'Probíhá tisk',
    unknown: 'Neznámý modul',
  },
  machine_status: {
    '-17': 'IO mód kazety',
    '-10': 'Udržovací mód',
    '-2': 'Skenuji',
    '-1': 'Udržuji',
    0: 'Nečinný',
    1: 'Zahajuji',
    2: 'ST_TRANSFORM',
    4: 'Zahajuji',
    6: 'Obnovuji',
    16: 'Pracuji',
    18: 'Obnovuji',
    32: 'Pozastaveno',
    36: 'Pozastaveno',
    38: 'Pozastavuji',
    48: 'Pozastaveno',
    50: 'Pozastavuji',
    64: 'Dokončeno',
    66: 'Dokončuji',
    68: 'Připravuji',
    128: 'Zrušeno',
    256: 'Alarm',
    512: 'Fatální',
    UNKNOWN: 'Neznámý',
  },
  material_test_generator: {
    block_settings: 'Nastavení bloků',
    columns: 'Sloupce',
    count: 'Počet',
    cut: 'Řez',
    engrave: 'Gravírování',
    export: 'Export',
    max: 'Max',
    min: 'Min',
    parameter: 'Parametr',
    preview: 'Náhled',
    rows: 'Řádky',
    size: 'Velikost (VxŠ)',
    spacing: 'Odstup',
    table_settings: 'Nastavení tabulky',
    text_settings: 'Nastavení textu',
    title: 'Generátor testování materiálu',
  },
  menu: {
    inches: 'palců',
    mm: 'mm',
  },
  message: {
    auth_error: '#820 Autorizační chyba: Aktualizujte prosím Beam Studio a firmware přístroje na nejnovější verzi.',
    authenticating: 'Ověřuji...',
    camera: {
      abort_preview: 'Zrušit',
      camera_cable_unstable: 'Bylo zjištěno, že kamera nestabilně přenáší fotografie. Stále můžete spustit náhled, ale může nastat problém s pomalým náhledem či vypršením časového limitu.',
      continue_preview: 'Pokračovat',
      fail_to_transmit_image: '#845 Něco se nepodařilo s přenosem obrázku.Restartujte prosím svůj přístroj nebo Beam Studio. Pokud bude tato chyba přetrvávat, přečtěte si prosím <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">tento návod</a>.',
      ws_closed_unexpectly: '#844 Nečekaně bylo ukončeno spojení s kamerou přístroje. Pokud bude tato chyba přetrvávat, přečtěte si prosím <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">tento návod</a>.',
    },
    cancelling: 'Ruším...',
    cant_connect_to_device: '#827 Nepodařilo se připojit k přístroji, zkontrolujte prosím své připojení',
    connected: 'Připojeno',
    connecting: 'Připojuji...',
    connectingCamera: 'Připojuji kameru...',
    connectingMachine: 'Připojuji %s...',
    connectionTimeout: '#805 Vypršel časový limit připojení přístroje. Zkontrolujte prosím stav své sítě a indikátor Wi-Fi vašeho přístroje.',
    device_blocked: {
      caption: 'Neautorizované sériové číslo',
      offline: 'Sériové číslo vašeho zařízení je omezeno na offline použití, protože se zdá být deaktivováno. Poskytněte sériové číslo zařízení (umístěné na zadní straně zařízení) vašemu prodejci a požádejte jej, aby kontaktoval support@flux3dp.com k aktivaci online autorizace. Pokud chcete zařízení používat offline, kontaktujte přímo support@flux3dp.com.',
      online: 'Zdá se, že sériové číslo vašeho zařízení je deaktivováno. Poskytněte sériové číslo zařízení (umístěné na zadní straně zařízení) vašemu prodejci a požádejte jej, aby kontaktoval support@flux3dp.com k aktivaci autorizace zařízení.',
    },
    device_busy: {
      caption: 'Přístroj je zaneprázdněný',
      message: 'Přístroj vykonává jinou úlohu, zkuste to prosím později. Pokud přestane fungovat, restartujte prosím přístroj.',
    },
    device_is_used: 'Přístroj je momentálně využíván, chcete zrušit současnou úlohu?',
    device_not_found: {
      caption: 'Výchozí přístroj nenalezen',
      message: '#812 Zkontrolujte prosím indikátor Wi-Fi svého přístroje',
    },
    disconnected: 'Připojení nestále, zkontrolujte prosím nastavení zařízení a zkuste to později.',
    endingLineCheckMode: 'Ukončuji mód kontroly linek...',
    endingRawMode: 'Ukončuji nezpracovaný mód...',
    enteringLineCheckMode: 'Otevírám mód kontroly linek...',
    enteringRawMode: 'Otevírám nezpracovaný mód...',
    enteringRedLaserMeasureMode: 'Vstup do režimu měření červeným laserem...',
    exitingRotaryMode: 'Ukončuji rotační mód...',
    getProbePosition: 'Získávám pozici sondy...',
    gettingLaserSpeed: 'Zjišťuji rychlost laserové hlavice...',
    homing: 'Navádím...',
    need_password: 'K připojení k přístroji je zapotřebí heslo',
    please_enter_dpi: 'Zadejte prosím jednotku svého souboru (v mm)',
    preview: {
      adjust: 'Přizpůsobit',
      adjust_height_tooltip: 'Použijte zaškrtávací políčko k povolení úprav.',
      already_performed_auto_focus: 'Již jste použili autofokus. Použít existující hodnoty?',
      auto_focus: 'Autofokus',
      auto_focus_instruction: 'Přesuňte prosím hlavici laserového modulu nad objekt a následujte instrukce k stisknutí AF pro fokus.',
      camera_preview: 'Náhled kamery',
      enter_manually: 'Vložit manuálně',
      please_enter_height: 'Zadejte prosím výšku objektu, aby kamera zachytila přesný záběr.',
    },
    promark_disconnected: '#850 Připojení zařízení bylo přerušeno, zkontrolujte stav připojení zařízení.',
    redLaserTakingReference: 'Přijímání reference...',
    retrievingCameraOffset: 'Zjišťuji ofset kamery...',
    settingLaserSpeed: 'Nastavuji rychlost laserové hlavice...',
    swiftray_disconnected: 'Nelze se připojit k backendu, opakování připojení probíhá.',
    swiftray_reconnected: 'Backend byl znovu připojen, zkuste znovu odeslat úkol.',
    time_remaining: 'Zbývající čas:',
    tryingToConenctMachine: 'Pokouším se připojit k přístroji...',
    turningOffAirPump: 'Vypínám vzdušnou pumpičku...',
    turningOffFan: 'Vypínám větrák...',
    unable_to_find_machine: 'Nepodařilo se najít přístroj',
    unable_to_start: '#830 Nepodařilo se zahájit úlohu. Zkuste to prosím znovu. Pokud se to bude opakovat, nahlaste prosím chybu:\n',
    unavailableWorkarea: '#804 Současná pracovní oblast přesahuje pracovní oblast tohoto přístroje. Zkontrolujte prosím pracovní oblast vybraného přístroje nebo vyberte pracovní oblast z Upravit > Nastavení dokumentu.',
    unknown_device: '#826 Nepodařilo se připojit k přístroji. Ujistěte se prosím, že je USB připojen k přístroji.',
    unknown_error: '#821 Aplikace se setkala s neznámou chybou, využijte prosím Pomoc > Menu > Hlášení chyby.',
    unsupport_osx_version: 'Současná verze macOS #s nemusí podporovat některé funkce. Aktualizujte prosím na macOS 11+.',
    unsupport_win_version: 'Současná verze operačního systému #s nemusí podporovat některé funkce. Aktualizujte prosím na nejaktuálnější verzi.',
    unsupported_example_file: 'Vybraný ukázkový soubor není podporován současnou pracovní oblastí.',
    uploading_fcode: 'Nahrávám FCode.',
    usb_unplugged: 'USB připojení ztraceno. Zkontrolujte prosím připojení své USB.',
    wrong_swiftray_version_message: 'Verze backendu je chybná (verze: {version}). Zkontrolujte, zda neběží více instancí Beam Studio, a zavřete přebytečné instance před opakováním.',
    wrong_swiftray_version_title: 'Nesprávná verze backendu',
  },
  monitor: {
    ask_reconnect: 'Připojení k přístroji bylo narušeno. Přejete si znovu připojit?',
    bug_report: 'Hlášení chyby',
    camera: 'Kamera',
    cancel: 'Storno',
    confirmFileDelete: 'Určitě chcete tento soubor smazat?',
    connecting: 'Připojuji, prosím vyčkejte...',
    DEVICE_ERROR: 'Něco je v nepořádku\nRestartujte prosím přístroj', // Deprecated in FW 3.3.1
    download: 'Stáhnout',
    extensionNotSupported: 'Tento formát souboru není podporován',
    fileExistContinue: 'Soubor již existuje, přejete si ho nahradit?',
    forceStop: 'Přejete si zrušit současnou úlohu?',
    go: 'Začít',
    HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Anomálie senzoru proudění vzduchu',
    HARDWARE_ERROR_BOTTOM_OPENED: '#903 Spodní část otevřena. Pro pokračování prosím uzavřete spodní část.',
    HARDWARE_ERROR_DOOR_OPENED: '#901 Pro pokračování prosím zavřete dvířka.',
    HARDWARE_ERROR_DRAWER_OPENED: '#911 Otevřená dvířka',
    HARDWARE_ERROR_FIRE_DETECTED: '#912 Anomálie senzoru plamene',
    HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Nesprávný modul detekován. Pro pokračování prosím nainstalujte správný modul.',
    HARDWARE_ERROR_HEADTYPE_NONE: '#917 Modul nedetekován. Pro pokračování se prosím ujistěte, že je modul správně nainstalován.',
    HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Neznámý modul detekován. Pro pokračování prosím nainstalujte správný modul.',
    HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Chyba Homing Pull-Off',
    HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Kritická chyba: Základní deska off-line. Kontaktujte prosím FLUX podporu.', // Deprecated in FW 3.3.1
    HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Přehřátí. Vyčkejte prosím několik minut.',
    HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Restart osy Z neúspěšný',
    HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Tiskový modul neodpovídá.',
    HARDWARE_ERROR_PROBE_SHOWED: 'Prosím, zatáhněte sondu.',
    HARDWARE_ERROR_PUMP_ERROR: '#900 Zkontrolujte prosím nádržku s vodou.',
    HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Nebyl identifikován rotační modul',
    HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Kritická chyba: Subsystém neodpovídá. Kontaktujte prosím FLUX podporu.', // Deprecated in FW 3.3.1
    HARDWARE_FAILURE: 'Něco je v nepořádku\nRestartujte prosím přístroj', // Deprecated in FW 3.3.1
    hour: 'h',
    left: 'zbývá',
    MAINBOARD_OFFLINE: '#905 Chyba při připojování k základní desce.\nRestartujte prosím přístroj.',
    minute: 'm',
    monitor: 'MONITOR',
    NO_RESPONSE: '#905 Chyba při připojování k základní desce.\nRestartujte prosím přístroj.',
    pause: 'Pauza',
    prepareRelocate: 'Připravuji k přemístění',
    processing: 'Zpracovávám',
    record: 'Nahrát',
    relocate: 'Přemístit',
    RESOURCE_BUSY: 'Přístroj je zaneprázdněný.\nPokud nepracuje, přístroj prosím restartujte.',
    resume: 'Obnovit',
    savingPreview: 'Generuji miniatury',
    second: 's',
    start: 'Začít',
    stop: 'Zastavit',
    SUBSYSTEM_ERROR: '#402 Kritická chyba: Subsystém neodpovídá. Kontaktujte prosím FLUX podporu.',
    task: {
      BEAMBOX: 'Laserové gravírování',
      'N/A': 'Volný mód',
    },
    taskTab: 'Úloha',
    temperature: 'Teplota',
    upload: 'Nahrát',
    USER_OPERATION: 'Pro pokračování prosím následujte instrukce na panelu zařízení.',
    USER_OPERATION_CHANGE_CARTRIDGE: 'Pro pokračování prosím vložte správnou kazetu.',
    USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Pro pokračování prosím vložte kazetu.',
    USER_OPERATION_CHANGE_TOOLHEAD: 'Pro pokračování prosím nainstalujte správný modul.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Nesprávný modul detekován. Pro pokračování prosím nainstalujte správný modul.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Modul nedetekován. Pro pokračování se prosím ujistěte, že je modul správně nainstalován.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Neznámý modul detekován. Pro pokračování prosím nainstalujte správný modul.',
    USER_OPERATION_REMOVE_CARTRIDGE: 'Pro pokračování prosím odstraňte kazetu.',
    USER_OPERATION_ROTARY_PAUSE: 'Přepněte prosím na rotační motor.',
  },
  my_cloud: {
    action: {
      confirmFileDelete: 'Opravdu si přejete tento soubor smazat? Tato akce se nedá vrátit zpět.',
      delete: 'Smazat',
      download: 'Stáhnout',
      duplicate: 'Duplikovat',
      open: 'Otevřít',
      rename: 'Přejmenovat',
    },
    file_limit: 'Soubor zdarma',
    loading_file: 'Načítám...',
    no_file_subtitle: 'Klikněte na Menu > "Soubor" > "Uložit do cloudu"',
    no_file_title: 'Pro zahájení uložte soubory do Mého cloudu',
    save_file: {
      choose_action: 'Uložit soubor:',
      input_file_name: 'Vložit název souboru:',
      invalid_char: 'Neplatné znaky:',
      save: 'Uložit',
      save_new: 'Uložit jako nový soubor',
      storage_limit_exceeded: 'Úložný prostor vašeho cloudu dosáhl maximální kapacity. Před uložením nových souborů prosím smažte nepotřebné soubory.',
    },
    sort: {
      a_to_z: 'Jméno: A - Z',
      most_recent: 'Nejnovější',
      oldest: 'Nejstarší',
      z_to_a: 'Jméno: Z - A',
    },
    title: 'Můj cloud',
    upgrade: 'Upgrade',
  },
  noun_project_panel: {
    clear: 'Vyčistit',
    elements: 'Prvky',
    enjoy_shape_library: 'Užijte si databázi tvarů.',
    export_svg_title: 'Nelze exportovat SVG',
    export_svg_warning: 'Tento projekt obsahuje objekty, které jsou chráněny právem duševního vlastnictví. Beam Studio proto během exportu automaticky vyjme tyto objekty. Stále si můžete uložit svůj projekt v Beam Studio Scene (ve formátu .beam), abyste si zachovali veškerá data. Stále si přejete exportovat?',
    learn_more: 'Zjistit víc',
    login_first: 'Přihlaste se k odemčení databáze tvarů.',
    recent: 'Nedávné',
    search: 'Hledat',
    shapes: 'Tvary',
  },
  pass_through: {
    export: 'Export do pracovní oblasti',
    exporting: 'Exportuji...',
    guide_mark: 'Vodicí značka',
    guide_mark_desc: 'Vodicí značky budou gravírovány jako referenční bod pro zarovnání kresby.',
    guide_mark_length: 'Délka:',
    guide_mark_x: 'Souřadnice X:',
    height_desc: 'Nastavit výšku všech sekcí pracovní oblasti.',
    help_links: {
      ado1: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
      fbb2: 'https://support.flux3dp.com/hc/en-us/articles/11570021253263',
    },
    help_text: 'Jak nastavit průchod pro %(model)s?',
    object_length: 'Délka objektu',
    ref_layer: 'Referenční vrstva',
    ref_layer_desc: 'Pozor: Výchozí hodnota referenční vrstvy je 0. Tato hodnota nebude použita, slouží pouze jako reference pro zarovnání.',
    ref_layer_name: 'Reference',
    title: 'Režim průchodu',
    workarea_height: 'Pracovní oblast (Výška):',
  },
  promark_connection_test: {
    description: 'Systém provede přibližně dvouminutový provoz červeného světla, aby zkontroloval stabilitu připojení.',
    health: 'Stabilita:',
    healthy_description: 'Stabilita je dobrá, ale během gravírování se vyhněte výraznému pohybu počítače.',
    res_0: 'Vynikající',
    res_1: 'Dobrá',
    res_2: 'Nízká',
    res_3: 'Velmi nízká',
    res_4: 'Extrémně nízká',
    restart: 'Restartovat test',
    start: 'Spustit test',
    stop: 'Zastavit test',
    title: 'Test stability připojení',
    unhealthy_description: 'Stabilita je příliš nízká. Doporučuje se vyměnit síťový adaptér nebo napájecí kabel.',
  },
  promark_settings: {
    angle: 'Úhel',
    bulge: 'Vyboulení',
    field: 'Pole',
    galvo_configuration: 'Konfigurace Galva',
    mark: 'Označit',
    mark_parameters: 'Parametry značení',
    offsetX: 'Posun X',
    offsetY: 'Posun Y',
    preview: 'Náhled',
    red_dot: 'Červená tečka',
    scale: 'Měřítko',
    scaleX: 'Měřítko X',
    scaleY: 'Měřítko Y',
    skew: 'Sklon',
    switchXY: 'Přepnout X/Y',
    title: 'Nastavení Promark',
    trapezoid: 'Trapezoid',
    workarea_hint: 'Pracovní plochu můžete změnit v "Nastavení dokumentu".',
    z_axis_adjustment: {
      section1: 'Upravte výšku osy Z pro doladění zaostření.',
      title: 'Nastavení osy Z',
      tooltip1: 'Zkuste označit čtverec 1x1 cm pro ověření, zda je aktuální ohnisková vzdálenost vhodná.',
    },
  },
  qr_code_generator: {
    error_tolerance: 'Chyba v toleranci',
    error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
    invert: 'Obrátit barvu pozadí',
    placeholder: 'Vložit odkaz nebo text',
    preview: 'Náhled',
    title: 'Generátor QR kódu',
  },
  rotary_settings: {
    circumference: 'Obvod',
    extend_workarea: 'Rozšířit pracovní oblast',
    mirror: 'Zrcadlo',
    object_diameter: 'Průměr objektu',
    overlap_size: 'Velikost překrytí',
    rotary_scale: 'Měřítko rotace',
    split_setting: 'Nastavení dělení',
    split_setting_url: 'https://support.flux3dp.com/hc/zh-tw/articles/12276094010767',
    split_size: 'Velikost dělení',
    type: 'Typ',
  },
  select_device: {
    auth_failure: '#811 Chyba při ověřování',
    select_device: 'Vybrat přístroj',
    unable_to_connect: '#810 Nepodařilo se navázat stabilní připojení s přístrojem',
  },
  settings: {
    anti_aliasing: 'Vyhlazování',
    auto_connect: 'Automaticky vybrat jediný přístroj',
    auto_switch_tab: 'Automatický přepínač panelu vrstev a objektů',
    autofocus_offset: 'Ofset autofokusu',
    autosave_enabled: 'Automatické ukládání',
    autosave_interval: 'Ukládat každé',
    autosave_number: 'Počet automaticky uložených pozic',
    autosave_path: 'Automatické místo ukládání',
    autosave_path_not_correct: 'Zvolená cesta nenalezena.',
    blade_precut_position: 'Pozice předřezu',
    blade_precut_switch: 'Předřez ostří',
    blade_radius: 'Poloměr ostří',
    bottom_up: 'Zezdola nahoru',
    calculation_optimization: 'Zrychlení výpočtu cesty',
    cancel: 'Storno',
    caption: 'Nastavení',
    check_updates: 'Automatická kontrola',
    close: 'Zavřít',
    confirm_remove_default: 'Výchozí přístroj bude odstraněn.',
    confirm_reset: 'Potvrdit restart Beam Studia?',
    continuous_drawing: 'Souvislé kreslení',
    curve_engraving_speed_limit: 'Omezení rychlosti gravírování křivky',
    custom_preview_height: 'Vlastní výška náhledu',
    default_beambox_model: 'Výchozí nastavení dokumentu',
    default_borderless_mode: 'Výchozí otevření spodní části',
    default_enable_autofocus_module: 'Výchozí autofokus',
    default_enable_diode_module: 'Výchozí diodový laser',
    default_font_family: 'Výchozí font',
    default_font_style: 'Výchozí styl fontu',
    default_laser_module: 'Modul výchozího laseru',
    default_machine: 'Výchozí přístroj',
    default_machine_button: 'Prázdný',
    default_units: 'Výchozí jednotky',
    diode_offset: 'Ofset diodového laseru',
    diode_one_way_engraving: 'Gravírování diodového laseru jedním směrem',
    diode_two_way_warning: 'Vysílání dvousměrového světla je rychlejší a pravděpodobně vygeneruje jisté nepřesnosti v pozici laserového gravírování. Doporučuje se nejprve otestovat.',
    disabled: 'Zakázáno',
    done: 'Použít',
    enable_custom_backlash: 'Povolit vlastní kompenzaci odporu',
    enable_low_speed: 'Povolit pomalý pohyb',
    enabled: 'Povoleno',
    engraving_direction: 'Směr',
    fast_gradient: 'Optimalizace rychlosti',
    font_convert: 'Konvertor textu na cestu',
    font_substitute: 'Nahradit nepodporované znaky',
    grouped_objects: 'Skupina objektů',
    groups: {
      ador_modules: 'Moduly Ador',
      autosave: 'Automatické ukládání',
      camera: 'Kamera',
      connection: 'Připojení',
      editor: 'Editor',
      engraving: 'Rastrování (Skenování)',
      general: 'Obecné',
      mask: 'Výstřižek pracovní oblasti',
      modules: 'Doplněk',
      path: 'Vektor (Obrys)',
      privacy: 'Soukromí',
      text_to_path: 'Text',
      update: 'Softwarové aktualizace',
    },
    guess_poke: 'Hledat IP adresu přístroje',
    guides: 'Vodítka',
    guides_origin: 'Počátky vodítek',
    help_center_urls: {
      anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
      calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
      connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
      continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
      default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
      default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
      default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
      fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
      font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
      font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
      image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
      loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
      mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
      reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
      segmented_engraving: 'https://support.flux3dp.com/hc/en-us/articles/12306366019215',
      simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
      vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
    },
    high: 'Vysoká',
    image_downsampling: 'Kvalita bitmapového náhledu',
    ip: 'IP adresa přístroje',
    keep_preview_result: 'Ponechat Náhled Výsledku',
    language: 'Jazyk',
    loop_compensation: 'Kompenzace smyčky',
    low: 'Nízké',
    low_laser_for_preview: 'Laser pro rámování',
    mask: 'Výstřižek pracovní oblasti',
    medium: 'Střední',
    module_offset_2w_ir: 'Ofset 2W infračerveného laseru',
    module_offset_10w: 'Ofset 10W diodového laseru',
    module_offset_20w: 'Ofset 20W diodového laseru',
    module_offset_printer: 'Ofset tiskárny',
    none: 'Žádné',
    normal: 'Normální',
    notification_off: 'Vyp',
    notification_on: 'Zap',
    notifications: 'Oznámení na ploše',
    off: 'Vyp',
    on: 'Zap',
    preview_movement_speed: 'Rychlost pohybu v náhledu',
    printer_advanced_mode: 'Pokročilý mód tiskárny',
    remove_default_machine_button: 'Odstranit',
    reset: 'Restartovat Beam Studio',
    reset_now: 'Restartovat Beam Studio',
    segmented_engraving: 'Segmentované gravírování',
    share_with_flux: 'Analýza Share Beam Studio',
    simplify_clipper_path: 'Optimalizovat vypočítanou cestu',
    single_object: 'Jeden objekt',
    tabs: {
      device: 'Přístroj',
      general: 'Obecné',
    },
    text_path_calc_optimization: 'Optimalizace kalkulace cesty',
    top_down: 'Seshora dolů',
    trace_output: 'Výstup obkreslování obrázku',
    update_beta: 'Beta',
    update_latest: 'Poslední',
    vector_speed_constraint: 'Omezení rychlosti',
    wrong_ip_format: 'Špatné formáty IP',
  },
  social_media: {
    facebook: 'Povídejte si s uživateli FLUX, ptejte se a naučte se tipy!',
    instagram: 'Získejte nejnovější inspirace, nabídky a dárky zdarma!',
    youtube: 'Podívejte se na návody Beam Studio a nápady na tvoření.',
  },
  support: {
    no_vcredist: 'Nainstalujte prosím Visual C++ Redistributable 2015<br/> Ke stažení na flux3dp.com',
    no_webgl: 'WebGL není podporován. Použijte prosím jiná zařízení.',
  },
  topbar: {
    alerts: {
      add_content_first: 'Nejprve prosím přidejte objekty.',
      door_opened: 'Uzavřete prosím dvířka, aby mohl laser nízké intenzity zahájit rámování.',
      fail_to_connect_with_camera: '#803 Nepodařilo se navázat spojení s kamerou. Restartujte prosím své zařízení nebo Beam Studio. Pokud bude tato chyba přetrvávat, přečtěte si prosím <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">tento návod</a>.',
      fail_to_get_door_status: 'Ujistěte se prosím, že jsou dvířka uzavřená, aby mohl laser nízké intenzity zahájit rámování.',
      fail_to_start_preview: '#803 Nepodařil se spustit náhled. Restartujte prosím své zařízení nebo Beam Studio. Pokud bude tato chyba přetrvávat, přečtěte si prosím <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">tento návod</a>.',
      headtype_mismatch: 'Nesprávný modul detekován.',
      headtype_none: 'Žádný modul nedetekován.',
      headtype_unknown: 'Neznámý modul detekován.',
      install_correct_headtype: 'Nainstalujte prosím správně moduly laserových diod 10W/20W, aby mohl laser nízké intenzity zahájit rámování.',
      job_origin_unavailable: 'Proces nastavení vodítek požaduje firmware verze 4.3.4 / 5.3.4 nebo vyšší. Chcete nyní aktualizovat firmware?',
      job_origin_warning: 'Momentálně používáte jako výchozí bod „aktuální pozici“. Ujistěte se, že je laserová hlava přesunuta do správné polohy, aby nedošlo ke kolizím.',
      power_too_high: 'PŘÍLIŠ VYSOKÁ INTENZITA',
      power_too_high_confirm: 'ROZUMÍM',
      power_too_high_msg: 'Využívání nižší intenzity laseru (pod 70%) prodlouží životnost laserové trubice.\nStiskněte "ROZUMÍM" pro pokračování.',
      pwm_unavailable: 'Depth mode (režim hloubky) vyžaduje firmware verze 4.3.4 / 5.3.4 nebo vyšší. Chcete nyní aktualizovat firmware?',
      QcleanScene: 'Chcete vymazat kresbu?<br/> Smaže se tím i historie úprav!',
      start_preview_timeout: '#803 Během spuštění náhledu došlo k přerušení. Restartujte prosím své zařízení nebo Beam Studio. Pokud bude tato chyba přetrvávat, přečtěte si prosím <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">tento návod</a>.',
    },
    frame_task: 'Rámování',
    hint: {
      polygon: 'Pro zvyšování / snižování stran využívejte klávesy + / -.',
    },
    menu: {
      about: 'O Beam Studio',
      about_beam_studio: 'O Beam Studio',
      account: 'Účet',
      add_new_machine: 'Nastavení přístroje',
      align_center: 'Zarovnat na střed',
      anti_aliasing: 'Vyhlazení',
      auto_align: 'Automatické Zarovnání',
      borderless_mode: 'Mód bez okrajů',
      bug_report: 'Nahlásit chybu',
      calibrate_beambox_camera: 'Kalibrovat kameru',
      calibrate_beambox_camera_borderless: 'Kalibrovat kameru (otevřená spodní část)',
      calibrate_camera_advanced: 'Kalibrace kamery (Pokročilé)',
      calibrate_diode_module: 'Kalibrovat modul laserové diody',
      calibrate_ir_module: 'Kalibrovat infračervený modul',
      calibrate_printer_module: 'Kalibrovat tiskový modul',
      calibration: 'Kalibrace',
      camera_calibration_data: 'Kalibrační data fotoaparátu',
      change_logs: 'Změnit záznamy',
      clear_scene: 'Nový',
      close: 'Zavřít okno',
      commands: 'Příkazy',
      contact: 'Kontaktujte nás',
      copy: 'Kopírovat',
      cut: 'Vyjmout',
      dashboard: 'Hlavní panel',
      decompose_path: 'Rozložit',
      delete: 'Smazat',
      design_market: 'Design Market',
      dev_tool: 'Debug nástroj',
      disassemble_use: 'Rozpojit',
      document_setting: 'Nastavení dokumentu',
      document_setting_short: 'Dokument',
      download_data: 'Stáhnout data',
      download_log: 'Záznamy stažení',
      download_log_canceled: 'Stahování záznamů zrušeno',
      download_log_error: 'Nastala neznámá chyba, zkuste to prosím později',
      duplicate: 'Duplikovat',
      edit: 'Úpravy',
      example_files: 'Příkladové soubory',
      export_BVG: 'BVG',
      export_flux_task: 'Úloha FLUX',
      export_JPG: 'JPG',
      export_PNG: 'PNG',
      export_SVG: 'SVG',
      export_to: 'Exportovat do...',
      file: 'Soubor',
      fit_to_window: 'Přizpůsobit na velikost okna',
      follow_us: 'Sledujte nás',
      forum: 'Komunitní fórum',
      group: 'Sloučit do skupiny',
      help: 'Pomoc',
      help_center: 'Centrum nápovědy',
      hide: 'Skrýt Beam Studio',
      hideothers: 'Skrýt ostatní',
      image_crop: 'Oříznout',
      image_curve: 'Křivka',
      image_invert: 'Invertovat',
      image_sharpen: 'Zaostřit',
      image_stamp: 'Zkosit',
      image_vectorize: 'Obkreslit',
      import_acrylic_focus_probe: 'Akrylový focus probe - 3mm',
      import_ador_laser_example: 'Příklad Ador Laseru',
      import_ador_printing_example_full: 'Příklad Ador tisknutí - barevné',
      import_ador_printing_example_single: 'Příklad Ador tisknutí - jednobarevné',
      import_beambox_2_example: 'Příklad Beambox II',
      import_beambox_2_focus_probe: 'Beambox II Fokusová sonda - 3 mm',
      import_hello_beambox: 'Příklady Beambox',
      import_hello_beamo: 'Příklady beamo',
      import_hexa_example: 'Příklad HEXA',
      import_material_printing_test: 'Test tisknutí materiálu',
      import_material_testing_cut: 'Test řezání materiálu',
      import_material_testing_engrave: 'Test gravírování materiálu',
      import_material_testing_line: 'Test linkování materiálu',
      import_material_testing_old: 'Test gravírování materiálu - Klasický',
      import_material_testing_simple_cut: 'Test řezání materiálu - Jednoduchý',
      import_promark_example: 'Příklad Promarku',
      import_promark_mopa_20w_color: 'MOPA 20W Test Barev',
      import_promark_mopa_60w_color: 'MOPA 60W Test Barev',
      import_promark_mopa_100w_color: 'MOPA 100W Test Barev',
      keyboard_shortcuts: 'Klávesové zkratky',
      layer_color_config: 'Nastavení barev',
      layer_setting: 'Vrstva',
      link: {
        beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
        contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
        design_market: 'https://dmkt.io',
        downloads: 'https://flux3dp.com/downloads/',
        forum: 'https://www.facebook.com/groups/flux.laser/',
        help_center: 'https://helpcenter.flux3dp.com/',
        shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
      },
      log: {
        camera: 'Kamera',
        cloud: 'Cloud',
        discover: 'Objevit',
        hardware: 'Hardware',
        network: 'Síť',
        player: 'Přehrávač',
        robot: 'Robot',
        usb: 'USB',
        usblist: 'Seznam USB',
      },
      machine_info: 'Informace o přístroji',
      machines: 'Přístroje',
      manage_account: 'Nastavit Můj účet',
      material_test: 'Test materiálu',
      minimize: 'Minimalizovat',
      my_account: 'Můj účet',
      network_testing: 'Testovat nastavení sítě',
      object: 'Objekt',
      offset: 'Ofset',
      open: 'Otevřít',
      paste: 'Vložit',
      paste_in_place: 'Vložit na místo',
      path: 'Cesta',
      photo_edit: 'Obrázek',
      preferences: 'Vlastnosti',
      promark_color_test: 'Promark Test Barev',
      questionnaire: 'Dotazník se zpětnou vazbou',
      quit: 'Ukončit',
      recent: 'Otevřít poslední',
      redo: 'Znovu',
      reload_app: 'Znovu načíst aplikaci',
      reset: 'Restartovat',
      rotary_setup: 'Nastavení rotačního zařízení',
      rotate: 'Otočit',
      samples: 'Příklady',
      save_as: 'Uložit jako...',
      save_scene: 'Uložit',
      save_to_cloud: 'Uložit do cloudu',
      scale: 'Škálovat',
      service: 'Služby',
      set_as_default: 'Nastavit jako výchozí',
      show_gesture_tutorial: 'Návod k dotykovým gestům',
      show_grids: 'Zobrazit mřížky',
      show_layer_color: 'Použít barvu vrstvy',
      show_rulers: 'Zobrazit pravítka',
      show_start_tutorial: 'Ukázat Úvodní výuku',
      show_ui_intro: 'Ukázat Úvod do uživatelského rozhraní',
      sign_in: 'Přihlásit',
      sign_out: 'Odhlásit',
      software_update: 'Aktualizace softwaru',
      svg_edit: 'SVG',
      switch_to_beta: 'Přepnout na beta verzi',
      switch_to_latest: 'Přepnout na stabilní verzi',
      tools: {
        box_generator: 'Generátor boxů',
        code_generator: 'Generátor kódu',
        material_test_generator: 'Generátor testování materiálu',
        title: 'Nástroje',
      },
      tutorial: 'Zahájit výuku k Delta Family Printing',
      undo: 'Zpět',
      ungroup: 'Rozdělit skupinu',
      update: 'Kontrola aktualizací',
      update_firmware: 'Aktualizovat firmware',
      upload_data: 'Nahrát data',
      using_beam_studio_api: 'Využívání Beam Studio API',
      view: 'Zobrazit',
      window: 'Okno',
      zoom_in: 'Přiblížit',
      zoom_out: 'Oddálit',
      zoom_with_window: 'Automaticky přizpůsobit na velikost okna',
    },
    preview: 'NÁHLED',
    preview_press_esc_to_stop: 'Stisknutím ESC zastavíte náhled kamery.',
    rename_tab: 'Přejmenovat kartu',
    select_machine: 'Vybrat přístroj',
    tag_names: {
      dxf: 'DXF objekt',
      ellipse: 'Ovál',
      g: 'Skupina',
      image: 'Obrázek',
      line: 'Linka',
      multi_select: 'Několik objektů',
      no_selection: 'Žádný výběr',
      pass_through_object: 'Procházet objektem',
      path: 'Cesta',
      polygon: 'Polygon',
      rect: 'Obdélník',
      svg: 'SVG objekt',
      text: 'Text',
      text_path: 'Text na cestě',
      use: 'Importovaný objekt',
    },
    task_preview: 'Náhled cesty',
    titles: {
      settings: 'Vlastnosti',
    },
    untitled: 'Bez názvu',
  },
  topmenu: {
    credit: 'Beam Studio bylo vytvořeno díky open source projektu <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> a dalšímu <a target="_blank" href="https://flux3dp.com/credits/">open source softwaru</a>.',
    device: {
      download_log_canceled: 'Stažení záznamu zrušeno.',
      download_log_error: 'Nastala neznámá chyba, zkuste to prosím znovu později.',
      log: {
        usblist: 'Seznam USB',
      },
      network_test: 'Otestovat síť',
    },
    file: {
      all_files: 'Všechny soubory',
      clear_recent: 'Vyčistit nedávno otevřené',
      converting: 'Konvertovat na Obrázek...',
      fcode_files: 'FLUX kód',
      import: 'Importovat',
      jpg_files: 'JPG',
      label: 'Soubor',
      path_not_exit: 'Zdá se, že tato cesta už na disku neexistuje.',
      png_files: 'PNG',
      save_fcode: 'Exportovat FLUX úlohu',
      save_jpg: 'Exportovat JPG',
      save_png: 'Exportovat PNG',
      save_scene: 'Uložit scénu',
      save_svg: 'Exportovat SVG',
      scene_files: 'Plocha Beam Studio',
      svg_files: 'SVG',
    },
    ok: 'OK',
    version: 'Verze',
  },
  tutorial: {
    ask_retry_calibration: 'Přejete si znovu kalibrovat kameru?',
    camera_calibration_failed: 'Kalibrace kamery selhala',
    gesture: {
      click: 'Poklepejte k vybrání objektu.',
      drag: 'Přetáhněte k vybrání několika objektů.',
      hold: 'Stiskněte a držte k otevření kontextového menu.',
      pan: 'Posouvejte plátno dvěma prsty.',
      zoom: 'Udělejte "špetku" dvěma prsty k přiblížení a oddálení plátna.',
    },
    links: {
      adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
    },
    look_for_machine: 'Hledám přístroj pro výuku...',
    needNewInterfaceTutorial: 'Přejete si nyní spustit výuku pro nové rozhraní Beam Studio?<br/>(Můžete ji nyní přeskočit a později spustit přes "Pomoc" > "Úvod do uživatelského rozhraní".)',
    needNewUserTutorial: 'Přejete si spustit výuku?<br/>(Můžete ji nyní přeskočit a později spustit přes "Pomoc" > "Ukázat Úvodní výuku".)',
    newInterface: {
      add_new_layer: 'Přidat novou vrstvu',
      align_controls: 'Ovládací prvky zarovnání',
      basic_shapes: 'Základní tvary',
      camera_preview: 'Náhled kamery',
      drag_to_sort: 'Táhnout k seřazení',
      end_alert: 'Opravdu si přejete ukončit představení nového uživatelského rozhrání?',
      flip: 'Převrátit',
      group_controls: 'Ovládací prvky skupin',
      layer_controls: 'Pravé tlačítko k výběru Ovládání vrstev:\nDuplikovat / Sloučit / Zamknout / Smazat vrstvy',
      object_actions: 'Akce objektů',
      pen_tool: 'Nástroj propiska',
      rename_by_double_click: 'Přejmenovat dvojitým kliknutím',
      select_image_text: 'Výběr / Obrázek / Text',
      select_machine: 'Vyberte přístroj',
      shape_operation: 'Práce s tvary',
      start_work: 'Zahájit práci',
      switch_between_layer_panel_and_object_panel: 'Přepínání mezi Panelem vrstev a Panelem objektů',
    },
    newUser: {
      add_new_layer: 'Přidat novou vrstvu',
      adjust_focus: '2. Přizpůsobit fokus',
      close_cover: '3. Zavřít dvířka',
      drag_to_draw: 'Táhnout k nakreslení',
      draw_a_circle: 'Nakreslit kruh',
      draw_a_rect: 'Nakreslit obdélník',
      end_alert: 'Jste si jistý, že chcete ukončit výuku?',
      end_preview_mode: 'Ukončit mód náhledu',
      infill: 'Zapnout výplň',
      please_select_wood_cutting: 'Vyberte prosím předvolbu "Dřevo - řez".',
      please_select_wood_engraving: 'Vyberte prosím předvolbu "Dřevo - gravírování".',
      preview_the_platform: 'Náhled platformy',
      put_wood: '1. Vložte vzorek dřeva',
      send_the_file: 'Poslat soubor',
      set_preset_wood_cut: 'Nastavit předvolbu: Dřevo - řez',
      set_preset_wood_engraving: 'Nastavit předvolbu: Dřevo - gravírování',
      switch_to_layer_panel: 'Přepnout na panel vrstev',
      switch_to_object_panel: 'Přepnout na panel objektů',
      switch_to_preview_mode: 'Přepnout na mód náhledu',
    },
    next: 'DALŠÍ',
    retry: 'Opakovat',
    set_connection: 'Nastavit připojení',
    skip: 'Přeskočit',
    skip_tutorial: 'Přeskočil jste výuku. Můžete ji kdykoliv spustit přes "Pomoc" > "Ukázat Úvodní výuku"',
    suggest_calibrate_camera_first: 'Doporučujeme uživatelům před každým náhledem nejprve kalibrovat kameru a znovu provést fokus pro optimální výsledky.<br/>Potvrďte prosím, že si nyní přejete kalibrovat.<br/>(Můžete toto přeskočit a později udělat přes "Menu" > "Přístroj" > [Váš přístroj] > "Kalibrovat kameru".)',
    tutorial_complete: 'To je z výuky vše. Nyní je čas tvořit!',
    unable_to_find_machine: 'Nepodařilo se najít přístroj pro Výuku. Přejete si jít do nastavení připojení, opakovat nebo výuku přeskočit?',
    welcome: 'VÍTEJTE',
  },
  update: {
    cannot_reach_internet: '#823 Server není dosažitelný<br/> Zkontrolujte prosím internetové připojení',
    download: 'ONLINE AKTUALIZACE',
    firmware: {
      caption: 'Aktualizace firmwaru k tomuto přístroji je k dispozici',
      confirm: 'UPLOAD',
      firmware_too_old_update_by_sdcard: 'Verze firmwaru je příliš stará. Aktualizujte prosím firmware SD kartou.',
      force_update_message: '#814 Aktualizujte prosím svůj přístroj na nejaktuálnější verzi firmwaru.',
      latest_firmware: {
        cant_get_latest: 'Nepodařilo se získat informace k nejaktuálnějšímu firmwaru.',
        caption: 'Aktualizace firmwaru přístroje.',
        message: 'Máte nejaktuálnější firmware přístroje.',
        still_update: 'AKTUALIZACE',
      },
      message_pattern_1: '"%s" je nyní připraven k aktualizaci firmwaru.',
      message_pattern_2: '%s firmware v%s je nyní k dispozici - Máte v%s.',
      too_old_for_web: 'Současná verze firmwaru vašeho přístroje je v%s.\nPokud chcete použít online verzi Beam Studia, aktualizujte prosím firmware přístroje na nejaktuálnější verzi.',
      update_fail: '#822 Selhání při aktualizaci',
      update_success: 'Aktualizace firmwaru úspěšně nahrána',
      upload_file: 'Upload firmwaru (*.bin / *.fxfw)',
    },
    install: 'INSTALOVAT',
    later: 'POZDĚJI',
    preparing: 'Připravuji...',
    release_note: 'Poznámka k verzi:',
    skip: 'Přeskočit tuto verzi',
    software: {
      available_switch: 'Beam Studio v%s je nyní k dispozici. Máte v%s. Chcete přejít na tuto verzi?',
      available_update: 'Beam Studio v%s je nyní k dispozici. Máte v%s. Chcete stáhnout aktualizaci?',
      caption: 'Aktualizace softwaru pro Beam Studio je k dispozici.',
      check_update: 'Kontrola aktualizace',
      checking: 'Kontroluji aktualizace',
      downloading: 'V pozadí se stahují aktualizace, kliknutím na tlačítko "OK" můžete pokračovat ve své práci.',
      install_or_not: 'je připraven k aktualizaci. Chcete nyní restartovat a aktualizovat?',
      no: 'Ne',
      no_response: 'Nepodařilo se připojit k serveru, zkontrolujte prosím nastavení sítě.',
      not_found: 'Používáte nejaktuálnější verzi Beam Studio.',
      switch_or_not: 'je připraven k výměně. Chcete nyní restartovat a vyměnit?',
      switch_version: 'Změnit verzi',
      switch_version_not_found: 'Vyměnitelná verze nenalezena.',
      update_for_ador: 'Současná verze softwaru %s není kompatibilní, stáhněte prosím nejaktuálnější verzi Beam Studia pro Ador.',
      yes: 'Ano',
    },
    update: 'Aktualizace',
    updating: 'Aktualizuji...',
    upload: 'NAHRÁT',
  },
  web_cam: {
    no_device: 'Nelze detekovat kamerové zařízení. Znovu připojte kameru a zkuste to znovu.',
    no_permission: 'Beam Studio nemá oprávnění k přístupu k kameře. Ujistěte se, že je v nastavení prohlížeče nebo systému uděleno oprávnění pro Beam Studio.',
  },
  z_speed_limit_test: {
    alert_before: 'Před zahájením rytí křivky systém provede zátěžový test, aby zabránil ztrátě kroků v důsledku nadměrné hmotnosti.',
    alert_failed: 'Hmotnost aktuálního objektu překračuje limit zatížení pro aktuální rychlost osy Z. Snižte prosím rychlost osy Z na ovládacím panelu stroje nebo vyměňte gravírovaný objekt před opětovným testováním.',
    ignore: 'Ignorovat',
    retest: 'Otestovat znovu',
    testing: 'Provádí se zátěžový test...',
  },
};

export default lang;
