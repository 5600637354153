// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigValueDisplay-module__value--YylGS{float:right;line-height:32px;font-size:12px}._-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigValueDisplay-module__panel-item--Eu7Qj{display:inline;margin-right:12px}._-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigValueDisplay-module__input--TpiJA{width:80px;float:right}._-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigValueDisplay-module__input--TpiJA .ant-input-number-input{padding:5px 24px 5px 4px}._-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigValueDisplay-module__input--TpiJA .ant-input-number-suffix{font-size:9px;color:#bbb}._-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigValueDisplay-module__input--TpiJA ._-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigValueDisplay-module__unit--03Mry{font-size:9px}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/views/beambox/Right-Panels/ConfigPanel/ConfigValueDisplay.module.scss"],"names":[],"mappings":"AAAA,8GACE,WAAA,CACA,gBAAA,CACA,cAAA,CAGF,mHACE,cAAA,CACA,iBAAA,CAGF,8GACE,UAAA,CACA,WAAA,CAEA,sIACE,wBAAA,CAGF,uIACE,aAAA,CACA,UAAA,CAGF,2NACE,aAAA","sourcesContent":[".value {\n  float: right;\n  line-height: 32px;\n  font-size: 12px;\n}\n\n.panel-item {\n  display: inline;\n  margin-right: 12px;\n}\n\n.input {\n  width: 80px;\n  float: right;\n\n  :global(.ant-input-number-input) {\n    padding: 5px 24px 5px 4px;\n  }\n\n  :global(.ant-input-number-suffix) {\n    font-size: 9px;\n    color: #bbbbbb;\n  }\n\n  .unit {\n    font-size: 9px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"value": "_-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigValueDisplay-module__value--YylGS",
	"panel-item": "_-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigValueDisplay-module__panel-item--Eu7Qj",
	"input": "_-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigValueDisplay-module__input--TpiJA",
	"unit": "_-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigValueDisplay-module__unit--03Mry"
};
export default ___CSS_LOADER_EXPORT___;
