// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-boxgen-BoxSelector-module__selector--0Obb\\+{flex:1;border-bottom:0}@media screen and (max-width: 600px){._-_-packages-core-src-web-app-components-boxgen-BoxSelector-module__selector--0Obb\\+{width:250px;flex:none}}._-_-packages-core-src-web-app-components-boxgen-BoxSelector-module__container--jZg5I{display:flex;justify-content:center}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/boxgen/BoxSelector.module.scss"],"names":[],"mappings":"AAAA,sFACE,MAAA,CACA,eAAA,CACA,qCAHF,sFAII,WAAA,CACA,SAAA,CAAA,CAIJ,sFACE,YAAA,CACA,sBAAA","sourcesContent":[".selector {\n  flex: 1;\n  border-bottom: 0;\n  @media screen and (max-width: 600px) {\n    width: 250px;\n    flex: none;\n  }\n}\n\n.container {\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selector": "_-_-packages-core-src-web-app-components-boxgen-BoxSelector-module__selector--0Obb+",
	"container": "_-_-packages-core-src-web-app-components-boxgen-BoxSelector-module__container--jZg5I"
};
export default ___CSS_LOADER_EXPORT___;
