// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-widgets-FullWindowPanel-Header-module__header--64RpL{display:flex;align-items:center;border-bottom:1px solid #ddd;padding-left:40px;padding-right:20px}._-_-packages-core-src-web-app-widgets-FullWindowPanel-Header-module__header--64RpL ._-_-packages-core-src-web-app-widgets-FullWindowPanel-Header-module__icon--bgyC4{color:#000}._-_-packages-core-src-web-app-widgets-FullWindowPanel-Header-module__header--64RpL ._-_-packages-core-src-web-app-widgets-FullWindowPanel-Header-module__title--l7Cvj{font-size:20px;font-weight:700;line-height:46px}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/widgets/FullWindowPanel/Header.module.scss"],"names":[],"mappings":"AAAA,oFACE,YAAA,CACA,kBAAA,CACA,4BAAA,CACA,iBAAA,CACA,kBAAA,CAEA,sKACE,UAAA,CAGF,uKACE,cAAA,CACA,eAAA,CACA,gBAAA","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid #ddd;\n  padding-left: 40px;\n  padding-right: 20px;\n\n  .icon {\n    color: #000000;\n  }\n\n  .title {\n    font-size: 20px;\n    font-weight: 700;\n    line-height: 46px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "_-_-packages-core-src-web-app-widgets-FullWindowPanel-Header-module__header--64RpL",
	"icon": "_-_-packages-core-src-web-app-widgets-FullWindowPanel-Header-module__icon--bgyC4",
	"title": "_-_-packages-core-src-web-app-widgets-FullWindowPanel-Header-module__title--l7Cvj"
};
export default ___CSS_LOADER_EXPORT___;
