// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-dialogs-myCloud-Head-module__head--866KK{display:flex;position:absolute;top:20px;right:40px}._-_-packages-core-src-web-app-components-dialogs-myCloud-Head-module__head--866KK ._-_-packages-core-src-web-app-components-dialogs-myCloud-Head-module__select--JqsXj .ant-select-selection-item{color:#1890ff}._-_-packages-core-src-web-app-components-dialogs-myCloud-Head-module__select-dropdown--ExEil .ant-select-item-option-selected>div{color:#1890ff}._-_-packages-core-src-web-app-components-dialogs-myCloud-Head-module__tabs--GxMqQ>div{border-bottom:0}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/dialogs/myCloud/Head.module.scss","webpack://./../../packages/core/src/web/styles/_variables.scss"],"names":[],"mappings":"AAEA,mFACE,YAAA,CACA,iBAAA,CACA,QAAA,CACA,UAAA,CAEE,mMACE,aAAA,CAKN,mIACE,aCNa,CDSf,uFACE,eAAA","sourcesContent":["@use '@core/styles/variables' as variables;\n\n.head {\n  display: flex;\n  position: absolute;\n  top: 20px;\n  right: 40px;\n  .select {\n    :global(.ant-select-selection-item) {\n      color: variables.$primary-blue;\n    }\n  }\n}\n\n.select-dropdown :global(.ant-select-item-option-selected) > div {\n  color: variables.$primary-blue;\n}\n\n.tabs > div {\n  border-bottom: 0;\n}\n","$print-default-font-color: #4a4a4a;\n$default-button-border-radius: 4px;\n$size: 200px;\n$backgroundColor: #f0f0f0;\n$sidePanelWidth: 242px;\n$winSidePanelWidth: 258px;\n$panelBorderColor: #e0e0e0;\n$topBarHeight: 40px;\n\n$primary-blue: #1890ff;\n$primary-gray: #1e1e1e;\n$secondary-gray: #7c7c7c;\n\n$status-normal: #4fbb30;\n$status-warning: #faa22d;\n$status-critical: #fe4348;\n$s3: 'https://beam-studio-web.s3.ap-northeast-1.amazonaws.com';\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"head": "_-_-packages-core-src-web-app-components-dialogs-myCloud-Head-module__head--866KK",
	"select": "_-_-packages-core-src-web-app-components-dialogs-myCloud-Head-module__select--JqsXj",
	"select-dropdown": "_-_-packages-core-src-web-app-components-dialogs-myCloud-Head-module__select-dropdown--ExEil",
	"tabs": "_-_-packages-core-src-web-app-components-dialogs-myCloud-Head-module__tabs--GxMqQ"
};
export default ___CSS_LOADER_EXPORT___;
