// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-widgets-FullWindowPanel-Footer-module__footer--axd6G{display:flex;justify-content:flex-end}._-_-packages-core-src-web-app-widgets-FullWindowPanel-Footer-module__footer--axd6G._-_-packages-core-src-web-app-widgets-FullWindowPanel-Footer-module__mobile--aQ7iG{padding:0 12px 12px 12px}._-_-packages-core-src-web-app-widgets-FullWindowPanel-Footer-module__footer--axd6G:not(._-_-packages-core-src-web-app-widgets-FullWindowPanel-Footer-module__mobile--aQ7iG){padding:12px 24px;border-top:1px solid #ddd}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/widgets/FullWindowPanel/Footer.module.scss"],"names":[],"mappings":"AAAA,oFACE,YAAA,CACA,wBAAA,CAEA,uKACE,wBAAA,CAGF,6KACE,iBAAA,CACA,yBAAA","sourcesContent":[".footer {\n  display: flex;\n  justify-content: flex-end;\n\n  &.mobile {\n    padding: 0 12px 12px 12px;\n  }\n\n  &:not(.mobile) {\n    padding: 12px 24px;\n    border-top: 1px solid #ddd;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "_-_-packages-core-src-web-app-widgets-FullWindowPanel-Footer-module__footer--axd6G",
	"mobile": "_-_-packages-core-src-web-app-widgets-FullWindowPanel-Footer-module__mobile--aQ7iG"
};
export default ___CSS_LOADER_EXPORT___;
