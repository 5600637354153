// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-widgets-UnitInput-module__input--APYvI{position:relative}._-_-packages-core-src-web-app-widgets-UnitInput-module__input--APYvI._-_-packages-core-src-web-app-widgets-UnitInput-module__underline--ICmhx{border:1px solid #e6e6e6;border-width:0 0 1px}._-_-packages-core-src-web-app-widgets-UnitInput-module__unit--0aWAM{position:absolute;right:0;top:calc(50% - 6px);font-size:12px;color:#bbb}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/widgets/UnitInput.module.scss"],"names":[],"mappings":"AAAA,sEACE,iBAAA,CAEA,+IACE,wBAAA,CACA,oBAAA,CAIJ,qEACE,iBAAA,CACA,OAAA,CACA,mBAAA,CACA,cAAA,CACA,UAAA","sourcesContent":[".input {\n  position: relative;\n\n  &.underline {\n    border: 1px solid #e6e6e6;\n    border-width: 0 0 1px;\n  }\n}\n\n.unit {\n  position: absolute;\n  right: 0;\n  top: calc(50% - 6px);\n  font-size: 12px;\n  color: #bbbbbb;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "_-_-packages-core-src-web-app-widgets-UnitInput-module__input--APYvI",
	"underline": "_-_-packages-core-src-web-app-widgets-UnitInput-module__underline--ICmhx",
	"unit": "_-_-packages-core-src-web-app-widgets-UnitInput-module__unit--0aWAM"
};
export default ___CSS_LOADER_EXPORT___;
