// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-views-beambox-ImageTracePanel-StepTune-module__title--C-8\\+7{color:#626262;font-size:18px;font-weight:500;letter-spacing:.5px}._-_-packages-core-src-web-app-views-beambox-ImageTracePanel-StepTune-module__subtitle--bbmKF{margin:4px}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/views/beambox/ImageTracePanel/StepTune.module.scss"],"names":[],"mappings":"AAAA,4FACE,aAAA,CACA,cAAA,CACA,eAAA,CACA,mBAAA,CAGF,8FACE,UAAA","sourcesContent":[".title {\n  color: #626262;\n  font-size: 18px;\n  font-weight: 500;\n  letter-spacing: 0.5px;\n}\n\n.subtitle {\n  margin: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "_-_-packages-core-src-web-app-views-beambox-ImageTracePanel-StepTune-module__title--C-8+7",
	"subtitle": "_-_-packages-core-src-web-app-views-beambox-ImageTracePanel-StepTune-module__subtitle--bbmKF"
};
export default ___CSS_LOADER_EXPORT___;
