// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-settings-Settings-module__subtitle--lD2Vn{line-height:40px;font-size:20px;color:#333;border-bottom:1px #ccc solid;margin-top:15px;margin-bottom:15px;width:400px}._-_-packages-core-src-web-app-components-settings-Settings-module__form-item--xQgj5{font-size:13px;margin-bottom:0;line-break:auto;width:30%}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/settings/Settings.module.scss"],"names":[],"mappings":"AAAA,oFACE,gBAAA,CACA,cAAA,CACA,UAAA,CACA,4BAAA,CACA,eAAA,CACA,kBAAA,CACA,WAAA,CAGF,qFACE,cAAA,CACA,eAAA,CACA,eAAA,CACA,SAAA","sourcesContent":[".subtitle {\n  line-height: 40px;\n  font-size: 20px;\n  color: #333;\n  border-bottom: 1px #ccc solid;\n  margin-top: 15px;\n  margin-bottom: 15px;\n  width: 400px;\n}\n\n.form-item {\n  font-size: 13px;\n  margin-bottom: 0;\n  line-break: auto;\n  width: calc(30%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtitle": "_-_-packages-core-src-web-app-components-settings-Settings-module__subtitle--lD2Vn",
	"form-item": "_-_-packages-core-src-web-app-components-settings-Settings-module__form-item--xQgj5"
};
export default ___CSS_LOADER_EXPORT___;
