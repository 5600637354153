// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-ImageEditPanel-components-TopBar-index-module__top-bar--IoXyE{height:54px;padding:12px 24px}._-_-packages-core-src-web-app-components-ImageEditPanel-components-TopBar-index-module__w-100--zGUBA{width:100%}._-_-packages-core-src-web-app-components-ImageEditPanel-components-TopBar-index-module__mr-8px--g5EnM{margin-right:8px}._-_-packages-core-src-web-app-components-ImageEditPanel-components-TopBar-index-module__lh-32px--evm1Z{line-height:32px}._-_-packages-core-src-web-app-components-ImageEditPanel-components-TopBar-index-module__dp-flex--Eb89W{display:flex}._-_-packages-core-src-web-app-components-ImageEditPanel-components-TopBar-index-module__bdb--hP0sO{border-bottom:1px solid #ddd}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/ImageEditPanel/components/TopBar/index.module.scss"],"names":[],"mappings":"AAAA,wGACE,WAAA,CACA,iBAAA,CAGF,sGACE,UAAA,CAGF,uGACE,gBAAA,CAGF,wGACE,gBAAA,CAGF,wGACE,YAAA,CAGF,oGACE,4BAAA","sourcesContent":[".top-bar {\n  height: 54px;\n  padding: 12px 24px;\n}\n\n.w-100 {\n  width: 100%;\n}\n\n.mr-8px {\n  margin-right: 8px;\n}\n\n.lh-32px {\n  line-height: 32px;\n}\n\n.dp-flex {\n  display: flex;\n}\n\n.bdb {\n  border-bottom: 1px solid #ddd;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top-bar": "_-_-packages-core-src-web-app-components-ImageEditPanel-components-TopBar-index-module__top-bar--IoXyE",
	"w-100": "_-_-packages-core-src-web-app-components-ImageEditPanel-components-TopBar-index-module__w-100--zGUBA",
	"mr-8px": "_-_-packages-core-src-web-app-components-ImageEditPanel-components-TopBar-index-module__mr-8px--g5EnM",
	"lh-32px": "_-_-packages-core-src-web-app-components-ImageEditPanel-components-TopBar-index-module__lh-32px--evm1Z",
	"dp-flex": "_-_-packages-core-src-web-app-components-ImageEditPanel-components-TopBar-index-module__dp-flex--Eb89W",
	"bdb": "_-_-packages-core-src-web-app-components-ImageEditPanel-components-TopBar-index-module__bdb--hP0sO"
};
export default ___CSS_LOADER_EXPORT___;
