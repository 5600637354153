// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-actions-canvas-grid-module__container--EKy1W{pointer-events:none}._-_-packages-core-src-web-app-actions-canvas-grid-module__container--EKy1W ._-_-packages-core-src-web-app-actions-canvas-grid-module__x--mlipm>line{stroke:#000;opacity:.18;stroke-width:1;vector-effect:non-scaling-stroke}._-_-packages-core-src-web-app-actions-canvas-grid-module__container--EKy1W ._-_-packages-core-src-web-app-actions-canvas-grid-module__x--mlipm>line:nth-child(10n+1){opacity:.5}._-_-packages-core-src-web-app-actions-canvas-grid-module__container--EKy1W ._-_-packages-core-src-web-app-actions-canvas-grid-module__y--U-gj0>line{stroke:#000;opacity:.18;stroke-width:1;vector-effect:non-scaling-stroke}._-_-packages-core-src-web-app-actions-canvas-grid-module__container--EKy1W ._-_-packages-core-src-web-app-actions-canvas-grid-module__y--U-gj0>line:nth-child(10n+1){opacity:.5}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/actions/canvas/grid.module.scss"],"names":[],"mappings":"AAAA,4EACE,mBAAA,CAGE,qJACE,WAAA,CACA,WAAA,CACA,cAAA,CACA,gCAAA,CAEA,sKACE,UAAA,CAMJ,qJACE,WAAA,CACA,WAAA,CACA,cAAA,CACA,gCAAA,CAEA,sKACE,UAAA","sourcesContent":[".container {\n  pointer-events: none;\n\n  .x {\n    > line {\n      stroke: black;\n      opacity: 0.18;\n      stroke-width: 1;\n      vector-effect: non-scaling-stroke;\n\n      &:nth-child(10n + 1) {\n        opacity: 0.5;\n      }\n    }\n  }\n\n  .y {\n    > line {\n      stroke: black;\n      opacity: 0.18;\n      stroke-width: 1;\n      vector-effect: non-scaling-stroke;\n\n      &:nth-child(10n + 1) {\n        opacity: 0.5;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_-_-packages-core-src-web-app-actions-canvas-grid-module__container--EKy1W",
	"x": "_-_-packages-core-src-web-app-actions-canvas-grid-module__x--mlipm",
	"y": "_-_-packages-core-src-web-app-actions-canvas-grid-module__y--U-gj0"
};
export default ___CSS_LOADER_EXPORT___;
