// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div._-_-packages-core-src-web-app-views-tutorials-Tutorial-module__tutorial--4euK8{z-index:999}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/views/tutorials/Tutorial.module.scss"],"names":[],"mappings":"AAAA,mFACE,WAAA","sourcesContent":["div.tutorial {\n  z-index: 999;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tutorial": "_-_-packages-core-src-web-app-views-tutorials-Tutorial-module__tutorial--4euK8"
};
export default ___CSS_LOADER_EXPORT___;
