import type { ILang } from '@core/interfaces/ILang';

const lang: ILang = {
  alert: {
    abort: 'Przerwij',
    cancel: 'Anuluj',
    caption: 'Błąd',
    close: 'Zamknij',
    confirm: 'Potwierdź',
    dont_save: 'Nie zapisuj',
    dont_show_again: 'Nie pokazuj więcej',
    duplicated_preset_name: 'Zduplikowana nazwa ustawienia wstępnego',
    error: 'UH-OH',
    info: 'INFORMACJA',
    instruction: 'Instrukcja',
    learn_more: 'Dowiedz się więcej',
    no: 'Nie',
    ok: 'OK',
    ok2: 'OK',
    oops: 'Ups...',
    retry: 'Spróbuj ponownie',
    save: 'Zapisz',
    stop: 'Zatrzymaj',
    warning: 'OSTRZEŻENIE',
    yes: 'Tak',
  },
  auto_fit: {
    artwork_size: 'Rozmiar dzieła',
    error_tip1: '1. Czy dzieło sztuki jest prawidłowo umieszczone na materiale?',
    error_tip2: '2. Czy kontury materiału są wystarczająco wyraźne do rozpoznania?',
    failed_to_find_contour: 'Nie udało się znaleźć konturów do automatycznego dopasowania',
    learn_more: 'Obejrzyj samouczek.',
    learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    offset_x: 'Przesunięcie X',
    offset_y: 'Przesunięcie Y',
    pattern_size: 'Rozmiar wzoru',
    position_artwork: 'Pozycjonuj dzieło sztuki',
    position_step1: 'Dostosuj rozmiar i pozycję swojego dzieła na wzorze.',
    position_step2: 'Kliknij „Zastosuj”, aby zastosować automatyczne dopasowanie do tego wzoru.',
    preview_first: 'Najpierw wykonaj podgląd kamery.',
    reset_position: 'Zresetuj pozycję',
    rotation: 'Obróć',
    select_a_pattern: 'Wybierz wzór',
    selected_artwork: 'Wybrane dzieło sztuki',
    step1: 'Wybierz wzór, na którym chcesz umieścić dzieło sztuki.',
    step2: 'Jeśli żaden wzór nie zostanie znaleziony, ponownie przejrzyj podgląd kamery i zastosuj Dopasowanie Automatyczne.',
    step3: 'Jeśli nadal nie uda się znaleźć odpowiedniego wzoru, upewnij się, że twój przedmiot jest wyraźnie rozpoznawalny, a obszar roboczy jest czysty i wolny od zanieczyszczeń.',
    title: 'Dopasowanie Automatyczne',
  },
  barcode_generator: {
    bar_height: 'Wysokość paska',
    bar_width: 'Szerokość paska',
    barcode: {
      invalid_value: 'Wartość jest nieprawidłowa dla wybranego formatu.',
    },
    font: 'Czcionka',
    font_size: 'Rozmiar czcionki',
    hide_text: 'Ukryj tekst',
    invert_color: 'Odwróć kolor',
    text_margin: 'Margines tekstu',
  },
  beambox: {
    announcement_panel: {
      title: 'Ogłoszenie',
    },
    banner: {
      auto_feeder: 'Tryb Automatycznego Podajnika',
      camera_preview: 'Podgląd',
      camera_preview_borderless_mode: '(Otwarty Spód)',
      curve_engraving: 'Tryb Grawerowania Krzywizn',
      pass_through: 'Tryb Przelotowy',
      rotary: 'Tryb Obrotowy',
    },
    bottom_right_panel: {
      convert_text_to_path_before_export: 'Konwertuj tekst na ścieżkę...',
      export_file_error_ask_for_upload: 'Nie udało się wyeksportować zadania. Czy chcesz udostępnić scenę roboczą do opracowania zespołu do zgłaszania błędów?',
      retreive_image_data: 'Pobierz dane obrazu...',
    },
    context_menu: {
      copy: 'Kopiuj',
      cut: 'Wytnij',
      delete: 'Usuń',
      duplicate: 'Duplikuj',
      group: 'Grupuj',
      move_back: 'Przesuń na spód',
      move_down: 'Przesuń do tyłu',
      move_front: 'Przesuń na wierzch',
      move_up: 'Przesuń do przodu',
      paste: 'Wklej',
      paste_in_place: 'Wklej w miejscu',
      ungroup: 'Rozgrupuj',
    },
    document_panel: {
      add_on: 'Dodatki',
      auto_feeder: 'Automatyczny podajnik',
      auto_feeder_scale: 'Skala automatyczny podajnik',
      auto_feeder_url: 'https://support.flux3dp.com/hc/en-us/articles/11688230498575',
      borderless_mode: 'Otwarte dno',
      current_position: 'Aktualna Pozycja',
      disable: 'Wyłącz',
      document_settings: 'Ustawienia dokumentu',
      enable: 'Włącz',
      enable_autofocus: 'Autofokus',
      enable_diode: 'Laser diodowy',
      engrave_dpi: 'Jakość',
      frame_before_start: 'Ramka przed wykonaniem',
      frame_before_start_url: 'https://support.flux3dp.com/hc/en-us/articles/11494925637135',
      high: 'Wysoka',
      job_origin: 'Początek Pracy',
      laser_source: 'Źródło lasera',
      low: 'Niska',
      machine: 'Maszyna',
      medium: 'Średna',
      notification: {
        changeFromPrintingWorkareaTitle: 'Czy chcesz przekonwertować warstwy druku na warstwy lasera?',
      },
      origin: 'Początek',
      pass_through: 'Przepust',
      pass_through_height_desc: 'Wprowadź długość obiektu, aby rozszerzyć obszar roboczy.',
      rotary_mode: 'Moduł obrotowy',
      scale: 'Skala',
      start_from: 'Rozpocznij Od',
      start_position: 'Pozycja Startowa',
      start_work_button: 'Przycisk Wykonaj',
      ultra: 'Super wysoka',
      workarea: 'Obszar roboczy',
    },
    image_trace_panel: {
      back: 'Wróć',
      brightness: 'Jasność',
      cancel: 'Anuluj',
      contrast: 'Kontrast',
      next: 'Następny',
      okay: 'Dobrze',
      threshold: 'Próg',
      tuning: 'Parametry',
    },
    layer_color_config_panel: {
      add: 'Dodaj',
      add_config: 'Dodaj kolor',
      color: 'Kolor',
      default: 'Przywróć ustawienia domyślne',
      in_use: 'Ten kolor jest używany.',
      layer_color_config: 'Konfiguracja kolorów warstwy',
      no_input: 'Wprowadź prawidłowy kod koloru HEX.',
      power: 'Moc',
      repeat: 'Wykonaj',
      speed: 'Prędkość',
      sure_to_delete: 'Czy na pewno chcesz usunąć to ustawienie kolorów?',
      sure_to_reset: 'Utracisz wszystkie dostosowane parametry. Czy na pewno chcesz przywrócić ustawienia domyślne?',
    },
    left_panel: {
      borderless_blind_area: 'Obszar bez możliwości grawerowania',
      borderless_preview: 'Podgląd kamery w trybie Otwartego dna',
      diode_blind_area: 'Dodatkowy obszar bez podglądu dla lasera diodowego',
      ellipse: 'Elipsa',
      image: 'Obraz',
      label: {
        adjust_height: 'Dostosuj wysokość',
        array: 'Ułóż',
        boxgen: 'Boxgen',
        choose_camera: 'Kamera',
        clear_preview: 'Wyczyść podgląd',
        cursor: 'Wybierz',
        curve_engraving: {
          clear_area: 'Wyczyść wybrany obszar',
          exit: 'Wyjście',
          preview_3d_curve: 'Podgląd grawerowania krzywej',
          select_area: 'Wybierz obszar',
          title: 'Grawerowanie krzywej',
        },
        end_preview: 'Koniec podglądu',
        line: 'Linia',
        live_feed: 'Na żywo',
        my_cloud: 'Moja chmura',
        oval: 'Elipsa',
        pass_through: 'Przepust',
        pen: 'Pióro',
        photo: 'Obraz',
        polygon: 'Wielokąt',
        preview: 'Podgląd kamery',
        qr_code: 'Kod QR',
        rect: 'Prostokąt',
        shapes: 'Elementy',
        text: 'Tekst',
        trace: 'Obrysuj obraz',
      },
      line: 'Linia',
      rectangle: 'Prostokąt',
      text: 'Tekst',
      unpreviewable_area: 'Obszar bez podglądu',
    },
    network_testing_panel: {
      average_response: 'Średni czas odpowiedzi',
      cannot_connect_1: '#840 Połączenie z docelowym adresem IP nie powiodło się.',
      cannot_connect_2: '#840 Połączenie z docelowym adresem IP nie powiodło się. Upewnij się że urządzenie docelowe znajduje się w tej samej sieci.',
      cannot_get_local: 'Dostęp do lokalnego adresu IP nie powiódł się.',
      connection_quality: 'Jakość połączenia',
      device_not_on_list: '#842 Maszyny nie ma na liście, ale jakość połączenia jest >70, a średni czas odpowiedzi <100ms',
      empty_ip: '#818 Najpierw wprowadź adres IP urządzenia docelowego.',
      end: 'Koniec',
      fail_to_start_network_test: '#817 Rozpoczęcie testowania sieci nie powiodło się.',
      hint_connect_camera_timeout: 'Przekroczono limit czasu podczas uruchamiania podglądu kamery?',
      hint_connect_failed_when_sending_job: 'Nie udało się połączyć podczas wysyłania zadania?',
      hint_device_often_on_list: 'Maszyny często nie ma na liście?',
      insert_ip: 'Adres IP urządzenia docelowego:',
      invalid_ip: '#818 Nieprawidłowy adres IP',
      ip_startswith_169: '#843 Adres IP maszyny zaczyna się od 169.254',
      link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
      link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
      link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
      linux_permission_hint: 'Ten błąd zwykle występuje z powodu niewystarczających uprawnień.\nUprzejmie uruchom „sudo beam-studio --no-sandbox” w terminalu, aby uzyskać uprawnienia i przeprowadzić testy sieci.',
      local_ip: 'Lokalny adres IP:',
      network_testing: 'Testowanie sieci',
      network_unhealthy: '#841 Jakość połączenia <70 lub średni czas odpowiedzi >100 ms',
      start: 'Rozpocznij',
      test_completed: 'Test zakończony',
      test_fail: 'Test nieudany',
      testing: 'Testowanie sieci...',
    },
    object_panels: {
      lock_desc: 'Zachowaj proporcje szerokości i wysokości (SHIFT)',
      text_to_path: {
        check_thumbnail_warning: 'Niektóre teksty zostały zmienione na inne czcionki podczas zmieniania tekstów na ścieżki, a niektóre znaki mogą nie zostać normalnie przekonwertowane.Sprawdź ponownie obraz podglądu przed wysłaniem zadania.',
        error_when_parsing_text: 'Błąd podczas konwersji tekstu na ścieżkę',
        font_substitute_pop: 'Twój tekst zawiera znaki, które nie są obsługiwane przez bieżącą czcionkę. <br/>Czy chcesz użyć <strong>%s</strong> jako zamiennika?',
        retry: 'Spróbuj ponownie później lub wybierz inną czcionkę',
        use_current_font: 'Użyj bieżącej czcionki',
      },
      wait_for_parsing_font: 'Parsowanie czcionki... Proszę chwilę poczekać',
    },
    path_preview: {
      current_position: 'Aktualna pozycja',
      cut_distance: 'Odległość cięcia',
      cut_time: 'Skróć czas',
      end_preview: 'Zakończ podgląd',
      estimated_time: 'Szacowany całkowity czas',
      invert: 'Odwróć',
      pause: 'Wstrzymaj',
      play: 'Play',
      play_speed: 'Szybkość odtwarzania',
      preview_info: 'Podgląd informacji',
      rapid_distance: 'Odległość podróży',
      rapid_time: 'Czas podróży',
      remark: '* Wszystkie informacje są wartościami szacunkowymi w celach informacyjnych.',
      size: 'Rozmiar',
      start_here: 'Zacznij tutaj',
      stop: 'Zatrzymaj',
      travel_path: 'Ścieżka podróży',
    },
    photo_edit_panel: {
      aspect_ratio: 'Awspółczynnik proporcji',
      brightness: 'Jasność',
      brightness_and_contrast: 'Jasność / Kontrast',
      cancel: 'Anuluj',
      circumference: 'Obwód',
      compare: 'Porównywnaj',
      contrast: 'Kontrast',
      crop: 'Przytnij',
      curve: 'Krzywa',
      diameter: 'Średnica',
      free: 'Bezpłatny',
      invert: 'Odwróć kolor',
      okay: 'Dobrze',
      original: 'pierwowzór',
      phote_edit: 'Edycja zdjęć',
      processing: 'Przetwarzanie',
      radius: 'Promień',
      rotary_warped: 'Zniekształcony Obrotowo',
      rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
      sharpen: 'Wyostrzyć',
      sharpness: 'Ostrość',
      start: 'Rozpocznij',
      warp: 'Zniekształcenie',
    },
    popup: {
      ai_credit: {
        buy_link: 'https://member.flux3dp.com/en-US/credit',
        go: 'Iść',
        insufficient_credit: 'Skończył Ci się kredyt',
        insufficient_credit_msg: 'Nie możesz użyć %s. Przejdź do centrum członkowskiego i kup kredyt AI.',
        relogin_to_use: 'Aby skorzystać z tej funkcji, zaloguj się ponownie.',
      },
      auto_feeder_origin: 'Użyj podglądu kadrowania, aby sprawdzić ścieżkę i obszar głowicy laserowej, a następnie dostosuj położenie materiału lub pozycję początkową grawerowania na podstawie wyników, aby uniknąć kolizji podczas procesu grawerowania.',
      auto_switch_tab: {
        message: 'Nowa opcja automatycznego przełączania między panelem warstw a panelem obiektów została dodana do Preferencji. Ta opcja jest domyślnie wyłączona. Czy chcesz teraz włączyć automatyczne przełączanie? <br/>Możesz zmienić to ustawienie w dowolnym momencie w Preferencjach.',
        title: 'Automatyczne Przełączanie Między Panelem Warstw i Obiektów',
      },
      backend_connect_failed_ask_to_upload: '#802 Podczas próby połączenia z backendem występują błędy. Czy chcesz przesłać dziennik raportów o błędach?',
      backend_error_hint: 'Funkcje mogą nie działać poprawnie z powodu błędu zaplecza.',
      both_power_and_speed_too_high: '"Użycie niższej mocy lasera wydłuży żywotność tuby laserowej. \nPonadto wysoka prędkość przy tej rozdzielczości może skutkować niższą jakością cieniowania graweru."',
      bug_report: 'Zgłoszenie błędut',
      change_workarea_before_preview: 'Obszar roboczy %s nie pasuje do aktualnie ustawionego obszaru roboczego. Czy chcesz zmienić bieżący obszar roboczy?',
      convert_to_path_fail: 'Nie udało się przekonwertować na ścieżkę.',
      dxf_bounding_box_size_over: 'Rozmiar rysunku jest poza obszarem roboczym. Przesuń rysunek bliżej punktu początkowego w oprogramowaniu CAD lub upewnij się, że jednostka jest ustawiona prawidłowo.',
      dxf_version_waring: 'Wersja tego pliku DXF jest inna niż 2013, mogą występować potencjalne problemy z kompatybilnością.',
      facebook_group_invitation: {
        already_joined: 'Już dołączyłeś',
        join_now: 'Dołączyć',
        later: 'Może później',
        message: 'Dołączyćnasza oficjalna grupa na Facebooku, w której możesz kontaktować się z innymi użytkownikami laserów FLUX, omawiać lasery FLUX, dzielić się grafikami dotyczącymi laserów i być na bieżąco z najnowszymi aktualizacjami naszych produktów. Nie możemy się doczekać, aż Cię tam zobaczymy!',
        title: 'Dołączyć FLUX Official User Group',
      },
      import_file_contain_invalid_path: '#808 Zaimportowany plik SVG zawiera nieprawidłową ścieżkę do obrazu. Upewnij się, że wszystkie pliki obrazów istnieją lub umieść obraz w pliku',
      import_file_error_ask_for_upload: 'Nie udało się zaimportować pliku SVG. Czy chcesz udostępnić plik zespołowi programistycznemu w celu zgłoszenia błędu?',
      layer_by_color: 'Kolorami',
      layer_by_layer: 'Warstwami',
      loading_image: 'Ładowanie obrazu, proszę czekać...',
      more_than_two_object: 'Zbyt wiele obiektów. Obsługa tylko 2 obiektów',
      mouse: 'Mysz',
      no_support_text: 'Beam Studio nie obsługuje obecnie tagów tekstowych. Przed zaimportowaniem zamień tekst na ścieżki.',
      nolayer: 'Pojedyncza warstwa',
      not_support_object_type: 'Nie obsługuje typu obiektu',
      or_turn_off_borderless_mode: ' Lub wyłącz tryb Otwarte dno.',
      pdf2svg: {
        error_pdf2svg_not_found: '#825 Błąd: nie znaleziono polecenia pdf2svg. Zainstaluj pdf2svg za pomocą menedżera pakietów (np. „yum install pdf2svg” lub „apt-get install pdf2svg”).',
        error_when_converting_pdf: '#824 Błąd podczas konwersji pliku PDF na SVG:',
      },
      progress: {
        calculating: 'Obliczanie',
        uploading: 'Przesyłanieg',
      },
      questionnaire: {
        caption: 'Pomóż nam wypełnić kwestionariusz',
        message: 'Pomóż nam wypełnić ankietę, aby ulepszać Beam Studio.',
        no_questionnaire_available: 'W tej chwili nie ma dostępnej ankiety.',
        unable_to_get_url: 'Nie udało się uzyskać linku do kwestionariusza przez Internet. Potwierdź połączenie internetowe.',
      },
      recommend_downgrade_software: 'We detected an older firmware version. We are actively addressing compatibility issues, but for now, we recommend reverting to <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>.',
      recommend_upgrade_firmware: 'Wykryliśmy starszą wersję oprogramowania sprzętowego. Aktywnie rozwiązujemy problemy ze zgodnością, ale na razie zalecamy aktualizację oprogramowania do najnowszej wersji.',
      save_unsave_changed: 'Czy chcesz zapisać niezapisane zmiany?',
      select_at_least_two: 'Wybierz dwa obiekty, aby kontynuować',
      select_first: 'Najpierw wybierz obiekt.',
      select_import_method: 'Wybierz sposób rozdzielenia warstw:',
      select_import_module: 'Wybierz moduł:',
      sentry: {
        message: 'Czy zgadzasz się na automatyczne przesyłanie odpowiednich informacji do zespołu programistów w przypadku wystąpienia błędów?',
        title: 'Ulepszajmy Beam Studio razem',
      },
      should_update_firmware_to_continue: '#814 Twój firmware nie obsługuje tej wersji Beam Studio. Zaktualizuj oprogramowanie układowe, aby kontynuować. (Menu > Maszyna > [Twoja maszyna] > Aktualizuj oprogramowanie sprzętowe)',
      speed_too_high_lower_the_quality: 'Użycie zbyt dużej prędkości przy tej rozdzielczości może skutkować niższą jakością graweru cieniowania.',
      still_continue: 'Kontynuuj',
      successfully_uploaded: 'Przesłanie pliku powiodło się.',
      svg_1_1_waring: 'Wersja tego pliku SVG to v 1.1, mogą wystąpić potencjalne problemy z kompatybilnością.',
      svg_image_path_waring: 'Ten plik SVG zawiera <image> ładowane ze ścieżki pliku. Może to powodować błędy podczas ładowania. Aby uniknąć tego ryzyka, uprzejmie prosimy o użycie obrazu osadzonego podczas eksportowania SVG.',
      text_to_path: {
        caption: 'Konwerter tekstu na ścieżkę 2.0',
        message: 'Beam Studio wprowadza nowy konwerter tekstu na ścieżkę (Konwerter 2.0), który daje bardziej niezawodne wyniki! Czy chcesz teraz przełączyć się na niego? \nMożesz również dostosować to ustawienie później w sekcji "Konwerter tekstu na ścieżkę" w preferencjach.',
      },
      too_fast_for_auto_feeder: 'Używanie zbyt wysokich prędkości w warstwach zawierających obiekty ścieżek może zmniejszyć precyzję cięcia.\nNie zalecamy używania prędkości większych niż %(limit)s podczas cięcia z Auto Feeder.',
      too_fast_for_auto_feeder_and_constrain: 'Następujące warstwy: %(layers)s \nzawierają obiekty ścieżek wektorowych i mają prędkość przekraczającą %(limit)s.\nPrędkość cięcia obiektów ścieżek wektorowych z Auto Feeder będzie ograniczona do %(limit)s.\nMożesz usunąć to ograniczenie w Ustawieniach Preferencji.',
      too_fast_for_curve: 'Używanie zbyt dużej prędkości dla warstw zawierających obiekty grawerowania krzywoliniowego może zmniejszyć precyzję grawerowania.\nZalecamy utrzymanie prędkości poniżej %(limit)s dla optymalnych wyników.',
      too_fast_for_curve_and_constrain: 'Następujące warstwy: %(layers)s przekraczają limit prędkości %(limit)s.\nPrędkość grawerowania obiektów krzywoliniowych zostanie ograniczona do %(limit)s. Możesz usunąć to ograniczenie w ustawieniach preferencji.',
      too_fast_for_path: 'Użycie zbyt dużej prędkości w warstwach zawierających obiekty ścieżki może spowodować niższą precyzję cięcia.\nNie zalecamy używania prędkości większej niż %(limit)s podczas cięcia.',
      too_fast_for_path_and_constrain: 'Następujące warstwy: %(layers)s \nzawierają obiekty wektorowe a ustawiona do nich prędkość przekracza %(limit)s. \nSzybkość cięcia obiektów wektorowych zostanie ograniczona do %(limit)s. \nMożesz usunąć to ograniczenie w Ustawieniach programu.',
      touchpad: 'Panel dotykowy',
      ungroup_use: 'Spowoduje to rozgrupowanie zaimportowanych plików DXF lub SVG. Ponieważ plik może zawierać dużą liczbę elementów, rozgrupowanie może zająć trochę czasu. Czy na pewno chcesz kontynuować?',
      upload_failed: '#819 Przesyłanie pliku nie powiodło się.',
      upload_file_too_large: '#819 Plik jest za duży do przesłania.',
      vectorize_shading_image: 'Obrysowanie obrazów gradientowych zajmuje więcej czasu i jest podatne na szumy. Uprzejmie prosimy o wyłączenie gradientu obrazu przed kontynuowaniem.',
    },
    rating_panel: {
      description: 'Jeśli podoba Ci się Beam Studio, bylibyśmy bardzo wdzięczni za ocenę.',
      thank_you: 'Dziękuję!',
      title: 'Lubisz Beam Studio?',
    },
    right_panel: {
      laser_panel: {
        advanced: 'Zaawansowane',
        backlash: 'Reakcja',
        bi_directional: 'Dwukierunkowy',
        by: 'O',
        ce_z_high_speed: 'Szybkoobrotowa oś Z',
        ce_z_high_speed_link: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
        color_adjustment: 'Regulacja kanału',
        color_adjustment_short: 'Kanał',
        color_strength: 'Siła',
        cross_hatch: 'Kratkowanie Krzyżowe',
        custom_preset: 'Niestandardowe',
        cut: 'Cięcie',
        diode: 'Laser diodowy',
        do_not_adjust_default_para: 'Nie można dostosować ustawień wstępnych.',
        dottingTime: 'Czas punktowania',
        dropdown: {
          inches: {
            abs_engraving: 'ABS - grawerowanie',
            acrylic_3mm_cutting: 'Akryl - 1/8″ cięcie',
            acrylic_5mm_cutting: 'Akryl - 3/16″ cięcie',
            acrylic_8mm_cutting: 'Akryl - 1/4″ cięcie',
            acrylic_10mm_cutting: 'Akryl - 3/8″ cięcie',
            acrylic_engraving: 'Akryl - grawerowanie',
            acrylic_printing: 'Akryl - druk',
            aluminum_engraving: 'Aluminum - grawerowanie',
            aluminum_light: 'Aluminium (jasny)',
            bamboo_printing: 'Bambus - druk',
            black_abs: 'Czarny ABS',
            black_acrylic_3mm_cutting: 'Czarny akryl - 1/8″ cięcie',
            black_acrylic_5mm_cutting: 'Czarny akryl - 3/16″ cięcie',
            black_acrylic_engraving: 'Czarny akryl - grawerowanie',
            brass_dark: 'Mosiądz (ciemny)',
            brass_engraving: 'Mosiądz - grawerowanie',
            brass_light: 'Mosiądz (jasny)',
            canvas_printing: 'Płótno - druk',
            cardstock_printing: 'Karton - druk',
            copper: 'Miedź',
            cork_printing: 'Korek - druk',
            denim_1mm_cutting: 'Dżinsowa - 1/4″ cięcie',
            fabric_3mm_cutting: 'Tkanina - 1/8″ cięcie',
            fabric_5mm_cutting: 'Tkanina - 3/16″ cięcie',
            fabric_engraving: 'Tkanina - grawerowanie',
            fabric_printing: 'Tkanina - druk',
            flat_stone_printing: 'Kamień - druk',
            glass_bw_engraving: 'Szkło - grawerowanie',
            glass_printing: 'Szkło - druk',
            gloss_leather_printing: 'Gloss Skóra - druk',
            gold_engraving: 'Złoto - grawerowanie',
            iron_engraving: 'Żelazo - grawerowanie',
            leather_3mm_cutting: 'Skóra — 1/8″ cięcie',
            leather_5mm_cutting: 'Skóra — 3/16″ cięcie',
            leather_engraving: 'Skóra - grawerowanie',
            mdf_3mm_cutting: 'MDF - 1/8″ cięcie',
            mdf_5mm_cutting: 'MDF - 3/16″ cięcie',
            mdf_engraving: 'MDF - grawerowanie',
            metal_bw_engraving: 'Metal - grawerowanie',
            opaque_acrylic: 'Nieprzezroczysty akryl',
            pc_printing: 'PC - druk',
            rubber_bw_engraving: 'Guma - grawerowanie',
            silver_engraving: 'Srebro - grawerowanie',
            stainless_steel_bw_engraving_diode: 'Metal - grawerowanie (laser diodowy)',
            stainless_steel_dark: 'Stal nierdzewna (ciemny)',
            stainless_steel_engraving: 'Stal nierdzewna - grawerowanie',
            stainless_steel_light: 'Stal nierdzewna (jasny)',
            stainless_steel_printing: 'Stal nierdzewna - druk',
            steel_engraving_spray_engraving: 'Metal - grawerowanie',
            stone: 'Kamień',
            ti_engraving: 'Tytan - grawerowanie',
            titanium_dark: 'Tytan (ciemny)',
            titanium_light: 'Tytan (jasny)',
            white_abs: 'Biały ABS',
            wood_3mm_cutting: 'Drewno — 1/8″ cięcie',
            wood_5mm_cutting: 'Drewno — 3/16″ cięcie',
            wood_7mm_cutting: 'Drewno - 1/4″ cięcie',
            wood_8mm_cutting: 'Drewno - 1/4″ cięcie',
            wood_10mm_cutting: 'Drewno — 3/8″ cięcie',
            wood_engraving: 'Drewno - grawerowanie',
            wood_printing: 'Drewno - druk',
          },
          mm: {
            abs_engraving: 'ABS - grawerowanie',
            acrylic_3mm_cutting: 'Akryl - 3mm cięcie',
            acrylic_5mm_cutting: 'Akryl - 5mm cięcie',
            acrylic_8mm_cutting: 'Akryl - 8mm cięcie',
            acrylic_10mm_cutting: 'Akryl - 10mm cięcie',
            acrylic_engraving: 'Akryl - grawerowanie',
            acrylic_printing: 'Akryl - druk',
            aluminum_engraving: 'Aluminum - grawerowanie',
            aluminum_light: 'Aluminium (jasny)',
            bamboo_printing: 'Bambus - druk',
            black_abs: 'Czarny ABS',
            black_acrylic_3mm_cutting: 'Czarny akryl - 3mm cięcie',
            black_acrylic_5mm_cutting: 'Czarny akryl - 5mm cięcie',
            black_acrylic_engraving: 'Czarny akryl - grawerowanie',
            brass_dark: 'Mosiądz (ciemny)',
            brass_engraving: 'Mosiądz - grawerowanie',
            brass_light: 'Mosiądz (jasny)',
            canvas_printing: 'Płótno - druk',
            cardstock_printing: 'Karton - druk',
            copper: 'Miedź',
            cork_printing: 'Korek - druk',
            denim_1mm_cutting: 'Dżinsowa - 1mm cięcie',
            fabric_3mm_cutting: 'Tkanina - 3mm cięcie',
            fabric_5mm_cutting: 'Tkanina - 5mm cięcie',
            fabric_engraving: 'Tkanina - grawerowanie',
            fabric_printing: 'Tkanina - druk',
            flat_stone_printing: 'Kamień - druk',
            glass_bw_engraving: 'Szkło - grawerowanie',
            glass_printing: 'Szkło - druk',
            gloss_leather_printing: 'Gloss Skóra - druk',
            gold_engraving: 'Złoto - grawerowanie',
            iron_engraving: 'Żelazo - grawerowanie',
            leather_3mm_cutting: 'Skóra - 3mm cięcie',
            leather_5mm_cutting: 'Skóra - 5mm cięcie',
            leather_engraving: 'Skóra - grawerowanie',
            mdf_3mm_cutting: 'MDF - 3mm cięcie',
            mdf_5mm_cutting: 'MDF - 5mm cięcie',
            mdf_engraving: 'MDF - grawerowanie',
            metal_bw_engraving: 'Metal - grawerowanie',
            opaque_acrylic: 'Nieprzezroczysty akryl',
            pc_printing: 'PC - druk',
            rubber_bw_engraving: 'Guma - grawerowanie',
            silver_engraving: 'Srebro - grawerowanie',
            stainless_steel_bw_engraving_diode: 'Metal - grawerowanie (laser diodowy)',
            stainless_steel_dark: 'Stal nierdzewna (ciemny)',
            stainless_steel_engraving: 'Stal nierdzewna - grawerowanie',
            stainless_steel_light: 'Stal nierdzewna (jasny)',
            stainless_steel_printing: 'Stal nierdzewna - druk',
            steel_engraving_spray_engraving: 'Metal - grawerowanie',
            stone: 'Kamień',
            ti_engraving: 'Tytan - grawerowanie',
            titanium_dark: 'Tytan (ciemny)',
            titanium_light: 'Tytan (jasny)',
            white_abs: 'Biały ABS',
            wood_3mm_cutting: 'Drewno - 3mm cięcie',
            wood_5mm_cutting: 'Drewno - 5mm cięcie',
            wood_7mm_cutting: 'Drewno - 7mm cięcie',
            wood_8mm_cutting: 'Drewno - 8mm cięcie',
            wood_10mm_cutting: 'Drewno - 10mm cięcie',
            wood_engraving: 'Drewno - grawerowanie',
            wood_printing: 'Drewno - druk',
          },
          parameters: 'Presety',
          save: 'Dodaj bieżące parametry',
        },
        engrave: 'Graweruj',
        existing_name: 'Ta nazwa została już użyta.',
        fill_angle: 'Kąt Wypełnienia',
        fill_interval: 'Odstęp Wypełnienia',
        fill_setting: 'Ustawienia Wypełnienia',
        filled_path_only: 'Tylko dla ścieżek wypełnienia',
        focus_adjustment: 'Regulacja focusa',
        frequency: 'Częstotliwość',
        gradient_only: 'Tylko dla obrazów gradientowych',
        halftone: 'Połton',
        halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
        height: 'Wysokość obiektu',
        ink_saturation: 'Nasycenie',
        ink_type: {
          normal: 'Atrament',
          text: 'Rodzaj atramentu',
          UV: 'Atrament UV',
        },
        laser_speed: {
          fast: 'Szybka',
          max: 300,
          min: 3,
          slow: 'Wolna',
          step: 0.1,
          text: 'Prędkość',
          unit: 'mm/s',
        },
        low_power_warning: 'Niższa moc lasera (poniżej 10%) może nie emitować światła lasera.',
        low_speed_warning: 'Niska prędkość może spowodować spalenie materiału.',
        lower_focus: 'Obniż Fokus',
        lower_focus_desc: 'Obniżenie wysokości ostrości o określoną odległość po ustawieniu ostrości w celu poprawy wydajności cięcia.',
        module: 'Moduł',
        more: 'Zarządzaj',
        multi_layer: 'Wiele warstw',
        para_in_use: 'Ten parametr jest używany.',
        parameters: 'Parametry',
        power: {
          high: 'Wysoka',
          low: 'Niska',
          max: 100,
          min: 1,
          step: 0.1,
          text: 'Moc',
        },
        preset_management: {
          add_new: 'Dodaj Nowy',
          delete: 'Usuwaj',
          export: 'Eksportuj',
          export_preset_title: 'Eksportuj ustawienia wstępne',
          import: 'Importuj',
          laser: 'Laser',
          lower_focus_by: 'Obniż Ostrość o',
          new_preset_name: 'Nowa nazwa ustawienia wstępnego',
          preset: 'Ustawienie Wstępne',
          print: 'Drukuj',
          reset: 'Resetowanie',
          save_and_exit: 'Zapisz i wyjdź',
          show_all: 'Pokaż Wszystko',
          sure_to_import_presets: 'To spowoduje załadowanie układu ustawień wstępnych i nadpisanie dostosowanych parametrów. Czy na pewno chcesz kontynuować?',
          sure_to_reset: 'Spowoduje to usunięcie dostosowanych parametrów i zresetowanie wszystkich ustawień wstępnych. Czy na pewno chcesz kontynuować?',
          title: 'Zarządzaj Parametrami',
          wobble_diameter: 'Średnica wibracji',
          wobble_step: 'Rozmiar kroku wibracji',
        },
        preset_setting: 'Ustawienia parametrów (%s)',
        presets: 'Wstępnie ustawione',
        print_multipass: 'Wieloprzebiegowe',
        promark_speed_desc: 'Parametry prędkości nie mają zastosowania do obrazów z gradientem.',
        pulse_width: 'Szerokość Impulsu',
        pwm_advanced_desc: 'Ustaw minimalną moc dla trybu głębokości.',
        pwm_advanced_hint: 'To ustawienie dotyczy konkretnie obrazów gradientowych w trybie głębokości.',
        pwm_advanced_setting: 'Ustawienia Mocy w Trybie Głębi',
        repeat: 'Liczba Przejść',
        single_color: 'Jednolity Kolor',
        single_color_desc: 'Dotyczy tylko pełnej warstwy kolorowej i nie może być stosowane z rozszerzoną warstwą jednokolorową.',
        slider: {
          fast: 'Szybko',
          high: 'Wysoki',
          low: 'Niski',
          regular: 'Regularny',
          slow: 'Powolny',
          very_fast: 'Najszybszy',
          very_high: 'Maks',
          very_low: 'Min',
          very_slow: 'Najwolniej',
        },
        speed: 'Prędkość',
        speed_constrain_warning: 'Szybkość cięcia obiektów wektorowych zostanie ograniczona do %(limit)s. Możesz usunąć to ograniczenie w Ustawieniach preferencji.',
        speed_constrain_warning_auto_feeder: 'Prędkość cięcia obiektów Automatycznego Podajnika będzie ograniczona do %(limit)s. Możesz usunąć to ograniczenie w Ustawieniach preferencji.',
        speed_constrain_warning_curve_engraving: 'Prędkość grawerowania obiektów krzywych zostanie ograniczona do %(limit)s. Możesz usunąć to ograniczenie w ustawieniach preferencji.',
        stepwise_focusing: 'Stopniowe Fokusoanie',
        stepwise_focusing_desc: 'Stopniowe obniżanie odległości ostrości na podstawie wysokości obiektu podczas każdej liczby przejść.',
        strength: 'Moc',
        times: 'czasy',
        various_preset: 'Różne ustawienia wstępne',
        white_ink: 'Biały atrament',
        white_ink_settings: 'Ustawienia białego atramentu',
        wobble: 'Drganie',
        wobble_desc: 'Tworzy małe okręgi wzdłuż ścieżki, co jest korzystne przy zadaniach cięcia. (Tylko dla obiektów ścieżki.)',
        wobble_diameter: 'Średnica',
        wobble_step: 'Rozmiar kroku',
        z_step: 'Krok Z',
      },
      layer_panel: {
        current_layer: 'Bieżąca warstwa',
        layer1: 'Warstwa 1',
        layer_bitmap: 'Mapa bitowa',
        layer_cutting: 'Cięcie',
        layer_engraving: 'Grawerowanie',
        layers: {
          del: 'Usuń warstwę',
          dupe: 'Zduplikuj warstwę',
          fullColor: 'Pełny kolor',
          layer: 'Warstwa',
          layers: 'Warstwy',
          lock: 'Zablokuj warstwę',
          merge_all: 'Połącz wszystko',
          merge_down: 'Połącz w dół',
          merge_selected: 'Połącz wybrane warstwy',
          move_down: 'Przesuń warstwę w dół',
          move_elems_to: 'Przenieś elementy do:',
          move_selected: 'Przenieś wybrane elementy na inną warstwę',
          move_up: 'Przenieś warstwę w górę',
          new: 'Nowa warstwa',
          rename: 'Zmień nazwę warstwy',
          splitFullColor: 'Rozwiń warstwę',
          switchToFullColor: 'Przełącz na warstwę pełnego koloru',
          switchToSingleColor: 'Przełącz na pojedynczą warstwę koloru',
          unlock: 'Odblokować',
        },
        move_elems_to: 'Przenieś elementy do:',
        notification: {
          dupeLayerName: 'Istnieje już warstwa o takiej nazwie!',
          enterNewLayerName: 'Wprowadź nową nazwę warstwy',
          enterUniqueLayerName: 'Wprowadź unikalną nazwę warstwy',
          layerHasThatName: 'Warstwa ma już tę nazwę',
          mergeLaserLayerToPrintingLayerMsg: 'Należy pamiętać, że po wykonaniu tej operacji ustawienia warstwy lasera zostaną usunięte i ustawione zgodnie z bieżącą warstwą.',
          mergeLaserLayerToPrintingLayerTitle: 'Czy chcesz połączyć warstwy w moduł Drukowanie?',
          mergePrintingLayerToLaserLayerMsg: 'Należy pamiętać, że po wykonaniu tej operacji ustawienia kolorów warstwy druku zostaną usunięte i ustawione zgodnie z bieżącą warstwą.',
          mergePrintingLayerToLaserLayerTitle: 'Czy chcesz połączyć warstwy w moduł Laser?',
          moveElemFromPrintingLayerMsg: 'Pamiętaj, że jeśli wykonasz tę operację, ustawienia kolorów wybranego elementu zostaną usunięte i ustawione zgodnie z %s.',
          moveElemFromPrintingLayerTitle: 'Przenieść wybrany element do %s i przekonwertować go na element laserowy?',
          moveElemToPrintingLayerMsg: 'Pamiętaj, że jeśli wykonasz tę operację, ustawienia wybranego elementu zostaną usunięte i ustawione zgodnie z %s.',
          moveElemToPrintingLayerTitle: 'Przenieść wybrany element do %s i przekonwertować go na element drukujący?',
          newName: 'NOWA NAZWA',
          QmoveElemsToLayer: 'Przenieś wybrane elementy do warstwy „%s”?',
          splitColorMsg: 'Należy pamiętać, że wykonanie tej procedury nie umożliwi przywrócenia pełnej warstwy koloru.',
          splitColorTitle: 'Rozwinąć wybraną warstwę na warstwy CMYK?',
        },
      },
      object_panel: {
        actions_panel: {
          ai_bg_removal: 'Usunąć tła',
          ai_bg_removal_reminder: 'Naciśnięcie przycisku spowoduje natychmiastowe wykorzystanie 0,2 kredytu. Czy chcesz kontynuować?',
          ai_bg_removal_short: 'BG Usunąć',
          array: 'Szyk',
          auto_fit: 'Dopasowanie Automatyczne',
          bevel: 'Fazuj',
          brightness: 'Jasność',
          convert_to_path: 'Konwertuj na ścieżkę',
          create_textpath: 'Utwórz tekst na ścieżce',
          create_textpath_short: 'Tekst ścieżki',
          crop: 'Przytnij',
          decompose_path: 'Rozwiń',
          detach_path: 'Rozłóż tekst na ścieżce',
          detach_path_short: 'Rozwiń',
          disassemble_use: 'Rozłóż',
          disassembling: 'Demontuję...',
          edit_path: 'Edytuj ścieżkę',
          fetching_web_font: 'Pobieranie czcionki online...',
          grading: 'Cieniuj',
          invert: 'Odwróć',
          offset: 'Przesunięcie ścieżki',
          outline: 'Zarys',
          replace_with: 'Zamień z...',
          replace_with_short: 'Zastępować',
          sharpen: 'Wyostrzyć',
          simplify: 'Uproszczać',
          smart_nest: 'Inteligentne Gniazdowanie',
          trace: 'Obrysuj',
          ungrouping: 'Rozgrupowywuje...',
          uploading_font_to_machine: 'Przesyłanie czcionki do urządzenia...',
          wait_for_parsing_font: 'Parsowanie czcionki... Proszę chwilę poczekać',
          weld_text: 'Tekst spoiny',
        },
        align: 'Wyrównaj',
        boolean: 'Wartość logiczna',
        bottom_align: 'Wyrównaj do dołu',
        center_align: 'Wyrównaj do środka',
        difference: 'Wyklucz',
        distribute: 'Dystrybucja',
        flip: 'Odbij',
        group: 'Grupuj',
        hdist: 'Dystrybucja pozioma',
        hflip: 'Odbij w poziomie',
        intersect: 'Przetnij',
        left_align: 'Wyrównaj do lewej',
        lock_aspect: 'Zachowaj proporcję szerokości i wysokości',
        middle_align: 'Wyrównaj do środka',
        option_panel: {
          color: 'Kolor',
          fill: 'Wypełnienie',
          font_family: 'Czcionka',
          font_size: 'Rozmiar',
          font_style: 'Styl',
          letter_spacing: 'Odstępy między literami',
          line_spacing: 'Odstępy między wierszami',
          path_infill: 'Wypełnienie ścieżki',
          pwm_engraving: 'Tryb Głębokości',
          pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
          rounded_corner: 'Zaokrąglenie narożników',
          shading: 'Gradient',
          sides: 'Boki',
          start_offset: 'Przesunięcie tekstu',
          stroke: 'Skoku',
          stroke_color: 'Kolor skoku',
          stroke_width: 'Szerokość skoku',
          text_infill: 'Wypełnienie tekstu',
          threshold: 'Próg jasności',
          threshold_short: 'Próg',
          vertical_align: 'Wyrównanie',
          vertical_text: 'Tekst pionowy',
        },
        path_edit_panel: {
          connect: 'Łączyć',
          delete: 'Usuwać',
          disconnect: 'Odłączyć',
          node_type: 'TYP WĘZŁA',
          round: 'Okrągłe',
          sharp: 'Ostre',
        },
        right_align: 'Wyrównaj do prawej',
        subtract: 'Odejmij wierzch',
        top_align: 'Wyrównaj do góry',
        ungroup: 'Rozgrupuj',
        union: 'Połącz',
        unlock_aspect: 'Odblokuj proporcję szerokości i wysokości',
        vdist: 'Dystrybucja pionowa',
        vflip: 'Odbij w pionie',
        zoom: 'Powiększenie',
      },
      tabs: {
        layers: 'Warstwy',
        objects: 'Obiekty',
        path_edit: 'Edycja ścieżki',
      },
    },
    shapes_panel: {
      animals: 'Zwierzę',
      arrow: 'Strzałka',
      basic: 'Podstawowe',
      birds: 'Ptak',
      celebration: 'Celebracja',
      circular: 'Okrągły',
      CNY: 'Chiński Nowy Rok',
      corner: 'Róg',
      decor: 'Dekoracja',
      easter: 'Wielkanoc',
      elements: 'Elementy',
      environment: 'Środowisko',
      graphics: 'Grafika',
      halloween: 'Halloween',
      holidays: 'Święto',
      label: 'Etykieta',
      land: 'Ląd',
      line: 'Linia',
      nature: 'Natura',
      photo: 'Zdjęcie',
      plants: 'Rośliny',
      ribbon: 'Wstążka',
      sea: 'Morze',
      shape: 'Kształt',
      speech: 'Mowa',
      text: 'Tekst',
      title: 'Elementy',
      valentines: 'Walentynki',
      weather: 'Pogoda',
      Xmas: 'Święta Bożego Narodzenia',
    },
    svg_editor: {
      unable_to_fetch_clipboard_img: 'Nie udało się pobrać obrazu ze schowka',
      unnsupported_file_type: 'Typ pliku nie jest bezpośrednio obsługiwany. Przekonwertuj plik na format SVG lub bitmapę',
    },
    tag: {
      g: 'Grupuj',
      image: 'Obraz',
      text: 'Tekst',
      use: 'Importuj pliki SVG',
    },
    time_est_button: {
      calculate: 'Oszacuj czas',
      estimate_time: 'Szacowany czas:',
    },
    tool_panels: {
      _nest: {
        end: 'Zamknij',
        no_element: 'Nie ma elementu do ułożenia.',
        rotations: 'Możliwy obrót',
        spacing: 'Odległość',
        start_nest: 'Ułóż',
        stop_nest: 'Zatrzymaj',
      },
      _offset: {
        corner_type: 'Narożniki',
        direction: 'Kierunek przesunięcia',
        dist: 'Odległość przesunięcia',
        fail_message: 'Nie udało się przesunąć ścieżki obiektów.',
        inward: 'Na zewnątrz',
        not_support_message: 'Wybrane elementy zawierają nieobsługiwany tag SVG: Obraz, Grupę, Tekst i Zaimportowany obiekt. ',
        outward: 'Do wewnątrz',
        round: 'Okrągłe',
        sharp: 'Ostre',
      },
      array_dimension: 'Wymiar siatki',
      array_interval: 'Interwał powtórzenia',
      cancel: 'Anuluj',
      columns: 'Kolumny.',
      confirm: 'Potwierdź',
      dx: 'X',
      dy: 'Y',
      grid_array: 'Utwórz powtórzenie - siatka',
      nest: 'Optymalizacja ułożenia',
      offset: 'Przesunięcie',
      rows: 'Rzędy',
    },
    units: {
      mm: 'mm',
      walt: 'W',
    },
    zoom_block: {
      fit_to_window: 'Dopasuj do oknaw',
    },
  },
  boxgen: {
    add_option: 'Dodaj opcję',
    basic_box: 'Podstawowe pudełko',
    beam_radius: 'Kompensacja promienia wiązki',
    beam_radius_warning: 'Fjern kompensasjon for kuttebredde når kantene eller sammenføyningene på boksen er korte for å sikre riktig sammensetting',
    cancel: 'Anuluj',
    coming_soon: 'Wkrótce',
    continue_import: 'Kontynuuj import',
    control_tooltip: 'Lewy przycisk myszy aby obrócić\nPrzewiń aby przybliżyć\nPrawy przycisk myszy aby przesunąć',
    control_tooltip_touch: 'Przeciągnij aby obrócić\nZsuń palcami aby przybliżyć\nDwa palce aby przesunąć',
    cover: 'Pokrywa',
    customize: 'Dostosuj',
    depth: 'Głębokość',
    edge: 'Krawędź',
    finger: 'Palec',
    finger_warning: 'Długość wewnętrzna boku pudełka musi wynosić co najmniej 6 mm (0,24 cala), aby była kompatybilna z łączeniem palców.',
    height: 'Wysokość',
    import: 'Importuj',
    inner: 'Wewnętrzny',
    joints: 'Połączenie',
    max_dimension_tooltip: 'Maksymalna szerokość/wysokość/głębokość ustawiona na %s.',
    merge: 'Scal',
    outer: 'Zewnętrzny',
    reset: 'Resetuj',
    tCount: 'Liczba T',
    tCount_tooltip: 'Liczba gniazd T dotyczy krótszego boku; ilość na dłuższym boku jest obliczana na podstawie jego długości.',
    tDiameter: 'Średnica T',
    text_label: 'Etykieta',
    thickness: 'Grubość',
    title: 'BOXGEN',
    tLength: 'Długość T',
    tSlot: 'T-Slot',
    tSlot_warning: 'Długość boku pudełka musi wynosić co najmniej 30 mm (1,18 cala), aby była kompatybilna z łączeniem gniazd T.',
    volume: 'Objętość',
    width: 'Szerokość',
    workarea: 'Obszar roboczy',
    zoom: 'Powiększenie',
  },
  buttons: {
    back: 'COFNIJ',
    back_to_beam_studio: 'Wróć do Beam Studio',
    done: 'GOTOWE',
    next: 'DALEJ',
  },
  calibration: {
    ador_autofocus_focusing_block: 'Naciśnij ikonę „AF” na stronie głównej maszyny na 3 sekundy i pozwól sondzie dotknąć bloku ogniskującego.',
    ador_autofocus_material: 'Naciśnij ikonę „AF” na stronie głównej maszyny na 3 sekundy i pozwól, aby sonda delikatnie dotykała materiału.',
    align_ilb: 'Pozycja: Wyrównaj do punktu wyrytego na wewnętrznej lewej dolnej stronie.',
    align_ilt: 'Pozycja: Wyrównaj do punktu wyrytego na wewnętrznej lewej górnej stronie.',
    align_irb: 'Pozycja: Wyrównaj do punktu wyrytego na wewnętrznej prawej dolnej stronie.',
    align_irt: 'Pozycja: Wyrównaj do punktu wyrytego na wewnętrznej prawej górnej stronie.',
    align_olb: 'Pozycja: Wyrównaj do punktu wyrytego na zewnątrz po lewej dolnej stronie.',
    align_olt: 'Pozycja: Wyrównaj do punktu wyrytego na zewnątrz po lewej górnej stronie.',
    align_orb: 'Pozycja: Wyrównaj do punktu wyrytego na zewnątrz po prawej dolnej stronie.',
    align_ort: 'Pozycja: Wyrównaj do punktu wyrytego na zewnątrz po prawej górnej stronie.',
    align_red_cross_cut: 'Proszę wyrównać środek czerwonego krzyża z wyciętym krzyżem.',
    align_red_cross_print: 'Proszę wyrównać środek czerwonego krzyża z nadrukowanym krzyżykiem.',
    analyze_result_fail: 'Nie udało się przeanalizować przechwyconego obrazu.<br/>Upewnij się:<br/>1. Zrobione zdjęcie w całości pokryte białym papierem.<br/>2. Platforma jest odpowiednio skupiona.',
    ask_for_readjust: 'Czy chcesz pominąć krok grawerowania, a następnie zrobić zdjęcie i skalibrować bezpośrednio?',
    back: 'Wróć',
    calculating_camera_matrix: 'Obliczanie macierzy kamery...',
    calculating_regression_parameters: 'Obliczanie parametrów regresji...',
    calibrate_camera_before_calibrate_modules: 'Przed kalibracją modułów należy przeprowadzić kalibrację kamery.',
    calibrate_chessboard_success_msg: 'Pomyślnie uchwycono zdjęcie szachownicy.<br/>Wynik dla tego zdjęcia to %s (%.2f).',
    calibrate_done: 'Kalibracja wykonana. Lepsza dokładność aparatu jest zapewniona przy precyzyjnym ustawianiu ostrości.',
    calibrate_done_diode: 'Kalibracja wykonana. Przesunięcie modułu lasera diodowego zostało zapisane.',
    calibrating: 'Kalibracja...',
    calibrating_with_device_pictures: 'Kalibracja za pomocą zdjęć urządzenia...',
    camera_calibration: 'Kalibracja kamery',
    camera_parameter_saved_successfully: 'Parametry kamery zapisane pomyślnie.',
    cancel: 'Anuluj',
    check_checkpoint_data: 'Dane punktu kontrolnego',
    check_device_pictures: 'Sprawdź zdjęcia urządzenia',
    checking_checkpoint: 'Sprawdzanie danych punktu kontrolnego...',
    checking_pictures: 'Sprawdzanie zdjęć urządzenia...',
    diode_calibration: 'Kalibracja modułu lasera diodowego',
    do_engraving: 'Wykonaj Grawerowanie',
    download_chessboard_file: 'Pobierz plik szachownicy',
    downloading_checkpoint: 'Pobieranie danych punktu kontrolnego...',
    downloading_pictures: 'Pobieranie zdjęć...',
    drawing_calibration_image: 'Tworzenie obrazu kalibracji...',
    dx: 'X',
    dy: 'Y',
    elevate_and_cut: 'Podnieś i Wytnij',
    elevate_and_cut_step_1: 'Umieść jasno kolorowany kawałek drewna o rozmiarze A4 na środku powierzchni roboczej, unieś go na wysokość 20mm.',
    elevate_and_cut_step_1_prism_lift: 'Użyj Ador Prism Lift z jego maksymalną długością 14 mm wraz z drewnem o grubości co najmniej 6 mm, aby podnieść drewno do wysokości 20 mm.',
    failed_to_calibrate_camera: '#848 Nie udało się skalibrować kamery. Skontaktuj się z pomocą techniczną FLUX.',
    failed_to_calibrate_chessboard: 'Nie udało się skalibrować z obrazem szachownicy.',
    failed_to_calibrate_with_pictures: '#848 Nie udało się skalibrować za pomocą zdjęć urządzenia.',
    failed_to_download_pictures: '#848 Nie udało się pobrać zdjęć. Skontaktuj się z pomocą techniczną FLUX.',
    failed_to_move_laser_head: 'Nie udało się przemieścić głowicy lasera.',
    failed_to_parse_checkpoint: 'Nie udało się przeanalizować danych punktu kontrolnego.',
    failed_to_save_calibration_results: '#849 Nie udało się zapisać wyników kalibracji. Spróbuj ponownie. Jeśli to się będzie powtarzać, skontaktuj się z pomocą techniczną FLUX.',
    failed_to_save_camera_parameter: 'Nie udało się zapisać parametrów kamery.',
    failed_to_solve_pnp: 'Nie udało się rozwiązać pozycji kamery.',
    finish: 'Gotowe',
    found_checkpoint: 'Dane punktu kontrolnego znalezione na twoim urządzeniu. Czy chcesz przywrócić z punktu kontrolnego?',
    getting_plane_height: 'Pobieranie Wysokości Płaszczyzny...',
    hint_adjust_parameters: 'Użyj tych parametrów, aby dostosować czerwony kwadrat',
    hint_red_square: 'Wyrównaj zewnętrzną stronę czerwonego kwadratu z wyciętym kwadratem.',
    module_calibration_2w_ir: 'Kalibracja modułu podczerwieni',
    module_calibration_printer: 'Kalibracja modułu drukarki',
    moving_laser_head: 'Przemieszczanie głowicy lasera...',
    next: 'Dalej',
    no_picutre_found: '#846 Twoje urządzenie nie ma dostępnych zdjęć w formacie raw do kalibracji. Skontaktuj się z obsługą FLUX.',
    perform_autofocus_bb2: 'Proszę przejść do panelu sterowania maszyny i nacisnąć AF, aby wykonać autofokus.',
    please_do_camera_calibration_and_focus: {
      beambox: 'Podczas kalibracji modułu lasera diodowego potrzebna jest kamera.\nUpewnij się, że kamera tego urządzenia została skalibrowana.\nI uprzejmie dostosuj platformę do punktu ogniskowego',
      beamo: 'Do kalibracji modułu lasera diodowego potrzebna jest kamera.\nUpewnij się, że kamera tego urządzenia została skalibrowana.\nI uprzejmie dostosuj głowicę lasera, do punktu ogniskowego',
    },
    please_goto_beambox_first: 'Aby skorzystać z tej funkcji, przełącz się na tryb grawerowania (Beambox).',
    please_place_dark_colored_paper: 'Proszę umieścić ciemny papier formatu A4 lub Letter na środku obszaru roboczego.',
    please_place_paper: 'Proszę umieścić białą kartkę A4 w lewym górnym rogu obszaru roboczego.',
    please_place_paper_center: 'Proszę umieścić białą kartkę A4 w lewym górnym rogu obszaru roboczego',
    please_refocus: {
      beambox: 'Dostosuj wysokość soczewki do punktu ogniskowej na powierzchni kartki',
      beamo: 'Dostosuj głowicę lasera, aby skupić się na grawerowanym obiekcie',
      beamo_af: 'Dotknij dwukrotnie bocznego przycisku dodatku autofokusa, aby sonda delikatnie dotknęła materiału.',
      hexa: 'Kliknij dwukrotnie przycisk regulacji wysokości, aby podnieść stół o strukturze plastra miodu i sprawić, by sonda dotknęła materiału do grawerowania.',
    },
    preparing_to_take_picture: 'Przygotowanie do zrobienia zdjęcia...',
    promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    put_chessboard: 'Umieść Szachownicę',
    put_chessboard_1: 'Proszę przejść do panelu sterowania maszyny i nacisnąć AF, aby wykonać autofokus.',
    put_chessboard_2: 'Proszę przesunąć papier szachownicy lub głowicę lasera, aż cały szachownica zostanie potwierdzona w czerwonej ramce w oknie podglądu na żywo.',
    put_chessboard_3: 'Kliknij prawym przyciskiem myszy, aby pobrać obraz szachownicy w bieżącej lokalizacji i sprawdzić jego wyrazistość.',
    put_chessboard_bb2_desc_1: 'Proszę pobrać poniższy plik szachownicy i wydrukować go na papierze A4 (wydrukowana szachownica powinna mieć pola o wymiarach 1x1 cm).',
    put_chessboard_bb2_desc_2: 'Zamocuj wydrukowaną szachownicę na nienaruszalnej płycie, takiej jak akryl lub szkło, upewniając się, że szachownica jest gładka i pozbawiona zmarszczek lub uniesionych krawędzi.',
    put_chessboard_bb2_desc_3: 'Umieść płytę z szachownicą płasko w centrum obszaru roboczego.',
    put_chessboard_promark_1: 'Dostosuj odległość ogniskową do odpowiedniego punktu skupienia dla soczewki polowej.',
    put_chessboard_promark_2: 'Upewnij się, że papier z szachownicą jest wyraźnie widoczny bez odblasków. Kliknij "Dalej", aby zrobić zdjęcie.',
    put_chessboard_promark_desc_1: 'Proszę użyć papieru z szachownicą dostarczonego w pudełku z akcesoriami lub wydrukować poniższy wzór szachownicy na papierze A4 do kalibracji kamery.',
    put_chessboard_promark_desc_2: 'Umieść szachownicę płasko i na środku platformy roboczej.',
    put_paper: 'Połóż Papier',
    put_paper_promark_1: 'Umieść czarną tekturę z pudełka z akcesoriami na platformie roboczej.',
    put_paper_promark_2: 'Ustaw ostrość prawidłowo, a następnie kliknij "Rozpocznij Grawerować", aby kontynuować grawerowanie.',
    put_paper_skip: 'Jeśli rysunek kalibracyjny nie przejdzie automatycznie do robienia zdjęcia, naciśnij „Pomiń”, aby zrobić zdjęcie.',
    put_paper_step1: 'Proszę umieścić biały papier w formacie A4 lub Letter w centrum obszaru roboczego.',
    put_paper_step2: 'Zamocuj cztery rogi papieru, aby upewnić się, że leży płasko.',
    put_paper_step3: 'Kliknij "Rozpocznij Grawerować".',
    res_average: 'Średni',
    res_excellent: 'Doskonały',
    res_poor: 'Słaby',
    retake: 'Powtórz zdjęcie',
    rotation_angle: 'Obrotu',
    show_last_config: 'Pokaż ostatni wynik',
    skip: 'Pomiń',
    solve_pnp_step1: 'Proszę wyrównać punkty grawerowania zgodnie z numerem i oczekiwaną pozycją każdego czerwonego punktu oznaczenia.',
    solve_pnp_step2: 'Możesz nacisnąć przycisk "Powtórz zdjęcie" w celu ponownego wyrównania lub ręcznej regulacji pozycji znaczników.',
    solve_pnp_step3: 'Jeśli uchwycony obraz nie odpowiada rzeczywistej scenie, proszę powiększyć lub przesunąć ekran, aby dostosować punkt kalibracji na czarnej karcie kalibracyjnej do środka, a następnie spróbować "Powtórz zdjęcie".',
    solve_pnp_title: 'Wyrównaj punkty znacznikowe',
    start_engrave: 'Rozpocznij Grawerować',
    start_printing: 'Rozpocznij Drukowanie',
    taking_picture: 'Robienie zdjęcia...',
    unable_to_load_camera_parameters: "#851 Na twoim urządzeniu nie ma dostępnych parametrów kalibracji kamery. Przejdź do 'Kalibracja' > 'Kalibracja kamery (Zaawansowane)', aby ukończyć kalibrację i uzyskać parametry.",
    update_firmware_msg1: 'Twoje oprogramowanie układowe nie obsługuje tej funkcji. Zaktualizuj oprogramowanie układowe do wersji v',
    update_firmware_msg2: 'lub nowszy, aby kontynuować。 (Menu > Maszyna > [Twoja maszyna] > Aktualizuj oprogramowanie sprzętowe)',
    uploading_images: 'Przesyłanie obrazów...',
    use_last_config: 'Użyj wartości ostatniej kalibracji',
    use_old_camera_parameter: 'Czy chcesz użyć bieżących parametrów obiektywu aparatu?',
    with_af: 'Z autofokusem',
    with_prism_lift: 'Z Ador Prism Lift',
    without_af: 'Bez autofokusa',
    without_prism_lift: 'Bez Ador Prism Lift',
    x_ratio: 'Współczynnik X',
    y_ratio: 'Współczynnik Y',
    zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
  },
  camera_data_backup: {
    download_success: 'Pomyślnie pobrano dane z kamery.',
    downloading_data: 'Pobieranie danych...',
    estimated_time_left: 'Szacowany pozostały czas:',
    folder_not_exists: 'Wybrany folder nie istnieje.',
    incorrect_folder: 'Nie udało się przesłać danych kalibracji. Sprawdź, czy wybrany folder jest poprawny.',
    no_picture_found: 'Nie znaleziono obrazu w urządzeniu.',
    title: 'Kopiuj dane kamery',
    upload_success: 'Pomyślnie przesłano dane z kamery.',
    uploading_data: 'Przesyłanie danych...',
  },
  caption: {
    connectionTimeout: 'Czas połączenia minął',
  },
  change_logs: {
    added: 'Dodane:',
    change_log: 'Dzienniki zmian:',
    changed: 'Zmienione:',
    fixed: 'Naprawione:',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    see_older_version: 'Zobacz starsze wersje',
  },
  code_generator: {
    barcode: 'Kod kreskowy',
    qr_code: 'Kod QR',
  },
  curve_engraving: {
    '921': 'Nie udało się ustawić ostrości automatycznie.',
    '922': 'Nie udało się przeprowadzić pomiaru światłem czerwonym.',
    amount: 'Ilość',
    apply_arkwork: 'Zastosuj dzieło sztuki na krzywej',
    apply_camera: 'Zastosuj obraz z kamery na krzywej',
    click_to_select_point: 'Kliknij, aby wybrać lub odznaczyć punkty do ponownego pomiaru.',
    coloumns: 'Kolumny',
    column_gap: 'Odstęp między kolumnami',
    err_object_over_range: 'Obiekt przekracza zakres pomiarowy.',
    failed_to_take_reference: 'Nie udało się pobrać odniesienia',
    gap: 'Przerwa',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    measure_audofocus_area: 'Zmierz obszar autofokusu',
    preview_3d_curve: 'Podgląd grawerowania krzywej',
    remeasure: 'Ponownie zmierz',
    remeasuring_points: 'Ponowne mierzenie punktów...',
    reselect_area: 'Ponownie wybierz obszar',
    row_gap: 'Odstęp między rzędami',
    rows: 'Rzędy',
    set_object_height: 'Ustaw wysokość obiektu',
    set_object_height_desc: 'Zmierz maksymalną grubość obiektu.',
    start_autofocus: 'Rozpocznij autofokus',
    starting_measurement: 'Rozpoczynanie pomiaru...',
    sure_to_delete: 'Czy chcesz usunąć dane ogniskowania grawerowania krzywej?',
    take_reference: 'Pobierz referencję',
    take_reference_desc: 'Proszę przesunąć głowicę lasera na najwyższy punkt obiektu, opuścić sondę ostrości i kliknąć „Potwierdź”, aby ustawić ostrość.',
  },
  device: {
    abort: 'Przerwij',
    aborted: 'Wstrzymany',
    aborting: 'Przerywanie',
    busy: 'Zajęty',
    cartridge_info_read_failed: 'Upewnij się, że tusz jest w pełni włożony. Spróbuj wyjąć tusz i zainstaluj go ponownie.',
    cartridge_info_verification_failed: 'Proszę sprawdzić, czy tusz jest autentyczny FLUX.',
    cartridge_serial_number: 'Numer seryjny wkładu atramentowego',
    close_door_to_read_cartridge_info: 'Aby uzyskać dostęp do informacji o tuszu, proszę zamknąć pokrywę drzwi maszyny.',
    completed: 'Zakończony',
    completing: 'Zakańczanie',
    deviceList: 'Lista maszyn',
    disable: 'Wyłącz',
    disconnectedError: {
      caption: 'Maszyna rozłączona',
      message: 'Potwierdź, czy dostęp do sieci %s jest dostępny',
    },
    enable: 'Włącz',
    firmware_version: 'Wersja oprogramowania',
    ink_color: 'Kolor tuszu',
    ink_level: 'Poziom atramentu',
    ink_type: 'Typ tuszu',
    IP: 'IP',
    model_name: 'Nazwa modelu',
    occupied: 'Utrzymywanie',
    pause: 'Wstrzymaj',
    paused: 'Wstrzymany',
    pausedFromError: 'Wstrzymany z powodu błędu',
    pausing: 'Wstrzymywanie',
    please_wait: 'Proszę czekać...',
    preparing: 'Przygotowywanie',
    processing: 'Przetwarzanie',
    quit: 'Wyjdź',
    ready: 'Gotowy',
    reset: 'Resetuj',
    resuming: 'Wznawianie',
    retry: 'Spróbuj ponownie',
    running: 'Pracuje',
    scanning: 'Skanowanie',
    select: 'Wybierz',
    select_printer: 'Wybierz drukarkę',
    serial_number: 'Numer seryjny',
    start: 'Rozpocznij',
    starting: 'Rozpoczynanie',
    status: 'Status',
    submodule_type: 'Moduł',
    toolhead_change: 'Zmień głowicę narzędzia',
    unknown: 'Nieznany',
    uploading: 'Przesyłanie',
    UUID: 'UUID',
  },
  device_selection: {
    no_beambox: '#801 Nie możemy znaleźć Twojej maszyny w sieci.',
    no_device_web: '#801 Sprawdź status swojej maszyny lub kliknij poniżej „Konfiguracja maszyny”, aby skonfigurować maszynę.',
    select_usb_device: 'Wybierz urządzenie USB',
  },
  editor: {
    exposure: 'Podgląd naświetlenia',
    opacity: 'Podgląd przezroczystości',
    prespray_area: 'Obszar gruntowania',
  },
  error_pages: {
    screen_size: 'Należy pamiętać, że Beam Studio może nie działać optymalnie na Twoim urządzeniu. Aby uzyskać optymalne wrażenia, Twoje urządzenie powinno mieć ekran o minimalnej szerokości 1024 pikseli.',
  },
  flux_id_login: {
    connection_fail: '#847 Połączenie z usługą członkowską FLUX nie powiodło się.',
    email: 'E-mail',
    flux_plus: {
      access_monotype_feature: 'Nie masz dodatku Czcionki Monotype.',
      access_monotype_feature_note: 'Musisz mieć członkostwo FLUX+ Pro lub dodatek Czcionki Monotype, aby uzyskać dostęp do tej funkcji.',
      access_plus_feature_1: 'Uzyskujesz dostęp do funkcji',
      access_plus_feature_2: 'funkcja.',
      access_plus_feature_note: 'Musisz mieć członkostwo FLUX+, aby uzyskać dostęp do tej funkcji.',
      ai_credit_tooltip: 'Dla usuwania tła AI',
      explore_plans: 'Zapoznaj się z planami FLUX+',
      features: {
        ai_bg_removal: 'Usuwanie tła AI',
        boxgen: 'Generator pudełek 3D',
        dmkt: '1000+ plików projektowych',
        monotype: '250+ czcionek premium',
        my_cloud: 'Nielimitowana przestrzeń w chmurze',
      },
      flux_credit_tooltip: 'Dla plików Design Market i usuwania tła AI',
      get_addon: 'Pobierz dodatek',
      goto_member_center: 'Przejdź do Centrum członkowskiego',
      learn_more: 'Dowiedz się więcej',
      member_center_url: 'https://member.flux3dp.com/en-US/subscription',
      subscribe_now: 'Zapisz się teraz',
      thank_you: 'Dziękujemy za bycie cennym członkiem!',
      website_url: 'https://flux3dp.com/subscription',
    },
    forget_password: 'Zapomniałeś hasła?',
    incorrect: 'Adres e-mail lub hasło są nieprawidłowe.',
    login: 'Zaloguj sie',
    login_success: 'Zalogowano się pomyślnie.',
    lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
    new_to_flux: 'Nowy we FLUXie? Utwórz konto.',
    not_verified: 'Adres e-mail nie został jeszcze zweryfikowany.',
    offline: 'Pracuj offline',
    password: 'Hasło',
    register: 'Utwórz swoje konto FLUX',
    remember_me: 'Zapamiętaj mnie',
    signup_url: 'https://id.flux3dp.com/user/login#up',
    unlock_shape_library: 'Zaloguj się, aby odblokować bazę kształtów.',
    work_offline: 'Pracuj offline',
  },
  framing: {
    area_check: 'Sprawdzenie obszaru',
    areacheck_desc: 'Zapewnia bezpieczeństwo obszaru roboczego poprzez podgląd ramki ograniczającej obiekt i strefy przyspieszenia głowicy lasera.',
    calculating_task: 'Obliczanie zadania...',
    framing: 'Ramowanie',
    framing_desc: 'Podgląd ramki ograniczającej obiekt.',
    hull: 'Kadłub',
    hull_desc: 'Wyświetla podgląd kształtu, który ściśle podąża za projektem, jak gumka owinięta wokół obiektu.',
    low_laser: 'Niski laser',
    low_laser_desc: 'Ustaw niską moc lasera dla zadania ramowania.',
    rotate_axis: 'Oś obrotu',
    rotateaxis_desc: 'Podgląd osi obrotu, sama oś się nie porusza.',
    rotation_frame_warning: 'Podgląd ramki obróci się raz. Unikaj zatrzymywania się w połowie, aby zapobiec przesunięciu pozycji znakowania.',
    rotation_framing_desc: 'Podgląd pełnego zakresu obrotu, oś obraca się zgodnie z podglądem.',
    start_task: 'Rozpocznij Zadanie',
    start_task_description: 'Po zakończeniu podglądu ramki kliknij „Rozpocznij Zadanie”, aby przesłać zadanie.',
  },
  general: {
    choose_folder: 'Wybierz folder',
    processing: 'Przetwarzanie...',
  },
  generic_error: {
    OPERATION_ERROR: '[OE] Wystąpił konflikt statusu, spróbuj ponownie.',
    SUBSYSTEM_ERROR: '[SE] Błąd podczas wykonywania zadania przez oprogramowanie ukałdowe urządzenia. Proszę ponownie uruchomić maszynę.',
    UNKNOWN_COMMAND: '[UC] Zaktualizuj oprogramowanie sprzętowe urządzenia',
    UNKNOWN_ERROR: '[UE] Wystąpił nieznany błąd. Uruchom ponownie Beam Studio i maszynę.',
  },
  global: {
    apply: 'Zastosuj',
    back: 'Wróć',
    cancel: 'Anuluj',
    editing: {
      redo: 'Ponów',
      reset: 'Zresetuj',
      undo: 'Cofnij',
      zoom_in: 'Powiększ',
      zoom_out: 'Pomniejsz',
    },
    mode_conflict: 'Ta opcja nie jest dostępna w bieżącym trybie.',
    ok: 'OK',
    save: 'Zapisz',
    stop: 'Stop',
  },
  image_edit_panel: {
    eraser: {
      brush_size: 'Rozmiar Pędzla',
      description: 'Kliknij lub przeciągnij, aby ręcznie usunąć niechciane obszary.',
      title: 'Gumka',
    },
    magic_wand: {
      description: 'Wybierz i usuń sąsiadujące obszary na podstawie podobieństwa kolorów.',
      title: 'Magiczna Różdżka',
      tolerance: 'Tolerancja',
    },
    title: 'Edytuj Obraz',
  },
  initialize: {
    back: 'Cofnij',
    cancel: 'Anuluj',
    confirm: 'Potwierdzać',
    connect: 'Połącz',
    connect_ethernet: {
      title: 'Połączenie bezpośrednie',
      tutorial1: '1. Podłącz urządzenie do komputera kablem ethernetowym.',
      tutorial2_1: '2. Śledź ',
      tutorial2_2: ' aby ustawić Twój komputer jako router',
      tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
      tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
      tutorial2_a_text: 'ten przewodnik',
      tutorial3: '3. Kliknij Dalej.',
    },
    connect_machine_ip: {
      alert: {
        swiftray_connection_error: 'Nie udało się połączyć z serwerem. Uruchom ponownie Beam Studio i spróbuj ponownie.',
      },
      check_camera: 'Sprawdzanie dostępności kamery',
      check_connection: 'Sprawdzanie połączenia maszyny',
      check_firmware: 'Sprawdzanie wersji oprogramowania układowegon',
      check_ip: 'Sprawdzanie dostępności adresu IP',
      check_swiftray_connection: 'Sprawdzanie połączenia z serwerem',
      check_swiftray_connection_unreachable: 'serwer nieosiągalny',
      check_usb: 'Sprawdź połączenie USB',
      enter_ip: 'Wprowadź adres IP Swojej maszyny',
      finish_setting: 'Zacznij tworzyć!',
      invalid_format: 'Niepoprawny format',
      invalid_ip: 'Nieprawidłowy adres IP:: ',
      promark_hint: 'Jeśli wielokrotnie nie można nawiązać połączenia przez USB, zapoznaj się z <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">artykułem w Centrum Pomocy</a>.',
      retry: 'Spróbuj ponownie',
      starts_with_169254: 'Zaczyna się od 169.254',
      succeeded_message: 'Zakończ ustawianie 🎉',
      unreachable: 'IP nieosiągalne',
    },
    connect_usb: {
      connect_camera: 'Podłącz kamerę maszyny do komputera za pomocą kabla USB.',
      title: 'Połączenie USB',
      title_sub: '(tylko HEXA)',
      turn_off_machine: 'Twyłącz maszynę.',
      turn_on_machine: 'Włącz maszynę.',
      tutorial1: 'Podłącz urządzenie do komputera kablem USB.',
      tutorial2: "Kliknij 'Dalej'.",
      wait_for_turning_on: "Kliknij 'Dalej' after the startup process is complete and you have entered the main screen.",
    },
    connect_wifi: {
      title: 'Łączenie z Wi-Fi',
      tutorial1: '1. Przejdź do Panelu dotykowego > Kliknij „Sieć” > „Połącz z Wi-Fi”.',
      tutorial1_ador: '1. Przejdź do Panelu dotykowego > Kliknij „MASZYNA” > Kliknij „Sieć” > „Połącz się z Wi-Fi”.',
      tutorial2: '2. Wybierz i połącz z preferowaną siecią Wi-Fi.',
      what_if_1: 'Co zrobić, jeśli nie widzę mojego Wi-Fi?',
      what_if_1_content: '1. Tryb szyfrowania Wi-Fi powinien być WPA2 lub bez hasła.Tryb szyfrowania można ustawić w panelu administracyjnym routera Wi-Fi.Jeśli router nie obsługuje standardu WPA2 i potrzebujesz pomocy w doborze odpowiedniego routera, skontaktuj się z pomocą techniczną FLUX.',
      what_if_2: 'Co jeśli nie widzę żadnego Wi-Fi?',
      what_if_2_content: '1. Upewnij się że adapter Wi-Fi jest dobrze podłączony. Jeśli na ekranie dotykowym nie ma adresu MAC sieci bezprzewodowej, skontaktuj się z pomocą techniczną FLUX. Kanał Wi-Fi powinien mieć częstotliwość 2,4 GHz (5 GHz nie jest obsługiwane).',
    },
    connect_wired: {
      title: 'Podłączenie do sieci przewodowej',
      tutorial1: '1. Połącz urządzenie z routerem.',
      tutorial2: '2. Naciśnij „Sieć”, aby uzyskać adres IP sieci przewodowej.',
      tutorial2_ador: '2. Prasa „MASZYNA” > „Sieć”, aby uzyskać adres IP sieci przewodowej.',
      what_if_1: 'Co jeśli adres IP jest pusty?',
      what_if_1_content: '1. Upewnij się, że kabel Ethernet jest dobrze podłączony.\n2. Jeśli na ekranie dotykowym nie ma adresu MAC sieci przewodowej, skontaktuj się z pomocą techniczną FLUX.',
      what_if_2: 'Co jeśli adres IP zaczyna się od 169?',
      what_if_2_content: '1. Jeśli adres IP zaczyna się od 169.254, powinien to być problem z ustawieniem DHCP, skontaktuj się ze swoim dostawcą usług internetowych w celu uzyskania dalszej pomocy. Jeśli Twój komputer łączy się z Internetem bezpośrednio za pomocą protokołu PPPoE, zmień sposób korzystania z routera na łączenie za pomocą protokołu PPPoE i włącz funkcję DHCP w routerze.',
    },
    connecting: 'Łączę......',
    connection_types: {
      ether2ether: 'Połączenie bezpośrednie',
      usb: 'Połączenie USB',
      wifi: 'Wi-Fi',
      wired: 'Sieć przewoda',
    },
    next: 'Następny',
    no_machine: 'Nie mam teraz maszyny.',
    promark: {
      configuration_confirmation: 'To zapewnia, że Twój Promark jest poprawnie skonfigurowany dla optymalnej wydajności i dokładności.',
      or_complete_later: `Lub pomiń ten krok i dokończ ustawienia Promark później w:
    Maszyny > „Nazwa Promark” > Ustawienia Promark`,
      qc_instructions: 'Wypełnij parametry znajdujące się na odwrocie karty „QC Pass”',
      select_laser_source: 'Wybierz swój Promark',
      select_workarea: 'Wybierz Obszar Roboczy',
      settings: 'Ustawienia Promark',
    },
    retry: 'Spróbuj ponownie',
    select_beambox: 'Wybierz swój Beambox',
    select_connection_type: 'Jak chcesz się połączyć?',
    select_language: 'Wybierz język',
    select_machine_type: 'Wybierz typ swojej maszyny',
    setting_completed: {
      back: 'Wróć',
      great: 'Witamy w Beam Studio',
      ok: 'ZACZNIJ TWORZYĆ',
      setup_later: 'Zawsze możesz skonfigurować Swoją maszynę, wybierając z górnego paska menu > „Maszyny” > „Ustawienia maszyny”',
      start: 'Start',
    },
    skip: 'Pomiń',
    start: 'Start',
  },
  input_machine_password: {
    connect: 'POŁĄCZ',
    password: 'Hasło',
    require_password: '"%s" wymaga hasła',
  },
  insecure_websocket: {
    extension_detected: 'Wykryto rozszerzenie Beam Studio Connect',
    extension_detected_description: 'Wykryliśmy, że zainstalowałeś rozszerzenie Beam Studio Connect. Kliknij "Potwierdź", aby przekierować do protokołu HTTPS, lub kliknij "Anuluj", aby kontynuować korzystanie z protokołu HTTP.',
    extension_not_deteced: 'Nie można wykryć rozszerzenia Beam Studio Connect',
    extension_not_deteced_description: "Aby używać HTTPS, kliknij 'Potwierdź', aby zainstalować rozszerzenie Beam Studio Connect. Po zainstalowaniu rozszerzenia odśwież stronę, aby je aktywować.<br/>W przeciwnym razie kliknij poniższy link, aby zobaczyć, jak używać HTTP w Chrome.",
    unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Link</a>',
  },
  layer_module: {
    general_laser: 'Laser',
    laser_2w_infrared: '2W Podczerwień Laser',
    laser_10w_diode: '10W Dioda Laser',
    laser_20w_diode: '20W Dioda Laser',
    non_working_area: 'Obszar Niepracujący',
    none: 'brak',
    notification: {
      convertFromLaserModuleMsg: 'Należy pamiętać, że po wykonaniu tej operacji ustawienia warstwy lasera zostaną usunięte i ustawione zgodnie z bieżącą warstwą.',
      convertFromLaserModuleTitle: 'Czy chcesz przekonwertować moduł lasera na moduł drukowania?',
      convertFromPrintingModuleMsg: 'Należy pamiętać, że po wykonaniu tej operacji ustawienia kolorów warstwy druku zostaną usunięte i ustawione zgodnie z bieżącą warstwą.',
      convertFromPrintingModuleTitle: 'Czy chcesz przekonwertować moduł drukowania na moduł lasera?',
      importedDocumentContainsPrinting: 'Dokument zawiera warstwę do druku, czy chcesz zmienić obszar roboczy na Ador?',
      performIRCaliMsg: 'Kliknij „Potwierdź”, aby wykonać kalibrację lub przejdź do kalibracji z górnego menu. ( Urządzenie > [Nazwa Twojego urządzenia] > Kalibruj moduł podczerwieni)',
      performIRCaliTitle: 'Wykonaj kalibrację modułu podczerwieni',
      performPrintingCaliMsg: 'Kliknij „Potwierdź”, aby wykonać kalibrację lub przejdź do kalibracji z górnego menu.( Urządzenie > [Nazwa Twojego urządzenia] > Kalibruj moduł drukujący)',
      performPrintingCaliTitle: 'Wykonaj kalibrację modułu drukującego',
      printingLayersCoverted: 'Warstwy drukarskie zostały zamienione na warstwy laserowe.',
    },
    printing: 'Druk',
    unknown: 'Moduł nieznany',
  },
  machine_status: {
    '-17': 'Tryb I/O kasety',
    '-10': 'Tryb utrzymania',
    '-2': 'Skanowanie',
    '-1': 'Utrzymywanie',
    0: 'Wolny',
    1: 'Inicjowanie',
    2: 'ST_TRANSFORMUJ',
    4: 'Rozpoczynanie',
    6: 'Wznawianie',
    16: 'Pracuje',
    18: 'Wznawianie',
    32: 'Wstrzymany',
    36: 'Wstrzymany',
    38: 'Wstrzymywanie',
    48: 'Wstrzymany',
    50: 'Wstrzymywanie',
    64: 'Zakończony',
    66: 'Zakańczanie',
    68: 'Przygotowywanie',
    128: 'Wstrzymany',
    256: 'Alarm',
    512: 'Znaczący',
    UNKNOWN: 'Nieznany',
  },
  material_test_generator: {
    block_settings: 'Ustawienia Bloków',
    columns: 'Kolumny',
    count: 'Liczba',
    cut: 'Wytnij',
    engrave: 'Graweruj',
    export: 'Eksportuj',
    max: 'Max',
    min: 'Min',
    parameter: 'Parametr',
    preview: 'Podgląd',
    rows: 'Wiersze',
    size: 'Rozmiar (WxS)',
    spacing: 'Odstępy',
    table_settings: 'Ustawienia Tabeli',
    text_settings: 'Ustawienia tekstu',
    title: 'Generator Testu Materiału',
  },
  menu: {
    inches: 'Cale',
    mm: 'mm',
  },
  message: {
    auth_error: '#820 Błąd autoryzacji: Zaktualizuj oprogramowanie Beam Studio i urządzenia do najnowszej wersji.',
    authenticating: 'Uwierzytelnianie...',
    camera: {
      abort_preview: 'Przerwij',
      camera_cable_unstable: 'Wykryto, że kamera niestabilnie przesyła zdjęcia. Podgląd można nadal wykonać, ale może wystąpić problem z powolnym podglądem lub przekroczeniem limitu czasu.',
      continue_preview: 'Kontynuuj',
      fail_to_transmit_image: '#845 Coś poszło nie tak z transmisją obrazu. Uruchom ponownie komputer i Beam Studio. Jeśli ten błąd będzie się powtarzał, postępuj zgodnie z <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">tym przewodnikiem</a>.',
      ws_closed_unexpectly: '#844 Połączenie z kamerą maszyny zostało niespodziewanie zamknięte. If this error persists, please follow <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">this guide</a>.',
    },
    cancelling: 'Anulowanie...',
    cant_connect_to_device: '#827 Nie można podłączyć urządzenia, sprawdź połączenie',
    connected: 'Połączony',
    connecting: 'Łączenie...',
    connectingCamera: 'Łączenie z kamerą...',
    connectingMachine: 'Łączenie %s...',
    connectionTimeout: '#805 Upłynął limit czasu połączenia urządzenia. Sprawdź stan sieci i wskaźnik Wi-Fi urządzenia.',
    device_blocked: {
      caption: 'Nieautoryzowany numer seryjny',
      offline: 'Numer seryjny urządzenia jest ograniczony do użytku offline, ponieważ wydaje się być dezaktywowany. Proszę podać numer seryjny urządzenia (znajdujący się z tyłu urządzenia) swojemu sprzedawcy i poprosić go o kontakt z support@flux3dp.com w celu aktywacji autoryzacji online. Jeśli chcesz używać urządzenia offline, skontaktuj się bezpośrednio z support@flux3dp.com.',
      online: 'Numer seryjny urządzenia wydaje się być dezaktywowany. Proszę podać numer seryjny urządzenia (znajdujący się z tyłu urządzenia) swojemu sprzedawcy i poprosić go o kontakt z support@flux3dp.com w celu aktywacji autoryzacji urządzenia.',
    },
    device_busy: {
      caption: 'Maszyna zajęta',
      message: 'Urządzenie wykonuje inne zadanie, spróbuj ponownie później. Jeśli przestanie działać, uruchom ponownie urządzenie.',
    },
    device_is_used: 'Maszyna jest używana, czy chcesz przerwać bieżące zadanie?',
    device_not_found: {
      caption: 'Nie znaleziono domyślnej maszyny',
      message: '#812 Sprawdź wskaźnik Wi-Fi urządzenia',
    },
    disconnected: 'Połączenie niestabilne. Sprawdź połączenie urządzenia i spróbuj ponownie później',
    endingLineCheckMode: 'Wychodzenie z trybu sprawdzania linii...',
    endingRawMode: 'Wychodzenie z trybu startowego...',
    enteringLineCheckMode: 'Wejście w tryb sprawdzania linii...',
    enteringRawMode: 'Wejście w tryb startowy...',
    enteringRedLaserMeasureMode: 'Wchodzenie w tryb pomiaru czerwonym laserem...',
    exitingRotaryMode: 'Wychodzenie z trybu obrotowego...',
    getProbePosition: 'Pobieranie pozycji sondy...',
    gettingLaserSpeed: 'Odczytywanie prędkości głowicy lasera...',
    homing: 'Pozycjonowanie...',
    need_password: 'Potrzebujesz hasła, aby połączyć się z urządzeniem',
    please_enter_dpi: 'Wprowadź jednostkę swojego pliku (in mm)',
    preview: {
      adjust: 'Regulować',
      adjust_height_tooltip: 'Kliknij pole wyboru, aby włączyć edycję.',
      already_performed_auto_focus: 'Wykonałeś już autofokus, używasz istniejących wartości?',
      auto_focus: 'Fokus Auto',
      auto_focus_instruction: 'Przesuń głowicę modułu laserowego nad obiekt i postępuj zgodnie z instrukcjami animacji, aby nacisnąć przycisk AF w celu ustawienia ostrości.',
      camera_preview: 'Kamera Zapowiedź',
      enter_manually: 'Wprowadzić ręcznie',
      please_enter_height: 'Proszę podać wysokość obiektu. Aby uchwycić precyzyjne ujęcie kamery.',
    },
    promark_disconnected: '#850 Połączenie z urządzeniem zostało przerwane, sprawdź status połączenia.',
    redLaserTakingReference: 'Pobieranie odniesienia...',
    retrievingCameraOffset: 'Odczyt przesunięcia kamery...',
    settingLaserSpeed: 'Ustawianie prędkości głowicy laserowej...',
    swiftray_disconnected: 'Nie można połączyć się z backendem, ponawianie próby połączenia.',
    swiftray_reconnected: 'Backend ponownie połączony, spróbuj ponownie wysłać zadanie.',
    time_remaining: 'Pozostały czas:',
    tryingToConenctMachine: 'Próba połączenia z maszyną...',
    turningOffAirPump: 'Wyłączanie pompy powietrza...',
    turningOffFan: 'Wyłączanie wentylatora...',
    unable_to_find_machine: 'Nie można znaleźć maszyny',
    unable_to_start: '#830 Nie można rozpocząć zadania. Proszę spróbuj ponownie. Jeśli sytuacja się powtórzy, skontaktuj się z nami, wysyłając raport o błędzie:\n',
    unavailableWorkarea: '#804 Bieżący obszar roboczy przekracza obszar roboczy tego urządzenia. Sprawdź obszar roboczy wybranej maszyny lub ustaw obszar roboczy w Edycja > Ustawienia dokumentu.',
    unknown_device: '#826 Nie można połączyć się z urządzeniem. Upewnij się, że urządzenie jest podłączone do portu USB',
    unknown_error: '#821 Aplikacja napotkała nieznany błąd, użyj opcji Pomoc > Menu > Raport o błędzie.',
    unsupport_osx_version: 'Bieżąca wersja systemu MacOS X %s może nie obsługiwać niektórych funkcji. Uprzejmie prosimy o aktualizację do najnowszej wersji.',
    unsupport_win_version: 'Bieżąca wersja systemu operacyjnego %s może nie obsługiwać niektórych funkcji. Uprzejmie prosimy o aktualizację do najnowszej wersji.',
    unsupported_example_file: 'Wybrany przykładowy plik nie jest obsługiwany przez bieżący obszar roboczy.',
    uploading_fcode: 'Przesyłanie FCode',
    usb_unplugged: 'Utracono połączenie USB. Sprawdź połączenie USB',
    wrong_swiftray_version_message: 'Nieprawidłowa wersja backendu (wersja: {version}). Sprawdź, czy uruchomiono wiele instancji Beam Studio i zamknij zbędne instancje przed ponowną próbą.',
    wrong_swiftray_version_title: 'Nieprawidłowa wersja backendu',
  },
  monitor: {
    ask_reconnect: 'Połączenie z maszyną zostało zerwane. Czy chcesz ponownie się połączyć?',
    bug_report: 'Zgłoszenie błędu',
    camera: 'Kamera',
    cancel: 'Anuluj',
    confirmFileDelete: 'Czy na pewno chcesz usunąć ten plik?',
    connecting: 'Trwa łączenie, proszę czekać...',
    DEVICE_ERROR: 'Coś poszło nie tak\nProszę ponownie uruchomić komputer', // Deprecated in FW 3.3.1
    download: 'Pobierz',
    extensionNotSupported: 'Ten format pliku nie jest obsługiwany',
    fileExistContinue: 'Plik już istnieje, czy chcesz go zastąpić?',
    forceStop: 'Czy chcesz przerwać bieżące zadanie?',
    go: 'Rozpocznij',
    HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Nieprawidłowość czujnika przepływu powietrza',
    HARDWARE_ERROR_BOTTOM_OPENED: '#903 Dno otwarte. Zamknij dno, aby kontynuować.',
    HARDWARE_ERROR_DOOR_OPENED: '#901 Zamknij drzwi, aby kontynuować.',
    HARDWARE_ERROR_DRAWER_OPENED: '#911 Szuflada otwarta',
    HARDWARE_ERROR_FIRE_DETECTED: '#912 Nieprawidłowość czujnika płomienia',
    HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Wykryto nieprawidłowy moduł. Aby kontynuować, zainstaluj odpowiedni moduł.',
    HARDWARE_ERROR_HEADTYPE_NONE: '#917 Nie wykryto modułu. Aby kontynuować, upewnij się, że moduł jest poprawnie zainstalowany.',
    HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Wykryto nieznany moduł. Aby kontynuować, zainstaluj odpowiedni moduł.',
    HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Błąd odrywania od pozycji bazowej',
    HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Błąd krytyczny: płyta główna odłączona. Skontaktuj się z pomocą techniczną FLUX.', // Deprecated in FW 3.3.1
    HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Przegrzanie. Poczekaj kilka minut.',
    HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Resetowanie osi Z nie powiodło się',
    HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Moduł drukarki brak reakcji.',
    HARDWARE_ERROR_PROBE_SHOWED: 'Proszę cofnąć sondę.',
    HARDWARE_ERROR_PUMP_ERROR: '#900 Sprawdź w swoim zbiorniku na wodę.',
    HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Nie wykryto modułu obrotowego',
    HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Błąd krytyczny: Brak odpowiedzi podsystemu. Skontaktuj się z pomocą techniczną FLUX.', // Deprecated in FW 3.3.1
    HARDWARE_FAILURE: 'Coś poszło nie tak\nProszę ponownie uruchomić komputer', // Deprecated in FW 3.3.1
    hour: 'h',
    left: 'Pozostało',
    MAINBOARD_OFFLINE: '#905 Błąd podczas łączenia z płytą główną.\nPlease restart the machine. ',
    minute: 'm',
    monitor: 'MONITOR',
    NO_RESPONSE: '#905 Błąd podczas łączenia z płytą główną. Proszę ponownie uruchomić maszynę.',
    pause: 'Wstrzymaj',
    prepareRelocate: 'Przygotowanie do przeniesienia',
    processing: 'Przetwarzanie',
    record: 'Nagraj',
    relocate: 'Przenieś',
    RESOURCE_BUSY: 'Maszyna jest zajęta\nJeśli nie działa, uruchom ponownie maszynę',
    resume: 'Wznawiać',
    savingPreview: 'Generowanie miniatur',
    second: 's',
    start: 'Rozpocznij',
    stop: 'Zatrzymaj',
    SUBSYSTEM_ERROR: '#402 Błąd krytyczny: Brak odpowiedzi podsystemu. Skontaktuj się z pomocą techniczną FLUX.',
    task: {
      BEAMBOX: 'Grawerowanie laserowe',
      'N/A': 'Tryb wolny',
    },
    taskTab: 'Zadanie',
    temperature: 'Temperatura',
    upload: 'Wgraj',
    USER_OPERATION: 'Aby kontynuować, postępuj zgodnie z instrukcjami na panelu urządzenia.',
    USER_OPERATION_CHANGE_CARTRIDGE: 'Aby kontynuować, włóż właściwy kartridż.',
    USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Aby kontynuować, włóż kartridż.',
    USER_OPERATION_CHANGE_TOOLHEAD: 'Aby kontynuować, zainstaluj odpowiedni moduł.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Wykryto nieprawidłowy moduł. Aby kontynuować, zainstaluj odpowiedni moduł.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Nie wykryto modułu. Aby kontynuować, upewnij się, że moduł jest poprawnie zainstalowany.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Wykryto nieznany moduł. Aby kontynuować, zainstaluj odpowiedni moduł.',
    USER_OPERATION_REMOVE_CARTRIDGE: 'Aby kontynuować, wyjmij wkład.',
    USER_OPERATION_ROTARY_PAUSE: 'Proszę przełączyć się na silnik obrotowy.',
  },
  my_cloud: {
    action: {
      confirmFileDelete: 'Czy na pewno chcesz usunąć ten plik? Tej akcji nie można cofnąć.',
      delete: 'Usuń',
      download: 'Pobierz',
      duplicate: 'Duplikuj',
      open: 'Otwórz',
      rename: 'Zmień nazwę',
    },
    file_limit: 'Bezpłatny plik',
    loading_file: 'Ładowanie...',
    no_file_subtitle: 'Przejdź do Menu > "Plik" > "Zapisz w chmurze"',
    no_file_title: 'Zapisz pliki w Mojej chmurze, aby rozpocząć.',
    save_file: {
      choose_action: 'Zapisz plik:',
      input_file_name: 'Wprowadź nazwę pliku:',
      invalid_char: 'Nieprawidłowe znaki:',
      save: 'Zapisz',
      save_new: 'Zapisz jako nowy plik',
      storage_limit_exceeded: 'Twoja pamięć w chmurze osiągnęła górny limit. Usuń niepotrzebne pliki przed zapisaniem nowych.',
    },
    sort: {
      a_to_z: 'Nazwa: A - Z',
      most_recent: 'Najnowsze',
      oldest: 'Najstarsze',
      z_to_a: 'Nazwa: Z - A',
    },
    title: 'Moja chmura',
    upgrade: 'Uaktualnij',
  },
  noun_project_panel: {
    clear: 'Wyczyść',
    elements: 'Elementy',
    enjoy_shape_library: 'Korzystaj z bazy kształtów.',
    export_svg_title: 'Nie można wyeksportować pliku SVG',
    export_svg_warning: 'Ten projekt zawiera obiekty chronione prawem własności intelektualnej. Dlatego Beam Studio automatycznie wykluczy te obiekty podczas eksportu. Nadal możesz zapisać swój projekt w Beam Studio Scene (format .beam), aby zachować wszystkie dane. Czy nadal chcesz eksportować?',
    learn_more: 'Dowiedz się więcej',
    login_first: 'Zaloguj się, aby odblokować bazę kształtów.',
    recent: 'Ostatnie',
    search: 'Szukaj',
    shapes: 'Kształty',
  },
  pass_through: {
    export: 'Eksportuj do Obszaru Roboczego',
    exporting: 'Eksportowanie...',
    guide_mark: 'Znacznik Przewodnika',
    guide_mark_desc: 'Znaczniki przewodnika będą grawerowane jako punkt odniesienia do wyrównania dzieła sztuki.',
    guide_mark_length: 'Długość:',
    guide_mark_x: 'Współrzędna X:',
    height_desc: 'Ustaw wysokość każdej sekcji obszaru roboczego.',
    help_links: {
      ado1: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
      fbb2: 'https://support.flux3dp.com/hc/en-us/articles/11570021253263',
    },
    help_text: 'Jak skonfigurować przepust dla %(model)s?',
    object_length: 'Długość Obiektu',
    ref_layer: 'Warstwa Referencyjna',
    ref_layer_desc: 'Należy pamiętać, że wykonanie warstwy odniesienia jest domyślnie ustawione na 0. Nie zostanie ono wykonane, jest tylko punktem odniesienia do wyrównania.',
    ref_layer_name: 'Odniesienie',
    title: 'Tryb przepustu',
    workarea_height: 'Obszar Roboczy (Wysokość):',
  },
  promark_connection_test: {
    description: 'System przeprowadzi operację czerwonego światła przez około dwie minuty, aby sprawdzić stabilność połączenia.',
    health: 'Stabilność:',
    healthy_description: 'Stabilność jest dobra, ale unikaj znacznego poruszania komputerem podczas grawerowania.',
    res_0: 'Doskonała',
    res_1: 'Dobra',
    res_2: 'Niska',
    res_3: 'Bardzo Niska',
    res_4: 'Bardzo Słaba',
    restart: 'Uruchom Ponownie Test',
    start: 'Rozpocznij Test',
    stop: 'Zatrzymaj Test',
    title: 'Test Stabilności Połączenia',
    unhealthy_description: 'Stabilność jest zbyt niska. Zaleca się wymianę adaptera sieciowego lub kabla zasilającego.',
  },
  promark_settings: {
    angle: 'Kąt',
    bulge: 'Wybrzuszenie',
    field: 'Pole',
    galvo_configuration: 'Konfiguracja Galvo',
    mark: 'Oznacz',
    mark_parameters: 'Parametry znakowania',
    offsetX: 'Przesunięcie X',
    offsetY: 'Przesunięcie Y',
    preview: 'Podgląd',
    red_dot: 'Czerwona kropka',
    scale: 'Skala',
    scaleX: 'Skala X',
    scaleY: 'Skala Y',
    skew: 'Przekrzywienie',
    switchXY: 'Zmiana X/Y',
    title: 'Ustawienia Promark',
    trapezoid: 'Trapez',
    workarea_hint: 'Możesz zmienić obszar roboczy w "Ustawieniach dokumentu".',
    z_axis_adjustment: {
      section1: 'Dostosuj wysokość osi Z, aby precyzyjnie ustawić ostrość.',
      title: 'Regulacja Osi Z',
      tooltip1: 'Spróbuj oznaczyć kwadrat 1x1 cm, aby potwierdzić, czy bieżąca odległość ogniskowa jest odpowiednia.',
    },
  },
  qr_code_generator: {
    error_tolerance: 'Tolerancja błędów',
    error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
    invert: 'Odwróć kolory tła i pierwszego planu',
    placeholder: 'Wpisz łącze lub tekst',
    preview: 'Podgląd',
    title: 'Generator kodów QR',
  },
  rotary_settings: {
    circumference: 'Obwód',
    extend_workarea: 'Rozszerz obszar roboczy',
    mirror: 'Lustro',
    object_diameter: 'Średnica Obiektu',
    overlap_size: 'Rozmiar nakładania',
    rotary_scale: 'Skala obrotu',
    split_setting: 'Ustawienie podziału',
    split_setting_url: 'https://support.flux3dp.com/hc/en-us/articles/12276094010767',
    split_size: 'Rozmiar podziału',
    type: 'Typ',
  },
  select_device: {
    auth_failure: '##811 Uwierzytelnianie nie powiodło się',
    select_device: 'Wybierz urządzenie',
    unable_to_connect: '#810 Nie można zbudować stabilnego połączenia z maszyną',
  },
  settings: {
    anti_aliasing: 'Wygładzanie krawędzi',
    auto_connect: 'Automatycznie wybierz jedyną maszynę',
    auto_switch_tab: 'Automatyczne przełączanie między panelem warstw a panelem obiektów',
    autofocus_offset: 'Przesunięcie autofokusa',
    autosave_enabled: 'Automatyczne zapisywanie',
    autosave_interval: 'Zapisz co',
    autosave_number: 'Liczba automatycznych zapisów',
    autosave_path: 'Lokalizacja automatycznego zapisu',
    autosave_path_not_correct: 'Nie znaleziono określonej ścieżki.',
    blade_precut_position: 'Pozycja cięcia wstępnego',
    blade_precut_switch: 'Wstępnie nacięcie',
    blade_radius: 'Promień ostrza',
    bottom_up: 'Od dołu do góry',
    calculation_optimization: 'Przyspieszenie obliczeń ścieżki',
    cancel: 'Anuluj',
    caption: 'Ustawienia',
    check_updates: 'Sprawdzaj automatycznie',
    close: 'Zamknij',
    confirm_remove_default: 'Maszyna domyślna zostanie usunięta.',
    confirm_reset: 'Potwierdzić zresetowanie Beam Studio?',
    continuous_drawing: 'Ciągłe rysowanie',
    curve_engraving_speed_limit: 'Ograniczenie prędkości grawerowania krzywej',
    custom_preview_height: 'Niestandardowa wysokość podglądu',
    default_beambox_model: 'Domyślne ustawienie dokumentu',
    default_borderless_mode: 'Domyślnie otwarte dno',
    default_enable_autofocus_module: 'Domyślny autofokus',
    default_enable_diode_module: 'Domyślny laser diodowy',
    default_font_family: 'Domyślna czcionka',
    default_font_style: 'Domyślny styl czcionki',
    default_laser_module: 'Domyślny moduł laserowy',
    default_machine: 'Maszyna domyślna',
    default_machine_button: 'Wyczyść',
    default_units: 'Jednostki domyślne',
    diode_offset: 'Offset lasera diodowego',
    diode_one_way_engraving: 'Grawerowanie laserem diodowym w jedną stronę',
    diode_two_way_warning: 'Dwukierunkowe grawerowanie jest szybsze, lecz może powodować obniżenie szczegółowości. Zalecamy najpierw przetestować.',
    disabled: 'Wyłączony',
    done: 'Zastosuj',
    enable_custom_backlash: 'Włącz niestandardową kompensację luzu',
    enable_low_speed: 'Włącz ruch z małą prędkością',
    enabled: 'Włączony',
    engraving_direction: 'Kierunek',
    fast_gradient: 'Optymalizacja prędkości',
    font_convert: 'Konwerter tekstu na ścieżkę',
    font_substitute: 'Zastąp nieobsługiwane znaki',
    grouped_objects: 'Zgrupowane obiekty',
    groups: {
      ador_modules: 'Moduły Adoracji',
      autosave: 'Automatyczne zapisywanie',
      camera: 'Kamera',
      connection: 'Połączenie',
      editor: 'Ustawienia edycji',
      engraving: 'Grawerowanie',
      general: 'Ogólne',
      mask: 'Przycinanie obszaru roboczego',
      modules: 'Dodatki',
      path: 'Cięcie',
      privacy: 'Prywatność',
      text_to_path: 'Tekst',
      update: 'Aktualizacje oprogramowania',
    },
    guess_poke: 'Wyszukaj adres IP maszyny',
    guides: 'Linie pomocnicze',
    guides_origin: 'Początek lini pomocniczych',
    help_center_urls: {
      anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
      calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
      connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
      continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
      default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
      default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
      default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
      fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
      font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
      font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
      image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
      loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
      mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
      reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
      segmented_engraving: 'https://support.flux3dp.com/hc/en-us/articles/12306366019215',
      simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
      vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
    },
    high: 'Wysoki',
    image_downsampling: 'Jakość podglądu mapy bitowej',
    ip: 'Adres IP maszyny',
    keep_preview_result: 'Zachowaj Wynik Podglądu',
    language: 'Język',
    loop_compensation: 'Kompensacja pętli',
    low: 'Niski',
    low_laser_for_preview: 'Laser dla Rama do biegania',
    mask: 'Przycinanie obszaru roboczego',
    medium: 'Średni',
    module_offset_2w_ir: 'Laser offsetowy na podczerwień o mocy 2 W',
    module_offset_10w: 'Offset lasera diodowego o mocy 10 W',
    module_offset_20w: 'Offset lasera diodowego o mocy 20 W',
    module_offset_printer: 'Przesunięcie drukarki',
    none: 'Żaden',
    normal: 'Normalny',
    notification_off: 'Nie',
    notification_on: 'Tak',
    notifications: 'Powiadomienia na komputerze',
    off: 'Wyłączony',
    on: 'Włączony',
    preview_movement_speed: 'Prędkość ruchu - podgląd',
    printer_advanced_mode: 'Tryb zaawansowany drukarki',
    remove_default_machine_button: 'Usuń',
    reset: 'Zresetuj Beam Studio',
    reset_now: 'Zresetuj Beam Studio',
    segmented_engraving: 'Grawerowanie segmentowe',
    share_with_flux: 'Udostępnij analizy Beam Studio',
    simplify_clipper_path: 'Optymalizuj obliczoną ścieżkę',
    single_object: 'Pojedynczy obiekt',
    tabs: {
      device: 'Maszyna',
      general: 'Ogólne',
    },
    text_path_calc_optimization: 'Optymalizacja obliczania ścieżki',
    top_down: 'Z góry na dół',
    trace_output: 'Efekt obrysowania obrazu',
    update_beta: 'Beta',
    update_latest: 'Najnowsza (stabilna)',
    vector_speed_constraint: 'Ograniczenie prędkości',
    wrong_ip_format: 'Niewłaściwe formaty adresów IP',
  },
  social_media: {
    facebook: 'Rozmawiaj z użytkownikami FLUX, zadawaj pytania i poznawaj wskazówki!',
    instagram: 'Zdobądź najnowsze inspiracje, oferty i darmowe prezenty!',
    youtube: 'Zobacz samouczki Beam Studio i pomysły na rękodzieło.',
  },
  support: {
    no_vcredist: 'Zainstaluj pakiet redystrybucyjny Visual C++ 2015<br/>, który można pobrać ze strony flux3dp.com',
    no_webgl: 'WebGL nie jest obsługiwany. Użyj innych urządzeń.',
  },
  topbar: {
    alerts: {
      add_content_first: 'Please add objects first',
      door_opened: 'Zamknij pokrywę drzwi, aby włączyć niską moc lasera do uruchomienia ramki.',
      fail_to_connect_with_camera: '#803 Nie udało się nawiązać połączenia z kamerą maszyny. Uruchom ponownie komputer i Beam Studio. Jeśli błąd będzie się powtarzał, postępuj zgodnie z <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">tym przewodnikiem</a>.',
      fail_to_get_door_status: 'Upewnij się, że pokrywa drzwi jest zamknięta, aby włączyć niską moc lasera do uruchomienia ramki.',
      fail_to_start_preview: '#803 Nie udało się uruchomić trybu podglądu. Uruchom ponownie komputer i Beam Studio. Jeśli błąd będzie się powtarzał, postępuj zgodnie z <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">tym przewodnikiem</a>.',
      headtype_mismatch: 'Nieprawidłowy moduł wykryty. ',
      headtype_none: 'Nie wykryto modułu. ',
      headtype_unknown: 'Wykryto nieznany moduł. ',
      install_correct_headtype: 'Zainstaluj prawidłowe moduły lasera diodowego 10 W/20 W, aby włączyć niską moc lasera do uruchomienia ramki.',
      job_origin_unavailable: 'Ustawienie początku zadania wymaga wersji firmware 4.3.5 / 5.3.5 lub wyższej. Czy chcesz teraz zaktualizować firmware?',
      job_origin_warning: 'Obecnie używasz „aktualnej pozycji” jako punktu początkowego. Upewnij się, że głowica lasera została przesunięta do właściwej pozycji, aby uniknąć kolizji.',
      power_too_high: 'ZBYT WYSOKA MOC',
      power_too_high_confirm: 'ROZUMIEM',
      power_too_high_msg: 'Użycie mniejszej mocy lasera (poniżej 70%) wydłuży żywotność tuby laserowej. Wpisz „ROZUMIEM”, aby kontynuować.',
      pwm_unavailable: 'Tryb Głębokości wymaga wersji firmware 4.3.4 / 5.3.4 lub nowszej. Czy chcesz zaktualizować firmware teraz?',
      QcleanScene: 'Czy chcesz wyczyścić rysunek?<br/>Spowoduje to także usunięcie historii cofania!',
      start_preview_timeout: '#803 Upłynął limit czasu podczas uruchamiania trybu podglądu. Uruchom ponownie komputer i Beam Studio. Jeśli błąd będzie się powtarzał, postępuj zgodnie z <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">tym przewodnikiem</a>.',
    },
    frame_task: 'Rama do biegania',
    hint: {
      polygon: 'Naciśnij klawisz + / -, aby zwiększyć / zmniejszyć ilość boków.',
    },
    menu: {
      about: 'O Beam Studio',
      about_beam_studio: 'O Beam Studio',
      account: 'Konto',
      add_new_machine: 'Konfiguracja maszyny',
      align_center: 'Wyrównaj do środka',
      anti_aliasing: 'Wygładzanie krawędzi',
      auto_align: 'Automatyczne Wyrównanie',
      borderless_mode: 'Tryb bez obramowania',
      bug_report: 'Zgłoszenie błędu',
      calibrate_beambox_camera: 'Kalibracja kamery',
      calibrate_beambox_camera_borderless: 'Kalibracja kamery (Otwarte dno)',
      calibrate_camera_advanced: 'Kalibracja kamery (Zaawansowane)',
      calibrate_diode_module: 'Skalibruj moduł lasera diodowego',
      calibrate_ir_module: 'Kalibracja modułu podczerwieni',
      calibrate_printer_module: 'Kalibracja modułu drukowania',
      calibration: 'Kalibracje',
      camera_calibration_data: 'Dane Kalibracji Kamery',
      change_logs: 'Dzienniki zmian',
      clear_scene: 'Nowe pliki',
      close: 'Zamknij okno',
      commands: 'Polecenia',
      contact: 'Skontaktuj się z nami',
      copy: 'Kopiuj',
      cut: 'Wytnij',
      dashboard: 'Panel',
      decompose_path: 'Rozłóż',
      delete: 'Usuń',
      design_market: 'Design Market',
      dev_tool: 'Narzędzie Debugowania',
      disassemble_use: 'Rozłóż',
      document_setting: 'Ustawienia dokumentu',
      document_setting_short: 'Dokumentu',
      download_data: 'Pobierz Dane',
      download_log: 'Pobierz dzienniki logów',
      download_log_canceled: 'Pobieranie dziennika logów zostało anulowane',
      download_log_error: 'Wystąpił nieznany błąd, spróbuj ponownie później',
      duplicate: 'Duplikuj',
      edit: 'Edycja',
      example_files: 'Przykładowe pliki',
      export_BVG: 'BVG',
      export_flux_task: 'Plik FLUX',
      export_JPG: 'JPG',
      export_PNG: 'PNG',
      export_SVG: 'SVG',
      export_to: 'Eksportuj do...',
      file: 'Plik',
      fit_to_window: 'Dopasuj obszar roboczy do okna',
      follow_us: 'Śledź nas',
      forum: 'Forum społecznościowe',
      group: 'Grupuj',
      help: 'Pomoc',
      help_center: 'Centrum pomocy',
      hide: 'Ukryj Beam Studio',
      hideothers: 'Ukryj inne',
      image_crop: 'Przytnij',
      image_curve: 'Krzywa',
      image_invert: 'Odwróć',
      image_sharpen: 'Wyostrzyj',
      image_stamp: 'Faza',
      image_vectorize: 'Obrysuj',
      import_acrylic_focus_probe: 'Akrylowa sonda ostrości - 3mm',
      import_ador_laser_example: 'Przykład lasera Ador',
      import_ador_printing_example_full: 'Przykład druku Ador - cały kolor',
      import_ador_printing_example_single: 'Przykład druku Ador — pojedynczy kolor',
      import_beambox_2_example: 'Przykład Beambox II',
      import_beambox_2_focus_probe: 'Sonda Ostrości Beambox II - 3 mm',
      import_hello_beambox: 'Przykład Beambox',
      import_hello_beamo: 'Przykład Bemo',
      import_hexa_example: 'Przykład HEXA',
      import_material_printing_test: 'Test drukowania materiału',
      import_material_testing_cut: 'Test cięcia',
      import_material_testing_engrave: 'Test grawerowania',
      import_material_testing_line: 'Test nacinania',
      import_material_testing_old: 'Test grawerowania — klasyczny',
      import_material_testing_simple_cut: 'Test cięcia - prosty',
      import_promark_example: 'Przykład Promark',
      import_promark_mopa_20w_color: 'Test Koloru MOPA 20W',
      import_promark_mopa_60w_color: 'Test Koloru MOPA 60W',
      import_promark_mopa_100w_color: 'Test Koloru MOPA 100W',
      keyboard_shortcuts: 'Skróty klawiaturowe',
      layer_color_config: 'Ustawienia kolorów',
      layer_setting: 'Warstwa',
      link: {
        beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
        contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
        design_market: 'https://dmkt.io',
        downloads: 'https://flux3dp.com/downloads/',
        forum: 'https://www.facebook.com/groups/flux.laser/',
        help_center: 'https://helpcenter.flux3dp.com/',
        shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
      },
      log: {
        camera: 'Kamera',
        cloud: 'Chmura',
        discover: 'Odkryj',
        hardware: 'Sprzęt komputerowy',
        network: 'Sieć',
        player: 'Gracz',
        robot: 'Robot',
        usb: 'USB',
        usblist: 'Lista USB',
      },
      machine_info: 'Informacje o maszynie',
      machines: 'Maszyny',
      manage_account: 'Zarządzaj kontem',
      material_test: 'Test materiału',
      minimize: 'Zminimalizuj',
      my_account: 'Moje konto',
      network_testing: 'Przetestuj ustawienia sieciowe',
      object: 'Obiekt',
      offset: 'Obiekt',
      open: 'Otwarty',
      paste: 'Wklej',
      paste_in_place: 'Wklej w miejscu',
      path: 'Ścieżka',
      photo_edit: 'Obraz',
      preferences: 'Preferencje',
      promark_color_test: 'Promark Test Koloru',
      questionnaire: 'Kwestionariusz opinii',
      quit: 'Wyjdź',
      recent: 'Otwórz Ostatnie',
      redo: 'Ponów',
      reload_app: 'Przeładuj Aplikację',
      reset: 'Resetuj',
      rotary_setup: 'Ustawienia Obracania',
      rotate: 'Obróć',
      samples: 'Przykłady',
      save_as: 'Zapisz jako...',
      save_scene: 'Zapisz',
      save_to_cloud: 'Zapisz w chmurze',
      scale: 'Skaluj',
      service: 'Usługi',
      set_as_default: 'Ustaw jako domyślne',
      show_gesture_tutorial: 'Wprowadzenie do gestów dłoni',
      show_grids: 'Pokaż siatkę',
      show_layer_color: 'Użyj koloru warstwy',
      show_rulers: 'Pokaż miarki',
      show_start_tutorial: 'Pokaż samouczek startowy',
      show_ui_intro: 'Pokaż wprowadzenie do interfejsu',
      sign_in: 'Zaloguj się',
      sign_out: 'Wyloguj się',
      software_update: 'Aktualizacja oprogramowania',
      svg_edit: 'SVG',
      switch_to_beta: 'Przełącz na Wersję Beta',
      switch_to_latest: 'Przełącz na Stabilną Wersję',
      tools: {
        box_generator: 'Generator Pudełek',
        code_generator: 'Generator kodu',
        material_test_generator: 'Generator Testu Materiału',
        title: 'Narzędzia',
      },
      tutorial: 'Uruchom samouczek dotyczący drukowania z rodziny Delta',
      undo: 'Cofnij',
      ungroup: 'Rozgrupuj',
      update: 'Sprawdź Aktualizację',
      update_firmware: 'Aktualizacja oprogramowania',
      upload_data: 'Prześlij Dane',
      using_beam_studio_api: 'Korzystanie z API Beam Studio',
      view: 'Widok',
      window: 'Okno',
      zoom_in: 'Powiększ',
      zoom_out: 'Zmniejsz',
      zoom_with_window: 'Automatyczne dopasowywuj obszar roboczy do okna',
    },
    preview: 'PODGLĄD',
    preview_press_esc_to_stop: 'Naciśnij ESC, aby zatrzymać podgląd kamery.',
    rename_tab: 'Zmień nazwę karty',
    select_machine: 'Wybierz maszynę',
    tag_names: {
      dxf: 'Obiekt DXF',
      ellipse: 'Owal',
      g: 'Grupa',
      image: 'Obraz',
      line: 'Linia',
      multi_select: 'Wiele obiektów',
      no_selection: 'Brak wyboru',
      pass_through_object: 'Przeprowadź Obiekt',
      path: 'Ścieżka',
      polygon: 'Wielokąt',
      rect: 'Prostokąt',
      svg: 'Obiekt SVG',
      text: 'Tekst',
      text_path: 'Tekst na ścieżce',
      use: 'Zaimportowany obiekt',
    },
    task_preview: 'Podgląd ścieżki',
    titles: {
      settings: 'Preferencje',
    },
    untitled: 'Bez nazwy',
  },
  topmenu: {
    credit: 'Beam Studio jest możliwe dzięki projektowi open source <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> i innym <a target="_blank" href="https://flux3dp.com/credits/">oprogramowanie open source</a>.',
    device: {
      download_log_canceled: 'Pobieranie dziennika logów zostało anulowane',
      download_log_error: 'Wystąpił nieznany błąd, spróbuj ponownie później',
      log: {
        usblist: 'Lista USB',
      },
      network_test: 'Sprawdź połączenie sieciowe',
    },
    file: {
      all_files: 'Wszystkie pliki',
      clear_recent: 'Wyczyść ostatnio otwarte',
      converting: 'Konwertowanie na obraz...',
      fcode_files: 'Kod FLUX',
      import: 'Importuj',
      jpg_files: 'JPG',
      label: 'Plik',
      path_not_exit: 'Wygląda na to, że ta ścieżka już nie istnieje na dysku.',
      png_files: 'PNG',
      save_fcode: 'Eksportuj plik FLUX',
      save_jpg: 'Eksportuj JPG',
      save_png: 'Eksportuj PNG',
      save_scene: 'Zapisz projekt',
      save_svg: 'Eksportuj SVG',
      scene_files: 'Plik Beam Studio',
      svg_files: 'SVG',
    },
    ok: 'OK',
    version: 'Wersja',
  },
  tutorial: {
    ask_retry_calibration: 'Czy chcesz ponownie skalibrować aparat?',
    camera_calibration_failed: 'Kalibracja aparatu nie powiodła się',
    gesture: {
      click: 'Stuknij, aby wybrać obiekt.',
      drag: 'Przeciągnij, aby wybrać wiele obiektów.',
      hold: 'Naciśnij i przytrzymaj, aby otworzyć menu kontekstowe.',
      pan: 'Przewiń płótno dwoma palcami.',
      zoom: 'Ściśnij dwoma palcami, aby powiększyć/pomniejszyć płótno.',
    },
    links: {
      adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
    },
    look_for_machine: 'Wyszukiwanie samouczków...',
    needNewInterfaceTutorial: 'Czy chcesz rozpocząć samouczek dla nowego interfejsu Beam Studio?<br/>(Możesz to teraz pominąć i rozpocząć później, klikając "Pomoc" > "Pokaż Wprowadzenie do Interfejsu".)',
    needNewUserTutorial: 'Czy chcesz rozpocząć samouczek?<br/>(Możesz to teraz pominąć i rozpocząć później, klikając "Pomoc" > "Pokaż Samouczek Startowy".)',
    newInterface: {
      add_new_layer: 'Dodaj nową warstwę',
      align_controls: 'Opcje wyrównania obiektów',
      basic_shapes: 'Podstawowe kształty',
      camera_preview: 'Podgląd kamery',
      drag_to_sort: 'Przeciągnij, aby posortować',
      end_alert: 'Czy na pewno zakończyć wprowadzanie do nowego interfejsu użytkownika?',
      flip: 'Odbicie',
      group_controls: 'Sterowanie grupowe',
      layer_controls: 'Kliknij prawym przyciskiem myszy, aby wybrać opcje warstw:nDuplicate / Merge / Lock / Delete Layers',
      object_actions: 'Akcje obiektów',
      pen_tool: 'Narzędzie Pióro',
      rename_by_double_click: 'Zmień nazwę, klikając dwukrotnie',
      select_image_text: 'Wybierz / Obraz / Tekst',
      select_machine: 'Wybierz maszynę',
      shape_operation: 'Operacja kształtu',
      start_work: 'Zacząć pracę',
      switch_between_layer_panel_and_object_panel: 'Przełączanie między panelem warstw a panelem obiektów',
    },
    newUser: {
      add_new_layer: 'Dodaj nową warstwę',
      adjust_focus: '2. Ustaw focus',
      close_cover: '3. Zamknij pokrywę',
      drag_to_draw: 'Przeciągnij, aby narysować',
      draw_a_circle: 'Narysuj okrąg',
      draw_a_rect: 'Narysuj prostokąt',
      end_alert: 'Czy na pewno chcesz zakończyć samouczek?',
      end_preview_mode: 'Zakończ tryb podglądu',
      infill: 'Włącz wypełnienie',
      please_select_wood_cutting: 'Wybierz ustawienie wstępne „Drewno — cięcie”.',
      please_select_wood_engraving: 'Prosimy wybrać ustawienie wstępne „Drewno — grawerowanie”.',
      preview_the_platform: 'Podgląd platformy',
      put_wood: '1. Umieść próbkę drewna',
      send_the_file: 'Wyślij plik',
      set_preset_wood_cut: 'Ustaw ustawienie wstępne: Drewno — cięcie',
      set_preset_wood_engraving: 'Ustaw ustawienie wstępne: Drewno — grawerowanie',
      switch_to_layer_panel: 'Przejdź do panelu warstw',
      switch_to_object_panel: 'Przełącz do panelu obiektów',
      switch_to_preview_mode: 'Przełącz do trybu podglądu',
    },
    next: 'DALEJ',
    retry: 'Spróbuj ponownie',
    set_connection: 'Ustaw połączenie',
    skip: 'Pomiń',
    skip_tutorial: 'Pominąłeś samouczek. Zawsze możesz uruchomić samouczek, klikając „Pomoc” > „Pokaż samouczek startowy”',
    suggest_calibrate_camera_first: 'Zalecamy użytkownikom skalibrowanie kamery początkowo i ponowne skupienie przed każdym podglądem dla optymalnych wyników.<br/>Czy chcesz potwierdzić przeprowadzenie kalibracji teraz?<br/>(Możesz to teraz pominąć i wykonać później, klikając "Menu" > "Maszyna" > [Twoja Maszyna] > "Skalibruj Kamerę".)',
    tutorial_complete: 'To wszystko w samouczku. Teraz czas na tworzenie!',
    unable_to_find_machine: 'Nie można znaleźć maszyny do samouczka. Czy chcesz przejść do strony ustawień połączenia, spróbować ponownie czy pominąć samouczek?',
    welcome: 'WITAM',
  },
  update: {
    cannot_reach_internet: 'Serwer #823 jest nieosiągalny<br/>Sprawdź połączenie internetowe',
    download: 'AKTUALIZACJA ONLINE',
    firmware: {
      caption: 'Dostępna jest aktualizacja oprogramowania układowego urządzenia',
      confirm: 'PRZEŚLIJ',
      firmware_too_old_update_by_sdcard: 'Wersja oprogramowania sprzętowego jest zbyt stara. Zaktualizuj oprogramowanie sprzętowe za pomocą karty SD.',
      force_update_message: '#814 Zaktualizuj urządzenie do najnowszej wersji oprogramowania sprzętowego.',
      latest_firmware: {
        cant_get_latest: 'Nie można uzyskać informacji o najnowszym oprogramowaniu urządzenia.',
        caption: 'Aktualizacja oprogramowania układowego urządzenia',
        message: 'Masz najnowsze oprogramowanie układowe urządzenia',
        still_update: 'AKTUALIZACJA',
      },
      message_pattern_1: '%s jest teraz gotowy do aktualizacji oprogramowania układowego.',
      message_pattern_2: '%s Firmware v%s jest już dostępny - Teraz masz v%s.',
      too_old_for_web: 'Bieżąca wersja oprogramowania układowego urządzenia to v%s.\nIf you want to use the online version of the Beam Studio, please update the machine firmware to the latest version.',
      update_fail: 'Aktualizacja nieudana #822',
      update_success: 'Pomyślnie przesłano aktualizację oprogramowania układowego',
      upload_file: 'Przesyłanie oprogramowania układowego (*.bin / *.fxfw)',
    },
    install: 'ZAINSTALUJ',
    later: 'PÓŹNIEJ',
    preparing: 'Przygotowywanie...',
    release_note: 'Informacje o wersji:',
    skip: 'Pomiń tę wersję',
    software: {
      available_switch: 'Beam Studio v%s jest już dostępna. Teraz masz v%s. Czy chcesz przełączyć się na tę wersję?',
      available_update: 'Beam Studio v%s jest już dostępna. Teraz masz v%s. Czy chcesz pobrać aktualizację?',
      caption: 'Dostępna jest aktualizacja oprogramowania Beam Studio',
      check_update: 'Sprawdź dostępne aktualizacje',
      checking: 'Sprawdzanie aktualizacji',
      downloading: 'Pobieram aktualizacje w tle, możesz kliknąć „OK”, aby kontynuować pracę.',
      install_or_not: 'jest gotowy do aktualizacji. Czy chcesz teraz ponownie uruchomić i zaktualizować?',
      no: 'Nie',
      no_response: 'Nie udało się połączyć z serwerem, sprawdź ustawienia sieciowe.',
      not_found: 'Używasz najnowszej wersji Beam Studio.',
      switch_or_not: 'jest gotowy do zmiany. Czy chcesz teraz ponownie uruchomić i zamienić?',
      switch_version: 'Zmień wersję',
      switch_version_not_found: 'Nie znaleziono wersji do zamiany.',
      update_for_ador: 'Obecna wersja oprogramowania %s jest starsza. Pobierz najnowszą wersję Beam Studio dla Ador.',
      yes: 'Tak',
    },
    update: 'AKTUALIZACJA',
    updating: 'Aktualizowanie...',
    upload: 'PRZEŚLIJ',
  },
  web_cam: {
    no_device: 'Nie można wykryć urządzenia kamery. Proszę podłączyć kamerę ponownie i spróbować ponownie.',
    no_permission: 'Beam Studio nie ma uprawnień do uzyskania dostępu do kamery. Upewnij się, że uprawnienia są przyznane Beam Studio w ustawieniach przeglądarki lub systemu.',
  },
  z_speed_limit_test: {
    alert_before: 'Przed rozpoczęciem grawerowania krzywej system przeprowadzi test obciążenia, aby zapobiec utracie kroków z powodu nadmiernej wagi.',
    alert_failed: 'Waga obecnego obiektu przekracza limit obciążenia dla bieżącej prędkości osi Z. Zmniejsz prędkość osi Z w panelu maszyny lub zamień obiekt grawerowania przed ponownym testem.',
    ignore: 'Ignoruj',
    retest: 'Przetestuj ponownie',
    testing: 'Przeprowadzanie testu obciążenia...',
  },
};

export default lang;
