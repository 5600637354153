// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-dialogs-updateFontConvert-module__message---4zup{white-space:break-spaces}._-_-packages-core-src-web-app-components-dialogs-updateFontConvert-module__button--Xa8xT{padding:0;margin-top:10px}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/dialogs/updateFontConvert.module.scss"],"names":[],"mappings":"AAAA,2FACE,wBAAA,CAGF,0FACE,SAAA,CACA,eAAA","sourcesContent":[".message {\n  white-space: break-spaces;\n}\n\n.button {\n  padding: 0;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": "_-_-packages-core-src-web-app-components-dialogs-updateFontConvert-module__message---4zup",
	"button": "_-_-packages-core-src-web-app-components-dialogs-updateFontConvert-module__button--Xa8xT"
};
export default ___CSS_LOADER_EXPORT___;
