import type { ILang } from '@core/interfaces/ILang';

const lang: ILang = {
  alert: {
    abort: 'Annuleren',
    cancel: 'Annuleren',
    caption: 'Fout',
    close: 'Sluiten',
    confirm: 'Bevestigen',
    dont_save: 'Niet opslaan',
    dont_show_again: 'Niet meer weergeven',
    duplicated_preset_name: 'Dubbele voorinstellingsnaam',
    error: 'FOUT',
    info: 'INFO',
    instruction: 'Instructie',
    learn_more: 'Meer informatie',
    no: 'Nee',
    ok: 'OK',
    ok2: 'OK',
    oops: 'Oeps...',
    retry: 'Opnieuw proberen',
    save: 'Opslaan',
    stop: 'Stoppen',
    warning: 'WAARSCHUWING',
    yes: 'Ja',
  },
  auto_fit: {
    artwork_size: 'Grootte van het kunstwerk',
    error_tip1: '1. Is het kunstwerk correct op het materiaal geplaatst?',
    error_tip2: '2. Zijn de materiaalcontouren duidelijk genoeg voor herkenning?',
    failed_to_find_contour: 'Geen contouren gevonden voor automatische aanpassing',
    learn_more: 'Bekijk de tutorial.',
    learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    offset_x: 'Offset X',
    offset_y: 'Offset Y',
    pattern_size: 'Patroongrootte',
    position_artwork: 'Kunstwerk positioneren',
    position_step1: 'Pas de grootte en positie van uw kunstwerk op het patroon aan.',
    position_step2: 'Klik op "Toepassen" om Auto Fit op dit patroon toe te passen.',
    preview_first: 'Voer eerst een cameravoorvertoning uit.',
    reset_position: 'Positie resetten',
    rotation: 'Draaien',
    select_a_pattern: 'Kies een patroon',
    selected_artwork: 'Geselecteerd kunstwerk',
    step1: 'Kies een patroon waar u het kunstwerk wilt plaatsen.',
    step2: 'Als er geen patroon wordt gevonden, bekijk de cameravoorkeur opnieuw en pas Auto Aanpassen toe.',
    step3: 'Als het nog steeds niet lukt om het juiste patroon te vinden, zorg er dan voor dat uw werkstuk duidelijk herkenbaar is en dat de werkruimte vrij is van vuil.',
    title: 'Auto Aanpassen',
  },
  barcode_generator: {
    bar_height: 'Balkhoogte',
    bar_width: 'Balkbreedte',
    barcode: {
      invalid_value: 'De waarde is ongeldig voor het geselecteerde formaat.',
    },
    font: 'Lettertype',
    font_size: 'Lettergrootte',
    hide_text: 'Tekst verbergen',
    invert_color: 'Kleur omkeren',
    text_margin: 'Tekstmarge',
  },
  beambox: {
    announcement_panel: {
      title: 'Aankondiging',
    },
    banner: {
      auto_feeder: 'Automatische Voedermodus',
      camera_preview: 'Voorbeeld',
      camera_preview_borderless_mode: '(Open Onderkant)',
      curve_engraving: 'Kromme Graveermodus',
      pass_through: 'Doorvoer Modus',
      rotary: 'Rotatiemodus',
    },
    bottom_right_panel: {
      convert_text_to_path_before_export: 'Tekst naar pad omzetten...',
      export_file_error_ask_for_upload: 'Export mislukt. Wilt u de werkende scène naar het ontwikkelteam sturen voor een bugrapport?',
      retreive_image_data: 'Gegevens ophalen...',
    },
    context_menu: {
      copy: 'Kopiëren',
      cut: 'Knippen',
      delete: 'Verwijderen',
      duplicate: 'Dupliceren',
      group: 'Groeperen',
      move_back: 'Naar achtergrond verplaatsen',
      move_down: 'Naar achteren verplaatsen',
      move_front: 'Naar voorgrond verplaatsen',
      move_up: 'Naar voren verplaatsen',
      paste: 'Plakken',
      paste_in_place: 'Plakken op plaats',
      ungroup: 'Degroeperen',
    },
    document_panel: {
      add_on: 'Add-ons',
      auto_feeder: 'Automatische voeder',
      auto_feeder_scale: 'Schaal voor automatische voeder',
      auto_feeder_url: 'https://support.flux3dp.com/hc/en-us/articles/11688230498575',
      borderless_mode: 'Open onderzijde',
      current_position: 'Huidige Positie',
      disable: 'Uitschakelen',
      document_settings: 'Documentinstellingen',
      enable: 'Inschakelen',
      enable_autofocus: 'Autofocus',
      enable_diode: 'Diode laser',
      engrave_dpi: 'Resolutie',
      frame_before_start: 'Frame voordat uitgevoerd wordt',
      frame_before_start_url: 'https://support.flux3dp.com/hc/en-us/articles/11494925637135',
      high: 'Hoog',
      job_origin: 'Werk Oorsprong',
      laser_source: 'Laserbron',
      low: 'Laag',
      machine: 'Machine',
      medium: 'Gemiddeld',
      notification: {
        changeFromPrintingWorkareaTitle: 'Wilt u de printlagen converteren naar laslagen?',
      },
      origin: 'Oorsprong',
      pass_through: 'Doorgang',
      pass_through_height_desc: 'Voer de lengte van het object in om het werkgebied uit te breiden.',
      rotary_mode: 'Roterend',
      scale: 'Schaal',
      start_from: 'Start Vanaf',
      start_position: 'Startpositie',
      start_work_button: 'Uitvoeren Knop',
      ultra: 'Ultra hoog',
      workarea: 'Werkgebied',
    },
    image_trace_panel: {
      back: 'Terug',
      brightness: 'Helderheid',
      cancel: 'Annuleren',
      contrast: 'Contrast',
      next: 'Volgende',
      okay: 'OK',
      threshold: 'Drempel',
      tuning: 'Parameters',
    },
    layer_color_config_panel: {
      add: 'Toevoegen',
      add_config: 'Kleur toevoegen',
      color: 'Kleur',
      default: 'Terugzetten naar standaard',
      in_use: 'Deze kleur wordt gebruikt.',
      layer_color_config: 'Laagkleurconfiguraties',
      no_input: 'Voer een geldige hex-kleurcode in.',
      power: 'Vermogen',
      repeat: 'Uitvoeren',
      speed: 'Snelheid',
      sure_to_delete: 'Weet u zeker dat u deze kleurinstelling wilt verwijderen?',
      sure_to_reset: 'U verliest alle aangepaste parameters, weet u zeker dat u terug wilt naar standaard?',
    },
    left_panel: {
      borderless_blind_area: 'Niet-graverend gebied',
      borderless_preview: 'Open onderkant modus camerabeeld',
      diode_blind_area: 'Blind gebied hybride laseradd-on',
      ellipse: 'Ellips',
      image: 'Afbeelding',
      label: {
        adjust_height: 'Hoogte aanpassen',
        array: 'Array',
        boxgen: 'Boxgen',
        choose_camera: 'Camera',
        clear_preview: 'Voorbeeld wissen',
        cursor: 'Selecteer',
        curve_engraving: {
          clear_area: 'Geselecteerd gebied wissen',
          exit: 'Afsluiten',
          preview_3d_curve: 'Voorbeeld van kromme graveren',
          select_area: 'Gebied selecteren',
          title: 'Kromme graveren',
        },
        end_preview: 'Liveweergave stoppen',
        line: 'Lijn',
        live_feed: 'Live beeld',
        my_cloud: 'Mijn cloud',
        oval: 'Ovaal',
        pass_through: 'Doorgang',
        pen: 'Pen',
        photo: 'Afbeelding',
        polygon: 'Veelhoek',
        preview: 'Liveweergave',
        qr_code: 'QR-code',
        rect: 'Rechthoek',
        shapes: 'Vormen',
        text: 'Tekst',
        trace: 'Afbeelding traceren',
      },
      line: 'Lijn',
      rectangle: 'Rechthoek',
      text: 'Tekst',
      unpreviewable_area: 'Blind gebied',
    },
    network_testing_panel: {
      average_response: 'Gemiddelde reactietijd',
      cannot_connect_1: '#840 Kan geen verbinding maken met doel-IP.',
      cannot_connect_2: '#840 Kan geen verbinding maken met doel-IP. Zorg ervoor dat het doel zich in hetzelfde netwerk bevindt.',
      cannot_get_local: 'Ophalen lokaal IP-adres mislukt',
      connection_quality: 'Verbindingskwaliteit',
      device_not_on_list: '#842 Machine staat niet in lijst, maar verbinding boven 70% en reactietijd onder 100ms',
      empty_ip: '#818 Voer eerst het doelapparaat IP-adres in.',
      end: 'Einde',
      fail_to_start_network_test: '#817 Kan netwerktest niet starten',
      hint_connect_camera_timeout: 'Time-out bij starten camera voorbeeld?',
      hint_connect_failed_when_sending_job: 'Mislukt om verbinding te maken bij versturen van taak?',
      hint_device_often_on_list: 'Staat de machine vaak niet in de lijst?',
      insert_ip: 'Doelapparaat IP-adres:',
      invalid_ip: '#818 Ongeldig IP-adres',
      ip_startswith_169: '#843 Machine IP-adres begint met 169.254',
      link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
      link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
      link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
      linux_permission_hint: 'Deze fout komt meestal door onvoldoende rechten.\nVoer alsjeblieft "sudo beam-studio --no-sandbox" uit in terminal om rechten te krijgen en de netwerktest uit te voeren.',
      local_ip: 'Lokaal IP-adres:',
      network_testing: 'Netwerktest',
      network_unhealthy: '#841 Verbinding onder 70% of reactietijd boven 100ms',
      start: 'Start',
      test_completed: 'Test voltooid',
      test_fail: 'Test mislukt',
      testing: 'Netwerk testen...',
    },
    object_panels: {
      lock_desc: 'Breedte-hoogteverhouding behouden (SHIFT)',
      text_to_path: {
        check_thumbnail_warning: 'Sommige teksten zijn veranderd in andere lettertypen bij het converteren van tekst naar paden en sommige tekens zijn mogelijk niet correct geconverteerd.\nControleer de voorbeeldafbeelding opnieuw voordat u de taak verstuurt.',
        error_when_parsing_text: 'Fout bij het converteren van tekst naar pad',
        font_substitute_pop: 'Uw tekst bevat tekens die niet worden ondersteund door het huidige lettertype. <br/>Wilt u <strong>%s</strong> gebruiken als vervanging?',
        retry: 'Probeer het later opnieuw of kies een ander lettertype',
        use_current_font: 'Huidig lettertype gebruiken',
      },
      wait_for_parsing_font: 'Lettertypen aan het verwerken... Een moment geduld',
    },
    path_preview: {
      current_position: 'Huidige positie',
      cut_distance: 'Snijafstand',
      cut_time: 'Snijtijd',
      end_preview: 'Voorbeeld beëindigen',
      estimated_time: 'Geschatte totale tijd',
      invert: 'Omkeren',
      pause: 'Pauzeren',
      play: 'Afspelen',
      play_speed: 'Afspeelsnelheid',
      preview_info: 'Voorbeeldinformatie',
      rapid_distance: 'Reisafstand',
      rapid_time: 'Reistijd',
      remark: '* Alle informatie is een geschatte waarde als referentie.',
      size: 'Grootte',
      start_here: 'Start hier',
      stop: 'Stoppen',
      travel_path: 'Reispad',
    },
    photo_edit_panel: {
      aspect_ratio: 'Beeldverhouding',
      brightness: 'Helderheid',
      brightness_and_contrast: 'Helderheid / Contrast',
      cancel: 'Annuleren',
      circumference: 'Omtrek',
      compare: 'Vergelijken',
      contrast: 'Contrast',
      crop: 'Bijsnijden',
      curve: 'Kromming',
      diameter: 'Diameter',
      free: 'Vrij',
      invert: 'Kleuren omkeren',
      okay: 'OK',
      original: 'Origineel',
      phote_edit: 'Foto bewerken',
      processing: 'Verwerken',
      radius: 'Straal',
      rotary_warped: 'Roterend Vervormd',
      rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
      sharpen: 'Scherpte',
      sharpness: 'Scherpte',
      start: 'Starten',
      warp: 'Vervormen',
    },
    popup: {
      ai_credit: {
        buy_link: 'https://member.flux3dp.com/en-US/credit',
        go: 'Ga',
        insufficient_credit: 'Je hebt geen tegoed meer',
        insufficient_credit_msg: 'Je kunt %s niet gebruiken. Ga naar het tegoedcentrum en koop AI-tegoed.',
        relogin_to_use: 'Log opnieuw in om deze functie te gebruiken.',
      },
      auto_feeder_origin: 'Gebruik de kaderweergave om het pad en gebied van de laserkop te controleren. Pas vervolgens de materiaalplaatsing of de startpositie van de gravure aan op basis van de resultaten om botsingen tijdens het graveerproces te voorkomen.',
      auto_switch_tab: {
        message: 'Een nieuwe optie om automatisch te schakelen tussen het lagenpaneel en het objectenpaneel is toegevoegd aan de Voorkeuren. Deze optie is standaard uitgeschakeld. Wilt u automatische schakeling nu inschakelen? <br/>U kunt deze instelling op elk moment wijzigen in Voorkeuren.',
        title: 'Automatisch Wisselen Tussen Lagen- en Objectenpaneel',
      },
      backend_connect_failed_ask_to_upload: '#802 Fouten blijven optreden bij verbinden met de backend. Wilt u uw bugrapport uploaden?',
      backend_error_hint: 'Functies werken mogelijk niet goed vanwege een backendfout.',
      both_power_and_speed_too_high: 'Gebruik van lagere lasersterkte verlengt de levensduur van de laserbuis.\nTe hoge snelheid bij deze resolutie kan resulteren in lagere kwaliteit van arcering.',
      bug_report: 'Bugrapportage',
      change_workarea_before_preview: 'Het werkgebied van %s komt niet overeen met het huidige werkgebied. Wilt u overschakelen naar het huidige werkgebied?',
      convert_to_path_fail: 'Converteren naar pad is mislukt.',
      dxf_bounding_box_size_over: 'De tekeninggrootte is buiten het werkgebied. Verplaats uw tekening dichter bij de oorsprong in uw CAD-software, of zorg dat de eenheid correct is.',
      dxf_version_waring: 'De DXF-versie is niet 2013, er kunnen compatibiliteitsproblemen zijn.',
      facebook_group_invitation: {
        already_joined: 'Al lid',
        join_now: 'Ik doe mee',
        later: 'Misschien later',
        message: 'Word lid van onze officiële Facebook-groep om in contact te komen met andere FLUX-lasergebruikers, FLUX-lasers te bespreken, laserkunstwerken te delen en op de hoogte te blijven van de nieuwste updates over onze producten. We kijken ernaar uit u daar te zien!',
        title: 'Word lid van de officiële FLUX gebruikersgroep',
      },
      import_file_contain_invalid_path: '#808 Geïmporteerd SVG-bestand bevat ongeldig pad naar afbeelding. Zorg ervoor dat alle afbeeldingen bestaan of inbed de afbeelding in het bestand',
      import_file_error_ask_for_upload: 'Importeren van SVG-bestand mislukt. Wilt u het bestand beschikbaar stellen aan het ontwikkelteam voor bugrapportage?',
      layer_by_color: 'Kleur',
      layer_by_layer: 'Laag',
      loading_image: 'Afbeelding laden, even geduld...',
      more_than_two_object: 'Te veel objecten. Ondersteuning voor slechts 2 objecten',
      mouse: 'Muis',
      no_support_text: 'Beam Studio ondersteunt momenteel geen teksttag. Zet tekst om naar pad voordat u importeert.',
      nolayer: 'Enkele laag',
      not_support_object_type: 'Objecttype wordt niet ondersteund',
      or_turn_off_borderless_mode: 'Of schakel de modus zonder onderrand uit.',
      pdf2svg: {
        error_pdf2svg_not_found: '#825 Fout: opdracht pdf2svg niet gevonden. Installeer pdf2svg met uw pakketbeheerder.',
        error_when_converting_pdf: '#824 Fout bij converteren van PDF naar SVG:',
      },
      progress: {
        calculating: 'Berekenen',
        uploading: 'Uploaden',
      },
      questionnaire: {
        caption: 'Help ons met het invullen van de vragenlijst',
        message: 'Help ons met het invullen van de vragenlijst om Beam Studio beter te maken.',
        no_questionnaire_available: 'Er is momenteel geen vragenlijst beschikbaar.',
        unable_to_get_url: 'Het ophalen van de link naar de vragenlijst via internet is mislukt. Controleer uw internetverbinding.',
      },
      recommend_downgrade_software: 'We hebben een oudere firmwareversie gedetecteerd. We werken actief aan compatibiliteitsproblemen, maar voor nu raden we aan terug te keren naar <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>.',
      recommend_upgrade_firmware: 'We hebben een oudere firmwareversie gedetecteerd. We werken actief aan compatibiliteitsproblemen, maar voor nu raden we aan de firmware bij te werken naar de laatste versie.',
      save_unsave_changed: 'Wilt u de niet-opgeslagen wijzigingen opslaan?',
      select_at_least_two: 'Selecteer twee objecten om door te gaan',
      select_first: 'Selecteer eerst een object.',
      select_import_method: 'Selecteer de laagstijl:',
      select_import_module: 'Selecteer module:',
      sentry: {
        message: 'Gaat u ermee akkoord om relevante informatie automatisch te uploaden naar het ontwikkelingsteam wanneer er fouten optreden?',
        title: 'Laten we Beam Studio samen beter maken',
      },
      should_update_firmware_to_continue: '#814 Uw firmware ondersteunt deze versie van Beam Studio niet. Werk de firmware bij om door te gaan. (Menu > Machine > [Uw machine] > Firmware bijwerken)',
      speed_too_high_lower_the_quality: 'Te hoge snelheid bij deze resolutie kan resulteren in lagere kwaliteit van arcering.',
      still_continue: 'Doorgaan',
      successfully_uploaded: 'Uploaden van bestand gelukt.',
      svg_1_1_waring: 'Deze SVG-versie is 1.1, er kunnen compatibiliteitsproblemen zijn.',
      svg_image_path_waring: 'Dit SVG-bestand bevat <image> paden. Dit kan mislukken bij laden.\nGebruik ingesloten afbeeldingen bij exporteren van SVG om dit te voorkomen.',
      text_to_path: {
        caption: 'Tekst naar pad Converter 2.0',
        message: "Beam Studio introduceert nu een nieuwe tekst naar pad converter (Converter 2.0), die betrouwbaardere resultaten oplevert! Wilt u nu overschakelen? \nU kunt deze instelling later ook aanpassen in het gedeelte 'Tekst naar pad Converter' binnen de voorkeuren.",
      },
      too_fast_for_auto_feeder: 'Het gebruik van te hoge snelheden in lagen die padobjecten bevatten, kan de snijprecisie verminderen.\nWe raden aan om geen snelheden hoger dan %(limit)s te gebruiken bij het snijden met Auto Feeder.',
      too_fast_for_auto_feeder_and_constrain: 'De volgende lagen: %(layers)s \nbevatten vectorpadobjecten en hebben een snelheid die %(limit)s overschrijdt.\nDe snijsnelheid van vectorpadobjecten met Auto Feeder wordt beperkt tot %(limit)s.\nDeze beperking kan worden verwijderd in de Voorkeuren Instellingen.',
      too_fast_for_curve: 'Het gebruik van een te hoge snelheid voor lagen met curve-graveerobjecten kan de graveernauwkeurigheid verminderen.\nWe raden aan de snelheid onder %(limit)s te houden voor optimale resultaten.',
      too_fast_for_curve_and_constrain: 'De volgende lagen: %(layers)s overschrijden de snelheidslimiet van %(limit)s.\nDe graveersnelheid voor curve-objecten wordt beperkt tot %(limit)s. U kunt deze limiet verwijderen in de Voorkeursinstellingen.',
      too_fast_for_path: 'Te hoge snelheid in lagen met padobjecten kan resulteren in lagere precisie bij snijden.\nWe raden geen snelheid hoger dan %(limit)s aan bij snijden.',
      too_fast_for_path_and_constrain: 'De volgende lagen: %(layers)s \nbevatten vector padobjecten, en hebben een snelheid hoger dan %(limit)s.\nDe snijsnelheid van vector padobjecten wordt beperkt tot %(limit)s.\nU kunt deze beperking opheffen bij de voorkeuren.',
      touchpad: 'Touchpad',
      ungroup_use: 'Dit zal geïmporteerde DXF/SVG ontgroeperen. Omdat er veel elementen kunnen zijn, kan het even duren. Weet u zeker dat u wilt doorgaan?',
      upload_failed: '#819 Uploaden van bestand mislukt.',
      upload_file_too_large: '#819 Bestand is te groot om te uploaden.',
      vectorize_shading_image: 'Gradientafbeeldingen vergen meer verwerkingstijd voor vectorisatie en zijn gevoelig voor ruis. Schakel de afbeeldingsgradiënt uit voordat u verdergaat.',
    },
    rating_panel: {
      description: 'Als u van Beam Studio houdt, zouden we het zeer op prijs stellen als u ons kunt beoordelen.',
      thank_you: 'Bedankt!',
      title: 'Geniet u van Beam Studio?',
    },
    right_panel: {
      laser_panel: {
        advanced: 'Geavanceerd',
        backlash: 'Speling',
        bi_directional: 'Tweerichtingsverkeer',
        by: 'Met',
        ce_z_high_speed: 'Hogesnelheids-Z-as',
        ce_z_high_speed_link: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
        color_adjustment: 'Kanaal aanpassing',
        color_adjustment_short: 'Kanaal',
        color_strength: 'Kracht',
        cross_hatch: 'Kruislings Arceren',
        custom_preset: 'Aangepast',
        cut: 'Snijden',
        diode: 'Diode laser',
        do_not_adjust_default_para: 'Standaardinstellingen kunnen niet worden aangepast.',
        dottingTime: 'Puntentijd',
        dropdown: {
          inches: {
            abs_engraving: 'ABS - Graveren',
            acrylic_3mm_cutting: 'Acryl - 1/8″ Snijden',
            acrylic_5mm_cutting: 'Acryl - 3/16″ Snijden',
            acrylic_8mm_cutting: 'Acryl - 1/4″ Snijden',
            acrylic_10mm_cutting: 'Acryl - 3/8″ Snijden',
            acrylic_engraving: 'Acryl - Graveren',
            acrylic_printing: 'Acryl - Printen',
            aluminum_engraving: 'Aluminium - Graveren',
            aluminum_light: 'Aluminium (licht)',
            bamboo_printing: 'Bamboe - Printen',
            black_abs: 'Zwart ABS',
            black_acrylic_3mm_cutting: 'Zwart acryl - 1/8″ Snijden',
            black_acrylic_5mm_cutting: 'Zwart acryl - 3/16″ Snijden',
            black_acrylic_engraving: 'Zwart acryl - Graveren',
            brass_dark: 'Messing (donker)',
            brass_engraving: 'Messing - Graveren',
            brass_light: 'Messing (licht)',
            canvas_printing: 'Canvas - Printen',
            cardstock_printing: 'Karton - Printen',
            copper: 'Koper',
            cork_printing: 'Kurk - Printen',
            denim_1mm_cutting: 'Denim - 1/32″ Snijden',
            fabric_3mm_cutting: 'Textiel - 1/8″ Snijden',
            fabric_5mm_cutting: 'Textiel - 3/16″ Snijden',
            fabric_engraving: 'Textiel - Graveren',
            fabric_printing: 'Textiel - Printen',
            flat_stone_printing: 'Platte steen - Printen',
            glass_bw_engraving: 'Glas - Graveren',
            glass_printing: 'Glas - Printen',
            gloss_leather_printing: 'Glans leer - Printen',
            gold_engraving: 'Goud - Graveren',
            iron_engraving: 'IJzer - Graveren',
            leather_3mm_cutting: 'Leer - 1/8″ Snijden',
            leather_5mm_cutting: 'Leer - 3/16″ Snijden',
            leather_engraving: 'Leer - Graveren',
            mdf_3mm_cutting: 'MDF - 1/8″ Snijden',
            mdf_5mm_cutting: 'MDF - 3/16″ Snijden',
            mdf_engraving: 'MDF - Graveren',
            metal_bw_engraving: 'Metaal - Graveren',
            opaque_acrylic: 'Ondoorzichtig acryl',
            pc_printing: 'PC - Printen',
            rubber_bw_engraving: 'Rubber - Graveren',
            silver_engraving: 'Zilver - Graveren',
            stainless_steel_bw_engraving_diode: 'RVS - Graveren (diodelaser)',
            stainless_steel_dark: 'Roestvrij staal (donker)',
            stainless_steel_engraving: 'RVS - Graveren',
            stainless_steel_light: 'Roestvrij staal (licht)',
            stainless_steel_printing: 'RVS - Printen',
            steel_engraving_spray_engraving: 'Metaal - Graveren',
            stone: 'Steen',
            ti_engraving: 'Titanium - Graveren',
            titanium_dark: 'Titanium (donker)',
            titanium_light: 'Titanium (licht)',
            white_abs: 'Wit ABS',
            wood_3mm_cutting: 'Hout - 1/8″ Snijden',
            wood_5mm_cutting: 'Hout - 3/16″ Snijden',
            wood_7mm_cutting: 'Hout - 1/4″ Snijden',
            wood_8mm_cutting: 'Hout - 1/4″ Snijden',
            wood_10mm_cutting: 'Hout - 3/8″ Snijden',
            wood_engraving: 'Hout - Graveren',
            wood_printing: 'Hout - Printen',
          },
          mm: {
            abs_engraving: 'ABS - Graveren',
            acrylic_3mm_cutting: 'Acryl - 3mm Snijden',
            acrylic_5mm_cutting: 'Acryl - 5mm Snijden',
            acrylic_8mm_cutting: 'Acryl - 8mm Snijden',
            acrylic_10mm_cutting: 'Acryl - 10mm Snijden',
            acrylic_engraving: 'Acryl - Graveren',
            acrylic_printing: 'Acryl - Printen',
            aluminum_engraving: 'Aluminium - Graveren',
            aluminum_light: 'Aluminium (licht)',
            bamboo_printing: 'Bamboe - Printen',
            black_abs: 'Zwart ABS',
            black_acrylic_3mm_cutting: 'Zwart Acryl - 3mm Snijden',
            black_acrylic_5mm_cutting: 'Zwart Acryl - 5mm Snijden',
            black_acrylic_engraving: 'Zwart Acryl - Graveren',
            brass_dark: 'Messing (donker)',
            brass_engraving: 'Messing - Graveren',
            brass_light: 'Messing (licht)',
            canvas_printing: 'Canvas - Printen',
            cardstock_printing: 'Karton - Printen',
            copper: 'Koper',
            cork_printing: 'Kurk - Printen',
            denim_1mm_cutting: 'Denim - 1mm Snijden',
            fabric_3mm_cutting: 'Stof - 3mm Snijden',
            fabric_5mm_cutting: 'Stof - 5mm Snijden',
            fabric_engraving: 'Stof - Graveren',
            fabric_printing: 'Stof - Printen',
            flat_stone_printing: 'Platte steen - Printen',
            glass_bw_engraving: 'Glas - Graveren',
            glass_printing: 'Glas - Printen',
            gloss_leather_printing: 'Glansleer - Printen',
            gold_engraving: 'Goud - Graveren',
            iron_engraving: 'IJzer - Graveren',
            leather_3mm_cutting: 'Leer - 3mm Snijden',
            leather_5mm_cutting: 'Leer - 5mm Snijden',
            leather_engraving: 'Leer - Graveren',
            mdf_3mm_cutting: 'MDF - 3mm Snijden',
            mdf_5mm_cutting: 'MDF - 5mm Snijden',
            mdf_engraving: 'MDF - Graveren',
            metal_bw_engraving: 'Metaal - Graveren',
            opaque_acrylic: 'Ondoorzichtig acryl',
            pc_printing: 'PC - Printen',
            rubber_bw_engraving: 'Rubber - Graveren',
            silver_engraving: 'Zilver - Graveren',
            stainless_steel_bw_engraving_diode: 'Metaal - Graveren (Diode Laser)',
            stainless_steel_dark: 'Roestvrij staal (donker)',
            stainless_steel_engraving: 'Roestvrij Staal - Graveren',
            stainless_steel_light: 'Roestvrij staal (licht)',
            stainless_steel_printing: 'Roestvrij staal - Printen',
            steel_engraving_spray_engraving: 'Metaal - Graveren',
            stone: 'Steen',
            ti_engraving: 'Titaan - Graveren',
            titanium_dark: 'Titanium (donker)',
            titanium_light: 'Titanium (licht)',
            white_abs: 'Wit ABS',
            wood_3mm_cutting: 'Hout - 3mm Snijden',
            wood_5mm_cutting: 'Hout - 5mm Snijden',
            wood_7mm_cutting: 'Hout - 7mm Snijden',
            wood_8mm_cutting: 'Hout - 8mm Snijden',
            wood_10mm_cutting: 'Hout - 10mm Snijden',
            wood_engraving: 'Hout - Graveren',
            wood_printing: 'Hout - Printen',
          },
          parameters: 'Voorinstellingen',
          save: 'Voeg huidige parameters toe',
        },
        engrave: 'Graveren',
        existing_name: 'Deze naam is al in gebruik.',
        fill_angle: 'Vulhoek',
        fill_interval: 'Vulinterval',
        fill_setting: 'Vulinstellingen',
        filled_path_only: 'Alleen voor vulpaden',
        focus_adjustment: 'Focusaanpassing',
        frequency: 'Frequentie',
        gradient_only: 'Alleen voor verloopafbeeldingen',
        halftone: 'Halftoon',
        halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
        height: 'Objecthoogte',
        ink_saturation: 'Verzadiging',
        ink_type: {
          normal: 'Inkt',
          text: 'Inkttype',
          UV: 'UV-inkt',
        },
        laser_speed: {
          fast: 'Snel',
          max: 300,
          min: 3,
          slow: 'Langzaam',
          step: 0.1,
          text: 'Snelheid',
          unit: 'mm/s',
        },
        low_power_warning: 'Lager laservermogen (onder 10%) zou geen laserlicht kunnen uitstralen.',
        low_speed_warning: 'Lage snelheid kan brandplekken op het materiaal veroorzaken.',
        lower_focus: 'Focus Verlagen',
        lower_focus_desc: 'Verlagen van de focushoogte met een specifieke afstand na het focussen om de snijprestaties te verbeteren.',
        module: 'Module',
        more: 'Beheren',
        multi_layer: 'Meerdere lagen',
        para_in_use: 'Deze parameter wordt gebruikt.',
        parameters: 'Parameters',
        power: {
          high: 'Hoog',
          low: 'Laag',
          max: 100,
          min: 1,
          step: 0.1,
          text: 'Vermogen',
        },
        preset_management: {
          add_new: 'Nieuwe Toevoegen',
          delete: 'Verwijderen',
          export: 'Exporteren',
          export_preset_title: 'Voorinstellingen exporteren',
          import: 'Importeren',
          laser: 'Laser',
          lower_focus_by: 'Verlaag Focus met',
          new_preset_name: 'Nieuwe voorinstelling naam',
          preset: 'Voorinstelling',
          print: 'Afdrukken',
          reset: 'Resetten',
          save_and_exit: 'Opslaan en afsluiten',
          show_all: 'Alles Weergeven',
          sure_to_import_presets: 'Hierdoor wordt de indeling van de voorinstellingen geladen en worden aangepaste parameters overschreven. Weet u zeker dat u wilt doorgaan?',
          sure_to_reset: 'Hierdoor worden uw aangepaste parameters verwijderd en worden alle voorinstellingen opnieuw ingesteld. Weet u zeker dat u wilt doorgaan?',
          title: 'Beheer Parameters',
          wobble_diameter: 'Wobble-diameter',
          wobble_step: 'Wobble-stapgrootte',
        },
        preset_setting: 'Parameterinstellingen (%s)',
        presets: 'Voorinstelling',
        print_multipass: 'Meervoudig',
        promark_speed_desc: 'Snelheidsparameters zijn niet van toepassing op afbeeldingen met verlopen.',
        pulse_width: 'Pulsbreedte',
        pwm_advanced_desc: 'Stel een minimaal vermogen in voor dieptemodus.',
        pwm_advanced_hint: 'Deze instelling is specifiek van toepassing op gradiëntafbeeldingen bij gebruik van dieptemodus.',
        pwm_advanced_setting: 'Stroominstellingen voor Dieptemodus',
        repeat: 'Aantal Passes',
        single_color: 'Enkele Kleur',
        single_color_desc: 'Alleen van toepassing op volledige kleurenlaag en kan niet worden gebruikt met uitgebreide enkele kleurenlaag.',
        slider: {
          fast: 'Snel',
          high: 'Hoog',
          low: 'Laag',
          regular: 'Normaal',
          slow: 'Zeer langzaam',
          very_fast: 'Zeer snel',
          very_high: 'Maximaal',
          very_low: 'Minimaal',
          very_slow: 'Langzaamst',
        },
        speed: 'Snelheid',
        speed_constrain_warning: 'De snijsnelheid van vectorpadobjecten wordt beperkt tot %(limit)s. U kunt deze beperking opheffen bij de voorkeursinstellingen.',
        speed_constrain_warning_auto_feeder: 'De snijsnelheid van Auto Feeder-objecten wordt beperkt tot %(limit)s. U kunt deze limiet verwijderen in de Voorkeureninstellingen.',
        speed_constrain_warning_curve_engraving: 'De snelheid van gebogen graveerobjecten wordt beperkt tot %(limit)s. Je kunt deze limiet verwijderen in de voorkeureninstellingen.',
        stepwise_focusing: 'Stapsgewijs Focussen',
        stepwise_focusing_desc: 'Het geleidelijk verlagen van de focusafstand op basis van de hoogte van het object tijdens elk aantal passes.',
        strength: 'Vermogen',
        times: 'keer',
        various_preset: 'Diverse voorinstellingen',
        white_ink: 'Witte inkt',
        white_ink_settings: 'Witte inkt instellingen',
        wobble: 'Wiebelen',
        wobble_desc: 'Creëert kleine cirkels langs het pad, wat voordelig is voor snijtaken. (Alleen voor padobjecten.)',
        wobble_diameter: 'Diameter',
        wobble_step: 'Stapgrootte',
        z_step: 'Z-stap',
      },
      layer_panel: {
        current_layer: 'Huidige laag',
        layer1: 'Laag 1',
        layer_bitmap: 'Bitmap',
        layer_cutting: 'Snijden',
        layer_engraving: 'Graveren',
        layers: {
          del: 'Laag verwijderen',
          dupe: 'Laag dupliceren',
          fullColor: 'Volledige kleur',
          layer: 'Laag',
          layers: 'Lagen',
          lock: 'Laag vergrendelen',
          merge_all: 'Alles samenvoegen',
          merge_down: 'Samenvoegen omlaag',
          merge_selected: 'Geselecteerde lagen samenvoegen',
          move_down: 'Laag omlaag verplaatsen',
          move_elems_to: 'Elementen verplaatsen naar:',
          move_selected: 'Geselecteerde elementen verplaatsen naar een andere laag',
          move_up: 'Laag omhoog verplaatsen',
          new: 'Nieuwe laag',
          rename: 'Laag hernoemen',
          splitFullColor: 'Laag splitsen',
          switchToFullColor: 'Overschakelen naar volledige kleurenlaag',
          switchToSingleColor: 'Overschakelen naar enkele kleurenlaag',
          unlock: 'Ontgrendelen',
        },
        move_elems_to: 'Verplaats elementen naar:',
        notification: {
          dupeLayerName: 'Er is al een laag met die naam!',
          enterNewLayerName: 'Voer de nieuwe laagnaam in',
          enterUniqueLayerName: 'Voer een unieke laagnaam in',
          layerHasThatName: 'Laag heeft al die naam',
          mergeLaserLayerToPrintingLayerMsg: 'Houd er rekening mee dat als u deze bewerking voltooit, de instellingen van de laserlaag worden verwijderd en ingesteld volgens de huidige laag.',
          mergeLaserLayerToPrintingLayerTitle: 'Wilt u deze lagen samenvoegen tot één printlaag?',
          mergePrintingLayerToLaserLayerMsg: 'Houd er rekening mee dat als u deze bewerking voltooit, de kleurinstellingen van de printlaag worden verwijderd en ingesteld volgens de huidige laag.',
          mergePrintingLayerToLaserLayerTitle: 'Wilt u deze lagen samenvoegen tot één laserlaag?',
          moveElemFromPrintingLayerMsg: 'Houd er rekening mee dat als u deze bewerking voltooit, de kleurinstellingen van het geselecteerde element worden verwijderd en ingesteld volgens %s.',
          moveElemFromPrintingLayerTitle: 'Wilt u het geselecteerde element verplaatsen naar %s en converteren naar een laser element?',
          moveElemToPrintingLayerMsg: 'Houd er rekening mee dat als u deze bewerking voltooit, de instellingen van het geselecteerde element worden verwijderd en ingesteld volgens %s.',
          moveElemToPrintingLayerTitle: 'Wilt u het geselecteerde element verplaatsen naar %s en converteren naar een print element?',
          newName: 'NIEUWE NAAM',
          QmoveElemsToLayer: "Geselecteerde elementen verplaatsen naar laag '%s'?",
          splitColorMsg: 'Houd er rekening mee dat u, als u doorgaat met deze bewerking, niet meer kunt terugkeren naar de oorspronkelijke kleurlagen.',
          splitColorTitle: 'Wilt u de geselecteerde laag uitbreiden naar CMYK-lagen?',
        },
      },
      object_panel: {
        actions_panel: {
          ai_bg_removal: 'Achtergrond verwijderen',
          ai_bg_removal_reminder: 'Druk op de knop gebruikt direct 0,2 tegoed, wilt u doorgaan?',
          ai_bg_removal_short: 'Achtergrondverw.',
          array: 'Array',
          auto_fit: 'Auto Aanpassen',
          bevel: 'Afronden',
          brightness: 'Helderheid',
          convert_to_path: 'Converteren naar pad',
          create_textpath: 'Tekst op pad maken',
          create_textpath_short: 'Padtekst',
          crop: 'Bijsnijden',
          decompose_path: 'Ontleden',
          detach_path: 'Tekst van pad scheiden',
          detach_path_short: 'Scheiden',
          disassemble_use: 'Demonteren',
          disassembling: 'Aan het demonteren...',
          edit_path: 'Pad bewerken',
          fetching_web_font: 'Online lettertype ophalen...',
          grading: 'Graderen',
          invert: 'Inverteren',
          offset: 'Verschuiven',
          outline: 'Omtrek',
          replace_with: 'Vervangen door...',
          replace_with_short: 'Vervangen',
          sharpen: 'Scherper maken',
          simplify: 'Vereenvoudigen',
          smart_nest: 'Slim Nestelen',
          trace: 'Natrekken',
          ungrouping: 'Groepering opheffen...',
          uploading_font_to_machine: 'Lettertype uploaden naar machine...',
          wait_for_parsing_font: 'Lettertype aan het parseren... Een moment geduld',
          weld_text: 'Tekst lassen',
        },
        align: 'Uitlijnen',
        boolean: 'Booleaans',
        bottom_align: 'Onder uitlijnen',
        center_align: 'Centreren',
        difference: 'Verschil',
        distribute: 'Verdeel',
        flip: 'Spiegelen',
        group: 'Groep',
        hdist: 'Horizontaal verdelen',
        hflip: 'Horizontaal spiegelen',
        intersect: 'Snijden',
        left_align: 'Links uitlijnen',
        lock_aspect: 'Aspect vergrendelen',
        middle_align: 'Midden uitlijnen',
        option_panel: {
          color: 'Kleur',
          fill: 'Vulling',
          font_family: 'Lettertype',
          font_size: 'Grootte',
          font_style: 'Stijl',
          letter_spacing: 'Letterspatiëring',
          line_spacing: 'Regelafstand',
          path_infill: 'Padvulling',
          pwm_engraving: 'Dieptemodus',
          pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
          rounded_corner: 'Afgeronde hoek',
          shading: 'Verloop',
          sides: 'Zijden',
          start_offset: 'Tekstverschuiving',
          stroke: 'Lijn',
          stroke_color: 'Lijnkleur',
          stroke_width: 'Lijndikte',
          text_infill: 'Tekstvulling',
          threshold: 'Drempelhelderheid',
          threshold_short: 'Drempel',
          vertical_align: 'Uitlijning',
          vertical_text: 'Verticaal tekst',
        },
        path_edit_panel: {
          connect: 'Verbinden',
          delete: 'Verwijderen',
          disconnect: 'Ontkoppelen',
          node_type: 'Knooppunttype',
          round: 'Rond',
          sharp: 'Scherp',
        },
        right_align: 'Rechts uitlijnen',
        subtract: 'Aftrekken',
        top_align: 'Boven uitlijnen',
        ungroup: 'Degroepeer',
        union: 'Verenigen',
        unlock_aspect: 'Aspect ontgrendelen',
        vdist: 'Verticaal verdelen',
        vflip: 'Verticaal omdraaien',
        zoom: 'Zoom',
      },
      tabs: {
        layers: 'Lagen',
        objects: 'Objecten',
        path_edit: 'Pad bewerken',
      },
    },
    shapes_panel: {
      animals: 'Dier',
      arrow: 'Pijl',
      basic: 'Basis',
      birds: 'Vogel',
      celebration: 'Viering',
      circular: 'Cirkelvormig',
      CNY: 'Chinees nieuwjaar',
      corner: 'Hoek',
      decor: 'Decoratie',
      easter: 'Pasen',
      elements: 'Elementen',
      environment: 'Omgeving',
      graphics: 'Grafisch',
      halloween: 'Halloween',
      holidays: 'Feestdag',
      label: 'Label',
      land: 'Land',
      line: 'Lijn',
      nature: 'Natuur',
      photo: 'Foto',
      plants: 'Planten',
      ribbon: 'Lint',
      sea: 'Zee',
      shape: 'Vorm',
      speech: 'Spraak',
      text: 'Tekstframe',
      title: 'Elementen',
      valentines: 'Valentijnsdag',
      weather: 'Weer',
      Xmas: 'Kerstmis',
    },
    svg_editor: {
      unable_to_fetch_clipboard_img: 'Het ophalen van de afbeelding uit het klembord is mislukt',
      unnsupported_file_type: 'Het bestandstype wordt niet direct ondersteund. Converteer het bestand naar SVG of bitmap',
    },
    tag: {
      g: 'Groep',
      image: 'Afbeelding',
      text: 'Tekst',
      use: 'Importeer SVG',
    },
    time_est_button: {
      calculate: 'Schat tijd in',
      estimate_time: 'Geschatte tijd:',
    },
    tool_panels: {
      _nest: {
        end: 'Sluiten',
        no_element: 'Er zijn geen elementen om in te pakken.',
        rotations: 'Mogelijke rotaties',
        spacing: 'Tussenruimte',
        start_nest: 'Inpakken',
        stop_nest: 'Stoppen',
      },
      _offset: {
        corner_type: 'Hoek',
        direction: 'Verschuivingsrichting',
        dist: 'Verschuivingsafstand',
        fail_message: 'Kan objecten niet verplaatsen.',
        inward: 'Naar binnen',
        not_support_message: 'Geselecteerde elementen bevatten niet-ondersteunde SVG-tag:\nAfbeelding, Groep, Tekst en Geïmporteerd Object.',
        outward: 'Naar buiten',
        round: 'Rond',
        sharp: 'Scherp',
      },
      array_dimension: 'Rasterafmetingen',
      array_interval: 'Arrayinterval',
      cancel: 'Annuleren',
      columns: 'Kolommen',
      confirm: 'Bevestigen',
      dx: 'X',
      dy: 'Y',
      grid_array: 'Rasterreeks aanmaken',
      nest: 'Nestoptimalisatie',
      offset: 'Verschuiving',
      rows: 'Rijen',
    },
    units: {
      mm: 'mm',
      walt: 'W',
    },
    zoom_block: {
      fit_to_window: 'Passend maken aan venster',
    },
  },
  boxgen: {
    add_option: 'Optie toevoegen',
    basic_box: 'Basisdoos',
    beam_radius: 'Straalcompensatie',
    beam_radius_warning: 'Verwijder de zaagcompensatie wanneer de randen of verbindingen van de doos kort zijn om de doosassemblage te garanderen',
    cancel: 'Annuleren',
    coming_soon: 'Binnenkort beschikbaar',
    continue_import: 'Doorgaan met importeren',
    control_tooltip: 'Linkermuisknop om te draaien\nScrollen om in te zoomen\nRechtermuisknop om te pannen',
    control_tooltip_touch: 'Slepen om te draaien\nKnijpen om in te zoomen\nTwee vingers om te pannen',
    cover: 'Deksel',
    customize: 'Aanpassen',
    depth: 'Diepte',
    edge: 'Rand',
    finger: 'Vinger',
    finger_warning: 'De minimale binnenmaat van de doos moet 6 mm (0,24 inch) zijn om compatibel te zijn met vingerverbindingen.',
    height: 'Hoogte',
    import: 'Importeren',
    inner: 'Binnen',
    joints: 'Verbinding',
    max_dimension_tooltip: 'Max. breedte/hoogte/diepte instelling is %s.',
    merge: 'Samenvoegen',
    outer: 'Buiten',
    reset: 'Resetten',
    tCount: 'T Aantal',
    tCount_tooltip: 'Het aantal T-sleuven heeft betrekking op de korte zijde; het aantal aan de lange zijde wordt berekend op basis van de lengte.',
    tDiameter: 'T Diameter',
    text_label: 'Label',
    thickness: 'Dikte',
    title: 'DOOSGEN',
    tLength: 'T Lengte',
    tSlot: 'T-sleuf',
    tSlot_warning: 'De minimale binnenmaat van de doos moet 30 mm (1,18 inch) zijn om compatibel te zijn met T-sleuf verbindingen.',
    volume: 'Volume',
    width: 'Breedte',
    workarea: 'Werkgebied',
    zoom: 'Zoomen',
  },
  buttons: {
    back: 'Terug',
    back_to_beam_studio: 'Terug naar Beam Studio',
    done: 'Klaar',
    next: 'Volgende',
  },
  calibration: {
    ador_autofocus_focusing_block: 'Druk 3 seconden op het "AF"-pictogram en laat de sonde het scherpstelblok aanraken.',
    ador_autofocus_material: 'Druk 3 seconden op het "AF"-pictogram en laat de sonde het materiaal zachtjes aanraken.',
    align_ilb: 'Positie: Lijn uit met het gegraveerde punt aan de binnenkant linksonder.',
    align_ilt: 'Positie: Lijn uit met het gegraveerde punt aan de binnenkant linksboven.',
    align_irb: 'Positie: Lijn uit met het gegraveerde punt aan de binnenkant rechtsonder.',
    align_irt: 'Positie: Lijn uit met het gegraveerde punt aan de binnenkant rechtsboven.',
    align_olb: 'Positie: Lijn uit met het gegraveerde punt aan de buitenkant linksonder.',
    align_olt: 'Positie: Lijn uit met het gegraveerde punt aan de buitenkant linksboven.',
    align_orb: 'Positie: Lijn uit met het gegraveerde punt aan de buitenkant rechtsonder.',
    align_ort: 'Positie: Lijn uit met het gegraveerde punt aan de buitenkant rechtsboven.',
    align_red_cross_cut: 'Lijn het midden van het rode kruis uit met het snijdende kruis.',
    align_red_cross_print: 'Lijn het midden van het rode kruis uit met het geprinte kruis.',
    analyze_result_fail: 'Kan vastgelegde afbeelding niet analyseren.<br/>Controleer het volgende:<br/>1. Gefotografeerd papier volledig bedekt met wit papier.<br/>2. Het platform is goed scherpgesteld.',
    ask_for_readjust: 'Wilt u de graveerstap overslaan en dan een foto nemen en rechtstreeks kalibreren?',
    back: 'Terug',
    calculating_camera_matrix: 'Camera matrix berekenen...',
    calculating_regression_parameters: 'Regressieparameters berekenen...',
    calibrate_camera_before_calibrate_modules: 'Voer eerst een camerakalibratie uit voordat u modules kalibreert.',
    calibrate_chessboard_success_msg: 'De foto van het schaakbord is succesvol vastgelegd.<br/>De score voor deze foto is %s (%.2f).',
    calibrate_done: 'Kalibratie voltooid. Betere cameranauwkeurigheid bij precieze scherpstelling.',
    calibrate_done_diode: 'Kalibratie voltooid. Offset van diode lasermodule is opgeslagen.',
    calibrating: 'Kalibreren...',
    calibrating_with_device_pictures: "Kalibreren met apparaatfoto's...",
    camera_calibration: 'Camera-calibratie',
    camera_parameter_saved_successfully: 'Camera parameters succesvol opgeslagen.',
    cancel: 'Annuleren',
    check_checkpoint_data: 'Controlepuntgegevens',
    check_device_pictures: "Apparaatfoto's controleren",
    checking_checkpoint: 'Controleer controlepuntgegevens...',
    checking_pictures: "Apparaatfoto's controleren...",
    diode_calibration: 'Diode lasermodule-calibratie',
    do_engraving: 'Graveren Utivoeren',
    download_chessboard_file: 'Download schaakbord bestand',
    downloading_checkpoint: 'Downloaden van controlepuntgegevens...',
    downloading_pictures: "Foto's downloaden...",
    drawing_calibration_image: 'Kalibratieafbeelding tekenen...',
    dx: 'X',
    dy: 'Y',
    elevate_and_cut: 'Optillen en Snijden',
    elevate_and_cut_step_1: 'Plaats een A4-formaat lichtgekleurd hout in het midden van de werkruimte en verhoog het tot 20mm.',
    elevate_and_cut_step_1_prism_lift: 'Gebruik Ador Prism Lift met een maximale lengte van 14mm samen met een houten plank van minstens 6mm dikte om het hout tot een hoogte van 20mm te verhogen.',
    failed_to_calibrate_camera: '#848 Fout bij camera kalibratie, neem contact op met FLUX Support.',
    failed_to_calibrate_chessboard: 'Mislukt kalibreren met het schaakbordbeeld.',
    failed_to_calibrate_with_pictures: "#848 Kalibratie met apparaatfoto's mislukt.",
    failed_to_download_pictures: "#848 Fout bij downloaden foto's, neem contact op met FLUX Support.",
    failed_to_move_laser_head: 'Kon de laserhoofdkop niet verplaatsen.',
    failed_to_parse_checkpoint: 'Kan de controlepuntgegevens niet analyseren.',
    failed_to_save_calibration_results: '#849 Fout bij opslaan kalibratieresultaten, probeer opnieuw. Neem contact op met FLUX Support als dit blijft gebeuren.',
    failed_to_save_camera_parameter: 'Opslaan van camera parameters mislukt.',
    failed_to_solve_pnp: 'Het lukte niet om de camerapositie op te lossen.',
    finish: 'Klaar',
    found_checkpoint: 'Controlepuntgegevens gevonden op uw apparaat. Wilt u herstellen vanuit het controlepunt?',
    getting_plane_height: 'Hoogte van het vlak ophalen...',
    hint_adjust_parameters: 'Gebruik deze parameters om het rode vierkant aan te passen',
    hint_red_square: 'Lijn de buitenkant van het rode vierkant uit met het gesneden vierkant.',
    module_calibration_2w_ir: 'Infraroodmodule-calibratie',
    module_calibration_printer: 'Printermodule-calibratie',
    moving_laser_head: 'De laserhoofdkop verplaatsen...',
    next: 'Volgende',
    no_picutre_found: "#846 Uw apparaat heeft geen beschikbare ruwe foto's voor kalibratie. Neem contact op met FLUX-ondersteuning.",
    perform_autofocus_bb2: 'Ga naar het bedieningspaneel van de machine en druk op AF om de autofocus uit te voeren.',
    please_do_camera_calibration_and_focus: {
      beambox: 'Wanneer u de diode lasermodule kalibreert, is een camera nodig.\nZorg ervoor dat de camera van deze machine is gekalibreerd.\nEn pas het platform vriendelijk aan op het brandpunt (de hoogte van het omgekeerde acryl)',
      beamo: 'Wanneer u de diode lasermodule kalibreert, is een camera nodig.\nZorg ervoor dat de camera van deze machine is gekalibreerd.\nEn pas de laserkop vriendelijk aan om scherp te stellen op het graveerobject (de hoogte van het omgekeerde acryl)',
    },
    please_goto_beambox_first: 'Schakel over naar de graveermodus (Beambox) om deze functie te gebruiken.',
    please_place_dark_colored_paper: 'Plaats een A4- of briefpapierformaat donkergekleurd papier in het midden van het werkgebied.',
    please_place_paper: 'Plaats een A4- of briefpapierformaat wit papier linksboven in het werkgebied.',
    please_place_paper_center: 'Plaats een A4- of briefpapierformaat wit papier in het midden van het werkgebied.',
    please_refocus: {
      beambox: 'Stel het platform af op de brandpuntsafstand (de hoogte van de omlaag gedraaide acryl).',
      beamo: 'Stel de laserkop af om scherp te stellen op het graveerobject (de hoogte van de omlaag gedraaide acryl).',
      beamo_af: 'Dubbeltik de zijknop van de autofocus-accessoire en laat de sonde het materiaal zachtjes aanraken.',
      hexa: 'Dubbelklik op de hoogteverstelknop om de honingraattabel omhoog te brengen en de sonde het graveermateriaal te laten aanraken.',
    },
    preparing_to_take_picture: 'Voorbereiden om foto te nemen...',
    promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    put_chessboard: 'Schaakbord Plaatsen',
    put_chessboard_1: 'Ga naar het bedieningspaneel van de machine en druk op AF om de autofocus uit te voeren.',
    put_chessboard_2: 'Verplaats het schaakbordpapier of de laserhoofdkop totdat het volledige schaakbord binnen het rode kader in het livevoorbeeldvenster is bevestigd.',
    put_chessboard_3: 'Klik met de rechtermuisknop om de afbeelding van het schaakbord op de huidige locatie te downloaden en controleer de helderheid ervan.',
    put_chessboard_bb2_desc_1: 'Download het volgende schaakbordbestand en print het op A4-papier (het geprinte schaakbord moet vakjes van 1x1 cm hebben).',
    put_chessboard_bb2_desc_2: 'Bevestig het geprinte schaakbordpapier op een niet-vervormbare ondergrond, zoals acryl of glas, en zorg ervoor dat het schaakbord glad is en vrij van kreuken of opstaande randen.',
    put_chessboard_bb2_desc_3: 'Plaats de ondergrond met het schaakbord plat in het midden van het werkgebied.',
    put_chessboard_promark_1: 'Pas de brandpuntsafstand aan naar de juiste focus voor de veldlens.',
    put_chessboard_promark_2: 'Zorg ervoor dat het schaakbordpapier duidelijk zichtbaar is zonder schittering. Klik op "Volgende" om een foto te maken.',
    put_chessboard_promark_desc_1: 'Gebruik het schaakbordpapier dat in de accessoiredoos is meegeleverd, of druk het volgende schaakbordpatroon af op A4-papier voor camerakalibratie.',
    put_chessboard_promark_desc_2: 'Plaats het schaakbord plat en gecentreerd op het werkplatform.',
    put_paper: 'Plaats papier',
    put_paper_promark_1: 'Plaats het zwarte karton uit de accessoiredoos op het werkplatform.',
    put_paper_promark_2: 'Stel de focus correct af en klik vervolgens op "Graveren Starten" om verder te gaan met graveren.',
    put_paper_skip: 'Als de kalibratietekening niet automatisch doorgaat naar het maken van een foto, druk dan op "Overslaan" om een foto te maken.',
    put_paper_step1: 'Plaats alstublieft een A4- of Letter-formaat wit papier in het midden van het werkgebied.',
    put_paper_step2: 'Bevestig de vier hoeken van het papier om ervoor te zorgen dat het plat ligt.',
    put_paper_step3: 'Klik op "Graveren Starten".',
    res_average: 'Gemiddeld',
    res_excellent: 'Uitstekend',
    res_poor: 'Slecht',
    retake: 'Foto opnieuw nemen',
    rotation_angle: 'Rotatie',
    show_last_config: 'Laatste resultaat weergeven',
    skip: 'Overslaan',
    solve_pnp_step1: 'Lijn de graveerpunten uit volgens het nummer en de verwachte positie van elk rood markeerpunt.',
    solve_pnp_step2: 'U kunt op "Foto opnieuw nemen" drukken om opnieuw uit te lijnen of de posities van de markeringen handmatig aan te passen.',
    solve_pnp_step3: 'Als de vastgelegde afbeelding niet overeenkomt met de werkelijke scène, zoom dan in of sleep het scherm om het kalibratiepunt op de zwarte kalibratiekaart naar het midden te brengen en probeer vervolgens de "Foto opnieuw nemen".',
    solve_pnp_title: 'Markeringspunten uitlijnen',
    start_engrave: 'Graveren Starten',
    start_printing: 'Afdrukken Starten',
    taking_picture: 'Foto maken...',
    unable_to_load_camera_parameters: "#851 Er zijn geen camerakalibratieparameters beschikbaar op uw apparaat. Ga naar 'Kalibratie' > 'Camera kalibreren (Geavanceerd)' om de kalibratie te voltooien en de parameters te verkrijgen.",
    update_firmware_msg1: 'Uw firmware ondersteunt deze functie niet. Werk de firmware bij naar v',
    update_firmware_msg2: 'of hoger om door te gaan。 (Menu > Machine > [Uw machine] > Firmware bijwerken)',
    uploading_images: 'Afbeeldingen uploaden...',
    use_last_config: 'Laatste kalibratiewaarden gebruiken',
    use_old_camera_parameter: 'Wilt u de huidige camerobjectiefparameters gebruiken?',
    with_af: 'Met autofocus',
    with_prism_lift: 'Met Ador Prism Lift',
    without_af: 'Zonder autofocus',
    without_prism_lift: 'Zonder Ador Prism Lift',
    x_ratio: 'X-verhouding',
    y_ratio: 'Y-verhouding',
    zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
  },
  camera_data_backup: {
    download_success: 'Camera-gegevens succesvol gedownload.',
    downloading_data: 'Gegevens downloaden...',
    estimated_time_left: 'Geschatte resterende tijd:',
    folder_not_exists: 'Geselecteerde map bestaat niet.',
    incorrect_folder: 'Mislukt om kalibratiegegevens te uploaden. Controleer of de geselecteerde map correct is.',
    no_picture_found: 'Geen afbeelding gevonden in de machine.',
    title: 'Camera gegevens back-up',
    upload_success: 'Camera-gegevens succesvol geüpload.',
    uploading_data: 'Gegevens uploaden...',
  },
  caption: {
    connectionTimeout: 'Verbinding time-out',
  },
  change_logs: {
    added: 'Toegevoegd:',
    change_log: 'Wijzigingslogboeken:',
    changed: 'Gewijzigd:',
    fixed: 'Opgelost:',
    help_center_url: 'https://support.flux3dp.com/hc/en-US/sections/360000421876',
    see_older_version: 'Bekijk oudere versies',
  },
  code_generator: {
    barcode: 'Streepjescode',
    qr_code: 'QR-code',
  },
  curve_engraving: {
    '921': 'Automatisch scherpstellen mislukt.',
    '922': 'Meting met rood licht mislukt.',
    amount: 'Hoeveelheid',
    apply_arkwork: 'Kunstwerk toepassen op kromme',
    apply_camera: 'Camerabeeld toepassen op kromme',
    click_to_select_point: 'Klik om punten te selecteren of te deselecteren voor opnieuw meten.',
    coloumns: 'Kolommen',
    column_gap: 'Kolomafstand',
    err_object_over_range: 'Het object overschrijdt het meetbereik.',
    failed_to_take_reference: 'Referentie nemen mislukt',
    gap: 'Opening',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    measure_audofocus_area: 'Meet autofocusgebied',
    preview_3d_curve: 'Voorbeeld van kromme graveren',
    remeasure: 'Opnieuw meten',
    remeasuring_points: 'Punten opnieuw meten...',
    reselect_area: 'Selecteer gebied opnieuw',
    row_gap: 'Rijafstand',
    rows: 'Rijen',
    set_object_height: 'Stel objecthoogte in',
    set_object_height_desc: 'Meet de maximale dikte van het object.',
    start_autofocus: 'Start autofocus',
    starting_measurement: 'Meten starten...',
    sure_to_delete: 'Wil je de focusgegevens van de kromme gravering verwijderen?',
    take_reference: 'Referentie nemen',
    take_reference_desc: 'Verplaats de laserkop naar het hoogste punt van het object, laat de focusprobe zakken en klik op "Bevestigen" om te focussen.',
  },
  device: {
    abort: 'Afbreken',
    aborted: 'Afgebroken',
    aborting: 'Wordt afgebroken',
    busy: 'Bezig',
    cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
    cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
    cartridge_serial_number: 'Ink cartridge serial number',
    close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
    completed: 'Voltooid',
    completing: 'Voltooien',
    deviceList: 'Apparaatlijst',
    disable: 'Uitschakelen',
    disconnectedError: {
      caption: 'Machine losgekoppeld',
      message: 'Controleer of het netwerk van %s beschikbaar is',
    },
    enable: 'Inschakelen',
    firmware_version: 'Firmwareversie',
    ink_color: 'Ink color',
    ink_level: 'Ink level',
    ink_type: 'Ink type',
    IP: 'IP',
    model_name: 'Modelnaam',
    occupied: 'Bezet',
    pause: 'Pauzeer',
    paused: 'Gepauzeerd',
    pausedFromError: 'Gepauzeerd vanwege fout',
    pausing: 'Wordt gepauzeerd',
    please_wait: 'Een ogenblik geduld...',
    preparing: 'Voorbereiden',
    processing: 'Verwerken',
    quit: 'Afsluiten',
    ready: 'Klaar',
    reset: 'Resetten (uitzetten)',
    resuming: 'Hervatten',
    retry: 'Opnieuw proberen',
    running: 'Werken',
    scanning: 'Scannen',
    select: 'Selecteren',
    select_printer: 'Selecteer printer',
    serial_number: 'Serienummer',
    start: 'Start',
    starting: 'Opstarten',
    status: 'Status',
    submodule_type: 'Module',
    toolhead_change: 'Gereedschapskop wijzigen',
    unknown: 'Onbekend',
    uploading: 'Uploaden',
    UUID: 'UUID',
  },
  device_selection: {
    no_beambox: '#801 We kunnen uw machine niet vinden in het netwerk.\nVolg de <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">handleiding</a> om verbindingsproblemen op te lossen.',
    no_device_web: "#801 Controleer de status van uw machine, of klik op 'Machine-instellingen' hieronder om de machine in te stellen.",
    select_usb_device: 'Selecteer USB-apparaat',
  },
  editor: {
    exposure: 'Voorbeeld belichting',
    opacity: 'Voorbeeld dekking',
    prespray_area: 'Priming gebied',
  },
  error_pages: {
    screen_size: 'Houd er rekening mee dat Beam Studio mogelijk niet optimaal werkt op uw apparaat. Voor een optimale ervaring moet het een apparaat zijn met een schermbreedte van minimaal 1024 pixels.',
  },
  flux_id_login: {
    connection_fail: '#847 Verbinding met FLUX-lidservice mislukt.',
    email: 'E-mail',
    flux_plus: {
      access_monotype_feature: 'U heeft geen Monotype-lettertypen-invoegtoepassing.',
      access_monotype_feature_note: 'U moet FLUX+ Pro-lid zijn of de Monotype-lettertypen-invoegtoepassing hebben om toegang te krijgen tot deze functie.',
      access_plus_feature_1: 'U heeft toegang tot een',
      access_plus_feature_2: 'functie.',
      access_plus_feature_note: 'U moet FLUX+ lid zijn om toegang te krijgen tot deze functie.',
      ai_credit_tooltip: 'Voor AI-achtergrondverwijdering',
      explore_plans: 'Verken FLUX+ abonnementen',
      features: {
        ai_bg_removal: 'AI-achtergrondverwijdering',
        boxgen: '3D-doosgenerator',
        dmkt: '1000+ ontwerpbestanden',
        monotype: '250+ premium lettertypen',
        my_cloud: 'Onbeperkte cloudopslag',
      },
      flux_credit_tooltip: 'Voor ontwerpmarktbestanden en AI-achtergrondverwijdering',
      get_addon: 'Krijg invoegtoepassing',
      goto_member_center: 'Ga naar het ledengedeelte',
      learn_more: 'Meer informatie',
      member_center_url: 'https://member.flux3dp.com/en-US/subscription',
      subscribe_now: 'Abonneer nu',
      thank_you: 'Bedankt voor uw lidmaatschap!',
      website_url: 'https://flux3dp.com/subscription',
    },
    forget_password: 'Wachtwoord vergeten?',
    incorrect: 'E-mailadres of wachtwoord is onjuist.',
    login: 'Inloggen',
    login_success: 'Succesvol ingelogd.',
    lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
    new_to_flux: 'Nieuw bij FLUX? Maak een account.',
    not_verified: 'Het e-mailadres is nog niet geverifieerd.',
    offline: 'Offline werken',
    password: 'Wachtwoord',
    register: 'Maak uw FLUX-account',
    remember_me: 'Onthouden',
    signup_url: 'https://id.flux3dp.com/user/login#up',
    unlock_shape_library: 'Log in om de vormendatabase te ontgrendelen.',
    work_offline: 'Offline werken',
  },
  framing: {
    area_check: 'Gebied Controle',
    areacheck_desc: 'Zorgt voor een veilige werkruimte door het begrenzingsvak van het object en de versnellingszone van de laserkop te tonen.',
    calculating_task: 'Taak berekenen...',
    framing: 'Inlijsten',
    framing_desc: 'Geeft een voorbeeld van de begrenzingsbox van het object.',
    hull: 'Omhulsel',
    hull_desc: 'Voorvertoont een vorm die nauw aansluit bij het ontwerp, zoals een elastiek dat om het object is gewikkeld.',
    low_laser: 'Lage laser',
    low_laser_desc: 'Stel een laag laservermogen in voor de inlijsttaak.',
    rotate_axis: 'Rotatieas',
    rotateaxis_desc: 'Preview het rotatie-as centrum, de as zelf beweegt niet.',
    rotation_frame_warning: 'De framevoorbeeld zal één keer roteren. Stop niet halverwege om verkeerde markeerposities te voorkomen.',
    rotation_framing_desc: 'Preview het volledige rotatiebereik, de as draait volgens de preview.',
    start_task: 'Taak Starten',
    start_task_description: 'Klik op "Taak Starten" na het voltooien van de framevoorbeeld om de taak in te dienen.',
  },
  general: {
    choose_folder: 'Map kiezen',
    processing: 'Verwerken...',
  },
  generic_error: {
    OPERATION_ERROR: '[OE] Er is een statusconflict opgetreden, probeer de actie opnieuw.',
    SUBSYSTEM_ERROR: '[SE] Fout bij het uitvoeren van de taak door de firmware van de machine. Herstart de machine.',
    UNKNOWN_COMMAND: '[UC] Werk de firmware van het apparaat bij',
    UNKNOWN_ERROR: '[UE] Onbekende fout opgetreden. Herstart Beam Studio en de machine.',
  },
  global: {
    apply: 'Toepassen',
    back: 'Terug',
    cancel: 'Annuleren',
    editing: {
      redo: 'Opnieuw',
      reset: 'Resetten',
      undo: 'Ongedaan maken',
      zoom_in: 'Inzoomen',
      zoom_out: 'Uitzoomen',
    },
    mode_conflict: 'Deze optie is niet beschikbaar in de huidige modus.',
    ok: 'OK',
    save: 'Opslaan',
    stop: 'Stop',
  },
  image_edit_panel: {
    eraser: {
      brush_size: 'Kwastgrootte',
      description: 'Klik of sleep om ongewenste gebieden handmatig te wissen.',
      title: 'Gum',
    },
    magic_wand: {
      description: 'Selecteer en verwijder aangrenzende gebieden op basis van kleurvergelijking.',
      title: 'Toverstok',
      tolerance: 'Tolerantie',
    },
    title: 'Afbeelding Bewerken',
  },
  initialize: {
    back: 'Terug',
    cancel: 'Annuleren',
    confirm: 'Bevestigen',
    connect: 'Verbinden',
    connect_ethernet: {
      title: 'Directe verbinding',
      tutorial1: '1. Verbind de machine met uw computer met een ethernetkabel.',
      tutorial2_1: '2. Volg',
      tutorial2_2: 'om uw computer als router in te stellen.',
      tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
      tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
      tutorial2_a_text: 'deze handleiding',
      tutorial3: '3. Klik op Volgende.',
    },
    connect_machine_ip: {
      alert: {
        swiftray_connection_error: 'Kan geen verbinding maken met de server. Herstart Beam Studio en probeer het opnieuw.',
      },
      check_camera: 'Camera beschikbaarheid controleren',
      check_connection: 'Machineverbinding controleren',
      check_firmware: 'Firmwareversie controleren',
      check_ip: 'IP-beschikbaarheid controleren',
      check_swiftray_connection: 'Serververbinding controleren',
      check_swiftray_connection_unreachable: 'server niet bereikbaar',
      check_usb: 'Controleer USB-verbinding',
      enter_ip: 'Voer uw machine-IP in',
      finish_setting: 'Begin met ontwerpen!',
      invalid_format: 'Ongeldig formaat',
      invalid_ip: 'Ongeldig IP: ',
      promark_hint: 'Als u herhaaldelijk geen verbinding via USB kunt maken, raadpleeg dan het <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">Helpcentrum-artikel</a>.',
      retry: 'Opnieuw proberen',
      starts_with_169254: 'Begint met 169.254',
      succeeded_message: 'Verbinding gelukt 🎉',
      unreachable: 'IP onbereikbaar',
    },
    connect_usb: {
      connect_camera: 'Verbind de camera van de machine met uw computer via een USB-kabel.',
      title: 'USB-verbinding',
      title_sub: '(alleen HEXA & Ador)',
      turn_off_machine: 'Zet de machine uit.',
      turn_on_machine: 'Zet de machine aan.',
      tutorial1: 'Sluit de machine aan op uw computer met een USB-kabel.',
      tutorial2: "Klik op 'Volgende'.",
      wait_for_turning_on: "Klik op 'Volgende' nadat het opstartproces is voltooid en u het hoofdscherm hebt bereikt.",
    },
    connect_wifi: {
      title: 'Verbinden met Wi-Fi',
      tutorial1: '1. Ga naar het aanraakscherm > Tik op "Netwerk" > "Verbind met WiFi".',
      tutorial1_ador: '1. Ga naar het aanraakscherm > Tik op "MACHINE" > Tik op "Netwerk" > "Verbind met WiFi".',
      tutorial2: '2. Selecteer en verbind met uw voorkeurs Wi-Fi.',
      what_if_1: 'Wat als ik mijn Wi-Fi niet zie?',
      what_if_1_content: '1. De Wi-Fi-encryptiemodus moet WPA2 zijn of geen wachtwoord hebben.\n2. De encryptiemodus kan worden ingesteld in de Wi-Fi-routerbeheerinterface. Als de router WPA2 niet ondersteunt en u hulp nodig heeft bij het uitkiezen van de juiste router, neem dan contact op met FLUX-ondersteuning.',
      what_if_2: 'Wat als ik helemaal geen Wi-Fi zie?',
      what_if_2_content: '1. Zorg ervoor dat de Wi-Fi-dongle volledig is aangesloten.\n2. Als er geen MAC-adres van het draadloze netwerk op het aanraakscherm staat, neem dan contact op met FLUX-ondersteuning.\n3. Het Wi-Fi-kanaal moet 2.4Ghz zijn (5Ghz wordt niet ondersteund).',
    },
    connect_wired: {
      title: 'Verbinden met bekabeld netwerk',
      tutorial1: '1. Verbind de machine met uw router.',
      tutorial2: '2. Druk op "Netwerk" om het IP-adres van het bekabelde netwerk te krijgen.',
      tutorial2_ador: '2. Druk op "MACHINE" > "Netwerk" om het IP-adres van het bekabelde netwerk te krijgen.',
      what_if_1: 'Wat als het IP-adres leeg is?',
      what_if_1_content: '1. Zorg ervoor dat de ethernetkabel volledig is aangesloten.\n2. Als er geen MAC-adres van het bekabelde netwerk op het aanraakscherm staat, neem dan contact op met FLUX-ondersteuning.',
      what_if_2: 'Wat als het IP begint met 169?',
      what_if_2_content: '1. Als het IP-adres begint met 169.254, zou het een DHCP-instellingsprobleem moeten zijn, neem voor verdere hulp contact op met uw ISP (internet service provider).\n2. Als uw computer direct verbinding maakt met internet via PPPoE, verander dit dan naar verbinding maken via de router met PPPoE en schakel de DHCP-functie op de router in.',
    },
    connecting: 'Verbinden...',
    connection_types: {
      ether2ether: 'Directe verbinding',
      usb: 'USB-verbinding',
      wifi: 'Wi-Fi',
      wired: 'Bekabeld netwerk',
    },
    next: 'Volgende',
    no_machine: 'Ik heb nu geen machine.',
    promark: {
      configuration_confirmation: 'Dit zorgt ervoor dat uw Promark correct is geconfigureerd voor optimale efficiëntie en nauwkeurigheid.',
      or_complete_later: `Of sla deze stap over en voltooi de Promark-instellingen later bij:
    Machines > "Promark Naam" > Promark-instellingen`,
      qc_instructions: 'Vul de parameters in die op de achterkant van de "QC Pass"-kaart staan',
      select_laser_source: 'Selecteer uw Promark',
      select_workarea: 'Selecteer Werkgebied',
      settings: 'Promark-instellingen',
    },
    retry: 'Opnieuw',
    select_beambox: 'Selecteer uw Beambox',
    select_connection_type: 'Hoe wilt u verbinden?',
    select_language: 'Selecteer Taal',
    select_machine_type: 'Selecteer Uw Machinetype',
    setting_completed: {
      back: 'Terug',
      great: 'Welkom bij Beam Studio',
      ok: 'BEGIN MET ONTWERPEN',
      setup_later: 'U kunt uw machine altijd instellen via de titelbalk > "Machines" > "Machine instellen"',
      start: 'Start',
    },
    skip: 'Overslaan',
    start: 'Starten',
  },
  input_machine_password: {
    connect: 'VERBINDEN',
    password: 'Wachtwoord',
    require_password: '"%s" vereist een wachtwoord',
  },
  insecure_websocket: {
    extension_detected: 'Beam Studio Connect-extensie gedetecteerd',
    extension_detected_description: "We hebben gedetecteerd dat u de Beam Studio Connect-extensie heeft geïnstalleerd. Klik op 'Bevestigen' om door te verwijzen naar HTTPS, of klik op 'Annuleren' om HTTP te blijven gebruiken.",
    extension_not_deteced: 'Kan Beam Studio Connect-extensie niet detecteren',
    extension_not_deteced_description: "Om HTTPS te gebruiken, klik op 'Bevestigen' om de Beam Studio Connect-extensie te installeren. Nadat u de extensie hebt geïnstalleerd, ververs de pagina om deze te activeren.<br/>Anders klikt u op de onderstaande link om te zien hoe u HTTP op Chrome kunt gebruiken.",
    unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Link</a>',
  },
  layer_module: {
    general_laser: 'Laser',
    laser_2w_infrared: '2W Infraroodlaser',
    laser_10w_diode: '10W Diode Laser',
    laser_20w_diode: '20W Diode Laser',
    non_working_area: 'Niet-Werkgebied',
    none: 'None',
    notification: {
      convertFromLaserModuleMsg: 'Houd er rekening mee dat als u deze bewerking voltooit, de instellingen van de laserlaag worden verwijderd en ingesteld volgens de huidige laag.',
      convertFromLaserModuleTitle: 'Wilt u de lasermodule converteren naar de printmodule?',
      convertFromPrintingModuleMsg: 'Houd er rekening mee dat als u deze bewerking voltooit, de kleurinstellingen van de printlaag worden verwijderd en ingesteld volgens de huidige laag.',
      convertFromPrintingModuleTitle: 'Wilt u de Printmodule converteren naar de Lasermodule?',
      importedDocumentContainsPrinting: 'Het document bevat een printlaag, wilt u het werkgebied wijzigen naar printen?',
      performIRCaliMsg: 'Klik op "Bevestigen" om de kalibratie uit te voeren, of open de kalibratie via het bovenste menu. <br /> (Machine> [Uw machinenaam]> Kalibreer lasermodule)',
      performIRCaliTitle: 'Voer de kalibratie van de lasermodule uit',
      performPrintingCaliMsg: 'Klik op "Bevestigen" om de kalibratie uit te voeren, of open de kalibratie via het bovenste menu. <br /> (Machine> [Uw machinenaam]> Kalibreer printmodule)',
      performPrintingCaliTitle: 'Voer de kalibratie van de printmodule uit',
      printingLayersCoverted: 'De printlagen zijn geconverteerd naar laserlagen.',
    },
    printing: 'Printen',
    unknown: 'Unknown Module',
  },
  machine_status: {
    '-17': 'Cartridge IO-modus',
    '-10': 'Onderhoudsmodus',
    '-2': 'Scannend',
    '-1': 'Onderhouden',
    '0': 'Inactief',
    '1': 'Initialiseren',
    '2': 'ST_TRANSFORM',
    '4': 'Opstarten',
    '6': 'Hervatten',
    '16': 'Werkend',
    '18': 'Hervatten',
    '32': 'Gepauzeerd',
    '36': 'Gepauzeerd',
    '38': 'Pauzerend',
    '48': 'Gepauzeerd',
    '50': 'Pauzerend',
    '64': 'Voltooid',
    '66': 'Voltooiend',
    '68': 'Voorbereidend',
    '128': 'Afgebroken',
    '256': 'Alarm',
    '512': 'Fataal',
    UNKNOWN: 'Onbekend',
  },
  material_test_generator: {
    block_settings: 'Blokinstellingen',
    columns: 'Kolommen',
    count: 'Aantal',
    cut: 'Knippen',
    engrave: 'Graveren',
    export: 'Exporteren',
    max: 'Max',
    min: 'Min',
    parameter: 'Parameter',
    preview: 'Voorvertoning',
    rows: 'Rijen',
    size: 'Grootte (HxB)',
    spacing: 'Afstand',
    table_settings: 'Tabelinstellingen',
    text_settings: 'Tekstinstellingen',
    title: 'Materiaaltestgenerator',
  },
  menu: {
    inches: 'Duimen',
    mm: 'mm',
  },
  message: {
    auth_error: '#820 Auth-fout: Werk Beam Studio en apparaatfirmware bij naar de nieuwste versie.',
    authenticating: 'Authenticeren...',
    camera: {
      abort_preview: 'Afbreken',
      camera_cable_unstable: "Er is gedetecteerd dat de camera foto's instabiel overdraagt. Voorbeeldweergave kan nog steeds normaal worden uitgevoerd, maar er kan sprake zijn van trage voorbeeldweergave of time-out.",
      continue_preview: 'Doorgaan',
      fail_to_transmit_image: '#845 Er ging iets mis met beeldoverdracht. Start uw machine of Beam Studio opnieuw op. Als deze fout aanhoudt, volg dan <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">deze handleiding</a>.',
      ws_closed_unexpectly: '#844 Verbinding met machinecamera onverwacht verbroken. Als deze fout aanhoudt, volg dan <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">deze handleiding</a>.',
    },
    cancelling: 'Annuleren...',
    cant_connect_to_device: '#827 Kan geen verbinding maken met de machine, controleer uw verbinding',
    connected: 'Verbonden',
    connecting: 'Verbinden...',
    connectingCamera: 'Camera verbinden...',
    connectingMachine: 'Verbinden met %s...',
    connectionTimeout: '#805 Apparaat verbinding time-out. Controleer uw netwerkstatus en de Wi-Fi-indicator van uw machine.',
    device_blocked: {
      caption: 'Niet-geautoriseerd serienummer',
      offline: 'Het serienummer van uw apparaat is beperkt tot offline gebruik omdat het gedeactiveerd lijkt te zijn. Geef het serienummer van het apparaat (te vinden aan de achterkant van het apparaat) aan uw verkoper en vraag hem contact op te nemen met support@flux3dp.com om de online autorisatie te activeren. Als u het apparaat offline wilt gebruiken, neem dan rechtstreeks contact op met support@flux3dp.com.',
      online: 'Het serienummer van uw apparaat lijkt gedeactiveerd te zijn. Geef het serienummer van het apparaat (te vinden aan de achterkant van het apparaat) aan uw verkoper en vraag hem contact op te nemen met support@flux3dp.com om de apparaatautorisatie te activeren.',
    },
    device_busy: {
      caption: 'Apparaat bezet',
      message: 'Het apparaat voert een andere taak uit, probeer het later opnieuw. Als het apparaat niet meer werkt, start het dan opnieuw op.',
    },
    device_is_used: 'Het apparaat wordt gebruikt, wilt u de huidige taak annuleren?',
    device_not_found: {
      caption: 'Standaardmachine niet gevonden',
      message: '#812 Controleer de Wi-Fi-indicator van uw apparaat',
    },
    disconnected: 'Verbinding instabiel, controleer apparaatverbinding en probeer het later opnieuw',
    endingLineCheckMode: 'Lijncontrolemodus sluiten...',
    endingRawMode: 'Raw-modus sluiten...',
    enteringLineCheckMode: 'Lijncontrolemodus openen...',
    enteringRawMode: 'Raw-modus openen...',
    enteringRedLaserMeasureMode: 'Rood-laser meetmodus wordt ingeschakeld...',
    exitingRotaryMode: 'Rotatiemodus sluiten...',
    getProbePosition: 'Probe-positie ophalen...',
    gettingLaserSpeed: 'Lasersnelheid lezen...',
    homing: 'Refereren...',
    need_password: 'Wachtwoord vereist om verbinding te maken met het apparaat',
    please_enter_dpi: 'Voer de eenheid van uw bestand in (in mm)',
    preview: {
      adjust: 'Aanpassen',
      adjust_height_tooltip: 'Schakel het selectievakje in om bewerken mogelijk te maken.',
      already_performed_auto_focus: 'U heeft al automatisch scherpgesteld, bestaande waarden gebruiken?',
      auto_focus: 'Auto scherpstellen',
      auto_focus_instruction: 'Verplaats de lasermodulekop boven het object en volg de animatie-instructies om op AF te drukken om scherp te stellen.',
      camera_preview: 'Camera voorbeeldweergave',
      enter_manually: 'Handmatig invoeren',
      please_enter_height: 'Voer de hoogte van het object in om een nauwkeurige camerafoto te maken.',
    },
    promark_disconnected: '#850 Apparaatverbinding onderbroken, controleer de verbindingsstatus van het apparaat.',
    redLaserTakingReference: 'Referentie wordt genomen...',
    retrievingCameraOffset: 'Camera-offset lezen...',
    settingLaserSpeed: 'Lasersnelheid instellen...',
    swiftray_disconnected: 'Kan geen verbinding maken met de backend, opnieuw verbinden.',
    swiftray_reconnected: 'Backend opnieuw verbonden, probeer de taak opnieuw te verzenden.',
    time_remaining: 'Resterende tijd:',
    tryingToConenctMachine: 'Proberen te verbinden met machine...',
    turningOffAirPump: 'Luchtpomp uitschakelen...',
    turningOffFan: 'Ventilator uitschakelen...',
    unable_to_find_machine: 'Kan machine niet vinden',
    unable_to_start: '#830 Kan de taak niet starten. Probeer het opnieuw. Als dit opnieuw gebeurt, neem dan contact met ons op met een bugrapportage:',
    unavailableWorkarea: '#804 Huidige werkgebied overschrijdt het werkgebied van dit apparaat. Controleer het werkgebied van het geselecteerde apparaat of stel het werkgebied in via Bewerken > Documentinstellingen.',
    unknown_device: '#826 Kan geen verbinding maken met het apparaat, zorg ervoor dat de USB is aangesloten op het apparaat',
    unknown_error: '#821 De applicatie heeft een onbekende fout gevonden, gebruik Help > Menu > Fout rapporteren.',
    unsupport_osx_version: 'De huidige macOS-versie %s ondersteunt mogelijk niet alle functies. Werk alsjeblieft bij naar macOS 11+.',
    unsupport_win_version: 'De huidige besturingssysteemversie %s ondersteunt mogelijk niet alle functies. Werk alsjeblieft bij naar de nieuwste versie.',
    unsupported_example_file: 'Het geselecteerde voorbeeldbestand wordt niet ondersteund door het huidige werkgebied.',
    uploading_fcode: 'FCode uploaden',
    usb_unplugged: 'USB-verbinding verbroken. Controleer uw USB-verbinding',
    wrong_swiftray_version_message: 'Onjuiste backend-versie (versie: {version}). Controleer of er meerdere instanties van Beam Studio actief zijn en sluit overtollige instanties voordat u het opnieuw probeert.',
    wrong_swiftray_version_title: 'Onjuiste backend-versie',
  },
  monitor: {
    ask_reconnect: 'De verbinding met de machine is verbroken. Wilt u opnieuw verbinden?',
    bug_report: 'Foutrapport',
    camera: 'Camera',
    cancel: 'Annuleren',
    confirmFileDelete: 'Weet u zeker dat u dit bestand wilt verwijderen?',
    connecting: 'Verbinden...',
    DEVICE_ERROR: 'Er ging iets mis. Start de machine opnieuw op.',
    download: 'Downloaden',
    extensionNotSupported: 'Dit bestandsformaat wordt niet ondersteund',
    fileExistContinue: 'Het bestand bestaat al, wilt u het overschrijven?',
    forceStop: 'Wilt u de huidige taak afbreken?',
    go: 'Start',
    HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Luchtstroomsensor afwijkend',
    HARDWARE_ERROR_BOTTOM_OPENED: '#903 Onderkant geopend. Sluit de onderkant om door te gaan.',
    HARDWARE_ERROR_DOOR_OPENED: '#901 Sluit de deur om door te gaan.',
    HARDWARE_ERROR_DRAWER_OPENED: '#911 Lade geopend',
    HARDWARE_ERROR_FIRE_DETECTED: '#912 Vlamsensor afwijkend',
    HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Onjuiste module gedetecteerd. Installeer de juiste module om door te gaan.',
    HARDWARE_ERROR_HEADTYPE_NONE: '#917 Module niet gedetecteerd. Zorg dat de module juist is geïnstalleerd om door te gaan.',
    HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Onbekende module gedetecteerd. Installeer de juiste module om door te gaan.',
    HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Homing pull-off fout',
    HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Kritieke fout: Hoofdprintplaat offline. Neem contact op met FLUX-ondersteuning.',
    HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Oververhit. Wacht een paar minuten.',
    HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Z-as reset mislukt',
    HARDWARE_ERROR_PRINTER_NO_RESPONSE: 'Geen reactie van printermodule.',
    HARDWARE_ERROR_PROBE_SHOWED: 'Trek de probe alstublieft in.',
    HARDWARE_ERROR_PUMP_ERROR: '#900 Controleer uw watertank.',
    HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Geen draaitafelmodule gedetecteerd',
    HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Kritieke fout: Geen reactie van subsysteem. Neem contact op met FLUX-ondersteuning.',
    HARDWARE_FAILURE: 'Er ging iets mis. Start de machine opnieuw op.',
    hour: 'u',
    left: 'links',
    MAINBOARD_OFFLINE: '#905 Fout bij verbinden met moederbord. Start de machine opnieuw op.',
    minute: 'm',
    monitor: 'MONITOR',
    NO_RESPONSE: '#905 Fout bij verbinden met moederbord. Start de machine opnieuw op.',
    pause: 'Pauzeren',
    prepareRelocate: 'Voorbereiden op verplaatsen',
    processing: 'Verwerken',
    record: 'Opnemen',
    relocate: 'Verplaatsen',
    RESOURCE_BUSY: 'De machine is bezet. Herstart de machine als deze niet draait.',
    resume: 'Hervatten',
    savingPreview: 'Miniatuurweergaven genereren',
    second: 's',
    start: 'Start',
    stop: 'Stoppen',
    SUBSYSTEM_ERROR: '#402 Kritieke fout: subsysteem reageert niet. Neem contact op met FLUX-ondersteuning.',
    task: {
      BEAMBOX: 'Laser graveren',
      'N/A': 'Vrije modus',
    },
    taskTab: 'Taak',
    temperature: 'Temperatuur',
    upload: 'Uploaden',
    USER_OPERATION: 'Volg de instructies op het apparaatpaneel om door te gaan.',
    USER_OPERATION_CHANGE_CARTRIDGE: 'Plaats de juiste cartridge om door te gaan.',
    USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Plaats de cartridge om door te gaan.',
    USER_OPERATION_CHANGE_TOOLHEAD: 'Installeer de juiste module om door te gaan.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Onjuiste module gedetecteerd. Installeer de juiste module om door te gaan.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Module niet gedetecteerd. Zorg dat de module juist is geïnstalleerd om door te gaan.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Onbekende module gedetecteerd. Installeer de juiste module om door te gaan.',
    USER_OPERATION_REMOVE_CARTRIDGE: 'Verwijder de cartridge om door te gaan.',
    USER_OPERATION_ROTARY_PAUSE: 'Schakel over naar de draaimotor.',
  },
  my_cloud: {
    action: {
      confirmFileDelete: 'Weet u zeker dat u dit bestand wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.',
      delete: 'Verwijderen',
      download: 'Downloaden',
      duplicate: 'Dupliceren',
      open: 'Openen',
      rename: 'Hernoemen',
    },
    file_limit: 'Gratis bestand',
    loading_file: 'Bezig met laden...',
    no_file_subtitle: 'Ga naar Menu > "Bestand" > "Opslaan in cloud"',
    no_file_title: 'Sla bestanden op in Mijn cloud om te beginnen.',
    save_file: {
      choose_action: 'Bestand opslaan:',
      input_file_name: 'Voer bestandsnaam in:',
      invalid_char: 'Ongeldige tekens:',
      save: 'Opslaan',
      save_new: 'Opslaan als nieuw bestand',
      storage_limit_exceeded: 'Uw cloudopslag heeft de bovengrens bereikt. Verwijder onnodige bestanden voordat u nieuwe opslaat.',
    },
    sort: {
      a_to_z: 'Naam: A - Z',
      most_recent: 'Meest recent',
      oldest: 'Oudste',
      z_to_a: 'Naam: Z - A',
    },
    title: 'Mijn cloud',
    upgrade: 'Upgrade',
  },
  noun_project_panel: {
    clear: 'Wissen',
    elements: 'Elementen',
    enjoy_shape_library: 'Geniet van de vormenbibliotheek.',
    export_svg_title: 'Kan geen SVG exporteren',
    export_svg_warning: 'Dit project bevat objecten die beschermd zijn door de intellectuele eigendomswet. Beam Studio zal deze objecten daarom automatisch uitsluiten tijdens de export. U kunt uw project nog steeds opslaan in het Beam Studio Scene (.beam)-formaat om al uw gegevens te behouden. Wilt u nog steeds exporteren?',
    learn_more: 'Meer informatie',
    login_first: 'Log in om de vormendatabase te ontgrendelen.',
    recent: 'Recent',
    search: 'Zoeken',
    shapes: 'Vormen',
  },
  pass_through: {
    export: 'Exporteren naar Werkgebied',
    exporting: 'Exporteren...',
    guide_mark: 'Gidsmarkering',
    guide_mark_desc: 'Gidsmarkeringen worden gegraveerd als referentiepunt om het kunstwerk uit te lijnen.',
    guide_mark_length: 'Lengte:',
    guide_mark_x: 'X-coördinaat:',
    height_desc: 'Stel de hoogte van elke werkgebiedsectie in.',
    help_links: {
      ado1: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
      fbb2: 'https://support.flux3dp.com/hc/en-us/articles/11570021253263',
    },
    help_text: 'Hoe stel je doorgang in voor %(model)s?',
    object_length: 'Objectlengte',
    ref_layer: 'Referentielaag',
    ref_layer_desc: 'Let op dat de uitvoering van de referentielaag standaard is ingesteld op 0. Het wordt niet uitgevoerd, maar dient alleen als uitlijningsreferentie.',
    ref_layer_name: 'Referentie',
    title: 'Doorgangsmodus',
    workarea_height: 'Werkgebied (Hoogte):',
  },
  promark_connection_test: {
    description: 'Het systeem voert ongeveer twee minuten een roodlichtbewerking uit om te controleren of de verbinding stabiel is.',
    health: 'Stabiliteit:',
    healthy_description: 'De stabiliteit is goed, maar vermijd aanzienlijke beweging van de computer tijdens het graveren.',
    res_0: 'Uitstekend',
    res_1: 'Goed',
    res_2: 'Laag',
    res_3: 'Te Laag',
    res_4: 'Zeer Laag',
    restart: 'Test Opnieuw Starten',
    start: 'Test Starten',
    stop: 'Test Stoppen',
    title: 'Verbindingsstabiliteitstest',
    unhealthy_description: 'De stabiliteit is te laag. Het wordt aanbevolen om de netwerkadapter of stroomkabel te vervangen.',
  },
  promark_settings: {
    angle: 'Hoek',
    bulge: 'Bolling',
    field: 'Veld',
    galvo_configuration: 'Galvo-configuratie',
    mark: 'Markeren',
    mark_parameters: 'Markeerparameters',
    offsetX: 'Offset X',
    offsetY: 'Offset Y',
    preview: 'Voorvertoning',
    red_dot: 'Rode stip',
    scale: 'Schaal',
    scaleX: 'Schaal X',
    scaleY: 'Schaal Y',
    skew: 'Scheefheid',
    switchXY: 'X/Y wisselen',
    title: 'Promark-instellingen',
    trapezoid: 'Trapezium',
    workarea_hint: 'U kunt het werkgebied wijzigen in "Documentinstellingen".',
    z_axis_adjustment: {
      section1: 'Pas de hoogte van de Z-as aan om de focus te verfijnen.',
      title: 'Z-As Aanpassing',
      tooltip1: 'Probeer een vierkant van 1x1 cm te markeren om te bevestigen of de huidige brandpuntsafstand geschikt is.',
    },
  },
  qr_code_generator: {
    error_tolerance: 'Foutcorrectie',
    error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
    invert: 'Achtergrondkleur omkeren',
    placeholder: 'Voer een link of tekst in',
    preview: 'Voorbeeld',
    title: 'QR-codegenerator',
  },
  rotary_settings: {
    circumference: 'Omtrek',
    extend_workarea: 'Werkgebied uitbreiden',
    mirror: 'Spiegel',
    object_diameter: 'Objectdiameter',
    overlap_size: 'Overlapgrootte',
    rotary_scale: 'Rotatieschaal',
    split_setting: 'Splitsinstelling',
    split_setting_url: 'https://support.flux3dp.com/hc/en-us/articles/12276094010767',
    split_size: 'Splitsingsgrootte',
    type: 'Type',
  },
  select_device: {
    auth_failure: '#811 Verificatie mislukt',
    select_device: 'Selecteer apparaat',
    unable_to_connect: '#810 Kan geen stabiele verbinding maken met machine',
  },
  settings: {
    anti_aliasing: 'Antialiasing',
    auto_connect: 'Selecteer automatisch de enige machine',
    auto_switch_tab: 'Automatisch schakelen tussen laag- en objectpaneel',
    autofocus_offset: 'Autofocus offset',
    autosave_enabled: 'Automatisch opslaan',
    autosave_interval: 'Elke',
    autosave_number: 'Aantal automatisch opslaan',
    autosave_path: 'Locatie voor automatisch opslaan',
    autosave_path_not_correct: 'Opgegeven pad niet gevonden.',
    blade_precut_position: 'Voorinsnijpositie',
    blade_precut_switch: 'Mes voorinsnijding',
    blade_radius: 'Messtraal',
    bottom_up: 'Van onder naar boven',
    calculation_optimization: 'Versnelling van padberekening',
    cancel: 'Annuleren',
    caption: 'Instellingen',
    check_updates: 'Automatisch controleren',
    close: 'Sluiten',
    confirm_remove_default: 'De standaardmachine wordt verwijderd.',
    confirm_reset: 'Beam Studio resetten bevestigen?',
    continuous_drawing: 'Doorlopende tekening',
    curve_engraving_speed_limit: 'Snelheidslimiet voor kromme graveren',
    custom_preview_height: 'Aangepaste voorbeeldhoogte',
    default_beambox_model: 'Standaard documentinstellingen',
    default_borderless_mode: 'Open onderkant standaard',
    default_enable_autofocus_module: 'Autofocus standaard',
    default_enable_diode_module: 'Diode laser standaard',
    default_font_family: 'Standaard lettertype',
    default_font_style: 'Standaard lettertypestijl',
    default_laser_module: 'Standaard lasermodule',
    default_machine: 'Standaardmachine',
    default_machine_button: 'Leeg',
    default_units: 'Standaard eenheden',
    diode_offset: 'Diode laser offset',
    diode_one_way_engraving: 'Diode laser eenrichtingsgravering',
    diode_two_way_warning: 'Bidirectioneel licht uitstralen is sneller en genereert waarschijnlijk enige onnauwkeurigheid in de lasergraveerpositie. Aanbevolen om eerst te testen.',
    disabled: 'Uitgeschakeld',
    done: 'Toepassen',
    enable_custom_backlash: 'Schakel aangepaste backlash compensatie in',
    enable_low_speed: 'Schakel lage snelheidsbeweging in',
    enabled: 'Ingeschakeld',
    engraving_direction: 'Richting',
    fast_gradient: 'Snelheidsoptimalisatie',
    font_convert: 'Tekst naar pad Converter',
    font_substitute: 'Vervang onondersteunde tekens',
    grouped_objects: 'Gegroepeerde objecten',
    groups: {
      ador_modules: 'Ador-plug-ins',
      autosave: 'Automatisch opslaan',
      camera: 'Camera',
      connection: 'Verbinding',
      editor: 'Editor',
      engraving: 'Graveren (scannen)',
      general: 'Algemeen',
      mask: 'Werkgebied bijsnijden',
      modules: 'Plug-ins',
      path: 'Vector (omtrekken)',
      privacy: 'Privacy',
      text_to_path: 'Tekst',
      update: 'Software-updates',
    },
    guess_poke: 'Zoek naar machine IP-adres',
    guides: 'Hulplijnen',
    guides_origin: 'Hulplijnen oorsprong',
    help_center_urls: {
      anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
      calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
      connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
      continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
      default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
      default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
      default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
      fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
      font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
      font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
      image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
      loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
      mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
      reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
      segmented_engraving: 'https://support.flux3dp.com/hc/en-us/articles/12306366019215',
      simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
      vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
    },
    high: 'Hoog',
    image_downsampling: 'Bitmap voorbeeldkwaliteit',
    ip: 'Machine IP-adres',
    keep_preview_result: 'Voorbeeldresultaat Behouden',
    language: 'Taal',
    loop_compensation: 'Luscompensatie',
    low: 'Laag',
    low_laser_for_preview: 'Laser voor Frame uitvoeren',
    mask: 'Werkgebied bijsnijden',
    medium: 'Gemiddeld',
    module_offset_2w_ir: '2W infrarood laser offset',
    module_offset_10w: '10W diode laser offset',
    module_offset_20w: '20W diode laser offset',
    module_offset_printer: 'Printer offset',
    none: 'Geen',
    normal: 'Normaal',
    notification_off: 'Uit',
    notification_on: 'Aan',
    notifications: 'Bureaubladmeldingen',
    off: 'Uit',
    on: 'Aan',
    preview_movement_speed: 'Voorbeeld bewegingssnelheid',
    printer_advanced_mode: 'Printer geavanceerde modus',
    remove_default_machine_button: 'Verwijderen',
    reset: 'Beam Studio resetten',
    reset_now: 'Beam Studio resetten',
    segmented_engraving: 'Gesegmenteerd graveren',
    share_with_flux: 'Beam Studio-analyses delen',
    simplify_clipper_path: 'Optimaliseer het berekende pad',
    single_object: 'Enkel object',
    tabs: {
      device: 'Machine',
      general: 'Algemeen',
    },
    text_path_calc_optimization: 'Padberekeningsoptimalisatie',
    top_down: 'Van boven naar beneden',
    trace_output: 'Afbeelding vectorisatie uitvoer',
    update_beta: 'Bèta',
    update_latest: 'Laatste',
    vector_speed_constraint: 'Snelheidslimiet',
    wrong_ip_format: 'Ongeldig IP-formaat',
  },
  social_media: {
    facebook: 'Chat met FLUXers, stel vragen en leer tips!',
    instagram: 'Ontvang de nieuwste inspiratie, aanbiedingen en gratis items!',
    youtube: 'Bekijk Beam Studio-tutorials en knutselideeën.',
  },
  support: {
    no_vcredist: 'Installeer Visual C++ Redistributable 2015<br/>Downloadbaar op flux3dp.com',
    no_webgl: 'WebGL wordt niet ondersteund. Gebruik een ander apparaat.',
  },
  topbar: {
    alerts: {
      add_content_first: 'Voeg eerst objecten toe',
      door_opened: 'Sluit het deksel om laag laservermogen voor het uitvoeren van frames in te schakelen.',
      fail_to_connect_with_camera: '#803 Kan geen verbinding maken met machinecamera. Start uw machine of Beam Studio opnieuw op. Als deze fout zich blijft voordoen, volg dan <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">deze handleiding</a>.',
      fail_to_get_door_status: 'Zorg ervoor dat het deksel gesloten is om laag laservermogen voor het uitvoeren van frames in te schakelen.',
      fail_to_start_preview: '#803 Kan voorbeeldmodus niet starten. Start uw machine of Beam Studio opnieuw op. Als deze fout zich blijft voordoen, volg dan <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">deze handleiding</a>.',
      headtype_mismatch: 'Onjuiste module gedetecteerd. ',
      headtype_none: 'Module niet gedetecteerd. ',
      headtype_unknown: 'Onbekende module gedetecteerd. ',
      install_correct_headtype: 'Installeer de 10W/20W diode lasermodules correct om laag laservermogen voor het uitvoeren van frames in te schakelen.',
      job_origin_unavailable: 'Het instellen van de taakbron vereist firmwareversie 4.3.5 / 5.3.5 of hoger. Wilt u de firmware nu bijwerken?',
      job_origin_warning: 'U gebruikt momenteel de “huidige positie” als startpunt. Zorg ervoor dat de laserkop naar de juiste positie wordt verplaatst om botsingen te voorkomen.',
      power_too_high: 'TE HOGE SPANNING',
      power_too_high_confirm: 'BEGREPEN',
      power_too_high_msg: 'Gebruik een lager laservermogen (onder 70%) om de levensduur van de lasertube te verlengen.\nVoer "BEGREPEN" in om door te gaan.',
      pwm_unavailable: 'Dieptemodus vereist firmwareversie 4.3.4 / 5.3.4 of hoger. Wilt u de firmware nu bijwerken?',
      QcleanScene: 'Wil je de tekening wissen?<br/>Dit zal ook je undo-geschiedenis wissen!',
      start_preview_timeout: '#803 Time-out opgetreden bij het starten van de voorbeeldmodus. Start uw machine of Beam Studio opnieuw op. Als deze fout zich blijft voordoen, volg dan <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">deze handleiding</a>.',
    },
    frame_task: 'Frame uitvoeren',
    hint: {
      polygon: 'Druk op + / - om het aantal zijden te verhogen / verlagen.',
    },
    menu: {
      about: 'Over Beam Studio',
      about_beam_studio: 'Over Beam Studio',
      account: 'Account',
      add_new_machine: 'Machine-instellingen',
      align_center: 'Centreren',
      anti_aliasing: 'Antialiasing',
      auto_align: 'Automatisch Uitlijnen',
      borderless_mode: 'Randloze modus',
      bug_report: 'Fout melden',
      calibrate_beambox_camera: 'Camera kalibreren',
      calibrate_beambox_camera_borderless: 'Camera kalibreren (open onderkant)',
      calibrate_camera_advanced: 'Camera kalibreren (Geavanceerd)',
      calibrate_diode_module: 'Diodelasermodule kalibreren',
      calibrate_ir_module: 'Infraroodmodule kalibreren',
      calibrate_printer_module: 'Printmodule kalibreren',
      calibration: 'Kalibraties',
      camera_calibration_data: 'Camera kalibratiegegevens',
      change_logs: 'Wijzigingslogboeken',
      clear_scene: 'Nieuwe bestanden',
      close: 'Venster sluiten',
      commands: "Commando's",
      contact: 'Contact',
      copy: 'Kopiëren',
      cut: 'Knippen',
      dashboard: 'Dashboard',
      decompose_path: 'Ontleden',
      delete: 'Verwijderen',
      design_market: 'Ontwerpmarkt',
      dev_tool: 'Debug-tool',
      disassemble_use: 'Demonteren',
      document_setting: 'Documentinstellingen',
      document_setting_short: 'Document',
      download_data: 'Gegevens downloaden',
      download_log: 'Logs downloaden',
      download_log_canceled: 'Log download geannuleerd',
      download_log_error: 'Onbekende fout opgetreden, later opnieuw proberen',
      duplicate: 'Dupliceren',
      edit: 'Bewerken',
      example_files: 'Voorbeeldbestanden',
      export_BVG: 'BVG',
      export_flux_task: 'FLUX taak',
      export_JPG: 'JPG',
      export_PNG: 'PNG',
      export_SVG: 'SVG',
      export_to: 'Exporteren naar...',
      file: 'Bestand',
      fit_to_window: 'Aanpassen aan venster',
      follow_us: 'Volg ons',
      forum: 'Communityforum',
      group: 'Groeperen',
      help: 'Help',
      help_center: 'Hulppagina',
      hide: 'Verberg Beam Studio',
      hideothers: 'Anderen verbergen',
      image_crop: 'Bijsnijden',
      image_curve: 'Kromme',
      image_invert: 'Inverteren',
      image_sharpen: 'Scherpstellen',
      image_stamp: 'Facet',
      image_vectorize: 'Vectoriseren',
      import_acrylic_focus_probe: 'Acryl focus probe - 3mm',
      import_ador_laser_example: 'Voorbeeld van Ador Laser',
      import_ador_printing_example_full: 'Voorbeeld van Ador Printing - Volledige kleur',
      import_ador_printing_example_single: 'Voorbeeld van Ador Printing - Enkele kleur',
      import_beambox_2_example: 'Beambox II voorbeeld ',
      import_beambox_2_focus_probe: 'Beambox II Focusprobe - 3 mm',
      import_hello_beambox: 'Beambox voorbeeld',
      import_hello_beamo: 'beamo voorbeeld',
      import_hexa_example: 'Voorbeeld van HEXA',
      import_material_printing_test: 'Materiaal printtest',
      import_material_testing_cut: 'Materiaal snijtest',
      import_material_testing_engrave: 'Materiaal graveertest',
      import_material_testing_line: 'Materiaal testlijn',
      import_material_testing_old: 'Materiaal graveertest - Klassiek',
      import_material_testing_simple_cut: 'Materiaal snijtest - Eenvoudig',
      import_promark_example: 'Voorbeeld van Promark',
      import_promark_mopa_20w_color: 'MOPA 20W Kleurtest',
      import_promark_mopa_60w_color: 'MOPA 60W Kleurtest',
      import_promark_mopa_100w_color: 'MOPA 100W Kleurtest',
      keyboard_shortcuts: 'Toetsencombinaties',
      layer_color_config: 'Kleurinstellingen',
      layer_setting: 'Laag',
      link: {
        beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
        contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
        design_market: 'https://dmkt.io',
        downloads: 'https://flux3dp.com/downloads/',
        forum: 'https://www.facebook.com/groups/flux.laser/',
        help_center: 'https://helpcenter.flux3dp.com/',
        shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
      },
      log: {
        camera: 'Camera',
        cloud: 'Cloud',
        discover: 'Ontdekken',
        hardware: 'Hardware',
        network: 'Netwerk',
        player: 'Speler',
        robot: 'Robot',
        usb: 'USB',
        usblist: 'USB-lijst',
      },
      machine_info: 'Machinegegevens',
      machines: 'Machines',
      manage_account: 'Mijn account beheren',
      material_test: 'Materiaaltest',
      minimize: 'Minimaliseren',
      my_account: 'Mijn account',
      network_testing: 'Netwerkinstellingen testen',
      object: 'Object',
      offset: 'Verschuiven',
      open: 'Openen',
      paste: 'Plakken',
      paste_in_place: 'Plakken op plaats',
      path: 'Pad',
      photo_edit: 'Afbeelding',
      preferences: 'Voorkeuren',
      promark_color_test: 'Promark Kleurtest',
      questionnaire: 'Vragenlijst feedback',
      quit: 'Afsluiten',
      recent: 'Open recente bestanden',
      redo: 'Opnieuw',
      reload_app: 'App opnieuw laden',
      reset: 'Resetten',
      rotary_setup: 'Draaibare Instelling',
      rotate: 'Draaien',
      samples: 'Voorbeelden',
      save_as: 'Opslaan als...',
      save_scene: 'Opslaan',
      save_to_cloud: 'Opslaan in cloud',
      scale: 'Schalen',
      service: 'Diensten',
      set_as_default: 'Instellen als standaard',
      show_gesture_tutorial: 'Introductie handgebaren',
      show_grids: 'Rasters weergeven',
      show_layer_color: 'Laagkleur gebruiken',
      show_rulers: 'Linialen tonen',
      show_start_tutorial: 'Introductietutorial tonen',
      show_ui_intro: 'Interface-introductie tonen',
      sign_in: 'Inloggen',
      sign_out: 'Uitloggen',
      software_update: 'Software-update',
      svg_edit: 'SVG',
      switch_to_beta: 'Schakel over naar bètaversie',
      switch_to_latest: 'Schakel over naar stabiele release',
      tools: {
        box_generator: 'Doos Generator',
        code_generator: 'Codegenerator',
        material_test_generator: 'Materiaaltestgenerator',
        title: 'Gereedschappen',
      },
      tutorial: 'Delta-familie printtutorial starten',
      undo: 'Ongedaan maken',
      ungroup: 'Degroeperen',
      update: 'Vink Bijwerken aan',
      update_firmware: 'Firmware bijwerken',
      upload_data: 'Gegevens uploaden',
      using_beam_studio_api: 'Beam Studio API gebruiken',
      view: 'Weergave',
      window: 'Venster',
      zoom_in: 'Inzoomen',
      zoom_out: 'Uitzoomen',
      zoom_with_window: 'Automatisch aanpassen aan venster',
    },
    preview: 'Voorbeeld',
    preview_press_esc_to_stop: 'Druk op ESC om de cameravoorvertoning te stoppen.',
    rename_tab: 'Tab hernoemen',
    select_machine: 'Selecteer een machine',
    tag_names: {
      dxf: 'DXF-object',
      ellipse: 'Ovaal',
      g: 'Groep',
      image: 'Afbeelding',
      line: 'Lijn',
      multi_select: 'Meerdere objecten',
      no_selection: 'Geen selectie',
      pass_through_object: 'Object Doorvoeren',
      path: 'Pad',
      polygon: 'Veelhoek',
      rect: 'Rechthoek',
      svg: 'SVG-object',
      text: 'Tekst',
      text_path: 'Tekst op pad',
      use: 'Geïmporteerd object',
    },
    task_preview: 'Padvoorbeeld',
    titles: {
      settings: 'Voorkeuren',
    },
    untitled: 'Naamloos',
  },
  topmenu: {
    credit: 'Beam Studio is mogelijk gemaakt door het <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> open source project en andere <a target="_blank" href="https://flux3dp.com/credits/">open source software</a>.',
    device: {
      download_log_canceled: 'Logboekdownload geannuleerd',
      download_log_error: 'Onbekende fout opgetreden, probeer het later opnieuw',
      log: {
        usblist: 'USB-lijst',
      },
      network_test: 'Netwerk testen',
    },
    file: {
      all_files: 'Alle bestanden',
      clear_recent: 'Recent geopende wissen',
      converting: 'Converteren naar afbeelding...',
      fcode_files: 'FLUX-code',
      import: 'Importeren',
      jpg_files: 'JPG',
      label: 'Bestand',
      path_not_exit: 'Dit pad lijkt niet meer te bestaan op de schijf.',
      png_files: 'PNG',
      save_fcode: 'Exporteer FLUX-taak',
      save_jpg: 'Exporteer JPG',
      save_png: 'Exporteer PNG',
      save_scene: 'Scene opslaan',
      save_svg: 'Exporteer SVG',
      scene_files: 'Beam Studio-scène',
      svg_files: 'SVG',
    },
    ok: 'OK',
    version: 'Versie',
  },
  tutorial: {
    ask_retry_calibration: 'Wilt u de camera opnieuw kalibreren?',
    camera_calibration_failed: 'Camerakalibratie mislukt',
    gesture: {
      click: 'Tik om het object te selecteren.',
      drag: 'Sleep om meerdere objecten te selecteren.',
      hold: 'Houd ingedrukt om het contextmenu te openen.',
      pan: 'Scroll het canvas met twee vingers.',
      zoom: 'Knijp met twee vingers in/uit om in/uit te zoomen op het canvas.',
    },
    links: {
      adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
    },
    look_for_machine: 'Machine zoeken voor tutorial...',
    needNewInterfaceTutorial: 'Wilt u een tutorial starten voor de nieuwe interface van Beam Studio?<br/>(U kunt het nu overslaan en later starten door te klikken op "Help" > "Toon Interface Introductie".)',
    needNewUserTutorial: 'Wilt u een tutorial starten?<br/>(U kunt het nu overslaan en later starten door te klikken op "Help" > "Toon Starttutorial".)',
    newInterface: {
      add_new_layer: 'Voeg nieuwe laag toe',
      align_controls: 'Lijn uitlijnen',
      basic_shapes: 'Basisvormen',
      camera_preview: 'Camera voorbeeld',
      drag_to_sort: 'Sleep om te sorteren',
      end_alert: 'Weet u zeker dat u de nieuwe UI-introductie wilt afsluiten?',
      flip: 'Spiegelen',
      group_controls: 'Groepeer',
      layer_controls: 'Rechtsklik voor laagopties:\nDupliceren / Samenvoegen / Vergrendelen / Lagen verwijderen',
      object_actions: 'Objectacties',
      pen_tool: 'Pen tool',
      rename_by_double_click: 'Hernoem door dubbel te klikken',
      select_image_text: 'Selecteer afbeelding / tekst',
      select_machine: 'Selecteer een machine',
      shape_operation: 'Vormbewerking',
      start_work: 'Begin met werken',
      switch_between_layer_panel_and_object_panel: 'Schakel tussen laagpaneel en objectpaneel',
    },
    newUser: {
      add_new_layer: 'Voeg een nieuwe laag toe',
      adjust_focus: '2. Stel de focus in',
      close_cover: '3. Sluit het deksel',
      drag_to_draw: 'Sleep om te tekenen',
      draw_a_circle: 'Teken een cirkel',
      draw_a_rect: 'Teken een rechthoek',
      end_alert: 'Weet u zeker dat u de zelfstudie wilt afsluiten?',
      end_preview_mode: 'Voorbeeldmodus beëindigen',
      infill: 'Infill inschakelen',
      please_select_wood_cutting: 'Selecteer a.u.b. de voorinstelling "Hout - Snijden".',
      please_select_wood_engraving: 'Selecteer a.u.b. de voorinstelling "Hout - Graveren".',
      preview_the_platform: 'Bekijk het platform',
      put_wood: '1. Plaats het houtmonster',
      send_the_file: 'Verstuur het bestand',
      set_preset_wood_cut: 'Stel voorinstelling in: Hout - Snijden',
      set_preset_wood_engraving: 'Stel voorinstelling in: Hout - Graveren',
      switch_to_layer_panel: 'Schakel over naar laagvenster',
      switch_to_object_panel: 'Schakel over naar Objectenpaneel',
      switch_to_preview_mode: 'Schakel over naar voorbeeldmodus',
    },
    next: 'VOLGENDE',
    retry: 'Opnieuw proberen',
    set_connection: 'Verbinding instellen',
    skip: 'Overslaan',
    skip_tutorial: 'U heeft de tutorial overgeslagen. U kunt altijd de tutorial starten door te klikken op "Help" > "Tutorial tonen"',
    suggest_calibrate_camera_first: 'We adviseren gebruikers om de camera aanvankelijk te kalibreren en opnieuw scherp te stellen voor elke voorbeeldweergave voor optimale resultaten.<br/>Wilt u bevestigen om nu de kalibratie uit te voeren?<br/>(U kunt het nu overslaan en later uitvoeren door te klikken op "Menu" > "Machine" > [Uw Machine] > "Camera Kalibreren".)',
    tutorial_complete: 'Dat is alles voor de zelfstudie. Nu is het tijd om te creëren!',
    unable_to_find_machine: 'Kan geen machine vinden voor tutorial. Wilt u naar de verbindingsinstellingenpagina gaan, de tutorial opnieuw proberen of overslaan?',
    welcome: 'WELKOM',
  },
  update: {
    cannot_reach_internet: '#823 Server is niet bereikbaar<br/>Controleer de internetverbinding',
    download: 'ONLINE UPDATE',
    firmware: {
      caption: 'Er is een firmware-update voor de machine beschikbaar',
      confirm: 'UPLOADEN',
      firmware_too_old_update_by_sdcard: 'De firmwareversie is te oud. Werk de firmware bij met behulp van een SD-kaart.',
      force_update_message: '#814 Werk uw machine bij naar de nieuwste firmwareversie.',
      latest_firmware: {
        cant_get_latest: 'Kan geen informatie krijgen over de nieuwste firmware.',
        caption: 'Werk machinefirmware bij',
        message: 'U heeft de nieuwste machinefirmware',
        still_update: 'BIJWERKEN',
      },
      message_pattern_1: '"%s" is nu klaar voor een firmware-update.',
      message_pattern_2: '%s firmware v%s is nu beschikbaar - U heeft v%s.',
      too_old_for_web: 'Uw huidige machinefirmwareversie is v%s.\nAls u de online versie van Beam Studio wilt gebruiken, werk dan de machinefirmware bij naar de nieuwste versie.',
      update_fail: '#822 Update mislukt',
      update_success: 'Firmware-update succesvol geüpload',
      upload_file: 'Firmware uploaden (*.bin / *.fxfw)',
    },
    install: 'INSTALLEREN',
    later: 'LATER',
    preparing: 'Voorbereiden...',
    release_note: 'Release opmerkingen:',
    skip: 'Deze versie overslaan',
    software: {
      available_switch: 'Beam Studio v%s is nu beschikbaar. U heeft versie %s. Wilt u upgraden naar deze versie?',
      available_update: 'Beam Studio v%s is nu beschikbaar. U heeft versie %s. Wilt u de update downloaden?',
      caption: 'Er is een software-update voor Beam Studio beschikbaar',
      check_update: 'Controleren op updates',
      checking: 'Controleren op updates',
      downloading: 'Updates worden op de achtergrond gedownload, u kunt op "OK" klikken om door te gaan met uw werk.',
      install_or_not: 'is klaar voor update. Wilt u nu opnieuw opstarten en updaten?',
      no: 'Nee',
      no_response: 'Kan geen verbinding maken met server, controleer uw netwerkinstellingen.',
      not_found: 'U gebruikt de nieuwste versie van Beam Studio.',
      switch_or_not: 'is klaar om te wisselen. Wilt u nu opnieuw opstarten en wisselen?',
      switch_version: 'Versie wisselen',
      switch_version_not_found: 'Te upgraden versie niet gevonden.',
      update_for_ador: 'De huidige softwareversie %s is niet compatibel, download de nieuwste versie van Beam Studio voor Ador.',
      yes: 'Ja',
    },
    update: 'Bijwerken',
    updating: 'Bezig met updaten...',
    upload: 'UPLOADEN',
  },
  web_cam: {
    no_device: 'Kan camera-apparaat niet detecteren. Sluit de camera opnieuw aan en probeer het opnieuw.',
    no_permission: 'Beam Studio heeft geen toestemming om toegang te krijgen tot de camera. Zorg ervoor dat de toestemming is verleend aan Beam Studio in de browser- of systeeminstellingen.',
  },
  z_speed_limit_test: {
    alert_before: 'Voordat de krommegravure begint, voert het systeem een belastingstest uit om verlies van stappen door overmatig gewicht te voorkomen.',
    alert_failed: 'Het gewicht van het huidige object overschrijdt de belastinglimiet voor de huidige Z-as snelheid. Verlaag de Z-as snelheid op het machinepaneel of vervang het graveerobject voordat u opnieuw test.',
    ignore: 'Negeren',
    retest: 'Opnieuw testen',
    testing: 'Bezig met het uitvoeren van de belastingtest...',
  },
};

export default lang;
