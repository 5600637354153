import type { ILang } from '@core/interfaces/ILang';

const lang: ILang = {
  alert: {
    abort: 'Batalkan',
    cancel: 'Batal',
    caption: 'Ralat',
    close: 'Tutup',
    confirm: 'Sahkan',
    dont_save: 'Jangan Simpan',
    dont_show_again: 'Jangan tunjukkan lagi',
    duplicated_preset_name: 'Nama praset sama',
    error: 'UH-OH',
    info: 'INFO',
    instruction: 'Arahan',
    learn_more: 'Ketahui Lebih Lanjut',
    no: 'Tidak',
    ok: 'OK',
    ok2: 'OK',
    oops: 'Oops...',
    retry: 'Cuba Lagi',
    save: 'Simpan',
    stop: 'Berhenti',
    warning: 'AMARAN',
    yes: 'Ya',
  },
  auto_fit: {
    artwork_size: 'Saiz Karya',
    error_tip1: '1. Adakah karya seni diletakkan dengan betul pada bahan?',
    error_tip2: '2. Adakah kontur bahan cukup jelas untuk pengiktirafan?',
    failed_to_find_contour: 'Geen contouren gevonden voor automatische aanpassing',
    learn_more: 'Lihat Tutorial.',
    learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    offset_x: 'Penyimpangan X',
    offset_y: 'Penyimpangan Y',
    pattern_size: 'Saiz Corak',
    position_artwork: 'Kedudukan Karya Seni',
    position_step1: 'Laraskan saiz dan kedudukan karya seni anda pada corak.',
    position_step2: 'Klik "Guna" untuk menggunakan Auto Fit pada corak ini.',
    preview_first: 'Sila lakukan pratonton kamera terlebih dahulu.',
    reset_position: 'Tetapkan Semula Kedudukan',
    rotation: 'Putar',
    select_a_pattern: 'Pilih Corak',
    selected_artwork: 'Karya Seni Terpilih',
    step1: 'Pilih corak di mana anda ingin meletakkan karya seni.',
    step2: 'Jika tiada corak dijumpai, pratonton kamera sekali lagi dan gunakan Muat Auto.',
    step3: 'Jika masih gagal menemui corak yang betul, pastikan bahan kerja anda dapat dikenali dengan jelas dan kawasan kerja bersih daripada serpihan.',
    title: 'Muat Auto',
  },
  barcode_generator: {
    bar_height: 'Tinggi Bar',
    bar_width: 'Lebar Bar',
    barcode: {
      invalid_value: 'Nilai tidak sah untuk format yang dipilih.',
    },
    font: 'Font',
    font_size: 'Saiz Font',
    hide_text: 'Sembunyikan Teks',
    invert_color: 'Balik Warna',
    text_margin: 'Margin Teks',
  },
  beambox: {
    announcement_panel: {
      title: 'Pengumuman',
    },
    banner: {
      auto_feeder: 'Mod Pemberi Automatik',
      camera_preview: 'Pratonton',
      camera_preview_borderless_mode: '(Bahagian Bawah Terbuka)',
      curve_engraving: 'Mod Ukiran Lengkung',
      pass_through: 'Mod Lintasan',
      rotary: 'Mod Putaran',
    },
    bottom_right_panel: {
      convert_text_to_path_before_export: 'Tukar Teks ke Laluan...',
      export_file_error_ask_for_upload: 'Gagal eksport tugas. Adakah anda bersedia untuk memberikan adegan kerja kepada pasukan pembangunan untuk laporan pepijat?',
      retreive_image_data: 'Dapatkan Data Imej...',
    },
    context_menu: {
      copy: 'Salin',
      cut: 'Potong',
      delete: 'Padam',
      duplicate: 'Duplikat',
      group: 'Kumpulan',
      move_back: 'Hantar ke Belakang',
      move_down: 'Hantar ke Belakang',
      move_front: 'Bawa ke Hadapan',
      move_up: 'Bawa ke Depan',
      paste: 'Tampal',
      paste_in_place: 'Tampal di Tempat',
      ungroup: 'Nyahkumpul',
    },
    document_panel: {
      add_on: 'Tambahan',
      auto_feeder: 'Pemberi automatik',
      auto_feeder_scale: 'Skala Pemakan automatik',
      auto_feeder_url: 'https://support.flux3dp.com/hc/en-us/articles/11688230498575',
      borderless_mode: 'Buka Bawah',
      current_position: 'Kedudukan Semasa',
      disable: 'Lumpuhkan',
      document_settings: 'Tetapan Dokumen',
      enable: 'Benarkan',
      enable_autofocus: 'Autofokus',
      enable_diode: 'Laser Diod',
      engrave_dpi: 'Resolusi',
      frame_before_start: 'Frame sebelum melaksanakan',
      frame_before_start_url: 'https://support.flux3dp.com/hc/en-us/articles/11494925637135',
      high: 'Tinggi',
      job_origin: 'Asal Tugas',
      laser_source: 'Sumber Laser',
      low: 'Rendah',
      machine: 'Mesin',
      medium: 'Sederhana',
      notification: {
        changeFromPrintingWorkareaTitle: 'Adakah anda mahu menukar Lapisan Cetakan kepada Lapisan Laser?',
      },
      origin: 'Asal',
      pass_through: 'Laluan terus',
      pass_through_height_desc: 'Masukkan panjang objek untuk melanjutkan kawasan kerja.',
      rotary_mode: 'Rotari',
      scale: 'Skala',
      start_from: 'Mula Dari',
      start_position: 'Kedudukan Mula',
      start_work_button: 'Butang Laksana',
      ultra: 'Ultra Tinggi',
      workarea: 'Kawasan Kerja',
    },
    image_trace_panel: {
      back: 'Kembali',
      brightness: 'Kecerahan',
      cancel: 'Batal',
      contrast: 'Kontras',
      next: 'Seterusnya',
      okay: 'OK',
      threshold: 'Ambang',
      tuning: 'Parameter',
    },
    layer_color_config_panel: {
      add: 'Tambah',
      add_config: 'Tambah Warna',
      color: 'Warna',
      default: 'Tetapan Semula ke Lalai',
      in_use: 'Warna ini sedang digunakan.',
      layer_color_config: 'Konfigurasi Warna Lapisan',
      no_input: 'Sila masukkan kod warna heks yang sah.',
      power: 'Kuasa',
      repeat: 'Ulang',
      speed: 'Kelajuan',
      sure_to_delete: 'Adakah anda pasti untuk memadam tetapan warna ini?',
      sure_to_reset: 'Anda akan kehilangan semua parameter tersuai, adakah anda pasti untuk menetapkan semula kepada lalai?',
    },
    left_panel: {
      borderless_blind_area: 'Kawasan Tidak Ukir',
      borderless_preview: 'Pratonton Mod Kamera Bawah Terbuka',
      diode_blind_area: 'Kawasan Buta Tambahan Laser Hibrid',
      ellipse: 'Elips',
      image: 'Imej',
      label: {
        adjust_height: 'Laras Ketinggian',
        array: 'Susunan',
        boxgen: 'Boxgen',
        choose_camera: 'Kamera',
        clear_preview: 'Kosongkan Pratonton',
        cursor: 'Pilih',
        curve_engraving: {
          clear_area: 'Kosongkan Kawasan Terpilih',
          exit: 'Keluar',
          preview_3d_curve: 'Pratonton Ukiran Lengkung',
          select_area: 'Pilih kawasan',
          title: 'Ukiran Lengkung',
        },
        end_preview: 'Tamat Pratonton',
        line: 'Garis',
        live_feed: 'Suapan Langsung',
        my_cloud: 'Awan Saya',
        oval: 'Bujur',
        pass_through: 'Laluan terus',
        pen: 'Pen',
        photo: 'Imej',
        polygon: 'Poligon',
        preview: 'Pratonton Kamera',
        qr_code: 'Kod QR',
        rect: 'Segi empat tepat',
        shapes: 'Elemen',
        text: 'Teks',
        trace: 'Imej Rentas',
      },
      line: 'Garis',
      rectangle: 'Segi empat tepat',
      text: 'Teks',
      unpreviewable_area: 'Kawasan Buta',
    },
    network_testing_panel: {
      average_response: 'Purata Masa Tindak Balas',
      cannot_connect_1: '#840 Gagal menyambung ke IP sasaran.',
      cannot_connect_2: '#840 Gagal menyambung ke IP sasaran. Sila pastikan sasaran berada dalam rangkaian yang sama.',
      cannot_get_local: 'Akses ke alamat IP tempatan gagal.',
      connection_quality: 'Kualiti Sambungan',
      device_not_on_list: '#842 Mesin tidak berada dalam senarai, tetapi Kualiti sambungan> 70 dan purata masa gerak balas <100ms',
      empty_ip: '#818 Sila masukkan alamat IP peranti sasaran terlebih dahulu.',
      end: 'Tamat',
      fail_to_start_network_test: '#817 Gagal memulakan ujian rangkaian.',
      hint_connect_camera_timeout: 'Timeout berlaku ketika memulakan pratonton kamera?',
      hint_connect_failed_when_sending_job: 'Gagal menyambung ketika menghantar tugasan?',
      hint_device_often_on_list: 'Mesin kerap tidak ditemui dalam senarai?',
      insert_ip: 'Alamat IP peranti sasaran:',
      invalid_ip: '#818 Alamat IP tidak sah',
      ip_startswith_169: '#843 Alamat IP mesin bermula dengan 169.254',
      link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
      link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
      link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
      linux_permission_hint: 'Ralat ini biasanya berlaku disebabkan kekurangan kebenaran.\nSila jalankan "sudo beam-studio --no-sandbox" dalam terminal untuk mendapatkan kebenaran dan melakukan ujian rangkaian.',
      local_ip: 'Alamat IP tempatan:',
      network_testing: 'Ujian Rangkaian',
      network_unhealthy: '#841 Kualiti sambungan <70 atau purata masa gerak balas > 100ms',
      start: 'Mula',
      test_completed: 'Ujian Selesai',
      test_fail: 'Ujian Gagal',
      testing: 'Menguji Rangkaian...',
    },
    object_panels: {
      lock_desc: 'Pelihara nisbah lebar dan tinggi (SHIFT)',
      text_to_path: {
        check_thumbnail_warning: 'Sesetengah teks telah ditukar kepada font lain semasa menukar teks kepada laluan dan sesetengah aksara mungkin tidak ditukar dengan betul.\nSila semak pratonton imej sekali lagi sebelum menghantar tugasan.',
        error_when_parsing_text: 'Ralat semasa menukar teks kepada laluan',
        font_substitute_pop: 'Teks anda mengandungi aksara yang tidak disokong oleh fon semasa. <br/>Adakah anda mahu menggunakan <strong>%s</strong> sebagai pengganti?',
        retry: 'Sila cuba semula kemudian atau pilih fon lain',
        use_current_font: 'Guna Font Semasa',
      },
      wait_for_parsing_font: 'Menghurai font... Sila tunggu sebentar',
    },
    path_preview: {
      current_position: 'Kedudukan Semasa',
      cut_distance: 'Jarak Pemotongan',
      cut_time: 'Masa Pemotongan',
      end_preview: 'Tamat Pratonton',
      estimated_time: 'Anggaran Masa Keseluruhan',
      invert: 'Songsang',
      pause: 'Jeda',
      play: 'Main',
      play_speed: 'Kelajuan Main Balik',
      preview_info: 'Maklumat Pratonton',
      rapid_distance: 'Jarak Perjalanan',
      rapid_time: 'Masa Perjalanan',
      remark: '* Semua maklumat adalah anggaran nilai untuk rujukan.',
      size: 'Saiz',
      start_here: 'Mula Di Sini',
      stop: 'Berhenti',
      travel_path: 'Laluan Perjalanan',
    },
    photo_edit_panel: {
      aspect_ratio: 'Nisbah Aspek',
      brightness: 'Kecerahan',
      brightness_and_contrast: 'Kecerahan / Kontras',
      cancel: 'Batal',
      circumference: 'Lilitan',
      compare: 'Banding',
      contrast: 'Kontras',
      crop: 'Pangkas',
      curve: 'Lengkung',
      diameter: 'Diameter',
      free: 'Bebas',
      invert: 'Songsang Warna',
      okay: 'Okay',
      original: 'Asal',
      phote_edit: 'Sunting Foto',
      processing: 'Pemprosesan',
      radius: 'Jejari',
      rotary_warped: 'Berputar Terherot',
      rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
      sharpen: 'Tajamkan',
      sharpness: 'Ketajaman',
      start: 'Mula',
      warp: 'Herot',
    },
    popup: {
      ai_credit: {
        buy_link: 'https://member.flux3dp.com/en-us/credit',
        go: 'Pergi',
        insufficient_credit: 'Anda kehabisan Kredit',
        insufficient_credit_msg: 'Anda tidak boleh guna %s. Pergi ke pusat ahli dan beli Kredit AI.',
        relogin_to_use: 'Sila log masuk semula untuk guna fungsi ini.',
      },
      auto_feeder_origin: 'Gunakan pratonton pembingkaian untuk menyemak laluan dan kawasan kepala laser, kemudian laraskan kedudukan bahan atau kedudukan mula ukiran berdasarkan hasil bagi mengelakkan perlanggaran semasa proses ukiran.',
      auto_switch_tab: {
        message: 'Pilihan baru untuk menukar secara automatik antara panel Lapisan dan panel Objek telah ditambah dalam Keutamaan. Pilihan ini dilumpuhkan secara lalai. Adakah anda ingin mengaktifkan penukaran automatik sekarang? <br/>Anda boleh menukar tetapan ini pada bila-bila masa dalam Keutamaan.',
        title: 'Tukar Automatik Panel Lapisan dan Objek',
      },
      backend_connect_failed_ask_to_upload: '#802 Ralat berterusan berlaku semasa cuba menyambung ke backend. Adakah anda mahu memuat naik laporan pepijat log anda?',
      backend_error_hint: 'Ciri-ciri mungkin tidak berfungsi dengan betul disebabkan ralat backend.',
      both_power_and_speed_too_high: 'Menggunakan kuasa laser yang lebih rendah akan melanjutkan jangka hayat tiub laser. Juga, kelajuan yang terlalu tinggi pada resolusi ini boleh mengakibatkan kualiti ukiran bayangan yang lebih rendah.',
      bug_report: 'Laporan Pepijat',
      change_workarea_before_preview: 'Kawasan kerja %s tidak sepadan dengan kawasan kerja yang ditetapkan sekarang. Adakah anda mahu menukar kawasan kerja semasa?',
      convert_to_path_fail: 'Gagal menukar ke laluan.',
      dxf_bounding_box_size_over: 'Saiz lukisan melebihi kawasan kerja. Sila pindahkan lukisan anda lebih dekat kepada asal dalam perisian CAD anda, atau pastikan unit ditetapkan dengan betul.',
      dxf_version_waring: 'Versi fail DXF ini bukan 2013, mungkin terdapat masalah ketidakserasian.',
      facebook_group_invitation: {
        already_joined: 'Sudah Sertai',
        join_now: 'Sertai Sekarang',
        later: 'Mungkin Nanti',
        message: 'Sertai kumpulan Facebook rasmi kami untuk berhubung dengan pengguna laser FLUX yang lain, berkongsi karya laser, dan sentiasa dikemas kini dengan maklumat terkini mengenai produk kami. Kami tidak sabar untuk berjumpa dengan anda di sana!',
        title: 'Sertai Kumpulan Pengguna Rasmi FLUX',
      },
      import_file_contain_invalid_path: '#808 Fail SVG yang diimport mengandungi laluan imej yang tidak sah. Sila pastikan semua fail imej wujud atau benam imej dalam fail',
      import_file_error_ask_for_upload: 'Gagal untuk Imported SVG file. Adakah anda bersedia untuk menyediakan fail kepada pasukan pembangunan untuk laporan pepijat ?',
      layer_by_color: 'Warna',
      layer_by_layer: 'Lapisan',
      loading_image: 'Memuatkan imej, sila tunggu...',
      more_than_two_object: 'Terlalu banyak objek. Hanya menyokong 2 objek',
      mouse: 'Tetikus',
      no_support_text: 'Beam Studio tidak menyokong tag teks pada masa ini. Sila tukar teks kepada laluan sebelum import.',
      nolayer: 'Lapisan Tunggal',
      not_support_object_type: 'Tidak menyokong jenis objek',
      or_turn_off_borderless_mode: ' Atau matikan mod Buka Bawah.',
      pdf2svg: {
        error_pdf2svg_not_found: '#825 Ralat: Arahan pdf2svg tidak dijumpai. Sila pasang pdf2svg dengan pengurus pakej anda (cth., "yum install pdf2svg" atau "apt-get install pdf2svg").',
        error_when_converting_pdf: '#824 Ralat semasa menukar PDF ke SVG:',
      },
      progress: {
        calculating: 'Mengira',
        uploading: 'Memuat naik',
      },
      questionnaire: {
        caption: 'Bantu Kami Isi Soal Selidik',
        message: 'Bantu kami mengisi soal selidik untuk menjadikan Beam Studio lebih baik.',
        no_questionnaire_available: 'Tiada soal selidik yang tersedia buat masa ini.',
        unable_to_get_url: 'Gagal mendapatkan pautan ke soal selidik melalui internet. Sila sahkan sambungan internet anda.',
      },
      recommend_downgrade_software: 'Kami mengesan versi perisian tegar yang lebih lama. Kami sedang menangani isu-isu keserasian, tetapi buat masa ini, kami mengesyorkan kembali ke <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>.',
      recommend_upgrade_firmware: 'Kami mengesan versi firmware yang lebih lama. Kami sedang menangani isu-isu keserasian, tetapi buat masa ini, kami mengesyorkan mengemas kini ke firmware terkini.',
      save_unsave_changed: 'Adakah anda mahu menyimpan perubahan yang belum disimpan?',
      select_at_least_two: 'Pilih dua objek untuk teruskan',
      select_first: 'Pilih objek pertama.',
      select_import_method: 'Pilih gaya pelapisan:',
      select_import_module: 'Pilih Modul:',
      sentry: {
        message: 'Adakah anda bersetuju untuk memuat naik maklumat yang berkaitan secara automatik kepada pasukan pembangunan apabila ralat berlaku?',
        title: 'Mari kita baikkan Beam Studio bersama-sama',
      },
      should_update_firmware_to_continue: '#814 Perisian tegar anda tidak menyokong versi Beam Studio ini. Sila kemas kini perisian tegar untuk meneruskan. (Menu > Mesin > [Mesin Anda] > Kemas kini Perisian Tegar)',
      speed_too_high_lower_the_quality: 'Menggunakan kelajuan terlalu tinggi pada resolusi ini boleh mengakibatkan kualiti ukiran bayangan yang lebih rendah.',
      still_continue: 'Teruskan',
      successfully_uploaded: 'Muat naik fail berjaya.',
      svg_1_1_waring: 'Versi fail SVG ini ialah v 1.1, mungkin terdapat masalah ketidakserasian.',
      svg_image_path_waring: 'Fail SVG ini mengandungi <image> memuat dari laluan fail. Ini mungkin menyebabkan kegagalan semasa memuat.\nUntuk mengelakkan risiko ini, Sila baik guna imej terbenam semasa mengeksport SVG.',
      text_to_path: {
        caption: 'Penukar Teks ke Laluan 2.0',
        message: "Beam Studio kini memperkenalkan penukar teks ke laluan yang baru (Penukar 2.0), yang menghasilkan keputusan yang lebih boleh dipercayai! Adakah anda mahu beralih kepadanya sekarang? \nAnda juga boleh menyesuaikan tetapan ini kemudian dalam bahagian 'Penukar Teks ke Laluan' dalam keutamaan.",
      },
      too_fast_for_auto_feeder: 'Menggunakan kelajuan terlalu tinggi pada lapisan yang mengandungi objek laluan boleh mengurangkan ketepatan pemotongan.\nKami tidak mengesyorkan menggunakan kelajuan lebih daripada %(limit)s semasa memotong dengan Auto Feeder.',
      too_fast_for_auto_feeder_and_constrain: 'Lapisan berikut: %(layers)s \nmengandungi objek laluan vektor dan mempunyai kelajuan melebihi %(limit)s.\nKelajuan pemotongan untuk objek laluan vektor dengan Auto Feeder akan dihadkan kepada %(limit)s.\nAnda boleh mengalih keluar had ini di Tetapan Keutamaan.',
      too_fast_for_curve: 'Menggunakan kelajuan terlalu tinggi untuk lapisan yang mengandungi objek ukiran lengkung boleh mengurangkan ketepatan ukiran.\nKami mengesyorkan mengekalkan kelajuan di bawah %(limit)s untuk hasil yang optimum.',
      too_fast_for_curve_and_constrain: 'Lapisan berikut: %(layers)s melebihi had kelajuan %(limit)s.\nKelajuan ukiran untuk objek lengkung akan dihadkan kepada %(limit)s. Anda boleh mengalih keluar had ini dalam Tetapan Keutamaan.',
      too_fast_for_path: 'Menggunakan kelajuan yang terlalu tinggi dalam lapisan yang mengandungi objek laluan boleh mengakibatkan ketepatan yang lebih rendah semasa memotong. Kami tidak mengesyorkan menggunakan kelajuan lebih pantas daripada %(limit)s semasa memotong.',
      too_fast_for_path_and_constrain: 'Lapisan berikut: %(layers)s\n mengandungi objek laluan vektor, dan mempunyai kelajuan melebihi %(limit)s. Kelajuan pemotongan objek laluan vektor akan dihadkan kepada %(limit)s. Anda boleh mengalih keluar had ini di Tetapan Keutamaan.',
      touchpad: 'Pad Sentuh',
      ungroup_use: 'Ini akan menyahkumpulkan DXF atau SVG yang diimport. Oleh kerana fail itu mungkin mengandungi sejumlah besar elemen, ia mungkin mengambil masa untuk menyahkumpulkan. Adakah anda pasti untuk meneruskan?',
      upload_failed: '#819 Gagal memuat naik fail.',
      upload_file_too_large: '#819 Fail terlalu besar untuk dimuat naik.',
      vectorize_shading_image: 'Imej gradien mengambil lebih masa untuk mengesan dan cenderung kepada hingar. Sila matikan gradien imej sebelum melaksanakan.',
    },
    rating_panel: {
      description: 'Jika anda suka Beam Studio, kami amat menghargai jika anda boleh menilai kami.',
      thank_you: 'Terima kasih!',
      title: 'Suka Beam Studio?',
    },
    right_panel: {
      laser_panel: {
        advanced: 'Lanjutan',
        backlash: 'Ricih',
        bi_directional: 'Dua Arah',
        by: 'Dengan',
        ce_z_high_speed: 'Paksi Z berkelajuan tinggi',
        ce_z_high_speed_link: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
        color_adjustment: 'Pelarasan Saluran',
        color_adjustment_short: 'Saluran',
        color_strength: 'Kekuatan',
        cross_hatch: 'Loret Bersilang',
        custom_preset: 'Suai',
        cut: 'Potong',
        diode: 'Laser Diod',
        do_not_adjust_default_para: 'Praset tidak boleh diubah.',
        dottingTime: 'Masa Pemarkahan',
        dropdown: {
          inches: {
            abs_engraving: 'ABS - Ukir',
            acrylic_3mm_cutting: 'Akrilik - 1/8″ Potong',
            acrylic_5mm_cutting: 'Akrilik - 3/16″ Potong',
            acrylic_8mm_cutting: 'Akrilik - 1/4″ Potong',
            acrylic_10mm_cutting: 'Akrilik - 3/8″ Potong',
            acrylic_engraving: 'Akrilik - Ukir',
            acrylic_printing: 'Akrilik - Cetak',
            aluminum_engraving: 'Aluminium - Ukir',
            aluminum_light: 'Aluminium (terang)',
            bamboo_printing: 'Buluh - Cetak',
            black_abs: 'ABS hitam',
            black_acrylic_3mm_cutting: 'Akrilik Hitam - 1/8″ Potong',
            black_acrylic_5mm_cutting: 'Akrilik Hitam - 3/16″ Potong',
            black_acrylic_engraving: 'Akrilik Hitam - Ukir',
            brass_dark: 'Kuningan (gelap)',
            brass_engraving: 'Gangsa - Ukir',
            brass_light: 'Kuningan (terang)',
            canvas_printing: 'Kanvas - Cetak',
            cardstock_printing: 'Kad Stok - Cetak',
            copper: 'Tembaga',
            cork_printing: 'Gabus - Cetak',
            denim_1mm_cutting: 'Denim - 1/32″ Potong',
            fabric_3mm_cutting: 'Kain - 1/8″ Potong',
            fabric_5mm_cutting: 'Kain - 3/16″ Potong',
            fabric_engraving: 'Kain - Ukir',
            fabric_printing: 'Fabrik - Cetak',
            flat_stone_printing: 'Batu Rata - Cetak',
            glass_bw_engraving: 'Kaca - Ukir',
            glass_printing: 'Kaca - Cetak',
            gloss_leather_printing: 'Kulit Berkilat - Cetak',
            gold_engraving: 'Emas - Ukir',
            iron_engraving: 'Besi - Ukir',
            leather_3mm_cutting: 'Kulit - 1/8″ Potong',
            leather_5mm_cutting: 'Kulit - 3/16″ Potong',
            leather_engraving: 'Kulit - Ukir',
            mdf_3mm_cutting: 'MDF New Zealand - 1/8″ Potong',
            mdf_5mm_cutting: 'MDF New Zealand - 3/16″ Potong',
            mdf_engraving: 'MDF New Zealand - Ukir',
            metal_bw_engraving: 'Logam - Ukir',
            opaque_acrylic: 'Akrilik legap',
            pc_printing: 'PC - Cetak',
            rubber_bw_engraving: 'Getah - Ukir',
            silver_engraving: 'Perak - Ukir',
            stainless_steel_bw_engraving_diode: 'Logam - Ukir (Laser Diod)',
            stainless_steel_dark: 'Keluli tahan karat (gelap)',
            stainless_steel_engraving: 'Keluli Tahan Karat - Ukir',
            stainless_steel_light: 'Keluli tahan karat (terang)',
            stainless_steel_printing: 'Keluli Tahan Karat - Cetak',
            steel_engraving_spray_engraving: 'Logam - Ukir',
            stone: 'Batu',
            ti_engraving: 'Titanium - Ukir',
            titanium_dark: 'Titanium (gelap)',
            titanium_light: 'Titanium (terang)',
            white_abs: 'ABS putih',
            wood_3mm_cutting: 'Kayu - 1/8″ Potong',
            wood_5mm_cutting: 'Kayu - 3/16″ Potong',
            wood_7mm_cutting: 'Kayu - 1/4″ Potong',
            wood_8mm_cutting: 'Kayu - 1/4″ Potong',
            wood_10mm_cutting: 'Kayu - 3/8″ Potong',
            wood_engraving: 'Kayu - Ukir',
            wood_printing: 'Kayu - Cetak',
          },
          mm: {
            abs_engraving: 'ABS - Ukir',
            acrylic_3mm_cutting: 'Akrilik - 3mm Potong',
            acrylic_5mm_cutting: 'Akrilik - 5mm Potong',
            acrylic_8mm_cutting: 'Akrilik - 8mm Potong',
            acrylic_10mm_cutting: 'Akrilik - 10mm Potong',
            acrylic_engraving: 'Akrilik - Ukir',
            acrylic_printing: 'Akrilik - Cetak',
            aluminum_engraving: 'Aluminium - Ukir',
            aluminum_light: 'Aluminium (terang)',
            bamboo_printing: 'Buluh - Cetak',
            black_abs: 'ABS hitam',
            black_acrylic_3mm_cutting: 'Akrilik Hitam - 3mm Potong',
            black_acrylic_5mm_cutting: 'Akrilik Hitam - 5mm Potong',
            black_acrylic_engraving: 'Akrilik Hitam - Ukir',
            brass_dark: 'Kuningan (gelap)',
            brass_engraving: 'Gangsa - Ukir',
            brass_light: 'Kuningan (terang)',
            canvas_printing: 'Kanvas - Cetak',
            cardstock_printing: 'Kad Tebal - Cetak',
            copper: 'Tembaga',
            cork_printing: 'Gabus - Cetak',
            denim_1mm_cutting: 'Denim - 1mm Potong',
            fabric_3mm_cutting: 'Fabrik - 3mm Potong',
            fabric_5mm_cutting: 'Fabrik - 5mm Potong',
            fabric_engraving: 'Kain - Ukir',
            fabric_printing: 'Fabrik - Cetak',
            flat_stone_printing: 'Batu Rata - Cetak',
            glass_bw_engraving: 'Kaca - Ukir',
            glass_printing: 'Kaca - Cetak',
            gloss_leather_printing: 'Kulit Berkilat - Cetak',
            gold_engraving: 'Emas - Ukir',
            iron_engraving: 'Besi - Ukir',
            leather_3mm_cutting: 'Kulit - 3mm Potong',
            leather_5mm_cutting: 'Kulit - 5mm Potong',
            leather_engraving: 'Kulit - Ukir',
            mdf_3mm_cutting: 'MDF New Zealand - 3mm Potong',
            mdf_5mm_cutting: 'MDF New Zealand - 5mm Potong',
            mdf_engraving: 'MDF New Zealand - Ukir',
            metal_bw_engraving: 'Logam - Ukir',
            opaque_acrylic: 'Akrilik legap',
            pc_printing: 'PC - Cetak',
            rubber_bw_engraving: 'Getah - Ukir',
            silver_engraving: 'Perak - Ukir',
            stainless_steel_bw_engraving_diode: 'Logam - Ukir (Laser Diod)',
            stainless_steel_dark: 'Keluli tahan karat (gelap)',
            stainless_steel_engraving: 'Keluli Tahan Karat - Ukir',
            stainless_steel_light: 'Keluli tahan karat (terang)',
            stainless_steel_printing: 'Keluli Tahan Karat - Cetak',
            steel_engraving_spray_engraving: 'Logam - Ukir',
            stone: 'Batu',
            ti_engraving: 'Titanium - Ukir',
            titanium_dark: 'Titanium (gelap)',
            titanium_light: 'Titanium (terang)',
            white_abs: 'ABS putih',
            wood_3mm_cutting: 'Kayu - 3mm Potong',
            wood_5mm_cutting: 'Kayu - 5mm Potong',
            wood_7mm_cutting: 'Kayu - 7mm Potong',
            wood_8mm_cutting: 'Kayu - 8mm Potong',
            wood_10mm_cutting: 'Kayu - 10mm Potong',
            wood_engraving: 'Kayu - Ukir',
            wood_printing: 'Kayu - Cetak',
          },
          parameters: 'Praset',
          save: 'Tambah parameter semasa',
        },
        engrave: 'Ukir',
        existing_name: 'Nama ini telah digunakan.',
        fill_angle: 'Sudut Isian',
        fill_interval: 'Jarak Isian',
        fill_setting: 'Tetapan Isian',
        filled_path_only: 'Hanya untuk laluan isian',
        focus_adjustment: 'Pelarasan Fokus',
        frequency: 'Frekuensi',
        gradient_only: 'Hanya untuk imej kecerunan',
        halftone: 'Setengah nada',
        halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
        height: 'Ketinggian Objek',
        ink_saturation: 'Ketepuan',
        ink_type: {
          normal: 'Dakwat',
          text: 'Jenis Dakwat',
          UV: 'Dakwat UV',
        },
        laser_speed: {
          fast: 'Pantas',
          max: 300,
          min: 3,
          slow: 'Perlahan',
          step: 0.1,
          text: 'Kelajuan',
          unit: 'mm/s',
        },
        low_power_warning: 'Kuasa laser yang rendah (bawah 10%) mungkin tidak memancarkan cahaya laser.',
        low_speed_warning: 'Kelajuan rendah mungkin menyebabkan bahan terbakar.',
        lower_focus: 'Turunkan Fokus',
        lower_focus_desc: 'Menurunkan ketinggian fokus dengan jarak tertentu selepas memfokus untuk meningkatkan prestasi pemotongan.',
        module: 'Modul',
        more: 'Urus',
        multi_layer: 'Lapisan Pelbagai',
        para_in_use: 'Parameter ini sedang digunakan.',
        parameters: 'Parameter',
        power: {
          high: 'Tinggi',
          low: 'Rendah',
          max: 100,
          min: 1,
          step: 0.1,
          text: 'Kuasa',
        },
        preset_management: {
          add_new: 'Tambah Baru',
          delete: 'Padam',
          export: 'Eksport',
          export_preset_title: 'Eksport Praset',
          import: 'Import',
          laser: 'Laser',
          lower_focus_by: 'Turunkan Fokus oleh',
          new_preset_name: 'Nama Praset Baharu',
          preset: 'Pratetap',
          print: 'Cetak',
          reset: 'Tetap Semula',
          save_and_exit: 'Simpan dan Keluar',
          show_all: 'Tunjukkan Semua',
          sure_to_import_presets: 'Ini akan memuatkan susunan pratetap dan menimpa parameter yang telah disesuaikan, adakah anda pasti untuk meneruskan?',
          sure_to_reset: 'Ini akan memadamkan parameter tersuai anda dan menetapkan semula semua pratetap, adakah anda pasti untuk meneruskan?',
          title: 'Urus Parameter',
          wobble_diameter: 'Diameter gegaran',
          wobble_step: 'Saiz langkah gegaran',
        },
        preset_setting: 'Tetapan Parameter (%s)',
        presets: 'Praset',
        print_multipass: 'Multi-laluan',
        promark_speed_desc: 'Parameter kelajuan tidak terpakai pada imej kecerunan.',
        pulse_width: 'Lebar Denyut',
        pwm_advanced_desc: 'Tetapkan kuasa minimum untuk mod kedalaman.',
        pwm_advanced_hint: 'Tetapan ini berlaku khususnya untuk imej kecerunan apabila menggunakan mod kedalaman.',
        pwm_advanced_setting: 'Tetapan Kuasa Mod Kedalaman',
        repeat: 'Kiraan Lalu',
        single_color: 'Warna Tunggal',
        single_color_desc: 'Hanya boleh digunakan untuk lapisan warna penuh dan tidak boleh digunakan dengan lapisan warna tunggal yang diperluas.',
        slider: {
          fast: 'Pantas',
          high: 'Tinggi',
          low: 'Rendah',
          regular: 'Biasa',
          slow: 'Perlahan',
          very_fast: 'Paling Pantas',
          very_high: 'Maksimum',
          very_low: 'Minimum',
          very_slow: 'Paling Perlahan',
        },
        speed: 'Kelajuan',
        speed_constrain_warning: 'Kelajuan pemotongan objek laluan vektor akan dihadkan kepada %(limit)s. Anda boleh mengalih had ini di Tetapan Keutamaan.',
        speed_constrain_warning_auto_feeder: 'Kelajuan pemotongan objek Pengumpan Automatik akan dihadkan kepada %(limit)s. Anda boleh membuang had ini dalam Tetapan Keutamaan.',
        speed_constrain_warning_curve_engraving: 'Kelajuan ukiran lengkung akan dihadkan kepada %(limit)s. Anda boleh menghapuskan had ini dalam Tetapan Keutamaan.',
        stepwise_focusing: 'Pemfokusan Berperingkat',
        stepwise_focusing_desc: 'Menurunkan jarak fokus secara beransur-ansur berdasarkan ketinggian objek semasa setiap kiraan lalu.',
        strength: 'Kuasa',
        times: 'kali',
        various_preset: 'Pelbagai Praset',
        white_ink: 'Dakwat Putih',
        white_ink_settings: 'Tetapan Dakwat Putih',
        wobble: 'Goyangan',
        wobble_desc: 'Cipta bulatan kecil di sepanjang laluan, yang bermanfaat untuk tugas memotong. (Hanya untuk objek laluan.)',
        wobble_diameter: 'Diameter',
        wobble_step: 'Saiz langkah',
        z_step: 'Langkah Z',
      },
      layer_panel: {
        current_layer: 'Lapisan Semasa',
        layer1: 'Lapisan 1',
        layer_bitmap: 'Bitmap',
        layer_cutting: 'Pemotongan',
        layer_engraving: 'Ukiran',
        layers: {
          del: 'Padam Lapisan',
          dupe: 'Gandakan Lapisan',
          fullColor: 'Warna penuh',
          layer: 'Lapisan',
          layers: 'Lapisan',
          lock: 'Kunci Lapisan',
          merge_all: 'Gabung Semua',
          merge_down: 'Gabung Bawah',
          merge_selected: 'Gabung Lapisan Terpilih',
          move_down: 'Turun Lapisan',
          move_elems_to: 'Alih elemen ke:',
          move_selected: 'Alih elemen terpilih ke lapisan lain',
          move_up: 'Naik Lapisan',
          new: 'Lapisan Baru',
          rename: 'Nama Semula Lapisan',
          splitFullColor: 'Kembangkan Lapisan Warna',
          switchToFullColor: 'Tukar ke lapisan warna penuh',
          switchToSingleColor: 'Tukar ke lapisan warna tunggal',
          unlock: 'Buka Kunci',
        },
        move_elems_to: 'Alihkan elemen ke:',
        notification: {
          dupeLayerName: 'Sudah ada lapisan dengan nama itu!',
          enterNewLayerName: 'Sila masukkan nama lapisan baru',
          enterUniqueLayerName: 'Sila masukkan nama lapisan unik',
          layerHasThatName: 'Lapisan sudah ada nama itu',
          mergeLaserLayerToPrintingLayerMsg: 'Sila ambil perhatian bahawa jika anda lengkapkan operasi ini, tetapan lapisan laser akan dibuang dan ditetapkan mengikut lapisan semasa.',
          mergeLaserLayerToPrintingLayerTitle: 'Adakah anda mahu menggabungkan lapisan ini ke satu Lapisan Percetakan?',
          mergePrintingLayerToLaserLayerMsg: 'Sila ambil perhatian bahawa jika anda lengkapkan operasi ini, tetapan warna lapisan percetakan akan dibuang dan ditetapkan mengikut lapisan semasa.',
          mergePrintingLayerToLaserLayerTitle: 'Adakah anda mahu menggabungkan lapisan ini ke satu Lapisan Laser?',
          moveElemFromPrintingLayerMsg: 'Sila ambil perhatian bahawa jika anda lengkapkan operasi ini, tetapan warna elemen terpilih akan dibuang dan ditetapkan mengikut %s.',
          moveElemFromPrintingLayerTitle: 'Alih elemen terpilih ke %s dan tukar ke elemen laser?',
          moveElemToPrintingLayerMsg: 'Sila ambil perhatian bahawa jika anda lengkapkan operasi ini, tetapan elemen terpilih akan dibuang dan ditetapkan mengikut %s.',
          moveElemToPrintingLayerTitle: 'Alih elemen terpilih ke %s dan tukar ia ke elemen percetakan?',
          newName: 'NAMA BARU',
          QmoveElemsToLayer: "Alihkan elemen terpilih ke lapisan '%s'?",
          splitColorMsg: 'Sila ambil perhatian bahawa jika anda teruskan dengan operasi ini, anda tidak akan dapat kembali ke lapisan warna asal.',
          splitColorTitle: 'Adakah anda mahu mengembangkan lapisan terpilih ke lapisan CMYK?',
        },
      },
      object_panel: {
        actions_panel: {
          ai_bg_removal: 'Buang Latar Belakang',
          ai_bg_removal_reminder: 'Menekan butang akan menggunakan 0.2 Kredit dengan serta-merta, anda mahu meneruskan?',
          ai_bg_removal_short: 'Buang LB',
          array: 'Susunan',
          auto_fit: 'Muat Auto',
          bevel: 'Bevel',
          brightness: 'Kecerahan',
          convert_to_path: 'Tukar ke Laluan',
          create_textpath: 'Cipta Teks pada Laluan',
          create_textpath_short: 'Teks Laluan',
          crop: 'Potong',
          decompose_path: 'Hurai',
          detach_path: 'Lepaskan Teks dari Laluan',
          detach_path_short: 'Lepaskan',
          disassemble_use: 'Hurai',
          disassembling: 'Menghurai...',
          edit_path: 'Edit Laluan',
          fetching_web_font: 'Mendapatkan fon dalam talian...',
          grading: 'Pemeringkatan',
          invert: 'Songsang',
          offset: 'Ofset',
          outline: 'Garis luar',
          replace_with: 'Ganti Dengan...',
          replace_with_short: 'Ganti',
          sharpen: 'Tajamkan',
          simplify: 'Ringkas',
          smart_nest: 'Sarang Pintar',
          trace: 'Jejak',
          ungrouping: 'Menyahkumpul...',
          uploading_font_to_machine: 'Memuat naik fon ke mesin...',
          wait_for_parsing_font: 'Mohon tunggu sebentar',
          weld_text: 'Kimpal Teks',
        },
        align: 'Jajarkan',
        boolean: 'Boolean',
        bottom_align: 'Jajar Bawah',
        center_align: 'Jajar Tengah',
        difference: 'Beza',
        distribute: 'Agihkan',
        flip: 'Terbalik',
        group: 'Kumpulkan',
        hdist: 'Agihkan Mendatar',
        hflip: 'Terbalik Mendatar',
        intersect: 'Iris',
        left_align: 'Jajar Kiri',
        lock_aspect: 'Kunci Nisbah',
        middle_align: 'Jajar Tengah',
        option_panel: {
          color: 'Warna',
          fill: 'Isi',
          font_family: 'Font',
          font_size: 'Saiz',
          font_style: 'Gaya',
          letter_spacing: 'Jarak huruf',
          line_spacing: 'Jarak baris',
          path_infill: 'Isi laluan',
          pwm_engraving: 'Mod Kedalaman',
          pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
          rounded_corner: 'Sudut Bulat',
          shading: 'Cerun',
          sides: 'Sisi',
          start_offset: 'Ofset teks',
          stroke: 'Lukisan',
          stroke_color: 'Warna Lukisan',
          stroke_width: 'Lebar Garisan',
          text_infill: 'Isi teks',
          threshold: 'Ambang kecerahan',
          threshold_short: 'Ambang',
          vertical_align: 'Penjajaran',
          vertical_text: 'Teks menegak',
        },
        path_edit_panel: {
          connect: 'Sambung',
          delete: 'Padam',
          disconnect: 'Putuskan',
          node_type: 'JENIS NOD',
          round: 'Bulat',
          sharp: 'Tajam',
        },
        right_align: 'Jajar Kanan',
        subtract: 'Tolak',
        top_align: 'Jajar Atas',
        ungroup: 'Nyahgabung',
        union: 'Gabung',
        unlock_aspect: 'Buka Kunci Nisbah',
        vdist: 'Agihkan Menegak',
        vflip: 'Terbalik Menegak',
        zoom: 'Zum',
      },
      tabs: {
        layers: 'Lapisan',
        objects: 'Objek',
        path_edit: 'Sunting Laluan',
      },
    },
    shapes_panel: {
      animals: 'Haiwan',
      arrow: 'Anak Panah',
      basic: 'Asas',
      birds: 'Burung',
      celebration: 'Perayaan',
      circular: 'Bulat',
      CNY: 'Tahun Baru Cina',
      corner: 'Sudut',
      decor: 'Hiasan',
      easter: 'Easter',
      elements: 'Unsur',
      environment: 'Persekitaran',
      graphics: 'Grafik',
      halloween: 'Halloween',
      holidays: 'Cuti',
      label: 'Label',
      land: 'Darat',
      line: 'Garisan',
      nature: 'Alam Semulajadi',
      photo: 'Foto',
      plants: 'Tumbuhan',
      ribbon: 'Pita',
      sea: 'Laut',
      shape: 'Bentuk',
      speech: 'Ucapan',
      text: 'Teks',
      title: 'Elemen',
      valentines: 'Hari Valentine',
      weather: 'Cuaca',
      Xmas: 'Krismas',
    },
    svg_editor: {
      unable_to_fetch_clipboard_img: 'Gagal mengambil imej dari papan keratan anda',
      unnsupported_file_type: 'Jenis fail ini tidak disokong secara langsung. Sila tukar fail ke SVG atau bitmap',
    },
    tag: {
      g: 'Kumpulan',
      image: 'Imej',
      text: 'Teks',
      use: 'Import SVG',
    },
    time_est_button: {
      calculate: 'Anggarkan masa',
      estimate_time: 'Anggaran Masa:',
    },
    tool_panels: {
      _nest: {
        end: 'Tutup',
        no_element: 'Tiada elemen untuk disusun.',
        rotations: 'Kemungkinan Putaran',
        spacing: 'Jarak',
        start_nest: 'Susun',
        stop_nest: 'Berhenti',
      },
      _offset: {
        corner_type: 'Sudut',
        direction: 'Arah Ofset',
        dist: 'Jarak Ofset',
        fail_message: 'Gagal untuk Mengoset Objek.',
        inward: 'Ke Dalam',
        not_support_message: 'Elemen terpilih mengandungi tag SVG yang tidak disokong:\nImej, Kumpulan, Teks dan Objek Diimport.',
        outward: 'Ke Luar',
        round: 'Bulat',
        sharp: 'Tajam',
      },
      array_dimension: 'Dimensi Susunan',
      array_interval: 'Sela Susunan',
      cancel: 'Batal',
      columns: 'Lajur',
      confirm: 'Sahkan',
      dx: 'X',
      dy: 'Y',
      grid_array: 'Cipta Susunan Grid',
      nest: 'Pengoptimuman Susun Atur',
      offset: 'Ofset',
      rows: 'Baris',
    },
    units: {
      mm: 'mm',
      walt: 'W',
    },
    zoom_block: {
      fit_to_window: 'Muat ke Tetingkap',
    },
  },
  boxgen: {
    add_option: 'Tambah Pilihan',
    basic_box: 'Kotak Asas',
    beam_radius: 'Pampasan kerf',
    beam_radius_warning: 'Alih keluar pampasan kerf apabila tepi atau sambungan kotak pendek untuk memastikan pemasangan kotak',
    cancel: 'Batal',
    coming_soon: 'Akan Datang',
    continue_import: 'Teruskan untuk Import',
    control_tooltip: 'Kiri tetikus untuk putar\nGulung untuk zum\nTetikus kanan untuk anjak',
    control_tooltip_touch: 'Seret untuk putar\nCubit untuk zum\nDua jari untuk anjak',
    cover: 'Penutup',
    customize: 'Ubah suai',
    depth: 'Kedalaman',
    edge: 'Tepi',
    finger: 'Jari',
    finger_warning: 'Panjang sisi dalam kotak perlu sekurang-kurangnya 6mm (0.24 inci) untuk serasi dengan sambungan Jari.',
    height: 'Tinggi',
    import: 'Import',
    inner: 'Dalam',
    joints: 'Sambungan',
    max_dimension_tooltip: 'Had maksimum lebar/tinggi/kedalaman ialah %s.',
    merge: 'Gabung',
    outer: 'Luar',
    reset: 'Tetapkan Semula',
    tCount: 'Kiraan T',
    tCount_tooltip: 'Bilangan slot T terpakai pada sisi pendek; kuantiti pada sisi panjang dikira berdasarkan panjangnya.',
    tDiameter: 'Diameter T',
    text_label: 'Label Teks',
    thickness: 'Ketebalan',
    title: 'BOXGEN',
    tLength: 'Panjang T',
    tSlot: 'T-Slot',
    tSlot_warning: 'Panjang sisi kotak perlu sekurang-kurangnya 30mm (1.18 inci) untuk serasi dengan sambungan T-slot.',
    volume: 'Isipadu',
    width: 'Lebar',
    workarea: 'Kawasan Kerja',
    zoom: 'Zum',
  },
  buttons: {
    back: 'KEMBALI',
    back_to_beam_studio: 'Kembali ke Beam Studio',
    done: 'SELESAI',
    next: 'SETERUSNYA',
  },
  calibration: {
    ador_autofocus_focusing_block: 'Tekan ikon "AF" pada halaman utama mesin selama 3 saat dan biarkan probe menyentuh Blok Fokus.',
    ador_autofocus_material: 'Tekan ikon "AF" pada halaman utama mesin selama 3 saat dan biarkan probe menyentuh bahan dengan lembut.',
    align_ilb: 'Kedudukan: Selaraskan dengan titik terukir di bahagian bawah kiri dalam.',
    align_ilt: 'Kedudukan: Selaraskan dengan titik terukir di bahagian atas kiri dalam.',
    align_irb: 'Kedudukan: Selaraskan dengan titik terukir di bahagian bawah kanan dalam.',
    align_irt: 'Kedudukan: Selaraskan dengan titik terukir di bahagian atas kanan dalam.',
    align_olb: 'Kedudukan: Selaraskan dengan titik terukir di bahagian bawah kiri luar.',
    align_olt: 'Kedudukan: Selaraskan dengan titik terukir di bahagian atas kiri luar.',
    align_orb: 'Kedudukan: Selaraskan dengan titik terukir di bahagian bawah kanan luar.',
    align_ort: 'Kedudukan: Selaraskan dengan titik terukir di bahagian atas kanan luar.',
    align_red_cross_cut: 'Sejajarkan tengah salib merah dengan potongan salib.',
    align_red_cross_print: 'Sejajarkan tengah salib merah dengan salib yang dicetak.',
    analyze_result_fail: 'Gagal menganalisis imej yang ditangkap. <br/> Pastikan: <br/> 1. Gambar yang ditangkap diliputi sepenuhnya dengan kertas putih. <br/> 2. Platform difokuskan dengan betul.',
    ask_for_readjust: 'Adakah anda mahu melangkau langkah pengukiran dan kemudian mengambil gambar dan menentusahkan secara langsung?',
    back: 'Kembali',
    calculating_camera_matrix: 'Mengira Matriks Kamera...',
    calculating_regression_parameters: 'Mengira Parameter Regresi...',
    calibrate_camera_before_calibrate_modules: 'Lakukan penentusahan kamera sebelum menentusahkan modul.',
    calibrate_chessboard_success_msg: 'Gambar papan catur berjaya ditangkap.<br/>Skor untuk gambar ini adalah %s (%.2f).',
    calibrate_done: 'Pentakbiran selesai. Ketepatan kamera yang lebih baik diberikan apabila fokus dengan tepat.',
    calibrate_done_diode: 'Pentakbiran selesai. Ofset modul laser diod telah disimpan.',
    calibrating: 'Menentukur...',
    calibrating_with_device_pictures: 'Mengkalibrasi dengan gambar peranti...',
    camera_calibration: 'Penentukuran Kamera',
    camera_parameter_saved_successfully: 'Parameter kamera berjaya disimpan.',
    cancel: 'Batalkan',
    check_checkpoint_data: 'Data kawalan titik',
    check_device_pictures: 'Semak Gambar Peranti',
    checking_checkpoint: 'Memeriksa data kawalan titik...',
    checking_pictures: 'Memeriksa gambar peranti...',
    diode_calibration: 'Penentukuran Modul Laser Diod',
    do_engraving: 'Mula Ukiran',
    download_chessboard_file: 'Muat turun fail papan dam',
    downloading_checkpoint: 'Memuat turun data kawalan titik...',
    downloading_pictures: 'Memuat turun Gambar...',
    drawing_calibration_image: 'Melukis imej pentakbiran...',
    dx: 'X',
    dy: 'Y',
    elevate_and_cut: 'Angkat dan Potong',
    elevate_and_cut_step_1: 'Letakkan sekeping kayu berwarna terang berukuran A4 di tengah-tengah ruang kerja, diangkat hingga 20mm.',
    elevate_and_cut_step_1_prism_lift: 'Gunakan Ador Prism Lift dengan panjang maksimum 14mm bersama dengan sekeping kayu yang sekurang-kurangnya 6mm tebal.',
    failed_to_calibrate_camera: '#848 Gagal menentusahkan kamera, hubungi Sokongan FLUX.',
    failed_to_calibrate_chessboard: 'Gagal mengkalibrasi dengan gambar papan dam.',
    failed_to_calibrate_with_pictures: '#848 Gagal mengkalibrasi dengan gambar peranti.',
    failed_to_download_pictures: '#848 Gagal memuat turun gambar, hubungi Sokongan FLUX.',
    failed_to_move_laser_head: 'Gagal menggerakkan kepala laser.',
    failed_to_parse_checkpoint: 'Gagal mengurai data kawalan titik.',
    failed_to_save_calibration_results: '#849 Gagal menyimpan keputusan penentusahan, cuba lagi. Jika masalah berterusan, hubungi Sokongan FLUX.',
    failed_to_save_camera_parameter: 'Gagal menyimpan parameter kamera.',
    failed_to_solve_pnp: 'Gagal menyelesaikan kedudukan kamera.',
    finish: 'Selesai',
    found_checkpoint: 'Data kawalan titik dijumpai pada peranti anda. Mahukah anda pulih daripada titik kawalan?',
    getting_plane_height: 'Mendapatkan Ketinggian Satah...',
    hint_adjust_parameters: 'Sila gunakan parameter ini untuk melaraskan kotak merah',
    hint_red_square: 'Sila sejajarkan luar petak merah dengan petak yang dipotong.',
    module_calibration_2w_ir: 'Penentukuran Modul Inframerah',
    module_calibration_printer: 'Penentukuran Modul Pencetak',
    moving_laser_head: 'Menggerakkan kepala laser...',
    next: 'Seterusnya',
    no_picutre_found: '#846 Peranti anda tidak mempunyai gambar mentah yang tersedia untuk kalibrasi. Sila hubungi sokongan FLUX.',
    perform_autofocus_bb2: 'Sila pergi ke panel kawalan mesin dan tekan AF untuk melakukan autofokus.',
    please_do_camera_calibration_and_focus: {
      beambox: 'Semasa menentusahkan modul laser diod, kamera diperlukan.\nPastikan kamera mesin ini telah ditentusahkan.\nDan laraskan platform pada titik fokus (ketinggian akrilik songsang)',
      beamo: 'Semasa menentusahkan modul laser diod, kamera diperlukan.\nPastikan kamera mesin ini telah ditentusahkan.\nDan laraskan kepala laser untuk fokus pada objek ukiran (ketinggian akrilik songsang)',
    },
    please_goto_beambox_first: 'Sila tukar ke Mod Pengukiran (Beambox) untuk menggunakan ciri ini.',
    please_place_dark_colored_paper: 'Sila letakkan kertas berwarna gelap bersaiz A4 atau Surat di tengah kawasan kerja.',
    please_place_paper: 'Sila letakkan kertas A4 atau Saiz Surat berwarna putih di penjuru kiri atas ruang kerja.',
    please_place_paper_center: 'Sila letakkan kertas A4 atau Saiz Surat berwarna putih di tengah ruang kerja.',
    please_refocus: {
      beambox: 'Sila laraskan platform ke titik fokus (ketinggian akrilik songsang)',
      beamo: 'Sila laraskan kepala laser untuk fokus pada objek ukiran (ketinggian akrilik songsang)',
      beamo_af: 'Sila ketuk dua kali butang sisi tambahan fokus automatik dan biarkan prob menyentuh bahan dengan lembut.',
      hexa: 'Klik dua kali butang pelarasan ketinggian untuk menaikkan meja sarang lebah dan membuat prob menyentuh bahan ukiran.',
    },
    preparing_to_take_picture: 'Sedang Bersedia untuk Ambil Gambar...',
    promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    put_chessboard: 'Letakkan Papan Catur',
    put_chessboard_1: 'Sila pergi ke panel kawalan mesin dan tekan AF untuk melakukan autofokus.',
    put_chessboard_2: 'Sila gerakkan kertas papan dam atau kepala laser sehingga keseluruhan papan dam disahkan berada dalam bingkai merah di tetingkap pratonton langsung.',
    put_chessboard_3: 'Klik kanan untuk memuat turun gambar papan catur di lokasi semasa dan periksa kejelasannya.',
    put_chessboard_bb2_desc_1: 'Muat turun fail papan catur berikut dan cetaknya pada kertas A4 (papan catur yang dicetak harus mempunyai kotak berukuran 1x1 cm).',
    put_chessboard_bb2_desc_2: 'Lekatkan kertas papan catur yang telah dicetak pada papan yang tidak mudah berubah bentuk, seperti akrilik atau kaca, dan pastikan papan catur rata tanpa kedutan atau terangkat.',
    put_chessboard_bb2_desc_3: 'Letakkan papan dengan papan catur di tengah kawasan kerja secara rata.',
    put_chessboard_promark_1: 'Laraskan jarak fokus ke fokus yang sesuai untuk kanta medan.',
    put_chessboard_promark_2: 'Pastikan kertas papan dam dapat dilihat dengan jelas tanpa silau. Klik "Seterusnya" untuk mengambil gambar.',
    put_chessboard_promark_desc_1: 'Sila gunakan kertas papan dam yang disediakan dalam kotak aksesori, atau cetak corak papan dam berikut pada kertas A4 untuk kalibrasi kamera.',
    put_chessboard_promark_desc_2: 'Sila letakkan kertas papan dam dengan rata dan di tengah-tengah platform kerja.',
    put_paper: 'Letakkan Kertas',
    put_paper_promark_1: 'Letakkan kadbod hitam dari kotak aksesori di platform kerja.',
    put_paper_promark_2: 'Laraskan fokus dengan betul, kemudian klik "Mula Ukiran" untuk meneruskan ukiran.',
    put_paper_skip: 'Jika lukisan penentukuran tidak meneruskan secara automatik ke pengambilan gambar, tekan "Lompat" untuk mengambil gambar.',
    put_paper_step1: 'Sila letakkan sehelai kertas putih bersaiz A4 atau Surat di tengah-tengah kawasan kerja.',
    put_paper_step2: 'Pastikan keempat-empat sudut kertas diikat untuk memastikan ia rata.',
    put_paper_step3: 'Klik "Mula Ukiran".',
    res_average: 'Purata',
    res_excellent: 'Cemerlang',
    res_poor: 'Lemah',
    retake: 'Ambil Semula Gambar',
    rotation_angle: 'Pusingan',
    show_last_config: 'Tunjukkan Keputusan Terakhir',
    skip: 'Langkau',
    solve_pnp_step1: 'Sila selaraskan titik ukiran mengikut nombor dan kedudukan yang diharapkan bagi setiap titik penanda merah.',
    solve_pnp_step2: 'Anda boleh tekan "Ambil Semula Gambar" untuk menyelaraskan semula atau menyesuaikan posisi penanda secara manual.',
    solve_pnp_step3: 'Sekiranya gambar yang diambil tidak sesuai dengan keadaan sebenar, sila zum atau seret skrin untuk menyesuaikan titik kalibrasi pada kad kalibrasi hitam ke tengah, kemudian cuba "Ambil Semula Gambar".',
    solve_pnp_title: 'Sejajarkan Titik Penanda',
    start_engrave: 'Mula Ukiran',
    start_printing: 'Mula Cetak',
    taking_picture: 'Mengambil Gambar...',
    unable_to_load_camera_parameters: "#851 Tiada parameter kalibrasi kamera tersedia pada peranti anda. Pergi ke 'Kalibrasi' > 'Kalibrasi Kamera (Lanjutan)' untuk melengkapkan kalibrasi dan mendapatkan parameter.",
    update_firmware_msg1: 'Firmware anda tidak menyokong fungsi ini. Sila kemaskini firmware ke v',
    update_firmware_msg2: 'atau lebih tinggi untuk meneruskan。 (Menu > Mesin > [Mesin Anda] > Kemaskini Firmware)',
    uploading_images: 'Memuat naik Imej...',
    use_last_config: 'Guna Nilai Pentakbiran Terakhir',
    use_old_camera_parameter: 'Adakah anda ingin menggunakan parameter lensa kamera semasa?',
    with_af: 'Dengan Fokus Automatik',
    with_prism_lift: 'Dengan Ador Prism Lift',
    without_af: 'Tanpa Fokus Automatik',
    without_prism_lift: 'Tanpa Ador Prism Lift',
    x_ratio: 'Nisbah X',
    y_ratio: 'Nisbah Y',
    zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
  },
  camera_data_backup: {
    download_success: 'Data kamera berjaya dimuat turun.',
    downloading_data: 'Memuat turun data...',
    estimated_time_left: 'Anggaran masa yang tinggal:',
    folder_not_exists: 'Folder yang dipilih tidak wujud.',
    incorrect_folder: 'Gagal mengunggah data kalibrasi. Sila semak sama ada folder yang anda pilih betul atau tidak.',
    no_picture_found: 'Tiada gambar dijumpai dalam mesin.',
    title: 'Simpanan Data Kamera',
    upload_success: 'Data kamera berjaya dimuat naik.',
    uploading_data: 'Memuat naik data...',
  },
  caption: {
    connectionTimeout: 'Sambungan tamat masa',
  },
  change_logs: {
    added: 'Ditambah:',
    change_log: 'Log Perubahan:',
    changed: 'Diubah:',
    fixed: 'Diperbetulkan:',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    see_older_version: 'Lihat Versi Lama',
  },
  code_generator: {
    barcode: 'Kod Bar',
    qr_code: 'Kod QR',
  },
  curve_engraving: {
    '921': 'Gagal untuk fokus secara automatik.',
    '922': 'Gagal melakukan pengukuran dengan cahaya merah.',
    amount: 'Jumlah',
    apply_arkwork: 'Terapkan karya seni pada lengkung',
    apply_camera: 'Terapkan imej kamera pada lengkung',
    click_to_select_point: 'Klik untuk memilih atau nyahpilih titik untuk diukur semula.',
    coloumns: 'Lajur',
    column_gap: 'Jurang Lajur',
    err_object_over_range: 'Objek melebihi julat pengukuran.',
    failed_to_take_reference: 'Gagal mengambil rujukan',
    gap: 'Jurang',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    measure_audofocus_area: 'Ukur Kawasan Fokus Auto',
    preview_3d_curve: 'Pratonton Ukiran Lengkung',
    remeasure: 'Ukur Semula',
    remeasuring_points: 'Mengukur Semula Titik...',
    reselect_area: 'Pilih Semula Kawasan',
    row_gap: 'Jurang Baris',
    rows: 'Baris',
    set_object_height: 'Tetapkan Ketinggian Objek',
    set_object_height_desc: 'Ukur ketebalan maksimum objek.',
    start_autofocus: 'Mulakan Autofokus',
    starting_measurement: 'Memulakan pengukuran...',
    sure_to_delete: 'Adakah anda ingin memadam data fokus ukiran lengkung?',
    take_reference: 'Ambil Rujukan',
    take_reference_desc: 'Sila gerakkan kepala laser ke titik tertinggi objek, turunkan probe fokus, dan klik "Sahkan" untuk memfokuskan.',
  },
  device: {
    abort: 'Batalkan',
    aborted: 'Dibatalkan',
    aborting: 'Membatalkan',
    busy: 'Sibuk',
    cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
    cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
    cartridge_serial_number: 'Ink cartridge serial number',
    close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
    completed: 'Selesai',
    completing: 'Menyiapkan',
    deviceList: 'Senarai Peranti',
    disable: 'Lumpuhkan',
    disconnectedError: {
      caption: 'Mesin terputus sambungan',
      message: 'Sila sahkan sama ada capaian rangkaian %s tersedia',
    },
    enable: 'Hidupkan',
    firmware_version: 'Versi Firmware',
    ink_color: 'Ink color',
    ink_level: 'Ink level',
    ink_type: 'Ink type',
    IP: 'IP',
    model_name: 'Nama Model',
    occupied: 'Menyelenggara',
    pause: 'Jeda',
    paused: 'Dijeda',
    pausedFromError: 'Dijeda kerana ralat',
    pausing: 'Menjeda',
    please_wait: 'Sila Tunggu...',
    preparing: 'Mempersiapkan',
    processing: 'Memproses',
    quit: 'Keluar',
    ready: 'Sedia',
    reset: 'Tetapkan Semula (Tendang)',
    resuming: 'Meneruskan',
    retry: 'Cuba Lagi',
    running: 'Bekerja',
    scanning: 'Mengimbas',
    select: 'Pilih',
    select_printer: 'Pilih Pencetak',
    serial_number: 'Nombor Siri',
    start: 'Mulakan',
    starting: 'Memulakan',
    status: 'Status',
    submodule_type: 'Module',
    toolhead_change: 'Tukar Kepala Alat',
    unknown: 'Tidak diketahui',
    uploading: 'Memuat Naik',
    UUID: 'UUID',
  },
  device_selection: {
    no_beambox: '#801 Kami tidak dapat mengesan mesin anda dalam rangkaian.\nSila ikuti <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">panduan</a> untuk menyelesaikan masalah sambungan.',
    no_device_web: "#801 Semak status mesin anda, atau klik pada 'Tambah Mesin Baru' di bawah untuk menetapkan mesin.",
    select_usb_device: 'Pilih Peranti USB',
  },
  editor: {
    exposure: 'Prapandangan Pendedahan',
    opacity: 'Ketelusan Pratonton',
    prespray_area: 'Kawasan Persediaan',
  },
  error_pages: {
    screen_size: 'Sila ambil perhatian bahawa Beam Studio mungkin tidak berfungsi dengan optimum pada peranti anda. Untuk pengalaman yang terbaik, ia memerlukan peranti dengan lebar skrin minimum 1024 piksel.',
  },
  flux_id_login: {
    connection_fail: '#847 Gagal menyambung ke perkhidmatan ahli FLUX.',
    email: 'E-mel',
    flux_plus: {
      access_monotype_feature: 'Anda tidak mempunyai Tambahan Font Monotype.',
      access_monotype_feature_note: 'Anda perlu mempunyai keahlian FLUX+ Pro atau Tambahan Font Monotype untuk mengakses ciri ini.',
      access_plus_feature_1: 'Anda mengakses',
      access_plus_feature_2: 'ciri.',
      access_plus_feature_note: 'Anda perlu mempunyai keahlian FLUX+ untuk mengakses ciri ini.',
      ai_credit_tooltip: 'Untuk penyingkiran latar belakang AI',
      explore_plans: 'Terokai Pelan FLUX+',
      features: {
        ai_bg_removal: 'Penyingkiran Latar Belakang AI',
        boxgen: 'Penjana Kotak 3D',
        dmkt: '1000+ Fail Reka Bentuk',
        monotype: '250+ Fon Premium',
        my_cloud: 'Storan Awan Tanpa Had',
      },
      flux_credit_tooltip: 'Untuk fail Pasaran Reka Bentuk dan penyingkiran latar belakang AI',
      get_addon: 'Dapatkan Tambahan',
      goto_member_center: 'Pergi ke Pusat Ahli',
      learn_more: 'Ketahui Lebih Lanjut',
      member_center_url: 'https://member.flux3dp.com/en-US/subscription',
      subscribe_now: 'Langgan sekarang',
      thank_you: 'Terima kasih kerana menjadi ahli yang berharga!',
      website_url: 'https://flux3dp.com/subscription',
    },
    forget_password: 'Lupa Kata Laluan?',
    incorrect: 'Alamat e-mel atau kata laluan tidak betul.',
    login: 'Log masuk',
    login_success: 'Berjaya log masuk.',
    lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
    new_to_flux: 'Baru kepada FLUX? Buat akaun.',
    not_verified: 'Alamat e-mel belum disahkan lagi.',
    offline: 'Bekerja Secara Luar Talian',
    password: 'Kata laluan',
    register: 'Buat Akaun FLUX Anda',
    remember_me: 'Ingatkan saya',
    signup_url: 'https://id.flux3dp.com/user/login#up',
    unlock_shape_library: 'Log masuk untuk membuka pangkalan data bentuk.',
    work_offline: 'Bekerja Secara Luar Talian',
  },
  framing: {
    area_check: 'Semakan Kawasan',
    areacheck_desc: 'Memastikan kawasan kerja selamat dengan memaparkan kotak sempadan objek dan zon pecutan kepala laser.',
    calculating_task: 'Mengira tugas...',
    framing: 'Pembingkaian',
    framing_desc: 'Pratonton kotak sempadan objek.',
    hull: 'Kerangka',
    hull_desc: 'Memaparkan bentuk yang rapat mengikuti reka bentuk, seperti gelang getah yang dililitkan pada objek.',
    low_laser: 'Laser rendah',
    low_laser_desc: 'Tetapkan nilai laser berkuasa rendah untuk tugas pembingkaian.',
    rotate_axis: 'Paksi putaran',
    rotateaxis_desc: 'Pratonton pusat paksi putaran, paksi itu sendiri tidak bergerak.',
    rotation_frame_warning: 'Pratonton bingkai akan berputar sekali. Elakkan berhenti di tengah jalan untuk mengelakkan penyimpangan kedudukan tanda.',
    rotation_framing_desc: 'Pratonton keseluruhan julat putaran, paksi berputar mengikut pratonton.',
    start_task: 'Mula Tugas',
    start_task_description: 'Selepas melengkapkan pratonton bingkai, klik "Mula Tugas" untuk menghantar kerja.',
  },
  general: {
    choose_folder: 'Pilih Folder',
    processing: 'Memproses...',
  },
  generic_error: {
    OPERATION_ERROR: '[OE] Konflik status berlaku, sila cuba semula tindakan.',
    SUBSYSTEM_ERROR: '[SE] Ralat perisian mesin sewaktu melaksanakan tugas. Sila mulakan semula mesin.',
    UNKNOWN_COMMAND: '[UC] Sila kemaskini Perisian Peranti',
    UNKNOWN_ERROR: '[UE] Ralat tidak diketahui berlaku. Sila mulakan semula Beam Studio dan mesin.',
  },
  global: {
    apply: 'Guna',
    back: 'Kembali',
    cancel: 'Batal',
    editing: {
      redo: 'Ulangi',
      reset: 'Tetapkan Semula',
      undo: 'Batal',
      zoom_in: 'Zum Masuk',
      zoom_out: 'Zum Keluar',
    },
    mode_conflict: 'Pilihan ini tidak tersedia dalam mod semasa.',
    ok: 'OK',
    save: 'Simpan',
    stop: 'Henti',
  },
  image_edit_panel: {
    eraser: {
      brush_size: 'Saiz Berus',
      description: 'Klik atau seret untuk memadam kawasan yang tidak diingini secara manual.',
      title: 'Pemadam',
    },
    magic_wand: {
      description: 'Pilih dan keluarkan kawasan bersebelahan berdasarkan kesamaan warna.',
      title: 'Tongkat Sihir',
      tolerance: 'Toleransi',
    },
    title: 'Edit Imej',
  },
  initialize: {
    back: 'Undur',
    cancel: 'Batal',
    confirm: 'Sahkan',
    connect: 'Sambung',
    connect_ethernet: {
      title: 'Sambungan Terus',
      tutorial1: '1. Sambungkan mesin dengan komputer anda dengan kabel ethernet.',
      tutorial2_1: '2. Ikuti ',
      tutorial2_2: ' untuk menjadikan komputer anda sebagai penghala.',
      tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
      tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
      tutorial2_a_text: 'panduan ini',
      tutorial3: '3. Klik Seterusnya.',
    },
    connect_machine_ip: {
      alert: {
        swiftray_connection_error: 'Tidak dapat menyambung ke pelayan. Sila mulakan semula Beam Studio dan cuba lagi.',
      },
      check_camera: 'Memeriksa ketersediaan kamera',
      check_connection: 'Memeriksa Sambungan Mesin',
      check_firmware: 'Memeriksa versi perisian',
      check_ip: 'Memeriksa ketersediaan IP',
      check_swiftray_connection: 'Memeriksa sambungan pelayan',
      check_swiftray_connection_unreachable: 'pelayan tidak dapat dicapai',
      check_usb: 'Semak Sambungan USB',
      enter_ip: 'Masukkan IP Mesin Anda',
      finish_setting: 'Mulakan Mencipta!',
      invalid_format: 'Format tidak sah',
      invalid_ip: 'IP Tidak Sah: ',
      promark_hint: 'Jika anda berulang kali gagal menyambung melalui USB, sila rujuk kepada <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">artikel Pusat Bantuan</a>.',
      retry: 'Cuba lagi',
      starts_with_169254: 'Bermula dengan 169.254',
      succeeded_message: 'Berjaya disambungkan 🎉',
      unreachable: 'IP tidak boleh dicapai',
    },
    connect_usb: {
      connect_camera: 'Sambungkan kamera mesin ke komputer anda dengan kabel USB.',
      title: 'Sambungan USB',
      title_sub: ' (HEXA Sahaja)',
      turn_off_machine: 'Matikan mesin.',
      turn_on_machine: 'Hidupkan mesin.',
      tutorial1: '1. Sambungkan mesin dengan komputer anda dengan kabel USB.',
      tutorial2: '2. Klik Seterusnya.',
      wait_for_turning_on: "Klik 'Seterusnya' selepas proses permulaan selesai dan anda telah masuk ke skrin utama.",
    },
    connect_wifi: {
      title: 'Menyambung ke Wi-Fi',
      tutorial1: '1. Pergi ke Panel Sentuh > Klik "Rangkaian" > "Sambung ke WiFi".',
      tutorial1_ador: '1. Pergi ke Panel Sentuh > Klik "MESIN" > Klik "Rangkaian" > "Sambung ke WiFi".',
      tutorial2: '2. Pilih dan sambung Wi-Fi pilihan anda.',
      what_if_1: 'Apa yang perlu dilakukan jika saya tidak nampak Wi-Fi saya?',
      what_if_1_content: '1. Mod penyulitan Wi-Fi perlu WPA2 atau tiada kata laluan.\n2. Mod penyulitan boleh ditetapkan dalam antara muka pentadbiran penghala Wi-Fi. Jika penghala tidak menyokong WPA2 dan anda memerlukan bantuan untuk memilih penghala yang betul, sila hubungi Sokongan FLUX.',
      what_if_2: 'Apa yang perlu dilakukan jika saya tidak dapat melihat sebarang Wi-Fi?',
      what_if_2_content: '1. Pastikan dongle Wi-Fi dimasukkan sepenuhnya.\n2. Jika tiada Alamat MAC rangkaian wayarles pada skrin sentuh, sila hubungi Sokongan FLUX.\n3. Saluran Wi-Fi perlu 2.4Ghz (5Ghz tidak disokong).',
    },
    connect_wired: {
      title: 'Menyambung ke Rangkaian Berwayar',
      tutorial1: '1. Sambungkan mesin dengan penghala anda.',
      tutorial2: '2. Tekan "Rangkaian" untuk mendapatkan IP rangkaian berwayar.',
      tutorial2_ador: '2. Tekan "MESIN" > "Rangkaian" untuk mendapatkan IP rangkaian berwayar.',
      what_if_1: 'Apa yang perlu dilakukan jika IP kosong?',
      what_if_1_content: '1. Pastikan Kabel Ethernet dimasukkan sepenuhnya.\n2. Jika tiada Alamat MAC rangkaian berwayar pada skrin sentuh, sila hubungi Sokongan FLUX.',
      what_if_2: 'Apa yang perlu dilakukan jika IP bermula dengan 169?',
      what_if_2_content: '1. Jika alamat IP bermula dengan 169.254, ia sepatutnya isu tetapan DHCP, sila hubungi ISP (pembekal perkhidmatan internet) anda untuk bantuan lanjut.\n2. Jika komputer anda menyambung ke internet secara terus menggunakan PPPoE, sila tukar kepada menggunakan penghala untuk menyambung menggunakan PPPoE, dan hidupkan ciri DHCP dalam penghala.',
    },
    connecting: 'Menyambung...',
    connection_types: {
      ether2ether: 'Sambungan Terus',
      usb: 'Sambungan USB',
      wifi: 'Wi-Fi',
      wired: 'Rangkaian Berwayar',
    },
    next: 'Seterusnya',
    no_machine: 'Saya tiada mesin sekarang.',
    promark: {
      configuration_confirmation: 'Ini memastikan Promark anda dikonfigurasikan dengan betul untuk kecekapan dan ketepatan optimum.',
      or_complete_later: `Atau, langkau langkah ini dan lengkapkan Tetapan Promark kemudian di:
    Mesin > "Nama Promark" > Tetapan Promark`,
      qc_instructions: 'Isi Parameter yang terletak di belakang kad "QC Pass"',
      select_laser_source: 'Pilih Promark Anda',
      select_workarea: 'Pilih Kawasan Kerja',
      settings: 'Tetapan Promark',
    },
    retry: 'Cuba Lagi',
    select_beambox: 'Pilih Beambox Anda',
    select_connection_type: 'Bagaimana anda mahu sambung?',
    select_language: 'Pilih Bahasa',
    select_machine_type: 'Pilih Model Anda',
    setting_completed: {
      back: 'Kembali',
      great: 'Selamat datang ke Beam Studio',
      ok: 'MULA MENCIPTA',
      setup_later: 'Anda boleh menyediakan mesin anda dari Titlebar > "Mesin" > "Persediaan Mesin"',
      start: 'Mulakan',
    },
    skip: 'Langkau',
    start: 'Mulakan',
  },
  input_machine_password: {
    connect: 'SAMBUNG',
    password: 'Kata Laluan',
    require_password: '"%s" memerlukan kata laluan',
  },
  insecure_websocket: {
    extension_detected: 'Pengesanan Sambungan Beam Studio Connect',
    extension_detected_description: 'Kami telah mengesan anda telah memasang sambungan Beam Studio Connect. Sila klik ‘Sahkan’ untuk diarahkan ke HTTPS, atau klik ‘Batal’ untuk terus menggunakan HTTP.',
    extension_not_deteced: 'Tidak dapat Mengesan Sambungan Beam Studio Connect',
    extension_not_deteced_description: "Untuk menggunakan HTTPS, sila klik 'Sahkan' untuk memasang sambungan Beam Studio Connect. Selepas memasang sambungan, sila segarkan halaman untuk mengaktifkannya.<br/>Jika tidak, klik pautan di bawah untuk melihat cara menggunakan HTTP pada Chrome.",
    unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Pautan</a>',
  },
  layer_module: {
    general_laser: 'Laser',
    laser_2w_infrared: 'Laser Inframerah 2W',
    laser_10w_diode: 'Laser Diod 10W',
    laser_20w_diode: 'Laser Diod 20W',
    non_working_area: 'Kawasan Bukan Kerja',
    none: 'None',
    notification: {
      convertFromLaserModuleMsg: 'Sila ambil perhatian bahawa jika anda melengkapkan operasi ini, tetapan lapisan laser akan dibuang dan ditetapkan mengikut lapisan semasa.',
      convertFromLaserModuleTitle: 'Adakah anda mahu menukar modul Laser kepada modul Pencetakan?',
      convertFromPrintingModuleMsg: 'Sila ambil perhatian bahawa jika anda melengkapkan operasi ini, tetapan warna lapisan pencetakan akan dibuang dan ditetapkan mengikut lapisan semasa.',
      convertFromPrintingModuleTitle: 'Adakah anda mahu menukar modul Pencetakan kepada modul Laser?',
      importedDocumentContainsPrinting: 'Dokumen mengandungi lapisan pencetakan, adakah anda mahu menukar ruang kerja kepada Ador?',
      performIRCaliMsg: 'Klik “Sahkan” untuk melaksanakan penentukuran, atau akses penentukuran melalui menu atas.<br />(Mesin > [Nama Mesin Anda] > Tentukur Modul Inframerah)',
      performIRCaliTitle: 'Lakukan penentukuran modul Inframerah',
      performPrintingCaliMsg: 'Klik “Sahkan” untuk melaksanakan penentukuran, atau akses penentukuran melalui menu atas.<br />(Mesin > [Nama Mesin Anda] > Tentukur Modul Percetakan)',
      performPrintingCaliTitle: 'Lakukan penentukuran modul percetakan',
      printingLayersCoverted: 'Lapisan percetakan telah ditukar kepada lapisan laser.',
    },
    printing: 'Pencetakan',
    unknown: 'Unknown Module',
  },
  machine_status: {
    '-17': 'Mod IO Kartrij',
    '-10': 'Mod penyelenggaraan',
    '-2': 'Penimbangan',
    '-1': 'Menyelenggarakan',
    '0': 'Senggang',
    '1': 'Memulakan',
    '2': 'ST_TRANSFORM',
    '4': 'Memulakan',
    '6': 'Menyambung semula',
    '16': 'Bekerja',
    '18': 'Menyambung semula',
    '32': 'Dijeda',
    '36': 'Dijeda',
    '38': 'Dijeda',
    '48': 'Dijeda',
    '50': 'Dijeda',
    '64': 'Selesai',
    '66': 'Menyempurnakan',
    '68': 'Menyediakan',
    '128': 'Dibatalkan',
    '256': 'Amaran',
    '512': 'Nahas',
    UNKNOWN: 'Tidak diketahui',
  },
  material_test_generator: {
    block_settings: 'Tetapan Blok',
    columns: 'Lajur',
    count: 'Kuantiti',
    cut: 'Potong',
    engrave: 'Ukir',
    export: 'Eksport',
    max: 'Maks',
    min: 'Min',
    parameter: 'Parameter',
    preview: 'Pratonton',
    rows: 'Baris',
    size: 'Saiz (HxW)',
    spacing: 'Jarak',
    table_settings: 'Tetapan Jadual',
    text_settings: 'Tetapan Teks',
    title: 'Penjana Ujian Bahan',
  },
  menu: {
    inches: 'Inci',
    mm: 'mm',
  },
  message: {
    auth_error: '#820 Ralat Pengesahan: Sila kemas kini Beam Studio dan firmware mesin ke versi terkini.',
    authenticating: 'Mengesahkan...',
    camera: {
      abort_preview: 'Batalkan',
      camera_cable_unstable: 'Kamera menghantar gambar secara tidak stabil dikesan. Pratonton masih boleh dilakukan dengan normal, tetapi mungkin ada masalah pratonton perlahan atau masa tamat.',
      continue_preview: 'Teruskan',
      fail_to_transmit_image: '#845 Sesuatu telah berlaku dengan penghantaran imej. Sila mulakan semula mesin atau Beam Studio anda. Jika ralat ini berterusan, sila ikuti <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">panduan ini</a>.',
      ws_closed_unexpectly: '#844 Sambungan ke kamera mesin telah ditutup secara mengejut. Jika ralat ini berterusan, sila ikuti <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">panduan ini</a>.',
    },
    cancelling: 'Membatalkan...',
    cant_connect_to_device: '#827 Tidak dapat disambung ke mesin, sila semak sambungan anda',
    connected: 'Disambung',
    connecting: 'Menyambung...',
    connectingCamera: 'Menyambung kamera...',
    connectingMachine: 'Menyambung %s...',
    connectionTimeout: '#805 Sambungan Peranti Tamat Masa. Sila semak keadaan rangkaian anda dan penunjuk Wi-Fi mesin anda.',
    device_blocked: {
      caption: 'Nombor Siri Tidak Sah',
      offline: 'Nombor siri peranti anda terhad untuk kegunaan luar talian kerana ia nampaknya telah dinyahaktifkan. Sila berikan nombor siri peranti (terletak di bahagian belakang peranti) kepada penjual anda dan minta mereka menghubungi support@flux3dp.com untuk mengaktifkan kebenaran dalam talian. Jika anda ingin menggunakan peranti secara luar talian, sila hubungi support@flux3dp.com secara langsung.',
      online: 'Nombor siri peranti anda nampaknya telah dinyahaktifkan. Sila berikan nombor siri peranti (terletak di bahagian belakang peranti) kepada penjual anda dan minta mereka menghubungi support@flux3dp.com untuk mengaktifkan kebenaran peranti.',
    },
    device_busy: {
      caption: 'Mesin Sibuk',
      message: 'Mesin sedang melaksanakan tugas lain, cuba lagi nanti. Jika berhenti berfungsi, sila mulakan semula mesin.',
    },
    device_is_used: 'Mesin sedang digunakan, adakah anda mahu membatalkan tugas semasa?',
    device_not_found: {
      caption: 'Mesin Lalai tidak dijumpai',
      message: '#812 Sila semak penunjuk Wi-Fi mesin anda',
    },
    disconnected: 'Sambungan tidak stabil, Sila semak sambungan peranti dan cuba lagi nanti',
    endingLineCheckMode: 'Keluar dari mod semakan garis akhir...',
    endingRawMode: 'Keluar mod mentah...',
    enteringLineCheckMode: 'Memasuki mod semak garisan...',
    enteringRawMode: 'Memasuki mod mentah...',
    enteringRedLaserMeasureMode: 'Memasuki mod pengukuran laser merah...',
    exitingRotaryMode: 'Keluar dari mod putaran...',
    getProbePosition: 'Mendapatkan Kedudukan Probe...',
    gettingLaserSpeed: 'Membaca kelajuan kepala laser...',
    homing: 'Penjajaran semula...',
    need_password: 'Kata laluan diperlukan untuk Menyambung ke Mesin',
    please_enter_dpi: 'Sila masukkan unit fail anda (dalam mm)',
    preview: {
      adjust: 'Laras',
      adjust_height_tooltip: 'Klik kotak semak untuk membenarkan penyuntingan.',
      already_performed_auto_focus: 'Anda telah melakukan fokus automatik, guna nilai sedia ada?',
      auto_focus: 'Fokus Automatik',
      auto_focus_instruction: 'Sila gerakkan kepala modul laser di atas objek, dan ikut arahan animasi untuk menekan AF bagi memfokus.',
      camera_preview: 'Pratonton Kamera',
      enter_manually: 'Masukkan Secara Manual',
      please_enter_height: 'Sila masukkan ketinggian objek. Bagi menangkap gambar kamera yang tepat.',
    },
    promark_disconnected: '#850 Sambungan peranti terganggu, sila periksa status sambungan peranti.',
    redLaserTakingReference: 'Mengambil rujukan...',
    retrievingCameraOffset: 'Membaca ofset kamera...',
    settingLaserSpeed: 'Menetapkan kelajuan kepala laser...',
    swiftray_disconnected: 'Tidak dapat menyambung ke backend, mencuba untuk menyambung semula.',
    swiftray_reconnected: 'Backend telah disambung semula, sila cuba hantar kerja semula.',
    time_remaining: 'Masa yang tinggal:',
    tryingToConenctMachine: 'Cuba sambung ke mesin...',
    turningOffAirPump: 'Mematikan pam udara...',
    turningOffFan: 'Mematikan kipas...',
    unable_to_find_machine: 'Tidak dapat menjumpai mesin ',
    unable_to_start: '#830 Tidak dapat memulakan tugasan. Sila cuba lagi. Jika ini berlaku lagi, sila hubungi kami dengan laporan bug:',
    unavailableWorkarea: '#804 Kawasan kerja semasa melebihi kawasan kerja mesin ini. Sila semak kawasan kerja mesin yang dipilih atau tetapkan kawasan kerja dari Edit > Tetapan Dokumen.',
    unknown_device: '#826 Tidak dapat disambung ke mesin, pastikan USB disambung ke mesin',
    unknown_error: '#821 Aplikasi telah menghadapi ralat yang tidak diketahui, sila gunakan Bantuan > Menu > Laporan Pepijat.',
    unsupport_osx_version: 'Versi macOS %s semasa mungkin tidak menyokong beberapa fungsi. Sila kemas kini ke macOS 11+.',
    unsupport_win_version: 'Versi OS %s semasa mungkin tidak menyokong beberapa fungsi. Sila kemas kini ke versi terkini.',
    unsupported_example_file: 'Contoh fail yang dipilih tidak disokong oleh ruang kerja semasa.',
    uploading_fcode: 'Memuat naik FCode',
    usb_unplugged: 'Sambungan USB terputus. Sila semak sambungan USB anda',
    wrong_swiftray_version_message: 'Versi backend salah (versi: {version}). Sila periksa sama ada beberapa instance Beam Studio sedang berjalan dan tutup instance tambahan sebelum mencuba semula.',
    wrong_swiftray_version_title: 'Versi backend salah',
  },
  monitor: {
    ask_reconnect: 'Sambungan dengan mesin telah terputus. Adakah anda mahu menyambung semula?',
    bug_report: 'Laporan Pepijat',
    camera: 'Kamera',
    cancel: 'Batal',
    confirmFileDelete: 'Adakah anda pasti mahu memadam fail ini?',
    connecting: 'Menyambung, sila tunggu...',
    DEVICE_ERROR: 'Sesuatu telah berlaku\nSila mulakan semula mesin',
    download: 'Muat Turun',
    extensionNotSupported: 'Format fail ini tidak disokong',
    fileExistContinue: 'Fail sudah wujud, adakah anda mahu menggantikannya?',
    forceStop: 'Adakah anda mahu membatalkan tugas semasa?',
    go: 'Mula',
    HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Sensor aliran udara mengesan anomali',
    HARDWARE_ERROR_BOTTOM_OPENED: '#903 Bahagian bawah Dibuka. Tutup bahagian bawah untuk teruskan.',
    HARDWARE_ERROR_DOOR_OPENED: '#901 Tutup pintu untuk teruskan.',
    HARDWARE_ERROR_DRAWER_OPENED: '#911 Laci dibuka',
    HARDWARE_ERROR_FIRE_DETECTED: '#912 Sensor api mengesan anomali',
    HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Modul yang salah dikesan. Sila pasang modul yang betul untuk meneruskan.',
    HARDWARE_ERROR_HEADTYPE_NONE: '#917 Modul tidak dikesan. Sila pastikan modul dipasang dengan betul untuk meneruskan.',
    HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Modul tidak diketahui dikesan. Sila pasang modul yang betul untuk meneruskan.',
    HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Ralat Tarikan Pulang Penjuruhan',
    HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Ralat Kritikal: Papan utama luar talian. Sila hubungi Sokongan FLUX.',
    HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Terlalu panas. Sila tunggu beberapa minit.',
    HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Penetapan semula paksi-Z gagal',
    HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Tiada respons daripada modul pencetak.',
    HARDWARE_ERROR_PROBE_SHOWED: 'Sila tarik balik probe.',
    HARDWARE_ERROR_PUMP_ERROR: '#900 Sila semak tangki air anda.',
    HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Tiada modul putar dikesan',
    HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Ralat Kritikal: Tiada respons subsistem. Sila hubungi Sokongan FLUX.',
    HARDWARE_FAILURE: 'Sesuatu telah berlaku\nSila mulakan semula mesin',
    hour: 'j',
    left: 'kiri',
    MAINBOARD_OFFLINE: '#905 Ralat semasa menyambung ke papan induk.\nSila mulakan semula mesin.',
    minute: 'm',
    monitor: 'PEMANTAU',
    NO_RESPONSE: '#905 Ralat semasa menyambung ke papan induk.\nSila mulakan semula mesin.',
    pause: 'Jeda',
    prepareRelocate: 'Menyediakan untuk Pindah Lokasi',
    processing: 'Pemprosesan',
    record: 'Rakam',
    relocate: 'Pindah Lokasi',
    RESOURCE_BUSY: 'Mesin sibuk\nJika tidak beroperasi, sila mulakan semula mesin',
    resume: 'Sambung Semula',
    savingPreview: 'Menghasilkan imej kecil',
    second: 's',
    start: 'Mula',
    stop: 'Henti',
    SUBSYSTEM_ERROR: '#402 Ralat Kritikal: Tiada respon subsistem. Sila hubungi Sokongan FLUX.',
    task: {
      BEAMBOX: 'Ukiran Laser',
      'N/A': 'Mod Bebas',
    },
    taskTab: 'Tugas',
    temperature: 'Suhu',
    upload: 'Muat Naik',
    USER_OPERATION: 'Sila ikuti arahan di panel peranti untuk meneruskan.',
    USER_OPERATION_CHANGE_CARTRIDGE: 'Sila masukkan kartrij yang betul untuk meneruskan.',
    USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Sila masukkan kartrij untuk meneruskan.',
    USER_OPERATION_CHANGE_TOOLHEAD: 'Sila pasang modul yang betul untuk meneruskan.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Modul yang salah dikesan. Sila pasang modul yang betul untuk meneruskan.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Modul tidak dikesan. Sila pastikan modul dipasang dengan betul untuk meneruskan.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Modul tidak diketahui dikesan. Sila pasang modul yang betul untuk meneruskan.',
    USER_OPERATION_REMOVE_CARTRIDGE: 'Sila keluarkan kartrij untuk meneruskan.',
    USER_OPERATION_ROTARY_PAUSE: 'Sila tukar kepada motor putar',
  },
  my_cloud: {
    action: {
      confirmFileDelete: 'Anda pasti mahu memadam fail ini? Tindakan ini tidak boleh dibatalkan.',
      delete: 'Padam',
      download: 'Muat Turun',
      duplicate: 'Duplikat',
      open: 'Buka',
      rename: 'Namakan Semula',
    },
    file_limit: 'Fail percuma',
    loading_file: 'Memuat...',
    no_file_subtitle: 'Pergi ke Menu > "Fail" > "Simpan ke Awan"',
    no_file_title: 'Simpan fail ke Awan Saya untuk bermula.',
    save_file: {
      choose_action: 'Simpan Fail:',
      input_file_name: 'Masukkan nama fail:',
      invalid_char: 'Aksara tidak sah:',
      save: 'Simpan',
      save_new: 'Simpan sebagai fail baru',
      storage_limit_exceeded: 'Penyimpanan awan anda telah mencapai had atas. Sila padam mana-mana fail yang tidak diperlukan sebelum menyimpan fail baru.',
    },
    sort: {
      a_to_z: 'Nama: A - Z',
      most_recent: 'Terbaru',
      oldest: 'Terlama',
      z_to_a: 'Nama: Z - A',
    },
    title: 'Awan Saya',
    upgrade: 'Naik taraf',
  },
  noun_project_panel: {
    clear: 'Kosongkan',
    elements: 'Elemen',
    enjoy_shape_library: 'Nikmati pangkalan data bentuk.',
    export_svg_title: 'Tidak Dapat Eksport SVG',
    export_svg_warning: 'Projek ini mengandungi objek yang dilindungi oleh undang-undang harta intelek. Oleh itu, Beam Studio akan mengecualikan objek ini secara automatik semasa eksport. Anda masih boleh menyimpan projek anda dalam format Beam Studio Scene (.beam) untuk mengekalkan keseluruhan data anda. Anda masih mahu eksport?',
    learn_more: 'Ketahui Lebih Lanjut',
    login_first: 'Log masuk untuk membuka pangkalan data bentuk.',
    recent: 'Terbaru',
    search: 'Cari',
    shapes: 'Bentuk',
  },
  pass_through: {
    export: 'Eksport ke Kawasan Kerja',
    exporting: 'Mengeksport...',
    guide_mark: 'Tanda Panduan',
    guide_mark_desc: 'Tanda panduan akan diukir sebagai titik rujukan untuk menyelaraskan karya seni.',
    guide_mark_length: 'Panjang:',
    guide_mark_x: 'Koordinat X:',
    height_desc: 'Tetapkan ketinggian setiap bahagian kawasan kerja.',
    help_links: {
      ado1: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
      fbb2: 'https://support.flux3dp.com/hc/en-us/articles/11570021253263',
    },
    help_text: 'Bagaimana untuk menyediakan laluan terus untuk %(model)s?',
    object_length: 'Panjang Objek',
    ref_layer: 'Lapisan Rujukan',
    ref_layer_desc: 'Sila ambil perhatian bahawa pelaksanaan lapisan rujukan ditetapkan kepada 0 secara lalai. Ia tidak akan dilaksanakan tetapi hanya untuk rujukan penjajaran.',
    ref_layer_name: 'Rujukan',
    title: 'Mod Laluan Terus',
    workarea_height: 'Kawasan Kerja (Tinggi):',
  },
  promark_connection_test: {
    description: 'Sistem akan menjalankan operasi cahaya merah selama kira-kira dua minit untuk memeriksa kestabilan sambungan.',
    health: 'Kestabilan:',
    healthy_description: 'Kestabilan adalah baik, tetapi elakkan menggerakkan komputer secara signifikan semasa ukiran.',
    res_0: 'Cemerlang',
    res_1: 'Baik',
    res_2: 'Rendah',
    res_3: 'Terlalu Rendah',
    res_4: 'Sangat Rendah',
    restart: 'Mulakan Semula Ujian',
    start: 'Mulakan Ujian',
    stop: 'Hentikan Ujian',
    title: 'Ujian Kestabilan Sambungan',
    unhealthy_description: 'Kestabilan terlalu rendah. Adalah disyorkan untuk menggantikan penyesuai rangkaian atau kabel kuasa.',
  },
  promark_settings: {
    angle: 'Sudut',
    bulge: 'Bonjolan',
    field: 'Medan',
    galvo_configuration: 'Konfigurasi Galvo',
    mark: 'Tanda',
    mark_parameters: 'Parameter Penandaan',
    offsetX: 'Pelesapan X',
    offsetY: 'Pelesapan Y',
    preview: 'Pratonton',
    red_dot: 'Titik Merah',
    scale: 'Skala',
    scaleX: 'Skala X',
    scaleY: 'Skala Y',
    skew: 'Senget',
    switchXY: 'Tukar X/Y',
    title: 'Tetapan Promark',
    trapezoid: 'Trapezoid',
    workarea_hint: 'Anda boleh menukar kawasan kerja dalam "Tetapan Dokumen".',
    z_axis_adjustment: {
      section1: 'Laraskan ketinggian paksi Z untuk melaraskan fokus.',
      title: 'Pelarasan Paksi Z',
      tooltip1: 'Cuba tandakan petak 1x1 cm untuk mengesahkan jika jarak fokus semasa sesuai.',
    },
  },
  qr_code_generator: {
    error_tolerance: 'Ralat Toleransi',
    error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
    invert: 'Songsangkan warna latar belakang',
    placeholder: 'Masukkan pautan atau teks',
    preview: 'Pratonton',
    title: 'Penjana Kod QR',
  },
  rotary_settings: {
    circumference: 'Lilitan',
    extend_workarea: 'Perluaskan kawasan kerja',
    mirror: 'Cermin',
    object_diameter: 'Diameter Objek',
    overlap_size: 'Saiz pertindihan',
    rotary_scale: 'Skala Putaran',
    split_setting: 'Tetapan pembahagian',
    split_setting_url: 'https://support.flux3dp.com/hc/en-us/articles/12276094010767',
    split_size: 'Saiz pembahagian',
    type: 'Jenis',
  },
  select_device: {
    auth_failure: '#811 Pengesahan gagal',
    select_device: 'Pilih Peranti',
    unable_to_connect: '#810 Tidak dapat membina sambungan stabil dengan mesin',
  },
  settings: {
    anti_aliasing: 'Anti-Aliasing',
    auto_connect: 'Pilih mesin tunggal secara automatik',
    auto_switch_tab: 'Tukar Automatik Panel Lapisan dan Objek',
    autofocus_offset: 'Ofset Autofokus',
    autosave_enabled: 'Simpan Automatik',
    autosave_interval: 'Simpan Setiap',
    autosave_number: 'Bilangan Simpan Automatik',
    autosave_path: 'Lokasi Simpan Automatik',
    autosave_path_not_correct: 'Laluan yang dinyatakan tidak dijumpai.',
    blade_precut_position: 'Kedudukan Pra-potong',
    blade_precut_switch: 'Pra-potong Bilah',
    blade_radius: 'Jejari Bilah',
    bottom_up: 'Bawah-atas',
    calculation_optimization: 'Pecutan Pengiraan Laluan',
    cancel: 'Batal',
    caption: 'Tetapan',
    check_updates: 'Semak Automatik',
    close: 'Tutup',
    confirm_remove_default: 'Mesin lalai akan dibuang.',
    confirm_reset: 'Sahkan tetapkan semula Beam Studio?',
    continuous_drawing: 'Lukisan Berterusan',
    curve_engraving_speed_limit: 'Had kelajuan ukiran lengkung',
    custom_preview_height: 'Ketinggian Pratonton Tersuai',
    default_beambox_model: 'Tetapan Dokumen Lalai',
    default_borderless_mode: 'Buka Bawah Lalai',
    default_enable_autofocus_module: 'Autofokus Lalai',
    default_enable_diode_module: 'Laser Diod Lalai',
    default_font_family: 'Font Lalai',
    default_font_style: 'Gaya Font Lalai',
    default_laser_module: 'Modul Laser Lalai',
    default_machine: 'Mesin Lalai',
    default_machine_button: 'Kosongkan',
    default_units: 'Unit Lalai',
    diode_offset: 'Ofset Laser Diod',
    diode_one_way_engraving: 'Ukiran Satu Arah Laser Diod',
    diode_two_way_warning: 'Pancaran dua arah lebih pantas dan berkemungkinan menjana ketidaktepatan pada kedudukan ukiran laser. Disyorkan untuk uji dahulu.',
    disabled: 'Dilumpuhkan',
    done: 'Guna',
    enable_custom_backlash: 'Dayakan Pampasan Undur Tersuai',
    enable_low_speed: 'Dayakan pergerakan perlahan',
    enabled: 'Didayakan',
    engraving_direction: 'Arah',
    fast_gradient: 'Pengoptimuman Kelajuan',
    font_convert: 'Penukar Teks ke Laluan',
    font_substitute: 'Gantikan Aksara Tidak Disokong',
    grouped_objects: 'Objek Berkumpulan',
    groups: {
      ador_modules: 'Modul Ador',
      autosave: 'Simpanan Automatik',
      camera: 'Kamera',
      connection: 'Sambungan',
      editor: 'Penyunting',
      engraving: 'Pemotongan Raster',
      general: 'Umum',
      mask: 'Pemotongan Kawasan Kerja',
      modules: 'Tambahan',
      path: 'Vektor',
      privacy: 'Privasi',
      text_to_path: 'Teks',
      update: 'Kemaskini Perisian',
    },
    guess_poke: 'Cari alamat IP mesin',
    guides: 'Panduan',
    guides_origin: 'Asal Panduan',
    help_center_urls: {
      anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
      calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
      connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
      continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
      default_borderless_mode: 'https://support.flux3dp.com/hc/en-us/articles/360001104076',
      default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
      default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
      fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
      font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
      font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
      image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
      loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
      mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
      reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
      segmented_engraving: 'https://support.flux3dp.com/hc/en-us/articles/12306366019215',
      simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
      vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
    },
    high: 'Tinggi',
    image_downsampling: 'Kualiti Pratonton Bitmap',
    ip: 'Alamat IP Mesin',
    keep_preview_result: 'Kekalkan Hasil Pratonton',
    language: 'Bahasa',
    loop_compensation: 'Pampasan Gelung',
    low: 'Rendah',
    low_laser_for_preview: 'Laser untuk Bingkai sedang berjalan',
    mask: 'Penyepadan Kawasan Kerja',
    medium: 'Sederhana',
    module_offset_2w_ir: 'Ofset Laser Inframerah 2W',
    module_offset_10w: 'Ofset Laser Diod 10W',
    module_offset_20w: 'Ofset Laser Diod 20W',
    module_offset_printer: 'Ofset Pencetak',
    none: 'Tiada',
    normal: 'Tinggi',
    notification_off: 'Mati',
    notification_on: 'Hidup',
    notifications: 'Pemberitahuan Meja',
    off: 'Mati',
    on: 'Hidup',
    preview_movement_speed: 'Kelajuan Pratonton Pergerakan',
    printer_advanced_mode: 'Mod Lanjutan Pencetak',
    remove_default_machine_button: 'Buang',
    reset: 'Tetapkan Semula Beam Studio',
    reset_now: 'Tetapkan Semula Beam Studio',
    segmented_engraving: 'Ukiran bersegmen',
    share_with_flux: 'Kongsi Analitik Studio Sinar',
    simplify_clipper_path: 'Optimumkan Laluan Dikira',
    single_object: 'Objek Tunggal',
    tabs: {
      device: 'Mesin',
      general: 'Umum',
    },
    text_path_calc_optimization: 'Pengoptimuman Pengiraan Laluan',
    top_down: 'Atas-bawah',
    trace_output: 'Output Imej Jejak',
    update_beta: 'Beta',
    update_latest: 'Terkini',
    vector_speed_constraint: 'Had Kelajuan',
    wrong_ip_format: 'Format IP Salah',
  },
  social_media: {
    facebook: 'Berbual dengan FLUXers, ajukan soalan, dan pelajari petua!',
    instagram: 'Dapatkan inspirasi terbaru, tawaran, dan hadiah percuma!',
    youtube: 'Lihat tutorial Beam Studio dan idea kraf.',
  },
  support: {
    no_vcredist: 'Sila pasang Visual C++ Redistributable 2015<br/>Ia boleh dimuat turun di flux3dp.com',
    no_webgl: 'WebGL tidak disokong. Sila gunakan peranti lain.',
  },
  topbar: {
    alerts: {
      add_content_first: 'Sila tambah objek terlebih dahulu',
      door_opened: 'Sila tutup pintu untuk membolehkan laser beroperasi.',
      fail_to_connect_with_camera: '#803 Gagal menghubungi kamera mesin. Sila mulakan semula mesin atau Beam Studio anda. Jika ralat ini berterusan, sila ikuti <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">panduan ini</a>.',
      fail_to_get_door_status: 'Sila pastikan pintu ditutup untuk membolehkan laser beroperasi.',
      fail_to_start_preview: '#803 Gagal memulakan mod pratonton. Sila mulakan semula mesin atau Beam Studio anda. Jika ralat ini berterusan, sila ikuti <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">panduan ini</a>.',
      headtype_mismatch: 'Modul tidak sepadan dikesan. ',
      headtype_none: 'Modul tidak dikesan. ',
      headtype_unknown: 'Modul tidak diketahui dikesan. ',
      install_correct_headtype: 'Sila pasang modul laser diod 10W/20W dengan betul untuk membolehkan laser beroperasi.',
      job_origin_unavailable: 'Menetapkan asal kerja memerlukan versi firmware 4.3.5 / 5.3.5 atau lebih tinggi. Adakah anda ingin mengemas kini firmware sekarang?',
      job_origin_warning: 'Anda sedang menggunakan “kedudukan semasa” sebagai titik permulaan. Pastikan kepala laser dipindahkan ke kedudukan yang betul untuk mengelakkan perlanggaran.',
      power_too_high: 'KUASA TERLALU TINGGI',
      power_too_high_confirm: 'DIAMBIL PERHATIAN',
      power_too_high_msg: 'Menggunakan kuasa laser yang lebih rendah (bawah 70%) akan memanjangkan jangka hayat tiub laser.\nMasukkan "DIAMBIL PERHATIAN" untuk meneruskan.',
      pwm_unavailable: 'Mod Kedalaman memerlukan versi firmware 4.3.4 / 5.3.4 atau ke atas. Adakah anda ingin mengemas kini firmware sekarang?',
      QcleanScene: 'Adakah anda mahu padamkan lukisan?<br/>Ini juga akan memadam sejarah undo anda!',
      start_preview_timeout: '#803 Timeout telah berlaku ketika memulakan mod pratonton. Sila mulakan semula mesin atau Beam Studio anda. Jika ralat ini berterusan, sila ikuti <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">panduan ini</a>.',
    },
    frame_task: 'Bingkai sedang berjalan',
    hint: {
      polygon: 'Tekan kekunci + / - untuk menambah / mengurangkan sisi.',
    },
    menu: {
      about: 'Tentang Beam Studio',
      about_beam_studio: 'Mengenai Beam Studio',
      account: 'Akaun',
      add_new_machine: 'Tambah Mesin Baru',
      align_center: 'Jajar tengah',
      anti_aliasing: 'Anti-Aliasing',
      auto_align: 'Penjajaran Auto',
      borderless_mode: 'Mod Tanpa Sempadan',
      bug_report: 'Laporan Pepijat',
      calibrate_beambox_camera: 'Kalibrasi Kamera',
      calibrate_beambox_camera_borderless: 'Kalibrasi Kamera (Tanpa Bingkai)',
      calibrate_camera_advanced: 'Kalibrasi Kamera (Lanjutan)',
      calibrate_diode_module: 'Kalibrasi Modul Laser Diod',
      calibrate_ir_module: 'Tentukur Modul Inframerah',
      calibrate_printer_module: 'Tentukur Modul Pencetakan',
      calibration: 'Penentukuran',
      camera_calibration_data: 'Data Penentukuran Kamera',
      change_logs: 'Log Perubahan',
      clear_scene: 'Fail baru',
      close: 'Tutup Tetingkap',
      commands: 'Arahan',
      contact: 'Hubungi Kami',
      copy: 'Salin',
      cut: 'Potong',
      dashboard: 'Papan Pemuka',
      decompose_path: 'Nyahkompon',
      delete: 'Padam',
      design_market: 'Pasaran Reka Bentuk',
      dev_tool: 'Alat Debug',
      disassemble_use: 'Nyahpasang',
      document_setting: 'Tetapan Dokumen',
      document_setting_short: 'Dokumen',
      download_data: 'Muat Turun Data',
      download_log: 'Muat turun Log',
      download_log_canceled: 'Muat turun log dibatalkan',
      download_log_error: 'Ralat tidak diketahui, sila cuba lagi',
      duplicate: 'Duplikat',
      edit: 'Sunting',
      example_files: 'Fail Contoh',
      export_BVG: 'BVG',
      export_flux_task: 'tugas FLUX',
      export_JPG: 'JPG',
      export_PNG: 'PNG',
      export_SVG: 'SVG',
      export_to: 'Eksport Kepada...',
      file: 'Fail',
      fit_to_window: 'Muat ke Saiz Tetingkap',
      follow_us: 'Ikuti kami',
      forum: 'Forum Komuniti',
      group: 'Kumpulkan',
      help: 'Bantuan',
      help_center: 'Pusat Bantuan',
      hide: 'Sembunyikan Beam Studio',
      hideothers: 'Sembunyikan Lain',
      image_crop: 'Potong',
      image_curve: 'Lengkung',
      image_invert: 'Songsang',
      image_sharpen: 'Tajamkan',
      image_stamp: 'Cungkil',
      image_vectorize: 'Jejak',
      import_acrylic_focus_probe: 'Pemfokus Akrilik Probe - 3mm',
      import_ador_laser_example: 'Contoh Ador Laser',
      import_ador_printing_example_full: 'Contoh Cetakan Ador - Warna Penuh',
      import_ador_printing_example_single: 'Contoh Cetakan Ador - Satu Warna',
      import_beambox_2_example: 'Contoh Beambox II',
      import_beambox_2_focus_probe: 'Probe Fokus Beambox II - 3mm',
      import_hello_beambox: 'Contoh Beambox',
      import_hello_beamo: 'Contoh beamo',
      import_hexa_example: 'Contoh HEXA',
      import_material_printing_test: 'Ujian Cetak Bahan',
      import_material_testing_cut: 'Ujian Pemotongan Bahan',
      import_material_testing_engrave: 'Ujian Ukiran Bahan',
      import_material_testing_line: 'Ujian Garisan Bahan',
      import_material_testing_old: 'Ujian Ukiran Bahan - Klasik',
      import_material_testing_simple_cut: 'Ujian Pemotongan Bahan - Mudah',
      import_promark_example: 'Contoh Promark',
      import_promark_mopa_20w_color: 'Ujian Warna MOPA 20W',
      import_promark_mopa_60w_color: 'Ujian Warna MOPA 60W',
      import_promark_mopa_100w_color: 'Ujian Warna MOPA 100W',
      keyboard_shortcuts: 'Pintasan papan kekunci',
      layer_color_config: 'Tetapan warna',
      layer_setting: 'Lapisan',
      link: {
        beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
        contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
        design_market: 'https://dmkt.io',
        downloads: 'https://flux3dp.com/downloads/',
        forum: 'https://www.facebook.com/groups/flux.laser/',
        help_center: 'https://helpcenter.flux3dp.com/',
        shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
      },
      log: {
        camera: 'Kamera',
        cloud: 'Awan',
        discover: 'Cari',
        hardware: 'Perkakasan',
        network: 'Rangkaian',
        player: 'Pemain',
        robot: 'Robot',
        usb: 'USB',
        usblist: 'Senarai USB',
      },
      machine_info: 'Maklumat Mesin',
      machines: 'Mesin',
      manage_account: 'Urus Akaun Saya',
      material_test: 'Ujian Bahan',
      minimize: 'Minimumkan',
      my_account: 'Akaun Saya',
      network_testing: 'Ujian Rangkaian',
      object: 'Objek',
      offset: 'Ofset',
      open: 'Buka',
      paste: 'Tampal',
      paste_in_place: 'Tampal di tempat',
      path: 'Laluan',
      photo_edit: 'Imej',
      preferences: 'Keutamaan',
      promark_color_test: 'Ujian Warna Promark',
      questionnaire: 'Soal Selidik Maklum Balas',
      quit: 'Tutup',
      recent: 'Buka Terkini',
      redo: 'Buat semula',
      reload_app: 'Muat Semula Apl',
      reset: 'Set semula',
      rotary_setup: 'Persediaan Putaran',
      rotate: 'Putar',
      samples: 'Contoh',
      save_as: 'Simpan Sebagai...',
      save_scene: 'Simpan',
      save_to_cloud: 'Simpan ke Awan',
      scale: 'Skala',
      service: 'Perkhidmatan',
      set_as_default: 'Tetapkan sebagai Lalai',
      show_gesture_tutorial: 'Tutorial Gerak Isyarat',
      show_grids: 'Tunjukkan Grid',
      show_layer_color: 'Gunakan Warna Lapisan',
      show_rulers: 'Tunjukkan Pembaris',
      show_start_tutorial: 'Tunjukkan Tutorial Permulaan',
      show_ui_intro: 'Tunjukkan Pengenalan Antaramuka',
      sign_in: 'Log Masuk',
      sign_out: 'Log Keluar',
      software_update: 'Kemas Kini Perisian',
      svg_edit: 'SVG',
      switch_to_beta: 'Tukar ke Beta Release',
      switch_to_latest: 'Tukar ke Release Stabil',
      tools: {
        box_generator: 'Penjana Kotak',
        code_generator: 'Penjana Kod',
        material_test_generator: 'Penjana Ujian Bahan',
        title: 'Alat',
      },
      tutorial: 'Tutorial Permulaan',
      undo: 'Buat asal',
      ungroup: 'Nyahkumpul',
      update: 'Semak Kemas Kini',
      update_firmware: 'Kemas Kini Perisian',
      upload_data: 'Muat Naik Data',
      using_beam_studio_api: 'Menggunakan API Beam Studio',
      view: 'Lihat',
      window: 'Tetingkap',
      zoom_in: 'Zum Masuk',
      zoom_out: 'Zum Keluar',
      zoom_with_window: 'Padankan Secara Automatik ke Saiz Tetingkap',
    },
    preview: 'PRATONTON',
    preview_press_esc_to_stop: 'Tekan ESC untuk menghentikan pratonton kamera.',
    rename_tab: 'Namakan semula tab',
    select_machine: 'Pilih mesin',
    tag_names: {
      dxf: 'Objek DXF',
      ellipse: 'Bulatan',
      g: 'Kumpulan',
      image: 'Imej',
      line: 'Garisan',
      multi_select: 'Beberapa Objek',
      no_selection: 'Tiada Pilihan',
      pass_through_object: 'Objek Lulus Melalui',
      path: 'Laluan',
      polygon: 'Poligon',
      rect: 'Segi empat tepat',
      svg: 'Objek SVG',
      text: 'Teks',
      text_path: 'Teks pada Laluan',
      use: 'Objek Diimport',
    },
    task_preview: 'Laluan pratonton',
    titles: {
      settings: 'Tetapan',
    },
    untitled: 'Tanpa Tajuk',
  },
  topmenu: {
    credit: 'Beam Studio dimungkinkan oleh projek sumber terbuka <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> dan <a target="_blank" href="https://flux3dp.com/credits/">perisian sumber terbuka</a> lain.',
    device: {
      download_log_canceled: 'Muat turun log dibatalkan',
      download_log_error: 'Ralat tidak diketahui berlaku, sila cuba lagi kemudian',
      log: {
        usblist: 'Senarai USB',
      },
      network_test: 'Uji Rangkaian',
    },
    file: {
      all_files: 'Semua Fail',
      clear_recent: 'Kosongkan Baru-baru ini Dibuka',
      converting: 'Menukar ke Imej...',
      fcode_files: 'Kod FLUX',
      import: 'Import',
      jpg_files: 'JPG',
      label: 'Fail',
      path_not_exit: 'Laluan ini sepertinya tidak wujud lagi pada cakera.',
      png_files: 'PNG',
      save_fcode: 'Eksport Tugas FLUX',
      save_jpg: 'Eksport JPG',
      save_png: 'Eksport PNG',
      save_scene: 'Simpan Babak',
      save_svg: 'Eksport SVG',
      scene_files: 'Skrin Beam Studio',
      svg_files: 'SVG',
    },
    ok: 'OK',
    version: 'Versi',
  },
  tutorial: {
    ask_retry_calibration: 'Adakah anda ingin menentukur semula kamera?',
    camera_calibration_failed: 'Penentukuran Kamera Gagal',
    gesture: {
      click: 'Ketik untuk memilih objek.',
      drag: 'Seret untuk memilih beberapa objek.',
      hold: 'Tekan dan tahan untuk membuka menu konteks.',
      pan: 'Tatal dengan dua jari untuk mengimbas kanvas.',
      zoom: 'Cubit dengan dua jari untuk mengecilkan/membesarkan kanvas.',
    },
    links: {
      adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
    },
    look_for_machine: 'Mencari mesin untuk tutorial...',
    needNewInterfaceTutorial: 'Adakah anda ingin memulakan tutorial untuk antara muka baru Beam Studio?<br/>(Anda boleh langkau sekarang dan memulakannya kemudian dengan mengklik "Bantuan" > "Tunjukkan Pengenalan Antara Muka".)',
    needNewUserTutorial: 'Adakah anda ingin memulakan tutorial?<br/>(Anda boleh langkau sekarang dan memulakannya kemudian dengan mengklik "Bantuan" > "Tunjukkan Tutorial Permulaan".)',
    newInterface: {
      add_new_layer: 'Tambah Lapisan Baru',
      align_controls: 'Kawalan Jajar',
      basic_shapes: 'Bentuk Asas',
      camera_preview: 'Pratonton Kamera',
      drag_to_sort: 'Seret untuk susun',
      end_alert: 'Adakah anda pasti untuk menamatkan pengenalan UI baharu?',
      flip: 'Terbalik',
      group_controls: 'Kawalan Kumpulan',
      layer_controls: 'Klik kanan untuk pilih Kawalan Lapisan:\nDuplikat / Gabung / Kunci / Padam Lapisan',
      object_actions: 'Tindakan Objek',
      pen_tool: 'Alat Pena',
      rename_by_double_click: 'Namakan Semula dengan klik dua kali',
      select_image_text: 'Pilih / Imej / Teks',
      select_machine: 'Pilih mesin',
      shape_operation: 'Operasi Bentuk',
      start_work: 'Mulakan Kerja',
      switch_between_layer_panel_and_object_panel: 'Tukar antara Panel Lapisan dan Panel Objek',
    },
    newUser: {
      add_new_layer: 'Tambah Lapisan Baru',
      adjust_focus: '2. Laraskan fokus',
      close_cover: '3. Tutup penutup',
      drag_to_draw: 'Seret untuk Melukis',
      draw_a_circle: 'Lukis Bulatan',
      draw_a_rect: 'Lukis Segi Empat Tepat',
      end_alert: 'Adakah anda pasti untuk menamatkan tutorial?',
      end_preview_mode: 'Akhiri Mod Pratonton',
      infill: 'Hidupkan Isian',
      please_select_wood_cutting: 'Sila pilih praset "Kayu - Potong".',
      please_select_wood_engraving: 'Sila pilih praset "Kayu - Ukir".',
      preview_the_platform: 'Pratonton Platform',
      put_wood: '1. Letakkan sampel kayu',
      send_the_file: 'Hantar Fail',
      set_preset_wood_cut: 'Tetapkan Praset: Kayu - Potong',
      set_preset_wood_engraving: 'Tetapkan Praset: Kayu - Ukir',
      switch_to_layer_panel: 'Tukar ke Panel Lapisan',
      switch_to_object_panel: 'Tukar ke Panel Objek',
      switch_to_preview_mode: 'Tukar ke Mod Pratonton',
    },
    next: 'SETERUSNYA',
    retry: 'Cuba Semula',
    set_connection: 'Tetapkan Sambungan',
    skip: 'Langkau',
    skip_tutorial: 'Anda telah melangkau tutorial. Anda sentiasa boleh mulakan tutorial dengan klik "Bantuan" > "Tunjukkan Mulakan Tutorial"',
    suggest_calibrate_camera_first: 'Kami menasihatkan pengguna untuk mengkalibrasi kamera pada mulanya dan menetap semula fokus sebelum setiap pratinjau untuk hasil yang optimum.<br/>Adakah anda ingin mengesahkan untuk menjalankan kalibrasi sekarang?<br/>(Anda boleh langkau sekarang dan lakukan nanti dengan mengklik "Menu" > "Mesin" > [Mesin Anda] > "Kalibrasi Kamera".)',
    tutorial_complete: 'Itu sahaja untuk tutorial. Sekarang masanya untuk mencipta!',
    unable_to_find_machine: 'Tidak dapat mencari mesin untuk Tutorial. Adakah anda ingin pergi ke halaman tetapan sambungan, cuba semula atau langkau tutorial?',
    welcome: 'SELAMAT DATANG',
  },
  update: {
    cannot_reach_internet: '#823 Pelayan tidak boleh dicapai<br/>Sila semak sambungan internet',
    download: 'KEMASKINI DALAM TALIAN',
    firmware: {
      caption: 'Kemaskini Perisian Peranti kepada mesin tersedia',
      confirm: 'MUAT NAIK',
      firmware_too_old_update_by_sdcard: 'Versi perisian peranti terlalu lama. Sila kemaskini perisian peranti menggunakan kad SD.',
      force_update_message: '#814 Sila kemaskini mesin anda ke versi perisian peranti terkini.',
      latest_firmware: {
        cant_get_latest: 'Tidak dapat dapatkan maklumat perisian peranti terkini.',
        caption: 'Kemaskini perisian peranti Mesin',
        message: 'Anda ada versi terkini perisian peranti Mesin',
        still_update: 'KEMASKINI',
      },
      message_pattern_1: '"%s" kini bersedia untuk kemaskini perisian peranti.',
      message_pattern_2: '%s Perisian Peranti v%s kini tersedia - Anda ada v%s.',
      too_old_for_web: 'Versi perisian peranti mesin semasa anda ialah v%s.\nJika anda mahu menggunakan versi atas talian Studio Beam, sila kemaskini perisian peranti mesin ke versi terkini.',
      update_fail: '#822 Kemaskini Gagal',
      update_success: 'Kemaskini perisian peranti berjaya dimuat naik',
      upload_file: 'Muat naik perisian peranti (*.bin / *.fxfw)',
    },
    install: 'PASANG',
    later: 'KEMUDIAN',
    preparing: 'Mempersiapkan...',
    release_note: 'Nota Keluaran:',
    skip: 'Langkau Versi Ini',
    software: {
      available_switch: 'Beam Studio v%s tersedia sekarang. Anda mempunyai v%s. Adakah anda mahu beralih ke versi ini?',
      available_update: 'Beam Studio v%s tersedia sekarang. Anda mempunyai v%s. Adakah anda mahu memuat turun kemaskini?',
      caption: 'Kemaskini Perisian Beam Studio tersedia',
      check_update: 'Semak Kemaskini',
      checking: 'Memeriksa Kemaskini',
      downloading: 'Memuat turun kemaskini di latar belakang, anda boleh klik "OK" untuk meneruskan kerja anda.',
      install_or_not: 'sedia untuk dikemaskini. Adakah anda mahu memulakan semula dan mengemaskini sekarang?',
      no: 'Tidak',
      no_response: 'Pelayan tidak boleh dicapai<br/>Sila semak sambungan internet',
      not_found: 'Anda menggunakan versi terkini Beam Studio.',
      switch_or_not: 'sedia untuk ditukar. Adakah anda mahu memulakan semula dan menukar sekarang?',
      switch_version: 'Tukar Versi',
      switch_version_not_found: 'Versi Boleh Tukar tidak dijumpai.',
      update_for_ador: 'Versi perisian semasa %s tidak serasi, sila muat turun versi terkini Beam Studio untuk Ador.',
      yes: 'Ya',
    },
    update: 'Kemaskini',
    updating: 'Mengemaskini...',
    upload: 'MUAT NAIK',
  },
  web_cam: {
    no_device: 'Tidak dapat mengesan peranti kamera. Sila sambungkan semula kamera dan cuba lagi.',
    no_permission: 'Beam Studio tidak mempunyai kebenaran untuk mengakses kamera. Pastikan kebenaran diberikan kepada Beam Studio dalam tetapan pelayar atau sistem.',
  },
  z_speed_limit_test: {
    alert_before: 'Sebelum memulakan ukiran lengkung, sistem akan melakukan ujian beban untuk mengelakkan kehilangan langkah akibat berat berlebihan.',
    alert_failed: 'Berat objek semasa melebihi had beban untuk kelajuan paksi Z semasa. Kurangkan kelajuan paksi Z pada panel mesin atau gantikan objek ukiran sebelum menguji semula.',
    ignore: 'Abaikan',
    retest: 'Uji semula',
    testing: 'Melaksanakan ujian beban...',
  },
};

export default lang;
