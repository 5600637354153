// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-views-monitor-widgets-Breadcrumbs-module__breadcrumb--MBnRg li:last-child{font-weight:bold}._-_-packages-core-src-web-app-views-monitor-widgets-Breadcrumbs-module__breadcrumb--MBnRg ._-_-packages-core-src-web-app-views-monitor-widgets-Breadcrumbs-module__item--q7zoa{cursor:pointer}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/views/monitor/widgets/Breadcrumbs.module.scss"],"names":[],"mappings":"AACE,yGACE,gBAAA,CAEF,gLACE,cAAA","sourcesContent":[".breadcrumb {\n  li:last-child {\n    font-weight: bold;\n  }\n  .item {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumb": "_-_-packages-core-src-web-app-views-monitor-widgets-Breadcrumbs-module__breadcrumb--MBnRg",
	"item": "_-_-packages-core-src-web-app-views-monitor-widgets-Breadcrumbs-module__item--q7zoa"
};
export default ___CSS_LOADER_EXPORT___;
