// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-views-dialogs-Prompt-module__checkbox--rChfK{margin-top:8px}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/views/dialogs/Prompt.module.scss"],"names":[],"mappings":"AAAA,4EACE,cAAA","sourcesContent":[".checkbox {\n  margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": "_-_-packages-core-src-web-app-views-dialogs-Prompt-module__checkbox--rChfK"
};
export default ___CSS_LOADER_EXPORT___;
