import type { ILang } from '@core/interfaces/ILang';

const lang: ILang = {
  alert: {
    abort: 'ยกเลิก',
    cancel: 'ยกเลิก',
    caption: 'ข้อผิดพลาด',
    close: 'ปิด',
    confirm: 'ยืนยัน',
    dont_save: 'ไม่บันทึก',
    dont_show_again: 'ไม่แสดงซ้ําอีก',
    duplicated_preset_name: 'ชื่อชุดค่าที่ซ้ํากัน',
    error: 'โอ้โห',
    info: 'ข้อมูล',
    instruction: 'คําแนะนํา',
    learn_more: 'เรียนรู้เพิ่มเติม',
    no: 'ไม่ใช่',
    ok: 'ตกลง',
    ok2: 'ตกลง',
    oops: 'โอ๊ย...',
    retry: 'ลองใหม่',
    save: 'บันทึก',
    stop: 'หยุด',
    warning: 'คําเตือน',
    yes: 'ใช่',
  },
  auto_fit: {
    artwork_size: 'ขนาดชิ้นงาน',
    error_tip1: '1. งานศิลปะถูกวางไว้อย่างถูกต้องบนวัสดุหรือไม่?',
    error_tip2: '2. ขอบเขตของวัสดุชัดเจนพอสำหรับการจดจำหรือไม่?',
    failed_to_find_contour: 'ไม่พบเส้นขอบสำหรับการปรับพอดีอัตโนมัติ',
    learn_more: 'ดูบทแนะนำ',
    learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    offset_x: 'ระยะเยื้อง X',
    offset_y: 'ระยะเยื้อง Y',
    pattern_size: 'ขนาดลวดลาย',
    position_artwork: 'กำหนดตำแหน่งงานศิลปะ',
    position_step1: 'ปรับขนาดและตำแหน่งของงานศิลปะบนลวดลาย',
    position_step2: 'คลิก "นําไปใช้" เพื่อใช้การปรับอัตโนมัติกับลวดลายนี้',
    preview_first: 'โปรดทำตัวอย่างกล้องก่อน',
    reset_position: 'รีเซ็ตตำแหน่ง',
    rotation: 'หมุน',
    select_a_pattern: 'เลือกลวดลาย',
    selected_artwork: 'งานศิลปะที่เลือก',
    step1: 'เลือกลวดลายที่คุณต้องการวางงานศิลปะ',
    step2: 'หากไม่พบลวดลายใด ๆ ให้พรีวิวกล้องอีกครั้งและใช้ ปรับให้พอดีอัตโนมัติ',
    step3: 'หากยังคงไม่พบลวดลายที่ถูกต้อง โปรดตรวจสอบให้แน่ใจว่าวัสดุของคุณสามารถจดจำได้ชัดเจนและพื้นที่ทำงานสะอาดปราศจากเศษขยะ',
    title: 'ปรับให้พอดีอัตโนมัติ',
  },
  barcode_generator: {
    bar_height: 'ความสูงของแท่ง',
    bar_width: 'ความกว้างของแท่ง',
    barcode: {
      invalid_value: 'ค่านี้ไม่ถูกต้องสำหรับรูปแบบที่เลือก',
    },
    font: 'ฟอนต์',
    font_size: 'ขนาดฟอนต์',
    hide_text: 'ซ่อนข้อความ',
    invert_color: 'กลับสี',
    text_margin: 'ระยะขอบข้อความ',
  },
  beambox: {
    announcement_panel: {
      title: 'ประกาศ',
    },
    banner: {
      auto_feeder: 'โหมดป้อนอัตโนมัติ',
      camera_preview: 'แสดงตัวอย่าง',
      camera_preview_borderless_mode: '(เปิดด้านล่าง)',
      curve_engraving: 'โหมดแกะสลักเส้นโค้ง',
      pass_through: 'โหมดส่งผ่าน',
      rotary: 'โหมดหมุน',
    },
    bottom_right_panel: {
      convert_text_to_path_before_export: 'แปลงข้อความเป็นเส้นทาง...',
      export_file_error_ask_for_upload: 'ส่งออกล้มเหลว ต้องการส่งงานสู่ทีมพัฒนาเพื่อรายงานข้อบกพร่องหรือไม่',
      retreive_image_data: 'ดึงข้อมูลรูปภาพ...',
    },
    context_menu: {
      copy: 'คัดลอก',
      cut: 'ตัด',
      delete: 'ลบ',
      duplicate: 'ทําสําเนา',
      group: 'จัดกลุ่ม',
      move_back: 'ส่งไปข้างหลังสุด',
      move_down: 'ส่งไปข้างหลัง',
      move_front: 'นําไปข้างหน้าสุด',
      move_up: 'นําไปข้างหน้า',
      paste: 'วาง',
      paste_in_place: 'วางในตําแหน่ง',
      ungroup: 'แยกกลุ่ม',
    },
    document_panel: {
      add_on: 'เพิ่มเติม',
      auto_feeder: 'ตัวป้อนอัตโนมัติ',
      auto_feeder_scale: 'มาตราส่วนตัวป้อนอัตโนมัติ',
      auto_feeder_url: 'https://support.flux3dp.com/hc/en-us/articles/11688230498575',
      borderless_mode: 'โหมดเปิดก้น',
      current_position: 'ตำแหน่งปัจจุบัน',
      disable: 'ปิดใช้งาน',
      document_settings: 'การตั้งค่าเอกสาร',
      enable: 'เปิดใช้งาน',
      enable_autofocus: 'โฟกัสอัตโนมัติ',
      enable_diode: 'เลเซอร์ไดโอด',
      engrave_dpi: 'ความละเอียด',
      frame_before_start: 'กรอบก่อนดำเนินการ',
      frame_before_start_url: 'https://support.flux3dp.com/hc/en-us/articles/11494925637135',
      high: 'สูง',
      job_origin: 'ต้นกำเนิดงาน',
      laser_source: 'แหล่งกำเนิดเลเซอร์',
      low: 'ต่ํา',
      machine: 'เครื่อง',
      medium: 'ปานกลาง',
      notification: {
        changeFromPrintingWorkareaTitle: 'คุณต้องการแปลงชั้นการพิมพ์เป็นชั้นเลเซอร์หรือไม่?',
      },
      origin: 'ต้นกำเนิด',
      pass_through: 'ผ่าน',
      pass_through_height_desc: 'ป้อนความยาวของวัตถุเพื่อขยายพื้นที่การทำงาน',
      rotary_mode: 'โหมดหมุน',
      scale: 'มาตราส่วน',
      start_from: 'เริ่มจาก',
      start_position: 'ตำแหน่งเริ่มต้น',
      start_work_button: 'ปุ่มดำเนินการ',
      ultra: 'สูงมาก',
      workarea: 'พื้นที่ทํางาน',
    },
    image_trace_panel: {
      back: 'กลับ',
      brightness: 'ความสว่าง',
      cancel: 'ยกเลิก',
      contrast: 'ความคมชัด',
      next: 'ถัดไป',
      okay: 'ตกลง',
      threshold: 'เกณฑ์',
      tuning: 'พารามิเตอร์',
    },
    layer_color_config_panel: {
      add: 'เพิ่ม',
      add_config: 'เพิ่มสี',
      color: 'สี',
      default: 'รีเซ็ตเป็นค่าเริ่มต้น',
      in_use: 'กําลังใช้สีนี้อยู่',
      layer_color_config: 'การกําหนดสีชั้น',
      no_input: 'กรุณาใส่รหัสสีเฮ็กซ์ที่ถูกต้อง',
      power: 'กําลัง',
      repeat: 'ทําซ้ํา',
      speed: 'ความเร็ว',
      sure_to_delete: 'คุณแน่ใจหรือไม่ที่จะลบการตั้งค่าสีนี้?',
      sure_to_reset: 'คุณต้องการรีเซ็ตเป็นค่าเริ่มต้น ใช่หรือไม่ การตั้งค่าที่กําหนดเองทั้งหมดจะหายไป',
    },
    left_panel: {
      borderless_blind_area: 'พื้นที่ที่ไม่สามารถกัดได้',
      borderless_preview: 'เปิดกล้องด้านล่างเพื่อดูตัวอย่าง',
      diode_blind_area: 'พื้นที่มืดของเลเซอร์แอดออน',
      ellipse: 'วงรี',
      image: 'รูปภาพ',
      label: {
        adjust_height: 'ปรับระดับความสูง',
        array: 'แถวลําดับ',
        boxgen: 'Boxgen',
        choose_camera: 'กล้อง',
        clear_preview: 'ล้างตัวอย่าง',
        cursor: 'เลือก',
        curve_engraving: {
          clear_area: 'ล้างพื้นที่ที่เลือก',
          exit: 'ออกจากระบบ',
          preview_3d_curve: 'ดูตัวอย่างการแกะสลักเส้นโค้ง',
          select_area: 'เลือกพื้นที่',
          title: 'การแกะสลักเส้นโค้ง',
        },
        end_preview: 'สิ้นสุดตัวอย่าง',
        line: 'เส้น',
        live_feed: 'ภาพถ่ายสด',
        my_cloud: 'คลาวด์ของฉัน',
        oval: 'วงรี',
        pass_through: 'ผ่าน',
        pen: 'ปากกา',
        photo: 'รูปภาพ',
        polygon: 'รูปเหลี่ยม',
        preview: 'ตัวอย่าง',
        qr_code: 'คิวอาร์โค้ด',
        rect: 'สี่เหลี่ยมผืนผ้า',
        shapes: 'องค์ประกอบ',
        text: 'ข้อความ',
        trace: 'ตามเส้นทาง',
      },
      line: 'เส้น',
      rectangle: 'สี่เหลี่ยมผืนผ้า',
      text: 'ข้อความ',
      unpreviewable_area: 'พื้นที่มืด',
    },
    network_testing_panel: {
      average_response: 'เวลาตอบสนองเฉลี่ย',
      cannot_connect_1: '#840 ไม่สามารถเชื่อมต่อกับ IP เป้าหมาย',
      cannot_connect_2: '#840 ไม่สามารถเชื่อมต่อกับ IP เป้าหมาย โปรดตรวจสอบให้แน่ใจว่าเป้าหมายอยู่ในเครือข่ายเดียวกัน',
      cannot_get_local: 'การเข้าถึง IP address ภายในล้มเหลว',
      connection_quality: 'คุณภาพการเชื่อมต่อ',
      device_not_on_list: '#842 เครื่องไม่อยู่ในรายการ แต่คุณภาพการเชื่อมต่อ >70 และเวลาตอบสนองเฉลี่ย <100 มิลลิวินาที',
      empty_ip: '#818 โปรดป้อนที่อยู่ IP อุปกรณ์เป้าหมายก่อน',
      end: 'สิ้นสุด',
      fail_to_start_network_test: '#817 ล้มเหลวในการเริ่มต้นทดสอบเครือข่าย',
      hint_connect_camera_timeout: 'เกิดการหมดเวลาเมื่อเริ่มต้นดูตัวอย่างกล้อง?',
      hint_connect_failed_when_sending_job: 'ล้มเหลวในการเชื่อมต่อเมื่อส่งงาน?',
      hint_device_often_on_list: 'เครื่องไม่พบในรายการบ่อยครั้ง?',
      insert_ip: 'ที่อยู่ IP อุปกรณ์เป้าหมาย:',
      invalid_ip: '#818 ที่อยู่ IP ไม่ถูกต้อง',
      ip_startswith_169: '#843 ที่อยู่ IP เครื่องเริ่มต้นด้วย 169.254',
      link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
      link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
      link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
      linux_permission_hint: 'ข้อผิดพลาดนี้เกิดขึ้นจากสิทธิ์ที่ไม่เพียงพอ\nโปรดเรียกใช้ "sudo beam-studio --no-sandbox" ใน terminal เพื่อให้ได้สิทธิ์และทดสอบเครือข่าย',
      local_ip: 'ที่อยู่ IP ท้องถิ่น:',
      network_testing: 'การทดสอบเครือข่าย',
      network_unhealthy: '#841 คุณภาพการเชื่อมต่อ <70 หรือเวลาตอบสนองเฉลี่ย >100 มิลลิวินาที',
      start: 'เริ่ม',
      test_completed: 'ทดสอบเสร็จสิ้น',
      test_fail: 'ทดสอบล้มเหลว',
      testing: 'กําลังทดสอบเครือข่าย...',
    },
    object_panels: {
      lock_desc: 'รักษาสัดส่วนความกว้างและความสูง (SHIFT)',
      text_to_path: {
        check_thumbnail_warning: 'บางข้อความถูกเปลี่ยนเป็นฟอนต์อื่นเมื่อแปลงข้อความเป็นเส้นทาง และบางอักขระอาจไม่ได้แปลงไปตามปกติ\nกรุณาตรวจสอบภาพตัวอย่างอีกครั้งก่อนส่งงาน',
        error_when_parsing_text: 'ข้อผิดพลาดเมื่อแปลงข้อความเป็นเส้นทาง',
        font_substitute_pop: 'ข้อความของคุณมีอักขระที่แบบอักษรปัจจุบันไม่รองรับ <br/>คุณต้องการใช้ <strong>%s</strong> แทนไหม?',
        retry: 'โปรดลองใหม่ในภายหลังหรือเลือกแบบอักษรอื่น',
        use_current_font: 'ใช้ฟอนต์ปัจจุบัน',
      },
      wait_for_parsing_font: 'กําลังแปลงฟอนต์... กรุณารอสักครู่',
    },
    path_preview: {
      current_position: 'ตําแหน่งปัจจุบัน',
      cut_distance: 'ระยะทางตัด',
      cut_time: 'เวลาตัด',
      end_preview: 'สิ้นสุดการแสดงตัวอย่าง',
      estimated_time: 'ประมาณการเวลาทั้งหมด',
      invert: 'กลับด้าน',
      pause: 'หยุดชั่วคราว',
      play: 'เล่น',
      play_speed: 'ความเร็วในการเล่น',
      preview_info: 'ข้อมูลแสดงตัวอย่าง',
      rapid_distance: 'ระยะทางเดินทาง',
      rapid_time: 'เวลาเดินทาง',
      remark: '* ข้อมูลทั้งหมดเป็นค่าประมาณการ',
      size: 'ขนาด',
      start_here: 'เริ่มต้นที่นี่',
      stop: 'หยุด',
      travel_path: 'เส้นทางเดินทาง',
    },
    photo_edit_panel: {
      aspect_ratio: 'อัตราส่วน',
      brightness: 'ความสว่าง',
      brightness_and_contrast: 'ความสว่าง / ความคมชัด',
      cancel: 'ยกเลิก',
      circumference: 'เส้นรอบวง',
      compare: 'เปรียบเทียบ',
      contrast: 'ความคมชัด',
      crop: 'ครอบตัด',
      curve: 'เส้นโค้ง',
      diameter: 'เส้นผ่านศูนย์กลาง',
      free: 'อิสระ',
      invert: 'กลับสี',
      okay: 'ตกลง',
      original: 'ต้นฉบับ',
      phote_edit: 'แก้ไขรูปภาพ',
      processing: 'กําลังประมวลผล',
      radius: 'รัศมี',
      rotary_warped: 'บิดเบี้ยวแบบหมุน',
      rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
      sharpen: 'คมชัด',
      sharpness: 'ระดับความคมชัด',
      start: 'เริ่ม',
      warp: 'บิดเบี้ยว',
    },
    popup: {
      ai_credit: {
        buy_link: 'https://member.flux3dp.com/en-US/credit',
        go: 'ไป',
        insufficient_credit: 'คุณหมดเครดิตแล้ว',
        insufficient_credit_msg: 'คุณไม่สามารถใช้ %s ได้ ไปที่ศูนย์สมาชิกและซื้อเครดิต AI',
        relogin_to_use: 'โปรดเข้าสู่ระบบใหม่เพื่อใช้ฟังก์ชันนี้',
      },
      auto_feeder_origin: 'ใช้การแสดงตัวอย่างกรอบเพื่อตรวจสอบเส้นทางและพื้นที่ของหัวเลเซอร์ จากนั้นปรับตำแหน่งวัสดุหรือจุดเริ่มต้นของการแกะสลักตามผลลัพธ์เพื่อหลีกเลี่ยงการชนกันระหว่างกระบวนการแกะสลัก',
      auto_switch_tab: {
        message: 'มีการเพิ่มตัวเลือกใหม่ในการสลับระหว่างแผงเลเยอร์และแผงวัตถุโดยอัตโนมัติในการตั้งค่า ตัวเลือกนี้ปิดใช้งานโดยค่าเริ่มต้น คุณต้องการเปิดการสลับอัตโนมัติในตอนนี้หรือไม่? <br/>คุณสามารถเปลี่ยนการตั้งค่านี้ได้ทุกเมื่อในการตั้งค่า',
        title: 'สลับอัตโนมัติระหว่างแผงชั้นและวัตถุ',
      },
      backend_connect_failed_ask_to_upload: '#802 เกิดข้อผิดพลาดขณะเชื่อมต่อกับแบ็คเอนด์ คุณต้องการอัปโหลดรายงานข้อบกพร่องหรือไม่',
      backend_error_hint: 'คุณสมบัติอาจทํางานไม่ถูกต้องเนื่องจากข้อผิดพลาดของแบ็คเอนด์',
      both_power_and_speed_too_high: 'การใช้พลังงานเลเซอร์ต่ําลงจะช่วยยืดอายุการใช้งานของหลอดเลเซอร์\nนอกจากนี้ ความเร็วที่สูงเกินไปที่ความละเอียดนี้ อาจทําให้คุณภาพการกัดลายเงาแย่ลง',
      bug_report: 'รายงานข้อบกพร่อง',
      change_workarea_before_preview: 'พื้นที่ทํางานไม่ตรงกับที่ตั้งไว้ คุณต้องการสลับเปลี่ยนหรือไม่',
      convert_to_path_fail: 'การแปลงเป็นเส้นทางล้มเหลว',
      dxf_bounding_box_size_over: 'ขนาดการวาดเกินพื้นที่ทํางาน โปรดย้ายการวาดให้อยู่ในบริเวณจุดกําเนิดในโปรแกรม CAD หรือตรวจสอบหน่วยให้ถูกต้อง',
      dxf_version_waring: 'เวอร์ชันของไฟล์ DXF นี้ไม่ใช่ 2013 อาจมีปัญหาด้านความเข้ากันได้ที่อาจเกิดขึ้น',
      facebook_group_invitation: {
        already_joined: 'เข้าร่วมแล้ว',
        join_now: 'เข้าร่วม',
        later: 'ทีหลัง',
        message: 'เชื่อมต่อกับผู้ใช้เลเซอร์ FLUX อื่นๆ แชร์งานศิลปะ และรับข่าวสารผลิตภัณฑ์ใหม่ เรารอต้อนรับคุณ',
        title: 'เข้าร่วมกลุ่มผู้ใช้ FLUX',
      },
      import_file_contain_invalid_path: '#808 ไฟล์ SVG ที่นําเข้ามีเส้นทางรูปภาพไม่ถูกต้อง โปรดตรวจสอบให้แน่ใจว่าไฟล์รูปภาพทั้งหมดมีอยู่หรือฝังรูปภาพในไฟล์',
      import_file_error_ask_for_upload: 'ล้มเหลวในการนําเข้าไฟล์ SVG คุณต้องการส่งไฟล์ให้ทีมพัฒนาเพื่อรายงานข้อผิดพลาดหรือไม่',
      layer_by_color: 'สี',
      layer_by_layer: 'ชั้น',
      loading_image: 'กําลังโหลดรูปภาพ โปรดรอสักครู่...',
      more_than_two_object: 'วัตถุมากเกินไป รองรับเพียง 2 วัตถุ',
      mouse: 'เมาส์',
      no_support_text: 'Beam Studio ไม่รองรับข้อความในขณะนี้ โปรดแปลงข้อความเป็นเส้นทางก่อนนําเข้า',
      nolayer: 'ชั้นเดียว',
      not_support_object_type: 'ไม่รองรับประเภทวัตถุ',
      or_turn_off_borderless_mode: ' หรือปิดโหมด Open Bottom',
      pdf2svg: {
        error_pdf2svg_not_found: '#825 ไม่พบคําสั่ง pdf2svg โปรดติดตั้ง pdf2svg',
        error_when_converting_pdf: '#824 เกิดข้อผิดพลาดขณะแปลง PDF เป็น SVG:',
      },
      progress: {
        calculating: 'กําลังคํานวณ',
        uploading: 'อัปโหลดกําลังดําเนินการ',
      },
      questionnaire: {
        caption: 'ช่วยเราตอบแบบสอบถาม',
        message: 'ช่วยตอบแบบสอบถามเพื่อปรับปรุง Beam Studio',
        no_questionnaire_available: 'ไม่มีแบบสอบถามพร้อมใช้งานในขณะนี้',
        unable_to_get_url: 'ไม่สามารถเชื่อมต่ออินเทอร์เน็ตเพื่อรับแบบสอบถาม โปรดตรวจสอบการเชื่อมต่อ',
      },
      recommend_downgrade_software: 'เราตรวจพบเวอร์ชันเฟิร์มแวร์เก่า เรากําลังแก้ไขปัญหาด้านความเข้ากันได้อย่างแข็งขัน แต่ในตอนนี้ เราแนะนําให้กลับไปใช้ <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>',
      recommend_upgrade_firmware: 'เราตรวจพบรุ่นเฟิร์มแวร์เก่า เรากําลังแก้ไขปัญหาความเข้ากันได้อย่างเร่งด่วน แต่ในตอนนี้เราแนะนําให้อัปเดตเป็นรุ่นล่าสุด',
      save_unsave_changed: 'คุณต้องการบันทึกการเปลี่ยนแปลงที่ยังไม่ได้บันทึกหรือไม่',
      select_at_least_two: 'เลือกสองวัตถุเพื่อดําเนินการต่อ',
      select_first: 'เลือกวัตถุก่อน',
      select_import_method: 'เลือกวิธีการวาง:',
      select_import_module: 'เลือกโมดูล:',
      sentry: {
        message: 'คุณตกลงอัปโหลดข้อมูลเมื่อเกิดข้อผิดพลาดไปยังทีมพัฒนาหรือไม่',
        title: 'ช่วยปรับปรุง Beam Studio',
      },
      should_update_firmware_to_continue: '#814 เฟิร์มแวร์ของคุณไม่รองรับเวอร์ชัน Beam Studio นี้ โปรดอัปเดตเฟิร์มแวร์เพื่อดําเนินการต่อ (เมนู > เครื่อง > [เครื่องของคุณ] > อัปเดตเฟิร์มแวร์)',
      speed_too_high_lower_the_quality: 'การใช้ความเร็วสูงเกินไปที่ความละเอียดนี้ อาจทําให้คุณภาพการกัดลายเงาแย่ลง',
      still_continue: 'ดําเนินการต่อ',
      successfully_uploaded: 'อัปโหลดไฟล์สําเร็จ',
      svg_1_1_waring: 'เวอร์ชันของไฟล์ SVG นี้คือ v 1.1 อาจมีปัญหาด้านความเข้ากันได้ที่อาจเกิดขึ้น',
      svg_image_path_waring: 'ไฟล์ SVG นี้มีการโหลด <image> จากเส้นทางไฟล์ ซึ่งอาจทําให้เกิดความล้มเหลวเมื่อโหลด\nเพื่อหลีกเลี่ยงความเสี่ยงนี้ โปรดใช้ภาพฝังเมื่อส่งออก SVG',
      text_to_path: {
        caption: 'โปรแกรมแปลงข้อความเป็นเส้นทาง 2.0',
        message: "Beam Studio แนะนําโปรแกรมแปลงข้อความเป็นเส้นทางใหม่ (โปรแกรมแปลง 2.0) ซึ่งให้ผลลัพธ์ที่เชื่อถือได้มากขึ้น! คุณต้องการสลับไปใช้โปรแกรมแปลงนี้เลยหรือไม่?\nคุณยังสามารถปรับการตั้งค่านี้ในภายหลังได้ในส่วน 'โปรแกรมแปลงข้อความเป็นเส้นทาง' ภายในการตั้งค่า",
      },
      too_fast_for_auto_feeder: 'การใช้ความเร็วที่สูงเกินไปในชั้นที่มีวัตถุเส้นทางอาจทำให้ความแม่นยำในการตัดลดลง\nเราไม่แนะนำให้ใช้ความเร็วที่สูงกว่า %(limit)s เมื่อทำการตัดด้วย Auto Feeder',
      too_fast_for_auto_feeder_and_constrain: 'ชั้นต่อไปนี้: %(layers)s \nมีวัตถุเส้นทางเวกเตอร์และมีความเร็วที่เกิน %(limit)s\nความเร็วในการตัดของวัตถุเส้นทางเวกเตอร์กับ Auto Feeder จะถูกจำกัดไว้ที่ %(limit)s\nคุณสามารถลบขีดจำกัดนี้ได้ในการตั้งค่าความชอบ',
      too_fast_for_curve: 'การใช้ความเร็วที่สูงเกินไปสำหรับเลเยอร์ที่มีวัตถุแกะสลักเส้นโค้งอาจลดความแม่นยำในการแกะสลักได้\nแนะนำให้รักษาความเร็วต่ำกว่าค่า %(limit)s เพื่อให้ได้ผลลัพธ์ที่ดีที่สุด',
      too_fast_for_curve_and_constrain: 'เลเยอร์ต่อไปนี้: %(layers)s เกินขีดจำกัดความเร็วที่กำหนดไว้ที่ %(limit)s\nความเร็วในการแกะสลักสำหรับวัตถุเส้นโค้งจะถูกจำกัดที่ %(limit)s คุณสามารถลบข้อจำกัดนี้ได้ในการตั้งค่าค่ากำหนด',
      too_fast_for_path: 'การใช้ความเร็วสูงเกินไปในชั้นที่มีวัตถุเส้นทาง อาจทําให้ความแม่นยําในการตัดลดลง\nเราไม่แนะนําให้ใช้ความเร็วเกิน %(limit)s เมื่อตัด',
      too_fast_for_path_and_constrain: 'ชั้นต่อไปนี้: %(layers)s \nมีวัตถุเส้นทางเวกเตอร์ และมีความเร็วเกิน %(limit)s\nความเร็วในการตัดวัตถุเส้นทางเวกเตอร์จะถูกจํากัดไว้ที่ %(limit)s\nคุณสามารถนําขีด จํากัดนี้ออกได้ที่การตั้งค่า',
      touchpad: 'ทัชแพด',
      ungroup_use: 'การยกเลิกกลุ่มอาจใช้เวลาสักครู่ คุณแน่ใจหรือไม่ว่าต้องการดําเนินการต่อ',
      upload_failed: '#819 การอัปโหลดไฟล์ล้มเหลว',
      upload_file_too_large: '#819 ไฟล์มีขนาดใหญ่เกินไปสําหรับการอัปโหลด',
      vectorize_shading_image: 'ภาพเกรเดียนต์ใช้เวลาในการติดตามมากขึ้นและมีโอกาสเกิดสัญญาณรบกวนสูง โปรดปิดเกรเดียนต์ของภาพก่อนดําเนินการ',
    },
    rating_panel: {
      description: 'ถ้าคุณชอบ Beam Studio เราจะขอบคุณอย่างยิ่งหากคุณสามารถให้คะแนนเรา',
      thank_you: 'ขอบคุณ!',
      title: 'ชอบ Beam Studio?',
    },
    right_panel: {
      laser_panel: {
        advanced: 'ขั้นสูง',
        backlash: 'การย้อนกลับ',
        bi_directional: 'สองทิศทาง',
        by: 'ตามค่า',
        ce_z_high_speed: 'แกน Z ความเร็วสูง',
        ce_z_high_speed_link: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
        color_adjustment: 'การปรับสี',
        color_adjustment_short: 'ช่องสี',
        color_strength: 'ความเข้ม',
        cross_hatch: 'การฟักไข่ข้าม',
        custom_preset: 'กําหนดเอง',
        cut: 'ตัด',
        diode: 'เลเซอร์ไดโอด',
        do_not_adjust_default_para: 'ไม่สามารถปรับแต่งค่าเริ่มต้น',
        dottingTime: 'เวลาแต้มจุด',
        dropdown: {
          inches: {
            abs_engraving: 'ABS - กัดขูด',
            acrylic_3mm_cutting: 'อะคริลิค - 1/8″ ตัด',
            acrylic_5mm_cutting: 'อะคริลิค - 3/16″ ตัด',
            acrylic_8mm_cutting: 'อะคริลิค - 1/4″ ตัด',
            acrylic_10mm_cutting: 'อะคริลิค - 3/8″ ตัด',
            acrylic_engraving: 'อะคริลิค - กัดขูด',
            acrylic_printing: 'อะคริลิก - การพิมพ์',
            aluminum_engraving: 'อะลูมิเนียม - กัดขูด',
            aluminum_light: 'อลูมิเนียม (สว่าง)',
            bamboo_printing: 'ไผ่ - การพิมพ์',
            black_abs: 'ABS สีดำ',
            black_acrylic_3mm_cutting: 'อะคริลิกดํา - 1/8″ ตัด',
            black_acrylic_5mm_cutting: 'อะคริลิกดํา - 3/16″ ตัด',
            black_acrylic_engraving: 'อะคริลิกดํา - กัดขูด',
            brass_dark: 'ทองเหลือง (เข้ม)',
            brass_engraving: 'ทองแดง - กัดขูด',
            brass_light: 'ทองเหลือง (สว่าง)',
            canvas_printing: 'ผ้าใบ - การพิมพ์',
            cardstock_printing: 'กระดาษปอนด์ - การพิมพ์',
            copper: 'ทองแดง',
            cork_printing: 'ยาง - การพิมพ์',
            denim_1mm_cutting: 'ยีนส์ - 1/32″ ตัด',
            fabric_3mm_cutting: 'ผ้า - 1/8″ ตัด',
            fabric_5mm_cutting: 'ผ้า - 3/16″ ตัด',
            fabric_engraving: 'ผ้า - กัดขูด',
            fabric_printing: 'ผ้า - การพิมพ์',
            flat_stone_printing: 'หินแบน - การพิมพ์',
            glass_bw_engraving: 'แก้ว - กัดขูด',
            glass_printing: 'แก้ว - การพิมพ์',
            gloss_leather_printing: 'หนังเรียบเงา - การพิมพ์',
            gold_engraving: 'ทองคํา - กัดขูด',
            iron_engraving: 'เหล็ก - กัดขูด',
            leather_3mm_cutting: 'หนัง - 1/8″ ตัด',
            leather_5mm_cutting: 'หนัง - 3/16″ ตัด',
            leather_engraving: 'หนัง - กัดขูด',
            mdf_3mm_cutting: 'ไม้อัด - 1/8″ ตัด',
            mdf_5mm_cutting: 'ไม้อัด - 3/16″ ตัด',
            mdf_engraving: 'ไม้อัด - กัดขูด',
            metal_bw_engraving: 'โลหะ - กัดขูด',
            opaque_acrylic: 'อะคริลิคทึบแสง',
            pc_printing: 'PC - การพิมพ์',
            rubber_bw_engraving: 'ยาง - กัดขูด',
            silver_engraving: 'เงิน - กัดขูด',
            stainless_steel_bw_engraving_diode: 'โลหะ - กัดขูด (เลเซอร์ไดโอด)',
            stainless_steel_dark: 'สแตนเลส (เข้ม)',
            stainless_steel_engraving: 'สแตนเลส - กัดขูด',
            stainless_steel_light: 'สแตนเลส (สว่าง)',
            stainless_steel_printing: 'สแตนเลสสตีล - การพิมพ์',
            steel_engraving_spray_engraving: 'โลหะ - กัดขูด',
            stone: 'หิน',
            ti_engraving: 'ไทเทเนียม - กัดขูด',
            titanium_dark: 'ไทเทเนียม (เข้ม)',
            titanium_light: 'ไทเทเนียม (สว่าง)',
            white_abs: 'ABS สีขาว',
            wood_3mm_cutting: 'ไม้ - 1/8″ ตัด',
            wood_5mm_cutting: 'ไม้ - 3/16″ ตัด',
            wood_7mm_cutting: 'ไม้ - 1/4″ ตัด',
            wood_8mm_cutting: 'ไม้ - 1/4″ ตัด',
            wood_10mm_cutting: 'ไม้ - 3/8″ ตัด',
            wood_engraving: 'ไม้ - กัดขูด',
            wood_printing: 'ไม้ - การพิมพ์',
          },
          mm: {
            abs_engraving: 'ABS - กัดขูด',
            acrylic_3mm_cutting: 'อะคริลิค - 3 มม. ตัด',
            acrylic_5mm_cutting: 'อะคริลิค - 5 มม. ตัด',
            acrylic_8mm_cutting: 'อะคริลิค - 8 มม. ตัด',
            acrylic_10mm_cutting: 'อะคริลิค - 10 มม. ตัด',
            acrylic_engraving: 'อะคริลิค - กัดขูด',
            acrylic_printing: 'อะคริลิก - พิมพ์',
            aluminum_engraving: 'อะลูมิเนียม - กัดขูด',
            aluminum_light: 'อลูมิเนียม (สว่าง)',
            bamboo_printing: 'ไผ่ - พิมพ์',
            black_abs: 'ABS สีดำ',
            black_acrylic_3mm_cutting: 'อะคริลิคดํา - 3 มม. ตัด',
            black_acrylic_5mm_cutting: 'อะคริลิคดํา - 5 มม. ตัด',
            black_acrylic_engraving: 'อะคริลิคดํา - กัดขูด',
            brass_dark: 'ทองเหลือง (เข้ม)',
            brass_engraving: 'ทองแดง - กัดขูด',
            brass_light: 'ทองเหลือง (สว่าง)',
            canvas_printing: 'ผ้าใบ - พิมพ์',
            cardstock_printing: 'กระดาษอาร์ต - พิมพ์',
            copper: 'ทองแดง',
            cork_printing: 'คอร์ก - พิมพ์',
            denim_1mm_cutting: 'ยีนส์ - 1 มม. ตัด',
            fabric_3mm_cutting: 'ผ้า - 3 มม. ตัด',
            fabric_5mm_cutting: 'ผ้า - 5 มม. ตัด',
            fabric_engraving: 'ผ้า - กัดขูด',
            fabric_printing: 'ผ้า - พิมพ์',
            flat_stone_printing: 'หินแบน - พิมพ์',
            glass_bw_engraving: 'แก้ว - กัดขูด',
            glass_printing: 'แก้ว - พิมพ์',
            gloss_leather_printing: 'หนังเรียบเงา - พิมพ์',
            gold_engraving: 'ทองคํา - กัดขูด',
            iron_engraving: 'เหล็ก - กัดขูด',
            leather_3mm_cutting: 'หนัง - 3 มม. ตัด',
            leather_5mm_cutting: 'หนัง - 5 มม. ตัด',
            leather_engraving: 'หนัง - กัดขูด',
            mdf_3mm_cutting: 'ไม้อัด - 3 มม. ตัด',
            mdf_5mm_cutting: 'ไม้อัด - 5 มม. ตัด',
            mdf_engraving: 'ไม้อัด - กัดขูด',
            metal_bw_engraving: 'โลหะ - กัดขูด',
            opaque_acrylic: 'อะคริลิคทึบแสง',
            pc_printing: 'พลาสติก - พิมพ์',
            rubber_bw_engraving: 'ยาง - กัดขูด',
            silver_engraving: 'เงิน - กัดขูด',
            stainless_steel_bw_engraving_diode: 'โลหะ - กัดขูด (เลเซอร์ไดโอด)',
            stainless_steel_dark: 'สแตนเลส (เข้ม)',
            stainless_steel_engraving: 'สแตนเลส - กัดขูด',
            stainless_steel_light: 'สแตนเลส (สว่าง)',
            stainless_steel_printing: 'สแตนเลส - พิมพ์',
            steel_engraving_spray_engraving: 'โลหะ - กัดขูด',
            stone: 'หิน',
            ti_engraving: 'ไทเทเนียม - กัดขูด',
            titanium_dark: 'ไทเทเนียม (เข้ม)',
            titanium_light: 'ไทเทเนียม (สว่าง)',
            white_abs: 'ABS สีขาว',
            wood_3mm_cutting: 'ไม้ - 3 มม. ตัด',
            wood_5mm_cutting: 'ไม้ - 5 มม. ตัด',
            wood_7mm_cutting: 'ไม้ - 7 มม. ตัด',
            wood_8mm_cutting: 'ไม้ - 8 มม. ตัด',
            wood_10mm_cutting: 'ไม้ - 10 มม. ตัด',
            wood_engraving: 'ไม้ - กัดขูด',
            wood_printing: 'ไม้ - พิมพ์',
          },
          parameters: 'การตั้งค่าล่วงหน้า',
          save: 'เพิ่มพารามิเตอร์ปัจจุบัน',
        },
        engrave: 'กัดสลัก',
        existing_name: 'มีชื่อนี้อยู่แล้ว',
        fill_angle: 'มุมเติมเต็ม',
        fill_interval: 'ช่วงเติมเต็ม',
        fill_setting: 'การตั้งค่าการเติม',
        filled_path_only: 'ใช้ได้เฉพาะเส้นทางเติมสี',
        focus_adjustment: 'การปรับโฟกัส',
        frequency: 'ความถี่',
        gradient_only: 'ใช้ได้เฉพาะภาพไล่สี',
        halftone: 'ฮาล์ฟทอน',
        halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
        height: 'ความสูงของวัตถุ',
        ink_saturation: 'ความอิ่มตัวสี',
        ink_type: {
          normal: 'หมึก',
          text: 'ชนิดหมึก',
          UV: 'หมึก UV',
        },
        laser_speed: {
          fast: 'เร็ว',
          max: 300,
          min: 3,
          slow: 'ช้า',
          step: 0.1,
          text: 'ความเร็ว',
          unit: 'มม./วินาที',
        },
        low_power_warning: 'กําลังเลเซอร์ต่ํา (ต่ํากว่า 10%) อาจไม่ส่งแสงเลเซอร์ออกมา',
        low_speed_warning: 'ความเร็วต่ําอาจทําให้วัสดุไหม้',
        lower_focus: 'ลดระยะโฟกัส',
        lower_focus_desc: 'ลดความสูงของโฟกัสตามระยะทางที่กำหนดหลังจากโฟกัสเพื่อปรับปรุงประสิทธิภาพการตัด',
        module: 'โหมด',
        more: 'จัดการ',
        multi_layer: 'หลายเลเยอร์',
        para_in_use: 'กําลังใช้พารามิเตอร์นี้อยู่',
        parameters: 'พารามิเตอร์',
        power: {
          high: 'สูง',
          low: 'ต่ํา',
          max: 100,
          min: 1,
          step: 0.1,
          text: 'กําลัง',
        },
        preset_management: {
          add_new: 'เพิ่มใหม่',
          delete: 'ลบ',
          export: 'ส่งออก',
          export_preset_title: 'ส่งออกการตั้งค่าล่วงหน้า',
          import: 'นําเข้า',
          laser: 'เลเซอร์',
          lower_focus_by: 'ลดโฟกัสลงโดย',
          new_preset_name: 'ชื่อการตั้งค่าล่วงหน้าใหม่',
          preset: 'การตั้งค่าเริ่มต้น',
          print: 'พิมพ์',
          reset: 'รีเซ็ต',
          save_and_exit: 'บันทึกและออก',
          show_all: 'แสดงทั้งหมด',
          sure_to_import_presets: 'สิ่งนี้จะโหลดการจัดเรียงการตั้งค่าล่วงหน้าและเขียนทับพารามิเตอร์ที่กำหนดเอง คุณแน่ใจหรือไม่ว่าต้องการดำเนินการต่อ?',
          sure_to_reset: 'สิ่งนี้จะลบพารามิเตอร์ที่คุณกำหนดเองและรีเซ็ตการตั้งค่าล่วงหน้าทั้งหมด คุณแน่ใจหรือไม่ว่าต้องการดำเนินการต่อ?',
          title: 'จัดการพารามิเตอร์',
          wobble_diameter: 'เส้นผ่านศูนย์กลางการสั่น',
          wobble_step: 'ขนาดก้าวการสั่น',
        },
        preset_setting: 'การตั้งค่าพารามิเตอร์ (%s)',
        presets: 'ค่าที่ตั้งไว้',
        print_multipass: 'พิมพ์หลายชั้น',
        promark_speed_desc: 'พารามิเตอร์ความเร็วไม่สามารถใช้ได้กับภาพไล่เฉดสี',
        pulse_width: 'ความกว้างของพัลส์',
        pwm_advanced_desc: 'ตั้งค่ากำลังไฟขั้นต่ำสำหรับโหมดความลึก',
        pwm_advanced_hint: 'การตั้งค่านี้ใช้กับภาพไล่สีโดยเฉพาะเมื่อใช้โหมดความลึก',
        pwm_advanced_setting: 'การตั้งค่าพลังงานโหมดความลึก',
        repeat: 'จำนวนรอบ',
        single_color: 'สีเดียว',
        single_color_desc: 'ใช้ได้เฉพาะกับเลเยอร์สีเต็มและไม่สามารถใช้กับเลเยอร์สีเดียวที่ขยายออกได้',
        slider: {
          fast: 'เร็ว',
          high: 'สูง',
          low: 'ต่ํา',
          regular: 'ปกติ',
          slow: 'ช้า',
          very_fast: 'เร็วมาก',
          very_high: 'สูงมาก',
          very_low: 'ต่ํามาก',
          very_slow: 'ช้ามาก',
        },
        speed: 'ความเร็ว',
        speed_constrain_warning: 'ความเร็วการตัดของวัตถุเส้นทางเวกเตอร์จะถูกจํากัดไว้ที่ %(limit)s คุณสามารถลบขีดจํากัดนี้ออกได้ที่การตั้งค่าการตั้งค่า',
        speed_constrain_warning_auto_feeder: 'ความเร็วการตัดของวัตถุ Auto Feeder จะถูกจำกัดที่ %(limit)s คุณสามารถลบข้อจำกัดนี้ในการตั้งค่าความชอบ',
        speed_constrain_warning_curve_engraving: 'ความเร็วในการแกะสลักแบบโค้งจะถูกจำกัดไว้ที่ %(limit)s คุณสามารถลบข้อจำกัดนี้ได้ที่การตั้งค่าค่ากำหนด',
        stepwise_focusing: 'โฟกัสทีละขั้น',
        stepwise_focusing_desc: 'ลดระยะโฟกัสลงเรื่อยๆ ตามความสูงของวัตถุในแต่ละจำนวนรอบ',
        strength: 'กําลัง',
        times: 'ครั้ง',
        various_preset: 'การตั้งค่าล่วงหน้าหลากหลาย',
        white_ink: 'หมึกขาว',
        white_ink_settings: 'การตั้งค่าหมึกขาว',
        wobble: 'แกว่ง',
        wobble_desc: 'สร้างวงกลมเล็ก ๆ ตามเส้นทาง ซึ่งมีประโยชน์สำหรับงานตัด (เฉพาะสำหรับวัตถุเส้นทางเท่านั้น)',
        wobble_diameter: 'เส้นผ่านศูนย์กลาง',
        wobble_step: 'ขนาดขั้น',
        z_step: 'ขั้นตอน Z',
      },
      layer_panel: {
        current_layer: 'ชั้นปัจจุบัน',
        layer1: 'ชั้น 1',
        layer_bitmap: 'รูปบิตแมป',
        layer_cutting: 'ตัด',
        layer_engraving: 'การแกะสลัก',
        layers: {
          del: 'ลบชั้น',
          dupe: 'ทําสําเนาชั้น',
          fullColor: 'สีเต็มรูปแบบ',
          layer: 'ชั้น',
          layers: 'ชั้น',
          lock: 'ล็อคชั้น',
          merge_all: 'รวมทั้งหมด',
          merge_down: 'รวมลง',
          merge_selected: 'รวมเลเยอร์ที่เลือก',
          move_down: 'ย้ายชั้นลง',
          move_elems_to: 'ย้ายองค์ประกอบไปยัง:',
          move_selected: 'ย้ายองค์ประกอบที่เลือกไปยังเลเยอร์อื่น',
          move_up: 'ย้ายชั้นขึ้น',
          new: 'ชั้นใหม่',
          rename: 'เปลี่ยนชื่อชั้น',
          splitFullColor: 'แยกชั้นสี',
          switchToFullColor: 'สลับไปใช้ชั้นสีเต็มรูปแบบ',
          switchToSingleColor: 'สลับไปใช้ชั้นสีเดียว',
          unlock: 'ปลดล็อค',
        },
        move_elems_to: 'ย้ายองค์ประกอบไปยัง:',
        notification: {
          dupeLayerName: 'มีชื่อชั้นนี้อยู่แล้ว!',
          enterNewLayerName: 'โปรดป้อนชื่อชั้นใหม่',
          enterUniqueLayerName: 'โปรดป้อนชื่อชั้นใหม่',
          layerHasThatName: 'ชั้นมีชื่อนี้อยู่แล้ว',
          mergeLaserLayerToPrintingLayerMsg: 'โปรดทราบว่าหากดําเนินการต่อ การตั้งค่าของชั้นเลเซอร์จะถูกลบออกและตั้งค่าใหม่ตามชั้นปัจจุบัน',
          mergeLaserLayerToPrintingLayerTitle: 'คุณต้องการรวมชั้นเหล่านี้เข้าด้วยกันเป็นชั้นสําหรับพิมพ์หรือไม่?',
          mergePrintingLayerToLaserLayerMsg: 'โปรดทราบว่าหากดําเนินการต่อ การตั้งค่าสีของชั้นสําหรับพิมพ์จะถูกลบออกและตั้งค่าใหม่ตามชั้นปัจจุบัน',
          mergePrintingLayerToLaserLayerTitle: 'คุณต้องการรวมชั้นเหล่านี้เข้าด้วยกันเป็นชั้นเลเซอร์หรือไม่?',
          moveElemFromPrintingLayerMsg: 'โปรดทราบว่าหากดําเนินการต่อ การตั้งค่าสีขององค์ประกอบที่เลือกจะถูกลบออกและตั้งค่าใหม่ตาม %s',
          moveElemFromPrintingLayerTitle: 'ย้ายองค์ประกอบที่เลือกไปยัง %s และแปลงเป็นส่วนสําหรับพิมพ์?',
          moveElemToPrintingLayerMsg: 'โปรดทราบว่าหากดําเนินการต่อ การตั้งค่าขององค์ประกอบที่เลือกจะถูกลบออกและตั้งค่าใหม่ตาม %s',
          moveElemToPrintingLayerTitle: 'ย้ายองค์ประกอบที่เลือกไปยัง %s และแปลงเป็นองค์ประกอบสําหรับพิมพ์?',
          newName: 'ชื่อใหม่',
          QmoveElemsToLayer: "ย้ายองค์ประกอบที่เลือกไปยังชั้น '%s'?",
          splitColorMsg: 'โปรดทราบว่าหากดําเนินการต่อ คุณจะไม่สามารถกลับไปใช้ชั้นสีเดิมได้',
          splitColorTitle: 'คุณต้องการขยายชั้นที่เลือกเป็นชั้น CMYK หรือไม่?',
        },
      },
      object_panel: {
        actions_panel: {
          ai_bg_removal: 'ลบพื้นหลัง',
          ai_bg_removal_reminder: 'กดปุ่มจะใช้เครดิต 0.2 เลย ต้องการดําเนินการต่อหรือไม่',
          ai_bg_removal_short: 'ลบพื้นหลัง',
          array: 'แถวลําดับ',
          auto_fit: 'ปรับให้พอดีอัตโนมัติ',
          bevel: 'ขอบเขต',
          brightness: 'ความสว่าง',
          convert_to_path: 'แปลงเป็นเส้นทาง',
          create_textpath: 'สร้างข้อความบนเส้นทาง',
          create_textpath_short: 'ข้อความบนเส้นทาง',
          crop: 'ครอบตัด',
          decompose_path: 'แยกส่วน',
          detach_path: 'แยกข้อความออกจากเส้นทาง',
          detach_path_short: 'ถอดประกอบ',
          disassemble_use: 'ถอดประกอบ',
          disassembling: 'กําลังถอดประกอบ...',
          edit_path: 'แก้ไขเส้นทาง',
          fetching_web_font: 'กําลังดึงฟอนต์ออนไลน์...',
          grading: 'ความละเอียด',
          invert: 'กลับด้าน',
          offset: 'ชดเชย',
          outline: 'เส้นกรอบ',
          replace_with: 'แทนที่ด้วย...',
          replace_with_short: 'แทนที่',
          sharpen: 'คมชัด',
          simplify: 'ลดรายละเอียด',
          smart_nest: 'จัดวางอัจฉริยะ',
          trace: 'ติดตาม',
          ungrouping: 'กําลังแยกกลุ่ม...',
          uploading_font_to_machine: 'กําลังอัปโหลดฟอนต์ไปยังเครื่อง...',
          wait_for_parsing_font: 'กําลังวิเคราะห์ฟอนต์... กรุณารอสักครู่',
          weld_text: 'เชื่อมติดข้อความ',
        },
        align: 'จัดแนว',
        boolean: 'บูลีน',
        bottom_align: 'ล่างสุด',
        center_align: 'กึ่งกลาง',
        difference: 'แตกต่าง',
        distribute: 'กระจาย',
        flip: 'พลิก',
        group: 'จัดกลุ่ม',
        hdist: 'กระจายแนวนอน',
        hflip: 'พลิกแนวนอน',
        intersect: 'ตัด',
        left_align: 'ชิดซ้าย',
        lock_aspect: 'ล็อคอัตราส่วน',
        middle_align: 'กึ่งกลางแนวตั้ง',
        option_panel: {
          color: 'สี',
          fill: 'เติมสีใน',
          font_family: 'แบบอักษร',
          font_size: 'ขนาด',
          font_style: 'สไตล์',
          letter_spacing: 'ระยะห่างตัวอักษร',
          line_spacing: 'ระยะบรรทัด',
          path_infill: 'เติมสีในเส้นทาง',
          pwm_engraving: 'โหมดความลึก',
          pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
          rounded_corner: 'มุมมน',
          shading: 'ไล่ระดับสี',
          sides: 'ด้าน',
          start_offset: 'ช่องว่างข้อความ',
          stroke: 'เส้นขอบ',
          stroke_color: 'สีเส้นขอบ',
          stroke_width: 'ความกว้างของเส้น',
          text_infill: 'เติมสีในข้อความ',
          threshold: 'เกณฑ์ความสว่าง',
          threshold_short: 'เกณฑ์',
          vertical_align: 'การจัดแนว',
          vertical_text: 'ข้อความแนวตั้ง',
        },
        path_edit_panel: {
          connect: 'เชื่อมต่อ',
          delete: 'ลบ',
          disconnect: 'ตัดการเชื่อมต่อ',
          node_type: 'ประเภทจุดเชื่อม',
          round: 'มน',
          sharp: 'แหลม',
        },
        right_align: 'ชิดขวา',
        subtract: 'ลบ',
        top_align: 'บนสุด',
        ungroup: 'แยกกลุ่ม',
        union: 'รวม',
        unlock_aspect: 'ปลดล็อคอัตราส่วน',
        vdist: 'กระจายแนวตั้ง',
        vflip: 'พลิกแนวตั้ง',
        zoom: 'ซูม',
      },
      tabs: {
        layers: 'ชั้น',
        objects: 'วัตถุ',
        path_edit: 'แก้ไขเส้นทาง',
      },
    },
    shapes_panel: {
      animals: 'สัตว์',
      arrow: 'ลูกศร',
      basic: 'พื้นฐาน',
      birds: 'นก',
      celebration: 'งานเฉลิมฉลอง',
      circular: 'วงกลม',
      CNY: 'ตรุษจีน',
      corner: 'มุม',
      decor: 'ตกแต่ง',
      easter: 'อีสเตอร์',
      elements: 'องค์ประกอบ',
      environment: 'สิ่งแวดล้อม',
      graphics: 'กราฟิก',
      halloween: 'ฮาโลวีน',
      holidays: 'วันหยุด',
      label: 'ป้ายชื่อ',
      land: 'บก',
      line: 'เส้น',
      nature: 'ธรรมชาติ',
      photo: 'รูปภาพ',
      plants: 'พืช',
      ribbon: 'ริบบิ้น',
      sea: 'ทะเล',
      shape: 'รูปร่าง',
      speech: 'คําพูด',
      text: 'กรอบข้อความ',
      title: 'องค์ประกอบ',
      valentines: 'วาเลนไทน์',
      weather: 'สภาพอากาศ',
      Xmas: 'คริสต์มาส',
    },
    svg_editor: {
      unable_to_fetch_clipboard_img: 'ไม่สามารถดึงรูปภาพจากคลิปบอร์ดได้',
      unnsupported_file_type: 'ประเภทไฟล์ไม่รองรับ โปรดแปลงไฟล์เป็น SVG หรือบิตแมป',
    },
    tag: {
      g: 'กลุ่ม',
      image: 'รูปภาพ',
      text: 'ข้อความ',
      use: 'นําเข้า SVG',
    },
    time_est_button: {
      calculate: 'ประมาณเวลา',
      estimate_time: 'เวลาที่ประมาณ:',
    },
    tool_panels: {
      _nest: {
        end: 'ปิด',
        no_element: 'ไม่มีองค์ประกอบสําหรับจัดเรียง',
        rotations: 'การหมุน',
        spacing: 'ระยะห่าง',
        start_nest: 'จัดเรียง',
        stop_nest: 'หยุด',
      },
      _offset: {
        corner_type: 'มุม',
        direction: 'ทิศทางชดเชย',
        dist: 'ระยะห่างชดเชย',
        fail_message: 'ไม่สามารถชดเชยวัตถุ',
        inward: 'เข้าหา',
        not_support_message: 'องค์ประกอบที่เลือกมีแท็ก SVG ไม่รองรับ: รูปภาพ, กลุ่ม, ข้อความ และวัตถุนําเข้า',
        outward: 'ออกจาก',
        round: 'มน',
        sharp: 'แหลม',
      },
      array_dimension: 'มิติของตาราง',
      array_interval: 'ระยะห่างของตาราง',
      cancel: 'ยกเลิก',
      columns: 'คอลัมน์',
      confirm: 'ยืนยัน',
      dx: 'X',
      dy: 'Y',
      grid_array: 'สร้างตารางแบบกริด',
      nest: 'การจัดเรียงเพื่อเพิ่มประสิทธิภาพ',
      offset: 'ชดเชย',
      rows: 'แถว',
    },
    units: {
      mm: 'มม.',
      walt: 'W',
    },
    zoom_block: {
      fit_to_window: 'พอดีกับหน้าต่าง',
    },
  },
  boxgen: {
    add_option: 'เพิ่มตัวเลือก',
    basic_box: 'กล่องพื้นฐาน',
    beam_radius: 'ช่องว่างสําหรับตัด',
    beam_radius_warning: 'เมื่อขอบหรือรอยต่อของกล่องสั้น ให้ลดรัศมีของลําแสงเลเซอร์เพื่อให้แน่ใจว่ากล่องประกอบได้',
    cancel: 'ยกเลิก',
    coming_soon: 'เร็วๆ นี้',
    continue_import: 'ดําเนินการนําเข้าต่อ',
    control_tooltip: 'เมาส์ซ้ายเพื่อหมุน\nเลื่อนเพื่อซูม\nเมาส์ขวาเพื่อเลื่อน',
    control_tooltip_touch: 'ลากเพื่อหมุน\nหุบเพื่อซูม\nสองนิ้วเพื่อเลื่อน',
    cover: 'ฝาปิด',
    customize: 'ปรับแต่ง',
    depth: 'ความลึก',
    edge: 'ขอบ',
    finger: 'นิ้ว',
    finger_warning: 'ความยาวด้านในของกล่องต้องมีอย่างน้อย 6 มม. (0.24 นิ้ว) เพื่อให้สามารถใช้งานการเชื่อมต่อนิ้วมือได้',
    height: 'ความสูง',
    import: 'นําเข้า',
    inner: 'ภายใน',
    joints: 'รอยต่อ',
    max_dimension_tooltip: 'ความกว้าง/ความสูง/ความลึกสูงสุดคือ %s',
    merge: 'รวม',
    outer: 'ภายนอก',
    reset: 'รีเซ็ต',
    tCount: 'จํานวน T ',
    tCount_tooltip: 'จํานวนช่อง T นั้นจะนับจากด้านสั้น ส่วนปริมาณบนด้านยาวจะคํานวณจากความยาว',
    tDiameter: 'เส้นผ่านศูนย์กลาง T',
    text_label: 'ป้ายชื่อ',
    thickness: 'ความหนา',
    title: 'BOXGEN',
    tLength: 'ความยาว T',
    tSlot: 'ร่อง T ',
    tSlot_warning: 'ความยาวด้านข้างของกล่องต้องมีอย่างน้อย 30 มม. (1.18 นิ้ว) เพื่อให้สามารถใช้งานการเชื่อมต่อร่อง T ได้',
    volume: 'ปริมาตร',
    width: 'ความกว้าง',
    workarea: 'พื้นที่ทํางาน',
    zoom: 'ซูม',
  },
  buttons: {
    back: 'ย้อนกลับ',
    back_to_beam_studio: 'กลับไปที่ Beam Studio',
    done: 'เสร็จสิ้น',
    next: 'ถัดไป',
  },
  calibration: {
    ador_autofocus_focusing_block: 'กดไอคอน "AF" บนหน้าหลักของเครื่องเป็นเวลา 3 วินาที แล้วให้โพรบสัมผัส Focusing block',
    ador_autofocus_material: 'กดไอคอน “AF” บนหน้าหลักของเครื่องเป็นเวลา 3 วินาที แล้วให้โพรบสัมผัสวัสดุอย่างอ่อนโยน',
    align_ilb: 'ตำแหน่ง: จัดตำแหน่งกับจุดแกะสลักด้านในล่างซ้าย',
    align_ilt: 'ตำแหน่ง: จัดตำแหน่งกับจุดแกะสลักด้านในบนซ้าย',
    align_irb: 'ตำแหน่ง: จัดตำแหน่งกับจุดแกะสลักด้านในล่างขวา',
    align_irt: 'ตำแหน่ง: จัดตำแหน่งกับจุดแกะสลักด้านในบนขวา',
    align_olb: 'ตำแหน่ง: จัดตำแหน่งกับจุดแกะสลักด้านนอกล่างซ้าย',
    align_olt: 'ตำแหน่ง: จัดตำแหน่งกับจุดแกะสลักด้านนอกบนซ้าย',
    align_orb: 'ตำแหน่ง: จัดตำแหน่งกับจุดแกะสลักด้านนอกล่างขวา',
    align_ort: 'ตำแหน่ง: จัดตำแหน่งกับจุดแกะสลักด้านนอกบนขวา',
    align_red_cross_cut: 'โปรดจัดตําแหน่งกลางกากบาทสีแดงให้ตรงกับกากบาทตัด',
    align_red_cross_print: 'โปรดจัดตําแหน่งกลางกากบาทสีแดงให้ตรงกับกากบาทพิมพ์',
    analyze_result_fail: 'ล้มเหลวในการวิเคราะห์ภาพที่บันทึก<br/>โปรดตรวจสอบ:<br/>1. ภาพที่บันทึกครอบคลุมกระดาษสีขาวทั้งหมด<br/>2. แท่นวางโฟกัสเหมาะสม',
    ask_for_readjust: 'คุณต้องการข้ามขั้นตอนการกัดขูดและถ่ายรูปเพื่อปรับเทียบเลยหรือไม่?',
    back: 'กลับ',
    calculating_camera_matrix: 'กําลังคํานวณเมทริกซ์กล้อง...',
    calculating_regression_parameters: 'กําลังคํานวณพารามิเตอร์การถดถอย...',
    calibrate_camera_before_calibrate_modules: 'โปรดทําการปรับเทียบกล้องก่อนปรับเทียบโมดูล',
    calibrate_chessboard_success_msg: 'ถ่ายภาพกระดานหมากรุกสำเร็จ คะแนนสำหรับภาพนี้คือ %s (%.2f).',
    calibrate_done: 'ปรับเทียบเสร็จสิ้น ความแม่นยําของกล้องจะดีขึ้นเมื่อโฟกัสอย่างแม่นยํา',
    calibrate_done_diode: 'ปรับเทียบเสร็จสิ้น ค่าชดเชยของโมดูลเลเซอร์ไดโอดได้รับการบันทึกแล้ว',
    calibrating: 'กำลังปรับเทียบ...',
    calibrating_with_device_pictures: 'กำลังปรับแต่งด้วยภาพอุปกรณ์...',
    camera_calibration: 'การปรับเทียบกล้อง',
    camera_parameter_saved_successfully: 'บันทึกพารามิเตอร์ของกล้องสำเร็จ',
    cancel: 'ยกเลิก',
    check_checkpoint_data: 'ข้อมูลจุดตรวจสอบ',
    check_device_pictures: 'ตรวจสอบภาพอุปกรณ์',
    checking_checkpoint: 'กำลังตรวจสอบข้อมูลจุดตรวจสอบ...',
    checking_pictures: 'กำลังตรวจสอบภาพอุปกรณ์...',
    diode_calibration: 'การปรับเทียบโมดูลเลเซอร์',
    do_engraving: 'ทําการกัดขูด',
    download_chessboard_file: 'ดาวน์โหลดไฟล์กระดานหมากรุก',
    downloading_checkpoint: 'กำลังดาวน์โหลดข้อมูลจุดตรวจสอบ...',
    downloading_pictures: 'กําลังดาวน์โหลดรูปภาพ...',
    drawing_calibration_image: 'กําลังวาดภาพสําหรับการปรับเทียบ...',
    dx: 'X',
    dy: 'Y',
    elevate_and_cut: 'ยกและตัด',
    elevate_and_cut_step_1: 'วางชิ้นไม้ขนาด A4 สีสว่างในศูนย์กลางของพื้นที่ทำงานและยกไปยังความสูง 20mm.',
    elevate_and_cut_step_1_prism_lift: 'ใช้ Ador Prism Lift ด้วยความยาวสูงสุด 14 มม. พร้อมกับไม้ที่มีความหนาอย่างน้อย 6 มม. เพื่อยกไม้ให้สูง 20 มม.',
    failed_to_calibrate_camera: '#848 ไม่สามารถปรับเทียบกล้องได้ โปรดติดต่อฝ่ายสนับสนุน FLUX',
    failed_to_calibrate_chessboard: 'ไม่สามารถปรับเทียบด้วยภาพหมากรุกได้',
    failed_to_calibrate_with_pictures: '#848 การปรับแต่งด้วยภาพอุปกรณ์ล้มเหลว',
    failed_to_download_pictures: '#848 ไม่สามารถดาวน์โหลดรูปภาพได้ โปรดติดต่อฝ่ายสนับสนุน FLUX',
    failed_to_move_laser_head: 'ล้มเหลวในการย้ายหัวเลเซอร์.',
    failed_to_parse_checkpoint: 'การวิเคราะห์ข้อมูลจุดตรวจสอบล้มเหลว.',
    failed_to_save_calibration_results: '#849 ไม่สามารถบันทึกผลการปรับเทียบได้ โปรดลองอีกครั้ง หากยังคงเกิดขึ้นอยู่ โปรดติดต่อฝ่ายสนับสนุน FLUX',
    failed_to_save_camera_parameter: 'การบันทึกพารามิเตอร์ของกล้องล้มเหลว',
    failed_to_solve_pnp: 'ไม่สามารถแก้ไขตำแหน่งของกล้องได้.',
    finish: 'เสร็จสิ้น',
    found_checkpoint: 'พบข้อมูลจุดตรวจสอบบนอุปกรณ์ของคุณ คุณต้องการกู้คืนจากจุดตรวจสอบหรือไม่?',
    getting_plane_height: 'กำลังรับความสูงของระดับพื้น...',
    hint_adjust_parameters: 'โปรดใช้พารามิเตอร์เหล่านี้เพื่อปรับกรอบสีแดง',
    hint_red_square: 'โปรดจัดด้านนอกของสี่เหลี่ยมสีแดงให้ตรงกับสี่เหลี่ยมที่ตัด',
    module_calibration_2w_ir: 'การปรับเทียบโมดูลอินฟราเรด',
    module_calibration_printer: 'การปรับเทียบโมดูลพิมพ์',
    moving_laser_head: 'กำลังย้ายหัวเลเซอร์...',
    next: 'ถัดไป',
    no_picutre_found: '#846 อุปกรณ์ของคุณไม่มีรูปถ่ายดิบที่มีสำหรับการปรับแต่ง กรุณาติดต่อ FLUX support',
    perform_autofocus_bb2: 'กรุณาไปที่แผงควบคุมเครื่องและกด AF เพื่อทำการโฟกัสอัตโนมัติ',
    please_do_camera_calibration_and_focus: {
      beambox: 'เมื่อทําการปรับเทียบโมดูลเลเซอร์ ไดโอด กล้องจําเป็นต้องใช้งาน\nโปรดตรวจสอบให้แน่ใจว่ากล้องของเครื่องนี้ได้รับการปรับเทียบแล้ว\nและโปรดปรับแท่นวางให้อยู่ในโฟกัส',
      beamo: 'เมื่อทําการปรับเทียบโมดูลเลเซอร์ ไดโอด กล้องจําเป็นต้องใช้งาน\nโปรดตรวจสอบให้แน่ใจว่ากล้องของเครื่องนี้ได้รับการปรับเทียบแล้ว\nและโปรดปรับหัวเลเซอร์ให้โฟกัสกับวัตถุที่จะกัดเซาะ',
    },
    please_goto_beambox_first: 'โปรดสลับไปที่โหมดกัดขูด (Beambox) เพื่อใช้คุณสมบัตินี้',
    please_place_dark_colored_paper: 'โปรดวางกระดาษสีเข้มขนาด A4 หรือ Letter ที่กลางพื้นที่ทํางาน',
    please_place_paper: 'โปรดวางกระดาษขนาด A4 หรือ Letter สีขาว ที่มุมซ้ายบนของพื้นที่ทํางาน',
    please_place_paper_center: 'โปรดวางกระดาษขนาด A4 หรือ Letter สีขาว ที่กลางพื้นที่ทํางาน',
    please_refocus: {
      beambox: 'โปรดปรับแท่นวางให้อยู่ที่จุดโฟกัส (ความสูงของอะคริลิคที่พลิกกลับ)',
      beamo: 'โปรดปรับหัวเลเซอร์ให้โฟกัสที่วัตถุที่จะกัดขูด (ความสูงของอะคริลิคที่พลิกกลับ)',
      beamo_af: 'โปรดกดปุ่มด้านข้างของอุปกรณ์เสริมตั้งโฟกัสอัตโนมัติสองครั้งและให้เซ็นเซอร์สัมผัสวัสดุอย่างอ่อนโยน',
      hexa: 'โปรดกดปุ่มปรับระดับสองครั้งเพื่อยกโต๊ะรังผึ้งขึ้นและให้เซ็นเซอร์สัมผัสวัสดุที่จะกัดขูด',
    },
    preparing_to_take_picture: 'กำลังเตรียมพร้อมในการถ่ายภาพ...',
    promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    put_chessboard: 'วางกระดานหมากรุก',
    put_chessboard_1: 'กรุณาไปที่แผงควบคุมเครื่องและกด AF เพื่อทำการโฟกัสอัตโนมัติ',
    put_chessboard_2: 'กรุณาเลื่อนกระดาษหมากรุกหรือหัวเลเซอร์จนกระทั่งหมากรุกทั้งหมดได้รับการยืนยันว่าอยู่ภายในกรอบสีแดงในหน้าต่างแสดงตัวอย่างสด',
    put_chessboard_3: 'คลิกขวาเพื่อดาวน์โหลดรูปกระดานหมากรุกในตำแหน่งปัจจุบันและตรวจสอบความคมชัด',
    put_chessboard_bb2_desc_1: 'กรุณาดาวน์โหลดไฟล์กระดานหมากรุกด้านล่างและพิมพ์ลงบนกระดาษ A4 (กระดานหมากรุกที่พิมพ์ควรมีช่องขนาด 1x1 ซม.)',
    put_chessboard_bb2_desc_2: 'ติดกระดาษกระดานหมากรุกที่พิมพ์ลงบนแผ่นที่ไม่เสียรูป เช่น อะคริลิกหรือกระจก โดยให้กระดานหมากรุกเรียบ ไม่มีรอยย่นหรือขอบที่ยกขึ้น',
    put_chessboard_bb2_desc_3: 'วางแผ่นกระดานหมากรุกให้อยู่ในแนวราบตรงกลางพื้นที่ทำงาน',
    put_chessboard_promark_1: 'ปรับระยะโฟกัสให้เหมาะสมกับเลนส์สนาม',
    put_chessboard_promark_2: 'กรุณาตรวจสอบให้แน่ใจว่ากระดาษตารางหมากรุกมองเห็นได้ชัดเจนโดยไม่มีแสงสะท้อน คลิก "ถัดไป" เพื่อถ่ายภาพ',
    put_chessboard_promark_desc_1: 'กรุณาใช้กระดาษตารางหมากรุกที่จัดเตรียมไว้ในกล่องอุปกรณ์เสริมหรือพิมพ์ลายตารางหมากรุกต่อไปนี้ลงบนกระดาษ A4 สำหรับการสอบเทียบกล้อง',
    put_chessboard_promark_desc_2: 'กรุณาวางกระดานหมากรุกให้แบนราบและอยู่กึ่งกลางบนแท่นทำงาน',
    put_paper: 'วางกระดาษ',
    put_paper_promark_1: 'วางกระดาษแข็งสีดำจากกล่องอุปกรณ์ลงบนพื้นที่ทำงาน',
    put_paper_promark_2: 'ปรับโฟกัสให้ถูกต้อง จากนั้นคลิก "เริ่มกัดขูด" เพื่อดำเนินการแกะสลักต่อไป',
    put_paper_skip: 'หากการวาดการปรับเทียบไม่ดำเนินการถ่ายภาพโดยอัตโนมัติ ให้กด "ข้าม" เพื่อถ่ายภาพ',
    put_paper_step1: 'โปรดวางกระดาษสีขาวขนาด A4 หรือจดหมายในศูนย์กลางของพื้นที่ทำงาน',
    put_paper_step2: 'รัดมุมสี่มุมของกระดาษเพื่อให้แน่ใจว่ามันเรียบ',
    put_paper_step3: 'คลิก "เริ่มกัดขูด"',
    res_average: 'ปานกลาง',
    res_excellent: 'ยอดเยี่ยม',
    res_poor: 'แย่',
    retake: 'ถ่ายรูปใหม่',
    rotation_angle: 'การหมุน',
    show_last_config: 'แสดงผลล่าสุด',
    skip: 'ข้าม',
    solve_pnp_step1: 'โปรดจัดแนวจุดแกะสลักตามหมายเลขและตำแหน่งที่คาดหวังของแต่ละจุดเครื่องหมายสีแดง',
    solve_pnp_step2: 'คุณสามารถกด "ถ่ายรูปใหม่" เพื่อจัดตำแหน่งใหม่หรือปรับตำแหน่งของจุดมาร์คเกอร์เองได้',
    solve_pnp_step3: 'หากภาพที่ถ่ายไม่ตรงกับฉากจริง กรุณาขยายหรือเลื่อนหน้าจอเพื่อปรับจุดปรับเทียบบนการ์ดปรับเทียบสีดำให้อยู่กลางแล้วลอง "ถ่ายรูปใหม่" อีกครั้ง',
    solve_pnp_title: 'ปรับตำแหน่งของจุดมาร์คเกอร์',
    start_engrave: 'เริ่มกัดขูด',
    start_printing: 'เริ่มพิมพ์',
    taking_picture: 'กําลังถ่ายภาพ...',
    unable_to_load_camera_parameters: "#851 ไม่มีพารามิเตอร์การปรับเทียบกล้องที่พร้อมใช้งานบนอุปกรณ์ของคุณ โปรดไปที่ 'การปรับเทียบ' > 'การปรับเทียบกล้อง (ขั้นสูง)' เพื่อดำเนินการปรับเทียบให้เสร็จสิ้นและรับพารามิเตอร์",
    update_firmware_msg1: 'เฟิร์มแวร์ของคุณไม่รองรับฟังก์ชันนี้ โปรดอัปเดตเฟิร์มแวร์เป็น v ',
    update_firmware_msg2: 'หรือสูงกว่าเพื่อดําเนินการต่อ (เมนู > เครื่อง > [เครื่องของคุณ] > อัปเดตเฟิร์มแวร์)',
    uploading_images: 'กําลังอัปโหลดรูปภาพ...',
    use_last_config: 'ใช้ค่าปรับเทียบล่าสุด',
    use_old_camera_parameter: 'คุณต้องการใช้พารามิเตอร์เลนส์กล้องปัจจุบันหรือไม่?',
    with_af: 'มีตั้งโฟกัสอัตโนมัติ',
    with_prism_lift: 'ด้วย Ador Prism Lift',
    without_af: 'ไม่มีตั้งโฟกัสอัตโนมัติ',
    without_prism_lift: 'โดยไม่มี Ador Prism Lift',
    x_ratio: 'อัตราส่วน X',
    y_ratio: 'อัตราส่วน Y',
    zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
  },
  camera_data_backup: {
    download_success: 'ดาวน์โหลดข้อมูลกล้องสำเร็จ',
    downloading_data: 'กำลังดาวน์โหลดข้อมูล...',
    estimated_time_left: 'เวลาที่เหลือโดยประมาณ:',
    folder_not_exists: 'โฟลเดอร์ที่เลือกไม่มีอยู่',
    incorrect_folder: 'การอัปโหลดข้อมูลการจัดเก็บล้มเหลว โปรดตรวจสอบว่าโฟลเดอร์ที่คุณเลือกถูกต้องหรือไม่',
    no_picture_found: 'ไม่พบรูปในเครื่อง',
    title: 'การสำรองข้อมูลกล้อง',
    upload_success: 'อัปโหลดข้อมูลกล้องสำเร็จ',
    uploading_data: 'กำลังอัปโหลดข้อมูล...',
  },
  caption: {
    connectionTimeout: 'หมดเวลาการเชื่อมต่อ',
  },
  change_logs: {
    added: 'เพิ่ม:',
    change_log: 'บันทึกการเปลี่ยนแปลง:',
    changed: 'เปลี่ยน:',
    fixed: 'แก้ไข:',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    see_older_version: 'ดูเวอร์ชันเก่า',
  },
  code_generator: {
    barcode: 'บาร์โค้ด',
    qr_code: 'คิวอาร์โค้ด',
  },
  curve_engraving: {
    '921': 'โฟกัสอัตโนมัติล้มเหลว',
    '922': 'ไม่สามารถวัดด้วยแสงสีแดงได้.',
    amount: 'จำนวน',
    apply_arkwork: 'ใช้ผลงานศิลปะบนเส้นโค้ง',
    apply_camera: 'ใช้ภาพจากกล้องบนเส้นโค้ง',
    click_to_select_point: 'คลิกเพื่อเลือกหรือล้างการเลือกจุดที่จะวัดอีกครั้ง',
    coloumns: 'คอลัมน์',
    column_gap: 'ช่องว่างระหว่างคอลัมน์',
    err_object_over_range: 'วัตถุเกินช่วงการวัด.',
    failed_to_take_reference: 'ล้มเหลวในการอ้างอิง',
    gap: 'ช่องว่าง',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    measure_audofocus_area: 'วัดพื้นที่โฟกัสอัตโนมัติ',
    preview_3d_curve: 'ดูตัวอย่างการแกะสลักเส้นโค้ง',
    remeasure: 'วัดใหม่',
    remeasuring_points: 'กำลังวัดจุดใหม่...',
    reselect_area: 'เลือกพื้นที่ใหม่',
    row_gap: 'ช่องว่างระหว่างแถว',
    rows: 'แถว',
    set_object_height: 'ตั้งค่าความสูงของวัตถุ',
    set_object_height_desc: 'วัดความหนาสูงสุดของวัตถุ',
    start_autofocus: 'เริ่มโฟกัสอัตโนมัติ',
    starting_measurement: 'กำลังเริ่มวัด...',
    sure_to_delete: 'คุณต้องการลบข้อมูลโฟกัสของการแกะสลักเส้นโค้งหรือไม่?',
    take_reference: 'รับค่าการอ้างอิง',
    take_reference_desc: 'กรุณาย้ายหัวเลเซอร์ไปยังจุดที่สูงที่สุดของวัตถุ ลดหัววัดโฟกัสลง และคลิก "ยืนยัน" เพื่อปรับโฟกัส',
  },
  device: {
    abort: 'ยกเลิก',
    aborted: 'ถูกยกเลิก',
    aborting: 'กําลังยกเลิก',
    busy: 'กําลังใช้งาน',
    cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
    cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
    cartridge_serial_number: 'Ink cartridge serial number',
    close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
    completed: 'เสร็จสิ้น',
    completing: 'กําลังเสร็จสิ้น',
    deviceList: 'รายการอุปกรณ์',
    disable: 'ปิดใช้งาน',
    disconnectedError: {
      caption: 'เครื่องตัดไม่ได้เชื่อมต่อ',
      message: 'โปรดตรวจสอบการเข้าถึงเครือข่ายของ %s',
    },
    enable: 'เปิดใช้งาน',
    firmware_version: 'เวอร์ชันเฟิร์มแวร์',
    ink_color: 'Ink color',
    ink_level: 'Ink level',
    ink_type: 'Ink type',
    IP: 'IP',
    model_name: 'รุ่น',
    occupied: 'กําลังบํารุงรักษา',
    pause: 'หยุดชั่วคราว',
    paused: 'หยุดชั่วคราว',
    pausedFromError: 'หยุดชั่วคราวจากข้อผิดพลาด',
    pausing: 'กําลังหยุดชั่วคราว',
    please_wait: 'โปรดรอสักครู่...',
    preparing: 'กําลังเตรียม',
    processing: 'กําลังประมวลผล',
    quit: 'ออกจากระบบ',
    ready: 'พร้อม',
    reset: 'รีเซ็ต',
    resuming: 'กําลังดําเนินการต่อ',
    retry: 'ลองใหม่',
    running: 'กําลังทํางาน',
    scanning: 'กําลังสแกน',
    select: 'เลือก',
    select_printer: 'เลือกเครื่องพิมพ์',
    serial_number: 'หมายเลขซีเรียล',
    start: 'เริ่ม',
    starting: 'กําลังเริ่มต้น',
    status: 'สถานะ',
    submodule_type: 'Module',
    toolhead_change: 'เปลี่ยนหัวเครื่องมือ',
    unknown: 'ไม่ทราบ',
    uploading: 'กําลังอัปโหลด',
    UUID: 'UUID',
  },
  device_selection: {
    no_beambox: '#801 เราไม่พบเครื่องของคุณในเครือข่าย\nโปรดทําตาม<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">คําแนะนํา</a>เพื่อแก้ไขปัญหาการเชื่อมต่อ',
    no_device_web: "#801 กรุณาตรวจสอบสถานะของเครื่องของคุณหรือคลิกที่ 'ตั้งค่าเครื่อง' ด้านล่างเพื่อตั้งค่าเครื่อง.",
    select_usb_device: 'เลือกอุปกรณ์ USB',
  },
  editor: {
    exposure: 'ดูตัวอย่างการรับแสง',
    opacity: 'ความโปร่งแสงของตัวอย่างภาพ',
    prespray_area: 'พื้นที่พ่นสารเคมี',
  },
  error_pages: {
    screen_size: 'โปรดทราบว่า Beam Studio อาจไม่ทํางานได้อย่างเต็มประสิทธิภาพบนอุปกรณ์ของคุณ สําหรับประสบการณ์ที่ดีที่สุด ควรเป็นอุปกรณ์ที่มีความกว้างของหน้าจออย่างน้อย 1024 พิกเซล',
  },
  flux_id_login: {
    connection_fail: '#847 เชื่อมต่อกับบริการสมาชิก FLUX ไม่สําเร็จ',
    email: 'อีเมล',
    flux_plus: {
      access_monotype_feature: 'คุณไม่มีโมโนไทป์ ฟอนต์ แอดออน',
      access_monotype_feature_note: 'คุณต้องเป็นสมาชิก FLUX+ Pro หรือซื้อโมโนไทป์ ฟอนต์ แอดออน เพื่อใช้คุณสมบัตินี้',
      access_plus_feature_1: 'คุณกําลังเข้าถึง',
      access_plus_feature_2: 'คุณสมบัติ',
      access_plus_feature_note: 'คุณต้องเป็นสมาชิก FLUX+ เพื่อใช้คุณสมบัตินี้',
      ai_credit_tooltip: 'สําหรับการลบพื้นหลัง AI',
      explore_plans: 'เรียกดูแผน FLUX+',
      features: {
        ai_bg_removal: 'การลบพื้นหลัง AI',
        boxgen: 'โปรแกรมสร้างกล่อง 3 มิติ',
        dmkt: 'ไฟล์ดีไซน์ 1000+',
        monotype: 'ฟอนต์พรีเมียม 250+',
        my_cloud: 'พื้นที่จัดเก็บข้อมูลบนคลาวด์ไม่จํากัด',
      },
      flux_credit_tooltip: 'สําหรับไฟล์ตลาดการออกแบบและการลบพื้นหลัง AI',
      get_addon: 'รับแอดออน',
      goto_member_center: 'ไปที่ศูนย์สมาชิก',
      learn_more: 'เรียนรู้เพิ่มเติม',
      member_center_url: 'https://member.flux3dp.com/en-US/subscription',
      subscribe_now: 'สมัครรับข่าวสารตอนนี้',
      thank_you: 'ขอบคุณที่เป็นสมาชิกที่มีค่าของเรา!',
      website_url: 'https://flux3dp.com/subscription',
    },
    forget_password: 'ลืมรหัสผ่าน?',
    incorrect: 'อีเมลหรือรหัสผ่านไม่ถูกต้อง',
    login: 'เข้าสู่ระบบ',
    login_success: 'เข้าสู่ระบบสําเร็จ',
    lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
    new_to_flux: 'ใหม่กับ FLUX? สร้างบัญชี',
    not_verified: 'อีเมลยังไม่ได้รับการยืนยัน',
    offline: 'ทํางานออฟไลน์',
    password: 'รหัสผ่าน',
    register: 'สร้างบัญชี FLUX',
    remember_me: 'จดจําฉันไว้',
    signup_url: 'https://id.flux3dp.com/user/login#up',
    unlock_shape_library: 'เข้าสู่ระบบเพื่อปลดล็อกฐานข้อมูลรูปทรง',
    work_offline: 'ทํางานออฟไลน์',
  },
  framing: {
    area_check: 'ตรวจสอบพื้นที่',
    areacheck_desc: 'ตรวจสอบความปลอดภัยของพื้นที่ทำงานโดยการแสดงกล่องขอบเขตของวัตถุและโซนเร่งความเร็วของหัวเลเซอร์',
    calculating_task: 'กำลังคำนวณงาน...',
    framing: 'การกำหนดกรอบ',
    framing_desc: 'แสดงตัวอย่างกล่องขอบเขตของวัตถุ',
    hull: 'โครงร่าง',
    hull_desc: 'แสดงตัวอย่างรูปทรงที่ใกล้เคียงกับการออกแบบ เหมือนยางยืดพันรอบวัตถุ',
    low_laser: 'เลเซอร์กำลังต่ำ',
    low_laser_desc: 'ตั้งค่าเลเซอร์กำลังต่ำสำหรับงานกำหนดกรอบ',
    rotate_axis: 'แกนหมุน',
    rotateaxis_desc: 'แสดงตัวอย่างศูนย์กลางแกนหมุน, แกนหมุนจะไม่ขยับ',
    rotation_frame_warning: 'การแสดงตัวอย่างกรอบจะหมุนหนึ่งรอบ โปรดหลีกเลี่ยงการหยุดกลางคันเพื่อป้องกันการเยื้องตำแหน่งการทำเครื่องหมาย',
    rotation_framing_desc: 'แสดงตัวอย่างช่วงการหมุนทั้งหมด แกนหมุนจะหมุนตามที่แสดงในตัวอย่าง',
    start_task: 'เริ่มงาน',
    start_task_description: 'หลังจากแสดงตัวอย่างเฟรมเสร็จสิ้น ให้คลิก "เริ่มงาน" เพื่อส่งงาน.',
  },
  general: {
    choose_folder: 'เลือกโฟลเดอร์',
    processing: 'กำลังประมวลผล...',
  },
  generic_error: {
    OPERATION_ERROR: '[OE] เกิดความขัดแย้งสถานะ โปรดลองทําซ้ําการกระทํา',
    SUBSYSTEM_ERROR: '[SE] เกิดข้อผิดพลาดเมื่อเฟิร์มแวร์ของเครื่องกําลังปฏิบัติงาน โปรดรีสตาร์ทเครื่อง',
    UNKNOWN_COMMAND: '[UC] โปรดอัปเดตเฟิร์มแวร์อุปกรณ์',
    UNKNOWN_ERROR: '[UE] เกิดข้อผิดพลาดที่ไม่รู้จัก โปรดรีสตาร์ท Beam Studio และเครื่อง',
  },
  global: {
    apply: 'นําไปใช้',
    back: 'กลับ',
    cancel: 'ยกเลิก',
    editing: {
      redo: 'ทำซ้ำ',
      reset: 'รีเซ็ต',
      undo: 'เลิกทำ',
      zoom_in: 'ขยายเข้า',
      zoom_out: 'ย่อออก',
    },
    mode_conflict: 'ตัวเลือกนี้ไม่สามารถใช้ได้ในโหมดปัจจุบัน.',
    ok: 'ตกลง',
    save: 'บันทึก',
    stop: 'หยุด',
  },
  image_edit_panel: {
    eraser: {
      brush_size: 'ขนาดแปรง',
      description: 'คลิกหรือลากเพื่อลบพื้นที่ที่ไม่ต้องการด้วยตนเอง.',
      title: 'ยางลบ',
    },
    magic_wand: {
      description: 'เลือกและลบพื้นที่ติดกันตามความคล้ายคลึงของสี.',
      title: 'ไม้กายสิทธิ์',
      tolerance: 'ความทนทาน',
    },
    title: 'แก้ไขภาพ',
  },
  initialize: {
    back: 'กลับ',
    cancel: 'ยกเลิก',
    confirm: 'ยืนยัน',
    connect: 'เชื่อมต่อ',
    connect_ethernet: {
      title: 'การเชื่อมต่อโดยตรง',
      tutorial1: '1. เชื่อมต่อเครื่องกับคอมพิวเตอร์ของคุณด้วยสายอีเธอร์เน็ต',
      tutorial2_1: '2. ปฏิบัติตาม',
      tutorial2_2: ' เพื่อทําให้คอมพิวเตอร์ของคุณเป็นเราเตอร์',
      tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
      tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
      tutorial2_a_text: 'คู่มือนี้',
      tutorial3: '3. คลิกถัดไป',
    },
    connect_machine_ip: {
      alert: {
        swiftray_connection_error: 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ กรุณารีสตาร์ท Beam Studio และลองอีกครั้ง.',
      },
      check_camera: 'กําลังตรวจสอบกล้อง',
      check_connection: 'กําลังตรวจสอบการเชื่อมต่อเครื่อง',
      check_firmware: 'กําลังตรวจสอบเวอร์ชันเฟิร์มแวร์',
      check_ip: 'กําลังตรวจสอบความพร้อมใช้งานของ IP',
      check_swiftray_connection: 'กำลังตรวจสอบการเชื่อมต่อกับเซิร์ฟเวอร์',
      check_swiftray_connection_unreachable: 'ไม่สามารถเข้าถึงเซิร์ฟเวอร์',
      check_usb: 'ตรวจสอบการเชื่อมต่อ USB',
      enter_ip: 'ป้อน IP ของเครื่องของคุณ',
      finish_setting: 'เริ่มสร้าง!',
      invalid_format: 'รูปแบบไม่ถูกต้อง',
      invalid_ip: 'IP ไม่ถูกต้อง: ',
      promark_hint: 'หากไม่สามารถเชื่อมต่อผ่าน USB ซ้ำๆ กรุณาดู <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">บทความในศูนย์ช่วยเหลือ</a>.',
      retry: 'ลองใหม่',
      starts_with_169254: 'เริ่มต้นด้วย 169.254',
      succeeded_message: 'เชื่อมต่อสําเร็จ 🎉',
      unreachable: 'IP เข้าถึงไม่ได้',
    },
    connect_usb: {
      connect_camera: 'เชื่อมต่อกล้องของเครื่องเข้ากับคอมพิวเตอร์ของคุณด้วยสาย USB.',
      title: 'การเชื่อมต่อ USB',
      title_sub: ' (HEXA เท่านั้น)',
      turn_off_machine: 'ปิดเครื่อง',
      turn_on_machine: 'เปิดเครื่อง',
      tutorial1: '1. เชื่อมต่อเครื่องกับคอมพิวเตอร์ของคุณด้วยสาย USB',
      tutorial2: '2. คลิกถัดไป',
      wait_for_turning_on: 'คลิก "ถัดไป" หลังจากกระบวนการเริ่มต้นเสร็จสมบูรณ์และคุณเข้าสู่หน้าจอหลัก',
    },
    connect_wifi: {
      title: 'กําลังเชื่อมต่อ Wi-Fi',
      tutorial1: '1. ไปที่หน้าจอสัมผัส > คลิก "เครือข่าย" > "เชื่อมต่อ WiFi"',
      tutorial1_ador: '1. ไปที่หน้าจอสัมผัส > คลิก "MACHINE" > คลิก "Network" > "Connect to WiFi"',
      tutorial2: '2. เลือกและเชื่อมต่อ Wi-Fi ที่ต้องการ',
      what_if_1: 'ถ้าฉันไม่เห็น Wi-Fi ของฉันล่ะ?',
      what_if_1_content: '1. โหมดการเข้ารหัส Wi-Fi ควรเป็น WPA2 หรือไม่มีรหัสผ่าน\n2. โหมดการเข้ารหัสสามารถตั้งค่าได้ในหน้าจอการตั้งค่าเราเตอร์ Wi-Fi หากเราเตอร์ไม่รองรับ WPA2 และต้องการความช่วยเหลือในการเลือกเราเตอร์ที่เหมาะสม โปรดติดต่อฝ่ายสนับสนุน',
      what_if_2: 'ถ้าฉันไม่เห็น Wi-Fi?',
      what_if_2_content: '1. ตรวจสอบให้แน่ใจว่าตัวรับสัญญาณ Wi-Fi เสียบเข้ากับพอร์ตเต็มที่แล้ว\n2. หากไม่มีที่อยู่ MAC ของเครือข่ายไร้สายบนหน้าจอสัมผัส โปรดติดต่อฝ่ายสนับสนุน\n3. ช่องสัญญาณ Wi-Fi ควรเป็น 2.4Ghz (5Ghz ไม่รองรับ)',
    },
    connect_wired: {
      title: 'การเชื่อมต่อเครือข่ายแบบมีสาย',
      tutorial1: '1. เชื่อมต่อเครื่องกับเราเตอร์ของคุณ',
      tutorial2: '2. กด "เครือข่าย" เพื่อรับ IP ของเครือข่ายแบบมีสาย',
      tutorial2_ador: '2. กด "MACHINE" > "Network" เพื่อดู IP ของเครือข่ายแบบมีสาย',
      what_if_1: 'ถ้า IP ว่างเปล่าล่ะ',
      what_if_1_content: '1. ตรวจสอบให้แน่ใจว่าสาย Ethernet เสียบเข้ากับพอร์ตเต็มที่แล้ว\n2. หากไม่มีที่อยู่ MAC ของเครือข่ายแบบมีสายบนหน้าจอสัมผัส โปรดติดต่อฝ่ายสนับสนุน',
      what_if_2: 'ถ้า IP เริ่มต้นด้วย 169?',
      what_if_2_content: '1. หากที่อยู่ IP เริ่มต้นด้วย 169.254 น่าจะเป็นปัญหาการตั้งค่า DHCP โปรดติดต่อ ISP ของคุณ (ผู้ให้บริการอินเทอร์เน็ต) สําหรับความช่วยเหลือเพิ่มเติม\n2. หากคอมพิวเตอร์ของคุณเชื่อมต่อกับอินเทอร์เน็ตโดยตรงโดยใช้ PPPoE โปรดเปลี่ยนไปใช้เราเตอร์ในการเชื่อมต่อโดยใช้ PPPoE และเปิดใช้งานคุณสมบัติ DHCP ในเราเตอร์',
    },
    connecting: 'กําลังเชื่อมต่อ...',
    connection_types: {
      ether2ether: 'การเชื่อมต่อโดยตรง',
      usb: 'การเชื่อมต่อ USB',
      wifi: 'Wi-Fi',
      wired: 'เครือข่ายแบบมีสาย',
    },
    next: 'ถัดไป',
    no_machine: 'ฉันไม่มีเครื่องในขณะนี้',
    promark: {
      configuration_confirmation: 'การดำเนินการนี้จะช่วยให้ Promark ของคุณได้รับการกำหนดค่าอย่างเหมาะสมเพื่อประสิทธิภาพและความแม่นยำที่ดีที่สุด',
      or_complete_later: `หรือข้ามขั้นตอนนี้และตั้งค่า Promark ภายหลังได้ที่:
    เครื่อง > "ชื่อ Promark" > การตั้งค่า Promark`,
      qc_instructions: 'กรอกพารามิเตอร์ที่อยู่ด้านหลังของบัตร "QC Pass"',
      select_laser_source: 'เลือก Promark ของคุณ',
      select_workarea: 'เลือกพื้นที่ทำงาน',
      settings: 'การตั้งค่า Promark',
    },
    retry: 'ลองใหม่',
    select_beambox: 'เลือก Beambox ของคุณ',
    select_connection_type: 'คุณต้องการเชื่อมต่ออย่างไร?',
    select_language: 'เลือกภาษา',
    select_machine_type: 'เลือกรุ่นของเครื่อง',
    setting_completed: {
      back: 'กลับ',
      great: 'ยินดีต้อนรับสู่ Beam Studio',
      ok: 'เริ่มสร้าง',
      setup_later: 'คุณสามารถตั้งค่าเครื่องของคุณได้ที่แถบชื่อ > "เครื่อง" > "ตั้งค่าเครื่อง"',
      start: 'เริ่ม',
    },
    skip: 'ข้าม',
    start: 'เริ่ม',
  },
  input_machine_password: {
    connect: 'เชื่อมต่อ',
    password: 'รหัสผ่าน',
    require_password: '"%s" ต้องการรหัสผ่าน',
  },
  insecure_websocket: {
    extension_detected: 'ตรวจพบส่วนขยาย Beam Studio Connect',
    extension_detected_description: "เราตรวจพบว่าคุณได้ติดตั้งส่วนขยาย Beam Studio Connect กรุณาคลิก 'ยืนยัน' เพื่อเปลี่ยนเส้นทางไปที่ HTTPS หรือคลิก 'ยกเลิก' เพื่อทำต่อตาม HTTP",
    extension_not_deteced: 'ไม่สามารถตรวจพบส่วนขยาย Beam Studio Connect',
    extension_not_deteced_description: "หากต้องการใช้ HTTPS โปรดคลิก 'ยืนยัน' เพื่อติดตั้งส่วนขยาย Beam Studio Connect หลังจากติดตั้งส่วนขยายแล้ว กรุณารีเฟรชหน้าเพื่อเปิดใช้งาน มิฉะนั้น โปรดคลิกลิงก์ด้านล่างเพื่อดูวิธีการใช้ HTTP บน Chrome",
    unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">ลิงก์</a>',
  },
  layer_module: {
    general_laser: 'เลเซอร์',
    laser_2w_infrared: 'เลเซอร์อินฟราเรด 2W',
    laser_10w_diode: 'เลเซอร์ไดโอด 10W',
    laser_20w_diode: 'เลเซอร์ไดโอด 20W',
    non_working_area: 'พื้นที่ที่ไม่ทำงาน',
    none: 'None',
    notification: {
      convertFromLaserModuleMsg: 'โปรดทราบว่าหากคุณดําเนินการครบถ้วนแล้ว การตั้งค่าของชั้นเลเซอร์จะถูกลบออกและตั้งค่าตามชั้นปัจจุบัน',
      convertFromLaserModuleTitle: 'คุณต้องการแปลงโมดูลเลเซอร์เป็นโมดูลการพิมพ์หรือไม่?',
      convertFromPrintingModuleMsg: 'โปรดทราบว่าหากคุณดําเนินการครบถ้วนแล้ว การตั้งค่าสีของชั้นการพิมพ์จะถูกลบออกและตั้งค่าตามชั้นปัจจุบัน',
      convertFromPrintingModuleTitle: 'คุณต้องการแปลงโมดูลการพิมพ์เป็นโมดูลเลเซอร์หรือไม่?',
      importedDocumentContainsPrinting: 'เอกสารมีชั้นการพิมพ์ คุณต้องการเปลี่ยนพื้นที่ทํางานเป็น Ador หรือไม่',
      performIRCaliMsg: 'คลิก "ยืนยัน" เพื่อทําการปรับเทียบ หรือเข้าถึงการปรับเทียบผ่านเมนูด้านบน <br /> (เครื่อง > [ชื่อเครื่องของคุณ] > ปรับเทียบโมดูลอินฟราเรด)',
      performIRCaliTitle: 'ปรับเทียบโมดูลอินฟราเรด',
      performPrintingCaliMsg: 'คลิก "ยืนยัน" เพื่อทําการปรับเทียบ หรือเข้าถึงการปรับเทียบผ่านเมนูด้านบน <br />(เครื่อง > [ชื่อเครื่องของคุณ] > ปรับเทียบโมดูลการพิมพ์)',
      performPrintingCaliTitle: 'ปรับเทียบโมดูลการพิมพ์',
      printingLayersCoverted: 'ชั้นพิมพ์ได้ถูกแปลงเป็นชั้นเลเซอร์แล้ว',
    },
    printing: 'การพิมพ์',
    unknown: 'Unknown Module',
  },
  machine_status: {
    '-17': 'โหมด IO ของตลับหมึก',
    '-10': 'วิกฤต',
    '-2': 'โหมดบํารุงรักษา',
    '-1': 'กําลังสแกน',
    '0': 'ว่าง',
    '1': 'กําลังเริ่มต้น',
    '2': 'ST_TRANSFORM',
    '4': 'กําลังเริ่มต้น',
    '6': 'กําลังดําเนินการต่อ',
    '16': 'กําลังดําเนินการต่อ',
    '18': 'กําลังทํางาน',
    '32': 'กําลังทํางาน',
    '36': 'หยุดชั่วคราว',
    '38': 'หยุดชั่วคราว',
    '48': 'กําลังหยุดชั่วคราว',
    '50': 'หยุดชั่วคราว',
    '64': 'กําลังหยุดชั่วคราว',
    '66': 'เสร็จสิ้น',
    '68': 'กําลังเสร็จสิ้น',
    '128': 'กําลังเตรียมการ',
    '256': 'ยกเลิก',
    '512': 'สัญญาณเตือน',
    UNKNOWN: 'กําลังบํารุงรักษา',
  },
  material_test_generator: {
    block_settings: 'การตั้งค่าบล็อก',
    columns: 'คอลัมน์',
    count: 'จำนวน',
    cut: 'ตัด',
    engrave: 'แกะสลัก',
    export: 'ส่งออก',
    max: 'มากสุด',
    min: 'น้อยสุด',
    parameter: 'พารามิเตอร์',
    preview: 'ดูตัวอย่าง',
    rows: 'แถว',
    size: 'ขนาด (สxก)',
    spacing: 'ระยะห่าง',
    table_settings: 'การตั้งค่าตาราง',
    text_settings: 'การตั้งค่าข้อความ',
    title: 'ตัวสร้างการทดสอบวัสดุ',
  },
  menu: {
    inches: 'นิ้ว',
    mm: 'มม.',
  },
  message: {
    auth_error: '#820 ข้อผิดพลาดการตรวจสอบสิทธิ์: โปรดอัปเดต Beam Studio และเฟิร์มแวร์เครื่องเป็นเวอร์ชันล่าสุด',
    authenticating: 'กําลังตรวจสอบสิทธิ์...',
    camera: {
      abort_preview: 'ยกเลิก',
      camera_cable_unstable: 'ตรวจพบว่ากล้องกําลังส่งรูปภาพไม่มั่นคง การแสดงตัวอย่างยังคงทํางานได้ตามปกติ แต่อาจมีปัญหาการแสดงตัวอย่างช้าหรือหมดเวลา',
      continue_preview: 'ดําเนินการต่อ',
      fail_to_transmit_image: '#845 มีบางอย่างผิดพลาดกับการส่งภาพ โปรดรีสตาร์ทเครื่องของคุณหรือ Beam Studio หากข้อผิดพลาดนี้ยังคงเกิดขึ้น โปรดทําตาม<a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">คู่มือนี้</a>',
      ws_closed_unexpectly: '#844 การเชื่อมต่อกล้องเครื่องถูกปิดโดยไม่คาดคิด หากข้อผิดพลาดนี้ยังคงเกิดขึ้น โปรดทําตาม<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">คู่มือนี้</a>',
    },
    cancelling: 'ยกเลิก...',
    cant_connect_to_device: '#827 ไม่สามารถเชื่อมต่อกับเครื่องได้ โปรดตรวจสอบการเชื่อมต่อของคุณ',
    connected: 'เชื่อมต่อแล้ว',
    connecting: 'กําลังเชื่อมต่อ...',
    connectingCamera: 'เชื่อมต่อกล้อง...',
    connectingMachine: 'กําลังเชื่อมต่อ %s...',
    connectionTimeout: '#805 หมดเวลาเชื่อมต่ออุปกรณ์ โปรดตรวจสอบสถานะเครือข่ายและตัวบ่งชี้ Wi-Fi ของเครื่อง',
    device_blocked: {
      caption: 'หมายเลขซีเรียลไม่ได้รับอนุญาต',
      offline: 'หมายเลขซีเรียลของอุปกรณ์คุณถูกจำกัดให้ใช้งานแบบออฟไลน์เนื่องจากดูเหมือนจะถูกปิดการใช้งาน กรุณาแจ้งหมายเลขซีเรียลของอุปกรณ์ (อยู่ด้านหลังของอุปกรณ์) ให้กับตัวแทนจำหน่ายของคุณ และให้พวกเขาติดต่อ support@flux3dp.com เพื่อเปิดใช้งานการอนุญาตออนไลน์ หากคุณต้องการใช้อุปกรณ์แบบออฟไลน์ โปรดติดต่อ support@flux3dp.com โดยตรง',
      online: 'หมายเลขซีเรียลของอุปกรณ์คุณดูเหมือนจะถูกปิดการใช้งาน กรุณาแจ้งหมายเลขซีเรียลของอุปกรณ์ (อยู่ด้านหลังของอุปกรณ์) ให้กับตัวแทนจำหน่ายของคุณ และให้พวกเขาติดต่อ support@flux3dp.com เพื่อเปิดใช้งานการอนุญาตอุปกรณ์',
    },
    device_busy: {
      caption: 'เครื่องกําลังใช้งาน',
      message: 'เครื่องกําลังทํางานอยู่ โปรดลองอีกครั้งในภายหลัง หากหยุดทํางาน โปรดรีสตาร์ทเครื่อง',
    },
    device_is_used: 'เครื่องกําลังใช้งานอยู่ ต้องการยกเลิกงานปัจจุบันหรือไม่?',
    device_not_found: {
      caption: 'ไม่พบเครื่องพื้นฐาน',
      message: '#812 โปรดตรวจสอบตัวบ่งชี้ Wi-Fi ของเครื่อง',
    },
    disconnected: 'การเชื่อมต่อไม่มั่นคง โปรดตรวจสอบการเชื่อมต่ออุปกรณ์และลองอีกครั้งในภายหลัง',
    endingLineCheckMode: 'ออกจากโหมดตรวจสอบเส้น...',
    endingRawMode: 'กําลังออกจากโหมดดิบ...',
    enteringLineCheckMode: 'กําลังเข้าสู่โหมดตรวจสอบเส้น...',
    enteringRawMode: 'กําลังเข้าสู่โหมดดิบ...',
    enteringRedLaserMeasureMode: 'กำลังเข้าสู่โหมดวัดด้วยเลเซอร์สีแดง...',
    exitingRotaryMode: 'ออกจากโหมดหมุน...',
    getProbePosition: 'กําลังรับค่าตําแหน่งของโปรบ...',
    gettingLaserSpeed: 'อ่านความเร็วหัวเลเซอร์...',
    homing: 'กําลังโฮมมิ่ง...',
    need_password: 'ต้องการรหัสผ่านในการเชื่อมต่อกับเครื่อง',
    please_enter_dpi: 'โปรดป้อนหน่วยของไฟล์ของคุณ (เป็นมิลลิเมตร)',
    preview: {
      adjust: 'ปรับ',
      adjust_height_tooltip: 'คลิกที่ช่องทําเครื่องหมายเพื่อเปิดใช้การแก้ไข',
      already_performed_auto_focus: 'คุณได้ทําการโฟกัสอัตโนมัติแล้ว ต้องการใช้ค่าที่มีอยู่หรือไม่?',
      auto_focus: 'โฟกัสอัตโนมัติ',
      auto_focus_instruction: 'โปรดย้ายหัวโมดูลเลเซอร์เหนือวัตถุ และทําตามคําแนะนําในภาพเคลื่อนไหวเพื่อกด AF เพื่อโฟกัส',
      camera_preview: 'ตัวอย่างกล้อง',
      enter_manually: 'ป้อนด้วยตนเอง',
      please_enter_height: 'โปรดป้อนความสูงของวัตถุ เพื่อจับภาพกล้องอย่างแม่นยํา',
    },
    promark_disconnected: '#850 การเชื่อมต่ออุปกรณ์ถูกตัด กรุณาตรวจสอบสถานะการเชื่อมต่อของอุปกรณ์',
    redLaserTakingReference: 'กำลังรับข้อมูลอ้างอิง...',
    retrievingCameraOffset: 'อ่านค่าชดเชยกล้อง...',
    settingLaserSpeed: 'ตั้งค่าความเร็วหัวเลเซอร์...',
    swiftray_disconnected: 'ไม่สามารถเชื่อมต่อกับแบ็กเอนด์ กำลังพยายามเชื่อมต่อใหม่',
    swiftray_reconnected: 'แบ็กเอนด์เชื่อมต่อใหม่แล้ว โปรดลองส่งงานอีกครั้ง',
    time_remaining: 'เวลาที่เหลือ:',
    tryingToConenctMachine: 'กําลังพยายามเชื่อมต่อเครื่อง...',
    turningOffAirPump: 'ปิดปั๊มลม...',
    turningOffFan: 'ปิดแฟน...',
    unable_to_find_machine: 'ไม่พบเครื่อง ',
    unable_to_start: '#830 ไม่สามารถเริ่มงานได้ โปรดลองอีกครั้ง หากเกิดขึ้นอีก โปรดติดต่อเราพร้อมรายงานข้อบกพร่อง',
    unavailableWorkarea: '#804 พื้นที่ทํางานปัจจุบันเกินพื้นที่ทํางานของเครื่องนี้ โปรดตรวจสอบพื้นที่ทํางานของเครื่องที่เลือก หรือตั้งค่าพื้นที่ทํางานจาก แก้ไข > การตั้งค่าเอกสาร',
    unknown_device: '#826 ไม่สามารถเชื่อมต่อกับเครื่องได้ โปรดตรวจสอบว่า USB เชื่อมต่อกับเครื่องอยู่',
    unknown_error: '#821 แอปพลิเคชันพบข้อผิดพลาดที่ไม่รู้จัก โปรดใช้ ช่วยเหลือ > เมนู > รายงานข้อบกพร่อง',
    unsupport_osx_version: 'เวอร์ชัน macOS ปัจจุบัน %s อาจไม่สนับสนุนบางฟังก์ชัน โปรดอัปเดตเป็น macOS 11+',
    unsupport_win_version: 'เวอร์ชัน OS ปัจจุบัน %s อาจไม่สนับสนุนบางฟังก์ชัน โปรดอัปเดตเป็นเวอร์ชันล่าสุด',
    unsupported_example_file: 'ไฟล์ตัวอย่างที่เลือกไม่รองรับพื้นที่ทํางาน',
    uploading_fcode: 'กําลังอัปโหลด FCode',
    usb_unplugged: 'การเชื่อมต่อ USB หายไป โปรดตรวจสอบการเชื่อมต่อ USB ของคุณ',
    wrong_swiftray_version_message: 'เวอร์ชันแบ็กเอนด์ไม่ถูกต้อง (เวอร์ชัน: {version}) โปรดตรวจสอบว่ามีอินสแตนซ์ของ Beam Studio หลายตัวทำงานอยู่หรือไม่ และปิดอินสแตนซ์ที่ไม่จำเป็นก่อนลองอีกครั้ง',
    wrong_swiftray_version_title: 'เวอร์ชันแบ็กเอนด์ไม่ถูกต้อง',
  },
  monitor: {
    ask_reconnect: 'การเชื่อมต่อถูกตัด ต้องการเชื่อมต่อใหม่หรือไม่',
    bug_report: 'รายงานข้อบกพร่อง',
    camera: 'กล้อง',
    cancel: 'ยกเลิก',
    confirmFileDelete: 'คุณแน่ใจหรือไม่ว่าต้องการลบไฟล์',
    connecting: 'กําลังเชื่อมต่อ...',
    DEVICE_ERROR: 'เกิดข้อผิดพลาด\nโปรดรีสตาร์ทเครื่อง',
    download: 'ดาวน์โหลด',
    extensionNotSupported: 'รูปแบบไฟล์ไม่รองรับ',
    fileExistContinue: 'ไฟล์มีอยู่แล้ว ต้องการแทนที่หรือไม่',
    forceStop: 'ยกเลิกงานปัจจุบัน',
    go: 'เริ่ม',
    HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 ตรวจพบการไหลของอากาศผิดปกติ',
    HARDWARE_ERROR_BOTTOM_OPENED: '#903 ฐานเปิดอยู่ ปิดฐานเพื่อดําเนินการต่อ',
    HARDWARE_ERROR_DOOR_OPENED: '#901 ปิดประตูเพื่อดําเนินการต่อ',
    HARDWARE_ERROR_DRAWER_OPENED: '#911 ลิ้นชักเปิดอยู่',
    HARDWARE_ERROR_FIRE_DETECTED: '#912 ตรวจพบไฟผิดปกติ',
    HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 โมดูลที่ตรวจพบไม่ถูกต้อง โปรดติดตั้งโมดูลที่ถูกต้องเพื่อดําเนินการต่อ',
    HARDWARE_ERROR_HEADTYPE_NONE: '#917 ไม่พบโมดูล โปรดตรวจสอบให้แน่ใจว่าโมดูลติดตั้งอย่างเหมาะสมเพื่อดําเนินการต่อ',
    HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 ตรวจพบโมดูลที่ไม่รู้จัก โปรดติดตั้งโมดูลที่ถูกต้องเพื่อดําเนินการต่อ',
    HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 ข้อผิดพลาดการดึงกลับโฮมมิ่ง',
    HARDWARE_ERROR_MAINBOARD_ERROR: '#401 ข้อผิดพลาดวิกฤติ: เมนบอร์ดออฟไลน์ โปรดติดต่อฝ่ายสนับสนุน',
    HARDWARE_ERROR_OVER_TEMPERATURE: '#902 ร้อนเกินไป โปรดรอสักครู่',
    HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 รีเซ็ตแกน Z ล้มเหลว',
    HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 ไม่มีการตอบสนองจากโมดูลพิมพ์',
    HARDWARE_ERROR_PROBE_SHOWED: 'กรุณาถอนโพรบกลับเข้าไป',
    HARDWARE_ERROR_PUMP_ERROR: '#900 โปรดตรวจสอบถังน้ําของคุณ',
    HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 ไม่พบโมดูลหมุน',
    HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 ข้อผิดพลาดวิกฤติ: ระบบย่อยไม่ตอบสนอง โปรดติดต่อฝ่ายสนับสนุน',
    HARDWARE_FAILURE: 'เกิดข้อผิดพลาด\nโปรดรีสตาร์ทเครื่อง',
    hour: 'ชม.',
    left: 'เหลือ',
    MAINBOARD_OFFLINE: '#905 ข้อผิดพลาดการเชื่อมต่อกับเมนบอร์ด\nโปรดรีสตาร์ทเครื่อง',
    minute: 'นาที',
    monitor: 'ตรวจสอบ',
    NO_RESPONSE: '#905 ข้อผิดพลาดการเชื่อมต่อกับเมนบอร์ด\nโปรดรีสตาร์ทเครื่อง',
    pause: 'หยุดชั่วคราว',
    prepareRelocate: 'กําลังเตรียมการย้ายตําแหน่ง',
    processing: 'กําลังประมวลผล',
    record: 'บันทึก',
    relocate: 'ย้ายตําแหน่ง',
    RESOURCE_BUSY: 'เครื่องกําลังทํางานอยู่\nหากไม่ทํางาน โปรดรีสตาร์ทเครื่อง',
    resume: 'ดําเนินการต่อ',
    savingPreview: 'กําลังสร้างภาพขนาดย่อ',
    second: 'วินาที',
    start: 'เริ่ม',
    stop: 'หยุด',
    SUBSYSTEM_ERROR: '#402 ข้อผิดพลาดวิกฤติ: ระบบย่อยไม่ตอบสนอง โปรดติดต่อฝ่ายสนับสนุน',
    task: {
      BEAMBOX: 'เลเซอร์กัดขูด',
      'N/A': 'โหมดอิสระ',
    },
    taskTab: 'งาน',
    temperature: 'อุณหภูมิ',
    upload: 'อัปโหลด',
    USER_OPERATION: 'กรุณาทำตามคำแนะนำในพาเนลของอุปกรณ์เพื่อดำเนินการต่อ',
    USER_OPERATION_CHANGE_CARTRIDGE: 'กรุณาใส่แผ่นหมึกที่ถูกต้องเพื่อดำเนินการต่อ',
    USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'กรุณาใส่แผ่นหมึกเพื่อดำเนินการต่อ',
    USER_OPERATION_CHANGE_TOOLHEAD: 'โปรดติดตั้งโมดูลที่ถูกต้องเพื่อดําเนินการต่อ',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 โมดูลที่ตรวจพบไม่ถูกต้อง โปรดติดตั้งโมดูลที่ถูกต้องเพื่อดําเนินการต่อ',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 ไม่พบโมดูล โปรดตรวจสอบให้แน่ใจว่าโมดูลติดตั้งอย่างเหมาะสมเพื่อดําเนินการต่อ',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 ตรวจพบโมดูลที่ไม่รู้จัก โปรดติดตั้งโมดูลที่ถูกต้องเพื่อดําเนินการต่อ',
    USER_OPERATION_REMOVE_CARTRIDGE: 'โปรดนําตลับหมึกออกเพื่อดําเนินการต่อ',
    USER_OPERATION_ROTARY_PAUSE: 'โปรดสลับไปยังมอเตอร์หมุน',
  },
  my_cloud: {
    action: {
      confirmFileDelete: 'คุณแน่ใจหรือไม่ว่าต้องการลบไฟล์นี้? การกระทํานี้ไม่สามารถยกเลิกได้',
      delete: 'ลบ',
      download: 'ดาวน์โหลด',
      duplicate: 'ทําสําเนา',
      open: 'เปิด',
      rename: 'เปลี่ยนชื่อ',
    },
    file_limit: 'ไฟล์ฟรี',
    loading_file: 'กําลังโหลด...',
    no_file_subtitle: 'ไปที่เมนู > "ไฟล์" > "บันทึกไปยังคลาวด์"',
    no_file_title: 'บันทึกไฟล์ไปยัง คลาวด์ของฉัน เพื่อเริ่มต้น',
    save_file: {
      choose_action: 'บันทึกไฟล์:',
      input_file_name: 'กรอกชื่อไฟล์:',
      invalid_char: 'อักขระไม่ถูกต้อง:',
      save: 'บันทึก',
      save_new: 'บันทึกเป็นไฟล์ใหม่',
      storage_limit_exceeded: 'พื้นที่จัดเก็บข้อมูลบนคลาวด์ของคุณเต็มแล้ว โปรดลบไฟล์ที่ไม่จําเป็นออกก่อนบันทึกไฟล์ใหม่',
    },
    sort: {
      a_to_z: 'ชื่อ: ก-ฮ',
      most_recent: 'ล่าสุด',
      oldest: 'เก่าสุด',
      z_to_a: 'ชื่อ: ฮ-ก',
    },
    title: 'คลาวด์ของฉัน',
    upgrade: 'อัพเกรด',
  },
  noun_project_panel: {
    clear: 'ล้าง',
    elements: 'องค์ประกอบ',
    enjoy_shape_library: 'เพลิดเพลินกับฐานข้อมูลรูปทรง',
    export_svg_title: 'ไม่สามารถส่งออก SVG',
    export_svg_warning: 'โปรเจ็กต์นี้มีวัตถุที่ได้รับความคุ้มครองโดยกฎหมายทรัพย์สินทางปัญญา ดังนั้น Beam Studio จะตัดวัตถุเหล่านี้ออกโดยอัตโนมัติระหว่างการส่งออก คุณยังคงสามารถบันทึกโปรเจ็กต์ในรูปแบบ Beam Studio Scene (.beam) เพื่อเก็บข้อมูลทั้งหมด คุณต้องการส่งออกอยู่หรือไม่?',
    learn_more: 'เรียนรู้เพิ่มเติม',
    login_first: 'เข้าสู่ระบบเพื่อปลดล็อกฐานข้อมูลรูปทรง',
    recent: 'ล่าสุด',
    search: 'ค้นหา',
    shapes: 'รูปทรง',
  },
  pass_through: {
    export: 'ส่งออกไปยังพื้นที่ทำงาน',
    exporting: 'กำลังส่งออก...',
    guide_mark: 'จุดหมาย',
    guide_mark_desc: 'จุดหมายจะถูกแกะสลักเป็นจุดอ้างอิงเพื่อจัดแนวงานศิลปะ',
    guide_mark_length: 'ความยาว:',
    guide_mark_x: 'พิกัด X:',
    height_desc: 'ตั้งค่าความสูงของแต่ละส่วนของพื้นที่ทำงาน',
    help_links: {
      ado1: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
      fbb2: 'https://support.flux3dp.com/hc/en-us/articles/11570021253263',
    },
    help_text: 'วิธีตั้งค่าการผ่านสำหรับ %(model)s?',
    object_length: 'ความยาวของวัตถุ',
    ref_layer: 'ชั้นอ้างอิง',
    ref_layer_desc: 'โปรดทราบว่าการดำเนินการของชั้นอ้างอิงถูกตั้งค่าเป็น 0 โดยค่าเริ่มต้น จะไม่ถูกดำเนินการแต่ใช้สำหรับอ้างอิงการจัดแนวเท่านั้น',
    ref_layer_name: 'อ้างอิง',
    title: 'โหมดผ่าน',
    workarea_height: 'พื้นที่ทำงาน (ความสูง):',
  },
  promark_connection_test: {
    description: 'ระบบจะทำงานด้วยแสงสีแดงเป็นเวลาประมาณสองนาทีเพื่อตรวจสอบว่า การเชื่อมต่อมีเสถียรภาพหรือไม่',
    health: 'เสถียรภาพ:',
    healthy_description: 'เสถียรภาพดี แต่อย่าย้ายคอมพิวเตอร์มากเกินไประหว่างการแกะสลัก',
    res_0: 'ยอดเยี่ยม',
    res_1: 'ดี',
    res_2: 'ต่ำ',
    res_3: 'ต่ำมาก',
    res_4: 'ต่ำสุด',
    restart: 'เริ่มการทดสอบใหม่',
    start: 'เริ่มการทดสอบ',
    stop: 'หยุดการทดสอบ',
    title: 'การทดสอบเสถียรภาพการเชื่อมต่อ',
    unhealthy_description: 'เสถียรภาพต่ำเกินไป แนะนำให้เปลี่ยนอะแดปเตอร์เครือข่ายหรือสายไฟ',
  },
  promark_settings: {
    angle: 'มุม',
    bulge: 'การโป่งพอง',
    field: 'ฟิลด์',
    galvo_configuration: 'การตั้งค่า Galvo',
    mark: 'ทำเครื่องหมาย',
    mark_parameters: 'พารามิเตอร์การมาร์ก',
    offsetX: 'การชดเชย X',
    offsetY: 'การชดเชย Y',
    preview: 'ดูตัวอย่าง',
    red_dot: 'จุดแดง',
    scale: 'มาตราส่วน',
    scaleX: 'มาตราส่วน X',
    scaleY: 'มาตราส่วน Y',
    skew: 'การบิดเบือน',
    switchXY: 'สลับ X/Y',
    title: 'การตั้งค่า Promark',
    trapezoid: 'รูปสี่เหลี่ยมคางหมู',
    workarea_hint: 'คุณสามารถเปลี่ยนพื้นที่ทำงานได้ใน "การตั้งค่าเอกสาร"',
    z_axis_adjustment: {
      section1: 'ปรับความสูงของแกน Z เพื่อปรับโฟกัสให้ละเอียดขึ้น',
      title: 'การปรับแกน Z',
      tooltip1: 'ลองทำเครื่องหมายสี่เหลี่ยมขนาด 1x1 ซม. เพื่อยืนยันว่าระยะโฟกัสปัจจุบันเหมาะสมหรือไม่',
    },
  },
  qr_code_generator: {
    error_tolerance: 'ระดับความคลาดเคลื่อนที่ยอมรับได้',
    error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
    invert: 'สีพื้นหลังกลับกัน',
    placeholder: 'กรอกลิงก์หรือข้อความ',
    preview: 'ตัวอย่าง',
    title: 'ตัวสร้างคิวอาร์โค้ด',
  },
  rotary_settings: {
    circumference: 'เส้นรอบวง',
    extend_workarea: 'ขยายพื้นที่ทำงาน',
    mirror: 'กระจก',
    object_diameter: 'เส้นผ่านศูนย์กลางของวัตถุ',
    overlap_size: 'ขนาดการทับซ้อน',
    rotary_scale: 'มาตราส่วนการหมุน',
    split_setting: 'การตั้งค่าการแบ่ง',
    split_setting_url: 'https://support.flux3dp.com/hc/en-us/articles/12276094010767',
    split_size: 'ขนาดการแบ่ง',
    type: 'ประเภท',
  },
  select_device: {
    auth_failure: '#811 การยืนยันตัวตนล้มเหลว',
    select_device: 'เลือกอุปกรณ์',
    unable_to_connect: '#810 ไม่สามารถสร้างการเชื่อมต่อที่มั่นคงกับเครื่อง',
  },
  settings: {
    anti_aliasing: 'ความต่อเนื่องของเส้น',
    auto_connect: 'เลือกเครื่องโดยอัตโนมัติ',
    auto_switch_tab: 'สลับชั้นอัตโนมัติและแผงวัตถุ',
    autofocus_offset: 'ชดเชยการโฟกัสอัตโนมัติ',
    autosave_enabled: 'บันทึกอัตโนมัติ',
    autosave_interval: 'บันทึกทุก',
    autosave_number: 'จํานวนการบันทึกอัตโนมัติ',
    autosave_path: 'ตําแหน่งบันทึกอัตโนมัติ',
    autosave_path_not_correct: 'ไม่พบเส้นทางที่ระบุ',
    blade_precut_position: 'ตําแหน่งเจาะล่วงหน้า',
    blade_precut_switch: 'เจาะล่วงหน้าด้วยใบมีด',
    blade_radius: 'รัศมีใบมีด',
    bottom_up: 'ล่างขึ้นบน',
    calculation_optimization: 'การเร่งความเร็วในการคำนวณเส้นทาง',
    cancel: 'ยกเลิก',
    caption: 'การตั้งค่า',
    check_updates: 'ตรวจสอบการอัปเดตอัตโนมัติ',
    close: 'ปิด',
    confirm_remove_default: 'เครื่องเริ่มต้นกําลังจะถูกลบ',
    confirm_reset: 'ยืนยันการรีเซ็ต Beam Studio?',
    continuous_drawing: 'การวาดต่อเนื่อง',
    curve_engraving_speed_limit: 'จำกัดความเร็วในการแกะสลักเส้นโค้ง',
    custom_preview_height: 'ความสูงแสดงตัวอย่างกําหนดเอง',
    default_beambox_model: 'การตั้งค่าเอกสารเริ่มต้น',
    default_borderless_mode: 'เปิดด้านล่างเริ่มต้น',
    default_enable_autofocus_module: 'เปิดใช้งานโหมดอัตโนมัติเริ่มต้น',
    default_enable_diode_module: 'เลเซอร์ไดโอดเริ่มต้น',
    default_font_family: 'แบบอักษรเริ่มต้น',
    default_font_style: 'รูปแบบแบบอักษรเริ่มต้น',
    default_laser_module: 'โมดูลเลเซอร์เริ่มต้น',
    default_machine: 'เครื่องเริ่มต้น',
    default_machine_button: 'ไม่มี',
    default_units: 'หน่วยเริ่มต้น',
    diode_offset: 'ชดเชยเลเซอร์ไดโอด',
    diode_one_way_engraving: 'การแกะสลักทางเดียวด้วยเลเซอร์ไดโอด',
    diode_two_way_warning: 'การส่องแสงสองทางจะเร็วกว่า แต่อาจทําให้ตําแหน่งลําเลเซอร์ผิดพลาดได้ ขอแนะนําให้ทดสอบก่อน',
    disabled: 'ปิดใช้งาน',
    done: 'ตกลง',
    enable_custom_backlash: 'เปิดใช้งานการชดเชยการถอยหลังแบบกําหนดเอง',
    enable_low_speed: 'เปิดใช้งานความเร็วต่ํา',
    enabled: 'เปิดใช้งาน',
    engraving_direction: 'ทิศทาง',
    fast_gradient: 'เพิ่มความเร็ว',
    font_convert: 'โปรแกรมแปลงข้อความเป็นเส้นทาง',
    font_substitute: 'แทนที่อักขระที่ไม่รองรับ',
    grouped_objects: 'กลุ่มวัตถุ',
    groups: {
      ador_modules: 'โมดูล Ador',
      autosave: 'บันทึกอัตโนมัติ',
      camera: 'กล้อง',
      connection: 'การเชื่อมต่อ',
      editor: 'แก้ไข',
      engraving: 'การสแกน',
      general: 'ทั่วไป',
      mask: 'การตัดพื้นที่ทํางาน',
      modules: 'โมดูลเสริม',
      path: 'เส้นทาง',
      privacy: 'ความเป็นส่วนตัว',
      text_to_path: 'ข้อความ',
      update: 'การอัปเดตซอฟต์แวร์',
    },
    guess_poke: 'ค้นหาที่อยู่ IP เครื่อง',
    guides: 'แนวประ',
    guides_origin: 'ต้นกําเนิดแนวประ',
    help_center_urls: {
      anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
      calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
      connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
      continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
      default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
      default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
      default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
      fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
      font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
      font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
      image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
      loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
      mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
      reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
      segmented_engraving: 'https://support.flux3dp.com/hc/en-us/articles/12306366019215',
      simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
      vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
    },
    high: 'สูง',
    image_downsampling: 'คุณภาพตัวอย่างภาพแรสเตอร์',
    ip: 'ที่อยู่ IP เครื่อง',
    keep_preview_result: 'คงผลลัพธ์การแสดงตัวอย่างไว้',
    language: 'ภาษา',
    loop_compensation: 'ชดเชยการวนลูป',
    low: 'ต่ํา',
    low_laser_for_preview: 'เลเซอร์สําหรับ กําลังประมวลผลเฟรม',
    mask: 'ตัดขอบพื้นที่ทํางาน',
    medium: 'ปานกลาง',
    module_offset_2w_ir: 'ชดเชยเลเซอร์อินฟราเรด 2 วัตต์',
    module_offset_10w: 'ชดเชยเลเซอร์ไดโอด 10 วัตต์',
    module_offset_20w: 'ชดเชยเลเซอร์ไดโอด 20 วัตต์',
    module_offset_printer: 'ชดเชยตัวพิมพ์',
    none: 'ไม่มี',
    normal: 'สูง',
    notification_off: 'ปิด',
    notification_on: 'เปิด',
    notifications: 'การแจ้งเตือนบนเดสก์ท็อป',
    off: 'ปิด',
    on: 'เปิด',
    preview_movement_speed: 'ความเร็วการแสดงตัวอย่าง',
    printer_advanced_mode: 'โหมดขั้นสูงของเครื่องพิมพ์',
    remove_default_machine_button: 'ลบ',
    reset: 'รีเซ็ต Beam Studio',
    reset_now: 'รีเซ็ต Beam Studio',
    segmented_engraving: 'การแกะสลักแบบแบ่งส่วน',
    share_with_flux: 'แชร์ข้อมูลการใช้งาน Beam Studio',
    simplify_clipper_path: 'เพิ่มประสิทธิภาพเส้นทางที่คํานวณ',
    single_object: 'วัตถุเดี่ยว',
    tabs: {
      device: 'เครื่อง',
      general: 'ทั่วไป',
    },
    text_path_calc_optimization: 'เพิ่มประสิทธิภาพการคํานวณเส้นทาง',
    top_down: 'บนลงล่าง',
    trace_output: 'ผลลัพธ์การแปลงภาพ',
    update_beta: 'เบต้า',
    update_latest: 'ล่าสุด',
    vector_speed_constraint: 'ขีดจํากัดความเร็ว',
    wrong_ip_format: 'รูปแบบ IP ไม่ถูกต้อง',
  },
  social_media: {
    facebook: 'พูดคุยกับ FLUXers ถามคำถาม และเรียนรู้เคล็ดลับ!',
    instagram: 'รับแรงบันดาลใจ ข้อเสนอ และของแจกฟรีล่าสุด!',
    youtube: 'ดูบทเรียน Beam Studio และไอเดียงานฝีมือ',
  },
  support: {
    no_vcredist: 'โปรดติดตั้ง Visual C++ Redistributable 2015<br/>สามารถดาวน์โหลดได้ที่ flux3dp.com',
    no_webgl: 'WebGL ไม่รองรับ โปรดใช้อุปกรณ์อื่น',
  },
  topbar: {
    alerts: {
      add_content_first: 'โปรดเพิ่มวัตถุก่อน',
      door_opened: 'โปรดปิดฝาครอบประตูเพื่อเปิดใช้งานเลเซอร์ต่ํากําลังสําหรับการทํางาน',
      fail_to_connect_with_camera: '#803 ไม่สามารถเชื่อมต่อกับกล้องได้ โปรดรีสตาร์ทเครื่องหรือ Beam Studio หากยังคงเกิดข้อผิดพลาดนี้ โปรดปฏิบัติตาม <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">คําแนะนํานี้</a>',
      fail_to_get_door_status: 'โปรดตรวจสอบให้แน่ใจว่าฝาครอบประตูปิด เพื่อเปิดใช้งานเลเซอร์ต่ํากําลังสําหรับการทํางาน',
      fail_to_start_preview: '#803 ไม่สามารถเริ่มโหมดแสดงตัวอย่างได้ โปรดรีสตาร์ทเครื่องของคุณหรือ Beam Studio หากยังคงเกิดข้อผิดพลาดนี้ โปรดปฏิบัติตาม <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">คําแนะนํานี้</a>',
      headtype_mismatch: 'ตรวจพบหัวเลเซอร์ไม่ถูกต้อง ',
      headtype_none: 'ไม่พบหัวเลเซอร์ ',
      headtype_unknown: 'ตรวจพบหัวเลเซอร์ที่ไม่รู้จัก ',
      install_correct_headtype: 'โปรดติดตั้งหัวเลเซอร์ไดโอด 10W/20W ให้ถูกต้องเพื่อเปิดใช้งานเลเซอร์ต่ํากําลังสําหรับการทํางาน',
      job_origin_unavailable: 'การตั้งค่าจุดเริ่มต้นงานต้องการเฟิร์มแวร์เวอร์ชัน 4.3.5 / 5.3.5 ขึ้นไป คุณต้องการอัปเดตเฟิร์มแวร์ตอนนี้หรือไม่?',
      job_origin_warning: 'คุณกำลังใช้ "ตำแหน่งปัจจุบัน" เป็นจุดเริ่มต้น กรุณาตรวจสอบให้แน่ใจว่าหัวเลเซอร์ถูกย้ายไปยังตำแหน่งที่ถูกต้องเพื่อหลีกเลี่ยงการชนกัน',
      power_too_high: 'พลังงานสูงเกินไป',
      power_too_high_confirm: 'ทราบแล้ว',
      power_too_high_msg: 'การใช้พลังงานเลเซอร์ต่ํากว่า 70% จะช่วยยืดอายุการใช้งานของหลอดเลเซอร์\nกด "ทราบแล้ว" เพื่อดําเนินการต่อ',
      pwm_unavailable: 'โหมดความลึกต้องใช้เฟิร์มแวร์เวอร์ชัน 4.3.4 / 5.3.4 หรือใหม่กว่า คุณต้องการอัปเดตเฟิร์มแวร์ตอนนี้หรือไม่?',
      QcleanScene: 'คุณต้องการล้างภาพวาดหรือไม่?<br/>สิ่งนี้ยังจะล้างประวัติการเลิกทำของคุณด้วย!',
      start_preview_timeout: '#803 หมดเวลาในการเริ่มโหมดแสดงตัวอย่าง โปรดรีสตาร์ทเครื่องของคุณหรือ Beam Studio หากยังคงเกิดข้อผิดพลาดนี้ โปรดปฏิบัติตาม <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">คําแนะนํานี้</a>',
    },
    frame_task: 'กําลังประมวลผลเฟรม',
    hint: {
      polygon: 'กดปุ่ม + / - เพื่อเพิ่ม / ลดด้าน',
    },
    menu: {
      about: 'เกี่ยวกับ Beam Studio',
      about_beam_studio: 'เกี่ยวกับ Beam Studio',
      account: 'บัญชี',
      add_new_machine: 'ตั้งค่าเครื่อง',
      align_center: 'จัดกึ่งกลาง',
      anti_aliasing: 'การปรับความคมชัด',
      auto_align: 'จัดแนวอัตโนมัติ',
      borderless_mode: 'โหมดไร้ขอบ',
      bug_report: 'รายงานข้อบกพร่อง',
      calibrate_beambox_camera: 'ปรับเทียบกล้อง',
      calibrate_beambox_camera_borderless: 'ปรับเทียบกล้อง (เปิดด้านล่าง)',
      calibrate_camera_advanced: 'การปรับเทียบกล้อง (ขั้นสูง)',
      calibrate_diode_module: 'ปรับเทียบโมดูลเลเซอร์',
      calibrate_ir_module: 'ปรับเทียบโมดูลอินฟราเรด',
      calibrate_printer_module: 'ปรับเทียบโมดูลการพิมพ์',
      calibration: 'การปรับเทียบ',
      camera_calibration_data: 'ข้อมูลการปรับเทียบกล้อง',
      change_logs: 'บันทึกการเปลี่ยนแปลง',
      clear_scene: 'ไฟล์ใหม่',
      close: 'ปิดหน้าต่าง',
      commands: 'คําสั่ง',
      contact: 'ติดต่อเรา',
      copy: 'คัดลอก',
      cut: 'ตัด',
      dashboard: 'แดชบอร์ด',
      decompose_path: 'แยกเส้นทาง',
      delete: 'ลบ',
      design_market: 'ตลาดการออกแบบ',
      dev_tool: 'เครื่องมือดีบัก',
      disassemble_use: 'ถอดประกอบ',
      document_setting: 'ตั้งค่าเอกสาร',
      document_setting_short: 'เอกสาร',
      download_data: 'ดาวน์โหลดข้อมูล',
      download_log: 'ดาวน์โหลดบันทึก',
      download_log_canceled: 'ยกเลิกการดาวน์โหลดบันทึก',
      download_log_error: 'เกิดข้อผิดพลาดที่ไม่รู้จัก โปรดลองอีกครั้งในภายหลัง',
      duplicate: 'ทําสําเนา',
      edit: 'แก้ไข',
      example_files: 'ไฟล์ตัวอย่าง',
      export_BVG: 'BVG',
      export_flux_task: 'งาน FLUX',
      export_JPG: 'JPG',
      export_PNG: 'PNG',
      export_SVG: 'SVG',
      export_to: 'ส่งออกไปยัง...',
      file: 'ไฟล์',
      fit_to_window: 'พอดีกับขนาดหน้าต่าง',
      follow_us: 'ติดตามเรา',
      forum: 'ฟอรั่มชุมชน',
      group: 'จัดกลุ่ม',
      help: 'ช่วยเหลือ',
      help_center: 'ศูนย์ช่วยเหลือ',
      hide: 'ซ่อน Beam Studio',
      hideothers: 'ซ่อนอื่นๆ',
      image_crop: 'ตัดขอบภาพ',
      image_curve: 'โค้ง',
      image_invert: 'กลับภาพ',
      image_sharpen: 'เพิ่มความคมชัด',
      image_stamp: 'ขอบเขต',
      image_vectorize: 'ติดตามเส้นทาง',
      import_acrylic_focus_probe: 'โฟกัสโพรบอะคริลิค - 3 มม.',
      import_ador_laser_example: 'ตัวอย่างของ Ador Laser',
      import_ador_printing_example_full: 'ตัวอย่างการพิมพ์ของ Ador - สีเต็มรูปแบบ',
      import_ador_printing_example_single: 'ตัวอย่างการพิมพ์ของ Ador - สีเดียว',
      import_beambox_2_example: 'ตัวอย่างของ Beambox II',
      import_beambox_2_focus_probe: 'หัววัดโฟกัส Beambox II - 3 มม.',
      import_hello_beambox: 'ตัวอย่างของ Beambox',
      import_hello_beamo: 'ตัวอย่างของ beamo',
      import_hexa_example: 'ตัวอย่าง HEXA',
      import_material_printing_test: 'ทดสอบการพิมพ์วัสดุ',
      import_material_testing_cut: 'ทดสอบการตัดวัสดุ',
      import_material_testing_engrave: 'ทดสอบการกัดวัสดุ',
      import_material_testing_line: 'ทดสอบเส้นวัสดุ',
      import_material_testing_old: 'ทดสอบการตัดวัสดุ - คลาสสิก',
      import_material_testing_simple_cut: 'ทดสอบการตัดวัสดุ - ง่าย',
      import_promark_example: 'ตัวอย่างของ Promark',
      import_promark_mopa_20w_color: 'การทดสอบสี MOPA 20W',
      import_promark_mopa_60w_color: 'การทดสอบสี MOPA 60W',
      import_promark_mopa_100w_color: 'การทดสอบสี MOPA 100W',
      keyboard_shortcuts: 'ปุ่มลัดบนแป้นพิมพ์',
      layer_color_config: 'ตั้งค่าสี',
      layer_setting: 'เลเยอร์',
      link: {
        beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
        contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
        design_market: 'https://dmkt.io',
        downloads: 'https://flux3dp.com/downloads/',
        forum: 'https://www.facebook.com/groups/flux.laser/',
        help_center: 'https://helpcenter.flux3dp.com/',
        shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
      },
      log: {
        camera: 'กล้อง',
        cloud: 'คลาวด์',
        discover: 'ค้นพบ',
        hardware: 'ฮาร์ดแวร์',
        network: 'เครือข่าย',
        player: 'เครื่องเล่น',
        robot: 'หุ่นยนต์',
        usb: 'USB',
        usblist: 'รายการ USB',
      },
      machine_info: 'ข้อมูลเครื่อง',
      machines: 'เครื่อง',
      manage_account: 'จัดการบัญชี',
      material_test: 'การทดสอบวัสดุ',
      minimize: 'ย่อหน้าต่าง',
      my_account: 'บัญชีของฉัน',
      network_testing: 'ทดสอบการตั้งค่าเครือข่าย',
      object: 'วัตถุ',
      offset: 'ชดเชย',
      open: 'เปิด',
      paste: 'วาง',
      paste_in_place: 'วางแทน',
      path: 'เส้นทาง',
      photo_edit: 'รูปภาพ',
      preferences: 'ตั้งค่า',
      promark_color_test: 'การทดสอบสี Promark',
      questionnaire: 'แบบสอบถามป้อนกลับ',
      quit: 'ออกจากระบบ',
      recent: 'เปิดล่าสุด',
      redo: 'ทําซ้ํา',
      reload_app: 'รีโหลดแอป',
      reset: 'รีเซ็ต',
      rotary_setup: 'การตั้งค่าการหมุน',
      rotate: 'หมุน',
      samples: 'ตัวอย่าง',
      save_as: 'บันทึกเป็น...',
      save_scene: 'บันทึก',
      save_to_cloud: 'บันทึกไปยังคลาวด์',
      scale: 'ปรับมาตราส่วน',
      service: 'บริการ',
      set_as_default: 'ตั้งเป็นค่าเริ่มต้น',
      show_gesture_tutorial: 'แนะนําท่ามือ',
      show_grids: 'แสดงตาราง',
      show_layer_color: 'ใช้สีชั้น',
      show_rulers: 'แสดงตัวบอกขนาด',
      show_start_tutorial: 'แสดงการสอนเริ่มต้น',
      show_ui_intro: 'แสดงการแนะนําหน้าจอ',
      sign_in: 'เข้าสู่ระบบ',
      sign_out: 'ออกจากระบบ',
      software_update: 'อัปเดตซอฟต์แวร์',
      svg_edit: 'SVG',
      switch_to_beta: 'สลับเป็นรุ่นเบต้า',
      switch_to_latest: 'สลับเป็นรุ่นเสถียร',
      tools: {
        box_generator: 'เครื่องสร้างกล่อง',
        code_generator: 'เครื่องสร้างโค้ด',
        material_test_generator: 'ตัวสร้างการทดสอบวัสดุ',
        title: 'เครื่องมือ',
      },
      tutorial: 'คู่มือเริ่มต้น',
      undo: 'เลิกทํา',
      ungroup: 'แยกกลุ่ม',
      update: 'ตรวจสอบการอัปเดต',
      update_firmware: 'อัปเดตเฟิร์มแวร์',
      upload_data: 'อัปโหลดข้อมูล',
      using_beam_studio_api: 'ใช้ Beam Studio API',
      view: 'มุมมอง',
      window: 'หน้าต่าง',
      zoom_in: 'ซูมเข้า',
      zoom_out: 'ซูมออก',
      zoom_with_window: 'ปรับพอดีกับขนาดหน้าต่างอัตโนมัติ',
    },
    preview: 'แสดงตัวอย่าง',
    preview_press_esc_to_stop: 'กด ESC เพื่อหยุดการแสดงตัวอย่างกล้อง.',
    rename_tab: 'เปลี่ยนชื่อแท็บ',
    select_machine: 'เลือกเครื่อง',
    tag_names: {
      dxf: 'วัตถุ DXF',
      ellipse: 'วงรี',
      g: 'กลุ่ม',
      image: 'รูปภาพ',
      line: 'เส้น',
      multi_select: 'วัตถุหลายอย่าง',
      no_selection: 'ไม่มีการเลือก',
      pass_through_object: 'วัตถุผ่านทะลุ',
      path: 'เส้นทาง',
      polygon: 'รูปหลายเหลี่ยม',
      rect: 'สี่เหลี่ยม',
      svg: 'วัตถุ SVG',
      text: 'ข้อความ',
      text_path: 'ข้อความบนเส้นทาง',
      use: 'วัตถุที่นําเข้า',
    },
    task_preview: 'ตัวอย่างเส้นทาง',
    titles: {
      settings: 'ตั้งค่า',
    },
    untitled: 'ไม่มีชื่อ',
  },
  topmenu: {
    credit: 'Beam Studio เป็นไปได้ด้วยโครงการโอเพนซอร์ส <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> และ <a target="_blank" href="https://flux3dp.com/credits/">ซอฟต์แวร์โอเพนซอร์ส</a> อื่นๆ',
    device: {
      download_log_canceled: 'ยกเลิกการดาวน์โหลดบันทึกแล้ว',
      download_log_error: 'เกิดข้อผิดพลาดที่ไม่รู้จัก โปรดลองอีกครั้งในภายหลัง',
      log: {
        usblist: 'รายการ USB',
      },
      network_test: 'ทดสอบเครือข่าย',
    },
    file: {
      all_files: 'ทุกไฟล์',
      clear_recent: 'ล้างที่เปิดล่าสุด',
      converting: 'กําลังแปลงเป็นรูปภาพ...',
      fcode_files: 'FLUX รหัส',
      import: 'นําเข้า',
      jpg_files: 'JPG',
      label: 'ไฟล์',
      path_not_exit: 'เส้นทางนี้ไม่ดูเหมือนจะมีอยู่บนดิสก์อีกต่อไป',
      png_files: 'PNG',
      save_fcode: 'ส่งออกงาน FLUX',
      save_jpg: 'ส่งออก JPG',
      save_png: 'ส่งออก PNG',
      save_scene: 'บันทึกฉาก',
      save_svg: 'ส่งออก SVG',
      scene_files: 'Beam Studio ฉาก',
      svg_files: 'SVG',
    },
    ok: 'ตกลง',
    version: 'เวอร์ชัน',
  },
  tutorial: {
    ask_retry_calibration: 'คุณต้องการปรับเทียบกล้องใหม่อีกครั้งหรือไม่?',
    camera_calibration_failed: 'การปรับเทียบกล้องล้มเหลว',
    gesture: {
      click: 'แตะเพื่อเลือกวัตถุ',
      drag: 'ลากเพื่อเลือกวัตถุหลายรายการ',
      hold: 'กดค้างเพื่อเปิดเมนูบริบท',
      pan: 'เลื่อนดูผังงานด้วยสองนิ้ว',
      zoom: 'หุบ/ขยายนิ้วเพื่อซูมเข้า/ออกผังงาน',
    },
    links: {
      adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
    },
    look_for_machine: 'กําลังค้นหาเครื่องสําหรับตัวช่วยสอน...',
    needNewInterfaceTutorial: 'คุณต้องการเริ่มต้นการสอนสำหรับอินเตอร์เฟซใหม่ของ Beam Studio หรือไม่?<br/>หรือคุณสามารถข้ามไปก่อนแล้วเริ่มภายหลังได้โดยการคลิกที่ "ช่วยเหลือ" > "แสดงการสอนเบื้องต้น"',
    needNewUserTutorial: 'เราขอแนะนำให้ผู้ใช้สองเลนส์ในการสะท้อนภาพเพื่อปรับตำแหน่งให้พอดี<br/>คุณต้องการใช้พารามิเตอร์เลนส์ปัจจุบันหรือไม่?',
    newInterface: {
      add_new_layer: 'เพิ่มเลเยอร์ใหม่',
      align_controls: 'ตัวควบคุมการจัดเรียง',
      basic_shapes: 'รูปทรงพื้นฐาน',
      camera_preview: 'ดูตัวอย่างจากกล้อง',
      drag_to_sort: 'ลากเพื่อเรียงลําดับ',
      end_alert: 'คุณแน่ใจหรือไม่ว่าต้องการสิ้นสุดการแนะนํา UI ใหม่?',
      flip: 'พลิก',
      group_controls: 'ตัวควบคุมกลุ่ม',
      layer_controls: 'คลิกขวาเพื่อเลือกตัวควบคุมเลเยอร์:\nทําสําเนา / ผสาน / ล็อก / ลบเลเยอร์',
      object_actions: 'การกระทําวัตถุ',
      pen_tool: 'เครื่องมือปากกา',
      rename_by_double_click: 'เปลี่ยนชื่อโดยดับเบิ้ลคลิก',
      select_image_text: 'เลือก / รูปภาพ / ข้อความ',
      select_machine: 'เลือกเครื่อง',
      shape_operation: 'การดําเนินการรูปทรง',
      start_work: 'เริ่มทํางาน',
      switch_between_layer_panel_and_object_panel: 'สลับระหว่างแผงเลเยอร์และแผงวัตถุ',
    },
    newUser: {
      add_new_layer: 'เพิ่มชั้นใหม่',
      adjust_focus: '2. ปรับโฟกัส',
      close_cover: '3. ปิดฝาครอบ',
      drag_to_draw: 'ลากเพื่อวาด',
      draw_a_circle: 'วาดวงกลม',
      draw_a_rect: 'วาดสี่เหลี่ยมผืนผ้า',
      end_alert: 'คุณแน่ใจหรือไม่ว่าต้องการสิ้นสุดการสอน?',
      end_preview_mode: 'สิ้นสุดโหมดดูตัวอย่าง',
      infill: 'เปิดใช้งานช่องว่างภายใน',
      please_select_wood_cutting: 'โปรดเลือกค่าที่ตั้งล่วงหน้า "ไม้ - ตัด"',
      please_select_wood_engraving: 'โปรดเลือกค่าที่ตั้งล่วงหน้า "ไม้ - กัดขูด"',
      preview_the_platform: 'ดูตัวอย่างแพลตฟอร์ม',
      put_wood: '1. วางตัวอย่างไม้',
      send_the_file: 'ส่งไฟล์',
      set_preset_wood_cut: 'ตั้งค่าที่กําหนดไว้ล่วงหน้า: ไม้ - ตัด',
      set_preset_wood_engraving: 'ตั้งค่าที่กําหนดไว้ล่วงหน้า: ไม้ - กัดขูด',
      switch_to_layer_panel: 'สลับไปที่แผงชั้น',
      switch_to_object_panel: 'สลับไปยังหน้าต่างการจัดการอ็อบเจกต์',
      switch_to_preview_mode: 'สลับไปที่โหมดดูตัวอย่าง',
    },
    next: 'ถัดไป',
    retry: 'ลองใหม่อีกครั้ง',
    set_connection: 'ตั้งค่าการเชื่อมต่อ',
    skip: 'ข้าม',
    skip_tutorial: 'คุณได้ข้ามตัวช่วยสอนแล้ว คุณสามารถเริ่มตัวช่วยสอนได้ตลอดเวลาโดยคลิก "ช่วยเหลือ" > "แสดงตัวช่วยสอนเริ่มต้น"',
    suggest_calibrate_camera_first: 'เราขอแนะนำให้ผู้ใช้ทำการปรับแต่งกล้องและปรับโฟกัสใหม่ก่อนการดูตัวอย่างเพื่อให้ได้ผลลัพธ์ที่ดีที่สุด<br/>ยืนยันเพื่อดำเนินการปรับแต่งตอนนี้หรือไม่?<br/>หรือคุณสามารถข้ามไปก่อนแล้วทำภายหลังได้โดยการคลิกที่ "เมนู" > "เครื่อง" > [เครื่องของคุณ] > "ปรับแต่งกล้อง"',
    tutorial_complete: 'นั่นคือทั้งหมดสําหรับบทสอน ตอนนี้เป็นเวลาที่จะสร้าง!',
    unable_to_find_machine: 'ไม่พบเครื่องสําหรับตัวช่วยสอน คุณต้องการไปที่หน้าตั้งค่าการเชื่อมต่อ ลองใหม่อีกครั้ง หรือข้ามตัวช่วยสอนหรือไม่?',
    welcome: 'ยินดีต้อนรับ',
  },
  update: {
    cannot_reach_internet: '#823 เซิร์ฟเวอร์เข้าถึงไม่ได้<br/>โปรดตรวจสอบการเชื่อมต่ออินเทอร์เน็ต',
    download: 'อัปเดตออนไลน์',
    firmware: {
      caption: 'มีการอัปเดตเฟิร์มแวร์ของเครื่อง',
      confirm: 'อัปโหลด',
      firmware_too_old_update_by_sdcard: 'เวอร์ชันเฟิร์มแวร์เก่าเกินไป โปรดอัปเดตเฟิร์มแวร์โดยใช้การ์ด SD',
      force_update_message: '#814 โปรดอัปเดตเครื่องของคุณเป็นเวอร์ชันเฟิร์มแวร์ล่าสุด',
      latest_firmware: {
        cant_get_latest: 'ไม่สามารถดึงข้อมูลเฟิร์มแวร์ล่าสุด',
        caption: 'อัปเดตเฟิร์มแวร์เครื่อง',
        message: 'คุณมีเฟิร์มแวร์เครื่องล่าสุดอยู่แล้ว',
        still_update: 'อัปเดต',
      },
      message_pattern_1: '"%s" พร้อมสําหรับการอัปเดตเฟิร์มแวร์แล้ว',
      message_pattern_2: '%s เฟิร์มแวร์ v%s มีให้ใช้แล้ว - คุณมี v%s',
      too_old_for_web: 'เวอร์ชันเฟิร์มแวร์เครื่องปัจจุบันของคุณคือ v%s.\nหากต้องการใช้รุ่นออนไลน์ของ Beam Studio โปรดอัปเดตเฟิร์มแวร์เครื่องเป็นเวอร์ชันล่าสุด',
      update_fail: '#822 การอัปเดตล้มเหลว',
      update_success: 'อัปโหลดเฟิร์มแวร์สําเร็จแล้ว',
      upload_file: 'อัปโหลดเฟิร์มแวร์ (*.bin / *.fxfw)',
    },
    install: 'ติดตั้ง',
    later: 'ภายหลัง',
    preparing: 'กําลังเตรียมการ...',
    release_note: 'บันทึกการอัปเดต:',
    skip: 'ข้ามเวอร์ชันนี้',
    software: {
      available_switch: 'Beam Studio v%s พร้อมใช้งานแล้ว คุณกําลังใช้ v%s คุณต้องการสลับไปใช้เวอร์ชันนี้หรือไม่?',
      available_update: 'Beam Studio v%s พร้อมใช้งานแล้ว คุณกําลังใช้ v%s คุณต้องการดาวน์โหลดอัปเดตหรือไม่?',
      caption: 'มีการอัปเดต Beam Studio',
      check_update: 'ตรวจสอบการอัปเดต',
      checking: 'กําลังตรวจสอบการอัปเดต',
      downloading: 'กําลังดาวน์โหลดการอัปเดตในพื้นหลัง คุณสามารถคลิก "ตกลง" เพื่อดําเนินการต่อ',
      install_or_not: 'พร้อมสําหรับการอัปเดต คุณต้องการรีสตาร์ทและอัปเดตเลยหรือไม่?',
      no: 'ไม่',
      no_response: 'เชื่อมต่อกับเซิร์ฟเวอร์ล้มเหลว โปรดตรวจสอบการตั้งค่าเครือข่ายของคุณ',
      not_found: 'คุณกําลังใช้เวอร์ชันล่าสุดของ Beam Studio',
      switch_or_not: 'พร้อมสําหรับการสลับ คุณต้องการรีสตาร์ทและสลับเลยหรือไม่?',
      switch_version: 'สลับเวอร์ชัน',
      switch_version_not_found: 'ไม่พบเวอร์ชันที่สามารถสลับได้',
      update_for_ador: 'เวอร์ชันซอฟต์แวร์ปัจจุบัน %s ไม่สามารถใช้งานได้ โปรดดาวน์โหลดเวอร์ชันล่าสุดของ Beam Studio สําหรับ Ador',
      yes: 'ใช่',
    },
    update: 'อัปเดต',
    updating: 'กําลังอัปเดต...',
    upload: 'อัปโหลด',
  },
  web_cam: {
    no_device: 'ไม่สามารถตรวจพบอุปกรณ์กล้องได้ โปรดเชื่อมต่อกล้องใหม่และลองอีกครั้ง',
    no_permission: 'Beam Studio ไม่มีสิทธิ์เข้าถึงกล้อง โปรดตรวจสอบให้แน่ใจว่า Beam Studio ได้รับสิทธิ์ในเบราว์เซอร์หรือในการตั้งค่าระบบ',
  },
  z_speed_limit_test: {
    alert_before: 'ก่อนเริ่มการแกะสลักเส้นโค้ง ระบบจะทำการทดสอบโหลดเพื่อป้องกันการสูญเสียขั้นตอนเนื่องจากน้ำหนักที่มากเกินไป.',
    alert_failed: 'น้ำหนักของวัตถุปัจจุบันเกินขีดจำกัดโหลดสำหรับความเร็วแกน Z ปัจจุบัน กรุณาลดความเร็วแกน Z ในแผงควบคุมเครื่องหรือเปลี่ยนวัตถุที่จะแกะสลักก่อนทดสอบใหม่',
    ignore: 'ละเว้น',
    retest: 'ทดสอบอีกครั้ง',
    testing: 'กำลังดำเนินการทดสอบโหลด...',
  },
};

export default lang;
