// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-views-beambox-Right-Panels-DimensionPanel-FlipButtons-module__container--Zwlgy{display:flex;align-items:center;gap:4px}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/views/beambox/Right-Panels/DimensionPanel/FlipButtons.module.scss"],"names":[],"mappings":"AAAA,8GACE,YAAA,CACA,kBAAA,CACA,OAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_-_-packages-core-src-web-app-views-beambox-Right-Panels-DimensionPanel-FlipButtons-module__container--Zwlgy"
};
export default ___CSS_LOADER_EXPORT___;
