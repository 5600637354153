export default {
  // folder
  NOT_EXIST: 'NOT_EXIST',
  OK: 'OK',
  // Status
  READY: 'READY',
  status: {
    ABORTED: 128,
    ABORTING: 130,
    ALARM: 256,
    COMPLETED: 64,
    COMPLETING: 66,
    FATAL: 512,
    IDLE: 0,
    INIT: 1,
    PAUSED: 32,
    PAUSED_FROM_RUNNING: 48,
    PAUSED_FROM_STARTING: 36,
    PAUSING_FROM_RUNNING: 50,
    PAUSING_FROM_STARTING: 38,
    PREPARING: 68,
    RESUME_TO_RUNNING: 18,
    RESUME_TO_STARTING: 6,
    RUNNING: 16,
    STARTING: 4,
    TASK_CARTDRIDGE_IO: -17,
    TASK_MAINTAIN: -1,
    TASK_RAW: -10,
    TASK_REDLIGHT: -15, // Actually red light & z speed limit test
    TASK_SCAN: -2, // TODO: this is for delta, could be removed?
    TOOLHEAD_CHANGE: 1024,
  },
  statusColor: {
    '-17': 'grey',
    '-10': 'grey',
    '-2': 'grey',
    '-1': 'orange',
    0: 'grey',
    1: 'grey',
    4: 'blue',
    6: 'blue',
    16: 'blue',
    18: 'blue',
    32: 'orange',
    36: 'orange',
    38: 'orange',
    48: 'orange',
    50: 'orange',
    64: 'green',
    66: 'blue',
    128: 'red',
    256: 'red',
    512: 'red',
    1024: 'orange',
  },
};
