// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-beambox-top-bar-TopBarHints-module__container--PMkMm{position:absolute;z-index:1;top:80px;margin-left:70px;height:40px;line-height:40px;color:#333;font-weight:bold;font-size:13px;letter-spacing:.95px;text-shadow:-1px 0 #fff,0 1px #fff,1px 0 #fff,0 -1px #fff}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/beambox/top-bar/TopBarHints.module.scss"],"names":[],"mappings":"AAAA,+FACE,iBAAA,CACA,SAAA,CACA,QAAA,CACA,gBAAA,CACA,WAAA,CACA,gBAAA,CACA,UAAA,CACA,gBAAA,CACA,cAAA,CACA,oBAAA,CACA,yDACE","sourcesContent":[".container {\n  position: absolute;\n  z-index: 1;\n  top: 80px;\n  margin-left: 70px;\n  height: 40px;\n  line-height: 40px;\n  color: #333333;\n  font-weight: bold;\n  font-size: 13px;\n  letter-spacing: 0.95px;\n  text-shadow:\n    -1px 0 white,\n    0 1px white,\n    1px 0 white,\n    0 -1px white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_-_-packages-core-src-web-app-components-beambox-top-bar-TopBarHints-module__container--PMkMm"
};
export default ___CSS_LOADER_EXPORT___;
