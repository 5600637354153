import type { ILang } from '@core/interfaces/ILang';

const lang: ILang = {
  alert: {
    abort: 'Ακύρωση',
    cancel: 'Ακύρωση',
    caption: 'Σφάλμα',
    close: 'Κλείσιμο',
    confirm: 'Επιβεβαίωση',
    dont_save: 'Μην αποθηκεύσετε',
    dont_show_again: 'Να μην εμφανιστεί ξανά',
    duplicated_preset_name: 'Όνομα προεπιλογής υπάρχει ήδη',
    error: 'Σφάλμα',
    info: 'ΠΛΗΡΟΦΟΡΙΕΣ',
    instruction: 'Οδηγία',
    learn_more: 'Μάθετε περισσότερα',
    no: 'Όχι',
    ok: 'Εντάξει',
    ok2: 'Εντάξει',
    oops: 'Ωχ...',
    retry: 'Επανάληψη',
    save: 'Αποθήκευση',
    stop: 'Σταματήστε',
    warning: 'ΠΡΟΕΙΔΟΠΟΙΗΣΗ',
    yes: 'Ναι',
  },
  auto_fit: {
    artwork_size: 'Μέγεθος έργου',
    error_tip1: '1. Είναι το έργο τοποθετημένο σωστά στο υλικό;',
    error_tip2: '2. Είναι τα περιγράμματα του υλικού αρκετά καθαρά για αναγνώριση;',
    failed_to_find_contour: 'Αποτυχία εύρεσης περιγράμματος για αυτόματη προσαρμογή',
    learn_more: 'Προβολή οδηγού.',
    learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    offset_x: 'Μετατόπιση X',
    offset_y: 'Μετατόπιση Y',
    pattern_size: 'Μέγεθος μοτίβου',
    position_artwork: 'Τοποθετήστε το έργο τέχνης',
    position_step1: 'Ρυθμίστε το μέγεθος και τη θέση του έργου τέχνης σας στο μοτίβο.',
    position_step2: 'Κάντε κλικ στο «Εφαρμογή» για να εφαρμόσετε το Auto Fit σε αυτό το μοτίβο.',
    preview_first: 'Παρακαλώ εκτελέστε πρώτα την προεπισκόπηση της κάμερας.',
    reset_position: 'Επαναφορά θέσης',
    rotation: 'Περιστροφή',
    select_a_pattern: 'Επιλέξτε ένα μοτίβο',
    selected_artwork: 'Επιλεγμένο έργο τέχνης',
    step1: 'Επιλέξτε ένα μοτίβο όπου θέλετε να τοποθετήσετε το έργο τέχνης.',
    step2: 'Εάν δεν βρεθεί μοτίβο, προβάλετε ξανά την κάμερα και εφαρμόστε την Αυτόματη Προσαρμογή.',
    step3: 'Εάν εξακολουθεί να μην βρίσκει το σωστό μοτίβο, βεβαιωθείτε ότι το αντικείμενό σας αναγνωρίζεται καθαρά και ότι η περιοχή εργασίας είναι καθαρή από συντρίμμια.',
    title: 'Έξυπνη Τοποθέτηση',
  },
  barcode_generator: {
    bar_height: 'Ύψος γραμμής',
    bar_width: 'Πλάτος γραμμής',
    barcode: {
      invalid_value: 'Η τιμή δεν είναι έγκυρη για την επιλεγμένη μορφή.',
    },
    font: 'Γραμματοσειρά',
    font_size: 'Μέγεθος γραμματοσειράς',
    hide_text: 'Απόκρυψη κειμένου',
    invert_color: 'Αντιστροφή χρώματος',
    text_margin: 'Περιθώριο κειμένου',
  },
  beambox: {
    announcement_panel: {
      title: 'Ανακοίνωση',
    },
    banner: {
      auto_feeder: 'Λειτουργία Αυτόματης Τροφοδοσίας',
      camera_preview: 'Προεπισκόπηση',
      camera_preview_borderless_mode: '(Ανοιχτός Πάτος)',
      curve_engraving: 'Λειτουργία Χάραξης Καμπύλης',
      pass_through: 'Λειτουργία Διέλευσης',
      rotary: 'Λειτουργία Περιστροφής',
    },
    bottom_right_panel: {
      convert_text_to_path_before_export: 'Μετατρέψτε το κείμενο σε μονοπάτι πριν από την εξαγωγή',
      export_file_error_ask_for_upload: 'Αποτυχία εξαγωγής εργασίας. Είστε πρόθυμοι να παρέχετε τη σκηνή εργασίας στην ομάδα ανάπτυξης για αναφορά σφαλμάτων;',
      retreive_image_data: 'Ανάκτηση δεδομένων εικόνας...',
    },
    context_menu: {
      copy: 'Αντιγραφή',
      cut: 'Αποκοπή',
      delete: 'Διαγραφή',
      duplicate: 'Αντιγραφή',
      group: 'Ομάδα',
      move_back: 'Μετακινήστε πίσω',
      move_down: 'Μετακινήστε κάτω',
      move_front: 'Μετακινήστε μπροστά',
      move_up: 'Μετακινήστε πάνω',
      paste: 'Επικόλληση',
      paste_in_place: 'Επικόλληση στη θέση',
      ungroup: 'Κατάργηση ομαδοποίησης',
    },
    document_panel: {
      add_on: 'Πρόσθετες λειτουργίες',
      auto_feeder: 'Αυτόματος τροφοδότης',
      auto_feeder_scale: 'Κλίμακα αυτόματου τροφοδότη',
      auto_feeder_url: 'https://support.flux3dp.com/hc/en-us/articles/11688230498575',
      borderless_mode: 'Ανοιχτό κάτω μέρος',
      current_position: 'Τρέχουσα Θέση',
      disable: 'Απενεργοποίηση',
      document_settings: 'Ρυθμίσεις εγγράφου',
      enable: 'Ενεργοποίηση',
      enable_autofocus: 'Αυτόματη εστίαση',
      enable_diode: 'Λέιζερ διόδου',
      engrave_dpi: 'Ανάλυση',
      frame_before_start: 'Πλαίσιο πριν από την εκτέλεση',
      frame_before_start_url: 'https://support.flux3dp.com/hc/en-us/articles/11494925637135',
      high: 'Υψηλή',
      job_origin: 'Αφετηρία Εργασίας',
      laser_source: 'Πηγή λέιζερ',
      low: 'Χαμηλή',
      machine: 'Μηχανή',
      medium: 'Μέτρια',
      notification: {
        changeFromPrintingWorkareaTitle: 'Θέλετε να μετατρέψετε τα επίπεδα εκτύπωσης σε επίπεδα λέιζερ;',
      },
      origin: 'Αρχή',
      pass_through: 'Διέλευση',
      pass_through_height_desc: 'Εισαγάγετε το μήκος του αντικειμένου για να επεκτείνετε την περιοχή εργασίας.',
      rotary_mode: 'Περιστροφική λειτουργία',
      scale: 'Skala',
      start_from: 'Ξεκινάει Από',
      start_position: 'Θέση Έναρξης',
      start_work_button: 'Κουμπί Εκτέλεσης',
      ultra: 'Πολύ υψηλή',
      workarea: 'Περιοχή εργασίας',
    },
    image_trace_panel: {
      back: 'Πίσω',
      brightness: 'Φωτεινότητα',
      cancel: 'Ακύρωση',
      contrast: 'Αντίθεση',
      next: 'Επόμενο',
      okay: 'Εντάξει',
      threshold: 'Κατώφλι',
      tuning: 'Ρυθμίσεις',
    },
    layer_color_config_panel: {
      add: 'Προσθήκη',
      add_config: 'Προσθήκη χρώματος',
      color: 'Χρώμα',
      default: 'Επαναφορά στις προεπιλογές',
      in_use: 'Αυτό το χρώμα χρησιμοποιείται.',
      layer_color_config: 'Διαμορφώσεις χρωμάτων στρώσης',
      no_input: 'Παρακαλώ εισάγετε έγκυρο κωδικό χρώματος δεκαεξαδικού.',
      power: 'Ισχύς',
      repeat: 'Επανάληψη',
      speed: 'Ταχύτητα',
      sure_to_delete: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτή τη ρύθμιση χρώματος;',
      sure_to_reset: 'Θα χαθούν όλες οι προσαρμοσμένες ρυθμίσεις. Είστε σίγουροι ότι θέλετε επαναφορά στις προεπιλογές;',
    },
    left_panel: {
      borderless_blind_area: 'Μη χαρακτική περιοχή',
      borderless_preview: 'Προεπισκόπηση κάμερας ανοιχτής λειτουργίας κάτω μέρους',
      diode_blind_area: 'Τυφλή περιοχή υβριδικού laser προσθήκης',
      ellipse: 'Έλλειψη',
      image: 'Εικόνα',
      label: {
        adjust_height: 'Ρύθμιση ύψους',
        array: 'Πίνακας',
        boxgen: 'Boxgen',
        choose_camera: 'Κάμερα',
        clear_preview: 'Εκκαθάριση προεπισκόπησης',
        cursor: 'Επιλογή',
        curve_engraving: {
          clear_area: 'Εκκαθάριση επιλεγμένης περιοχής',
          exit: 'Έξοδος',
          preview_3d_curve: 'Προεπισκόπηση χάραξης καμπύλης',
          select_area: 'Επιλέξτε περιοχή',
          title: 'Χάραξη καμπύλης',
        },
        end_preview: 'Τέλος προεπισκόπησης',
        line: 'Γραμμή',
        live_feed: 'Ζωντανή μετάδοση',
        my_cloud: 'Το Cloud μου',
        oval: 'Στρογγυλεμένο ορθογώνιο',
        pass_through: 'Διέλευση',
        pen: 'Πένα',
        photo: 'Εικόνα',
        polygon: 'Πολύγωνο',
        preview: 'Προεπισκόπηση κάμερας',
        qr_code: 'Κωδικός QR',
        rect: 'Ορθογώνιο',
        shapes: 'Σχήματα',
        text: 'Κείμενο',
        trace: 'Ιχνηλάτηση εικόνας',
      },
      line: 'Γραμμή',
      rectangle: 'Ορθογώνιο',
      text: 'Κείμενο',
      unpreviewable_area: 'Τυφλή περιοχή',
    },
    network_testing_panel: {
      average_response: 'Μέσος χρόνος απόκρισης',
      cannot_connect_1: '#840 Αποτυχία σύνδεσης στην IP διεύθυνση-στόχο.',
      cannot_connect_2: '#840 Αποτυχία σύνδεσης στην IP διεύθυνση-στόχο. Παρακαλώ βεβαιωθείτε ότι ο στόχος βρίσκεται στο ίδιο δίκτυο.',
      cannot_get_local: 'Αποτυχία πρόσβασης στην τοπική διεύθυνση IP.',
      connection_quality: 'Ποιότητα σύνδεσης',
      device_not_on_list: '#842 Το μηχάνημα δεν βρίσκεται στη λίστα, αλλά η ποιότητα σύνδεσης είναι >70 και ο μέσος χρόνος απόκρισης είναι <100ms',
      empty_ip: '#818 Παρακαλώ εισάγετε πρώτα τη διεύθυνση IP της συσκευής προορισμού.',
      end: 'Τέλος',
      fail_to_start_network_test: '#817 Αποτυχία έναρξης δοκιμής δικτύου.',
      hint_connect_camera_timeout: 'Χρονικό όριο συμβαίνει κατά την εκκίνηση της προεπισκόπησης κάμερας;',
      hint_connect_failed_when_sending_job: 'Αποτυχία σύνδεσης κατά την αποστολή εργασίας;',
      hint_device_often_on_list: 'Το μηχάνημα συχνά δεν βρίσκεται στη λίστα;',
      insert_ip: 'Διεύθυνση IP προορισμού:',
      invalid_ip: '#818 Μη έγκυρη διεύθυνση IP',
      ip_startswith_169: '#843 Η διεύθυνση IP του μηχανήματος ξεκινά με 169.254',
      link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
      link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
      link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
      linux_permission_hint: 'Αυτό το σφάλμα συνήθως προκύπτει λόγω ανεπαρκών δικαιωμάτων. \nΕυγενικά εκτελέστε "sudo beam-studio --no-sandbox" στο τερματικό για να λάβετε άδειες και να εκτελέσετε δοκιμή δικτύου.',
      local_ip: 'Τοπική Διεύθυνση IP:',
      network_testing: 'Δοκιμή Δικτύου',
      network_unhealthy: '#841 Ποιότητα σύνδεσης <70 ή μέσος χρόνος απόκρισης >100ms',
      start: 'Έναρξη',
      test_completed: 'Ολοκλήρωση δοκιμής',
      test_fail: 'Αποτυχία δοκιμής',
      testing: 'Έλεγχος Δικτύου...',
    },
    object_panels: {
      lock_desc: 'Διατήρηση του λόγου πλάτους και ύψους (SHIFT)',
      text_to_path: {
        check_thumbnail_warning: 'Ορισμένα κείμενα άλλαξαν σε άλλες γραμματοσειρές κατά τη μετατροπή κειμένων σε διαδρομές και ορισμένοι χαρακτήρες ενδέχεται να μην μετατραπούν κανονικά. \nΕλέγξτε ξανά την προεπισκόπηση εικόνας πριν στείλετε την εργασία.',
        error_when_parsing_text: 'Σφάλμα κατά τη μετατροπή κειμένου σε διαδρομή',
        font_substitute_pop: 'Το κείμενό σας περιέχει χαρακτήρες που δεν υποστηρίζονται από την τρέχουσα γραμματοσειρά. <br/>Θα θέλατε να χρησιμοποιήσετε το <strong>%s</strong> ως υποκατάστατο;',
        retry: 'Παρακαλώ δοκιμάστε αργότερα ή επιλέξτε άλλη γραμματοσειρά',
        use_current_font: 'Χρήση Τρέχουσας Γραμματοσειράς',
      },
      wait_for_parsing_font: 'Ανάλυση γραμματοσειράς... Παρακαλώ περιμένετε ένα δευτερόλεπτο',
    },
    path_preview: {
      current_position: 'Τρέχουσα θέση',
      cut_distance: 'Απόσταση κοπής',
      cut_time: 'Χρόνος κοπής',
      end_preview: 'Τέλος προεπισκόπησης',
      estimated_time: 'Συνολικός εκτιμώμενος χρόνος',
      invert: 'Αντιστροφή',
      pause: 'Παύση',
      play: 'Αναπαραγωγή',
      play_speed: 'Ταχύτητα αναπαραγωγής',
      preview_info: 'Πληροφορίες προεπισκόπησης',
      rapid_distance: 'Απόσταση μετακίνησης',
      rapid_time: 'Χρόνος μετακίνησης',
      remark: '* Όλες οι πληροφορίες είναι εκτιμήσεις για αναφορά.',
      size: 'Μέγεθος',
      start_here: 'Ξεκινήστε εδώ',
      stop: 'Σταμάτημα',
      travel_path: 'Διαδρομή μετακίνησης',
    },
    photo_edit_panel: {
      aspect_ratio: 'Αναλογία διαστάσεων',
      brightness: 'Φωτεινότητα',
      brightness_and_contrast: 'Φωτεινότητα / Αντίθεση',
      cancel: 'Ακύρωση',
      circumference: 'Περίμετρος',
      compare: 'Σύγκριση',
      contrast: 'Αντίθεση',
      crop: 'Περικοπή',
      curve: 'Καμπύλη',
      diameter: 'Διάμετρος',
      free: 'Ελεύθερο',
      invert: 'Αντιστροφή χρωμάτων',
      okay: 'Εντάξει',
      original: 'Αρχικό',
      phote_edit: 'Επεξεργασία φωτογραφίας',
      processing: 'Επεξεργασία',
      radius: 'Ακτίνα',
      rotary_warped: 'Περιστροφική Παραμόρφωση',
      rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
      sharpen: 'Εστίαση',
      sharpness: 'Ευκρίνεια',
      start: 'Έναρξη',
      warp: 'Παραμόρφωση',
    },
    popup: {
      ai_credit: {
        buy_link: 'https://member.flux3dp.com/en-US/credit',
        go: 'Μετάβαση',
        insufficient_credit: 'Έχετε εξαντλήσει την πίστωση',
        insufficient_credit_msg: 'Δεν μπορείτε να χρησιμοποιήσετε το %s. Μεταβείτε στο κέντρο μελών και αγοράστε πίστωση AI.',
        relogin_to_use: 'Παρακαλούμε συνδεθείτε ξανά για να χρησιμοποιήσετε αυτήν τη λειτουργία.',
      },
      auto_feeder_origin: 'Χρησιμοποιήστε την προεπισκόπηση πλαισίου για να ελέγξετε τη διαδρομή και την περιοχή της κεφαλής λέιζερ και στη συνέχεια προσαρμόστε τη θέση του υλικού ή τη θέση εκκίνησης της χάραξης βάσει των αποτελεσμάτων για να αποφύγετε συγκρούσεις κατά τη διαδικασία χάραξης.',
      auto_switch_tab: {
        message: 'Μια νέα επιλογή για αυτόματη εναλλαγή μεταξύ του πίνακα Επιπέδων και του πίνακα Αντικειμένων έχει προστεθεί στις Προτιμήσεις. Αυτή η επιλογή είναι απενεργοποιημένη από προεπιλογή. Θέλετε να ενεργοποιήσετε την αυτόματη εναλλαγή τώρα; <br/>Μπορείτε να αλλάξετε αυτήν τη ρύθμιση οποιαδήποτε στιγμή στις Προτιμήσεις.',
        title: 'Αυτόματη Εναλλαγή Πάνελ Επίπεδου και Αντικειμένου',
      },
      backend_connect_failed_ask_to_upload: '#802 Συνεχίζουν να προκύπτουν σφάλματα κατά τη σύνδεση με το backend. Θέλετε να μεταφορτώσετε την αναφορά σφαλμάτων;',
      backend_error_hint: 'Οι λειτουργίες ενδέχεται να μην λειτουργούν σωστά λόγω σφάλματος backend.',
      both_power_and_speed_too_high: 'Η χρήση χαμηλότερης ισχύος λέιζερ θα επεκτείνει τη διάρκεια ζωής του σωλήνα λέιζερ.  Επίσης, πολύ υψηλή ταχύτητα σε αυτή την ανάλυση μπορεί να έχει ως αποτέλεσμα χαμηλότερη ποιότητα χάραξης.',
      bug_report: 'Αναφορά σφαλμάτων',
      change_workarea_before_preview: 'Η περιοχή εργασίας του %s δεν ταιριάζει με την τρέχουσα περιοχή εργασίας που έχει οριστεί. Θέλετε να αλλάξετε την τρέχουσα περιοχή εργασίας;',
      convert_to_path_fail: 'Αποτυχία μετατροπής σε μονοπάτι.',
      dxf_bounding_box_size_over: 'Το μέγεθος του σχεδίου είναι εκτός της περιοχής εργασίας. Μετακινήστε το σχέδιό σας πιο κοντά στην αρχή στο λογισμικό CAD σας ή βεβαιωθείτε ότι η μονάδα έχει οριστεί σωστά.',
      dxf_version_waring: 'Η έκδοση αυτού του αρχείου DXF δεν είναι 2013, ενδέχεται να υπάρχουν πιθανά προβλήματα ασυμβατότητας.',
      facebook_group_invitation: {
        already_joined: 'Ήδη μέλος',
        join_now: 'Μέσα',
        later: 'Ίσως αργότερα',
        message: 'Συμμετάσχετε στην επίσημη ομάδα μας στο Facebook για να συνδεθείτε με άλλους χρήστες, να συζητήσετε, να μοιραστείτε έργα και να ενημερωθείτε για τα νέα μας. Ανυπομονούμε να σας δούμε!',
        title: 'Συμμετοχή στην επίσημη ομάδα χρηστών',
      },
      import_file_contain_invalid_path: '#808 Το εισαγόμενο αρχείο SVG περιέχει μη έγκυρη διαδρομή εικόνας. Βεβαιωθείτε ότι όλα τα αρχεία εικόνας υπάρχουν ή ενσωματώστε την εικόνα στο αρχείο',
      import_file_error_ask_for_upload: 'Αποτυχία εισαγωγής αρχείου SVG. Θέλετε να παρέχετε το αρχείο στην ομάδα ανάπτυξης για αναφορά σφαλμάτων;',
      layer_by_color: 'Χρώμα',
      layer_by_layer: 'Στρώμα',
      loading_image: 'Φόρτωση εικόνας, παρακαλώ περιμένετε...',
      more_than_two_object: 'Πάρα πολλά αντικείμενα. Υποστήριξη μόνο για 2 αντικείμενα',
      mouse: 'Ποντίκι',
      no_support_text: 'Το Beam Studio δεν υποστηρίζει ετικέτες κειμένου αυτή τη στιγμή. Μεταφέρετε το κείμενο σε μονοπάτι πριν από την εισαγωγή.',
      nolayer: 'Μονό στρώμα',
      not_support_object_type: 'Μη υποστηριζόμενος τύπος αντικειμένου',
      or_turn_off_borderless_mode: ' Ή απενεργοποιήστε τη λειτουργία Ανοιχτό Κάτω Μέρος.',
      pdf2svg: {
        error_pdf2svg_not_found: '#825 Σφάλμα: Η εντολή pdf2svg δεν βρέθηκε. Εγκαταστήστε το pdf2svg με τον διαχειριστή πακέτων σας (π.χ. "yum install pdf2svg" ή "apt-get install pdf2svg").',
        error_when_converting_pdf: '#824 Σφάλμα κατά τη μετατροπή του PDF σε SVG:',
      },
      progress: {
        calculating: 'Υπολογισμός',
        uploading: 'Μεταφόρτωση',
      },
      questionnaire: {
        caption: 'Βοηθήστε μας να βελτιώσουμε την εφαρμογή',
        message: 'Βοηθήστε μας να βελτιώσουμε την εφαρμογή συμπληρώνοντας το ερωτηματολόγιο',
        no_questionnaire_available: 'Δεν υπάρχει διαθέσιμο ερωτηματολόγιο αυτή τη στιγμή.',
        unable_to_get_url: 'Αποτυχία λήψης του συνδέσμου προς το ερωτηματολόγιο. Ελέγξτε τη σύνδεσή σας στο διαδίκτυο.',
      },
      recommend_downgrade_software: 'Εντοπίσαμε μια παλαιότερη έκδοση υλικολογισμικού. Αντιμετωπίζουμε θέματα συμβατότητας, αλλά προς το παρόν συνιστούμε να επιστρέψετε στο Beam Studio 1.9.5.',
      recommend_upgrade_firmware: 'Εντοπίσαμε μια παλαιότερη έκδοση υλικολογισμικού. Αντιμετωπίζουμε θέματα συμβατότητας, αλλά προς το παρόν συνιστούμε την ενημέρωση στο τελευταίο υλικολογισμικό.',
      save_unsave_changed: 'Θέλετε να αποθηκεύσετε τις μη αποθηκευμένες αλλαγές;',
      select_at_least_two: 'Επιλέξτε δύο αντικείμενα για να συνεχίσετε',
      select_first: 'Επιλέξτε πρώτα ένα αντικείμενο.',
      select_import_method: 'Επιλέξτε στυλ στρώσης:',
      select_import_module: 'Επιλέξτε Μονάδα:',
      sentry: {
        message: 'Συμφωνείτε να μεταφορτώνουμε αυτόματα τις σχετικές πληροφορίες στην ομάδα ανάπτυξης όταν προκύπτουν σφάλματα;',
        title: 'Ας βελτιώσουμε το Beam Studio μαζί',
      },
      should_update_firmware_to_continue: '#814 Το υλικολογισμικό σας δεν υποστηρίζει αυτή την έκδοση του Beam Studio. Παρακαλώ ενημερώστε το υλικολογισμικό για να συνεχίσετε. (Μενού > Μηχάνημα > [Το μηχάνημά σας] > Ενημέρωση υλικολογισμικού)',
      speed_too_high_lower_the_quality: 'Η χρήση πολύ υψηλής ταχύτητας σε αυτή την ανάλυση μπορεί να έχει ως αποτέλεσμα χαμηλότερη ποιότητα χάραξης.',
      still_continue: 'Συνέχεια',
      successfully_uploaded: 'Η μεταφόρτωση του αρχείου ολοκληρώθηκε με επιτυχία.',
      svg_1_1_waring: 'Η έκδοση αυτού του αρχείου SVG είναι v 1.1, ενδέχεται να υπάρχουν πιθανά προβλήματα ασυμβατότητας.',
      svg_image_path_waring: 'Αυτό το αρχείο SVG περιέχει φόρτωση <image> από διαδρομή αρχείου. Αυτό ενδέχεται να προκαλέσει αποτυχίες κατά τη φόρτωση.\nΓια να αποφύγετε αυτόν τον κίνδυνο, χρησιμοποιήστε ενσωματωμένη εικόνα κατά την εξαγωγή SVG.',
      text_to_path: {
        caption: 'Μετατροπέας κειμένου σε μονοπάτι 2.0',
        message: "Το Beam Studio εισάγει τώρα έναν νέο μετατροπέα κειμένου σε μονοπάτι (Μετατροπέας 2.0), ο οποίος παράγει πιο αξιόπιστα αποτελέσματα! Θα θέλατε να τον ενεργοποιήσετε τώρα; \\nΜπορείτε επίσης να προσαρμόσετε αυτήν τη ρύθμιση αργότερα στην ενότητα 'Μετατροπέας κειμένου σε μονοπάτι' εντός των προτιμήσεων.",
      },
      too_fast_for_auto_feeder: 'Η χρήση πολύ υψηλής ταχύτητας σε στρώματα που περιέχουν αντικείμενα διαδρομής μπορεί να οδηγήσει σε μειωμένη ακρίβεια κατά την κοπή.\nΔεν συνιστούμε τη χρήση ταχύτητας μεγαλύτερης από %(limit)s κατά την κοπή με το Auto Feeder.',
      too_fast_for_auto_feeder_and_constrain: 'Τα παρακάτω στρώματα: %(layers)s \nπεριέχουν διανυσματικά μονοπάτια και έχουν ταχύτητα που υπερβαίνει το %(limit)s.\nΗ ταχύτητα κοπής για διανυσματικά μονοπάτια με το Auto Feeder θα περιοριστεί στο %(limit)s.\nΜπορείτε να καταργήσετε αυτό το όριο στις Ρυθμίσεις Προτιμήσεων.',
      too_fast_for_curve: 'Η χρήση πολύ υψηλών ταχυτήτων για στρώματα που περιέχουν αντικείμενα χάραξης καμπυλών μπορεί να μειώσει την ακρίβεια της χάραξης.\nΣυνιστούμε να διατηρείτε την ταχύτητα κάτω από %(limit)s για βέλτιστα αποτελέσματα.',
      too_fast_for_curve_and_constrain: 'Τα ακόλουθα στρώματα: %(layers)s υπερβαίνουν το όριο ταχύτητας %(limit)s.\nΗ ταχύτητα χάραξης για καμπύλες θα περιοριστεί σε %(limit)s. Μπορείτε να καταργήσετε αυτό το όριο στις ρυθμίσεις προτιμήσεων.',
      too_fast_for_path: 'Η χρήση πολύ υψηλής ταχύτητας σε στρώματα που περιέχουν αντικείμενα διαδρομής μπορεί να έχει ως αποτέλεσμα χαμηλότερη ακρίβεια κατά την κοπή. Δεν συνιστούμε τη χρήση ταχύτητας ταχύτερη από %(limit)s κατά την κοπή.',
      too_fast_for_path_and_constrain: 'Τα ακόλουθα στρώματα: %(layers)s \nπεριέχουν αντικείμενα διανυσματικής διαδρομής και έχουν ταχύτητα που υπερβαίνει τα %(limit)s. Η ταχύτητα κοπής των αντικειμένων διανυσματικής διαδρομής θα περιοριστεί στα %(limit)s. Μπορείτε να καταργήσετε αυτόν τον περιορισμό στις Ρυθμίσεις.',
      touchpad: 'TouchPad',
      ungroup_use: 'Αυτό θα καταργήσει την ομαδοποίηση των εισαγόμενων DXF ή SVG. Επειδή το αρχείο ενδέχεται να περιέχει μεγάλο αριθμό στοιχείων, ενδέχεται να χρειαστεί χρόνος για την κατάργηση της ομαδοποίησης. Είστε σίγουροι ότι θέλετε να προχωρήσετε;',
      upload_failed: '#819 Η μεταφόρτωση του αρχείου απέτυχε.',
      upload_file_too_large: '#819 Το αρχείο είναι πολύ μεγάλο για αποστολή.',
      vectorize_shading_image: 'Οι εικόνες με διαβάθμιση χρώματος χρειάζονται περισσότερο χρόνο για να ιχνηλατηθούν και είναι επιρρεπείς στο θόρυβο. Απενεργοποιήστε τη διαβάθμιση της εικόνας πριν εκτελέσετε.',
    },
    rating_panel: {
      description: 'Αν σας αρέσει το Beam Studio, θα εκτιμούσαμε πολύ την αξιολόγησή σας.',
      thank_you: 'Ευχαριστώ!',
      title: 'Απολαμβάνετε το Beam Studio;',
    },
    right_panel: {
      laser_panel: {
        advanced: 'Προηγμένες',
        backlash: 'Οπίσθια κίνηση',
        bi_directional: 'Διπλής Κατεύθυνσης',
        by: 'Κατά',
        ce_z_high_speed: 'Άξονας Z υψηλής ταχύτητας',
        ce_z_high_speed_link: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
        color_adjustment: 'Ρύθμιση καναλιού',
        color_adjustment_short: 'Κανάλι',
        color_strength: 'Δύναμη',
        cross_hatch: 'Διασταυρωμένη Σκίαση',
        custom_preset: 'Προσαρμοσμένο',
        cut: 'Κόψιμο',
        diode: 'Δίοδος λέιζερ',
        do_not_adjust_default_para: 'Οι προεπιλογές δεν μπορούν να τροποποιηθούν.',
        dottingTime: 'Χρόνος στίξης',
        dropdown: {
          inches: {
            abs_engraving: 'ABS - Χάραξη',
            acrylic_3mm_cutting: 'Aκρυλικό - 1/8″ Κοπή',
            acrylic_5mm_cutting: 'Aκρυλικό - 3/16″ Κοπή',
            acrylic_8mm_cutting: 'Aκρυλικό - 1/4″ Κοπή',
            acrylic_10mm_cutting: 'Aκρυλικό - 3/8″ Κοπή',
            acrylic_engraving: 'Ακρυλικό - Χάραξη',
            acrylic_printing: 'Aκρυλικό - Εκτύπωση',
            aluminum_engraving: 'Αλουμίνιο - Χάραξη',
            aluminum_light: 'Αλουμίνιο (ανοιχτό)',
            bamboo_printing: 'Μπαμπού - Εκτύπωση',
            black_abs: 'Μαύρο ABS',
            black_acrylic_3mm_cutting: 'Μαύρο ακρυλικό - 1/8″ Κοπή',
            black_acrylic_5mm_cutting: 'Μαύρο ακρυλικό - 3/16″ Κοπή',
            black_acrylic_engraving: 'Μαύρο ακρυλικό - Χάραξη',
            brass_dark: 'Ορείχαλκος (σκούρο)',
            brass_engraving: 'Ορείχαλκος - Χάραξη',
            brass_light: 'Ορείχαλκος (ανοιχτό)',
            canvas_printing: 'Καμβάς - Εκτύπωση',
            cardstock_printing: 'Χαρτόνι - Εκτύπωση',
            copper: 'Χαλκός',
            cork_printing: 'Φελλός - Εκτύπωση',
            denim_1mm_cutting: 'Τζην - 1/32″ Κοπή',
            fabric_3mm_cutting: 'Ύφασμα - 1/8″ Κοπή',
            fabric_5mm_cutting: 'Ύφασμα - 3/16″ Κοπή',
            fabric_engraving: 'Ύφασμα - Χάραξη',
            fabric_printing: 'Ύφασμα - Εκτύπωση',
            flat_stone_printing: 'Επίπεδη Πέτρα - Εκτύπωση',
            glass_bw_engraving: 'Γυαλί - Χάραξη',
            glass_printing: 'Γυαλί - Εκτύπωση',
            gloss_leather_printing: 'Γυαλιστερό δέρμα - Εκτύπωση',
            gold_engraving: 'Χρυσός - Χάραξη',
            iron_engraving: 'Σίδηρος - Χάραξη',
            leather_3mm_cutting: 'Δέρμα - 1/8″ Κοπή',
            leather_5mm_cutting: 'Δέρμα - 3/16″ Κοπή',
            leather_engraving: 'Δέρμα - Χάραξη',
            mdf_3mm_cutting: 'MDF Νέας Ζηλανδίας - 1/8″ Κοπή',
            mdf_5mm_cutting: 'MDF Νέας Ζηλανδίας - 3/16″ Κοπή',
            mdf_engraving: 'MDF Νέας Ζηλανδίας - Χάραξη',
            metal_bw_engraving: 'Μέταλλο - Χάραξη',
            opaque_acrylic: 'Αδιαφανές ακρυλικό',
            pc_printing: 'Πολυκαρβονικό - Εκτύπωση',
            rubber_bw_engraving: 'Καουτσούκ - Χάραξη',
            silver_engraving: 'Ασήμι - Χάραξη',
            stainless_steel_bw_engraving_diode: 'Ανοξείδωτος χάλυβας - Χάραξη (Δίοδος λέιζερ)',
            stainless_steel_dark: 'Ανοξείδωτο ατσάλι (σκούρο)',
            stainless_steel_engraving: 'Ανοξείδωτος χάλυβας - Χάραξη',
            stainless_steel_light: 'Ανοξείδωτο ατσάλι (ανοιχτό)',
            stainless_steel_printing: 'Ανοξείδωτος Χάλυβας - Εκτύπωση',
            steel_engraving_spray_engraving: 'Μέταλλο - Χάραξη',
            stone: 'Πέτρα',
            ti_engraving: 'Τιτάνιο - Χάραξη',
            titanium_dark: 'Τιτάνιο (σκούρο)',
            titanium_light: 'Τιτάνιο (ανοιχτό)',
            white_abs: 'Λευκό ABS',
            wood_3mm_cutting: 'Ξύλο - 1/8″ Κοπή',
            wood_5mm_cutting: 'Ξύλο - 3/16″ Κοπή',
            wood_7mm_cutting: 'Ξύλο - 1/4″ Κοπή',
            wood_8mm_cutting: 'Ξύλο - 1/4″ Κοπή',
            wood_10mm_cutting: 'Ξύλο - 3/8″ Κοπή',
            wood_engraving: 'Ξύλο - Χάραξη',
            wood_printing: 'Ξύλο - Εκτύπωση',
          },
          mm: {
            abs_engraving: 'ABS - Χάραξη',
            acrylic_3mm_cutting: 'Ακρυλικό - 3mm Κοπή',
            acrylic_5mm_cutting: 'Ακρυλικό - 5mm Κοπή',
            acrylic_8mm_cutting: 'Ακρυλικό - 8mm Κοπή',
            acrylic_10mm_cutting: 'Ακρυλικό - 10mm Κοπή',
            acrylic_engraving: 'Ακρυλικό - Χάραξη',
            acrylic_printing: 'Aκρυλικό - Εκτύπωση',
            aluminum_engraving: 'Αλουμίνιο - Χάραξη',
            aluminum_light: 'Αλουμίνιο (ανοιχτό)',
            bamboo_printing: 'Μπαμπού - Εκτύπωση',
            black_abs: 'Μαύρο ABS',
            black_acrylic_3mm_cutting: 'Μαύρο ακρυλικό - 3mm Κοπή',
            black_acrylic_5mm_cutting: 'Μαύρο ακρυλικό - 5mm Κοπή',
            black_acrylic_engraving: 'Μαύρο ακρυλικό - Χάραξη',
            brass_dark: 'Ορείχαλκος (σκούρο)',
            brass_engraving: 'Ορείχαλκος - Χάραξη',
            brass_light: 'Ορείχαλκος (ανοιχτό)',
            canvas_printing: 'Καμβάς - Εκτύπωση',
            cardstock_printing: 'Χαρτόνι - Εκτύπωση',
            copper: 'Χαλκός',
            cork_printing: 'Φελλός - Εκτύπωση',
            denim_1mm_cutting: 'Τζην - 1mm Κοπή',
            fabric_3mm_cutting: 'Ύφασμα - 3mm Κοπή',
            fabric_5mm_cutting: 'Ύφασμα - 5mm Κοπή',
            fabric_engraving: 'Ύφασμα - Χάραξη',
            fabric_printing: 'Ύφασμα - Εκτύπωση',
            flat_stone_printing: 'Επίπεδη Πέτρα - Εκτύπωση',
            glass_bw_engraving: 'Γυαλί - Χάραξη',
            glass_printing: 'Γυαλί - Εκτύπωση',
            gloss_leather_printing: 'Γυαλιστερό δέρμα - Εκτύπωση',
            gold_engraving: 'Χρυσός - Χάραξη',
            iron_engraving: 'Σίδηρος - Χάραξη',
            leather_3mm_cutting: 'Δέρμα - 3mm Κοπή',
            leather_5mm_cutting: 'Δέρμα - 5mm Κοπή',
            leather_engraving: 'Δέρμα - Χάραξη',
            mdf_3mm_cutting: 'MDF - 3mm Κοπή',
            mdf_5mm_cutting: 'MDF - 5mm Κοπή',
            mdf_engraving: 'MDF - Χάραξη',
            metal_bw_engraving: 'Μέταλλο - Χάραξη',
            opaque_acrylic: 'Αδιαφανές ακρυλικό',
            pc_printing: 'Πολυκαρβονικό - Εκτύπωση',
            rubber_bw_engraving: 'Καουτσούκ - Χάραξη',
            silver_engraving: 'Ασήμι - Χάραξη',
            stainless_steel_bw_engraving_diode: 'Ανοξείδωτος χάλυβας - Χάραξη (Δίοδος λέιζερ)',
            stainless_steel_dark: 'Ανοξείδωτο ατσάλι (σκούρο)',
            stainless_steel_engraving: 'Ανοξείδωτος χάλυβας - Χάραξη',
            stainless_steel_light: 'Ανοξείδωτο ατσάλι (ανοιχτό)',
            stainless_steel_printing: 'Ανοξείδωτος Χάλυβας - Εκτύπωση',
            steel_engraving_spray_engraving: 'Μέταλλο - Χάραξη',
            stone: 'Πέτρα',
            ti_engraving: 'Τιτάνιο - Χάραξη',
            titanium_dark: 'Τιτάνιο (σκούρο)',
            titanium_light: 'Τιτάνιο (ανοιχτό)',
            white_abs: 'Λευκό ABS',
            wood_3mm_cutting: 'Ξύλο - 3mm Κοπή',
            wood_5mm_cutting: 'Ξύλο - 5mm Κοπή',
            wood_7mm_cutting: 'Ξύλο - 7mm Κοπή',
            wood_8mm_cutting: 'Ξύλο - 8mm Κοπή',
            wood_10mm_cutting: 'Ξύλο - 10mm Κοπή',
            wood_engraving: 'Ξύλο - Χάραξη',
            wood_printing: 'Ξύλο - Εκτύπωση',
          },
          parameters: 'Προεπιλογές',
          save: 'Προσθήκη τρέχοντος παραμέτρων',
        },
        engrave: 'Χαράξτε',
        existing_name: 'Αυτό το όνομα έχει ήδη χρησιμοποιηθεί.',
        fill_angle: 'Γωνία Γεμίσματος',
        fill_interval: 'Διάστημα Γεμίσματος',
        fill_setting: 'Ρυθμίσεις Γέμισης',
        filled_path_only: 'Μόνο για διαδρομές πλήρωσης',
        focus_adjustment: 'Ρύθμιση εστίασης',
        frequency: 'Συχνότητα',
        gradient_only: 'Μόνο για εικόνες με διαβάθμιση',
        halftone: 'Μισοτόνος',
        halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
        height: 'Ύψος αντικειμένου',
        ink_saturation: 'Κορεσμός',
        ink_type: {
          normal: 'Μελάνι',
          text: 'Τύπος μελανιού',
          UV: 'UV μελάνι',
        },
        laser_speed: {
          fast: 'Γρήγορα',
          max: 300,
          min: 3,
          slow: 'Αργά',
          step: 0.1,
          text: 'Ταχύτητα',
          unit: 'mm/s',
        },
        low_power_warning: 'Χαμηλή ισχύς λέιζερ (κάτω από 10%) ενδέχεται να μην εκπέμπει το φως λέιζερ.',
        low_speed_warning: 'Η χαμηλή ταχύτητα μπορεί να προκαλέσει κάψιμο του υλικού.',
        lower_focus: 'Χαμηλώστε την Εστίαση',
        lower_focus_desc: 'Χαμήλωμα του ύψους εστίασης κατά συγκεκριμένη απόσταση μετά την εστίαση για βελτίωση της απόδοσης κοπής.',
        module: 'Μονάδα',
        more: 'Διαχείριση',
        multi_layer: 'Πολλαπλά στρώματα',
        para_in_use: 'Αυτή η παράμετρος χρησιμοποιείται.',
        parameters: 'Παράμετροι',
        power: {
          high: 'Υψηλή',
          low: 'Χαμηλή',
          max: 100,
          min: 1,
          step: 0.1,
          text: 'Ισχύς',
        },
        preset_management: {
          add_new: 'Προσθήκη Νέου',
          delete: 'Διαγραφή',
          export: 'Εξαγωγή',
          export_preset_title: 'Εξαγωγή Προεπιλογών',
          import: 'Εισαγωγή',
          laser: 'Λέιζερ',
          lower_focus_by: 'Μείωση Εστίασης κατά',
          new_preset_name: 'Νέο Όνομα Προεπιλογής',
          preset: 'Προκαθορισμένο',
          print: 'Εκτύπωση',
          reset: 'Επαναφορά',
          save_and_exit: 'Αποθήκευση και Έξοδος',
          show_all: 'Εμφάνιση Όλων',
          sure_to_import_presets: 'Αυτό θα φορτώσει τη διάταξη των προεπιλογών και θα αντικαταστήσει τις εξατομικευμένες παραμέτρους. Είστε βέβαιοι ότι θέλετε να συνεχίσετε;',
          sure_to_reset: 'Αυτό θα διαγράψει τις εξατομικευμένες παραμέτρους σας και θα επαναφέρει όλες τις προεπιλογές. Είστε βέβαιοι ότι θέλετε να συνεχίσετε;',
          title: 'Διαχείριση Παραμέτρων',
          wobble_diameter: 'Διάμετρος ταλάντωσης',
          wobble_step: 'Μέγεθος βήματος ταλάντωσης',
        },
        preset_setting: 'Προκαθορισμένες ρυθμίσεις (%s)',
        presets: 'Προεπιλογή',
        print_multipass: 'Πολλαπλή διέλευση',
        promark_speed_desc: 'Οι παράμετροι ταχύτητας δεν ισχύουν για εικόνες διαβάθμισης.',
        pulse_width: 'Πλάτος Παλμού',
        pwm_advanced_desc: 'Ρυθμίστε μια ελάχιστη ισχύ για τη λειτουργία βάθους.',
        pwm_advanced_hint: 'Αυτή η ρύθμιση εφαρμόζεται συγκεκριμένα σε εικόνες κλίσης όταν χρησιμοποιείτε τη λειτουργία βάθους.',
        pwm_advanced_setting: 'Ρυθμίσεις Ισχύος Λειτουργίας Βάθους',
        repeat: 'Αριθμός Περασμάτων',
        single_color: 'Μονόχρωμο',
        single_color_desc: 'Ισχύει μόνο για πλήρως έγχρωμο στρώμα και δεν μπορεί να χρησιμοποιηθεί με επεκταμένο μονοχρωματικό στρώμα.',
        slider: {
          fast: 'Γρήγορα',
          high: 'Υψηλό',
          low: 'Χαμηλό',
          regular: 'Κανονικό',
          slow: 'Αργά',
          very_fast: 'Πολύ γρήγορα',
          very_high: 'Μέγιστο',
          very_low: 'Ελάχιστο',
          very_slow: 'Πολύ αργά',
        },
        speed: 'Ταχύτητα',
        speed_constrain_warning: 'Η ταχύτητα κοπής των διανυσμάτων θα περιοριστεί στα %(limit)s. Μπορείτε να καταργήσετε αυτό το όριο από τις Ρυθμίσεις.',
        speed_constrain_warning_auto_feeder: 'Η ταχύτητα κοπής των αντικειμένων Αυτόματης Τροφοδότησης θα περιοριστεί στο %(limit)s. Μπορείτε να καταργήσετε αυτόν τον περιορισμό στις Ρυθμίσεις Προτιμήσεων.',
        speed_constrain_warning_curve_engraving: 'Η ταχύτητα χάραξης καμπυλών θα περιοριστεί σε %(limit)s. Μπορείτε να καταργήσετε αυτό το όριο στις ρυθμίσεις προτιμήσεων.',
        stepwise_focusing: 'Βηματική Εστίαση',
        stepwise_focusing_desc: 'Σταδιακό χαμήλωμα της απόστασης εστίασης βάσει του ύψους του αντικειμένου κατά κάθε αριθμό περάσματος.',
        strength: 'Ισχύς',
        times: 'φορές',
        various_preset: 'Διάφορες Προεπιλογές',
        white_ink: 'Λευκή μελάνη',
        white_ink_settings: 'Ρυθμίσεις λευκής μελάνης',
        wobble: 'Κυματισμός',
        wobble_desc: 'Δημιουργεί μικρούς κύκλους κατά μήκος της διαδρομής, κάτι που είναι επωφελές για τις εργασίες κοπής. (Μόνο για αντικείμενα διαδρομής.)',
        wobble_diameter: 'Διάμετρος',
        wobble_step: 'Μέγεθος βήματος',
        z_step: 'Βήμα Z',
      },
      layer_panel: {
        current_layer: 'Τρέχον Στρώμα',
        layer1: 'Στρώμα 1',
        layer_bitmap: 'Χάρτης bit',
        layer_cutting: 'Κοπή',
        layer_engraving: 'Χάραξη',
        layers: {
          del: 'Διαγραφή Στρώματος',
          dupe: 'Διπλασιασμός Στρώματος',
          fullColor: 'Πλήρες χρώμα',
          layer: 'Στρώμα',
          layers: 'Στρώματα',
          lock: 'Κλείδωμα Στρώματος',
          merge_all: 'Συγχώνευση Όλων των Στρωμάτων',
          merge_down: 'Συγχώνευση με Κάτω Στρώμα',
          merge_selected: 'Συγχώνευση Επιλεγμένων Στρωμάτων',
          move_down: 'Μετακίνηση Στρώματος Κάτω',
          move_elems_to: 'Μετακινήστε στοιχεία σε:',
          move_selected: 'Μετακινήστε τα επιλεγμένα στοιχεία σε διαφορετικό επίπεδο',
          move_up: 'Μετακίνηση Στρώματος Πάνω',
          new: 'Νέο Στρώμα',
          rename: 'Μετονομασία Στρώματος',
          splitFullColor: 'Διαίρεση επιπέδου πλήρους χρώματος',
          switchToFullColor: 'Μετάβαση σε επίπεδο πλήρους χρώματος',
          switchToSingleColor: 'Μετάβαση σε επίπεδο μονού χρώματος',
          unlock: 'Ξεκλείδωμα',
        },
        move_elems_to: 'Μετακίνηση στοιχείων σε:',
        notification: {
          dupeLayerName: 'Υπάρχει ήδη ένα στρώμα με αυτό το όνομα!',
          enterNewLayerName: 'Παρακαλώ εισάγετε το νέο όνομα στρώματος',
          enterUniqueLayerName: 'Παρακαλώ εισάγετε ένα μοναδικό όνομα στρώματος',
          layerHasThatName: 'Το στρώμα έχει ήδη αυτό το όνομα',
          mergeLaserLayerToPrintingLayerMsg: 'Λάβετε υπόψη ότι αν ολοκληρώσετε αυτήν τη λειτουργία, οι ρυθμίσεις του στρώματος λέιζερ θα αφαιρεθούν και θα οριστούν σύμφωνα με το τρέχον στρώμα.',
          mergeLaserLayerToPrintingLayerTitle: 'Θέλετε να συγχωνεύσετε αυτά τα στρώματα σε ένα στρώμα εκτύπωσης;',
          mergePrintingLayerToLaserLayerMsg: 'Λάβετε υπόψη ότι αν ολοκληρώσετε αυτήν τη λειτουργία, οι ρυθμίσεις χρώματος του στρώματος εκτύπωσης θα αφαιρεθούν και θα οριστούν σύμφωνα με το τρέχον στρώμα.',
          mergePrintingLayerToLaserLayerTitle: 'Θέλετε να συγχωνεύσετε αυτά τα στρώματα σε ένα στρώμα λέιζερ;',
          moveElemFromPrintingLayerMsg: 'Λάβετε υπόψη ότι αν ολοκληρώσετε αυτήν τη λειτουργία, οι ρυθμίσεις χρώματος του επιλεγμένου στοιχείου θα αφαιρεθούν και θα οριστούν σύμφωνα με το %s.',
          moveElemFromPrintingLayerTitle: 'Μετακινήστε το επιλεγμένο στοιχείο στο %s και μετατρέψτε το σε στοιχείο λέιζερ;',
          moveElemToPrintingLayerMsg: 'Λάβετε υπόψη ότι αν ολοκληρώσετε αυτήν τη λειτουργία, οι ρυθμίσεις του επιλεγμένου στοιχείου θα αφαιρεθούν και θα οριστούν σύμφωνα με το %s.',
          moveElemToPrintingLayerTitle: 'Μετακινήστε το επιλεγμένο στοιχείο στο %s και μετατρέψτε το σε στοιχείο εκτύπωσης;',
          newName: 'ΝΕΟ ΟΝΟΜΑ',
          QmoveElemsToLayer: "Μετακινήστε τα επιλεγμένα στοιχεία στο στρώμα '%s';",
          splitColorMsg: 'Λάβετε υπόψη ότι αν συνεχίσετε με αυτήν τη λειτουργία, δεν θα μπορείτε να επιστρέψετε στα αρχικά στρώματα χρωμάτων.',
          splitColorTitle: 'Θέλετε να επεκτείνετε το επιλεγμένο στρώμα σε στρώματα CMYK;',
        },
      },
      object_panel: {
        actions_panel: {
          ai_bg_removal: 'Αφαίρεση φόντου',
          ai_bg_removal_reminder: 'Πατώντας το κουμπί θα χρησιμοποιηθούν αμέσως 0,2 πιστώσεις, θέλετε να συνεχίσετε;',
          ai_bg_removal_short: 'Αφαίρεση φόντου',
          array: 'Πίνακας',
          auto_fit: 'Αυτόματη Προσαρμογή',
          bevel: 'Λοξοτομία',
          brightness: 'Φωτεινότητα',
          convert_to_path: 'Μετατροπή σε μονοπάτι',
          create_textpath: 'Δημιουργία κειμένου σε μονοπάτι',
          create_textpath_short: 'Μονοπάτι κειμένου',
          crop: 'Περικοπή',
          decompose_path: 'Αποσύνθεση',
          detach_path: 'Αποσύνθεση κειμένου από μονοπάτι',
          detach_path_short: 'Αποσυνθέτηση',
          disassemble_use: 'Αποσυναρμολόγηση',
          disassembling: 'Αποσυναρμολόγηση...',
          edit_path: 'Επεξεργασία μονοπατιού',
          fetching_web_font: 'Λήψη διαδικτυακής γραμματοσειράς...',
          grading: 'Βαθμονόμηση',
          invert: 'Αντιστροφή',
          offset: 'Μετατόπιση',
          outline: 'Περίγραμμα',
          replace_with: 'Αντικατάσταση με...',
          replace_with_short: 'Αντικατάσταση',
          sharpen: 'Εστίαση',
          simplify: 'Απλοποίηση',
          smart_nest: 'Έξυπνη Τοποθέτηση',
          trace: 'Ιχνηλάτηση',
          ungrouping: 'Κατάργηση ομαδοποίησης...',
          uploading_font_to_machine: 'Μεταφόρτωση γραμματοσειράς στο μηχάνημα...',
          wait_for_parsing_font: 'Επεξεργασία γραμματοσειράς... Παρακαλώ περιμένετε',
          weld_text: 'Συγχώνευση κειμένου',
        },
        align: 'Ευθυγράμμιση',
        boolean: 'Λογική πράξη',
        bottom_align: 'Ευθυγράμμιση κάτω',
        center_align: 'Ευθυγράμμιση κέντρου',
        difference: 'Διαφορά',
        distribute: 'Διανομή',
        flip: 'Αναστροφή',
        group: 'Ομάδα',
        hdist: 'Οριζόντια διανομή',
        hflip: 'Οριζόντια αναστροφή',
        intersect: 'Τομή',
        left_align: 'Ευθυγράμμιση αριστερά',
        lock_aspect: 'Κλείδωμα αναλογίας',
        middle_align: 'Ευθυγράμμιση μέσης',
        option_panel: {
          color: 'Χρώμα',
          fill: 'Εσωτερική πλήρωση',
          font_family: 'Γραμματοσειρά',
          font_size: 'Μέγεθος',
          font_style: 'Στυλ',
          letter_spacing: 'Διάστημα γραμμάτων',
          line_spacing: 'Διάστημα γραμμών',
          path_infill: 'Πλήρωση διαδρομής',
          pwm_engraving: 'Λειτουργία Βάθους',
          pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
          rounded_corner: 'Στρογγυλεμένη γωνία',
          shading: 'Διαβάθμιση',
          sides: 'Πλευρές',
          start_offset: 'Μετατόπιση κειμένου',
          stroke: 'Περίγραμμα',
          stroke_color: 'Χρώμα περιγράμματος',
          stroke_width: 'Πλάτος γραμμής',
          text_infill: 'Πλήρωση κειμένου',
          threshold: 'Φωτεινότητα ορίου',
          threshold_short: 'Κατώφλι',
          vertical_align: 'Στοίχιση',
          vertical_text: 'Κάθετο κείμενο',
        },
        path_edit_panel: {
          connect: 'Συνδέστε',
          delete: 'Διαγράψτε',
          disconnect: 'Αποσυνδέστε',
          node_type: 'ΤΥΠΟΣ ΚΟΜΒΟΥ',
          round: 'Στρογγυλό',
          sharp: 'Αιχμηρό',
        },
        right_align: 'Ευθυγράμμιση δεξιά',
        subtract: 'Αφαίρεση',
        top_align: 'Ευθυγράμμιση επάνω',
        ungroup: 'Κατάργηση ομαδοποίησης',
        union: 'Ένωση',
        unlock_aspect: 'Ξεκλείδωμα αναλογίας',
        vdist: 'Κάθετη κατανομή',
        vflip: 'Κάθετη αναστροφή',
        zoom: 'Μεγέθυνση',
      },
      tabs: {
        layers: 'Επίπεδα',
        objects: 'Αντικείμενα',
        path_edit: 'Επεξεργασία Μονοπατιού',
      },
    },
    shapes_panel: {
      animals: 'Ζώο',
      arrow: 'Βέλος',
      basic: 'Βασικά',
      birds: 'Πουλί',
      celebration: 'Εορτασμός',
      circular: 'Κυκλικό',
      CNY: 'Κινεζική Πρωτοχρονιά',
      corner: 'Γωνία',
      decor: 'Διακόσμηση',
      easter: 'Πάσχα',
      elements: 'Στοιχεία',
      environment: 'Περιβάλλον',
      graphics: 'Γραφικά',
      halloween: 'Απόκριες',
      holidays: 'Αργίες',
      label: 'Ετικέτα',
      land: 'Ξηρά',
      line: 'Γραμμή',
      nature: 'Φύση',
      photo: 'Φωτογραφία',
      plants: 'Φυτά',
      ribbon: 'Κορδέλα',
      sea: 'Θάλασσα',
      shape: 'Σχήμα',
      speech: 'Ομιλία',
      text: 'Πλαίσιο κειμένου',
      title: 'Σχήματα',
      valentines: 'Αγίου Βαλεντίνου',
      weather: 'Καιρός',
      Xmas: 'Χριστούγεννα',
    },
    svg_editor: {
      unable_to_fetch_clipboard_img: 'Αποτυχία λήψης εικόνας από το πρόχειρο',
      unnsupported_file_type: 'Ο τύπος αρχείου δεν υποστηρίζεται. Μετατρέψτε το αρχείο σε SVG ή bitmap',
    },
    tag: {
      g: 'Ομάδα',
      image: 'Εικόνα',
      text: 'Κείμενο',
      use: 'Εισαγωγή SVG',
    },
    time_est_button: {
      calculate: 'Υπολογισμός χρόνου',
      estimate_time: 'Εκτιμώμενος χρόνος:',
    },
    tool_panels: {
      _nest: {
        end: 'Κλείσιμο',
        no_element: 'Δεν υπάρχουν στοιχεία για τοποθέτηση.',
        rotations: 'Πιθανή Περιστροφή',
        spacing: 'Απόσταση',
        start_nest: 'Τοποθέτηση',
        stop_nest: 'Σταμάτημα',
      },
      _offset: {
        corner_type: 'Γωνία',
        direction: 'Κατεύθυνση μετατόπισης',
        dist: 'Απόσταση μετατόπισης',
        fail_message: 'Αποτυχία μετατόπισης αντικειμένων.',
        inward: 'Προς τα μέσα',
        not_support_message: 'Τα επιλεγμένα στοιχεία περιέχουν μη υποστηριζόμενες ετικέτες SVG:\nΕικόνα, Ομάδα, Κείμενο και Εισαγόμενο Αντικείμενο.',
        outward: 'Προς τα έξω',
        round: 'Στρογγυλή',
        sharp: 'Αιχμηρή',
      },
      array_dimension: 'Διάσταση Πίνακα',
      array_interval: 'Διάστημα Πίνακα',
      cancel: 'Ακύρωση',
      columns: 'Στήλες',
      confirm: 'Επιβεβαίωση',
      dx: 'X',
      dy: 'Y',
      grid_array: 'Δημιουργία Πλέγματος Πίνακα',
      nest: 'Βελτιστοποίηση διάταξης',
      offset: 'Μετατόπιση',
      rows: 'Γραμμές',
    },
    units: {
      mm: 'χιλιοστόμετρα',
      walt: 'W',
    },
    zoom_block: {
      fit_to_window: 'Προσαρμογή στο παράθυρο',
    },
  },
  boxgen: {
    add_option: 'Προσθήκη επιλογής',
    basic_box: 'Βασικό Κουτί',
    beam_radius: 'Αντιστάθμιση ακτίνας κοπής',
    beam_radius_warning: 'Αφαιρέστε την αντιστάθμιση Kerf όταν οι άκρες ή οι αρμοί του κουτιού είναι σύντομοι για να διασφαλίσετε τη συναρμολόγηση του κουτιού',
    cancel: 'Ακύρωση',
    coming_soon: 'Έρχεται Σύντομα',
    continue_import: 'Συνεχίστε την εισαγωγή',
    control_tooltip: 'Αριστερό κλικ για περιστροφή\nΤροχός ποντικιού για μεγέθυνση\nΔεξί κλικ για μετακίνηση',
    control_tooltip_touch: 'Σύρετε για περιστροφή\nΤσίμπημα για μεγέθυνση\nΔύο δάχτυλα για μετακίνηση',
    cover: 'Κάλυμμα',
    customize: 'Προσαρμογή',
    depth: 'Βάθος',
    edge: 'Άκρη',
    finger: 'Δάχτυλο',
    finger_warning: 'Το εσωτερικό μήκος πλευράς του κουτιού πρέπει να είναι τουλάχιστον 6 χιλιοστά 6mm (0.24 inches) για να είναι συμβατό με τη σύνδεση δακτύλου.',
    height: 'Ύψος',
    import: 'Εισαγωγή',
    inner: 'Εσωτερικό',
    joints: 'Αρμός',
    max_dimension_tooltip: 'Το μέγιστο πλάτος/ύψος/βάθος ρύθμιση είναι %s.',
    merge: 'Συγχώνευση',
    outer: 'Εξωτερικό',
    reset: 'Επαναφορά',
    tCount: 'Μέτρηση T',
    tCount_tooltip: 'Ο αριθμός υποδοχών T ισχύει για τη μικρή πλευρά. Η ποσότητα στη μεγάλη πλευρά υπολογίζεται με βάση το μήκος της.',
    tDiameter: 'Διάμετρος T',
    text_label: 'Ετικέτα',
    thickness: 'Πάχος',
    title: 'BOXGEN',
    tLength: 'Μήκος T',
    tSlot: 'T-Υποδοχή',
    tSlot_warning: 'Το μήκος πλευράς του κουτιού πρέπει να είναι τουλάχιστον 30mm (1.18 inches) για να είναι συμβατό με τη σύνδεση T-Υποδοχή.',
    volume: 'Όγκος',
    width: 'Πλάτος',
    workarea: 'Περιοχή εργασίας',
    zoom: 'Μεγέθυνση',
  },
  buttons: {
    back: 'Πίσω',
    back_to_beam_studio: 'Επιστροφή στο Beam Studio',
    done: 'Τέλος',
    next: 'Επόμενο',
  },
  calibration: {
    ador_autofocus_focusing_block: 'Πατήστε το εικονίδιο "AF" στην κύρια σελίδα της μηχανής για 3 δευτερόλεπτα και αφήστε τον αισθητήρα να αγγίξει το μπλοκ εστίασης.',
    ador_autofocus_material: 'Πατήστε το εικονίδιο "AF" στην κύρια σελίδα της μηχανής για 3 δευτερόλεπτα και αφήστε τον αισθητήρα να αγγίξει απαλά το υλικό.',
    align_ilb: 'Θέση: Ευθυγραμμίστε με το χαραγμένο σημείο στο εσωτερικό κάτω αριστερά.',
    align_ilt: 'Θέση: Ευθυγραμμίστε με το χαραγμένο σημείο στο εσωτερικό επάνω αριστερά.',
    align_irb: 'Θέση: Ευθυγραμμίστε με το χαραγμένο σημείο στο εσωτερικό κάτω δεξιά.',
    align_irt: 'Θέση: Ευθυγραμμίστε με το χαραγμένο σημείο στο εσωτερικό επάνω δεξιά.',
    align_olb: 'Θέση: Ευθυγραμμίστε με το χαραγμένο σημείο στο εξωτερικό κάτω αριστερά.',
    align_olt: 'Θέση: Ευθυγραμμίστε με το χαραγμένο σημείο στο εξωτερικό επάνω αριστερά.',
    align_orb: 'Θέση: Ευθυγραμμίστε με το χαραγμένο σημείο στο εξωτερικό κάτω δεξιά.',
    align_ort: 'Θέση: Ευθυγραμμίστε με το χαραγμένο σημείο στο εξωτερικό επάνω δεξιά.',
    align_red_cross_cut: 'Παρακαλώ ευθυγραμμίστε τη μέση του κόκκινου σταυρού με τον σταυρό κοπής.',
    align_red_cross_print: 'Παρακαλώ ευθυγραμμίστε τη μέση του κόκκινου σταυρού με τον τυπωμένο σταυρό.',
    analyze_result_fail: 'Αποτυχία ανάλυσης της καταγεγραμμένης εικόνας. <br/> Παρακαλώ βεβαιωθείτε: <br/> 1. Η καταγεγραμμένη εικόνα καλύπτεται πλήρως με λευκό χαρτί. <br/> 2. Η πλατφόρμα εστιάζεται σωστά.',
    ask_for_readjust: 'Θέλετε να παραλείψετε το βήμα χάραξης και στη συνέχεια να τραβήξετε φωτογραφία και να βαθμονομήσετε απευθείας;',
    back: 'Πiσω',
    calculating_camera_matrix: 'Υπολογισμός Μήτρας Κάμερας...',
    calculating_regression_parameters: 'Υπολογισμός παραμέτρων παλινδρόμησης...',
    calibrate_camera_before_calibrate_modules: 'Παρακαλώ εκτελέστε βαθμονόμηση κάμερας πριν βαθμονομήσετε τις μονάδες.',
    calibrate_chessboard_success_msg: 'Η φωτογραφία της σκακιέρας ελήφθη με επιτυχία.<br/>Το σκορ για αυτή τη φωτογραφία είναι %s (%.2f).',
    calibrate_done: 'Η βαθμονόμηση ολοκληρώθηκε. Καλύτερη ακρίβεια κάμερας επιτυγχάνεται όταν γίνεται σωστή εστίαση.',
    calibrate_done_diode: 'Η βαθμονόμηση ολοκληρώθηκε. Η αντιστάθμιση της διοδικής μονάδας λέιζερ έχει αποθηκευτεί.',
    calibrating: 'Καλιμπράρισμα...',
    calibrating_with_device_pictures: 'Βαθμονόμηση με τις εικόνες της συσκευής...',
    camera_calibration: 'Βαθμονόμηση κάμερας',
    camera_parameter_saved_successfully: 'Οι παράμετροι της κάμερας αποθηκεύτηκαν επιτυχώς.',
    cancel: 'Ακύρωση',
    check_checkpoint_data: 'Δεδομένα σημείου ελέγχου',
    check_device_pictures: 'Έλεγχος Εικόνων Συσκευής',
    checking_checkpoint: 'Ελέγχος δεδομένων σημείου ελέγχου...',
    checking_pictures: 'Γίνεται έλεγχος των εικόνων της συσκευής...',
    diode_calibration: 'Βαθμονόμηση διόδου λέιζερ',
    do_engraving: 'Κάντε χάραξη',
    download_chessboard_file: 'Λήψη αρχείου σκακιέρας',
    downloading_checkpoint: 'Λήψη δεδομένων σημείου ελέγχου...',
    downloading_pictures: 'Λήψη Εικόνων...',
    drawing_calibration_image: 'Σχεδιάζοντας εικόνα βαθμονόμησης...',
    dx: 'Χ',
    dy: 'Υ',
    elevate_and_cut: 'Ανύψωση και Κοπή',
    elevate_and_cut_step_1: 'Τοποθετήστε ένα ξύλο μεγέθους A4 ανοιχτού χρώματος στο κέντρο της περιοχής εργασίας, υψωμένο στα 20mm.',
    elevate_and_cut_step_1_prism_lift: 'Χρησιμοποιήστε το Ador Prism Lift με μέγιστο μήκος 14mm μαζί με ένα ξύλο τουλάχιστον 6mm πάχους.',
    failed_to_calibrate_camera: '#848 Αποτυχία βαθμονόμησης κάμερας, παρακαλώ επικοινωνήστε με την υποστήριξη FLUX.',
    failed_to_calibrate_chessboard: 'Η βαθμονόμηση με την εικόνα της σκακιέρας απέτυχε.',
    failed_to_calibrate_with_pictures: '#848 Απέτυχε η βαθμονόμηση με τις εικόνες της συσκευής.',
    failed_to_download_pictures: '#848 Αποτυχία λήψης εικόνων, επικοινωνήστε με την υποστήριξη FLUX.',
    failed_to_move_laser_head: 'Αποτυχία μετακίνησης της κεφαλής λέιζερ.',
    failed_to_parse_checkpoint: 'Απέτυχε η ανάλυση των δεδομένων σημείου ελέγχου.',
    failed_to_save_calibration_results: '#849 Αποτυχία αποθήκευσης αποτελεσμάτων βαθμονόμησης, παρακαλώ δοκιμάστε ξανά. Αν αυτό συνεχίζει να συμβαίνει, παρακαλώ επικοινωνήστε με την υποστήριξη FLUX.',
    failed_to_save_camera_parameter: 'Αποτυχία αποθήκευσης των παραμέτρων της κάμερας.',
    failed_to_solve_pnp: 'Δεν ήταν δυνατή η επίλυση της θέσης της κάμερας.',
    finish: 'Τέλος',
    found_checkpoint: 'Δεδομένα σημείου ελέγχου βρέθηκαν στη συσκευή σας. Θέλετε να ανακτήσετε από το σημείο ελέγχου;',
    getting_plane_height: 'Λήψη Ύψους Επιπέδου...',
    hint_adjust_parameters: 'Χρησιμοποιήστε αυτές τις παραμέτρους για να προσαρμόσετε το κόκκινο τετράγωνο',
    hint_red_square: 'Ευθυγραμμίστε την εξωτερική πλευρά του κόκκινου τετραγώνου με το τετράγωνο κοπής.',
    module_calibration_2w_ir: 'Βαθμονόμηση υπέρυθρης μονάδας',
    module_calibration_printer: 'Βαθμονόμηση μονάδας εκτυπωτή',
    moving_laser_head: 'Μετακίνηση της κεφαλής λέιζερ...',
    next: 'Επόμενο',
    no_picutre_found: '#846 Η συσκευή σας δεν έχει διαθέσιμες ακατέργαστες φωτογραφίες για βαθμονόμηση. Παρακαλώ επικοινωνήστε με την υποστήριξη της FLUX.',
    perform_autofocus_bb2: 'Παρακαλώ πηγαίνετε στον πίνακα ελέγχου της μηχανής και πατήστε AF για να εκτελέσετε την αυτόματη εστίαση.',
    please_do_camera_calibration_and_focus: {
      beambox: 'Κατά τη βαθμονόμηση της διοδικής μονάδας λέιζερ, χρειάζεται κάμερα.\nΒεβαιωθείτε ότι η κάμερα αυτού του μηχανήματος έχει βαθμονομηθεί.\nΚαι ευγενικά προσαρμόστε την πλατφόρμα στο εστιακό σημείο (το ύψος του ανεστραμμένου ακρυλικού)',
      beamo: 'Κατά τη βαθμονόμηση της διοδικής μονάδας λέιζερ, χρειάζεται κάμερα.\nΒεβαιωθείτε ότι η κάμερα αυτού του μηχανήματος έχει βαθμονομηθεί.\nΚαι ευγενικά προσαρμόστε την κεφαλή λέιζερ για να εστιάσετε στο αντικείμενο χάραξης (το ύψος του ανεστραμμένου ακρυλικού)',
    },
    please_goto_beambox_first: 'Παρακαλώ μεταβείτε πρώτα στη λειτουργία χάραξης (Beambox) προκειμένου να χρησιμοποιήσετε αυτήν τη δυνατότητα.',
    please_place_dark_colored_paper: 'Παρακαλώ τοποθετήστε ένα σκουρόχρωμο χαρτί μεγέθους Α4 ή Letter στο κέντρο της περιοχής εργασίας.',
    please_place_paper: 'Παρακαλώ τοποθετήστε ένα λευκό χαρτί μεγέθους Α4 ή Letter στην αριστερή επάνω γωνία της περιοχής εργασίας.',
    please_place_paper_center: 'Παρακαλώ τοποθετήστε ένα λευκό χαρτί μεγέθους Α4 ή Letter στο κέντρο της περιοχής εργασίας.',
    please_refocus: {
      beambox: 'Παρακαλώ ρυθμίστε την πλατφόρμα στο εστιακό σημείο (το ύψος του ανεστραμμένου ακρυλικού)',
      beamo: 'Παρακαλώ ρυθμίστε το λέιζερ κεφαλής για να εστιάσει στο αντικείμενο χάραξης (το ύψος του ανεστραμμένου ακρυλικού)',
      beamo_af: 'Παρακαλώ κάντε διπλό πάτημα στο πλάι κουμπί της προσθήκης αυτόματης εστίασης και αφήστε τον αισθητήρα να αγγίξει απαλά το υλικό.',
      hexa: 'Κάντε διπλό κλικ στο κουμπί ρύθμισης ύψους για να ανεβάσετε το τραπέζι μελισσών πάνω και να κάνετε τον αισθητήρα να αγγίξει το υλικό χάραξης.',
    },
    preparing_to_take_picture: 'Προετοιμασία για λήψη φωτογραφίας...',
    promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    put_chessboard: 'Τοποθέτηση Σκακιέρας',
    put_chessboard_1: 'Παρακαλώ πηγαίνετε στον πίνακα ελέγχου της μηχανής και πατήστε AF για να εκτελέσετε την αυτόματη εστίαση.',
    put_chessboard_2: 'Παρακαλώ μετακινήστε το χαρτί με το μοτίβο σκακιέρας ή την κεφαλή λέιζερ μέχρι να επιβεβαιωθεί ότι ολόκληρο το μοτίβο σκακιέρας είναι εντός του κόκκινου πλαισίου στο παράθυρο ζωντανής προεπισκόπησης.',
    put_chessboard_3: 'Κάντε δεξί κλικ για να κατεβάσετε την εικόνα της σκακιέρας στην τρέχουσα τοποθεσία και να ελέγξετε την ευκρίνειά της.',
    put_chessboard_bb2_desc_1: 'Κατεβάστε το παρακάτω αρχείο σκακιέρας και εκτυπώστε το σε χαρτί A4 (η εκτυπωμένη σκακιέρα πρέπει να έχει τετράγωνα 1x1 cm).',
    put_chessboard_bb2_desc_2: 'Στερεώστε τη σκακιέρα που εκτυπώσατε σε μια μη παραμορφώσιμη επιφάνεια, όπως ακρυλικό ή γυαλί, διασφαλίζοντας ότι η σκακιέρα είναι επίπεδη και χωρίς ζάρες ή ανασηκωμένες γωνίες.',
    put_chessboard_bb2_desc_3: 'Τοποθετήστε τη σκακιέρα στο κέντρο της περιοχής εργασίας.',
    put_chessboard_promark_1: 'Ρυθμίστε την απόσταση εστίασης στην κατάλληλη εστίαση για τον φακό πεδίου.',
    put_chessboard_promark_2: 'Βεβαιωθείτε ότι το χαρτί σκακιέρας είναι καθαρά ορατό χωρίς αντηλιά. Κάντε κλικ στο "Επόμενο" για να τραβήξετε φωτογραφία.',
    put_chessboard_promark_desc_1: 'Παρακαλώ χρησιμοποιήστε το χαρτί σκακιέρας που παρέχεται στο κουτί αξεσουάρ ή εκτυπώστε το ακόλουθο μοτίβο σκακιέρας σε χαρτί Α4 για την καλιμπράρισμα της κάμερας.',
    put_chessboard_promark_desc_2: 'Ρυθμίστε σωστά την εστίαση και μετά κάντε κλικ στο «Επόμενο» για να συνεχίσετε με τη χάραξη.',
    put_paper: 'Τοποθετήστε χαρτί',
    put_paper_promark_1: 'Τοποθετήστε το μαύρο χαρτόνι από το κουτί των εξαρτημάτων στην πλατφόρμα εργασίας.',
    put_paper_promark_2: 'Ρυθμίστε σωστά την εστίαση και μετά κάντε κλικ στο "Ξεκινήστε τη χάραξη" για να συνεχίσετε με τη χάραξη.',
    put_paper_skip: 'Εάν το σχέδιο βαθμονόμησης δεν προχωρήσει αυτόματα στη λήψη εικόνας, πατήστε "Παράλειψη" για να τραβήξετε εικόνα.',
    put_paper_step1: 'Παρακαλούμε τοποθετήστε ένα λευκό χαρτί μεγέθους A4 ή Λέιζερ στο κέντρο της περιοχής εργασίας.',
    put_paper_step2: 'Ασφαλίστε τις τέσσερις γωνίες του χαρτιού για να διασφαλίσετε ότι είναι επίπεδο.',
    put_paper_step3: 'Κάντε κλικ στο "Ξεκινήστε τη χάραξη".',
    res_average: 'Μέτριο',
    res_excellent: 'Εξαιρετικό',
    res_poor: 'Κακό',
    retake: 'Επανάληψη λήψης',
    rotation_angle: 'Περιστροφή',
    show_last_config: 'Εμφάνιση Τελευταίας Ρύθμισης',
    skip: 'Παράλειψη',
    solve_pnp_step1: 'Παρακαλώ ευθυγραμμίστε τα σημεία χάραξης σύμφωνα με τον αριθμό και την αναμενόμενη θέση κάθε κόκκινου δείκτη.',
    solve_pnp_step2: 'Μπορείτε να πατήσετε "Επανάληψη λήψης" για να ευθυγραμμιστείτε ξανά ή να ρυθμίσετε χειροκίνητα τις θέσεις των σημείων σήμανσης.',
    solve_pnp_step3: "Εάν η εικόνα που τραβήχτηκε δεν αντιστοιχεί στην πραγματική σκηνή, παρακαλώ μεγεθύνετε ή σύρετε την οθόνη για να ρυθμίσετε το σημείο βαθμονόμησης στην μαύρη κάρτα βαθμονόμησης στο κέντρο και στη συνέχεια δοκιμάστε να 'Επανάληψη λήψης'.",
    solve_pnp_title: 'Ευθυγραμμίστε τα σημεία σήμανσης',
    start_engrave: 'Ξεκινήστε τη χάραξη',
    start_printing: 'Ξεκινήστε την εκτύπωση',
    taking_picture: 'Λήψη Φωτογραφίας...',
    unable_to_load_camera_parameters: "#851 Δεν υπάρχουν διαθέσιμες παράμετροι ρύθμισης της κάμερας στη συσκευή σας. Μεταβείτε στις 'Ρυθμίσεις' > 'Ρύθμιση κάμερας (Προχωρημένο)' για να ολοκληρώσετε τη ρύθμιση και να αποκτήσετε τις παραμέτρους.",
    update_firmware_msg1: 'Το υλικολογισμικό σας δεν υποστηρίζει αυτήν τη λειτουργία. Ενημερώστε το υλικολογισμικό σε v',
    update_firmware_msg2: 'ή νεότερη έκδοση για να συνεχίσετε. (Μενού > Μηχάνημα > [Το μηχάνημά σας] > Ενημέρωση λογισμικού)',
    uploading_images: 'Μεταφόρτωση Εικόνων...',
    use_last_config: 'Χρήση Τελευταίας Βαθμονόμησης',
    use_old_camera_parameter: 'Θέλετε να χρησιμοποιήσετε την τρέχουσα παράμετρο φακού της κάμερας;',
    with_af: 'Με Αυτόματη Εστίαση',
    with_prism_lift: 'Με το Ador Prism Lift',
    without_af: 'Χωρίς Αυτόματη Εστίαση',
    without_prism_lift: 'Χωρίς το Ador Prism Lift',
    x_ratio: 'Αναλογία Χ',
    y_ratio: 'Αναλογία Υ',
    zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
  },
  camera_data_backup: {
    download_success: 'Επιτυχής λήψη δεδομένων κάμερας.',
    downloading_data: 'Λήψη δεδομένων...',
    estimated_time_left: 'Υπολοιπόμενος εκτιμώμενος χρόνος:',
    folder_not_exists: 'Ο επιλεγμένος φάκελος δεν υπάρχει.',
    incorrect_folder: 'Αποτυχία μεταφόρτωσης δεδομένων βαθμονόμησης. Παρακαλώ ελέγξτε εάν ο φάκελος που επιλέξατε είναι σωστός.',
    no_picture_found: 'Δεν βρέθηκε εικόνα στη μηχανή.',
    title: 'Αντίγραφο ασφαλείας δεδομένων κάμερας',
    upload_success: 'Επιτυχής αποστολή δεδομένων κάμερας.',
    uploading_data: 'Ανεβάζοντας δεδομένα...',
  },
  caption: {
    connectionTimeout: 'Λήξη χρονικού ορίου σύνδεσης',
  },
  change_logs: {
    added: 'Προστέθηκαν:',
    change_log: 'Αλλαγές:',
    changed: 'Άλλαξαν:',
    fixed: 'Διορθώθηκαν:',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    see_older_version: 'Δείτε παλαιότερες εκδόσεις',
  },
  code_generator: {
    barcode: 'Γραμμωτός κώδικας',
    qr_code: 'QR Κωδικός',
  },
  curve_engraving: {
    '921': 'Αποτυχία αυτόματης εστίασης.',
    '922': 'Αποτυχία εκτέλεσης μέτρησης με κόκκινο φως.',
    amount: 'Ποσότητα',
    apply_arkwork: 'Εφαρμογή έργου τέχνης στην καμπύλη',
    apply_camera: 'Εφαρμογή εικόνας κάμερας στην καμπύλη',
    click_to_select_point: 'Κάντε κλικ για να επιλέξετε ή να αποεπιλέξετε σημεία για επαναμέτρηση.',
    coloumns: 'Στήλες',
    column_gap: 'Διάκενο στηλών',
    err_object_over_range: 'Το αντικείμενο υπερβαίνει το εύρος μέτρησης.',
    failed_to_take_reference: 'Αποτυχία λήψης αναφοράς',
    gap: 'Κενό',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    measure_audofocus_area: 'Μετρήστε την περιοχή αυτόματης εστίασης',
    preview_3d_curve: 'Προεπισκόπηση χάραξης καμπύλης',
    remeasure: 'Επαναμέτρηση',
    remeasuring_points: 'Επαναμέτρηση σημείων...',
    reselect_area: 'Επαναεπιλογή περιοχής',
    row_gap: 'Διάκενο σειρών',
    rows: 'Σειρές',
    set_object_height: 'Ρύθμιση ύψους αντικειμένου',
    set_object_height_desc: 'Μετρήστε το μέγιστο πάχος του αντικειμένου.',
    start_autofocus: 'Εκκίνηση αυτόματης εστίασης',
    starting_measurement: 'Ξεκινώντας μέτρηση...',
    sure_to_delete: 'Θέλετε να διαγράψετε τα δεδομένα εστίασης της χάραξης καμπύλης;',
    take_reference: 'Λήψη αναφοράς',
    take_reference_desc: 'Παρακαλώ μετακινήστε την κεφαλή του λέιζερ στο υψηλότερο σημείο του αντικειμένου, χαμηλώστε τον αισθητήρα εστίασης και κάντε κλικ στο "Επιβεβαίωση" για εστίαση.',
  },
  device: {
    abort: 'Ακύρωση',
    aborted: 'Ακυρώθηκε',
    aborting: 'Ακύρωση',
    busy: 'Απασχολημένος',
    cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
    cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
    cartridge_serial_number: 'Ink cartridge serial number',
    close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
    completed: 'Ολοκληρώθηκε',
    completing: 'Ολοκλήρωση',
    deviceList: 'Λίστα συσκευών',
    disable: 'Απενεργοποίηση',
    disconnectedError: {
      caption: 'Η σύνδεση της συσκευής αποσυνδέθηκε',
      message: 'Παρακαλώ επιβεβαιώστε αν η πρόσβαση δικτύου του %s είναι διαθέσιμη',
    },
    enable: 'Ενεργοποίηση',
    firmware_version: 'Έκδοση υλικολογισμικού',
    ink_color: 'Ink color',
    ink_level: 'Ink level',
    ink_type: 'Ink type',
    IP: 'Διεύθυνση IP',
    model_name: 'Μοντέλο',
    occupied: 'Συντήρηση',
    pause: 'Παύση',
    paused: 'Σε παύση',
    pausedFromError: 'Σε παύση λόγω σφάλματος',
    pausing: 'Παύει',
    please_wait: 'Παρακαλώ περιμένετε...',
    preparing: 'Προετοιμασία',
    processing: 'Επεξεργασία',
    quit: 'Έξοδος',
    ready: 'Έτοιμος',
    reset: 'Επαναφορά',
    resuming: 'Συνέχιση',
    retry: 'Επανάληψη',
    running: 'Λειτουργία',
    scanning: 'Σάρωση',
    select: 'Επιλογή',
    select_printer: 'Επιλέξτε εκτυπωτή',
    serial_number: 'Σειριακός αριθμός',
    start: 'Εκκίνηση',
    starting: 'Εκκίνηση',
    status: 'Κατάσταση',
    submodule_type: 'Module',
    toolhead_change: 'Αλλαγή κεφαλής εργαλείου',
    unknown: 'Άγνωστη',
    uploading: 'Μεταφόρτωση',
    UUID: 'UUID',
  },
  device_selection: {
    no_beambox: '#801 Δεν μπορούμε να βρούμε το μηχάνημά σας στο δίκτυο.\nΠαρακαλώ ακολουθήστε τον <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">οδηγό</a> για αντιμετώπιση προβλημάτων σύνδεσης.',
    no_device_web: "#801 Ελέγξτε την κατάσταση της μηχανής σας ή κάντε κλικ στο 'Ρύθμιση μηχανής' παρακάτω για να ρυθμίσετε τη μηχανή.",
    select_usb_device: 'Επιλέξτε συσκευή USB',
  },
  editor: {
    exposure: 'Προεπισκόπηση έκθεσης',
    opacity: 'Προεπισκόπηση Αδιαφάνειας',
    prespray_area: 'Περιοχή προετοιμασίας',
  },
  error_pages: {
    screen_size: 'Λάβετε υπόψη ότι το Beam Studio μπορεί να μην λειτουργεί βέλτιστα στη συσκευή σας. Για βέλτιστη εμπειρία, θα πρέπει να είναι μια συσκευή με πλάτος οθόνης τουλάχιστον 1024 εικονοστοιχεία.',
  },
  flux_id_login: {
    connection_fail: '#847 Αποτυχία σύνδεσης στην υπηρεσία μέλους FLUX.',
    email: 'Email',
    flux_plus: {
      access_monotype_feature: 'Δεν έχετε το πρόσθετο γραμματοσειρών Monotype.',
      access_monotype_feature_note: 'Πρέπει να έχετε συνδρομή FLUX+ Pro ή το πρόσθετο γραμματοσειρών Monotype για να έχετε πρόσβαση σε αυτή τη λειτουργία.',
      access_plus_feature_1: 'Έχετε πρόσβαση σε μια',
      access_plus_feature_2: 'λειτουργία.',
      access_plus_feature_note: 'Πρέπει να έχετε συνδρομή FLUX+ για να έχετε πρόσβαση σε αυτή τη λειτουργία.',
      ai_credit_tooltip: 'Για αφαίρεση φόντου με τεχνητή νοημοσύνη',
      explore_plans: 'Εξερευνήστε τα σχέδια FLUX+',
      features: {
        ai_bg_removal: 'Αφαίρεση φόντου με τεχνητή νοημοσύνη',
        boxgen: 'Γεννήτρια 3D κουτιών',
        dmkt: '1000+ αρχεία σχεδίασης',
        monotype: '250+ προπληρωμένες γραμματοσειρές',
        my_cloud: 'Απεριόριστη αποθήκευση στο cloud',
      },
      flux_credit_tooltip: 'Για αρχεία Αγοράς Σχεδίων και αφαίρεση φόντου με τεχνητή νοημοσύνη',
      get_addon: 'Αποκτήστε το πρόσθετο',
      goto_member_center: 'Μεταβείτε στο Κέντρο Μελών',
      learn_more: 'Μάθετε περισσότερα',
      member_center_url: 'https://member.flux3dp.com/en-US/subscription',
      subscribe_now: 'Εγγραφείτε τώρα',
      thank_you: 'Σας ευχαριστούμε που είστε τιμημένο μέλος!',
      website_url: 'https://flux3dp.com/subscription',
    },
    forget_password: 'Ξεχάσατε τον κωδικό σας;',
    incorrect: 'Το email ή ο κωδικός πρόσβασης δεν είναι σωστά.',
    login: 'Σύνδεση',
    login_success: 'Επιτυχής σύνδεση.',
    lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
    new_to_flux: 'Νέος στο FLUX; Δημιουργήστε έναν λογαριασμό.',
    not_verified: 'Το email δεν έχει επιβεβαιωθεί ακόμα.',
    offline: 'Εργασία εκτός σύνδεσης',
    password: 'Κωδικός πρόσβασης',
    register: 'Δημιουργήστε τον λογαριασμό σας FLUX',
    remember_me: 'Να με θυμάσαι',
    signup_url: 'https://id.flux3dp.com/user/login#up',
    unlock_shape_library: 'Συνδεθείτε για να ξεκλειδώσετε τη βάση δεδομένων σχημάτων.',
    work_offline: 'Εργασία εκτός σύνδεσης',
  },
  framing: {
    area_check: 'Έλεγχος περιοχής',
    areacheck_desc: 'Εξασφαλίζει την ασφάλεια της περιοχής εργασίας προβάλλοντας το πλαίσιο οριοθέτησης του αντικειμένου και τη ζώνη επιτάχυνσης της κεφαλής λέιζερ.',
    calculating_task: 'Υπολογισμός εργασίας...',
    framing: 'Πλαισίωση',
    framing_desc: 'Προεπισκόπηση του ορίου αντικειμένου.',
    hull: 'Περίβλημα',
    hull_desc: 'Προεπισκοπεί ένα σχήμα που ακολουθεί στενά το σχέδιο, όπως ένα λάστιχο γύρω από το αντικείμενο.',
    low_laser: 'Χαμηλή ισχύς λέιζερ',
    low_laser_desc: 'Ορίστε τιμή χαμηλής ισχύος λέιζερ για την εργασία πλαισίωσης.',
    rotate_axis: 'Άξονας περιστροφής',
    rotateaxis_desc: 'Προεπισκόπηση του κέντρου του άξονα περιστροφής, ο άξονας δεν κινείται.',
    rotation_frame_warning: 'Η προεπισκόπηση πλαισίου θα περιστραφεί μία φορά. Αποφύγετε τη διακοπή στη μέση για να αποτρέψετε την κακή ευθυγράμμιση της σήμανσης.',
    rotation_framing_desc: 'Προεπισκόπηση ολόκληρης της περιοχής περιστροφής, ο άξονας περιστρέφεται σύμφωνα με την προεπισκόπηση.',
    start_task: 'Έναρξη Εργασίας',
    start_task_description: 'Αφού ολοκληρωθεί η προεπισκόπηση καρέ, κάντε κλικ στο "Έναρξη Εργασίας" για να υποβάλετε τη δουλειά.',
  },
  general: {
    choose_folder: 'Επιλέξτε φάκελο',
    processing: 'Επεξεργασία...',
  },
  generic_error: {
    OPERATION_ERROR: '[ΣΕ] Παρουσιάστηκε σύγκρουση κατάστασης, παρακαλώ επαναλάβετε την ενέργεια.',
    SUBSYSTEM_ERROR: '[ΣΥΕ] Σφάλμα κατά την εκτέλεση του έργου από το firmware της μηχανής. Παρακαλώ επανεκκινήστε τη μηχανή.',
    UNKNOWN_COMMAND: 'Παρακαλώ ενημερώστε το υλικολογισμικό της συσκευής',
    UNKNOWN_ERROR: '[ΑΕ] Παρουσιάστηκε άγνωστο σφάλμα. Παρακαλώ επανεκκινήστε το Beam Studio και τη μηχανή.',
  },
  global: {
    apply: 'Εφαρμογή',
    back: 'Πίσω',
    cancel: 'Ακύρωση',
    editing: {
      redo: 'Επανάληψη',
      reset: 'Επαναφορά',
      undo: 'Αναίρεση',
      zoom_in: 'Μεγέθυνση',
      zoom_out: 'Σμίκρυνση',
    },
    mode_conflict: 'Αυτή η επιλογή δεν είναι διαθέσιμη στην τρέχουσα λειτουργία.',
    ok: 'Εντάξει',
    save: 'Αποθήκευση',
    stop: 'Σταμάτημα',
  },
  image_edit_panel: {
    eraser: {
      brush_size: 'Μέγεθος πινέλου',
      description: 'Κάντε κλικ ή σύρετε για να διαγράψετε χειροκίνητα ανεπιθύμητες περιοχές.',
      title: 'Γόμα',
    },
    magic_wand: {
      description: 'Επιλέξτε και αφαιρέστε γειτονικές περιοχές με βάση την ομοιότητα χρωμάτων.',
      title: 'Μαγικό Ραβδί',
      tolerance: 'Ανοχή',
    },
    title: 'Επεξεργασία Εικόνας',
  },
  initialize: {
    back: 'Πίσω',
    cancel: 'Ακύρωση',
    confirm: 'Επιβεβαίωση',
    connect: 'Σύνδεση',
    connect_ethernet: {
      title: 'Άμεση σύνδεση',
      tutorial1: '1. Συνδέστε το μηχάνημα με τον υπολογιστή σας με καλώδιο Ethernet.',
      tutorial2_1: '2. Ακολουθήστε ',
      tutorial2_2: ' για να κάνετε τον υπολογιστή σας ως δρομολογητή.',
      tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
      tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
      tutorial2_a_text: 'αυτόν τον οδηγό',
      tutorial3: '3. Κάντε κλικ στο Επόμενο.',
    },
    connect_machine_ip: {
      alert: {
        swiftray_connection_error: 'Αδύνατη η σύνδεση με τον διακομιστή. Επανεκκινήστε το Beam Studio και δοκιμάστε ξανά.',
      },
      check_camera: 'Έλεγχος διαθεσιμότητας κάμερας',
      check_connection: 'Έλεγχος σύνδεσης μηχανήματος',
      check_firmware: 'Έλεγχος έκδοσης υλικολογισμικού',
      check_ip: 'Έλεγχος διαθεσιμότητας IP',
      check_swiftray_connection: 'Έλεγχος σύνδεσης διακομιστή',
      check_swiftray_connection_unreachable: 'ο διακομιστής δεν είναι προσβάσιμος',
      check_usb: 'Έλεγχος σύνδεσης USB',
      enter_ip: 'Εισάγετε τη διεύθυνση IP του μηχανήματός σας',
      finish_setting: 'Ξεκινήστε τη δημιουργία!',
      invalid_format: 'Μη έγκυρη μορφή',
      invalid_ip: 'Μη έγκυρη διεύθυνση IP: ',
      promark_hint: 'Εάν αποτύχετε επανειλημμένα να συνδεθείτε μέσω USB, ανατρέξτε στο <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">άρθρο του Κέντρου βοήθειας</a>.',
      retry: 'Επανάληψη',
      starts_with_169254: 'Ξεκινά με 169.254',
      succeeded_message: 'Επιτυχής σύνδεση 🎉',
      unreachable: 'Μη προσβάσιμη διεύθυνση IP',
    },
    connect_usb: {
      connect_camera: 'Συνδέστε την κάμερα της μηχανής στον υπολογιστή σας με καλώδιο USB.',
      title: 'Σύνδεση USB',
      title_sub: ' (HEXA & Ador Only)',
      turn_off_machine: 'Απενεργοποιήστε το μηχάνημα.',
      turn_on_machine: 'Ενεργοποιήστε το μηχάνημα.',
      tutorial1: 'Συνδέστε το μηχάνημα με τον υπολογιστή σας με καλώδιο USB.',
      tutorial2: 'Κάντε κλικ στο "Επόμενο".',
      wait_for_turning_on: 'Πατήστε "Επόμενο" μετά την ολοκλήρωση της διαδικασίας εκκίνησης και αφού έχετε μπει στην κύρια οθόνη.',
    },
    connect_wifi: {
      title: 'Σύνδεση στο Wi-Fi',
      tutorial1: '1. Μεταβείτε στον Πίνακα αφής > Πατήστε στο "Δίκτυο" > "Σύνδεση στο WiFi".',
      tutorial1_ador: '1. Μεταβείτε στον Πίνακα αφής > Πατήστε στο "ΜΗΧΑΝΗΜΑ" > Πατήστε στο "Δίκτυο" > "Σύνδεση στο WiFi".',
      tutorial2: '2. Επιλέξτε και συνδεθείτε στο προτιμώμενο Wi-Fi δίκτυό σας.',
      what_if_1: 'Τι συμβαίνει αν δεν βλέπω το Wi-Fi μου;',
      what_if_1_content: '1. Η κρυπτογράφηση Wi-Fi πρέπει να είναι WPA2 ή χωρίς κωδικό πρόσβασης.\n2. Η κρυπτογράφηση μπορεί να ρυθμιστεί στο περιβάλλον διαχείρισης δρομολογητή Wi-Fi. Αν ο δρομολογητής δεν υποστηρίζει WPA2 και χρειάζεστε βοήθεια στην επιλογή του σωστού δρομολογητή, επικοινωνήστε με την υποστήριξη.',
      what_if_2: 'Τι συμβαίνει αν δεν βλέπω κανένα Wi-Fi;',
      what_if_2_content: '1. Βεβαιωθείτε ότι το Wi-Fi dongle είναι πλήρως συνδεδεμένο.\n2. Αν δεν υπάρχει διεύθυνση MAC του ασύρματου δικτύου στην οθόνη αφής, επικοινωνήστε με την υποστήριξη.\n3. Το κανάλι Wi-Fi πρέπει να είναι 2.4Ghz (το 5Ghz δεν υποστηρίζεται).',
    },
    connect_wired: {
      title: 'Σύνδεση σε Ενσύρματο Δίκτυο',
      tutorial1: '1. Συνδέστε το μηχάνημα με το δρομολογητή σας.',
      tutorial2: '2. Πατήστε "Δίκτυο" για να λάβετε τη διεύθυνση IP ενσύρματου δικτύου.',
      tutorial2_ador: '2. Πατήστε "ΜΗΧΑΝΗΜΑ"> "Δίκτυο" για να λάβετε τη διεύθυνση IP ενσύρματου δικτύου.',
      what_if_1: 'Τι συμβαίνει αν η διεύθυνση IP είναι κενή;',
      what_if_1_content: '1. Βεβαιωθείτε ότι το καλώδιο Ethernet είναι πλήρως συνδεδεμένο.\n2. Αν δεν υπάρχει διεύθυνση MAC του ενσύρματου δικτύου στην οθόνη αφής, επικοινωνήστε με την υποστήριξη.',
      what_if_2: 'Τι συμβαίνει αν η διεύθυνση IP ξεκινάει με 169;',
      what_if_2_content: '1. Αν η διεύθυνση IP ξεκινάει με 169.254, θα πρέπει να υπάρχει πρόβλημα ρύθμισης DHCP, επικοινωνήστε με τον πάροχο υπηρεσιών διαδικτύου (ISP) για περαιτέρω βοήθεια.\n2. Αν ο υπολογιστής σας συνδέεται στο διαδίκτυο απευθείας χρησιμοποιώντας PPPoE, αλλάξτε ώστε να χρησιμοποιείτε το δρομολογητή για σύνδεση μέσω PPPoE και ενεργοποιήστε τη λειτουργία DHCP στο δρομολογητή.',
    },
    connecting: 'Σύνδεση...',
    connection_types: {
      ether2ether: 'Άμεση σύνδεση',
      usb: 'Σύνδεση USB',
      wifi: 'Wi-Fi',
      wired: 'Ενσύρματο δίκτυο',
    },
    next: 'Επόμενο',
    no_machine: 'Δεν έχω μηχάνημα αυτή τη στιγμή.',
    promark: {
      configuration_confirmation: 'Αυτό διασφαλίζει ότι το Promark σας είναι σωστά ρυθμισμένο για βέλτιστη απόδοση και ακρίβεια.',
      or_complete_later: `Ή παραλείψτε αυτό το βήμα και ολοκληρώστε τις Ρυθμίσεις Promark αργότερα στο:
    Μηχανήματα > "Όνομα Promark" > Ρυθμίσεις Promark`,
      qc_instructions: 'Συμπληρώστε τις παραμέτρους που βρίσκονται στο πίσω μέρος της κάρτας "QC Pass"',
      select_laser_source: 'Επιλέξτε το Promark σας',
      select_workarea: 'Επιλέξτε Περιοχή Εργασίας',
      settings: 'Ρυθμίσεις Promark',
    },
    retry: 'Επανάληψη',
    select_beambox: 'Επιλέξτε το Beambox σας',
    select_connection_type: 'Πώς θέλετε να συνδεθείτε;',
    select_language: 'Επιλέξτε γλώσσα',
    select_machine_type: 'Επιλέξτε τον τύπο του μηχανήματός σας',
    setting_completed: {
      back: 'Πίσω',
      great: 'Καλώς ήρθατε στο Beam Studio',
      ok: 'ΞΕΚΙΝΗΣΤΕ ΤΗ ΔΗΜΙΟΥΡΓΙΑ',
      setup_later: 'Μπορείτε πάντα να ρυθμίσετε τη μηχανή σας από τη γραμμή τίτλου > "Μηχανές" > "Ρύθμιση μηχανής"',
      start: 'Έναρξη',
    },
    skip: 'Παράλειψη',
    start: 'Έναρξη',
  },
  input_machine_password: {
    connect: 'ΣΥΝΔΕΣΗ',
    password: 'Κωδικός πρόσβασης',
    require_password: '"%s" απαιτεί κωδικό πρόσβασης',
  },
  insecure_websocket: {
    extension_detected: 'Εντοπίστηκε η επέκταση Beam Studio Connect',
    extension_detected_description: 'Ανιχνεύσαμε ότι έχετε εγκαταστήσει την επέκταση Beam Studio Connect. Κάντε κλικ στο "Επιβεβαίωση" για να ανακατευθυνθείτε στο HTTPS, ή κάντε κλικ στο "Ακύρωση" για να συνεχίσετε να χρησιμοποιείτε το HTTP.',
    extension_not_deteced: 'Δεν ήταν δυνατή η ανίχνευση της επέκτασης Beam Studio Connect',
    extension_not_deteced_description: "Για να χρησιμοποιήσετε το HTTPS, κάντε κλικ στο 'Επιβεβαίωση' για να εγκαταστήσετε την επέκταση Beam Studio Connect. Μετά την εγκατάσταση της επέκτασης, παρακαλώ ανανεώστε τη σελίδα για να την ενεργοποιήσετε.<br/>Διαφορετικά, κάντε κλικ στον παρακάτω σύνδεσμο για να δείτε πώς να χρησιμοποιήσετε το HTTP στο Chrome.",
    unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Σύνδεσμος</a>',
  },
  layer_module: {
    general_laser: 'Λέιζερ',
    laser_2w_infrared: 'Υπέρυθρο λέιζερ 2W',
    laser_10w_diode: 'Λέιζερ διόδου 10W',
    laser_20w_diode: 'Λέιζερ διόδου 20W',
    non_working_area: 'Μη Εργασιακή Περιοχή',
    none: 'None',
    notification: {
      convertFromLaserModuleMsg: 'Προσέξτε, αν ολοκληρώσετε αυτή τη λειτουργία, οι ρυθμίσεις του στρώματος λέιζερ θα αφαιρεθούν.',
      convertFromLaserModuleTitle: 'Θέλετε να μετατρέψετε το λέιζερ σε εκτύπωση;',
      convertFromPrintingModuleMsg: 'Προσέξτε, αν ολοκληρώσετε αυτή τη λειτουργία, οι ρυθμίσεις χρωμάτων του στρώματος εκτύπωσης θα αφαιρεθούν.',
      convertFromPrintingModuleTitle: 'Θέλετε να μετατρέψετε την εκτύπωση σε λέιζερ;',
      importedDocumentContainsPrinting: 'Το έγγραφο περιέχει στρώμα εκτύπωσης, θέλετε να αλλάξετε την περιοχή εργασίας σε Ador;',
      performIRCaliMsg: 'Κάντε κλικ στο "Επιβεβαίωση" για να εκτελέσετε βαθμονόμηση ή μέσω του μενού (Μηχάνημα > [Όνομα Μηχανήματος] > Βαθμονόμηση Υπερύθρου)',
      performIRCaliTitle: 'Εκτελέστε βαθμονόμηση υπερύθρου',
      performPrintingCaliMsg: 'Κάντε κλικ στο "Επιβεβαίωση" για να εκτελέσετε βαθμονόμηση ή μέσω του μενού (Μηχάνημα > [Όνομα Μηχανήματος] > Βαθμονόμηση Εκτύπωσης)',
      performPrintingCaliTitle: 'Εκτελέστε βαθμονόμηση εκτύπωσης',
      printingLayersCoverted: 'Τα στρώματα εκτύπωσης έχουν μετατραπεί σε στρώματα λέιζερ.',
    },
    printing: 'Εκτύπωση',
    unknown: 'Unknown Module',
  },
  machine_status: {
    '-17': 'Λειτουργία κασέτας',
    '-10': 'Λειτουργία συντήρησης',
    '-2': 'Σάρωση',
    '-1': 'Συντήρηση',
    '0': 'Έτοιμο',
    '1': 'Εκτελείται',
    '2': 'Παύση',
    '4': 'Σε παύση',
    '6': 'Σε παύση',
    '16': 'Σε παύση',
    '18': 'Σε παύση',
    '32': 'Σε παύση',
    '36': 'Σε παύση',
    '38': 'Παύση',
    '48': 'Σε παύση',
    '50': 'Παύση',
    '64': 'Ολοκληρώθηκε',
    '66': 'Ολοκλήρωση',
    '68': 'Προετοιμασία',
    '128': 'Ακυρώθηκε',
    '256': 'Συναγερμός',
    '512': 'Θανατηφόρο',
    UNKNOWN: 'Άγνωστο',
  },
  material_test_generator: {
    block_settings: 'Ρυθμίσεις Μπλοκ',
    columns: 'Στήλες',
    count: 'Ποσότητα',
    cut: 'Κοπή',
    engrave: 'Χάραξη',
    export: 'Εξαγωγή',
    max: 'Μέγ',
    min: 'Ελάχ',
    parameter: 'Παράμετρος',
    preview: 'Προεπισκόπηση',
    rows: 'Σειρές',
    size: 'Μέγεθος (ΥxΠ)',
    spacing: 'Απόσταση',
    table_settings: 'Ρυθμίσεις Πίνακα',
    text_settings: 'Ρυθμίσεις κειμένου',
    title: 'Γεννήτρια Δοκιμής Υλικού',
  },
  menu: {
    inches: 'Ίντσες',
    mm: 'χιλιοστά',
  },
  message: {
    auth_error: '#820 Σφάλμα έγκρισης: Παρακαλώ ενημερώστε το Beam Studio και το υλικολογισμικό της μηχανής στην πιο πρόσφατη έκδοση.',
    authenticating: 'Πιστοποίηση σε εξέλιξη...',
    camera: {
      abort_preview: 'Ακύρωση',
      camera_cable_unstable: 'Ανιχνεύθηκε ασταθής μετάδοση εικόνας από την κάμερα. Η προεπισκόπηση μπορεί ακόμα να εκτελεστεί κανονικά, αλλά μπορεί να υπάρχει πρόβλημα αργής προεπισκόπησης ή λήξης χρονικού ορίου.',
      continue_preview: 'Συνέχεια',
      fail_to_transmit_image: '#845 Κάτι πήγε στραβά με τη μετάδοση εικόνας. Παρακαλώ επανεκκινήστε το μηχάνημά σας ή το Beam Studio. Αν αυτό το σφάλμα επιμένει, ακολουθήστε τον οδηγό.',
      ws_closed_unexpectly: '#844 Η σύνδεση με την κάμερα του μηχανήματος έχει κλείσει απροσδόκητα. Αν αυτό το σφάλμα επιμένει, ακολουθήστε τον οδηγό.',
    },
    cancelling: 'Ακύρωση...',
    cant_connect_to_device: '#827 Αδυναμία σύνδεσης με τη μηχανή, παρακαλώ ελέγξτε τη σύνδεσή σας',
    connected: 'Συνδέθηκε',
    connecting: 'Σύνδεση...',
    connectingCamera: 'Σύνδεση κάμερας...',
    connectingMachine: 'Σύνδεση με %s...',
    connectionTimeout: '#805 Χρονικό όριο σύνδεσης συσκευής. Ελέγξτε την κατάσταση του δικτύου σας και τον δείκτη Wi-Fi του μηχανήματός σας.',
    device_blocked: {
      caption: 'Μη εξουσιοδοτημένος αριθμός σειράς',
      offline: 'Ο σειριακός αριθμός της συσκευής σας περιορίζεται στη χρήση εκτός σύνδεσης καθώς φαίνεται να είναι απενεργοποιημένος. Παρακαλούμε να δώσετε τον σειριακό αριθμό της συσκευής (που βρίσκεται στο πίσω μέρος της συσκευής) στον έμπορό σας και να τους ζητήσετε να επικοινωνήσουν με το support@flux3dp.com για να ενεργοποιηθεί η εξουσιοδότηση σύνδεσης. Εάν θέλετε να χρησιμοποιήσετε τη συσκευή εκτός σύνδεσης, επικοινωνήστε απευθείας με το support@flux3dp.com.',
      online: 'Ο σειριακός αριθμός της συσκευής σας φαίνεται να είναι απενεργοποιημένος. Παρακαλούμε να δώσετε τον σειριακό αριθμό της συσκευής (που βρίσκεται στο πίσω μέρος της συσκευής) στον έμπορό σας και να τους ζητήσετε να επικοινωνήσουν με το support@flux3dp.com για να ενεργοποιηθεί η εξουσιοδότηση της συσκευής.',
    },
    device_busy: {
      caption: 'Η συσκευή είναι απασχολημένη',
      message: 'Η συσκευή εκτελεί άλλη εργασία, δοκιμάστε αργότερα. Αν σταματήσει να λειτουργεί, επανεκκινήστε τη συσκευή.',
    },
    device_is_used: 'Η συσκευή χρησιμοποιείται, θέλετε να ακυρώσετε την τρέχουσα εργασία;',
    device_not_found: {
      caption: 'Δεν βρέθηκε προεπιλεγμένη συσκευή',
      message: '#812 Ελέγξτε την ένδειξη Wi-Fi της συσκευής σας',
    },
    disconnected: 'Ασταθής σύνδεση, παρακαλώ ελέγξτε τη σύνδεση της συσκευής και δοκιμάστε ξανά αργότερα',
    endingLineCheckMode: 'Έξοδος από τη λειτουργία ελέγχου γραμμής...',
    endingRawMode: 'Έξοδος από ακατέργαστη λειτουργία...',
    enteringLineCheckMode: 'Είσοδος σε λειτουργία ελέγχου γραμμής...',
    enteringRawMode: 'Είσοδος σε ακατέργαστη λειτουργία...',
    enteringRedLaserMeasureMode: 'Είσοδος σε λειτουργία μέτρησης με κόκκινο λέιζερ...',
    exitingRotaryMode: 'Έξοδος από τη λειτουργία περιστροφής...',
    getProbePosition: 'Λήψη θέσης Αισθητήρα...',
    gettingLaserSpeed: 'Ανάγνωση ταχύτητας κεφαλής λέιζερ...',
    homing: 'Ομοιοποίηση...',
    need_password: 'Απαιτείται κωδικός πρόσβασης για σύνδεση με τη συσκευή',
    please_enter_dpi: 'Παρακαλώ εισάγετε τη μονάδα του αρχείου σας (σε mm)',
    preview: {
      adjust: 'Προσαρμογή',
      adjust_height_tooltip: 'Κάντε κλικ στο πλαίσιο ελέγχου για να ενεργοποιήσετε την επεξεργασία.',
      already_performed_auto_focus: 'Έχετε ήδη εκτελέσει αυτόματη εστίαση, να χρησιμοποιήσετε τις υπάρχουσες ρυθμίσεις;',
      auto_focus: 'Αυτόματη εστίαση',
      auto_focus_instruction: 'Παρακαλώ μετακινήστε το κεφάλι του λέιζερ πάνω από το αντικείμενο και ακολουθήστε τις οδηγίες κίνησης για να πατήσετε AF για να εστιάσετε.',
      camera_preview: 'Προεπισκόπηση κάμερας',
      enter_manually: 'Εισαγωγή χειροκίνητα',
      please_enter_height: 'Παρακαλώ εισάγετε το ύψος του αντικειμένου για ακριβή λήψη κάμερας.',
    },
    promark_disconnected: '#850 Η σύνδεση με τη συσκευή διακόπηκε, ελέγξτε την κατάσταση σύνδεσης της συσκευής.',
    redLaserTakingReference: 'Λήψη αναφοράς...',
    retrievingCameraOffset: 'Ανάγνωση μετατόπισης κάμερας...',
    settingLaserSpeed: 'Ρύθμιση ταχύτητας κεφαλής λέιζερ...',
    swiftray_disconnected: 'Δεν είναι δυνατή η σύνδεση με το backend, προσπάθεια επανασύνδεσης.',
    swiftray_reconnected: 'Το backend επανασυνδέθηκε, δοκιμάστε να στείλετε ξανά την εργασία.',
    time_remaining: 'Υπολειπόμενος χρόνος:',
    tryingToConenctMachine: 'Προσπάθεια σύνδεσης με το μηχάνημα...',
    turningOffAirPump: 'Απενεργοποίηση αντλίας αέρα...',
    turningOffFan: 'Απενεργοποίηση ανεμιστήρα...',
    unable_to_find_machine: 'Αδυναμία εύρεσης μηχανής',
    unable_to_start: '#830 Αδυναμία έναρξης της εργασίας. Παρακαλώ δοκιμάστε ξανά. Αν συμβεί ξανά, επικοινωνήστε μαζί μας με αναφορά σφαλμάτων:',
    unavailableWorkarea: '#804 Η τρέχουσα περιοχή εργασίας υπερβαίνει την περιοχή εργασίας αυτής της μηχανής. Παρακαλώ ελέγξτε την περιοχή εργασίας της επιλεγμένης μηχανής ή ορίστε την περιοχή εργασίας από Επεξεργασία > Ρυθμίσεις εγγράφου.',
    unknown_device: '#826 Δεν είναι δυνατή η σύνδεση με τη συσκευή, βεβαιωθείτε ότι το USB είναι συνδεδεμένο με τη συσκευή',
    unknown_error: '#821 Η εφαρμογή συνάντησε άγνωστο σφάλμα, χρησιμοποιήστε Βοήθεια > Μενού > Αναφορά σφαλμάτων.',
    unsupport_osx_version: 'Η τρέχουσα έκδοση macOS %s ενδέχεται να μην υποστηρίζει ορισμένες λειτουργίες. Ενημερώστε παρακαλώ σε macOS 11+.',
    unsupport_win_version: 'Η τρέχουσα έκδοση λειτουργικού συστήματος %s ενδέχεται να μην υποστηρίζει ορισμένες λειτουργίες. Ενημερώστε παρακαλώ στην πιο πρόσφατη έκδοση.',
    unsupported_example_file: 'Το επιλεγμένο παράδειγμα αρχείου δεν υποστηρίζεται από την τρέχουσα περιοχή εργασίας.',
    uploading_fcode: 'Μεταφόρτωση FCode',
    usb_unplugged: 'Η σύνδεση USB έχει χαθεί. Παρακαλώ ελέγξτε τη σύνδεση USB σας',
    wrong_swiftray_version_message: 'Λανθασμένη έκδοση backend (έκδοση: {version}). Ελέγξτε εάν εκτελούνται πολλαπλές εμφανίσεις του Beam Studio και κλείστε τις περιττές πριν ξαναπροσπαθήσετε.',
    wrong_swiftray_version_title: 'Λανθασμένη έκδοση backend',
  },
  monitor: {
    ask_reconnect: 'Η σύνδεση με τη μηχανή διακόπηκε. Θέλετε να συνδεθείτε ξανά;',
    bug_report: 'Αναφορά Σφαλμάτων',
    camera: 'Κάμερα',
    cancel: 'Ακύρωση',
    confirmFileDelete: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το αρχείο;',
    connecting: 'Σύνδεση, παρακαλώ περιμένετε...',
    DEVICE_ERROR: 'Κάτι πήγε στραβά\\nΠαρακαλώ επανεκκινήστε το μηχάνημα',
    download: 'Λήψη',
    extensionNotSupported: 'Αυτή η μορφή αρχείου δεν υποστηρίζεται',
    fileExistContinue: 'Το αρχείο υπάρχει ήδη, θέλετε να το αντικαταστήσετε;',
    forceStop: 'Θέλετε να ακυρώσετε την τρέχουσα εργασία;',
    go: 'Έναρξη',
    HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Αισθητήρας ροής αέρα ανώμαλος',
    HARDWARE_ERROR_BOTTOM_OPENED: '#903 Το κάτω μέρος άνοιξε. Κλείστε το κάτω μέρος για να συνεχίσετε.',
    HARDWARE_ERROR_DOOR_OPENED: '#901 Κλείστε την πόρτα για να συνεχίσετε.',
    HARDWARE_ERROR_DRAWER_OPENED: '#911 Το συρτάρι άνοιξε',
    HARDWARE_ERROR_FIRE_DETECTED: '#912 Ανώμαλος αισθητήρας φλόγας',
    HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Εντοπίστηκε λανθασμένη κεφαλή. Παρακαλώ εγκαταστήστε τη σωστή κεφαλή για να συνεχίσετε.',
    HARDWARE_ERROR_HEADTYPE_NONE: '#917 Δεν εντοπίστηκε κεφαλή. Παρακαλώ βεβαιωθείτε ότι η κεφαλή έχει εγκατασταθεί σωστά για να συνεχίσετε.',
    HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Εντοπίστηκε άγνωστη κεφαλή. Παρακαλώ εγκαταστήστε τη σωστή κεφαλή για να συνεχίσετε.',
    HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Σφάλμα Ομαλής Εκκίνησης',
    HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Κρίσιμο σφάλμα: Το κύριο τμήμα εκτός σύνδεσης. Παρακαλούμε επικοινωνήστε με την υποστήριξη.',
    HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Υπερθερμάνθηκε. Παρακαλώ περιμένετε λίγα λεπτά.',
    HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Αποτυχία επαναφοράς άξονα z',
    HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Καμία απόκριση από τη μονάδα εκτύπωσης.',
    HARDWARE_ERROR_PROBE_SHOWED: 'Παρακαλώ ανασύρετε τον ανιχνευτή.',
    HARDWARE_ERROR_PUMP_ERROR: '#900 Παρακαλώ ελέγξτε τη δεξαμενή νερού σας.',
    HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Δεν εντοπίστηκε μονάδα περιστροφής',
    HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Κρίσιμο σφάλμα: Το υποσύστημα δεν απαντά. Παρακαλούμε επικοινωνήστε με την υποστήριξη.',
    HARDWARE_FAILURE: 'Κάτι πήγε στραβά. Παρακαλώ επανεκκινήστε το μηχάνημα',
    hour: 'ώ',
    left: 'αριστερά',
    MAINBOARD_OFFLINE: '#905 Σφάλμα κατά τη σύνδεση με την κεντρική πλακέτα. Παρακαλώ επανεκκινήστε το μηχάνημα.',
    minute: 'λ',
    monitor: 'Παρακολούθηση',
    NO_RESPONSE: '#905 Σφάλμα κατά τη σύνδεση με την κεντρική πλακέτα.\\nΠαρακαλώ επανεκκινήστε το μηχάνημα.',
    pause: 'Παύση',
    prepareRelocate: 'Προετοιμασία για μετεγκατάσταση',
    processing: 'Επεξεργασία',
    record: 'Εγγραφή',
    relocate: 'Μετεγκατάσταση',
    RESOURCE_BUSY: 'Το μηχάνημα είναι απασχολημένο\\nΑν δεν λειτουργεί, παρακαλώ επανεκκινήστε το μηχάνημα',
    resume: 'Συνέχεια',
    savingPreview: 'Δημιουργία προεπισκοπήσεων',
    second: 'δ',
    start: 'Έναρξη',
    stop: 'Διακοπή',
    SUBSYSTEM_ERROR: '#402 Κρίσιμο Σφάλμα: Καμία απόκριση υποσυστήματος. Παρακαλώ επικοινωνήστε με την υποστήριξη.',
    task: {
      BEAMBOX: 'Χάραξη με λέιζερ',
      'N/A': 'Ελεύθερη λειτουργία',
    },
    taskTab: 'Εργασία',
    temperature: 'Θερμοκρασία',
    upload: 'Μεταφόρτωση',
    USER_OPERATION: 'Ακολουθήστε τις οδηγίες στο πίνακα της συσκευής για να συνεχίσετε.',
    USER_OPERATION_CHANGE_CARTRIDGE: 'Παρακαλούμε εισάγετε τη σωστή κάρτα για να συνεχίσετε.',
    USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Παρακαλούμε εισάγετε την κάρτα για να συνεχίσετε.',
    USER_OPERATION_CHANGE_TOOLHEAD: 'Παρακαλώ εγκαταστήστε τη σωστή κεφαλή για να συνεχίσετε.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Εντοπίστηκε λανθασμένη κεφαλή. Παρακαλώ εγκαταστήστε τη σωστή κεφαλή για να συνεχίσετε.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Δεν εντοπίστηκε κεφαλή. Παρακαλώ βεβαιωθείτε ότι η κεφαλή έχει εγκατασταθεί σωστά για να συνεχίσετε.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Εντοπίστηκε άγνωστη κεφαλή. Παρακαλώ εγκαταστήστε τη σωστή κεφαλή για να συνεχίσετε.',
    USER_OPERATION_REMOVE_CARTRIDGE: 'Παρακαλώ αφαιρέστε την κασέτα για να συνεχίσετε.',
    USER_OPERATION_ROTARY_PAUSE: 'Παρακαλώ αλλάξτε στον περιστροφικό κινητήρα.',
  },
  my_cloud: {
    action: {
      confirmFileDelete: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το αρχείο; Αυτή η ενέργεια δεν μπορεί να αναιρεθεί.',
      delete: 'Διαγραφή',
      download: 'Λήψη',
      duplicate: 'Αντίγραφο',
      open: 'Άνοιγμα',
      rename: 'Μετονομασία',
    },
    file_limit: 'δωρεάν αρχείο',
    loading_file: 'Φόρτωση...',
    no_file_subtitle: 'Μεταβείτε στο Μενού > "Αρχείο" > "Αποθήκευση στο Cloud"',
    no_file_title: 'Αποθηκεύστε αρχεία στο My Cloud για να ξεκινήσετε.',
    save_file: {
      choose_action: 'Αποθήκευση αρχείου:',
      input_file_name: 'Εισαγωγή ονόματος αρχείου:',
      invalid_char: 'Μη έγκυροι χαρακτήρες:',
      save: 'Αποθήκευση',
      save_new: 'Αποθήκευση ως νέο αρχείο',
      storage_limit_exceeded: 'Η αποθήκευση στο cloud σας έχει φτάσει στο ανώτατο όριο. Παρακαλώ διαγράψτε τυχόν περιττά αρχεία πριν αποθηκεύσετε νέα.',
    },
    sort: {
      a_to_z: 'Όνομα: Α - Ω',
      most_recent: 'Πιο πρόσφατα',
      oldest: 'Παλαιότερα',
      z_to_a: 'Όνομα: Ω - Α',
    },
    title: 'Το Cloud μου',
    upgrade: 'Αναβάθμιση',
  },
  noun_project_panel: {
    clear: 'Καθαρισμός',
    elements: 'Στοιχεία',
    enjoy_shape_library: 'Απολαύστε τη βάση δεδομένων σχημάτων.',
    export_svg_title: 'Αδυναμία εξαγωγής SVG',
    export_svg_warning: 'Αυτό το έργο περιέχει αντικείμενα που προστατεύονται από το νόμο περί πνευματικής ιδιοκτησίας. Επομένως, το Beam Studio θα εξαιρέσει αυτόματα αυτά τα αντικείμενα κατά την εξαγωγή. Μπορείτε ακόμα να αποθηκεύσετε το έργο σας σε μορφή Beam Studio Scene (.beam) για να διατηρήσετε όλα τα δεδομένα σας. Θέλετε ακόμα να εξαγάγετε;',
    learn_more: 'Μάθετε περισσότερα',
    login_first: 'Συνδεθείτε για να ξεκλειδώσετε τη βάση δεδομένων σχημάτων.',
    recent: 'Πρόσφατα',
    search: 'Αναζήτηση',
    shapes: 'Σχήματα',
  },
  pass_through: {
    export: 'Εξαγωγή στην Περιοχή Εργασίας',
    exporting: 'Εξαγωγή...',
    guide_mark: 'Σημείο Οδηγού',
    guide_mark_desc: 'Τα σημεία οδηγού θα χαραχθούν ως σημείο αναφοράς για την ευθυγράμμιση του έργου τέχνης.',
    guide_mark_length: 'Μήκος:',
    guide_mark_x: 'Συντεταγμένη Χ:',
    height_desc: 'Ρυθμίστε το ύψος κάθε τμήματος της περιοχής εργασίας.',
    help_links: {
      ado1: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
      fbb2: 'https://support.flux3dp.com/hc/en-us/articles/11570021253263',
    },
    help_text: 'Πώς να ρυθμίσετε τη διέλευση για το %(model)s;',
    object_length: 'Μήκος Αντικειμένου',
    ref_layer: 'Επίπεδο Αναφοράς',
    ref_layer_desc: 'Σημειώστε ότι η εκτέλεση του επιπέδου αναφοράς έχει οριστεί σε 0 από προεπιλογή. Δεν θα εκτελεστεί, αλλά είναι μόνο για αναφορά ευθυγράμμισης.',
    ref_layer_name: 'Αναφορά',
    title: 'Λειτουργία διέλευσης',
    workarea_height: 'Περιοχή Εργασίας (Ύψος):',
  },
  promark_connection_test: {
    description: 'Το σύστημα θα εκτελέσει μια λειτουργία κόκκινου φωτός για περίπου δύο λεπτά για να ελέγξει εάν η σύνδεση είναι σταθερή.',
    health: 'Σταθερότητα:',
    healthy_description: 'Η σταθερότητα είναι καλή, αλλά αποφύγετε σημαντικές μετακινήσεις του υπολογιστή κατά τη χάραξη.',
    res_0: 'Εξαιρετική',
    res_1: 'Καλή',
    res_2: 'Χαμηλή',
    res_3: 'Πολύ χαμηλή',
    res_4: 'Εξαιρετικά χαμηλή',
    restart: 'Επανεκκίνηση δοκιμής',
    start: 'Έναρξη δοκιμής',
    stop: 'Διακοπή δοκιμής',
    title: 'Δοκιμή σταθερότητας σύνδεσης',
    unhealthy_description: 'Η σταθερότητα είναι πολύ χαμηλή. Συνιστάται η αντικατάσταση του προσαρμογέα δικτύου ή του καλωδίου τροφοδοσίας.',
  },
  promark_settings: {
    angle: 'Γωνία',
    bulge: 'Εξόγκωμα',
    field: 'Πεδίο',
    galvo_configuration: 'Ρυθμίσεις Galvo',
    mark: 'Σήμανση',
    mark_parameters: 'Παράμετροι χάραξης',
    offsetX: 'Μετατόπιση X',
    offsetY: 'Μετατόπιση Y',
    preview: 'Προεπισκόπηση',
    red_dot: 'Κόκκινη κουκκίδα',
    scale: 'Κλίμακα',
    scaleX: 'Κλίμακα X',
    scaleY: 'Κλίμακα Y',
    skew: 'Κλίση',
    switchXY: 'Εναλλαγή X/Y',
    title: 'Ρυθμίσεις Promark',
    trapezoid: 'Τραπέζιο',
    workarea_hint: 'Μπορείτε να αλλάξετε την περιοχή εργασίας στις "Ρυθμίσεις Εγγράφου".',
    z_axis_adjustment: {
      section1: 'Ρυθμίστε το ύψος του άξονα Ζ για να βελτιώσετε την εστίαση.',
      title: 'Ρύθμιση Άξονα Ζ',
      tooltip1: 'Δοκιμάστε να μαρκάρετε ένα τετράγωνο 1x1 εκ. για να επιβεβαιώσετε αν η τρέχουσα εστιακή απόσταση είναι κατάλληλη.',
    },
  },
  qr_code_generator: {
    error_tolerance: 'Ανοχή σφάλματος',
    error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
    invert: 'Αντιστροφή χρωμάτων φόντου',
    placeholder: 'Εισάγετε έναν σύνδεσμο ή κείμενο',
    preview: 'Προεπισκόπηση',
    title: 'Δημιουργός κωδικού QR',
  },
  rotary_settings: {
    circumference: 'Περιφέρεια',
    extend_workarea: 'Επέκταση περιοχής εργασίας',
    mirror: 'Κατοπτρισμός',
    object_diameter: 'Διάμετρος Αντικειμένου',
    overlap_size: 'Μέγεθος επικάλυψης',
    rotary_scale: 'Κλίμακα περιστροφής',
    split_setting: 'Ρύθμιση διαίρεσης',
    split_setting_url: 'https://support.flux3dp.com/hc/en-us/articles/12276094010767',
    split_size: 'Μέγεθος διαίρεσης',
    type: 'τύπος',
  },
  select_device: {
    auth_failure: '#811 Αποτυχία πιστοποίησης',
    select_device: 'Επιλέξτε συσκευή',
    unable_to_connect: '#810 Αδυναμία δημιουργίας σταθερής σύνδεσης με τη μηχανή',
  },
  settings: {
    anti_aliasing: 'Αντι-aliasing',
    auto_connect: 'Αυτόματη επιλογή μοναδικής συσκευής',
    auto_switch_tab: 'Αυτόματη εναλλαγή μεταξύ πίνακα επιπέδων και αντικειμένων',
    autofocus_offset: 'Αντιστάθμιση αυτόματης εστίασης',
    autosave_enabled: 'Αυτόματη αποθήκευση',
    autosave_interval: 'Αυτόματη αποθήκευση κάθε',
    autosave_number: 'Αριθμός αυτόματων αποθηκεύσεων',
    autosave_path: 'Τοποθεσία αυτόματης αποθήκευσης',
    autosave_path_not_correct: 'Η καθορισμένη διαδρομή δεν βρέθηκε.',
    blade_precut_position: 'Θέση προκοπής',
    blade_precut_switch: 'Προκοπή λεπίδας',
    blade_radius: 'Ακτίνα λεπίδας',
    bottom_up: 'Από κάτω προς τα πάνω',
    calculation_optimization: 'Επιτάχυνση υπολογισμού διαδρομής',
    cancel: 'Ακύρωση',
    caption: 'Ρυθμίσεις',
    check_updates: 'Αυτόματος έλεγχος',
    close: 'Κλείσιμο',
    confirm_remove_default: 'Το προεπιλεγμένο μηχάνημα θα αφαιρεθεί.',
    confirm_reset: 'Επιβεβαίωση επαναφοράς Beam Studio',
    continuous_drawing: 'Συνεχής σχεδίαση',
    curve_engraving_speed_limit: 'Όριο ταχύτητας χάραξης καμπύλης',
    custom_preview_height: 'Προσαρμοσμένο Ύψος Προεπισκόπησης',
    default_beambox_model: 'Προεπιλεγμένη ρύθμιση εγγράφου',
    default_borderless_mode: 'Προεπιλογή Άνοιγμα Κάτω',
    default_enable_autofocus_module: 'Προεπιλογή Αυτόματης Εστίασης',
    default_enable_diode_module: 'Προεπιλογή Διόδου Λέιζερ',
    default_font_family: 'Προεπιλεγμένη γραμματοσειρά',
    default_font_style: 'Προεπιλεγμένο στυλ γραμματοσειράς',
    default_laser_module: 'Προεπιλεγμένη μονάδα λέιζερ',
    default_machine: 'Προεπιλεγμένο μηχάνημα',
    default_machine_button: 'Κενό',
    default_units: 'Προεπιλεγμένες μονάδες',
    diode_offset: 'Αντιστάθμιση λέιζερ διόδου',
    diode_one_way_engraving: 'Μονόδρομη χάραξη λέιζερ διόδου',
    diode_two_way_warning: 'Η αμφίδρομη εκπομπή φωτός είναι ταχύτερη και μπορεί να προκαλέσει ανακρίβεια στη θέση χάραξης λέιζερ. Συνιστάται δοκιμή πρώτα.',
    disabled: 'Απενεργοποιημένο',
    done: 'Εφαρμογή',
    enable_custom_backlash: 'Ενεργοποίηση Προσαρμοσμένης Αντιστάθμισης Οπισθοδρόμησης',
    enable_low_speed: 'Ενεργοποίηση Χαμηλής Ταχύτητας',
    enabled: 'Ενεργοποιημένο',
    engraving_direction: 'Κατεύθυνση',
    fast_gradient: 'Βελτιστοποίηση ταχύτητας',
    font_convert: 'Μετατροπέας κειμένου σε μονοπάτι',
    font_substitute: 'Υποκατάσταση Μη Υποστηριζόμενων Χαρακτήρων',
    grouped_objects: 'Ομαδοποιημένα Αντικείμενα',
    groups: {
      ador_modules: 'Πρόσθετα Ador',
      autosave: 'Αυτόματη αποθήκευση',
      camera: 'Κάμερα',
      connection: 'Σύνδεση',
      editor: 'Επεξεργαστής',
      engraving: 'Χάραξη (σάρωση)',
      general: 'Γενικά',
      mask: 'Περικοπή Περιοχής Εργασίας',
      modules: 'Πρόσθετα',
      path: 'Διανύσματα (Περιγράμματα)',
      privacy: 'Ιδιωτικότητα',
      text_to_path: 'Κείμενο',
      update: 'Ενημερώσεις λογισμικού',
    },
    guess_poke: 'Αναζήτηση διεύθυνσης IP συσκευής',
    guides: 'Οδηγοί',
    guides_origin: 'Προέλευση οδηγών',
    help_center_urls: {
      anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
      calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
      connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
      continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
      default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
      default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
      default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
      fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
      font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
      font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
      image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
      loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
      mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
      reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
      segmented_engraving: 'https://support.flux3dp.com/hc/en-us/articles/12306366019215',
      simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
      vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
    },
    high: 'Υψηλή',
    image_downsampling: 'Ποιότητα προεπισκόπησης bitmap',
    ip: 'Διεύθυνση IP συσκευής',
    keep_preview_result: 'Διατήρηση Προεπισκόπησης Αποτελέσματος',
    language: 'Γλώσσα',
    loop_compensation: 'Αντιστάθμιση βρόγχου',
    low: 'Χαμηλό',
    low_laser_for_preview: 'Λέιζερ για Εκτέλεση πλαισίου',
    mask: 'Περικοπή Περιοχής Εργασίας',
    medium: 'Μέτριος',
    module_offset_2w_ir: '2W υπέρυθρης ακτίνας λέιζερ αντιστάθμιση',
    module_offset_10w: 'Αντιστάθμιση λέιζερ διόδου 10W',
    module_offset_20w: '20W λέιζερ διόδου αντιστάθμιση',
    module_offset_printer: 'Αντιστάθμιση εκτυπωτή',
    none: 'Κανένα',
    normal: 'Υψηλό',
    notification_off: 'Απενεργοποίηση',
    notification_on: 'Ενεργοποίηση',
    notifications: 'Ειδοποιήσεις επιφάνειας εργασίας',
    off: 'Απενεργοποιημένο',
    on: 'Ενεργοποιημένο',
    preview_movement_speed: 'Ταχύτητα προεπισκόπησης κίνησης',
    printer_advanced_mode: 'Προηγμένη λειτουργία εκτυπωτή',
    remove_default_machine_button: 'Αφαίρεση',
    reset: 'Επαναφορά Beam Studio',
    reset_now: 'Επαναφορά Beam Studio',
    segmented_engraving: 'Τμηματική χάραξη',
    share_with_flux: 'Κοινοποίηση αναλυτικών Beam Studio',
    simplify_clipper_path: 'Βελτιστοποίηση Υπολογισμένης Διαδρομής',
    single_object: 'Ένα Αντικείμενο',
    tabs: {
      device: 'Συσκευή',
      general: 'Γενικά',
    },
    text_path_calc_optimization: 'Βελτιστοποίηση Υπολογισμού Διαδρομής Κειμένου',
    top_down: 'Από πάνω προς τα κάτω',
    trace_output: 'Έξοδος ιχνηλάτησης εικόνας',
    update_beta: 'Δοκιμαστική έκδοση',
    update_latest: 'Τελευταία',
    vector_speed_constraint: 'Όριο ταχύτητας',
    wrong_ip_format: 'Λανθασμένη μορφή IP',
  },
  social_media: {
    facebook: 'Συζητήστε με FLUXers, κάντε ερωτήσεις και μάθετε συμβουλές!',
    instagram: 'Λάβετε τις τελευταίες εμπνεύσεις, προσφορές και δώρα!',
    youtube: 'Δείτε τα μαθήματα Beam Studio και ιδέες χειροτεχνίας.',
  },
  support: {
    no_vcredist: 'Παρακαλώ εγκαταστήστε το Visual C++ Redistributable 2015<br/>Μπορείτε να το κατεβάσετε από flux3dp.com',
    no_webgl: 'Το WebGL δεν υποστηρίζεται. Παρακαλώ χρησιμοποιήστε άλλη συσκευή.',
  },
  topbar: {
    alerts: {
      add_content_first: 'Παρακαλώ προσθέστε αντικείμενα πρώτα',
      door_opened: 'Παρακαλώ κλείστε το κάλυμμα της πόρτας για να ενεργοποιήσετε τη χαμηλής ισχύος λέιζερ λειτουργία.',
      fail_to_connect_with_camera: '#803 Αποτυχία σύνδεσης με την κάμερα. Κάντε επανεκκίνηση του μηχανήματος ή του Beam Studio. Αν το σφάλμα επιμένει, ακολουθήστε <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">αυτόν τον οδηγό</a>.',
      fail_to_get_door_status: 'Παρακαλώ βεβαιωθείτε ότι το κάλυμμα της πόρτας είναι κλειστό για να ενεργοποιήσετε τη χαμηλής ισχύος λέιζερ λειτουργία.',
      fail_to_start_preview: '#803 Αποτυχία εκκίνησης προεπισκόπησης. Κάντε επανεκκίνηση του μηχανήματος ή του Beam Studio. Αν το σφάλμα επιμένει, ακολουθήστε <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">αυτόν τον οδηγό</a>.',
      headtype_mismatch: 'Εντοπίστηκε λανθασμένος τύπος κεφαλής. ',
      headtype_none: 'Δεν εντοπίστηκε κεφαλή. ',
      headtype_unknown: 'Εντοπίστηκε άγνωστος τύπος κεφαλής. ',
      install_correct_headtype: 'Παρακαλώ εγκαταστήστε σωστά τις διοδικές λέιζερ κεφαλές 10W/20W για να ενεργοποιήσετε τη χαμηλής ισχύος λέιζερ λειτουργία.',
      job_origin_unavailable: 'Η ρύθμιση της προέλευσης της εργασίας απαιτεί έκδοση υλικολογισμικού 4.3.5 / 5.3.5 ή νεότερη. Θέλετε να ενημερώσετε το υλικολογισμικό τώρα;',
      job_origin_warning: 'Χρησιμοποιείτε την “τρέχουσα θέση” ως σημείο εκκίνησης. Βεβαιωθείτε ότι η κεφαλή λέιζερ έχει μετακινηθεί στη σωστή θέση για να αποφύγετε συγκρούσεις.',
      power_too_high: 'ΥΠΕΡΒΟΛΙΚΗ ΙΣΧΥΣ',
      power_too_high_confirm: 'ΣΗΜΕΙΩΘΗΚΕ',
      power_too_high_msg: 'Η χρήση χαμηλότερης ισχύος λέιζερ (κάτω από 70%) θα επεκτείνει τη διάρκεια ζωής του σωλήνα λέιζερ.\nΕισάγετε "ΣΗΜΕΙΩΘΗΚΕ" για να προχωρήσετε.',
      pwm_unavailable: 'Η Λειτουργία Βάθους απαιτεί έκδοση υλικολογισμικού 4.3.4 / 5.3.4 ή νεότερη. Θέλετε να ενημερώσετε το υλικολογισμικό τώρα;',
      QcleanScene: 'Θέλετε να διαγράψετε το σχέδιο;<br/>Αυτό θα διαγράψει επίσης το ιστορικό αναίρεσής σας!',
      start_preview_timeout: '#803 Χρονικό όριο κατά την εκκίνηση της προεπισκόπησης. Κάντε επανεκκίνηση του μηχανήματος ή του Beam Studio. Αν το σφάλμα επιμένει, ακολουθήστε <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">αυτόν τον οδηγό</a>.',
    },
    frame_task: 'Εκτέλεση πλαισίου',
    hint: {
      polygon: 'Πατήστε το πλήκτρο + / - για να αυξήσετε / μειώσετε τις πλευρές.',
    },
    menu: {
      about: 'Σχετικά με το Beam Studio',
      about_beam_studio: 'Σχετικά με το Beam Studio',
      account: 'Λογαριασμός',
      add_new_machine: 'Ρύθμιση μηχανής',
      align_center: 'Στοίχιση στο κέντρο',
      anti_aliasing: 'Αντι-aliasing',
      auto_align: 'Αυτόματη Στοίχιση',
      borderless_mode: 'Λειτουργία χωρίς πλαίσιο',
      bug_report: 'Αναφορά σφαλμάτων',
      calibrate_beambox_camera: 'Βαθμονόμηση κάμερας',
      calibrate_beambox_camera_borderless: 'Βαθμονόμηση κάμερας (Ανοιχτό κάτω μέρος)',
      calibrate_camera_advanced: 'Ρύθμιση κάμερας (Προχωρημένο)',
      calibrate_diode_module: 'Βαθμονόμηση μονάδας λέιζερ διόδου',
      calibrate_ir_module: 'Βαθμονόμηση υπέρυθρης μονάδας',
      calibrate_printer_module: 'Βαθμονόμηση εκτυπωτικής μονάδας',
      calibration: 'Βαθμονομήσεις',
      camera_calibration_data: 'Δεδομένα Βαθμονόμησης Κάμερας',
      change_logs: 'Καταγραφή αλλαγών',
      clear_scene: 'Νέα αρχεία',
      close: 'Κλείσιμο παραθύρου',
      commands: 'Εντολές',
      contact: 'Επικοινωνήστε μαζί μας',
      copy: 'Αντιγραφή',
      cut: 'Αποκοπή',
      dashboard: 'Πίνακας ελέγχου',
      decompose_path: 'Αποσυνθέστε',
      delete: 'Διαγραφή',
      design_market: 'Αγορά σχεδίων',
      dev_tool: 'Εργαλείο Εντοπισμού Σφαλμάτων',
      disassemble_use: 'Αποσυναρμολόγηση',
      document_setting: 'Ρυθμίσεις εγγράφου',
      document_setting_short: 'Έγγραφο',
      download_data: 'Κατέβασμα Δεδομένων',
      download_log: 'Κατεβάστε αρχεία καταγραφής',
      download_log_canceled: 'Η λήψη αρχείου καταγραφής ακυρώθηκε',
      download_log_error: 'Παρουσιάστηκε άγνωστο σφάλμα, δοκιμάστε ξανά αργότερα',
      duplicate: 'Αντιγραφή',
      edit: 'Επεξεργασία',
      example_files: 'Αρχεία Παραδείγματος',
      export_BVG: 'BVG',
      export_flux_task: 'Εξαγωγή εργασίας FLUX',
      export_JPG: 'JPG',
      export_PNG: 'PNG',
      export_SVG: 'SVG',
      export_to: 'Εξαγωγή σε...',
      file: 'Αρχείο',
      fit_to_window: 'Προσαρμογή στο μέγεθος παραθύρου',
      follow_us: 'Ακολουθήστε μας',
      forum: 'Κοινότητα φόρουμ',
      group: 'Ομαδοποίηση',
      help: 'Βοήθεια',
      help_center: 'Κέντρο βοήθειας',
      hide: 'Απόκρυψη Beam Studio',
      hideothers: 'Απόκρυψη άλλων',
      image_crop: 'Περικοπή',
      image_curve: 'Καμπύλη',
      image_invert: 'Αντιστροφή',
      image_sharpen: 'Εστίαση',
      image_stamp: 'Υδατογραφήματα',
      image_vectorize: 'Ιχνηλάτηση',
      import_acrylic_focus_probe: 'Δοκιμή εστίασης ακρυλικού - 3mm',
      import_ador_laser_example: 'Παράδειγμα Laser Ador',
      import_ador_printing_example_full: 'Παράδειγμα εκτύπωσης Ador - Πλήρες χρώμα',
      import_ador_printing_example_single: 'Παράδειγμα εκτύπωσης Ador - Ένα χρώμα',
      import_beambox_2_example: 'Παράδειγμα του Beambox II',
      import_beambox_2_focus_probe: 'Beambox II Αισθητήρας Εστίασης - 3mm',
      import_hello_beambox: 'Παράδειγμα Beambox',
      import_hello_beamo: 'Παράδειγμα του beamo',
      import_hexa_example: 'Παράδειγμα HEXA',
      import_material_printing_test: 'Δοκιμή εκτύπωσης υλικού',
      import_material_testing_cut: 'Δοκιμή κοπής υλικού',
      import_material_testing_engrave: 'Δοκιμή χάραξης υλικού',
      import_material_testing_line: 'Δοκιμή γραμμής υλικού',
      import_material_testing_old: 'Δοκιμή χάραξης υλικού - Κλασική',
      import_material_testing_simple_cut: 'Δοκιμή κοπής υλικού - Απλή',
      import_promark_example: 'Παράδειγμα Promark',
      import_promark_mopa_20w_color: 'MOPA 20W Δοκιμή Χρώματος',
      import_promark_mopa_60w_color: 'MOPA 60W Δοκιμή Χρώματος',
      import_promark_mopa_100w_color: 'MOPA 100W Δοκιμή Χρώματος',
      keyboard_shortcuts: 'Συντομεύσεις πληκτρολογίου',
      layer_color_config: 'Ρυθμίσεις χρωμάτων',
      layer_setting: 'Στρώμα',
      link: {
        beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
        contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
        design_market: 'https://dmkt.io',
        downloads: 'https://flux3dp.com/downloads/',
        forum: 'https://www.facebook.com/groups/flux.laser/',
        help_center: 'https://helpcenter.flux3dp.com/',
        shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
      },
      log: {
        camera: 'Κάμερα',
        cloud: 'Νέφος',
        discover: 'Ανακάλυψη',
        hardware: 'Υλικό',
        network: 'Δίκτυο',
        player: 'Πρόγραμμα αναπαραγωγής',
        robot: 'Ρομπότ',
        usb: 'USB',
        usblist: 'Λίστα USB',
      },
      machine_info: 'Πληροφορίες μηχανήματος',
      machines: 'Μηχανές',
      manage_account: 'Διαχείριση λογαριασμού',
      material_test: 'Δοκιμή Υλικού',
      minimize: 'Ελαχιστοποίηση',
      my_account: 'Ο λογαριασμός μου',
      network_testing: 'Δοκιμή ρυθμίσεων δικτύου',
      object: 'Αντικείμενο',
      offset: 'Μετατόπιση',
      open: 'Άνοιγμα',
      paste: 'Επικόλληση',
      paste_in_place: 'Επικόλληση στη θέση',
      path: 'Διαδρομή',
      photo_edit: 'Επεξεργασία εικόνας',
      preferences: 'Προτιμήσεις',
      promark_color_test: 'Δοκιμή Χρώματος Promark',
      questionnaire: 'Ερωτηματολόγιο ανατροφοδότησης',
      quit: 'Έξοδος',
      recent: 'Άνοιγμα Πρόσφατων',
      redo: 'Επανάληψη',
      reload_app: 'Επαναφόρτωση Εφαρμογής',
      reset: 'Επαναφορά',
      rotary_setup: 'Ρυθμίσεις Περιστροφής',
      rotate: 'Περιστροφή',
      samples: 'Παραδείγματα',
      save_as: 'Αποθήκευση ως...',
      save_scene: 'Αποθήκευση',
      save_to_cloud: 'Αποθήκευση στο Cloud',
      scale: 'Κλίμακα',
      service: 'Υπηρεσίες',
      set_as_default: 'Ορισμός ως προεπιλογή',
      show_gesture_tutorial: 'Εισαγωγή χειρονομιών',
      show_grids: 'Εμφάνιση πλεγμάτων',
      show_layer_color: 'Χρήση χρώματος στρώματος',
      show_rulers: 'Εμφάνιση οδηγών',
      show_start_tutorial: 'Εμφάνιση εισαγωγικού μαθήματος',
      show_ui_intro: 'Εμφάνιση εισαγωγής διεπαφής',
      sign_in: 'Σύνδεση',
      sign_out: 'Αποσύνδεση',
      software_update: 'Ενημέρωση λογισμικού',
      svg_edit: 'SVG',
      switch_to_beta: 'Μετάβαση σε Beta Έκδοση',
      switch_to_latest: 'Μετάβαση σε Σταθερή Έκδοση',
      tools: {
        box_generator: 'Γεννήτρια Κουτιού',
        code_generator: 'Γεννήτρια κώδικα',
        material_test_generator: 'Γεννήτρια Δοκιμής Υλικού',
        title: 'Εργαλεία',
      },
      tutorial: 'Ξεκινήστε το εκπαιδευτικό πρόγραμμα εκτύπωσης Delta',
      undo: 'Αναίρεση',
      ungroup: 'Κατάργηση ομαδοποίησης',
      update: 'Έλεγχος Ενημέρωσης',
      update_firmware: 'Ενημέρωση υλικολογισμικού',
      upload_data: 'Ανέβασμα Δεδομένων',
      using_beam_studio_api: 'Χρήση Beam Studio API',
      view: 'Προβολή',
      window: 'Παράθυρο',
      zoom_in: 'Μεγέθυνση',
      zoom_out: 'Σμίκρυνση',
      zoom_with_window: 'Αυτόματη προσαρμογή στο μέγεθος παραθύρου',
    },
    preview: 'ΠΡΟΕΠΙΣΚΟΠΗΣΗ',
    preview_press_esc_to_stop: 'Πατήστε ESC για να σταματήσετε την προεπισκόπηση της κάμερας.',
    rename_tab: 'Μετονομασία καρτέλας',
    select_machine: 'Επιλέξτε μηχανή',
    tag_names: {
      dxf: 'DXF Αντικείμενο',
      ellipse: 'Ελλειψοειδές',
      g: 'Ομάδα',
      image: 'Εικόνα',
      line: 'Γραμμή',
      multi_select: 'Πολλαπλά Αντικείμενα',
      no_selection: 'Καμία επιλογή',
      pass_through_object: 'Διέλευση Αντικειμένου',
      path: 'Διαδρομή',
      polygon: 'Πολύγωνο',
      rect: 'Ορθογώνιο',
      svg: 'SVG Αντικείμενο',
      text: 'Κείμενο',
      text_path: 'Κείμενο σε Διαδρομή',
      use: 'Εισαγόμενο Αντικείμενο',
    },
    task_preview: 'Προεπισκόπηση διαδρομής',
    titles: {
      settings: 'Προτιμήσεις',
    },
    untitled: 'Χωρίς τίτλο',
  },
  topmenu: {
    credit: 'Το Beam Studio καθίσταται δυνατό από το ανοιχτού κώδικα έργο <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> και άλλο <a target="_blank" href="https://flux3dp.com/credits/">λογισμικό ανοιχτού κώδικα</a>.',
    device: {
      download_log_canceled: 'Η λήψη αρχείου καταγραφής ακυρώθηκε',
      download_log_error: 'Παρουσιάστηκε άγνωστο σφάλμα, δοκιμάστε ξανά αργότερα',
      log: {
        usblist: 'Λίστα USB',
      },
      network_test: 'Δοκιμή δικτύου',
    },
    file: {
      all_files: 'Όλα τα αρχεία',
      clear_recent: 'Εκκαθάριση πρόσφατων αρχείων',
      converting: 'Μετατροπή σε εικόνα...',
      fcode_files: 'Κώδικας FLUX',
      import: 'Εισαγωγή',
      jpg_files: 'JPG',
      label: 'Αρχείο',
      path_not_exit: 'Αυτή η διαδρομή δεν υπάρχει πλέον στον δίσκο.',
      png_files: 'PNG',
      save_fcode: 'Εξαγωγή Εργασίας FLUX',
      save_jpg: 'Εξαγωγή JPG',
      save_png: 'Εξαγωγή PNG',
      save_scene: 'Αποθήκευση Σκηνής',
      save_svg: 'Εξαγωγή SVG',
      scene_files: 'Σκηνές Beam Studio',
      svg_files: 'SVG',
    },
    ok: 'ΕΝΤΑΞΕΙ',
    version: 'Έκδοση',
  },
  tutorial: {
    ask_retry_calibration: 'Θα θέλατε να επαναλάβετε τη βαθμονόμηση της κάμερας;',
    camera_calibration_failed: 'Αποτυχία βαθμονόμησης κάμερας',
    gesture: {
      click: 'Πατήστε για επιλογή αντικειμένου',
      drag: 'Σύρετε για πολλαπλή επιλογή',
      hold: 'Πατήστε παρατεταμένα για αναδυόμενο μενού',
      pan: 'Κάντε κύλιση στον καμβά με δύο δάχτυλα.',
      zoom: 'Αγγίξτε με δύο δάχτυλα για μεγέθυνση / σμίκρυνση',
    },
    links: {
      adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
    },
    look_for_machine: 'Αναζήτηση μηχανής για το εκπαιδευτικό πρόγραμμα...',
    needNewInterfaceTutorial: 'Θα θέλατε να ξεκινήσετε ένα εκπαιδευτικό πρόγραμμα για το νέο διεπαφής του Beam Studio;<br/>(Μπορείτε να το παραλείψετε τώρα και να ξεκινήσετε αργότερα κάνοντας κλικ στο "Βοήθεια" > "Εμφάνιση Εισαγωγής Διεπαφής".)',
    needNewUserTutorial: 'Θέλετε να ξεκινήσετε ένα εκπαιδευτικό πρόγραμμα;<br/>(Μπορείτε να το παραλείψετε τώρα και να ξεκινήσετε αργότερα κάνοντας κλικ στο "Βοήθεια" > "Εμφάνιση Έναρξης Οδηγού".)',
    newInterface: {
      add_new_layer: 'Προσθήκη Νέου Επιπέδου',
      align_controls: 'Έλεγχοι ευθυγράμμισης',
      basic_shapes: 'Βασικά Σχήματα',
      camera_preview: 'Προεπισκόπηση Κάμερας',
      drag_to_sort: 'Σύρετε για ταξινόμηση',
      end_alert: 'Είστε σίγουροι ότι θέλετε να τερματίσετε την εισαγωγή στο νέο περιβάλλον χρήστη;',
      flip: 'Αναστροφή',
      group_controls: 'Έλεγχοι ομαδοποίησης',
      layer_controls: 'Δεξί κλικ για επιλογή ελέγχων στρώματος: Αντιγραφή / Συγχώνευση / Κλείδωμα / Διαγραφή στρωμάτων',
      object_actions: 'Ενέργειες αντικειμένου',
      pen_tool: 'Εργαλείο Πένας',
      rename_by_double_click: 'Μετονομασία με διπλό κλικ',
      select_image_text: 'Επιλογή / Εικόνα / Κείμενο',
      select_machine: 'Επιλέξτε μια μηχανή',
      shape_operation: 'Λειτουργία σχήματος',
      start_work: 'Ξεκινήστε την εργασία',
      switch_between_layer_panel_and_object_panel: 'Εναλλαγή μεταξύ του πίνακα στρωμάτων και του πίνακα αντικειμένων',
    },
    newUser: {
      add_new_layer: 'Προσθήκη νέου στρώματος',
      adjust_focus: '2. Ρυθμίστε την εστίαση',
      close_cover: '3. Κλείστε το κάλυμμα',
      drag_to_draw: 'Σύρετε για σχεδίαση',
      draw_a_circle: 'Σχεδιάστε έναν κύκλο',
      draw_a_rect: 'Σχεδιάστε ένα ορθογώνιο',
      end_alert: 'Είστε σίγουροι ότι θέλετε να τερματίσετε την εκμάθηση;',
      end_preview_mode: 'Τερματισμός Λειτουργίας Προεπισκόπησης',
      infill: 'Ενεργοποίηση πλήρωσης',
      please_select_wood_cutting: 'Παρακαλώ επιλέξτε την προεπιλογή "Ξύλο - Κοπή"',
      please_select_wood_engraving: 'Παρακαλώ επιλέξτε την προεπιλογή "Ξύλο - Χάραξη"',
      preview_the_platform: 'Προεπισκόπηση της Πλατφόρμας',
      put_wood: '1. Τοποθετήστε το δείγμα ξύλου',
      send_the_file: 'Αποστολή Αρχείου',
      set_preset_wood_cut: 'Ορισμός προεπιλογής: Ξύλο - Κοπή',
      set_preset_wood_engraving: 'Ορισμός προεπιλογής: Ξύλο - Χάραξη',
      switch_to_layer_panel: 'Μετάβαση στο πάνελ στρωμάτων',
      switch_to_object_panel: 'Μετάβαση στο πίνακα αντικειμένων',
      switch_to_preview_mode: 'Μετάβαση σε λειτουργία προεπισκόπησης',
    },
    next: 'ΕΠΟΜΕΝΟ',
    retry: 'Επανάληψη',
    set_connection: 'Ορισμός σύνδεσης',
    skip: 'Παράλειψη',
    skip_tutorial: 'Παρακάμψατε το εκπαιδευτικό. Μπορείτε να το ξεκινήσετε από το μενού Βοήθεια.',
    suggest_calibrate_camera_first: 'Συνιστούμε στους χρήστες να βαθμονομήσουν αρχικά την κάμερα και να επαναφέρουν την εστίαση πριν από κάθε προεπισκόπηση για βέλτιστα αποτελέσματα.<br/>Θα θέλατε να επιβεβαιώσετε τη διενέργεια της βαθμονόμησης τώρα;<br/>(Μπορείτε να το παραλείψετε τώρα και να το κάνετε αργότερα κάνοντας κλικ στο "Μενού" > "Μηχάνημα" > [Η Συσκευή Σας] > "Βαθμονόμηση Κάμερας".)',
    tutorial_complete: 'Αυτό ήταν όλο για το εκπαιδευτικό. Ώρα για δημιουργία!',
    unable_to_find_machine: 'Δεν βρέθηκε μηχανή για το εκπαιδευτικό. Θέλετε να μεταβείτε στις ρυθμίσεις, να επαναλάβετε ή να το παραλείψετε;',
    welcome: 'ΚΑΛΩΣ ΟΡΙΣΑΤΕ',
  },
  update: {
    cannot_reach_internet: '#823 Ο διακομιστής δεν είναι προσβάσιμος<br/>Ελέγξτε τη σύνδεση στο διαδίκτυο',
    download: 'ΔΙΑΔΙΚΤΥΑΚΗ ΕΝΗΜΕΡΩΣΗ',
    firmware: {
      caption: 'Μια ενημέρωση υλικολογισμικού για το μηχάνημα είναι διαθέσιμη',
      confirm: 'ΜΕΤΑΦΟΡΤΩΣΗ',
      firmware_too_old_update_by_sdcard: 'Η έκδοση υλικολογισμικού είναι πολύ παλιά. Παρακαλώ αναβαθμίστε το υλικολογισμικό χρησιμοποιώντας μια κάρτα SD.',
      force_update_message: '#814 Παρακαλώ αναβαθμίστε τη μηχανή σας στην τελευταία έκδοση υλικολογισμικού.',
      latest_firmware: {
        cant_get_latest: 'Αδυναμία λήψης πληροφοριών για την τελευταία έκδοση υλικολογισμικού.',
        caption: 'Ενημέρωση υλικολογισμικού μηχανήματος',
        message: 'Έχετε την τελευταία έκδοση υλικολογισμικού μηχανήματος',
        still_update: 'ΕΝΗΜΕΡΩΣΗ',
      },
      message_pattern_1: 'Το "%s" είναι τώρα έτοιμο για ενημέρωση υλικολογισμικού.',
      message_pattern_2: '%s Υλικολογισμικό v%s είναι τώρα διαθέσιμο - Έχετε το v%s.',
      too_old_for_web: 'Η τρέχουσα έκδοση υλικολογισμικού της μηχανής σας είναι η v%s.\nΑν θέλετε να χρησιμοποιήσετε την online έκδοση του Beam Studio, αναβαθμίστε το υλικολογισμικό της μηχανής στην τελευταία έκδοση.',
      update_fail: '#822 Αποτυχία αναβάθμισης',
      update_success: 'Επιτυχής αναβάθμιση υλικολογισμικού',
      upload_file: 'Φόρτωση υλικολογισμικού (*.bin / *.fxfw)',
    },
    install: 'ΕΓΚΑΤΑΣΤΑΣΗ',
    later: 'ΑΡΓΟΤΕΡΑ',
    preparing: 'Προετοιμασία...',
    release_note: 'Σημείωση έκδοσης:',
    skip: 'Παράλειψη Αυτής Της Έκδοσης',
    software: {
      available_switch: 'Το Beam Studio v%s είναι διαθέσιμο. Έχετε την έκδοση v%s. Θέλετε να αναβαθμίσετε;',
      available_update: 'Το Beam Studio v%s είναι διαθέσιμο. Έχετε την έκδοση v%s. Θέλετε να κατεβάσετε την ενημέρωση;',
      caption: 'Διαθέσιμη ενημέρωση λογισμικού για το Beam Studio',
      check_update: 'Έλεγχος για ενημέρωση',
      checking: 'Έλεγχος για ενημέρωση',
      downloading: 'Γίνεται λήψη ενημερώσεων στο παρασκήνιο, μπορείτε να κάνετε κλικ στο "ΟΚ" για να συνεχίσετε την εργασία σας.',
      install_or_not: 'είναι έτοιμο για ενημέρωση. Θα θέλατε να κάνετε επανεκκίνηση και να αναβαθμίσετε τώρα;',
      no: 'Όχι',
      no_response: 'Αποτυχία σύνδεσης με τον διακομιστή, ελέγξτε τις ρυθμίσεις δικτύου σας.',
      not_found: 'Χρησιμοποιείτε την τελευταία έκδοση του Beam Studio.',
      switch_or_not: 'Το λογισμικό είναι έτοιμο για αναβάθμιση. Θέλετε να κάνετε επανεκκίνηση τώρα;',
      switch_version: 'Αλλαγή έκδοσης',
      switch_version_not_found: 'Δεν βρέθηκε διαθέσιμη έκδοση για αναβάθμιση.',
      update_for_ador: 'Η τρέχουσα έκδοση λογισμικού %s δεν είναι συμβατή, παρακαλώ κατεβάστε την τελευταία έκδοση του Beam Studio για τον Ador.',
      yes: 'Ναι',
    },
    update: 'Ενημέρωση',
    updating: 'Αναβάθμιση...',
    upload: 'ΜΕΤΑΦΟΡΤΩΣΗ',
  },
  web_cam: {
    no_device: 'Δεν είναι δυνατή η ανίχνευση της κάμερας. Παρακαλώ συνδέστε ξανά την κάμερα και δοκιμάστε ξανά.',
    no_permission: 'Το Beam Studio δεν έχει άδεια πρόσβασης στην κάμερα. Βεβαιωθείτε ότι έχει δοθεί άδεια στο Beam Studio από τις ρυθμίσεις του προγράμματος περιήγησης ή του συστήματος.',
  },
  z_speed_limit_test: {
    alert_before: 'Πριν ξεκινήσει η χάραξη καμπύλης, το σύστημα θα πραγματοποιήσει δοκιμή φορτίου για να αποτρέψει την απώλεια βημάτων λόγω υπερβολικού βάρους.',
    alert_failed: 'Το βάρος του τρέχοντος αντικειμένου υπερβαίνει το όριο φορτίου για την τρέχουσα ταχύτητα του άξονα Ζ. Μειώστε την ταχύτητα του άξονα Ζ στον πίνακα ελέγχου του μηχανήματος ή αντικαταστήστε το αντικείμενο χάραξης πριν επαναλάβετε τη δοκιμή.',
    ignore: 'Αγνόηση',
    retest: 'Επανάληψη δοκιμής',
    testing: 'Εκτελείται δοκιμή φόρτωσης...',
  },
};

export default lang;
