// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-views-dialogs-AlertAndProgress-module__nonstop--FzpGC [class~=ant-modal-title]{text-align:center}._-_-packages-core-src-web-app-views-dialogs-AlertAndProgress-module__nonstop--FzpGC [class~=ant-modal-footer]{text-align:center}._-_-packages-core-src-web-app-views-dialogs-AlertAndProgress-module__nonstop--FzpGC ._-_-packages-core-src-web-app-views-dialogs-AlertAndProgress-module__spinner-container--g7S9k{margin:16px 0;display:flex;justify-content:center}._-_-packages-core-src-web-app-views-dialogs-AlertAndProgress-module__nonstop--FzpGC ._-_-packages-core-src-web-app-views-dialogs-AlertAndProgress-module__caption--c72nN{text-align:center}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/views/dialogs/AlertAndProgress.module.scss"],"names":[],"mappings":"AACE,8GACE,iBAAA,CAEF,+GACE,iBAAA,CAGF,oLACE,aAAA,CACA,YAAA,CACA,sBAAA,CAGF,0KACE,iBAAA","sourcesContent":[".nonstop {\n  [class~='ant-modal-title'] {\n    text-align: center;\n  }\n  [class~='ant-modal-footer'] {\n    text-align: center;\n  }\n\n  .spinner-container {\n    margin: 16px 0;\n    display: flex;\n    justify-content: center;\n  }\n\n  .caption {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nonstop": "_-_-packages-core-src-web-app-views-dialogs-AlertAndProgress-module__nonstop--FzpGC",
	"spinner-container": "_-_-packages-core-src-web-app-views-dialogs-AlertAndProgress-module__spinner-container--g7S9k",
	"caption": "_-_-packages-core-src-web-app-views-dialogs-AlertAndProgress-module__caption--c72nN"
};
export default ___CSS_LOADER_EXPORT___;
