import Ador from './ador.svg';
import Beambox from './beambox.svg';
import Beamo from './beamo.svg';
import Hexa from './hexa.svg';
import Promark from './promark.svg';

export default {
  Ador,
  Beambox,
  Beamo,
  Hexa,
  Promark,
};
