// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-widgets-FullWindowPanel-BackButton-module__enhancer--PXqZV._-_-packages-core-src-web-app-widgets-FullWindowPanel-BackButton-module__button--YkBbo{display:flex;align-items:center;width:fit-content;gap:4px;margin:12px;color:#7c7c7c;font-size:14px}._-_-packages-core-src-web-app-widgets-FullWindowPanel-BackButton-module__enhancer--PXqZV._-_-packages-core-src-web-app-widgets-FullWindowPanel-BackButton-module__button--YkBbo svg{font-size:30px}._-_-packages-core-src-web-app-widgets-FullWindowPanel-BackButton-module__enhancer--PXqZV._-_-packages-core-src-web-app-widgets-FullWindowPanel-BackButton-module__button--YkBbo._-_-packages-core-src-web-app-widgets-FullWindowPanel-BackButton-module__desktop--ID3tI:not(._-_-packages-core-src-web-app-widgets-FullWindowPanel-BackButton-module__windows--fpkcO){margin-bottom:0}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/widgets/FullWindowPanel/BackButton.module.scss"],"names":[],"mappings":"AACE,iLACE,YAAA,CACA,kBAAA,CACA,iBAAA,CACA,OAAA,CACA,WAAA,CACA,aAAA,CACA,cAAA,CAEA,qLACE,cAAA,CAIA,uWACE,eAAA","sourcesContent":[".enhancer {\n  &.button {\n    display: flex;\n    align-items: center;\n    width: fit-content;\n    gap: 4px;\n    margin: 12px;\n    color: #7c7c7c;\n    font-size: 14px;\n\n    svg {\n      font-size: 30px;\n    }\n\n    &.desktop {\n      &:not(.windows) {\n        margin-bottom: 0;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"enhancer": "_-_-packages-core-src-web-app-widgets-FullWindowPanel-BackButton-module__enhancer--PXqZV",
	"button": "_-_-packages-core-src-web-app-widgets-FullWindowPanel-BackButton-module__button--YkBbo",
	"desktop": "_-_-packages-core-src-web-app-widgets-FullWindowPanel-BackButton-module__desktop--ID3tI",
	"windows": "_-_-packages-core-src-web-app-widgets-FullWindowPanel-BackButton-module__windows--fpkcO"
};
export default ___CSS_LOADER_EXPORT___;
