// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-beambox-svg-editor-ElementTitle-module__element-title--KP40L{pointer-events:none;position:absolute;z-index:1;top:5px;margin-left:20px;height:40px;line-height:40px;color:#333;font-weight:bold;font-size:13px;letter-spacing:.95px;text-shadow:-1px 0 #fff,0 1px #fff,1px 0 #fff,0 -1px #fff}@media screen and (max-width: 600px){._-_-packages-core-src-web-app-components-beambox-svg-editor-ElementTitle-module__element-title--KP40L{margin-top:20px;margin-left:15px}}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/beambox/svg-editor/ElementTitle.module.scss"],"names":[],"mappings":"AAAA,uGACE,mBAAA,CACA,iBAAA,CACA,SAAA,CACA,OAAA,CACA,gBAAA,CACA,WAAA,CACA,gBAAA,CACA,UAAA,CACA,gBAAA,CACA,cAAA,CACA,oBAAA,CACA,yDACE,CAKF,qCAlBF,uGAmBI,eAAA,CACA,gBAAA,CAAA","sourcesContent":[".element-title {\n  pointer-events: none;\n  position: absolute;\n  z-index: 1;\n  top: 5px;\n  margin-left: 20px;\n  height: 40px;\n  line-height: 40px;\n  color: #333333;\n  font-weight: bold;\n  font-size: 13px;\n  letter-spacing: 0.95px;\n  text-shadow:\n    -1px 0 white,\n    0 1px white,\n    1px 0 white,\n    0 -1px white;\n\n  @media screen and (max-width: 600px) {\n    margin-top: 20px;\n    margin-left: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"element-title": "_-_-packages-core-src-web-app-components-beambox-svg-editor-ElementTitle-module__element-title--KP40L"
};
export default ___CSS_LOADER_EXPORT___;
