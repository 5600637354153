import type { ILang } from '@core/interfaces/ILang';

const lang: ILang = {
  alert: {
    abort: 'Abortar',
    cancel: 'Cancelar',
    caption: 'Erro',
    close: 'Fechar',
    confirm: 'Confirmar',
    dont_save: 'Não salvar',
    dont_show_again: 'Não exibir novamente',
    duplicated_preset_name: 'Nome de predefinição duplicado',
    error: 'Erro',
    info: 'INFORMAÇÃO',
    instruction: 'Instrução',
    learn_more: 'Saiba mais',
    no: 'Não',
    ok: 'OK',
    ok2: 'OK',
    oops: 'Oops...',
    retry: 'Tentar novamente',
    save: 'Salvar',
    stop: 'Parar',
    warning: 'AVISO',
    yes: 'Sim',
  },
  auto_fit: {
    artwork_size: 'Tamanho da obra',
    error_tip1: '1. A obra de arte está colocada corretamente no material?',
    error_tip2: '2. Os contornos do material são claros o suficiente para reconhecimento?',
    failed_to_find_contour: 'Falha ao encontrar contornos para ajuste automático',
    learn_more: 'Ver tutorial.',
    learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    offset_x: 'Deslocamento X',
    offset_y: 'Deslocamento Y',
    pattern_size: 'Tamanho do padrão',
    position_artwork: 'Posicionar obra de arte',
    position_step1: 'Ajuste o tamanho e a posição da sua obra de arte no padrão.',
    position_step2: 'Clique em "Aplicar" para aplicar o Auto Fit a este padrão.',
    preview_first: 'Por favor, realize a pré-visualização da câmera primeiro.',
    reset_position: 'Redefinir posição',
    rotation: 'Girar',
    select_a_pattern: 'Selecionar um padrão',
    selected_artwork: 'Obra de arte selecionada',
    step1: 'Escolha um padrão onde deseja colocar a obra de arte.',
    step2: 'Se nenhum padrão for encontrado, visualize novamente a câmera e aplique Ajuste Automático.',
    step3: 'Se ainda assim não encontrar o padrão correto, certifique-se de que sua peça de trabalho é claramente reconhecível e que a área de trabalho está livre de detritos.',
    title: 'Ajuste Automático',
  },
  barcode_generator: {
    bar_height: 'Altura da barra',
    bar_width: 'Largura da barra',
    barcode: {
      invalid_value: 'O valor é inválido para o formato selecionado.',
    },
    font: 'Fonte',
    font_size: 'Tamanho da fonte',
    hide_text: 'Ocultar texto',
    invert_color: 'Inverter cor',
    text_margin: 'Margem do texto',
  },
  beambox: {
    announcement_panel: {
      title: 'Anúncio',
    },
    banner: {
      auto_feeder: 'Modo Alimentador Automático',
      camera_preview: 'Pré-visualização',
      camera_preview_borderless_mode: '(Fundo Aberto)',
      curve_engraving: 'Modo de Gravação Curva',
      pass_through: 'Modo Passagem Direta',
      rotary: 'Modo Rotativo',
    },
    bottom_right_panel: {
      convert_text_to_path_before_export: 'Converter Texto em Caminho...',
      export_file_error_ask_for_upload: 'Falha ao exportar tarefa. Deseja fornecer a cena de trabalho para a equipe de desenvolvimento relatar bugs?',
      retreive_image_data: 'Recuperar Dados de Imagem...',
    },
    context_menu: {
      copy: 'Copiar',
      cut: 'Recortar',
      delete: 'Excluir',
      duplicate: 'Duplicar',
      group: 'Agrupar',
      move_back: 'Enviar para trás',
      move_down: 'Mover para trás',
      move_front: 'Trazer para frente',
      move_up: 'Mover para frente',
      paste: 'Colar',
      paste_in_place: 'Colar no lugar',
      ungroup: 'Desagrupar',
    },
    document_panel: {
      add_on: 'Complementos',
      auto_feeder: 'Alimentador automático',
      auto_feeder_scale: 'Escala do alimentador automático',
      auto_feeder_url: 'https://support.flux3dp.com/hc/en-us/articles/11688230498575',
      borderless_mode: 'Abrir parte inferior',
      current_position: 'Posição Atual',
      disable: 'Desabilitar',
      document_settings: 'Configurações do documento',
      enable: 'Habilitar',
      enable_autofocus: 'Foco automático',
      enable_diode: 'Laser de diodo',
      engrave_dpi: 'Resolução',
      frame_before_start: 'Quadro antes de executar',
      frame_before_start_url: 'https://support.flux3dp.com/hc/en-us/articles/11494925637135',
      high: 'Alto',
      job_origin: 'Origem do Trabalho',
      laser_source: 'Fonte de laser',
      low: 'Baixo',
      machine: 'Máquina',
      medium: 'Médio',
      notification: {
        changeFromPrintingWorkareaTitle: 'Deseja converter as Camadas de Impressão em Camadas de Laser?',
      },
      origin: 'Origem',
      pass_through: 'Passagem direta',
      pass_through_height_desc: 'Insira o comprimento do objeto para estender a área de trabalho.',
      rotary_mode: 'Modo rotativo',
      scale: 'Escala',
      start_from: 'Começar de',
      start_position: 'Posição de Início',
      start_work_button: 'Botão Executar',
      ultra: 'Ultra Alto',
      workarea: 'Área de trabalho',
    },
    image_trace_panel: {
      back: 'Voltar',
      brightness: 'Brilho',
      cancel: 'Cancelar',
      contrast: 'Contraste',
      next: 'Próximo',
      okay: 'OK',
      threshold: 'Limite',
      tuning: 'Configurações',
    },
    layer_color_config_panel: {
      add: 'Adicionar',
      add_config: 'Adicionar cor',
      color: 'Cor',
      default: 'Redefinir padrão',
      in_use: 'Esta cor está em uso.',
      layer_color_config: 'Configurações de Cor de Camada',
      no_input: 'Por favor, insira um código de cor hexadecimal válido.',
      power: 'Potência',
      repeat: 'Repetir',
      speed: 'Velocidade',
      sure_to_delete: 'Tem certeza que deseja excluir esta configuração de cor?',
      sure_to_reset: 'Você perderá todos os parâmetros personalizados, tem certeza que deseja redefinir para o padrão?',
    },
    left_panel: {
      borderless_blind_area: 'Área não gravável',
      borderless_preview: 'Visualização da câmera no modo de fundo aberto',
      diode_blind_area: 'Área cega do complemento híbrido a laser',
      ellipse: 'Elipse',
      image: 'Imagem',
      label: {
        adjust_height: 'Ajustar Altura',
        array: 'Matriz',
        boxgen: 'Boxgen',
        choose_camera: 'Câmera',
        clear_preview: 'Limpar Pré-visualização',
        cursor: 'Selecionar',
        curve_engraving: {
          clear_area: 'Limpar área selecionada',
          exit: 'Sair',
          preview_3d_curve: 'Pré-visualização da gravação de curva',
          select_area: 'Selecionar área',
          title: 'Gravação de curva',
        },
        end_preview: 'Terminar Pré-visualização',
        line: 'Linha',
        live_feed: 'Transmissão ao vivo',
        my_cloud: 'Minha nuvem',
        oval: 'Elipse',
        pass_through: 'Passagem direta',
        pen: 'Caneta',
        photo: 'Imagem',
        polygon: 'Polígono',
        preview: 'Pré-visualização da Câmera',
        qr_code: 'Código QR',
        rect: 'Retângulo',
        shapes: 'Formas',
        text: 'Texto',
        trace: 'Traçar Imagem',
      },
      line: 'Linha',
      rectangle: 'Retângulo',
      text: 'Texto',
      unpreviewable_area: 'Área cega',
    },
    network_testing_panel: {
      average_response: 'Tempo médio de resposta',
      cannot_connect_1: '#840 Falha ao conectar ao IP de destino.',
      cannot_connect_2: '#840 Falha ao conectar ao IP de destino. Certifique-se de que o destino está na mesma rede.',
      cannot_get_local: 'Falha ao obter endereço IP local.',
      connection_quality: 'Qualidade da conexão',
      device_not_on_list: '#842 A máquina não está na lista, mas a qualidade da conexão é >70 e o tempo médio de resposta é <100ms',
      empty_ip: '#818 Por favor, insira primeiro o endereço IP do dispositivo de destino.',
      end: 'Encerrar',
      fail_to_start_network_test: '#817 Falha ao iniciar teste de rede.',
      hint_connect_camera_timeout: 'Tempo limite ocorre ao iniciar a visualização da câmera?',
      hint_connect_failed_when_sending_job: 'Falha ao conectar ao enviar um trabalho?',
      hint_device_often_on_list: 'A máquina geralmente não é encontrada na lista?',
      insert_ip: 'Endereço IP do dispositivo de destino:',
      invalid_ip: '#818 Endereço IP inválido',
      ip_startswith_169: '#843 O endereço IP da máquina começa com 169.254',
      link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
      link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
      link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
      linux_permission_hint: 'Este erro geralmente ocorre devido a permissões insuficientes.\nExecute "sudo beam-studio --no-sandbox" no terminal para obter permissões e executar o teste de rede.',
      local_ip: 'Endereço IP local:',
      network_testing: 'Teste de Rede',
      network_unhealthy: '#841 Qualidade da conexão <70 ou tempo médio de resposta >100ms',
      start: 'Iniciar',
      test_completed: 'Teste concluído',
      test_fail: 'Falha no teste',
      testing: 'Testando Rede...',
    },
    object_panels: {
      lock_desc: 'Manter proporção de largura e altura (SHIFT)',
      text_to_path: {
        check_thumbnail_warning: 'Alguns textos foram alterados para outras fontes ao converter textos em caminhos e alguns caracteres podem não ter sido convertidos corretamente. \nVerifique a visualização novamente antes de enviar a tarefa.',
        error_when_parsing_text: 'Erro ao converter texto em caminho',
        font_substitute_pop: 'Seu texto contém caracteres que não são suportados pela fonte atual. <br/>Gostaria de usar <strong>%s</strong> como substituição?',
        retry: 'Por favor, tente novamente mais tarde ou escolha outra fonte',
        use_current_font: 'Usar Fonte Atual',
      },
      wait_for_parsing_font: 'Analisando fonte... Por favor, aguarde um momento',
    },
    path_preview: {
      current_position: 'Posição atual',
      cut_distance: 'Distância de corte',
      cut_time: 'Tempo de corte',
      end_preview: 'Finalizar pré-visualização',
      estimated_time: 'Tempo total estimado',
      invert: 'Inverter',
      pause: 'Pausar',
      play: 'Reproduzir',
      play_speed: 'Velocidade de reprodução',
      preview_info: 'Visualizar informações',
      rapid_distance: 'Distância de deslocamento rápido',
      rapid_time: 'Tempo de deslocamento',
      remark: '* Todas as informações são valores estimados apenas para referência.',
      size: 'Tamanho',
      start_here: 'Começar aqui',
      stop: 'Parar',
      travel_path: 'Caminho de deslocamento',
    },
    photo_edit_panel: {
      aspect_ratio: 'Proporção',
      brightness: 'Brilho',
      brightness_and_contrast: 'Brilho / Contraste',
      cancel: 'Cancelar',
      circumference: 'Circunferência',
      compare: 'Comparar',
      contrast: 'Contraste',
      crop: 'Recortar',
      curve: 'Curva',
      diameter: 'Diâmetro',
      free: 'Livre',
      invert: 'Inverter Cor',
      okay: 'OK',
      original: 'Original',
      phote_edit: 'Edição de Foto',
      processing: 'Processando',
      radius: 'Raio',
      rotary_warped: 'Distorcido Rotativo',
      rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
      sharpen: 'Nitidez',
      sharpness: 'Nitidez',
      start: 'Iniciar',
      warp: 'Distorção',
    },
    popup: {
      ai_credit: {
        buy_link: 'https://member.flux3dp.com/en-US/credit',
        go: 'Ir',
        insufficient_credit: 'Você está sem crédito',
        insufficient_credit_msg: 'Você não pode usar o %s. Vá para o centro de membros e compre Créditos de IA.',
        relogin_to_use: 'Por favor, faça login novamente para usar esta função.',
      },
      auto_feeder_origin: 'Use a visualização do enquadramento para verificar o trajeto e a área da cabeça do laser, depois ajuste o posicionamento do material ou a posição inicial da gravação com base nos resultados para evitar colisões durante o processo de gravação.',
      auto_switch_tab: {
        message: 'Uma nova opção para alternar automaticamente entre o painel de Camadas e o painel de Objetos foi adicionada às Preferências. Esta opção está desativada por padrão. Gostaria de ativar a troca automática agora? <br/>Você pode alterar esta configuração a qualquer momento nas Preferências.',
        title: 'Alternância Automática Entre o Painel de Camadas e Objetos',
      },
      backend_connect_failed_ask_to_upload: '#802 Erros continuam ocorrendo ao tentar conectar ao backend. Deseja enviar seu registro de relatório de erros?',
      backend_error_hint: 'Recursos podem não estar funcionando corretamente devido a um erro no backend.',
      both_power_and_speed_too_high: 'Usar menor potência do laser estenderá a vida útil do tubo de laser. Além disso, velocidade muito alta nesta resolução pode resultar em menor qualidade de gravação sombreada.',
      bug_report: 'Relatório de erros',
      change_workarea_before_preview: 'A área de trabalho de %s não corresponde à área de trabalho atualmente definida. Deseja alterar a área de trabalho atual?',
      convert_to_path_fail: 'Falha ao converter para caminho.',
      dxf_bounding_box_size_over: 'O tamanho do desenho está fora da área de trabalho. Por favor, mova seu desenho mais perto da origem no seu software CAD, ou certifique-se de que a unidade está configurada corretamente.',
      dxf_version_waring: 'A versão deste arquivo DXF não é 2013, pode haver problemas potenciais de incompatibilidade.',
      facebook_group_invitation: {
        already_joined: 'Já entrei',
        join_now: 'Conte comigo',
        later: 'Talvez mais tarde',
        message: 'Junte-se ao nosso grupo oficial no Facebook para conectar-se com outros usuários de laser FLUX, discutir lasers FLUX, compartilhar trabalhos a laser e ficar por dentro das últimas atualizações dos nossos produtos. Mal podemos esperar para vê-lo por lá!',
        title: 'Junte-se ao grupo oficial de usuários FLUX',
      },
      import_file_contain_invalid_path: '#808 O arquivo SVG importado contém caminho de imagem inválido. Certifique-se de que todas as imagens existam ou incorpore a imagem no arquivo',
      import_file_error_ask_for_upload: 'Falha ao importar arquivo SVG. Deseja enviar o arquivo à equipe de desenvolvimento para relatar erros?',
      layer_by_color: 'Cor',
      layer_by_layer: 'Camada',
      loading_image: 'Carregando imagem, aguarde...',
      more_than_two_object: 'Objetos demais. Suporte apenas para 2 objetos',
      mouse: 'Mouse',
      no_support_text: 'O Beam Studio não suporta a tag de texto atualmente. Por favor, converta o texto em caminho antes de importar.',
      nolayer: 'Camada única',
      not_support_object_type: 'Tipo de objeto não suportado',
      or_turn_off_borderless_mode: ' Ou desative o modo Open Bottom.',
      pdf2svg: {
        error_pdf2svg_not_found: '#825 Erro: Comando pdf2svg não encontrado. Por favor, instale pdf2svg com o seu gerenciador de pacotes (por exemplo, "yum install pdf2svg" ou "apt-get install pdf2svg").',
        error_when_converting_pdf: '#824 Erro ao converter PDF para SVG:',
      },
      progress: {
        calculating: 'Calculando',
        uploading: 'Enviando',
      },
      questionnaire: {
        caption: 'Ajude-nos a responder o questionário',
        message: 'Ajude-nos a responder o questionário para melhorar o Beam Studio.',
        no_questionnaire_available: 'Não há questionário disponível agora.',
        unable_to_get_url: 'Falha ao obter o link para o questionário pela internet. Confirme sua conexão com a internet.',
      },
      recommend_downgrade_software: 'Detectamos uma versão de firmware mais antiga. Estamos abordando ativamente problemas de compatibilidade, mas por enquanto recomendamos reverter para <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>.',
      recommend_upgrade_firmware: 'Detectamos uma versão de firmware mais antiga. Estamos abordando ativamente problemas de compatibilidade, mas por enquanto recomendamos atualizar para o firmware mais recente.',
      save_unsave_changed: 'Você quer salvar as alterações não salvas?',
      select_at_least_two: 'Selecione pelo menos dois objetos para continuar',
      select_first: 'Selecione um objeto primeiro.',
      select_import_method: 'Selecionar estilo de camadas:',
      select_import_module: 'Selecionar Módulo:',
      sentry: {
        message: 'Você concorda em enviar automaticamente informações relevantes para a equipe de desenvolvimento quando ocorrerem erros?',
        title: 'Vamos melhorar o Beam Studio juntos',
      },
      should_update_firmware_to_continue: '#814 Seu firmware não suporta esta versão do Beam Studio. Atualize o firmware para continuar. (Menu> Máquina> [Sua Máquina]> Atualizar Firmware)',
      speed_too_high_lower_the_quality: 'Usar velocidade muito alta nesta resolução pode resultar em menor qualidade de gravação sombreada.',
      still_continue: 'Continuar',
      successfully_uploaded: 'Upload de arquivo bem-sucedido.',
      svg_1_1_waring: 'A versão deste arquivo SVG é v 1.1, pode haver problemas potenciais de incompatibilidade.',
      svg_image_path_waring: 'Este arquivo SVG contém <image> carregando do caminho do arquivo. Isso pode causar falhas ao carregar. \nPara evitar esse risco, use a imagem incorporada ao exportar SVG.',
      text_to_path: {
        caption: 'Conversor de Texto para Trajetória 2.0',
        message: 'O Beam Studio agora apresenta um novo conversor de texto para trajetória (Conversor 2.0), que produz resultados mais confiáveis! Gostaria de mudar para ele agora? \nVocê também pode ajustar essa configuração posteriormente na seção "Conversor de Texto para Trajetória" nas preferências.',
      },
      too_fast_for_auto_feeder: 'Usar velocidades muito altas em camadas que contêm objetos de caminho pode reduzir a precisão de corte.\nNão recomendamos usar velocidades superiores a %(limit)s ao cortar com o Auto Feeder.',
      too_fast_for_auto_feeder_and_constrain: 'As seguintes camadas: %(layers)s \ncontêm objetos de caminho vetorial e têm uma velocidade superior a %(limit)s.\nA velocidade de corte dos objetos de caminho vetorial com o Auto Feeder será limitada a %(limit)s.\nVocê pode remover esse limite nas Configurações de Preferências.',
      too_fast_for_curve: 'O uso de velocidades muito altas para camadas que contêm objetos de gravação curva pode reduzir a precisão da gravação.\nRecomendamos manter a velocidade abaixo de %(limit)s para obter melhores resultados.',
      too_fast_for_curve_and_constrain: 'As seguintes camadas: %(layers)s excedem o limite de velocidade de %(limit)s.\nA velocidade de gravação para objetos curvos será limitada a %(limit)s. Você pode remover esse limite nas Configurações de Preferências.',
      too_fast_for_path: 'Usar velocidade muito alta em camadas contendo objetos de caminho pode resultar em menor precisão ao cortar. Não recomendamos usar velocidade superior a %(limit)s ao cortar.',
      too_fast_for_path_and_constrain: 'As seguintes camadas: %(layers)s \ncontêm objetos de caminho vetorial e têm velocidade superior a %(limit)s. A velocidade de corte dos objetos de caminho vetorial será limitada a %(limit)s. Você pode remover este limite nas Configurações de Preferências.',
      touchpad: 'TouchPad',
      ungroup_use: 'Isso irá desagrupar DXF ou SVG importados. Como o arquivo pode conter um grande número de elementos, pode demorar para desagrupar. Tem certeza de que deseja continuar?',
      upload_failed: '#819 Falha no upload do arquivo.',
      upload_file_too_large: '#819 O arquivo é muito grande para upload.',
      vectorize_shading_image: 'Imagens com gradiente levam mais tempo para traçar e são propensas a ruído. Por favor, desative o gradiente da imagem antes de executar.',
    },
    rating_panel: {
      description: 'Se você gosta do Beam Studio, agradeceríamos muito se você pudesse nos avaliar.',
      thank_you: 'Obrigado!',
      title: 'Gosta do Beam Studio?',
    },
    right_panel: {
      laser_panel: {
        advanced: 'Avançado',
        backlash: 'Folga',
        bi_directional: 'Bidirecional',
        by: 'Em',
        ce_z_high_speed: 'Eixo Z de alta velocidade',
        ce_z_high_speed_link: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
        color_adjustment: 'Ajuste de canal',
        color_adjustment_short: 'Canal',
        color_strength: 'Intensidade',
        cross_hatch: 'Hachura Cruzada',
        custom_preset: 'Personalizado',
        cut: 'Cortar',
        diode: 'Laser de Diodo',
        do_not_adjust_default_para: 'As predefinições padrão não podem ser ajustadas.',
        dottingTime: 'Tempo de pontuação',
        dropdown: {
          inches: {
            abs_engraving: 'ABS - Gravação',
            acrylic_3mm_cutting: 'Acrílico - 1/8″ Corte',
            acrylic_5mm_cutting: 'Acrílico - 3/16″ Corte',
            acrylic_8mm_cutting: 'Acrílico - 1/4″ Corte',
            acrylic_10mm_cutting: 'Acrílico - 3/8″ Corte',
            acrylic_engraving: 'Acrílico - Gravação',
            acrylic_printing: 'Acrílico - Impressão',
            aluminum_engraving: 'Alumínio - Gravação',
            aluminum_light: 'Alumínio (claro)',
            bamboo_printing: 'Bambu - Impressão',
            black_abs: 'ABS preto',
            black_acrylic_3mm_cutting: 'Acrílico Preto - 1/8″ Corte',
            black_acrylic_5mm_cutting: 'Acrílico Preto - 3/16″ Corte',
            black_acrylic_engraving: 'Acrílico Preto - Gravação',
            brass_dark: 'Latão (escuro)',
            brass_engraving: 'Latão - Gravação',
            brass_light: 'Latão (claro)',
            canvas_printing: 'Lona - Impressão',
            cardstock_printing: 'Cartolina - Impressão',
            copper: 'Cobre',
            cork_printing: 'Cortiça - Impressão',
            denim_1mm_cutting: 'Denim - 1/32″ Corte',
            fabric_3mm_cutting: 'Tecido - 1/8″ Corte',
            fabric_5mm_cutting: 'Tecido - 3/16″ Corte',
            fabric_engraving: 'Tecido - Gravação',
            fabric_printing: 'Tecido - Impressão',
            flat_stone_printing: 'Pedra Plana - Impressão',
            glass_bw_engraving: 'Vidro - Gravação',
            glass_printing: 'Vidro - Impressão',
            gloss_leather_printing: 'Couro brilhante - Impressão',
            gold_engraving: 'Ouro - Gravação',
            iron_engraving: 'Ferro - Gravação',
            leather_3mm_cutting: 'Couro - 1/8″ Corte',
            leather_5mm_cutting: 'Couro - 3/16″ Corte',
            leather_engraving: 'Couro - Gravação',
            mdf_3mm_cutting: 'MDF da Nova Zelândia - 1/8″ Corte',
            mdf_5mm_cutting: 'MDF da Nova Zelândia - 3/16″ Corte',
            mdf_engraving: 'MDF da Nova Zelândia - Gravação',
            metal_bw_engraving: 'Metal - Gravação',
            opaque_acrylic: 'Acrílico opaco',
            pc_printing: 'PC - Impressão',
            rubber_bw_engraving: 'Borracha - Gravação',
            silver_engraving: 'Prata - Gravação',
            stainless_steel_bw_engraving_diode: 'Metal - Gravação (Laser de Diodo)',
            stainless_steel_dark: 'Aço inoxidável (escuro)',
            stainless_steel_engraving: 'Aço inoxidável - Gravação',
            stainless_steel_light: 'Aço inoxidável (claro)',
            stainless_steel_printing: 'Aço Inoxidável - Impressão',
            steel_engraving_spray_engraving: 'Metal - Gravação',
            stone: 'Pedra',
            ti_engraving: 'Titânio - Gravação',
            titanium_dark: 'Titânio (escuro)',
            titanium_light: 'Titânio (claro)',
            white_abs: 'ABS branco',
            wood_3mm_cutting: 'Madeira - 1/8″ Corte',
            wood_5mm_cutting: 'Madeira - 3/16″ Corte',
            wood_7mm_cutting: 'Madeira - 1/4″ Corte',
            wood_8mm_cutting: 'Madeira - 1/4″ Corte',
            wood_10mm_cutting: 'Madeira - 3/8″ Corte',
            wood_engraving: 'Madeira - Gravação',
            wood_printing: 'Madeira - Impressão',
          },
          mm: {
            abs_engraving: 'ABS - Gravação',
            acrylic_3mm_cutting: 'Acrílico - 3mm Corte',
            acrylic_5mm_cutting: 'Acrílico - 5mm Corte',
            acrylic_8mm_cutting: 'Acrílico - 8mm Corte',
            acrylic_10mm_cutting: 'Acrílico - 10mm Corte',
            acrylic_engraving: 'Acrílico - Gravação',
            acrylic_printing: 'Acrílico - Impressão',
            aluminum_engraving: 'Alumínio - Gravação',
            aluminum_light: 'Alumínio (claro)',
            bamboo_printing: 'Bambu - Impressão',
            black_abs: 'ABS preto',
            black_acrylic_3mm_cutting: 'Acrílico Preto - 3mm Corte',
            black_acrylic_5mm_cutting: 'Acrílico Preto - 5mm Corte',
            black_acrylic_engraving: 'Acrílico Preto - Gravação',
            brass_dark: 'Latão (escuro)',
            brass_engraving: 'Latão - Gravação',
            brass_light: 'Latão (claro)',
            canvas_printing: 'Lona - Impressão',
            cardstock_printing: 'Cartolina - Impressão',
            copper: 'Cobre',
            cork_printing: 'Cortiça - Impressão',
            denim_1mm_cutting: 'Denim - 1mm Corte',
            fabric_3mm_cutting: 'Tecido - 3mm Corte',
            fabric_5mm_cutting: 'Tecido - 5mm Corte',
            fabric_engraving: 'Tecido - Gravação',
            fabric_printing: 'Tecido - Impressão',
            flat_stone_printing: 'Pedra Plana - Impressão',
            glass_bw_engraving: 'Vidro - Gravação',
            glass_printing: 'Vidro - Impressão',
            gloss_leather_printing: 'Couro brilhante - Impressão',
            gold_engraving: 'Ouro - Gravação',
            iron_engraving: 'Ferro - Gravação',
            leather_3mm_cutting: 'Couro - 3mm Corte',
            leather_5mm_cutting: 'Couro - 5mm Corte',
            leather_engraving: 'Couro - Gravação',
            mdf_3mm_cutting: 'MDF - 3mm Corte',
            mdf_5mm_cutting: 'MDF - 5mm Corte',
            mdf_engraving: 'MDF - Gravação',
            metal_bw_engraving: 'Metal - Gravação',
            opaque_acrylic: 'Acrílico opaco',
            pc_printing: 'PC - Impressão',
            rubber_bw_engraving: 'Borracha - Gravação',
            silver_engraving: 'Prata - Gravação',
            stainless_steel_bw_engraving_diode: 'Metal - Gravação (Laser de Diodo)',
            stainless_steel_dark: 'Aço inoxidável (escuro)',
            stainless_steel_engraving: 'Aço inoxidável - Gravação',
            stainless_steel_light: 'Aço inoxidável (claro)',
            stainless_steel_printing: 'Aço Inoxidável - Impressão',
            steel_engraving_spray_engraving: 'Metal - Gravação',
            stone: 'Pedra',
            ti_engraving: 'Titânio - Gravação',
            titanium_dark: 'Titânio (escuro)',
            titanium_light: 'Titânio (claro)',
            white_abs: 'ABS branco',
            wood_3mm_cutting: 'Madeira - 3mm Corte',
            wood_5mm_cutting: 'Madeira - 5mm Corte',
            wood_7mm_cutting: 'Madeira - 7mm Corte',
            wood_8mm_cutting: 'Madeira - 8mm Corte',
            wood_10mm_cutting: 'Madeira - 10mm Corte',
            wood_engraving: 'Madeira - Gravação',
            wood_printing: 'Madeira - Impressão',
          },
          parameters: 'Predefinições',
          save: 'Adicionar parâmetros atuais',
        },
        engrave: 'Gravar',
        existing_name: 'Este nome já foi utilizado.',
        fill_angle: 'Ângulo de Preenchimento',
        fill_interval: 'Intervalo de Preenchimento',
        fill_setting: 'Configuração de Preenchimento',
        filled_path_only: 'Apenas para caminhos de preenchimento',
        focus_adjustment: 'Ajuste de Foco',
        frequency: 'Frequência',
        gradient_only: 'Apenas para imagens em gradiente',
        halftone: 'Meio-tom',
        halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
        height: 'Altura do Objeto',
        ink_saturation: 'Saturação',
        ink_type: {
          normal: 'Tinta',
          text: 'Tipo de tinta',
          UV: 'Tinta UV',
        },
        laser_speed: {
          fast: 'Rápido',
          max: 300,
          min: 3,
          slow: 'Lento',
          step: 0.1,
          text: 'Velocidade',
          unit: 'mm/s',
        },
        low_power_warning: 'Potência laser mais baixa (abaixo de 10%) pode não emitir a luz do laser.',
        low_speed_warning: 'Baixa velocidade pode causar queima do material.',
        lower_focus: 'Abaixar Foco',
        lower_focus_desc: 'Abaixar a altura do foco por uma distância específica após focar para melhorar o desempenho de corte.',
        module: 'Módulo',
        more: 'Gerenciar',
        multi_layer: 'Várias Camadas',
        para_in_use: 'Este parâmetro está em uso.',
        parameters: 'Parâmetros',
        power: {
          high: 'Alta',
          low: 'Baixa',
          max: 100,
          min: 1,
          step: 0.1,
          text: 'Potência',
        },
        preset_management: {
          add_new: 'Adicionar Novo',
          delete: 'Excluir',
          export: 'Exportar',
          export_preset_title: 'Exportar Predefinições',
          import: 'Importar',
          laser: 'Laser',
          lower_focus_by: 'Baixar Foco em',
          new_preset_name: 'Novo Nome de Predefinição',
          preset: 'Predefinição',
          print: 'Imprimir',
          reset: 'Redefinir',
          save_and_exit: 'Salvar e Sair',
          show_all: 'Mostrar Tudo',
          sure_to_import_presets: 'Isso carregará a organização dos predefinidos e substituirá os parâmetros personalizados. Tem certeza de que deseja continuar?',
          sure_to_reset: 'Isso excluirá seus parâmetros personalizados e redefinirá todos os predefinidos. Tem certeza de que deseja continuar?',
          title: 'Gerenciar Parâmetros',
          wobble_diameter: 'Diâmetro da oscilação',
          wobble_step: 'Tamanho do passo de oscilação',
        },
        preset_setting: 'Configurações de Parâmetros (%s)',
        presets: 'Predefinição',
        print_multipass: 'Multi-passagem',
        promark_speed_desc: 'Os parâmetros de velocidade não se aplicam a imagens com gradientes.',
        pulse_width: 'Largura de Pulso',
        pwm_advanced_desc: 'Defina uma potência mínima para o modo de profundidade.',
        pwm_advanced_hint: 'Esta configuração aplica-se especificamente a imagens de gradiente ao usar o modo de profundidade.',
        pwm_advanced_setting: 'Configurações de Potência do Modo de Profundidade',
        repeat: 'Contagem de Passes',
        single_color: 'Cor Única',
        single_color_desc: 'Apenas aplicável à camada de cor completa e não pode ser usado com a camada de cor única expandida.',
        slider: {
          fast: 'Rápido',
          high: 'Alto',
          low: 'Baixo',
          regular: 'Regular',
          slow: 'Lento',
          very_fast: 'Muito rápido',
          very_high: 'Muito alto',
          very_low: 'Muito baixo',
          very_slow: 'Muito lento',
        },
        speed: 'Velocidade',
        speed_constrain_warning: 'A velocidade de corte dos objetos de caminho vetorial será limitada a %(limit)s. Você pode remover este limite nas Configurações de Preferências.',
        speed_constrain_warning_auto_feeder: 'A velocidade de corte dos objetos Alimentador Automático será limitada a %(limit)s. Você pode remover este limite nas Configurações de Preferências.',
        speed_constrain_warning_curve_engraving: 'A velocidade de gravação de curvas será limitada a %(limit)s. Você pode remover esse limite nas Configurações de Preferências.',
        stepwise_focusing: 'Foco por Etapas',
        stepwise_focusing_desc: 'Abaixar gradualmente a distância do foco com base na altura do objeto durante cada contagem de passes.',
        strength: 'Potência',
        times: 'vezes',
        various_preset: 'Várias Predefinições',
        white_ink: 'Tinta Branca',
        white_ink_settings: 'Configurações de tinta branca',
        wobble: 'Oscilação',
        wobble_desc: 'Cria pequenos círculos ao longo do caminho, o que é benéfico para tarefas de corte. (Somente para objetos de caminho.)',
        wobble_diameter: 'Diâmetro',
        wobble_step: 'Tamanho do passo',
        z_step: 'Passo Z',
      },
      layer_panel: {
        current_layer: 'Camada Atual',
        layer1: 'Camada 1',
        layer_bitmap: 'Bitmap',
        layer_cutting: 'Corte',
        layer_engraving: 'Gravação',
        layers: {
          del: 'Excluir camada',
          dupe: 'Duplicar camada',
          fullColor: 'Cores completas',
          layer: 'Camada',
          layers: 'Camadas',
          lock: 'Bloquear camada',
          merge_all: 'Mesclar todas',
          merge_down: 'Mesclar com camada inferior',
          merge_selected: 'Mesclar camadas selecionadas',
          move_down: 'Mover camada para baixo',
          move_elems_to: 'Mover elementos para:',
          move_selected: 'Mover elementos selecionados para uma camada diferente',
          move_up: 'Mover camada para cima',
          new: 'Nova camada',
          rename: 'Renomear camada',
          splitFullColor: 'Expandir Camada',
          switchToFullColor: 'Mudar para camada de cores completas',
          switchToSingleColor: 'Mudar para camada de cor única',
          unlock: 'Desbloquear',
        },
        move_elems_to: 'Mover elementos para:',
        notification: {
          dupeLayerName: 'Já existe uma camada com esse nome!',
          enterNewLayerName: 'Insira o novo nome da camada',
          enterUniqueLayerName: 'Insira um nome de camada exclusivo',
          layerHasThatName: 'A camada já tem esse nome',
          mergeLaserLayerToPrintingLayerMsg: 'Observe que se concluir esta operação, as configurações da camada a laser serão removidas e definidas de acordo com a camada atual.',
          mergeLaserLayerToPrintingLayerTitle: 'Deseja mesclar essas camadas em uma camada de Impressão?',
          mergePrintingLayerToLaserLayerMsg: 'Observe que se concluir esta operação, as configurações de cor da camada de impressão serão removidas e definidas de acordo com a camada atual.',
          mergePrintingLayerToLaserLayerTitle: 'Deseja mesclar essas camadas em uma camada Laser?',
          moveElemFromPrintingLayerMsg: 'Observe que se concluir esta operação, as configurações de cor do elemento selecionado serão removidas e definidas de acordo com %s.',
          moveElemFromPrintingLayerTitle: 'Mover o elemento selecionado para %s e convertê-lo em elemento a laser?',
          moveElemToPrintingLayerMsg: 'Observe que se concluir esta operação, as configurações do elemento selecionado serão removidas e definidas de acordo com %s.',
          moveElemToPrintingLayerTitle: 'Mover o elemento selecionado para %s e convertê-lo em elemento de impressão?',
          newName: 'NOVO NOME',
          QmoveElemsToLayer: 'Mover os elementos selecionados para a camada "%s"?',
          splitColorMsg: 'Observe que se continuar com esta operação, não poderá voltar às camadas de cor originais.',
          splitColorTitle: 'Deseja expandir a camada selecionada em camadas CMYK?',
        },
      },
      object_panel: {
        actions_panel: {
          ai_bg_removal: 'Remoção de fundo',
          ai_bg_removal_reminder: 'Pressionar o botão usará imediatamente 0,2 créditos de, deseja continuar?',
          ai_bg_removal_short: 'Remoção de fundo',
          array: 'Matriz',
          auto_fit: 'Ajuste Automático',
          bevel: 'Bisel',
          brightness: 'Brilho',
          convert_to_path: 'Converter para contorno',
          create_textpath: 'Criar texto no contorno',
          create_textpath_short: 'Texto no contorno',
          crop: 'Recortar',
          decompose_path: 'Descompor',
          detach_path: 'Descompor texto no contorno',
          detach_path_short: 'Descompor',
          disassemble_use: 'Desmontar',
          disassembling: 'Desmontando...',
          edit_path: 'Editar caminho',
          fetching_web_font: 'Obtendo fonte online...',
          grading: 'Classificação',
          invert: 'Inverter',
          offset: 'Deslocamento',
          outline: 'Contorno',
          replace_with: 'Substituir por...',
          replace_with_short: 'Substituir',
          sharpen: 'Nitidez',
          simplify: 'Simplificar',
          smart_nest: 'Aninhamento Inteligente',
          trace: 'Traçar',
          ungrouping: 'Desagrupando...',
          uploading_font_to_machine: 'Enviando fonte para a máquina...',
          wait_for_parsing_font: 'Analisando fonte... Por favor, aguarde um segundo',
          weld_text: 'Fundir texto',
        },
        align: 'Alinhar',
        boolean: 'Booleano',
        bottom_align: 'Alinhar à base',
        center_align: 'Alinhar ao centro',
        difference: 'Diferença',
        distribute: 'Distribuir',
        flip: 'Inverter',
        group: 'Grupo',
        hdist: 'Distribuição horizontal',
        hflip: 'Inverter horizontalmente',
        intersect: 'Interseção',
        left_align: 'Alinhar à esquerda',
        lock_aspect: 'Bloquear proporção',
        middle_align: 'Alinhar ao meio',
        option_panel: {
          color: 'Cor',
          fill: 'Preenchimento',
          font_family: 'Fonte',
          font_size: 'Tamanho',
          font_style: 'Estilo',
          letter_spacing: 'Espaçamento entre letras',
          line_spacing: 'Espaçamento entre linhas',
          path_infill: 'Preenchimento de caminho',
          pwm_engraving: 'Modo de Profundidade',
          pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
          rounded_corner: 'Canto arredondado',
          shading: 'Sombreamento',
          sides: 'Lados',
          start_offset: 'Deslocamento de texto',
          stroke: 'Traço',
          stroke_color: 'Cor do traço',
          stroke_width: 'Largura da linha',
          text_infill: 'Preenchimento de texto',
          threshold: 'Limiar de brilho',
          threshold_short: 'Limiar',
          vertical_align: 'Alinhamento',
          vertical_text: 'Texto vertical',
        },
        path_edit_panel: {
          connect: 'Conectar',
          delete: 'Excluir',
          disconnect: 'Desconectar',
          node_type: 'TIPO DE NÓ',
          round: 'Arredondado',
          sharp: 'Afiado',
        },
        right_align: 'Alinhar à direita',
        subtract: 'Subtrair',
        top_align: 'Alinhar ao topo',
        ungroup: 'Desagrupar',
        union: 'União',
        unlock_aspect: 'Desbloquear proporção',
        vdist: 'Distribuição vertical',
        vflip: 'Inverter verticalmente',
        zoom: 'Zoom',
      },
      tabs: {
        layers: 'Camadas',
        objects: 'Objetos',
        path_edit: 'Editar Caminho',
      },
    },
    shapes_panel: {
      animals: 'Animal',
      arrow: 'Seta',
      basic: 'Básico',
      birds: 'Pássaro',
      celebration: 'Celebração',
      circular: 'Circular',
      CNY: 'Ano Novo Chinês',
      corner: 'Canto',
      decor: 'Decoração',
      easter: 'Páscoa',
      elements: 'Elementos',
      environment: 'Meio Ambiente',
      graphics: 'Gráficos',
      halloween: 'Dia das Bruxas',
      holidays: 'Feriado',
      label: 'Rótulo',
      land: 'Terra',
      line: 'Linha',
      nature: 'Natureza',
      photo: 'Foto',
      plants: 'Plantas',
      ribbon: 'Fita',
      sea: 'Mar',
      shape: 'Forma',
      speech: 'Discurso',
      text: 'Caixa de texto',
      title: 'Elementos',
      valentines: 'Dia dos Namorados',
      weather: 'Clima',
      Xmas: 'Natal',
    },
    svg_editor: {
      unable_to_fetch_clipboard_img: 'Falha ao obter imagem da sua área de transferência',
      unnsupported_file_type: 'O tipo de arquivo não é diretamente suportado. Por favor, converta o arquivo em SVG ou bitmap',
    },
    tag: {
      g: 'Grupo',
      image: 'Imagem',
      text: 'Texto',
      use: 'Importar SVG',
    },
    time_est_button: {
      calculate: 'Estimar tempo',
      estimate_time: 'Tempo estimado:',
    },
    tool_panels: {
      _nest: {
        end: 'Fechar',
        no_element: 'Não há elementos para organizar.',
        rotations: 'Possíveis Rotações',
        spacing: 'Espaçamento',
        start_nest: 'Organizar',
        stop_nest: 'Parar',
      },
      _offset: {
        corner_type: 'Canto',
        direction: 'Direção de deslocamento',
        dist: 'Distância de deslocamento',
        fail_message: 'Falha ao deslocar objetos.',
        inward: 'Para dentro',
        not_support_message: 'Elementos selecionados contendo tag SVG não suportada:\nImagem, Grupo, Texto e Objeto Importado.',
        outward: 'Para fora',
        round: 'Arredondado',
        sharp: 'Afiado',
      },
      array_dimension: 'Dimensão da Matriz',
      array_interval: 'Intervalo da Matriz',
      cancel: 'Cancelar',
      columns: 'Colunas',
      confirm: 'Confirmar',
      dx: 'X',
      dy: 'Y',
      grid_array: 'Criar Matriz de Grade',
      nest: 'Otimização de arranjo',
      offset: 'Deslocamento',
      rows: 'Linhas',
    },
    units: {
      mm: 'mm',
      walt: 'W',
    },
    zoom_block: {
      fit_to_window: 'Ajustar à janela',
    },
  },
  boxgen: {
    add_option: 'Adicionar Opção',
    basic_box: 'Caixa Básica',
    beam_radius: 'Compensação do raio do feixe',
    beam_radius_warning: 'Remova a compensação de corte quando as bordas ou juntas da caixa forem curtas para garantir a montagem da caixa',
    cancel: 'Cancelar',
    coming_soon: 'Em breve',
    continue_import: 'Continuar a Importar',
    control_tooltip: 'Botão esquerdo para rotacionar\nRoda para aplicar zoom\nBotão direito para mover',
    control_tooltip_touch: 'Arraste para rotacionar\nAproxime os dedos para aplicar zoom\nDois dedos para mover',
    cover: 'Tampa',
    customize: 'Personalizar',
    depth: 'Profundidade',
    edge: 'Borda',
    finger: 'Dedo',
    finger_warning: 'O comprimento interno do lado da caixa precisa ser de pelo menos 6 mm (0,24 polegadas) para ser compatível com a junção de dedos.',
    height: 'Altura',
    import: 'Importar',
    inner: 'Interno',
    joints: 'Junta',
    max_dimension_tooltip: 'A configuração máxima de largura/altura/profundidade é %s.',
    merge: 'Mesclar',
    outer: 'Externo',
    reset: 'Redefinir',
    tCount: 'Contagem T',
    tCount_tooltip: 'A contagem de ranhuras em T aplica-se ao lado curto; a quantidade no lado longo é calculada com base no seu comprimento.',
    tDiameter: 'Diâmetro T',
    text_label: 'Rótulo',
    thickness: 'Espessura',
    title: 'CAIXA',
    tLength: 'Comprimento T',
    tSlot: 'T-Slot',
    tSlot_warning: 'O comprimento do lado da caixa precisa ser de pelo menos 30 mm (1,18 polegadas) para ser compatível com a junção de ranhuras em T.',
    volume: 'Volume',
    width: 'Largura',
    workarea: 'Área de trabalho',
    zoom: 'Zoom',
  },
  buttons: {
    back: 'Voltar',
    back_to_beam_studio: 'Voltar para Beam Studio',
    done: 'Concluído',
    next: 'Próximo',
  },
  calibration: {
    ador_autofocus_focusing_block: 'Pressione o ícone "AF" na página principal da máquina por 3 segundos e deixe a sonda tocar o bloco de foco.',
    ador_autofocus_material: 'Pressione o ícone "AF" na página principal da máquina por 3 segundos e deixe a sonda tocar o material suavemente.',
    align_ilb: 'Posição: Alinhe com o ponto gravado na parte inferior esquerda interna.',
    align_ilt: 'Posição: Alinhe com o ponto gravado na parte superior esquerda interna.',
    align_irb: 'Posição: Alinhe com o ponto gravado na parte inferior direita interna.',
    align_irt: 'Posição: Alinhe com o ponto gravado na parte superior direita interna.',
    align_olb: 'Posição: Alinhe com o ponto gravado na parte inferior esquerda externa.',
    align_olt: 'Posição: Alinhe com o ponto gravado na parte superior esquerda externa.',
    align_orb: 'Posição: Alinhe com o ponto gravado na parte inferior direita externa.',
    align_ort: 'Posição: Alinhe com o ponto gravado na parte superior direita externa.',
    align_red_cross_cut: 'Alinhe o centro da cruz vermelha com a cruz de corte.',
    align_red_cross_print: 'Alinhe o centro da cruz vermelha com a cruz impressa.',
    analyze_result_fail: 'Falha ao analisar a imagem capturada.<br/>Certifique-se de que:<br/>1. A imagem capturada está totalmente coberta com papel branco.<br/>2. A plataforma está devidamente focada.',
    ask_for_readjust: 'Deseja pular a etapa de gravação e calibrar diretamente tirando uma foto?',
    back: 'Voltar',
    calculating_camera_matrix: 'Calculando Matriz da Câmera...',
    calculating_regression_parameters: 'Calculando parâmetros de regressão...',
    calibrate_camera_before_calibrate_modules: 'Calibre a câmera antes de calibrar os módulos.',
    calibrate_chessboard_success_msg: 'A foto do tabuleiro de xadrez foi capturada com sucesso.<br/>A pontuação para esta foto é %s (%.2f).',
    calibrate_done: 'Calibração concluída. Maior precisão da câmera é obtida quando o foco está correto.',
    calibrate_done_diode: 'Calibração concluída. O deslocamento do módulo de laser de diodo foi salvo.',
    calibrating: 'Calibrando...',
    calibrating_with_device_pictures: 'Calibrando com imagens do dispositivo...',
    camera_calibration: 'Calibração da Câmera',
    camera_parameter_saved_successfully: 'Parâmetros da câmera salvos com sucesso.',
    cancel: 'Cancelar',
    check_checkpoint_data: 'Dados do ponto de verificação',
    check_device_pictures: 'Verificar imagens do dispositivo',
    checking_checkpoint: 'Verificando dados do ponto de verificação...',
    checking_pictures: 'Verificando imagens do dispositivo...',
    diode_calibration: 'Calibração do Módulo de Laser de Diodo',
    do_engraving: 'Fazer Gravação',
    download_chessboard_file: 'Baixar arquivo de tabuleiro de xadrez',
    downloading_checkpoint: 'Baixando dados do ponto de verificação...',
    downloading_pictures: 'Baixando Imagens...',
    drawing_calibration_image: 'Desenhando imagem de calibração...',
    dx: 'X',
    dy: 'Y',
    elevate_and_cut: 'Elevar e Cortar',
    elevate_and_cut_step_1: 'Coloque um pedaço de madeira de cor clara de tamanho A4 no centro da área de trabalho, elevado a 20mm.',
    elevate_and_cut_step_1_prism_lift: 'Use o Ador Prism Lift com seu comprimento máximo de 14 mm junto com uma madeira com pelo menos 6 mm de espessura para elevar a madeira a uma altura de 20 mm.',
    failed_to_calibrate_camera: '#848 Falha ao calibrar a câmera, entre em contato com o suporte FLUX.',
    failed_to_calibrate_chessboard: 'Falha na calibração com a imagem do tabuleiro de xadrez.',
    failed_to_calibrate_with_pictures: '#848 Falha ao calibrar com imagens do dispositivo.',
    failed_to_download_pictures: '#848 Falha ao baixar imagens, entre em contato com o Suporte FLUX.',
    failed_to_move_laser_head: 'Falha ao mover a cabeça do laser.',
    failed_to_parse_checkpoint: 'Falha ao analisar os dados do ponto de verificação.',
    failed_to_save_calibration_results: '#849 Falha ao salvar resultados de calibração, tente novamente. Se isso continuar acontecendo, entre em contato com o suporte FLUX.',
    failed_to_save_camera_parameter: 'Falha ao salvar os parâmetros da câmera.',
    failed_to_solve_pnp: 'Falha ao resolver a posição da câmera.',
    finish: 'Concluído',
    found_checkpoint: 'Dados do ponto de verificação encontrados no seu dispositivo. Deseja recuperar do ponto de verificação?',
    getting_plane_height: 'Obtendo Altura do Plano...',
    hint_adjust_parameters: 'Use esses parâmetros para ajustar o quadrado vermelho',
    hint_red_square: 'Por favor, alinhe o exterior do quadrado vermelho com o quadrado de corte.',
    module_calibration_2w_ir: 'Calibração do Módulo Infravermelho',
    module_calibration_printer: 'Calibração do Módulo da Impressora',
    moving_laser_head: 'Movendo a cabeça do laser...',
    next: 'Próximo',
    no_picutre_found: '#846 Seu dispositivo não possui fotos brutas disponíveis para calibração. Entre em contato com o suporte da FLUX.',
    perform_autofocus_bb2: 'Por favor, vá ao painel de controle da máquina e pressione AF para realizar o foco automático.',
    please_do_camera_calibration_and_focus: {
      beambox: 'Ao calibrar o módulo de laser de diodo, a câmera é necessária.\nCertifique-se de que a câmera desta máquina foi calibrada.\nE ajuste gentilmente a plataforma ao ponto focal (a altura do acrílico virado para baixo)',
      beamo: 'Ao calibrar o módulo de laser de diodo, a câmera é necessária.\nCertifique-se de que a câmera desta máquina foi calibrada.\nE ajuste gentilmente a cabeça do laser para focar no objeto de gravação (a altura do acrílico virado para baixo)',
    },
    please_goto_beambox_first: 'Por favor, mude para o Modo de Gravação (Beambox) para usar este recurso.',
    please_place_dark_colored_paper: 'Por favor, coloque uma folha de papel escuro tamanho A4 ou Carta no centro da área de trabalho.',
    please_place_paper: 'Por favor, coloque uma folha de papel branco tamanho A4 ou Carta no canto superior esquerdo da área de trabalho.',
    please_place_paper_center: 'Por favor, coloque uma folha de papel branco tamanho A4 ou Carta no centro da área de trabalho.',
    please_refocus: {
      beambox: 'Por favor, ajuste a plataforma ao ponto focal (a altura do acrílico virado para baixo)',
      beamo: 'Por favor, ajuste a cabeça do laser para focar no objeto de gravação (a altura do acrílico virado para baixo)',
      beamo_af: 'Por favor, toque duas vezes no botão lateral do acessório de foco automático e deixe a sonda tocar o material suavemente.',
      hexa: 'Clique duas vezes no botão de ajuste de altura para elevar a mesa de favo de mel e fazer a sonda tocar o material de gravação.',
    },
    preparing_to_take_picture: 'Preparando para tirar uma foto...',
    promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    put_chessboard: 'Colocar Tabuleiro de Xadrez',
    put_chessboard_1: 'Por favor, vá ao painel de controle da máquina e pressione AF para realizar o foco automático.',
    put_chessboard_2: 'Por favor, mova o papel do tabuleiro de xadrez ou a cabeça do laser até que todo o tabuleiro de xadrez esteja confirmado dentro da moldura vermelha na janela de visualização ao vivo.',
    put_chessboard_3: 'Clique com o botão direito para baixar a imagem do tabuleiro de xadrez na localização atual e verificar sua nitidez.',
    put_chessboard_bb2_desc_1: 'Por favor, baixe o seguinte arquivo de tabuleiro de xadrez e imprima-o em papel A4 (o tabuleiro de xadrez impresso deve ter quadrados de 1x1 cm).',
    put_chessboard_bb2_desc_2: 'Fixe o tabuleiro de xadrez impresso em uma superfície indeformável, como acrílico ou vidro, garantindo que o tabuleiro esteja liso, sem rugas ou bordas levantadas.',
    put_chessboard_bb2_desc_3: 'Coloque a superfície com o tabuleiro de xadrez plana no centro da área de trabalho.',
    put_chessboard_promark_1: 'Ajuste a distância focal para o foco adequado da lente de campo.',
    put_chessboard_promark_2: 'Certifique-se de que o papel de xadrez esteja claramente visível, sem reflexo. Clique em "Próximo" para tirar uma foto.',
    put_chessboard_promark_desc_1: 'Por favor, utilize o papel de xadrez fornecido na caixa de acessórios, ou imprima o seguinte padrão de xadrez em papel A4 para a calibração da câmera.',
    put_chessboard_promark_desc_2: 'Coloque o tabuleiro de xadrez plano e centralizado na plataforma de trabalho.',
    put_paper: 'Coloque o papel',
    put_paper_promark_1: 'Coloque o cartão preto da caixa de acessórios na plataforma de trabalho.',
    put_paper_promark_2: 'Ajuste o foco corretamente e clique em "Iniciar Gravação" para continuar com a gravação.',
    put_paper_skip: 'Se o desenho de calibração não prosseguir automaticamente para tirar a foto, pressione "Pular" para tirar a foto.',
    put_paper_step1: 'Por favor, coloque um papel branco de tamanho A4 ou Carta no centro da área de trabalho.',
    put_paper_step2: 'Fixe os quatro cantos do papel para garantir que ele fique plano.',
    put_paper_step3: 'Clique em "Iniciar Gravação".',
    res_average: 'Média',
    res_excellent: 'Excelente',
    res_poor: 'Fraco',
    retake: 'Tirar foto novamente',
    rotation_angle: 'Rotação',
    show_last_config: 'Mostrar Último Resultado',
    skip: 'Pular',
    solve_pnp_step1: 'Alinhe os pontos de gravação de acordo com o número e a posição esperada de cada ponto de marcador vermelho.',
    solve_pnp_step2: 'Você pode pressionar "Tirar foto novamente" para realinhar ou ajustar manualmente as posições dos marcadores.',
    solve_pnp_step3: 'Se a imagem capturada não corresponder à cena real, por favor, amplie ou arraste a tela para ajustar o ponto de calibração na carta de calibração preta ao centro, e depois tente "Tirar foto novamente".',
    solve_pnp_title: 'Alinhar Pontos de Marcação',
    start_engrave: 'Iniciar Gravação',
    start_printing: 'Iniciar Impressão',
    taking_picture: 'Tirando Foto...',
    unable_to_load_camera_parameters: "#851 Não há parâmetros de calibração de câmera disponíveis no seu dispositivo. Vá para 'Calibração' > 'Calibrar câmera (Avançado)' para concluir a calibração e obter os parâmetros.",
    update_firmware_msg1: 'Seu firmware não suporta esta função. Atualize o firmware para v',
    update_firmware_msg2: 'ou superior para continuar. (Menu > Máquina > [Sua Máquina] > Atualizar Firmware)',
    uploading_images: 'Enviando Imagens...',
    use_last_config: 'Usar Último Valor de Calibração',
    use_old_camera_parameter: 'Deseja usar os parâmetros atuais da lente da câmera?',
    with_af: 'Com Foco Automático',
    with_prism_lift: 'Com Ador Prism Lift',
    without_af: 'Sem Foco Automático',
    without_prism_lift: 'Sem Ador Prism Lift',
    x_ratio: 'Proporção X',
    y_ratio: 'Proporção Y',
    zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
  },
  camera_data_backup: {
    download_success: 'Dados da câmera baixados com sucesso.',
    downloading_data: 'Baixando dados...',
    estimated_time_left: 'Tempo estimado restante:',
    folder_not_exists: 'A pasta selecionada não existe.',
    incorrect_folder: 'Falha ao carregar os dados de calibração. Por favor, verifique se a pasta selecionada está correta.',
    no_picture_found: 'Nenhuma imagem encontrada na máquina.',
    title: 'Backup de Dados da Câmera',
    upload_success: 'Dados da câmera enviados com sucesso.',
    uploading_data: 'Enviando dados...',
  },
  caption: {
    connectionTimeout: 'Tempo limite de conexão',
  },
  change_logs: {
    added: 'Adicionado:',
    change_log: 'Registros de alterações:',
    changed: 'Alterado:',
    fixed: 'Corrigido:',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    see_older_version: 'Ver versões anteriores',
  },
  code_generator: {
    barcode: 'Código de barras',
    qr_code: 'Código QR',
  },
  curve_engraving: {
    '921': 'Falha ao focar automaticamente.',
    '922': 'Falha na medição com luz vermelha.',
    amount: 'Quantidade',
    apply_arkwork: 'Aplicar arte na curva',
    apply_camera: 'Aplicar imagem da câmera na curva',
    click_to_select_point: 'Clique para selecionar ou desselecionar pontos para medir novamente.',
    coloumns: 'Colunas',
    column_gap: 'Espaço entre colunas',
    err_object_over_range: 'O objeto excede o intervalo de medição.',
    failed_to_take_reference: 'Falha ao capturar referência',
    gap: 'Espaço',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    measure_audofocus_area: 'Medir área de autofoco',
    preview_3d_curve: 'Pré-visualização da gravação de curva',
    remeasure: 'Re-medir',
    remeasuring_points: 'Re-medindo pontos...',
    reselect_area: 'Re-selecionar área',
    row_gap: 'Espaço entre linhas',
    rows: 'Linhas',
    set_object_height: 'Definir altura do objeto',
    set_object_height_desc: 'Meça a espessura máxima do objeto.',
    start_autofocus: 'Iniciar autofoco',
    starting_measurement: 'Iniciando medição...',
    sure_to_delete: 'Deseja excluir os dados de foco da gravação da curva?',
    take_reference: 'Pegar referência',
    take_reference_desc: 'Por favor, mova a cabeça do laser para o ponto mais alto do objeto, abaixe a sonda de foco e clique em "Confirmar" para focar.',
  },
  device: {
    abort: 'Abortar',
    aborted: 'Abortado',
    aborting: 'Abortando',
    busy: 'Ocupado',
    cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
    cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
    cartridge_serial_number: 'Ink cartridge serial number',
    close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
    completed: 'Concluído',
    completing: 'Concluindo',
    deviceList: 'Lista de Máquinas',
    disable: 'Desativar',
    disconnectedError: {
      caption: 'Máquina desconectada',
      message: 'Confirme se o acesso à rede de %s está disponível',
    },
    enable: 'Ativar',
    firmware_version: 'Versão do firmware',
    ink_color: 'Ink color',
    ink_level: 'Ink level',
    ink_type: 'Ink type',
    IP: 'IP',
    model_name: 'Nome do modelo',
    occupied: 'Ocupado',
    pause: 'Pausar',
    paused: 'Pausado',
    pausedFromError: 'Pausado por erro',
    pausing: 'Pausando',
    please_wait: 'Aguarde...',
    preparing: 'Preparando',
    processing: 'Processando',
    quit: 'Sair',
    ready: 'Pronto',
    reset: 'Redefinir',
    resuming: 'Retomando',
    retry: 'Tentar novamente',
    running: 'Em execução',
    scanning: 'Verificando',
    select: 'Selecionar',
    select_printer: 'Selecionar Impressora',
    serial_number: 'Número de série',
    start: 'Iniciar',
    starting: 'Iniciando',
    status: 'Status',
    submodule_type: 'Module',
    toolhead_change: 'Trocar Cabeça da Ferramenta',
    unknown: 'Desconhecido',
    uploading: 'Enviando',
    UUID: 'UUID',
  },
  device_selection: {
    no_beambox: '#801 Não conseguimos encontrar sua máquina na rede.\nSiga o <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">guia</a> para resolver o problema de conexão.',
    no_device_web: "#801 Verifique o estado da sua máquina ou clique em 'Configuração da máquina' abaixo para configurar a máquina.",
    select_usb_device: 'Selecionar dispositivo USB',
  },
  editor: {
    exposure: 'Exposição da pré-visualização',
    opacity: 'Opacidade da pré-visualização',
    prespray_area: 'Área de preparação',
  },
  error_pages: {
    screen_size: 'Observe que o Beam Studio pode não funcionar idealmente no seu dispositivo. Para uma experiência ideal, o dispositivo deve ter uma largura de tela de pelo menos 1024 pixels.',
  },
  flux_id_login: {
    connection_fail: '#847 Falha ao conectar ao serviço de membros FLUX.',
    email: 'E-mail',
    flux_plus: {
      access_monotype_feature: 'Você não tem o complemento Fontes Monotype.',
      access_monotype_feature_note: 'Você precisa da assinatura FLUX+ Pro ou do complemento Fontes Monotype para acessar este recurso.',
      access_plus_feature_1: 'Você está acessando um',
      access_plus_feature_2: 'recurso.',
      access_plus_feature_note: 'Você precisa da assinatura FLUX+ para acessar este recurso.',
      ai_credit_tooltip: 'Para remoção de fundo por IA',
      explore_plans: 'Explore planos FLUX+',
      features: {
        ai_bg_removal: 'Remoção de fundo por IA',
        boxgen: 'Gerador de caixas 3D',
        dmkt: '1000+ arquivos de design',
        monotype: '250+ fontes premium',
        my_cloud: 'Armazenamento em nuvem ilimitado',
      },
      flux_credit_tooltip: 'Para arquivos do Mercado de Design e remoção de fundo por IA',
      get_addon: 'Obter complemento',
      goto_member_center: 'Acesse o Centro de Membros',
      learn_more: 'Saiba mais',
      member_center_url: 'https://member.flux3dp.com/en-US/subscription',
      subscribe_now: 'Inscreva-se agora',
      thank_you: 'Obrigado por ser um membro valioso!',
      website_url: 'https://flux3dp.com/subscription',
    },
    forget_password: 'Esqueceu a senha?',
    incorrect: 'O endereço de e-mail ou a senha não está correto.',
    login: 'Entrar',
    login_success: 'Conectado com sucesso.',
    lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
    new_to_flux: 'Novo no FLUX? Crie uma conta.',
    not_verified: 'O endereço de e-mail ainda não foi verificado.',
    offline: 'Trabalhar offline',
    password: 'Senha',
    register: 'Criar sua conta FLUX',
    remember_me: 'Lembrar de mim',
    signup_url: 'https://id.flux3dp.com/user/login#up',
    unlock_shape_library: 'Faça login para desbloquear a biblioteca de formas.',
    work_offline: 'Trabalhar offline',
  },
  framing: {
    area_check: 'Verificação da Área',
    areacheck_desc: 'Garante a segurança da área de trabalho exibindo a caixa delimitadora do objeto e a zona de aceleração da cabeça do laser.',
    calculating_task: 'Calculando tarefa...',
    framing: 'Moldura',
    framing_desc: 'Visualiza a caixa delimitadora do objeto.',
    hull: 'Casco',
    hull_desc: 'Exibe uma pré-visualização de uma forma que segue de perto o design, como um elástico envolvido ao redor do objeto.',
    low_laser: 'Laser baixo',
    low_laser_desc: 'Defina um valor de laser de baixa potência para a tarefa de moldura.',
    rotate_axis: 'Eixo de rotação',
    rotateaxis_desc: 'Pré-visualização do centro do eixo de rotação, o próprio eixo não se move.',
    rotation_frame_warning: 'A pré-visualização da moldura girará uma vez. Evite parar no meio do processo para evitar desalinhamento da posição da marcação.',
    rotation_framing_desc: 'Pré-visualização do alcance completo da rotação, o eixo gira de acordo com a pré-visualização.',
    start_task: 'Iniciar Tarefa',
    start_task_description: 'Após concluir a pré-visualização do quadro, clique em "Iniciar Tarefa" para enviar o trabalho.',
  },
  general: {
    choose_folder: 'Escolher pasta',
    processing: 'Processando...',
  },
  generic_error: {
    OPERATION_ERROR: '[OE] Ocorreu um conflito de status, tente novamente a ação.',
    SUBSYSTEM_ERROR: '[SE] Erro ao executar a tarefa no firmware da máquina. Reinicie a máquina.',
    UNKNOWN_COMMAND: '[UC] Atualize o firmware do dispositivo',
    UNKNOWN_ERROR: '[UE] Ocorreu um erro desconhecido. Reinicie o Beam Studio e a máquina.',
  },
  global: {
    apply: 'Aplicar',
    back: 'Voltar',
    cancel: 'Cancelar',
    editing: {
      redo: 'Refazer',
      reset: 'Redefinir',
      undo: 'Desfazer',
      zoom_in: 'Aproximar',
      zoom_out: 'Afastar',
    },
    mode_conflict: 'Esta opção não está disponível no modo atual.',
    ok: 'OK',
    save: 'Salvar',
    stop: 'Parar',
  },
  image_edit_panel: {
    eraser: {
      brush_size: 'Tamanho do Pincel',
      description: 'Clique ou arraste para apagar manualmente áreas indesejadas.',
      title: 'Borracha',
    },
    magic_wand: {
      description: 'Selecione e remova áreas adjacentes com base na similaridade de cores.',
      title: 'Varinha Mágica',
      tolerance: 'Tolerância',
    },
    title: 'Editar Imagem',
  },
  initialize: {
    back: 'Voltar',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    connect: 'Conectar',
    connect_ethernet: {
      title: 'Conexão Direta',
      tutorial1: '1. Conecte a máquina ao seu computador com um cabo Ethernet.',
      tutorial2_1: '2. Siga',
      tutorial2_2: ' para configurar o seu computador como um roteador.',
      tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
      tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
      tutorial2_a_text: 'este guia',
      tutorial3: '3. Clique em Avançar.',
    },
    connect_machine_ip: {
      alert: {
        swiftray_connection_error: 'Não foi possível conectar ao servidor. Reinicie o Beam Studio e tente novamente.',
      },
      check_camera: 'Verificando disponibilidade da câmera',
      check_connection: 'Verificando conexão da máquina',
      check_firmware: 'Verificando versão do firmware',
      check_ip: 'Verificando disponibilidade de IP',
      check_swiftray_connection: 'Verificando conexão com o servidor',
      check_swiftray_connection_unreachable: 'servidor inacessível',
      check_usb: 'Verificar conexão USB',
      enter_ip: 'Insira o IP da sua máquina',
      finish_setting: 'Comece a criar!',
      invalid_format: 'Formato inválido',
      invalid_ip: 'IP inválido: ',
      promark_hint: 'Se você não conseguir se conectar repetidamente via USB, consulte o <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">artigo do Centro de Ajuda</a>.',
      retry: 'Tentar novamente',
      starts_with_169254: 'Começa com 169.254',
      succeeded_message: 'Conectado com sucesso 🎉',
      unreachable: 'IP inacessível',
    },
    connect_usb: {
      connect_camera: 'Conecte a câmera da máquina ao seu computador com um cabo USB.',
      title: 'Conexão USB',
      title_sub: ' (HEXA e Ador Apenas)',
      turn_off_machine: 'Desligue a máquina.',
      turn_on_machine: 'Ligue a máquina.',
      tutorial1: 'Conecte a máquina ao seu computador com um cabo USB.',
      tutorial2: "Clique em 'Próximo'.",
      wait_for_turning_on: 'Clique em "Próximo" após o processo de inicialização estar concluído e você ter entrado na tela principal.',
    },
    connect_wifi: {
      title: 'Conectando ao Wi-Fi',
      tutorial1: '1. Vá para o Painel de Toque > Clique em "Rede" > "Conectar ao Wi-Fi".',
      tutorial1_ador: '1. Vá para o Painel de Toque > Clique em "MÁQUINA" > Clique em "Rede" > "Conectar ao Wi-Fi".',
      tutorial2: '2. Selecione e conecte-se à rede Wi-Fi de sua preferência.',
      what_if_1: 'O que fazer se não vir a minha rede Wi-Fi?',
      what_if_1_content: '1. O modo de criptografia Wi-Fi deve ser WPA2 ou sem senha.\n2. O modo de criptografia pode ser definido na interface de administração do roteador Wi-Fi. Se o roteador não suportar WPA2 e você precisar de ajuda para escolher o roteador certo, entre em contato com o Suporte FLUX.',
      what_if_2: 'O que fazer se não vir nenhuma rede Wi-Fi?',
      what_if_2_content: '1. Certifique-se de que o adaptador Wi-Fi esteja totalmente conectado.\n2. Se não houver endereço MAC da rede sem fio na tela de toque, entre em contato com o Suporte FLUX.\n3. O canal Wi-Fi deve ser 2.4Ghz (5Ghz não é suportado).',
    },
    connect_wired: {
      title: 'Conectando à rede com fio',
      tutorial1: '1. Conecte a máquina com o seu roteador.',
      tutorial2: '2. Pressione "Rede" para obter o IP da rede com fio.',
      tutorial2_ador: '2. Pressione "MÁQUINA" > "Rede" para obter o IP da rede com fio.',
      what_if_1: 'O que fazer se o IP estiver vazio?',
      what_if_1_content: '1. Certifique-se de que o cabo Ethernet esteja totalmente conectado.\n2. Se não houver endereço MAC da rede com fio na tela de toque, entre em contato com o Suporte FLUX.',
      what_if_2: 'O que fazer se o IP começar com 169?',
      what_if_2_content: '1. Se o endereço IP começar com 169.254, deve ser um problema de configuração DHCP, entre em contato com seu ISP (provedor de serviços de internet) para mais assistência.\n2. Se o seu computador se conectar à internet diretamente usando PPPoE, mude para usar o roteador para conectar usando PPPoE e habilite o recurso DHCP no roteador.',
    },
    connecting: 'Conectando...',
    connection_types: {
      ether2ether: 'Conexão Direta',
      usb: 'Conexão USB',
      wifi: 'Wi-Fi',
      wired: 'Rede com Fio',
    },
    next: 'Próximo',
    no_machine: 'Não tenho uma máquina agora.',
    promark: {
      configuration_confirmation: 'Isso garante que seu Promark esteja configurado corretamente para máxima eficiência e precisão.',
      or_complete_later: `Ou, pule esta etapa e conclua as configurações do Promark mais tarde em:
    Máquinas > "Nome do Promark" > Configurações do Promark`,
      qc_instructions: 'Preencha os parâmetros localizados no verso do cartão "QC Pass"',
      select_laser_source: 'Selecione o seu Promark',
      select_workarea: 'Selecione a Área de Trabalho',
      settings: 'Configurações do Promark',
    },
    retry: 'Tentar novamente',
    select_beambox: 'Selecione o seu Beambox',
    select_connection_type: 'Como deseja se conectar?',
    select_language: 'Selecionar Idioma',
    select_machine_type: 'Selecione o Tipo da Sua Máquina',
    setting_completed: {
      back: 'Voltar',
      great: 'Bem-vindo ao Beam Studio',
      ok: 'COMECE A CRIAR',
      setup_later: 'Você pode configurar sua máquina a qualquer momento em Barra de título > "Máquinas" > "Configuração de máquina"',
      start: 'Começar',
    },
    skip: 'Pular',
    start: 'Iniciar',
  },
  input_machine_password: {
    connect: 'CONECTAR',
    password: 'Senha',
    require_password: '"%s" requer uma senha',
  },
  insecure_websocket: {
    extension_detected: 'Extensão Beam Studio Connect detectada',
    extension_detected_description: "Detectamos que você instalou a extensão Beam Studio Connect. Clique em 'Confirmar' para redirecionar para HTTPS, ou clique em 'Cancelar' para continuar usando HTTP.",
    extension_not_deteced: 'Não foi possível detectar a extensão Beam Studio Connect',
    extension_not_deteced_description: "Para usar HTTPS, clique em 'Confirmar' para instalar a extensão Beam Studio Connect. Após instalar a extensão, atualize a página para ativá-la.<br/>Caso contrário, clique no link abaixo para ver como usar HTTP no Chrome.",
    unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Link</a>',
  },
  layer_module: {
    general_laser: 'Laser',
    laser_2w_infrared: 'Laser infravermelho de 2W',
    laser_10w_diode: 'Laser de diodo de 10W',
    laser_20w_diode: 'Laser de diodo de 20W',
    non_working_area: 'Área Não Laboral',
    none: 'None',
    notification: {
      convertFromLaserModuleMsg: 'Tenha em mente que se completar esta operação, as configurações da camada laser serão removidas e definidas de acordo com a camada atual.',
      convertFromLaserModuleTitle: 'Deseja converter o módulo Laser em módulo de Impressão?',
      convertFromPrintingModuleMsg: 'Tenha em mente que se completar esta operação, as configurações de cor da camada de impressão serão removidas e definidas de acordo com a camada atual.',
      convertFromPrintingModuleTitle: 'Deseja converter o módulo de Impressão para o módulo Laser?',
      importedDocumentContainsPrinting: 'O documento contém camada de impressão, deseja alterar a área de trabalho para Ador?',
      performIRCaliMsg: 'Clique em "Confirmar" para executar a calibração ou acesse a calibração pelo menu superior.<br />(Máquina > [Nome da sua máquina] > Calibrar módulo Infravermelho)',
      performIRCaliTitle: 'Executar a calibração do módulo Infravermelho',
      performPrintingCaliMsg: 'Clique em "Confirmar" para executar a calibração ou acesse a calibração pelo menu superior.<br />(Máquina > [Nome da sua máquina] > Calibrar módulo de impressão)',
      performPrintingCaliTitle: 'Executar a calibração do módulo de impressão',
      printingLayersCoverted: 'As camadas de impressão foram convertidas em camadas laser.',
    },
    printing: 'Impressão',
    unknown: 'Unknown Module',
  },
  machine_status: {
    '-17': 'Modo de E/S do cartucho',
    '-10': 'Modo de manutenção',
    '-2': 'Digitalizando',
    '-1': 'Manutenção',
    '0': 'Ocioso',
    '1': 'Iniciando',
    '2': 'ST_TRANSFORM',
    '4': 'Iniciando',
    '6': 'Retomando',
    '16': 'Trabalhando',
    '18': 'Retomando',
    '32': 'Pausado',
    '36': 'Pausado',
    '38': 'Pausando',
    '48': 'Pausado',
    '50': 'Pausando',
    '64': 'Concluído',
    '66': 'Concluindo',
    '68': 'Preparando',
    '128': 'Abortado',
    '256': 'Alarme',
    '512': 'Fatal',
    UNKNOWN: 'Desconhecido',
  },
  material_test_generator: {
    block_settings: 'Configurações de Bloco',
    columns: 'Colunas',
    count: 'Quantidade',
    cut: 'Cortar',
    engrave: 'Gravar',
    export: 'Exportar',
    max: 'Máx',
    min: 'Min',
    parameter: 'Parâmetro',
    preview: 'Pré-visualização',
    rows: 'Linhas',
    size: 'Tamanho (AxL)',
    spacing: 'Espaçamento',
    table_settings: 'Configurações de Tabela',
    text_settings: 'Configurações de texto',
    title: 'Gerador de Teste de Material',
  },
  menu: {
    inches: 'Polegadas',
    mm: 'mm',
  },
  message: {
    auth_error: '#820 Erro de autenticação: atualize o Beam Studio e o firmware da máquina para a versão mais recente.',
    authenticating: 'Autenticando...',
    camera: {
      abort_preview: 'Cancelar',
      camera_cable_unstable: 'Foi detectado que a câmera está transferindo fotos de forma instável. A visualização ainda pode ser realizada normalmente, mas pode haver problema de visualização lenta ou tempo limite.',
      continue_preview: 'Continuar',
      fail_to_transmit_image: '#845 Ocorreu um erro na transmissão da imagem. Por favor, reinicie sua máquina ou o Beam Studio. Se este erro persistir, siga <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">este guia</a>.',
      ws_closed_unexpectly: '#844 A conexão com a câmera da máquina foi fechada inesperadamente. Se este erro persistir, siga <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">este guia</a>.',
    },
    cancelling: 'Cancelando...',
    cant_connect_to_device: '#827 Não foi possível conectar à máquina, verifique a conexão',
    connected: 'Conectado',
    connecting: 'Conectando...',
    connectingCamera: 'Conectando a câmera...',
    connectingMachine: 'Conectando %s...',
    connectionTimeout: '#805 Tempo limite de conexão do dispositivo. Verifique o estado da rede e o indicador Wi-Fi da sua máquina.',
    device_blocked: {
      caption: 'Número de série não autorizado',
      offline: 'O número de série do seu dispositivo está restrito ao uso offline, pois parece estar desativado. Por favor, forneça o número de série da máquina (localizado na parte de trás do dispositivo) ao seu revendedor e peça para que ele entre em contato com support@flux3dp.com para ativar a autorização online. Caso deseje usar o dispositivo offline, entre em contato diretamente com support@flux3dp.com.',
      online: 'O número de série do seu dispositivo parece estar desativado. Por favor, forneça o número de série da máquina (localizado na parte de trás do dispositivo) ao seu revendedor e peça para que ele entre em contato com support@flux3dp.com para ativar a autorização do dispositivo.',
    },
    device_busy: {
      caption: 'Máquina ocupada',
      message: 'A máquina está executando outra tarefa, tente novamente mais tarde. Se parar de funcionar, reinicie a máquina.',
    },
    device_is_used: 'A máquina está sendo usada, deseja abortar a tarefa atual?',
    device_not_found: {
      caption: 'Máquina padrão não encontrada',
      message: '#812 Verifique o indicador Wi-Fi da sua máquina',
    },
    disconnected: 'Conexão instável, verifique a conexão com o dispositivo e tente novamente mais tarde',
    endingLineCheckMode: 'Saindo do modo de verificação de linha...',
    endingRawMode: 'Saindo do modo bruto...',
    enteringLineCheckMode: 'Entrando no modo de verificação de linha...',
    enteringRawMode: 'Entrando no modo bruto...',
    enteringRedLaserMeasureMode: 'Entrando no modo de medição com laser vermelho...',
    exitingRotaryMode: 'Saindo do modo rotativo...',
    getProbePosition: 'Obtendo a posição da sonda...',
    gettingLaserSpeed: 'Lendo a velocidade do laser...',
    homing: 'Efetuando homing...',
    need_password: 'É necessária senha para conectar à máquina',
    please_enter_dpi: 'Informe a resolução do seu arquivo (em mm)',
    preview: {
      adjust: 'Ajustar',
      adjust_height_tooltip: 'Clique na caixa de seleção para habilitar a edição.',
      already_performed_auto_focus: 'Você já realizou o foco automático, deseja usar os valores existentes?',
      auto_focus: 'Foco Automático',
      auto_focus_instruction: 'Por favor, mova o módulo laser acima do objeto e siga as instruções de animação para pressionar AF para focar.',
      camera_preview: 'Visualização da Câmera',
      enter_manually: 'Inserir Manualmente',
      please_enter_height: 'Por favor, insira a altura do objeto para capturar a imagem da câmera com precisão.',
    },
    promark_disconnected: '#850 Conexão do dispositivo interrompida, por favor verifique o estado da conexão.',
    redLaserTakingReference: 'Pegando referência...',
    retrievingCameraOffset: 'Lendo o deslocamento da câmera...',
    settingLaserSpeed: 'Definindo a velocidade do laser...',
    swiftray_disconnected: 'Não foi possível conectar ao backend, tentando reconectar.',
    swiftray_reconnected: 'Backend reconectado, tente reenviar o trabalho.',
    time_remaining: 'Tempo restante:',
    tryingToConenctMachine: 'Tentando conectar à máquina...',
    turningOffAirPump: 'Desligando a bomba de ar...',
    turningOffFan: 'Desligando o ventilador...',
    unable_to_find_machine: 'Não foi possível encontrar a máquina ',
    unable_to_start: '#830 Não foi possível iniciar a tarefa. Tente novamente. Se o problema persistir, entre em contato conosco com um relatório de erro:',
    unavailableWorkarea: '#804 A área de trabalho atual excede a área de trabalho desta máquina. Verifique a área de trabalho da máquina selecionada ou defina a área de trabalho em Editar > Configurações do Documento.',
    unknown_device: '#826 Não é possível conectar à máquina, verifique se o USB está conectado à máquina',
    unknown_error: '#821 O aplicativo encontrou um erro desconhecido, use Ajuda > Menu > Relatório de erros.',
    unsupport_osx_version: 'A versão atual do macOS %s pode não suportar algumas funções. Atualize para o macOS 11+.',
    unsupport_win_version: 'A versão atual do SO %s pode não suportar algumas funções. Atualize para a versão mais recente.',
    unsupported_example_file: 'O arquivo de exemplo selecionado não é suportado por esta área de trabalho.',
    uploading_fcode: 'Enviando FCode',
    usb_unplugged: 'A conexão USB foi perdida. Verifique a conexão USB',
    wrong_swiftray_version_message: 'Versão incorreta do backend (versão: {version}). Verifique se há várias instâncias do Beam Studio em execução e feche as instâncias extras antes de tentar novamente.',
    wrong_swiftray_version_title: 'Versão incorreta do backend',
  },
  monitor: {
    ask_reconnect: 'A conexão com a máquina foi interrompida. Deseja reconectar?',
    bug_report: 'Relatório de erro',
    camera: 'Câmera',
    cancel: 'Cancelar',
    confirmFileDelete: 'Tem certeza de que deseja excluir este arquivo?',
    connecting: 'Conectando, aguarde...',
    DEVICE_ERROR: 'Ocorreu um erro. Por favor, reinicie a máquina',
    download: 'Baixar',
    extensionNotSupported: 'Este formato de arquivo não é suportado',
    fileExistContinue: 'O arquivo já existe, deseja substituí-lo?',
    forceStop: 'Deseja cancelar a tarefa atual?',
    go: 'Iniciar',
    HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Sensor de fluxo de ar anômalo',
    HARDWARE_ERROR_BOTTOM_OPENED: '#903 Base aberta. Feche a base para continuar.',
    HARDWARE_ERROR_DOOR_OPENED: '#901 Feche a porta para continuar.',
    HARDWARE_ERROR_DRAWER_OPENED: '#911 Gaveta aberta',
    HARDWARE_ERROR_FIRE_DETECTED: '#912 Sensor de chama anômalo',
    HARDWARE_ERROR_HEADTYPE_MISMATCH: 'Módulo incorreto detectado. Instale o módulo correto para continuar.',
    HARDWARE_ERROR_HEADTYPE_NONE: 'Módulo não detectado. Certifique-se de que o módulo esteja instalado corretamente para continuar.',
    HARDWARE_ERROR_HEADTYPE_UNKNOWN: 'Módulo desconhecido detectado. Instale o módulo correto para continuar.',
    HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Erro de afastamento de homing',
    HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Erro Crítico: Placa-mãe offline. Contate o Suporte FLUX.',
    HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Superaquecimento. Aguarde alguns minutos.',
    HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Falha ao redefinir eixo Z',
    HARDWARE_ERROR_PRINTER_NO_RESPONSE: 'Sem resposta do módulo da impressora.',
    HARDWARE_ERROR_PROBE_SHOWED: 'Por favor, retraia a sonda.',
    HARDWARE_ERROR_PUMP_ERROR: '#900 Verifique seu tanque de água.',
    HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Nenhum módulo rotativo detectado',
    HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Erro Crítico: Subsistema sem resposta. Contate o Suporte FLUX.',
    HARDWARE_FAILURE: 'Ocorreu um erro. Reinicie a máquina',
    hour: 'h',
    left: 'esquerda',
    MAINBOARD_OFFLINE: 'Erro ao conectar à placa-mãe. Reinicie a máquina.',
    minute: 'm',
    monitor: 'MONITOR',
    NO_RESPONSE: 'Erro ao conectar à placa principal. Por favor, reinicie a máquina.',
    pause: 'Pausar',
    prepareRelocate: 'Preparando para mover localização',
    processing: 'Processando',
    record: 'Gravar',
    relocate: 'Mover',
    RESOURCE_BUSY: 'A máquina está ocupada. Se não estiver em execução, reinicie a máquina',
    resume: 'Retomar',
    savingPreview: 'Gerando miniaturas',
    second: 's',
    start: 'Iniciar',
    stop: 'Parar',
    SUBSYSTEM_ERROR: 'Erro Crítico: Falha de resposta do subsistema. Entre em contato com o suporte.',
    task: {
      BEAMBOX: 'Gravação a laser',
      'N/A': 'Modo livre',
    },
    taskTab: 'Tarefa',
    temperature: 'Temperatura',
    upload: 'Enviar',
    USER_OPERATION: 'Por favor, siga as instruções no painel do dispositivo para continuar.',
    USER_OPERATION_CHANGE_CARTRIDGE: 'Por favor, insira o cartucho correto para continuar.',
    USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Por favor, insira o cartucho para continuar.',
    USER_OPERATION_CHANGE_TOOLHEAD: 'Instale o módulo correto para continuar.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: 'Módulo incorreto detectado. Instale o módulo correto para continuar.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: 'Módulo não detectado. Certifique-se de que o módulo esteja instalado corretamente para continuar.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: 'Módulo desconhecido detectado. Instale o módulo correto para continuar.',
    USER_OPERATION_REMOVE_CARTRIDGE: 'Por favor, remova o cartucho para continuar.',
    USER_OPERATION_ROTARY_PAUSE: 'Por favor, mude para o motor rotativo.',
  },
  my_cloud: {
    action: {
      confirmFileDelete: 'Tem certeza que deseja excluir este arquivo? Esta ação não pode ser desfeita.',
      delete: 'Excluir',
      download: 'Baixar',
      duplicate: 'Duplicar',
      open: 'Abrir',
      rename: 'Renomear',
    },
    file_limit: 'Arquivo gratuito',
    loading_file: 'Carregando...',
    no_file_subtitle: 'Vá para Menu > "Arquivo" > "Salvar na nuvem"',
    no_file_title: 'Salve arquivos em Minha nuvem para começar.',
    save_file: {
      choose_action: 'Salvar arquivo:',
      input_file_name: 'Digite o nome do arquivo:',
      invalid_char: 'Caracteres inválidos:',
      save: 'Salvar',
      save_new: 'Salvar como novo arquivo',
      storage_limit_exceeded: 'Seu armazenamento em nuvem atingiu o limite superior. Exclua quaisquer arquivos desnecessários antes de salvar novos.',
    },
    sort: {
      a_to_z: 'Nome: A - Z',
      most_recent: 'Mais recentes',
      oldest: 'Mais antigos',
      z_to_a: 'Nome: Z - A',
    },
    title: 'Minha nuvem',
    upgrade: 'Atualizar',
  },
  noun_project_panel: {
    clear: 'Limpar',
    elements: 'Elementos',
    enjoy_shape_library: 'Aproveite o banco de dados de formas.',
    export_svg_title: 'Não é possível exportar SVG',
    export_svg_warning: 'Este projeto contém objetos protegidos por direitos autorais. Portanto, o Beam Studio removerá automaticamente esses objetos durante a exportação. Você ainda pode salvar seu projeto no formato Beam Studio Scene (.beam) para manter todos os seus dados. Você ainda deseja exportar?',
    learn_more: 'Saiba Mais',
    login_first: 'Faça login para desbloquear o banco de dados de formas.',
    recent: 'Recentes',
    search: 'Pesquisar',
    shapes: 'Formas',
  },
  pass_through: {
    export: 'Exportar para Área de Trabalho',
    exporting: 'Exportando...',
    guide_mark: 'Marca de Guia',
    guide_mark_desc: 'As marcas de guia serão gravadas como um ponto de referência para alinhar a obra de arte.',
    guide_mark_length: 'Comprimento:',
    guide_mark_x: 'Coordenada X:',
    height_desc: 'Defina a altura de cada seção da área de trabalho.',
    help_links: {
      ado1: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
      fbb2: 'https://support.flux3dp.com/hc/en-us/articles/11570021253263',
    },
    help_text: 'Como configurar a passagem direta para %(model)s?',
    object_length: 'Comprimento do Objeto',
    ref_layer: 'Camada de Referência',
    ref_layer_desc: 'Observe que a execução da camada de referência está configurada como 0 por padrão. Ela não será executada, mas é apenas para referência de alinhamento.',
    ref_layer_name: 'Referência',
    title: 'Modo de passagem direta',
    workarea_height: 'Área de Trabalho (Altura):',
  },
  promark_connection_test: {
    description: 'O sistema executará uma operação de luz vermelha por cerca de dois minutos para verificar se a conexão é estável.',
    health: 'Estabilidade:',
    healthy_description: 'A estabilidade é boa, mas evite mover significativamente o computador durante a gravação.',
    res_0: 'Excelente',
    res_1: 'Bom',
    res_2: 'Baixo',
    res_3: 'Muito Baixo',
    res_4: 'Extremamente Baixo',
    restart: 'Reiniciar Teste',
    start: 'Iniciar Teste',
    stop: 'Parar Teste',
    title: 'Teste de Estabilidade da Conexão',
    unhealthy_description: 'A estabilidade está muito baixa. Recomenda-se substituir o adaptador de rede ou o cabo de alimentação.',
  },
  promark_settings: {
    angle: 'Ângulo',
    bulge: 'Abaulamento',
    field: 'Campo',
    galvo_configuration: 'Configuração Galvo',
    mark: 'Marcar',
    mark_parameters: 'Parâmetros de marcação',
    offsetX: 'Deslocamento X',
    offsetY: 'Deslocamento Y',
    preview: 'Pré-visualização',
    red_dot: 'Ponto vermelho',
    scale: 'Escala',
    scaleX: 'Escala X',
    scaleY: 'Escala Y',
    skew: 'Inclinação',
    switchXY: 'Alternar X/Y',
    title: 'Configurações do Promark',
    trapezoid: 'Trapézio',
    workarea_hint: 'Você pode alterar a área de trabalho nas "Configurações do documento".',
    z_axis_adjustment: {
      section1: 'Ajuste a altura do eixo Z para ajustar o foco.',
      title: 'Ajuste do Eixo Z',
      tooltip1: 'Tente marcar um quadrado de 1x1 cm para confirmar se a distância focal atual é apropriada.',
    },
  },
  qr_code_generator: {
    error_tolerance: 'Tolerância de erro',
    error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
    invert: 'Inverter cor de fundo',
    placeholder: 'Insira um link ou texto',
    preview: 'Pré-visualização',
    title: 'Gerador de código QR',
  },
  rotary_settings: {
    circumference: 'Circunferência',
    extend_workarea: 'Expandir área de trabalho',
    mirror: 'Espelho',
    object_diameter: 'Diâmetro do Objeto',
    overlap_size: 'Tamanho da sobreposição',
    rotary_scale: 'Escala de rotação',
    split_setting: 'Configuração de divisão',
    split_setting_url: 'https://support.flux3dp.com/hc/en-us/articles/12276094010767',
    split_size: 'Tamanho da divisão',
    type: 'Tipo',
  },
  select_device: {
    auth_failure: '#811 Falha na autenticação',
    select_device: 'Selecionar Dispositivo',
    unable_to_connect: '#810 Incapaz de estabelecer uma conexão estável com a máquina',
  },
  settings: {
    anti_aliasing: 'Suavização de serrilhado',
    auto_connect: 'Selecionar automaticamente a única máquina',
    auto_switch_tab: 'Troca automática entre painel de camadas e objetos',
    autofocus_offset: 'Compensação de foco automático',
    autosave_enabled: 'Salvamento automático ativado',
    autosave_interval: 'Salvar a cada',
    autosave_number: 'Número de salvamentos automáticos',
    autosave_path: 'Local para salvamento automático',
    autosave_path_not_correct: 'Caminho especificado não encontrado.',
    blade_precut_position: 'Posição de pré-corte',
    blade_precut_switch: 'Precorte da lâmina',
    blade_radius: 'Raio da lâmina',
    bottom_up: 'De baixo para cima',
    calculation_optimization: 'Aceleração do cálculo de caminho',
    cancel: 'Cancelar',
    caption: 'Configurações',
    check_updates: 'Verificação automática de atualizações',
    close: 'Fechar',
    confirm_remove_default: 'A máquina padrão será removida.',
    confirm_reset: 'Confirmar redefinição do Beam Studio?',
    continuous_drawing: 'Desenho contínuo',
    curve_engraving_speed_limit: 'Limite de velocidade de gravação em curva',
    custom_preview_height: 'Altura de visualização personalizada',
    default_beambox_model: 'Configuração de documento padrão',
    default_borderless_mode: 'Abrir sem borda por padrão',
    default_enable_autofocus_module: 'Foco automático padrão',
    default_enable_diode_module: 'Laser de diodo padrão',
    default_font_family: 'Fonte padrão',
    default_font_style: 'Estilo de fonte padrão',
    default_laser_module: 'Módulo laser padrão',
    default_machine: 'Máquina padrão',
    default_machine_button: 'Selecionar',
    default_units: 'Unidades padrão',
    diode_offset: 'Compensação do laser de diodo',
    diode_one_way_engraving: 'Gravação unidirecional com laser de diodo',
    diode_two_way_warning: 'A emissão bidirecional de luz é mais rápida e provavelmente gera alguma imprecisão na posição da gravação a laser. Recomenda-se testar primeiro.',
    disabled: 'Desabilitado',
    done: 'Aplicar',
    enable_custom_backlash: 'Ativar compensação de folga personalizada',
    enable_low_speed: 'Ativar movimento de baixa velocidade',
    enabled: 'Habilitado',
    engraving_direction: 'Direção',
    fast_gradient: 'Otimização de velocidade',
    font_convert: 'Conversor de Texto para Trajetória',
    font_substitute: 'Substituir caracteres não suportados',
    grouped_objects: 'Objetos agrupados',
    groups: {
      ador_modules: 'Módulos Ador',
      autosave: 'Salvamento automático',
      camera: 'Câmera',
      connection: 'Conexão',
      editor: 'Editor',
      engraving: 'Gravação (digitalização)',
      general: 'Geral',
      mask: 'Máscara de área de trabalho',
      modules: 'Complementos',
      path: 'Vetor (Contornos)',
      privacy: 'Privacidade',
      text_to_path: 'Texto',
      update: 'Atualizações de software',
    },
    guess_poke: 'Procurar endereço IP da máquina',
    guides: 'Guias',
    guides_origin: 'Origem das guias',
    help_center_urls: {
      anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
      calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
      connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
      continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
      default_borderless_mode: 'https://support.flux3dp.com/hc/en-us/articles/360001104076',
      default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
      default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
      fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
      font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
      font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
      image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
      loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
      mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
      reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
      segmented_engraving: 'https://support.flux3dp.com/hc/en-us/articles/12306366019215',
      simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
      vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
    },
    high: 'Alto',
    image_downsampling: 'Qualidade da pré-visualização de bitmap',
    ip: 'Endereço IP da Máquina',
    keep_preview_result: 'Manter Resultado da Prévia',
    language: 'Idioma',
    loop_compensation: 'Compensação de loop',
    low: 'Baixo',
    low_laser_for_preview: 'Laser para Executando quadro',
    mask: 'Máscara da área de trabalho',
    medium: 'Médio',
    module_offset_2w_ir: 'Deslocamento do laser infravermelho de 2W',
    module_offset_10w: 'Compensação de laser de diodo de 10W',
    module_offset_20w: 'Deslocamento do laser de diodo de 20W',
    module_offset_printer: 'Deslocamento da impressora',
    none: 'Nenhum',
    normal: 'Alto',
    notification_off: 'Desligado',
    notification_on: 'Ligado',
    notifications: 'Notificações na área de trabalho',
    off: 'Desligado',
    on: 'Ligado',
    preview_movement_speed: 'Velocidade de visualização do movimento',
    printer_advanced_mode: 'Modo avançado da impressora',
    remove_default_machine_button: 'Remover',
    reset: 'Redefinir Beam Studio',
    reset_now: 'Redefinir Beam Studio agora',
    segmented_engraving: 'Gravação segmentada',
    share_with_flux: 'Compartilhar análises do Beam Studio',
    simplify_clipper_path: 'Otimizar caminho calculado',
    single_object: 'Objeto único',
    tabs: {
      device: 'Máquina',
      general: 'Geral',
    },
    text_path_calc_optimization: 'Otimização de cálculo de caminho de texto',
    top_down: 'De cima para baixo',
    trace_output: 'Saída de rastreamento de imagem',
    update_beta: 'Beta',
    update_latest: 'Última versão',
    vector_speed_constraint: 'Limite de velocidade',
    wrong_ip_format: 'Formatos de IP incorretos',
  },
  social_media: {
    facebook: 'Converse com FLUXers, faça perguntas e aprenda dicas!',
    instagram: 'Receba as últimas inspirações, ofertas e brindes!',
    youtube: 'Veja tutoriais do Beam Studio e ideias de artesanato.',
  },
  support: {
    no_vcredist: 'Instale o Visual C++ Redistributable 2015<br/>Que pode ser baixado em flux3dp.com',
    no_webgl: 'WebGL não é suportado. Por favor, use outros dispositivos.',
  },
  topbar: {
    alerts: {
      add_content_first: 'Por favor, adicione objetos primeiro',
      door_opened: 'Feche a tampa da porta para habilitar o laser baixo para executar o quadro.',
      fail_to_connect_with_camera: '#803 Falha ao conectar com a câmera da máquina. Reinicie seu computador ou o Beam Studio. Se este erro persistir, siga <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">este guia</a>.',
      fail_to_get_door_status: 'Certifique-se de que a tampa da porta esteja fechada para habilitar o laser baixo para executar o quadro.',
      fail_to_start_preview: '#803 Falha ao iniciar o modo de visualização. Reinicie seu computador ou o Beam Studio. Se este erro persistir, siga <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">este guia</a>.',
      headtype_mismatch: 'Módulo incorreto detectado. ',
      headtype_none: 'Módulo não detectado. ',
      headtype_unknown: 'Módulo desconhecido detectado. ',
      install_correct_headtype: 'Instale os módulos laser de diodo de 10W/20W corretos para habilitar o laser baixo para executar o quadro.',
      job_origin_unavailable: 'Configurar a origem do trabalho requer a versão de firmware 4.3.5 / 5.3.5 ou superior. Deseja atualizar o firmware agora?',
      job_origin_warning: 'Atualmente, você está usando a “posição atual” como ponto de partida. Certifique-se de mover a cabeça do laser para a posição correta para evitar colisões.',
      power_too_high: 'POTÊNCIA MUITO ALTA',
      power_too_high_confirm: 'ENTENDI',
      power_too_high_msg: 'Usar potência laser mais baixa (abaixo de 70%) estenderá a vida útil do tubo laser.\nDigite "ENTENDI" para continuar.',
      pwm_unavailable: 'O Modo de Profundidade requer a versão 4.3.4 / 5.3.4 ou superior do firmware. Gostaria de atualizar o firmware agora?',
      QcleanScene: 'Quer apagar o desenho?<br/>Isso também apagará seu histórico de desfazer!',
      start_preview_timeout: '#803 O tempo limite ocorreu ao iniciar o modo de visualização. Reinicie seu computador ou o Beam Studio. Se este erro persistir, siga <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">este guia</a>.',
    },
    frame_task: 'Executando quadro',
    hint: {
      polygon: 'Pressione as teclas + / - para aumentar / diminuir os lados.',
    },
    menu: {
      about: 'Sobre o Beam Studio',
      about_beam_studio: 'Sobre o Beam Studio',
      account: 'Conta',
      add_new_machine: 'Configuração da máquina',
      align_center: 'Alinhar ao centro',
      anti_aliasing: 'Suavização de serrilhado',
      auto_align: 'Alinhamento Automático',
      borderless_mode: 'Modo sem bordas',
      bug_report: 'Relatório de Erros',
      calibrate_beambox_camera: 'Calibrar câmera',
      calibrate_beambox_camera_borderless: 'Calibrar câmera (abrir parte inferior)',
      calibrate_camera_advanced: 'Calibrar câmera (Avançado)',
      calibrate_diode_module: 'Calibrar módulo de laser de diodo',
      calibrate_ir_module: 'Calibrar módulo infravermelho',
      calibrate_printer_module: 'Calibrar módulo de impressão',
      calibration: 'Calibrações',
      camera_calibration_data: 'Dados de calibração da câmera',
      change_logs: 'Registro de Alterações',
      clear_scene: 'Novos arquivos',
      close: 'Fechar janela',
      commands: 'Comandos',
      contact: 'Contate-nos',
      copy: 'Copiar',
      cut: 'Recortar',
      dashboard: 'Painel',
      decompose_path: 'Descompor',
      delete: 'Excluir',
      design_market: 'Mercado de Design',
      dev_tool: 'Ferramenta de depuração',
      disassemble_use: 'Desmontar',
      document_setting: 'Configurações do documento',
      document_setting_short: 'Documento',
      download_data: 'Baixar dados',
      download_log: 'Baixar Registros',
      download_log_canceled: 'Download de registros cancelado',
      download_log_error: 'Ocorreu um erro desconhecido, tente novamente mais tarde',
      duplicate: 'Duplicar',
      edit: 'Editar',
      example_files: 'Arquivos de Exemplo',
      export_BVG: 'exportar BVG',
      export_flux_task: 'exportar tarefa FLUX',
      export_JPG: 'exportar JPG',
      export_PNG: 'exportar PNG',
      export_SVG: 'exportar SVG',
      export_to: 'Exportar para...',
      file: 'Arquivo',
      fit_to_window: 'Ajustar à Janela',
      follow_us: 'Siga-nos',
      forum: 'Fórum da Comunidade',
      group: 'Agrupar',
      help: 'Ajuda',
      help_center: 'Central de ajuda',
      hide: 'Ocultar Beam Studio',
      hideothers: 'Ocultar outros',
      image_crop: 'Recortar',
      image_curve: 'Curva',
      image_invert: 'Inverter',
      image_sharpen: 'Nitidez',
      image_stamp: 'Bisel',
      image_vectorize: 'Vetorizar',
      import_acrylic_focus_probe: 'Sonda de foco em acrílico - 3mm',
      import_ador_laser_example: 'Exemplo de Ador Laser',
      import_ador_printing_example_full: 'Exemplo de impressão Ador - Cores completas',
      import_ador_printing_example_single: 'Exemplo de impressão Ador - Cor única',
      import_beambox_2_example: 'Exemplo de Beambox II',
      import_beambox_2_focus_probe: 'Sonda de Foco Beambox II - 3 mm',
      import_hello_beambox: 'Exemplo de Beambox',
      import_hello_beamo: 'Exemplo beamo',
      import_hexa_example: 'Exemplo de HEXA',
      import_material_printing_test: 'Teste de impressão de material',
      import_material_testing_cut: 'Teste de corte de material',
      import_material_testing_engrave: 'Teste de gravação de material',
      import_material_testing_line: 'Teste de linha de material',
      import_material_testing_old: 'Teste de gravação de material - Clássico',
      import_material_testing_simple_cut: 'Teste de corte de material - Simples',
      import_promark_example: 'Exemplo de Promark',
      import_promark_mopa_20w_color: 'Teste de Cor MOPA 20W',
      import_promark_mopa_60w_color: 'Teste de Cor MOPA 60W',
      import_promark_mopa_100w_color: 'Teste de Cor MOPA 100W',
      keyboard_shortcuts: 'Atalhos de teclado',
      layer_color_config: 'Configurações de Cor',
      layer_setting: 'Camada',
      link: {
        beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
        contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
        design_market: 'https://dmkt.io',
        downloads: 'https://flux3dp.com/downloads/',
        forum: 'https://www.facebook.com/groups/flux.laser/',
        help_center: 'https://helpcenter.flux3dp.com/',
        shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
      },
      log: {
        camera: 'Câmera',
        cloud: 'Nuvem',
        discover: 'Descobrir',
        hardware: 'Hardware',
        network: 'Rede',
        player: 'Reprodutor',
        robot: 'Robô',
        usb: 'USB',
        usblist: 'Lista USB',
      },
      machine_info: 'Informações da Máquina',
      machines: 'Máquinas',
      manage_account: 'Gerenciar minha conta',
      material_test: 'Teste de Material',
      minimize: 'Minimizar',
      my_account: 'Minha Conta',
      network_testing: 'Testar Configurações de Rede',
      object: 'Objeto',
      offset: 'Deslocamento',
      open: 'Abrir',
      paste: 'Colar',
      paste_in_place: 'Colar no lugar',
      path: 'Caminho',
      photo_edit: 'Imagem',
      preferences: 'Preferências',
      promark_color_test: 'Teste de Cor Promark',
      questionnaire: 'Questionário de Feedback',
      quit: 'Sair',
      recent: 'Aberto Recente',
      redo: 'Refazer',
      reload_app: 'Aplicação de recarga',
      reset: 'Redefinir',
      rotary_setup: 'Configuração Rotativa',
      rotate: 'Girar',
      samples: 'Exemplos',
      save_as: 'Salvar como...',
      save_scene: 'Salvar',
      save_to_cloud: 'Salvar na nuvem',
      scale: 'Escala',
      service: 'Serviços',
      set_as_default: 'Definir como padrão',
      show_gesture_tutorial: 'Tutorial de gestos',
      show_grids: 'Mostrar grades',
      show_layer_color: 'Usar cor da camada',
      show_rulers: 'Mostrar réguas',
      show_start_tutorial: 'Mostrar tutorial inicial',
      show_ui_intro: 'Mostrar introdução da interface',
      sign_in: 'Entrar',
      sign_out: 'Sair',
      software_update: 'Atualização de Software',
      svg_edit: 'SVG',
      switch_to_beta: 'Mudar para a versão beta',
      switch_to_latest: 'Mudar para a libertação estável',
      tools: {
        box_generator: 'Gerador de Caixas',
        code_generator: 'Gerador de código',
        material_test_generator: 'Gerador de Teste de Material',
        title: 'Ferramentas',
      },
      tutorial: 'Iniciar Tutorial de Impressão da Família Delta',
      undo: 'Desfazer',
      ungroup: 'Desagrupar',
      update: 'Verificar atualização',
      update_firmware: 'Atualizar firmware',
      upload_data: 'Enviar dados',
      using_beam_studio_api: 'Usando API do Beam Studio',
      view: 'Visualizar',
      window: 'Janela',
      zoom_in: 'Ampliar',
      zoom_out: 'Reduzir',
      zoom_with_window: 'Ajuste Automático à Janela',
    },
    preview: 'PRÉ-VISUALIZAÇÃO',
    preview_press_esc_to_stop: 'Pressione ESC para parar a pré-visualização da câmera.',
    rename_tab: 'Renomear aba',
    select_machine: 'Selecionar uma máquina',
    tag_names: {
      dxf: 'Objeto DXF',
      ellipse: 'Elipse',
      g: 'Grupo',
      image: 'Imagem',
      line: 'Linha',
      multi_select: 'Múltipla seleção',
      no_selection: 'Sem seleção',
      pass_through_object: 'Objeto de Passagem',
      path: 'Caminho',
      polygon: 'Polígono',
      rect: 'Retângulo',
      svg: 'Objeto SVG',
      text: 'Texto',
      text_path: 'Texto em Caminho',
      use: 'Objeto Importado',
    },
    task_preview: 'Visualização da trajetória',
    titles: {
      settings: 'Configurações',
    },
    untitled: 'Sem título',
  },
  topmenu: {
    credit: 'Beam Studio é possível graças ao projeto de código aberto <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> e outro <a target="_blank" href="https://flux3dp.com/credits/">software de código aberto</a>.',
    device: {
      download_log_canceled: 'Download de log cancelado',
      download_log_error: 'Ocorreu um erro desconhecido, tente novamente mais tarde',
      log: {
        usblist: 'Lista USB',
      },
      network_test: 'Testar Rede',
    },
    file: {
      all_files: 'Todos os Arquivos',
      clear_recent: 'Limpar Recentes',
      converting: 'Convertendo para Imagem...',
      fcode_files: 'Código FLUX',
      import: 'Importar',
      jpg_files: 'JPG',
      label: 'Arquivo',
      path_not_exit: 'Este caminho não parece mais existir no disco.',
      png_files: 'PNG',
      save_fcode: 'Exportar Tarefa FLUX',
      save_jpg: 'Exportar JPG',
      save_png: 'Exportar PNG',
      save_scene: 'Salvar Cena',
      save_svg: 'Exportar SVG',
      scene_files: 'Cena do Beam Studio',
      svg_files: 'SVG',
    },
    ok: 'OK',
    version: 'Versão',
  },
  tutorial: {
    ask_retry_calibration: 'Deseja calibrar a câmera novamente?',
    camera_calibration_failed: 'Falha na calibração da câmera',
    gesture: {
      click: 'Toque para selecionar o objeto.',
      drag: 'Arraste para selecionar vários objetos.',
      hold: 'Pressione sem soltar para abrir o menu de contexto.',
      pan: 'Role a tela com dois dedos.',
      zoom: 'Aproxime ou afaste dois dedos para aplicar zoom.',
    },
    links: {
      adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
    },
    look_for_machine: 'Procurando máquina para o tutorial...',
    needNewInterfaceTutorial: 'Gostaria de iniciar um tutorial para a nova interface do Beam Studio?<br/>(Você pode pular agora e iniciar mais tarde clicando em "Ajuda" > "Mostrar Introdução à Interface".)',
    needNewUserTutorial: 'Gostaria de iniciar um tutorial?<br/>(Você pode pular agora e iniciar mais tarde clicando em "Ajuda" > "Mostrar Tutorial de Início".)',
    newInterface: {
      add_new_layer: 'Adicionar nova camada',
      align_controls: 'Controles de Alinhamento',
      basic_shapes: 'Formas básicas',
      camera_preview: 'Pré-visualização da câmera',
      drag_to_sort: 'Arraste para ordenar',
      end_alert: 'Tem certeza de que deseja finalizar a introdução da nova interface do usuário?',
      flip: 'Inverter',
      group_controls: 'Controles de Agrupamento',
      layer_controls: 'Clique com o botão direito para selecionar Controles de Camada: \nDuplicar / Mesclar / Bloquear / Excluir Camadas',
      object_actions: 'Ações de Objeto',
      pen_tool: 'Ferramenta caneta',
      rename_by_double_click: 'Renomear com duplo clique',
      select_image_text: 'Selecionar imagem/texto',
      select_machine: 'Selecione uma máquina',
      shape_operation: 'Operação de Forma',
      start_work: 'Começar o trabalho',
      switch_between_layer_panel_and_object_panel: 'Alternar entre o Painel de Camadas e o Painel de Objetos',
    },
    newUser: {
      add_new_layer: 'Adicionar uma nova camada',
      adjust_focus: '2. Ajustar o foco',
      close_cover: '3. Fechar a tampa',
      drag_to_draw: 'Arraste para desenhar',
      draw_a_circle: 'Desenhe um círculo',
      draw_a_rect: 'Desenhe um retângulo',
      end_alert: 'Tem certeza que deseja finalizar o tutorial?',
      end_preview_mode: 'Sair do modo de pré-visualização',
      infill: 'Ative o preenchimento',
      please_select_wood_cutting: 'Selecione gentilmente o Predefinição "Madeira - Corte".',
      please_select_wood_engraving: 'Selecione gentilmente o Predefinição "Madeira - Gravação".',
      preview_the_platform: 'Pré-visualizar a plataforma',
      put_wood: '1. Colocar a amostra de madeira',
      send_the_file: 'Enviar o arquivo',
      set_preset_wood_cut: 'Definir predefinição: Madeira - Corte',
      set_preset_wood_engraving: 'Definir predefinição: Madeira - Gravação',
      switch_to_layer_panel: 'Mudar para o painel de camadas',
      switch_to_object_panel: 'Mudar para o painel de objetos',
      switch_to_preview_mode: 'Mudar para o modo de visualização',
    },
    next: 'PRÓXIMO',
    retry: 'Tentar novamente',
    set_connection: 'Definir Conexão',
    skip: 'Pular',
    skip_tutorial: 'Você pulou o tutorial. Você sempre pode iniciar o tutorial clicando em "Ajuda" > "Mostrar Tutorial Inicial"',
    suggest_calibrate_camera_first: 'Recomendamos aos usuários que calibrem a câmera inicialmente e refaçam o foco antes de cada visualização para obter resultados ótimos.<br/>Gostaria de confirmar para realizar a calibração agora?<br/>(Você pode pular agora e fazer mais tarde clicando em "Menu" > "Máquina" > [Sua Máquina] > "Calibrar Câmera".)',
    tutorial_complete: 'Este é o fim do tutorial. Agora é hora de criar!',
    unable_to_find_machine: 'Não foi possível encontrar a máquina para o tutorial. Deseja ir para a página de configurações de conexão, tentar novamente ou pular o tutorial?',
    welcome: 'BEM-VINDO',
  },
  update: {
    cannot_reach_internet: '#823 Servidor inacessível<br/>Verifique a conexão com a Internet',
    download: 'ATUALIZAÇÃO ONLINE',
    firmware: {
      caption: 'Uma atualização de firmware para a máquina está disponível',
      confirm: 'ENVIAR',
      firmware_too_old_update_by_sdcard: 'A versão do firmware é muito antiga. Atualize o firmware usando um cartão SD.',
      force_update_message: '#814 Atualize sua máquina para a versão mais recente do firmware.',
      latest_firmware: {
        cant_get_latest: 'Não foi possível obter informações sobre o firmware mais recente.',
        caption: 'Atualização de firmware da máquina',
        message: 'Você tem o firmware mais recente da máquina',
        still_update: 'ATUALIZAR',
      },
      message_pattern_1: '"%s" está pronto para atualização de firmware.',
      message_pattern_2: '%s Firmware v%s está agora disponível - Você tem v%s.',
      too_old_for_web: 'A versão atual do firmware da sua máquina é v%s.\nSe desejar usar a versão online do Beam Studio, atualize o firmware da máquina para a versão mais recente.',
      update_fail: '#822 Falha na atualização',
      update_success: 'Firmware atualizado com sucesso',
      upload_file: 'Carregar firmware (*.bin / *.fxfw)',
    },
    install: 'INSTALAR',
    later: 'MAIS TARDE',
    preparing: 'Preparando...',
    release_note: 'Notas da versão:',
    skip: 'Pular esta versão',
    software: {
      available_switch: 'O Beam Studio v%s está disponível agora. Você tem a v%s. Gostaria de mudar para esta versão?',
      available_update: 'O Beam Studio v%s está disponível agora. Você tem a v%s. Gostaria de baixar a atualização?',
      caption: 'Uma atualização de software para o Beam Studio está disponível',
      check_update: 'Verificar atualizações',
      checking: 'Verificando atualizações',
      downloading: 'Baixando atualizações em segundo plano, você pode clicar em "OK" para continuar seu trabalho.',
      install_or_not: 'está pronto para atualização. Deseja reiniciar e atualizar agora?',
      no: 'Não',
      no_response: 'Falha ao conectar ao servidor, verifique a configuração de rede.',
      not_found: 'Você está usando a versão mais recente do Beam Studio.',
      switch_or_not: 'está pronto para atualizar. Gostaria de reiniciar e atualizar agora?',
      switch_version: 'Mudar versão',
      switch_version_not_found: 'Versão para atualização não encontrada.',
      update_for_ador: 'A versão de software atual %s é incompatível, baixe a versão mais recente do Beam Studio para Ador.',
      yes: 'Sim',
    },
    update: 'Atualizar',
    updating: 'Atualizando...',
    upload: 'ENVIAR',
  },
  web_cam: {
    no_device: 'Não foi possível detectar o dispositivo da câmera. Por favor, reconecte a câmera e tente novamente.',
    no_permission: 'O Beam Studio não tem permissão para acessar a câmera. Certifique-se de que a permissão foi concedida ao Beam Studio nas configurações do navegador ou do sistema.',
  },
  z_speed_limit_test: {
    alert_before: 'Antes de iniciar a gravação de curvas, o sistema realizará um teste de carga para evitar a perda de passos devido ao peso excessivo.',
    alert_failed: 'O peso do objeto atual excede o limite de carga para a velocidade atual do eixo Z. Reduza a velocidade do eixo Z no painel da máquina ou substitua o objeto de gravação antes de refazer o teste.',
    ignore: 'Ignorar',
    retest: 'Testar novamente',
    testing: 'Executando teste de carga...',
  },
};

export default lang;
