// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-dialogs-AnnouncementPanel-module__announcement--NS6vS .ant-modal-header{margin-bottom:12px}._-_-packages-core-src-web-app-components-dialogs-AnnouncementPanel-module__announcement--NS6vS ._-_-packages-core-src-web-app-components-dialogs-AnnouncementPanel-module__checkbox--ngouW{margin:20px 0}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/dialogs/AnnouncementPanel.module.scss"],"names":[],"mappings":"AACE,kHACE,kBAAA,CAGF,4LACE,aAAA","sourcesContent":[".announcement {\n  :global(.ant-modal-header) {\n    margin-bottom: 12px;\n  }\n\n  .checkbox {\n    margin: 20px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"announcement": "_-_-packages-core-src-web-app-components-dialogs-AnnouncementPanel-module__announcement--NS6vS",
	"checkbox": "_-_-packages-core-src-web-app-components-dialogs-AnnouncementPanel-module__checkbox--ngouW"
};
export default ___CSS_LOADER_EXPORT___;
