import AICredit from './ai-credit.svg';
import FluxCredit from './flux-credit.svg';
import FluxPlusLogo from './flux-plus-logo.svg';
import FluxPlus from './flux-plus.svg';

export default {
  AICredit,
  FluxCredit,
  FluxPlus,
  FluxPlusLogo,
};
