// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-dialogs-CodeGenerator-Barcode-module__hidden--8SE36{display:none}._-_-packages-core-src-web-app-components-dialogs-CodeGenerator-Barcode-module__visible--q3J7s{display:inline-block}._-_-packages-core-src-web-app-components-dialogs-CodeGenerator-Barcode-module__error-span--gkKmm{vertical-align:middle;text-align:center;color:red}._-_-packages-core-src-web-app-components-dialogs-CodeGenerator-Barcode-module__container--vnmCD{display:flex;min-height:200px;justify-content:center;align-items:center}._-_-packages-core-src-web-app-components-dialogs-CodeGenerator-Barcode-module__barcode-svg--TYd6z{max-width:1000px}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/dialogs/CodeGenerator/Barcode.module.scss"],"names":[],"mappings":"AAAA,8FACE,YAAA,CAGF,+FACE,oBAAA,CAGF,kGACE,qBAAA,CACA,iBAAA,CACA,SAAA,CAGF,iGACE,YAAA,CACA,gBAAA,CACA,sBAAA,CACA,kBAAA,CAGF,mGACE,gBAAA","sourcesContent":[".hidden {\n  display: none;\n}\n\n.visible {\n  display: inline-block;\n}\n\n.error-span {\n  vertical-align: middle;\n  text-align: center;\n  color: red;\n}\n\n.container {\n  display: flex;\n  min-height: 200px;\n  justify-content: center;\n  align-items: center;\n}\n\n.barcode-svg {\n  max-width: 1000px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hidden": "_-_-packages-core-src-web-app-components-dialogs-CodeGenerator-Barcode-module__hidden--8SE36",
	"visible": "_-_-packages-core-src-web-app-components-dialogs-CodeGenerator-Barcode-module__visible--q3J7s",
	"error-span": "_-_-packages-core-src-web-app-components-dialogs-CodeGenerator-Barcode-module__error-span--gkKmm",
	"container": "_-_-packages-core-src-web-app-components-dialogs-CodeGenerator-Barcode-module__container--vnmCD",
	"barcode-svg": "_-_-packages-core-src-web-app-components-dialogs-CodeGenerator-Barcode-module__barcode-svg--TYd6z"
};
export default ___CSS_LOADER_EXPORT___;
