// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-dialogs-camera-common-ExposureSlider-module__container--t30ls{display:flex;align-items:center}._-_-packages-core-src-web-app-components-dialogs-camera-common-ExposureSlider-module__container--t30ls ._-_-packages-core-src-web-app-components-dialogs-camera-common-ExposureSlider-module__icon--NfUIs{font-size:22px;margin-right:12px}._-_-packages-core-src-web-app-components-dialogs-camera-common-ExposureSlider-module__container--t30ls ._-_-packages-core-src-web-app-components-dialogs-camera-common-ExposureSlider-module__slider--uYDm1{width:100%;margin-left:0;margin-right:0}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/dialogs/camera/common/ExposureSlider.module.scss"],"names":[],"mappings":"AAAA,wGACE,YAAA,CACA,kBAAA,CAEA,2MACE,cAAA,CACA,iBAAA,CAGF,6MACE,UAAA,CACA,aAAA,CACA,cAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n\n  .icon {\n    font-size: 22px;\n    margin-right: 12px;\n  }\n\n  .slider {\n    width: 100%;\n    margin-left: 0;\n    margin-right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_-_-packages-core-src-web-app-components-dialogs-camera-common-ExposureSlider-module__container--t30ls",
	"icon": "_-_-packages-core-src-web-app-components-dialogs-camera-common-ExposureSlider-module__icon--NfUIs",
	"slider": "_-_-packages-core-src-web-app-components-dialogs-camera-common-ExposureSlider-module__slider--uYDm1"
};
export default ___CSS_LOADER_EXPORT___;
