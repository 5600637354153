// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-Input-module__input--v-zl0{width:80px;float:right}._-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-Input-module__input--v-zl0 .ant-input-number-input{padding:5px 24px 5px 4px}._-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-Input-module__input--v-zl0 ._-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-Input-module__unit--xfA43{font-size:10px}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/views/beambox/Right-Panels/ConfigPanel/Input.module.scss"],"names":[],"mappings":"AAAA,iGACE,UAAA,CACA,WAAA,CAEA,yHACE,wBAAA,CAGF,iMACE,cAAA","sourcesContent":[".input {\n  width: 80px;\n  float: right;\n\n  :global(.ant-input-number-input) {\n    padding: 5px 24px 5px 4px;\n  }\n\n  .unit {\n    font-size: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "_-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-Input-module__input--v-zl0",
	"unit": "_-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-Input-module__unit--xfA43"
};
export default ___CSS_LOADER_EXPORT___;
