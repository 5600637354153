// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-beambox-top-bar-FrameButton-module__button--ja-qr{height:30px;opacity:.7;cursor:pointer;-webkit-app-region:no-drag}._-_-packages-core-src-web-app-components-beambox-top-bar-FrameButton-module__button--ja-qr._-_-packages-core-src-web-app-components-beambox-top-bar-FrameButton-module__disabled--G5ONg{opacity:.2;pointer-events:none}._-_-packages-core-src-web-app-components-beambox-top-bar-FrameButton-module__button--ja-qr:hover:not(._-_-packages-core-src-web-app-components-beambox-top-bar-FrameButton-module__disabled--G5ONg){opacity:1}@media screen and (max-width: 600px){._-_-packages-core-src-web-app-components-beambox-top-bar-FrameButton-module__button--ja-qr:hover:not(._-_-packages-core-src-web-app-components-beambox-top-bar-FrameButton-module__disabled--G5ONg){opacity:.7}}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/beambox/top-bar/FrameButton.module.scss","webpack://./../../packages/core/src/web/app/components/beambox/top-bar/_mixins.scss"],"names":[],"mappings":"AAEA,4FCDE,WAAA,CACA,UAAA,CACA,cAAA,CACA,0BAAA,CACA,yLACE,UAAA,CACA,mBAAA,CAEF,qMACE,SAAA,CAEF,qCACE,qMACE,UAAA,CAAA","sourcesContent":["@use './_mixins.scss' as mixins;\n\n.button {\n  @include mixins.button();\n}\n","@mixin button() {\n  height: 30px;\n  opacity: 0.7;\n  cursor: pointer;\n  -webkit-app-region: no-drag;\n  &.disabled {\n    opacity: 0.2;\n    pointer-events: none;\n  }\n  &:hover:not(.disabled) {\n    opacity: 1;\n  }\n  @media screen and (max-width: 600px) {\n    &:hover:not(.disabled) {\n      opacity: 0.7;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "_-_-packages-core-src-web-app-components-beambox-top-bar-FrameButton-module__button--ja-qr",
	"disabled": "_-_-packages-core-src-web-app-components-beambox-top-bar-FrameButton-module__disabled--G5ONg"
};
export default ___CSS_LOADER_EXPORT___;
