// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-pages-ConnectMachineIp-Hint-module__link--9BNrP{text-decoration:underline;cursor:pointer}._-_-packages-core-src-web-app-pages-ConnectMachineIp-Hint-module__link--9BNrP:hover{color:#606060}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/pages/ConnectMachineIp/Hint.module.scss"],"names":[],"mappings":"AAAA,+EACE,yBAAA,CACA,cAAA,CAEA,qFACE,aAAA","sourcesContent":[".link {\n  text-decoration: underline;\n  cursor: pointer;\n\n  &:hover {\n    color: #606060;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "_-_-packages-core-src-web-app-pages-ConnectMachineIp-Hint-module__link--9BNrP"
};
export default ___CSS_LOADER_EXPORT___;
