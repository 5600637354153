// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-dialogs-camera-Calibration-module__link--VzcLu{color:#1890ff;cursor:pointer}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/dialogs/camera/Calibration.module.scss","webpack://./../../packages/core/src/web/styles/_variables.scss"],"names":[],"mappings":"AAEA,yFACE,aCMa,CDLb,cAAA","sourcesContent":["@use '@core/styles/variables' as variables;\n\n.link {\n  color: variables.$primary-blue;\n  cursor: pointer;\n}\n","$print-default-font-color: #4a4a4a;\n$default-button-border-radius: 4px;\n$size: 200px;\n$backgroundColor: #f0f0f0;\n$sidePanelWidth: 242px;\n$winSidePanelWidth: 258px;\n$panelBorderColor: #e0e0e0;\n$topBarHeight: 40px;\n\n$primary-blue: #1890ff;\n$primary-gray: #1e1e1e;\n$secondary-gray: #7c7c7c;\n\n$status-normal: #4fbb30;\n$status-warning: #faa22d;\n$status-critical: #fe4348;\n$s3: 'https://beam-studio-web.s3.ap-northeast-1.amazonaws.com';\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": "_-_-packages-core-src-web-app-components-dialogs-camera-Calibration-module__link--VzcLu"
};
export default ___CSS_LOADER_EXPORT___;
