import type { ILang } from '@core/interfaces/ILang';

const lang: ILang = {
  alert: {
    abort: 'Abortar',
    cancel: 'Cancelar',
    caption: 'Error',
    close: 'Cerrar',
    confirm: 'Confirmar',
    dont_save: 'No guardar...',
    dont_show_again: 'No volver a mostrar',
    duplicated_preset_name: 'Duplicado del nombre preestablecido',
    error: 'UH-OH',
    info: 'INFO',
    instruction: 'Instrucción',
    learn_more: 'Aprende más',
    no: 'No',
    ok: 'OK',
    ok2: 'OK',
    oops: 'Oops...',
    retry: 'Reintentar',
    save: 'Guardar',
    stop: 'Detener',
    warning: 'ADVERTENCIA',
    yes: 'Sí',
  },
  auto_fit: {
    artwork_size: 'Tamaño de la obra',
    error_tip1: '1. ¿Está la obra de arte colocada correctamente en el material?',
    error_tip2: '2. ¿Son los contornos del material lo suficientemente claros para el reconocimiento?',
    failed_to_find_contour: 'No se encontraron contornos para el ajuste automático',
    learn_more: 'Ver tutorial.',
    learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    offset_x: 'Desplazamiento X',
    offset_y: 'Desplazamiento Y',
    pattern_size: 'Tamaño del patrón',
    position_artwork: 'Posicionar obra de arte',
    position_step1: 'Ajusta el tamaño y la posición de tu obra de arte en el patrón.',
    position_step2: 'Haz clic en "Aplicar" para aplicar el Auto Fit a este patrón.',
    preview_first: 'Por favor, realice primero la vista previa de la cámara.',
    reset_position: 'Restablecer posición',
    rotation: 'Rotar',
    select_a_pattern: 'Seleccionar un patrón',
    selected_artwork: 'Obra de arte seleccionada',
    step1: 'Elija un patrón donde desee colocar la obra de arte.',
    step2: 'Si no se encuentra ningún patrón, vuelva a previsualizar la cámara y aplique Ajuste Automático.',
    step3: 'Si aún no encuentra el patrón correcto, asegúrese de que su pieza de trabajo sea claramente reconocible y que el área de trabajo esté libre de desechos.',
    title: 'Ajuste Automático',
  },
  barcode_generator: {
    bar_height: 'Altura de barra',
    bar_width: 'Ancho de barra',
    barcode: {
      invalid_value: 'El valor no es válido para el formato seleccionado.',
    },
    font: 'Fuente',
    font_size: 'Tamaño de fuente',
    hide_text: 'Ocultar texto',
    invert_color: 'Invertir color',
    text_margin: 'Margen de texto',
  },
  beambox: {
    announcement_panel: {
      title: 'Anuncio',
    },
    banner: {
      auto_feeder: 'Modo Alimentador Automático',
      camera_preview: 'Vista Previa',
      camera_preview_borderless_mode: '(Fondo Abierto)',
      curve_engraving: 'Modo de Grabado en Curva',
      pass_through: 'Modo de Paso',
      rotary: 'Modo Rotativo',
    },
    bottom_right_panel: {
      convert_text_to_path_before_export: 'Convierte el texto en un camino...',
      export_file_error_ask_for_upload: 'No se ha podido exportar la tarea. ¿Estás dispuesto a proporcionar una escena de trabajo para desarrollar el informe de errores?',
      retreive_image_data: 'Recuperar datos de imagen...',
    },
    context_menu: {
      copy: 'Copiar',
      cut: 'Cortar',
      delete: 'Eliminar',
      duplicate: 'Duplicar',
      group: 'Agrupar',
      move_back: 'Enviar al fondo',
      move_down: 'Enviar atrás',
      move_front: 'Traer al frente',
      move_up: 'Adelantar',
      paste: 'Pegar',
      paste_in_place: 'Pegar en su lugar',
      ungroup: 'Desagrupar',
    },
    document_panel: {
      add_on: 'Complementos',
      auto_feeder: 'Alimentador automático',
      auto_feeder_scale: 'Escala de alimentador automático',
      auto_feeder_url: 'https://support.flux3dp.com/hc/en-us/articles/11688230498575',
      borderless_mode: 'Fondo abierto',
      current_position: 'Posición Actual',
      disable: 'Deshabilitar',
      document_settings: 'Configuración del documento',
      enable: 'Habilitar',
      enable_autofocus: 'Autoenfoque',
      enable_diode: 'Láser diodo',
      engrave_dpi: 'Resolución',
      frame_before_start: 'Marco antes de ejecutar',
      frame_before_start_url: 'https://support.flux3dp.com/hc/en-us/articles/11494925637135',
      high: 'Alto',
      job_origin: 'Origen del Trabajo',
      laser_source: 'Fuente de láser',
      low: 'Bajo',
      machine: 'Máquina',
      medium: 'Medio',
      notification: {
        changeFromPrintingWorkareaTitle: '¿Desea convertir las capas de impresión en capas láser?',
      },
      origin: 'Origen',
      pass_through: 'Paso directo',
      pass_through_height_desc: 'Ingrese la longitud del objeto para extender el área de trabajo.',
      rotary_mode: 'Rotatorio',
      scale: 'Escala',
      start_from: 'Comenzar Desde',
      start_position: 'Posición de Inicio',
      start_work_button: 'Botón Ejecutar',
      ultra: 'Ultra Alto',
      workarea: 'Área de trabajo',
    },
    image_trace_panel: {
      back: 'Volver',
      brightness: 'Brillo',
      cancel: 'Cancelar',
      contrast: 'Contraste',
      next: 'Siguiente',
      okay: 'Bien',
      threshold: 'Umbral',
      tuning: 'Parámetros',
    },
    layer_color_config_panel: {
      add: 'Añadir',
      add_config: 'Agregar el color.',
      color: 'Color',
      default: 'Restablecer el valor predeterminado',
      in_use: 'Este color está en uso.',
      layer_color_config: 'Configuraciones de color de las capas',
      no_input: 'Por favor, introduce un código de color hexadecimal válido.',
      power: 'Power',
      repeat: 'Ejecutar',
      speed: 'Velocidad',
      sure_to_delete: '¿Está seguro de eliminar este ajuste de color?Are you sure to delete this color setting ?',
      sure_to_reset: 'Perderás todos los parámetros personalizados, ¿estás seguro de volver a los valores por defecto?',
    },
    left_panel: {
      borderless_blind_area: 'Área no grabada',
      borderless_preview: 'Vista previa de la cámara en modo sin bordes',
      diode_blind_area: 'Área ciega de adición de láser híbrido',
      ellipse: 'Elipse',
      image: 'Imagen',
      label: {
        adjust_height: 'Ajustar altura',
        array: 'Gama',
        boxgen: 'Boxgen',
        choose_camera: 'Cámara',
        clear_preview: 'Vista previa clara',
        cursor: 'Seleccionar',
        curve_engraving: {
          clear_area: 'Borrar área seleccionada',
          exit: 'Salir',
          preview_3d_curve: 'Vista previa del grabado de curvas',
          select_area: 'Seleccionar área',
          title: 'Grabado de curvas',
        },
        end_preview: 'Finalizar vista previa',
        line: 'Línea',
        live_feed: 'Transmisión en vivo',
        my_cloud: 'Mi nube',
        oval: 'Óvalo',
        pass_through: 'Paso directo',
        pen: 'Pluma',
        photo: 'Imagen',
        polygon: 'Polígono',
        preview: 'Vista previa de la cámara',
        qr_code: 'Código QR',
        rect: 'Rectángulo',
        shapes: 'Elementos',
        text: 'Texto',
        trace: 'Imagen de rastro',
      },
      line: 'Línea',
      rectangle: 'Rectángulo',
      text: 'Texto',
      unpreviewable_area: 'Área ciega',
    },
    network_testing_panel: {
      average_response: 'Tiempo de respuesta promedio',
      cannot_connect_1: '#840 Fallo en la conexión con el IP del objetivo.',
      cannot_connect_2: '#840 Fallo en la conexión a la IP de destino. Por favor, asegúrate de que el objetivo está en la misma red.',
      cannot_get_local: 'El acceso a la dirección IP local falló.',
      connection_quality: 'Calidad de la conexión',
      device_not_on_list: '#842 La máquina no está en la lista, pero la calidad de la conexión es >70 y el tiempo de respuesta promedio es <100ms',
      empty_ip: '#818 Por favor, introduce primero la IP del dispositivo objetivo.',
      end: 'Fin',
      fail_to_start_network_test: '#817 Fallo al iniciar las pruebas de red.',
      hint_connect_camera_timeout: '¿El tiempo de espera se produce al iniciar la vista previa de la cámara?',
      hint_connect_failed_when_sending_job: '¿Falló la conexión al enviar un trabajo?',
      hint_device_often_on_list: 'La máquina a menudo no se encuentra en la lista...',
      insert_ip: 'Dirección IP del dispositivo de destino:',
      invalid_ip: '#818 Dirección IP inválida',
      ip_startswith_169: '#843 La dirección IP de la máquina comienza con 169.254',
      link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
      link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
      link_device_often_on_list: 'https://tupport.flux3dp.com/hc/en-us/articles/360001841636',
      linux_permission_hint: 'Este error suele ocurrir debido a permisos insuficientes.\nPor favor ejecute "sudo beam-studio --no-sandbox" en la terminal para obtener permisos y realizar pruebas de red.',
      local_ip: 'Dirección IP local:',
      network_testing: 'Prueba de la red',
      network_unhealthy: '#841 Calidad de la conexión <70 o tiempo de respuesta promedio >100ms',
      start: 'Iniciar',
      test_completed: 'Prueba completada',
      test_fail: 'La prueba falló',
      testing: 'Probando red...',
    },
    object_panels: {
      lock_desc: 'Preservar la relación entre anchura y altura (SHIFT)',
      text_to_path: {
        check_thumbnail_warning: 'Algunos textos fueron cambiados a otras fuentes cuando se analizan los textos en las rutas y algunos caracteres no se convierten normalmente.\nPor favor, comprueba otra vez la imagen de vista previa antes de enviar la tarea.',
        error_when_parsing_text: 'Error al convertir texto a ruta',
        font_substitute_pop: 'Su texto contiene caracteres que no son compatibles con la fuente actual. <br/>¿Le gustaría usar <strong>%s</strong> como sustituto?',
        retry: 'Por favor, inténtelo de nuevo más tarde o elija otra fuente',
        use_current_font: 'Usar fuente actual',
      },
      wait_for_parsing_font: 'Fuente de análisis... Por favor, espera un segundo.',
    },
    path_preview: {
      current_position: 'Posición actual',
      cut_distance: 'Distancia de Corte',
      cut_time: 'Tiempo de Corte',
      end_preview: 'Finalizar vista previa',
      estimated_time: 'Tiempo Total Estimado',
      invert: 'Invertir',
      pause: 'Pausa',
      play: 'Reproducir',
      play_speed: 'Velocidad Reproducción',
      preview_info: 'Vista Previa',
      rapid_distance: 'Distancia de Movimientos Rápidos',
      rapid_time: 'Tiempo de Movimiento Rápido',
      remark: '* Toda la información es el valor estimado de referencia.',
      size: 'Tamaño',
      start_here: 'Empieza aqui',
      stop: 'Detener',
      travel_path: 'Ruta de Movimientos Rápidos',
    },
    photo_edit_panel: {
      aspect_ratio: 'Relación',
      brightness: 'Brillo',
      brightness_and_contrast: 'Brillo / Contraste',
      cancel: 'Cancelar',
      circumference: 'Circunferencia',
      compare: 'Comparar',
      contrast: 'Contraste',
      crop: 'Crop',
      curve: 'Curva',
      diameter: 'Diámetro',
      free: 'Libre',
      invert: 'Invertir el color',
      okay: 'Bien',
      original: 'Tamaño Org.',
      phote_edit: 'Edición de fotos',
      processing: 'Procesando',
      radius: 'Radio',
      rotary_warped: 'Deformado Rotativo',
      rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
      sharpen: 'Afila',
      sharpness: 'Nitidez',
      start: 'Comienza',
      warp: 'Deformar',
    },
    popup: {
      ai_credit: {
        buy_link: 'https://member.flux3dp.com/en-US/credit',
        go: 'Ir',
        insufficient_credit: 'Te quedaste sin creditos',
        insufficient_credit_msg: 'No puede usar %s Vaya al centro de miembros y compre AI Credit.',
        relogin_to_use: 'Por favor, vuelva a iniciar sesión para usar esta función.',
      },
      auto_feeder_origin: 'Utilice la vista previa de encuadre para verificar la trayectoria y el área del cabezal láser, luego ajuste la ubicación del material o la posición de inicio del grabado según los resultados para evitar colisiones durante el proceso de grabado.',
      auto_switch_tab: {
        message: 'Se ha añadido una nueva opción en Preferencias para cambiar automáticamente entre el panel de Capas y el panel de Objetos. Esta opción está desactivada por defecto. ¿Desea habilitar el cambio automático ahora? <br/>Puede cambiar esta configuración en cualquier momento en Preferencias.',
        title: 'Cambio Automático de Panel de Capas y Objetos',
      },
      backend_connect_failed_ask_to_upload: '#802 Los errores siguen ocurriendo cuando se intenta conectar con el backend. ¿Quieres tubir tu registro de informe de errores?',
      backend_error_hint: 'Es posible que las funciones no estén funcionando correctamente debido a un error en el backend.',
      both_power_and_speed_too_high: 'El uso de una menor potencia de láser extenderá la vida útil del tubo láser.\nAdemás, una velocidad demasiado alta a esta resolución puede reducir la calidad del grabado de sombreado.',
      bug_report: 'Informe de error',
      change_workarea_before_preview: 'El área de trabajo de %s no coincide con el área de trabajo configurada actualmente. ¿Quiere cambiar el área de trabajo actual?',
      convert_to_path_fail: 'No se ha podido convertir a la ruta.',
      dxf_bounding_box_size_over: 'El tamaño del dibujo está fuera del área de trabajo. Por favor, acerca tu dibujo al origen en tu software de CAD, o asegúrate de que la unidad esté ajustada correctamente.',
      dxf_version_waring: 'La versión de este archivo DXF no es la 2013, podría haber potenciales problemas de incompatibilidad.',
      facebook_group_invitation: {
        already_joined: 'Ya estoy inscrito',
        join_now: 'Únete ahora',
        later: 'Tal vez más tarde',
        message: 'Únete a nuestro grupo oficial de Facebook para conectar con otros usuarios de láser FLUX, hablar de los láseres FLUX, compartir ilustraciones de láser y estar al día de las últimas actualizaciones de nuestros productos. ¡Estamos deseando verte por allí!',
        title: 'Únete al grupo oficial de usuarios de FLUX',
      },
      import_file_contain_invalid_path: '#808 El archivo SVG importado contiene una ruta de imagen inválida. Por favor, asegúrese de que todos los archivos de imagen existen o incrustar la imagen en el archivo',
      import_file_error_ask_for_upload: 'No se importó el archivo SVG. ¿Estás dispuesto a proporcionar el archivo para desarrollar el equipo para el informe de errores?',
      layer_by_color: 'Color',
      layer_by_layer: 'Capa',
      loading_image: 'Cargando imagen, por favor espera...',
      more_than_two_object: 'Demasiados objetos. Sólo soporte para 2 objetos',
      mouse: 'Ratón',
      no_support_text: 'Beam Studio no soporta actualmente la etiqueta de texto. Por favor, transfiere el texto a la ruta antes de importarlo.',
      nolayer: 'Una sola capa',
      not_support_object_type: 'No apoyar el tipo de objeto',
      or_turn_off_borderless_mode: ' O desactivar el modo Abrir fondo.',
      pdf2svg: {
        error_pdf2svg_not_found: '#825 Error: Comando pdf2svg no encontrado. Por favor, instala pdf2svg con tu gestor de paquetes (por ejemplo, "yum install pdf2svg" o "apt-get install pdf2svg").',
        error_when_converting_pdf: '#824 Error al convertir PDF a SVG:',
      },
      progress: {
        calculating: 'Calculando',
        uploading: 'Cargando',
      },
      questionnaire: {
        caption: 'Ayúdanos a completar el cuestionario',
        message: 'Ayúdanos a completar el cuestionario para mejorar Beam Studio.',
        no_questionnaire_available: 'No hay ningún cuestionario disponible en este momento.',
        unable_to_get_url: 'No se pudo obtener el enlace al cuestionario a través de Internet. Confirme su conexión a Internet.',
      },
      recommend_downgrade_software: 'Detectamos una versión más antigua del firmware. Estamos abordando activamente problemas de compatibilidad, pero por ahora, recomendamos volver a <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>.',
      recommend_upgrade_firmware: 'Hemos detectado una versión de firmware más antigua. Estamos abordando activamente problemas de compatibilidad, pero por ahora, recomendamos actualizar al firmware más reciente.',
      save_unsave_changed: '¿Quieres guardar los cambios no guardados?',
      select_at_least_two: 'Selecciona dos objetos para proceder',
      select_first: 'Selecciona un objeto primero.',
      select_import_method: 'Selecciona el estilo de capas:',
      select_import_module: 'Seleccionar módulo:',
      sentry: {
        message: '¿Acepta cargar automáticamente información relevante al equipo de desarrollo cuando se produzcan errores?',
        title: 'Hagamos juntos Beam Studio mejor',
      },
      should_update_firmware_to_continue: '#814 tu firmware no es compatible con esta versión de Beam Studio. Por favor, actualiza el firmware para continuar. (Menú > Máquina > [tu máquina] > Actualizar el firmware)',
      speed_too_high_lower_the_quality: 'El uso de una velocidad demasiado alta en esta resolución puede resultar en una menor calidad de grabado de sombreado.',
      still_continue: 'Continuar',
      successfully_uploaded: 'La carga del archivo se realizó con éxito.',
      svg_1_1_waring: 'La versión de este archivo SVG es la v 1.1, podría haber potenciales problemas de incompatibilidad.',
      svg_image_path_waring: 'Este archivo SVG contiene <image> cargando desde la ruta del archivo. Esto puede causar fallos al cargar.\nPara evitar este riesgo, por favor, utiliza la imagen incrustada al exportar SVG.',
      text_to_path: {
        caption: 'Convertidor de texto a trayectoria 2.0',
        message: '¡Beam Studio ahora presenta un nuevo convertidor de texto a trayectoria (Convertidor 2.0), que produce resultados más confiables! ¿Le gustaría cambiar a él ahora? \nTambién puede ajustar esta configuración más tarde en la sección "Convertidor de texto a trayectoria" dentro de las preferencias.',
      },
      too_fast_for_auto_feeder: 'El uso de una velocidad demasiado alta en capas que contienen objetos de trazado puede reducir la precisión del corte.\nNo recomendamos utilizar una velocidad superior a %(limit)s al cortar con el Auto Feeder.',
      too_fast_for_auto_feeder_and_constrain: 'Las siguientes capas: %(layers)s \ncontienen trazados vectoriales y tienen una velocidad superior a %(limit)s.\nLa velocidad de corte de los trazados vectoriales con Auto Feeder se limitará a %(limit)s.\nPuedes eliminar este límite en la Configuración de Preferencias.',
      too_fast_for_curve: 'El uso de velocidades demasiado altas para capas que contienen objetos de grabado de curvas puede reducir la precisión del grabado.\nRecomendamos mantener la velocidad por debajo de %(limit)s para obtener resultados óptimos.',
      too_fast_for_curve_and_constrain: 'Las siguientes capas: %(layers)s superan el límite de velocidad de %(limit)s.\nLa velocidad de grabado para objetos de curva se limitará a %(limit)s. Puedes eliminar este límite en la configuración de preferencias.',
      too_fast_for_path: 'El uso de una velocidad demasiado alta en capas que contienen objetos de trayectoria puede resultar en una menor precisión al cortar.\nNo recomendamos utilizar una velocidad superior a %(limit)s al cortar.',
      too_fast_for_path_and_constrain: 'Capas siguientes: %(layers)s \nLa velocidad de corte de los objetos de trayectoria vectorial se limitará a %(limit)s.\nLa velocidad de corte de los objetos de trayectoria vectorial se limitará a %(limit)s.\nPuedes eliminar este límite en la Configuración de las Preferencias.',
      touchpad: 'TouchPad',
      ungroup_use: 'Esto desagrupará los DXF o SVG importados. Debido a que el archivo puede contener una gran cantidad de elementos, puede llevar tiempo desagruparlo. ¿Estás seguro de proceder?',
      upload_failed: '#819 La carga del archivo falló.',
      upload_file_too_large: '#819 El archivo es demasiado grande para subirlo.',
      vectorize_shading_image: 'Las imágenes con gradientes tardan más tiempo en ser trazadas y son propensas al ruido. Por favor, apaga el gradiente de la imagen antes de ejecutarla.',
    },
    rating_panel: {
      description: 'Si te gusta Beam Studio, agradeceríamos que compartieras tu experiencia.',
      thank_you: 'Gracias',
      title: '¿Te gusta Beam Studio?',
    },
    right_panel: {
      laser_panel: {
        advanced: 'Avanzado',
        backlash: 'Holgura',
        bi_directional: 'Bidireccional',
        by: 'Bajar Enfoque En',
        ce_z_high_speed: 'Eje Z de alta velocidad',
        ce_z_high_speed_link: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
        color_adjustment: 'Ajuste de canal',
        color_adjustment_short: 'Canal',
        color_strength: 'Intensidad',
        cross_hatch: 'Sombreado Cruzado',
        custom_preset: 'Personalizado',
        cut: 'Cortar',
        diode: 'Láser de diodo',
        do_not_adjust_default_para: 'Los preajustes no pueden ser ajustados.',
        dottingTime: 'Tiempo de punteado',
        dropdown: {
          inches: {
            abs_engraving: 'ABS - Grabado',
            acrylic_3mm_cutting: 'Acrílico - 1/8″ Corte',
            acrylic_5mm_cutting: 'Acrílico - 3/16″ Corte',
            acrylic_8mm_cutting: 'Acrílico - 1/4″ Corte',
            acrylic_10mm_cutting: 'Acrílico - 3/8″ Corte',
            acrylic_engraving: 'Acrílico - Grabado',
            acrylic_printing: 'Acrílico - Impresión',
            aluminum_engraving: 'Aluminio - Grabado',
            aluminum_light: 'Aluminio (claro)',
            bamboo_printing: 'Bambú - Impresión',
            black_abs: 'ABS negro',
            black_acrylic_3mm_cutting: 'Acrílico negro - 1/8″ Corte',
            black_acrylic_5mm_cutting: 'Acrílico negro - 3/16″ Corte',
            black_acrylic_engraving: 'Acrílico negro - Grabado',
            brass_dark: 'Latón (oscuro)',
            brass_engraving: 'Latón - Grabado',
            brass_light: 'Latón (claro)',
            canvas_printing: 'Lienzo - Impresión',
            cardstock_printing: 'Cartulina - Impresión',
            copper: 'Cobre',
            cork_printing: 'Corcho - Impresión',
            denim_1mm_cutting: 'Mezclilla - 1/32″ Corte',
            fabric_3mm_cutting: 'Tejido - 1/8″ Corte',
            fabric_5mm_cutting: 'Tejido - 3/16″ Corte',
            fabric_engraving: 'Tejido - Grabado',
            fabric_printing: 'Tejido - Impresión',
            flat_stone_printing: 'Piedra plana - Impresión',
            glass_bw_engraving: 'Vidrio - Grabado',
            glass_printing: 'Vidrio - Impresión',
            gloss_leather_printing: 'Piel brillante - Impresión',
            gold_engraving: 'Oro - Grabado',
            iron_engraving: 'Hierro - Grabado',
            leather_3mm_cutting: 'Cuero - 1/8″ Corte',
            leather_5mm_cutting: 'Cuero - 3/16″ Corte',
            leather_engraving: 'Cuero - Grabado',
            mdf_3mm_cutting: 'MDF de Nueva Zelanda - 1/8″ Corte',
            mdf_5mm_cutting: 'MDF de Nueva Zelanda - 3/16″ Corte',
            mdf_engraving: 'MDF de Nueva Zelanda - Grabado',
            metal_bw_engraving: 'Metal - Grabado',
            opaque_acrylic: 'Acrílico opaco',
            pc_printing: 'PC - Impresión',
            rubber_bw_engraving: 'Goma - Grabado',
            silver_engraving: 'Plata - Grabado',
            stainless_steel_bw_engraving_diode: 'Metal - Grabado (Láser Diodo)',
            stainless_steel_dark: 'Acero inoxidable (oscuro)',
            stainless_steel_engraving: 'Acero inoxidable - Grabado',
            stainless_steel_light: 'Acero inoxidable (claro)',
            stainless_steel_printing: 'Acero inoxidable - Impresión',
            steel_engraving_spray_engraving: 'Metal - Grabado',
            stone: 'Piedra',
            ti_engraving: 'Titanio - Grabado',
            titanium_dark: 'Titanio (oscuro)',
            titanium_light: 'Titanio (claro)',
            white_abs: 'ABS blanco',
            wood_3mm_cutting: 'Madera - 1/8″ Corte',
            wood_5mm_cutting: 'Madera - 3/16″ Corte',
            wood_7mm_cutting: 'Madera - 1/4″ Corte',
            wood_8mm_cutting: 'Madera - 1/4″ Corte',
            wood_10mm_cutting: 'Madera - 3/8″ Corte',
            wood_engraving: 'Madera - Grabado',
            wood_printing: 'Madera - Impresión',
          },
          mm: {
            abs_engraving: 'ABS - Grabado',
            acrylic_3mm_cutting: 'Acrílico - 3mm Corte',
            acrylic_5mm_cutting: 'Acrílico - 5mm Corte',
            acrylic_8mm_cutting: 'Acrílico - 8mm Corte',
            acrylic_10mm_cutting: 'Acrílico - 10mm Corte',
            acrylic_engraving: 'Acrílico - Grabado',
            acrylic_printing: 'Acrílico - Impresión',
            aluminum_engraving: 'Aluminio - Grabado',
            aluminum_light: 'Aluminio (claro)',
            bamboo_printing: 'Bambú - Impresión',
            black_abs: 'ABS negro',
            black_acrylic_3mm_cutting: 'Acrílico negro - 3mm Corte',
            black_acrylic_5mm_cutting: 'Acrílico negro - 5mm Corte',
            black_acrylic_engraving: 'Acrílico negro - Grabado',
            brass_dark: 'Latón (oscuro)',
            brass_engraving: 'Latón - Grabado',
            brass_light: 'Latón (claro)',
            canvas_printing: 'Lienzo - Impresión',
            cardstock_printing: 'Cartulina - Impresión',
            copper: 'Cobre',
            cork_printing: 'Corcho - Impresión',
            denim_1mm_cutting: 'Mezclilla - 1mm Corte',
            fabric_3mm_cutting: 'Tejido - 3mm Corte',
            fabric_5mm_cutting: 'Tejido - 5mm Corte',
            fabric_engraving: 'Tejido - Grabado',
            fabric_printing: 'Tejido - Impresión',
            flat_stone_printing: 'Piedra plana - Impresión',
            glass_bw_engraving: 'Vidrio - Grabado',
            glass_printing: 'Vidrio - Impresión',
            gloss_leather_printing: 'Piel brillante - Impresión',
            gold_engraving: 'Oro - Grabado',
            iron_engraving: 'Hierro - Grabado',
            leather_3mm_cutting: 'Cuero - 3mm Corte',
            leather_5mm_cutting: 'Cuero - 5mm Corte',
            leather_engraving: 'Cuero - Grabado',
            mdf_3mm_cutting: 'MDF de Nueva Zelanda - 3mm Corte',
            mdf_5mm_cutting: 'MDF de Nueva Zelanda - 5mm Corte',
            mdf_engraving: 'MDF de Nueva Zelanda - Grabado',
            metal_bw_engraving: 'Metal - Grabado',
            opaque_acrylic: 'Acrílico opaco',
            pc_printing: 'PC - Impresión',
            rubber_bw_engraving: 'Goma - Grabado',
            silver_engraving: 'Plata - Grabado',
            stainless_steel_bw_engraving_diode: 'Metal - Grabado (Láser Diodo)',
            stainless_steel_dark: 'Acero inoxidable (oscuro)',
            stainless_steel_engraving: 'Acero inoxidable - Grabado',
            stainless_steel_light: 'Acero inoxidable (claro)',
            stainless_steel_printing: 'Acero inoxidable - Impresión',
            steel_engraving_spray_engraving: 'Metal - Grabado',
            stone: 'Piedra',
            ti_engraving: 'Titanio - Grabado',
            titanium_dark: 'Titanio (oscuro)',
            titanium_light: 'Titanio (claro)',
            white_abs: 'ABS blanco',
            wood_3mm_cutting: 'Madera - 3mm Corte',
            wood_5mm_cutting: 'Madera - 5mm Corte',
            wood_7mm_cutting: 'Madera - 7mm Corte',
            wood_8mm_cutting: 'Madera - 8mm Corte',
            wood_10mm_cutting: 'Madera - 10mm Corte',
            wood_engraving: 'Madera - Grabado',
            wood_printing: 'Madera - Impresión',
          },
          parameters: 'Preajustes',
          save: 'Añade los parámetros actuales',
        },
        engrave: 'Grabar',
        existing_name: 'Se ha utilizado este nombre.',
        fill_angle: 'Ángulo de Relleno',
        fill_interval: 'Intervalo de Relleno',
        fill_setting: 'Configuración de Relleno',
        filled_path_only: 'Solo para rutas de relleno',
        focus_adjustment: 'Ajuste del enfoque',
        frequency: 'Frecuencia',
        gradient_only: 'Solo para imágenes degradadas',
        halftone: 'Semitono',
        halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
        height: 'La altura del objeto',
        ink_saturation: 'Saturación',
        ink_type: {
          normal: 'Tinta ordinaria',
          text: 'Tipo de tinta',
          UV: 'Tinta UV',
        },
        laser_speed: {
          fast: 'Rápido',
          max: 300,
          min: 3,
          slow: 'Lento',
          step: 0.1,
          text: 'Velocidad',
          unit: 'mm/s',
        },
        low_power_warning: 'Es posible que la potencia del láser más baja (menos del 10 %) no emita la luz del láser.',
        low_speed_warning: 'La velocidad baja puede causar quemaduras en el material.',
        lower_focus: 'Bajar Enfoque',
        lower_focus_desc: 'Bajar la altura del enfoque a una distancia específica después de enfocar para mejorar el rendimiento de corte.',
        module: 'Módulo',
        more: 'Gestionar',
        multi_layer: 'Capas múltiples',
        para_in_use: 'Este parámetro está siendo utilizado.',
        parameters: 'Parámetros',
        power: {
          high: 'Alto',
          low: 'Bajo',
          max: 100,
          min: 1,
          step: 0.1,
          text: 'Power',
        },
        preset_management: {
          add_new: 'Agregar Nuevo',
          delete: 'Eliminar',
          export: 'Exportar',
          export_preset_title: 'Exportar preajustes',
          import: 'Importar',
          laser: 'Láser',
          lower_focus_by: 'Bajar el Foco en',
          new_preset_name: 'Nuevo nombre preestablecido',
          preset: 'Preajuste',
          print: 'Imprimir',
          reset: 'Reiniciar',
          save_and_exit: 'Guardar y Salir',
          show_all: 'Mostrar Todo',
          sure_to_import_presets: 'Esto cargará la disposición de los ajustes preestablecidos y sobrescribirá los parámetros personalizados, ¿está seguro de que desea continuar?',
          sure_to_reset: 'Esto eliminará sus parámetros personalizados y restablecerá todos los ajustes preestablecidos, ¿está seguro de que desea continuar?',
          title: 'Administrar Parámetros',
          wobble_diameter: 'Diámetro de oscilación',
          wobble_step: 'Tamaño del paso de oscilación',
        },
        preset_setting: 'Ajuste de parámetros (%s)',
        presets: 'Preestablecido',
        print_multipass: 'Multipaso',
        promark_speed_desc: 'Los parámetros de velocidad no se aplican a imágenes con degradados.',
        pulse_width: 'Anchura de Pulso',
        pwm_advanced_desc: 'Establezca una potencia mínima para el modo de profundidad.',
        pwm_advanced_hint: 'Esta configuración se aplica específicamente a imágenes de gradiente cuando se utiliza el modo de profundidad.',
        pwm_advanced_setting: 'Configuración de Potencia en Modo de Profundidad',
        repeat: 'Número de Pasadas',
        single_color: 'Color Único',
        single_color_desc: 'Solo aplicable a la capa de color completo y no se puede usar con la capa de color único expandido.',
        slider: {
          fast: 'Rápido',
          high: 'Alto',
          low: 'Bajo',
          regular: 'Regular',
          slow: 'Lento',
          very_fast: 'Más Rápido',
          very_high: 'Máximo',
          very_low: 'Mínimo',
          very_slow: 'Más Lento',
        },
        speed: 'Velocidad',
        speed_constrain_warning: 'La velocidad de corte de los objetos de trayectoria vectorial se limitará a %(limit)s. Puede eliminar este límite en Configuración de preferencias.',
        speed_constrain_warning_auto_feeder: 'La velocidad de corte de los objetos Alimentador Automático se limitará a %(limit)s. Puede eliminar este límite en la Configuración de Preferencias.',
        speed_constrain_warning_curve_engraving: 'La velocidad de grabado de curvas se limitará a %(limit)s. Puedes eliminar este límite en la configuración de preferencias.',
        stepwise_focusing: 'Enfoque Paso a Paso',
        stepwise_focusing_desc: 'Bajar gradualmente la distancia de enfoque según la altura del objeto durante cada número de pasadas.',
        strength: 'Poder',
        times: 'veces',
        various_preset: 'Varios preajustes',
        white_ink: 'Tinta Blanca',
        white_ink_settings: 'Configuración de Tinta Blanca',
        wobble: 'Oscilación',
        wobble_desc: 'Crea pequeños círculos a lo largo del camino, lo que es beneficioso para tareas de corte. (Solo para objetos de ruta.)',
        wobble_diameter: 'Diámetro',
        wobble_step: 'Tamaño del paso',
        z_step: 'Paso Z',
      },
      layer_panel: {
        current_layer: 'Capa actual',
        layer1: 'Capa 1',
        layer_bitmap: 'Mapa de bits',
        layer_cutting: 'Corte',
        layer_engraving: 'Grabado',
        layers: {
          del: 'Eliminar la capa',
          dupe: 'Duplicar la capa',
          fullColor: 'Color completo',
          layer: 'Capa',
          layers: 'Capas',
          lock: 'Bloquear capa',
          merge_all: 'Fusionar todo',
          merge_down: 'Fusionarse',
          merge_selected: 'Fusionar capas seleccionadas',
          move_down: 'Mover la capa hacia abajo',
          move_elems_to: 'Mover los elementos a:',
          move_selected: 'Mover los elementos seleccionados a una capa diferente',
          move_up: 'Mover la capa hacia arriba',
          new: 'Nueva capa',
          rename: 'Renombrar la capa',
          splitFullColor: 'Expandir capa',
          switchToFullColor: 'Cambiar a capa a color completo',
          switchToSingleColor: 'Cambiar a capa a color único',
          unlock: 'Desbloquear',
        },
        move_elems_to: 'Mover los elementos a:',
        notification: {
          dupeLayerName: '¡Ya hay una capa llamada así!',
          enterNewLayerName: 'Por favor, introduce el nuevo nombre de la capa',
          enterUniqueLayerName: 'Por favor, introduce un nombre de capa único',
          layerHasThatName: 'La capa ya tiene ese nombre',
          mergeLaserLayerToPrintingLayerMsg: 'Ten en cuenta que si completas este procedimiento, se eliminarán y ajustarán las configuraciones de la capa láser según la capa actual.',
          mergeLaserLayerToPrintingLayerTitle: '¿Deseas fusionar las capas en una capa de impresión?',
          mergePrintingLayerToLaserLayerMsg: 'Ten en cuenta que si completas este procedimiento, se eliminarán y ajustarán las configuraciones de color de la capa de impresión según la capa actual.',
          mergePrintingLayerToLaserLayerTitle: '¿Desea fusionar estas capas en una capa de láser?',
          moveElemFromPrintingLayerMsg: 'Tenga en cuenta que si completa esta operación, la configuración de color del elemento seleccionado se eliminará y se establecerá según %s.',
          moveElemFromPrintingLayerTitle: '¿Mover el elemento seleccionado a %s y convertirlo en un elemento láser?',
          moveElemToPrintingLayerMsg: 'Tenga en cuenta que si completa esta operación, la configuración del elemento seleccionado se eliminará y se establecerá según %s.',
          moveElemToPrintingLayerTitle: '¿Mover el elemento seleccionado a %s y convertirlo en un elemento de impresión?',
          newName: 'NUEVO NOMBRE',
          QmoveElemsToLayer: "¿Mover los elementos seleccionados a la capa '%s'?",
          splitColorMsg: 'Tenga en cuenta que si continúa con esta operación, no podrá volver a las capas de color originales.',
          splitColorTitle: '¿Expandir la capa seleccionada en capas CMYK?',
        },
      },
      object_panel: {
        actions_panel: {
          ai_bg_removal: 'Background Removal',
          ai_bg_removal_reminder: 'Presionar el botón usará inmediatamente 0.2 Credit, ¿quieres continuar?',
          ai_bg_removal_short: 'Elim. Fondo',
          array: 'Gama',
          auto_fit: 'Ajuste Automático',
          bevel: 'Biselar',
          brightness: 'Brillo',
          convert_to_path: 'Convertir en trayectoria',
          create_textpath: 'Crear Tecto de Trayectoria',
          create_textpath_short: 'Texto camino',
          crop: 'Recortar',
          decompose_path: 'Descomponer',
          detach_path: 'Disolver el Texto de Trayectoria',
          detach_path_short: 'Descomponer',
          disassemble_use: 'Desmontar',
          disassembling: 'Desmontar...',
          edit_path: 'Editar trayectoria',
          fetching_web_font: 'Obteniendo la fuente en línea...',
          grading: 'Calificación',
          invert: 'Invertir',
          offset: 'Offset',
          outline: 'Contornear',
          replace_with: 'Reemplazar con...',
          replace_with_short: 'Reemplazar',
          sharpen: 'Afilar',
          simplify: 'Simplificar',
          smart_nest: 'Anidamiento Inteligente',
          trace: 'Trazado',
          ungrouping: 'Desagrupando...',
          uploading_font_to_machine: 'Cargando la fuente a la máquina ...',
          wait_for_parsing_font: 'Fuente de análisis... Por favor, espera un segundo.',
          weld_text: 'Soldar texto',
        },
        align: 'Alinear',
        boolean: 'Booleano',
        bottom_align: 'Alinear la parte inferior',
        center_align: 'Alinear el centro',
        difference: 'Diferencia',
        distribute: 'Distribuir',
        flip: 'Voltear',
        group: 'Agrupar',
        hdist: 'Distribuir horizontalmente',
        hflip: 'Volteo horizontal',
        intersect: 'Cortar',
        left_align: 'Alinear a la izquierda',
        lock_aspect: 'Bloquear relación de aspecto',
        middle_align: 'Alinear el medio',
        option_panel: {
          color: 'Color',
          fill: 'Relleno',
          font_family: 'Fuente',
          font_size: 'Tamaño',
          font_style: 'Estilo',
          letter_spacing: 'El espaciado de las letras',
          line_spacing: 'El espaciado de las líneas',
          path_infill: 'Relleno de trayectoria',
          pwm_engraving: 'Modo Profundidad',
          pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
          rounded_corner: 'Esquina redondeada',
          shading: 'Gradiente',
          sides: 'Lados',
          start_offset: 'Offset de Texto',
          stroke: 'Borde',
          stroke_color: 'Color del borde',
          stroke_width: 'Ancho del borde',
          text_infill: 'Relleno de texto',
          threshold: 'Brillo de umbral',
          threshold_short: 'Umbral',
          vertical_align: 'Alineación',
          vertical_text: 'Texto vertical',
        },
        path_edit_panel: {
          connect: 'Conectar',
          delete: 'Eliminar',
          disconnect: 'Desconectar',
          node_type: 'TIPO DE NODO',
          round: 'Suave',
          sharp: 'Afilado',
        },
        right_align: 'Alinear a la derecha',
        subtract: 'Sustraer',
        top_align: 'Alinear la parte superior',
        ungroup: 'Desagrupar',
        union: 'Unir',
        unlock_aspect: 'Desbloquear relación de aspecto',
        vdist: 'Distribuir verticalmente',
        vflip: 'Volteo vertical',
        zoom: 'Zoom',
      },
      tabs: {
        layers: 'Layers',
        objects: 'Objetos',
        path_edit: 'Editar Trayectoria',
      },
    },
    shapes_panel: {
      animals: 'Animal',
      arrow: 'Flecha',
      basic: 'Básico',
      birds: 'Ave',
      celebration: 'Celebración',
      circular: 'Circular',
      CNY: 'Año Nuevo Chino',
      corner: 'Esquina',
      decor: 'Decoración',
      easter: 'Pascua',
      elements: 'Elementos',
      environment: 'Medio ambiente',
      graphics: 'Gráficos',
      halloween: 'Halloween',
      holidays: 'Fiesta',
      label: 'Etiqueta',
      land: 'Tierra',
      line: 'Línea',
      nature: 'Naturaleza',
      photo: 'Foto',
      plants: 'Plantas',
      ribbon: 'Cinta',
      sea: 'Mar',
      shape: 'Forma',
      speech: 'Discurso',
      text: 'Marco de texto',
      title: 'Elementos',
      valentines: 'San Valentín',
      weather: 'Clima',
      Xmas: 'Navidad',
    },
    svg_editor: {
      unable_to_fetch_clipboard_img: 'No se ha podido recuperar la imagen de tu portapapeles',
      unnsupported_file_type: 'El tipo de archivo no está admitido directamente. Por favor, convierte el archivo en SVG o mapa de bits',
    },
    tag: {
      g: 'Grupo',
      image: 'Imagen',
      text: 'Texto',
      use: 'Importación SVG',
    },
    time_est_button: {
      calculate: 'Calcular el tiempo',
      estimate_time: 'Tiempo estimado necesario:',
    },
    tool_panels: {
      _nest: {
        end: 'Detente',
        no_element: 'No hay ningún elemento que arreglar.',
        rotations: 'Posible rotación',
        spacing: 'Espaciamiento',
        start_nest: 'Arreglar',
        stop_nest: 'Detente',
      },
      _offset: {
        corner_type: 'Esquina',
        direction: 'Dirección de compensación',
        dist: 'Distancia de compensación',
        fail_message: 'No se ha logrado compensar los objetos.',
        inward: 'Hacia adentro',
        not_support_message: 'Elementos seleccionados que contienen SVG no soportados tag:\nImagen, Grupo, Texto, Objeto importado',
        outward: 'Hacia Afuera',
        round: 'Curvo',
        sharp: 'Agudo',
      },
      array_dimension: 'Dimensión de la matriz',
      array_interval: 'Intervalo de la matriz',
      cancel: 'Cancelar',
      columns: 'Cols.',
      confirm: 'Confirmar',
      dx: 'X',
      dy: 'Y',
      grid_array: 'Crear una matriz de cuadrícula',
      nest: 'Optimización de la disposición',
      offset: 'Offset',
      rows: 'Filas',
    },
    units: {
      mm: 'mm',
      walt: 'W',
    },
    zoom_block: {
      fit_to_window: 'Ajustar a la ventana',
    },
  },
  boxgen: {
    add_option: 'Añadir Opción',
    basic_box: 'Caja Básica',
    beam_radius: 'Compensación del radio del láser',
    beam_radius_warning: 'Elimine la compensación de corte cuando los bordes o uniones de la caja sean cortos para asegurar el montaje de la caja',
    cancel: 'Cancelar',
    coming_soon: 'Próximamente',
    continue_import: 'Continuar importando',
    control_tooltip: 'Botón izquierdo para rotar\nDesplazarse para hacer zoom\nBotón derecho para mover',
    control_tooltip_touch: 'Arrastrar para rotar\nPellizcar para hacer zoom\nDos dedos para mover',
    cover: 'Tapa',
    customize: 'Personalizar',
    depth: 'Profundidad',
    edge: 'Borde',
    finger: 'Dedo',
    finger_warning: 'La longitud interna del lado de la caja debe ser de al menos 6 mm (0.24 pulgadas) para ser compatible con la unión de dedos.',
    height: 'Alto',
    import: 'Importar',
    inner: 'Interior',
    joints: 'Unión',
    max_dimension_tooltip: 'El ancho/alto/profundidad máximo es %s.',
    merge: 'Fusionar',
    outer: 'Exterior',
    reset: 'Restablecer',
    tCount: 'Cantidad de T',
    tCount_tooltip: 'El recuento de ranuras en T se aplica al lado corto; la cantidad en el lado largo se calcula en función de su longitud.',
    tDiameter: 'Diámetro de T',
    text_label: 'Etiqueta',
    thickness: 'Espesor',
    title: 'BOXGEN',
    tLength: 'Longitud de T',
    tSlot: 'Ranura en T',
    tSlot_warning: 'La longitud del lado de la caja debe ser de al menos 30 mm (1.18 pulgadas) para ser compatible con la unión de ranuras en T.',
    volume: 'Volumen',
    width: 'Ancho',
    workarea: 'Área de trabajo',
    zoom: 'Zoom',
  },
  buttons: {
    back: 'Volver',
    back_to_beam_studio: 'Volver a Beam Studio',
    done: 'Aplicar',
    next: 'Siguiente',
  },
  calibration: {
    ador_autofocus_focusing_block: 'Presione el ícono "AF" en la página principal de la máquina durante 3 segundos y deje que la sonda toque el bloque de enfoque.',
    ador_autofocus_material: 'Presione el icono "AF" en la página principal de la máquina durante 3 segundos y permita que la sonda toque suavemente el material.',
    align_ilb: 'Posición: Alinee con el punto grabado en la parte inferior izquierda interior.',
    align_ilt: 'Posición: Alinee con el punto grabado en la parte superior izquierda interior.',
    align_irb: 'Posición: Alinee con el punto grabado en la parte inferior derecha interior.',
    align_irt: 'Posición: Alinee con el punto grabado en la parte superior derecha interior.',
    align_olb: 'Posición: Alinee con el punto grabado en la parte inferior izquierda exterior.',
    align_olt: 'Posición: Alinee con el punto grabado en la parte superior izquierda exterior.',
    align_orb: 'Posición: Alinee con el punto grabado en la parte inferior derecha exterior.',
    align_ort: 'Posición: Alinee con el punto grabado en la parte superior derecha exterior.',
    align_red_cross_cut: 'Por favor, alinee el centro de la cruz roja con la cruz de corte.',
    align_red_cross_print: 'Por favor, alinee el centro de la cruz roja con la cruz impresa.',
    analyze_result_fail: 'Falla en el análisis de la imagen capturada.<br/>Por favor asegúrese:<br/>1. Imagen capturada totalmente cubierta con papel blanco.<br/>2. La plataforma está bien enfocada.',
    ask_for_readjust: '¿Quiere omitir el paso de grabado y luego tomar una foto y calibrar directamente?',
    back: 'Vuelve',
    calculating_camera_matrix: 'Calculando la matriz de la cámara...',
    calculating_regression_parameters: 'Calculando parámetros de regresión...',
    calibrate_camera_before_calibrate_modules: 'Por favor, realice la calibración de la cámara antes de calibrar los módulos.',
    calibrate_chessboard_success_msg: 'Se capturó exitosamente la foto del tablero de ajedrez.<br/>La puntuación para esta foto es %s (%.2f).',
    calibrate_done: 'Calibración realizada. La precisión de la cámara es mejor cuando se enfoca con precisión.',
    calibrate_done_diode: 'Calibración realizada. Se ha guardado el desplazamiento módulo láser de diodo.',
    calibrating: 'Calibrando...',
    calibrating_with_device_pictures: 'Calibrando con imágenes del dispositivo...',
    camera_calibration: 'Calibración de la cámara',
    camera_parameter_saved_successfully: 'Parámetros de la cámara guardados con éxito.',
    cancel: 'Cancelar',
    check_checkpoint_data: 'Datos del punto de control',
    check_device_pictures: 'Ver imágenes del dispositivo',
    checking_checkpoint: 'Comprobando datos del punto de control...',
    checking_pictures: 'Comprobando imágenes del dispositivo...',
    diode_calibration: 'Calibración del módulo láser diodo',
    do_engraving: 'Hacer Grabado',
    download_chessboard_file: 'Descargar archivo de tablero de ajedrez',
    downloading_checkpoint: 'Descargando datos del punto de control...',
    downloading_pictures: 'Descargando imágenes...',
    drawing_calibration_image: 'Dibujando la imagen de calibración...',
    dx: 'X',
    dy: 'Y',
    elevate_and_cut: 'Elevar y Cortar',
    elevate_and_cut_step_1: 'Coloque un trozo de madera de color claro del tamaño de A4 en el centro del área de trabajo y eleve a 20 mm.',
    elevate_and_cut_step_1_prism_lift: 'Utilice Ador Prism Lift con una longitud máxima de 14 mm con un trozo de madera de al menos 6 mm de grosor.',
    failed_to_calibrate_camera: '#848 Error al calibrar la cámara, por favor contacte al Soporte de FLUX.',
    failed_to_calibrate_chessboard: 'Falló la calibración con la imagen del tablero de ajedrez.',
    failed_to_calibrate_with_pictures: '#848 Error al calibrar con imágenes del dispositivo.',
    failed_to_download_pictures: '#848 Error al descargar imágenes, por favor contacte al Soporte de FLUX.',
    failed_to_move_laser_head: 'Error al mover la cabeza del láser.',
    failed_to_parse_checkpoint: 'No se pudo analizar los datos del punto de control.',
    failed_to_save_calibration_results: '#849 Error al guardar los resultados de la calibración, por favor inténtelo de nuevo. Si esto continúa ocurriendo, por favor contacte al Soporte de FLUX.',
    failed_to_save_camera_parameter: 'No se pudo guardar el parámetro de la cámara.',
    failed_to_solve_pnp: 'No se pudo resolver la posición de la cámara.',
    finish: 'Hecho',
    found_checkpoint: 'Se encontraron datos del punto de control en su dispositivo. ¿Desea recuperarlos del punto de control?',
    getting_plane_height: 'Obteniendo Altura del Plano...',
    hint_adjust_parameters: 'Usa estos parámetros para ajustar el cuadro rojo',
    hint_red_square: 'Favor de alinear el contorno del cuadro rojo con el cuadro punteado.',
    module_calibration_2w_ir: 'Calibración del Módulo Infrarrojo',
    module_calibration_printer: 'Calibración del Módulo de Impresora',
    moving_laser_head: 'Moviendo la cabeza del láser...',
    next: 'Siguiente',
    no_picutre_found: '#846 Tu dispositivo no tiene fotos en bruto disponibles para calibración. Por favor, ponte en contacto con el soporte técnico de FLUX.',
    perform_autofocus_bb2: 'Por favor, vaya al panel de control de la máquina y presione AF para realizar el enfoque automático.',
    please_do_camera_calibration_and_focus: {
      beambox: 'Al calibrar el módulo láser diodo, se necesita una cámara.\nPor favor, asegúrate de que la cámara de esta máquina ha sido calibrada.\nAjusta la plataforma al punto focal (la altura del acrílico rechazado)',
      beamo: 'Al calibrar el módulo láser diodo, se necesita una cámara.\nPor favor, asegúrate de que la cámara de esta máquina ha sido calibrada.\nAjusta la cabeza del láser para enfocar el objeto grabado (la altura del acrílico rechazado)',
    },
    please_goto_beambox_first: 'Por favor, cambia al Modo de Grabación ( Beambox ) para poder usar esta función.',
    please_place_dark_colored_paper: 'Coloque un papel de tamaño A4 o carta de color oscuro en el centro del área de trabajo.',
    please_place_paper: 'Por favor, coloca un papel blanco tamaño A4 o Carta en la esquina tuperior izquierda del área de trabajo',
    please_place_paper_center: 'Coloque una hoja de papel blanco de tamaño A4 o carta en el centro del área de trabajo.',
    please_refocus: {
      beambox: 'Por favor, ajusta la plataforma al punto focal (la altura del acrílico rechazado)',
      beamo: 'Por favor, ajusta el cabezal del láser para enfocar el objeto grabado (la altura del acrílico rechazado)',
      beamo_af: 'Toca dos veces el botón de enfoque automático y deja que el de enfoque toque el material con cuidado.',
      hexa: 'Double click the height adjustment button to rise the honeycomb table up and make the probe touch the engraving material.',
    },
    preparing_to_take_picture: 'Preparando para tomar foto...',
    promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    put_chessboard: 'Colocar Tablero de Ajedrez',
    put_chessboard_1: 'Por favor, vaya al panel de control de la máquina y presione AF para realizar el enfoque automático.',
    put_chessboard_2: 'Mueva el papel del tablero de ajedrez o la cabeza láser hasta que se confirme que todo el tablero de ajedrez está dentro del marco rojo en la ventana de vista previa en vivo.',
    put_chessboard_3: 'Haz clic derecho para descargar la imagen del tablero de ajedrez en la ubicación actual y verificar su claridad.',
    put_chessboard_bb2_desc_1: 'Descargue el siguiente archivo de tablero de ajedrez y imprímalo en papel A4 (el tablero de ajedrez impreso debe tener cuadrados de 1x1 cm).',
    put_chessboard_bb2_desc_2: 'Fije el tablero de ajedrez impreso a una superficie no deformable, como acrílico o vidrio, asegurándose de que el tablero esté liso y sin arrugas o levantamientos.',
    put_chessboard_bb2_desc_3: 'Coloque la superficie con el tablero de ajedrez plana en el centro del área de trabajo.',
    put_chessboard_promark_1: 'Ajuste la distancia focal al enfoque adecuado para la lente de campo.',
    put_chessboard_promark_2: 'Asegúrese de que el papel de tablero de ajedrez sea claramente visible sin reflejos. Haga clic en "Siguiente" para tomar una foto.',
    put_chessboard_promark_desc_1: 'Utilice el papel de tablero de ajedrez proporcionado en la caja de accesorios, o imprima el siguiente patrón de tablero de ajedrez en papel A4 para la calibración de la cámara.',
    put_chessboard_promark_desc_2: 'Ajuste el enfoque correctamente y luego haga clic en "Siguiente" para continuar con el grabado.',
    put_paper: 'Colocar papel',
    put_paper_promark_1: 'Coloque el cartón negro de la caja de accesorios en la plataforma de trabajo.',
    put_paper_promark_2: 'Ajuste el enfoque correctamente y luego haga clic en "Empezar a Grabar" para continuar con el grabado.',
    put_paper_skip: 'Si el dibujo de calibración no procede automáticamente a tomar una foto, presione "Omitir" para tomarla.',
    put_paper_step1: 'Por favor, coloque un papel blanco de tamaño A4 o Carta en el centro del área de trabajo.',
    put_paper_step2: 'Asegure las cuatro esquinas del papel para garantizar que quede plano.',
    put_paper_step3: 'Haga clic en "Empezar a Grabar".',
    res_average: 'Promedio',
    res_excellent: 'Excelente',
    res_poor: 'Deficiente',
    retake: 'Volver a tomar foto',
    rotation_angle: 'Rotación',
    show_last_config: 'Mostrar el último resultado',
    skip: 'Omitir',
    solve_pnp_step1: 'Alinee los puntos de grabado según el número y la posición esperada de cada punto de marcador rojo.',
    solve_pnp_step2: 'Puede presionar "Volver a tomar foto" para realinear o ajustar manualmente las posiciones de los marcadores.',
    solve_pnp_step3: 'Si la imagen capturada no coincide con la escena real, por favor acerque o arrastre la pantalla para ajustar el punto de calibración en la tarjeta de calibración negra al centro y luego intente "Volver a tomar foto".',
    solve_pnp_title: 'Alinear puntos de referencia',
    start_engrave: 'Empezar a Grabar',
    start_printing: 'Iniciar Impresión',
    taking_picture: 'Tomar una foto...',
    unable_to_load_camera_parameters: "#851 No hay parámetros de calibración de cámara disponibles en tu dispositivo. Ve a 'Calibración' > 'Calibrar cámara (Avanzado)' para completar la calibración y obtener los parámetros.",
    update_firmware_msg1: 'tu firmware no es compatible con esta función. Por favor, actualiza el firmware a v',
    update_firmware_msg2: 'o superior para continuar (Menú > Máquina > [tu máquina] > Actualizar el firmware)',
    uploading_images: 'Subiendo imágenes...',
    use_last_config: 'Utilizar los datos de  la última calibración',
    use_old_camera_parameter: '¿Desea utilizar los parámetros actuales del objetivo de la cámara?',
    with_af: 'Con ajuste de enfoque',
    with_prism_lift: 'Con Ador Prism Lift',
    without_af: 'Sin ajuste de enfoque',
    without_prism_lift: 'Sin Ador Prism Lift',
    x_ratio: 'Relación X',
    y_ratio: 'Relación Y',
    zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
  },
  camera_data_backup: {
    download_success: 'Datos de la cámara descargados con éxito.',
    downloading_data: 'Downloading data...',
    estimated_time_left: 'Tiempo estimado restante:',
    folder_not_exists: 'La carpeta seleccionada no existe.',
    incorrect_folder: 'Error al cargar los datos de calibración. Por favor, compruebe si la carpeta seleccionada es correcta.',
    no_picture_found: 'No se encontró ninguna imagen en la máquina.',
    title: 'Copia de seguridad de datos de la cámara',
    upload_success: 'Datos de la cámara cargados con éxito.',
    uploading_data: 'Cargando datos...',
  },
  caption: {
    connectionTimeout: 'Tiempo de espera de la conexión',
  },
  change_logs: {
    added: 'Agregado:',
    change_log: 'Cambiar registros:',
    changed: 'Cambiado:',
    fixed: 'Fija:',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    see_older_version: 'Ver versiones anteriores',
  },
  code_generator: {
    barcode: 'Código de barras',
    qr_code: 'Código QR',
  },
  curve_engraving: {
    '921': 'Error al enfocar automáticamente.',
    '922': 'Error al realizar la medición con luz roja.',
    amount: 'Cantidad',
    apply_arkwork: 'Aplicar obra de arte en la curva',
    apply_camera: 'Aplicar imagen de cámara en la curva',
    click_to_select_point: 'Haga clic para seleccionar o deseleccionar puntos para medir nuevamente.',
    coloumns: 'Columnas',
    column_gap: 'Espacio entre columnas',
    err_object_over_range: 'El objeto excede el rango de medición.',
    failed_to_take_reference: 'Error al tomar referencia',
    gap: 'Espacio',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    measure_audofocus_area: 'Medir área de autoenfoque',
    preview_3d_curve: 'Vista previa del grabado de curvas',
    remeasure: 'Volver a medir',
    remeasuring_points: 'Volviendo a medir puntos...',
    reselect_area: 'Volver a seleccionar área',
    row_gap: 'Espacio entre filas',
    rows: 'Filas',
    set_object_height: 'Establecer altura del objeto',
    set_object_height_desc: 'Medir el grosor máximo del objeto.',
    start_autofocus: 'Iniciar autofoco',
    starting_measurement: 'Iniciando medición...',
    sure_to_delete: '¿Desea eliminar los datos de enfoque del grabado de curvas?',
    take_reference: 'Tomar referencia',
    take_reference_desc: 'Por favor, mueva la cabeza del láser al punto más alto del objeto, baje la sonda de enfoque y haga clic en "Confirmar" para enfocar.',
  },
  device: {
    abort: 'Abortar',
    aborted: 'Abortado',
    aborting: 'Abortar',
    busy: 'Ocupado',
    cartridge_info_read_failed: 'Por favor, verifique que su tinta es auténtica de FLUX.',
    cartridge_info_verification_failed: 'Por favor, asegúrese de que la tinta esté completamente insertada. Intente quitar la tinta e instalarla nuevamente.',
    cartridge_serial_number: 'Número de serie del cartucho de tinta',
    close_door_to_read_cartridge_info: 'Para acceder a la información de la tinta, por favor cierre la cubierta de la puerta de la máquina.',
    completed: 'Completado',
    completing: 'Completando',
    deviceList: 'Lista de máquinas',
    disable: 'Deshabilitar',
    disconnectedError: {
      caption: 'Máquina desconectada',
      message: 'Por favor, confirma si el acceso a la red de %s está disponible',
    },
    enable: 'Habilitar',
    firmware_version: 'Versión del firmware',
    ink_color: 'Color de tinta',
    ink_level: 'Volumen de tinta restante',
    ink_type: 'Tipo de tinta',
    IP: 'IP',
    model_name: 'Nombre del modelo',
    occupied: 'Manteniendo',
    pause: 'Pausa',
    paused: 'Pausado',
    pausedFromError: 'Pausado por error',
    pausing: 'Pausa',
    please_wait: 'Por favor, espera...',
    preparing: 'Preparando',
    processing: 'Procesamiento',
    quit: 'Salir',
    ready: 'Listo',
    reset: 'Reinicio (Kick)',
    resuming: 'Reanudando',
    retry: 'Reintentar',
    running: 'Trabajando',
    scanning: 'Escaneando',
    select: 'Selecciona',
    select_printer: 'Selecciona la impresora',
    serial_number: 'Número de serie',
    start: 'Comenzar',
    starting: 'Comenzando',
    status: 'Estado',
    submodule_type: 'Módulo',
    toolhead_change: 'Cambiar cabezal de herramienta',
    unknown: 'Desconocido',
    uploading: 'Cargando',
    UUID: 'UUID',
  },
  device_selection: {
    no_beambox: '#801 No podemos encontrar tu máquina en la red.\nPor favor, sigue esta <a target="_blank" href="https://tupport.flux3dp.com/hc/en-us/articles/360001683556">guía</a> para solucionar el problema de conexión.',
    no_device_web: "#801 Verifique el estado de su máquina o haga clic en 'Configuración de la máquina' a continuación para configurar la máquina.",
    select_usb_device: 'Seleccionar dispositivo USB',
  },
  editor: {
    exposure: 'Vista previa de exposición',
    opacity: 'Opacidad de vista previa',
    prespray_area: 'Área de Imprimación',
  },
  error_pages: {
    screen_size: 'Ten en cuenta que Beam Studio podría no funcionar de manera óptima en este equipo. Para una mejor experiencia se recomienda una pantalla de al menos 1024 pixeles de ancho.',
  },
  flux_id_login: {
    connection_fail: '#847 La conexión con el servicio al cliente de Flux ha fallado.',
    email: 'Correo electrónico',
    flux_plus: {
      access_monotype_feature: 'No tiene el complemento Fuentes Monotype.',
      access_monotype_feature_note: 'Debe tener la membresía FLUX+ Pro o el complemento Fuentes Monotype para acceder a esta característica.',
      access_plus_feature_1: 'Está accediendo a una',
      access_plus_feature_2: 'característica.',
      access_plus_feature_note: 'Debe tener la membresía FLUX+ para acceder a esta característica.',
      ai_credit_tooltip: 'Para eliminación de fondo por IA',
      explore_plans: 'Explore los planes FLUX+',
      features: {
        ai_bg_removal: 'Eliminación de fondo por IA',
        boxgen: 'Generador de cajas 3D',
        dmkt: '1000+ archivos de diseño',
        monotype: '250+ fuentes premium',
        my_cloud: 'Almacenamiento ilimitado en la nube',
      },
      flux_credit_tooltip: 'Para archivos del Mercado de diseño y eliminación de fondo por IA',
      get_addon: 'Obtener complemento',
      goto_member_center: 'Ir al Centro de miembros',
      learn_more: 'Más información',
      member_center_url: 'https://member.flux3dp.com/en-US/subscription',
      subscribe_now: 'Suscríbase ahora',
      thank_you: '¡Gracias por ser un valioso miembro!',
      website_url: 'https://flux3dp.com/subscription',
    },
    forget_password: '¿Olvidaste la contraseña?',
    incorrect: 'El correo y/o la contraseña son incorrectos.',
    login: 'Iniciar sesión',
    login_success: 'Ingreso exitoso.',
    lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
    new_to_flux: '¿Nuevo en FLUX? Crea una cuenta ahora.',
    not_verified: 'Este correo electrónico no ha sido verificado.',
    offline: 'Trabajar sin conexión',
    password: 'Contraseña',
    register: 'Crea tu cuenta FLUX',
    remember_me: 'Recordarme',
    signup_url: 'https://id.flux3dp.com/user/login#up',
    unlock_shape_library: 'Ingresa para desbloquear la base de datos de figuras.',
    work_offline: 'Trabajar sin conexión',
  },
  framing: {
    area_check: 'Comprobación de Área',
    areacheck_desc: 'Asegura que el área de trabajo sea segura mostrando la caja delimitadora del objeto y la zona de aceleración del cabezal láser.',
    calculating_task: 'Calculando tarea...',
    framing: 'Enmarcado',
    framing_desc: 'Previsualiza el cuadro delimitador del objeto.',
    hull: 'Envoltura',
    hull_desc: 'Previsualiza una forma que sigue de cerca el diseño, como una banda elástica envuelta alrededor del objeto.',
    low_laser: 'Láser bajo',
    low_laser_desc: 'Establezca un valor de láser de baja potencia para la tarea de enmarcado.',
    rotate_axis: 'Eje de rotación',
    rotateaxis_desc: 'Previsualiza el centro del eje de rotación, el eje en sí no se mueve.',
    rotation_frame_warning: 'La vista previa del marco girará una vez. Evite detenerse a mitad de camino para evitar el desalineamiento de la posición de marcado.',
    rotation_framing_desc: 'Previsualiza el rango completo de rotación, el eje gira según la vista previa.',
    start_task: 'Iniciar Tarea',
    start_task_description: 'Después de completar la vista previa del marco, haga clic en "Iniciar Tarea" para enviar el trabajo.',
  },
  general: {
    choose_folder: 'Elegir Carpeta',
    processing: 'Procesando...',
  },
  generic_error: {
    OPERATION_ERROR: '[OE] Ocurrió un conflicto de estatus, por favor, reintenta la acción.',
    SUBSYSTEM_ERROR: '[SE]  Error cuando el firmware de la máquina ejecuta la tarea. Por favor, reinicia la máquina',
    UNKNOWN_COMMAND: '[UC] Por favor, actualiza el firmware del dispositivo',
    UNKNOWN_ERROR: '[UE] Por favor, reinicia Beam Studio.',
  },
  global: {
    apply: 'Aplicar',
    back: 'Volver',
    cancel: 'Cancelar',
    editing: {
      redo: 'Rehacer',
      reset: 'Restablecer',
      undo: 'Deshacer',
      zoom_in: 'Acercar',
      zoom_out: 'Alejar',
    },
    mode_conflict: 'Esta opción no está disponible en el modo actual.',
    ok: 'OK',
    save: 'Guardar',
    stop: 'Detener',
  },
  image_edit_panel: {
    eraser: {
      brush_size: 'Tamaño del pincel',
      description: 'Haga clic o arrastre para borrar manualmente áreas no deseadas.',
      title: 'Borrador',
    },
    magic_wand: {
      description: 'Seleccione y elimine áreas adyacentes según la similitud de color.',
      title: 'Varita mágica',
      tolerance: 'Tolerancia',
    },
    title: 'Editar Imagen',
  },
  initialize: {
    back: 'Volver',
    cancel: 'Cancelar',
    confirm: 'Confirmar',
    connect: 'Conectar',
    connect_ethernet: {
      title: 'Conexión directa',
      tutorial1: '1. Conecta la máquina con tu computadora con el cable ethernet.',
      tutorial2_1: '2. Sigue ',
      tutorial2_2: ' para hacer tu switch como un router.',
      tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
      tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
      tutorial2_a_text: 'esta guía',
      tutorial3: '3. Haz clic en Siguiente.',
    },
    connect_machine_ip: {
      alert: {
        swiftray_connection_error: 'No se puede conectar al servidor. Reinicie Beam Studio e inténtelo de nuevo.',
      },
      check_camera: 'Comprobando la disponibilidad de la cámara',
      check_connection: 'Comprobación de la conexión de la máquina',
      check_firmware: 'Comprobando la versión del firmware',
      check_ip: 'Comprobando la disponibilidad de la IP',
      check_swiftray_connection: 'Comprobando conexión con el servidor',
      check_swiftray_connection_unreachable: 'servidor inalcanzable',
      check_usb: 'Verificar la conexión USB',
      enter_ip: 'Introduce la IP de tu máquina',
      finish_setting: 'Finalizar el ajuste',
      invalid_format: 'Formato inválido',
      invalid_ip: 'IP Inválido: ',
      promark_hint: 'Si no logra conectarse repetidamente a través de USB, consulte el <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">artículo del Centro de ayuda</a>.',
      retry: 'Reintentar',
      starts_with_169254: 'Comienza con 169.254',
      succeeded_message: 'Conectado con éxito 🎉',
      unreachable: 'IP inalcanzable',
    },
    connect_usb: {
      connect_camera: 'Conecte la cámara de la máquina a su computadora con un cable USB.',
      title: 'Conexión USB',
      title_sub: ' (Solo para HEXA y Ador)',
      turn_off_machine: 'Apague la máquina.',
      turn_on_machine: 'Encienda la máquina.',
      tutorial1: 'Conecta la máquina a tu computadora con el cable USB.',
      tutorial2: "Haz clic en 'Siguiente'.",
      wait_for_turning_on: "Haz clic en 'Siguiente' después de que el proceso de inicio esté completo y hayas ingresado en la pantalla principal.",
    },
    connect_wifi: {
      title: 'Conectando a Wi-Fi',
      tutorial1: '1. Ve al panel táctil > Haz clic en "Red" > "Conectarse a WiFi".',
      tutorial1_ador: '1. Ve a Panel táctil > Haz clic en "MÁQUINA" > Haz clic en "Red" > "Conectar a Wi-Fi".',
      tutorial2: '2. Selecciona y conecta tu WiFi preferido.',
      what_if_1: '¿Qué pasa si no veo mi Wi-Fi?',
      what_if_1_content: '1. El modo de cifrado de la Wi-Fi debe ser WPA2 o sin contraseña.\n2. El modo de cifrado se puede establecer en la interfaz de administración del router Wi-Fi. Si el router no es compatible con WPA2 enrutador y necesitas ayuda para elegir el router adecuado, ponte en contacto con el servicio de asistencia técnica de FLUX.',
      what_if_2: '¿Qué pasa si no veo ninguna conexión Wi-Fi?',
      what_if_2_content: '1. Asegúrate de que el dongle Wi-Fi esté completamente conectado.\n2. Si no hay una dirección MAC de la red inalámbrica en la pantalla táctil, por favor contacta con el soporte de FLUX.\n3. El canal Wi-Fi debe ser de 2.4Ghz (5Ghz no es compatible).',
    },
    connect_wired: {
      title: 'Conexión a la red cableada',
      tutorial1: '1. Conecta la máquina con tu router.',
      tutorial2: '2. Presiona "Red" para obtener la IP de la red.',
      tutorial2_ador: '2. Presiona "MÁQUINA" > "Red" para obtener la IP de la red.',
      what_if_1: '¿Qué pasa si la IP está vacía?',
      what_if_1_content: '1. Asegúrate de que el cable Ethernet esté completamente enchufado.\n2. Si no hay una dirección MAC de la red alámbrica en la pantalla táctil, por favor contacta con el soporte de FLUX.',
      what_if_2: '¿Qué pasa si la IP comienza con 169?',
      what_if_2_content: '1. Si la dirección IP comienza con 169.254, debería ser un problema de configuración de DHCP, por favor contacta a tu ISP (proveedor de servicios de Internet) para mejor asistencia.',
    },
    connecting: 'Conectando...',
    connection_types: {
      ether2ether: 'Conexión directa',
      usb: 'Conexión USB',
      wifi: 'Wi-Fi',
      wired: 'Red cableada',
    },
    next: 'Siguiente',
    no_machine: 'No tengo una máquina ahora.',
    promark: {
      configuration_confirmation: 'Esto asegura que tu Promark esté correctamente configurado para máxima eficiencia y precisión.',
      or_complete_later: `O, omite este paso y completa la configuración de Promark más tarde en:
    Máquinas > "Nombre Promark" > Configuración de Promark`,
      qc_instructions: 'Rellena los parámetros que se encuentran en el reverso de la tarjeta "QC Pass"',
      select_laser_source: 'Selecciona tu Promark',
      select_workarea: 'Selecciona el Área de Trabajo',
      settings: 'Configuración de Promark',
    },
    retry: 'Reintentar',
    select_beambox: 'Seleccione su Beambox',
    select_connection_type: '¿Cómo deseas conectarte?',
    select_language: 'Selecciona el idioma',
    select_machine_type: 'Selecciona tu modelo',
    setting_completed: {
      back: 'Volver',
      great: 'Bienvenido a Beam Studio',
      ok: 'COMIENZA A CREAR',
      setup_later: 'Siempre puedes configurar tu máquina desde la barra de título > "Máquinas" > "Configuración de la máquina"',
      start: 'Comenzar ',
    },
    skip: 'Saltar',
    start: 'Comenzar',
  },
  input_machine_password: {
    connect: 'CONECTAR',
    password: 'Contraseña',
    require_password: '"%s" requiere una contraseña',
  },
  insecure_websocket: {
    extension_detected: 'Extensión Beam Studio Connect detectada',
    extension_detected_description: "Hemos detectado que has instalado la extensión Beam Studio Connect. Haz clic en 'Confirmar' para redirigir a HTTPS, o haz clic en 'Cancelar' para seguir utilizando HTTP.",
    extension_not_deteced: 'No se pudo detectar la extensión Beam Studio Connect',
    extension_not_deteced_description: "Para usar HTTPS, haga clic en 'Confirmar' para instalar la extensión Beam Studio Connect. Después de instalar la extensión, actualice la página para activarla.<br/>De lo contrario, haga clic en el enlace de abajo para ver cómo usar HTTP en Chrome.",
    unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Enlace</a>',
  },
  layer_module: {
    general_laser: 'Láser',
    laser_2w_infrared: 'Láser Infrarrojo de 2W',
    laser_10w_diode: 'Láser de Diodo de 10W',
    laser_20w_diode: 'Láser de Diodo de 20W',
    non_working_area: 'Área no laboral',
    none: 'Ninguno',
    notification: {
      convertFromLaserModuleMsg: 'Tenga en cuenta que si completa esta operación, la configuración de la capa láser se eliminará y se establecerá de acuerdo con la capa actual.',
      convertFromLaserModuleTitle: '¿Desea convertir el módulo láser en un módulo de impresión?',
      convertFromPrintingModuleMsg: 'Tenga en cuenta que si completa esta operación, la configuración de color de la capa de impresión se eliminará y se establecerá de acuerdo con la capa actual.',
      convertFromPrintingModuleTitle: '¿Desea convertir el módulo de impresión en un módulo láser?',
      importedDocumentContainsPrinting: 'El documento contiene capas de impresión. ¿Desea cambiar al área de trabajo a Ador?',
      performIRCaliMsg: 'Haz clic en "Confirmar" para ejecutar la calibración o accede a la calibración a través del menú superior.<br />(Máquina > [Nombre de tu máquina] > Calibrar módulo infrarrojo)',
      performIRCaliTitle: 'Realizar la calibración del módulo infrarrojo.',
      performPrintingCaliMsg: 'Haz clic en "Confirmar" para ejecutar la calibración o accede a la calibración a través del menú superior.<br />(Máquina > [Nombre de tu máquina] > Calibrar Módulo de Impresión)',
      performPrintingCaliTitle: 'Realice la calibración del módulo de impresión',
      printingLayersCoverted: 'Las capas de impresión se han convertido en capas láser.',
    },
    printing: 'Impresión',
    unknown: 'Módulo desconocido',
  },
  machine_status: {
    '-17': 'Cartridge IO Mode',
    '-10': 'Mantener el modo',
    '-2': 'Escaneando',
    '-1': 'Manteniendo',
    0: 'Inactivo',
    1: 'Iniciando',
    2: 'ST_TRANSFORMA',
    4: 'Comenzando',
    6: 'Reanudando',
    16: 'Trabajando',
    18: 'Reanudando',
    32: 'Pausado',
    36: 'Pausado',
    38: 'Pausa',
    48: 'Pausado',
    50: 'Pausa',
    64: 'Completado',
    66: 'Completando',
    68: 'Preparando',
    128: 'Abortado',
    256: 'Alarm',
    512: 'Fatal',
    UNKNOWN: 'Desconocido',
  },
  material_test_generator: {
    block_settings: 'Configuración de Bloque',
    columns: 'Columnas',
    count: 'Cantidad',
    cut: 'Cortar',
    engrave: 'Grabar',
    export: 'Exportar',
    max: 'Máx',
    min: 'Mín',
    parameter: 'Parámetro',
    preview: 'Vista Previa',
    rows: 'Filas',
    size: 'Tamaño (AlxAn)',
    spacing: 'Espaciado',
    table_settings: 'Configuración de Tabla',
    text_settings: 'Ajustes de texto',
    title: 'Generador de Pruebas de Material',
  },
  menu: {
    inches: 'Pulgadas',
    mm: 'mm',
  },
  message: {
    auth_error: '#820 Error de Autenticación: Por favor, actualiza Beam Studio y el firmware de la máquina a la última versión.',
    authenticating: 'Autentificando...',
    camera: {
      abort_preview: 'Abortar',
      camera_cable_unstable: 'Se detecta que la cámara está transfiriendo fotos de manera inestable. La vista previa aún se puede realizar normalmente, pero puede existir el problema de que la vista previa sea lenta o se agote el tiempo de espera.',
      continue_preview: 'Continuar',
      fail_to_transmit_image: '#845 Algo salió mal en la transmisión de imágenes. Por favor, reinicia tu máquina o Beam Studio. Si este error persiste, por favor sigue <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">esta guía</a>.',
      ws_closed_unexpectly: '#844 La conexión a la cámara de la máquina se ha cerrado inesperadamente. Si este error persiste, por favor sigue <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402755805071">esta guía</a>.',
    },
    cancelling: 'Cancelando...',
    cant_connect_to_device: '#827 No se puede conectar la máquina, por favor, comprueba tu conexión',
    connected: 'Conectado',
    connecting: 'Conectando...',
    connectingCamera: 'Conectando cámara ...',
    connectingMachine: 'Conectando %s...',
    connectionTimeout: '#805 Por favor, comprueba el estado de tu red y el indicador de Wi-Fi de tu máquina.',
    device_blocked: {
      caption: 'Número de serie no autorizado',
      offline: 'El número de serie de su dispositivo está restringido al uso fuera de línea ya que parece estar desactivado. Proporcione el número de serie del dispositivo (ubicado en la parte posterior del dispositivo) a su distribuidor y pídale que contacte a support@flux3dp.com para activar la autorización en línea. Si desea utilizar el dispositivo fuera de línea, comuníquese directamente con support@flux3dp.com.',
      online: 'El número de serie de su dispositivo parece estar desactivado. Proporcione el número de serie del dispositivo (ubicado en la parte posterior del dispositivo) a su distribuidor y pídale que contacte a support@flux3dp.com para activar la autorización del dispositivo.',
    },
    device_busy: {
      caption: 'Máquina ocupada',
      message: 'La máquina está ejecutando otra tarea, inténtalo de nuevo más tarde. Si deja de funcionar, por favor reinicia la máquina.',
    },
    device_is_used: 'La máquina está siendo usada, ¿quieres abortar la tarea actual?',
    device_not_found: {
      caption: 'Máquina por defecto no encontrada',
      message: '#812 Por favor, comprueba el indicador de Wi-Fi de tu máquina',
    },
    disconnected: 'La conexión es inestable. Por favor, comprueba la conexión del dispositivo e inténtalo de nuevo más tarde.',
    endingLineCheckMode: 'Saliendo del modo de verificación de línea ...',
    endingRawMode: 'Saliendo del modo raw...',
    enteringLineCheckMode: 'Ingresando al modo de verificación de línea ...',
    enteringRawMode: 'Entrando en modo raw...',
    enteringRedLaserMeasureMode: 'Entrando en modo de medición con láser rojo...',
    exitingRotaryMode: 'Saliendo del modo rotativo ...',
    getProbePosition: 'Obteniendo posición de la sonda...',
    gettingLaserSpeed: 'Lectura de la velocidad del cabezal láser ...',
    homing: 'Volviendo al inicio...',
    need_password: 'Necesita una contraseña para conectarse a la máquina',
    please_enter_dpi: 'Por favor, introduce la unidad de tu archivo (in mm)',
    preview: {
      adjust: 'Ajustar',
      adjust_height_tooltip: 'Haga clic en la casilla para habilitar la edición.',
      already_performed_auto_focus: 'Ya ha realizado el enfoque automático, ¿utilizar los valores existentes?',
      auto_focus: 'Enfoque automático',
      auto_focus_instruction: 'Por favor, mueva la cabeza del módulo láser sobre el objeto y siga las instrucciones de la animación para presionar AF y enfocar.',
      camera_preview: 'Vista de la cámara',
      enter_manually: 'Introducir manualmente',
      please_enter_height: 'Por favor, ingrese la altura del objeto para capturar una toma precisa de la cámara.',
    },
    promark_disconnected: '#850 La conexión del dispositivo se ha interrumpido, por favor revise el estado de la conexión.',
    redLaserTakingReference: 'Tomando referencia...',
    retrievingCameraOffset: 'Leyendo el desplazamiento de la cámara ...',
    settingLaserSpeed: 'Ajuste de la velocidad del cabezal láser ...',
    swiftray_disconnected: 'No se pudo conectar al backend, intentando reconectar.',
    swiftray_reconnected: 'El backend se ha reconectado, intente reenviar el trabajo.',
    time_remaining: 'Tiempo restante:',
    tryingToConenctMachine: 'Intentando conectar a la máquina...',
    turningOffAirPump: 'Apagado de la bomba de aire ...',
    turningOffFan: 'Apagando el ventilador ...',
    unable_to_find_machine: 'Incapaz de encontrar la máquina',
    unable_to_start: '#830 Incapaz de comenzar la tarea. Por favor, inténtalo otra vez. Si esto sucede de nuevo, por favor contáctanos con el informe de errores:\n',
    unavailableWorkarea: '#804 El área de trabajo actual excede el área de trabajo de esta máquina. Por favor, comprueba el área de trabajo de la máquina seleccionada o establece el área de trabajo desde Edición > Configuración de documentos.',
    unknown_device: '#826 No se puede conectar a la máquina, por favor asegúrate de que el USB está conectado a la máquina',
    unknown_error: '#821 La aplicación ha encontrado un error desconocido, por favor usa Ayuda > Menú > Informe de errores.',
    unsupport_osx_version: 'La versión actual de macOS %s puede que no admita algunas funciones. Por favor, actualice amablemente a macOS 11+.',
    unsupport_win_version: 'La versión actual del sistema operativo %s puede que no soporte algunas funciones. Por favor, actualiza a la última versión.',
    unsupported_example_file: 'El archivo de ejemplo seleccionado no es compatible con el área de trabajo actual.',
    uploading_fcode: 'subir el FCode',
    usb_unplugged: 'La conexión USB se ha perdido. Por favor, comprueba tu conexión USB',
    wrong_swiftray_version_message: 'Versión incorrecta del backend (versión: {version}). Verifique si hay varias instancias de Beam Studio en ejecución y cierre las instancias adicionales antes de intentarlo nuevamente.',
    wrong_swiftray_version_title: 'Versión incorrecta del backend',
  },
  monitor: {
    ask_reconnect: 'Se ha interrumpido la conexión con el equipo. ¿Deseas reconectar?',
    bug_report: 'Informe de errores',
    camera: 'Cámara',
    cancel: 'Cancelar',
    confirmFileDelete: '¿Estás seguro de que quieres borrar este archivo?',
    connecting: 'Conectando, por favor espere...',
    DEVICE_ERROR: 'Algo salió mal.\nPor favor, reinicia la máquina', // Deprecated in FW 3.3.1
    download: 'Descargar',
    extensionNotSupported: 'Este formato de archivo no es compatible',
    fileExistContinue: 'El archivo ya existe, ¿quieres reemplazarlo?',
    forceStop: '¿Deseas abortar la tarea actual?',
    go: 'Comenzar',
    HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Sensor de flujo de aire anómalo',
    HARDWARE_ERROR_BOTTOM_OPENED: '#903 Tapa Abierta. Cierra la puerta para continuar.',
    HARDWARE_ERROR_DOOR_OPENED: '#901 Cierra la puerta para continuar.',
    HARDWARE_ERROR_DRAWER_OPENED: '#911 Cajón abierto',
    HARDWARE_ERROR_FIRE_DETECTED: '#912 Sensor de llama anómalo',
    HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Módulo incorrecto detectado. Por favor, instale el módulo correcto para continuar.',
    HARDWARE_ERROR_HEADTYPE_NONE: '#917 Módulo no detectado. Por favor, asegúrese de que el módulo esté instalado correctamente para continuar.',
    HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Módulo desconocido detectado. Por favor, instale el módulo correcto para continuar.',
    HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Homing Pull-Off Error',
    HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Error Crítico: Placa principal desconectada. Por favor, contacta con el soporte de FLUX.', // Deprecated in FW 3.3.1
    HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Sobrecalentado. Por favor, espera unos minutos.',
    HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Restablecimiento del eje Z fallido',
    HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Módulo de impresora sin respuesta.',
    HARDWARE_ERROR_PROBE_SHOWED: 'Por favor, retraiga la sonda.',
    HARDWARE_ERROR_PUMP_ERROR: '#900 Por favor, comprueba tu tanque de agua.',
    HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 No se detectó módulo giratorio',
    HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Error crítico: sistema sin respuesta. Por favor contacta al Soporte de FLUX.', // Deprecated in FW 3.3.1
    HARDWARE_FAILURE: 'Algo salió mal.\nPor favor, reinicia la máquina', // Deprecated in FW 3.3.1
    hour: 'h',
    left: 'izquierda',
    MAINBOARD_OFFLINE: '#905 Error al conectarse a la placa principal.\nPor favor, reinicia la máquina.',
    minute: 'm',
    monitor: 'MONITOR',
    NO_RESPONSE: '#905 Error al conectarse a la placa principal.\nPor favor, reinicia la máquina.',
    pause: 'Pausar',
    prepareRelocate: 'Preparándose para la reubicación',
    processing: 'Procesando',
    record: 'Grabar',
    relocate: 'Reubicar',
    RESOURCE_BUSY: 'La máquina está ocupada\nSi no está funcionando, por favor reinicia la máquina.',
    resume: 'Reanudar',
    savingPreview: 'Generando miniaturas',
    second: 's',
    start: 'Comenzar',
    stop: 'Detener',
    SUBSYSTEM_ERROR: '#402 Error Crítico: sistema sin respuesta. Por favor, contacta con el soporte de FLUX.',
    task: {
      BEAMBOX: 'Grabado Láser',
      'N/A': 'Modo libre',
    },
    taskTab: 'Tarea',
    temperature: 'Temperatura',
    upload: 'Subir',
    USER_OPERATION: 'Por favor, siga las instrucciones en el panel del dispositivo para continuar.',
    USER_OPERATION_CHANGE_CARTRIDGE: 'Por favor, inserte el cartucho correcto para continuar.',
    USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Por favor, inserte el cartucho para continuar.',
    USER_OPERATION_CHANGE_TOOLHEAD: 'Por favor, instale el módulo correcto para continuar.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Módulo incorrecto detectado. Por favor, instale el módulo correcto para continuar.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Módulo no detectado. Por favor, asegúrese de que el módulo esté instalado correctamente para continuar.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Módulo desconocido detectado. Por favor, instale el módulo correcto para continuar.',
    USER_OPERATION_REMOVE_CARTRIDGE: 'Por favor, retire el cartucho para continuar.',
    USER_OPERATION_ROTARY_PAUSE: 'Por favor, cambia al motor rotativo',
  },
  my_cloud: {
    action: {
      confirmFileDelete: '¿Está seguro de que desea eliminar este archivo? Esta acción no se puede deshacer.',
      delete: 'Eliminar',
      download: 'Descargar',
      duplicate: 'Duplicar',
      open: 'Abrir',
      rename: 'Renombrar',
    },
    file_limit: 'Archivo gratuito',
    loading_file: 'Cargando...',
    no_file_subtitle: 'Vaya al Menú > "Archivo" > "Guardar en la nube"',
    no_file_title: 'Guarde archivos en Mi nube para comenzar.',
    save_file: {
      choose_action: 'Guardar archivo:',
      input_file_name: 'Introducir nombre de archivo:',
      invalid_char: 'Caracteres no válidos:',
      save: 'Guardar',
      save_new: 'Guardar como nuevo archivo',
      storage_limit_exceeded: 'Su almacenamiento en la nube ha alcanzado el límite superior. Elimine cualquier archivo innecesario antes de guardar nuevos.',
    },
    sort: {
      a_to_z: 'Nombre: A - Z',
      most_recent: 'Más recientes',
      oldest: 'Más antiguos',
      z_to_a: 'Nombre: Z - A',
    },
    title: 'Mi nube',
    upgrade: 'Actualizar',
  },
  noun_project_panel: {
    clear: 'Borrar',
    elements: 'Elementos',
    enjoy_shape_library: 'Base de datos Disfruta las Figuras.',
    export_svg_title: 'No se puede exportar SVG',
    export_svg_warning: 'Este proyecto contiene objetos que están protegidos por la ley de propiedad intelectual. Por lo tanto, Beam Studio excluirá automáticamente estos objetos durante la exportación. Aún puede guardar su proyecto dentro Escena de Beam Studio (formato .beam) para mantener todos sus datos. ¿Todavía quieres exportar?',
    learn_more: 'Aprende más',
    login_first: 'Ingresa para desbloquear la base de datos de figuras.',
    recent: 'Recientes',
    search: 'Buscar',
    shapes: 'Formas',
  },
  pass_through: {
    export: 'Exportar a Área de Trabajo...',
    exporting: 'Exportando',
    guide_mark: 'Marca de Guía',
    guide_mark_desc: 'Las marcas de guía se grabarán como un punto de referencia para alinear la obra de arte.',
    guide_mark_length: 'Longitud:',
    guide_mark_x: 'Coordenada X:',
    height_desc: 'Configure la altura de cada sección del área de trabajo.',
    help_links: {
      ado1: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
      fbb2: 'https://support.flux3dp.com/hc/en-us/articles/11570021253263',
    },
    help_text: '¿Cómo configurar el paso directo para %(model)s?',
    object_length: 'Longitud del Objeto',
    ref_layer: 'Capa de Referencia',
    ref_layer_desc: 'Tenga en cuenta que la ejecución de la capa de referencia está configurada en 0 por defecto. No se ejecutará, solo es para referencia de alineación.',
    ref_layer_name: 'Referencia',
    title: 'Modo de paso directo',
    workarea_height: 'Área de Trabajo (Altura):',
  },
  promark_connection_test: {
    description: 'El sistema ejecutará una operación de luz roja durante aproximadamente dos minutos para comprobar si la conexión es estable.',
    health: 'Estabilidad:',
    healthy_description: 'La estabilidad es buena, pero evite mover significativamente la computadora durante el grabado.',
    res_0: 'Excelente',
    res_1: 'Buena',
    res_2: 'Baja',
    res_3: 'Demasiado baja',
    res_4: 'Muy baja',
    restart: 'Reiniciar prueba',
    start: 'Iniciar prueba',
    stop: 'Detener prueba',
    title: 'Prueba de estabilidad de conexión',
    unhealthy_description: 'La estabilidad es demasiado baja. Se recomienda reemplazar el adaptador de red o el cable de alimentación.',
  },
  promark_settings: {
    angle: 'Ángulo',
    bulge: 'Abombamiento',
    field: 'Campo',
    galvo_configuration: 'Configuración Galvo',
    mark: 'Marcar',
    mark_parameters: 'Parámetros de marcado',
    offsetX: 'Desplazamiento X',
    offsetY: 'Desplazamiento Y',
    preview: 'Vista previa',
    red_dot: 'Punto rojo',
    scale: 'Escala',
    scaleX: 'Escala X',
    scaleY: 'Escala Y',
    skew: 'Sesgo',
    switchXY: 'Cambiar X/Y',
    title: 'Configuración de Promark',
    trapezoid: 'Trapecio',
    workarea_hint: 'Puedes cambiar el área de trabajo en "Configuración del documento".',
    z_axis_adjustment: {
      section1: 'Ajusta la altura del eje Z para afinar el enfoque.',
      title: 'Ajuste del Eje Z',
      tooltip1: 'Prueba a marcar un cuadrado de 1x1 cm para confirmar si la distancia focal actual es adecuada.',
    },
  },
  qr_code_generator: {
    error_tolerance: 'Tolerancia de error',
    error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
    invert: 'Invertir color de fondo',
    placeholder: 'Ingrese un enlace o texto',
    preview: 'Vista previa',
    title: 'Generador de códigos QR',
  },
  rotary_settings: {
    circumference: 'Circunferencia',
    extend_workarea: 'Ampliar área de trabajo',
    mirror: 'Espejo',
    object_diameter: 'Diámetro del Objeto',
    overlap_size: 'Tamaño de superposición',
    rotary_scale: 'Escala de rotación',
    split_setting: 'Configuración de división',
    split_setting_url: 'https://support.flux3dp.com/hc/en-us/articles/12276094010767',
    split_size: 'Tamaño de división',
    type: 'Tipo',
  },
  select_device: {
    auth_failure: '#811 Fallo de autenticación',
    select_device: 'Seleccionar Dispositivo',
    unable_to_connect: '#810 Incapaz de construir una conexión estable con la máquina',
  },
  settings: {
    anti_aliasing: 'Kantenglättung',
    auto_connect: 'Selecciona automáticamente la única máquina',
    auto_switch_tab: 'Cambio automático entre panel de capas y objetos',
    autofocus_offset: 'Desplazamiento de Enfoque Automático',
    autosave_enabled: 'Guardado Automático',
    autosave_interval: 'Guardar Cada',
    autosave_number: 'Número de Guardado Automático',
    autosave_path: 'Guardado Automático Ubicación',
    autosave_path_not_correct: 'No se encontró la ruta de acceso especificada.',
    blade_precut_position: 'Posición de precorte',
    blade_precut_switch: 'Precorte de la cuchilla',
    blade_radius: 'Radio de la cuchilla',
    bottom_up: 'De abajo hacia arriba',
    calculation_optimization: 'Aceleración de cálculo de trayectorias',
    cancel: 'Cancelar',
    caption: 'Ajustes',
    check_updates: 'Comprobación automática',
    close: 'Cerrar',
    confirm_remove_default: 'La máquina predeterminada va a ser eliminada.',
    confirm_reset: '¿Confirma el restablecimiento de Beam Studio?',
    continuous_drawing: 'Dibujo continuo',
    curve_engraving_speed_limit: 'Límite de velocidad de grabado en curva',
    custom_preview_height: 'Altura de la previsualización',
    default_beambox_model: 'Configuración de documentos por defecto',
    default_borderless_mode: 'Abrir fondo por defecto',
    default_enable_autofocus_module: 'Autoenfoque predeterminado',
    default_enable_diode_module: 'Láser diodo por defecto',
    default_font_family: 'Fuente por defecto',
    default_font_style: 'Estilo Fuente por defecto',
    default_laser_module: 'Módulo láser predeterminado',
    default_machine: 'Máquina por defecto',
    default_machine_button: 'Vacío',
    default_units: 'Unidades por defecto',
    diode_offset: 'Compensación de láser diodo',
    diode_one_way_engraving: 'Grabado unidireccional con láser de diodo',
    diode_two_way_warning: 'La máquina puede emitir luz en ambas direcciones y la velocidad es más rápida, por lo que puede causar errores de posición durante el grabado. Se recomienda utilizar materiales de prueba primero.',
    disabled: 'Deshabilitado',
    done: 'Aplicar',
    enable_custom_backlash: 'Activar la compensación de retroceso',
    enable_low_speed: 'Activar movimiento lento',
    enabled: 'Habilitado',
    engraving_direction: 'Dirección de grabado',
    fast_gradient: 'Optimización de la velocidad',
    font_convert: 'Convertidor de texto a trayectoria',
    font_substitute: 'sustituir los caracteres no compatibles',
    grouped_objects: 'Objetos agrupados',
    groups: {
      ador_modules: 'Módulo de Ador',
      autosave: 'Guardado Automático',
      camera: 'Cámara',
      connection: 'Conexión',
      editor: 'Editor',
      engraving: 'Rasterización (Escaneo)',
      general: 'General',
      mask: 'Recorte del área de trabajo',
      modules: 'Añadir',
      path: 'Vector (Esquemas)',
      privacy: 'Privacidad',
      text_to_path: 'Texto',
      update: 'Actualizaciones de software',
    },
    guess_poke: 'Buscar la IP de la máquina',
    guides: 'Guías',
    guides_origin: 'Guías de origen',
    help_center_urls: {
      anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
      calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
      connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
      continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
      default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
      default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
      default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
      fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
      font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
      font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
      image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
      loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
      mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
      reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
      segmented_engraving: 'https://support.flux3dp.com/hc/en-us/articles/12306366019215',
      simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
      vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
    },
    high: 'Alto',
    image_downsampling: 'Calidad de la previtualización de los mapas de bits',
    ip: 'Dirección IP de la máquina',
    keep_preview_result: 'Mantener Resultado de Vista Previa',
    language: 'Idioma',
    loop_compensation: 'Compensación del bucle',
    low: 'Bajo',
    low_laser_for_preview: 'Láser para Vista previa del cuadro',
    mask: 'Recorte del área de trabajo',
    medium: 'Medio',
    module_offset_2w_ir: 'Desplazamiento del láser infrarrojo de 2W',
    module_offset_10w: 'Desplazamiento del láser de diodo de 10W',
    module_offset_20w: 'Desplazamiento del láser de diodo de 20W',
    module_offset_printer: 'Desplazamiento de impresora',
    none: 'Ninguno',
    normal: 'Normal',
    notification_off: 'Apagado',
    notification_on: 'Encendido',
    notifications: 'Notificaciones de escritorio',
    off: 'Apagado',
    on: 'Encendido',
    preview_movement_speed: 'Previtualización de la velocidad del movimiento',
    printer_advanced_mode: 'Modo Avanzado de Impresión',
    remove_default_machine_button: 'Eliminar',
    reset: 'Reiniciar Beam Studio',
    reset_now: 'Reiniciar Beam Studio',
    segmented_engraving: 'Grabado segmentado',
    share_with_flux: 'Compartir Beam Studio Analytics',
    simplify_clipper_path: 'Optimizar la trayectoria calculada',
    single_object: 'Objeto único',
    tabs: {
      device: 'Máquina',
      general: 'General',
    },
    text_path_calc_optimization: 'Optimización del cálculo de la trayectoria',
    top_down: 'De arriba para abajo',
    trace_output: 'Salida de Trazado / Imagen de rastro',
    update_beta: 'Beta',
    update_latest: 'Último',
    vector_speed_constraint: 'Límite de velocidad',
    wrong_ip_format: 'Formatos IP incorrectos',
  },
  social_media: {
    facebook: '¡Chatea con FLUXers, haz preguntas y aprende consejos!',
    instagram: '¡Consigue las últimas inspiraciones, ofertas y regalos!',
    youtube: 'Mira tutoriales de Beam Studio e ideas para manualidades.',
  },
  support: {
    no_vcredist: 'Por favor, instala Vitual C++ Redistribuible 2015<br/> que puede ser descargado en flux3dp.com',
    no_webgl: 'WebGL no está disponible. Por favor, utiliza otros dispositivos.',
  },
  topbar: {
    alerts: {
      add_content_first: 'Por favor agregue objetos primero',
      door_opened: 'Cierre la cubierta de la puerta para habilitar el láser para ejecutar el marco.',
      fail_to_connect_with_camera: '#803 No se pudo establecer conexión con la cámara de la máquina. Por favor, reinicia tu máquina o Beam Studio. Si este error persiste, por favor sigue <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">esta guía</a>.',
      fail_to_get_door_status: 'Asegúrese de que la cubierta de la puerta esté cerrada para habilitar el láser para ejecutar el marco.',
      fail_to_start_preview: '#803 No se puede iniciar el modo de vista previa. Por favor, reinicia tu máquina o Beam Studio. Si este error persiste, por favor sigue <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">esta guía</a>.',
      headtype_mismatch: 'Módulo incorrecto detectado. ',
      headtype_none: 'Módulo no detectado. ',
      headtype_unknown: 'Módulo desconocido detectado. ',
      install_correct_headtype: 'Instale los módulos láser de diodo de 10W/20W correctamente para habilitar el láser para ejecutar el marco.',
      job_origin_unavailable: 'Establecer el origen del trabajo requiere la versión de firmware 4.3.5 / 5.3.5 o superior. ¿Desea actualizar el firmware ahora?',
      job_origin_warning: 'Actualmente estás utilizando la “posición actual” como punto de inicio. Asegúrate de mover la cabeza láser a la posición correcta para evitar colisiones.',
      power_too_high: 'POTENCIA DEMASIADO ALTA',
      power_too_high_confirm: 'ANOTADO',
      power_too_high_msg: 'Usar una potencia de láser menor (menos del 70%) alargará la vida del tubo láser.\nPulsa "ANOTADO" para proceder.',
      pwm_unavailable: 'El Modo Profundidad requiere la versión de firmware 4.3.4 / 5.3.4 o superior. ¿Le gustaría actualizar el firmware ahora?',
      QcleanScene: '¿Quieres borrar el dibujo?<br/>¡Esto también borrará tu historial de deshacer!',
      start_preview_timeout: '#803 El tiempo de espera se ha agotado al iniciar el modo de vista previa. Por favor, reinicia tu máquina o Beam Studio. Si este error persiste, por favor sigue <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">esta guía</a>.',
    },
    frame_task: 'Vista previa del cuadro',
    hint: {
      polygon: 'Presiona la tecla + / - para aumentar / disminuir los lados.',
    },
    menu: {
      about: 'Acerca de Beam Studio',
      about_beam_studio: 'Acerca de Beam Studio',
      account: 'Cuenta',
      add_new_machine: 'Configuración de la máquina',
      align_center: 'Alinear el centro',
      anti_aliasing: 'Suavizado',
      auto_align: 'Alineación Automática',
      borderless_mode: 'Modo sin bordes',
      bug_report: 'Informe de errores',
      calibrate_beambox_camera: 'Calibrar la cámara',
      calibrate_beambox_camera_borderless: 'Calibrar la cámara (Fondo abierto)',
      calibrate_camera_advanced: 'Calibrar cámara (Avanzado)',
      calibrate_diode_module: 'Calibrar el módulo láser de diodo',
      calibrate_ir_module: 'Calibrar módulo infrarrojo',
      calibrate_printer_module: 'Calibrar módulo de impresión',
      calibration: 'Calibraciones',
      camera_calibration_data: 'Datos de calibración de la cámara',
      change_logs: 'Cambiar registros',
      clear_scene: 'Nuevos archivos',
      close: 'Cerrar la ventana',
      commands: 'Comandos',
      contact: 'Contáctanos',
      copy: 'Copiar',
      cut: 'Cortar',
      dashboard: 'Tablero de mandos',
      decompose_path: 'Descomponer',
      delete: 'Eliminar',
      design_market: 'Design Market',
      dev_tool: 'Herramienta de depuración',
      disassemble_use: 'Desmontar',
      document_setting: 'Configuración del documento',
      document_setting_short: 'Documento',
      download_data: 'Descargar datos',
      download_log: 'Registros de descargas',
      download_log_canceled: 'Descarga del registro cancelada',
      download_log_error: 'Error desconocido,  inténtalo más tarde.',
      duplicate: 'Duplicar',
      edit: 'Editar',
      example_files: 'Archivos de Ejemplo',
      export_BVG: 'BVG',
      export_flux_task: 'Tarea de FLUX',
      export_JPG: 'JPG',
      export_PNG: 'PNG',
      export_SVG: 'SVG',
      export_to: 'Exportar a...',
      file: 'Archivo',
      fit_to_window: 'Ajustar al tamaño de la ventana',
      follow_us: 'Síguenos',
      forum: 'Foro de la Comunidad',
      group: 'Agrupar',
      help: 'Ayuda',
      help_center: 'Centro de ayuda',
      hide: 'Ocultar Beam Studio',
      hideothers: 'Ocultar a los demás',
      image_crop: 'Recortar',
      image_curve: 'Curva',
      image_invert: 'Invertir',
      image_sharpen: 'Afilar',
      image_stamp: 'Biselar',
      image_vectorize: 'Trazar',
      import_acrylic_focus_probe: 'Cristal de Enfoque de Acrílico - 3mm',
      import_ador_laser_example: 'Ejemplo de Ador Laser',
      import_ador_printing_example_full: 'Ejemplo de Ador Impresión - Color Completo',
      import_ador_printing_example_single: 'Ejemplo de Ador Impresión - Color Único',
      import_beambox_2_example: 'Ejemplo de Beambox II',
      import_beambox_2_focus_probe: 'Sonda de Enfoque Beambox II - 3 mm',
      import_hello_beambox: 'Ejemplo de Beambox',
      import_hello_beamo: 'Ejemplo de beamo',
      import_hexa_example: 'Ejemplo de HEXA',
      import_material_printing_test: 'Prueba de Impresión de Material',
      import_material_testing_cut: 'Prueba de corte de material',
      import_material_testing_engrave: 'Prueba de grabado de material',
      import_material_testing_line: 'Prueba de la línea de material',
      import_material_testing_old: 'Prueba de grabado de material - Clásico',
      import_material_testing_simple_cut: 'Prueba de corte de material - Simple',
      import_promark_example: 'Ejemplo de Promark',
      import_promark_mopa_20w_color: 'Prueba de Color MOPA 20W',
      import_promark_mopa_60w_color: 'Prueba de Color MOPA 60W',
      import_promark_mopa_100w_color: 'Prueba de Color MOPA 100W',
      keyboard_shortcuts: 'Atajos de teclado',
      layer_color_config: 'Ajustes de color',
      layer_setting: 'Capa',
      link: {
        beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
        contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
        design_market: 'https://dmkt.io',
        downloads: 'https://flux3dp.com/downloads/',
        forum: 'https://www.facebook.com/groups/flux.laser/',
        help_center: 'https://helpcenter.flux3dp.com/',
        shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
      },
      log: {
        camera: 'Cámara',
        cloud: 'Nube',
        discover: 'Descubre',
        hardware: 'Hardware',
        network: 'Red',
        player: 'Reproductor',
        robot: 'Robot',
        usb: 'USB',
        usblist: 'Lista USB',
      },
      machine_info: 'Información de la máquina',
      machines: 'Máquinas',
      manage_account: 'Administrar mi cuenta',
      material_test: 'Prueba de Material',
      minimize: 'Minimizar',
      my_account: 'Mi cuenta',
      network_testing: 'Prueba la configuración de la red',
      object: 'Objeto',
      offset: 'Compensar',
      open: 'Abrir',
      paste: 'Pegar',
      paste_in_place: 'Pegar en su lugar',
      path: 'Trayectoria',
      photo_edit: 'Imagen',
      preferences: 'Preferencias',
      promark_color_test: 'Prueba de Color Promark',
      questionnaire: 'Cuestionario de comentarios',
      quit: 'Salir',
      recent: 'Abierto recientemente',
      redo: 'Rehacer',
      reload_app: 'Recargar la aplicación',
      reset: 'Reiniciar',
      rotary_setup: 'Configuración Rotatoria',
      rotate: 'Girar',
      samples: 'Ejemplos',
      save_as: 'Guardar como...',
      save_scene: 'Guardar',
      save_to_cloud: 'Guardar en la nube',
      scale: 'Escala',
      service: 'Servicios',
      set_as_default: 'Establecido como predeterminado',
      show_gesture_tutorial: 'Instrucciones de gestos táctiles',
      show_grids: 'Mostrar las cuadrículas',
      show_layer_color: 'Usar el color de la capa',
      show_rulers: 'Mostrar las reglas',
      show_start_tutorial: 'Tutorial de inicio ',
      show_ui_intro: 'Mostrar introducción a la interfaz',
      sign_in: 'Registrarse',
      sign_out: 'Cerrar sesión',
      software_update: 'Actualización del software',
      svg_edit: 'SVG',
      switch_to_beta: 'Cambiar a la versión Beta',
      switch_to_latest: 'Cambiar a versión estable',
      tools: {
        box_generator: 'Generador de Cajas',
        code_generator: 'Generador de código',
        material_test_generator: 'Generador de Pruebas de Material',
        title: 'Herramientas',
      },
      tutorial: 'Iniciar el Tutorial de Impresión de la Familia Delta',
      undo: 'Deshacer',
      ungroup: 'Desagrupar',
      update: 'Verificar la actualización',
      update_firmware: 'Actualizar el firmware',
      upload_data: 'Subir datos',
      using_beam_studio_api: 'Uso de Beam Studio API',
      view: 'Vista',
      window: 'Ventana',
      zoom_in: 'Acercar',
      zoom_out: 'Alejar',
      zoom_with_window: 'Ajuste automático del tamaño de la ventana',
    },
    preview: 'VISTA PREVIA',
    preview_press_esc_to_stop: 'Presiona ESC para detener la vista previa de la cámara.',
    rename_tab: 'Renombrar pestaña',
    select_machine: 'Seleccione una máquina',
    tag_names: {
      dxf: 'Objeto DXF',
      ellipse: 'Óvalo',
      g: 'Grupo',
      image: 'Imagen',
      line: 'Línea',
      multi_select: 'Múltiples objetos',
      no_selection: 'Sin selección',
      pass_through_object: 'Objeto de Paso',
      path: 'Trayectoria',
      polygon: 'Polígono',
      rect: 'Rectángulo',
      svg: 'Objeto SVG',
      text: 'Texto',
      text_path: 'Texto en Trayectoria',
      use: 'Objeto importado',
    },
    task_preview: 'Previsualizar Trayectoria',
    titles: {
      settings: 'Preferencias',
    },
    untitled: 'Sin título',
  },
  topmenu: {
    credit: 'El desarrollo de Beam Studio se basa en la asistencia del proyecto de código abierto <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> y otro <a target="_blank" href="https://flux3dp.com/credits/">software de código abierto</a>.',
    device: {
      download_log_canceled: 'Descarga del registro cancelada',
      download_log_error: 'Ha ocurrido un error desconocido, por favor inténtalo más tarde.',
      log: {
        usblist: 'Lista USB',
      },
      network_test: 'Probar red',
    },
    file: {
      all_files: 'Todos los archivos',
      clear_recent: 'Eliminar Recientemente Abiertos',
      converting: 'Convirtiendo en imagen...',
      fcode_files: 'Código FLUX',
      import: 'Importar',
      jpg_files: 'JPG',
      label: 'Archivo',
      path_not_exit: 'Esta ubicación parece no existir en el disco.',
      png_files: 'PNG',
      save_fcode: 'Exportar tarea de FLUX',
      save_jpg: 'Exportar JPG',
      save_png: 'Exportar PNG',
      save_scene: 'Guardar vista',
      save_svg: 'Exportar SVG',
      scene_files: 'Escena de Beam Studio',
      svg_files: 'SVG',
    },
    ok: 'OK',
    version: 'Versión',
  },
  tutorial: {
    ask_retry_calibration: 'Would you like to calibrate the camera again?',
    camera_calibration_failed: 'Falló la calibración de la cámara',
    gesture: {
      click: 'Toque para seleccionar el objeto.',
      drag: 'Arrastre para seleccionar los múltiples objetos.',
      hold: 'Mantenga presionado por un momento para abrir el menú contextual.',
      pan: 'Desplácese por el lienzo con dos dedos.',
      zoom: 'Mueve dos dedos juntos para alejar o separar dos dedos para agrandar el lienzo.',
    },
    links: {
      adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
    },
    look_for_machine: 'Buscador de tutoriales...',
    needNewInterfaceTutorial: '¿Le gustaría iniciar un tutorial para la nueva interfaz de Beam Studio?<br/>(Puede omitirlo ahora y comenzarlo más tarde haciendo clic en "Ayuda" > "Mostrar Introducción a la Interfaz".)',
    needNewUserTutorial: '¿Le gustaría iniciar un tutorial?<br/>(Puede omitirlo ahora y comenzarlo más tarde haciendo clic en "Ayuda" > "Mostrar Tutorial de Inicio".)',
    newInterface: {
      add_new_layer: 'Añadir una nueva capa',
      align_controls: 'Alinear los controles',
      basic_shapes: 'Formas básicas',
      camera_preview: 'Vista previa de la cámara',
      drag_to_sort: 'Arrastrar para clasificar',
      end_alert: '¿Estás seguro de terminar la nueva introducción de la UI?',
      flip: 'Voltear',
      group_controls: 'Controles de grupo',
      layer_controls: 'Haz clic con el botón derecho del ratón para seleccionar Controles de capa:\nDuplicar / Fusionar / Bloquear / Eliminar Capas',
      object_actions: 'Acciones de Objeto',
      pen_tool: 'Herramienta de la pluma',
      rename_by_double_click: 'Cambiar el nombre con un doble clic',
      select_image_text: 'Seleccionar / Imagen / Texto',
      select_machine: 'Seleccione una máquina',
      shape_operation: 'Operación de forma',
      start_work: 'Empezar a trabajar',
      switch_between_layer_panel_and_object_panel: 'Cambiar entre el Panel de Capas y el Panel de Objetos',
    },
    newUser: {
      add_new_layer: 'Añadir una nueva capa',
      adjust_focus: '2. Ajusta el foco...',
      close_cover: '3. Cierra la tapa.',
      drag_to_draw: 'Arrastrar para dibujar',
      draw_a_circle: 'Dibuja un círculo',
      draw_a_rect: 'Dibuja un rectángulo',
      end_alert: '¿Estás seguro de terminar el tutorial?',
      end_preview_mode: 'Finalizar el modo de vista previa',
      infill: 'Enciende el relleno',
      please_select_wood_cutting: 'Por favor, selecciona "Madera - Corte".',
      please_select_wood_engraving: 'Por favor, selecciona "Madera - Grabado" Preseleccionado.',
      preview_the_platform: 'Vista previa de la plataforma',
      put_wood: '1. Pon la madera de muestra',
      send_the_file: 'Envía el archivo',
      set_preset_wood_cut: 'Establecer preselección: Madera - Corte',
      set_preset_wood_engraving: 'Establecer preselección: Madera - Grabado',
      switch_to_layer_panel: 'Cambiar al panel de capas',
      switch_to_object_panel: 'Cambiar al panel de objetos',
      switch_to_preview_mode: 'Cambiar al modo de vista previa',
    },
    next: 'SIGUIENTE',
    retry: 'Reintenta',
    set_connection: 'Establecer la conexión',
    skip: 'Saltar',
    skip_tutorial: 'Te has saltado el tutorial. Siempre puedes iniciar el tutorial haciendo clic en "Ayuda" > "Mostrar tutorial de inicio".',
    suggest_calibrate_camera_first: 'Recomendamos a los usuarios calibrar la cámara inicialmente y volver a enfocar antes de cada vista previa para obtener resultados óptimos.<br/>¿Desea confirmar para realizar la calibración ahora?<br/>(Puede omitirlo ahora y hacerlo más tarde haciendo clic en "Menú" > "Máquina" > [Su Máquina] > "Calibrar Cámara".)',
    tutorial_complete: 'Eso es todo por el tutorial. ¡Ahora es el momento de crear!',
    unable_to_find_machine: 'No se puede encontrar la máquina para el Tutorial. ¿Quieres ir a la página de configuración de la conexión, reintentar o saltar el tutorial?',
    welcome: 'BIENVENIDO',
  },
  update: {
    cannot_reach_internet: '#823 El servidor es inalcanzable <br/>Por favor, comprueba la conexión a Internet',
    download: 'ACTUALIZACIÓN EN LÍNEA',
    firmware: {
      caption: 'Una actualización del firmware de la máquina está disponible',
      confirm: 'CARGAR',
      firmware_too_old_update_by_sdcard: 'La versión del firmware es demasiado antigua. Actualice el firmware utilizando una tarjeta SD.',
      force_update_message: '#814 Por favor, actualice su máquina a la última versión del firmware.',
      latest_firmware: {
        cant_get_latest: 'No se puede obtener información del firmware más reciente.',
        caption: 'Actualización del firmware de la máquina',
        message: 'Tienes el último firmware de la máquina',
        still_update: 'ACTUALIZAR',
      },
      message_pattern_1: '"%s" está listo para la actualización del firmware.',
      message_pattern_2: '%s Firmware v%s está ahora disponible - Tienes v%s.',
      too_old_for_web: 'La versión actual del firmware de su máquina es v%s.\nSi quiere utilizar la versión en línea de Beam Studio,por favor actualice el firmware de la máquina a la última versión .',
      update_fail: '#822 Fallo de actualización',
      update_success: 'La actualización del firmware se ha cargado con éxito',
      upload_file: 'subir el firmware (*.bin / *.fxfw)',
    },
    install: 'INSTALAR',
    later: 'MÁS TARDE',
    preparing: 'Preparando...',
    release_note: 'Nota de versión:',
    skip: 'Saltar esta versión',
    software: {
      available_switch: 'Beam Studio v%s ya está disponible. Tienes los v%s. ¿Quieres cambiar a esta versión?',
      available_update: 'Beam Studio v%s está disponible ahora. Tienes v%s. ¿Deseas descargar la actualización?',
      caption: 'Una actualización del software de Beam Studio está disponible',
      check_update: 'Comprueba si hay alguna actualización',
      checking: 'Comprobando la actualización',
      downloading: 'Descargando las actualizaciones en segundo plano, puedes hacer clic en "OK" para continuar tu trabajo.',
      install_or_not: 'estás listo para la actualización. ¿Te gustaría reiniciar y actualizar ahora?',
      no: 'No',
      no_response: 'No te has conectado al servidor, por favor, comprueba tu configuración de red.',
      not_found: 'Estás usando la última versión de Beam Studio.',
      switch_or_not: 'estás listo para el cambio. ¿Te gustaría reiniciar y cambiar ahora?',
      switch_version: 'Cambiar la versión',
      switch_version_not_found: 'No se encontró la versión conmutable.',
      update_for_ador: 'La versión actual del software %s es antigua, por favor descargue la última versión de Beam Studio para Ador.',
      yes: 'Sí',
    },
    update: 'Actualizar',
    updating: 'Actualizando...',
    upload: 'CARGAR',
  },
  web_cam: {
    no_device: 'No se puede detectar el dispositivo de cámara. Vuelva a conectar la cámara e intente de nuevo.',
    no_permission: 'Beam Studio no tiene permiso para acceder a la cámara. Asegúrese de que el permiso esté concedido a Beam Studio en la configuración del navegador o del sistema.',
  },
  z_speed_limit_test: {
    alert_before: 'Antes de comenzar el grabado de curvas, el sistema realizará una prueba de carga para evitar la pérdida de pasos debido al peso excesivo.',
    alert_failed: 'El peso del objeto actual supera el límite de carga para la velocidad actual del eje Z. Reduzca la velocidad del eje Z en el panel de la máquina o reemplace el objeto de grabado antes de volver a probar.',
    ignore: 'Ignorar',
    retest: 'Reprobar',
    testing: 'Realizando prueba de carga...',
  },
};

export default lang;
