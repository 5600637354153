// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-dialogs-promark-PromarkSettings-module__container--8ojYi{width:560px;margin:28px 0;display:grid;grid-template-columns:1fr 1fr;gap:28px 40px}._-_-packages-core-src-web-app-components-dialogs-promark-PromarkSettings-module__footer--2OkjD{height:32px}._-_-packages-core-src-web-app-components-dialogs-promark-PromarkSettings-module__footer--2OkjD ._-_-packages-core-src-web-app-components-dialogs-promark-PromarkSettings-module__button--2cWyi{display:inline-flex;align-items:center;font-size:14px}._-_-packages-core-src-web-app-components-dialogs-promark-PromarkSettings-module__footer--2OkjD ._-_-packages-core-src-web-app-components-dialogs-promark-PromarkSettings-module__button--2cWyi ._-_-packages-core-src-web-app-components-dialogs-promark-PromarkSettings-module__icon--Nn6xy{margin-left:8px;font-size:10px}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/dialogs/promark/PromarkSettings.module.scss"],"names":[],"mappings":"AAAA,mGACE,WAAA,CACA,aAAA,CACA,YAAA,CACA,6BAAA,CACA,aAAA,CAGF,gGACE,WAAA,CAEA,gMACE,mBAAA,CACA,kBAAA,CACA,cAAA,CAEA,8RACE,eAAA,CACA,cAAA","sourcesContent":[".container {\n  width: 560px;\n  margin: 28px 0;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 28px 40px;\n}\n\n.footer {\n  height: 32px;\n\n  .button {\n    display: inline-flex;\n    align-items: center;\n    font-size: 14px;\n\n    .icon {\n      margin-left: 8px;\n      font-size: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_-_-packages-core-src-web-app-components-dialogs-promark-PromarkSettings-module__container--8ojYi",
	"footer": "_-_-packages-core-src-web-app-components-dialogs-promark-PromarkSettings-module__footer--2OkjD",
	"button": "_-_-packages-core-src-web-app-components-dialogs-promark-PromarkSettings-module__button--2cWyi",
	"icon": "_-_-packages-core-src-web-app-components-dialogs-promark-PromarkSettings-module__icon--Nn6xy"
};
export default ___CSS_LOADER_EXPORT___;
