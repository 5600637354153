// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-boxgen-Boxgen-module__canvas--b1UJj{position:relative;flex:1 1 auto}@media screen and (max-width: 600px){._-_-packages-core-src-web-app-components-boxgen-Boxgen-module__canvas--b1UJj{border-bottom:1px solid #ddd;border-left:0;width:100vw;height:250px}}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/boxgen/Boxgen.module.scss"],"names":[],"mappings":"AAAA,8EACE,iBAAA,CACA,aAAA,CACA,qCAHF,8EAII,4BAAA,CACA,aAAA,CACA,WAAA,CACA,YAAA,CAAA","sourcesContent":[".canvas {\n  position: relative;\n  flex: 1 1 auto;\n  @media screen and (max-width: 600px) {\n    border-bottom: 1px solid #ddd;\n    border-left: 0;\n    width: 100vw;\n    height: 250px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"canvas": "_-_-packages-core-src-web-app-components-boxgen-Boxgen-module__canvas--b1UJj"
};
export default ___CSS_LOADER_EXPORT___;
