// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-dialogs-camera-AdorCalibrationV2-FindCorner-module__footer-button--q3ERv{text-transform:capitalize}._-_-packages-core-src-web-app-components-dialogs-camera-AdorCalibrationV2-FindCorner-module__img-container--XrGFB{display:flex;min-height:360px;align-items:center;justify-content:center}._-_-packages-core-src-web-app-components-dialogs-camera-AdorCalibrationV2-FindCorner-module__img-container--XrGFB img{object-fit:contain;height:360px}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/dialogs/camera/AdorCalibrationV2/FindCorner.module.scss"],"names":[],"mappings":"AAAA,mHACE,yBAAA,CAGF,mHACE,YAAA,CACA,gBAAA,CACA,kBAAA,CACA,sBAAA,CAEA,uHACE,kBAAA,CACA,YAAA","sourcesContent":[".footer-button {\n  text-transform: capitalize;\n}\n\n.img-container {\n  display: flex;\n  min-height: 360px;\n  align-items: center;\n  justify-content: center;\n\n  img {\n    object-fit: contain;\n    height: 360px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer-button": "_-_-packages-core-src-web-app-components-dialogs-camera-AdorCalibrationV2-FindCorner-module__footer-button--q3ERv",
	"img-container": "_-_-packages-core-src-web-app-components-dialogs-camera-AdorCalibrationV2-FindCorner-module__img-container--XrGFB"
};
export default ___CSS_LOADER_EXPORT___;
