// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-dialogs-camera-common-Instruction-module__container--VpemN ._-_-packages-core-src-web-app-components-dialogs-camera-common-Instruction-module__step--EDAfr{margin:none;white-space:pre-line}._-_-packages-core-src-web-app-components-dialogs-camera-common-Instruction-module__container--VpemN ._-_-packages-core-src-web-app-components-dialogs-camera-common-Instruction-module__video--EZzEw{width:100%;min-height:225px}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/dialogs/camera/common/Instruction.module.scss"],"names":[],"mappings":"AACE,qMACE,WAAA,CACA,oBAAA,CAGF,sMACE,UAAA,CACA,gBAAA","sourcesContent":[".container {\n  .step {\n    margin: none;\n    white-space: pre-line;\n  }\n\n  .video {\n    width: 100%;\n    min-height: 225px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_-_-packages-core-src-web-app-components-dialogs-camera-common-Instruction-module__container--VpemN",
	"step": "_-_-packages-core-src-web-app-components-dialogs-camera-common-Instruction-module__step--EDAfr",
	"video": "_-_-packages-core-src-web-app-components-dialogs-camera-common-Instruction-module__video--EZzEw"
};
export default ___CSS_LOADER_EXPORT___;
