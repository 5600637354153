// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-ImageEditPanel-components-Sider-index-module__sider--MlPw\\+{width:356px;min-width:356px}._-_-packages-core-src-web-app-components-ImageEditPanel-components-Sider-index-module__h-100--OQl-3{height:100%}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/ImageEditPanel/components/Sider/index.module.scss"],"names":[],"mappings":"AAAA,sGACE,WAAA,CACA,eAAA,CAGF,qGACE,WAAA","sourcesContent":[".sider {\n  width: 356px;\n  min-width: 356px;\n}\n\n.h-100 {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sider": "_-_-packages-core-src-web-app-components-ImageEditPanel-components-Sider-index-module__sider--MlPw+",
	"h-100": "_-_-packages-core-src-web-app-components-ImageEditPanel-components-Sider-index-module__h-100--OQl-3"
};
export default ___CSS_LOADER_EXPORT___;
