// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-widgets-PathInput-module__container--5t313{display:flex;align-items:center}._-_-packages-core-src-web-app-widgets-PathInput-module__container--5t313 ._-_-packages-core-src-web-app-widgets-PathInput-module__btn--y7EkO{cursor:pointer;width:32px;height:32px;margin-right:8px;border:1px solid #bbb;border-radius:4px}._-_-packages-core-src-web-app-widgets-PathInput-module__container--5t313 ._-_-packages-core-src-web-app-widgets-PathInput-module__btn--y7EkO:hover{background-color:#eee}._-_-packages-core-src-web-app-widgets-PathInput-module__container--5t313 ._-_-packages-core-src-web-app-widgets-PathInput-module__btn--y7EkO>img{padding:4px}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/widgets/PathInput.module.scss"],"names":[],"mappings":"AAAA,0EACE,YAAA,CACA,kBAAA,CAEA,8IACE,cAAA,CACA,UAAA,CACA,WAAA,CACA,gBAAA,CACA,qBAAA,CACA,iBAAA,CAEA,oJACE,qBAAA,CAGF,kJACE,WAAA","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n\n  .btn {\n    cursor: pointer;\n    width: 32px;\n    height: 32px;\n    margin-right: 8px;\n    border: 1px solid #bbbbbb;\n    border-radius: 4px;\n\n    &:hover {\n      background-color: #eeeeee;\n    }\n\n    > img {\n      padding: 4px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_-_-packages-core-src-web-app-widgets-PathInput-module__container--5t313",
	"btn": "_-_-packages-core-src-web-app-widgets-PathInput-module__btn--y7EkO"
};
export default ___CSS_LOADER_EXPORT___;
