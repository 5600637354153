// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-pages-Beambox-module__container--Jcyar{width:100%;height:100%;display:flex;flex-direction:column}._-_-packages-core-src-web-app-pages-Beambox-module__container--Jcyar ._-_-packages-core-src-web-app-pages-Beambox-module__main--6ji9R{flex:1 1 auto;display:flex;height:0}._-_-packages-core-src-web-app-pages-Beambox-module__buttons--oQ1fG{position:fixed;z-index:1;margin-right:20px;bottom:20px;right:258px;display:inline-flex;align-items:flex-start;gap:8px}._-_-packages-core-src-web-app-pages-Beambox-module__buttons--oQ1fG:where(._-_-packages-core-src-web-app-pages-Beambox-module__mac--i3gGT){right:242px}@media screen and (max-width: 600px){._-_-packages-core-src-web-app-pages-Beambox-module__buttons--oQ1fG{flex-direction:column;align-items:flex-end;bottom:70px;right:10px;margin-right:0}}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/pages/Beambox.module.scss"],"names":[],"mappings":"AAAA,sEACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CAEA,uIACE,aAAA,CACA,YAAA,CACA,QAAA,CAIJ,oEACE,cAAA,CACA,SAAA,CACA,iBAAA,CACA,WAAA,CACA,WAAA,CAEA,mBAAA,CACA,sBAAA,CACA,OAAA,CAEA,2IACE,WAAA,CAGF,qCAfF,oEAgBI,qBAAA,CACA,oBAAA,CACA,WAAA,CACA,UAAA,CACA,cAAA,CAAA","sourcesContent":[".container {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n\n  .main {\n    flex: 1 1 auto;\n    display: flex;\n    height: 0; // need to add this 0 to avoid the height of the container to be 100% of the parent\n  }\n}\n\n.buttons {\n  position: fixed;\n  z-index: 1;\n  margin-right: 20px;\n  bottom: 20px;\n  right: 258px;\n\n  display: inline-flex;\n  align-items: flex-start;\n  gap: 8px;\n\n  &:where(.mac) {\n    right: 242px;\n  }\n\n  @media screen and (max-width: 600px) {\n    flex-direction: column;\n    align-items: flex-end;\n    bottom: 70px;\n    right: 10px;\n    margin-right: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_-_-packages-core-src-web-app-pages-Beambox-module__container--Jcyar",
	"main": "_-_-packages-core-src-web-app-pages-Beambox-module__main--6ji9R",
	"buttons": "_-_-packages-core-src-web-app-pages-Beambox-module__buttons--oQ1fG",
	"mac": "_-_-packages-core-src-web-app-pages-Beambox-module__mac--i3gGT"
};
export default ___CSS_LOADER_EXPORT___;
