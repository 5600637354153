// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-views-dialogs-image-edit-CropPanel-module__top-buttons--IRc-k{display:flex;justify-content:space-between;margin-bottom:8px}._-_-packages-core-src-web-app-views-dialogs-image-edit-CropPanel-module__field--2ogkg{display:flex;justify-content:space-between;align-items:center;column-gap:12px;margin-top:8px}._-_-packages-core-src-web-app-views-dialogs-image-edit-CropPanel-module__field--2ogkg ._-_-packages-core-src-web-app-views-dialogs-image-edit-CropPanel-module__label--jAg9K{white-space:nowrap;font-weight:bold}._-_-packages-core-src-web-app-views-dialogs-image-edit-CropPanel-module__field--2ogkg ._-_-packages-core-src-web-app-views-dialogs-image-edit-CropPanel-module__select--g39SQ{width:100%;border-radius:50px}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/views/dialogs/image-edit/CropPanel.module.scss"],"names":[],"mappings":"AAAA,6FACE,YAAA,CACA,6BAAA,CACA,iBAAA,CAEF,uFACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,eAAA,CACA,cAAA,CACA,8KACE,kBAAA,CACA,gBAAA,CAEF,+KACE,UAAA,CACA,kBAAA","sourcesContent":[".top-buttons {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 8px;\n}\n.field {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  column-gap: 12px;\n  margin-top: 8px;\n  .label {\n    white-space: nowrap;\n    font-weight: bold;\n  }\n  .select {\n    width: 100%;\n    border-radius: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top-buttons": "_-_-packages-core-src-web-app-views-dialogs-image-edit-CropPanel-module__top-buttons--IRc-k",
	"field": "_-_-packages-core-src-web-app-views-dialogs-image-edit-CropPanel-module__field--2ogkg",
	"label": "_-_-packages-core-src-web-app-views-dialogs-image-edit-CropPanel-module__label--jAg9K",
	"select": "_-_-packages-core-src-web-app-views-dialogs-image-edit-CropPanel-module__select--g39SQ"
};
export default ___CSS_LOADER_EXPORT___;
