import type { ILang } from '@core/interfaces/ILang';

const lang: ILang = {
  alert: {
    abort: 'Keskeytä',
    cancel: 'Peruuta',
    caption: 'Virhe',
    close: 'Sulje',
    confirm: 'Vahvista',
    dont_save: 'Älä tallenna',
    dont_show_again: 'Älä näytä uudelleen',
    duplicated_preset_name: 'Kaksoiskappaleen esiasetuksen nimi',
    error: 'VIRHE',
    info: 'INFO',
    instruction: 'Ohje',
    learn_more: 'Lisätietoja',
    no: 'Ei',
    ok: 'OK',
    ok2: 'OK',
    oops: 'Hups',
    retry: 'Yritä uudelleen',
    save: 'Tallenna',
    stop: 'Pysäytä',
    warning: 'VAROITUS',
    yes: 'Kyllä',
  },
  auto_fit: {
    artwork_size: 'Teoksen koko',
    error_tip1: '1. Onko taideteos asetettu oikein materiaalille?',
    error_tip2: '2. Ovatko materiaalin ääriviivat riittävän selkeät tunnistamista varten?',
    failed_to_find_contour: 'Ei löydetty ääriviivoja automaattiseen sovitukseen',
    learn_more: 'Katso opetusohjelma.',
    learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    offset_x: 'Siirtymä X',
    offset_y: 'Siirtymä Y',
    pattern_size: 'Kuvion koko',
    position_artwork: 'Sijoita taideteos',
    position_step1: 'Säädä taideteoksen kokoa ja sijaintia kuviossa.',
    position_step2: 'Napsauta "Käytä" soveltaaksesi automaattista sovitusta tähän kuvioon.',
    preview_first: 'Suorita ensin kameran esikatselu.',
    reset_position: 'Palauta sijainti',
    rotation: 'Kiertää',
    select_a_pattern: 'Valitse kuvio',
    selected_artwork: 'Valittu taideteos',
    step1: 'Valitse kuvio, johon haluat sijoittaa teoksen.',
    step2: 'Jos kuviota ei löydy, esikatsele kamera uudelleen ja käytä Automaattinen Sovitus -toimintoa.',
    step3: 'Jos oikean kuvion löytäminen edelleen epäonnistuu, varmista, että työkappaleesi on selvästi tunnistettavissa ja että työalue on puhdas roskista.',
    title: 'Automaattinen Sovitus',
  },
  barcode_generator: {
    bar_height: 'Palkin korkeus',
    bar_width: 'Palkin leveys',
    barcode: {
      invalid_value: 'Arvo ei kelpaa valittuun muotoon.',
    },
    font: 'Fontti',
    font_size: 'Fontin koko',
    hide_text: 'Piilota teksti',
    invert_color: 'Käännä värit',
    text_margin: 'Tekstin marginaali',
  },
  beambox: {
    announcement_panel: {
      title: 'Tiedote',
    },
    banner: {
      auto_feeder: 'Automaattinen Syöttötila',
      camera_preview: 'Esikatselu',
      camera_preview_borderless_mode: '(Avoin Pohja)',
      curve_engraving: 'Kaiverruksen Käyrätila',
      pass_through: 'Läpikulku Tila',
      rotary: 'Pyörivä Tila',
    },
    bottom_right_panel: {
      convert_text_to_path_before_export: 'Muunna teksti poluksi...',
      export_file_error_ask_for_upload: 'Vientitehtävän epäonnistui. Haluatko tarjota toimivan näkymän kehitystiimille vianraportointia varten?',
      retreive_image_data: 'Nouda kuvan tiedot...',
    },
    context_menu: {
      copy: 'Kopioi',
      cut: 'Leikkaa',
      delete: 'Poista',
      duplicate: 'Monista',
      group: 'Ryhmä',
      move_back: 'Lähetä taustalle',
      move_down: 'Lähetä taaksepäin',
      move_front: 'Tuo etualalle',
      move_up: 'Tuo eteenpäin',
      paste: 'Liitä',
      paste_in_place: 'Liitä paikalleen',
      ungroup: 'Poista ryhmitys',
    },
    document_panel: {
      add_on: 'Lisäosat',
      auto_feeder: 'Automaattinen syöttölaite',
      auto_feeder_scale: 'Automaattisen syöttölaitteen mittakaava',
      auto_feeder_url: 'https://support.flux3dp.com/hc/en-us/articles/11688230498575',
      borderless_mode: 'Avoin pohja',
      current_position: 'Nykyinen Sijainti',
      disable: 'Poista käytöstä',
      document_settings: 'Asiakirjan asetukset',
      enable: 'Ota käyttöön',
      enable_autofocus: 'Autofokus',
      enable_diode: 'Diodilaser',
      engrave_dpi: 'Resoluutio',
      frame_before_start: 'Ruutu ennen suoritusta',
      frame_before_start_url: 'https://support.flux3dp.com/hc/en-us/articles/11494925637135',
      high: 'Korkea',
      job_origin: 'Työn Alkuperä',
      laser_source: 'Laserlähde',
      low: 'Matala',
      machine: 'Kone',
      medium: 'Keskitaso',
      notification: {
        changeFromPrintingWorkareaTitle: 'Haluatko muuntaa tulostuskerrokset laserkerroksiksi?',
      },
      origin: 'Alkuperä',
      pass_through: 'Läpivienti',
      pass_through_height_desc: 'Syötä objektin pituus työalueen laajentamiseksi.',
      rotary_mode: 'Pyörivä',
      scale: 'Mittakaava',
      start_from: 'Aloita Mistä',
      start_position: 'Aloituspaikka',
      start_work_button: 'Suorita Painike',
      ultra: 'Erittäin korkea',
      workarea: 'Työalue',
    },
    image_trace_panel: {
      back: 'Takaisin',
      brightness: 'Kirkkaus',
      cancel: 'Peruuta',
      contrast: 'Kontrasti',
      next: 'Seuraava',
      okay: 'OK',
      threshold: 'Kynnys',
      tuning: 'Parametrit',
    },
    layer_color_config_panel: {
      add: 'Lisää',
      add_config: 'Lisää väri',
      color: 'Väri',
      default: 'Palauta oletusarvot',
      in_use: 'Tätä väriä käytetään.',
      layer_color_config: 'Kerroksen värimääritykset',
      no_input: 'Anna kelvollinen heksavärikoodi.',
      power: 'Teho',
      repeat: 'Suorita',
      speed: 'Nopeus',
      sure_to_delete: 'Haluatko varmasti poistaa tämän väriasetuksen?',
      sure_to_reset: 'Menetät kaikki mukautetut asetukset, haluatko varmasti palauttaa oletusarvot?',
    },
    left_panel: {
      borderless_blind_area: 'Ei-kaiverrusalue',
      borderless_preview: 'Avaa alareunan kameran esikatselu',
      diode_blind_area: 'Hybridilaser-lisävarusteen sokkoalue',
      ellipse: 'Ellipsi',
      image: 'Kuva',
      label: {
        adjust_height: 'Säädä korkeutta',
        array: 'Taulukko',
        boxgen: 'Boxgen',
        choose_camera: 'Kamera',
        clear_preview: 'Tyhjennä esikatselu',
        cursor: 'Valitse',
        curve_engraving: {
          clear_area: 'Tyhjennä valittu alue',
          exit: 'Poistu',
          preview_3d_curve: 'Esikatselu käyrän kaiverruksesta',
          select_area: 'Valitse alue',
          title: 'Käyrän kaiverrus',
        },
        end_preview: 'Lopeta esikatselu',
        line: 'Viiva',
        live_feed: 'Live-syöte',
        my_cloud: 'Pilveni',
        oval: 'Soikio',
        pass_through: 'Läpivienti',
        pen: 'Kynä',
        photo: 'Kuva',
        polygon: 'Monikulmio',
        preview: 'Kameran esikatselu',
        qr_code: 'QR-koodi',
        rect: 'Suorakaide',
        shapes: 'Kuvio',
        text: 'Teksti',
        trace: 'Jäljitä kuva',
      },
      line: 'Viiva',
      rectangle: 'Suorakaide',
      text: 'Teksti',
      unpreviewable_area: 'Sokkoalue',
    },
    network_testing_panel: {
      average_response: 'Keskimääräinen vasteaika',
      cannot_connect_1: '#840 Yhteyden muodostaminen kohde-IP-osoitteeseen epäonnistui.',
      cannot_connect_2: '#840 Yhteyden muodostaminen kohde-IP-osoitteeseen epäonnistui. Varmista, että kohde on samassa verkossa.',
      cannot_get_local: 'Paikallisen IP-osoitteen käyttö epäonnistui.',
      connection_quality: 'Yhteyden laatu',
      device_not_on_list: '#842 Laite ei ole listalla, mutta yhteyden laatu on >70 ja keskimääräinen vasteaika on <100 ms',
      empty_ip: '#818 Anna kohdelaitteen IP-osoite.',
      end: 'Lopeta',
      fail_to_start_network_test: '#817 Verkkotestauksen käynnistys epäonnistui.',
      hint_connect_camera_timeout: 'Aikakatkaisu esikatselun käynnistyksen yhteydessä?',
      hint_connect_failed_when_sending_job: 'Yhteyden muodostaminen epäonnistui työn lähetyksen yhteydessä?',
      hint_device_often_on_list: 'Laite ei usein löydy listalta?',
      insert_ip: 'Kohdelaitteen IP-osoite:',
      invalid_ip: '#818 Virheellinen IP-osoite',
      ip_startswith_169: '#843 Laitteen IP-osoite alkaa 169.254',
      link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
      link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
      link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
      linux_permission_hint: 'Tämä virhe johtuu yleensä riittämättömistä käyttöoikeuksista.\nSuorita terminaalissa komento "sudo beam-studio --no-sandbox" käyttöoikeuksien saamiseksi ja verkkotestauksen suorittamiseksi.',
      local_ip: 'Paikallinen IP-osoite:',
      network_testing: 'Verkkotestaus',
      network_unhealthy: '#841 Yhteyden laatu <70 tai keskimääräinen vasteaika >100 ms',
      start: 'Aloita',
      test_completed: 'Testi valmis',
      test_fail: 'Testi epäonnistui',
      testing: 'Testataan verkkoa...',
    },
    object_panels: {
      lock_desc: 'Säilytä leveyden ja korkeuden suhde (SHIFT)',
      text_to_path: {
        check_thumbnail_warning: 'Joitakin tekstejä vaihdettiin toisiin fontteihin muunnettaessa tekstejä poluiksi ja joitakin merkkejä ei ehkä muunnettu oikein. <br/>Tarkista esikatselukuva uudelleen ennen tehtävän lähettämistä.',
        error_when_parsing_text: 'Virhe tekstiä poluksi muunnettaessa',
        font_substitute_pop: 'Tekstissäsi on merkkejä, joita nykyinen fontti ei tue. <br/>Haluaisitko käyttää <strong>%s</strong> korvaavana fonttina?',
        retry: 'Yritä myöhemmin uudelleen tai valitse toinen fontti',
        use_current_font: 'Käytä nykyistä fonttia',
      },
      wait_for_parsing_font: 'Jäsentää fonttia... Odota hetki',
    },
    path_preview: {
      current_position: 'Nykyinen sijainti',
      cut_distance: 'Leikkausmatka',
      cut_time: 'Leikkausaika',
      end_preview: 'Lopeta esikatselu',
      estimated_time: 'Arvioitu kokonaisaika',
      invert: 'Käännä',
      pause: 'Keskeytä',
      play: 'Toista',
      play_speed: 'Toistonopeus',
      preview_info: 'Esikatselutiedot',
      rapid_distance: 'Siirtymämatka',
      rapid_time: 'Siirtymäaika',
      remark: '* Kaikki tiedot ovat arvioituja viitearvoja.',
      size: 'Koko',
      start_here: 'Aloita tästä',
      stop: 'Pysäytä',
      travel_path: 'Siirtymäreitti',
    },
    photo_edit_panel: {
      aspect_ratio: 'Kuvasuhde',
      brightness: 'Kirkkaus',
      brightness_and_contrast: 'Kirkkaus / Kontrasti',
      cancel: 'Peruuta',
      circumference: 'Ympärys',
      compare: 'Vertaa',
      contrast: 'Kontrasti',
      crop: 'Rajaa',
      curve: 'Käyrä',
      diameter: 'Halkaisija',
      free: 'Vapaa',
      invert: 'Käännä värit',
      okay: 'OK',
      original: 'Alkuperäinen',
      phote_edit: 'Kuvan muokkaus',
      processing: 'Käsitellään',
      radius: 'Säde',
      rotary_warped: 'Kiertyvä Vääristymä',
      rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
      sharpen: 'Terävöitä',
      sharpness: 'Terävyys',
      start: 'Aloita',
      warp: 'Vääristymä',
    },
    popup: {
      ai_credit: {
        buy_link: 'https://member.flux3dp.com/en-US/credit',
        go: 'Siirry',
        insufficient_credit: 'Creditit loppu',
        insufficient_credit_msg: 'Et voi käyttää %s. Mene jäsenkeskukseen ja osta AI-credittejä.',
        relogin_to_use: 'Kirjaudu sisään uudelleen käyttääksesi tätä toimintoa',
      },
      auto_feeder_origin: 'Käytä kehystysesikatselua tarkistaaksesi laserpään reitin ja alueen, ja säädä sitten materiaalin sijoittelua tai kaiverruksen aloituspaikkaa tulosten perusteella törmäysten välttämiseksi kaiverrusprosessin aikana.',
      auto_switch_tab: {
        message: 'Uusi vaihtoehto automaattiselle vaihtamiselle Tasojen ja Objektien paneelien välillä on lisätty Asetuksiin. Tämä vaihtoehto on oletusarvoisesti pois käytöstä. Haluatko ottaa automaattisen vaihtamisen käyttöön nyt? <br/>Voit muuttaa tätä asetusta milloin tahansa Asetuksissa.',
        title: 'Automaattinen Vaihto Tason ja Kohteen Paneelien Välillä',
      },
      backend_connect_failed_ask_to_upload: '#802 Virheitä yhdistettäessä taustajärjestelmään. Haluatko lähettää vianraportin?',
      backend_error_hint: 'Ominaisuuksissa voi olla ongelmia taustajärjestelmän virheen takia.',
      both_power_and_speed_too_high: 'Matalampi laserteho pidentää laserputken käyttöikää. Liian korkea nopeus tällä resoluutiolla voi myös heikentää varjostuksen laatua.',
      bug_report: 'Vianraportti',
      change_workarea_before_preview: '%s:n työalue ei vastaa nykyistä työaluetta. Haluatko vaihtaa nykyisen työalueen?',
      convert_to_path_fail: 'Muuntaminen poluiksi epäonnistui.',
      dxf_bounding_box_size_over: 'Piirroksen koko ylittää työalueen. Siirrä piirrosta lähemmäs origoa CAD-ohjelmassa tai tarkista mittayksiköt.',
      dxf_version_waring: 'Tämän DXF-tiedoston versio ei ole 2013, mikä voi aiheuttaa yhteensopivuusongelmia.',
      facebook_group_invitation: {
        already_joined: 'Jo liittynyt',
        join_now: 'Liity nyt',
        later: 'Ehkä myöhemmin',
        message: 'Liity viralliseen FLUX Facebook-ryhmäämme yhdistääksesi muiden FLUX-laserkäyttäjien kanssa, keskustellaksesi FLUX-lasereista, jakaaksesi laser-taidetta ja pysyäksesi ajan tasalla tuotteidemme uusimmista päivityksistä. Odotamme sinua innolla!',
        title: 'Liity FLUX:n viralliseen käyttäjäryhmään',
      },
      import_file_contain_invalid_path: '#808 Tuodussa SVG-tiedostossa on virheellisiä kuvapolkuja. Varmista, että kaikki kuvatiedostot ovat olemassa tai upota kuvat tiedostoon.',
      import_file_error_ask_for_upload: 'Tiedoston tuonti epäonnistui. Haluatko tarjota tiedoston kehitystiimille vianraporttia varten?',
      layer_by_color: 'Väri',
      layer_by_layer: 'Kerros',
      loading_image: 'Ladataan kuvaa, odota...',
      more_than_two_object: 'Liikaa objekteja. Vain 2 objektia tuetaan.',
      mouse: 'Hiiri',
      no_support_text: 'Beam Studio ei tällä hetkellä tue tekstiä. Muuta teksti poluiksi ennen tuontia.',
      nolayer: 'Yksikerroksinen',
      not_support_object_type: 'Objektityyppiä ei tueta.',
      or_turn_off_borderless_mode: ' Tai poista avoimen pohjan tila käytöstä.',
      pdf2svg: {
        error_pdf2svg_not_found: '#825 Virhe: pdf2svg-komentoa ei löydy. Asenna pdf2svg paketinhallinnalla.',
        error_when_converting_pdf: '#824 Virhe muunnettaessa PDF:ää SVG:ksi:',
      },
      progress: {
        calculating: 'Lasketaan',
        uploading: 'Lähetetään',
      },
      questionnaire: {
        caption: 'Auta meitä täyttämään kysely',
        message: 'Auta meitä tekemään Beam Studio paremmaksi täyttämällä kysely.',
        no_questionnaire_available: 'Kyselyä ei ole saatavilla tällä hetkellä.',
        unable_to_get_url: 'Linkkiä kyselyyn ei voitu hakea internetistä. Tarkista internetyhteys.',
      },
      recommend_downgrade_software: 'Havaitsimme vanhemman laiteohjelmistoversion. Työskentelemme parhaillaan yhteensopivuusongelmien kanssa, mutta toistaiseksi suosittelemme palaamaan Beam Studioon 1.9.5.',
      recommend_upgrade_firmware: 'Havaitsimme vanhemman laiteohjelmistoversion. Yhteensopivuusongelmia korjataan parhaillaan, mutta toistaiseksi suosittelemme päivittämään uusimpaan laiteohjelmistoversioon.',
      save_unsave_changed: 'Haluatko tallentaa tallentamattomat muutokset?',
      select_at_least_two: 'Valitse kaksi objektia jatkaaksesi',
      select_first: 'Valitse objekti ensin.',
      select_import_method: 'Valitse tuontityyli:',
      select_import_module: 'Valitse moduuli:',
      sentry: {
        message: 'Hyväksytkö automaattisen virheraporttien lähettämisen kehitystiimille virheiden sattuessa?',
        title: 'Tehdään Beam Studio paremmaksi yhdessä',
      },
      should_update_firmware_to_continue: '#814 Nykyinen laiteohjelmisto ei tue tätä Beam Studion versiota. Päivitä laiteohjelmisto jatkaaksesi. (Valikko > Laite > [Laite] > Päivitä laiteohjelmisto)',
      speed_too_high_lower_the_quality: 'Liian korkea nopeus tällä resoluutiolla voi heikentää varjostuksen laatua.',
      still_continue: 'Jatka',
      successfully_uploaded: 'Tiedoston lataus onnistui.',
      svg_1_1_waring: 'Tämän SVG-tiedoston versio 1.1 voi aiheuttaa yhteensopivuusongelmia.',
      svg_image_path_waring: 'Tämä SVG-tiedosto sisältää tiedostopolkuja. Se voi aiheuttaa virheitä ladattaessa.\nVälttääksesi tämän riskin, liitä kuvat SVG-tiedostoon vietäessä.',
      text_to_path: {
        caption: 'Teksti-polku muunnin 2.0',
        message: 'Beam Studio esittelee nyt uuden teksti-polku muuntimen (Muunnin 2.0), joka tuottaa luotettavampia tuloksia! Haluaisitko vaihtaa siihen nyt? \nVoit myös säätää tätä asetusta myöhemmin Teksti-polku muunnin -osiossa asetuksissa.',
      },
      too_fast_for_auto_feeder: 'Liian suuren nopeuden käyttäminen kerroksissa, jotka sisältävät polkuobjekteja, voi heikentää leikkaustarkkuutta.\nEmme suosittele nopeuden käyttämistä yli %(limit)s leikatessa Auto Feederilla.',
      too_fast_for_auto_feeder_and_constrain: 'Seuraavat kerrokset: %(layers)s \nsisältävät vektoripolkuja ja ylittävät nopeusrajan %(limit)s.\nVektoripolkujen leikkausnopeus Auto Feederilla rajoitetaan %(limit)s.\nVoit poistaa tämän rajoituksen Asetuksista.',
      too_fast_for_curve: 'Liian suuri nopeus kerroksille, jotka sisältävät käyräkaiverrusobjekteja, voi heikentää kaiverruksen tarkkuutta.\nSuosittelemme pitämään nopeuden alle %(limit)s parhaan tuloksen saavuttamiseksi.',
      too_fast_for_curve_and_constrain: 'Seuraavat kerrokset: %(layers)s ylittävät nopeusrajan %(limit)s.\nKäyräobjektien kaiverrusnopeus rajoitetaan arvoon %(limit)s. Voit poistaa tämän rajoituksen asetuksista.',
      too_fast_for_path: 'Liian korkea nopeus polkuobjekteja sisältävissä kerroksissa voi heikentää leikkaustarkkuutta. Emme suosittele yli %(limit)s nopeutta leikattaessa.',
      too_fast_for_path_and_constrain: 'Seuraavat kerrokset: %(layers)s \nsisältävät vektoripolkuobjekteja yli %(limit)s nopeudella. Polkuobjektien leikkausnopeus rajoitetaan %(limit)s:iin. Voit poistaa rajoituksen asetuksista.',
      touchpad: 'Kosketuslevy',
      ungroup_use: 'Tämä purkaa tuodun tiedoston ryhmityksen. Koska tiedosto voi sisältää suuren määrän elementtejä, ryhmityksen purku voi viedä aikaa. Haluatko jatkaa?',
      upload_failed: '#819 Tiedoston lataus epäonnistui.',
      upload_file_too_large: '#819 Tiedosto on liian suuri latausta varten.',
      vectorize_shading_image: 'Liukuvärit kuvissa altistuvat kohinalle. Poista kuvan liukuvärit ennen suorittamista.',
    },
    rating_panel: {
      description: 'Jos pidät Beam Studiosta, arvostaisimme suuresti, jos voisit arvostella meidät.',
      thank_you: 'Kiitos!',
      title: 'Pidätkö Beam Studio -ohjelmasta?',
    },
    right_panel: {
      laser_panel: {
        advanced: 'Edistynyt',
        backlash: 'Takaisinkytkentä',
        bi_directional: 'Kaksisuuntainen',
        by: 'Määrällä',
        ce_z_high_speed: 'Nopeanopeuksinen Z-akseli',
        ce_z_high_speed_link: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
        color_adjustment: 'Värikanavan säätö',
        color_adjustment_short: 'Värikanava',
        color_strength: 'Voimakkuus',
        cross_hatch: 'Ristikkäinen Varjostus',
        custom_preset: 'Mukautettu',
        cut: 'Leikkaa',
        diode: 'Diodilaser',
        do_not_adjust_default_para: 'Oletusasetuksia ei voi säätää.',
        dottingTime: 'Pisteytysaika',
        dropdown: {
          inches: {
            abs_engraving: 'ABS - kaivertaminen',
            acrylic_3mm_cutting: 'Akryyli - 1/8″ leikkaus',
            acrylic_5mm_cutting: 'Akryyli - 3/16″ leikkaus',
            acrylic_8mm_cutting: 'Akryyli - 1/4″ leikkaus',
            acrylic_10mm_cutting: 'Akryyli - 3/8″ leikkaus',
            acrylic_engraving: 'Akryyli - kaivertaminen',
            acrylic_printing: 'Akryyli - tulostus',
            aluminum_engraving: 'Alumiini - kaivertaminen',
            aluminum_light: 'Alumiini (vaalea)',
            bamboo_printing: 'Bambusu - tulostus',
            black_abs: 'Musta ABS',
            black_acrylic_3mm_cutting: 'Musta akryyli - 1/8″ leikkaus',
            black_acrylic_5mm_cutting: 'Musta akryyli - 3/16″ leikkaus',
            black_acrylic_engraving: 'Musta akryyli - kaivertaminen',
            brass_dark: 'Messinki (tumma)',
            brass_engraving: 'Messinki - kaivertaminen',
            brass_light: 'Messinki (vaalea)',
            canvas_printing: 'Kanvas - tulostus',
            cardstock_printing: 'Pakkopahvi - tulostus',
            copper: 'Kupari',
            cork_printing: 'Korkki - tulostus',
            denim_1mm_cutting: 'Denim - 1/32″ leikkaus',
            fabric_3mm_cutting: 'Kangas - 1/8″ leikkaus',
            fabric_5mm_cutting: 'Kangas - 3/16″ leikkaus',
            fabric_engraving: 'Kangas - kaivertaminen',
            fabric_printing: 'Kangas - tulostus',
            flat_stone_printing: 'Tasainen kivi - tulostus',
            glass_bw_engraving: 'Lasi - kaivertaminen',
            glass_printing: 'Lasi - tulostus',
            gloss_leather_printing: 'Kiiltävä nahka - tulostus',
            gold_engraving: 'Kulta - kaivertaminen',
            iron_engraving: 'Rauta - kaivertaminen',
            leather_3mm_cutting: 'Nahka - 1/8″ leikkaus',
            leather_5mm_cutting: 'Nahka - 3/16″ leikkaus',
            leather_engraving: 'Nahka - kaivertaminen',
            mdf_3mm_cutting: 'MDF - 1/8″ leikkaus',
            mdf_5mm_cutting: 'MDF - 3/16″ leikkaus',
            mdf_engraving: 'MDF - kaivertaminen',
            metal_bw_engraving: 'Metalli - kaivertaminen',
            opaque_acrylic: 'Läpinäkymätön akryyli',
            pc_printing: 'PC - tulostus',
            rubber_bw_engraving: 'Kumi - kaivertaminen',
            silver_engraving: 'Hopea - kaivertaminen',
            stainless_steel_bw_engraving_diode: 'Metalli - kaivertaminen (Diodilaser)',
            stainless_steel_dark: 'Ruostumaton teräs (tumma)',
            stainless_steel_engraving: 'Ruostumaton teräs - kaivertaminen',
            stainless_steel_light: 'Ruostumaton teräs (vaalea)',
            stainless_steel_printing: 'Ruostumaton teräs - tulostus',
            steel_engraving_spray_engraving: 'Metalli - kaivertaminen',
            stone: 'Kivi',
            ti_engraving: 'Titaani - kaivertaminen',
            titanium_dark: 'Titaani (tumma)',
            titanium_light: 'Titaani (vaalea)',
            white_abs: 'Valkoinen ABS',
            wood_3mm_cutting: 'Puu - 1/8″ leikkaus',
            wood_5mm_cutting: 'Puu - 3/16″ leikkaus',
            wood_7mm_cutting: 'Puu - 1/4″ leikkaus',
            wood_8mm_cutting: 'Puu - 1/4″ leikkaus',
            wood_10mm_cutting: 'Puu - 3/8″ leikkaus',
            wood_engraving: 'Puu - kaivertaminen',
            wood_printing: 'Puu - tulostus',
          },
          mm: {
            abs_engraving: 'ABS - kaivertaminen',
            acrylic_3mm_cutting: 'Akryyli - 3 mm leikkaus',
            acrylic_5mm_cutting: 'Akryyli - 5 mm leikkaus',
            acrylic_8mm_cutting: 'Akryyli - 8 mm leikkaus',
            acrylic_10mm_cutting: 'Akryyli - 10 mm leikkaus',
            acrylic_engraving: 'Akryyli - kaivertaminen',
            acrylic_printing: 'Akryyli - tulostus',
            aluminum_engraving: 'Alumiini - kaivertaminen',
            aluminum_light: 'Alumiini (vaalea)',
            bamboo_printing: 'Bambu - tulostus',
            black_abs: 'Musta ABS',
            black_acrylic_3mm_cutting: 'Musta akryyli - 3 mm leikkaus',
            black_acrylic_5mm_cutting: 'Musta akryyli - 5 mm leikkaus',
            black_acrylic_engraving: 'Musta akryyli - kaivertaminen',
            brass_dark: 'Messinki (tumma)',
            brass_engraving: 'Messinki - kaivertaminen',
            brass_light: 'Messinki (vaalea)',
            canvas_printing: 'Kanvas - tulostus',
            cardstock_printing: 'Pahvi - tulostus',
            copper: 'Kupari',
            cork_printing: 'Korkki - tulostus',
            denim_1mm_cutting: 'Denim - 1 mm leikkaus',
            fabric_3mm_cutting: 'Kangas - 3 mm leikkaus',
            fabric_5mm_cutting: 'Kangas - 5 mm leikkaus',
            fabric_engraving: 'Kangas - kaivertaminen',
            fabric_printing: 'Kangas - tulostus',
            flat_stone_printing: 'Tasainen kivi - tulostus',
            glass_bw_engraving: 'Lasi - kaivertaminen',
            glass_printing: 'Lasi - tulostus',
            gloss_leather_printing: 'Kiiltävä nahka - tulostus',
            gold_engraving: 'Kulta - kaivertaminen',
            iron_engraving: 'Rauta - kaivertaminen',
            leather_3mm_cutting: 'Nahka - 3 mm leikkaus',
            leather_5mm_cutting: 'Nahka - 5 mm leikkaus',
            leather_engraving: 'Nahka - kaivertaminen',
            mdf_3mm_cutting: 'MDF - 3 mm leikkaus',
            mdf_5mm_cutting: 'MDF - 5 mm leikkaus',
            mdf_engraving: 'MDF - kaivertaminen',
            metal_bw_engraving: 'Metalli - kaivertaminen',
            opaque_acrylic: 'Läpinäkymätön akryyli',
            pc_printing: 'PC - tulostus',
            rubber_bw_engraving: 'Kumi - kaivertaminen',
            silver_engraving: 'Hopea - kaivertaminen',
            stainless_steel_bw_engraving_diode: 'Ruostumaton teräs - kaivertaminen (Diodilaser)',
            stainless_steel_dark: 'Ruostumaton teräs (tumma)',
            stainless_steel_engraving: 'Ruostumaton teräs - kaivertaminen',
            stainless_steel_light: 'Ruostumaton teräs (vaalea)',
            stainless_steel_printing: 'Ruostumaton teräs - tulostus',
            steel_engraving_spray_engraving: 'Metalli - kaivertaminen',
            stone: 'Kivi',
            ti_engraving: 'Titaani - kaivertaminen',
            titanium_dark: 'Titaani (tumma)',
            titanium_light: 'Titaani (vaalea)',
            white_abs: 'Valkoinen ABS',
            wood_3mm_cutting: 'Puu - 3 mm leikkaus',
            wood_5mm_cutting: 'Puu - 5 mm leikkaus',
            wood_7mm_cutting: 'Puu - 7 mm leikkaus',
            wood_8mm_cutting: 'Puu - 8 mm leikkaus',
            wood_10mm_cutting: 'Puu - 10 mm leikkaus',
            wood_engraving: 'Puu - kaivertaminen',
            wood_printing: 'Puu - tulostus',
          },
          parameters: 'Esiasetukset',
          save: 'Lisää nykyiset parametrit',
        },
        engrave: 'Kaiverra',
        existing_name: 'Tämä nimi on jo käytössä.',
        fill_angle: 'Täyttökulma',
        fill_interval: 'Täyttöväli',
        fill_setting: 'Täyttöasetukset',
        filled_path_only: 'Vain täyttöpoluille',
        focus_adjustment: 'Tarkennuksen säätö',
        frequency: 'Taajuus',
        gradient_only: 'Vain liukuväriä varten',
        halftone: 'Puolitonni',
        halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
        height: 'Kohteen korkeus',
        ink_saturation: 'Kylläisyys',
        ink_type: {
          normal: 'Muste',
          text: 'Mustetyyppi',
          UV: 'UV-muste',
        },
        laser_speed: {
          fast: 'Nopea',
          max: 300,
          min: 3,
          slow: 'Hidas',
          step: 0.1,
          text: 'Nopeus',
          unit: 'mm/s',
        },
        low_power_warning: 'Alhainen laserteho (alle 10 %) ei välttämättä emittoi lasersädettä.',
        low_speed_warning: 'Alhainen nopeus voi aiheuttaa materiaalin palamista.',
        lower_focus: 'Laske Tarkennusta',
        lower_focus_desc: 'Fokuskorkeuden laskeminen tietyn matkan verran tarkennuksen jälkeen parantaaksesi leikkaustehokkuutta.',
        module: 'Moduuli',
        more: 'Hallitse',
        multi_layer: 'Useita tasoja',
        para_in_use: 'Tätä parametriä käytetään.',
        parameters: 'Parametrit',
        power: {
          high: 'Korkea',
          low: 'Matala',
          max: 100,
          min: 1,
          step: 0.1,
          text: 'Teho',
        },
        preset_management: {
          add_new: 'Lisää Uusi',
          delete: 'Poista',
          export: 'Vie',
          export_preset_title: 'Vie esiasetukset',
          import: 'Tuo',
          laser: 'Laser',
          lower_focus_by: 'Laske Tarkennusta',
          new_preset_name: 'Uusi esiasetuksen nimi',
          preset: 'Esiasetus',
          print: 'Tulosta',
          reset: 'Nollaa',
          save_and_exit: 'Tallenna ja poistu',
          show_all: 'Näytä Kaikki',
          sure_to_import_presets: 'Tämä lataa esiasetusten järjestelyn ja korvaa mukautetut parametrit. Oletko varma, että haluat jatkaa?',
          sure_to_reset: 'Tämä poistaa mukautetut parametrisi ja palauttaa kaikki esiasetukset, oletko varma, että haluat jatkaa?',
          title: 'Hallitse Parametrejä',
          wobble_diameter: 'Wobble-halkaisija',
          wobble_step: 'Wobble-askeleen koko',
        },
        preset_setting: 'Parametriasetukset (%s)',
        presets: 'Esiasetus',
        print_multipass: 'Monikerrospainatus',
        promark_speed_desc: 'Nopeusparametrit eivät koske liukuvärikuvia.',
        pulse_width: 'Pulssin leveys',
        pwm_advanced_desc: 'Aseta vähimmäisteho syvyystilaan.',
        pwm_advanced_hint: 'Tämä asetus koskee erityisesti liukuvärikuvia käytettäessä syvyystilaa.',
        pwm_advanced_setting: 'Syvyystilan Tehoasetukset',
        repeat: 'Ohitusmäärä',
        single_color: 'Yksivärinen',
        single_color_desc: 'Vain täysvärikerrokselle ja ei sovellu laajennettuun yksivärikerrokseen.',
        slider: {
          fast: 'Nopea',
          high: 'Korkea',
          low: 'Matala',
          regular: 'Normaali',
          slow: 'Hidas',
          very_fast: 'Nopein',
          very_high: 'Maksimi',
          very_low: 'Minimi',
          very_slow: 'Hidain',
        },
        speed: 'Nopeus',
        speed_constrain_warning: 'Vektoripolkuobjektien leikkausnopeus rajoitetaan %(limit)s. Voit poistaa tämän rajoituksen asetuksista.',
        speed_constrain_warning_auto_feeder: 'Auto Feeder -objektien leikkuunopeus rajoitetaan %(limit)s:ään. Voit poistaa tämän rajoituksen asetuksista.',
        speed_constrain_warning_curve_engraving: 'Kaiverruskäyrän nopeus rajoitetaan arvoon %(limit)s. Voit poistaa tämän rajoituksen asetuksista.',
        stepwise_focusing: 'Askeltarkennus',
        stepwise_focusing_desc: 'Fokusetäisyyden asteittainen laskeminen objektin korkeuden perusteella jokaisen ohitusmäärän aikana.',
        strength: 'Teho',
        times: 'kertaa',
        various_preset: 'Useita esiasetuksia',
        white_ink: 'Valkoinen muste',
        white_ink_settings: 'Valkoisen musteen asetukset',
        wobble: 'Värinä',
        wobble_desc: 'Luo pieniä ympyröitä reitin varrella, mikä on hyödyllistä leikkaustehtäviin. (Vain polkuobjekteille.)',
        wobble_diameter: 'Halkaisija',
        wobble_step: 'Askelkoko',
        z_step: 'Z-askel',
      },
      layer_panel: {
        current_layer: 'Nykyinen kerros',
        layer1: 'Taso 1',
        layer_bitmap: 'Bitmappi',
        layer_cutting: 'Leikkaus',
        layer_engraving: 'Kaiverrus',
        layers: {
          del: 'Poista taso',
          dupe: 'Kopioi taso',
          fullColor: 'Täysväri',
          layer: 'Taso',
          layers: 'Tasot',
          lock: 'Lukitse taso',
          merge_all: 'Yhdistä kaikki',
          merge_down: 'Yhdistä alas',
          merge_selected: 'Yhdistä valitut tasot',
          move_down: 'Siirrä taso alaspäin',
          move_elems_to: 'Siirrä elementit:',
          move_selected: 'Siirrä valitut elementit eri tasolle',
          move_up: 'Siirrä taso ylöspäin',
          new: 'Uusi taso',
          rename: 'Nimeä taso uudelleen',
          splitFullColor: 'Laajenna tasoa',
          switchToFullColor: 'Vaihda täysväritilaan',
          switchToSingleColor: 'Vaihda yksiväritilaan',
          unlock: 'Poista lukitus',
        },
        move_elems_to: 'Siirrä kuvio tasolle:',
        notification: {
          dupeLayerName: 'Samanniminen taso on jo olemassa!',
          enterNewLayerName: 'Anna uusi tason nimi',
          enterUniqueLayerName: 'Anna tasolle uniikki nimi',
          layerHasThatName: 'Tasolla on jo tuo nimi',
          mergeLaserLayerToPrintingLayerMsg: 'Huomioi, että jos suoritat tämän toiminnon, laser-tason asetukset poistetaan ja asetetaan nykyisen tason mukaisesti.',
          mergeLaserLayerToPrintingLayerTitle: 'Haluatko yhdistää nämä tasot yhdeksi tulostustasoksi?',
          mergePrintingLayerToLaserLayerMsg: 'Huomioi, että jos suoritat tämän toiminnon, tulostustason väriasetukset poistetaan ja asetetaan nykyisen tason mukaisesti.',
          mergePrintingLayerToLaserLayerTitle: 'Haluatko yhdistää nämä tasot yhdeksi laser-tasoksi?',
          moveElemFromPrintingLayerMsg: 'Huomioi, että jos suoritat tämän toiminnon, valitun elementin väriasetukset poistetaan ja asetetaan %s mukaisesti.',
          moveElemFromPrintingLayerTitle: 'Siirretäänkö valitut elementit kerrokseen %s ja muunnetaan laser-elementeiksi?',
          moveElemToPrintingLayerMsg: 'Huomioi, että jos suoritat tämän toiminnon, valitun elementin asetukset poistetaan ja asetetaan %s mukaisesti.',
          moveElemToPrintingLayerTitle: 'Siirretäänkö valittu elementti kohteeseen %s ja muunnetaan se tulostuselementiksi?',
          newName: 'UUSI NIMI',
          QmoveElemsToLayer: "Siirretäänkö valitut kuvio tasolle '%s'?",
          splitColorMsg: 'Huomioi, että jos jatkat tätä toimintoa, et voi palata alkuperäisiin väritasoihin.',
          splitColorTitle: 'Haluatko laajentaa valitun tason CMYK-tasoiksi?',
        },
      },
      object_panel: {
        actions_panel: {
          ai_bg_removal: 'Taustan poisto',
          ai_bg_removal_reminder: 'Painamalla nappia käytetään välittömästi 0,2 hyvitystä, haluatko jatkaa?',
          ai_bg_removal_short: 'TP',
          array: 'Taulukko',
          auto_fit: 'Automaattinen Sovitus',
          bevel: 'Viiste',
          brightness: 'Kirkkaus',
          convert_to_path: 'Muunna poluksi',
          create_textpath: 'Luo teksti polulle',
          create_textpath_short: 'Polun teksti',
          crop: 'Rajaa',
          decompose_path: 'Hajota',
          detach_path: 'Purkaa polun teksti',
          detach_path_short: 'Purkaa',
          disassemble_use: 'Purkaa',
          disassembling: 'Purkaa...',
          edit_path: 'Muokkaa polkua',
          fetching_web_font: 'Noudetaan verkkofonttia...',
          grading: 'Arvostelu',
          invert: 'Käännä',
          offset: 'Siirtymä',
          outline: 'Ääriviiva',
          replace_with: 'Korvaa...',
          replace_with_short: 'Korvaa',
          sharpen: 'Terävöitä',
          simplify: 'Yksinkertaista',
          smart_nest: 'Älykäs Pesä',
          trace: 'Jäljitä',
          ungrouping: 'Poista ryhmitys...',
          uploading_font_to_machine: 'Ladataan fontti koneeseen...',
          wait_for_parsing_font: 'Jäsennetään fonttia... Odota hetki',
          weld_text: 'Yhdistä teksti',
        },
        align: 'Tasaa',
        boolean: 'Boolen',
        bottom_align: 'Tasaa alas',
        center_align: 'Keskitä',
        difference: 'Ero',
        distribute: 'Jaa',
        flip: 'Käännä',
        group: 'Ryhmitä',
        hdist: 'Jaa vaakasuunnassa',
        hflip: 'Vaakasuora kääntö',
        intersect: 'Leikkaa',
        left_align: 'Tasaa vasemmalle',
        lock_aspect: 'Lukitse mittasuhde',
        middle_align: 'Keskitä pystyssä',
        option_panel: {
          color: 'Väri',
          fill: 'Täyttö',
          font_family: 'Fontti',
          font_size: 'Koko',
          font_style: 'Tyyli',
          letter_spacing: 'Kirjainväli',
          line_spacing: 'Riviväli',
          path_infill: 'Polun täyttö',
          pwm_engraving: 'Syvyystila',
          pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
          rounded_corner: 'Pyöristetty kulma',
          shading: 'Liukuväri',
          sides: 'Sivut',
          start_offset: 'Tekstin siirtymä',
          stroke: 'Ääriviiva',
          stroke_color: 'Ääriviivan väri',
          stroke_width: 'Viivan leveys',
          text_infill: 'Tekstin täyttö',
          threshold: 'Kynnysarvo',
          threshold_short: 'Kynnys',
          vertical_align: 'Tasaus',
          vertical_text: 'Pystyteksti',
        },
        path_edit_panel: {
          connect: 'Yhdistä',
          delete: 'Poista',
          disconnect: 'Katkaise yhteys',
          node_type: 'SOLMUN TYYPPI',
          round: 'Pyöreä',
          sharp: 'Terävä',
        },
        right_align: 'Tasaa oikealle',
        subtract: 'Vähennä',
        top_align: 'Tasaa ylös',
        ungroup: 'Poista ryhmitys',
        union: 'Yhdistä',
        unlock_aspect: 'Poista mittasuhteen lukitus',
        vdist: 'Jaa pystysuunnassa',
        vflip: 'Pystysuora kääntö',
        zoom: 'Lähennä',
      },
      tabs: {
        layers: 'Tasot',
        objects: 'Kuvio',
        path_edit: 'Polun muokkaus',
      },
    },
    shapes_panel: {
      animals: 'Eläin',
      arrow: 'Nuoli',
      basic: 'Perus',
      birds: 'Lintu',
      celebration: 'Juhlinta',
      circular: 'Ympyrä',
      CNY: 'Kiinalainen uusivuosi',
      corner: 'Kulma',
      decor: 'Koriste',
      easter: 'Pääsiäinen',
      elements: 'Alkuaineet',
      environment: 'Ympäristö',
      graphics: 'Grafiikka',
      halloween: 'Halloween',
      holidays: 'Juhlapäivä',
      label: 'Teksti',
      land: 'Maa',
      line: 'Viiva',
      nature: 'Luonto',
      photo: 'Valokuva',
      plants: 'Kasvit',
      ribbon: 'Nauha',
      sea: 'Meri',
      shape: 'Muoto',
      speech: 'Puhe',
      text: 'Tekstikehys',
      title: 'Kuvakkeet',
      valentines: 'Ystävänpäivä',
      weather: 'Sää',
      Xmas: 'Joulu',
    },
    svg_editor: {
      unable_to_fetch_clipboard_img: 'Leikepöydältä kuvan haku epäonnistui',
      unnsupported_file_type: 'Tiedostotyyppiä ei tueta suoraan. Muunna tiedosto SVG:ksi tai bittikartaksi',
    },
    tag: {
      g: 'Ryhmä',
      image: 'Kuva',
      text: 'Teksti',
      use: 'Tuo SVG',
    },
    time_est_button: {
      calculate: 'Arvioi aika',
      estimate_time: 'Arvioitu aika:',
    },
    tool_panels: {
      _nest: {
        end: 'Sulje',
        no_element: 'Ei ole järjestettäviä elementtejä',
        rotations: 'Mahdolliset kierrot',
        spacing: 'Väli',
        start_nest: 'Järjestä',
        stop_nest: 'Pysäytä',
      },
      _offset: {
        corner_type: 'Kulma',
        direction: 'Siirtymän suunta',
        dist: 'Siirtymän etäisyys',
        fail_message: 'Siirtymä epäonnistui.',
        inward: 'Sisäänpäin',
        not_support_message: 'Valitut elementit sisältävät SVG-tageja, joita ei tueta:\nKuva, Ryhmä, Teksti ja Tuotu objekti.',
        outward: 'Ulospäin',
        round: 'Pyöreä',
        sharp: 'Terävä',
      },
      array_dimension: 'Ruudukon mitat',
      array_interval: 'Ruudukon väli',
      cancel: 'Peruuta',
      columns: 'Sarakkeet',
      confirm: 'Vahvista',
      dx: 'X',
      dy: 'Y',
      grid_array: 'Luo ruudukko',
      nest: 'Järjestelyoptimointi',
      offset: 'Siirtymä',
      rows: 'Rivit',
    },
    units: {
      mm: 'mm',
      walt: 'W',
    },
    zoom_block: {
      fit_to_window: 'Sovita ikkunaan',
    },
  },
  boxgen: {
    add_option: 'Lisää vaihtoehto',
    basic_box: 'Peruslaatikko',
    beam_radius: 'Säteen kompensaatio',
    beam_radius_warning: 'Poista saumakompensaatio, kun laatikon reunat tai liitokset ovat lyhyitä, jotta laatikon kokoaminen onnistuu',
    cancel: 'Peruuta',
    coming_soon: 'Tulossa pian',
    continue_import: 'Jatka tuontia',
    control_tooltip: 'Vasen hiiren painike kääntää\nRulla lähentääksesi\nOikea hiiren painike siirtää',
    control_tooltip_touch: 'Vedä kääntääksesi\nNipistä lähentääksesi\nKahdella sormella siirtää',
    cover: 'Kansi',
    customize: 'Mukauta',
    depth: 'Syvyys',
    edge: 'Reuna',
    finger: 'Sormi',
    finger_warning: 'Laatikon sisäpuolen sivun pituuden on oltava vähintään 6 mm (0,24 tuumaa), jotta se on yhteensopiva sormiliitoksen kanssa.',
    height: 'Korkeus',
    import: 'Tuo',
    inner: 'Sisäinen',
    joints: 'Liitos',
    max_dimension_tooltip: 'Maksimileveys/korkeus/syvyys on %s.',
    merge: 'Yhdistä',
    outer: 'Ulkoinen',
    reset: 'Nollaa',
    tCount: 'T-määrä',
    tCount_tooltip: 'T-urien määrä koskee lyhyttä sivua; pitkän sivun määrä lasketaan sen pituuden mukaan.',
    tDiameter: 'T-halkaisija',
    text_label: 'Tekstikenttä',
    thickness: 'Paksuus',
    title: 'BOXGEN',
    tLength: 'T-pituus',
    tSlot: 'T-ura',
    tSlot_warning: 'Laatikon sivun pituuden on oltava vähintään 30 mm (1,18 tuumaa), jotta se on yhteensopiva T-uriliitoksen kanssa.',
    volume: 'Tilavuus',
    width: 'Leveys',
    workarea: 'Työalue',
    zoom: 'Lähennä',
  },
  buttons: {
    back: 'TAKAISIN',
    back_to_beam_studio: 'Takaisin Beam Studioon',
    done: 'VALMIS',
    next: 'SEURAAVA',
  },
  calibration: {
    ador_autofocus_focusing_block: 'Paina koneen pääsivulla "AF"-kuvaketta 3 sekunnin ajan ja anna anturin koskettaa tarkennuslohkoa.',
    ador_autofocus_material: 'Paina pääsivulla "AF"-kuvaketta 3 sekunnin ajan ja anna anturin koskettaa materiaalia kevyesti.',
    align_ilb: 'Sijainti: Kohdista kaiverrettuun pisteeseen vasemmassa alakulmassa sisäpuolella.',
    align_ilt: 'Sijainti: Kohdista kaiverrettuun pisteeseen vasemmassa yläkulmassa sisäpuolella.',
    align_irb: 'Sijainti: Kohdista kaiverrettuun pisteeseen oikeassa alakulmassa sisäpuolella.',
    align_irt: 'Sijainti: Kohdista kaiverrettuun pisteeseen oikeassa yläkulmassa sisäpuolella.',
    align_olb: 'Sijainti: Kohdista kaiverrettuun pisteeseen vasemmassa alakulmassa ulkopuolella.',
    align_olt: 'Sijainti: Kohdista kaiverrettuun pisteeseen vasemmassa yläkulmassa ulkopuolella.',
    align_orb: 'Sijainti: Kohdista kaiverrettuun pisteeseen oikeassa alakulmassa ulkopuolella.',
    align_ort: 'Sijainti: Kohdista kaiverrettuun pisteeseen oikeassa yläkulmassa ulkopuolella.',
    align_red_cross_cut: 'Kohdista punaisen ristin keskikohta leikkausristin kanssa.',
    align_red_cross_print: 'Kohdista punaisen ristin keskikohta tulostetun ristin kanssa.',
    analyze_result_fail: 'Kuvan analysointi epäonnistui.<br/>Tarkista:<br/>1. Kuvassa on kokonaan valkoinen paperi.<br/>2. Työtaso on tarkennettu oikein.',
    ask_for_readjust: 'Haluatko ohittaa kaiverrusvaiheen ja ottaa kuvan ja kalibroida suoraan?',
    back: 'Takaisin',
    calculating_camera_matrix: 'Lasketaan kameramatriisia...',
    calculating_regression_parameters: 'Lasketaan regressioparametreja...',
    calibrate_camera_before_calibrate_modules: 'Suorita kamerakalibrointi ennen moduulien kalibrointia.',
    calibrate_chessboard_success_msg: 'Shakkilautakuva otettiin onnistuneesti.<br/>Tämän kuvan pistemäärä on %s (%.2f).',
    calibrate_done: 'Kalibrointi valmis. Tarkempi kameratarkkuus saavutetaan tarkalla tarkennuksella.',
    calibrate_done_diode: 'Kalibrointi valmis. Diodilasermoduulin poikkeama on tallennettu.',
    calibrating: 'Kalibrointi...',
    calibrating_with_device_pictures: 'Kalibroidaan laitteen kuvien avulla...',
    camera_calibration: 'Kameran kalibrointi',
    camera_parameter_saved_successfully: 'Kameran parametrit tallennettu onnistuneesti.',
    cancel: 'Peruuta',
    check_checkpoint_data: 'Tarkistuspisteen tiedot',
    check_device_pictures: 'Tarkista laitteen kuvat',
    checking_checkpoint: 'Tarkistetaan tarkistuspisteen tietoja...',
    checking_pictures: 'Tarkistetaan laitteen kuvia...',
    diode_calibration: 'Diodilasermoduulin kalibrointi',
    do_engraving: 'Tee Kaiverrus',
    download_chessboard_file: 'Lataa shakkiruudukko-tiedosto',
    downloading_checkpoint: 'Ladataan tarkistuspisteen tietoja...',
    downloading_pictures: 'Ladataan kuvia...',
    drawing_calibration_image: 'Piirretään kalibrointikuva...',
    dx: 'X',
    dy: 'Y',
    elevate_and_cut: 'Nosta ja Leikkaa',
    elevate_and_cut_step_1: 'Aseta A4-kokoinen vaaleanvärinen puu työtilan keskelle ja nosta se 20 mm korkeuteen.',
    elevate_and_cut_step_1_prism_lift: 'Käytä Ador Prism Liftiä, jonka maksimipituus on 14 mm, yhdessä vähintään 6 mm paksun puun kanssa.',
    failed_to_calibrate_camera: '#848 Kameran kalibrointi epäonnistui, ota yhteyttä FLUX-tukeen.',
    failed_to_calibrate_chessboard: 'Kalibrointi shakkilautakuvan kanssa epäonnistui.',
    failed_to_calibrate_with_pictures: '#848 Kalibrointi laitteen kuvilla epäonnistui.',
    failed_to_download_pictures: '#848 Kuvien lataus epäonnistui, ota yhteyttä FLUX-tukeen.',
    failed_to_move_laser_head: 'Laserpään siirtäminen epäonnistui.',
    failed_to_parse_checkpoint: 'Tarkistuspisteen tietojen jäsentäminen epäonnistui.',
    failed_to_save_calibration_results: '#849 Kalibrointitulosten tallennus epäonnistui, yritä uudelleen. Jos ongelma jatkuu, ota yhteyttä FLUX-tukeen.',
    failed_to_save_camera_parameter: 'Kameran parametrien tallennus epäonnistui.',
    failed_to_solve_pnp: 'Kameran sijaintia ei voitu ratkaista.',
    finish: 'Valmis',
    found_checkpoint: 'Tarkistuspisteen tiedot löytyivät laitteestasi. Haluatko palauttaa ne tarkistuspisteestä?',
    getting_plane_height: 'Haetaan Tasokorkeutta...',
    hint_adjust_parameters: 'Käytä näitä parametreja punaisen neliön säätämiseen',
    hint_red_square: 'Kohdista punaisen neliön ulkoreuna leikatun neliön kanssa.',
    module_calibration_2w_ir: 'Infrapunamoduulin kalibrointi',
    module_calibration_printer: 'Tulostinmoduulin kalibrointi',
    moving_laser_head: 'Siirtää laserpäätä...',
    next: 'Seuraava',
    no_picutre_found: '#846 Laitteellasi ei ole saatavilla raakakuvia kalibrointia varten. Ota yhteyttä FLUX-tukeen.',
    perform_autofocus_bb2: 'Mene laitteen ohjauspaneeliin ja paina AF suorittaaksesi automaattitarkennuksen.',
    please_do_camera_calibration_and_focus: {
      beambox: 'Diodilasermoduulin kalibrointi vaatii kameran.\nVarmista, että tämän koneen kamera on kalibroitu.\nJa säädä alusta polttopisteeseen (käännetyn akryylin korkeus)',
      beamo: 'Diodilasermoduulin kalibrointi vaatii kameran.\nVarmista, että tämän koneen kamera on kalibroitu.\nJa kohdista laserpää kaiverrettavaan kohteeseen (käännetyn akryylin korkeus)',
    },
    please_goto_beambox_first: 'Siirry kaiverrustilaan (Beambox) käyttääksesi tätä ominaisuutta.',
    please_place_dark_colored_paper: 'Aseta A4- tai kirjekokoinen tumma paperi työalueen keskelle.',
    please_place_paper: 'Aseta A4- tai kirjekokoinen valkoinen paperi työalueen vasempaan ylänurkkaan.',
    please_place_paper_center: 'Aseta A4- tai kirjekokoinen valkoinen paperi työalueen keskelle.',
    please_refocus: {
      beambox: 'Säädä työtasoa polttoetäisyydelle (käännetyn akryylin korkeus).',
      beamo: 'Säädä laserpäätä kohdistumaan kaiverrettavaan esineeseen (käännetyn akryylin korkeus).',
      beamo_af: 'Napauta kaksoisnapautus autofokus-lisälaitteen sivupainiketta ja anna anturin koskettaa materiaalia kevyesti.',
      hexa: 'Napsauta korkeudensäätöpainiketta kahdesti nostaaksesi hunajakennopöydän ylös ja saadaksesi anturin koskettamaan kaiverrettavaa materiaalia.',
    },
    preparing_to_take_picture: 'Valmistaudutaan valokuvan ottamiseen...',
    promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    put_chessboard: 'Aseta Shakkilauta',
    put_chessboard_1: 'Mene laitteen ohjauspaneeliin ja paina AF suorittaaksesi automaattitarkennuksen.',
    put_chessboard_2: 'Siirrä shakkilautapaperia tai laserpäätä, kunnes koko shakkilauta vahvistetaan olevan punaisen kehyksen sisällä live-esikatseluikkunassa.',
    put_chessboard_3: 'Napsauta hiiren oikealla painikkeella ladataksesi shakkilautakuvan nykyisestä sijainnista ja tarkistaaksesi sen selkeyden.',
    put_chessboard_bb2_desc_1: 'Lataa seuraava shakkilautatiedosto ja tulosta se A4-paperille (tulostetun shakkilaudan neliöiden koko tulee olla 1x1 cm).',
    put_chessboard_bb2_desc_2: 'Kiinnitä tulostettu shakkilauta muotonsa pitävälle alustalle, kuten akryylille tai lasille, varmistaen, että lauta on sileä eikä siinä ole ryppyjä tai kohoumia.',
    put_chessboard_bb2_desc_3: 'Aseta alusta, jossa on shakkilauta, tasaisesti työalueen keskelle.',
    put_chessboard_promark_1: 'Säädä polttoväli sopivaksi kenttälinssin tarkennukselle.',
    put_chessboard_promark_2: 'Varmista, että shakkilautapaperi on selkeästi näkyvissä ilman häikäisyä. Napsauta "Seuraava" ottaaksesi kuvan.',
    put_chessboard_promark_desc_1: 'Käytä tarvikelaatikossa mukana toimitettua shakkilautapaperia tai tulosta seuraava shakkikuvio A4-paperille kameran kalibrointia varten.',
    put_chessboard_promark_desc_2: 'Säädä tarkennus oikein ja napsauta sitten "Seuraava" jatkaaksesi kaiverrusta.',
    put_paper: 'Aseta paperi',
    put_paper_promark_1: 'Aseta musta pahvi tarvikelaatikosta työalustalle.',
    put_paper_promark_2: 'Säädä tarkennus oikein ja napsauta sitten "Aloita Kaiverrus" jatkaaksesi kaiverrusta.',
    put_paper_skip: 'Jos kalibrointipiirros ei siirry automaattisesti kuvauksen ottamiseen, paina "Ohita" ottaaksesi kuvan.',
    put_paper_step1: 'Aseta A4- tai kirjekoon valkoinen paperi työalueen keskelle.',
    put_paper_step2: 'Kiinnitä paperin neljä kulmaa varmistaaksesi, että se on tasainen.',
    put_paper_step3: 'Napsauta "Aloita Kaiverrus".',
    res_average: 'Keskitasoinen',
    res_excellent: 'Erinomainen',
    res_poor: 'Heikko',
    retake: 'Ota kuva uudelleen',
    rotation_angle: 'Kierto',
    show_last_config: 'Näytä viimeisin tulos',
    skip: 'Ohita',
    solve_pnp_step1: 'Kohdista kaiverruspisteet punaisen merkintäpisteen numeron ja odotetun sijainnin mukaan.',
    solve_pnp_step2: 'Voit painaa "Ota kuva uudelleen" uudelleen suuntautua tai säätää merkkipisteiden sijaintia manuaalisesti.',
    solve_pnp_step3: 'Jos otettu kuva ei vastaa todellista tilannetta, zoomaa tai vedä näyttöä säätääksesi kalibrointipisteen mustassa kalibrointikortissa keskelle, ja kokeile sitten "Ota kuva uudelleen".',
    solve_pnp_title: 'Kohdista merkkipisteet',
    start_engrave: 'Aloita Kaiverrus',
    start_printing: 'Aloita Tulostus',
    taking_picture: 'Otetaan kuvaa...',
    unable_to_load_camera_parameters: "#851 Laitteellasi ei ole saatavilla kameran kalibrointiparametreja. Siirry kohtaan 'Kalibrointi' > 'Kalibroi kamera (Edistynyt)' suorittaaksesi kalibroinnin ja saadaksesi parametrit.",
    update_firmware_msg1: 'Firmwaresi ei tue tätä toimintoa. Päivitä firmwareen v',
    update_firmware_msg2: 'tai uudempaan jatkaaksesi。 (Valikko > Kone > [Sinun koneesi] > Päivitä firmware)',
    uploading_images: 'Lähetetään kuvia...',
    use_last_config: 'Käytä viimeisintä kalibrointiarvoa',
    use_old_camera_parameter: 'Haluatko käyttää nykyisiä kameran linssiparametreja?',
    with_af: 'Autofokuksella',
    with_prism_lift: 'Ador Prism Liftin kanssa',
    without_af: 'Ilman autofokusta',
    without_prism_lift: 'Ilman Ador Prism Liftiä',
    x_ratio: 'X-suhde',
    y_ratio: 'Y-suhde',
    zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
  },
  camera_data_backup: {
    download_success: 'Kameran tiedot on ladattu onnistuneesti.',
    downloading_data: 'Tietojen lataaminen...',
    estimated_time_left: 'Arvioitu jäljellä oleva aika:',
    folder_not_exists: 'Valittu kansio ei ole olemassa.',
    incorrect_folder: 'Kalibrointitietojen lataaminen epäonnistui. Tarkista, että valitsemasi kansio on oikea.',
    no_picture_found: 'Kuvaa ei löytynyt laitteesta.',
    title: 'Kameran tietojen varmuuskopiointi',
    upload_success: 'Kameran tiedot on ladattu onnistuneesti.',
    uploading_data: 'Tietojen lataaminen...',
  },
  caption: {
    connectionTimeout: 'Yhteyden aikakatkaisu',
  },
  change_logs: {
    added: 'Lisätty:',
    change_log: 'Muutosloki:',
    changed: 'Muutettu:',
    fixed: 'Korjattu:',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    see_older_version: 'Katso vanhempia versioita',
  },
  code_generator: {
    barcode: 'Viivakoodi',
    qr_code: 'QR-koodi',
  },
  curve_engraving: {
    '921': 'Automaattitarkennus epäonnistui.',
    '922': 'Punavalomittaus epäonnistui.',
    amount: 'Määrä',
    apply_arkwork: 'Lisää taideteos käyrälle',
    apply_camera: 'Lisää kamerakuva käyrälle',
    click_to_select_point: 'Napsauta valitaksesi tai poistaaksesi valinnan mittauksesta.',
    coloumns: 'Sarakkeiden määrä',
    column_gap: 'Sarakeväli',
    err_object_over_range: 'Kohde ylittää mittausalueen.',
    failed_to_take_reference: 'Referenssin ottaminen epäonnistui',
    gap: 'Välirako',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    measure_audofocus_area: 'Mittaa automaattitarkennusalue',
    preview_3d_curve: 'Esikatselu käyrän kaiverruksesta',
    remeasure: 'Mittaa uudelleen',
    remeasuring_points: 'Mittauspisteiden uudelleenmittaus...',
    reselect_area: 'Valitse alue uudelleen',
    row_gap: 'Riviväli',
    rows: 'Rivien määrä',
    set_object_height: 'Aseta objektin korkeus',
    set_object_height_desc: 'Mittaa objektin suurin paksuus.',
    start_autofocus: 'Käynnistä automaattitarkennus',
    starting_measurement: 'Aloitetaan mittaus...',
    sure_to_delete: 'Haluatko poistaa käyräkaiverruksen tarkennustiedot?',
    take_reference: 'Ota referenssi',
    take_reference_desc: 'Siirrä laserpää objektin korkeimpaan kohtaan, laske tarkennusanturi ja napsauta "Vahvista" tarkentaaksesi.',
  },
  device: {
    abort: 'Keskeytä',
    aborted: 'Keskeytetty',
    aborting: 'Keskeytetään',
    busy: 'Varattu',
    cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
    cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
    cartridge_serial_number: 'Ink cartridge serial number',
    close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
    completed: 'Valmis',
    completing: 'Viimeistelee',
    deviceList: 'Laiteluettelo',
    disable: 'Poista käytöstä',
    disconnectedError: {
      caption: 'Laite irrotettu',
      message: 'Varmista, onko %s:n verkkoyhteys käytettävissä',
    },
    enable: 'Ota käyttöön',
    firmware_version: 'Laiteohjelmiston versio',
    ink_color: 'Ink color',
    ink_level: 'Ink level',
    ink_type: 'Ink type',
    IP: 'IP',
    model_name: 'Mallin nimi',
    occupied: 'Huoltaa',
    pause: 'Keskeytä',
    paused: 'Keskeytetty',
    pausedFromError: 'Keskeytetty virheestä',
    pausing: 'Keskeytetään',
    please_wait: 'Odota...',
    preparing: 'Valmistelee',
    processing: 'Käsittelee',
    quit: 'Lopeta',
    ready: 'Valmis',
    reset: 'Nollaa',
    resuming: 'Jatkaa',
    retry: 'Yritä uudelleen',
    running: 'Työskentelee',
    scanning: 'Skannaa',
    select: 'Valitse',
    select_printer: 'Valitse tulostin',
    serial_number: 'Sarjanumero',
    start: 'Käynnistä',
    starting: 'Käynnistyy',
    status: 'Tila',
    submodule_type: 'Module',
    toolhead_change: 'Vaihda työkalupää',
    unknown: 'Tuntematon',
    uploading: 'Lähettää',
    UUID: 'UUID',
  },
  device_selection: {
    no_beambox: '#801 Emme löydä laitettasi verkosta.\nNoudata <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">ohjetta</a> yhteysongelman vianmääritykseen.',
    no_device_web: "#801 Tarkista koneesi tila tai napsauta alla olevaa 'Koneen asetukset' asettaaksesi koneen.",
    select_usb_device: 'Valitse USB-laite',
  },
  editor: {
    exposure: 'Esikatselun altistus',
    opacity: 'Esikatselun läpinäkyvyys',
    prespray_area: 'Esisuihkutusalue',
  },
  error_pages: {
    screen_size: 'Huomaa, että Beam Studio ei välttämättä toimi optimaalisesti laitteellasi. Paras kokemus saadaan näytöllä, jonka leveys on vähintään 1024 pikseliä.',
  },
  flux_id_login: {
    connection_fail: '#847 Yhteyden muodostaminen FLUX-jäsenyyteen epäonnistui.',
    email: 'Sähköposti',
    flux_plus: {
      access_monotype_feature: 'Sinulla ei ole Monotype-fontteja.',
      access_monotype_feature_note: 'Sinulla on oltava FLUX+ Pro -jäsenyys tai Monotype-fontit lisäosa käyttääksesi tätä ominaisuutta.',
      access_plus_feature_1: 'Käytät',
      access_plus_feature_2: 'ominaisuutta.',
      access_plus_feature_note: 'Sinulla on oltava FLUX+ -jäsenyys käyttääksesi tätä ominaisuutta.',
      ai_credit_tooltip: 'Taustan poistoon tekoälyllä',
      explore_plans: 'Tutustu FLUX+ -suunnitelmiin',
      features: {
        ai_bg_removal: 'AI-taustanpoisto',
        boxgen: '3D-laatikon luonti',
        dmkt: 'Yli 1000 suunnittelutiedostoa',
        monotype: 'Yli 250 Premium-fonttia',
        my_cloud: 'Rajoittamaton pilvitallennustila',
      },
      flux_credit_tooltip: 'Design Market -tiedostoihin ja tekoälyn taustanpoistoon',
      get_addon: 'Hanki lisäosa',
      goto_member_center: 'Siirry jäsenkeskukseen',
      learn_more: 'Lue lisää',
      member_center_url: 'https://member.flux3dp.com/en-US/subscription',
      subscribe_now: 'Tilaa nyt',
      thank_you: 'Kiitos arvokkaasta jäsenyydestä!',
      website_url: 'https://flux3dp.com/subscription',
    },
    forget_password: 'Unohtunut salasana?',
    incorrect: 'Virheellinen sähköpostiosoite tai salasana',
    login: 'Kirjaudu sisään',
    login_success: 'Kirjautuminen onnistui.',
    lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
    new_to_flux: 'Uusi käyttäjä? Luo tili',
    not_verified: 'Sähköpostiosoitetta ei ole vielä vahvistettu',
    offline: 'Työskentele offline-tilassa',
    password: 'Salasana',
    register: 'Luo FLUX-tili',
    remember_me: 'Muista minut',
    signup_url: 'https://id.flux3dp.com/user/login#up',
    unlock_shape_library: 'Kirjaudu sisään avataksesi muotokirjaston.',
    work_offline: 'Työskentele offline-tilassa',
  },
  framing: {
    area_check: 'Alueen tarkistus',
    areacheck_desc: 'Varmistaa työalueen turvallisuuden näyttämällä objektin rajauslaatikon ja laserpään kiihdytysvyöhykkeen.',
    calculating_task: 'Lasketaan tehtävää...',
    framing: 'Kehystys',
    framing_desc: 'Esikatselee objektin raja-laatikon.',
    hull: 'Runko',
    hull_desc: 'Esikatselu, joka seuraa tiiviisti muotoilua, kuin kuminauha kietoutuneena objektin ympärille.',
    low_laser: 'Matala laser',
    low_laser_desc: 'Aseta pienitehoinen laserteho kehyksen tehtävää varten.',
    rotate_axis: 'Kierron akseli',
    rotateaxis_desc: 'Esikatselee kierron akselikeskusta, itse akseli ei liiku.',
    rotation_frame_warning: 'Kehyksen esikatselu pyörii kerran. Älä keskeytä kesken, jotta merkintäpaikka ei siirry.',
    rotation_framing_desc: 'Esikatselee koko kierron alueen, akseli pyörii esikatselun mukaan.',
    start_task: 'Aloita Tehtävä',
    start_task_description: 'Kehyksen esikatselun jälkeen napsauta "Aloita Tehtävä" lähettääksesi työn.',
  },
  general: {
    choose_folder: 'Valitse kansio',
    processing: 'Käsitellään...',
  },
  generic_error: {
    OPERATION_ERROR: '[OE] Tilakonflikti tapahtui, yritä toimintoa uudelleen.',
    SUBSYSTEM_ERROR: '[SE] Virhe, kun laiteohjelmisto suoritti tehtävää. Käynnistä laite uudelleen.',
    UNKNOWN_COMMAND: '[UC] Päivitä laitteen laiteohjelmisto',
    UNKNOWN_ERROR: '[UE] Tuntematon virhe. Käynnistä Beam Studio ja laite uudelleen.',
  },
  global: {
    apply: 'Käytä',
    back: 'Takaisin',
    cancel: 'Peruuta',
    editing: {
      redo: 'Tee uudelleen',
      reset: 'Palauta',
      undo: 'Kumoa',
      zoom_in: 'Lähennä',
      zoom_out: 'Loitonna',
    },
    mode_conflict: 'Tämä vaihtoehto ei ole käytettävissä nykyisessä tilassa.',
    ok: 'OK',
    save: 'Tallenna',
    stop: 'Pysäytä',
  },
  image_edit_panel: {
    eraser: {
      brush_size: 'Siveltimen koko',
      description: 'Klikkaa tai vedä poistaaksesi ei-toivotut alueet manuaalisesti.',
      title: 'Pyyhekumi',
    },
    magic_wand: {
      description: 'Valitse ja poista vierekkäiset alueet värien samankaltaisuuden perusteella.',
      title: 'Taikasauva',
      tolerance: 'Toleranssi',
    },
    title: 'Muokkaa kuvaa',
  },
  initialize: {
    back: 'Takaisin',
    cancel: 'Peruuta',
    confirm: 'Vahvista',
    connect: 'Yhdistä',
    connect_ethernet: {
      title: 'Suora yhteys',
      tutorial1: '1. Yhdistä laite tietokoneeseesi Ethernet-kaapelilla.',
      tutorial2_1: '2. Noudata ',
      tutorial2_2: ' tehdäksesi tietokoneestasi reitittimen.',
      tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
      tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
      tutorial2_a_text: 'tätä ohjetta',
      tutorial3: '3. Paina Seuraava.',
    },
    connect_machine_ip: {
      alert: {
        swiftray_connection_error: 'Yhteyttä palvelimeen ei voitu muodostaa. Käynnistä Beam Studio uudelleen ja yritä uudelleen.',
      },
      check_camera: 'Tarkistetaan kameran saatavuus',
      check_connection: 'Tarkistetaan laitteen yhteyttä',
      check_firmware: 'Tarkistetaan laiteohjelmiston versio',
      check_ip: 'Tarkistetaan IP-osoitteen saatavuus',
      check_swiftray_connection: 'Tarkistetaan yhteys palvelimeen',
      check_swiftray_connection_unreachable: 'palvelin ei ole saavutettavissa',
      check_usb: 'Tarkista USB-yhteys',
      enter_ip: 'Anna laitteesi IP-osoite',
      finish_setting: 'Aloita luominen!',
      invalid_format: 'Virheellinen muoto',
      invalid_ip: 'Virheellinen IP: ',
      promark_hint: 'Jos USB-yhteyden muodostaminen epäonnistuu toistuvasti, katso <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">Ohjekeskuksen artikkeli</a>.',
      retry: 'Yritä uudelleen',
      starts_with_169254: 'Alkaa numerolla 169.254',
      succeeded_message: 'Yhteys muodostettu onnistuneesti 🎉',
      unreachable: 'IP-osoite ei vastaa',
    },
    connect_usb: {
      connect_camera: 'Yhdistä laitteen kamera tietokoneeseesi USB-kaapelilla.',
      title: 'USB-yhteys',
      title_sub: ' (vain HEXA)',
      turn_off_machine: 'Sammuta kone.',
      turn_on_machine: 'Käynnistä kone.',
      tutorial1: '1. Yhdistä laite tietokoneeseesi USB-kaapelilla.',
      tutorial2: '2. Paina Seuraava.',
      wait_for_turning_on: 'Paina "Seuraava" kun käynnistys on valmis ja olet päävalikossa.',
    },
    connect_wifi: {
      title: 'Yhdistetään Wi-Fiin',
      tutorial1: '1. Siirry kosketusnäytössä kohtaan Verkko > Valitse "Yhdistä WiFiin".',
      tutorial1_ador: '1. Siirry kosketusnäytölle > Paina "KONE" > Paina "Verkko" > "Yhdistä WiFiin".',
      tutorial2: '2. Valitse ja yhdistä haluamasi Wi-Fi.',
      what_if_1: 'Entä jos en näe Wi-Fiäni?',
      what_if_1_content: '1. Wi-Fi-salauksen tilan tulisi olla WPA2 tai ei salasanaa.\n2. Salaustilan voi asettaa Wi-Fi-reitittimen hallintaliittymässä. Jos reititin ei tue WPA2:ta ja tarvitset apua oikean reitittimen valinnassa, ota yhteyttä FLUX-tukeen.',
      what_if_2: 'Mitä teen, jos en näe yhtään Wi-Fi-verkkoa?',
      what_if_2_content: '1. Varmista, että Wi-Fi-tikku on kytketty kunnolla.\n2. Jos kosketusnäytöllä ei näy langattoman verkon MAC-osoitetta, ota yhteyttä FLUX-tukeen.\n3. Wi-Fi-kanavan tulisi olla 2,4 GHz (5 GHz ei ole tuettu).',
    },
    connect_wired: {
      title: 'Langallisen verkon yhdistäminen',
      tutorial1: '1. Yhdistä laite reitittimeesi.',
      tutorial2: '2. Paina "Verkko" saadaksesi langallisen verkon IP:n.',
      tutorial2_ador: '2. Paina "KONE" > "Verkko" saadaksesi langallisen verkon IP:n.',
      what_if_1: 'Mitä teen, jos IP on tyhjä?',
      what_if_1_content: '1. Varmista, että Ethernet-kaapeli on kytketty kunnolla.\n2. Jos kosketusnäytöllä ei näy langallisen verkon MAC-osoitetta, ota yhteyttä FLUX-tukeen.',
      what_if_2: 'Mitä teen, jos IP alkaa numerolla 169?',
      what_if_2_content: '1. Jos IP-osoite alkaa numerolla 169.254, kyseessä on todennäköisesti DHCP-asetusten ongelma, ota yhteyttä internetpalveluntarjoajaasi lisäavun saamiseksi.\n2. Jos tietokoneesi yhdistyy internetiin suoraan käyttäen PPPoE:tä, vaihda käyttämään reititintä PPPoE-yhteyden muodostamiseen ja ota DHCP käyttöön reitittimessä.',
    },
    connecting: 'Yhdistetään...',
    connection_types: {
      ether2ether: 'Suora yhteys',
      usb: 'USB-yhteys',
      wifi: 'Wi-Fi',
      wired: 'Langallinen verkko',
    },
    next: 'Seuraava',
    no_machine: 'Minulla ei ole konetta nyt.',
    promark: {
      configuration_confirmation: 'Tämä varmistaa, että Promark on oikein määritetty optimaalista tehokkuutta ja tarkkuutta varten.',
      or_complete_later: `Tai ohita tämä vaihe ja viimeistele Promark-asetukset myöhemmin:
    Koneet > "Promark-nimi" > Promark-asetukset`,
      qc_instructions: 'Täytä parametrit "QC Pass" -kortin takapuolella',
      select_laser_source: 'Valitse Promarkisi',
      select_workarea: 'Valitse työalue',
      settings: 'Promark-asetukset',
    },
    retry: 'Yritä uudelleen',
    select_beambox: 'Valitse Beamboxisi',
    select_connection_type: 'Miten haluat muodostaa yhteyden?',
    select_language: 'Valitse kieli',
    select_machine_type: 'Valitse mallisi',
    setting_completed: {
      back: 'Takaisin',
      great: 'Tervetuloa Beam Studioon',
      ok: 'ALOITA LUOMINEN',
      setup_later: 'Voit määrittää laitteesi myöhemmin yläpalkista kohdasta "Laitteet" > "Laitteen asetukset"',
      start: 'Aloita',
    },
    skip: 'Ohita',
    start: 'Aloita',
  },
  input_machine_password: {
    connect: 'YHDISTÄ',
    password: 'Salasana',
    require_password: '"%s" vaatii salasanan',
  },
  insecure_websocket: {
    extension_detected: 'Havaittu Beam Studio Connect -laajennus',
    extension_detected_description: "Olemme havainneet, että olet asentanut Beam Studio Connect -laajennuksen. Napsauta 'Vahvista' ohjataksesi HTTPS:ään tai napsauta 'Peruuta' jatkaaksesi HTTP:n käyttöä.",
    extension_not_deteced: 'Beam Studio Connect -laajennusta ei voitu havaita',
    extension_not_deteced_description: "Käyttääksesi HTTPS:ää, napsauta 'Vahvista' asentaaksesi Beam Studio Connect -laajennuksen. Asennettuasi laajennuksen, päivitä sivu aktivoidaksesi sen.<br/>Muussa tapauksessa napsauta alla olevaa linkkiä nähdäksesi, miten HTTP käytetään Chromessa.",
    unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Linkki</a>',
  },
  layer_module: {
    general_laser: 'Laser',
    laser_2w_infrared: '2 W infrapunainen laser',
    laser_10w_diode: '10 W diodilaser',
    laser_20w_diode: '20 W diodilaser',
    non_working_area: 'Ei-Työskentelyalue',
    none: 'None',
    notification: {
      convertFromLaserModuleMsg: 'Huomaa, että jos suoritat tämän toiminnon, laserkerroksen asetukset poistetaan ja asetetaan nykyisen kerroksen mukaan.',
      convertFromLaserModuleTitle: 'Haluatko muuntaa lasermoduulin tulostusmoduuliksi?',
      convertFromPrintingModuleMsg: 'Huomaa, että jos suoritat tämän toiminnon, tulostuskerroksen väriasetukset poistetaan ja asetetaan nykyisen kerroksen mukaan.',
      convertFromPrintingModuleTitle: 'Haluatko muuntaa tulostusmoduulin lasermoduuliksi?',
      importedDocumentContainsPrinting: 'Asiakirja sisältää tulostuskerroksen, haluatko vaihtaa työalueen Adoriin?',
      performIRCaliMsg: 'Napsauta "Vahvista" suorittaaksesi kalibroinnin, tai käytä kalibrointia ylävalikon kautta.<br />(Kone > [Koneen nimi] > Kalibroi infrapunamoduuli)',
      performIRCaliTitle: 'Suorita infrapunamoduulin kalibrointi',
      performPrintingCaliMsg: 'Napsauta "Vahvista" suorittaaksesi kalibroinnin, tai käytä kalibrointia ylävalikon kautta.<br />(Kone > [Koneen nimi] > Kalibroi tulostusmoduuli)',
      performPrintingCaliTitle: 'Suorita tulostusmoduulin kalibrointi',
      printingLayersCoverted: 'Tulostuskerrokset on muunnettu laserkerroksiksi.',
    },
    printing: 'Tulostus',
    unknown: 'Unknown Module',
  },
  machine_status: {
    '-17': 'Kasetin IO-tila',
    '-10': 'Huoltotila',
    '-2': 'Skannaa',
    '-1': 'Huoltaa',
    '0': 'Käyttämätön',
    '1': 'Alustaa',
    '2': 'Muunnos',
    '4': 'Käynnistää',
    '6': 'Jatka',
    '16': 'Työskentelee',
    '18': 'Jatka',
    '32': 'Keskeytetty',
    '36': 'Keskeytetty',
    '38': 'Keskeytä',
    '48': 'Keskeytetty',
    '50': 'Keskeytä',
    '64': 'Valmis',
    '66': 'Viimeistelee',
    '68': 'Valmistelee',
    '128': 'Keskeytetty',
    '256': 'Hälytys',
    '512': 'Vakava virhe',
    UNKNOWN: 'Tuntematon',
  },
  material_test_generator: {
    block_settings: 'Lohkon Asetukset',
    columns: 'Sarakkeet',
    count: 'Määrä',
    cut: 'Leikkaa',
    engrave: 'Kaiverra',
    export: 'Vie',
    max: 'Max',
    min: 'Min',
    parameter: 'Parametri',
    preview: 'Esikatselu',
    rows: 'Rivit',
    size: 'Koko (KxL)',
    spacing: 'Väli',
    table_settings: 'Taulukon Asetukset',
    text_settings: 'Tekstiasetukset',
    title: 'Materiaalitestin Generaattori',
  },
  menu: {
    inches: 'tuumaa',
    mm: 'mm',
  },
  message: {
    auth_error: '#820 Todennusvirhe: Päivitä Beam Studio ja koneen laiteohjelmisto uusimpaan versioon.',
    authenticating: 'Todentaudutaan...',
    camera: {
      abort_preview: 'Keskeytä',
      camera_cable_unstable: 'Kameran kuvan siirrossa havaittiin epävakautta. Esikatselu toimii edelleen normaalisti, mutta esikatselussa voi ilmetä hitautta tai aikakatkaisuja.',
      continue_preview: 'Jatka',
      fail_to_transmit_image: '#845 Kuvan siirrossa tapahtui virhe. Käynnistä kone tai Beam Studio uudelleen. Jos virhe ei poistu, noudata <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">tätä ohjetta</a>.',
      ws_closed_unexpectly: '#844 Yhteys kameran ja koneen välillä katkesi odottamatta. Jos virhe ei poistu, noudata <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">tätä ohjetta</a>.',
    },
    cancelling: 'Peruuttaminen...',
    cant_connect_to_device: '#827 Ei voi muodostaa yhteyttä koneeseen, tarkista yhteys',
    connected: 'Yhdistetty',
    connecting: 'Yhdistetään...',
    connectingCamera: 'Yhdistetään kameraan...',
    connectingMachine: 'Yhdistetään %s...',
    connectionTimeout: '#805 Laitteen yhteys katkaistiin. Tarkista verkkoyhteytesi ja laitteen Wi-Fi-merkkivalo.',
    device_blocked: {
      caption: 'Luvaton sarjanumero',
      offline: 'Laitteesi sarjanumero on rajoitettu offline-käyttöön, koska se näyttää olevan pois käytöstä. Anna laitteen sarjanumero (löytyy laitteen takaa) jälleenmyyjällesi ja pyydä häntä ottamaan yhteyttä osoitteeseen support@flux3dp.com online-valtuutuksen aktivoimiseksi. Jos haluat käyttää laitetta offline-tilassa, ota suoraan yhteyttä support@flux3dp.com.',
      online: 'Laitteesi sarjanumero näyttää olevan pois käytöstä. Anna laitteen sarjanumero (löytyy laitteen takaa) jälleenmyyjällesi ja pyydä häntä ottamaan yhteyttä osoitteeseen support@flux3dp.com laitteen valtuutuksen aktivoimiseksi.',
    },
    device_busy: {
      caption: 'Kone varattu',
      message: 'Kone suorittaa toista tehtävää, yritä myöhemmin uudelleen. Jos se lakkaa toimimasta, käynnistä kone uudelleen.',
    },
    device_is_used: 'Konetta käytetään, haluatko keskeyttää nykyisen tehtävän?',
    device_not_found: {
      caption: 'Oletuslaitetta ei löytynyt',
      message: '#812 Tarkista koneen Wi-Fi-merkkivalo',
    },
    disconnected: 'Epävakaa yhteys, tarkista laitteen yhteys ja yritä myöhemmin uudelleen',
    endingLineCheckMode: 'Poistutaan linjantarkistustilasta...',
    endingRawMode: 'Poistutaan raakatilasta...',
    enteringLineCheckMode: 'Siirrytään linjantarkistustilaan...',
    enteringRawMode: 'Siirrytään raakatilaan...',
    enteringRedLaserMeasureMode: 'Siirrytään punaisen lasermittauksen tilaan...',
    exitingRotaryMode: 'Poistutaan pyörintätilasta...',
    getProbePosition: 'Haetaan anturin sijaintia...',
    gettingLaserSpeed: 'Luetaan laserpään nopeutta...',
    homing: 'Hakeudutaan kotiasentoon...',
    need_password: 'Tarvitaan salasana koneeseen yhdistämiseen',
    please_enter_dpi: 'Anna tiedoston mittayksikkö (mm)',
    preview: {
      adjust: 'Säädä',
      adjust_height_tooltip: 'Valitse valintaruutu muokataksesi.',
      already_performed_auto_focus: 'Olet jo suorittanut automaattitarkennuksen, käytetäänkö olemassa olevia arvoja?',
      auto_focus: 'Automaattitarkennus',
      auto_focus_instruction: 'Siirrä lasermoduulin pää kohteen yläpuolelle ja seuraa animaatio-ohjeita painaaksesi AF tarkentaaksesi.',
      camera_preview: 'Kameran esikatselu',
      enter_manually: 'Anna manuaalisesti',
      please_enter_height: 'Anna kohteen korkeus. Jotta kameran kuva olisi tarkka.',
    },
    promark_disconnected: '#850 Yhteys laitteeseen katkaistu, tarkista laitteen yhteystila.',
    redLaserTakingReference: 'Otetaan viite...',
    retrievingCameraOffset: 'Luetaan kameran siirtymää...',
    settingLaserSpeed: 'Asetetaan laserpään nopeutta...',
    swiftray_disconnected: 'Yhteyttä backend-palvelimeen ei voitu muodostaa, yritetään uudelleen.',
    swiftray_reconnected: 'Backend on yhdistetty uudelleen, yritä lähettää tehtävä uudelleen.',
    time_remaining: 'Aikaa jäljellä:',
    tryingToConenctMachine: 'Yritetään yhdistää laitteeseen...',
    turningOffAirPump: 'Sammutetaan ilmapumppu...',
    turningOffFan: 'Sammutetaan tuuletin...',
    unable_to_find_machine: 'Ei löydy konetta ',
    unable_to_start: '#830 Tehtävän käynnistäminen ei onnistunut. Yritä uudelleen. Jos tämä toistuu, ota meihin yhteyttä vianraportilla:',
    unavailableWorkarea: '#804 Nykyinen työalue ylittää koneen työalueen. Tarkista valitun koneen työalue tai aseta työalue valikosta Muokkaa > Asiakirja-asetukset.',
    unknown_device: '#826 Ei voi muodostaa yhteyttä koneeseen, varmista että USB on liitetty koneeseen',
    unknown_error: '#821 Sovelluksessa tapahtui tuntematon virhe, ilmoita virheestä valikossa Apua > Valikko > Virheraportti.',
    unsupport_osx_version: 'Nykyinen macOS-versio %s ei ehkä tue kaikkia toimintoja. Päivitä macOS 11+ -versioon.',
    unsupport_win_version: 'Nykyinen käyttöjärjestelmäversio %s ei ehkä tue kaikkia toimintoja. Päivitä uusimpaan versioon.',
    unsupported_example_file: 'Valittu esimerkkitiedosto ei ole tuettu tällä työalueella.',
    uploading_fcode: 'Lähetetään FCodea',
    usb_unplugged: 'USB-yhteys katkesi. Tarkista USB-yhteys',
    wrong_swiftray_version_message: 'Väärä backend-versio (versio: {version}). Tarkista, onko useita Beam Studio -instansseja käynnissä, ja sulje ylimääräiset instanssit ennen uudelleenyritystä.',
    wrong_swiftray_version_title: 'Väärä backend-versio',
  },
  monitor: {
    ask_reconnect: 'Yhteys koneeseen on katkennut. Haluatko yhdistää uudelleen?',
    bug_report: 'Virheraportti',
    camera: 'Kamera',
    cancel: 'Peruuta',
    confirmFileDelete: 'Haluatko varmasti poistaa tämän tiedoston?',
    connecting: 'Yhdistetään, odota...',
    DEVICE_ERROR: 'Jotain meni pieleen\nKäynnistä uudelleen',
    download: 'Lataa',
    extensionNotSupported: 'Tätä tiedostomuotoa ei tueta',
    fileExistContinue: 'Tiedosto on jo olemassa, haluatko korvata sen?',
    forceStop: 'Haluatko keskeyttää nykyisen tehtävän?',
    go: 'Aloita',
    HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Ilmavirtausanturi poikkeava',
    HARDWARE_ERROR_BOTTOM_OPENED: '#903 Alaosa auki. Sulje jatkaaksesi',
    HARDWARE_ERROR_DOOR_OPENED: '#901 Sulje ovi jatkaaksesi',
    HARDWARE_ERROR_DRAWER_OPENED: '#911 Laatikko auki',
    HARDWARE_ERROR_FIRE_DETECTED: '#912 Liekkianturi poikkeava',
    HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Virheellinen moduuli havaittu. Asenna oikea moduuli jatkaaksesi.',
    HARDWARE_ERROR_HEADTYPE_NONE: '#917 Moduulia ei havaittu. Varmista, että moduuli on asennettu oikein jatkaaksesi.',
    HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Tuntematon moduuli havaittu. Asenna oikea moduuli jatkaaksesi.',
    HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Kotiutumisen irrotusvirhe',
    HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Kriittinen virhe: Pääkortti offline. Ota yhteyttä FLUX-tukeen.',
    HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Ylikuumeneminen. Odota muutama minuutti',
    HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Z-akselin nollaaminen epäonnistui',
    HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Tulostinmoduuli ei vastaa.',
    HARDWARE_ERROR_PROBE_SHOWED: 'Vedä anturi takaisin.',
    HARDWARE_ERROR_PUMP_ERROR: '#900 Tarkista vesisäiliö.',
    HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Pyörivää moduulia ei havaittu',
    HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Kriittinen virhe: Alijärjestelmä ei vastaa. Ota yhteyttä FLUX-tukeen.',
    HARDWARE_FAILURE: 'Jotain meni pieleen\nKäynnistä uudelleen',
    hour: 'h',
    left: 'vasen',
    MAINBOARD_OFFLINE: '#905 Virhe yhdistettäessä pääkorttiin.\nKäynnistä uudelleen.',
    minute: 'min',
    monitor: 'MONITORI',
    NO_RESPONSE: '#905 Virhe yhdistettäessä pääkorttiin.\nKäynnistä uudelleen.',
    pause: 'Keskeytä',
    prepareRelocate: 'Valmistaudutaan siirtämään',
    processing: 'Käsitellään',
    record: 'Tallenna',
    relocate: 'Siirrä',
    RESOURCE_BUSY: 'Laite varattu\nJos ei toimi, käynnistä uudelleen',
    resume: 'Jatka',
    savingPreview: 'Luodaan pikkukuvia',
    second: 's',
    start: 'Aloita',
    stop: 'Pysäytä',
    SUBSYSTEM_ERROR: '#402 Kriittinen virhe: Alijärjestelmä ei vastaa. Ota yhteys tukeen.',
    task: {
      BEAMBOX: 'Laserleikkaus',
      'N/A': 'Vapaa tila',
    },
    taskTab: 'Tehtävä',
    temperature: 'Lämpötila',
    upload: 'Lataa',
    USER_OPERATION: 'Noudata laitteen paneelissa olevia ohjeita jatkaaksesi.',
    USER_OPERATION_CHANGE_CARTRIDGE: 'Lisää oikea patruuna jatkaaksesi.',
    USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Lisää patruuna jatkaaksesi.',
    USER_OPERATION_CHANGE_TOOLHEAD: 'Asenna oikea moduuli jatkaaksesi.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Virheellinen moduuli havaittu. Asenna oikea moduuli jatkaaksesi.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Moduulia ei havaittu. Varmista, että moduuli on asennettu oikein jatkaaksesi.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Tuntematon moduuli havaittu. Asenna oikea moduuli jatkaaksesi.',
    USER_OPERATION_REMOVE_CARTRIDGE: 'Poista kasetti jatkaaksesi.',
    USER_OPERATION_ROTARY_PAUSE: 'Vaihda pyörivään moottoriin',
  },
  my_cloud: {
    action: {
      confirmFileDelete: 'Haluatko varmasti poistaa tämän tiedoston? Toimintoa ei voi perua.',
      delete: 'Poista',
      download: 'Lataa',
      duplicate: 'Kopioi',
      open: 'Avaa',
      rename: 'Nimeä uudelleen',
    },
    file_limit: 'Ilmainen tiedosto',
    loading_file: 'Ladataan...',
    no_file_subtitle: 'Siirry valikkoon > "Tiedosto" > "Tallenna pilvipalveluun"',
    no_file_title: 'Aloita tallentamalla tiedostoja pilveen.',
    save_file: {
      choose_action: 'Tallenna tiedosto:',
      input_file_name: 'Anna tiedoston nimi:',
      invalid_char: 'Virheelliset merkit:',
      save: 'Tallenna',
      save_new: 'Tallenna uutena tiedostona',
      storage_limit_exceeded: 'Pilvitallennustilasi on saavuttanut ylärajan. Poista kaikki tarpeettomat tiedostot ennen uusien tallentamista.',
    },
    sort: {
      a_to_z: 'Nimi: A - Ö',
      most_recent: 'Viimeisimmät',
      oldest: 'Vanhimmat',
      z_to_a: 'Nimi: Ö - A',
    },
    title: 'Pilveni',
    upgrade: 'Päivitä',
  },
  noun_project_panel: {
    clear: 'Tyhjennä',
    elements: 'Elementit',
    enjoy_shape_library: 'Nauti muotokirjastosta',
    export_svg_title: 'SVG-viennin virhe',
    export_svg_warning: 'Tämä projekti sisältää tekijänoikeussuojan alaisia objekteja. Beam Studio jättää nämä objektit automaattisesti pois viennin yhteydessä. Voit silti tallentaa projektisi Beam Studio -kohtauksena (.beam-muodossa) säilyttääksesi kaikki tiedot. Haluatko silti viedä?',
    learn_more: 'Lue lisää',
    login_first: 'Kirjaudu sisään avataksesi muotokirjaston',
    recent: 'Viimeaikaiset',
    search: 'Etsi',
    shapes: 'Muodot',
  },
  pass_through: {
    export: 'Vie Työalueelle',
    exporting: 'Viedään...',
    guide_mark: 'Opasmerkki',
    guide_mark_desc: 'Opasmerkit kaiverretaan viitepisteenä taideteoksen kohdistamiseksi.',
    guide_mark_length: 'Pituus:',
    guide_mark_x: 'X-koordinaatti:',
    height_desc: 'Aseta kunkin työalueen osan korkeus.',
    help_links: {
      ado1: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
      fbb2: 'https://support.flux3dp.com/hc/en-us/articles/11570021253263',
    },
    help_text: 'Kuinka asettaa läpivienti %(model)s varten?',
    object_length: 'Objektin Pituus',
    ref_layer: 'Viitekerros',
    ref_layer_desc: 'Huomaa, että referenssikerroksen suoritus on oletuksena asetettu arvoon 0. Sitä ei suoriteta, vaan se on vain kohdistusviite.',
    ref_layer_name: 'Viite',
    title: 'Läpivientitila',
    workarea_height: 'Työalue (Korkeus):',
  },
  promark_connection_test: {
    description: 'Järjestelmä suorittaa punavalotoiminnon noin kahden minuutin ajan tarkistaakseen, onko yhteys vakaa.',
    health: 'Vakaus:',
    healthy_description: 'Vakaus on hyvä, mutta vältä tietokoneen merkittävää liikuttamista kaiverruksen aikana.',
    res_0: 'Erinomainen',
    res_1: 'Hyvä',
    res_2: 'Matala',
    res_3: 'Liian matala',
    res_4: 'Erittäin matala',
    restart: 'Käynnistä testi uudelleen',
    start: 'Aloita testi',
    stop: 'Lopeta testi',
    title: 'Yhteyden vakaustesti',
    unhealthy_description: 'Vakaus on liian heikko. Suositellaan verkkosovittimen tai virtajohdon vaihtamista.',
  },
  promark_settings: {
    angle: 'Kulma',
    bulge: 'Pullistuma',
    field: 'Kenttä',
    galvo_configuration: 'Galvo-konfiguraatio',
    mark: 'Merkitse',
    mark_parameters: 'Merkintäparametrit',
    offsetX: 'Siirtymä X',
    offsetY: 'Siirtymä Y',
    preview: 'Esikatselu',
    red_dot: 'Punainen piste',
    scale: 'Mittakaava',
    scaleX: 'Mittakaava X',
    scaleY: 'Mittakaava Y',
    skew: 'Vinous',
    switchXY: 'Vaihda X/Y',
    title: 'Promark-asetukset',
    trapezoid: 'Trapezoidi',
    workarea_hint: 'Voit vaihtaa työalueen "Asiakirjan asetuksista".',
    z_axis_adjustment: {
      section1: 'Säädä Z-akselin korkeutta tarkentaaksesi kohdistusta.',
      title: 'Z-Akselin Säätö',
      tooltip1: 'Kokeile merkitä 1x1 cm neliö varmistaaksesi, että nykyinen polttoväli on sopiva.',
    },
  },
  qr_code_generator: {
    error_tolerance: 'Virhesieto',
    error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
    invert: 'Käännä värit',
    placeholder: 'Anna linkki tai teksti',
    preview: 'Esikatselu',
    title: 'QR-koodigeneraattori',
  },
  rotary_settings: {
    circumference: 'Ympärysmitta',
    extend_workarea: 'Laajenna työaluetta',
    mirror: 'Peilaus',
    object_diameter: 'Kohteen Halkaisija',
    overlap_size: 'Päällekkäisyyden koko',
    rotary_scale: 'Pyörimisen mittakaava',
    split_setting: 'Jakamisasetukset',
    split_setting_url: 'https://support.flux3dp.com/hc/en-us/articles/12276094010767',
    split_size: 'Jakamiskoko',
    type: 'Tyyppi',
  },
  select_device: {
    auth_failure: '#811 Todennus epäonnistui',
    select_device: 'Valitse laite',
    unable_to_connect: '#810 Yhteyden muodostaminen laitteeseen epäonnistui',
  },
  settings: {
    anti_aliasing: 'Anti-Aliasing',
    auto_connect: 'Valitse automaattisesti ainoa laite',
    auto_switch_tab: 'Automaattinen vaihto kerros- ja objektipaneelin välillä',
    autofocus_offset: 'Automaattitarkennuksen siirtymä',
    autosave_enabled: 'Automaattinen tallennus',
    autosave_interval: 'Tallenna joka',
    autosave_number: 'Automaattisten tallennusten määrä',
    autosave_path: 'Automaattisen tallennuksen sijainti',
    autosave_path_not_correct: 'Määritettyä polkua ei löydy.',
    blade_precut_position: 'Esileikkausasento',
    blade_precut_switch: 'Terän esileikkaus',
    blade_radius: 'Terän säde',
    bottom_up: 'Alas-ylös',
    calculation_optimization: 'Reitin laskennan kiihtyvyys',
    cancel: 'Peruuta',
    caption: 'Asetukset',
    check_updates: 'Automaattinen tarkistus',
    close: 'Sulje',
    confirm_remove_default: 'Oletuslaite poistetaan.',
    confirm_reset: 'Vahvista Beam Studion nollaus',
    continuous_drawing: 'Jatkuva piirto',
    curve_engraving_speed_limit: 'Kaiverruksen nopeusrajoitus kaareville pinnoille',
    custom_preview_height: 'Mukautettu esikatselukorkeus',
    default_beambox_model: 'Oletusasiakirja-asetukset',
    default_borderless_mode: 'Avaa alaosa oletuksena',
    default_enable_autofocus_module: 'Automaattitarkennuksen oletus',
    default_enable_diode_module: 'Diodilaserin oletus',
    default_font_family: 'Oletusfontti',
    default_font_style: 'Oletusfontin tyyli',
    default_laser_module: 'Oletuslasermoduuli',
    default_machine: 'Oletuslaite',
    default_machine_button: 'Tyhjennä',
    default_units: 'Oletusyksiköt',
    diode_offset: 'Diodilaserin siirtymä',
    diode_one_way_engraving: 'Diodilaserin yksisuuntainen kaiverrus',
    diode_two_way_warning: 'Kaksisuuntainen valon lähettäminen on nopeampaa ja todennäköisesti tuottaa epätarkkuutta laserkaiverruksen sijainnissa. Suositellaan testaamaan ensin.',
    disabled: 'Ei käytössä',
    done: 'Valmis',
    enable_custom_backlash: 'Ota mukautettu välys kompensointi käyttöön',
    enable_low_speed: 'Ota hitaan nopeuden liike käyttöön',
    enabled: 'Käytössä',
    engraving_direction: 'Suunta',
    fast_gradient: 'Nopeusoptimointi',
    font_convert: 'Teksti-polku muunnin',
    font_substitute: 'Korvaa tukemattomat merkit',
    grouped_objects: 'Ryhmitetyt objektit',
    groups: {
      ador_modules: 'Adorin moduulit',
      autosave: 'Automaattinen tallennus',
      camera: 'Kamera',
      connection: 'Yhteys',
      editor: 'Editori',
      engraving: 'Rasterointi (skannaus)',
      general: 'Yleiset',
      mask: 'Työalueen rajaus',
      modules: 'Lisäosa',
      path: 'Vektori (ääriviivat)',
      privacy: 'Yksityisyys',
      text_to_path: 'Teksti',
      update: 'Ohjelmistopäivitykset',
    },
    guess_poke: 'Etsi laitteen IP-osoite',
    guides: 'Apuviivat',
    guides_origin: 'Apuviivojen alkuperä',
    help_center_urls: {
      anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
      calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
      connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
      continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
      default_borderless_mode: 'https://support.flux3dp.com/hc/en-us/articles/360001104076',
      default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
      default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
      fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
      font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
      font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
      image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
      loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
      mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
      reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
      segmented_engraving: 'https://support.flux3dp.com/hc/en-us/articles/12306366019215',
      simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
      vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
    },
    high: 'Korkea',
    image_downsampling: 'Bitmap-esikatselun laatu',
    ip: 'Laitteen IP-osoite',
    keep_preview_result: 'Säilytä Esikatselutulos',
    language: 'Kieli',
    loop_compensation: 'Silmukkakompensaatio',
    low: 'Matala',
    low_laser_for_preview: 'Käynnissä oleva kehys:lle',
    mask: 'Työalueen rajaus',
    medium: 'Keskitaso',
    module_offset_2w_ir: '2 W infrapunalaerin siirtymä',
    module_offset_10w: '10 W diodilaserin siirtymä',
    module_offset_20w: '20 W diodilaserin siirtymä',
    module_offset_printer: 'Tulostimen siirtymä',
    none: 'Ei mitään',
    normal: 'Normaali',
    notification_off: 'Pois päältä',
    notification_on: 'Päällä',
    notifications: 'Työpöytäilmoitukset',
    off: 'Pois',
    on: 'Päällä',
    preview_movement_speed: 'Esikatselun liikenopeus',
    printer_advanced_mode: 'Tulostimen lisäasetukset',
    remove_default_machine_button: 'Poista',
    reset: 'Nollaa Beam Studio',
    reset_now: 'Nollaa Beam Studio',
    segmented_engraving: 'Segmentoitu kaiverrus',
    share_with_flux: 'Jaa Beam Studion analytiikka',
    simplify_clipper_path: 'Optimoi laskettu polku',
    single_object: 'Yksittäinen objekti',
    tabs: {
      device: 'Laite',
      general: 'Yleiset',
    },
    text_path_calc_optimization: 'Polun laskennan optimointi',
    top_down: 'Ylös-alas',
    trace_output: 'Kuvan jäljityslähtö',
    update_beta: 'Beeta',
    update_latest: 'Viimeisin',
    vector_speed_constraint: 'Nopeusrajoitus',
    wrong_ip_format: 'Väärä IP-muoto',
  },
  social_media: {
    facebook: 'Keskustele FLUXersin kanssa, kysy kysymyksiä ja opi vinkkejä!',
    instagram: 'Hanki uusimmat inspiraatiot, tarjoukset ja ilmaistuotteet!',
    youtube: 'Katso Beam Studion opetusohjelmat ja askarteluideat.',
  },
  support: {
    no_vcredist: 'Asenna Visual C++ Redistributable 2015<br/>Sen voi ladata osoitteesta flux3dp.com',
    no_webgl: 'WebGL ei ole tuettu. Käytä muita laitteita.',
  },
  topbar: {
    alerts: {
      add_content_first: 'Lisää ensin kohteita',
      door_opened: 'Sulje ovensuojus, jotta kehys voidaan käynnistää matalalla teholla.',
      fail_to_connect_with_camera: '#803 Yhteyden muodostaminen kameraan epäonnistui. Käynnistä tietokone tai Beam Studio uudelleen. Jos virhe toistuu, noudata <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">näitä ohjeita</a>.',
      fail_to_get_door_status: 'Varmista, että ovensuojus on kiinni, jotta kehys voidaan käynnistää matalalla teholla.',
      fail_to_start_preview: '#803 Esikatselutilan käynnistys epäonnistui. Käynnistä kone tai Beam Studio uudelleen. Jos virhe toistuu, noudata <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">tätä ohjetta</a>.',
      headtype_mismatch: 'Väärä moduuli havaittu. ',
      headtype_none: 'Moduulia ei havaittu. ',
      headtype_unknown: 'Tuntematon moduuli havaittu. ',
      install_correct_headtype: 'Asenna 10 W / 20 W diodilasermoduulit asianmukaisesti, jotta kehys voidaan käynnistää matalalla teholla.',
      job_origin_unavailable: 'Työn aloituskohdan asettaminen vaatii laiteohjelmistoversion 4.3.5 / 5.3.5 tai uudemman. Haluatko päivittää laiteohjelmiston nyt?',
      job_origin_warning: 'Käytät tällä hetkellä “nykyistä sijaintia” aloituspisteenä. Varmista, että laserkärki on siirretty oikeaan asentoon törmäysten välttämiseksi.',
      power_too_high: 'LIIAN SUURI TEHO',
      power_too_high_confirm: 'HUOMAUTETTU',
      power_too_high_msg: 'Matalampi laserteho (alle 70 %) pidentää laserputken käyttöikää.\nKirjoita "HUOMAUTETTU" jatkaaksesi.',
      pwm_unavailable: 'Syvyystila vaatii laiteohjelmistoversion 4.3.4 / 5.3.4 tai uudemman. Haluatko päivittää laiteohjelmiston nyt?',
      QcleanScene: 'Haluatko tyhjentää piirustuksen?<br/>Tämä poistaa myös kumoamishistoriasi!',
      start_preview_timeout: '#803 Aikakatkaisu esikatselutilan käynnistyksessä. Käynnistä kone tai Beam Studio uudelleen. Jos virhe toistuu, noudata <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">tätä ohjetta</a>.',
    },
    frame_task: 'Käynnissä oleva kehys',
    hint: {
      polygon: 'Paina +-näppäintä lisätäksesi/vähentääksesi sivuja.',
    },
    menu: {
      about: 'Tietoja Beam Studiosta',
      about_beam_studio: 'Tietoja Beam Studiosta',
      account: 'Tili',
      add_new_machine: 'Koneen asetukset',
      align_center: 'Keskitä',
      anti_aliasing: 'Anti-Aliasing',
      auto_align: 'Automaattinen Tasaus',
      borderless_mode: 'Reunaton tila',
      bug_report: 'Virheraportti',
      calibrate_beambox_camera: 'Kalibroi kamera',
      calibrate_beambox_camera_borderless: 'Kalibroi kamera (avoin pohja)',
      calibrate_camera_advanced: 'Kalibroi kamera (Edistynyt)',
      calibrate_diode_module: 'Kalibroi diodilasermoduuli',
      calibrate_ir_module: 'Kalibroi infrapunamoduuli',
      calibrate_printer_module: 'Kalibroi tulostinmoduuli',
      calibration: 'Kalibroinnit',
      camera_calibration_data: 'Kameran Kalibrointitiedot',
      change_logs: 'Muutosloki',
      clear_scene: 'Uudet tiedostot',
      close: 'Sulje ikkuna',
      commands: 'Komennot',
      contact: 'Ota yhteyttä',
      copy: 'Kopioi',
      cut: 'Leikkaa',
      dashboard: 'Hallintapaneeli',
      decompose_path: 'Hajota',
      delete: 'Poista',
      design_market: 'Muotoilumarkkinat',
      dev_tool: 'Vianmääritystyökalu',
      disassemble_use: 'Purkaa',
      document_setting: 'Asiakirjan asetukset',
      document_setting_short: 'Asiakirja',
      download_data: 'Lataa Tiedot',
      download_log: 'Lataa lokit',
      download_log_canceled: 'Lokien lataus peruutettu',
      download_log_error: 'Tapahtui tuntematon virhe, yritä myöhemmin uudelleen',
      duplicate: 'Monista',
      edit: 'Muokkaa',
      example_files: 'Esimerkkitiedostot',
      export_BVG: 'BVG',
      export_flux_task: 'FLUX-tehtävä',
      export_JPG: 'JPG',
      export_PNG: 'PNG',
      export_SVG: 'SVG',
      export_to: 'Vie...',
      file: 'Tiedosto',
      fit_to_window: 'Sovita ikkunaan',
      follow_us: 'Seuraa meitä',
      forum: 'Yhteisöfoorumi',
      group: 'Ryhmitä',
      help: 'Ohje',
      help_center: 'Ohjekeskus',
      hide: 'Piilota Beam Studio',
      hideothers: 'Piilota muut',
      image_crop: 'Rajaa',
      image_curve: 'Kaareva',
      image_invert: 'Käännä',
      image_sharpen: 'Terävöitä',
      image_stamp: 'Viistota',
      image_vectorize: 'Jäljitä',
      import_acrylic_focus_probe: 'Akryylitarkkuustesti - 3 mm',
      import_ador_laser_example: 'Adorin laser -esimerkki',
      import_ador_printing_example_full: 'Adorin tulostuksen esimerkki - Täysvärinen',
      import_ador_printing_example_single: 'Adorin tulostuksen esimerkki - Yksivärinen',
      import_beambox_2_example: 'Beambox II-esimerkki',
      import_beambox_2_focus_probe: 'Beambox II Tarkennusanturi - 3 mm',
      import_hello_beambox: 'Beambox-esimerkki',
      import_hello_beamo: 'beamo-esimerkki',
      import_hexa_example: 'Esimerkki HEXA:sta',
      import_material_printing_test: 'Materiaalin tulostustesti',
      import_material_testing_cut: 'Materiaalileikkaustesti',
      import_material_testing_engrave: 'Materiaalietsaustesti',
      import_material_testing_line: 'Materiaaliviivatesti',
      import_material_testing_old: 'Materiaalitestaus - Klassinen',
      import_material_testing_simple_cut: 'Materiaalileikkaustesti - Yksinkertainen',
      import_promark_example: 'Promark-esimerkki',
      import_promark_mopa_20w_color: 'MOPA 20W Väritesti',
      import_promark_mopa_60w_color: 'MOPA 60W Väritesti',
      import_promark_mopa_100w_color: 'MOPA 100W Väritesti',
      keyboard_shortcuts: 'Pikanäppäimet',
      layer_color_config: 'Väriasetukset',
      layer_setting: 'Taso',
      link: {
        beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
        contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
        design_market: 'https://dmkt.io',
        downloads: 'https://flux3dp.com/downloads/',
        forum: 'https://www.facebook.com/groups/flux.laser/',
        help_center: 'https://helpcenter.flux3dp.com/',
        shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
      },
      log: {
        camera: 'Kamera',
        cloud: 'Pilvi',
        discover: 'Etsi',
        hardware: 'Laitteisto',
        network: 'Verkko',
        player: 'Soitin',
        robot: 'Robotti',
        usb: 'USB',
        usblist: 'USB-luettelo',
      },
      machine_info: 'Koneen tiedot',
      machines: 'Koneet',
      manage_account: 'Hallitse tiliäni',
      material_test: 'Materiaalitesti',
      minimize: 'Pienennä',
      my_account: 'Oma tili',
      network_testing: 'Testaa verkkoasetukset',
      object: 'Objekti',
      offset: 'Siirrä',
      open: 'Avaa',
      paste: 'Liitä',
      paste_in_place: 'Liitä paikalleen',
      path: 'Polku',
      photo_edit: 'Kuva',
      preferences: 'Asetukset',
      promark_color_test: 'Promark Väritesti',
      questionnaire: 'Palautekysely',
      quit: 'Lopeta',
      recent: 'Avaa Viimeaikaiset',
      redo: 'Tee uudelleen',
      reload_app: 'Lataa Sovellus Uudelleen',
      reset: 'Nollaa',
      rotary_setup: 'Kierron Asetukset',
      rotate: 'Kierrä',
      samples: 'Esimerkkejä',
      save_as: 'Tallenna nimellä...',
      save_scene: 'Tallenna',
      save_to_cloud: 'Tallenna pilvipalveluun',
      scale: 'Skaalaa',
      service: 'Palvelut',
      set_as_default: 'Aseta oletukseksi',
      show_gesture_tutorial: 'Käden eleiden esittely',
      show_grids: 'Näytä ruudukot',
      show_layer_color: 'Käytä kerroksen väriä',
      show_rulers: 'Näytä viivain',
      show_start_tutorial: 'Näytä aloitusopastus',
      show_ui_intro: 'Näytä käyttöliittymän esittely',
      sign_in: 'Kirjaudu sisään',
      sign_out: 'Kirjaudu ulos',
      software_update: 'Ohjelmistopäivitys',
      svg_edit: 'SVG',
      switch_to_beta: 'Vaihda Beta-versioon',
      switch_to_latest: 'Vaihda Vakaa versioon',
      tools: {
        box_generator: 'Laatikkogeneraattori',
        code_generator: 'Koodigeneraattori',
        material_test_generator: 'Materiaalitestin Generaattori',
        title: 'Työkalut',
      },
      tutorial: 'Aloita Delta-perheen tulostusopastus',
      undo: 'Kumoa',
      ungroup: 'Poista ryhmitys',
      update: 'Tarkista Päivitys',
      update_firmware: 'Päivitä laiteohjelmisto',
      upload_data: 'Lähetä Tiedot',
      using_beam_studio_api: 'Beam Studion API:n käyttö',
      view: 'Näytä',
      window: 'Ikkuna',
      zoom_in: 'Lähennä',
      zoom_out: 'Loitonna',
      zoom_with_window: 'Sovita ikkunaan automaattisesti',
    },
    preview: 'ESIKATSELU',
    preview_press_esc_to_stop: 'Paina ESC lopettaaksesi kameran esikatselun.',
    rename_tab: 'Nimeä välilehti uudelleen',
    select_machine: 'Valitse kone',
    tag_names: {
      dxf: 'DXF-kohde',
      ellipse: 'Soikio',
      g: 'Ryhmä',
      image: 'Kuva',
      line: 'Viiva',
      multi_select: 'Useita kohteita',
      no_selection: 'Ei valintaa',
      pass_through_object: 'Läpi Kulkeva Kohde',
      path: 'Polku',
      polygon: 'Monikulmio',
      rect: 'Suorakaide',
      svg: 'SVG-kohde',
      text: 'Teksti',
      text_path: 'Teksti polulla',
      use: 'Tuotu kohde',
    },
    task_preview: 'Polun esikatselu',
    titles: {
      settings: 'Asetukset',
    },
    untitled: 'Nimeämätön',
  },
  topmenu: {
    credit: 'Beam Studio on mahdollinen <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> avoimen lähdekoodin projektin ja muiden <a target="_blank" href="https://flux3dp.com/credits/">avoimen lähdekoodin ohjelmistojen</a> ansiosta.',
    device: {
      download_log_canceled: 'Lokin lataus peruutettu',
      download_log_error: 'Tuntematon virhe tapahtui, yritä myöhemmin uudelleen',
      log: {
        usblist: 'USB-luettelo',
      },
      network_test: 'Testaa verkko',
    },
    file: {
      all_files: 'Kaikki tiedostot',
      clear_recent: 'Tyhjennä viimeksi avatut',
      converting: 'Muunnetaan kuvaksi...',
      fcode_files: 'FLUX Code',
      import: 'Tuo',
      jpg_files: 'JPG',
      label: 'Tiedosto',
      path_not_exit: 'Tätä polkua ei näytä enää olevan levyllä.',
      png_files: 'PNG',
      save_fcode: 'Vie FLUX-tehtävä',
      save_jpg: 'Vie JPG',
      save_png: 'Vie PNG',
      save_scene: 'Tallenna kohtaus',
      save_svg: 'Vie SVG',
      scene_files: 'Beam Studio Scene',
      svg_files: 'SVG',
    },
    ok: 'OK',
    version: 'Versio',
  },
  tutorial: {
    ask_retry_calibration: 'Haluaisitko kalibroida kameran uudelleen?',
    camera_calibration_failed: 'Kameran kalibrointi epäonnistui',
    gesture: {
      click: 'Valitse objekti napauttamalla.',
      drag: 'Valitse useita objekteja vetämällä.',
      hold: 'Avaa pikavalikko pitämällä painettuna.',
      pan: 'Vieritä kangasta kahdella sormella.',
      zoom: 'Lähennä ja loitonna nipistämällä kahta sormea.',
    },
    links: {
      adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
    },
    look_for_machine: 'Etsitään konetta opetusohjelmaa varten...',
    needNewInterfaceTutorial: 'Haluaisitko aloittaa opastuksen Beam Studion uudelle käyttöliittymälle?<br/>(Voit ohittaa sen nyt ja aloittaa myöhemmin napsauttamalla "Ohje" > "Näytä Käyttöliittymän Esittely".)',
    needNewUserTutorial: 'Haluaisitko aloittaa opastuksen?<br/>(Voit ohittaa sen nyt ja aloittaa myöhemmin napsauttamalla "Ohje" > "Näytä Aloita Opastus".)',
    newInterface: {
      add_new_layer: 'Lisää uusi taso',
      align_controls: 'Kohdistushallinta',
      basic_shapes: 'Perusmuodot',
      camera_preview: 'Kameran esikatselu',
      drag_to_sort: 'Lajittele raahaamalla',
      end_alert: 'Haluatko varmasti lopettaa uuden käyttöliittymän esittelyn?',
      flip: 'Käännä',
      group_controls: 'Ryhmähallinta',
      layer_controls: 'Napsauta hiiren oikealla painikkeella valitaksesi tason hallinta:\nKopioi / Yhdistä / Lukitse / Poista tasoja',
      object_actions: 'Objektitoiminnot',
      pen_tool: 'Kynätyökalu',
      rename_by_double_click: 'Nimeä uudelleen kaksoisnapsauttamalla',
      select_image_text: 'Valitse / Kuva / Teksti',
      select_machine: 'Valitse kone',
      shape_operation: 'Muoto-operaatio',
      start_work: 'Aloita työ',
      switch_between_layer_panel_and_object_panel: 'Vaihda tason ja objektin välillä',
    },
    newUser: {
      add_new_layer: 'Lisää uusi taso',
      adjust_focus: '2. Säädä tarkennusta',
      close_cover: '3. Sulje kansi',
      drag_to_draw: 'Vedä piirtääksesi',
      draw_a_circle: 'Piirrä ympyrä',
      draw_a_rect: 'Piirrä suorakaide',
      end_alert: 'Oletko varma, että haluat lopettaa opetusohjelman?',
      end_preview_mode: 'Lopeta esikatselutila',
      infill: 'Käännä täyttö päälle',
      please_select_wood_cutting: 'Valitse ystävällisesti "Puu - leikkaus" -esiasetus.',
      please_select_wood_engraving: 'Valitse ystävällisesti "Puu - kaivertaminen" -esiasetus.',
      preview_the_platform: 'Esikatsele alustaa',
      put_wood: '1. Aseta puunäyte',
      send_the_file: 'Lähetä tiedosto',
      set_preset_wood_cut: 'Aseta esiasetus: Puu - leikkaus',
      set_preset_wood_engraving: 'Aseta esiasetus: Puu - kaivertaminen',
      switch_to_layer_panel: 'Vaihda kerrospaneeliin',
      switch_to_object_panel: 'Vaihda objektipaneeliin',
      switch_to_preview_mode: 'Vaihda esikatselutilaan',
    },
    next: 'SEURAAVA',
    retry: 'Yritä uudelleen',
    set_connection: 'Aseta yhteys',
    skip: 'Ohita',
    skip_tutorial: 'Ohitat opetusohjelman. Voit aina käynnistää opetusohjelman napsauttamalla "Ohje" > "Näytä aloita opetusohjelma"',
    suggest_calibrate_camera_first: 'Suosittelemme käyttäjiä kalibroimaan kameran aluksi ja tarkentamaan uudelleen ennen jokaista esikatselua optimaalisten tulosten saavuttamiseksi.<br/>Haluatko vahvistaa suorittaaksesi kalibroinnin nyt?<br/>(Voit ohittaa sen nyt ja tehdä sen myöhemmin napsauttamalla "Valikko" > "Laite" > [Laiteesi] > "Kalibroi Kamera".)',
    tutorial_complete: 'Tässä oli tämän opastuksen anti. Nyt on aika luoda!',
    unable_to_find_machine: 'Opetusohjelman konetta ei löydy. Haluatko mennä yhteysasetussivulle, yrittää uudelleen tai ohittaa opetusohjelman?',
    welcome: 'TERVETULOA',
  },
  update: {
    cannot_reach_internet: '#823 Palvelin ei ole tavoitettavissa<br/>Tarkista internet-yhteys',
    download: 'VERKKO-PÄIVITYS',
    firmware: {
      caption: 'Laitteeseen on saatavilla laiteohjelmistopäivitys',
      confirm: 'LÄHETÄ',
      firmware_too_old_update_by_sdcard: 'Laiteohjelmistoversio on liian vanha. Päivitä laiteohjelmisto SD-kortin avulla.',
      force_update_message: '#814 Päivitä laitteesi uusimpaan laiteohjelmistoversioon.',
      latest_firmware: {
        cant_get_latest: 'Uusimman laiteohjelmiston tietoja ei voi hakea.',
        caption: 'Laitteen laiteohjelmistopäivitys',
        message: 'Sinulla on uusin laitteen laiteohjelmisto',
        still_update: 'PÄIVITÄ',
      },
      message_pattern_1: '"%s" on nyt valmis laiteohjelmistopäivitykseen.',
      message_pattern_2: '%s laiteohjelmisto v%s on nyt saatavilla - Sinulla on v%s.',
      too_old_for_web: 'Nykyinen laitteen laiteohjelmistoversiosi on v%s.\nJos haluat käyttää Beam Studion verkkoversiota, päivitä laitteen laiteohjelmisto uusimpaan versioon.',
      update_fail: '#822 Päivitys epäonnistui',
      update_success: 'Laiteohjelmistopäivitys ladattu onnistuneesti',
      upload_file: 'Laiteohjelmiston lataus (*.bin / *.fxfw)',
    },
    install: 'ASENNA',
    later: 'MYÖHEMMIN',
    preparing: 'Valmistellaan...',
    release_note: 'Julkaisutiedot:',
    skip: 'Ohita tämä versio',
    software: {
      available_switch: 'Uusi Beam Studio v%s on nyt saatavilla. Sinulla on v%s. Haluatko vaihtaa tähän versioon?',
      available_update: 'Uusi Beam Studio v%s on nyt saatavilla. Sinulla on v%s. Haluatko ladata päivityksen?',
      caption: 'Beam Studio -ohjelmistopäivitys on saatavilla',
      check_update: 'Tarkista päivitykset',
      checking: 'Tarkistetaan päivityksiä',
      downloading: 'Lataan päivityksiä taustalla, voit jatkaa työskentelyä painamalla "OK".',
      install_or_not: 'on valmis päivittämään. Haluatko käynnistää uudelleen ja päivittää nyt?',
      no: 'Ei',
      no_response: 'Yhteys palvelimeen epäonnistui, tarkista verkkoasetuksesi.',
      not_found: 'Käytät uusinta Beam Studio -versiota.',
      switch_or_not: 'on valmis vaihtamaan. Haluatko käynnistää uudelleen ja vaihtaa nyt?',
      switch_version: 'Vaihda versiota',
      switch_version_not_found: 'Vaihdettavaa versiota ei löytynyt.',
      update_for_ador: 'Ohjelmistoversio %s ei ole yhteensopiva, lataa uusin Beam Studio -versio Adorille.',
      yes: 'Kyllä',
    },
    update: 'Päivitä',
    updating: 'Päivitetään...',
    upload: 'LÄHETÄ',
  },
  web_cam: {
    no_device: 'Kameralaitetta ei havaittu. Kytke kamera uudelleen ja yritä uudelleen.',
    no_permission: 'Beam Studiolla ei ole lupaa käyttää kameraa. Varmista, että lupa on myönnetty Beam Studiolle selainasetuksissa tai järjestelmäasetuksissa.',
  },
  z_speed_limit_test: {
    alert_before: 'Ennen käyrän kaiverruksen aloittamista järjestelmä suorittaa kuormitustestin estääkseen askelhäviön liiallisen painon vuoksi.',
    alert_failed: 'Nykyisen objektin paino ylittää nykyisen Z-akselin nopeuden kuormitusrajan. Vähennä Z-akselin nopeutta koneen paneelissa tai vaihda kaiverrettava objekti ennen uudelleentestauksia.',
    ignore: 'Ohita',
    retest: 'Testaa uudelleen',
    testing: 'Suoritetaan kuormitustestiä...',
  },
};

export default lang;
