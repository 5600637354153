import type { ILang } from '@core/interfaces/ILang';

const lang: ILang = {
  alert: {
    abort: 'Afbryd',
    cancel: 'Annuller',
    caption: 'Fejl',
    close: 'Luk',
    confirm: 'Bekræft',
    dont_save: 'Gem ikke',
    dont_show_again: 'Vis ikke igen',
    duplicated_preset_name: 'Duplikeret forudindstillet navn',
    error: 'UH-OH',
    info: 'INFO',
    instruction: 'Instruktion',
    learn_more: 'Lær mere',
    no: 'Nej',
    ok: 'OK',
    ok2: 'OK',
    oops: 'Ups...',
    retry: 'Prøv igen',
    save: 'Gem',
    stop: 'Stop',
    warning: 'ADVARSEL',
    yes: 'Ja',
  },
  auto_fit: {
    artwork_size: 'Størrelse på kunstværk',
    error_tip1: '1. Er kunstværket placeret korrekt på materialet?',
    error_tip2: '2. Er materialets konturer klare nok til at blive genkendt?',
    failed_to_find_contour: 'Kunne ikke finde nogen konturer til automatisk tilpasning',
    learn_more: 'Vis vejledning.',
    learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    offset_x: 'Forskydning X',
    offset_y: 'Forskydning Y',
    pattern_size: 'Mønsterstørrelse',
    position_artwork: 'Placer kunstværket',
    position_step1: 'Juster størrelsen og placeringen af dit kunstværk på mønsteret.',
    position_step2: 'Klik på "Anvend" for at anvende Auto Fit på dette mønster.',
    preview_first: 'Udfør venligst kameraforhåndsvisning først.',
    reset_position: 'Nulstil position',
    rotation: 'Drej',
    select_a_pattern: 'Vælg et mønster',
    selected_artwork: 'Valgt kunstværk',
    step1: 'Vælg et mønster, hvor du vil placere kunstværket.',
    step2: 'Hvis der ikke findes noget mønster, skal du forhåndsvise kameraet igen og anvende Auto Fit.',
    step3: 'Hvis det stadig ikke lykkes at finde det korrekte mønster, skal du sikre, at dit emne er tydeligt genkendeligt, og arbejdsområdet er rent for snavs.',
    title: 'Auto Fit',
  },
  barcode_generator: {
    bar_height: 'Streg højde',
    bar_width: 'Stregbredde',
    barcode: {
      invalid_value: 'Værdien er ugyldig for det valgte format.',
    },
    font: 'Skrifttype',
    font_size: 'Skriftstørrelse',
    hide_text: 'Skjul tekst',
    invert_color: 'Invertér farve',
    text_margin: 'Tekstmargen',
  },
  beambox: {
    announcement_panel: {
      title: 'Meddelelse',
    },
    banner: {
      auto_feeder: 'Auto Feeder Tilstand',
      camera_preview: 'Forhåndsvisning',
      camera_preview_borderless_mode: '(Åben Bund)',
      curve_engraving: 'Kurvegraveringstilstand',
      pass_through: 'Gennemgangstilstand',
      rotary: 'Rotationsfunktion',
    },
    bottom_right_panel: {
      convert_text_to_path_before_export: 'Konverter tekst til sti...',
      export_file_error_ask_for_upload: 'Kunne ikke eksportere opgave. Er du villig til at dele arbejdsscenen med udviklingsholdet til fejlrapportering?',
      retreive_image_data: 'Henter billeddata...',
    },
    context_menu: {
      copy: 'Kopier',
      cut: 'Klip',
      delete: 'Slet',
      duplicate: 'Dupliker',
      group: 'Grupper',
      move_back: 'Send til bagende',
      move_down: 'Send bagud',
      move_front: 'Bring til forgrund',
      move_up: 'Bring frem',
      paste: 'Indsæt',
      paste_in_place: 'Indsæt på plads',
      ungroup: 'Del gruppe',
    },
    document_panel: {
      add_on: 'Tilføjelser',
      auto_feeder: 'Automatisk føder',
      auto_feeder_scale: 'Skala for automatisk føder',
      auto_feeder_url: 'https://support.flux3dp.com/hc/en-us/articles/11688230498575',
      borderless_mode: 'Åben bund',
      current_position: 'Nuværende Position',
      disable: 'Deaktivér',
      document_settings: 'Dokumentindstillinger',
      enable: 'Aktivér',
      enable_autofocus: 'Autofokus',
      enable_diode: 'Diodelaser',
      engrave_dpi: 'Opløsning',
      frame_before_start: 'Ramme før udførelse',
      frame_before_start_url: 'https://support.flux3dp.com/hc/en-us/articles/11494925637135',
      high: 'Høj',
      job_origin: 'Job Oprindelse',
      laser_source: 'Laserkilde',
      low: 'Lav',
      machine: 'Maskine',
      medium: 'Medium',
      notification: {
        changeFromPrintingWorkareaTitle: 'Vil du konvertere Udskriftslag til Laserskærelag?',
      },
      origin: 'Oprindelse',
      pass_through: 'Gennemgang',
      pass_through_height_desc: 'Indtast længden på objektet for at udvide arbejdsområdet.',
      rotary_mode: 'Roterende',
      scale: 'Skala',
      start_from: 'Start Fra',
      start_position: 'Startposition',
      start_work_button: 'Udfør Knap',
      ultra: 'Ultra høj',
      workarea: 'Arbejdsområde',
    },
    image_trace_panel: {
      back: 'Tilbage',
      brightness: 'Lysstyrke',
      cancel: 'Annullér',
      contrast: 'Kontrast',
      next: 'Næste',
      okay: 'Ok',
      threshold: 'Tærskel',
      tuning: 'Parametre',
    },
    layer_color_config_panel: {
      add: 'Tilføj',
      add_config: 'Tilføj farve',
      color: 'Farve',
      default: 'Nulstil til standard',
      in_use: 'Denne farve er i brug.',
      layer_color_config: 'Lagfarvekonfigurationer',
      no_input: 'Angiv venligst gyldig hex-farvekode.',
      power: 'Effekt',
      repeat: 'Udfør',
      speed: 'Hastighed',
      sure_to_delete: 'Er du sikker på, at du vil slette denne farveindstilling?',
      sure_to_reset: 'Du vil miste alle tilpassede parametre, er du sikker på, at du vil nulstille til standard?',
    },
    left_panel: {
      borderless_blind_area: 'Ikke-graveringsområde',
      borderless_preview: 'Åbn bundkameraforhåndsvisning i bundløs tilstand',
      diode_blind_area: 'Hybrid laser blindt område',
      ellipse: 'Ellipse',
      image: 'Billede',
      label: {
        adjust_height: 'Juster højde',
        array: 'Array',
        boxgen: 'Boxgen',
        choose_camera: 'Kamera',
        clear_preview: 'Ryd forhåndsvisning',
        cursor: 'Vælg',
        curve_engraving: {
          clear_area: 'Ryd valgt område',
          exit: 'Afslut',
          preview_3d_curve: 'Forhåndsvis kurvegravering',
          select_area: 'Vælg område',
          title: 'Kurvegravering',
        },
        end_preview: 'Afslut forhåndsvisning',
        line: 'Linje',
        live_feed: 'Live-feed',
        my_cloud: 'Min sky',
        oval: 'Oval',
        pass_through: 'Gennemgang',
        pen: 'Pen',
        photo: 'Billede',
        polygon: 'Polygon',
        preview: 'Kamera forhåndsvisning',
        qr_code: 'QR-kode',
        rect: 'Rektangel',
        shapes: 'Elementer',
        text: 'Tekst',
        trace: 'Spor billede',
      },
      line: 'Linje',
      rectangle: 'Rektangel',
      text: 'Tekst',
      unpreviewable_area: 'Blindt område',
    },
    network_testing_panel: {
      average_response: 'Gennemsnitlig svartid',
      cannot_connect_1: '#840 Kan ikke oprette forbindelse til mål-IP.',
      cannot_connect_2: '#840 Kan ikke oprette forbindelse til mål-IP. Kontroller venligst, at målet er på samme netværk.',
      cannot_get_local: 'Adgang til lokal IP-adresse mislykkedes.',
      connection_quality: 'Forbindelseskvalitet',
      device_not_on_list: '#842 Maskinen er ikke på listen, men forbindelseskvaliteten er >70 og den gennemsnitlige reaktionstid er <100 ms',
      empty_ip: '#818 Angiv venligst destinations-IP først.',
      end: 'Stop',
      fail_to_start_network_test: '#817 Kan ikke starte netværkstest.',
      hint_connect_camera_timeout: 'Timeout opstår, når kameravisningen startes?',
      hint_connect_failed_when_sending_job: 'Mislykkedes med at oprette forbindelse ved afsendelse af et job?',
      hint_device_often_on_list: 'Maskinen findes ofte ikke på listen?',
      insert_ip: 'Destinations-IP-adresse:',
      invalid_ip: '#818 Ugyldig IP-adresse',
      ip_startswith_169: '#843 Maskine IP-adresse starter med 169.254',
      link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
      link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
      link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
      linux_permission_hint: 'Denne fejl opstår normalt på grund af utilstrækkelige tilladelser.\nKør venligst "sudo beam-studio --no-sandbox" i terminalen for at få tilladelser og udføre netværkstest.',
      local_ip: 'Lokal IP-adresse:',
      network_testing: 'Netværkstest',
      network_unhealthy: '#841 Forbindelseskvalitet <70 eller gennemsnitlig reaktionstid >100 ms',
      start: 'Start',
      test_completed: 'Test afsluttet',
      test_fail: 'Test mislykkedes',
      testing: 'Tester netværk...',
    },
    object_panels: {
      lock_desc: 'Bevar forholdet mellem bredde og højde (SHIFT)',
      text_to_path: {
        check_thumbnail_warning: 'Nogle tekster blev ændret til andre skrifttyper ved parsing af tekster til stier, og nogle tegn konverteres muligvis ikke korrekt. <br/>Kontrollér venligst billedet igen, før du sender opgaven.',
        error_when_parsing_text: 'Fejl ved konvertering af tekst til sti',
        font_substitute_pop: 'Din tekst indeholder tegn, der ikke understøttes af den aktuelle skrifttype. <br/>Vil du bruge <strong>%s</strong> som erstatning?',
        retry: 'Prøv igen senere eller vælg en anden skrifttype',
        use_current_font: 'Brug nuværende skrifttype',
      },
      wait_for_parsing_font: 'Parser skrifttype ... Vent et sekund',
    },
    path_preview: {
      current_position: 'Nuværende position',
      cut_distance: 'Skæreafstand',
      cut_time: 'Skæretid',
      end_preview: 'Slut forhåndsvisning',
      estimated_time: 'Samlet estimeret tid',
      invert: 'Inverter',
      pause: 'Pause',
      play: 'Afspil',
      play_speed: 'Afspilningshastighed',
      preview_info: 'Forhåndsvisningsoplysninger',
      rapid_distance: 'Rejseafstand',
      rapid_time: 'Rejsetid',
      remark: '* Alle oplysninger er et estimeret referenceværdi.',
      size: 'Størrelse',
      start_here: 'Start her',
      stop: 'Stop',
      travel_path: 'Rejsesti',
    },
    photo_edit_panel: {
      aspect_ratio: 'Billedformat',
      brightness: 'Lysstyrke',
      brightness_and_contrast: 'Lysstyrke / Kontrast',
      cancel: 'Annullér',
      circumference: 'Omkreds',
      compare: 'Sammenlign',
      contrast: 'Kontrast',
      crop: 'Beskær',
      curve: 'Kurve',
      diameter: 'Diameter',
      free: 'Fri',
      invert: 'Inverter farve',
      okay: 'Ok',
      original: 'Original',
      phote_edit: 'Fotoredigering',
      processing: 'Behandler',
      radius: 'Radius',
      rotary_warped: 'Roterende Forvrænget',
      rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
      sharpen: 'Skarphed',
      sharpness: 'Skarphed',
      start: 'Start',
      warp: 'Forvrængning',
    },
    popup: {
      ai_credit: {
        buy_link: 'https://member.flux3dp.com/en-US/credit',
        go: 'Gå til',
        insufficient_credit: 'Du er løbet tør for kredit',
        insufficient_credit_msg: 'Du kan ikke bruge %s. Gå til medlemscenteret og køb AI-kredit',
        relogin_to_use: 'Log venligst ind igen for at bruge denne funktion',
      },
      auto_feeder_origin: 'Brug indramningsforhåndsvisningen til at kontrollere laserhovedets bane og område, og justér derefter materialets placering eller graveringens startposition baseret på resultaterne for at undgå kollisioner under graveringsprocessen.',
      auto_switch_tab: {
        message: 'En ny mulighed for automatisk at skifte mellem lagpanelet og objektpanelet er blevet tilføjet i Præferencer. Denne mulighed er deaktiveret som standard. Vil du aktivere automatisk skift nu? <br/>Du kan ændre denne indstilling når som helst i Præferencer.',
        title: 'Automatisk Skift mellem Lag- og Objektpanel',
      },
      backend_connect_failed_ask_to_upload: '#802 Fejl opstår hele tiden, når der forsøges forbindelse til backend. Vil du uploade din fejlrapportlog?',
      backend_error_hint: 'Funktioner fungerer muligvis ikke korrekt på grund af backend-fejl.',
      both_power_and_speed_too_high: 'At bruge lavere laserstyrke forlænger laserens levetid. Desuden kan for høj hastighed ved denne opløsning resultere i ringere skyggegraveringskvalitet.',
      bug_report: 'Fejlrapport',
      change_workarea_before_preview: 'Arbejdsområdet for %s matcher ikke det aktuelt indstillede arbejdsområde. Vil du skifte det aktuelle arbejdsområde?',
      convert_to_path_fail: 'Kunne ikke konvertere til sti.',
      dxf_bounding_box_size_over: 'Tegningsstørrelsen er uden for arbejdsområdet. Flyt venligst din tegning tættere på oprindelsen i dit CAD-software, eller sørg for, at enheden er indstillet korrekt.',
      dxf_version_waring: 'Versionen af denne DXF-fil er ikke 2013, der kan være potentielle kompatibilitetsproblemer.',
      facebook_group_invitation: {
        already_joined: 'Allerede tilmeldt',
        join_now: 'Tæl mig med',
        later: 'Måske senere',
        message: 'Tilmeld vores officielle Facebook-gruppe for at oprette forbindelse med andre FLUX-laserbrugere, diskutere FLUX-lasere, dele laserkunst og holde dig opdateret med de seneste opdateringer på vores produkter. Vi glæder os til at se dig der!',
        title: 'Tilmeld FLUX officiel brugergruppe',
      },
      import_file_contain_invalid_path: '#808 Importeret SVG-fil indeholder ugyldig billedsti. Sørg for, at alle billedfiler findes, eller indsæt billede i filen',
      import_file_error_ask_for_upload: 'Kunne ikke importere SVG-fil. Er du villig til at give filen til udviklingsholdet til fejlrapport?',
      layer_by_color: 'Farve',
      layer_by_layer: 'Lag',
      loading_image: 'Indlæser billede, vent venligst...',
      more_than_two_object: 'For mange objekter. Understøtter kun 2 objekter',
      mouse: 'Mus',
      no_support_text: 'Beam Studio understøtter ikke tekst-tag i øjeblikket. Konvertér tekst til sti før import.',
      nolayer: 'Enkelt lag',
      not_support_object_type: 'Understøtter ikke objekttypen',
      or_turn_off_borderless_mode: ' Eller slå bundløs tilstand fra.',
      pdf2svg: {
        error_pdf2svg_not_found: '#825 Fejl: Kommandoen pdf2svg blev ikke fundet. Installer venligst pdf2svg med din pakkehåndtering (f.eks. "yum install pdf2svg" eller "apt-get install pdf2svg").',
        error_when_converting_pdf: '#824 Fejl ved konvertering af PDF til SVG:',
      },
      progress: {
        calculating: 'Beregner',
        uploading: 'Uploader',
      },
      questionnaire: {
        caption: 'Hjælp os med at udfylde spørgeskemaet',
        message: 'Hjælp os med at udfylde spørgeskemaet for at gøre Beam Studio bedre.',
        no_questionnaire_available: 'Der er ikke noget spørgeskema tilgængeligt lige nu.',
        unable_to_get_url: 'Kunne ikke hente linket til spørgeskemaet via internettet. Bekræft venligst din internetforbindelse.',
      },
      recommend_downgrade_software: 'Vi registrerede en ældre firmwareversion. Vi arbejder aktivt på kompatibilitetsproblemer, men foreløbig anbefaler vi at vende tilbage til Beam Studio 1.9.5.',
      recommend_upgrade_firmware: 'Vi registrerede en ældre firmwareversion. Vi arbejder aktivt på kompatibilitetsproblemer, men foreløbig anbefaler vi at opdatere til den nyeste firmware.',
      save_unsave_changed: 'Vil du gemme ikke-gemte ændringer?',
      select_at_least_two: 'Vælg to objekter for at fortsætte',
      select_first: 'Vælg et objekt først.',
      select_import_method: 'Vælg lagdelingsstil:',
      select_import_module: 'Vælg modul:',
      sentry: {
        message: 'Accepterer du automatisk at uploade relevante oplysninger til udviklingsholdet, når der opstår fejl?',
        title: 'Lad os gøre Beam Studio bedre sammen',
      },
      should_update_firmware_to_continue: '#814 Din firmware understøtter ikke denne version af Beam Studio. Opdater venligst firmwaren for at fortsætte. (Menu > Maskine > [Din maskine] > Opdater firmware)',
      speed_too_high_lower_the_quality: 'At bruge så høj hastighed ved denne opløsning kan resultere i ringere kvalitet af skyggegravering.',
      still_continue: 'Fortsæt',
      successfully_uploaded: 'Upload af fil lykkedes.',
      svg_1_1_waring: 'Versionen af denne SVG-fil er v 1.1, der kan være potentielle kompatibilitetsproblemer.',
      svg_image_path_waring: 'Denne SVG-fil indeholder <image>, der indlæser fra filstien. Dette kan medføre fejl ved indlæsning. \nFor at undgå denne risiko skal du venligst bruge integreret billede ved eksport af SVG.',
      text_to_path: {
        caption: 'Tekst til sti konverter 2.0',
        message: "Beam Studio introducerer nu en ny tekst til sti konverter (Konverter 2.0), som producerer mere pålidelige resultater! Vil du skifte til den nu? \nDu kan også justere denne indstilling senere i 'Tekst til sti konverter' afsnittet i indstillingerne.",
      },
      too_fast_for_auto_feeder: 'Brug af for høj hastighed i lag, der indeholder baneobjekter, kan resultere i lavere præcision ved skæring.\nVi anbefaler ikke at bruge en hastighed hurtigere end %(limit)s ved skæring med Auto Feeder.',
      too_fast_for_auto_feeder_and_constrain: 'Følgende lag: %(layers)s \nindeholder vektorbaner og har en hastighed, der overstiger %(limit)s.\nSkærehastigheden for vektorbaner med Auto Feeder vil blive begrænset til %(limit)s.\nDu kan fjerne denne grænse i Præferenceindstillinger.',
      too_fast_for_curve: 'Brug af for høj hastighed til lag, der indeholder kurvegravering, kan reducere graveringspræcisionen.\nVi anbefaler at holde hastigheden under %(limit)s for optimale resultater med kurvegravering.',
      too_fast_for_curve_and_constrain: 'Følgende lag: %(layers)s overstiger hastighedsgrænsen på %(limit)s.\nGraveringshastigheden for kurveobjekter vil blive begrænset til %(limit)s. Du kan fjerne denne grænse i præferenceindstillingerne.',
      too_fast_for_path: 'At bruge for høj hastighed i lag, der indeholder stivektor-objekter, kan resultere i ringere præcision ved skæring. Vi anbefaler ikke at bruge hastigheder over %(limit)s. ved skæring.',
      too_fast_for_path_and_constrain: 'Følgende lag: %(layers)s \nindeholder stivektor-stibaner og har hastigheder over %(limit)s. Skærehastigheden for stivektor-stibaner vil blive begrænset til %(limit)s. Du kan fjerne denne begrænsning under Indstillinger.',
      touchpad: 'Touchpad',
      ungroup_use: 'Dette vil opdele importerede DXF eller SVG filer. Fordi filen kan indeholde et stort antal elementer, kan det tage tid at opdele. Er du sikker på at fortsætte?',
      upload_failed: '#819 Upload af fil mislykkedes.',
      upload_file_too_large: '#819 Filen er for stor til upload.',
      vectorize_shading_image: 'Gradientbilleder tager længere tid at spore og er tilbøjelige til støj. Slå venligst billedgradienten fra, før du fortsætter.',
    },
    rating_panel: {
      description: 'Hvis du kan lide Beam Studio, vil vi sætte stor pris på, hvis du kunne bedømme os.',
      thank_you: 'Tak!',
      title: 'Synes du godt om Beam Studio?',
    },
    right_panel: {
      laser_panel: {
        advanced: 'Avanceret',
        backlash: 'Tilbageløb',
        bi_directional: 'Tovejskommunikation',
        by: 'Med',
        ce_z_high_speed: 'Højhastigheds Z-akse',
        ce_z_high_speed_link: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
        color_adjustment: 'Kanaljustering',
        color_adjustment_short: 'Kanal',
        color_strength: 'Styrke',
        cross_hatch: 'Kryds Skraffer',
        custom_preset: 'Brugerdefineret',
        cut: 'Skær',
        diode: 'Diodelaser',
        do_not_adjust_default_para: 'Standardindstillinger kan ikke justeres.',
        dottingTime: 'Punkteringstid',
        dropdown: {
          inches: {
            abs_engraving: 'ABS - Gravering',
            acrylic_3mm_cutting: 'Akryl - 1/8″ Skæring',
            acrylic_5mm_cutting: 'Akryl - 3/16″ Skæring',
            acrylic_8mm_cutting: 'Akryl - 1/4″ Skæring',
            acrylic_10mm_cutting: 'Akryl - 3/8″ Skæring',
            acrylic_engraving: 'Akryl - Gravering',
            acrylic_printing: 'Akryl - Udskrivning',
            aluminum_engraving: 'Aluminium - Gravering',
            aluminum_light: 'Aluminium (lys)',
            bamboo_printing: 'Bambus - Udskrivning',
            black_abs: 'Sort ABS',
            black_acrylic_3mm_cutting: 'Sort Akryl - 1/8″ Skæring',
            black_acrylic_5mm_cutting: 'Sort Akryl - 3/16″ Skæring',
            black_acrylic_engraving: 'Sort Akryl - Gravering',
            brass_dark: 'Messing (mørk)',
            brass_engraving: 'Messing - Gravering',
            brass_light: 'Messing (lys)',
            canvas_printing: 'Lærred - Udskrivning',
            cardstock_printing: 'Karton - Udskrivning',
            copper: 'Kobber',
            cork_printing: 'Kork - Udskrivning',
            denim_1mm_cutting: 'Denim - 1/32″ Skæring',
            fabric_3mm_cutting: 'Stof - 1/8″ Skæring',
            fabric_5mm_cutting: 'Stof - 3/16″ Skæring',
            fabric_engraving: 'Stof - Gravering',
            fabric_printing: 'Stof - Udskrivning',
            flat_stone_printing: 'Flad sten - Udskrivning',
            glass_bw_engraving: 'Glas - Gravering',
            glass_printing: 'Glas - Udskrivning',
            gloss_leather_printing: 'Blankt læder - Udskrivning',
            gold_engraving: 'Guld - Gravering',
            iron_engraving: 'Jern - Gravering',
            leather_3mm_cutting: 'Læder - 1/8″ Skæring',
            leather_5mm_cutting: 'Læder - 3/16″ Skæring',
            leather_engraving: 'Læder - Gravering',
            mdf_3mm_cutting: 'MDF - 1/8″ Skæring',
            mdf_5mm_cutting: 'MDF - 3/16″ Skæring',
            mdf_engraving: 'MDF - Gravering',
            metal_bw_engraving: 'Metal - Gravering',
            opaque_acrylic: 'Uigennemsigtigt akryl',
            pc_printing: 'PC - Udskrivning',
            rubber_bw_engraving: 'Gummi - Gravering',
            silver_engraving: 'Sølv - Gravering',
            stainless_steel_bw_engraving_diode: 'Metal - gravering (diodelaser)',
            stainless_steel_dark: 'Rustfrit stål (mørk)',
            stainless_steel_engraving: 'Rustfrit stål - Gravering',
            stainless_steel_light: 'Rustfrit stål (lys)',
            stainless_steel_printing: 'Rustfrit stål - Udskrivning',
            steel_engraving_spray_engraving: 'Metal - Gravering',
            stone: 'Sten',
            ti_engraving: 'Titanium - Gravering',
            titanium_dark: 'Titanium (mørk)',
            titanium_light: 'Titanium (lys)',
            white_abs: 'Hvid ABS',
            wood_3mm_cutting: 'Træ - 1/8″ Skæring',
            wood_5mm_cutting: 'Træ - 3/16″ Skæring',
            wood_7mm_cutting: 'Træ - 1/4″ Skæring',
            wood_8mm_cutting: 'Træ - 1/4″ Skæring',
            wood_10mm_cutting: 'Træ - 3/8″ Skæring',
            wood_engraving: 'Træ - Gravering',
            wood_printing: 'Træ - Udskrivning',
          },
          mm: {
            abs_engraving: 'ABS - Gravering',
            acrylic_3mm_cutting: 'Akryl - 3 mm Skæring',
            acrylic_5mm_cutting: 'Akryl - 5 mm Skæring',
            acrylic_8mm_cutting: 'Akryl - 8 mm Skæring',
            acrylic_10mm_cutting: 'Akryl - 10 mm Skæring',
            acrylic_engraving: 'Akryl - Gravering',
            acrylic_printing: 'Akryl - Udskrivning',
            aluminum_engraving: 'Aluminium - Gravering',
            aluminum_light: 'Aluminium (lys)',
            bamboo_printing: 'Bambus - Udskrivning',
            black_abs: 'Sort ABS',
            black_acrylic_3mm_cutting: 'Sort akryl - 3 mm Skæring',
            black_acrylic_5mm_cutting: 'Sort akryl - 5 mm Skæring',
            black_acrylic_engraving: 'Sort akryl - Gravering',
            brass_dark: 'Messing (mørk)',
            brass_engraving: 'Messing - Gravering',
            brass_light: 'Messing (lys)',
            canvas_printing: 'Lærred - Udskrivning',
            cardstock_printing: 'Karton - Udskrivning',
            copper: 'Kobber',
            cork_printing: 'Kork - Udskrivning',
            denim_1mm_cutting: 'Denim - 1 mm Skæring',
            fabric_3mm_cutting: 'Stof - 3 mm Skæring',
            fabric_5mm_cutting: 'Stof - 5 mm Skæring',
            fabric_engraving: 'Stof - Gravering',
            fabric_printing: 'Stof - Udskrivning',
            flat_stone_printing: 'Flad sten - Udskrivning',
            glass_bw_engraving: 'Glas - Gravering',
            glass_printing: 'Glas - Udskrivning',
            gloss_leather_printing: 'Blankt læder - Udskrivning',
            gold_engraving: 'Guld - Gravering',
            iron_engraving: 'Jern - Gravering',
            leather_3mm_cutting: 'Læder - 3 mm Skæring',
            leather_5mm_cutting: 'Læder - 5 mm Skæring',
            leather_engraving: 'Læder - Gravering',
            mdf_3mm_cutting: 'New Zealand MDF - 3 mm Skæring',
            mdf_5mm_cutting: 'New Zealand MDF - 5 mm Skæring',
            mdf_engraving: 'New Zealand MDF - Gravering',
            metal_bw_engraving: 'Metal - Gravering',
            opaque_acrylic: 'Uigennemsigtigt akryl',
            pc_printing: 'PC - Udskrivning',
            rubber_bw_engraving: 'Gummi - Gravering',
            silver_engraving: 'Sølv - Gravering',
            stainless_steel_bw_engraving_diode: 'Metal - Gravering (diode laser)',
            stainless_steel_dark: 'Rustfrit stål (mørk)',
            stainless_steel_engraving: 'Rustfrit stål - Gravering',
            stainless_steel_light: 'Rustfrit stål (lys)',
            stainless_steel_printing: 'Rustfrit stål - Udskrivning',
            steel_engraving_spray_engraving: 'Metal - Gravering',
            stone: 'Sten',
            ti_engraving: 'Titan - Gravering',
            titanium_dark: 'Titanium (mørk)',
            titanium_light: 'Titanium (lys)',
            white_abs: 'Hvid ABS',
            wood_3mm_cutting: 'Træ - 3 mm Skæring',
            wood_5mm_cutting: 'Træ - 5 mm Skæring',
            wood_7mm_cutting: 'Træ - 7 mm Skæring',
            wood_8mm_cutting: 'Træ - 8 mm Skæring',
            wood_10mm_cutting: 'Træ - 10 mm Skæring',
            wood_engraving: 'Træ - Gravering',
            wood_printing: 'Træ - Udskrivning',
          },
          parameters: 'Forudindstillinger',
          save: 'Tilføj aktuelle parametre',
        },
        engrave: 'Graver',
        existing_name: 'Dette navn bruges allerede.',
        fill_angle: 'Fyld Vinkel',
        fill_interval: 'Fyld Interval',
        fill_setting: 'Udfyldningsindstillinger',
        filled_path_only: 'Kun for udfyldningsstier',
        focus_adjustment: 'Fokusjustering',
        frequency: 'Frekvens',
        gradient_only: 'Kun for gradientbilleder',
        halftone: 'Halvtoner',
        halftone_link: 'https://support.flux3dp.com/hc/zh-tw/articles/9402670389647',
        height: 'Objekthøjde',
        ink_saturation: 'Mætning',
        ink_type: {
          normal: 'Blæk',
          text: 'Blæktype',
          UV: 'UV-blæk',
        },
        laser_speed: {
          fast: 'Hurtig',
          max: 300,
          min: 3,
          slow: 'Langsom',
          step: 0.1,
          text: 'Hastighed',
          unit: 'mm/s',
        },
        low_power_warning: 'Lavere laserstyrke (under 10%) kan muligvis ikke udsende laserlys.',
        low_speed_warning: 'Lav hastighed kan forårsage brænding af materialet.',
        lower_focus: 'Sænk Fokus',
        lower_focus_desc: 'Sænke fokushøjden med en bestemt afstand efter fokusering for at forbedre skæreevnen.',
        module: 'Modul',
        more: 'Administrer',
        multi_layer: 'Flere lag',
        para_in_use: 'Denne parameter anvendes.',
        parameters: 'Parametre',
        power: {
          high: 'Høj',
          low: 'Lav',
          max: 100,
          min: 1,
          step: 0.1,
          text: 'Effekt',
        },
        preset_management: {
          add_new: 'Tilføj Ny',
          delete: 'Slet',
          export: 'Eksportér',
          export_preset_title: 'Eksportér forudindstillinger',
          import: 'Importér',
          laser: 'Laser',
          lower_focus_by: 'Sænk Fokus med',
          new_preset_name: 'Nyt forudindstillet navn',
          preset: 'Forudindstilling',
          print: 'Udskriv',
          reset: 'Nulstil',
          save_and_exit: 'Gem og luk',
          show_all: 'Vis Alle',
          sure_to_import_presets: 'Dette vil indlæse arrangementet af forudindstillinger og overskrive tilpassede parametre, er du sikker på, at du vil fortsætte?',
          sure_to_reset: 'Dette vil slette dine tilpassede parametre og nulstille alle forudindstillinger, er du sikker på, at du vil fortsætte?',
          title: 'Administrer Parametre',
          wobble_diameter: 'Wobble-diameter',
          wobble_step: 'Wobble-trinstørrelse',
        },
        preset_setting: 'Indstillinger (%s)',
        presets: 'Forudindstilling',
        print_multipass: 'Multi-pass',
        promark_speed_desc: 'Hastighedsparametre gælder ikke for gradientbilleder.',
        pulse_width: 'Pulsbredde',
        pwm_advanced_desc: 'Indstil en minimumseffekt for dybdetilstand.',
        pwm_advanced_hint: 'Denne indstilling gælder specifikt for gradientbilleder, når du bruger dybdetilstand.',
        pwm_advanced_setting: 'Dybdetilstands Strømindstillinger',
        repeat: 'Antal Gennemløb',
        single_color: 'Enkel Farve',
        single_color_desc: 'Kun gældende for fuldfarvet lag og kan ikke bruges med udvidet enkeltfarvet lag.',
        slider: {
          fast: 'Hurtig',
          high: 'Høj',
          low: 'Lav',
          regular: 'Almindelig',
          slow: 'Langsom',
          very_fast: 'Hurtigst',
          very_high: 'Maks',
          very_low: 'Min',
          very_slow: 'Langsomst',
        },
        speed: 'Hastighed',
        speed_constrain_warning: 'Skærehastigheden for vektorstibaner vil være begrænset til %(limit)s. Du kan fjerne denne begrænsning under Indstillinger.',
        speed_constrain_warning_auto_feeder: 'Skærehastigheden for Auto Feeder-objekter vil blive begrænset til %(limit)s. Du kan fjerne denne grænse i Indstillinger for præferencer.',
        speed_constrain_warning_curve_engraving: 'Hastigheden for kurvegravering vil blive begrænset til %(limit)s. Du kan fjerne denne grænse i præferenceindstillingerne.',
        stepwise_focusing: 'Trinvist Fokus',
        stepwise_focusing_desc: 'Gradvis sænkning af fokusafstanden baseret på objektets højde under hver gennemgang.',
        strength: 'Effekt',
        times: 'gange',
        various_preset: 'Forskellige forudindstillinger',
        white_ink: 'Hvid blæk',
        white_ink_settings: 'Hvid blæk indstillinger',
        wobble: 'Vibration',
        wobble_desc: 'Opret små cirkler langs stien, hvilket er gavnligt for skæreopgaver. (Kun for stiobjekter.)',
        wobble_diameter: 'Diameter',
        wobble_step: 'Trinstørrelse',
        z_step: 'Z-trin',
      },
      layer_panel: {
        current_layer: 'Aktuelt lag',
        layer1: 'Lag 1',
        layer_bitmap: 'Bitmap',
        layer_cutting: 'Skæring',
        layer_engraving: 'Gravering',
        layers: {
          del: 'Slet lag',
          dupe: 'Dupliker lag',
          fullColor: 'Fuld farve',
          layer: 'Lag',
          layers: 'Lag',
          lock: 'Lås lag',
          merge_all: 'Sammenlæg alle',
          merge_down: 'Sammenlæg nedad',
          merge_selected: 'Sammenlæg valgte lag',
          move_down: 'Flyt lag ned',
          move_elems_to: 'Flyt elementer til:',
          move_selected: 'Flyt valgte elementer til et andet lag',
          move_up: 'Flyt lag op',
          new: 'Nyt lag',
          rename: 'Omdøb lag',
          splitFullColor: 'Udvid lag',
          switchToFullColor: 'Skift til fuldfarvelag',
          switchToSingleColor: 'Skift til enkelt farvelag',
          unlock: 'Lås op',
        },
        move_elems_to: 'Flyt elementer til:',
        notification: {
          dupeLayerName: 'Der er allerede et lag med det navn!',
          enterNewLayerName: 'Indtast venligst det nye lagnavn',
          enterUniqueLayerName: 'Indtast venligst et unikt lagnavn',
          layerHasThatName: 'Laget har allerede det navn',
          mergeLaserLayerToPrintingLayerMsg: 'Vær opmærksom på, at hvis du gennemfører denne handling, vil indstillingerne for laserlaget blive fjernet og indstillet til det aktuelle lag.',
          mergeLaserLayerToPrintingLayerTitle: 'Vil du sammenlægge disse lag til ét udskriftslag?',
          mergePrintingLayerToLaserLayerMsg: 'Vær opmærksom på, at hvis du gennemfører denne handling, vil farveindstillingerne for udskriftslaget blive fjernet og indstillet til det aktuelle lag.',
          mergePrintingLayerToLaserLayerTitle: 'Vil du sammenlægge disse lag til ét laserlag?',
          moveElemFromPrintingLayerMsg: 'Bemærk, at hvis du gennemfører denne handling, vil farveindstillingerne for det valgte element blive fjernet og indstillet til %s.',
          moveElemFromPrintingLayerTitle: 'Flyt det valgte element til %s og konverter det til et laserelement?',
          moveElemToPrintingLayerMsg: 'Bemærk, at hvis du gennemfører denne handling, vil indstillingerne for det valgte element blive fjernet og indstillet til %s.',
          moveElemToPrintingLayerTitle: 'Flyt det valgte element til %s og konverter det til et udskriftselement?',
          newName: 'NYT NAVN',
          QmoveElemsToLayer: "Flyt valgte elementer til lag '%s'?",
          splitColorMsg: 'Bemærk, at hvis du fortsætter med denne handling, vil du ikke kunne vende tilbage til de oprindelige farvelag.',
          splitColorTitle: 'Vil du udvide det valgte lag til CMYK-lag?',
        },
      },
      object_panel: {
        actions_panel: {
          ai_bg_removal: 'Baggrundsfjernelse',
          ai_bg_removal_reminder: 'Tryk på knappen vil straks bruge 0,2 kredit. Vil du fortsætte?',
          ai_bg_removal_short: 'BG fjernelse',
          array: 'Array',
          auto_fit: 'Auto Fit',
          bevel: 'Fas',
          brightness: 'Lysstyrke',
          convert_to_path: 'Konverter til sti',
          create_textpath: 'Opret tekst på sti',
          create_textpath_short: 'Stitekst',
          crop: 'Beskær',
          decompose_path: 'Dekomponer',
          detach_path: 'Skil tekst på sti ad',
          detach_path_short: 'Skil ad',
          disassemble_use: 'Skil ad',
          disassembling: 'Skiller ad...',
          edit_path: 'Rediger sti',
          fetching_web_font: 'Henter online skrift...',
          grading: 'Gradering',
          invert: 'Inverter',
          offset: 'Forskyd',
          outline: 'Omrids',
          replace_with: 'Erstat med...',
          replace_with_short: 'Erstat',
          sharpen: 'Skarphed',
          simplify: 'Forenklet',
          smart_nest: 'Smart Nest',
          trace: 'Spor',
          ungrouping: 'Skiller gruppe ad...',
          uploading_font_to_machine: 'Uploader skrift til maskinen...',
          wait_for_parsing_font: 'Fortolker tekst... Vent venligst',
          weld_text: 'Svejs tekst',
        },
        align: 'Juster',
        boolean: 'Boolesk',
        bottom_align: 'Bundjuster',
        center_align: 'Centrer',
        difference: 'Forskel',
        distribute: 'Fordel',
        flip: 'Vend',
        group: 'Gruppér',
        hdist: 'Fordel vandret',
        hflip: 'Vandret vend',
        intersect: 'Gennemskær',
        left_align: 'Venstrejuster',
        lock_aspect: 'Lås størrelsesforhold',
        middle_align: 'Midtjuster',
        option_panel: {
          color: 'Farve',
          fill: 'Udfyldning',
          font_family: 'Skrifttype',
          font_size: 'Størrelse',
          font_style: 'Stil',
          letter_spacing: 'Bogstavafstand',
          line_spacing: 'Linjeafstand',
          path_infill: 'Stiforfyldning',
          pwm_engraving: 'Dybde-tilstand',
          pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
          rounded_corner: 'Afrundede hjørner',
          shading: 'Gradient',
          sides: 'Sider',
          start_offset: 'Tekstforskydning',
          stroke: 'Streg',
          stroke_color: 'Stregfarve',
          stroke_width: 'Stregbredde',
          text_infill: 'Tekstudfyldning',
          threshold: 'Tærskel lysstyrke',
          threshold_short: 'Tærskel',
          vertical_align: 'Justering',
          vertical_text: 'Lodret tekst',
        },
        path_edit_panel: {
          connect: 'Forbind',
          delete: 'Slet',
          disconnect: 'Afbryd',
          node_type: 'NODETYPE',
          round: 'Rund',
          sharp: 'Skarp',
        },
        right_align: 'Højrejuster',
        subtract: 'Fratræk',
        top_align: 'Topjuster',
        ungroup: 'Opdel gruppe',
        union: 'Forening',
        unlock_aspect: 'Lås størrelsesforhold op',
        vdist: 'Fordel lodret',
        vflip: 'Lodret vend',
        zoom: 'Zoom',
      },
      tabs: {
        layers: 'Lag',
        objects: 'Objekter',
        path_edit: 'Stiredigering',
      },
    },
    shapes_panel: {
      animals: 'Dyr',
      arrow: 'Pil',
      basic: 'Grundlæggende',
      birds: 'Fugle',
      celebration: 'Fest',
      circular: 'Cirkulær',
      CNY: 'Kinesisk nytår',
      corner: 'Hjørne',
      decor: 'Dekoration',
      easter: 'Påske',
      elements: 'Elementer',
      environment: 'Miljø',
      graphics: 'Grafik',
      halloween: 'Allehelgensaften',
      holidays: 'Helligdage',
      label: 'Etiket',
      land: 'Land',
      line: 'Linje',
      nature: 'Natur',
      photo: 'Foto',
      plants: 'Planter',
      ribbon: 'Bånd',
      sea: 'Hav',
      shape: 'Form',
      speech: 'Tale',
      text: 'Tekstboks',
      title: 'Elementer',
      valentines: 'Valentinsdag',
      weather: 'Vejr',
      Xmas: 'Jul',
    },
    svg_editor: {
      unable_to_fetch_clipboard_img: 'Kunne ikke hente billede fra dit udklipsholder',
      unnsupported_file_type: 'Filtypen understøttes ikke direkte. Konvertér filen til SVG eller bitmap',
    },
    tag: {
      g: 'Gruppe',
      image: 'Billede',
      text: 'Tekst',
      use: 'Importer SVG',
    },
    time_est_button: {
      calculate: 'Estimer tid',
      estimate_time: 'Estimeret tid:',
    },
    tool_panels: {
      _nest: {
        end: 'Luk',
        no_element: 'Der er ingen elementer at placere.',
        rotations: 'Mulige rotationer',
        spacing: 'Afstand',
        start_nest: 'Placer',
        stop_nest: 'Stop',
      },
      _offset: {
        corner_type: 'Hjørne',
        direction: 'Forskydningsretning',
        dist: 'Forskydningsafstand',
        fail_message: 'Kunne ikke forskyde objekter',
        inward: 'Indad',
        not_support_message: 'Valgte elementer indeholder ikke-understøttede SVG-tags:\nBillede, Gruppe, Tekst og Importeret Objekt.',
        outward: 'Udad',
        round: 'Rund',
        sharp: 'Skarp',
      },
      array_dimension: 'Arraydimension',
      array_interval: 'Arrayinterval',
      cancel: 'Annuller',
      columns: 'Kolonner',
      confirm: 'Bekræft',
      dx: 'X',
      dy: 'Y',
      grid_array: 'Opret gitterarray',
      nest: 'Placering Optimering',
      offset: 'Forskydning',
      rows: 'Rækker',
    },
    units: {
      mm: 'mm',
      walt: 'W',
    },
    zoom_block: {
      fit_to_window: 'Tilpas til vindue',
    },
  },
  boxgen: {
    add_option: 'Tilføj mulighed',
    basic_box: 'Basic Box',
    beam_radius: 'Kerfkompensation',
    beam_radius_warning: 'Fjern kerfkompensation, når kantene eller samlingerne på kassen er korte for at sikre kassemontering',
    cancel: 'Annullér',
    coming_soon: 'Kommer snart',
    continue_import: 'Fortsæt med at importere',
    control_tooltip: 'Venstre museknap for at rotere\nRul for at zoome\nHøjre museknap for at panorere',
    control_tooltip_touch: 'Træk for at rotere\nKnib sammen for at zoome\nTo fingre for at panorere',
    cover: 'Dæksel',
    customize: 'Tilpas',
    depth: 'Dybde',
    edge: 'Kant',
    finger: 'Finger',
    finger_warning: 'Den indvendige sidelængde af boksen skal være mindst 6 mm (0,24 tommer) for at være kompatibel med fingersammenføjning.',
    height: 'Højde',
    import: 'Importer',
    inner: 'Indre',
    joints: 'Samling',
    max_dimension_tooltip: 'Maks. bredde/højde/dybde indstilling er %s.',
    merge: 'Sammenføj',
    outer: 'Ydre',
    reset: 'Nulstil',
    tCount: 'T Tæl',
    tCount_tooltip: 'T-spor antallet gælder for den korte side; mængden på den lange side beregnes ud fra dens længde.',
    tDiameter: 'T Diameter',
    text_label: 'Etiket',
    thickness: 'Tykkelse',
    title: 'BOXGEN',
    tLength: 'T Længde',
    tSlot: 'T-spor',
    tSlot_warning: 'Boksside længden skal være mindst 30 mm (1,18 tommer) for at være kompatibel med T-spor sammenføjning.',
    volume: 'Volumen',
    width: 'Bredde',
    workarea: 'Arbejdsområde',
    zoom: 'Zoom',
  },
  buttons: {
    back: 'TILBAGE',
    back_to_beam_studio: 'Tilbage til Beam Studio',
    done: 'FÆRDIG',
    next: 'NÆSTE',
  },
  calibration: {
    ador_autofocus_focusing_block: 'Tryk på "AF"-ikonet på maskinens hovedside i 3 sekunder, og lad sonden røre fokuseringsblokken.',
    ador_autofocus_material: 'Tryk på "AF"-ikonet på maskinens hovedside i 3 sekunder, og lad sonden røre materialet blidt.',
    align_ilb: 'Position: Justér med graveringspunktet på den indre nederste venstre side.',
    align_ilt: 'Position: Justér med graveringpunktet på den indre øverste venstre.',
    align_irb: 'Position: Justér med graveringspunktet på den indre nederste højre side.',
    align_irt: 'Position: Justér med graveringspunktet på den indre øverste højre side.',
    align_olb: 'Position: Justér med graveringspunktet på den ydre nederste venstre side.',
    align_olt: 'Position: Justér med graveringpunktet på den ydre øverste venstre.',
    align_orb: 'Position: Justér med graveringspunktet på den ydre nederste højre side.',
    align_ort: 'Position: Justér med graveringspunktet på den ydre øverste højre side.',
    align_red_cross_cut: 'Juster venligst midten af det røde kryds med skærekorset.',
    align_red_cross_print: 'Juster venligst midten af det røde kryds med det printede kryds.',
    analyze_result_fail: 'Kunne ikke analysere det optagede billede. <br/> Kontroller venligst: <br/> 1. Det optagede billede dækker helt med hvidt papir. <br/> 2. Platformen er korrekt fokuseret.',
    ask_for_readjust: 'Vil du springe graveringstrinnet over og derefter tage et billede og kalibrere direkte?',
    back: 'Tilbage',
    calculating_camera_matrix: 'Beregner kameramatriks...',
    calculating_regression_parameters: 'Beregner regressionsparametre...',
    calibrate_camera_before_calibrate_modules: 'Udfør venligst kamera kalibrering før kalibrering af moduler.',
    calibrate_chessboard_success_msg: 'Skakbræt-fotoet er blevet taget med succes.<br/>Scoren for dette foto er %s (%.2f).',
    calibrate_done: 'Kalibrering udført. Bedre kameranøjagtighed opnås ved præcis fokusering.',
    calibrate_done_diode: 'Kalibrering udført. Forskydning af diode-lasermodul er gemt.',
    calibrating: 'Kalibrerer...',
    calibrating_with_device_pictures: 'Kalibrerer med enhedens billeder...',
    camera_calibration: 'Kamera kalibrering',
    camera_parameter_saved_successfully: 'Kameraparameter gemt succesfuldt.',
    cancel: 'Annuller',
    check_checkpoint_data: 'Kontrolpunktdata',
    check_device_pictures: 'Kontroller enhedens billeder',
    checking_checkpoint: 'Kontrollerer kontrolpunktdata...',
    checking_pictures: 'Kontrollerer enhedens billeder...',
    diode_calibration: 'Diode laser modul kalibrering',
    do_engraving: 'Udfør Gravering',
    download_chessboard_file: 'Download skakbræt fil',
    downloading_checkpoint: 'Downloader kontrolpunktdata...',
    downloading_pictures: 'Downloader billeder...',
    drawing_calibration_image: 'Tegner kalibreringsbillede...',
    dx: 'X',
    dy: 'Y',
    elevate_and_cut: 'Hæv og Skær',
    elevate_and_cut_step_1: 'Placer et A4-størrelse lyst træ midt i arbejdsområdet, hævet til 20mm.',
    elevate_and_cut_step_1_prism_lift: 'Brug Ador Prism Lift med en maksimal længde på 14mm sammen med et træ på mindst 6mm tykkelse.',
    failed_to_calibrate_camera: '#848 Kunne ikke kalibrere kamera, kontakt venligst FLUX-support.',
    failed_to_calibrate_chessboard: 'Kunne ikke kalibrere med skakbræt billedet.',
    failed_to_calibrate_with_pictures: '#848 Kunne ikke kalibrere med enhedens billeder.',
    failed_to_download_pictures: '#848 Kunne ikke downloade billeder, kontakt venligst FLUX-support.',
    failed_to_move_laser_head: 'Kunne ikke flytte laserhovedet.',
    failed_to_parse_checkpoint: 'Kunne ikke analysere kontrolpunktdata.',
    failed_to_save_calibration_results: '#849 Kunne ikke gemme kalibreringsresultater, prøv igen. Hvis dette bliver ved med at ske, skal du kontakte FLUX-support.',
    failed_to_save_camera_parameter: 'Kunne ikke gemme kameraparameter.',
    failed_to_solve_pnp: 'Kunne ikke løse kamerapositionen.',
    finish: 'Færdig',
    found_checkpoint: 'Kontrolpunktdata fundet på din enhed. Ønsker du at gendanne fra kontrolpunkt?',
    getting_plane_height: 'Får Højde på Plan...',
    hint_adjust_parameters: 'Brug disse parametre til at justere det røde kryds',
    hint_red_square: 'Juster venligst den røde firkants yderside med den skårne firkant.',
    module_calibration_2w_ir: 'Infrarødt modul kalibrering',
    module_calibration_printer: 'Printer modul kalibrering',
    moving_laser_head: 'Flytter laserhovedet...',
    next: 'Næste',
    no_picutre_found: '#846 Din enhed har ikke nogen tilgængelige rå fotos til kalibrering. Kontakt venligst FLUX support.',
    perform_autofocus_bb2: 'Gå venligst til maskinens kontrolpanel og tryk på AF for at udføre autofokus.',
    please_do_camera_calibration_and_focus: {
      beambox: 'Når du kalibrerer laserdiodemodulet, er kameraet nødvendigt.\nSørg venligst for, at kameraet på denne maskine er kalibreret.\nJuster venligst platformen til fokuspunktet (højden af det nedadgående akryl)',
      beamo: 'Når du kalibrerer laserdiodemodulet, er kameraet nødvendigt.\nSørg venligst for, at kameraet på denne maskine er kalibreret.\nJuster venligst laserhovedet til at fokusere på graveringsobjektet (højden af det nedadgående akryl)',
    },
    please_goto_beambox_first: 'Skift venligst til graveringstilstand (Beambox) for at bruge denne funktion.',
    please_place_dark_colored_paper: 'Anbring venligst et A4- eller brevstørrelse mørkt farvet papir i midten af arbejdsområdet.',
    please_place_paper: 'Anbring venligst et A4- eller brevstørrelse hvidt papir i venstre øverste hjørne af arbejdsområdet.',
    please_place_paper_center: 'Anbring venligst et A4- eller brevstørrelse hvidt papir i midten af arbejdsområdet.',
    please_refocus: {
      beambox: 'Juster venligst platformen til fokuspunktet (højden af den vendte acryl).',
      beamo: 'Juster venligst laserhovedet til at fokusere på graveringsobjektet (højden af den vendte acryl).',
      beamo_af: 'Dobbelttryk venligst på sideknappen på autofokus-tilbehøret og lad proben røre materialet blidt.',
      hexa: 'Dobbeltklik på højdejusteringsknappen for at løfte bordet op og få proben til at røre graveringsmaterialet.',
    },
    preparing_to_take_picture: 'Forbereder til at tage billede...',
    promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    put_chessboard: 'Placer Skakbræt',
    put_chessboard_1: 'Gå venligst til maskinens kontrolpanel og tryk på AF for at udføre autofokus.',
    put_chessboard_2: 'Flyt venligst skakbrætpapiret eller laserhovedet, indtil hele skakbrættet er bekræftet at være inden for den røde ramme i live-forhåndsvisningsvinduet.',
    put_chessboard_3: 'Højreklik for at downloade skakbræt-billedet på den nuværende placering og kontrollér dets klarhed.',
    put_chessboard_bb2_desc_1: 'Download den følgende skakbræt-fil, og print den på A4-papir (det printede skakbræt skal have 1x1 cm felter).',
    put_chessboard_bb2_desc_2: 'Fastgør det printede skakbræt på en ikke-deformerbar plade, såsom akryl eller glas, og sørg for, at skakbrættet er glat og fri for rynker eller løft.',
    put_chessboard_bb2_desc_3: 'Placer pladen med skakbrættet fladt i midten af arbejdsområdet.',
    put_chessboard_promark_1: 'Juster brændvidden til den passende fokus for feltlinsen.',
    put_chessboard_promark_2: 'Sørg for, at skakbrætspapiret er tydeligt synligt uden genskin. Klik på "Næste" for at tage et billede.',
    put_chessboard_promark_desc_1: 'Brug det skakbrætspapir, der er med i tilbehørsæsken, eller print det følgende skakbrætmønster på A4-papir til kamerapkalibrering.',
    put_chessboard_promark_desc_2: 'Juster fokus korrekt, og klik derefter på "Næste" for at fortsætte med graveringen.',
    put_paper: 'Læg papir',
    put_paper_promark_1: 'Placer det sorte pap fra tilbehørskassen på arbejdsplatformen.',
    put_paper_promark_2: 'Juster fokus korrekt, og klik derefter på "Start Gravering" for at fortsætte med graveringen.',
    put_paper_skip: 'Hvis kalibreringstegningen ikke automatisk går videre til at tage et billede, tryk på "Spring over" for at tage et billede.',
    put_paper_step1: 'Venligst placer et A4- eller brevstørrelse hvidt papir i midten af arbejdsområdet.',
    put_paper_step2: 'Sørg for at sikre de fire hjørner af papiret for at sikre, at det ligger fladt.',
    put_paper_step3: 'Klik på "Start Gravering".',
    res_average: 'Gennemsnitlig',
    res_excellent: 'Fremragende',
    res_poor: 'Dårlig',
    retake: 'Tag billede igen',
    rotation_angle: 'Rotation',
    show_last_config: 'Vis sidste resultat',
    skip: 'Spring Over',
    solve_pnp_step1: 'Justér venligst graveringpunkterne i henhold til nummeret og den forventede position for hvert rødt markørpunkt.',
    solve_pnp_step2: 'Du kan trykke på "Tag billede igen" for at justere igen eller manuelt justere markørpositionerne.',
    solve_pnp_step3: "Hvis det fangede billede ikke svarer til den faktiske scene, skal du zoome ind eller trække skærmen for at justere kalibreringspunktet på det sorte kalibreringskort til centrum, og derefter prøve at 'Tag billede igen'.",
    solve_pnp_title: 'Juster markørpunkterne',
    start_engrave: 'Start Gravering',
    start_printing: 'Start Udskrivning',
    taking_picture: 'Tager billede...',
    unable_to_load_camera_parameters: "#851 Der er ingen tilgængelige kamerakalibreringsparametre på din enhed. Gå til 'Kalibrering' > 'Kalibrér kamera (Avanceret)' for at fuldføre kalibreringen og få parametrene.",
    update_firmware_msg1: 'Din firmware understøtter ikke denne funktion. Opdater venligst firmware til v',
    update_firmware_msg2: 'eller nyere for at fortsætte。 (Menu > Maskine > [Din maskine] > Opdater firmware)',
    uploading_images: 'Uploader billeder...',
    use_last_config: 'Brug sidste kalibreringsværdi',
    use_old_camera_parameter: 'Ønsker du at bruge de aktuelle kamerelinseparametre?',
    with_af: 'Med autofokus',
    with_prism_lift: 'Med Ador Prism Lift',
    without_af: 'Uden autofokus',
    without_prism_lift: 'Uden Ador Prism Lift',
    x_ratio: 'X-forhold',
    y_ratio: 'Y-forhold',
    zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
  },
  camera_data_backup: {
    download_success: 'Kameradata er downloadet succesfuldt.',
    downloading_data: 'Downloader data...',
    estimated_time_left: 'Estimeret resterende tid:',
    folder_not_exists: 'Valgte mappe findes ikke.',
    incorrect_folder: 'Fejl ved upload af kalibreringsdata. Kontroller, om den valgte mappe er korrekt.',
    no_picture_found: 'Ingen billeder fundet i maskinen.',
    title: 'Kameradata Backup',
    upload_success: 'Kameradata er uploadet succesfuldt.',
    uploading_data: 'Uploader data...',
  },
  caption: {
    connectionTimeout: 'Forbindelsestimeout',
  },
  change_logs: {
    added: 'Tilføjet',
    change_log: 'Ændringslog',
    changed: 'Ændret',
    fixed: 'Rettet',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    see_older_version: 'Se ældre versioner',
  },
  code_generator: {
    barcode: 'Stregkode',
    qr_code: 'QR-kode',
  },
  curve_engraving: {
    '921': 'Automatisk fokusering mislykkedes.',
    '922': 'Kunne ikke udføre måling med rødt lys.',
    amount: 'Mængde',
    apply_arkwork: 'Anvend kunstværk på kurve',
    apply_camera: 'Anvend kamera billede på kurve',
    click_to_select_point: 'Klik for at vælge eller fravælge punkter til genmåling.',
    coloumns: 'Kolonner',
    column_gap: 'Kolonner Mellemrum',
    err_object_over_range: 'Objektet overstiger måleområdet.',
    failed_to_take_reference: 'Kunne ikke tage reference',
    gap: 'Mellemrum',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    measure_audofocus_area: 'Mål Autofokusområde',
    preview_3d_curve: 'Forhåndsvis kurvegravering',
    remeasure: 'Genmål',
    remeasuring_points: 'Genmåler punkter...',
    reselect_area: 'Vælg område igen',
    row_gap: 'Rækker Mellemrum',
    rows: 'Rækker',
    set_object_height: 'Indstil objektets højde',
    set_object_height_desc: 'Mål objektets maksimale tykkelse.',
    start_autofocus: 'Start Autofokus',
    starting_measurement: 'Starter måling...',
    sure_to_delete: 'Vil du slette fokusdataene for kurvegravering?',
    take_reference: 'Tag reference',
    take_reference_desc: 'Flyt venligst laserhovedet til objektets højeste punkt, sænk fokusproben, og klik på "Bekræft" for at fokusere.',
  },
  device: {
    abort: 'Afbryd',
    aborted: 'Afbrudt',
    aborting: 'Afbryder',
    busy: 'Optaget',
    cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
    cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
    cartridge_serial_number: 'Ink cartridge serial number',
    close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
    completed: 'Færdig',
    completing: 'Færdiggør',
    deviceList: 'Maskinliste',
    disable: 'Deaktiver',
    disconnectedError: {
      caption: 'Maskine afbrudt',
      message: 'Bekræft venligst om netværksadgang for %s er tilgængelig',
    },
    enable: 'Aktiver',
    firmware_version: 'Firmwareversion',
    ink_color: 'Ink color',
    ink_level: 'Ink level',
    ink_type: 'Ink type',
    IP: 'IP',
    model_name: 'Modelnavn',
    occupied: 'Optaget',
    pause: 'Pause',
    paused: 'Pauset',
    pausedFromError: 'Pauset pga. fejl',
    pausing: 'Pauser',
    please_wait: 'Vent venligst...',
    preparing: 'Forbereder',
    processing: 'Behandler',
    quit: 'Afslut',
    ready: 'Klar',
    reset: 'Nulstil',
    resuming: 'Genoptager',
    retry: 'Prøv igen',
    running: 'Kører',
    scanning: 'Scanner',
    select: 'Vælg',
    select_printer: 'Vælg printer',
    serial_number: 'Serienummer',
    start: 'Start',
    starting: 'Starter',
    status: 'Status',
    submodule_type: 'Module',
    toolhead_change: 'Skift Værktøjshoved',
    unknown: 'Ukendt',
    uploading: 'Uploader',
    UUID: 'UUID',
  },
  device_selection: {
    no_beambox: '#801 Vi kan ikke finde din maskine på netværket.\nFølg venligst <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">guiden</a> for at finde fejlen i forbindelsen.',
    no_device_web: "#801 Tjek venligst status for din maskine, eller klik på 'Maskinopsætning' nedenfor for at opsætte maskinen.",
    select_usb_device: 'Vælg USB-enhed',
  },
  editor: {
    exposure: 'Forhåndsvisningseksponering',
    opacity: 'Forhåndsvisning gennemsigtighed',
    prespray_area: 'Primeområde',
  },
  error_pages: {
    screen_size: 'Bemærk venligst at Beam Studio muligvis ikke fungerer optimalt på din enhed. For en optimal oplevelse bør det være en enhed med en skærm bredde på mindst 1024 pixels.',
  },
  flux_id_login: {
    connection_fail: '#847 Kunne ikke oprette forbindelse til FLUX medlemsservice.',
    email: 'E-mail',
    flux_plus: {
      access_monotype_feature: 'Du har ikke Monotype-skrifttyper Tilføjelse.',
      access_monotype_feature_note: 'Du skal have FLUX+ Pro-medlemskab eller Monotype-skrifttyper Tilføjelse for at få adgang til denne funktion.',
      access_plus_feature_1: 'Du får adgang til en',
      access_plus_feature_2: 'funktion.',
      access_plus_feature_note: 'Du skal have FLUX+ medlemskab for at få adgang til denne funktion.',
      ai_credit_tooltip: 'Til AI baggrundsfjernelse',
      explore_plans: 'Udforsk FLUX+ planer',
      features: {
        ai_bg_removal: 'AI baggrundsfjernelse',
        boxgen: '3D kassegenerator',
        dmkt: '1000+ designfiler',
        monotype: '250+ premium skrifttyper',
        my_cloud: 'Ubegrænset sky-lager',
      },
      flux_credit_tooltip: 'Til Design Market filer og AI baggrundsfjernelse',
      get_addon: 'Få tilføjelse',
      goto_member_center: 'Gå til Medlemscenter',
      learn_more: 'Lær mere',
      member_center_url: 'https://member.flux3dp.com/en-US/subscription',
      subscribe_now: 'Tilmeld dig nu',
      thank_you: 'Tak fordi du er et værdsat medlem!',
      website_url: 'https://flux3dp.com/subscription',
    },
    forget_password: 'Glemt adgangskode?',
    incorrect: 'E-mailadresse eller adgangskode er ikke korrekt.',
    login: 'Log ind',
    login_success: 'Logget ind med succes.',
    lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
    new_to_flux: 'Ny til FLUX? Opret en konto.',
    not_verified: 'E-mailadressen er endnu ikke bekræftet.',
    offline: 'Arbejd offline',
    password: 'Adgangskode',
    register: 'Opret din FLUX-konto',
    remember_me: 'Husk mig',
    signup_url: 'https://id.flux3dp.com/user/login#up',
    unlock_shape_library: 'Log ind for at låse formdatabasen op.',
    work_offline: 'Arbejd offline',
  },
  framing: {
    area_check: 'Områdekontrol',
    areacheck_desc: 'Sikrer arbejdsområdet ved at vise objektets afgrænsningsboks og laserhovedets accelerationszone.',
    calculating_task: 'Beregner opgave...',
    framing: 'Indramning',
    framing_desc: 'Forhåndsviser objektets afgrænsningsboks.',
    hull: 'Krop',
    hull_desc: 'Viser en form, der tæt følger designet, som et elastik, der er viklet omkring objektet.',
    low_laser: 'Lav laser',
    low_laser_desc: 'Indstil en lav laserværdi til rammeopgaven.',
    rotate_axis: 'Roter akse',
    rotateaxis_desc: 'Forhåndsvisning af rotationsaksens centrum, aksen selv bevæger sig ikke.',
    rotation_frame_warning: 'Rammeforhåndsvisningen vil rotere én gang. Undgå at stoppe midtvejs for at forhindre skæv markering.',
    rotation_framing_desc: 'Forhåndsvisning af det fulde rotationsområde, aksen roterer i henhold til forhåndsvisningen.',
    start_task: 'Start Opgave',
    start_task_description: 'Når rammeforhåndsvisningen er færdig, skal du klikke på "Start Opgave" for at indsende opgaven.',
  },
  general: {
    choose_folder: 'Vælg mappe',
    processing: 'Behandler...',
  },
  generic_error: {
    OPERATION_ERROR: '[OE] Der opstod en statuskonflikt, prøv handlingen igen.',
    SUBSYSTEM_ERROR: '[SE] Fejl, da maskinfirmwaren udførte opgaven. Genstart maskinen.',
    UNKNOWN_COMMAND: '[UC] Opdater venligst enhedsfirmwaren',
    UNKNOWN_ERROR: '[UE] Der opstod en ukendt fejl. Genstart venligst Beam Studio og maskinen.',
  },
  global: {
    apply: 'Anvend',
    back: 'Tilbage',
    cancel: 'Annuller',
    editing: {
      redo: 'Gentag',
      reset: 'Nulstil',
      undo: 'Fortryd',
      zoom_in: 'Zoom Ind',
      zoom_out: 'Zoom Ud',
    },
    mode_conflict: 'Denne mulighed er ikke tilgængelig i den aktuelle tilstand.',
    ok: 'OK',
    save: 'Gem',
    stop: 'Stop',
  },
  image_edit_panel: {
    eraser: {
      brush_size: 'Penselstørrelse',
      description: 'Klik eller træk for manuelt at slette uønskede områder.',
      title: 'Viskelæder',
    },
    magic_wand: {
      description: 'Vælg og fjern tilstødende områder baseret på farvesimilaritet.',
      title: 'Tryllestav',
      tolerance: 'Tolerance',
    },
    title: 'Rediger billede',
  },
  initialize: {
    back: 'Tilbage',
    cancel: 'Annuller',
    confirm: 'Bekræft',
    connect: 'Forbind',
    connect_ethernet: {
      title: 'Direkte forbindelse',
      tutorial1: '1. Forbind maskinen med din computer med et ethernetkabel.',
      tutorial2_1: '2. Følg ',
      tutorial2_2: ' for at gøre din computer til en router.',
      tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
      tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
      tutorial2_a_text: 'denne vejledning',
      tutorial3: '3. Klik på Næste.',
    },
    connect_machine_ip: {
      alert: {
        swiftray_connection_error: 'Kan ikke oprette forbindelse til serveren. Genstart Beam Studio og prøv igen.',
      },
      check_camera: 'Kontrollerer kamera tilgængelighed',
      check_connection: 'Kontrollerer maskinforbindelse',
      check_firmware: 'Kontrollerer firmware version',
      check_ip: 'Kontrollerer IP-tilgængelighed',
      check_swiftray_connection: 'Tjekker serverforbindelse',
      check_swiftray_connection_unreachable: 'server utilgængelig',
      check_usb: 'Kontroller USB-forbindelse',
      enter_ip: 'Indtast din maskines IP',
      finish_setting: 'Start med at oprette!',
      invalid_format: 'Ugyldigt format',
      invalid_ip: 'Ugyldig IP: ',
      promark_hint: 'Hvis du gentagne gange ikke kan oprette forbindelse via USB, henvises du til <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">Hjælpecenterets artikel</a>.',
      retry: 'Prøv igen',
      starts_with_169254: 'Starter med 169.254',
      succeeded_message: 'Forbindelse oprettet 🎉',
      unreachable: 'IP utilgængelig',
    },
    connect_usb: {
      connect_camera: 'Tilslut maskinens kamera til din computer med et USB-kabel.',
      title: 'USB-forbindelse',
      title_sub: ' (HEXA Only)',
      turn_off_machine: 'Sluk maskinen.',
      turn_on_machine: 'Tænd maskinen.',
      tutorial1: '1. Forbind maskinen med din computer med et USB-kabel.',
      tutorial2: '2. Klik på Næste.',
      wait_for_turning_on: 'Klik på "Næste", når opstartsprocessen er færdig, og du er kommet ind på hovedskærmen.',
    },
    connect_wifi: {
      title: 'Forbinder til Wi-Fi',
      tutorial1: '1. Gå til berøringsskærmen > Klik på "Netværk" > "Opret forbindelse til WiFi".',
      tutorial1_ador: '1. Gå til berøringspanelet > Klik på "MASKINE" > Klik på "Netværk" > "Opret forbindelse til WiFi".',
      tutorial2: '2. Vælg og opret forbindelse til dit foretrukne Wi-Fi.',
      what_if_1: 'Hvad nu hvis jeg ikke kan se mit Wi-Fi?',
      what_if_1_content: '1. Wi-Fi krypteringsmetoden bør være WPA2 eller ingen adgangskode.\n2. Krypteringsmetoden kan indstilles i Wi-Fi routeradministrationsgrænsefladen. Hvis routeren ikke understøtter WPA2, og du har brug for hjælp til at vælge den rigtige router, kontakt venligst FLUX Support.',
      what_if_2: 'Hvad nu, hvis jeg ikke ser noget Wi-Fi?',
      what_if_2_content: '1. Sørg for, at Wi-Fi-donglen er helt isat.\n2. Hvis der ikke er nogen MAC-adresse på det trådløse netværk på touchskærmen, kontakt venligst FLUX Support.\n3. Wi-Fi-kanalen bør være 2.4Ghz (5Ghz understøttes ikke).',
    },
    connect_wired: {
      title: 'Forbindelse til kablet netværk',
      tutorial1: '1. Forbind maskinen med din router.',
      tutorial2: '2. Tryk på "Netværk" for at få den kablede netværks-IP.',
      tutorial2_ador: '2. Tryk på "MASKINE" > "Netværk" for at få den kablede netværks-IP.',
      what_if_1: "Hvad nu hvis IP'en er tom?",
      what_if_1_content: '1. Sørg for, at Ethernet-kablet er helt isat.\n2. Hvis der ikke er nogen MAC-adresse på det kablede netværk på touchskærmen, kontakt venligst FLUX Support.',
      what_if_2: "Hvad nu hvis IP'en starter med 169?",
      what_if_2_content: '1. Hvis IP-adressen starter med 169.254, bør det være et DHCP-indstillingsproblem, kontakt venligst din ISP for yderligere hjælp.\n2. Hvis din computer opretter forbindelse til internettet direkte ved hjælp af PPPoE, skal du ændre til at bruge routeren til at oprette forbindelse ved hjælp af PPPoE og aktivere DHCP-funktionen i routeren.',
    },
    connecting: 'Forbinder...',
    connection_types: {
      ether2ether: 'Direkte forbindelse',
      usb: 'USB-forbindelse',
      wifi: 'Wi-Fi',
      wired: 'Ledningsnetværk',
    },
    next: 'Næste',
    no_machine: 'Jeg har ikke en maskine lige nu.',
    promark: {
      configuration_confirmation: 'Dette sikrer, at din Promark er korrekt konfigureret til optimal effektivitet og præcision.',
      or_complete_later: `Eller spring dette trin over og fuldfør Promark-indstillingerne senere på:
    Maskiner > "Promark Navn" > Promark-indstillinger`,
      qc_instructions: 'Udfyld parametrene på bagsiden af "QC Pass"-kortet',
      select_laser_source: 'Vælg din Promark',
      select_workarea: 'Vælg arbejdsområde',
      settings: 'Promark-indstillinger',
    },
    retry: 'Prøv igen',
    select_beambox: 'Vælg din Beambox',
    select_connection_type: 'Hvordan vil du forbinde?',
    select_language: 'Vælg sprog',
    select_machine_type: 'Vælg din model',
    setting_completed: {
      back: 'Tilbage',
      great: 'Velkommen til Beam Studio',
      ok: 'START MED AT OPRETTE',
      setup_later: 'Du kan altid opsætte din maskine fra titellinjen > "Maskiner" > "Maskinopsætning"',
      start: 'Start',
    },
    skip: 'Spring over',
    start: 'Start',
  },
  input_machine_password: {
    connect: 'FORBIND',
    password: 'Adgangskode',
    require_password: '"%s" kræver en adgangskode',
  },
  insecure_websocket: {
    extension_detected: 'Beam Studio Connect-udvidelse fundet',
    extension_detected_description: "Vi har registreret, at du har installeret Beam Studio Connect-udvidelsen. Klik venligst på 'Bekræft' for at omdirigere til HTTPS, eller klik på 'Annuller' for at fortsætte med at bruge HTTP.",
    extension_not_deteced: 'Kan ikke registrere Beam Studio Connect-udvidelse',
    extension_not_deteced_description: "For at bruge HTTPS skal du klikke på 'Bekræft' for at installere Beam Studio Connect-udvidelsen. Efter installation af udvidelsen skal du opdatere siden for at aktivere den.<br/>Ellers kan du klikke på linket nedenfor for at se, hvordan du bruger HTTP i Chrome.",
    unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Link</a>',
  },
  layer_module: {
    general_laser: 'Laser',
    laser_2w_infrared: '2W Infrarød laser',
    laser_10w_diode: '10W Diode laser',
    laser_20w_diode: '20W Diode laser',
    non_working_area: 'Ikke-arbejdsområde',
    none: 'None',
    notification: {
      convertFromLaserModuleMsg: 'Vær opmærksom på, at hvis du gennemfører denne handling, vil indstillingerne for laserlaget blive fjernet og indstillet i henhold til det aktuelle lag.',
      convertFromLaserModuleTitle: 'Vil du konvertere Lasermodulet til Printmodulet?',
      convertFromPrintingModuleMsg: 'Vær opmærksom på, at hvis du gennemfører denne handling, vil farveindstillingerne i printlaget blive fjernet og indstillet i henhold til det aktuelle lag.',
      convertFromPrintingModuleTitle: 'Vil du konvertere Printmodulet til Lasermodulet?',
      importedDocumentContainsPrinting: 'Dokumentet indeholder printlag, vil du ændre arbejdsområde til Ador?',
      performIRCaliMsg: 'Klik på "Bekræft" for at udføre kalibreringen, eller få adgang til kalibrering via topmenuen.<br />(Maskine > [Dit maskinnavn] > Kalibrer infrarød modul)',
      performIRCaliTitle: 'Udfør kalibrering af infrarød modul',
      performPrintingCaliMsg: 'Klik på "Bekræft" for at udføre kalibreringen, eller få adgang til kalibrering via topmenuen.<br />(Maskine > [Dit maskinnavn] > Kalibrer printmodul)',
      performPrintingCaliTitle: 'Udfør kalibrering af printmodulet',
      printingLayersCoverted: 'Printlagene er blevet konverteret til laserskærelag.',
    },
    printing: 'Printning',
    unknown: 'Unknown Module',
  },
  machine_status: {
    '-17': 'Patron IO-tilstand',
    '-10': 'Vedligeholdelsestilstand',
    '-2': 'Scanner',
    '-1': 'Vedligeholder',
    '0': 'Ledig',
    '1': 'Initierer',
    '2': 'ST_TRANSFORM',
    '4': 'Starter',
    '6': 'Genoptager',
    '16': 'Arbejder',
    '18': 'Genoptager',
    '32': 'På pause',
    '36': 'På pause',
    '38': 'Sætter på pause',
    '48': 'På pause',
    '50': 'Sætter på pause',
    '64': 'Færdig',
    '66': 'Færdiggør',
    '68': 'Forbereder',
    '128': 'Afbrudt',
    '256': 'Alarm',
    '512': 'Fatal',
    UNKNOWN: 'Ukendt',
  },
  material_test_generator: {
    block_settings: 'Blokindstillinger',
    columns: 'Kolonner',
    count: 'Antal',
    cut: 'Skær',
    engrave: 'Gravér',
    export: 'Eksportér',
    max: 'Max',
    min: 'Min',
    parameter: 'Parameter',
    preview: 'Forhåndsvisning',
    rows: 'Rækker',
    size: 'Størrelse (HxB)',
    spacing: 'Mellemrum',
    table_settings: 'Tabelindstillinger',
    text_settings: 'Tekstindstillinger',
    title: 'Materialetestgenerator',
  },
  menu: {
    inches: 'Tommer',
    mm: 'mm',
  },
  message: {
    auth_error: '#820 Godkendelsesfejl: Opdater Beam Studio og maskinfirmware til den nyeste version.',
    authenticating: 'Godkender...',
    camera: {
      abort_preview: 'Afbryd',
      camera_cable_unstable: 'Det er registreret, at kameraet overfører billeder ustabilt. Forhåndsvisning kan stadig udføres normalt, men der kan være problemer med langsom forhåndsvisning eller timeout.',
      continue_preview: 'Fortsæt',
      fail_to_transmit_image: '#845 Noget gik galt med billedtransmissionen. Genstart venligst din maskine eller Beam Studio. Hvis denne fejl fortsætter, skal du følge <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">denne vejledning</a>.',
      ws_closed_unexpectly: '#844 Forbindelsen til maskinkameraet er blevet lukket uventet. Hvis denne fejl fortsætter, skal du følge <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">denne vejledning</a>.',
    },
    cancelling: 'Annullerer...',
    cant_connect_to_device: '#827 Kan ikke oprette forbindelse til maskinen, kontroller venligst din forbindelse',
    connected: 'Forbundet',
    connecting: 'Forbinder...',
    connectingCamera: 'Forbinder kamera...',
    connectingMachine: 'Forbinder %s...',
    connectionTimeout: '#805 Enhedsforbindelse timeout. Kontroller venligst dit netværks tilstand og din maskines Wi-Fi-indikator.',
    device_blocked: {
      caption: 'Uautoriseret serienummer',
      offline: 'Dit enheds serienummer er begrænset til offline brug, da det ser ud til at være deaktiveret. Angiv venligst enhedens serienummer (placeret bag på enheden) til din forhandler og bed dem kontakte support@flux3dp.com for at aktivere online autorisation. Hvis du ønsker at bruge enheden offline, bedes du kontakte support@flux3dp.com direkte.',
      online: 'Dit enheds serienummer ser ud til at være deaktiveret. Angiv venligst enhedens serienummer (placeret bag på enheden) til din forhandler og bed dem kontakte support@flux3dp.com for at aktivere enhedens autorisation.',
    },
    device_busy: {
      caption: 'Maskinen er optaget',
      message: 'Maskinen udfører en anden opgave, prøv igen senere. Hvis den holder op med at fungere, genstart da maskinen.',
    },
    device_is_used: 'Maskinen er i brug, vil du afbryde den nuværende opgave?',
    device_not_found: {
      caption: 'Standardmaskine ikke fundet',
      message: '#812 Kontroller venligst din maskines Wi-Fi-indikator',
    },
    disconnected: 'Ustabil forbindelse, kontroller enhedsforbindelse og prøv igen senere',
    endingLineCheckMode: 'Forlader linje kontroltilstand...',
    endingRawMode: 'Forlader rå tilstand...',
    enteringLineCheckMode: 'Indtaster linjetjektilstand...',
    enteringRawMode: 'Indtaster rå tilstand...',
    enteringRedLaserMeasureMode: 'Indtastning af rødt laser-målemodus...',
    exitingRotaryMode: 'Forlader drejetilstand...',
    getProbePosition: 'Henter probe position...',
    gettingLaserSpeed: 'Læser lasers hovedhastighed...',
    homing: 'Hjemkører...',
    need_password: 'Adgangskode kræves for at oprette forbindelse til maskinen',
    please_enter_dpi: 'Angiv venligst enheden for din fil (i mm)',
    preview: {
      adjust: 'Juster',
      adjust_height_tooltip: 'Aktiver redigering ved at afkrydse.',
      already_performed_auto_focus: 'Du har allerede udført autofokus, vil du bruge eksisterende værdier?',
      auto_focus: 'Auto fokus',
      auto_focus_instruction: 'Flyt venligst lasermodulet over objektet og følg anvisningerne for at trykke på AF for at fokusere.',
      camera_preview: 'Kamera forhåndsvisning',
      enter_manually: 'Indtast manuelt',
      please_enter_height: 'Angiv venligst objektets højde. For at optage et præcist kamerabillede.',
    },
    promark_disconnected: '#850 Forbindelsen til enheden blev afbrudt, kontroller enhedens forbindelsesstatus.',
    redLaserTakingReference: 'Tager reference...',
    retrievingCameraOffset: 'Læser kamera forskydning...',
    settingLaserSpeed: 'Indstiller lasers hovedhastighed...',
    swiftray_disconnected: 'Kan ikke oprette forbindelse til backend, forsøger igen.',
    swiftray_reconnected: 'Backend er genoprettet, prøv venligst at sende jobbet igen.',
    time_remaining: 'Tid tilbage:',
    tryingToConenctMachine: 'Forsøger at oprette forbindelse til maskine...',
    turningOffAirPump: 'Slukker luftpumpe...',
    turningOffFan: 'Slukker blæser...',
    unable_to_find_machine: 'Kan ikke finde maskine ',
    unable_to_start: '#830 Kan ikke starte opgaven. Prøv igen. Hvis dette sker igen, bedes du kontakte os med fejlrapport:',
    unavailableWorkarea: '#804 Det nuværende arbejdsområde overstiger maskinens arbejdsområde. Kontroller det valgte maskines arbejdsområde eller indstil arbejdsområde fra Rediger > Dokumentindstillinger.',
    unknown_device: '#826 Kan ikke oprette forbindelse til maskinen, sørg for at USB er tilsluttet maskinen',
    unknown_error: '#821 Programmet stødte på en ukendt fejl, brug Hjælp > Menu > Fejlrapportering.',
    unsupport_osx_version: 'Den nuværende macOS-version %s understøtter muligvis ikke alle funktioner. Opdater venligst til macOS 11+.',
    unsupport_win_version: 'Den nuværende OS-version %s understøtter muligvis ikke alle funktioner. Opdater venligst til den nyeste version.',
    unsupported_example_file: 'Det valgte eksempelfil understøttes ikke af det aktuelle arbejdsområde.',
    uploading_fcode: 'Uploader FCode',
    usb_unplugged: 'USB-forbindelsen er tabt. Kontroller venligst din USB-forbindelse',
    wrong_swiftray_version_message: 'Forkert backend-version (version: {version}). Kontroller, om flere eksemplarer af Beam Studio kører, og luk eventuelle ekstra instanser, før du prøver igen.',
    wrong_swiftray_version_title: 'Forkert backend-version',
  },
  monitor: {
    ask_reconnect: 'Forbindelsen til maskinen er afbrudt. Vil du genoprette forbindelsen?',
    bug_report: 'Fejlrapport',
    camera: 'Kamera',
    cancel: 'Annuller',
    confirmFileDelete: 'Er du sikker på, at du vil slette denne fil?',
    connecting: 'Forbinder, vent venligst...',
    DEVICE_ERROR: 'Der opstod en fejl. \nGenstart venligst maskinen',
    download: 'Download',
    extensionNotSupported: 'Dette filformat understøttes ikke',
    fileExistContinue: 'Filen findes allerede. Vil du erstatte den?',
    forceStop: 'Ønsker du at afbryde den igangværende opgave?',
    go: 'Start',
    HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Luftstrømsensor unormal',
    HARDWARE_ERROR_BOTTOM_OPENED: '#903 Bund åbnet. Luk bunden for at fortsætte.',
    HARDWARE_ERROR_DOOR_OPENED: '#901 Luk døren for at fortsætte.',
    HARDWARE_ERROR_DRAWER_OPENED: '#911 Skuffen åbnet',
    HARDWARE_ERROR_FIRE_DETECTED: '#912 Flammesensor unormal',
    HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Forkert modul registreret. Installer det korrekte modul for at fortsætte.',
    HARDWARE_ERROR_HEADTYPE_NONE: '#917 Modul ikke registreret. Sørg for, at modulet er installeret korrekt for at fortsætte.',
    HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Ukendt modul registreret. Installer det korrekte modul for at fortsætte.',
    HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Hjemkørsel trækfejl',
    HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Kritisk fejl: Hovedkort offline. Kontakt venligst FLUX-support.',
    HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Overophedet. Vent venligst et par minutter.',
    HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Z-akse nulstilling mislykkedes',
    HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Ingen reaktion fra printermodulet.',
    HARDWARE_ERROR_PROBE_SHOWED: 'Træk venligst sonden tilbage.',
    HARDWARE_ERROR_PUMP_ERROR: '#900 Kontroller venligst din vandtank.',
    HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Ingen roterende modul registreret',
    HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Kritisk fejl: Intet svar fra subsystem. Kontakt venligst FLUX-support.',
    HARDWARE_FAILURE: 'Der opstod en fejl. \nGenstart venligst maskinen',
    hour: 't',
    left: 'tilbage',
    MAINBOARD_OFFLINE: '#905 Fejl ved forbindelse til hovedprintkort. \nGenstart venligst maskinen',
    minute: 'm',
    monitor: 'OVERVÅG',
    NO_RESPONSE: '#905 Fejl ved forbindelse til hovedprintkort. \nGenstart venligst maskinen',
    pause: 'Pause',
    prepareRelocate: 'Forbereder flytning',
    processing: 'Behandler',
    record: 'Optag',
    relocate: 'Flyt',
    RESOURCE_BUSY: 'Maskinen er optaget. \nGenstart den venligst hvis den ikke kører',
    resume: 'Genoptag',
    savingPreview: 'Genererer miniaturebilleder',
    second: 's',
    start: 'Start',
    stop: 'Stop',
    SUBSYSTEM_ERROR: '#402 Kritisk fejl: Intet svar fra delsystem. Kontakt venligst FLUX-support',
    task: {
      BEAMBOX: 'Lasergravering',
      'N/A': 'Fri tilstand',
    },
    taskTab: 'Opgave',
    temperature: 'Temperatur',
    upload: 'Upload',
    USER_OPERATION: 'Følg venligst instruktionerne på enhedspanelet for at fortsætte.',
    USER_OPERATION_CHANGE_CARTRIDGE: 'Indsæt venligst den korrekte patron for at fortsætte.',
    USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Indsæt venligst patronen for at fortsætte.',
    USER_OPERATION_CHANGE_TOOLHEAD: 'Installer det korrekte modul for at fortsætte.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Forkert modul registreret. Installer det korrekte modul for at fortsætte.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Modul ikke registreret. Sørg for, at modulet er installeret korrekt for at fortsætte.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Ukendt modul registreret. Installer det korrekte modul for at fortsætte.',
    USER_OPERATION_REMOVE_CARTRIDGE: 'Fjern venligst patronen for at fortsætte.',
    USER_OPERATION_ROTARY_PAUSE: 'Skift venligst til roterende motor',
  },
  my_cloud: {
    action: {
      confirmFileDelete: 'Er du sikker på, at du vil slette denne fil? Denne handling kan ikke fortrydes.',
      delete: 'Slet',
      download: 'Hent',
      duplicate: 'Dupliker',
      open: 'Åbn',
      rename: 'Omdøb',
    },
    file_limit: 'Gratis fil',
    loading_file: 'Indlæser...',
    no_file_subtitle: 'Gå til Menu > "Fil" > "Gem i skyen"',
    no_file_title: 'Gem filer til Min sky for at komme i gang.',
    save_file: {
      choose_action: 'Gem fil:',
      input_file_name: 'Indtast filnavn:',
      invalid_char: 'Ugyldige tegn:',
      save: 'Gem',
      save_new: 'Gem som ny fil',
      storage_limit_exceeded: 'Din cloud-lager har nået øvre grænse. Slet venligst eventuelle unødvendige filer, før du gemmer nye.',
    },
    sort: {
      a_to_z: 'Navn: A - Å',
      most_recent: 'Nyeste',
      oldest: 'Ældste',
      z_to_a: 'Navn: Å - A',
    },
    title: 'Min sky',
    upgrade: 'Opgrader',
  },
  noun_project_panel: {
    clear: 'Ryd',
    elements: 'Elementer',
    enjoy_shape_library: 'Nyd formdatabasen.',
    export_svg_title: 'Kan ikke eksportere SVG',
    export_svg_warning: 'Dette projekt indeholder objekter, der er beskyttet af ophavsret. Derfor vil Beam Studio automatisk udelade disse objekter under eksporten. Du kan stadig gemme dit projekt i Beam Studio Scene (.beam-format) for at beholde alle dine data. Vil du stadig eksportere?',
    learn_more: 'Lær mere',
    login_first: 'Log ind for at låse formdatabasen op.',
    recent: 'Seneste',
    search: 'Søg',
    shapes: 'Former',
  },
  pass_through: {
    export: 'Eksportér til Arbejdsområde',
    exporting: 'Eksporterer...',
    guide_mark: 'Vejledningsmærker vil blive indgraveret som et referencepunkt til justering af kunstværket.',
    guide_mark_desc: 'Vejledning linjer vil blive graveret som et referencepunkt for at justere kunstværket.',
    guide_mark_length: 'Længde:',
    guide_mark_x: 'X Koordinat:',
    height_desc: 'Indstil højden på hver sektion i arbejdsområdet.',
    help_links: {
      ado1: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
      fbb2: 'https://support.flux3dp.com/hc/en-us/articles/11570021253263',
    },
    help_text: 'Hvordan opsætter man gennemgang for %(model)s?',
    object_length: 'Objekt Længde',
    ref_layer: 'Reference Lag',
    ref_layer_desc: 'Bemærk, at udførelsen af reference-laget er indstillet til 0 som standard. Det vil ikke blive udført, men er kun til justeringsreference.',
    ref_layer_name: 'Reference',
    title: 'Gennemgangstilstand',
    workarea_height: 'Arbejdsområde (Højde):',
  },
  promark_connection_test: {
    description: 'Systemet vil køre en rødlysoperation i cirka to minutter for at kontrollere, om forbindelsen er stabil.',
    health: 'Stabilitet:',
    healthy_description: 'Stabiliteten er god, men undgå at flytte computeren markant under gravering.',
    res_0: 'Fremragende',
    res_1: 'God',
    res_2: 'Lav',
    res_3: 'For lav',
    res_4: 'Meget lav',
    restart: 'Genstart test',
    start: 'Start test',
    stop: 'Stop test',
    title: 'Forbindelsesstabilitetstest',
    unhealthy_description: 'Stabiliteten er for lav. Det anbefales at udskifte netværksadapteren eller strømkablet.',
  },
  promark_settings: {
    angle: 'Vinkel',
    bulge: 'Udbuling',
    field: 'Felt',
    galvo_configuration: 'Galvo-konfiguration',
    mark: 'Markér',
    mark_parameters: 'Mærkeparametre',
    offsetX: 'Forskydning X',
    offsetY: 'Forskydning Y',
    preview: 'Forhåndsvisning',
    red_dot: 'Rød prik',
    scale: 'Skalering',
    scaleX: 'Skalering X',
    scaleY: 'Skalering Y',
    skew: 'Skævhed',
    switchXY: 'Skift X/Y',
    title: 'Promark-indstillinger',
    trapezoid: 'Trapez',
    workarea_hint: 'Du kan ændre arbejdsområdet i "Dokumentindstillinger".',
    z_axis_adjustment: {
      section1: 'Juster Z-aksens højde for at finjustere fokus.',
      title: 'Justering af Z-Aksen',
      tooltip1: 'Prøv at markere en 1x1 cm firkant for at bekræfte, om den aktuelle fokuseringsafstand er passende.',
    },
  },
  qr_code_generator: {
    error_tolerance: 'Fejltolerance',
    error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
    invert: 'Inverter baggrundsfarve',
    placeholder: 'Indtast et link eller tekst',
    preview: 'Forhåndsvisning',
    title: 'QR-kodegenerator',
  },
  rotary_settings: {
    circumference: 'Omkreds',
    extend_workarea: 'Udvid arbejdsområdet',
    mirror: 'Spejling',
    object_diameter: 'Objekt Diameter',
    overlap_size: 'Overlapningsstørrelse',
    rotary_scale: 'Rotationsskala',
    split_setting: 'Delingsindstilling',
    split_setting_url: 'https://support.flux3dp.com/hc/en-us/articles/12276094010767',
    split_size: 'Delingsstørrelse',
    type: 'Type',
  },
  select_device: {
    auth_failure: '#811 Godkendelsesfejl',
    select_device: 'Vælg enhed',
    unable_to_connect: '#810 Kan ikke oprette en stabil forbindelse til maskinen',
  },
  settings: {
    anti_aliasing: 'Kantudjævning',
    auto_connect: 'Vælg automatisk den eneste maskine',
    auto_switch_tab: 'Automatisk skift mellem lag- og objektpanel',
    autofocus_offset: 'Autofokus Forskydning',
    autosave_enabled: 'Auto-gem',
    autosave_interval: 'Gem hver',
    autosave_number: 'Antal auto-gem',
    autosave_path: 'Auto-gem placering',
    autosave_path_not_correct: 'Angivet sti ikke fundet.',
    blade_precut_position: 'Forskæringsposition',
    blade_precut_switch: 'Blad forskæring',
    blade_radius: 'Bladradius',
    bottom_up: 'Bund-op',
    calculation_optimization: 'Stiberegningsacceleration',
    cancel: 'Annuller',
    caption: 'Indstillinger',
    check_updates: 'Automatisk kontrol',
    close: 'Luk',
    confirm_remove_default: 'Standardmaskinen vil blive fjernet.',
    confirm_reset: 'Bekræft nulstilling af Beam Studio?',
    continuous_drawing: 'Kontinuerlig tegning',
    curve_engraving_speed_limit: 'Hastighedsgrænse for kurvegravering',
    custom_preview_height: 'Brugerdefineret Forhåndsvisningshøjde',
    default_beambox_model: 'Standard dokumentindstilling',
    default_borderless_mode: 'Åbn Bund Standard',
    default_enable_autofocus_module: 'Autofokus Standard',
    default_enable_diode_module: 'Diode Laser Standard',
    default_font_family: 'Standard skrifttype',
    default_font_style: 'Standard skrifttypestil',
    default_laser_module: 'Standard lasermodul',
    default_machine: 'Standardmaskine',
    default_machine_button: 'Tom',
    default_units: 'Standardenheder',
    diode_offset: 'Diode Laser Forskydning',
    diode_one_way_engraving: 'Diode Laser Envejs Gravering',
    diode_two_way_warning: 'Tovejs lysudsendelse er hurtigere og genererer sandsynligvis nogen unøjagtighed på lasergraveringsposition. Anbefaler at teste først.',
    disabled: 'Deaktiveret',
    done: 'Anvend',
    enable_custom_backlash: 'Aktivér Brugerdefineret Backlash Kompensation',
    enable_low_speed: 'Aktivér langsom hastighed bevægelse',
    enabled: 'Aktiveret',
    engraving_direction: 'Retning',
    fast_gradient: 'Hastighedsoptimering',
    font_convert: 'Tekst til sti konverter',
    font_substitute: 'Erstat Ikke-understøttede Tegn',
    grouped_objects: 'Grupperede Objekter',
    groups: {
      ador_modules: 'Ador moduler',
      autosave: 'Autosave',
      camera: 'Kamera',
      connection: 'Forbindelse',
      editor: 'Editor',
      engraving: 'Rastering (Scanning)',
      general: 'Generelt',
      mask: 'Workarea Clipping',
      modules: 'Add-on',
      path: 'Vector (Outlines)',
      privacy: 'Privacy',
      text_to_path: 'Text',
      update: 'Softwareopdateringer',
    },
    guess_poke: 'Søg efter maskinens IP-adresse',
    guides: 'Retningslinjer',
    guides_origin: 'Retningslinjeoprindelse',
    help_center_urls: {
      anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
      calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
      connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
      continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
      default_borderless_mode: 'https://support.flux3dp.com/hc/en-us/articles/360001104076',
      default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
      default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
      fast_gradient: 'https://support.flux3dp.com/hc/en-US/articles/360004496235',
      font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
      font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
      image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
      loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
      mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
      reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
      segmented_engraving: 'https://support.flux3dp.com/hc/en-us/articles/12306366019215',
      simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
      vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
    },
    high: 'Høj',
    image_downsampling: 'Bitmap eksempelkvalitet',
    ip: 'Maskinens IP-adresse',
    keep_preview_result: 'Behold Forhåndsvisningsresultat',
    language: 'Sprog',
    loop_compensation: 'Sløjfekompensation',
    low: 'Lav',
    low_laser_for_preview: 'Laser til Kørende Ramme',
    mask: 'Arbejdsområde Beskæring',
    medium: 'Mellem',
    module_offset_2w_ir: '2W infrarød laserforskydning',
    module_offset_10w: '10W diodelaser forskydning',
    module_offset_20w: '20W diodelaser forskydning',
    module_offset_printer: 'Printer forskydning',
    none: 'Ingen',
    normal: 'Normal',
    notification_off: 'Off',
    notification_on: 'On',
    notifications: 'Skrivebordspåmindelser',
    off: 'Fra',
    on: 'Til',
    preview_movement_speed: 'Eksempel på bevægelses hastighed',
    printer_advanced_mode: 'Avanceret printer tilstand',
    remove_default_machine_button: 'Fjern',
    reset: 'Nulstil Beam Studio',
    reset_now: 'Nulstil Beam Studio',
    segmented_engraving: 'Segmenteret gravering',
    share_with_flux: 'Del Beam Studio Analytics',
    simplify_clipper_path: 'Optimer den Beregnede Sti',
    single_object: 'Enkelt Objekt',
    tabs: {
      device: 'Maskine',
      general: 'Generelt',
    },
    text_path_calc_optimization: 'Stiberegning Optimering',
    top_down: 'Top-ned',
    trace_output: 'Billede Spor Output',
    update_beta: 'Beta',
    update_latest: 'Latest',
    vector_speed_constraint: 'Hastighedsgrænse',
    wrong_ip_format: 'Forkert IP-format',
  },
  social_media: {
    facebook: 'Chat med FLUXers, stil spørgsmål og lær tips!',
    instagram: 'Få de nyeste inspirationer, tilbud og freebies!',
    youtube: 'Se Beam Studio tutorials og håndværksidéer.',
  },
  support: {
    no_vcredist: 'Installer venligst Visual C++ Redistributable 2015<br/>Det kan downloades på flux3dp.com',
    no_webgl: 'WebGL understøttes ikke. Brug venligst andre enheder.',
  },
  topbar: {
    alerts: {
      add_content_first: 'Tilføj venligst objekter først',
      door_opened: 'Luk venligst døren for at aktivere lav laser til kørsel af ramme.',
      fail_to_connect_with_camera: '#803 Kunne ikke oprette forbindelse til maskinkamera. Genstart venligst din maskine eller Beam Studio. Hvis denne fejl fortsætter, skal du følge <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">denne vejledning</a>.',
      fail_to_get_door_status: 'Sørg venligst for at døren er lukket for at aktivere lav laser til kørsel af ramme.',
      fail_to_start_preview: '#803 Kunne ikke starte forhåndsvisning. Genstart maskinen eller Beam Studio. Hvis fejlen fortsætter, se <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">denne vejledning</a>.',
      headtype_mismatch: 'Forkert modul registreret. ',
      headtype_none: 'Modul ikke registreret. ',
      headtype_unknown: 'Ukendt modul registreret. ',
      install_correct_headtype: 'Installer venligst 10W/20W diode laser moduler korrekt for at aktivere lav laser til kørsel af ramme.',
      job_origin_unavailable: 'Indstilling af joboprindelse kræver firmwareversion 4.3.5 / 5.3.5 eller nyere. Vil du opdatere firmwaren nu?',
      job_origin_warning: 'Momentálně používáte jako výchozí bod „aktuální pozici“. Ujistěte se, že je laserová hlava přesunuta do správné polohy, aby nedošlo ke kolizím.',
      power_too_high: 'FOR HØJ EFFEKT',
      power_too_high_confirm: 'NOTERET',
      power_too_high_msg: 'Brug af lavere laserstyrke (under 70%) forlænger laserens levetid.\nIndtast "NOTERET" for at fortsætte.',
      pwm_unavailable: 'Dybde-tilstand kræver firmware version 4.3.4 / 5.3.4 eller nyere. Vil du opdatere firmwaren nu?',
      QcleanScene: 'Vil du rydde tegningen?<br/>Dette vil også slette din angre-historik!',
      start_preview_timeout: '#803 Timeout opstod ved start af forhåndsvisning. Genstart maskinen eller Beam Studio. Hvis fejlen fortsætter, se <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">denne vejledning</a>.',
    },
    frame_task: 'Kørende ramme',
    hint: {
      polygon: 'Tryk på + / - tasten for at øge / mindske sider.',
    },
    menu: {
      about: 'Om Beam Studio',
      about_beam_studio: 'Om Beam Studio',
      account: 'Konto',
      add_new_machine: 'Maskinopsætning',
      align_center: 'Centrér',
      anti_aliasing: 'Anti-aliasing',
      auto_align: 'Auto Justering',
      borderless_mode: 'Rammeløs tilstand',
      bug_report: 'Fejlrapport',
      calibrate_beambox_camera: 'Kalibrer kamera',
      calibrate_beambox_camera_borderless: 'Kalibrer kamera (åben bund)',
      calibrate_camera_advanced: 'Kalibrér kamera (Avanceret)',
      calibrate_diode_module: 'Kalibrer diode lasermodul',
      calibrate_ir_module: 'Kalibrer infrarød modul',
      calibrate_printer_module: 'Kalibrer printmodul',
      calibration: 'Kalibreringer',
      camera_calibration_data: 'Kamerakalibreringsdata',
      change_logs: 'Ændringslog',
      clear_scene: 'Nye filer',
      close: 'Luk vindue',
      commands: 'Kommandoer',
      contact: 'Kontakt os',
      copy: 'Kopiér',
      cut: 'Klip',
      dashboard: 'Instrumentbræt',
      decompose_path: 'Dekomponer',
      delete: 'Slet',
      design_market: 'Designmarked',
      dev_tool: 'Fejlfindingsværktøj',
      disassemble_use: 'Afmonter',
      document_setting: 'Dokumentindstillinger',
      document_setting_short: 'Dokument',
      download_data: 'Download Data',
      download_log: 'Download logs',
      download_log_canceled: 'Logdownload annulleret',
      download_log_error: 'Der opstod en ukendt fejl, prøv igen senere',
      duplicate: 'Duplikér',
      edit: 'Rediger',
      example_files: 'Eksempelfiler',
      export_BVG: 'BVG',
      export_flux_task: 'FLUX opgave',
      export_JPG: 'JPG',
      export_PNG: 'PNG',
      export_SVG: 'SVG',
      export_to: 'Eksportér til...',
      file: 'Fil',
      fit_to_window: 'Tilpas til vinduestørrelse',
      follow_us: 'Følg os',
      forum: 'Community-forum',
      group: 'Gruppér',
      help: 'Hjælp',
      help_center: 'Hjælpecenter',
      hide: 'Skjul Beam Studio',
      hideothers: 'Skjul andre',
      image_crop: 'Beskær',
      image_curve: 'Kurve',
      image_invert: 'Inverter',
      image_sharpen: 'Skarp',
      image_stamp: 'Stempel',
      image_vectorize: 'Spor',
      import_acrylic_focus_probe: 'Akryl fokusprobe - 3mm',
      import_ador_laser_example: 'Eksempel på Ador Laser',
      import_ador_printing_example_full: 'Eksempel på Ador Print - Fuld farve',
      import_ador_printing_example_single: 'Eksempel på Ador Print - Enkelt farve',
      import_beambox_2_example: 'Eksempel på Beambox II',
      import_beambox_2_focus_probe: 'Beambox II Fokusprobe - 3 mm',
      import_hello_beambox: 'Eksempel på Beambox',
      import_hello_beamo: 'Eksempel på beamo',
      import_hexa_example: 'Eksempel på HEXA',
      import_material_printing_test: 'Materiale print test',
      import_material_testing_cut: 'Materiale skæretest',
      import_material_testing_engrave: 'Materiale graveringstest',
      import_material_testing_line: 'Materiale linjetest',
      import_material_testing_old: 'Materiale graveringstest - Klassisk',
      import_material_testing_simple_cut: 'Materiale skæretest - Enkel',
      import_promark_example: 'Eksempel på Promark',
      import_promark_mopa_20w_color: 'MOPA 20W Farvetest',
      import_promark_mopa_60w_color: 'MOPA 60W Farvetest',
      import_promark_mopa_100w_color: 'MOPA 100W Farvetest',
      keyboard_shortcuts: 'Tastaturgenveje',
      layer_color_config: 'Farveindstillinger',
      layer_setting: 'Lag',
      link: {
        beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
        contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
        design_market: 'https://dmkt.io',
        downloads: 'https://flux3dp.com/downloads/',
        forum: 'https://www.facebook.com/groups/flux.laser/',
        help_center: 'https://helpcenter.flux3dp.com/',
        shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
      },
      log: {
        camera: 'Kamera',
        cloud: 'Sky',
        discover: 'Opdag',
        hardware: 'Hardware',
        network: 'Netværk',
        player: 'Afspiller',
        robot: 'Robot',
        usb: 'USB',
        usblist: 'USB-liste',
      },
      machine_info: 'Maskininfo',
      machines: 'Maskiner',
      manage_account: 'Administrer min konto',
      material_test: 'Materialetest',
      minimize: 'Minimer',
      my_account: 'Min konto',
      network_testing: 'Test netværksindstillinger',
      object: 'Objekt',
      offset: 'Forskyd',
      open: 'Åbn',
      paste: 'Indsæt',
      paste_in_place: 'Indsæt på plads',
      path: 'Sti',
      photo_edit: 'Billede',
      preferences: 'Indstillinger',
      promark_color_test: 'Promark Farvetest',
      questionnaire: 'Feedbackskema',
      quit: 'Afslut',
      recent: 'Åbn Seneste',
      redo: 'Gendan',
      reload_app: 'Genindlæs App',
      reset: 'Nulstil',
      rotary_setup: 'Roterende Opsætning',
      rotate: 'Rotér',
      samples: 'Eksempler',
      save_as: 'Gem som...',
      save_scene: 'Gem',
      save_to_cloud: 'Gem i skyen',
      scale: 'Skalér',
      service: 'Tjenester',
      set_as_default: 'Sæt som standard',
      show_gesture_tutorial: 'Introduktion til håndbevægelser',
      show_grids: 'Vis gitter',
      show_layer_color: 'Brug lagfarve',
      show_rulers: 'Vis linealer',
      show_start_tutorial: 'Vis startvejledning',
      show_ui_intro: 'Vis grænsefladeintroduktion',
      sign_in: 'Log ind',
      sign_out: 'Log ud',
      software_update: 'Softwareopdatering',
      svg_edit: 'SVG',
      switch_to_beta: 'Skift til Beta-udgivelse',
      switch_to_latest: 'Skift til Stabil udgivelse',
      tools: {
        box_generator: 'Boksgenerator',
        code_generator: 'Kodegenerator',
        material_test_generator: 'Materialetestgenerator',
        title: 'Værktøjer',
      },
      tutorial: 'Start vejledning til Delta Family Printing',
      undo: 'Fortryd',
      ungroup: 'Opdel gruppe',
      update: 'Tjek Opdatering',
      update_firmware: 'Opdater firmware',
      upload_data: 'Upload Data',
      using_beam_studio_api: 'Brug Beam Studio API',
      view: 'Vis',
      window: 'Vindue',
      zoom_in: 'Zoom ind',
      zoom_out: 'Zoom ud',
      zoom_with_window: 'Auto tilpas til vinduestørrelse',
    },
    preview: 'FORHÅNDSVISNING',
    preview_press_esc_to_stop: 'Tryk på ESC for at stoppe kameraforhåndsvisningen.',
    rename_tab: 'Omdøb fane',
    select_machine: 'Vælg en maskine',
    tag_names: {
      dxf: 'DXF-objekt',
      ellipse: 'Oval',
      g: 'Gruppe',
      image: 'Billede',
      line: 'Linje',
      multi_select: 'Flere objekter',
      no_selection: 'Ingen valg',
      pass_through_object: 'Passér Gennem Objekt',
      path: 'Sti',
      polygon: 'Polygon',
      rect: 'Rektangel',
      svg: 'SVG-objekt',
      text: 'Tekst',
      text_path: 'Tekst på sti',
      use: 'Importeret objekt',
    },
    task_preview: 'Opgaverammeforhåndsvisning',
    titles: {
      settings: 'Indstillinger',
    },
    untitled: 'Untitled',
  },
  topmenu: {
    credit: 'Beam Studio er muliggjort af <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> open source projektet og anden <a target="_blank" href="https://flux3dp.com/credits/">open source software</a>.',
    device: {
      download_log_canceled: 'Log download annulleret',
      download_log_error: 'Der opstod en ukendt fejl, prøv igen senere',
      log: {
        usblist: 'USB Liste',
      },
      network_test: 'Test Netværk',
    },
    file: {
      all_files: 'Alle filer',
      clear_recent: 'Ryd Senest Åbnet',
      converting: 'Konverterer til billede...',
      fcode_files: 'FLUX Code',
      import: 'Importer',
      jpg_files: 'JPG',
      label: 'Fil',
      path_not_exit: 'Denne sti findes ikke længere på disken.',
      png_files: 'PNG',
      save_fcode: 'Eksporter FLUX opgave',
      save_jpg: 'Eksporter JPG',
      save_png: 'Eksporter PNG',
      save_scene: 'Gem scene',
      save_svg: 'Eksporter SVG',
      scene_files: 'Beam Studio Scene',
      svg_files: 'SVG',
    },
    ok: 'OK',
    version: 'Version',
  },
  tutorial: {
    ask_retry_calibration: 'Vil du kalibrere kameraet igen?',
    camera_calibration_failed: 'Kamera Kalibrering Mislykkedes',
    gesture: {
      click: 'Tryk for at vælge objektet.',
      drag: 'Træk for at vælge flere objekter.',
      hold: 'Tryk og hold for at åbne genvejsmenuen.',
      pan: 'Rul lærredet med to fingre.',
      zoom: 'Knib med to fingre for at zoome ind/ud på lærredet.',
    },
    links: {
      adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
    },
    look_for_machine: 'Søger maskine til vejledning...',
    needNewInterfaceTutorial: 'Vil du starte en tutorial for Beam Studios nye interface?<br/>(Du kan springe det over nu og starte senere ved at klikke på "Hjælp" > "Vis Interface Introduktion".)',
    needNewUserTutorial: 'Vil du starte en tutorial?<br/>(Du kan springe det over nu og starte senere ved at klikke på "Hjælp" > "Vis Start Tutorial".)',
    newInterface: {
      add_new_layer: 'Tilføj nyt lag',
      align_controls: 'Justering af kontrol',
      basic_shapes: 'Grundlæggende former',
      camera_preview: 'Kameraforhåndsvisning',
      drag_to_sort: 'Træk for at sortere',
      end_alert: 'Er du sikker på, du vil afslutte introduktionen til det nye brugerinterface?',
      flip: 'Vend',
      group_controls: 'Gruppekontrol',
      layer_controls: 'Højreklik for at vælge lagkontrol: Dupliker/Flet/Lås/Slet lag',
      object_actions: 'Objekthandlinger',
      pen_tool: 'Penneværktøj',
      rename_by_double_click: 'Omdøb ved dobbeltklik',
      select_image_text: 'Vælg billede/tekst',
      select_machine: 'Vælg en maskine',
      shape_operation: 'Formhåndtering',
      start_work: 'Start arbejde',
      switch_between_layer_panel_and_object_panel: 'Skift mellem lagpanel og objektpanel',
    },
    newUser: {
      add_new_layer: 'Tilføj et nyt lag',
      adjust_focus: '2. Juster fokus',
      close_cover: '3. Luk låget',
      drag_to_draw: 'Træk for at tegne',
      draw_a_circle: 'Tegn en cirkel',
      draw_a_rect: 'Tegn et rektangel',
      end_alert: 'Er du sikker på, du vil afslutte vejledningen?',
      end_preview_mode: 'Afslut forhåndsvisningstilstand',
      infill: 'Aktiver udfyldning',
      please_select_wood_cutting: 'Vælg venligst Træ - Skæring som forudindstilling.',
      please_select_wood_engraving: 'Vælg venligst Træ - Gravering som forudindstilling.',
      preview_the_platform: 'Forhåndsvis platformen',
      put_wood: '1. Anbring prøvetræet',
      send_the_file: 'Send filen',
      set_preset_wood_cut: 'Sæt forudindstilling: Træ - Skæring',
      set_preset_wood_engraving: 'Sæt forudindstilling: Træ - Gravering',
      switch_to_layer_panel: 'Skift til lag panel',
      switch_to_object_panel: 'Skift til objektpanel',
      switch_to_preview_mode: 'Skift til forhåndsvisningstilstand',
    },
    next: 'NÆSTE',
    retry: 'Prøv igen',
    set_connection: 'Sæt forbindelse',
    skip: 'Spring over',
    skip_tutorial: 'Du har sprunget vejledningen over. Du kan altid starte vejledningen ved at klikke på "Hjælp" > "Vis start vejledning"',
    suggest_calibrate_camera_first: 'Vi anbefaler brugere at kalibrere kameraet først og fokusere igen før hver forhåndsvisning for optimale resultater.<br/>Vil du bekræfte at udføre kalibreringen nu?<br/>(Du kan springe det over nu og gøre det senere ved at klikke på "Menu" > "Maskine" > [Din Maskine] > "Kalibrer Kamera".)',
    tutorial_complete: 'Det var alt for selvstudiet. Nu er det tid til at gå i gang!',
    unable_to_find_machine: 'Kunne ikke finde maskine til vejledning. Vil du gå til forbindelsesindstillings siden, prøve igen eller springe vejledningen over?',
    welcome: 'VELKOMMEN',
  },
  update: {
    cannot_reach_internet: '#823 Server er utilgængelig<br/>Tjek venligst internetforbindelsen',
    download: 'ONLINE OPDATERING',
    firmware: {
      caption: 'En firmwareopdatering til maskinen er tilgængelig',
      confirm: 'UPLOAD',
      firmware_too_old_update_by_sdcard: 'Firmwareversionen er for gammel. Opdater venligst firmwaren ved hjælp af et SD-kort.',
      force_update_message: '#814 Opdater venligst din maskine til den nyeste firmwareversion.',
      latest_firmware: {
        cant_get_latest: 'Kunne ikke hente information om den nyeste firmware.',
        caption: 'Maskin firmwareopdatering',
        message: 'Du har den nyeste maskin firmware',
        still_update: 'OPDATER',
      },
      message_pattern_1: "'%s' er nu klar til firmwareopdatering.",
      message_pattern_2: '%s Firmware v%s er nu tilgængelig - Du har v%s.',
      too_old_for_web: 'Din nuværende maskin firmwareversion er v%s.\nHvis du vil bruge den online version af Beam Studio skal du opdatere maskin firmwaren til den nyeste version.',
      update_fail: '#822 Opdatering mislykkedes',
      update_success: 'Firmwareopdateringen er uploadet',
      upload_file: 'Firmware upload (*.bin / *.fxfw)',
    },
    install: 'INSTALLER',
    later: 'SENERE',
    preparing: 'Forbereder...',
    release_note: 'Frigivelsesnote:',
    skip: 'Spring denne version over',
    software: {
      available_switch: 'Beam Studio v%s er tilgængelig nu. Du har v%s. Vil du skifte til denne version?',
      available_update: 'Beam Studio v%s er tilgængelig nu. Du har v%s. Vil du downloade opdateringen?',
      caption: 'En softwareopdatering til Beam Studio er tilgængelig',
      check_update: 'Søg efter opdateringer',
      checking: 'Søger efter opdateringer',
      downloading: 'Downloader opdateringer i baggrunden, du kan klikke "OK" for at fortsætte dit arbejde.',
      install_or_not: 'er klar til opdatering. Vil du genstarte og opdatere nu?',
      no: 'Nej',
      no_response: 'Kunne ikke oprette forbindelse til serveren, tjek venligst dine netværksindstillinger.',
      not_found: 'Du bruger den nyeste version af Beam Studio.',
      switch_or_not: 'er klar til skift. Vil du genstarte og skifte nu?',
      switch_version: 'Skift version',
      switch_version_not_found: 'Skiftbar version ikke fundet.',
      update_for_ador: 'Den nuværende softwareversion %s er ikke kompatibel, download venligst den nyeste version af Beam Studio til Ador.',
      yes: 'Ja',
    },
    update: 'Opdater',
    updating: 'Opdaterer...',
    upload: 'UPLOAD',
  },
  web_cam: {
    no_device: 'Kan ikke registrere kameranhed. Tilslut kameraet igen og prøv igen.',
    no_permission: 'Beam Studio har ikke tilladelse til at få adgang til kameraet. Sørg for, at tilladelsen er givet til Beam Studio i browserindstillingerne eller systemindstillingerne.',
  },
  z_speed_limit_test: {
    alert_before: 'Før kurvegravering starter, vil systemet udføre en belastningstest for at forhindre tab af trin på grund af for høj vægt.',
    alert_failed: 'Den aktuelle genstands vægt overstiger belastningsgrænsen for den nuværende Z-akse hastighed. Reducer venligst Z-akse hastigheden på maskinens panel eller udskift graveringsobjektet, før du tester igen.',
    ignore: 'Ignorer',
    retest: 'Test igen',
    testing: 'Udfører belastningstest...',
  },
};

export default lang;
