import isWeb from '@core/helpers/is-web';
import type { WebFont } from '@core/interfaces/IFont';

const fonts: WebFont[] = [
  // Passengers Script
  {
    family: 'passengers script',
    fileName: 'PassengersScript.ttf',
    italic: false,
    postscriptName: 'PassengersScript-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Carlito
  {
    family: 'carlito',
    fileName: 'Carlito-Regular.ttf',
    italic: false,
    postscriptName: 'Carlito-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  {
    family: 'Carlito',
    fileName: 'Carlito-Italic.ttf',
    italic: true,
    postscriptName: 'Carlito-Italic',
    style: 'Italic',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  {
    family: 'Carlito',
    fileName: 'Carlito-Bold.ttf',
    italic: false,
    postscriptName: 'Carlito-Bold',
    style: 'Bold',
    supportLangs: ['en', 'es', 'de'],
    weight: 700,
  },
  {
    family: 'Carlito',
    fileName: 'Carlito-BoldItalic.ttf',
    italic: true,
    postscriptName: 'Carlito-BoldItalic',
    style: 'Bold Italic',
    supportLangs: ['en', 'es', 'de'],
    weight: 700,
  },
  // Arrow
  {
    family: 'arrow',
    fileName: 'Almo_Andrea_FontlabARROW.ttf',
    italic: false,
    postscriptName: 'ArrowSerif',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Chanticleer Roman NF
  {
    family: 'ChanticleerRomanNF',
    fileName: 'ChanticleerRomanNF.ttf',
    italic: false,
    postscriptName: 'ChanticleerRomanNF-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  {
    family: 'ChanticleerRomanNF',
    fileName: 'ChanticleerRomanNF-Bold.ttf',
    italic: false,
    postscriptName: 'ChanticleerRomanNF-Bold',
    style: 'Bold',
    supportLangs: ['en', 'es', 'de'],
    weight: 700,
  },
  // Alegreya
  {
    family: 'Alegreya',
    fileName: 'Alegreya-Regular.otf',
    italic: false,
    postscriptName: 'Alegreya-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  {
    family: 'Alegreya',
    fileName: 'Alegreya-Italic.otf',
    italic: true,
    postscriptName: 'Alegreya-Italic',
    style: 'Italic',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  {
    family: 'Alegreya',
    fileName: 'Alegreya-Bold.otf',
    italic: false,
    postscriptName: 'Alegreya-Bold',
    style: 'Bold',
    supportLangs: ['en', 'es', 'de'],
    weight: 700,
  },
  {
    family: 'Alegreya',
    fileName: 'Alegreya-BoldItalic.otf',
    italic: true,
    postscriptName: 'Alegreya-BoldItalic',
    style: 'Bold Italic',
    supportLangs: ['en', 'es', 'de'],
    weight: 700,
  },
  // AlegreyaSC
  {
    family: 'Alegreya SC',
    fileName: 'AlegreyaSC-Regular.otf',
    italic: false,
    postscriptName: 'AlegreyaSC-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  {
    family: 'Alegreya SC',
    fileName: 'AlegreyaSC-Italic.otf',
    italic: true,
    postscriptName: 'AlegreyaSC-Italic',
    style: 'Italic',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  {
    family: 'Alegreya SC',
    fileName: 'AlegreyaSC-Bold.otf',
    italic: false,
    postscriptName: 'AlegreyaSC-Bold',
    style: 'Bold',
    supportLangs: ['en', 'es', 'de'],
    weight: 700,
  },
  {
    family: 'Alegreya SC',
    fileName: 'AlegreyaSC-BoldItalic.otf',
    italic: true,
    postscriptName: 'AlegreyaSC-BoldItalic',
    style: 'Bold Italic',
    supportLangs: ['en', 'es', 'de'],
    weight: 700,
  },
  // Amethysta
  {
    family: 'amethysta',
    fileName: 'Amethysta-Regular.ttf',
    italic: false,
    postscriptName: 'Amethysta-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Old Standard TT
  {
    family: 'old standard TT',
    fileName: 'OldStandard-Regular.ttf',
    italic: false,
    postscriptName: 'OldStandard-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  {
    family: 'old standard TT',
    fileName: 'OldStandard-Italic.ttf',
    italic: true,
    postscriptName: 'OldStandard-Italic',
    style: 'Italic',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  {
    family: 'old standard TT',
    fileName: 'OldStandard-Bold.ttf',
    italic: false,
    postscriptName: 'OldStandard-Bold',
    style: 'Bold',
    supportLangs: ['en', 'es', 'de'],
    weight: 700,
  },
  // Lavigne
  {
    family: 'lavigne',
    fileName: 'Lavigne.ttf',
    italic: false,
    postscriptName: 'Lavigne-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // FatCow
  {
    family: 'fatcow',
    fileName: 'FatCow.ttf',
    italic: false,
    postscriptName: 'FatCow-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  {
    family: 'fatcow',
    fileName: 'FatCow-Italic.ttf',
    italic: true,
    postscriptName: 'FatCow-Italic',
    style: 'Italic',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Modern Sans
  {
    family: 'Modern Sans',
    fileName: 'ModernSans-Light.otf',
    italic: false,
    postscriptName: 'Modern Sans-Light',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Phantomonia
  {
    family: 'phantomonia',
    fileName: 'Phantomonia.ttf',
    italic: false,
    postscriptName: 'Phantomonia-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Kong Quest
  {
    family: 'kong quest',
    fileName: 'KongQuest-Regular.otf',
    italic: false,
    postscriptName: 'Kong Quest-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Plexifont BV
  {
    family: 'plexifont bv',
    fileName: 'plexifont.ttf',
    italic: false,
    postscriptName: 'Plexifont BV-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Oldies Cartoon
  {
    family: 'Oldies Cartoon',
    fileName: 'oldies cartoon.ttf',
    italic: false,
    postscriptName: 'Oldies Cartoon-Medium',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Don Graffiti
  {
    family: 'Don Graffiti',
    fileName: 'DonGraffiti.otf',
    italic: false,
    postscriptName: 'Don Graffiti-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Eddie
  {
    family: 'Eddie',
    fileName: 'eddie.ttf',
    italic: false,
    postscriptName: 'Eddie-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Shagadelic
  {
    family: 'shagadelic',
    fileName: 'shagade.ttf',
    italic: false,
    postscriptName: 'Shagadelic-Bold',
    style: 'Bold',
    supportLangs: ['en', 'es', 'de'],
    weight: 700,
  },
  // Fanzine
  {
    family: 'fanzine',
    fileName: 'fanzine.ttf',
    italic: false,
    postscriptName: 'Fanzine-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Impact Label
  {
    family: 'ImpactLabel',
    fileName: 'Impact_Label.ttf',
    italic: false,
    postscriptName: 'Impact Label-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Impact Label Reversed
  {
    family: 'Impact Label Reversed',
    fileName: 'Impact_Label_Reversed.ttf',
    italic: false,
    postscriptName: 'Impact Label Reversed-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Mr Bedfort
  {
    family: 'Mr Bedfort',
    fileName: 'MrBedfort-Regular.ttf',
    italic: false,
    postscriptName: 'Mr Bedfort-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Allura
  {
    family: 'allura',
    fileName: 'Allura-Regular.otf',
    italic: false,
    postscriptName: 'Allura-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Cedarville-Cursive
  {
    family: 'CedarvilleCursive',
    fileName: 'Cedarville-Cursive.ttf',
    italic: false,
    postscriptName: 'CedarvilleCursive-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Robert
  {
    family: 'robert',
    fileName: 'Robert.ttf',
    italic: false,
    postscriptName: 'Robert-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Rhesmanisa
  {
    family: 'Rhesmanisa',
    fileName: 'Rhesmanisa.otf',
    italic: false,
    postscriptName: 'Rhesmanisa-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Flanella
  {
    family: 'Flanella',
    fileName: 'Flanella.ttf',
    italic: false,
    postscriptName: 'Flanella-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Radicalis
  {
    family: 'Radicalis',
    fileName: 'Radicalis.ttf',
    italic: false,
    postscriptName: 'Radicalis-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Raustila
  {
    family: 'raustila',
    fileName: 'raustila-Regular.ttf',
    italic: false,
    postscriptName: 'Raustila-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Lemon Tuesday
  {
    family: 'lemon tuesday',
    fileName: 'LemonTuesday.otf',
    italic: false,
    postscriptName: 'LemonTuesday-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Nickainley
  {
    family: 'nickainley',
    fileName: 'Nickainley-Normal.ttf',
    italic: false,
    postscriptName: 'Nickainley-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Volaroid Sans
  {
    family: 'volaroid Sans',
    fileName: 'Volaroid-sans.otf',
    italic: false,
    postscriptName: 'Volaroid Sans-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Volaroid Script
  {
    family: 'VolaroidScript',
    fileName: 'Volaroid-Script.ttf',
    italic: false,
    postscriptName: 'Volaroid Script-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Grenadier NF
  {
    family: 'Grenadier NF',
    fileName: 'GrenadierNF.ttf',
    italic: false,
    postscriptName: 'Grenadier NF-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Airstream NF
  {
    family: 'AirstreamNF',
    fileName: 'AirstreamNF.ttf',
    italic: false,
    postscriptName: 'Airstream NF-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Life Savers
  {
    family: 'life savers',
    fileName: 'LifeSavers-Regular.ttf',
    italic: false,
    postscriptName: 'LifeSavers-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  {
    family: 'life savers',
    fileName: 'LifeSavers-Bold.ttf',
    italic: false,
    postscriptName: 'LifeSavers-Bold',
    style: 'Bold',
    supportLangs: ['en', 'es', 'de'],
    weight: 700,
  },
  // Dymaxion Script
  {
    family: 'dymaxionscript',
    fileName: 'DymaxionScript.ttf',
    italic: false,
    postscriptName: 'DymaxionScript-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Riesling
  {
    family: 'Riesling',
    fileName: 'riesling.ttf',
    italic: false,
    postscriptName: 'Riesling-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Carnivalee Freakshow
  {
    family: 'Carnivalee Freakshow',
    fileName: 'CarnevaleeFreakshow.ttf',
    italic: false,
    postscriptName: 'CarnivaleeFreakshow-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // Park Lane NF
  {
    family: 'Park Lane NF',
    fileName: 'ParkLaneNF.ttf',
    italic: false,
    postscriptName: 'Park Lane NF-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // True Crimes
  {
    family: 'True Crimes',
    fileName: 'true-crimes.ttf',
    italic: false,
    postscriptName: 'True Crimes-Regular',
    style: 'Regular',
    supportLangs: ['en', 'es', 'de'],
    weight: 400,
  },
  // 楷書
  {
    family: 'TW-MOE-Std-Kai',
    fileName: 'edukai-4.0.ttf',
    italic: false,
    postscriptName: 'TW-MOE-Std-Kai',
    style: 'Regular',
    supportLangs: ['zh-tw'],
    weight: 400,
  },
  // GenYoGothic TW
  {
    family: 'GenYoGothic TW',
    fileName: 'GenYoGothic-R.ttc',
    italic: false,
    postscriptName: 'GenYoGothicTW-R',
    style: 'Regular',
    supportLangs: ['zh-tw'],
    weight: 400,
  },
  {
    family: 'GenYoGothic TW',
    fileName: 'GenYoGothic-B.ttc',
    italic: false,
    postscriptName: 'GenYoGothicTW-B',
    style: 'Bold',
    supportLangs: ['zh-tw'],
    weight: 700,
  },
  // GenYoGothic JP
  {
    family: 'GenYoGothic JP',
    fileName: 'GenYoGothic-R.ttc',
    italic: false,
    postscriptName: 'GenYoGothicJP-R',
    style: 'Regular',
    supportLangs: ['ja'],
    weight: 400,
  },
  {
    family: 'GenYoGothic JP',
    fileName: 'GenYoGothic-B.ttc',
    italic: false,
    postscriptName: 'GenYoGothicJP-B',
    style: 'Bold',
    supportLangs: ['ja'],
    weight: 700,
  },
  // GenRyuMin TW
  {
    family: 'GenRyuMin TW',
    fileName: 'GenRyuMin-R.ttc',
    italic: false,
    postscriptName: 'GenRyuMinTW-R',
    style: 'Regular',
    supportLangs: ['zh-tw'],
    weight: 400,
  },
  {
    family: 'GenRyuMin TW',
    fileName: 'GenRyuMin-B.ttc',
    italic: false,
    postscriptName: 'GenRyuMinTW-B',
    style: 'Bold',
    supportLangs: ['zh-tw'],
    weight: 700,
  },
  // GenRyuMin JP
  {
    family: 'GenRyuMin JP',
    fileName: 'GenRyuMin-R.ttc',
    italic: false,
    postscriptName: 'GenRyuMinJP-R',
    style: 'Regular',
    supportLangs: ['ja'],
    weight: 400,
  },
  {
    family: 'GenRyuMin JP',
    fileName: 'GenRyuMin-B.ttc',
    italic: false,
    postscriptName: 'GenRyuMinJP-B',
    style: 'Bold',
    supportLangs: ['ja'],
    weight: 700,
  },
  // GenWanMin TW
  {
    family: 'GenWanMin TW',
    fileName: 'GenWanMin-R.ttc',
    italic: false,
    postscriptName: 'GenWanMinTW-R',
    style: 'Regular',
    supportLangs: ['zh-tw'],
    weight: 400,
  },
  {
    family: 'GenWanMin TW',
    fileName: 'GenWanMin-SB.ttc',
    italic: false,
    postscriptName: 'GenWanMinTW-SB',
    style: 'Bold',
    supportLangs: ['zh-tw'],
    weight: 700,
  },
  // GenWanMin JP
  {
    family: 'GenWanMin JP',
    fileName: 'GenWanMin-R.ttc',
    italic: false,
    postscriptName: 'GenWanMinJP-R',
    style: 'Regular',
    supportLangs: ['ja'],
    weight: 400,
  },
  {
    family: 'GenWanMin JP',
    fileName: 'GenWanMin-SB.ttc',
    italic: false,
    postscriptName: 'GenWanMinJP-SB',
    style: 'Bold',
    supportLangs: ['ja'],
    weight: 700,
  },
  // GenSekiGothic TW
  {
    family: 'GenSekiGothic TW',
    fileName: 'GenSekiGothic-R.ttc',
    italic: false,
    postscriptName: 'GenSekiGothicTW-R',
    style: 'Regular',
    supportLangs: ['zh-tw'],
    weight: 400,
  },
  {
    family: 'GenSekiGothic TW',
    fileName: 'GenSekiGothic-B.ttc',
    italic: false,
    postscriptName: 'GenSekiGothicTW-B',
    style: 'Bold',
    supportLangs: ['zh-tw'],
    weight: 700,
  },
  // GenSekiGothic JP
  {
    family: 'GenSekiGothic JP',
    fileName: 'GenSekiGothic-R.ttc',
    italic: false,
    postscriptName: 'GenSekiGothicJP-R',
    style: 'Regular',
    supportLangs: ['ja'],
    weight: 400,
  },
  {
    family: 'GenSekiGothic JP',
    fileName: 'GenSekiGothic-B.ttc',
    italic: false,
    postscriptName: 'GenSekiGothicJP-B',
    style: 'Bold',
    supportLangs: ['ja'],
    weight: 700,
  },
  // GenSenRounded TW
  {
    collectionIdx: 1,
    family: 'GenSenRounded TW',
    fileName: 'GenSenRounded-R.ttc',
    italic: false,
    postscriptName: 'GenSenRoundedTW-R',
    style: 'Regular',
    supportLangs: ['zh-tw'],
    weight: 400,
  },
  {
    family: 'GenSenRounded TW',
    fileName: 'GenSenRounded-B.ttc',
    italic: false,
    postscriptName: 'GenSenRoundedTW-B',
    style: 'Bold',
    supportLangs: ['zh-tw'],
    weight: 700,
  },
  // GenSenRounded JP
  {
    collectionIdx: 0,
    family: 'GenSenRounded JP',
    fileName: 'GenSenRounded-R.ttc',
    italic: false,
    postscriptName: 'GenSenRoundedJP-R',
    style: 'Regular',
    supportLangs: ['ja'],
    weight: 400,
  },
  {
    family: 'GenSenRounded JP',
    fileName: 'GenSenRounded-B.ttc',
    italic: false,
    postscriptName: 'GenSenRoundedJP-B',
    style: 'Bold',
    supportLangs: ['ja'],
    weight: 700,
  },
  // Gen Shin Gothic
  {
    family: 'Gen Shin Gothic',
    fileName: 'GenShinGothic-Regular.ttf',
    italic: false,
    postscriptName: 'Gen Shin Gothic',
    style: 'Regular',
    supportLangs: ['ja', 'zh-tw'],
    weight: 400,
  },
  {
    family: 'Gen Shin Gothic',
    fileName: 'GenShinGothic-Bold.ttf',
    italic: false,
    postscriptName: 'Gen Shin Gothic Bold',
    style: 'Bold',
    supportLangs: ['ja', 'zh-tw'],
    weight: 700,
  },
  // Gen Shin Gothic P
  {
    family: 'Gen Shin Gothic P',
    fileName: 'GenShinGothic-P-Regular.ttf',
    italic: false,
    postscriptName: 'Gen Shin Gothic P',
    style: 'Regular',
    supportLangs: ['ja', 'zh-tw'],
    weight: 400,
  },
  {
    family: 'Gen Shin Gothic P',
    fileName: 'GenShinGothic-P-Bold.ttf',
    italic: false,
    postscriptName: 'Gen Shin Gothic P Bold',
    style: 'Bold',
    supportLangs: ['ja', 'zh-tw'],
    weight: 700,
  },
  // Gen Shin Gothic Monospace
  {
    family: 'Gen Shin Gothic Monospace',
    fileName: 'GenShinGothic-Monospace-Regular.ttf',
    italic: false,
    postscriptName: 'Gen Shin Gothic Monospace',
    style: 'Regular',
    supportLangs: ['ja', 'zh-tw'],
    weight: 400,
  },
  {
    family: 'Gen Shin Gothic Monospace',
    fileName: 'GenShinGothic-Monospace-Bold.ttf',
    italic: false,
    postscriptName: 'Gen Shin Gothic Monospace Bold',
    style: 'Bold',
    supportLangs: ['ja', 'zh-tw'],
    weight: 700,
  },
  // TaipeiSansTC
  {
    family: 'Taipei Sans TC Beta',
    fileName: 'TaipeiSansTCBeta-Regular.ttf',
    italic: false,
    postscriptName: 'TaipeiSansTC-Regular',
    style: 'Regular',
    supportLangs: ['zh-tw'],
    weight: 400,
  },
  {
    family: 'Taipei Sans TC Beta',
    fileName: 'TaipeiSansTCBeta-Bold.ttf',
    italic: false,
    postscriptName: 'TaipeiSansTC-Bold',
    style: 'Bold',
    supportLangs: ['zh-tw'],
    weight: 700,
  },
  // GenEi Gothic M
  {
    family: 'GenEi Gothic M',
    fileName: 'GenEiGothicM-Regular.ttf',
    italic: false,
    postscriptName: 'GenEi Gothic M',
    style: 'Regular',
    supportLangs: ['ja', 'zh-tw'],
    weight: 400,
  },
  {
    family: 'GenEi Gothic M',
    fileName: 'GenEiGothicM-Bold.ttf',
    italic: false,
    postscriptName: 'GenEi Gothic M Bold',
    style: 'Bold',
    supportLangs: ['ja', 'zh-tw'],
    weight: 700,
  },
  // Chiron Sans HK Pro
  {
    family: 'Chiron Sans HK Pro',
    fileName: 'ChironSansHKPro-Regular.otf',
    italic: false,
    postscriptName: 'Chiron Sans HK Pro',
    style: 'Regular',
    supportLangs: ['zh-tw'],
    weight: 400,
  },
  {
    family: 'Chiron Sans HK Pro',
    fileName: 'ChironSansHKPro-Bold.otf',
    italic: false,
    postscriptName: 'Chiron Sans HK Pro-Bold',
    style: 'Bold',
    supportLangs: ['zh-tw'],
    weight: 700,
  },
  {
    family: 'Chiron Sans HK Pro',
    fileName: 'ChironSansHKPro-Italic.otf',
    italic: true,
    postscriptName: 'Chiron Sans HK Pro-Italic',
    style: 'Italic',
    supportLangs: ['zh-tw'],
    weight: 400,
  },
  {
    family: 'Chiron Sans HK Pro',
    fileName: 'ChironSansHKPro-BoldIt.otf',
    italic: true,
    postscriptName: 'Chiron Sans HK Pro-BoldIt',
    style: 'Bold Italic',
    supportLangs: ['zh-tw'],
    weight: 700,
  },
  // Huayuan Gothic
  {
    family: 'Huayuan Gothic',
    fileName: 'HuayuanGothic-Regular.ttf',
    italic: false,
    postscriptName: 'Huayuan Gothic',
    style: 'Regular',
    supportLangs: ['zh-tw'],
    weight: 400,
  },
  {
    family: 'Huayuan Gothic',
    fileName: 'HuayuanGothic-Bold.ttf',
    italic: false,
    postscriptName: 'Huayuan Gothic Bold',
    style: 'Bold',
    supportLangs: ['zh-tw'],
    weight: 700,
  },
  // GlowSans TC
  {
    family: 'Glow Sans TC',
    fileName: 'GlowSansTC-Normal-Regular.otf',
    fontkitError: true,
    italic: false,
    postscriptName: 'GlowSans TC',
    style: 'Regular',
    supportLangs: ['zh-tw'],
    weight: 400,
  },
  {
    family: 'Glow Sans TC',
    fileName: 'GlowSansTC-Normal-Bold.otf',
    fontkitError: true,
    italic: false,
    postscriptName: 'Glow Sans TC',
    style: 'Bold',
    supportLangs: ['zh-tw'],
    weight: 700,
  },
  // GlowSans SC
  {
    family: 'GlowSans SC',
    fileName: 'GlowSansSC-Normal-Regular.otf',
    fontkitError: true,
    italic: false,
    postscriptName: 'GlowSans SC',
    style: 'Regular',
    supportLangs: ['zh-cn'],
    weight: 400,
  },
  {
    family: 'Glow Sans SC',
    fileName: 'GlowSansSC-Normal-Bold.otf',
    fontkitError: true,
    italic: false,
    postscriptName: 'Glow Sans SC',
    style: 'Bold',
    supportLangs: ['zh-cn'],
    weight: 700,
  },
  // Glow Sans J
  {
    family: 'Glow Sans J',
    fileName: 'GlowSansJ-Normal-Regular.otf',
    fontkitError: true,
    italic: false,
    postscriptName: 'GlowSans J',
    style: 'Regular',
    supportLangs: ['ja'],
    weight: 400,
  },
  {
    family: 'Glow Sans J',
    fileName: 'GlowSansJ-Normal-Bold.otf',
    fontkitError: true,
    italic: false,
    postscriptName: 'Glow Sans J',
    style: 'Bold',
    supportLangs: ['ja'],
    weight: 700,
  },
  // 07TetsubinGothic
  {
    family: '07TetsubinGothic',
    fileName: '07TetsubinGothic.otf',
    italic: false,
    postscriptName: '07TetsubinGothic',
    style: 'Regular',
    supportLangs: ['ja', 'zh-tw'],
    weight: 400,
  },
  // M PLUS 1
  {
    family: 'M PLUS 1',
    fileName: 'Mplus1-Regular.ttf',
    italic: false,
    postscriptName: 'M PLUS 1',
    style: 'Regular',
    supportLangs: ['ja', 'zh-tw'],
    weight: 400,
  },
  {
    family: 'M PLUS 1',
    fileName: 'Mplus1-Bold.ttf',
    italic: false,
    postscriptName: 'M PLUS 1 Bold',
    style: 'Bold',
    supportLangs: ['ja', 'zh-tw'],
    weight: 700,
  },
  // M PLUS 2
  {
    family: 'M PLUS 2',
    fileName: 'Mplus2-Regular.ttf',
    italic: false,
    postscriptName: 'M PLUS 2',
    style: 'Regular',
    supportLangs: ['ja', 'zh-tw'],
    weight: 400,
  },
  {
    family: 'M PLUS 2',
    fileName: 'Mplus2-Bold.ttf',
    italic: false,
    postscriptName: 'M PLUS 2 Bold',
    style: 'Bold',
    supportLangs: ['ja', 'zh-tw'],
    weight: 700,
  },
  // M PLUS 1 Code
  {
    family: 'M PLUS 1 Code',
    fileName: 'Mplus1Code-Regular.ttf',
    italic: false,
    postscriptName: 'M PLUS 1 Code',
    style: 'Regular',
    supportLangs: ['ja', 'zh-tw'],
    weight: 400,
  },
  {
    family: 'M PLUS 1 Code',
    fileName: 'Mplus1Code-Bold.ttf',
    italic: false,
    postscriptName: 'M PLUS 1 Code Bold',
    style: 'Bold',
    supportLangs: ['ja', 'zh-tw'],
    weight: 700,
  },
  // M PLUS Code Latin
  {
    family: 'M PLUS Code Latin',
    fileName: 'MplusCodeLatin50-Regular.ttf',
    italic: false,
    postscriptName: 'M PLUS Code Latin',
    style: 'Regular',
    supportLangs: ['ja', 'zh-tw'],
    weight: 400,
  },
  {
    family: 'M PLUS Code Latin',
    fileName: 'MplusCodeLatin50-Bold.ttf',
    italic: false,
    postscriptName: 'M PLUS Code Latin Bold',
    style: 'Bold',
    supportLangs: ['ja', 'zh-tw'],
    weight: 700,
  },
  // Togalite
  {
    family: 'Togalite',
    fileName: 'Togalite-Regular.otf',
    italic: false,
    postscriptName: 'Togalite',
    style: 'Regular',
    supportLangs: ['ja', 'zh-tw'],
    weight: 400,
  },
  {
    family: 'Togalite',
    fileName: 'Togalite-Bold.otf',
    italic: false,
    postscriptName: 'Togalite Bold',
    style: 'Bold',
    supportLangs: ['ja', 'zh-tw'],
    weight: 700,
  },
  // Senobi Gothic
  {
    family: 'Senobi Gothic',
    fileName: 'Senobi-Gothic-Regular.ttf',
    italic: false,
    postscriptName: 'Senobi Gothic',
    style: 'Regular',
    supportLangs: ['ja', 'zh-tw'],
    weight: 400,
  },
  {
    family: 'Senobi Gothic',
    fileName: 'Senobi-Gothic-Bold.ttf',
    italic: false,
    postscriptName: 'Senobi Gothic Bold',
    style: 'Bold',
    supportLangs: ['ja', 'zh-tw'],
    weight: 700,
  },
  // Genkaimincho
  {
    family: 'Genkaimincho',
    fileName: 'genkai-mincho.ttf',
    italic: false,
    postscriptName: 'Genkaimincho',
    style: 'Regular',
    supportLangs: ['ja', 'zh-tw'],
    weight: 400,
  },
  // SoukouMincho
  {
    family: 'SoukouMincho',
    fileName: 'SoukouMincho.ttf',
    italic: false,
    postscriptName: 'SoukouMincho',
    style: 'Regular',
    supportLangs: ['ja', 'zh-tw'],
    weight: 400,
  },
  // Isego
  {
    family: 'Isego',
    fileName: 'Isego.otf',
    italic: false,
    postscriptName: 'Isego',
    style: 'Regular',
    supportLangs: ['ja', 'zh-tw'],
    weight: 400,
  },
  // Isemin
  {
    family: 'isemin',
    fileName: 'Isemin.otf',
    italic: false,
    postscriptName: 'Isemin',
    style: 'Regular',
    supportLangs: ['ja', 'zh-tw'],
    weight: 400,
  },
  // HanaMinPlus
  {
    family: 'HanaMinPlus',
    fileName: 'HanaMinPlus.ttf',
    italic: false,
    postscriptName: 'HanaMinPlus',
    style: 'Regular',
    supportLangs: ['ja', 'zh-tw'],
    weight: 400,
  },
  // IPAexMincho
  {
    family: 'IPAexMincho',
    fileName: 'ipaexm.ttf',
    italic: false,
    postscriptName: 'IPAexMincho',
    style: 'Regular',
    supportLangs: ['ja', 'zh-tw'],
    weight: 400,
  },
  // I.Ming
  {
    family: 'I.Ming',
    fileName: 'I.Ming-7.01.ttf',
    italic: false,
    postscriptName: 'I.Ming',
    style: 'Regular',
    supportLangs: ['ja', 'zh-tw', 'zh-cn'],
    weight: 400,
  },
  // Koku Mincho Regular
  {
    family: 'Koku Mincho Regular',
    fileName: 'font_1_kokumr_1.00_rls.ttf',
    italic: false,
    postscriptName: 'Koku Mincho Regular',
    style: 'Regular',
    supportLangs: ['ja', 'zh-tw'],
    weight: 400,
  },
  // Oradano-mincho-GSRR
  {
    family: 'Oradano-mincho-GSRR',
    fileName: 'OradanoGSRR.ttf',
    italic: false,
    postscriptName: 'Oradano-mincho-GSRR',
    style: 'Regular',
    supportLangs: ['ja', 'zh-tw'],
    weight: 400,
  },
  // Huiwen-mincho
  {
    family: 'Huiwen-mincho',
    fileName: 'Huiwen-mincho.otf',
    italic: false,
    postscriptName: 'Huiwen-mincho',
    style: 'Regular',
    supportLangs: ['ja', 'zh-tw', 'zh-cn'],
    weight: 400,
  },
  // Swei Gothic CJK TC
  {
    family: 'Swei Gothic CJK TC',
    fileName: 'SweiGothicCJKtc-Regular.ttf',
    italic: false,
    postscriptName: 'Swei Gothic CJK TC',
    style: 'Regular',
    supportLangs: ['zh-tw'],
    weight: 400,
  },
  {
    family: 'Swei Gothic CJK TC',
    fileName: 'SweiGothicCJKtc-Bold.ttf',
    italic: false,
    postscriptName: 'Swei Gothic CJK TC Bold',
    style: 'Bold',
    supportLangs: ['zh-tw'],
    weight: 700,
  },
  // Swei Gothic CJK JP
  {
    family: 'Swei Gothic CJK JP',
    fileName: 'SweiGothicCJKjp-Regular.ttf',
    italic: false,
    postscriptName: 'Swei Gothic CJK JP',
    style: 'Regular',
    supportLangs: ['ja'],
    weight: 400,
  },
  {
    family: 'Swei Gothic CJK JP',
    fileName: 'SweiGothicCJKjp-Bold.ttf',
    italic: false,
    postscriptName: 'Swei Gothic CJK JP Bold',
    style: 'Bold',
    supportLangs: ['ja'],
    weight: 700,
  },
  // Swei Gothic CJK SC
  {
    family: 'Swei Gothic CJK SC',
    fileName: 'SweiGothicCJKsc-Regular.ttf',
    italic: false,
    postscriptName: 'Swei Gothic CJK SC',
    style: 'Regular',
    supportLangs: ['zh-cn'],
    weight: 400,
  },
  {
    family: 'Swei Gothic CJK SC',
    fileName: 'SweiGothicCJKsc-Bold.ttf',
    italic: false,
    postscriptName: 'Swei Gothic CJK SC Bold',
    style: 'Bold',
    supportLangs: ['zh-cn'],
    weight: 700,
  },
  // Gen Jyuu Gothic
  {
    family: 'Gen Jyuu Gothic',
    fileName: 'GenJyuuGothic-Regular.ttf',
    italic: false,
    postscriptName: 'Gen Jyuu Gothic',
    style: 'Regular',
    supportLangs: ['ja', 'zh-tw'],
    weight: 400,
  },
  {
    family: 'Gen Jyuu Gothic',
    fileName: 'GenJyuuGothic-Bold.ttf',
    italic: false,
    postscriptName: 'Gen Jyuu Gothic Bold',
    style: 'Bold',
    supportLangs: ['ja', 'zh-tw'],
    weight: 700,
  },
  // Corporate Logo Rounded ver2
  {
    family: 'Corporate Logo Rounded ver2',
    fileName: 'Corporate-Logo-Rounded.ttf',
    italic: false,
    postscriptName: 'Corporate Logo Rounded ver2',
    style: 'Bold',
    supportLangs: ['ja', 'zh-tw'],
    weight: 700,
  },
  // jf-openhuninn-1.1
  {
    family: 'jf-openhuninn-1.1',
    fileName: 'jf-openhuninn-1.1.ttf',
    italic: false,
    postscriptName: 'jf-openhuninn-1.1',
    style: 'Regular',
    supportLangs: ['zh-tw'],
    weight: 400,
  },
  // irohamaru
  {
    family: 'irohamaru',
    fileName: 'irohamaru-Regular.ttf',
    italic: false,
    postscriptName: 'irohamaru',
    style: 'Regular',
    supportLangs: ['zh-tw'],
    weight: 400,
  },
  {
    family: 'irohamaru',
    fileName: 'irohamaru-Medium.ttf',
    italic: false,
    postscriptName: 'irohamaru Bold',
    style: 'Bold',
    supportLangs: ['zh-tw', 'ja'],
    weight: 700,
  },
  // timemachine wa
  {
    family: 'timemachine wa',
    fileName: 'timemachine-wa.ttf',
    italic: false,
    postscriptName: 'timemachine wa',
    style: 'Regular',
    supportLangs: ['zh-tw', 'ja'],
    weight: 400,
  },
  // SetoFont
  {
    family: 'SetoFont',
    fileName: 'setofont.ttf',
    italic: false,
    postscriptName: 'SetoFont',
    style: 'Regular',
    supportLangs: ['zh-tw', 'ja'],
    weight: 400,
  },
  // 內海字體
  {
    family: 'NaikaiFont',
    fileName: 'NaikaiFont-Regular.ttf',
    italic: false,
    postscriptName: '內海字體',
    style: 'Regular',
    supportLangs: ['zh-tw'],
    weight: 400,
  },
  {
    family: 'NaikaiFont',
    fileName: 'NaikaiFont-Bold.ttf',
    italic: false,
    postscriptName: '內海字體 Bold',
    style: 'Bold',
    supportLangs: ['zh-tw'],
    weight: 700,
  },
  // 內海字體 JP
  {
    family: '內海字體JP',
    fileName: 'NaikaiFontJP-Regular.ttf',
    italic: false,
    postscriptName: '內海字體JP',
    style: 'Regular',
    supportLangs: ['ja'],
    weight: 400,
  },
  {
    family: '內海字體JP',
    fileName: 'NaikaiFontJP-Bold.ttf',
    italic: false,
    postscriptName: '內海字體JP Bold',
    style: 'Bold',
    supportLangs: ['ja'],
    weight: 700,
  },
  // Mamelon
  {
    family: 'mamelon',
    fileName: 'Mamelon-4-Hi-Regular.otf',
    italic: false,
    postscriptName: 'Mamelon',
    style: 'Regular',
    supportLangs: ['zh-tw', 'ja'],
    weight: 400,
  },
  // Ronde-B
  {
    family: 'ronde-B',
    fileName: 'Ronde-B_square.otf',
    italic: false,
    postscriptName: 'Ronde-B',
    style: 'Regular',
    supportLangs: ['zh-tw', 'ja'],
    weight: 400,
  },
  // Nagurigaki Crayon
  {
    family: 'Nagurigaki Crayon',
    fileName: 'crayon_1-1.ttf',
    italic: false,
    postscriptName: 'Nagurigaki Crayon',
    style: 'Regular',
    supportLangs: ['zh-tw', 'ja'],
    weight: 400,
  },
  // Tanuki Permanent Marker
  {
    family: 'Tanuki Permanent Marker',
    fileName: 'TanukiMagic.ttf',
    italic: false,
    postscriptName: 'Tanuki Permanent Marker',
    style: 'Regular',
    supportLangs: ['zh-tw', 'ja'],
    weight: 400,
  },
  // AsobiMemogaki
  {
    family: 'AsobiMemogaki',
    fileName: 'AsobiMemogaki-Regular-1-01.ttf',
    italic: false,
    postscriptName: 'AsobiMemogaki',
    style: 'Regular',
    supportLangs: ['zh-tw', 'ja'],
    weight: 400,
  },
  // Mukasi Mukasi
  {
    family: 'mukasi mukasi',
    fileName: 'gomarice_mukasi_mukasi.ttf',
    italic: false,
    postscriptName: 'Mukasi Mukasi',
    style: 'Regular',
    supportLangs: ['zh-tw', 'ja'],
    weight: 400,
  },
  // ShigotoMemogaki
  {
    family: 'ShigotoMemogaki',
    fileName: 'ShigotoMemogaki-Regular-1-01.ttf',
    italic: false,
    postscriptName: 'ShigotoMemogaki',
    style: 'Regular',
    supportLangs: ['zh-tw', 'ja'],
    weight: 400,
  },
  // DartsFont
  {
    family: 'DartsFont',
    fileName: 'DartsFont.ttf',
    italic: false,
    postscriptName: 'DartsFont',
    style: 'Regular',
    supportLangs: ['zh-tw', 'ja'],
    weight: 400,
  },
  // Mushin
  {
    family: 'Mushin',
    fileName: 'mushin.otf',
    italic: false,
    postscriptName: 'Mushin',
    style: 'Regular',
    supportLangs: ['zh-tw', 'ja'],
    weight: 400,
  },
  // おつとめフォント
  {
    family: 'おつとめフォント',
    fileName: 'OtsutomeFont_Ver3.ttf',
    italic: false,
    postscriptName: 'おつとめフォント',
    style: 'Bold',
    supportLangs: ['zh-tw', 'ja'],
    weight: 700,
  },
  // 851MkPOP
  {
    family: '851MkPOP',
    fileName: '851MkPOP_100.ttf',
    italic: false,
    postscriptName: '851MkPOP',
    style: 'Regular',
    supportLangs: ['zh-tw', 'ja'],
    weight: 400,
  },
  // 001Shirokuma
  {
    family: '001Shirokuma',
    fileName: '001Shirokuma-Regular.otf',
    italic: false,
    postscriptName: '001Shirokuma',
    style: 'Regular',
    supportLangs: ['zh-tw', 'ja'],
    weight: 400,
  },
  // KAWAIITEGAKIMOJI
  {
    family: 'kawaiitegakimoji',
    fileName: 'KTEGAKI.ttf',
    italic: false,
    postscriptName: 'KAWAIITEGAKIMOJI',
    style: 'Regular',
    supportLangs: ['zh-tw', 'ja'],
    weight: 400,
  },
  // 851CHIKARA-DZUYOKU-KANA-B
  {
    family: '851CHIKARA-DZUYOKU-KANA-B',
    fileName: '851CHIKARA-DZUYOKU_kanaB_004.ttf',
    italic: false,
    postscriptName: '851CHIKARA-DZUYOKU-KANA-B',
    style: 'Regular',
    supportLangs: ['zh-tw', 'ja'],
    weight: 400,
  },
  // Aoyagireisyosimo2
  {
    family: 'Aoyagireisyosimo2',
    fileName: 'aoyagireisyosimo_ttf_2_01.ttf',
    italic: false,
    postscriptName: 'aoyagireisyosimo2',
    style: 'Regular',
    supportLangs: ['zh-tw', 'ja'],
    weight: 400,
  },
  // AoyagiKouzanFontT
  {
    family: 'AoyagiKouzanFontT',
    fileName: '青柳衡山フォントT.ttf',
    italic: false,
    postscriptName: 'AoyagiKouzanFontT',
    style: 'Regular',
    supportLangs: ['zh-tw', 'ja'],
    weight: 400,
  },
  // Bakudai
  {
    family: 'Bakudai',
    fileName: 'Bakudai-Regular.ttf',
    italic: false,
    postscriptName: 'Bakudai',
    style: 'Regular',
    supportLangs: ['zh-tw', 'ja'],
    weight: 400,
  },
  {
    family: 'Bakudai',
    fileName: 'Bakudai-Bold.ttf',
    italic: false,
    postscriptName: 'Bakudai Bold',
    style: 'Bold',
    supportLangs: ['zh-tw', 'ja'],
    weight: 700,
  },
  // Klee One
  {
    family: 'Klee One',
    fileName: 'KleeOne-Regular.ttf',
    italic: false,
    postscriptName: 'Klee One',
    style: 'Regular',
    supportLangs: ['zh-tw', 'ja'],
    weight: 400,
  },
  {
    family: 'Klee One',
    fileName: 'KleeOne-SemiBold.ttf',
    italic: false,
    postscriptName: 'Klee One Bold',
    style: 'Bold',
    supportLangs: ['zh-tw', 'ja'],
    weight: 700,
  },
  // Kaisotai
  {
    family: 'Kaisotai',
    fileName: 'Kaisotai-Next-UP-B.ttf',
    italic: false,
    postscriptName: 'Kaisotai',
    style: 'Regular',
    supportLangs: ['zh-tw', 'ja'],
    weight: 400,
  },
  // Makinas
  {
    family: 'makinas',
    fileName: 'Makinas-4-Flat.otf',
    italic: false,
    postscriptName: 'Makinas',
    style: 'Regular',
    supportLangs: ['zh-tw', 'ja'],
    weight: 400,
  },
  // 851Gkktt
  {
    family: '851Gkktt',
    fileName: '851Gkktt_005.ttf',
    italic: false,
    postscriptName: '851Gkktt',
    style: 'Regular',
    supportLangs: ['zh-tw', 'ja'],
    weight: 400,
  },
  // Chogokuboso Gothic
  {
    family: 'Chogokuboso Gothic',
    fileName: 'chogokubosogothic_5.ttf',
    italic: false,
    postscriptName: 'Chogokuboso Gothic',
    style: 'Regular',
    supportLangs: ['zh-tw', 'ja'],
    weight: 400,
  },
  // BoutiqueBitmap7x7
  {
    family: 'BoutiqueBitmap7x7',
    fileName: 'BoutiqueBitmap7x7.ttf',
    italic: false,
    postscriptName: 'BoutiqueBitmap7x7',
    style: 'Regular',
    supportLangs: ['zh-tw', 'ja'],
    weight: 400,
  },
];

const applyStyle = (fontsInUse: WebFont[]): void => {
  const style = document.createElement('style');
  let styleText = '';

  for (let i = 0; i < fontsInUse.length; i += 1) {
    const font = fontsInUse[i];

    styleText += `
    @font-face {
      font-family: '${font.family}';
      font-style: ${font.italic ? 'italic' : 'normal'};
      font-weight: ${font.weight};
      font-display: swap;
      src : url('https://beam-studio-web.s3.ap-northeast-1.amazonaws.com/fonts/${font.fileName}');
    }`;
  }

  const head = document.querySelector('head');

  style.innerHTML = styleText;
  head?.appendChild(style);
};

const getAvailableFonts = (lang?: string): WebFont[] => {
  let availableFonts = fonts;

  if (lang) {
    availableFonts = availableFonts.filter((font) => {
      if (!font.supportLangs) {
        return true;
      }

      return font.supportLangs.includes(lang);
    });
  }

  if (!isWeb()) {
    availableFonts = availableFonts.filter((font) => !font.fontkitError);
  }

  return availableFonts;
};

export default {
  applyStyle,
  getAvailableFonts,
};
