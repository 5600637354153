// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-SaveConfigButton-module__container--K2eX1{height:100%;padding:0;background:none;border:none;cursor:pointer;display:inline-flex;align-items:center}._-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-SaveConfigButton-module__container--K2eX1._-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-SaveConfigButton-module__disabled--axKtz{opacity:.3;cursor:default}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/views/beambox/Right-Panels/ConfigPanel/SaveConfigButton.module.scss"],"names":[],"mappings":"AAAA,gHACE,WAAA,CACA,SAAA,CACA,eAAA,CACA,WAAA,CACA,cAAA,CACA,mBAAA,CACA,kBAAA,CAEA,8NACE,UAAA,CACA,cAAA","sourcesContent":[".container {\n  height: 100%;\n  padding: 0;\n  background: none;\n  border: none;\n  cursor: pointer;\n  display: inline-flex;\n  align-items: center;\n\n  &.disabled {\n    opacity: 0.3;\n    cursor: default;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-SaveConfigButton-module__container--K2eX1",
	"disabled": "_-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-SaveConfigButton-module__disabled--axKtz"
};
export default ___CSS_LOADER_EXPORT___;
