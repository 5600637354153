// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-dialogs-camera-common-Title-module__title--AFtQz{display:inline-flex;align-items:center}._-_-packages-core-src-web-app-components-dialogs-camera-common-Title-module__title--AFtQz ._-_-packages-core-src-web-app-components-dialogs-camera-common-Title-module__icon---iCtl{margin-left:10px;font-size:14px;cursor:pointer}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/dialogs/camera/common/Title.module.scss"],"names":[],"mappings":"AAAA,2FACE,mBAAA,CACA,kBAAA,CAEA,qLACE,gBAAA,CACA,cAAA,CACA,cAAA","sourcesContent":[".title {\n  display: inline-flex;\n  align-items: center;\n\n  .icon {\n    margin-left: 10px;\n    font-size: 14px;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "_-_-packages-core-src-web-app-components-dialogs-camera-common-Title-module__title--AFtQz",
	"icon": "_-_-packages-core-src-web-app-components-dialogs-camera-common-Title-module__icon---iCtl"
};
export default ___CSS_LOADER_EXPORT___;
