// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-beambox-DpiInfo-module__container--1RRfO{position:fixed;bottom:20px;left:175px;height:20px;font-size:16px;z-index:1;color:#838383}._-_-packages-core-src-web-app-components-beambox-DpiInfo-module__container--1RRfO._-_-packages-core-src-web-app-components-beambox-DpiInfo-module__mobile--iDZFQ{left:25px;bottom:110px}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/beambox/DpiInfo.module.scss"],"names":[],"mappings":"AAAA,mFACE,cAAA,CACA,WAAA,CACA,UAAA,CACA,WAAA,CACA,cAAA,CACA,SAAA,CACA,aAAA,CAEA,kKACE,SAAA,CACA,YAAA","sourcesContent":[".container {\n  position: fixed;\n  bottom: 20px;\n  left: 175px;\n  height: 20px;\n  font-size: 16px;\n  z-index: 1;\n  color: #838383;\n\n  &.mobile {\n    left: 25px;\n    bottom: 110px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_-_-packages-core-src-web-app-components-beambox-DpiInfo-module__container--1RRfO",
	"mobile": "_-_-packages-core-src-web-app-components-beambox-DpiInfo-module__mobile--iDZFQ"
};
export default ___CSS_LOADER_EXPORT___;
