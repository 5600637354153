// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-AdvancedPrintingBlock-index-module__container--1o6mO{padding:10px 0 !important}._-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-AdvancedPrintingBlock-index-module__container--1o6mO .ant-collapse-header-text{font-weight:bold;font-size:10pt}._-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-AdvancedPrintingBlock-index-module__container--1o6mO ._-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-AdvancedPrintingBlock-index-module__panel--u7eX5{padding:0;margin-top:20px;display:flex;flex-direction:column;gap:20px;font-size:10pt}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/views/beambox/Right-Panels/ConfigPanel/AdvancedPrintingBlock/index.module.scss"],"names":[],"mappings":"AACA,2HACE,yBAAA,CAEA,qJACE,gBAAA,CACA,cAAA,CAGF,kPACE,SAAA,CACA,eAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,cAAA","sourcesContent":["// Copied from AdvancedBlock.module.scss\n.container {\n  padding: 10px 0 !important;\n\n  :global(.ant-collapse-header-text) {\n    font-weight: bold;\n    font-size: 10pt;\n  }\n\n  .panel {\n    padding: 0;\n    margin-top: 20px;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    font-size: 10pt;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-AdvancedPrintingBlock-index-module__container--1o6mO",
	"panel": "_-_-packages-core-src-web-app-views-beambox-Right-Panels-ConfigPanel-AdvancedPrintingBlock-index-module__panel--u7eX5"
};
export default ___CSS_LOADER_EXPORT___;
