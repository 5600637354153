import type { ILang } from '@core/interfaces/ILang';

const lang: ILang = {
  alert: {
    abort: 'Hủy bỏ',
    cancel: 'Hủy bỏ',
    caption: 'Lỗi',
    close: 'Đóng',
    confirm: 'Xác nhận',
    dont_save: 'Không lưu',
    dont_show_again: 'Không hiển thị lại',
    duplicated_preset_name: 'Tên tiền định đã tồn tại',
    error: 'Ối...',
    info: 'THÔNG TIN',
    instruction: 'Hướng dẫn',
    learn_more: 'Tìm hiểu thêm',
    no: 'Không',
    ok: 'OK',
    ok2: 'OK',
    oops: 'Ối...',
    retry: 'Thử lại',
    save: 'Lưu',
    stop: 'Dừng',
    warning: 'CẢNH BÁO',
    yes: 'Có',
  },
  auto_fit: {
    artwork_size: 'Kích thước tác phẩm',
    error_tip1: '1. Tác phẩm nghệ thuật có được đặt đúng vị trí trên vật liệu không?',
    error_tip2: '2. Các đường viền của vật liệu có đủ rõ để nhận diện không?',
    failed_to_find_contour: 'Không tìm thấy đường viền nào để tự động căn chỉnh',
    learn_more: 'Xem hướng dẫn.',
    learn_more_url: 'https://support.flux3dp.com/hc/en-us/articles/10273384373775',
    offset_x: 'Độ lệch X',
    offset_y: 'Độ lệch Y',
    pattern_size: 'Kích thước mẫu',
    position_artwork: 'Định vị tác phẩm nghệ thuật',
    position_step1: 'Điều chỉnh kích thước và vị trí của tác phẩm nghệ thuật trên mẫu.',
    position_step2: 'Nhấn "Áp dụng" để áp dụng Tự động căn chỉnh lên mẫu này.',
    preview_first: 'Vui lòng thực hiện xem trước máy ảnh trước.',
    reset_position: 'Đặt lại vị trí',
    rotation: 'Xoay',
    select_a_pattern: 'Chọn mẫu',
    selected_artwork: 'Tác phẩm nghệ thuật đã chọn',
    step1: 'Chọn một mẫu nơi bạn muốn đặt tác phẩm nghệ thuật.',
    step2: 'Nếu không tìm thấy mẫu nào, vui lòng xem lại bản xem trước camera và áp dụng Tự động căn chỉnh.',
    step3: 'Nếu vẫn không tìm được mẫu chính xác, hãy đảm bảo rằng vật liệu của bạn có thể nhận dạng rõ ràng và khu vực làm việc sạch sẽ, không có mảnh vụn.',
    title: 'Tự Động Vừa Khít',
  },
  barcode_generator: {
    bar_height: 'Chiều cao thanh',
    bar_width: 'Chiều rộng thanh',
    barcode: {
      invalid_value: 'Giá trị không hợp lệ cho định dạng đã chọn.',
    },
    font: 'Phông chữ',
    font_size: 'Kích thước phông chữ',
    hide_text: 'Ẩn văn bản',
    invert_color: 'Đảo màu',
    text_margin: 'Lề văn bản',
  },
  beambox: {
    announcement_panel: {
      title: 'Thông báo',
    },
    banner: {
      auto_feeder: 'Chế Độ Nạp Liệu Tự Động',
      camera_preview: 'Xem Trước',
      camera_preview_borderless_mode: '(Mở Dưới)',
      curve_engraving: 'Chế Độ Khắc Đường Cong',
      pass_through: 'Chế Độ Xuyên Qua',
      rotary: 'Chế Độ Quay',
    },
    bottom_right_panel: {
      convert_text_to_path_before_export: 'Đang chuyển đổi chữ thành đường dẫn...',
      export_file_error_ask_for_upload: 'Xuất tệp thất bại. Bạn có muốn cung cấp cảnh làm việc để báo cáo lỗi không?',
      retreive_image_data: 'Đang truy xuất dữ liệu hình ảnh...',
    },
    context_menu: {
      copy: 'Sao chép',
      cut: 'Cắt',
      delete: 'Xóa',
      duplicate: 'Nhân bản',
      group: 'Nhóm',
      move_back: 'Đẩy xuống cuối cùng',
      move_down: 'Đẩy xuống phía sau',
      move_front: 'Đưa lên trước nhất',
      move_up: 'Đưa lên phía trước',
      paste: 'Dán',
      paste_in_place: 'Dán tại vị trí',
      ungroup: 'Bỏ nhóm',
    },
    document_panel: {
      add_on: 'Phụ kiện',
      auto_feeder: 'Bộ nạp tự động',
      auto_feeder_scale: 'Tỷ lệ Bộ nạp Tự động',
      auto_feeder_url: 'https://support.flux3dp.com/hc/en-us/articles/11688230498575',
      borderless_mode: 'Mở đáy',
      current_position: 'Vị Trí Hiện Tại',
      disable: 'Tắt',
      document_settings: 'Cài đặt tài liệu',
      enable: 'Bật',
      enable_autofocus: 'Tự động lấy nét',
      enable_diode: 'Laser điốt',
      engrave_dpi: 'Độ phân giải',
      frame_before_start: 'Khung trước khi thực thi',
      frame_before_start_url: 'https://support.flux3dp.com/hc/en-us/articles/11494925637135',
      high: 'Cao',
      job_origin: 'Gốc Công Việc',
      laser_source: 'Nguồn laser',
      low: 'Thấp',
      machine: 'Máy',
      medium: 'Trung bình',
      notification: {
        changeFromPrintingWorkareaTitle: 'Bạn có muốn chuyển các lớp in sang lớp laser không?',
      },
      origin: 'Gốc',
      pass_through: 'Truyền qua',
      pass_through_height_desc: 'Nhập chiều dài của đối tượng để mở rộng khu vực làm việc.',
      rotary_mode: 'Chế độ xoay',
      scale: 'Tỷ lệ',
      start_from: 'Bắt Đầu Từ',
      start_position: 'Vị Trí Bắt Đầu',
      start_work_button: 'Nút Thực Hiện',
      ultra: 'Rất cao',
      workarea: 'Khu vực làm việc',
    },
    image_trace_panel: {
      back: 'Quay lại',
      brightness: 'Độ sáng',
      cancel: 'Hủy',
      contrast: 'Độ tương phản',
      next: 'Tiếp',
      okay: 'Đồng ý',
      threshold: 'Ngưỡng',
      tuning: 'Thiết lập',
    },
    layer_color_config_panel: {
      add: 'Thêm',
      add_config: 'Thêm màu',
      color: 'Màu sắc',
      default: 'Đặt lại mặc định',
      in_use: 'Màu này đang được sử dụng',
      layer_color_config: 'Cấu hình màu theo lớp',
      no_input: 'Vui lòng nhập mã màu hợp lệ',
      power: 'Công suất',
      repeat: 'Lặp lại',
      speed: 'Tốc độ',
      sure_to_delete: 'Bạn có chắc chắn muốn xóa cài đặt màu này?',
      sure_to_reset: 'Bạn sẽ mất tất cả các thiết lập tùy chỉnh, bạn có chắc chắn muốn đặt lại về mặc định?',
    },
    left_panel: {
      borderless_blind_area: 'Vùng không khắc',
      borderless_preview: 'Xem trước Chế độ Mở đáy Máy ảnh',
      diode_blind_area: 'Vùng mù Phụ kiện Laser',
      ellipse: 'Hình elip',
      image: 'Hình ảnh',
      label: {
        adjust_height: 'Điều chỉnh chiều cao',
        array: 'Mảng',
        boxgen: 'Boxgen',
        choose_camera: 'Camera',
        clear_preview: 'Xóa xem trước',
        cursor: 'Chọn',
        curve_engraving: {
          clear_area: 'Xóa khu vực đã chọn',
          exit: 'Thoát',
          preview_3d_curve: 'Xem trước khắc đường cong',
          select_area: 'Chọn khu vực',
          title: 'Khắc đường cong',
        },
        end_preview: 'Kết thúc xem trước',
        line: 'Đường thẳng',
        live_feed: 'Phát trực tiếp',
        my_cloud: 'Đám mây của tôi',
        oval: 'Hình bầu dục',
        pass_through: 'Truyền qua',
        pen: 'Bút',
        photo: 'Hình ảnh',
        polygon: 'Đa giác',
        preview: 'Xem trước Máy ảnh',
        qr_code: 'Mã QR',
        rect: 'Hình chữ nhật',
        shapes: 'Các phần tử',
        text: 'Văn bản',
        trace: 'Truy tìm Hình ảnh',
      },
      line: 'Đường thẳng',
      rectangle: 'Hình chữ nhật',
      text: 'Văn bản',
      unpreviewable_area: 'Vùng mù',
    },
    network_testing_panel: {
      average_response: 'Thời gian phản hồi trung bình',
      cannot_connect_1: '#840 Không thể kết nối với địa chỉ IP đích.',
      cannot_connect_2: '#840 Không thể kết nối với địa chỉ IP đích. Hãy chắc chắn rằng địa chỉ đích cùng mạng.',
      cannot_get_local: 'Không lấy được địa chỉ IP cục bộ',
      connection_quality: 'Chất lượng kết nối',
      device_not_on_list: '#842 Máy không có trong danh sách, nhưng chất lượng kết nối >70 và thời gian phản hồi trung bình <100ms',
      empty_ip: '#818 Vui lòng nhập địa chỉ IP thiết bị đích trước',
      end: 'Kết thúc',
      fail_to_start_network_test: '#817 Không thể bắt đầu kiểm tra mạng',
      hint_connect_camera_timeout: 'Có lỗi kết nối khi gửi công việc?',
      hint_connect_failed_when_sending_job: 'Không thể kết nối khi gửi công việc?',
      hint_device_often_on_list: 'Máy thường không có trong danh sách?',
      insert_ip: 'Địa chỉ IP thiết bị đích:',
      invalid_ip: '#818 Địa chỉ IP không hợp lệ',
      ip_startswith_169: '#843 Địa chỉ IP máy bắt đầu bằng 169.254',
      link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
      link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
      link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
      linux_permission_hint: 'Lỗi này thường do quyền truy cập không đủ. Hãy chạy "sudo beam-studio --no-sandbox" trong Terminal để có quyền và kiểm tra mạng',
      local_ip: 'Địa chỉ IP cục bộ:',
      network_testing: 'Kiểm tra mạng',
      network_unhealthy: '#841 Chất lượng kết nối <70 hoặc thời gian phản hồi trung bình >100ms',
      start: 'Bắt đầu',
      test_completed: 'Kiểm tra hoàn tất',
      test_fail: 'Kiểm tra thất bại',
      testing: 'Đang kiểm tra mạng...',
    },
    object_panels: {
      lock_desc: 'Giữ nguyên tỷ lệ chiều rộng và chiều cao (SHIFT)',
      text_to_path: {
        check_thumbnail_warning: 'Một số văn bản đã được thay đổi sang các font khác khi chuyển đổi văn bản thành đường dẫn và một số ký tự có thể không được chuyển đổi bình thường. Vui lòng kiểm tra lại trước khi gửi.',
        error_when_parsing_text: 'Lỗi khi chuyển đổi văn bản thành đường dẫn',
        font_substitute_pop: 'Văn bản của bạn chứa các ký tự không được hỗ trợ bởi font hiện tại. <br/>Bạn có muốn sử dụng <strong>%s</strong> làm ký tự thay thế?',
        retry: 'Vui lòng thử lại sau hoặc chọn font khác',
        use_current_font: 'Sử dụng font hiện tại',
      },
      wait_for_parsing_font: 'Đang phân tích font... Vui lòng đợi',
    },
    path_preview: {
      current_position: 'Vị trí hiện tại',
      cut_distance: 'Khoảng cách cắt',
      cut_time: 'Thời gian cắt',
      end_preview: 'Kết thúc xem trước',
      estimated_time: 'Tổng thời gian ước tính',
      invert: 'Đảo ngược',
      pause: 'Tạm dừng',
      play: 'Phát',
      play_speed: 'Tốc độ phát lại',
      preview_info: 'Thông tin xem trước',
      rapid_distance: 'Khoảng cách di chuyển',
      rapid_time: 'Thời gian di chuyển',
      remark: '* Tất cả thông tin là giá trị ước tính tham khảo.',
      size: 'Kích thước',
      start_here: 'Bắt đầu tại đây',
      stop: 'Dừng',
      travel_path: 'Đường đi',
    },
    photo_edit_panel: {
      aspect_ratio: 'Tỷ lệ',
      brightness: 'Độ sáng',
      brightness_and_contrast: 'Độ sáng / Độ tương phản',
      cancel: 'Hủy',
      circumference: 'Chu vi',
      compare: 'So sánh',
      contrast: 'Độ tương phản',
      crop: 'Cắt',
      curve: 'Đường cong',
      diameter: 'Đường kính',
      free: 'Tự do',
      invert: 'Đảo màu',
      okay: 'Đồng ý',
      original: 'Gốc',
      phote_edit: 'Chỉnh sửa ảnh',
      processing: 'Đang xử lý',
      radius: 'Bán kính',
      rotary_warped: 'Biến Dạng Xoay',
      rotary_warped_link: 'https://support.flux3dp.com/hc/en-us/articles/10828006201103',
      sharpen: 'Làm nét',
      sharpness: 'Độ sắc nét',
      start: 'Bắt đầu',
      warp: 'Biến Dạng',
    },
    popup: {
      ai_credit: {
        buy_link: 'https://member.flux3dp.com/en-US/credit',
        go: 'Đi',
        insufficient_credit: 'Bạn đã hết Tín dụng',
        insufficient_credit_msg: 'Bạn không thể sử dụng %s. Vào trung tâm thành viên và mua Tín dụng AI.',
        relogin_to_use: 'Vui lòng đăng nhập lại để sử dụng chức năng này.',
      },
      auto_feeder_origin: 'Sử dụng chế độ xem trước khung để kiểm tra đường đi và khu vực của đầu laser, sau đó điều chỉnh vị trí vật liệu hoặc vị trí bắt đầu khắc theo kết quả để tránh va chạm trong quá trình khắc.',
      auto_switch_tab: {
        message: 'Một tùy chọn mới để tự động chuyển đổi giữa bảng Lớp và bảng Đối tượng đã được thêm vào Tùy chọn. Tùy chọn này bị tắt theo mặc định. Bạn có muốn bật chuyển đổi tự động ngay bây giờ không? <br/>Bạn có thể thay đổi cài đặt này bất kỳ lúc nào trong Tùy chọn.',
        title: 'Chuyển Đổi Tự Động Giữa Bảng Lớp và Đối Tượng',
      },
      backend_connect_failed_ask_to_upload: '#802 Lỗi liên tục khi kết nối backend. Bạn có muốn gửi báo cáo lỗi?',
      backend_error_hint: 'Các tính năng có thể không hoạt động do lỗi backend',
      both_power_and_speed_too_high: 'Sử dụng công suất thấp hơn sẽ kéo dài tuổi thọ laser. Ngoài ra, tốc độ quá cao có thể làm giảm chất lượng khắc.',
      bug_report: 'Báo cáo lỗi',
      change_workarea_before_preview: 'Khu vực làm việc không khớp. Bạn có muốn đổi khu vực làm việc hiện tại?',
      convert_to_path_fail: 'Không thể chuyển thành đường dẫn',
      dxf_bounding_box_size_over: 'Kích thước bản vẽ vượt quá khu vực làm việc. Vui lòng di chuyển gần gốc tọa độ hơn hoặc đảm bảo đơn vị chính xác',
      dxf_version_waring: 'Phiên bản DXF này có thể gặp vấn đề tương thích',
      facebook_group_invitation: {
        already_joined: 'Đã tham gia',
        join_now: 'Tôi tham gia',
        later: 'Có lẽ sau',
        message: 'Tham gia nhóm Facebook chính thức của chúng tôi để kết nối với các người dùng laser FLUX khác, thảo luận về sản phẩm laser FLUX, chia sẻ tác phẩm và cập nhật các bản cập nhật mới nhất. Chúng tôi mong được gặp bạn!',
        title: 'Tham gia nhóm người dùng chính thức FLUX',
      },
      import_file_contain_invalid_path: '#808 Tệp SVG được nhập chứa đường dẫn hình ảnh không hợp lệ. Hãy đảm bảo tất cả hình ảnh tồn tại hoặc nhúng hình vào tệp',
      import_file_error_ask_for_upload: 'Nhập tệp SVG thất bại. Bạn có muốn cung cấp tệp cho nhóm phát triển để báo cáo lỗi?',
      layer_by_color: 'Màu sắc',
      layer_by_layer: 'Theo từng lớp',
      loading_image: 'Đang tải hình ảnh, vui lòng đợi...',
      more_than_two_object: 'Chỉ hỗ trợ 2 đối tượng',
      mouse: 'Chuột',
      no_support_text: 'Beam Studio hiện không hỗ trợ chữ. Vui lòng chuyển đổi chữ thành đường dẫn trước khi nhập.',
      nolayer: 'Lớp đơn',
      not_support_object_type: 'Không hỗ trợ loại đối tượng này',
      or_turn_off_borderless_mode: 'Hoặc tắt chế độ toàn màn hình',
      pdf2svg: {
        error_pdf2svg_not_found: '#825 Lỗi: Không tìm thấy lệnh pdf2svg. Vui lòng cài đặt pdf2svg',
        error_when_converting_pdf: '#824 Lỗi khi chuyển đổi PDF sang SVG:',
      },
      progress: {
        calculating: 'Đang tính toán',
        uploading: 'Đang tải lên',
      },
      questionnaire: {
        caption: 'Giúp chúng tôi điền vào bảng câu hỏi',
        message: 'Giúp chúng tôi điền bảng câu hỏi để Beam Studio tốt hơn',
        no_questionnaire_available: 'Hiện không có bảng câu hỏi nào',
        unable_to_get_url: 'Không lấy được liên kết đến bảng câu hỏi qua mạng. Vui lòng kiểm tra kết nối internet',
      },
      recommend_downgrade_software: 'Chúng tôi phát hiện ra phiên bản firmware cũ hơn. Chúng tôi đang khắc phục các vấn đề tương thích, nhưng hiện tại, khuyên bạn dùng <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>.',
      recommend_upgrade_firmware: 'Chúng tôi phát hiện ra phiên bản firmware cũ hơn. Chúng tôi đang khắc phục các vấn đề tương thích, nhưng hiện tại, khuyên bạn nên cập nhật lên phiên bản firmware mới nhất.',
      save_unsave_changed: 'Bạn có muốn lưu thay đổi chưa lưu?',
      select_at_least_two: 'Chọn hai đối tượng để tiếp tục',
      select_first: 'Hãy chọn một đối tượng trước',
      select_import_method: 'Chọn kiểu xếp lớp:',
      select_import_module: 'Chọn mô-đun:',
      sentry: {
        message: 'Bạn có đồng ý tự động tải lên thông tin liên quan khi có lỗi xảy ra?',
        title: 'Hãy cùng cải thiện Beam Studio',
      },
      should_update_firmware_to_continue: '#814 Phần mềm của bạn không hỗ trợ phiên bản Beam Studio này. Vui lòng cập nhật firmware để tiếp tục. (Menu > Máy > [Máy của bạn] > Cập nhật firmware)',
      speed_too_high_lower_the_quality: 'Sử dụng tốc độ quá cao ở độ phân giải này có thể làm giảm chất lượng khắc.',
      still_continue: 'Tiếp tục',
      successfully_uploaded: 'Tải tệp thành công.',
      svg_1_1_waring: 'Phiên bản SVG này là 1.1, có thể gặp vấn đề tương thích',
      svg_image_path_waring: 'Tệp SVG này chứa <image> từ đường dẫn tệp. Điều này có thể gây lỗi khi mở.\nĐể tránh rủi ro, hãy dùng ảnh nhúng khi xuất SVG',
      text_to_path: {
        caption: 'Bộ chuyển đổi Văn bản thành Đường dẫn 2.0',
        message: "Beam Studio giới thiệu một bộ chuyển đổi văn bản thành đường dẫn mới (Bộ chuyển đổi 2.0), tạo ra kết quả đáng tin cậy hơn! Bạn có muốn chuyển sang nó bây giờ không? \nBạn cũng có thể điều chỉnh cài đặt này sau trong phần 'Cài đặt bộ chuyển đổi văn bản thành đường dẫn' trong tùy chọn.",
      },
      too_fast_for_auto_feeder: 'Sử dụng tốc độ quá cao trong các lớp chứa các đối tượng đường dẫn có thể làm giảm độ chính xác khi cắt.\nChúng tôi không khuyến nghị sử dụng tốc độ vượt quá %(limit)s khi cắt với bộ nạp tự động.',
      too_fast_for_auto_feeder_and_constrain: 'Các lớp sau: %(layers)s \nchứa đối tượng đường dẫn vector và có tốc độ vượt quá %(limit)s.\nTốc độ cắt của đối tượng đường dẫn vector với bộ nạp tự động sẽ bị giới hạn ở %(limit)s.\nBạn có thể loại bỏ giới hạn này trong Cài đặt Tùy chọn.',
      too_fast_for_curve: 'Sử dụng tốc độ quá cao cho các lớp chứa đối tượng khắc đường cong có thể làm giảm độ chính xác của khắc.\nChúng tôi khuyến nghị giữ tốc độ dưới %(limit)s để có kết quả tối ưu.',
      too_fast_for_curve_and_constrain: 'Các lớp sau: %(layers)s vượt quá giới hạn tốc độ %(limit)s.\nTốc độ khắc cho các đối tượng đường cong sẽ bị giới hạn ở %(limit)s. Bạn có thể loại bỏ giới hạn này trong Cài đặt Tùy chọn.',
      too_fast_for_path: 'Sử dụng tốc độ quá cao cho đường dẫn có thể làm giảm độ chính xác khi cắt. Chúng tôi không khuyến nghị dùng tốc độ trên %(limit)s khi cắt đường dẫn.',
      too_fast_for_path_and_constrain: 'Các lớp: %(layers)s \nchứa đường dẫn vector và có tốc độ trên %(limit)s.\nTốc độ cắt đường dẫn sẽ bị giới hạn ở %(limit)s.\nBạn có thể bỏ giới hạn này trong Cài đặt.',
      touchpad: 'Bảng cảm ứng',
      ungroup_use: 'Điều này sẽ bỏ nhóm và có thể mất thời gian. Bạn có chắc tiếp tục?',
      upload_failed: '#819 Tải tệp thất bại.',
      upload_file_too_large: '#819 Tệp quá lớn để tải lên.',
      vectorize_shading_image: 'Ảnh gradient mất nhiều thời gian vector hóa và dễ bị nhiễu. Vui lòng tắt trước khi thực thi',
    },
    rating_panel: {
      description: 'Nếu bạn thích Beam Studio, chúng tôi rất đánh giá cao nếu bạn có thể đánh giá ứng dụng của chúng tôi.',
      thank_you: 'Cảm ơn bạn!',
      title: 'Thích Beam Studio?',
    },
    right_panel: {
      laser_panel: {
        advanced: 'Nâng Cao',
        backlash: 'Độ rơ',
        bi_directional: 'Hai Chiều',
        by: 'Bởi',
        ce_z_high_speed: 'Trục Z tốc độ cao',
        ce_z_high_speed_link: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
        color_adjustment: 'Điều chỉnh kênh màu',
        color_adjustment_short: 'Kênh màu',
        color_strength: 'Sức mạnh',
        cross_hatch: 'Nét Chéo',
        custom_preset: 'Tùy chỉnh',
        cut: 'Cắt',
        diode: 'Laser điốt',
        do_not_adjust_default_para: 'Không thể điều chỉnh thiết lập mặc định.',
        dottingTime: 'Thời gian chấm điểm',
        dropdown: {
          inches: {
            abs_engraving: 'ABS - Khắc',
            acrylic_3mm_cutting: 'Acrylic - 1/8″ Cắt',
            acrylic_5mm_cutting: 'Acrylic - 3/16″ Cắt',
            acrylic_8mm_cutting: 'Acrylic - 1/4″ Cắt',
            acrylic_10mm_cutting: 'Acrylic - 3/8″ Cắt',
            acrylic_engraving: 'Acrylic - Khắc',
            acrylic_printing: 'Nhựa acrylic - In',
            aluminum_engraving: 'Nhôm - Khắc',
            aluminum_light: 'Nhôm (sáng)',
            bamboo_printing: 'Tre - In',
            black_abs: 'ABS đen',
            black_acrylic_3mm_cutting: 'Nhựa acrylic đen - 1/8″ Cắt',
            black_acrylic_5mm_cutting: 'Nhựa acrylic đen - 3/16″ Cắt',
            black_acrylic_engraving: 'Nhựa acrylic đen - Khắc',
            brass_dark: 'Đồng thau (tối)',
            brass_engraving: 'Đồng - Khắc',
            brass_light: 'Đồng thau (sáng)',
            canvas_printing: 'Vải bạt - In',
            cardstock_printing: 'Giấy cứng - In',
            copper: 'Đồng',
            cork_printing: 'Liège - In',
            denim_1mm_cutting: 'Denim - 1/32″ Cắt',
            fabric_3mm_cutting: 'Vải - 1/8″ Cắt',
            fabric_5mm_cutting: 'Vải - 3/16″ Cắt',
            fabric_engraving: 'Vải - Khắc',
            fabric_printing: 'Vải - In',
            flat_stone_printing: 'Đá phẳng - In',
            glass_bw_engraving: 'Kính - Khắc',
            glass_printing: 'Kính - In',
            gloss_leather_printing: 'Da bóng - In',
            gold_engraving: 'Vàng - Khắc',
            iron_engraving: 'Sắt - Khắc',
            leather_3mm_cutting: 'Da - 1/8″ Cắt',
            leather_5mm_cutting: 'Da - 3/16″ Cắt',
            leather_engraving: 'Da - Khắc',
            mdf_3mm_cutting: 'MDF New Zealand - 1/8″ Cắt',
            mdf_5mm_cutting: 'MDF New Zealand - 3/16″ Cắt',
            mdf_engraving: 'MDF New Zealand - Khắc',
            metal_bw_engraving: 'Kim loại - Khắc',
            opaque_acrylic: 'Acrylic mờ',
            pc_printing: 'PC - In',
            rubber_bw_engraving: 'Cao su - Khắc',
            silver_engraving: 'Bạc - Khắc',
            stainless_steel_bw_engraving_diode: 'Kim loại - Khắc (Laser diode)',
            stainless_steel_dark: 'Thép không gỉ (tối)',
            stainless_steel_engraving: 'Thép không gỉ - Khắc',
            stainless_steel_light: 'Thép không gỉ (sáng)',
            stainless_steel_printing: 'Thép không gỉ - In',
            steel_engraving_spray_engraving: 'Kim loại - Khắc',
            stone: 'Đá',
            ti_engraving: 'Titan - Khắc',
            titanium_dark: 'Titan (tối)',
            titanium_light: 'Titan (sáng)',
            white_abs: 'ABS trắng',
            wood_3mm_cutting: 'Gỗ - 1/8″ Cắt',
            wood_5mm_cutting: 'Gỗ - 3/16″ Cắt',
            wood_7mm_cutting: 'Gỗ - 1/4″ Cắt',
            wood_8mm_cutting: 'Gỗ - 1/4″ Cắt',
            wood_10mm_cutting: 'Gỗ - 3/8″ Cắt',
            wood_engraving: 'Gỗ - Khắc',
            wood_printing: 'Gỗ - In',
          },
          mm: {
            abs_engraving: 'ABS - Khắc',
            acrylic_3mm_cutting: 'Acrylic - 3mm Cắt',
            acrylic_5mm_cutting: 'Acrylic - 5mm Cắt',
            acrylic_8mm_cutting: 'Acrylic - 8mm Cắt',
            acrylic_10mm_cutting: 'Acrylic - 10mm Cắt',
            acrylic_engraving: 'Acrylic - Khắc',
            acrylic_printing: 'Nhựa acrylic - In',
            aluminum_engraving: 'Nhôm - Khắc',
            aluminum_light: 'Nhôm (sáng)',
            bamboo_printing: 'Tre - In',
            black_abs: 'ABS đen',
            black_acrylic_3mm_cutting: 'Nhựa acrylic đen - 3mm Cắt',
            black_acrylic_5mm_cutting: 'Nhựa acrylic đen - 5mm Cắt',
            black_acrylic_engraving: 'Nhựa acrylic đen - Khắc',
            brass_dark: 'Đồng thau (tối)',
            brass_engraving: 'Đồng thau - Khắc',
            brass_light: 'Đồng thau (sáng)',
            canvas_printing: 'Vải bạt - In',
            cardstock_printing: 'Giấy cứng - In',
            copper: 'Đồng',
            cork_printing: 'Liège - In',
            denim_1mm_cutting: 'Denim - 1mm Cắt',
            fabric_3mm_cutting: 'Vải - 3mm Cắt',
            fabric_5mm_cutting: 'Vải - 5mm Cắt',
            fabric_engraving: 'Vải - Khắc',
            fabric_printing: 'Vải - In',
            flat_stone_printing: 'Đá phẳng - In',
            glass_bw_engraving: 'Kính - Khắc',
            glass_printing: 'Kính - In',
            gloss_leather_printing: 'Da bóng - In',
            gold_engraving: 'Vàng - Khắc',
            iron_engraving: 'Sắt - Khắc',
            leather_3mm_cutting: 'Da - 3mm Cắt',
            leather_5mm_cutting: 'Da - 5mm Cắt',
            leather_engraving: 'Da - Khắc',
            mdf_3mm_cutting: 'MDF - 3mm Cắt',
            mdf_5mm_cutting: 'MDF - 5mm Cắt',
            mdf_engraving: 'MDF - Khắc',
            metal_bw_engraving: 'Kim loại - Khắc',
            opaque_acrylic: 'Acrylic mờ',
            pc_printing: 'PC - In',
            rubber_bw_engraving: 'Cao su - Khắc',
            silver_engraving: 'Bạc - Khắc',
            stainless_steel_bw_engraving_diode: 'Kim loại - Khắc (Laser điốt)',
            stainless_steel_dark: 'Thép không gỉ (tối)',
            stainless_steel_engraving: 'Thép không gỉ - Khắc',
            stainless_steel_light: 'Thép không gỉ (sáng)',
            stainless_steel_printing: 'Thép không gỉ - In',
            steel_engraving_spray_engraving: 'Kim loại - Khắc',
            stone: 'Đá',
            ti_engraving: 'Titan - Khắc',
            titanium_dark: 'Titan (tối)',
            titanium_light: 'Titan (sáng)',
            white_abs: 'ABS trắng',
            wood_3mm_cutting: 'Gỗ - 3mm Cắt',
            wood_5mm_cutting: 'Gỗ - 5mm Cắt',
            wood_7mm_cutting: 'Gỗ - 7mm Cắt',
            wood_8mm_cutting: 'Gỗ - 8mm Cắt',
            wood_10mm_cutting: 'Gỗ - 10mm Cắt',
            wood_engraving: 'Gỗ - Khắc',
            wood_printing: 'Gỗ - In',
          },
          parameters: 'Cài đặt mặc định',
          save: 'Thêm thông số hiện tại',
        },
        engrave: 'Khắc',
        existing_name: 'Tên này đã được sử dụng.',
        fill_angle: 'Góc Lấp Đầy',
        fill_interval: 'Khoảng Cách Lấp Đầy',
        fill_setting: 'Cài đặt Đổ đầy',
        filled_path_only: 'Chỉ dành cho đường dẫn tô màu',
        focus_adjustment: 'Điều chỉnh tiêu điểm',
        frequency: 'Tần Số',
        gradient_only: 'Chỉ dành cho hình ảnh chuyển sắc',
        halftone: 'Nửa tone',
        halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
        height: 'Chiều cao vật thể',
        ink_saturation: 'Độ bão hòa mực',
        ink_type: {
          normal: 'Mực',
          text: 'Loại mực',
          UV: 'Mực UV',
        },
        laser_speed: {
          fast: 'Nhanh',
          max: 300,
          min: 3,
          slow: 'Chậm',
          step: 0.1,
          text: 'Tốc độ',
          unit: 'mm/s',
        },
        low_power_warning: 'Công suất laser thấp (dưới 10%) có thể không phát ra ánh sáng laser.',
        low_speed_warning: 'Tốc độ thấp có thể gây cháy vật liệu.',
        lower_focus: 'Hạ Tiêu Cự',
        lower_focus_desc: 'Hạ độ cao tiêu cự theo một khoảng cách cụ thể sau khi lấy nét để cải thiện hiệu suất cắt.',
        module: 'Mô-đun',
        more: 'Quản lý',
        multi_layer: 'Nhiều lớp',
        para_in_use: 'Thông số này đang được sử dụng.',
        parameters: 'Thông số',
        power: {
          high: 'Cao',
          low: 'Thấp',
          max: 100,
          min: 1,
          step: 0.1,
          text: 'Công suất',
        },
        preset_management: {
          add_new: 'Thêm Mới',
          delete: 'Xóa',
          export: 'Xuất',
          export_preset_title: 'Xuất cấu hình mặc định',
          import: 'Nhập',
          laser: 'Laser',
          lower_focus_by: 'Giảm Tiêu Điểm Bởi',
          new_preset_name: 'Tên cấu hình mới',
          preset: 'Thiết Lập Trước',
          print: 'In',
          reset: 'Đặt lại',
          save_and_exit: 'Lưu và thoát',
          show_all: 'Hiển Thị Tất Cả',
          sure_to_import_presets: 'Điều này sẽ tải bố trí của các cài đặt trước và ghi đè các tham số tùy chỉnh, bạn có chắc chắn muốn tiếp tục không?',
          sure_to_reset: 'Điều này sẽ xóa các tham số tùy chỉnh của bạn và đặt lại tất cả các cài đặt trước, bạn có chắc chắn muốn tiếp tục không?',
          title: 'Quản Lý Thông Số',
          wobble_diameter: 'Đường kính rung',
          wobble_step: 'Kích thước bước rung',
        },
        preset_setting: 'Thiết lập thông số (%s)',
        presets: 'Thiết lập',
        print_multipass: 'Đa lớp',
        promark_speed_desc: 'Các tham số tốc độ không áp dụng cho hình ảnh gradient.',
        pulse_width: 'Độ Rộng Xung',
        pwm_advanced_desc: 'Đặt công suất tối thiểu cho chế độ độ sâu.',
        pwm_advanced_hint: 'Cài đặt này áp dụng cụ thể cho hình ảnh gradient khi sử dụng chế độ độ sâu.',
        pwm_advanced_setting: 'Cài Đặt Công Suất Chế Độ Độ Sâu',
        repeat: 'Số Lần Cắt',
        single_color: 'Màu Đơn',
        single_color_desc: 'Chỉ áp dụng cho lớp màu đầy đủ và không thể sử dụng với lớp màu đơn mở rộng.',
        slider: {
          fast: 'Nhanh',
          high: 'Cao',
          low: 'Thấp',
          regular: 'Bình thường',
          slow: 'Chậm',
          very_fast: 'Rất nhanh',
          very_high: 'Rất cao',
          very_low: 'Rất thấp',
          very_slow: 'Rất chậm',
        },
        speed: 'Tốc độ',
        speed_constrain_warning: 'Tốc độ cắt của các đối tượng đường vector sẽ bị giới hạn ở %(limit)s. Bạn có thể loại bỏ giới hạn này tại Cài đặt Tùy chọn.',
        speed_constrain_warning_auto_feeder: 'Tốc độ cắt của các đối tượng bộ nạp tự động sẽ bị giới hạn ở %(limit)s. Bạn có thể bỏ giới hạn này trong Cài đặt Sở thích.',
        speed_constrain_warning_curve_engraving: 'Tốc độ khắc đường cong sẽ bị giới hạn ở mức %(limit)s. Bạn có thể xóa giới hạn này trong Cài đặt Tùy chọn.',
        stepwise_focusing: 'Lấy Nét Từng Bước',
        stepwise_focusing_desc: 'Hạ dần khoảng cách tiêu cự dựa trên chiều cao của đối tượng trong mỗi lần cắt.',
        strength: 'Công suất',
        times: 'lần',
        various_preset: 'Các cài đặt mặc định khác nhau',
        white_ink: 'Mực trắng',
        white_ink_settings: 'Cài đặt mực trắng',
        wobble: 'Rung động',
        wobble_desc: 'Tạo các vòng tròn nhỏ dọc theo đường đi, hữu ích cho các nhiệm vụ cắt. (Chỉ dành cho các đối tượng đường dẫn.)',
        wobble_diameter: 'Đường kính',
        wobble_step: 'Kích thước bước',
        z_step: 'Bước Z',
      },
      layer_panel: {
        current_layer: 'Lớp hiện tại',
        layer1: 'Lớp 1',
        layer_bitmap: 'Ảnh điểm',
        layer_cutting: 'Cắt',
        layer_engraving: 'Khắc',
        layers: {
          del: 'Xóa lớp',
          dupe: 'Nhân đôi lớp',
          fullColor: 'Màu đầy đủ',
          layer: 'Lớp',
          layers: 'Lớp',
          lock: 'Khóa lớp',
          merge_all: 'Hợp nhất tất cả',
          merge_down: 'Hợp nhất xuống dưới',
          merge_selected: 'Hợp nhất các lớp đã chọn',
          move_down: 'Di chuyển lớp xuống',
          move_elems_to: 'Di chuyển các phần tử đến:',
          move_selected: 'Di chuyển các phần tử đã chọn sang một lớp khác',
          move_up: 'Di chuyển lớp lên',
          new: 'Lớp mới',
          rename: 'Đổi tên lớp',
          splitFullColor: 'Mở rộng lớp',
          switchToFullColor: 'Chuyển sang lớp màu đầy đủ',
          switchToSingleColor: 'Chuyển sang lớp màu đơn',
          unlock: 'Mở khóa',
        },
        move_elems_to: 'Di chuyển các phần tử đến:',
        notification: {
          dupeLayerName: 'Đã có một lớp trùng tên!',
          enterNewLayerName: 'Vui lòng nhập tên lớp mới',
          enterUniqueLayerName: 'Vui lòng nhập tên lớp duy nhất',
          layerHasThatName: 'Lớp đã có tên này',
          mergeLaserLayerToPrintingLayerMsg: 'Lưu ý rằng nếu hoàn tất thao tác này, cài đặt của lớp laser sẽ bị xóa và đặt theo lớp hiện tại.',
          mergeLaserLayerToPrintingLayerTitle: 'Bạn có muốn hợp nhất các lớp này thành một lớp In ấn?',
          mergePrintingLayerToLaserLayerMsg: 'Lưu ý rằng nếu hoàn tất thao tác này, cài đặt màu của lớp in ấn sẽ bị xóa và đặt theo lớp hiện tại.',
          mergePrintingLayerToLaserLayerTitle: 'Bạn có muốn hợp nhất các lớp này thành một lớp Laser?',
          moveElemFromPrintingLayerMsg: 'Lưu ý rằng nếu hoàn tất thao tác này, cài đặt màu của phần tử đã chọn sẽ bị xóa và đặt theo %s.',
          moveElemFromPrintingLayerTitle: 'Di chuyển phần tử đã chọn sang %s và chuyển nó thành phần tử laser?',
          moveElemToPrintingLayerMsg: 'Lưu ý rằng nếu hoàn tất thao tác này, cài đặt của phần tử đã chọn sẽ bị xóa và đặt theo %s.',
          moveElemToPrintingLayerTitle: 'Di chuyển phần tử đã chọn sang %s và chuyển nó thành phần tử in ấn?',
          newName: 'TÊN MỚI',
          QmoveElemsToLayer: "Di chuyển các phần tử đã chọn đến lớp '%s'?",
          splitColorMsg: 'Lưu ý rằng nếu tiếp tục thao tác này, bạn sẽ không thể quay lại các lớp màu ban đầu.',
          splitColorTitle: 'Bạn có muốn mở rộng lớp đã chọn thành các lớp CMYK?',
        },
      },
      object_panel: {
        actions_panel: {
          ai_bg_removal: 'Xóa nền',
          ai_bg_removal_reminder: 'Nhấn nút sẽ sử dụng ngay 0.2 Tín dụng, bạn có muốn tiếp tục không?',
          ai_bg_removal_short: 'Xóa nền',
          array: 'Mảng',
          auto_fit: 'Tự Động Vừa Khít',
          bevel: 'Bo góc',
          brightness: 'Độ sáng',
          convert_to_path: 'Chuyển đổi thành đường viền',
          create_textpath: 'Tạo chữ trên đường viền',
          create_textpath_short: 'Chữ trên đường dẫn',
          crop: 'Cắt',
          decompose_path: 'Tách',
          detach_path: 'Tách chữ khỏi đường dẫn',
          detach_path_short: 'Tách',
          disassemble_use: 'Tháo rời',
          disassembling: 'Đang tháo rời...',
          edit_path: 'Chỉnh sửa đường dẫn',
          fetching_web_font: 'Đang tải phông chữ...',
          grading: 'Phân cấp màu',
          invert: 'Đảo ngược',
          offset: 'Offset',
          outline: 'Đường viền',
          replace_with: 'Thay thế bằng...',
          replace_with_short: 'Thay',
          sharpen: 'Làm nét',
          simplify: 'Đơn giản hóa',
          smart_nest: 'Tổ Thông Minh',
          trace: 'Vẽ dọc theo đường viền',
          ungrouping: 'Đang bỏ nhóm...',
          uploading_font_to_machine: 'Đang tải phông chữ lên máy...',
          wait_for_parsing_font: 'Đang phân tích phông chữ... Vui lòng đợi',
          weld_text: 'Ghép chữ',
        },
        align: 'Căn chỉnh',
        boolean: 'Phép toán Boole',
        bottom_align: 'Căn dưới',
        center_align: 'Căn giữa',
        difference: 'Khác biệt',
        distribute: 'Phân bố',
        flip: 'Lật',
        group: 'Nhóm',
        hdist: 'Phân bố theo chiều ngang',
        hflip: 'Lật ngang',
        intersect: 'Giao',
        left_align: 'Căn trái',
        lock_aspect: 'Khóa tỷ lệ',
        middle_align: 'Căn giữa',
        option_panel: {
          color: 'Màu sắc',
          fill: 'Mức độ đặc',
          font_family: 'Phông chữ',
          font_size: 'Cỡ chữ',
          font_style: 'Kiểu',
          letter_spacing: 'Khoảng cách chữ',
          line_spacing: 'Khoảng cách dòng',
          path_infill: 'Độ đặc đường',
          pwm_engraving: 'Chế Độ Độ Sâu',
          pwm_engraving_link: 'https://support.flux3dp.com/hc/en-us/articles/10419884701327',
          rounded_corner: 'Góc bo tròn',
          shading: 'Màu sắc',
          sides: 'Cạnh',
          start_offset: 'Lùi chữ',
          stroke: 'Đường viền',
          stroke_color: 'Màu viền',
          stroke_width: 'Độ rộng viền',
          text_infill: 'Độ đặc chữ',
          threshold: 'Ngưỡng',
          threshold_short: 'Ngưỡng',
          vertical_align: 'Căn chỉnh',
          vertical_text: 'Chữ dọc',
        },
        path_edit_panel: {
          connect: 'Kết nối',
          delete: 'Xóa',
          disconnect: 'Ngắt kết nối',
          node_type: 'KIỂU NODE',
          round: 'Tròn',
          sharp: 'Sắc nét',
        },
        right_align: 'Căn phải',
        subtract: 'Trừ',
        top_align: 'Căn trên',
        ungroup: 'Bỏ nhóm',
        union: 'Kết hợp',
        unlock_aspect: 'Mở khóa tỷ lệ',
        vdist: 'Phân bố theo chiều dọc',
        vflip: 'Lật dọc',
        zoom: 'Thu phóng',
      },
      tabs: {
        layers: 'Lớp',
        objects: 'Đối tượng',
        path_edit: 'Chỉnh sửa đường dẫn',
      },
    },
    shapes_panel: {
      animals: 'Động vật',
      arrow: 'Mũi tên',
      basic: 'Cơ bản',
      birds: 'Chim',
      celebration: 'Kỷ niệm',
      circular: 'Tròn',
      CNY: 'Tết',
      corner: 'Góc',
      decor: 'Trang trí',
      easter: 'Phục Sinh',
      elements: 'Các yếu tố',
      environment: 'Môi trường',
      graphics: 'Đồ họa',
      halloween: 'Halloween',
      holidays: 'Ngày lễ',
      label: 'Nhãn',
      land: 'Đất liền',
      line: 'Đường thẳng',
      nature: 'Thiên nhiên',
      photo: 'Ảnh',
      plants: 'Thực vật',
      ribbon: 'Ruy băng',
      sea: 'Biển',
      shape: 'Hình dạng',
      speech: 'Phát biểu',
      text: 'Khung văn bản',
      title: 'Các yếu tố',
      valentines: 'Lễ Tình Nhân',
      weather: 'Thời tiết',
      Xmas: 'Giáng sinh',
    },
    svg_editor: {
      unable_to_fetch_clipboard_img: 'Không tải được hình ảnh từ bộ nhớ tạm.',
      unnsupported_file_type: 'Định dạng tệp không được hỗ trợ trực tiếp. Vui lòng chuyển đổi tệp thành SVG hoặc ảnh bitmap.',
    },
    tag: {
      g: 'Nhóm',
      image: 'Hình ảnh',
      text: 'Văn bản',
      use: 'Nhập SVG',
    },
    time_est_button: {
      calculate: 'Ước tính thời gian',
      estimate_time: 'Thời gian ước tính:',
    },
    tool_panels: {
      _nest: {
        end: 'Đóng',
        no_element: 'Không có phần tử để sắp xếp',
        rotations: 'Xoay có thể',
        spacing: 'Khoảng cách',
        start_nest: 'Sắp xếp',
        stop_nest: 'Dừng',
      },
      _offset: {
        corner_type: 'Góc',
        direction: 'Hướng offset',
        dist: 'Khoảng cách offset',
        fail_message: 'Không thể offset đối tượng',
        inward: 'Hướng trong',
        not_support_message: 'Các phần tử được chọn chứa thẻ SVG không hỗ trợ:\nHình ảnh, Nhóm, Văn bản và Đối tượng nhập:\n',
        outward: 'Hướng ngoài',
        round: 'Tròn',
        sharp: 'Sắc nét',
      },
      array_dimension: 'Kích thước mảng',
      array_interval: 'Khoảng cách mảng',
      cancel: 'Hủy',
      columns: 'Cột',
      confirm: 'Xác nhận',
      dx: 'X',
      dy: 'Y',
      grid_array: 'Tạo mảng lưới',
      nest: 'Sắp xếp tối ưu',
      offset: 'Offset',
      rows: 'Hàng',
    },
    units: {
      mm: 'mm',
      walt: 'W',
    },
    zoom_block: {
      fit_to_window: 'Vừa với Cửa sổ',
    },
  },
  boxgen: {
    add_option: 'Thêm tùy chọn',
    basic_box: 'Hộp cơ bản',
    beam_radius: 'Bù đường kính tia laser',
    beam_radius_warning: 'Xóa bỏ bù độ rộng lưỡi cắt khi các cạnh hoặc khớp của hộp ngắn để đảm bảo lắp ráp hộp',
    cancel: 'Hủy',
    coming_soon: 'Sắp ra mắt',
    continue_import: 'Tiếp tục nhập',
    control_tooltip: 'Trái chuột để xoay\nCuộn để thu phóng\nPhải chuột để di chuyển',
    control_tooltip_touch: 'Kéo để xoay\nVuốt để thu phóng\nHai ngón tay để di chuyển',
    cover: 'Nắp đậy',
    customize: 'Tùy chỉnh',
    depth: 'Chiều sâu',
    edge: 'Cạnh',
    finger: 'Ngón tay',
    finger_warning: 'Chiều dài cạnh trong của hộp cần ít nhất 6mm (0,24 inch) để tương thích với khớp nối ngón tay.',
    height: 'Chiều cao',
    import: 'Nhập',
    inner: 'Bên trong',
    joints: 'Khớp nối',
    max_dimension_tooltip: 'Kích thước tối đa là %s.',
    merge: 'Gộp',
    outer: 'Bên ngoài',
    reset: 'Đặt lại',
    tCount: 'Số lượng T',
    tCount_tooltip: 'Số lượng khớp T áp dụng cho cạnh ngắn; số lượng ở cạnh dài được tính dựa trên chiều dài của nó.',
    tDiameter: 'Đường kính T',
    text_label: 'Nhãn',
    thickness: 'Độ dày',
    title: 'BOXGEN',
    tLength: 'Chiều dài T',
    tSlot: 'Rãnh T',
    tSlot_warning: 'Chiều dài cạnh hộp cần ít nhất 30mm (1,18 inch) để tương thích với khớp nối T.',
    volume: 'Thể tích',
    width: 'Chiều rộng',
    workarea: 'Khu vực làm việc',
    zoom: 'Thu phóng',
  },
  buttons: {
    back: 'Quay lại',
    back_to_beam_studio: 'Quay lại Beam Studio',
    done: 'Hoàn tất',
    next: 'Tiếp theo',
  },
  calibration: {
    ador_autofocus_focusing_block: 'Nhấn biểu tượng “AF” trên màn hình chính của máy trong 3 giây và để đầu dò chạm vào khối lấy nét.',
    ador_autofocus_material: 'Nhấn biểu tượng “AF” trên màn hình chính của máy trong 3 giây và để đầu dò chạm nhẹ vào vật liệu.',
    align_ilb: 'Vị trí: Căn chỉnh với điểm khắc ở phía dưới cùng bên trái bên trong.',
    align_ilt: 'Vị trí: Căn chỉnh với điểm khắc ở phía trên cùng bên trái bên trong.',
    align_irb: 'Vị trí: Căn chỉnh với điểm khắc ở phía dưới cùng bên phải bên trong.',
    align_irt: 'Vị trí: Căn chỉnh với điểm khắc ở phía trên cùng bên phải bên trong.',
    align_olb: 'Vị trí: Căn chỉnh với điểm khắc ở phía dưới cùng bên trái bên ngoài.',
    align_olt: 'Vị trí: Căn chỉnh với điểm khắc ở phía trên cùng bên trái bên ngoài.',
    align_orb: 'Vị trí: Căn chỉnh với điểm khắc ở phía dưới cùng bên phải bên ngoài.',
    align_ort: 'Vị trí: Căn chỉnh với điểm khắc ở phía trên cùng bên phải bên ngoài.',
    align_red_cross_cut: 'Hãy căn chỉnh giữa dấu cộng đỏ với dấu cắt.',
    align_red_cross_print: 'Hãy căn chỉnh giữa dấu cộng đỏ với dấu in.',
    analyze_result_fail: 'Không thể phân tích ảnh chụp.<br/>Vui lòng đảm bảo:<br/>1. Ảnh chụp phủ kín toàn bộ tờ giấy.<br/>2. Bệ đỡ được lấy nét đúng cách.',
    ask_for_readjust: 'Bạn có muốn bỏ qua bước khắc và chụp ảnh để hiệu chỉnh trực tiếp?',
    back: 'Quay Lại',
    calculating_camera_matrix: 'Đang tính toán ma trận camera...',
    calculating_regression_parameters: 'Đang tính toán tham số hồi quy...',
    calibrate_camera_before_calibrate_modules: 'Vui lòng hiệu chỉnh camera trước khi hiệu chỉnh các mô-đun.',
    calibrate_chessboard_success_msg: 'Đã chụp thành công ảnh bàn cờ.<br/>Điểm cho ảnh này là %s (%.2f).',
    calibrate_done: 'Hiệu chỉnh hoàn tất. Độ chính xác của camera tốt hơn khi lấy nét chính xác.',
    calibrate_done_diode: 'Hiệu chỉnh hoàn tất. Offset của mô-đun laser đi-ốt đã được lưu.',
    calibrating: 'Đang hiệu chỉnh...',
    calibrating_with_device_pictures: 'Đang hiệu chỉnh với hình ảnh thiết bị...',
    camera_calibration: 'Hiệu chỉnh camera',
    camera_parameter_saved_successfully: 'Thông số máy ảnh đã được lưu thành công.',
    cancel: 'Hủy Bỏ',
    check_checkpoint_data: 'Dữ liệu kiểm tra điểm',
    check_device_pictures: 'Kiểm tra hình ảnh thiết bị',
    checking_checkpoint: 'Đang kiểm tra dữ liệu kiểm tra điểm...',
    checking_pictures: 'Đang kiểm tra hình ảnh thiết bị...',
    diode_calibration: 'Hiệu chỉnh module laser điốt',
    do_engraving: 'Thực Hiện Khắc',
    download_chessboard_file: 'Tải xuống tệp bàn cờ',
    downloading_checkpoint: 'Đang tải dữ liệu kiểm tra điểm...',
    downloading_pictures: 'Đang tải ảnh...',
    drawing_calibration_image: 'Đang vẽ ảnh hiệu chỉnh...',
    dx: 'X',
    dy: 'Y',
    elevate_and_cut: 'Nâng và Cắt',
    elevate_and_cut_step_1: 'Đặt một miếng gỗ màu sáng kích thước A4 ở giữa không gian làm việc và nâng lên đến 20mm.',
    elevate_and_cut_step_1_prism_lift: 'Sử dụng Ador Prism Lift với chiều dài tối đa là 14 mm cùng với một miếng gỗ có độ dày ít nhất 6 mm để nâng cao gỗ lên đến độ cao 20 mm.',
    failed_to_calibrate_camera: '#848 Hiệu chỉnh camera thất bại, vui lòng liên hệ hỗ trợ FLUX.',
    failed_to_calibrate_chessboard: 'Không thể hiệu chỉnh với hình ảnh bàn cờ.',
    failed_to_calibrate_with_pictures: '#848 Không thể hiệu chỉnh với hình ảnh thiết bị.',
    failed_to_download_pictures: '#848 Tải ảnh thất bại, vui lòng liên hệ hỗ trợ FLUX.',
    failed_to_move_laser_head: 'Không thể di chuyển đầu laser.',
    failed_to_parse_checkpoint: 'Không thể phân tích dữ liệu kiểm tra điểm.',
    failed_to_save_calibration_results: '#849 Lưu kết quả hiệu chỉnh thất bại, vui lòng thử lại. Nếu vẫn xảy ra, vui lòng liên hệ hỗ trợ FLUX.',
    failed_to_save_camera_parameter: 'Lưu thông số máy ảnh không thành công.',
    failed_to_solve_pnp: 'Không thể giải quyết vị trí của camera.',
    finish: 'Hoàn Tất',
    found_checkpoint: 'Dữ liệu kiểm tra điểm được tìm thấy trên thiết bị của bạn. Bạn có muốn khôi phục từ điểm kiểm tra không?',
    getting_plane_height: 'Đang Lấy Độ Cao Mặt Phẳng...',
    hint_adjust_parameters: 'Sử dụng các thông số này để điều chỉnh hình vuông đỏ',
    hint_red_square: 'Vui lòng căn chỉnh mép ngoài của hình vuông đỏ với hình vuông cắt.',
    module_calibration_2w_ir: 'Hiệu chỉnh module hồng ngoại',
    module_calibration_printer: 'Hiệu chỉnh module máy in',
    moving_laser_head: 'Đang di chuyển đầu laser...',
    next: 'Tiếp',
    no_picutre_found: '#846 Thiết bị của bạn không có ảnh nguyên bản nào để hiệu chỉnh. Vui lòng liên hệ hỗ trợ FLUX.',
    perform_autofocus_bb2: 'Vui lòng đi tới bảng điều khiển máy và nhấn AF để thực hiện lấy nét tự động.',
    please_do_camera_calibration_and_focus: {
      beambox: 'Khi hiệu chỉnh mô-đun laser đi-ốt, cần có máy ảnh.\nHãy đảm bảo máy ảnh của máy này đã được hiệu chỉnh.\nVà hãy điều chỉnh nền tảng đến điểm tiêu cự (chiều cao của acrylic đã quay xuống)',
      beamo: 'Khi hiệu chỉnh mô-đun laser đi-ốt, cần có máy ảnh.\nHãy đảm bảo máy ảnh của máy này đã được hiệu chỉnh.\nVà hãy điều chỉnh đầu laser để lấy nét vào vật khắc (chiều cao của acrylic đã quay xuống)',
    },
    please_goto_beambox_first: 'Vui lòng chuyển sang Chế độ Khắc (Beambox) để sử dụng tính năng này.',
    please_place_dark_colored_paper: 'Vui lòng đặt một tờ giấy khổ A4 hoặc Letter màu tối ở giữa khu vực làm việc.',
    please_place_paper: 'Vui lòng đặt một tờ giấy khổ A4 hoặc Letter màu trắng ở góc trái trên của khu vực làm việc.',
    please_place_paper_center: 'Vui lòng đặt một tờ giấy khổ A4 hoặc Letter màu trắng ở giữa khu vực làm việc.',
    please_refocus: {
      beambox: 'Vui lòng điều chỉnh bệ đỡ đến điểm tiêu cự (chiều cao của mặt acrylic úp xuống)',
      beamo: 'Vui lòng điều chỉnh đầu laser để lấy nét vào vật khắc (chiều cao của mặt acrylic úp xuống)',
      beamo_af: 'Vui lòng nhấn đúp nút bên của phụ kiện lấy nét tự động và để đầu dò chạm nhẹ vào vật liệu.',
      hexa: 'Nhấn đúp nút điều chỉnh chiều cao để nâng bàn tổ ong lên và làm cho đầu dò chạm vào vật liệu khắc.',
    },
    preparing_to_take_picture: 'Chuẩn bị để chụp ảnh...',
    promark_help_link: 'https://support.flux3dp.com/hc/en-us/articles/11173605809295',
    put_chessboard: 'Đặt Bàn Cờ',
    put_chessboard_1: 'Vui lòng đi tới bảng điều khiển máy và nhấn AF để thực hiện lấy nét tự động.',
    put_chessboard_2: 'Vui lòng di chuyển giấy bàn cờ hoặc đầu laser cho đến khi toàn bộ bàn cờ được xác nhận nằm trong khung đỏ trong cửa sổ xem trước trực tiếp.',
    put_chessboard_3: 'Nhấp chuột phải để tải xuống hình ảnh bàn cờ ở vị trí hiện tại và kiểm tra độ rõ nét.',
    put_chessboard_bb2_desc_1: 'Vui lòng tải xuống tệp bàn cờ sau đây và in nó trên giấy A4 (bàn cờ in ra phải có các ô kích thước 1x1 cm).',
    put_chessboard_bb2_desc_2: 'Gắn giấy bàn cờ đã in lên một tấm bảng không bị biến dạng, chẳng hạn như acrylic hoặc kính, đảm bảo bàn cờ phẳng, không có nếp gấp hoặc mép bị nhô lên.',
    put_chessboard_bb2_desc_3: 'Đặt bảng với bàn cờ nằm phẳng ở trung tâm khu vực làm việc.',
    put_chessboard_promark_1: 'Điều chỉnh khoảng cách tiêu cự đến điểm lấy nét phù hợp cho ống kính trường.',
    put_chessboard_promark_2: 'Hãy đảm bảo giấy bàn cờ hiển thị rõ ràng mà không bị chói. Nhấn "Tiếp theo" để chụp ảnh.',
    put_chessboard_promark_desc_1: 'Vui lòng sử dụng giấy bàn cờ được cung cấp trong hộp phụ kiện hoặc in mẫu bàn cờ sau trên giấy A4 để hiệu chỉnh máy ảnh.',
    put_chessboard_promark_desc_2: 'Vui lòng đặt bàn cờ phẳng và ở trung tâm trên nền làm việc.',
    put_paper: 'Đặt Giấy',
    put_paper_promark_1: 'Đặt tấm bìa đen từ hộp phụ kiện lên bề mặt làm việc.',
    put_paper_promark_2: 'Điều chỉnh tiêu cự đúng cách, sau đó nhấn "Bắt Đầu Khắc" để tiếp tục khắc.',
    put_paper_skip: 'Nếu bản vẽ hiệu chỉnh không tự động chuyển sang chụp ảnh, hãy nhấn "Bỏ qua" để chụp ảnh.',
    put_paper_step1: 'Vui lòng đặt một tờ giấy màu trắng kích thước A4 hoặc Thư vào giữa khu vực làm việc.',
    put_paper_step2: 'Cố định bốn góc của tờ giấy để đảm bảo nó nằm phẳng.',
    put_paper_step3: 'Nhấp vào "Bắt Đầu Khắc".',
    res_average: 'Trung bình',
    res_excellent: 'Xuất sắc',
    res_poor: 'Kém',
    retake: 'Chụp lại',
    rotation_angle: 'Xoay',
    show_last_config: 'Hiển thị kết quả gần nhất',
    skip: 'Bỏ Qua',
    solve_pnp_step1: 'Vui lòng căn chỉnh các điểm khắc theo số và vị trí mong đợi của từng điểm đánh dấu màu đỏ.',
    solve_pnp_step2: 'Bạn có thể nhấn "Chụp lại" để điều chỉnh lại hoặc điều chỉnh vị trí của các đánh dấu theo cách thủ công.',
    solve_pnp_step3: 'Nếu hình ảnh chụp không khớp với cảnh thực tế, vui lòng phóng to hoặc kéo màn hình để điều chỉnh điểm hiệu chỉnh trên thẻ hiệu chỉnh màu đen vào giữa, sau đó thử "Chụp lại".',
    solve_pnp_title: 'Cân chỉnh điểm đánh dấu',
    start_engrave: 'Bắt Đầu Khắc',
    start_printing: 'Bắt Đầu In Ấn',
    taking_picture: 'Đang chụp ảnh...',
    unable_to_load_camera_parameters: "#851 Không có thông số hiệu chỉnh máy ảnh nào khả dụng trên thiết bị của bạn. Đi tới 'Hiệu chỉnh' > 'Hiệu chỉnh máy ảnh (Nâng cao)' để hoàn tất hiệu chỉnh và nhận thông số.",
    update_firmware_msg1: 'Phần mềm của bạn không hỗ trợ chức năng này. Vui lòng nâng cấp lên phiên bản',
    update_firmware_msg2: 'trở lên để tiếp tục。 (Menu > Máy > [Máy của bạn] > Cập nhật phần mềm)',
    uploading_images: 'Đang tải ảnh lên...',
    use_last_config: 'Sử dụng giá trị hiệu chỉnh gần nhất',
    use_old_camera_parameter: 'Bạn có muốn sử dụng thông số ống kính máy ảnh hiện tại không?',
    with_af: 'Có lấy nét tự động',
    with_prism_lift: 'Với Ador Prism Lift',
    without_af: 'Không có lấy nét tự động',
    without_prism_lift: 'Không có Ador Prism Lift',
    x_ratio: 'Tỷ lệ X',
    y_ratio: 'Tỷ lệ Y',
    zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
  },
  camera_data_backup: {
    download_success: 'Dữ liệu máy ảnh đã được tải xuống thành công.',
    downloading_data: 'Đang tải dữ liệu...',
    estimated_time_left: 'Thời gian còn lại ước tính:',
    folder_not_exists: 'Thư mục đã chọn không tồn tại.',
    incorrect_folder: 'Không thể tải lên dữ liệu hiệu chuẩn. Vui lòng kiểm tra xem thư mục bạn đã chọn có đúng không.',
    no_picture_found: 'Không tìm thấy hình ảnh trong máy.',
    title: 'Sao lưu dữ liệu máy ảnh',
    upload_success: 'Dữ liệu máy ảnh đã được tải lên thành công.',
    uploading_data: 'Đang tải lên dữ liệu...',
  },
  caption: {
    connectionTimeout: 'Hết thời gian kết nối',
  },
  change_logs: {
    added: 'Đã thêm:',
    change_log: 'Nhật ký thay đổi:',
    changed: 'Đã thay đổi:',
    fixed: 'Đã sửa:',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    see_older_version: 'Xem phiên bản cũ',
  },
  code_generator: {
    barcode: 'Mã vạch',
    qr_code: 'Mã QR',
  },
  curve_engraving: {
    '921': 'Không thể lấy nét tự động.',
    '922': 'Không thể thực hiện đo bằng ánh sáng đỏ.',
    amount: 'Số lượng',
    apply_arkwork: 'Áp dụng tác phẩm nghệ thuật lên đường cong',
    apply_camera: 'Áp dụng hình ảnh camera lên đường cong',
    click_to_select_point: 'Nhấp để chọn hoặc bỏ chọn các điểm để đo lại.',
    coloumns: 'Cột',
    column_gap: 'Khoảng cách cột',
    err_object_over_range: 'Đối tượng vượt quá phạm vi đo lường.',
    failed_to_take_reference: 'Không thể lấy tham chiếu',
    gap: 'Khoảng cách',
    help_center_url: 'https://support.flux3dp.com/hc/en-us/articles/10364060644495',
    measure_audofocus_area: 'Đo khu vực lấy nét tự động',
    preview_3d_curve: 'Xem trước khắc đường cong',
    remeasure: 'Đo lại',
    remeasuring_points: 'Đang đo lại các điểm...',
    reselect_area: 'Chọn lại Khu vực',
    row_gap: 'Khoảng cách hàng',
    rows: 'Hàng',
    set_object_height: 'Đặt chiều cao đối tượng',
    set_object_height_desc: 'Đo độ dày tối đa của đối tượng.',
    start_autofocus: 'Bắt đầu Lấy nét tự động',
    starting_measurement: 'Bắt đầu đo...',
    sure_to_delete: 'Bạn có muốn xóa dữ liệu tiêu điểm của khắc đường cong không?',
    take_reference: 'Lấy tham chiếu',
    take_reference_desc: 'Vui lòng di chuyển đầu laser đến điểm cao nhất của đối tượng, hạ đầu dò tiêu điểm xuống và nhấp vào "Xác nhận" để lấy nét.',
  },
  device: {
    abort: 'Hủy',
    aborted: 'Đã hủy',
    aborting: 'Đang hủy',
    busy: 'Bận',
    cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
    cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
    cartridge_serial_number: 'Ink cartridge serial number',
    close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
    completed: 'Đã hoàn thành',
    completing: 'Hoàn tất',
    deviceList: 'Danh sách thiết bị',
    disable: 'Tắt',
    disconnectedError: {
      caption: 'Máy bị ngắt kết nối',
      message: 'Vui lòng kiểm tra kết nối mạng của %s',
    },
    enable: 'Bật',
    firmware_version: 'Phiên bản firmware',
    ink_color: 'Ink color',
    ink_level: 'Ink level',
    ink_type: 'Ink type',
    IP: 'IP',
    model_name: 'Tên kiểu máy',
    occupied: 'Đang sử dụng',
    pause: 'Tạm dừng',
    paused: 'Đã tạm dừng',
    pausedFromError: 'Tạm dừng do lỗi',
    pausing: 'Đang tạm dừng',
    please_wait: 'Vui lòng chờ...',
    preparing: 'Đang chuẩn bị',
    processing: 'Đang xử lý',
    quit: 'Thoát',
    ready: 'Sẵn sàng',
    reset: 'Khởi động lại',
    resuming: 'Đang tiếp tục',
    retry: 'Thử lại',
    running: 'Đang hoạt động',
    scanning: 'Đang quét',
    select: 'Chọn',
    select_printer: 'Chọn máy in',
    serial_number: 'Số serial',
    start: 'Bắt đầu',
    starting: 'Đang khởi động',
    status: 'Trạng thái',
    submodule_type: 'Module',
    toolhead_change: 'Thay đổi đầu công cụ',
    unknown: 'Không xác định',
    uploading: 'Đang tải lên',
    UUID: 'UUID',
  },
  device_selection: {
    no_beambox: '#801 Chúng tôi không tìm thấy máy của bạn trên mạng.\nVui lòng làm theo <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">hướng dẫn</a> để khắc phục sự cố kết nối.',
    no_device_web: "#801 Vui lòng kiểm tra tình trạng của máy bạn, hoặc nhấn vào 'Thiết lập máy in' bên dưới để cài đặt máy.",
    select_usb_device: 'Chọn thiết bị USB',
  },
  editor: {
    exposure: 'Xem trước phơi sáng',
    opacity: 'Độ mờ xem trước',
    prespray_area: 'Vùng làm ẩm ban đầu',
  },
  error_pages: {
    screen_size: 'Lưu ý rằng Beam Studio có thể không hoạt động tối ưu trên thiết bị của bạn. Để trải nghiệm tốt nhất, nên sử dụng thiết bị có độ rộng màn hình tối thiểu 1024 pixel.',
  },
  flux_id_login: {
    connection_fail: '#847 Lỗi kết nối dịch vụ thành viên FLUX.',
    email: 'Email',
    flux_plus: {
      access_monotype_feature: 'Bạn không có bổ trợ Phông chữ Monotype.',
      access_monotype_feature_note: 'Bạn cần có tư cách thành viên FLUX+ Pro hoặc bổ trợ Phông chữ Monotype để truy cập tính năng này.',
      access_plus_feature_1: 'Bạn đang truy cập một',
      access_plus_feature_2: 'tính năng.',
      access_plus_feature_note: 'Bạn cần có tư cách thành viên FLUX+ để truy cập tính năng này.',
      ai_credit_tooltip: 'Để xóa nền AI',
      explore_plans: 'Khám phá các gói FLUX+',
      features: {
        ai_bg_removal: 'Xóa nền bằng AI',
        boxgen: 'Tạo hình hộp 3D',
        dmkt: '1000+ mẫu thiết kế',
        monotype: '250+ phông chữ chuyên nghiệp',
        my_cloud: 'Lưu trữ đám mây của tôi',
      },
      flux_credit_tooltip: 'Để tải tệp thiết kế và xóa nền AI',
      get_addon: 'Nhận bổ trợ',
      goto_member_center: 'Đi đến Trung tâm Thành viên',
      learn_more: 'Tìm hiểu thêm',
      member_center_url: 'https://member.flux3dp.com/en-US/subscription',
      subscribe_now: 'Đăng ký ngay',
      thank_you: 'Cảm ơn vì đã là thành viên quý giá!',
      website_url: 'https://flux3dp.com/subscription',
    },
    forget_password: 'Quên mật khẩu?',
    incorrect: 'Email hoặc mật khẩu không chính xác',
    login: 'Đăng nhập',
    login_success: 'Đăng nhập thành công.',
    lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
    new_to_flux: 'Mới dùng FLUX? Đăng ký tài khoản',
    not_verified: 'Email chưa được xác minh',
    offline: 'Làm việc ngoại tuyến',
    password: 'Mật khẩu',
    register: 'Tạo tài khoản FLUX',
    remember_me: 'Nhớ tôi',
    signup_url: 'https://id.flux3dp.com/user/login#up',
    unlock_shape_library: 'Đăng nhập để mở khóa cơ sở dữ liệu hình dạng.',
    work_offline: 'Làm việc ngoại tuyến',
  },
  framing: {
    area_check: 'Kiểm tra khu vực',
    areacheck_desc: 'Đảm bảo khu vực làm việc an toàn bằng cách xem trước hộp giới hạn của đối tượng và vùng gia tốc của đầu laser.',
    calculating_task: 'Đang tính toán nhiệm vụ...',
    framing: 'Đóng khung',
    framing_desc: 'Xem trước hộp giới hạn của đối tượng.',
    hull: 'Vỏ',
    hull_desc: 'Xem trước hình dạng bám sát thiết kế, giống như dây cao su bao quanh đối tượng.',
    low_laser: 'Laser yếu',
    low_laser_desc: 'Đặt giá trị laser công suất thấp cho nhiệm vụ đóng khung.',
    rotate_axis: 'Trục xoay',
    rotateaxis_desc: 'Xem trước trung tâm trục xoay, trục không di chuyển.',
    rotation_frame_warning: 'Bản xem trước khung sẽ xoay một vòng. Tránh dừng giữa chừng để tránh lệch vị trí đánh dấu.',
    rotation_framing_desc: 'Xem trước phạm vi xoay đầy đủ, trục xoay theo xem trước.',
    start_task: 'Bắt Đầu Nhiệm Vụ',
    start_task_description: 'Sau khi hoàn thành xem trước khung, nhấp vào "Bắt Đầu Nhiệm Vụ" để gửi công việc.',
  },
  general: {
    choose_folder: 'Chọn Thư mục',
    processing: 'Đang xử lý...',
  },
  generic_error: {
    OPERATION_ERROR: '[Xung đột trạng thái] Xung đột trạng thái đã xảy ra, vui lòng thử lại hành động.',
    SUBSYSTEM_ERROR: '[Lỗi hệ thống phụ] Lỗi khi phần mềm máy thực hiện nhiệm vụ. Vui lòng khởi động lại máy.',
    UNKNOWN_COMMAND: '[Lệnh không xác định] Vui lòng cập nhật phần mềm thiết bị',
    UNKNOWN_ERROR: '[Lỗi không xác định] Đã xảy ra lỗi không xác định. Vui lòng khởi động lại Beam Studio và máy.',
  },
  global: {
    apply: 'Áp dụng',
    back: 'Quay lại',
    cancel: 'Hủy',
    editing: {
      redo: 'Làm lại',
      reset: 'Đặt lại',
      undo: 'Hoàn tác',
      zoom_in: 'Phóng to',
      zoom_out: 'Thu nhỏ',
    },
    mode_conflict: 'Tùy chọn này không khả dụng trong chế độ hiện tại.',
    ok: 'OK',
    save: 'Lưu',
    stop: 'Dừng',
  },
  image_edit_panel: {
    eraser: {
      brush_size: 'Kích thước Bút',
      description: 'Nhấp hoặc kéo để xóa thủ công các khu vực không mong muốn.',
      title: 'Tẩy',
    },
    magic_wand: {
      description: 'Chọn và xóa các khu vực liền kề dựa trên sự tương đồng về màu sắc.',
      title: 'Đũa Phép',
      tolerance: 'Độ Chịu Lệch',
    },
    title: 'Chỉnh sửa Hình ảnh',
  },
  initialize: {
    back: 'Quay lại',
    cancel: 'Hủy',
    confirm: 'Xác nhận',
    connect: 'Kết nối',
    connect_ethernet: {
      title: 'Kết nối trực tiếp',
      tutorial1: '1. Kết nối máy với máy tính của bạn bằng cáp Ethernet.',
      tutorial2_1: '2. Làm theo ',
      tutorial2_2: 'để biến máy tính của bạn thành một bộ định tuyến.',
      tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
      tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
      tutorial2_a_text: 'hướng dẫn này',
      tutorial3: '3. Nhấn Tiếp theo.',
    },
    connect_machine_ip: {
      alert: {
        swiftray_connection_error: 'Không thể kết nối với máy chủ. Vui lòng khởi động lại Beam Studio và thử lại.',
      },
      check_camera: 'Đang kiểm tra tính khả dụng camera',
      check_connection: 'Đang kiểm tra kết nối máy',
      check_firmware: 'Đang kiểm tra phiên bản firmware',
      check_ip: 'Đang kiểm tra tính khả dụng IP',
      check_swiftray_connection: 'Đang kiểm tra kết nối với máy chủ',
      check_swiftray_connection_unreachable: 'không thể kết nối máy chủ',
      check_usb: 'Kiểm tra kết nối USB',
      enter_ip: 'Nhập địa chỉ IP của máy',
      finish_setting: 'Bắt đầu tạo!',
      invalid_format: 'Định dạng không hợp lệ',
      invalid_ip: 'IP không hợp lệ: ',
      promark_hint: 'Nếu bạn liên tục không kết nối được qua USB, vui lòng tham khảo <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/11318820440591">bài viết tại Trung tâm trợ giúp</a>.',
      retry: 'Thử lại',
      starts_with_169254: 'Bắt đầu bằng 169.254',
      succeeded_message: 'Kết nối thành công 🎉',
      unreachable: 'Không thể truy cập IP',
    },
    connect_usb: {
      connect_camera: 'Kết nối máy ảnh của máy với máy tính của bạn bằng cáp USB.',
      title: 'Kết nối USB',
      title_sub: '(HEXA & Ador Chỉ)',
      turn_off_machine: 'Tắt máy.',
      turn_on_machine: 'Bật máy.',
      tutorial1: 'Kết nối máy với máy tính của bạn bằng cáp USB.',
      tutorial2: "Nhấn 'Tiếp theo'.",
      wait_for_turning_on: "Nhấn 'Tiếp theo' sau khi quá trình khởi động hoàn tất và bạn đã vào màn hình chính.",
    },
    connect_wifi: {
      title: 'Đang kết nối với Wi-Fi',
      tutorial1: '1. Đi đến Bảng điều khiển cảm ứng > Nhấp vào "Mạng" > "Kết nối với WiFi".',
      tutorial1_ador: '1. Đi đến Bảng điều khiển cảm ứng > Nhấp vào "MÁY" > Nhấp vào "Mạng" > "Kết nối với WiFi".',
      tutorial2: '2. Chọn và kết nối Wi-Fi ưa thích của bạn.',
      what_if_1: 'Nếu tôi không thấy Wi-Fi của mình thì sao?',
      what_if_1_content: '1. Chế độ mã hóa Wi-Fi nên là WPA2 hoặc không có mật khẩu.\n2. Chế độ mã hóa có thể được đặt trong giao diện quản trị bộ định tuyến Wi-Fi. Nếu bộ định tuyến không hỗ trợ WPA2 và bạn cần trợ giúp để chọn đúng bộ định tuyến, vui lòng liên hệ với bộ phận hỗ trợ FLUX.',
      what_if_2: 'Nếu tôi không thấy bất kỳ Wi-Fi nào thì sao?',
      what_if_2_content: '1. Đảm bảo rằng đầu nối Wi-Fi được cắm đầy đủ.\n2. Nếu không có Địa chỉ MAC của mạng không dây trên màn hình cảm ứng, vui lòng liên hệ với bộ phận hỗ trợ FLUX.\n3. Kênh Wi-Fi nên là 2.4Ghz (5Ghz không được hỗ trợ).',
    },
    connect_wired: {
      title: 'Kết nối với Mạng có dây',
      tutorial1: '1. Kết nối máy với bộ định tuyến của bạn.',
      tutorial2: '2. Nhấn "Mạng" để lấy IP mạng có dây.',
      tutorial2_ador: '2. Nhấn "MÁY" > "Mạng" để lấy IP mạng có dây.',
      what_if_1: 'Nếu IP trống thì sao?',
      what_if_1_content: '1. Đảm bảo cáp Ethernet được cắm đầy đủ.\n2. Nếu không có Địa chỉ MAC của mạng có dây trên màn hình cảm ứng, vui lòng liên hệ với bộ phận hỗ trợ FLUX.',
      what_if_2: 'Nếu IP bắt đầu bằng 169 thì sao?',
      what_if_2_content: '1. Nếu địa chỉ IP bắt đầu bằng 169.254, đó nên là vấn đề cài đặt DHCP, vui lòng liên hệ với nhà cung cấp dịch vụ internet của bạn để được hỗ trợ thêm.\n2. Nếu máy tính của bạn kết nối internet trực tiếp bằng PPPoE, vui lòng thay đổi sang sử dụng bộ định tuyến để kết nối bằng PPPoE, và bật tính năng DHCP trong bộ định tuyến.',
    },
    connecting: 'Đang kết nối...',
    connection_types: {
      ether2ether: 'Kết nối trực tiếp',
      usb: 'Kết nối USB',
      wifi: 'Wi-Fi',
      wired: 'Mạng có dây',
    },
    next: 'Tiếp theo',
    no_machine: 'Tôi không có máy hiện tại.',
    promark: {
      configuration_confirmation: 'Điều này đảm bảo rằng Promark của bạn được cấu hình đúng cách để đạt hiệu quả và độ chính xác tối ưu.',
      or_complete_later: `Hoặc bỏ qua bước này và hoàn thành Cài đặt Promark sau tại:
    Máy móc > "Tên Promark" > Cài đặt Promark`,
      qc_instructions: 'Điền vào các thông số nằm ở mặt sau của thẻ "QC Pass"',
      select_laser_source: 'Chọn Promark của bạn',
      select_workarea: 'Chọn Khu vực Làm việc',
      settings: 'Cài đặt Promark',
    },
    retry: 'Thử lại',
    select_beambox: 'Chọn Beambox của bạn',
    select_connection_type: 'Bạn muốn kết nối như thế nào?',
    select_language: 'Chọn ngôn ngữ',
    select_machine_type: 'Chọn loại máy của bạn',
    setting_completed: {
      back: 'Quay lại',
      great: 'Chào mừng đến với Beam Studio',
      ok: 'BẮT ĐẦU TẠO',
      setup_later: 'Bạn luôn có thể thiết lập máy của mình từ Thanh menu > "Máy" > "Thiết lập máy"',
      start: 'Bắt đầu',
    },
    skip: 'Bỏ qua',
    start: 'Bắt đầu',
  },
  input_machine_password: {
    connect: 'KẾT NỐI',
    password: 'Mật khẩu',
    require_password: '"%s" yêu cầu mật khẩu',
  },
  insecure_websocket: {
    extension_detected: 'Phát hiện Phần mở rộng Beam Studio Connect',
    extension_detected_description: "Chúng tôi đã phát hiện bạn đã cài đặt phần mở rộng Beam Studio Connect. Nhấn vào 'Xác nhận' để chuyển hướng đến HTTPS, hoặc nhấn vào 'Hủy' để tiếp tục sử dụng HTTP.",
    extension_not_deteced: 'Không thể phát hiện Phần mở rộng Beam Studio Connect',
    extension_not_deteced_description: "Để sử dụng HTTPS, vui lòng nhấp vào 'Xác nhận' để cài đặt tiện ích mở rộng Beam Studio Connect. Sau khi cài đặt tiện ích mở rộng, vui lòng làm mới trang để kích hoạt nó.<br/>Nếu không, hãy nhấp vào liên kết bên dưới để xem cách sử dụng HTTP trên Chrome.",
    unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Liên kết</a>',
  },
  layer_module: {
    general_laser: 'Laser',
    laser_2w_infrared: 'Laser hồng ngoại 2W',
    laser_10w_diode: 'Laser diode 10W',
    laser_20w_diode: 'Laser diode 20W',
    non_working_area: 'Khu Vực Không Làm Việc',
    none: 'None',
    notification: {
      convertFromLaserModuleMsg: 'Lưu ý rằng nếu hoàn tất thao tác này, cài đặt của lớp laser sẽ bị xóa và được đặt theo lớp hiện tại.',
      convertFromLaserModuleTitle: 'Bạn có muốn chuyển đổi Module Laser thành Module In ấn?',
      convertFromPrintingModuleMsg: 'Vui lòng lưu ý rằng nếu bạn hoàn tất thao tác này, các cài đặt màu của lớp in ấn sẽ bị xóa và được đặt theo lớp hiện tại.',
      convertFromPrintingModuleTitle: 'Bạn có muốn chuyển đổi mô-đun In ấn thành mô-đun Laser?',
      importedDocumentContainsPrinting: 'Tài liệu chứa lớp in ấn, bạn có muốn chuyển sang Không gian Làm việc Ador?',
      performIRCaliMsg: 'Nhấp “Xác nhận” để thực hiện hiệu chuẩn, hoặc truy cập hiệu chuẩn qua menu trên cùng. <br />(Máy > [Tên máy của bạn] > Hiệu chuẩn Module Hồng ngoại)',
      performIRCaliTitle: 'Thực hiện hiệu chuẩn Module Hồng ngoại',
      performPrintingCaliMsg: 'Nhấp “Xác nhận” để thực hiện hiệu chuẩn, hoặc truy cập hiệu chuẩn qua menu trên cùng.<br /> (Máy > [Tên máy của bạn] > Hiệu chuẩn Module In ấn)',
      performPrintingCaliTitle: 'Thực hiện hiệu chuẩn Module In ấn',
      printingLayersCoverted: 'Các lớp in ấn đã được chuyển đổi thành các lớp laser.',
    },
    printing: 'In ấn',
    unknown: 'Unknown Module',
  },
  machine_status: {
    '-17': 'Chế độ Cartridge IO',
    '-10': 'Chế độ bảo trì',
    '-2': 'Đang quét',
    '-1': 'Đang bảo trì',
    '0': 'Nghỉ',
    '1': 'Khởi động',
    '2': 'ST_TRANSFORM',
    '4': 'Khởi động',
    '6': 'Tiếp tục',
    '16': 'Đang hoạt động',
    '18': 'Tiếp tục',
    '32': 'Tạm dừng',
    '36': 'Tạm dừng',
    '38': 'Đang tạm dừng',
    '48': 'Tạm dừng',
    '50': 'Đang tạm dừng',
    '64': 'Hoàn thành',
    '66': 'Đang hoàn thành',
    '68': 'Đang chuẩn bị',
    '128': 'Đã hủy',
    '256': 'Cảnh báo',
    '512': 'Lỗi nghiêm trọng',
    UNKNOWN: 'Không xác định',
  },
  material_test_generator: {
    block_settings: 'Cài đặt khối',
    columns: 'Cột',
    count: 'Số lượng',
    cut: 'Cắt',
    engrave: 'Khắc',
    export: 'Xuất',
    max: 'Tối đa',
    min: 'Tối thiểu',
    parameter: 'Tham số',
    preview: 'Xem trước',
    rows: 'Hàng',
    size: 'Kích thước (CxR)',
    spacing: 'Khoảng cách',
    table_settings: 'Cài đặt bảng',
    text_settings: 'Cài đặt văn bản',
    title: 'Trình tạo thử nghiệm vật liệu',
  },
  menu: {
    inches: 'Inches',
    mm: 'mm',
  },
  message: {
    auth_error: '#820 Lỗi xác thực: Vui lòng cập nhật Beam Studio và firmware máy lên phiên bản mới nhất.',
    authenticating: 'Đang xác thực...',
    camera: {
      abort_preview: 'Hủy bỏ',
      camera_cable_unstable: 'Phát hiện camera đang truyền ảnh không ổn định. Xem trước vẫn có thể thực hiện bình thường, nhưng có thể gặp vấn đề xem trước chậm hoặc hết thời gian chờ.',
      continue_preview: 'Tiếp tục',
      fail_to_transmit_image: '#845 Đã xảy ra lỗi khi truyền ảnh. Vui lòng khởi động lại máy hoặc Beam Studio. Nếu lỗi này vẫn xảy ra, vui lòng làm theo <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">hướng dẫn này</a>.',
      ws_closed_unexpectly: '#844 Kết nối với camera máy đã bị đóng bất ngờ. Nếu lỗi này vẫn xảy ra, vui lòng làm theo <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">hướng dẫn này</a>.',
    },
    cancelling: 'Hủy bỏ...',
    cant_connect_to_device: '#827 Không thể kết nối với máy, vui lòng kiểm tra kết nối của bạn',
    connected: 'Đã kết nối',
    connecting: 'Đang kết nối...',
    connectingCamera: 'Đang kết nối camera...',
    connectingMachine: 'Đang kết nối %s...',
    connectionTimeout: '#805 Hết thời gian kết nối thiết bị. Vui lòng kiểm tra kết nối mạng và đèn báo Wi-Fi của máy.',
    device_blocked: {
      caption: 'Số Serial Không Hợp Lệ',
      offline: 'Số serial của thiết bị bị giới hạn chỉ sử dụng ngoại tuyến vì dường như đã bị vô hiệu hóa. Vui lòng cung cấp số serial của thiết bị (nằm ở mặt sau của thiết bị) cho nhà bán lẻ của bạn và yêu cầu họ liên hệ với support@flux3dp.com để kích hoạt quyền sử dụng trực tuyến. Nếu bạn muốn sử dụng thiết bị ngoại tuyến, vui lòng liên hệ trực tiếp với support@flux3dp.com.',
      online: 'Số serial của thiết bị dường như đã bị vô hiệu hóa. Vui lòng cung cấp số serial của thiết bị (nằm ở mặt sau của thiết bị) cho nhà bán lẻ của bạn và yêu cầu họ liên hệ với support@flux3dp.com để kích hoạt quyền sử dụng thiết bị.',
    },
    device_busy: {
      caption: 'Máy bận',
      message: 'Máy đang thực hiện tác vụ khác, vui lòng thử lại sau. Nếu máy ngừng hoạt động, vui lòng khởi động lại.',
    },
    device_is_used: 'Máy đang được sử dụng, bạn có muốn hủy tác vụ hiện tại không?',
    device_not_found: {
      caption: 'Không tìm thấy máy mặc định',
      message: '#812 Vui lòng kiểm tra đèn báo Wi-Fi của máy',
    },
    disconnected: 'Kết nối không ổn định, vui lòng kiểm tra kết nối thiết bị và thử lại sau',
    endingLineCheckMode: 'Đang thoát chế độ kiểm tra đường...',
    endingRawMode: 'Đang thoát chế độ thô...',
    enteringLineCheckMode: 'Đang vào chế độ kiểm tra đường...',
    enteringRawMode: 'Đang vào chế độ thô...',
    enteringRedLaserMeasureMode: 'Đang vào chế độ đo laser đỏ...',
    exitingRotaryMode: 'Đang thoát chế độ xoay...',
    getProbePosition: 'Đang lấy vị trí đầu dò...',
    gettingLaserSpeed: 'Đang đọc tốc độ đầu laser...',
    homing: 'Đang hoàn vị...',
    need_password: 'Cần Mật khẩu để Kết nối với Máy',
    please_enter_dpi: 'Vui lòng nhập Đơn vị của tệp tin (tính bằng mm)',
    preview: {
      adjust: 'Điều chỉnh',
      adjust_height_tooltip: 'Nhấp vào hộp kiểm để bật chỉnh sửa.',
      already_performed_auto_focus: 'Bạn đã thực hiện lấy nét tự động, có sử dụng giá trị hiện tại không?',
      auto_focus: 'Lấy nét tự động',
      auto_focus_instruction: 'Vui lòng di chuyển đầu mô-đun laser phía trên vật thể, và làm theo hướng dẫn hoạt ảnh để nhấn AF để lấy nét.',
      camera_preview: 'Xem trước Camera',
      enter_manually: 'Nhập thủ công',
      please_enter_height: 'Vui lòng nhập chiều cao của vật thể. Để chụp ảnh camera chính xác.',
    },
    promark_disconnected: '#850 Kết nối thiết bị bị gián đoạn, vui lòng kiểm tra trạng thái kết nối của thiết bị.',
    redLaserTakingReference: 'Đang lấy tham chiếu...',
    retrievingCameraOffset: 'Đang đọc bù camera...',
    settingLaserSpeed: 'Đang đặt tốc độ đầu laser...',
    swiftray_disconnected: 'Không thể kết nối với backend, đang thử kết nối lại.',
    swiftray_reconnected: 'Backend đã được kết nối lại, vui lòng thử gửi lại công việc.',
    time_remaining: 'Thời gian còn lại:',
    tryingToConenctMachine: 'Đang thử kết nối với máy...',
    turningOffAirPump: 'Đang tắt bơm khí...',
    turningOffFan: 'Đang tắt quạt...',
    unable_to_find_machine: 'Không tìm thấy máy ',
    unable_to_start: '#830 Không thể bắt đầu tác vụ. Vui lòng thử lại. Nếu điều này xảy ra lại, vui lòng liên hệ với chúng tôi để báo cáo lỗi:',
    unavailableWorkarea: '#804 Khu vực làm việc hiện tại vượt quá khu vực làm việc của máy này. Vui lòng kiểm tra khu vực làm việc của máy đã chọn hoặc đặt lại khu vực làm việc.',
    unknown_device: '#826 Không thể kết nối với máy, vui lòng đảm bảo USB được cắm vào máy',
    unknown_error: '#821 Ứng dụng gặp lỗi không xác định, vui lòng sử dụng Trợ giúp > Menu > Báo cáo lỗi.',
    unsupport_osx_version: 'Phiên bản macOS %s có thể không hỗ trợ một số chức năng. Vui lòng nâng cấp lên macOS 11+.',
    unsupport_win_version: 'Phiên bản HĐH %s có thể không hỗ trợ một số chức năng. Vui lòng nâng cấp lên phiên bản mới nhất.',
    unsupported_example_file: 'Tệp ví dụ đã chọn không được hỗ trợ bởi khu vực làm việc hiện tại.',
    uploading_fcode: 'Đang tải lên FCode',
    usb_unplugged: 'Kết nối USB bị mất. Vui lòng kiểm tra kết nối USB của bạn',
    wrong_swiftray_version_message: 'Phiên bản backend không đúng (phiên bản: {version}). Vui lòng kiểm tra xem có nhiều phiên bản Beam Studio đang chạy không và đóng bất kỳ phiên bản thừa nào trước khi thử lại.',
    wrong_swiftray_version_title: 'Phiên bản backend không đúng',
  },
  monitor: {
    ask_reconnect: 'Kết nối với thiết bị đã bị ngắt. Bạn có muốn kết nối lại không?',
    bug_report: 'Báo cáo lỗi',
    camera: 'Camera',
    cancel: 'Hủy',
    confirmFileDelete: 'Bạn có chắc chắn muốn xóa tệp này không?',
    connecting: 'Đang kết nối, vui lòng đợi...',
    DEVICE_ERROR: 'Đã xảy ra lỗi\nVui lòng khởi động lại máy',
    download: 'Tải xuống',
    extensionNotSupported: 'Định dạng tệp không được hỗ trợ',
    fileExistContinue: 'Tệp đã tồn tại, bạn có muốn ghi đè không?',
    forceStop: 'Bạn có muốn hủy công việc hiện tại không?',
    go: 'Bắt đầu',
    HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: 'Cảm biến luồng khí bất thường',
    HARDWARE_ERROR_BOTTOM_OPENED: 'Vui lòng đóng đáy để tiếp tục.',
    HARDWARE_ERROR_DOOR_OPENED: '#901 Đóng cửa để tiếp tục.',
    HARDWARE_ERROR_DRAWER_OPENED: 'Ngăn kéo đang mở',
    HARDWARE_ERROR_FIRE_DETECTED: 'Cảm biến lửa bất thường',
    HARDWARE_ERROR_HEADTYPE_MISMATCH: 'Phát hiện module không khớp. Vui lòng lắp đúng module để tiếp tục.',
    HARDWARE_ERROR_HEADTYPE_NONE: 'Không phát hiện module. Vui lòng đảm bảo module được lắp đúng để tiếp tục.',
    HARDWARE_ERROR_HEADTYPE_UNKNOWN: 'Phát hiện module không xác định. Vui lòng lắp đúng module để tiếp tục.',
    HARDWARE_ERROR_HOMING_PULLOFF_FAILED: 'Lỗi kéo về vị trí gốc',
    HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Lỗi nghiêm trọng: Bo mạch chủ ngoại tuyến. Vui lòng liên hệ hỗ trợ FLUX.',
    HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Quá nóng. Vui lòng đợi vài phút.',
    HARDWARE_ERROR_PLATFORM_HOMING_FAILED: 'Lỗi khởi động trục Z',
    HARDWARE_ERROR_PRINTER_NO_RESPONSE: 'Module máy in không phản hồi.',
    HARDWARE_ERROR_PROBE_SHOWED: 'Vui lòng thu hồi đầu dò.',
    HARDWARE_ERROR_PUMP_ERROR: '#900 Vui lòng kiểm tra bể nước của bạn.',
    HARDWARE_ERROR_ROTARY_NOT_DETECTED: 'Không phát hiện module quay',
    HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Lỗi nghiêm trọng: Hệ thống phụ không phản hồi. Vui lòng liên hệ hỗ trợ FLUX.',
    HARDWARE_FAILURE: 'Đã xảy ra lỗi\nVui lòng khởi động lại máy',
    hour: 'giờ',
    left: 'trái',
    MAINBOARD_OFFLINE: '#905 Lỗi kết nối với bo mạch chủ.\nVui lòng khởi động lại máy.',
    minute: 'phút',
    monitor: 'GIÁM SÁT',
    NO_RESPONSE: '#905 Lỗi kết nối với bo mạch chủ.\nVui lòng khởi động lại máy.',
    pause: 'Tạm dừng',
    prepareRelocate: 'Đang chuẩn bị di chuyển',
    processing: 'Đang xử lý',
    record: 'Ghi lại',
    relocate: 'Di chuyển',
    RESOURCE_BUSY: 'Máy đang bận\nNếu không đang chạy, vui lòng khởi động lại máy',
    resume: 'Tiếp tục',
    savingPreview: 'Đang tạo ảnh xem trước',
    second: 'giây',
    start: 'Bắt đầu',
    stop: 'Dừng',
    SUBSYSTEM_ERROR: '#402 Lỗi nghiêm trọng: Hệ thống con không phản hồi. Vui lòng liên hệ hỗ trợ FLUX.',
    task: {
      BEAMBOX: 'Khắc laser',
      'N/A': 'Chế độ tự do',
    },
    taskTab: 'Nhiệm vụ',
    temperature: 'Nhiệt độ',
    upload: 'Tải lên',
    USER_OPERATION: 'Vui lòng tuân theo hướng dẫn trên bảng điều khiển của thiết bị để tiếp tục.',
    USER_OPERATION_CHANGE_CARTRIDGE: 'Vui lòng chèn hộp mực chính xác để tiếp tục.',
    USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Vui lòng chèn hộp mực để tiếp tục.',
    USER_OPERATION_CHANGE_TOOLHEAD: 'Vui lòng lắp đúng module để tiếp tục.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: 'Phát hiện module không khớp. Vui lòng lắp đúng module để tiếp tục.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: 'Không phát hiện module. Vui lòng đảm bảo module được lắp đúng để tiếp tục.',
    USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: 'Phát hiện module không xác định. Vui lòng lắp đúng module để tiếp tục.',
    USER_OPERATION_REMOVE_CARTRIDGE: 'Vui lòng tháo hộp mực để tiếp tục.',
    USER_OPERATION_ROTARY_PAUSE: 'Vui lòng chuyển sang module quay.',
  },
  my_cloud: {
    action: {
      confirmFileDelete: 'Bạn có chắc chắn muốn xóa tệp tin này không? Hành động này không thể hoàn tác.',
      delete: 'Xóa',
      download: 'Tải xuống',
      duplicate: 'Nhân bản',
      open: 'Mở',
      rename: 'Đổi tên',
    },
    file_limit: 'Tệp miễn phí',
    loading_file: 'Đang tải...',
    no_file_subtitle: 'Vào Menu > "Tệp" > "Lưu lên đám mây"',
    no_file_title: 'Lưu tệp lên Đám mây của tôi để bắt đầu.',
    save_file: {
      choose_action: 'Lưu tệp:',
      input_file_name: 'Nhập tên tệp:',
      invalid_char: 'Ký tự không hợp lệ:',
      save: 'Lưu',
      save_new: 'Lưu thành tệp mới',
      storage_limit_exceeded: 'Bộ nhớ đám mây của bạn đã đạt giới hạn. Vui lòng xóa các tệp không cần thiết trước khi lưu các tệp mới.',
    },
    sort: {
      a_to_z: 'Tên: A-Z',
      most_recent: 'Gần đây nhất',
      oldest: 'Cũ nhất',
      z_to_a: 'Tên: Z-A',
    },
    title: 'Đám mây của tôi',
    upgrade: 'Nâng cấp',
  },
  noun_project_panel: {
    clear: 'Xóa',
    elements: 'Các yếu tố',
    enjoy_shape_library: 'Tận hưởng thư viện hình dạng',
    export_svg_title: 'Không thể Xuất SVG',
    export_svg_warning: 'Dự án này chứa các đối tượng được bảo vệ bởi luật sở hữu trí tuệ. Vì vậy Beam Studio sẽ tự động loại trừ các đối tượng này trong quá trình xuất. Bạn vẫn có thể lưu dự án trong định dạng Beam Studio Scene (.beam) để giữ toàn bộ dữ liệu. Bạn vẫn muốn xuất?',
    learn_more: 'Tìm hiểu thêm',
    login_first: 'Đăng nhập để mở khóa thư viện hình dạng',
    recent: 'Gần đây',
    search: 'Tìm kiếm',
    shapes: 'Hình dạng',
  },
  pass_through: {
    export: 'Xuất sang Khu Vực Làm Việc',
    exporting: 'Đang xuất',
    guide_mark: 'Dấu Hướng Dẫn',
    guide_mark_desc: 'Dấu hướng dẫn sẽ được khắc làm điểm tham chiếu để căn chỉnh tác phẩm nghệ thuật.',
    guide_mark_length: 'Chiều dài:',
    guide_mark_x: 'Tọa Độ X:',
    height_desc: 'Cài đặt chiều cao của mỗi phần khu vực làm việc.',
    help_links: {
      ado1: 'https://support.flux3dp.com/hc/en-us/articles/10140002160399',
      fbb2: 'https://support.flux3dp.com/hc/en-us/articles/11570021253263',
    },
    help_text: 'Làm thế nào để thiết lập truyền qua cho %(model)s?',
    object_length: 'Chiều Dài Đối Tượng',
    ref_layer: 'Lớp Tham Chiếu',
    ref_layer_desc: 'Lưu ý rằng việc thực hiện lớp tham chiếu được đặt mặc định là 0. Nó sẽ không được thực hiện mà chỉ để tham chiếu căn chỉnh.',
    ref_layer_name: 'Tham Chiếu',
    title: 'Chế độ truyền qua',
    workarea_height: 'Khu Vực Làm Việc (Chiều Cao):',
  },
  promark_connection_test: {
    description: 'Hệ thống sẽ chạy ánh sáng đỏ trong khoảng hai phút để kiểm tra xem kết nối có ổn định hay không.',
    health: 'Độ ổn định:',
    healthy_description: 'Độ ổn định tốt, nhưng tránh di chuyển máy tính quá nhiều trong quá trình khắc.',
    res_0: 'Xuất Sắc',
    res_1: 'Tốt',
    res_2: 'Thấp',
    res_3: 'Rất Thấp',
    res_4: 'Cực Kỳ Thấp',
    restart: 'Kiểm Tra Lại',
    start: 'Bắt Đầu Kiểm Tra',
    stop: 'Dừng Kiểm Tra',
    title: 'Kiểm Tra Độ Ổn Định Kết Nối',
    unhealthy_description: 'Độ ổn định quá thấp. Khuyến nghị thay thế bộ điều hợp mạng hoặc dây nguồn.',
  },
  promark_settings: {
    angle: 'Góc',
    bulge: 'Phồng',
    field: 'Trường',
    galvo_configuration: 'Cấu hình Galvo',
    mark: 'Đánh dấu',
    mark_parameters: 'Tham số đánh dấu',
    offsetX: 'Dịch chuyển X',
    offsetY: 'Dịch chuyển Y',
    preview: 'Xem trước',
    red_dot: 'Chấm Đỏ',
    scale: 'Tỉ lệ',
    scaleX: 'Tỉ lệ X',
    scaleY: 'Tỉ lệ Y',
    skew: 'Nghiêng',
    switchXY: 'Chuyển đổi X/Y',
    title: 'Cài đặt Promark',
    trapezoid: 'Hình thang',
    workarea_hint: 'Bạn có thể thay đổi khu vực làm việc trong "Cài đặt Tài liệu".',
    z_axis_adjustment: {
      section1: 'Điều chỉnh độ cao trục Z để tinh chỉnh tiêu điểm.',
      title: 'Điều Chỉnh Trục Z',
      tooltip1: 'Thử đánh dấu một hình vuông 1x1 cm để xác nhận nếu khoảng cách tiêu điểm hiện tại là phù hợp.',
    },
  },
  qr_code_generator: {
    error_tolerance: 'Sai số dung sai',
    error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
    invert: 'Đảo ngược màu nền',
    placeholder: 'Nhập liên kết hoặc văn bản',
    preview: 'Xem trước',
    title: 'Tạo mã QR',
  },
  rotary_settings: {
    circumference: 'Chu Vi',
    extend_workarea: 'Mở rộng khu vực làm việc',
    mirror: 'Gương',
    object_diameter: 'Đường Kính Vật Thể',
    overlap_size: 'Kích thước chồng lấp',
    rotary_scale: 'Tỷ lệ xoay',
    split_setting: 'Cài đặt chia',
    split_setting_url: 'https://support.flux3dp.com/hc/en-us/articles/12276094010767',
    split_size: 'Kích thước chia',
    type: 'loại',
  },
  select_device: {
    auth_failure: '#811 Xác thực thất bại',
    select_device: 'Chọn thiết bị',
    unable_to_connect: '#810 Không thể kết nối ổn định với máy',
  },
  settings: {
    anti_aliasing: 'Làm mịn cạnh',
    auto_connect: 'Tự động kết nối máy duy nhất',
    auto_switch_tab: 'Chuyển đổi tự động giữa bảng Lớp và Đối tượng',
    autofocus_offset: 'Bù đắp Lấy nét tự động',
    autosave_enabled: 'Tự động lưu',
    autosave_interval: 'Lưu mỗi',
    autosave_number: 'Số lượng tự động lưu',
    autosave_path: 'Vị trí tự động lưu',
    autosave_path_not_correct: 'Đường dẫn không tồn tại.',
    blade_precut_position: 'Vị trí cắt trước',
    blade_precut_switch: 'Cắt trước lưỡi',
    blade_radius: 'Bán kính lưỡi',
    bottom_up: 'Từ dưới lên',
    calculation_optimization: 'Tăng tốc tính toán đường dẫn',
    cancel: 'Hủy',
    caption: 'Cài đặt',
    check_updates: 'Tự động kiểm tra cập nhật',
    close: 'Đóng',
    confirm_remove_default: 'Máy mặc định sẽ bị xóa.',
    confirm_reset: 'Xác nhận đặt lại Beam Studio?',
    continuous_drawing: 'Vẽ liên tục',
    curve_engraving_speed_limit: 'Giới hạn tốc độ khắc đường cong',
    custom_preview_height: 'Chiều cao xem trước tùy chỉnh',
    default_beambox_model: 'Thiết lập mặc định',
    default_borderless_mode: 'Mở đáy mặc định',
    default_enable_autofocus_module: 'Lấy nét tự động mặc định',
    default_enable_diode_module: 'Laser đi-ốt mặc định',
    default_font_family: 'Phông chữ mặc định',
    default_font_style: 'Kiểu phông chữ mặc định',
    default_laser_module: 'Module Laser mặc định',
    default_machine: 'Máy mặc định',
    default_machine_button: 'Trống',
    default_units: 'Đơn vị mặc định',
    diode_offset: 'Bù đắp Laser Diode',
    diode_one_way_engraving: 'Khắc Laser Diode một chiều',
    diode_two_way_warning: 'Phát sáng hai chiều nhanh hơn và có thể tạo ra một số sai lệch vị trí khắc laser. Khuyến nghị kiểm tra trước.',
    disabled: 'Tắt',
    done: 'Áp dụng',
    enable_custom_backlash: 'Bật bù lùi tùy chỉnh',
    enable_low_speed: 'Bật chế độ chuyển động tốc độ thấp',
    enabled: 'Bật',
    engraving_direction: 'Hướng',
    fast_gradient: 'Tối ưu tốc độ',
    font_convert: 'Cài đặt bộ chuyển đổi văn bản thành đường dẫn',
    font_substitute: 'Thay thế ký tự không hỗ trợ',
    grouped_objects: 'Nhóm đối tượng',
    groups: {
      ador_modules: 'Các mô-đun Ador',
      autosave: 'Tự động lưu',
      camera: 'Camera',
      connection: 'Kết nối',
      editor: 'Trình chỉnh sửa',
      engraving: 'Khắc (Quét)',
      general: 'Chung',
      mask: 'Vùng cắt',
      modules: 'Phụ kiện',
      path: 'Đường vẽ',
      privacy: 'Quyền riêng tư',
      text_to_path: 'Chữ',
      update: 'Cập nhật phần mềm',
    },
    guess_poke: 'Tìm kiếm địa chỉ IP máy',
    guides: 'Hướng dẫn',
    guides_origin: 'Gốc hướng dẫn',
    help_center_urls: {
      anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
      calculation_optimization: 'https://support.flux3dp.com/hc/en-us/articles/11146997425039',
      connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
      continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
      default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
      default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
      default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
      fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
      font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
      font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
      image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
      loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
      mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
      reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
      segmented_engraving: 'https://support.flux3dp.com/hc/en-us/articles/12306366019215',
      simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
      vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
    },
    high: 'Cao',
    image_downsampling: 'Chất lượng xem trước ảnh',
    ip: 'Địa chỉ IP máy',
    keep_preview_result: 'Giữ Kết Quả Xem Trước',
    language: 'Ngôn ngữ',
    loop_compensation: 'Bù vòng lặp',
    low: 'Thấp',
    low_laser_for_preview: 'Laser cho Đang chạy khung',
    mask: 'Cắt vùng làm việc',
    medium: 'Trung bình',
    module_offset_2w_ir: 'Bù đắp Laser Hồng ngoại 2W',
    module_offset_10w: 'Bù đắp Laser Diode 10W',
    module_offset_20w: 'Bù đắp Laser Diode 20W',
    module_offset_printer: 'Bù đắp Máy in',
    none: 'Không có',
    normal: 'Cao',
    notification_off: 'Tắt',
    notification_on: 'Bật',
    notifications: 'Thông báo trên màn hình',
    off: 'Tắt',
    on: 'Bật',
    preview_movement_speed: 'Tốc độ xem trước chuyển động',
    printer_advanced_mode: 'Chế độ nâng cao Máy in',
    remove_default_machine_button: 'Xóa',
    reset: 'Đặt lại Beam Studio',
    reset_now: 'Đặt lại Beam Studio',
    segmented_engraving: 'Khắc phân đoạn',
    share_with_flux: 'Chia sẻ Phân tích Beam Studio',
    simplify_clipper_path: 'Tối ưu hóa đường dẫn tính toán',
    single_object: 'Đối tượng đơn',
    tabs: {
      device: 'Máy',
      general: 'Chung',
    },
    text_path_calc_optimization: 'Tối ưu hóa tính toán đường dẫn văn bản',
    top_down: 'Từ trên xuống',
    trace_output: 'Kết quả trích xuất ảnh',
    update_beta: 'Beta',
    update_latest: 'Mới nhất',
    vector_speed_constraint: 'Giới hạn tốc độ',
    wrong_ip_format: 'Định dạng IP không hợp lệ',
  },
  social_media: {
    facebook: 'Trò chuyện với FLUXers, đặt câu hỏi và học mẹo!',
    instagram: 'Nhận những cảm hứng, ưu đãi và quà tặng mới nhất!',
    youtube: 'Xem hướng dẫn Beam Studio và ý tưởng thủ công.',
  },
  support: {
    no_vcredist: 'Vui lòng cài đặt Visual C++ Redistributable 2015\nCó thể tải về tại flux3dp.com',
    no_webgl: 'WebGL không được hỗ trợ. Vui lòng sử dụng thiết bị khác.',
  },
  topbar: {
    alerts: {
      add_content_first: 'Vui lòng thêm đối tượng trước khi thêm nội dung',
      door_opened: 'Vui lòng đóng nắp để bật laser thấp cho khung chạy.',
      fail_to_connect_with_camera: '#803 Không thể kết nối với camera máy. Vui lòng khởi động lại máy hoặc Beam Studio. Nếu lỗi này vẫn xảy ra, vui lòng làm theo <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">hướng dẫn này</a>.',
      fail_to_get_door_status: 'Vui lòng đảm bảo nắp đã đóng để bật laser thấp cho khung chạy.',
      fail_to_start_preview: '#803 Không thể bắt đầu chế độ xem trước. Vui lòng khởi động lại máy hoặc Beam Studio. Nếu lỗi này vẫn xảy ra, vui lòng làm theo <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">hướng dẫn này</a>.',
      headtype_mismatch: 'Phát hiện mô-đun không chính xác. ',
      headtype_none: 'Không tìm thấy mô-đun. ',
      headtype_unknown: 'Phát hiện mô-đun không xác định. ',
      install_correct_headtype: 'Vui lòng lắp đặt mô-đun laser diode 10W/20W đúng để bật laser thấp cho khung chạy.',
      job_origin_unavailable: 'Thiết lập điểm gốc công việc yêu cầu phiên bản firmware 4.3.5 / 5.3.5 trở lên. Bạn có muốn cập nhật firmware ngay bây giờ không?',
      job_origin_warning: 'Bạn hiện đang sử dụng “vị trí hiện tại” làm điểm bắt đầu. Vui lòng đảm bảo đầu laser được di chuyển đến vị trí chính xác để tránh va chạm.',
      power_too_high: 'CÔNG SUẤT QUÁ CAO',
      power_too_high_confirm: 'ĐÃ GHI NHẬN',
      power_too_high_msg: 'Sử dụng công suất laser thấp hơn (dưới 70%) sẽ kéo dài tuổi thọ ống laser.\nNhập "ĐÃ GHI NHẬN" để tiếp tục.',
      pwm_unavailable: 'Chế Độ Độ Sâu yêu cầu phiên bản firmware 4.3.4 / 5.3.4 hoặc cao hơn. Bạn có muốn cập nhật firmware bây giờ không?',
      QcleanScene: 'Bạn có muốn xóa bản vẽ không?<br/>Điều này cũng sẽ xóa lịch sử hoàn tác của bạn!',
      start_preview_timeout: '#803 Đã xảy ra lỗi khi bắt đầu chế độ xem trước. Vui lòng khởi động lại máy hoặc Beam Studio. Nếu lỗi này vẫn xảy ra, vui lòng làm theo <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">hướng dẫn này</a>.',
    },
    frame_task: 'Đang chạy khung',
    hint: {
      polygon: 'Nhấn phím + / - để tăng / giảm số cạnh',
    },
    menu: {
      about: 'Về Beam Studio',
      about_beam_studio: 'Về Beam Studio',
      account: 'Tài khoản',
      add_new_machine: 'Thiết lập máy in',
      align_center: 'Căn giữa',
      anti_aliasing: 'Làm mịn cạnh',
      auto_align: 'Căn Chỉnh Tự Động',
      borderless_mode: 'Chế độ không viền',
      bug_report: 'Báo cáo lỗi',
      calibrate_beambox_camera: 'Hiệu chỉnh máy ảnh',
      calibrate_beambox_camera_borderless: 'Hiệu chỉnh máy ảnh (mở đáy)',
      calibrate_camera_advanced: 'Hiệu chỉnh máy ảnh (Nâng cao)',
      calibrate_diode_module: 'Hiệu chuẩn mô-đun laser đi-ốt',
      calibrate_ir_module: 'Hiệu chuẩn mô-đun hồng ngoại',
      calibrate_printer_module: 'Hiệu chuẩn mô-đun in ấn',
      calibration: 'Hiệu Chuẩn',
      camera_calibration_data: 'Dữ Liệu Hiệu Chỉnh Camera',
      change_logs: 'Nhật ký thay đổi',
      clear_scene: 'Tệp mới',
      close: 'Đóng cửa sổ',
      commands: 'Các lệnh',
      contact: 'Liên hệ chúng tôi',
      copy: 'Sao chép',
      cut: 'Cắt',
      dashboard: 'Bảng điều khiển',
      decompose_path: 'Phân tách',
      delete: 'Xóa',
      design_market: 'Thị trường thiết kế',
      dev_tool: 'Công Cụ Gỡ Lỗi',
      disassemble_use: 'Tháo rời',
      document_setting: 'Thiết lập tài liệu',
      document_setting_short: 'Tài liệu',
      download_data: 'Tải Xuống Dữ Liệu',
      download_log: 'Tải xuống nhật ký',
      download_log_canceled: 'Đã hủy tải xuống nhật ký',
      download_log_error: 'Đã xảy ra lỗi không xác định, vui lòng thử lại sau',
      duplicate: 'Nhân bản',
      edit: 'Chỉnh sửa',
      example_files: 'Tệp Mẫu',
      export_BVG: 'BVG',
      export_flux_task: 'Nhiệm vụ FLUX',
      export_JPG: 'JPG',
      export_PNG: 'PNG',
      export_SVG: 'SVG',
      export_to: 'Xuất ra...',
      file: 'Tệp',
      fit_to_window: 'Vừa với kích thước cửa sổ',
      follow_us: 'Theo dõi chúng tôi',
      forum: 'Diễn đàn cộng đồng',
      group: 'Nhóm',
      help: 'Trợ giúp',
      help_center: 'Trung tâm trợ giúp',
      hide: 'Ẩn Beam Studio',
      hideothers: 'Ẩn các cửa sổ khác',
      image_crop: 'Cắt',
      image_curve: 'Đường cong',
      image_invert: 'Đảo ngược',
      image_sharpen: 'Làm nét',
      image_stamp: 'Khắc dấu',
      image_vectorize: 'Vẽ vector',
      import_acrylic_focus_probe: 'Đầu dò tiêu điểm acrylic - 3mm',
      import_ador_laser_example: 'Ví dụ của Ador Laser',
      import_ador_printing_example_full: 'Ví dụ in của Ador - Đầy đủ màu',
      import_ador_printing_example_single: 'Ví dụ in của Ador - Một màu',
      import_beambox_2_example: 'Ví dụ về Beambox II',
      import_beambox_2_focus_probe: 'Đầu dò Lấy Nét Beambox II - 3mm',
      import_hello_beambox: 'Ví dụ của Beambox',
      import_hello_beamo: 'Ví dụ của beamo',
      import_hexa_example: 'Ví dụ về HEXA',
      import_material_printing_test: 'Kiểm tra in vật liệu',
      import_material_testing_cut: 'Kiểm tra cắt vật liệu',
      import_material_testing_engrave: 'Kiểm tra khắc vật liệu',
      import_material_testing_line: 'Kiểm tra đường vật liệu',
      import_material_testing_old: 'Kiểm tra vật liệu - Cổ điển',
      import_material_testing_simple_cut: 'Kiểm tra cắt vật liệu - Đơn giản',
      import_promark_example: 'Ví dụ về Promark',
      import_promark_mopa_20w_color: 'Kiểm Tra Màu MOPA 20W',
      import_promark_mopa_60w_color: 'Kiểm Tra Màu MOPA 60W',
      import_promark_mopa_100w_color: 'Kiểm Tra Màu MOPA 100W',
      keyboard_shortcuts: 'Phím tắt',
      layer_color_config: 'Cài đặt màu',
      layer_setting: 'Lớp',
      link: {
        beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
        contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
        design_market: 'https://dmkt.io',
        downloads: 'https://flux3dp.com/downloads/',
        forum: 'https://www.facebook.com/groups/flux.laser/',
        help_center: 'https://helpcenter.flux3dp.com/',
        shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
      },
      log: {
        camera: 'Camera',
        cloud: 'Đám mây',
        discover: 'Khám phá',
        hardware: 'Phần cứng',
        network: 'Mạng',
        player: 'Trình phát',
        robot: 'Robot',
        usb: 'USB',
        usblist: 'Danh sách USB',
      },
      machine_info: 'Thông tin máy in',
      machines: 'Máy in',
      manage_account: 'Quản lý tài khoản của tôi',
      material_test: 'Kiểm Tra Vật Liệu',
      minimize: 'Thu nhỏ',
      my_account: 'Tài khoản của tôi',
      network_testing: 'Kiểm tra cài đặt mạng',
      object: 'Đối tượng',
      offset: 'Dịch chuyển',
      open: 'Mở',
      paste: 'Dán',
      paste_in_place: 'Dán tại vị trí',
      path: 'Đường dẫn',
      photo_edit: 'Ảnh',
      preferences: 'Tùy chọn',
      promark_color_test: 'Kiểm Tra Màu Promark',
      questionnaire: 'Bảng câu hỏi phản hồi',
      quit: 'Thoát',
      recent: 'Mở Gần Đây',
      redo: 'Làm lại',
      reload_app: 'Tải lại Ứng Dụng',
      reset: 'Đặt lại',
      rotary_setup: 'Cài Đặt Xoay',
      rotate: 'Xoay',
      samples: 'Ví dụ',
      save_as: 'Lưu dưới dạng...',
      save_scene: 'Lưu',
      save_to_cloud: 'Lưu lên đám mây',
      scale: 'Tỷ lệ',
      service: 'Dịch vụ',
      set_as_default: 'Đặt làm mặc định',
      show_gesture_tutorial: 'Hướng dẫn cử chỉ',
      show_grids: 'Hiển thị lưới',
      show_layer_color: 'Sử dụng màu lớp',
      show_rulers: 'Hiển thị thước kẻ',
      show_start_tutorial: 'Xem hướng dẫn bắt đầu',
      show_ui_intro: 'Xem giới thiệu giao diện',
      sign_in: 'Đăng nhập',
      sign_out: 'Đăng xuất',
      software_update: 'Cập nhật phần mềm',
      svg_edit: 'SVG',
      switch_to_beta: 'Chuyển sang Bản Beta',
      switch_to_latest: 'Chuyển sang Bản Ổn Định',
      tools: {
        box_generator: 'Trình Tạo Hộp',
        code_generator: 'Trình tạo mã',
        material_test_generator: 'Trình tạo thử nghiệm vật liệu',
        title: 'Công cụ',
      },
      tutorial: 'Hướng dẫn in Delta',
      undo: 'Hoàn tác',
      ungroup: 'Bỏ nhóm',
      update: 'Kiểm Tra Cập Nhật',
      update_firmware: 'Cập nhật phần mềm',
      upload_data: 'Tải Lên Dữ Liệu',
      using_beam_studio_api: 'Sử dụng API Beam Studio',
      view: 'Xem',
      window: 'Cửa sổ',
      zoom_in: 'Phóng to',
      zoom_out: 'Thu nhỏ',
      zoom_with_window: 'Tự động vừa với kích thước cửa sổ',
    },
    preview: 'XEM TRƯỚC',
    preview_press_esc_to_stop: 'Nhấn ESC để dừng xem trước camera.',
    rename_tab: 'Đổi tên tab',
    select_machine: 'Chọn máy',
    tag_names: {
      dxf: 'Đối tượng DXF',
      ellipse: 'Hình elip',
      g: 'Nhóm',
      image: 'Hình ảnh',
      line: 'Đường thẳng',
      multi_select: 'Nhiều đối tượng',
      no_selection: 'Không có lựa chọn',
      pass_through_object: 'Đối Tượng Xuyên Qua',
      path: 'Đường dẫn',
      polygon: 'Đa giác',
      rect: 'Hình chữ nhật',
      svg: 'Đối tượng SVG',
      text: 'Văn bản',
      text_path: 'Văn bản trên đường dẫn',
      use: 'Đối tượng nhập khẩu',
    },
    task_preview: 'Xem trước đường đi',
    titles: {
      settings: 'Tùy chọn',
    },
    untitled: 'Chưa đặt tên',
  },
  topmenu: {
    credit: 'Beam Studio được tạo ra nhờ dự án nguồn mở <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> và các <a target="_blank" href="https://flux3dp.com/credits/">phần mềm nguồn mở</a> khác.',
    device: {
      download_log_canceled: 'Đã hủy tải nhật ký',
      download_log_error: 'Đã xảy ra lỗi không xác định, vui lòng thử lại sau',
      log: {
        usblist: 'Danh sách USB',
      },
      network_test: 'Kiểm tra mạng',
    },
    file: {
      all_files: 'Tất cả Tập tin',
      clear_recent: 'Xóa Mới Mở Gần Đây',
      converting: 'Đang chuyển đổi sang Hình ảnh...',
      fcode_files: 'Mã FLUX',
      import: 'Nhập',
      jpg_files: 'JPG',
      label: 'Tập tin',
      path_not_exit: 'Dường như đường dẫn này không còn tồn tại trên đĩa nữa.',
      png_files: 'PNG',
      save_fcode: 'Xuất FLUX Task',
      save_jpg: 'Xuất JPG',
      save_png: 'Xuất PNG',
      save_scene: 'Lưu Cảnh',
      save_svg: 'Xuất SVG',
      scene_files: 'Cảnh Beam Studio',
      svg_files: 'SVG',
    },
    ok: 'OK',
    version: 'Phiên bản',
  },
  tutorial: {
    ask_retry_calibration: 'Bạn có muốn hiệu chuẩn lại máy ảnh không?',
    camera_calibration_failed: 'Hiệu chỉnh camera thất bại',
    gesture: {
      click: 'Chạm để chọn đối tượng.',
      drag: 'Kéo để chọn nhiều đối tượng.',
      hold: 'Nhấn và giữ để mở menu ngữ cảnh.',
      pan: 'Cuộn màn hình bằng hai ngón tay.',
      zoom: 'Phóng to / thu nhỏ màn hình bằng hai ngón tay.',
    },
    links: {
      adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
    },
    look_for_machine: 'Đang tìm kiếm máy cho hướng dẫn...',
    needNewInterfaceTutorial: 'Bạn có muốn bắt đầu một hướng dẫn cho giao diện mới của Beam Studio không?<br/>(Bạn có thể bỏ qua bây giờ và bắt đầu sau bằng cách nhấp vào "Trợ giúp" > "Hiện Giới Thiệu Giao Diện".)',
    needNewUserTutorial: 'Bạn có muốn bắt đầu một hướng dẫn không?<br/>(Bạn có thể bỏ qua bây giờ và bắt đầu sau bằng cách nhấp vào "Trợ giúp" > "Hiện Hướng dẫn Bắt đầu".)',
    newInterface: {
      add_new_layer: 'Thêm lớp mới',
      align_controls: 'Căn chỉnh các điều khiển',
      basic_shapes: 'Hình cơ bản',
      camera_preview: 'Xem trước Camera',
      drag_to_sort: 'Kéo để sắp xếp',
      end_alert: 'Bạn có chắc chắn muốn kết thúc hướng dẫn giao diện mới?',
      flip: 'Lật',
      group_controls: 'Nhóm các điều khiển',
      layer_controls: 'Nhấp phải để chọn Điều khiển lớp:\nNhân bản / Gộp / Khóa / Xóa các Lớp',
      object_actions: 'Hành động đối tượng',
      pen_tool: 'Công cụ bút',
      rename_by_double_click: 'Đổi tên bằng cách nhấp đúp',
      select_image_text: 'Chọn / Hình ảnh / Văn bản',
      select_machine: 'Chọn máy',
      shape_operation: 'Thao tác hình dạng',
      start_work: 'Bắt đầu công việc',
      switch_between_layer_panel_and_object_panel: 'Chuyển đổi giữa Bảng lớp và Bảng đối tượng',
    },
    newUser: {
      add_new_layer: 'Thêm lớp mới',
      adjust_focus: '2. Điều chỉnh tiêu điểm',
      close_cover: '3. Đóng nắp',
      drag_to_draw: 'Kéo để vẽ',
      draw_a_circle: 'Vẽ một vòng tròn',
      draw_a_rect: 'Vẽ một hình chữ nhật',
      end_alert: 'Bạn có chắc chắn muốn kết thúc hướng dẫn?',
      end_preview_mode: 'Kết thúc Chế độ xem trước',
      infill: 'Bật độ rỗng',
      please_select_wood_cutting: 'Vui lòng chọn cài đặt sẵn "Gỗ - Cắt".',
      please_select_wood_engraving: 'Vui lòng chọn cài đặt sẵn "Gỗ - Khắc".',
      preview_the_platform: 'Xem trước nền tảng',
      put_wood: '1. Đặt mẫu gỗ',
      send_the_file: 'Gửi tệp',
      set_preset_wood_cut: 'Đặt cài đặt sẵn: Gỗ - Cắt',
      set_preset_wood_engraving: 'Đặt cài đặt sẵn: Gỗ - Khắc',
      switch_to_layer_panel: 'Chuyển sang Bảng lớp',
      switch_to_object_panel: 'Chuyển đổi sang Bảng Đối tượng',
      switch_to_preview_mode: 'Chuyển sang Chế độ xem trước',
    },
    next: 'TIẾP',
    retry: 'Thử lại',
    set_connection: 'Thiết lập kết nối',
    skip: 'Bỏ qua',
    skip_tutorial: 'Bạn đã bỏ qua hướng dẫn. Bạn luôn có thể bắt đầu hướng dẫn bằng cách nhấp vào "Trợ giúp" > "Hiển thị hướng dẫn bắt đầu"',
    suggest_calibrate_camera_first: 'Chúng tôi khuyên người dùng nên hiệu chỉnh máy ảnh ban đầu và làm nét lại trước mỗi lần xem trước để có kết quả tốt nhất.<br/>Bạn có muốn xác nhận để thực hiện hiệu chỉnh ngay bây giờ không?<br/>(Bạn có thể bỏ qua nó bây giờ và làm sau bằng cách nhấp vào "Menu" > "Máy" > [Máy của Bạn] > "Hiệu chỉnh Máy Ảnh".)',
    tutorial_complete: 'Đó là tất cả cho hướng dẫn. Bây giờ là lúc để tạo!',
    unable_to_find_machine: 'Không tìm thấy máy cho Hướng dẫn. Bạn có muốn đến trang cài đặt kết nối, thử lại hoặc bỏ qua hướng dẫn?',
    welcome: 'CHÀO MỪNG',
  },
  update: {
    cannot_reach_internet: '#823 Không kết nối được máy chủ<br/>Vui lòng kiểm tra kết nối internet',
    download: 'CẬP NHẬT TRỰC TUYẾN',
    firmware: {
      caption: 'Có bản cập nhật phần mềm máy',
      confirm: 'TẢI LÊN',
      firmware_too_old_update_by_sdcard: 'Phiên bản firmware quá cũ. Vui lòng cập nhật firmware bằng thẻ SD.',
      force_update_message: '#814 Vui lòng cập nhật máy của bạn lên phiên bản firmware mới nhất.',
      latest_firmware: {
        cant_get_latest: 'Không thể lấy thông tin về phiên bản phần mềm mới nhất.',
        caption: 'Cập nhật phần mềm máy',
        message: 'Bạn đang sử dụng phiên bản phần mềm máy mới nhất',
        still_update: 'CẬP NHẬT',
      },
      message_pattern_1: '"%s" đã sẵn sàng để cập nhật phần mềm.',
      message_pattern_2: 'Phiên bản phần mềm máy %s v%s hiện có sẵn - Bạn đang dùng phiên bản %s.',
      too_old_for_web: 'Phiên bản firmware máy hiện tại của bạn là %s.\nNếu bạn muốn sử dụng phiên bản trực tuyến của Beam Studio, vui lòng cập nhật firmware máy lên phiên bản mới nhất.',
      update_fail: '#822 Cập nhật thất bại',
      update_success: 'Cập nhật firmware thành công',
      upload_file: 'Tải lên firmware (*.bin / *.fxfw)',
    },
    install: 'CÀI ĐẶT',
    later: 'SAU',
    preparing: 'Đang chuẩn bị...',
    release_note: 'Ghi chú phát hành:',
    skip: 'Bỏ qua Phiên Bản này',
    software: {
      available_switch: 'Beam Studio phiên bản %s đã sẵn sàng. Bạn đang dùng phiên bản %s. Bạn có muốn chuyển sang phiên bản này?',
      available_update: 'Beam Studio phiên bản %s đã sẵn sàng. Bạn đang dùng phiên bản %s. Bạn có muốn tải bản cập nhật?',
      caption: 'Có bản cập nhật Beam Studio',
      check_update: 'Kiểm tra cập nhật',
      checking: 'Đang kiểm tra cập nhật',
      downloading: 'Đang tải các bản cập nhật trong nền, bạn có thể nhấn "OK" để tiếp tục công việc.',
      install_or_not: 'sẵn sàng để cập nhật. Bạn có muốn khởi động lại và cập nhật ngay bây giờ?',
      no: 'Không',
      no_response: 'Không kết nối được máy chủ, vui lòng kiểm tra cài đặt mạng.',
      not_found: 'Bạn đang sử dụng phiên bản mới nhất của Beam Studio.',
      switch_or_not: 'sẵn sàng để nâng cấp. Bạn có muốn khởi động lại và nâng cấp ngay bây giờ?',
      switch_version: 'Chuyển đổi phiên bản',
      switch_version_not_found: 'Không tìm thấy Phiên Bản có thể Nâng cấp.',
      update_for_ador: 'Phiên bản phần mềm hiện tại %s không tương thích, vui lòng tải phiên bản Beam Studio mới nhất cho Ador.',
      yes: 'Có',
    },
    update: 'Cập nhật',
    updating: 'Đang cập nhật...',
    upload: 'TẢI LÊN',
  },
  web_cam: {
    no_device: 'Không thể phát hiện thiết bị camera. Vui lòng kết nối lại camera và thử lại.',
    no_permission: 'Beam Studio không có quyền truy cập vào camera. Vui lòng đảm bảo rằng quyền đã được cấp cho Beam Studio trong cài đặt trình duyệt hoặc hệ thống.',
  },
  z_speed_limit_test: {
    alert_before: 'Trước khi bắt đầu khắc đường cong, hệ thống sẽ thực hiện kiểm tra tải trọng để ngăn chặn mất bước do trọng lượng quá mức.',
    alert_failed: 'Trọng lượng của đối tượng hiện tại vượt quá giới hạn tải cho tốc độ trục Z hiện tại. Vui lòng giảm tốc độ trục Z trên bảng điều khiển máy hoặc thay thế đối tượng khắc trước khi kiểm tra lại.',
    ignore: 'Bỏ qua',
    retest: 'Kiểm tra lại',
    testing: 'Đang thực hiện kiểm tra tải...',
  },
};

export default lang;
