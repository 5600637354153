// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-views-monitor-MonitorTabExtraContent-module__btn--I9cgY{font-size:20px;margin:0 0 0 12px;border:none;background:none}._-_-packages-core-src-web-app-views-monitor-MonitorTabExtraContent-module__btn--I9cgY:disabled{opacity:.3}._-_-packages-core-src-web-app-views-monitor-MonitorTabExtraContent-module__btn--I9cgY:not(:disabled):hover{color:#4096ff}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/views/monitor/MonitorTabExtraContent.module.scss"],"names":[],"mappings":"AAAA,uFACE,cAAA,CACA,iBAAA,CACA,WAAA,CACA,eAAA,CAEA,gGACE,UAAA,CAIA,4GACE,aAAA","sourcesContent":[".btn {\n  font-size: 20px;\n  margin: 0 0 0 12px;\n  border: none;\n  background: none;\n\n  &:disabled {\n    opacity: 0.3;\n  }\n\n  &:not(:disabled) {\n    &:hover {\n      color: #4096ff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "_-_-packages-core-src-web-app-views-monitor-MonitorTabExtraContent-module__btn--I9cgY"
};
export default ___CSS_LOADER_EXPORT___;
