// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-ImageEditPanel-components-Sider-PanelContent-module__wrapper--sRurM{flex:1;padding:20px 40px 0;overflow:hidden}@media screen and (max-width: 600px){._-_-packages-core-src-web-app-components-ImageEditPanel-components-Sider-PanelContent-module__wrapper--sRurM{padding:12px 12px 0}}._-_-packages-core-src-web-app-components-ImageEditPanel-components-Sider-PanelContent-module__wrapper--sRurM ._-_-packages-core-src-web-app-components-ImageEditPanel-components-Sider-PanelContent-module__hint-text--7\\+9nA{color:#7c7c7c;font-size:14px;margin-bottom:40px;display:flex;align-items:center;column-gap:8px}._-_-packages-core-src-web-app-components-ImageEditPanel-components-Sider-PanelContent-module__wrapper--sRurM ._-_-packages-core-src-web-app-components-ImageEditPanel-components-Sider-PanelContent-module__hint-text--7\\+9nA ._-_-packages-core-src-web-app-components-ImageEditPanel-components-Sider-PanelContent-module__icon--HkueI{font-size:16px}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/ImageEditPanel/components/Sider/PanelContent.module.scss"],"names":[],"mappings":"AAAA,8GACE,MAAA,CACA,mBAAA,CACA,eAAA,CAEA,qCALF,8GAMI,mBAAA,CAAA,CAGF,+NACE,aAAA,CACA,cAAA,CACA,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,cAAA,CAEA,0UACE,cAAA","sourcesContent":[".wrapper {\n  flex: 1;\n  padding: 20px 40px 0;\n  overflow: hidden;\n\n  @media screen and (max-width: 600px) {\n    padding: 12px 12px 0;\n  }\n\n  .hint-text {\n    color: #7c7c7c;\n    font-size: 14px;\n    margin-bottom: 40px;\n    display: flex;\n    align-items: center;\n    column-gap: 8px;\n\n    .icon {\n      font-size: 16px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "_-_-packages-core-src-web-app-components-ImageEditPanel-components-Sider-PanelContent-module__wrapper--sRurM",
	"hint-text": "_-_-packages-core-src-web-app-components-ImageEditPanel-components-Sider-PanelContent-module__hint-text--7+9nA",
	"icon": "_-_-packages-core-src-web-app-components-ImageEditPanel-components-Sider-PanelContent-module__icon--HkueI"
};
export default ___CSS_LOADER_EXPORT___;
