// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-dialogs-CodeGenerator-index-module__modal--8XDnr{min-width:520px}._-_-packages-core-src-web-app-components-dialogs-CodeGenerator-index-module__title-flex--ZYwXA{line-height:\"24px\";margin-bottom:20}._-_-packages-core-src-web-app-components-dialogs-CodeGenerator-index-module__fw-n--Y2qVD{font-weight:normal}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/dialogs/CodeGenerator/index.module.scss"],"names":[],"mappings":"AAAA,2FACE,eAAA,CAGF,gGACE,kBAAA,CACA,gBAAA,CAGF,0FACE,kBAAA","sourcesContent":[".modal {\n  min-width: 520px;\n}\n\n.title-flex {\n  line-height: '24px';\n  margin-bottom: 20;\n}\n\n.fw-n {\n  font-weight: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "_-_-packages-core-src-web-app-components-dialogs-CodeGenerator-index-module__modal--8XDnr",
	"title-flex": "_-_-packages-core-src-web-app-components-dialogs-CodeGenerator-index-module__title-flex--ZYwXA",
	"fw-n": "_-_-packages-core-src-web-app-components-dialogs-CodeGenerator-index-module__fw-n--Y2qVD"
};
export default ___CSS_LOADER_EXPORT___;
