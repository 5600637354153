// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/.pnpm/css-loader@5.2.7_webpack@5.98.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._-_-packages-core-src-web-app-components-settings-connection-TestInfo-module__container--mFSyO{font-size:12px;line-height:23px;letter-spacing:.1px;white-space:pre;color:#999;font-weight:600;height:100%}._-_-packages-core-src-web-app-components-settings-connection-TestInfo-module__container--mFSyO ._-_-packages-core-src-web-app-components-settings-connection-TestInfo-module__info--o1yL6{padding:5px 0;color:#333}", "",{"version":3,"sources":["webpack://./../../packages/core/src/web/app/components/settings/connection/TestInfo.module.scss"],"names":[],"mappings":"AAAA,gGACE,cAAA,CACA,gBAAA,CACA,mBAAA,CACA,eAAA,CACA,UAAA,CACA,eAAA,CACA,WAAA,CAEA,2LACE,aAAA,CACA,UAAA","sourcesContent":[".container {\n  font-size: 12px;\n  line-height: 23px;\n  letter-spacing: 0.1px;\n  white-space: pre;\n  color: #999999;\n  font-weight: 600;\n  height: 100%;\n\n  .info {\n    padding: 5px 0;\n    color: #333333;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_-_-packages-core-src-web-app-components-settings-connection-TestInfo-module__container--mFSyO",
	"info": "_-_-packages-core-src-web-app-components-settings-connection-TestInfo-module__info--o1yL6"
};
export default ___CSS_LOADER_EXPORT___;
